import { useState, useRef, useEffect, useMemo } from "react";
import { Modal, Form, Stack, InputGroup, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
// import NoRecord from "../includes/NoRecord";
// import FormSelect from "../includes/FormSelect";
// import { useTranslation } from "react-i18next";
import SettingProductItem from "./SettingProductItem";

const SettingProduct = () => {
    const { session } = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [recordFound, setRecordFound] = useState(0);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [initVal, setInitVal] = useState({});

    const [openCanvas, setOpenCanvas] = useState(false);
    const [canvasData, setCanvasData] = useState({});

    const { Formik } = formik;

    const containerStyle = useMemo(() => ({ width: "100%", height: "78vh", paddingTop: 10 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridColDef = useMemo(() => {
        return {
        resizable: true,
        sortable: false,
        };
    }, []);

    const [gridColumn] = useState([
        // {
        //     headerName: "No",
        //     field: "",
        //     maxWidth: 70,
        //     headerClass: "center",
        //     cellClass: "center",
        //     cellRenderer: (params) => {
        //       if (params.data) {
        //         return params.rowIndex + 1;
        //       } else {
        //         return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        //       }
        //     },
        // },
        {
            headerName: 'Product Category Title',
            field: 'cat_title',
            filter: true,
            flex: 1,
            minWidth: 300,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div onClick={() => {setOpenCanvas(true); setCanvasData(params.data);}}>
                            {params.data.cat_title}
                        </div>
                    );
                }
            }
        },
        {
            headerName: 'Action',
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon me-2" onClick={() => {handleDelete(params.data.product_cat_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        axios.get("ws/ws_product.php", {
            params: {
                task: 'getProductCategories',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setRowData([]);
            setRecordFound(0);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (e) => {
        const searchText = e.target.value;
        const filterInstance = gridRef.current.api.getFilterInstance('cat_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    const addSchema = yup.object().shape({
        prod_cat_title: yup.string().required('This field is required')
    });

    const sendAdd = (values) => {
        axios.get("ws/ws_product.php", {
            params: {
                task: 'addProductCategories',
                cat_title: values.prod_cat_title,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully added',
                    timer: 1500
                })
                .then(result => {
                    getGridData();
                    setIsAdd(false);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const handleEdit = (data) => {
        var init = {
            prod_cat_id: data.product_cat_id,
            prod_cat_title: data.cat_title
        }

        setInitVal(init);
        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        prod_cat_title: yup.string().required('This field is required')
    });

    const sendEdit = (values) => {
        axios.get("ws/ws_product.php", {
            params: {
                task: 'updateProductCategories',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                cat_id: values.prod_cat_id,
                cat_title: values.prod_cat_title,
                cat_type: ""
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully updated',
                    timer: 1500
                })
                .then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: 'error',
            title: 'Are you sure?',
            text: 'This product category will be delete',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get("ws/ws_product.php", {
                    params: {
                        task: 'deleteProductCategories',
                        cat_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Successfully deleted',
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }else if(Number(data.status) === 2){
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'This product category cannot be deleted because there are products that still exist in product category . Please delete the product first'
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Please try again or contact your IT Support'
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: error.message
                    });
                });
            }
        });
    }

    return(
        <div>
            {loading ? <Loader/> : 
            <div className="py-3 px-3">
                <Stack direction="horizontal" gap={2} className="mb-3">
                    <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>
                        Add Product Category
                    </button>
                    <Form.Group style={{width: '280px'}}>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Search by product category title"
                                onChange={handleSearch}
                            />
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="fontAwesomeIcon" size="lg"/>
                            </InputGroup.Text>                        
                        </InputGroup>
                    </Form.Group>
                    <div className="ms-auto">
                        {recordFound === 1 ? `${recordFound} record found.` : recordFound > 1 ? `${recordFound} records found.` : `No record found.`}
                    </div>
                </Stack>

                <div style={containerStyle}>
                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                        <AgGridReact 
                            ref={gridRef}
                            columnDefs={gridColumn} 
                            rowData={rowData} 
                            defaultColDef={gridColDef} 
                            rowHeight={50} 
                            pagination={false} 
                        />
                    </div>
                </div>

                <Modal show={isAdd} onHide={setIsAdd}>
                    <Formik
                        validationSchema={addSchema}
                        onSubmit={sendAdd}
                        initialValues={
                            {
                                prod_cat_title: ''
                            }
                        }
                    >
                        {({ handleSubmit, setFieldValue, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title as={'h6'}>Add Product Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>Product category title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            isInvalid={errors.prod_cat_title && touched.prod_cat_title}
                                            onChange={(e) => {setFieldValue('prod_cat_title', e.target.value)}}
                                        />
                                        {errors.prod_cat_title && touched.prod_cat_title && <div className="op-error-message">{errors.prod_cat_title}</div>}
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>

                <Modal show={isEdit} onHide={setIsEdit}>
                    <Formik
                        validationSchema={editSchema}
                        onSubmit={sendEdit}
                        initialValues={initVal}
                    >
                        {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title as={'h6'}>Edit product category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label>Product Category Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            isInvalid={errors.prod_cat_title && touched.prod_cat_title}
                                            value={values.prod_cat_title}
                                            onChange={(e) => {setFieldValue('prod_cat_title', e.target.value)}}
                                        />
                                        {errors.prod_cat_title && touched.prod_cat_title && <div className="op-error-message">{errors.prod_cat_title}</div>}
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>

                <Offcanvas show={openCanvas} onHide={setOpenCanvas} placement="bottom" style={{left: 57, height: "100vh"}}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title as={'h6'}>Product: {canvasData.cat_title}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <SettingProductItem id={canvasData.product_cat_id}/>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            }
        </div>
    );
}

export default SettingProduct;