import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card, Stack, Container } from "react-bootstrap";
import { useKit } from "./CustomerKit";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../lib/scss/saleskit-project.scss";

const CustomerKitProject = () => {
  const { projectData, hostUrl, hostUrlType, company_id } = useKit();
  const { key } = useParams();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState({});

  // SET FUNCTION ----------------------------

  const setProjectImage = (image) => {
    if (image) {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg`;
    }
  };

  // OPEN FUNCTION ---------------------------

  const openProject = (record) => {
    setTimeout(() => {
      navigate(`/rea-sales-kit/${key}/${record.project_id}`);
    }, 500);
  };

  // RENDER FUNCTION -------------------------

  return (
    <Container className="saleskit-project">
      <Row className="g-0">
        {projectData.length > 0 &&
          projectData.map((record, idx) => (
            <Col key={idx} xxl={12} className="mb-3">
              <Card className="shadow-sm border-0 h-100">
                <Row className="g-0">
                  <Col xxl={7} className="cursor-pointer" onClick={() => openProject(record)}>
                    <Swiper
                      navigation={false}
                      thumbs={{ swiper: thumbsSwiper[idx] }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="swiper-main"
                      style={{
                        "--swiper-navigation-color": "white",
                        "--swiper-pagination-color": "transparent",
                      }}
                    >
                      {record.project_image.split(",").map((data, i) => (
                        <SwiperSlide key={i}>
                          <img src={setProjectImage(data)} className="cursor-pointer" alt="" />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Col>
                  <Col xxl={5} className="p-3">
                    <Stack direction="vertical" className="h-100">
                      <h5 className="cursor-pointer" onClick={() => openProject(record)}>
                        {record.project_name}
                      </h5>
                      {record.project_location && <p className="mb-0 text-muted">{record.project_location}</p>}
                      {record.project_desc && <p className="mb-0">{record.project_desc}</p>}
                      
                      <div className="mt-auto">
                        <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [idx]: swiper }))} spaceBetween={5} slidesPerView={4} freeMode={true} watchSlidesProgress={true} className="swiper-thumbnail" modules={[FreeMode, Navigation, Thumbs]}>
                          {record.project_image.split(",").map((data, i) => (
                            <SwiperSlide key={i}>
                              <img src={setProjectImage(data)} alt="" className="cursor-pointer" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Stack>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
};

export default CustomerKitProject;
