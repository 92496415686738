import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import AsyncSelect from "react-select/async-creatable";
import { useState } from "react";
import debounce from "lodash/debounce";

const FormSelectCreatable = ({ options, value, valueDefault, onChange, placeholder = "", shadow, icon, isSearchable, width, border = 1, isInvalid = false, loadOptions, isLoading }) => {
  const [inputValue, setInputValue] = useState("");

  const selectStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "10px",
      fontSize: "12px",
      width: width,
      borderRadius: "0.375rem",
      border: state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : provided.border,
      boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
      "&:hover": {
        border: "1px solid hsl(0, 0%, 80%)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginRight: "5px",
    }),
    options: (provided) => ({
      ...provided,
      zIndex: 4,
    }),
  };

  const DropdownIndicator = (props) => <components.DropdownIndicator {...props}>{null}</components.DropdownIndicator>;

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const debouncedLoadOptions = inputValue ? debounce(loadOptions, 1000) : null;

  const formatOptionLabel = (option, { context }) => {
    if (Number(option.value) === 0) {
      if (context === "value") {
        return option.label;
      }
      return option.customLabel;
    } else {
      return option.label;
    }
  };

  const SingleValue = ({ children, ...props }) => {
    return (
      <components.SingleValue {...props}>
        {props.data.value === 0 ? <div className="op-label badge position-absolute end-0 me-2 bg-primary text-light">New</div> : null}
        {children}
      </components.SingleValue>
    );
  };

  return (
    <AsyncSelect
      className={shadow ? "shadow" : ""}
      cacheOptions
      defaultOptions
      allowCreateWhileLoading={false}
      createOptionPosition={"first"}
      formatOptionLabel={formatOptionLabel}
      loadOptions={debouncedLoadOptions}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, SingleValue }}
      styles={selectStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "var(--op-primary-hover-color)",
          primary: "var(--op-primary-color)",
          primary50: "var(--op-primary-hover-color)",
        },
      })}
      placeholder={placeholder}
      onChange={onChange}
      onInputChange={handleInputChange}
    />
  );
};

export default FormSelectCreatable;
