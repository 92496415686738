import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import moment from "moment";
import { useTranslation } from "react-i18next";

const BUSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingBU_headername_1'),
            field: "date_time_create",
            resizable: true,
            width: 300,
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(params.data.date_time_create).format('lll');
                }
            }
        },
        {
            headerName: t('SettingBU_headername_2'),
            field: "unit_title",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingBU_headername_3'),
            resizable: true,
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon" onClick={()=>{handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {

        axios.get('ws/ws_setting.php',{
            params: {
                task: "getAllBU",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {

                data.record.sort(function(a, b) { return new Date(b.date_time_create) - new Date(a.date_time_create) });

                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);

            }else{
                
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);

            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        unit_title: yup.string().required(t('SettingBU_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_setting.php', {
            params:{
                task: "addBUV2",
                unit_title: values.unit_title,
                user_tag_id: '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {

            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingBU_alert_success_title'),
                    text: t('SettingBU_alert_success_add_text'),
                    timer: 1500
                }).then(result => {
                    setIsLoading(true);
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingBU_alert_success_title'),
                    text: t('SettingBU_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        unit_title_edit: yup.string().required(t('SettingBU_required_field'))
    });

    const handleEdit = (data) => {
        setEditInitVal({
            business_unit_id: data.business_unit_id,
            unit_title_edit: data.unit_title
        });

        setIsEdit(true);
    }

    const sendEdit = (values) => {
        axios.get('ws/ws_setting.php', {
            params:{
                task: "updateBUV2",
                business_unit_id: values.business_unit_id,
                unit_title: values.unit_title_edit,
                user_tag_id: '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingBU_alert_success_title'),
                    text: t('SettingBU_alert_success_edit_text'),
                    timer: 1500
                }).then(result => {
                    setIsLoading(true);
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingBU_alert_failed_title'),
                    text: t('SettingBU_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingBU_add_bu')}</button>
                            <div className="px-2 ms-auto">Result: {recordFound === 1 ? t('SettingBU_record_one', {count: recordFound}):recordFound > 1 ? t('SettingBU_record_many', {count: recordFound}):t('SettingBU_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    unit_title: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingBU_modalHeader_add')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingBU_modalBody_add_title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.unit_title && touched.unit_title}
                                                onChange={handleChange('unit_title')}
                                            />
                                            {errors.unit_title && touched.unit_title && <div className="op-error-message">{errors.unit_title}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingBU_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingBU_modalHeader_edit')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingBU_modalBody_edit_title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.unit_title_edit && touched.unit_title_edit}
                                                value={values.unit_title_edit}
                                                onChange={handleChange("unit_title_edit")}
                                            />
                                            {errors.unit_title_edit && touched.unit_title_edit && <div className="op-error-message">{errors.unit_title_edit}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingBU_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );
}

export default BUSetting;