import { useState, useEffect, useRef, useMemo } from "react";
import { Col, Container, Row, Stack, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { useAuth } from "../auth/AuthContext";
import { FormSelect } from "../includes/FormCustom";
import { useNavigate } from "react-router-dom";
import Loader from "../includes/Loader";
import { faMapLocationDot } from "@fortawesome/pro-solid-svg-icons";
import { setCurrency } from "../lib/js/Function";

const BookingList = () => {
  const init = useRef(false);
  const navigate = useNavigate();
  const { session } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isPhase, setIsPhase] = useState(false);
  const [isUnitType, setIsUnitType] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [stages, setStages] = useState({ reserved: 1, booked: 1, spa_signed: 1, spa_stamped: 1, spa_sold: 1 });
  const [filter, setFilter] = useState({
    booking_status: { value: "", label: "All Status" },
    project: { label: "All Project", value: "" },
    phase: { label: "All Phase", value: "" },
    unit_type: { label: "All Unit Type", value: "" },
    unit: { label: "All Unit", value: "" },
  });

  const [statusOptions] = useState([
    { value: "", label: "All Status" },
    { value: "booked", label: "Reserved" },
    { value: "booking_confirmed", label: "Booked" },
    { value: "spa_signed", label: "SPA Signed" },
    { value: "spa_stamped", label: "SPA Stamped" },
    { value: "spa_sold", label: "Sold" },
    { value: "cancelled", label: "Cancelled" },
  ]);

  // GET FUNCTION ------------------------------------

  const getBookingList = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "GetBookingListing",
          booking_status: filter.booking_status.value,
          rea_project_id: filter.project.value,
          rea_phase_id: filter.phase.value,
          rea_type_id: filter.unit_type.value,
          rea_unit_id: filter.unit.value,
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;
      setStages(data.stages);
      if (data.status === 0) {
        setBookingData(data.record);
      } else {
        setBookingData([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectList = async () => {
    try {
      const response = await axios.get("ws/ws_rea_project.php", {
        params: {
          task: "ProjectList",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const projectOptions = data.record.map((record) => ({ label: record.project_name, value: record.rea_project_id }));
        projectOptions.unshift({ label: "All Project", value: "" });
        setProjectList(projectOptions);
        setProjectData(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION -------------------------------

  const onchangeProject = (project) => {
    if (project.value) {
      const projectInfo = projectData.find((record) => record.rea_project_id === project.value);
      const phaseOptions =
        projectInfo.phase_count > 0
          ? projectInfo.phase_record.map((record) => ({
              label: record.phase_name,
              value: record.rea_phase_id,
            }))
          : [];

      phaseOptions.unshift({
        label: "All Phase",
        value: "",
      });

      setPhaseList(phaseOptions);
      setIsPhase(false);
      setIsUnitType(false);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        project: project,
        phase: phaseOptions[0],
        unit_type: { label: "All Unit Type", value: "" },
        unit: { label: "All Unit", value: "" },
      }));

      setTimeout(() => setIsPhase(true), 1);
    } else {
      setPhaseList([]);
      setIsPhase(false);
      setIsUnitType(false);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        project: { label: "All Project", value: "" },
        phase: { label: "All Phase", value: "" },
        unit_type: { label: "All Unit Type", value: "" },
        unit: { label: "All Unit", value: "" },
      }));
    }
  };

  const onchangePhase = (phase) => {
    if (phase.value) {
      const projectInfo = projectData.find((record) => record.rea_project_id === filter.project.value);
      const unitTypeInfo = projectInfo.type_count > 0 && projectInfo.type_record.filter((record) => record.rea_phase_id === phase.value);
      const unitTypeOption =
        projectInfo.type_count > 0
          ? unitTypeInfo.map((record) => ({
              label: record.unit_title + " " + record.unit_type,
              value: record.rea_type_id,
            }))
          : [];

      unitTypeOption.unshift({
        label: "All Unit Type",
        value: "",
      });

      setUnitTypeList(unitTypeOption);
      setIsUnitType(false);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        phase: phase,
        unit_type: { label: "All Unit Type", value: "" },
        unit: { label: "All Unit", value: "" },
      }));

      setTimeout(() => setIsUnitType(true), 1);
    } else {
      setUnitTypeList([]);
      setIsUnitType(false);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        phase: { label: "All Phase", value: "" },
        unit_type: { label: "All Unit Type", value: "" },
        unit: { label: "All Unit", value: "" },
      }));
    }
  };

  const onchangeUnitType = (unit_type) => {
    if (unit_type.value) {
      const projectInfo = projectData.find((record) => record.rea_project_id === filter.project.value);
      const unitInfo = projectInfo.unit_count > 0 && projectInfo.unit_record.filter((record) => record.rea_type_id === unit_type.value && record.rea_phase_id === filter.phase.value);
      const unitOptions =
        projectInfo.type_count > 0
          ? unitInfo.map((record) => ({
              label: record.unit_number,
              value: record.rea_unit_id,
            }))
          : [];

      unitOptions.unshift({
        label: "All Unit",
        value: "",
      });

      setUnitList(unitOptions);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        unit_type: unit_type,
        unit: { label: "All Unit", value: "" },
      }));
      setTimeout(() => setIsUnit(true), 1);
    } else {
      setUnitList([]);
      setIsUnit(false);
      setFilter((prevState) => ({
        ...prevState,
        unit_type: { label: "All Unit Type", value: "" },
        unit: { label: "All Unit", value: "" },
      }));
    }
  };

  const onchangeUnit = (unit) => {
    setFilter((prevState) => ({ ...prevState, unit: unit }));
  };

  const onchangeStatus = (status) => {
    setFilter((prevState) => ({ ...prevState, booking_status: status }));
  };

  // SET FUNCTION -----------------------------------------

  const setStatus = (params) => {
    const statusMapping = {
      cancelled: { displayName: "Cancelled", color: "#6f42c1" },
      booking_confirmed: { displayName: "Booked", color: "#ffc107" },
      booked: { displayName: "Reserved", color: "#0d6efd" },
      spa_signed: { displayName: "SPA Signed", color: "#20c997" },
      spa_stamped: { displayName: "SPA Stamped", color: "#0dcaf0" },
      spa_sold: { displayName: "Sold", color: "#dc3545" },
    };

    // const status = params.value.toLowerCase();
    const status = (params.value || "").toLowerCase();
    const mappedStatus = statusMapping[status] || { displayName: status, color: "" };

    const statusStyle = {
      backgroundColor: mappedStatus.color,
      color: "white",
    };

    return (
      <div className="op-label" style={statusStyle}>
        {mappedStatus.displayName}
      </div>
    );
  };

  const setDateFormat = (params) => {
    const dateTime = params.value;
    const providedDate = moment(dateTime);

    if (dateTime === "0000-00-00 00:00:00") {
      return "-";
    } else {
      return providedDate.format("ll");
    }
  };

  const setSellingPrice = (params) => {
    if (params.data && params.data.nett_price) {
      return setCurrency(params.data.nett_price);
    } else {
      return "";
    }
  };

  const setRepeatedBuyer = (params) => {
    if (params.value) {
      if (params.data.booking_status !== "cancelled") {
        const text = setOrdinalNumber(params.value) + " time buyer";
        return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
      }
      return "";
    }
    return "";
  };

  const setOrdinalNumber = (number) => {
    if (number % 100 >= 11 && number % 100 <= 13) {
      return number + "<sup>th</sup>";
    }

    var suffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
    return number + "<sup>" + suffixes[number % 10] + "</sup>";
  };

  const setProject = (params) => {
    if (params.value) {
      return (
        <>
          <p className="m-0" style={{ lineHeight: 1.5 }}>
            <span>
              <b>Project - </b> {params.data.project_name}
            </span>
            <br />
            <span>
              <b>Phase - </b> {params.data.phase_name}
            </span>
            <br />
            <span>
              <b>Unit Type - </b> {params.data.unit_title} {params.data.unit_type}
            </span>
            <br />
            <span>
              <b>Unit Number - </b> {params.data.unit_number}
            </span>
          </p>
        </>
      );
    }
  };

  // OPEN FUNCTION ----------------------------------------

  const openBookingDetails = (params) => {
    if (params.data.booking_status !== "cancelled") {
      // window.open(`/booking-details/${params.data.rea_booking_id}/${params.data.deal_id}`, "_blank");
      navigate(`/booking-details/${params.data.rea_booking_id}/${params.data.deal_id}`);
    }
  };

  // GRID FUNCTION ----------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const repeatedBuyerComp = [411, 106];
  const gridColumn = [
    { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
    { headerName: "Buyer Name", field: "buyer_name", width: 200, cellStyle: { textTransform: "uppercase" }, onCellClicked: openBookingDetails },
    { headerName: "Repeated Buyer", field: "repeated_buyer", width: 150, cellRenderer: setRepeatedBuyer, hide: !repeatedBuyerComp.includes(Number(session.company_id)) },
    { headerName: "Unit Information", field: "project_name", width: 250, wrapText: true, autoHeight: true, cellRenderer: setProject },
    { headerName: "Owner", field: "user_name", width: 150 },
    { headerName: "SPA Price", field: "nett_price", width: 150, cellRenderer: setSellingPrice },
    { headerName: "Booking Status", field: "booking_status", width: 200, cellRenderer: setStatus },
    { headerName: "Reserved Date", field: "booking_reserved_date", width: 200, cellRenderer: setDateFormat, hide: Number(stages.reserved) === 0 },
    { headerName: "Booked Date", field: "booking_booked_date", width: 200, cellRenderer: setDateFormat, hide: Number(stages.booked) === 0 },
    { headerName: "SPA Signed Date", field: "booking_spa_signed_date", width: 200, cellRenderer: setDateFormat, hide: Number(stages.spa_signed) === 0 },
    { headerName: "SPA Stamped Date", field: "booking_spa_stamped_date", width: 200, cellRenderer: setDateFormat, hide: Number(stages.spa_stamped) === 0 },
    { headerName: "Sold Date", field: "booking_spa_sold_date", width: 200, cellRenderer: setDateFormat, hide: Number(stages.spa_sold) === 0 },
    { headerName: "Booking Cancelled Date", field: "booking_cancelled_date", width: 200, cellRenderer: setDateFormat },
  ];

  // USEEFFECT FUNCTION ------------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getBookingList();
          await getProjectList();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12}>
              <Stack direction="horizontal" gap={1}>
                {/* <Button variant="" className="op-primary-color text-light" onClick={() => navigate("/sales-chart/unit/0/0/0/0", { replace: true })}>
                  <FontAwesomeIcon icon={faMapLocationDot} className="me-2" />
                  Sales Chart
                </Button> */}

                <Form.Group className="ms-auto">
                  <FormSelect options={statusOptions} valueDefault={filter.booking_status} onChange={(e) => onchangeStatus(e)} width="200px" shadow={true} border={false} />
                </Form.Group>

                <Form.Group>
                  <FormSelect options={projectList} valueDefault={filter.project} onChange={(e) => onchangeProject(e)} width="200px" shadow={true} border={false} />
                </Form.Group>

                {isPhase && (
                  <Form.Group>
                    <FormSelect options={phaseList} valueDefault={filter.phase} onChange={(e) => onchangePhase(e)} width="200px" shadow={true} border={false} />
                  </Form.Group>
                )}

                {isUnitType && (
                  <Form.Group>
                    <FormSelect options={unitTypeList} valueDefault={filter.unit_type} onChange={(e) => onchangeUnitType(e)} width="200px" shadow={true} border={false} />
                  </Form.Group>
                )}

                {isUnit && (
                  <Form.Group>
                    <FormSelect options={unitList} valueDefault={filter.unit} onChange={(e) => onchangeUnit(e)} width="200px" shadow={true} border={false} />
                  </Form.Group>
                )}

                <Button variant="" className="op-primary-color text-light" onClick={() => getBookingList()}>
                  Apply
                </Button>
              </Stack>
            </Col>
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact columnDefs={gridColumn} rowData={bookingData} rowHeight={100} pagination={true} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default BookingList;
