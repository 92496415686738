import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Modal, Stack, Form } from "react-bootstrap";
import Loader from "../../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import Swal from "sweetalert2/dist/sweetalert2";
import { FormSelect } from "../../includes/FormCustom";

const layoutList = [
  { label: "Condo", value: "condo" },
  { label: "Landed", value: "landed" },
];

const unitSummaryList = [
  { label: "Bedroom", value: "bedroom" },
  { label: "Unit Type", value: "unit_type" },
];

const PhaseSettings = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    rea_phase_id: "",
    rea_project_id: "",
    phase_name: "",
    phase_desc: "",
    phase_location: "",
    phase_type: "",
    phase_layout: "",
    phase_facilities: "",
    rebate_title: "",
    rebate_perc: "",
    rebate_value: "",
    rebate_inactive: "",
    bumi_package: "",
    completion_date: "",
    phase_image: "",
    phase_lat: "",
    phase_lon: "",
    "3d_walkthrough": "",
    video_url: "",
    user_tag_id: "",
    unit_summary_type: "bedroom",
    site_plan_image: "",
    on_hold_interval: "1800",
    max_buyer: "5",
    salesperson_user_tag_id: "",
    on_hold_limit_per_user: 2,
  });

  // GET FUNCTION ======================================

  const getPhaseListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setPhaseList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetProjectListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.project_name,
          value: record.rea_project_id,
        }));

        setProjectList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreate = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "CreatePhase",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_project_id: values.rea_project_id,
          phase_name: values.phase_name,
          phase_desc: values.phase_desc,
          phase_location: values.phase_location,
          phase_type: values.phase_type,
          phase_layout: values.phase_layout,
          phase_facilities: values.phase_facilities,
          rebate_title: values.rebate_title,
          rebate_perc: values.rebate_perc,
          rebate_value: values.rebate_value,
          rebate_inactive: values.rebate_inactive,
          bumi_package: values.bumi_package,
          completion_date: values.completion_date,
          phase_image: values.phase_image,
          phase_lat: values.phase_lat,
          phase_lon: values.phase_lon,
          "3d_walkthrough": values["3d_walkthrough"],
          video_url: values.video_url,
          user_tag_id: values.user_tag_id,
          unit_summary_type: values.unit_summary_type,
          site_plan_image: values.site_plan_image,
          on_hold_interval: values.on_hold_interval,
          max_buyer: values.max_buyer,
          salesperson_user_tag_id: values.salesperson_user_tag_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getPhaseListing();
        setIsCreate(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created",
          timer: 2000,
        });
      } else {
        setIsCreate(false);
        Swal.fire({
          icon: "error",
          text: "Failed to create",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEdit = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdatePhase",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: values.rea_phase_id,
          rea_project_id: values.rea_project_id,
          phase_name: values.phase_name,
          phase_desc: values.phase_desc,
          phase_location: values.phase_location,
          phase_type: values.phase_type,
          phase_layout: values.phase_layout,
          phase_facilities: values.phase_facilities,
          rebate_title: values.rebate_title,
          rebate_perc: values.rebate_perc,
          rebate_value: values.rebate_value,
          rebate_inactive: values.rebate_inactive,
          bumi_package: values.bumi_package,
          completion_date: values.completion_date,
          phase_image: values.phase_image,
          phase_lat: values.phase_lat,
          phase_lon: values.phase_lon,
          "3d_walkthrough": values["3d_walkthrough"],
          video_url: values.video_url,
          user_tag_id: values.user_tag_id,
          unit_summary_type: values.unit_summary_type,
          site_plan_image: values.site_plan_image,
          on_hold_interval: values.on_hold_interval,
          max_buyer: values.max_buyer,
          salesperson_user_tag_id: values.salesperson_user_tag_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getPhaseListing();
        setIsEdit(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        setIsEdit(false);
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRemove = async (values) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemovePhase",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            rea_phase_id: values.rea_phase_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getPhaseListing();
          Swal.fire({
            icon: "success",
            text: "Successfully removed",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to remove " + data.message,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEdit = (values) => {
    setInitialValues({
      rea_phase_id: values.rea_phase_id,
      rea_project_id: values.rea_project_id,
      phase_name: values.phase_name,
      phase_desc: values.phase_desc,
      phase_location: values.phase_location,
      phase_type: values.phase_type,
      phase_layout: values.phase_layout,
      phase_facilities: values.phase_facilities,
      rebate_title: values.rebate_title,
      rebate_perc: values.rebate_perc,
      rebate_value: values.rebate_value,
      rebate_inactive: values.rebate_inactive,
      bumi_package: values.bumi_package,
      completion_date: values.completion_date,
      phase_image: values.phase_image,
      phase_lat: values.phase_lat,
      phase_lon: values.phase_lon,
      "3d_walkthrough": values["3d_walkthrough"],
      video_url: values.video_url,
      user_tag_id: values.user_tag_id,
      unit_summary_type: values.unit_summary_type,
      site_plan_image: values.site_plan_image,
      on_hold_interval: values.on_hold_interval,
      max_buyer: values.max_buyer,
      salesperson_user_tag_id: values.salesperson_user_tag_id,
      on_hold_limit_per_user: values.on_hold_limit_per_user,
    });

    setTimeout(() => {
      setIsEdit(true);
    }, 100);
  };

  // GRID SETUP ========================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      width: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Name",
      field: "phase_name",
      autoHeight: true,
    },
    {
      headerName: "Project",
      field: "project_name",
      autoHeight: true,
    },
    {
      headerName: "Description",
      field: "phase_desc",
    },
    {
      headerName: "Location",
      field: "phase_location",
    },
    {
      headerName: "Type",
      field: "phase_type",
    },
    {
      headerName: "Layout",
      field: "phase_layout",
    },
    {
      headerName: "Facilities",
      field: "phase_facilities",
    },
    {
      headerName: "Rebate Title",
      field: "rebate_title",
    },
    {
      headerName: "Rebate Percentage",
      field: "rebate_perc",
    },
    {
      headerName: "Rebate Value",
      field: "rebate_value",
    },
    {
      headerName: "Rebate Inactive",
      field: "rebate_inactive",
    },
    {
      headerName: "Bumi Packege",
      field: "bumi_package",
    },
    {
      headerName: "Ready by",
      field: "completion_date",
    },
    {
      headerName: "Image",
      field: "phase_image",
    },
    {
      headerName: "Latitude",
      field: "phase_lat",
    },
    {
      headerName: "Longitude",
      field: "phase_lon",
    },
    {
      headerName: "Virtual Tour",
      field: "3d_walkthrough",
    },
    {
      headerName: "Video",
      field: "video_url",
    },
    {
      headerName: "On Fold Limit Per User",
      field: "on_hold_limit_per_user",
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      width: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={() => sendRemove(params.data)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_phase_id.toString();
  }, []);

  // USEEFFECT FUNCTION --------------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getPhaseListing();
          await getProjectListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-3">
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create Phase
            </Button>
          </Stack>
          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact ref={gridRef} rowSelection="multiple" rowData={phaseList} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={70} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik
          onSubmit={sendCreate}
          initialValues={{
            rea_project_id: "",
            phase_name: "",
            phase_desc: "",
            phase_location: "",
            phase_type: "",
            phase_layout: "",
            phase_facilities: "",
            rebate_title: "",
            rebate_perc: "",
            rebate_value: "",
            rebate_inactive: "",
            bumi_package: "",
            completion_date: "",
            phase_image: "",
            phase_lat: "",
            phase_lon: "",
            "3d_walkthrough": "",
            video_url: "",
            user_tag_id: "",
            unit_summary_type: "bedroom",
            site_plan_image: "",
            on_hold_interval: "1800",
            max_buyer: "5",
            salesperson_user_tag_id: "",
            on_hold_limit_per_user: 2,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Phase</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Name</Form.Label>
                  <Form.Control type="text" value={values.phase_name} onChange={handleChange("phase_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Description</Form.Label>
                  <Form.Control type="text" value={values.phase_desc} onChange={handleChange("phase_desc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Location</Form.Label>
                  <Form.Control type="text" value={values.phase_location} onChange={handleChange("phase_location")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Type</Form.Label>
                  <Form.Control type="text" value={values.phase_type} onChange={handleChange("phase_type")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Layout</Form.Label>
                  <FormSelect options={layoutList} onChange={(e) => setFieldValue("phase_layout", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Facilities</Form.Label>
                  <Form.Control type="text" value={values.phase_facilities} onChange={handleChange("phase_facilities")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Title</Form.Label>
                  <Form.Control type="text" value={values.rebate_title} onChange={handleChange("rebate_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Percentage</Form.Label>
                  <Form.Control type="text" value={values.rebate_perc} onChange={handleChange("rebate_perc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Value</Form.Label>
                  <Form.Control type="text" value={values.rebate_value} onChange={handleChange("rebate_value")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Inactive</Form.Label>
                  <Form.Control type="text" value={values.rebate_inactive} onChange={handleChange("rebate_inactive")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bumi Package</Form.Label>
                  <Form.Control type="text" value={values.bumi_package} onChange={handleChange("bumi_package")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Completion Date</Form.Label>
                  <Form.Control type="date" value={values.completion_date} onChange={handleChange("completion_date")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Image</Form.Label>
                  <Form.Control type="text" value={values.phase_image} onChange={handleChange("phase_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Video</Form.Label>
                  <Form.Control type="text" value={values.video_url} onChange={handleChange("video_url")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Latitude</Form.Label>
                  <Form.Control type="text" value={values.phase_lat} onChange={handleChange("phase_lat")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Longitude</Form.Label>
                  <Form.Control type="text" value={values.phase_lon} onChange={handleChange("phase_lon")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Virtual Tour</Form.Label>
                  <Form.Control type="text" value={values["3d_walkthrough"]} onChange={handleChange("3d_walkthrough")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Notification Team Admin ID</Form.Label>
                  <Form.Control type="text" value={values.user_tag_id} onChange={handleChange("user_tag_id")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Notification Team Salesperson ID</Form.Label>
                  <Form.Control type="text" value={values.salesperson_user_tag_id} onChange={handleChange("salesperson_user_tag_id")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Summary Type</Form.Label>
                  <FormSelect options={unitSummaryList} valueDefault={unitSummaryList.find((record) => record.value === values.unit_summary_type)} onChange={(e) => setFieldValue("unit_summary_type", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Site Plan Image</Form.Label>
                  <Form.Control type="text" value={values.site_plan_image} onChange={handleChange("site_plan_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>On Hold Interval</Form.Label>
                  <Form.Control type="text" value={values.on_hold_interval} onChange={handleChange("on_hold_interval")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Max Buyer</Form.Label>
                  <Form.Control type="text" value={values.max_buyer} onChange={handleChange("max_buyer")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>On Hold Limit Per User</Form.Label>
                  <Form.Control type="text" value={values.on_hold_limit_per_user} onChange={handleChange("on_hold_limit_per_user")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect options={projectList} onChange={(e) => setFieldValue("rea_project_id", e.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEdit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit Phase</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Name</Form.Label>
                  <Form.Control type="text" value={values.phase_name} onChange={handleChange("phase_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Description</Form.Label>
                  <Form.Control type="text" value={values.phase_desc} onChange={handleChange("phase_desc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Location</Form.Label>
                  <Form.Control type="text" value={values.phase_location} onChange={handleChange("phase_location")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Type</Form.Label>
                  <Form.Control type="text" value={values.phase_type} onChange={handleChange("phase_type")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Layout</Form.Label>
                  <FormSelect options={layoutList} valueDefault={layoutList.find(record => record.value === values.phase_layout)} onChange={(e) => setFieldValue("phase_layout", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Facilities</Form.Label>
                  <Form.Control type="text" value={values.phase_facilities} onChange={handleChange("phase_facilities")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Title</Form.Label>
                  <Form.Control type="text" value={values.rebate_title} onChange={handleChange("rebate_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Percentage</Form.Label>
                  <Form.Control type="text" value={values.rebate_perc} onChange={handleChange("rebate_perc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Value</Form.Label>
                  <Form.Control type="text" value={values.rebate_value} onChange={handleChange("rebate_value")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Rebate Inactive</Form.Label>
                  <Form.Control type="text" value={values.rebate_inactive} onChange={handleChange("rebate_inactive")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bumi Package</Form.Label>
                  <Form.Control type="text" value={values.bumi_package} onChange={handleChange("bumi_package")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Completion Date</Form.Label>
                  <Form.Control type="date" value={values.completion_date} onChange={handleChange("completion_date")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Image</Form.Label>
                  <Form.Control type="text" value={values.phase_image} onChange={handleChange("phase_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Video</Form.Label>
                  <Form.Control type="text" value={values.video_url} onChange={handleChange("video_url")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Latitude</Form.Label>
                  <Form.Control type="text" value={values.phase_lat} onChange={handleChange("phase_lat")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase Longitude</Form.Label>
                  <Form.Control type="text" value={values.phase_lon} onChange={handleChange("phase_lon")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Virtual Tour</Form.Label>
                  <Form.Control type="text" value={values["3d_walkthrough"]} onChange={handleChange("3d_walkthrough")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Notification Team Admin ID</Form.Label>
                  <Form.Control type="text" value={values.user_tag_id} onChange={handleChange("user_tag_id")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Notification Team Salesperson ID</Form.Label>
                  <Form.Control type="text" value={values.salesperson_user_tag_id} onChange={handleChange("salesperson_user_tag_id")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Summary Type</Form.Label>
                  <FormSelect options={unitSummaryList} valueDefault={unitSummaryList.find((record) => record.value === values.unit_summary_type)} onChange={(e) => setFieldValue("unit_summary_type", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Site Plan Image</Form.Label>
                  <Form.Control type="text" value={values.site_plan_image} onChange={handleChange("site_plan_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>On Hold Interval</Form.Label>
                  <Form.Control type="text" value={values.on_hold_interval} onChange={handleChange("on_hold_interval")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Max Buyer</Form.Label>
                  <Form.Control type="text" value={values.max_buyer} onChange={handleChange("max_buyer")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>On Hold Limit Per User</Form.Label>
                  <Form.Control type="text" value={values.on_hold_limit_per_user} onChange={handleChange("on_hold_limit_per_user")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect options={projectList} valueDefault={projectList.find((record) => Number(record.value) === Number(values.rea_project_id))} onChange={(e) => setFieldValue("rea_project_id", e.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default PhaseSettings;
