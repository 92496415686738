import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

const SettingFBConnection = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            {isLoading ? <Loader/> : 
            <Container fluid className="my-4">
                <Form.Group>
                    <Form.Label>Facebook Leads Ad Setup</Form.Label>
                </Form.Group>
                <button className="btn op-button op-primary-color text-light">
                    <FontAwesomeIcon icon={faFacebook} size="xl" className="me-2"/>
                    Login with facebook
                </button>
            </Container>}
        </div>
    );
}

export default SettingFBConnection;