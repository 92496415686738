import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 as faGrid2Active, faHouseCircleCheck as faHouseCircleCheckActive, faHouseBuilding as faHouseBuildingActive, faBallotCheck as faBallotCheckActive, faHouseChimneyHeart as faHouseChimneyHeartActive } from "@fortawesome/pro-solid-svg-icons";
import { faBallotCheck, faGrid2, faHouseBuilding, faHouseChimneyHeart, faHouseCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { useAuth } from "../auth/AuthContext";

const Booking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useAuth();
  useEffect(() => {
    if (location.pathname === "/booking" || location.pathname === "/booking/") {
      navigate("/booking/project/0/0", { replace: true });
    }
  }, [navigate]);

  return (
    <Container fluid className="m-0 p-0 d-flex" style={{ height: "calc(100vh - 56px)" }}>
      <div className="h-100 overflow-auto op-scrollbar" style={{ width: "20%" }}>
        <Nav variant="pills" className="h-100 flex-column sidebar-tab bg-light py-4 px-3">
          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/dashboard" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faGrid2Active : faGrid2} size="xl" />
                    </span>
                    <span>Dashboard</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/list" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faHouseCircleCheckActive : faHouseCircleCheck} size="xl" className="me-2" />
                    </span>
                    <span>Booking</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/booking/project/0/0" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faHouseBuildingActive : faHouseBuilding} size="xl" className="me-2" />
                  </span>
                  <span>Project Information</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          {Number(session.booking_viewer_access) === 0 && Number(session.role_id) === 1 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/approval" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faBallotCheckActive : faBallotCheck} size="xl" className="me-2" />
                    </span>
                    <span>Approval</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          {Number(session.booking_viewer_access) === 0 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/booking/interest" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faHouseChimneyHeartActive : faHouseChimneyHeart} size="xl" className="me-2" />
                    </span>
                    <span>Interest</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}
        </Nav>
      </div>

      <div className="overflow-auto" style={{ width: "85%" }}>
        <Outlet />
      </div>
    </Container>
  );
};

export default Booking;
