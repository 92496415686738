import { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Row, Stack, Nav, Card, Image, ListGroup, Modal, Form, ButtonGroup, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import "../lib/scss/unitDetails.scss";
import { faHouseBuilding, faHouseChimneyWindow, faSackDollar, faHouse as faHouse2, faHouseFlag, faBorderBottomRight, faStreetView, faBedFront, faBath, faSquareParking } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faHouseCircleExclamation, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { setCurrency } from "../lib/js/Function";
import moment from "moment";
import { FormSelect, FormSelectCreatable } from "../includes/FormCustom";
import * as formik from "formik";
import * as yup from "yup";
import { faChevronRight, faX } from "@fortawesome/pro-light-svg-icons";
import MortgageCalculator from "./MortgageCalculator";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import { immediateToast } from "izitoast-react";
import iziToast from "izitoast";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";

const SalesChartUnitDetails = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const { prospect_id, deal_id, rea_unit_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [unitData, setUnitData] = useState({});
  const [tab, setTab] = useState("details");
  const navigate = useNavigate();
  const [unitImages, setUnitImages] = useState([]);
  const [isNewInterest, setIsNewInterest] = useState(false);
  const { Formik } = formik;
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [dealSource, setDealSource] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [interestData, setInterestData] = useState({});
  const [isInterest, setIsInterest] = useState(false);
  const [interestCount, setInterestCount] = useState(0);
  const [floorPlan, setFloorPlan] = useState([]);
  const [isCalculator, setIsCalculator] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [unitSwiper, setUnitSwiper] = useState(false);
  const [unitSwiperIndex, setUnitSwiperIndex] = useState(0);
  const [unitModalSwiperIndex, setUnitModalSwiperIndex] = useState(0);
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const [floorPlanSwiper, setFloorPlanSwiper] = useState(false);
  const [floorPlanSwiperIndex, setFloorPlanSwiperIndex] = useState(0);
  const [floorPlanModalSwiperIndex, setFloorPlanModalSwiperIndex] = useState(0);
  const [interestList, setInterestList] = useState({ priority1: [], priority2: [], priority3: [] });
  const [interestPhase, setInterestPhase] = useState({ priority1: [], priority2: [], priority3: [] });
  const [modules, setModules] = useState({});

  const newInterestSchema = yup.object().shape({
    prospect_name: yup.string().required("This field is required"),
    contact_num: yup.string().required("This field is required"),
    priority1: yup.object().test("priority1", "This field is required", function (value) {
      const { source1, priority1_disabled } = this.parent || {};
      try {
        if (!priority1_disabled && !value.value && source1.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
    priority2: yup.object().test("priority2", "This field is required", function (value) {
      const { source2, priority2_disabled } = this.parent || {};
      try {
        if (!priority2_disabled && !value.value && source2.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
    priority3: yup.object().test("priority3", "", function (value) {
      const { source3, priority3_disabled } = this.parent || {};
      try {
        if (!priority3_disabled && !value.value && source3.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
    source1: yup.object().test("source1", "", function (value) {
      const { priority1, source1_disabled } = this.parent || {};
      try {
        if (!source1_disabled && !value.value && priority1.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
    source2: yup.object().test("source2", "", function (value) {
      const { priority2, source2_disabled } = this.parent || {};
      try {
        if (!source2_disabled && !value.value && priority2.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
    source3: yup.object().test("source3", "", function (value) {
      const { priority3, source3_disabled } = this.parent || {};
      try {
        if (!source3_disabled && !value.value && priority3.value) {
          return this.createError({ message: "This field is required" });
        }
        return true;
      } catch (error) {
        console.error(error);
      }
    }),
  });

  // GET FUNCTION -----------------------------------------

  const getViewUnit = async () => {
    try {
      const response = await axios.get("ws/ws_rea_unit.php", {
        params: {
          task: "ViewUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_unit_id: rea_unit_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const sourceRecord = data.source;
        const unitRecord = data.unit;

        sourceRecord.unshift({ label: "Choose Source", value: "" });
        unitRecord.unshift({ label: "Choose Unit", value: "" });

        data.record.unit_status = Number(data.record.on_hold) === 1 ? "on_hold" : data.record.unit_status;
        setUnitData(data.record);
        setUnitImages(data.record.unit_images);
        setUnitInterest(data.interest);
        setUnitArray(unitRecord);
        setDealSource(sourceRecord);
        setModules(data.modules);
        if (data.record.unit_floor_plan.length > 0) {
          setFloorPlan(data.record.unit_floor_plan);
        } else {
          setFloorPlan(data.record.floor_plan);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProspect = async (inputValue, callback) => {
    setIsFindProspect(false);

    try {
      const response = await axios.get("/ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var prospectRecord = data.record
          .filter((record) => {
            return Number(record.prospect_id) > 0;
          })
          .map((record) => ({
            label: record.prospect_name + " (" + record.contact1 + ")",
            value: record.prospect_id,
            customLabel: "",
            prospect_name: record.prospect_name,
            contact1: record.contact1 ? record.contact1 : record.contact2 ? record.contact3 : record.contact3 ? record.contact3 : record.contact4 ? record.contact4 : record.contact5 ? record.contact5 : "",
            contact_id: record.contact_id,
            email: record.email1,
          }));

        var newProspectRecord = data.record.find((record) => Number(record.prospect_id) === 0);
        if (newProspectRecord) {
          prospectRecord.unshift({
            label: inputValue,
            value: 0,
            prospect_name: inputValue,
            customLabel: newProspectRecord.msg,
            contact1: "",
            contact_id: "",
          });
        }

        callback(prospectRecord);
        setIsFindProspect(true);
      } else {
        const prospectRecord = [{ label: inputValue, value: 0, prospect_name: inputValue, customLabel: data.error[0].msg, contact1: "", contact_id: "" }];
        callback(prospectRecord);
        setIsFindProspect(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProspectUnitInterest = async (prospect_id) => {
    try {
      if (Number(prospect_id) > 0) {
        const response = await axios.get("ws/ws_rea_unit_interest.php", {
          params: {
            task: "getProspectPhaseInterests",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            phase_id: unitData.rea_phase_id,
            prospect_id: prospect_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const priority1 = data.record.find((record) => Number(record.interested_phase_unit_priority) === 1);
          const priority2 = data.record.find((record) => Number(record.interested_phase_unit_priority) === 2);
          const priority3 = data.record.find((record) => Number(record.interested_phase_unit_priority) === 3);

          return {
            priority1: priority1 ? priority1 : "",
            priority2: priority2 ? priority2 : "",
            priority3: priority3 ? priority3 : "",
          };
        } else {
          return {
            priority1: "",
            priority2: "",
            priority3: "",
          };
        }
      } else {
        return {
          priority1: "",
          priority2: "",
          priority3: "",
        };
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProspectInterest = async (prospect_id) => {
    try {
      if (Number(prospect_id) > 0) {
        const response = await axios.get("ws/ws_rea_unit_interest.php", {
          params: {
            task: "getProspectPhaseInterests",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            phase_id: unitData.rea_phase_id,
            prospect_id: prospect_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const priority1 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 1);
          const priority2 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 2);
          const priority3 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 3);

          setInterestPhase({
            priority1: priority1 || [],
            priority2: priority2 || [],
            priority3: priority3 || [],
          });
        } else {
          setInterestPhase({ priority1: [], priority2: [], priority3: [] });
        }
      } else {
        setInterestPhase({ priority1: [], priority2: [], priority3: [] });
      }
    } catch (error) {
      setInterestPhase({ priority1: [], priority2: [], priority3: [] });
    }
  };

  const getOnHoldStatus = async () => {
    try {
      const response = await axios.get("ws/ws_rea_unit.php", {
        params: {
          task: "CheckUnitOnHold",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_unit_id: unitData.rea_unit_id,
        },
      });

      const data = await response.data;
      return data.status === 0 ? true : false;
    } catch (error) {
      console.error(error);
    }
  };

  const getOnHoldLimit = async () => {
    try {
      const response = await axios.get("ws/ws_rea_unit.php", {
        params: {
          task: "CheckLimitHoldUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = await response.data;
      return data.status === 0 ? true : false;
    } catch (error) {
      console.error(error);
    }
  };

  // OPEN FUNCTION ----------------------------------------

  const openFindUnit = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: unitData.unit_level_title,
      carpark_id: unitData.unit_car_park_title,
    };

    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/sales-chart/unit/${deal_id}/${prospect_id}?search=true`);
  };

  const openFindLevel = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: unitData.unit_level_title,
      carpark_id: unitData.unit_car_park_title,
    };

    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/sales-chart/level/${deal_id}/${prospect_id}/${unitData.rea_project_id}/${unitData.rea_phase_id}/${unitData.unit_level_title}?search=true`);
  };

  const openFindCarpark = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: unitData.unit_level_title,
      carpark_id: unitData.unit_car_park_title,
    };

    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/sales-chart/carpark/${deal_id}/${prospect_id}/${unitData.rea_project_id}/${unitData.rea_phase_id}/${unitData.unit_car_park_title}?search=true`);
  };

  const openBookingForm = async () => {
    try {
      const isOnHoldLimit = await getOnHoldLimit();
      const isUnitOnHold = await getOnHoldStatus();

      if (!isOnHoldLimit) {
        immediateToast("warning", { title: "Unfortunately", message: "You have reached a limit to hold the unit", timeout: 10000 });
      } else if (isUnitOnHold) {
        getViewUnit();
        immediateToast("warning", { title: "Unfortunately", message: "This unit is currently on hold by another person.", timeout: 10000 });
      } else {
        navigate(`/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openInterestProspect = async (info) => {
    try {
      await getProspectInterest(info.prospect_id);
      setIsInterest(true);
      setInterestData(info);
    } catch (error) {
      console.error(error);
      setIsInterest(false);
    }
  };

  // SET FUNCTION -----------------------------------------

  const setUnitInterest = async (interest) => {
    try {
      const priority1 = interest.filter((record) => Number(record.interested_phase_unit_priority) === 1);
      const priority2 = interest.filter((record) => Number(record.interested_phase_unit_priority) === 2);
      const priority3 = interest.filter((record) => Number(record.interested_phase_unit_priority) === 3);
      setInterestCount(interest.length);
      setInterestList({
        priority1: priority1,
        priority2: priority2,
        priority3: priority3,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "secondary";
    } else if (status === "on_hold") {
      return "orange";
    } else {
      return "";
    }
  };

  const setUnitStatusLabel = (status) => {
    if (status === "available") {
      return "Available";
    } else if (status === "booked") {
      return "Booked";
    } else if (status === "reserved") {
      return "Reserved";
    } else if (status === "sold") {
      return "Sold";
    } else if (status === "unavailable") {
      return "Unavailable";
    } else if (status === "on_hold") {
      return "On Hold";
    } else {
      return "";
    }
  };

  const setSwiperLabel = (index, images) => {
    const cleanLabel = (filename) => {
      let decodedFilename = decodeURIComponent(filename);
      decodedFilename = decodedFilename.replace(/\.[^/.]+$/, "");
      decodedFilename = decodedFilename.replace(/_/g, " ");
      return decodedFilename;
    };

    if (index < images.length) {
      return cleanLabel(images[index]);
    }

    return "Unnamed";
  };

  const setProjectImage = (image) => {
    if (image !== "generic.png") {
      return `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/project/${image}`;
    } else {
      return `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/generic.png`;
    }
  };

  const setFormat = (format) => {
    return Number(format) === 1;
  };

  // SEND FUNCTION ---------------------------------------

  const sendProspect = async (values) => {
    if (Number(values.prospect_id) > 0) {
      sendInterest(values);
    } else {
      let checkContact = await sendCheckProspect(values.contact_num);
      if (!checkContact) {
        return iziToast.warning({
          title: "Caution",
          message: `The contact number 1 already exist in the system, try a different contact number`,
          timeout: 2000,
        });
      }

      try {
        const response = await axios.get(`ws/ws_prospect.php`, {
          params: {
            task: "addNewProspectV2",
            name: values.prospect_name,
            organization: "",
            des: "",
            des_title: "",
            prospect_address: "",
            contact: values.contact_num,
            email: values.email,
            contact2: "",
            email2: "",
            contact3: "",
            email3: "",
            contact4: "",
            contact5: "",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (Number(data.status) === 0) {
          values.prospect_id = data.record;
          sendInterest(values);
        } else {
          iziToast.warning({
            title: "Caution",
            message: `Please try again or contact your IT Support`,
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const sendInterest = async (values) => {
    try {
      const recordInterest = [];
      if (!values.priority1_disabled && values.priority1.value) {
        recordInterest.push({
          lead_name: values.prospect_name,
          lead_contact: values.contact_num,
          lead_email: values.email,
          unit_priority: 1,
          prospect_id: values.prospect_id,
          ds_id: values.source1.value,
          unit_id: values.priority1.value,
        });
      }

      if (!values.priority2_disabled && values.priority2.value) {
        recordInterest.push({
          lead_name: values.prospect_name,
          lead_contact: values.contact_num,
          lead_email: values.email,
          unit_priority: 2,
          prospect_id: values.prospect_id,
          ds_id: values.source2.value,
          unit_id: values.priority2.value,
        });
      }

      if (!values.priority3_disabled && values.priority3.value) {
        recordInterest.push({
          lead_name: values.prospect_name,
          lead_contact: values.contact_num,
          lead_email: values.email,
          unit_priority: 3,
          prospect_id: values.prospect_id,
          ds_id: values.source3.value,
          unit_id: values.priority3.value,
        });
      }

      if (recordInterest.length === 0) {
        iziToast.warning({
          title: "Caution",
          message: "No interest was added",
          timeout: 2000,
        });
        return;
      }

      const response = await Promise.all(
        recordInterest.map(async (record) => {
          return sendNewInterest(record);
        })
      );

      if (response.includes(false)) {
        setIsNewInterest(false);
        iziToast.warning({
          title: "Caution",
          message: "There are some priorities that have not been added. Please try again or contact our IT support for assistance",
          timeout: 2000,
        });
      } else {
        setIsNewInterest(false);
        getViewUnit();
        iziToast.success({
          title: "Success",
          message: "Interest successfully added",
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendNewInterest = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_unit_interest.php", {
        params: {
          task: "setUnitInterest",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          phase_id: unitData.rea_phase_id,
          unit_id: values.unit_id,
          lead_name: values.lead_name,
          lead_contact: values.lead_contact,
          lead_email: values.lead_email,
          unit_priority: values.unit_priority,
          prospect_id: values.prospect_id,
          ds_id: values.ds_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCheckProspect = async (contact) => {
    try {
      const res = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          company: session.company_id,
          type: "Mobile",
          mobile: contact,
          people: "",
        },
      });

      let data = res.data;

      if (Number(data.status === 0)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const sendBlockUnit = async () => {
    try {
      const response = await axios.get("ws/ws_rea.php", {
        params: {
          task: "BlockUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          unit_status: unitData.unit_status,
          rea_unit_id: unitData.rea_unit_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getViewUnit();
        iziToast.success({
          title: "Success",
          message: `Successfully ${unitData.unit_status === "available" ? "Lock" : "Unlock"} the unit`,
          timeout: 2000,
        });
      } else {
        iziToast.success({
          title: "Caution",
          message: `Something wrong with your entry, please try again or contact our developer`,
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendDeleteInterest = async (record) => {
    try {
      const response = await axios.get("ws/ws_rea_unit_interest.php", {
        params: {
          task: "removeUnitInterests",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          phase_id: record.interested_phase_id,
          prospect_id: record.prospect_id,
          unit_id: record.interested_unit_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getViewUnit();
        iziToast.success({
          title: "Success",
          message: `Successfully removed the interest`,
          timeout: 2000,
        });
      } else {
        iziToast.warning({
          title: "Success",
          message: `Something wrong with your entry, please try again or contact our developer`,
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RenderImageUnavailable = () => (
    <div className="position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: 400 }}>
      <FontAwesomeIcon icon={faHouse} size="10x" style={{ opacity: 0.05 }} />
      <h6 style={{ position: "absolute" }}>Image Not Available</h6>
    </div>
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getViewUnit();
          init.current = true;
          setTimeout(() => setLoading(false), 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="unit-details">
          <div className="section-header">
            <Stack direction="horizontal" className="h-100">
              <div className="w-100 px-5">
                <div className="op-text-bigger fs-5 fw-bold">{unitData.unit_number}</div>
                <Stack direction="horizontal" gap={2} className="mt-1">
                  <Button variant={setStatusColor(unitData.unit_status)}>{setUnitStatusLabel(unitData.unit_status)}</Button>

                  {Number(session.booking_viewer_access) === 0 && (
                    <>
                      {unitData.unit_status === "reserved" || unitData.unit_status === "booked" || unitData.unit_status === "sold" ? (
                        <>
                          {(Number(session.role_id) === 1 || Number(session.user_id) === Number(unitData.booking_record.user_id)) && unitData.booking_status === 0 && (
                            <Button variant="" className="op-primary-color text-light" onClick={() => window.open(`${session.origin}/booking-details/${unitData.booking_record.rea_booking_id}/${unitData.booking_record.deal_id}`, "_blank")}>
                              Booking Details
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button variant="" className="op-primary-color text-light" disabled={unitData.unit_status !== "available" || Number(unitData.on_hold) === 1} onClick={() => openBookingForm()}>
                          Book Now
                        </Button>
                      )}
                    </>
                  )}

                  {setFormat(modules.is_bumi_reserved) && (
                    <Button variant="" className="op-primary-color text-light" onClick={() => openFindUnit()}>
                      Find Unit
                    </Button>
                  )}
                </Stack>
              </div>

              <div className="w-100 px-5">
                {setFormat(modules.is_lock_unit) && Number(session.role_id) === 1 && (unitData.unit_status === "available" || unitData.unit_status === "unavailable") && Number(unitData.on_hold) === 0 && Number(session.booking_viewer_access) === 0 && (
                  <Button variant="" className="op-primary-color text-light float-end" onClick={() => sendBlockUnit()}>
                    {unitData.unit_status === "available" ? "Lock Unit" : "Unlock Unit"}
                  </Button>
                )}

                {setFormat(modules.is_bumi_reserved) && Number(unitData.bumi_reserved) !== 2 && (
                  <Button variant="" className="float-end shadow-sm border me-2" style={{ backgroundColor: "#eee", color: "#000" }}>
                    {Number(unitData.bumi_reserved) === 1 ? "BUMI" : "NON BUMI"}
                  </Button>
                )}
              </div>
            </Stack>
          </div>

          <div className="section-body">
            <div className="section-sidebar">
              <Nav variant="pills" className="flex-column mx-3 mt-3 activity-filter" defaultActiveKey="details" onSelect={(key) => setTab(key)}>
                <Nav.Item className="mb-2">
                  <Nav.Link className="py-3" eventKey="details">
                    <FontAwesomeIcon icon={faHouse} className="me-2" size="lg" />
                    Unit Information
                  </Nav.Link>
                </Nav.Item>
                {setFormat(modules.is_unit_interest) && Number(session.booking_viewer_access) === 0 && (
                  <Nav.Item className="mb-2">
                    <Nav.Link className="py-3 d-flex align-items-center justify-content-between" eventKey="interest">
                      <div>
                        <FontAwesomeIcon icon={faHouseCircleExclamation} className="me-2" size="lg" />
                        Unit Interest
                      </div>
                      <div className={`op-label circular fw-bold ${tab !== "interest" ? "op-primary-color text-light" : "bg-light text-dark"}`}>{interestCount}</div>
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>
            <div className="section-content">
              <Container fluid className="p-4">
                {tab === "details" && (
                  <Row>
                    {(setFormat(modules.up_spa_price) || setFormat(modules.up_selling_psf) || setFormat(modules.up_net_price_after_discount) || setFormat(modules.up_max_discount) || setFormat(modules.up_mortgage_calculator) || setFormat(modules.up_bumi_selling_psf)) && (
                      <Col xxl={12} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Unit Pricing</Card.Header>
                          <Card.Body>
                            <Row>
                              {[
                                { key: "up_spa_price", label: modules.up_spa_price_label, icon: faSackDollar, value: setCurrency(unitData.unit_selling_price || 0) },
                                { key: "up_selling_psf", label: modules.up_selling_psf_label, icon: faSackDollar, value: unitData.selling_psf },
                                { key: "up_net_price_after_discount", label: modules.up_net_price_after_disc_label, icon: faSackDollar, value: unitData.net_price_after_disc },
                                { key: "up_bumi_selling_psf", label: modules.up_bumi_selling_psf_label, icon: faSackDollar, value: unitData.bumi_selling_psf },
                                { key: "up_max_discount", label: modules.up_max_discount_label, icon: faSackDollar, value: unitData.max_discount },
                              ].map(
                                ({ key, label, icon, value }) =>
                                  setFormat(modules[key]) && (
                                    <Col xxl={4} className="mb-3" key={key}>
                                      <div className="w-100">
                                        <div className="d-flex align-items-center">
                                          <div className="me-2" style={{ width: 30 }}>
                                            <FontAwesomeIcon icon={icon} size="2x" />
                                          </div>
                                          <div>
                                            <span className="fw-bold">{label}</span> <br />
                                            <span className="text-muted">{value}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )
                              )}
                            </Row>
                          </Card.Body>
                          {setFormat(modules.up_mortgage_calculator) && (
                            <Card.Footer className="py-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={setIsCalculator}>
                              <span>{modules.up_mortgage_calculator_label}</span>
                              <span>
                                <FontAwesomeIcon icon={faChevronRight} size="xl" />
                              </span>
                            </Card.Footer>
                          )}
                        </Card>
                      </Col>
                    )}

                    {(setFormat(modules.ud_project) ||
                      setFormat(modules.ud_phase) ||
                      setFormat(modules.ud_unit_type) ||
                      setFormat(modules.ud_unit_number) ||
                      setFormat(modules.ud_bedroom) ||
                      setFormat(modules.ud_bathroom) ||
                      setFormat(modules.ud_builtup_area) ||
                      setFormat(modules.ud_builtup_area_sqm) ||
                      setFormat(modules.ud_land_area) ||
                      setFormat(modules.ud_land_area_sqm) ||
                      setFormat(modules.ud_unit_level) ||
                      setFormat(modules.ud_facing) ||
                      setFormat(modules.ud_unit_car_park_no) ||
                      setFormat(modules.ud_total_carpark)) && (
                      <Col xxl={12} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Unit Details</Card.Header>
                          <Card.Body>
                            <Row>
                              <Col xxl={12}>
                                <Row>
                                  {[
                                    { key: "ud_project", label: modules.ud_project_label, icon: faHouseBuilding, value: unitData.project_name || "Unspecified" },
                                    { key: "ud_phase", label: modules.ud_phase_label, icon: faHouseChimneyWindow, value: unitData.phase_name || "Unspecified" },
                                    { key: "ud_unit_type", label: modules.ud_unit_type_label, icon: faHouseChimneyWindow, value: `${unitData.unit_title} ${unitData.unit_type}` },
                                    { key: "ud_unit_number", label: modules.ud_unit_number_label, icon: faHouse2, value: unitData.unit_number || "Unspecified" },
                                    { key: "ud_bedroom", label: modules.ud_bedroom_label, icon: faBedFront, value: unitData.unit_total_bedroom || "Unspecified" },
                                    { key: "ud_bathroom", label: modules.ud_bathroom_label, icon: faBath, value: unitData.unit_total_bathroom || "Unspecified" },
                                    { key: "ud_builtup_area", label: modules.ud_builtup_area_label, icon: faHouseFlag, value: unitData.unit_builtup_area || "Unspecified" },
                                    { key: "ud_builtup_area_sqm", label: modules.ud_builtup_area_sqm_label, icon: faHouseFlag, value: unitData.unit_builtup_area_sqm },
                                    { key: "ud_land_area", label: modules.ud_land_area_label, icon: faBorderBottomRight, value: unitData.unit_land_area || "Unspecified" },
                                    { key: "ud_land_area_sqm", label: modules.ud_land_area_sqm_label, icon: faBorderBottomRight, value: unitData.unit_land_area_sqm },
                                    { key: "ud_unit_level", label: modules.ud_unit_level_label, icon: faHouse2, value: unitData.unit_level_title || "Unspecified", condition: unitData.phase_layout === "condo" },
                                    { key: "ud_facing", label: modules.ud_facing_label, icon: faStreetView, value: unitData.unit_facing || "Unspecified" },
                                    { key: "ud_unit_car_park_no", label: modules.ud_unit_car_park_no_label, icon: faSquareParking, value: unitData.unit_car_park_no || "Unspecified" },
                                    { key: "ud_total_carpark", label: modules.ud_total_carpark_label, icon: faSquareParking, value: unitData.total_carpark || "Unspecified" },
                                  ].map(
                                    ({ key, label, icon, value, condition = true }) =>
                                      setFormat(modules[key]) &&
                                      condition && (
                                        <Col xxl={4} className="mb-3" key={key}>
                                          <div className="w-100">
                                            <div className="d-flex align-items-center">
                                              <div className="me-2" style={{ width: 30 }}>
                                                <FontAwesomeIcon icon={icon} size="2x" />
                                              </div>
                                              <div>
                                                <span className="fw-bold">{label}</span> <br />
                                                <span className="text-muted">{value}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                  )}
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_solicitor) && (
                      <Col xxl={12} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Solicitor Details</Card.Header>
                          <Card.Body>
                            {[
                              { label: "Solicitor", value: unitData.solicitor_info },
                              { label: "Solicitor Contact", value: unitData.solicitor_contact },
                              { label: "Solicitor Address", value: unitData.solicitor_address },
                              { label: "Solicitor PIC", value: unitData.solicitor_pic },
                            ].map(({ label, value }, index) => (
                              <section className="mb-3" key={index}>
                                <div className="w-100">
                                  <span className="fw-bold">{label}</span> <br />
                                  <span className="text-muted">{value || "Unspecified"}</span>
                                </div>
                              </section>
                            ))}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_remark) && (
                      <Col xxl={12} className="mb-4">
                        <Card className="border-0 shadow-sm w-100 ">
                          <Card.Header className="fw-bold">Remark</Card.Header>
                          <Card.Body>{unitData.remark || "Unspecified"}</Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_unit_gallery) && (
                      <Col xxl={6} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Unit Gallery</Card.Header>
                          <Card.Body>
                            {unitImages.length > 0 ? (
                              <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                                {unitImages.map((data, i) => (
                                  <SwiperSlide key={i}>
                                    <img
                                      className="cursor-pointer"
                                      style={{ height: 400, borderRadius: 5 }}
                                      src={setProjectImage(data)}
                                      alt="mediaimage"
                                      onClick={() => {
                                        setUnitSwiperIndex(i);
                                        setUnitSwiper(null);
                                        setIsUnit(true);
                                      }}
                                    />
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            ) : (
                              <RenderImageUnavailable />
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {setFormat(modules.is_floor_plan) && (
                      <Col xxl={6} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Floor Plan</Card.Header>
                          <Card.Body>
                            <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                              {floorPlan.map((data, i) => (
                                <SwiperSlide key={i}>
                                  <img
                                    className="cursor-pointer"
                                    style={{ height: 400, borderRadius: 5 }}
                                    src={setProjectImage(data)}
                                    alt="mediaimage"
                                    onClick={() => {
                                      setFloorPlanSwiperIndex(i);
                                      setFloorPlanSwiper(null);
                                      setIsFloorPlan(true);
                                    }}
                                  />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {unitData.phase_layout === "condo" && setFormat(modules.is_level) && (
                      <Col xxl={6} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Level Site Plan ({unitData.unit_level_title || "Unspecified"})</Card.Header>
                          <Card.Body onClick={() => openFindLevel()}>
                            <Image className="cursor-pointer" src={setProjectImage(unitData.unit_level_image)} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {unitData.phase_layout === "condo" && setFormat(modules.is_carpark) && (
                      <Col xxl={6} className="mb-4">
                        <Card className="border-0 shadow-sm w-100">
                          <Card.Header className="fw-bold">Car Park Site Plan ({unitData.unit_car_park_title || "Unspecified"})</Card.Header>
                          <Card.Body onClick={() => openFindCarpark()}>
                            <Image className="cursor-pointer" src={setProjectImage(unitData.unit_car_park_image)} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </Row>
                )}

                {tab === "interest" && (
                  <Row>
                    <Col xxl={12} className="mb-3">
                      <Button variant="" className="op-primary-color text-light" onClick={setIsNewInterest}>
                        New Interest
                      </Button>
                    </Col>

                    {interestList.priority1.length > 0 && (
                      <Col xxl={12} className="mb-5">
                        <h6 className="text-center mb-3 op-text-bigger">
                          1<sup>st</sup> Priority ({interestList.priority1.length})
                        </h6>
                        <ListGroup as="ol">
                          {interestList.priority1.map((record, index) => (
                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                              <Stack direction="horizontal" className="p-2 align-items-baseline">
                                <div>
                                  <div className="fw-bold cursor-pointer" onClick={() => openInterestProspect(record)}>
                                    {record.lead_name}
                                  </div>
                                  <span>{record.lead_contact}</span> <br />
                                  <span className="text-muted" style={{ fontSize: 10 }}>
                                    {moment(new Date(record.date_time_create)).format("ll")}
                                  </span>
                                </div>
                                <div className="ms-auto h-100">
                                  <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                  <div className="d-flex flex-row-reverse">
                                    <ButtonGroup className="mt-2">
                                      {unitData.unit_status === "available" && (
                                        <Button variant="" className="op-primary-color text-light" onClick={() => navigate(`/booking-form/${rea_unit_id}/0/${record.prospect_id}`)}>
                                          Book Now
                                        </Button>
                                      )}
                                      <Button variant="danger" onClick={() => sendDeleteInterest(record)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </Stack>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    )}

                    {interestList.priority2.length > 0 && (
                      <Col xxl={12} className="mb-5">
                        <h6 className="text-center mb-3 op-text-bigger">
                          2<sup>nd</sup> Priority ({interestList.priority2.length})
                        </h6>
                        <ListGroup as="ol">
                          {interestList.priority2.map((record, index) => (
                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                              <Stack direction="horizontal" className="p-2 align-items-baseline">
                                <div>
                                  <div className="fw-bold cursor-pointer" onClick={() => openInterestProspect(record)}>
                                    {record.lead_name}
                                  </div>
                                  <span>{record.lead_contact}</span> <br />
                                  <span className="text-muted" style={{ fontSize: 10 }}>
                                    {moment(new Date(record.date_time_create)).format("ll")}
                                  </span>
                                </div>
                                <div className="ms-auto h-100">
                                  <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                  <div className="d-flex flex-row-reverse">
                                    <ButtonGroup className="mt-2">
                                      {unitData.unit_status === "available" && (
                                        <Button variant="" className="op-primary-color text-light" onClick={() => navigate(`/booking-form/${rea_unit_id}/0/${record.prospect_id}`)}>
                                          Book Now
                                        </Button>
                                      )}
                                      <Button variant="danger" onClick={() => sendDeleteInterest(record)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </Stack>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    )}

                    {interestList.priority3.length > 0 && (
                      <Col xxl={12} className="mb-5">
                        <h6 className="text-center mb-3 op-text-bigger">
                          3<sup>rd</sup> Priority ({interestList.priority3.length})
                        </h6>
                        <ListGroup as="ol">
                          {interestList.priority3.map((record, index) => (
                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                              <Stack direction="horizontal" className="p-2 align-items-baseline">
                                <div>
                                  <div className="fw-bold cursor-pointer" onClick={() => openInterestProspect(record)}>
                                    {record.lead_name}
                                  </div>
                                  <span>{record.lead_contact}</span> <br />
                                  <span className="text-muted" style={{ fontSize: 10 }}>
                                    {moment(new Date(record.date_time_create)).format("ll")}
                                  </span>
                                </div>
                                <div className="ms-auto h-100">
                                  <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                  <div className="d-flex flex-row-reverse">
                                    <ButtonGroup className="mt-2">
                                      {unitData.unit_status === "available" && (
                                        <Button variant="" className="op-primary-color text-light" onClick={() => navigate(`/booking-form/${rea_unit_id}/0/${record.prospect_id}`)}>
                                          Book Now
                                        </Button>
                                      )}
                                      <Button variant="danger" onClick={() => sendDeleteInterest(record)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </ButtonGroup>
                                  </div>
                                </div>
                              </Stack>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </Col>
                    )}
                  </Row>
                )}
              </Container>
            </div>
          </div>
        </div>
      )}

      <Modal show={isUnit} onHide={setIsUnit} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button variant="link" className="m-0 text-light text-decoration-none" onClick={() => setIsUnit(false)}>
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ background: "#505050" }}>
          <Swiper
            initialSlide={unitSwiperIndex}
            onSlideChange={(swiper) => setUnitModalSwiperIndex(swiper.activeIndex)}
            spaceBetween={10}
            navigation={true}
            zoom={true}
            thumbs={{ swiper: unitSwiper }}
            modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
            className="mySwiper2"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              zIndex: 0,
            }}
          >
            {unitImages.map((data, i) => (
              <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                <div className="swiper-zoom-container">
                  <img className="cursor-pointer" src={setProjectImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="g-0">
            <Col md={{ span: 6, offset: 3 }}>
              <label className="my-3 text-light fw-bold">{setSwiperLabel(unitModalSwiperIndex, unitImages)}</label>
              <Swiper onSwiper={setUnitSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                {unitImages.map((data, i) => (
                  <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                    <img src={setProjectImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={isFloorPlan} onHide={setIsFloorPlan} fullscreen={true} dialogClassName="op-modal-dialog-fullscreen">
        <Modal.Header className="position-relative border-0 py-1" style={{ background: "#505050" }}>
          <div className="d-flex flex-row-reverse w-100">
            <Button variant="link" className="m-0 text-light text-decoration-none" onClick={() => setIsFloorPlan(false)}>
              <FontAwesomeIcon icon={faX} size="xl" style={{ color: "#fff" }} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ background: "#505050" }}>
          <Swiper
            initialSlide={floorPlanSwiperIndex}
            onSlideChange={(swiper) => setFloorPlanModalSwiperIndex(swiper.activeIndex)}
            spaceBetween={10}
            navigation={true}
            zoom={true}
            thumbs={{ swiper: floorPlanSwiper }}
            modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
            className="mySwiper2"
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
              zIndex: 0,
            }}
          >
            {floorPlan.map((data, i) => (
              <SwiperSlide key={i} className="d-flex justify-content-center w-100">
                <div className="swiper-zoom-container">
                  <img className="cursor-pointer" src={setProjectImage(data)} style={{ width: "auto", height: "65vh", objectFit: "cover" }} alt="mediaimage" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="g-0">
            <Col md={{ span: 6, offset: 3 }}>
              <label className="my-3 text-light fw-bold">{setSwiperLabel(floorPlanModalSwiperIndex, floorPlan)}</label>
              <Swiper onSwiper={setFloorPlanSwiper} spaceBetween={10} slidesPerView={8} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]}>
                {floorPlan.map((data, i) => (
                  <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                    <img src={setProjectImage(data)} style={{ width: "100%", height: 55, objectFit: "cover", borderRadius: 5 }} alt="mediaimage" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={isNewInterest} onHide={setIsNewInterest} className="modal-lg">
        <Formik
          validationSchema={newInterestSchema}
          onSubmit={sendProspect}
          initialValues={{
            prospect_id: "",
            prospect_name: "",
            contact_num: "",
            contact_num_type: "mobile",
            contact_num_disabled: true,
            email: "",
            email_disabled: true,
            priority1: unitArray[0],
            priority1_show: true,
            priority1_disabled: true,
            priority2: unitArray[0],
            priority2_show: true,
            priority2_disabled: true,
            priority3: unitArray[0],
            priority3_show: true,
            priority3_disabled: true,
            source1: dealSource[0],
            source1_show: true,
            source1_disabled: true,
            source2: dealSource[0],
            source2_show: true,
            source2_disabled: true,
            source3: dealSource[0],
            source3_show: true,
            source3_disabled: true,
          }}
        >
          {({ handleSubmit, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">Add Interest</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Lead Name</Form.Label>
                  <FormSelectCreatable
                    name="prospect_id"
                    loadOptions={getProspect}
                    isLoading={isFindProspect}
                    isInvalid={errors.prospect_id && touched.prospect_id}
                    placeholder=""
                    onChange={async (info) => {
                      try {
                        setFieldValue("prospect_name", info.prospect_name);
                        setFieldValue("prospect_id", info.value);
                        setFieldValue("priority1_disabled", false);
                        setFieldValue("priority2_disabled", false);
                        setFieldValue("priority3_disabled", false);
                        setFieldValue("source1_disabled", false);
                        setFieldValue("source2_disabled", false);
                        setFieldValue("source3_disabled", false);

                        if (Number(info.value) > 0) {
                          if (info.contact1) {
                            setFieldValue("contact_num", info.contact1);
                            setFieldValue("contact_num_disabled", true);
                          } else {
                            setFieldValue("contact_num", "");
                            setFieldValue("contact_num_disabled", false);
                          }

                          if (info.email) {
                            setFieldValue("email", info.email);
                            setFieldValue("email_disabled", true);
                          } else {
                            setFieldValue("email", "");
                            setFieldValue("email_disabled", false);
                          }
                        } else {
                          setFieldValue("contact_num", "");
                          setFieldValue("email", "");
                          setFieldValue("contact_num_disabled", false);
                          setFieldValue("email_disabled", false);
                        }

                        const priority = await getProspectUnitInterest(info.value);
                        if (priority.priority1) {
                          const priority1 = unitArray.find((record) => Number(record.value) === Number(priority.priority1.interested_unit_id));
                          setFieldValue("priority1", priority1 || unitArray[0]);
                          setFieldValue("priority1_show", false);
                          setFieldValue("priority1_disabled", true);

                          const source1 = dealSource.find((record) => Number(record.value) === Number(priority.priority1.ds_id));
                          setFieldValue("source1", source1 || dealSource[0]);
                          setFieldValue("source1_show", false);
                          setFieldValue("source1_disabled", true);

                          setTimeout(() => {
                            setFieldValue("priority1_show", true);
                            setFieldValue("source1_show", true);
                          }, 100);
                        } else {
                          setFieldValue("priority1", unitArray[0]);
                          setFieldValue("priority1_show", false);
                          setFieldValue("priority1_disabled", false);

                          setFieldValue("source1", dealSource[0]);
                          setFieldValue("source1_show", false);
                          setFieldValue("source1_disabled", false);

                          setTimeout(() => {
                            setFieldValue("priority1_show", true);
                            setFieldValue("source1_show", true);
                          }, 100);
                        }

                        if (priority.priority2) {
                          const priority2 = unitArray.find((record) => Number(record.value) === Number(priority.priority2.interested_unit_id));
                          setFieldValue("priority2", priority2 || unitArray[0]);
                          setFieldValue("priority2_show", false);
                          setFieldValue("priority2_disabled", true);

                          const source2 = dealSource.find((record) => Number(record.value) === Number(priority.priority2.ds_id));
                          setFieldValue("source2", source2 || dealSource[0]);
                          setFieldValue("source2_show", false);
                          setFieldValue("source2_disabled", true);

                          setTimeout(() => {
                            setFieldValue("priority2_show", true);
                            setFieldValue("source2_show", true);
                          }, 100);
                        } else {
                          setFieldValue("priority2", unitArray[0]);
                          setFieldValue("priority2_show", false);
                          setFieldValue("priority2_disabled", false);

                          setFieldValue("source2", dealSource[0]);
                          setFieldValue("source2_show", false);
                          setFieldValue("source2_disabled", false);

                          setTimeout(() => {
                            setFieldValue("priority2_show", true);
                            setFieldValue("source2_show", true);
                          }, 100);
                        }

                        if (priority.priority3) {
                          const priority3 = unitArray.find((record) => Number(record.value) === Number(priority.priority3.interested_unit_id));
                          setFieldValue("priority3", priority3 || unitArray[0]);
                          setFieldValue("priority3_show", false);
                          setFieldValue("priority3_disabled", true);

                          const source3 = dealSource.find((record) => Number(record.value) === Number(priority.priority3.ds_id));
                          setFieldValue("source3", source3 || dealSource[0]);
                          setFieldValue("source3_show", false);
                          setFieldValue("source3_disabled", true);

                          setTimeout(() => {
                            setFieldValue("priority3_show", true);
                            setFieldValue("source3_show", true);
                          }, 100);
                        } else {
                          setFieldValue("priority3", unitArray[0]);
                          setFieldValue("priority3_show", false);
                          setFieldValue("priority3_disabled", false);

                          setFieldValue("source3", dealSource[0]);
                          setFieldValue("source3_show", false);
                          setFieldValue("source3_disabled", false);

                          setTimeout(() => {
                            setFieldValue("priority3_show", true);
                            setFieldValue("source3_show", true);
                          }, 100);
                        }
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                  {errors.prospect_name && touched.prospect_name && <div className="op-error-message">{errors.prospect_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={values.contact_num}
                    disabled={values.contact_num_disabled}
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        setFieldValue("contact_num", e.target.value);
                      }
                    }}
                  />
                  {errors.contact_num && touched.contact_num && <div className="op-error-message">{errors.contact_num}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" value={values.email} disabled={values.email_disabled} onChange={(e) => setFieldValue("email", e.target.value)} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    1<sup>st</sup> Priority
                  </Form.Label>
                  {values.priority1_show && (
                    <>
                      <FormSelect
                        placeholder="Choose Unit"
                        isSearchable={true}
                        isClearable={values.priority1.value ? true : false}
                        valueDefault={values.priority1}
                        options={unitArray.filter((record) => Number(record.value) !== Number(values.priority1.value) && Number(record.value) !== Number(values.priority2.value) && Number(record.value) !== Number(values.priority3.value))}
                        onChange={(e) => setFieldValue("priority1", e)}
                        disabled={values.priority1_disabled}
                      />
                      {errors.priority1 && touched.priority1 && <div className="op-error-message">{errors.priority1}</div>}
                    </>
                  )}

                  {values.source1_show && (
                    <>
                      <FormSelect className="mt-2" isSearchable={true} isClearable={values.source1.value ? true : false} placeholder="Choose Source" valueDefault={values.source1} options={dealSource} onChange={(e) => setFieldValue("source1", e)} disabled={values.source1_disabled} />
                      {errors.source1 && touched.source1 && <div className="op-error-message">{errors.source1}</div>}
                    </>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    2<sup>nd</sup> Priority
                  </Form.Label>
                  {values.priority2_show && (
                    <>
                      <FormSelect
                        placeholder="Choose Unit"
                        isSearchable={true}
                        isClearable={values.priority2.value ? true : false}
                        valueDefault={values.priority2}
                        options={unitArray.filter((record) => Number(record.value) !== Number(values.priority1.value) && Number(record.value) !== Number(values.priority2.value) && Number(record.value) !== Number(values.priority3))}
                        onChange={(e) => setFieldValue("priority2", e)}
                        disabled={values.priority2_disabled}
                      />
                      {errors.priority2 && touched.priority2 && <div className="op-error-message">{errors.priority2}</div>}
                    </>
                  )}

                  {values.source2_show && (
                    <>
                      <FormSelect className="mt-2" isSearchable={true} isClearable={values.source2.value ? true : false} placeholder="Choose Source" valueDefault={values.source2} options={dealSource} onChange={(e) => setFieldValue("source2", e)} disabled={values.source2_disabled} />
                      {errors.source2 && touched.source2 && <div className="op-error-message">{errors.source2}</div>}
                    </>
                  )}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    3<sup>rd</sup> Priority
                  </Form.Label>
                  {values.priority3_show && (
                    <>
                      <FormSelect
                        placeholder="Choose Unit"
                        isSearchable={true}
                        isClearable={values.priority3.value ? true : false}
                        valueDefault={values.priority3}
                        options={unitArray.filter((record) => Number(record.value) !== Number(values.priority1.value) && Number(record.value) !== Number(values.priority2.value) && Number(record.value) !== Number(values.priority3))}
                        onChange={(e) => setFieldValue("priority3", e)}
                        disabled={values.priority3_disabled}
                      />
                      {errors.priority3 && touched.priority3 && <div className="op-error-message">{errors.priority3}</div>}
                    </>
                  )}

                  {values.source3_show && (
                    <>
                      <FormSelect className="mt-2" isSearchable={true} isClearable={values.source3.value ? true : false} placeholder="Choose Source" valueDefault={values.source3} options={dealSource} onChange={(e) => setFieldValue("source3", e)} disabled={values.source3_disabled} />
                      {errors.source3 && touched.source3 && <div className="op-error-message">{errors.source3}</div>}
                    </>
                  )}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Submit
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isInterest} onHide={setIsInterest} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title className="op-text-bigger">{interestData.lead_name}'s interest</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {interestPhase.priority1.length > 0 && (
              <Col xxl={12} className="mb-3">
                <h6 className="text-center mb-3 op-text-bigger">
                  1<sup>st</sup> Priority ({interestPhase.priority1.length})
                </h6>
                <ListGroup as="ol">
                  {interestPhase.priority1.map((record, index) => (
                    <ListGroup.Item as="li" className="shadow-sm" key={index}>
                      <Stack direction="horizontal" className="p-2 align-items-baseline">
                        <div>
                          <div className="fw-bold">{record.lead_name}</div>
                          <span>{record.lead_contact}</span> <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {record.phase_name} - {record.unit_number}
                          </span>
                          <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {moment(new Date(record.date_time_create)).format("ll")}
                          </span>
                        </div>
                        <div className="ms-auto h-100">
                          <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                        </div>
                      </Stack>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            )}

            {interestPhase.priority2.length > 0 && (
              <Col xxl={12} className="mb-3">
                <h6 className="text-center mb-3 op-text-bigger">
                  2<sup>nd</sup> Priority ({interestPhase.priority2.length})
                </h6>
                <ListGroup as="ol">
                  {interestPhase.priority2.map((record, index) => (
                    <ListGroup.Item as="li" className="shadow-sm" key={index}>
                      <Stack direction="horizontal" className="p-2 align-items-baseline">
                        <div>
                          <div className="fw-bold">{record.lead_name}</div>
                          <span>{record.lead_contact}</span> <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {record.phase_name} - {record.unit_number}
                          </span>
                          <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {moment(new Date(record.date_time_create)).format("ll")}
                          </span>
                        </div>
                        <div className="ms-auto h-100">
                          <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                        </div>
                      </Stack>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            )}

            {interestPhase.priority3.length > 0 && (
              <Col xxl={12} className="mb-3">
                <h6 className="text-center mb-3 op-text-bigger">
                  3<sup>rd</sup> Priority ({interestPhase.priority3.length})
                </h6>
                <ListGroup as="ol">
                  {interestPhase.priority3.map((record, index) => (
                    <ListGroup.Item as="li" className="shadow-sm" key={index}>
                      <Stack direction="horizontal" className="p-2 align-items-baseline">
                        <div>
                          <div className="fw-bold">{record.lead_name}</div>
                          <span>{record.lead_contact}</span> <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {record.phase_name} - {record.unit_number}
                          </span>{" "}
                          <br />
                          <span className="text-muted" style={{ fontSize: 10 }}>
                            {moment(new Date(record.date_time_create)).format("ll")}
                          </span>
                        </div>
                        <div className="ms-auto h-100">
                          <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                        </div>
                      </Stack>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
            )}

            {!interestPhase.priority1.length && !interestPhase.priority2.length && !interestPhase.priority3.length && <span className="text-center">This prospect does not have any interest in this phase yet</span>}
          </Row>
        </Modal.Body>
      </Modal>

      <Offcanvas show={isCalculator} onHide={setIsCalculator} placement="bottom" style={{ left: 56, height: "100vh" }}>
        <MortgageCalculator getUnitData={unitData} />
      </Offcanvas>
    </Container>
  );
};

export default SalesChartUnitDetails;
