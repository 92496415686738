import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Modal, Offcanvas, Form, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import Swal from "sweetalert2/dist/sweetalert2";
import { FormSelect } from "../../includes/FormCustom";

const UnitTypeSettings = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    rea_phase_id: "",
    rea_project_id: "",
    rea_type_id: "",
    unit_title: "",
    unit_type: "",
    total_bedroom: "",
    total_bathroom: "",
    builtup_area: "",
    land_area: "",
    reservation_fee: "",
    floor_plan: "",
  });

  // GET FUNCTION ======================================

  const getUnitTypeListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetTypeListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setUnitTypeList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPhaseListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.phase_name,
          value: record.rea_phase_id,
          project: record.rea_project_id,
        }));

        setPhaseList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetProjectListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.project_name,
          value: record.rea_project_id,
        }));

        setProjectList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreate = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "CreateType",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: values.rea_phase_id,
          rea_project_id: values.rea_project_id,
          unit_title: values.unit_title,
          unit_type: values.unit_type,
          total_bedroom: values.total_bedroom,
          total_bathroom: values.total_bathroom,
          builtup_area: values.builtup_area,
          land_area: values.land_area,
          reservation_fee: values.reservation_fee,
          floor_plan: values.floor_plan,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getUnitTypeListing();
        setIsCreate(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created",
          timer: 2000,
        });
      } else {
        setIsCreate(false);
        Swal.fire({
          icon: "error",
          text: "Failed to create",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEdit = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdateType",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: values.rea_phase_id,
          rea_project_id: values.rea_project_id,
          unit_title: values.unit_title,
          unit_type: values.unit_type,
          total_bedroom: values.total_bedroom,
          total_bathroom: values.total_bathroom,
          builtup_area: values.builtup_area,
          land_area: values.land_area,
          reservation_fee: values.reservation_fee,
          floor_plan: values.floor_plan,
          rea_type_id: values.rea_type_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getUnitTypeListing();
        setIsEdit(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        setIsEdit(false);
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRemove = async (values) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemoveType",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            rea_type_id: values.rea_type_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getUnitTypeListing();
          Swal.fire({
            icon: "success",
            text: "Successfully removed",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to remove " + data.message,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEdit = (values) => {
    setInitialValues({
      rea_phase_id: values.rea_phase_id,
      rea_project_id: values.rea_project_id,
      rea_type_id: values.rea_type_id,
      unit_title: values.unit_title,
      unit_type: values.unit_type,
      total_bedroom: values.total_bedroom,
      total_bathroom: values.total_bathroom,
      builtup_area: values.builtup_area,
      land_area: values.land_area,
      reservation_fee: values.reservation_fee,
      floor_plan: values.floor_plan,
    });

    setTimeout(() => {
      setIsEdit(true);
    }, 100);
  };

  // GRID SETUP ========================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      width: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Unit Title",
      field: "unit_title",
      autoHeight: true,
    },
    {
      headerName: "Unit Type",
      field: "unit_type",
    },
    {
      headerName: "Bedroom",
      field: "total_bedroom",
    },
    {
      headerName: "Bathroom",
      field: "total_bathroom",
    },
    {
      headerName: "Built-Up Area",
      field: "builtup_area",
    },
    {
      headerName: "Land Area",
      field: "land_area",
    },
    {
      headerName: "Reservation Fee",
      field: "reservation_fee",
    },
    {
      headerName: "Floor Plan",
      field: "floor_plan",
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      width: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={() => sendRemove(params.data)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_type_id.toString();
  }, []);

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getUnitTypeListing();
          await getPhaseListing();
          await getProjectListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-3">
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create Unit Type
            </Button>
          </Stack>
          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact ref={gridRef} rowSelection="multiple" rowData={unitTypeList} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={70} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik
          onSubmit={sendCreate}
          initialValues={{
            rea_phase_id: "",
            rea_project_id: "",
            unit_title: "",
            unit_type: "",
            total_bedroom: "",
            total_bathroom: "",
            builtup_area: "",
            land_area: "",
            reservation_fee: "",
            floor_plan: "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Unit Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Title</Form.Label>
                  <Form.Control type="text" value={values.unit_title} onChange={handleChange("unit_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Type</Form.Label>
                  <Form.Control type="text" value={values.unit_type} onChange={handleChange("unit_type")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bedroom</Form.Label>
                  <Form.Control type="number" min={0} value={values.total_bedroom} onChange={handleChange("total_bedroom")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bathroom</Form.Label>
                  <Form.Control type="number" min={0} value={values.total_bathroom} onChange={handleChange("total_bathroom")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Builtup Area</Form.Label>
                  <Form.Control type="number" min={0} value={values.builtup_area} onChange={handleChange("builtup_area")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Land Area</Form.Label>
                  <Form.Control type="number" min={0} value={values.land_area} onChange={handleChange("land_area")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Reservation Fee</Form.Label>
                  <Form.Control type="number" min={0} value={values.reservation_fee} onChange={handleChange("reservation_fee")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Floor Plan</Form.Label>
                  <Form.Control type="text" value={values.floor_plan} onChange={handleChange("floor_plan")} as="textarea" rows={3} style={{ resize: "none" }} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect options={projectList} onChange={(e) => setFieldValue("rea_project_id", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase</Form.Label>
                  <FormSelect options={values.rea_project_id ? phaseList.filter((record) => record.project === values.rea_project_id) : phaseList} onChange={(e) => setFieldValue("rea_phase_id", e.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEdit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Unit Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Title</Form.Label>
                  <Form.Control type="text" value={values.unit_title} onChange={handleChange("unit_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Type</Form.Label>
                  <Form.Control type="text" value={values.unit_type} onChange={handleChange("unit_type")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bedroom</Form.Label>
                  <Form.Control type="number" min={0} value={values.total_bedroom} onChange={handleChange("total_bedroom")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Bathroom</Form.Label>
                  <Form.Control type="number" min={0} value={values.total_bathroom} onChange={handleChange("total_bathroom")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Builtup Area</Form.Label>
                  <Form.Control type="number" min={0} value={values.builtup_area} onChange={handleChange("builtup_area")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Land Area</Form.Label>
                  <Form.Control type="number" min={0} value={values.land_area} onChange={handleChange("land_area")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Reservation Fee</Form.Label>
                  <Form.Control type="number" min={0} value={values.reservation_fee} onChange={handleChange("reservation_fee")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Floor Plan</Form.Label>
                  <Form.Control type="text" value={values.floor_plan} onChange={handleChange("floor_plan")} as="textarea" rows={3} style={{ resize: "none" }} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect options={projectList} valueDefault={projectList.find((record) => record.value === values.rea_project_id)} onChange={(e) => setFieldValue("rea_project_id", e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phase</Form.Label>
                  <FormSelect options={values.rea_project_id ? phaseList.filter((record) => record.project === values.rea_project_id) : phaseList} valueDefault={phaseList.find((record) => record.value === values.rea_phase_id)} onChange={(e) => setFieldValue("rea_phase_id", e.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default UnitTypeSettings;
