import { useEffect, useState } from "react";
import { useAuth } from "../../auth/AuthContext";
import { FormDate2, FormSelect } from "../../includes/FormCustom";
import axios from "../../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { Row, Col, Form, Modal, InputGroup } from "react-bootstrap";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";
import { MobilePrefix } from "../../lib/js/Function";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";

const EditBuyer = ({ buyer_no, bookingData, record, mainFormat, jointFormat, onDone, salutationOptions, prefixOptions, raceOptions, identityTypeOptions, occupationOptions, genderOptions, nationalityOptions, maritalStatusOptions, relationOptions, country, state, city }) => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);

  const { session } = useAuth();
  const { Formik, useFormikContext } = formik;
  const [idFrontInit, setIdFrontInit] = useState(true);
  const [idBackInit, setIdBackInit] = useState(true);

  const setFindRegionCode = (prefix) => {
    const prefixData = MobilePrefix.find((record) => Number(record.value) === Number(prefix));
    return prefixData.regionCode;
  };

  const buyerData = {
    buyer_no: buyer_no,
    buyer_id: record.rea_booking_buyer_id,
    role: record.buyer_role,
    fullname: record.buyer_name,
    salutation: record.buyer_salutation,
    identification_type: record.buyer_id_type,
    identification_id: record.buyer_id_number,
    occupation: record.buyer_occupation,
    monthly_income: record.buyer_income,
    dob: record.buyer_dob,
    gender: record.buyer_gender,
    race: record.buyer_race,
    nationality: record.buyer_nationality,
    marital_status: record.buyer_marital,
    email_address: record.buyer_email,
    mobile_number_region: setFindRegionCode(record.buyer_mobile_prefix),
    mobile_number_prefix: record.buyer_mobile_prefix,
    mobile_number: Number(record.buyer_mobile_number) !== 0 ? record.buyer_mobile_number : "",
    office_number_region: setFindRegionCode(record.buyer_house_prefix),
    office_number_prefix: record.buyer_house_prefix,
    office_number: Number(record.buyer_house_number) !== 0 ? record.buyer_house_number : "",
    address1: record.buyer_address1,
    address2: record.buyer_address2,
    address3: record.buyer_address3,
    postal_code: record.buyer_postcode,
    country: record.buyer_country,
    state: record.buyer_state,
    city: record.buyer_city,
    eme_full_name: record.emergency_name,
    eme_relation: record.emergency_relation,
    eme_mobile_number_region: setFindRegionCode(record.emergency_prefix),
    eme_mobile_number_prefix: record.emergency_prefix,
    eme_mobile_number: Number(record.emergency_number) !== 0 ? record.emergency_number : "",
    eme_email: record.emergency_email,
    idFront: record.buyer_id_front ? [{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${record.buyer_id_front}`, options: { type: "remote" } }] : [],
    idFrontName: record.buyer_id_front,
    idBack: record.buyer_id_back ? [{ source: `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/ic/${record.buyer_id_back}`, options: { type: "remote" } }] : [],
    idBackName: record.buyer_id_back,
    idFrontAllowProcess: record.buyer_id_front ? false : true,
    idBackAllowProcess: record.buyer_id_back ? false : true,
    fax_number: record.buyer_fax_number,
  };

  const buyerSchema = yup.object().shape({
    fullname: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.fullname_required) === 1 : Number(jointFormat.fullname_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    salutation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.salutation_required) === 1 : Number(jointFormat.salutation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    identification_type: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_type_required) === 1 : Number(jointFormat.identification_type_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    identification_id: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_number_required) === 1 : Number(jointFormat.identification_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    occupation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.occupation_required) === 1 : Number(jointFormat.occupation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    monthly_income: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.monthly_income_required) === 1 : Number(jointFormat.monthly_income_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    dob: yup.date().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.dob_required) === 1 : Number(jointFormat.dob_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    gender: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.gender_required) === 1 : Number(jointFormat.gender_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    race: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.race_required) === 1 : Number(jointFormat.race_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    nationality: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.nationality_required) === 1 : Number(jointFormat.nationality_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    marital_status: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.marital_status_required) === 1 : Number(jointFormat.marital_status_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    email_address: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.email_address_required) === 1 : Number(jointFormat.email_address_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    mobile_number: yup.string().test("mobile_number", "Invalid mobile number format", function (value) {
      const { mobile_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.mobile_number_required) === 1 && !value) {
          return this.createError({ message: "Mobile number is required" });
        }

        if (role === "joint" && Number(jointFormat.mobile_number_required) === 1 && !value) {
          return this.createError({ message: "Mobile number is required" });
        }

        if (value && mobile_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, mobile_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid mobile number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    office_number: yup.string().test("office_number", "Invalid mobile number format", function (value) {
      const { office_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.office_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (role === "joint" && Number(jointFormat.office_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (value && office_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, office_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid office number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    fax_number: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.fax_number_required) === 1 : Number(jointFormat.fax_number_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address1: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address1_required) === 1 : Number(jointFormat.address1_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address2: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address2_required) === 1 : Number(jointFormat.address2_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    address3: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.address3_required) === 1 : Number(jointFormat.address3_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    postal_code: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.postcode_required) === 1 : Number(jointFormat.postcode_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    country: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.country_required) === 1 : Number(jointFormat.country_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    state: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.state_required) === 1 : Number(jointFormat.state_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    city: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.city_required) === 1 : Number(jointFormat.city_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_full_name: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_fullname_required) === 1 : Number(jointFormat.emergency_contact_fullname_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_relation: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_relation_required) === 1 : Number(jointFormat.emergency_contact_relation_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    eme_mobile_number: yup.string().test("eme_mobile_number", "Invalid mobile number format", function (value) {
      const { eme_mobile_number_region, role } = this.parent || {};
      try {
        if (role === "main" && Number(mainFormat.emergency_contact_mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (role === "joint" && Number(jointFormat.emergency_contact_mobile_number_required) === 1 && !value) {
          return this.createError({ message: "The field is required" });
        }

        if (value && eme_mobile_number_region) {
          const phoneNumber = parsePhoneNumberFromString(value, eme_mobile_number_region);
          if (!phoneNumber || !phoneNumber.isValid()) {
            return this.createError({ message: "Invalid emergency contact number" });
          }
        }

        return true;
      } catch (error) {
        return false;
      }
    }),
    eme_email: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.emergency_contact_email_required) === 1 : Number(jointFormat.emergency_contact_email_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    idFrontName: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_card_front_required) === 1 : Number(jointFormat.identification_card_front_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
    idBackName: yup.string().when("role", {
      is: (role) => (role === "main" ? Number(mainFormat.identification_card_back_required) === 1 : Number(jointFormat.identification_card_back_required) === 1),
      then: () => yup.string().required("The field is required"),
    }),
  });

  const sendEditBuyer = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateBuyer",
          rea_booking_id: bookingData.rea_booking_id,
          buyer_no: values.buyer_no,
          buyer_name: values.fullname,
          buyer_salutation: values.salutation,
          buyer_id_type: values.identification_type,
          buyer_id_number: values.identification_id,
          buyer_occupation: values.occupation,
          buyer_income: values.monthly_income,
          buyer_dob: values.dob,
          buyer_gender: values.gender,
          buyer_race: values.race,
          buyer_nationality: values.nationality,
          buyer_marital: values.marital_status,
          buyer_email: values.email_address,
          buyer_mobile_prefix: values.mobile_number_prefix,
          buyer_mobile_number: values.mobile_number,
          buyer_house_prefix: values.office_number_prefix,
          buyer_house_number: values.office_number,
          buyer_address1: values.address1,
          buyer_address2: values.address2,
          buyer_address3: values.address3,
          buyer_postcode: values.postal_code,
          buyer_city: values.city,
          buyer_state: values.state,
          buyer_country: values.country,
          emergency_name: values.eme_full_name,
          emergency_relation: values.eme_relation,
          emergency_prefix: values.eme_mobile_number_prefix,
          emergency_number: values.eme_mobile_number,
          emergency_email: values.eme_email,
          buyer_fax_number: values.fax_number,
          buyer_id_front: values.idFrontName,
          buyer_id_back: values.idBackName,
          rea_booking_buyer_id: values.buyer_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          text: "Successfully updated the buyer",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          text: "Failed to update buyer, please try again or contact our developer",
        });
      }
    } catch (error) {
      onDone();
      Swal.fire({
        icon: "error",
        text: error,
      });
    }
  };

  const setFieldLabel = (label, required) => {
    return `${label} ${Number(required) === 1 ? "*" : ""}`;
  };

  const setBuyerLabelField = (role, column) => {
    if (role === "main") {
      return setFieldLabel(mainFormat[`${column}_label`], mainFormat[`${column}_required`]);
    } else {
      return setFieldLabel(jointFormat[`${column}_label`], jointFormat[`${column}_required`]);
    }
  };

  const setBuyerShowField = (role, column) => {
    if (role === "main") {
      return Number(mainFormat[`${column}_show`]) === 1;
    } else {
      return Number(jointFormat[`${column}_show`]) === 1;
    }
  };

  const ScrollToFirstError = () => {
    const { errors, isSubmitting, isValidating } = useFormikContext();

    useEffect(() => {
      if (isSubmitting && !isValidating) {
        let errorKeys = Object.keys(errors);
        if (errorKeys.length > 0) {
          const selector = `[name="${errorKeys[0]}"]`;
          const errorElement = document.querySelector(selector);
          if (errorElement) {
            errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
    }, [errors, isSubmitting, isValidating]);

    return null;
  };

  return (
    <Formik onSubmit={sendEditBuyer} validationSchema={buyerSchema} initialValues={buyerData}>
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <ScrollToFirstError />
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase" as={"h6"}>
              Edit Buyer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Row>
              {setBuyerShowField(record.buyer_role, "salutation") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "salutation")}</Form.Label>
                    <FormSelect options={salutationOptions} name={`salutation`} isSearchable={true} valueDefault={salutationOptions.find((data) => data.value === values.salutation)} onChange={(e) => handleChange("salutation")(e.value)} />
                    {errors.salutation && touched.salutation && <div className="op-error-message">{errors.salutation}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "fullname") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "fullname")}</Form.Label>
                    <Form.Control className="text-uppercase" type="text" name="fullname" value={values.fullname} onChange={(e) => handleChange("fullname")(e.target.value)} />
                    {errors.fullname && touched.fullname && <div className="op-error-message">{errors.fullname}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "identification_type") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "identification_type")}</Form.Label>
                    <FormSelect options={identityTypeOptions} name={`identification_type`} isSearchable={true} valueDefault={identityTypeOptions.find((data) => data.value === values.identification_type)} onChange={(e) => handleChange("identification_type")(e.value)} />
                    {errors.identification_type && touched.identification_type && <div className="op-error-message">{errors.identification_type}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "identification_number") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "identification_number")}</Form.Label>
                    <Form.Control className="text-uppercase" type="text" name={`identification_id`} value={values.identification_id} onChange={(e) => handleChange("identification_id")(e.target.value)} />
                    {errors.identification_id && touched.identification_id && <div className="op-error-message">{errors.identification_id}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "occupation") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "occupation")}</Form.Label>
                    <FormSelect options={occupationOptions} name={`occupation`} isSearchable={true} valueDefault={occupationOptions.find((data) => data.value === values.occupation)} onChange={(e) => handleChange("occupation")(e.value)} />
                    {errors.occupation && touched.occupation && <div className="op-error-message">{errors.occupation}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "monthly_income") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "monthly_income")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`monthly_income`}
                      value={values.monthly_income}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFieldValue("monthly_income", e.target.value);
                        }
                      }}
                    />
                    {errors.monthly_income && touched.monthly_income && <div className="op-error-message">{errors.monthly_income}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "dob") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "dob")}</Form.Label>
                    <FormDate2
                      value={values.dob ? moment(new Date(values.dob)).format("YYYY-MM-DD") : ""}
                      name={`dob`}
                      onChange={(e) => {
                        setFieldValue("dob", e);
                      }}
                    />
                    {errors.dob && touched.dob && <div className="op-error-message">{errors.dob}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "gender") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="">{setBuyerLabelField(record.buyer_role, "gender")}</Form.Label>
                    <FormSelect
                      options={genderOptions}
                      name={`gender`}
                      isSearchable={true}
                      valueDefault={genderOptions.find((data) => data.value === values.gender)}
                      onChange={(e) => {
                        setFieldValue("gender", e.value);
                      }}
                    />
                    {errors.gender && touched.gender && <div className="op-error-message">{errors.gender}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "race") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "race")}</Form.Label>
                    <FormSelect
                      options={raceOptions}
                      name={`race`}
                      isSearchable={true}
                      valueDefault={raceOptions.find((data) => data.value === values.race)}
                      onChange={(e) => {
                        setFieldValue("race", e.value);
                      }}
                    />
                    {errors.race && touched.race && <div className="op-error-message">{errors.race}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "nationality") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "nationality")}</Form.Label>
                    <FormSelect
                      options={nationalityOptions}
                      name={`nationality`}
                      isSearchable={true}
                      valueDefault={nationalityOptions.find((data) => data.value === values.nationality)}
                      onChange={(e) => {
                        setFieldValue("nationality", e.value);
                      }}
                    />
                    {errors.nationality && touched.nationality && <div className="op-error-message">{errors.nationality}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "marital_status") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "marital_status")}</Form.Label>
                    <FormSelect
                      options={maritalStatusOptions}
                      name={`marital_status`}
                      isSearchable={true}
                      valueDefault={maritalStatusOptions.find((data) => data.value === values.marital_status)}
                      onChange={(e) => {
                        setFieldValue("marital_status", e.value);
                      }}
                    />
                    {errors.marital_status && touched.marital_status && <div className="op-error-message">{errors.marital_status}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "email_address") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "email_address")}</Form.Label>
                    <Form.Control
                      type="email"
                      name={`email_address`}
                      value={values.email_address}
                      onChange={(e) => {
                        setFieldValue("email_address", e.target.value);
                      }}
                    />
                    {errors.email_address && touched.email_address && <div className="op-error-message">{errors.email_address}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "mobile_number") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "mobile_number")}</Form.Label>
                    <InputGroup>
                      <FormSelect
                        options={prefixOptions}
                        valueDefault={prefixOptions.find((data) => data.value === values.mobile_number_prefix)}
                        onChange={(e) => {
                          setFieldValue("mobile_number_prefix", e.value);
                        }}
                        width="80px"
                        borderRight={true}
                      />
                      <Form.Control
                        className="text-uppercase"
                        type="text"
                        name={`mobile_number`}
                        value={values.mobile_number}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            setFieldValue("mobile_number", e.target.value);
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.mobile_number && touched.mobile_number && <div className="op-error-message">{errors.mobile_number}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "office_number") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "office_number")}</Form.Label>
                    <InputGroup>
                      <FormSelect
                        options={prefixOptions}
                        valueDefault={prefixOptions.find((data) => data.value === values.office_number_prefix)}
                        onChange={(e) => {
                          setFieldValue("office_number_prefix", e.value);
                        }}
                        width="80px"
                        borderRight={true}
                      />
                      <Form.Control
                        className="text-uppercase"
                        type="text"
                        name={`office_number`}
                        value={values.office_number}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            setFieldValue("office_number", e.target.value);
                          }
                        }}
                      />
                      {errors.office_number && touched.office_number && <div className="op-error-message">{errors.office_number}</div>}
                    </InputGroup>
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "fax_number") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "fax_number")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`fax_number`}
                      value={values.fax_number}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFieldValue("fax_number", e.target.value);
                        }
                      }}
                    />
                    {errors.fax_number && touched.fax_number && <div className="op-error-message">{errors.fax_number}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "address1") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "address1")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`address1`}
                      value={values.address1}
                      onChange={(e) => {
                        setFieldValue("address1", e.target.value);
                      }}
                    />
                    {errors.address1 && touched.address1 && <div className="op-error-message">{errors.address1}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "address2") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "address2")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`address2`}
                      value={values.address2}
                      onChange={(e) => {
                        setFieldValue("address2", e.target.value);
                      }}
                    />
                    {errors.address2 && touched.address2 && <div className="op-error-message">{errors.address2}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "address3") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "address3")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      name={`address3`}
                      type="text"
                      value={values.address3}
                      onChange={(e) => {
                        setFieldValue("address3", e.target.value);
                      }}
                    />
                    {errors.address3 && touched.address3 && <div className="op-error-message">{errors.address3}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "postcode") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "postcode")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`postal_code`}
                      value={values.postal_code}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setFieldValue("postal_code", e.target.value);
                        }
                      }}
                    />
                    {errors.postal_code && touched.postal_code && <div className="op-error-message">{errors.postal_code}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "country") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "country")}</Form.Label>
                    <FormSelect
                      options={country}
                      name={`country`}
                      valueDefault={country.find((data) => data.value === values.country)}
                      isSearchable={true}
                      onChange={(e) => {
                        setFieldValue("country", e.value);
                        setFieldValue("state", "");
                        setFieldValue("city", "");
                      }}
                    />
                    {errors.country && touched.country && <div className="op-error-message">{errors.country}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "state") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "state")}</Form.Label>
                    <FormSelect
                      name={`state`}
                      options={state.filter((data) => data.country === values.country)}
                      valueDefault={state.find((data) => data.value === values.state)}
                      isSearchable={true}
                      onChange={(e) => {
                        setFieldValue("state", e.value);
                        setFieldValue("city", "");
                      }}
                    />
                    {errors.state && touched.state && <div className="op-error-message">{errors.state}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "city") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "city")}</Form.Label>
                    <FormSelect
                      name={`city`}
                      options={city.filter((data) => data.state === values.state)}
                      valueDefault={city.find((data) => data.value === values.city)}
                      isSearchable={true}
                      onChange={(e) => {
                        setFieldValue("city", e.value);
                      }}
                    />
                    {errors.city && touched.city && <div className="op-error-message">{errors.city}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "emergency_contact_fullname") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "emergency_contact_fullname")}</Form.Label>
                    <Form.Control
                      className="text-uppercase"
                      type="text"
                      name={`eme_full_name`}
                      value={values.eme_full_name}
                      onChange={(e) => {
                        setFieldValue("eme_full_name", e.target.value);
                      }}
                    />
                    {errors.eme_full_name && touched.eme_full_name && <div className="op-error-message">{errors.eme_full_name}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "emergency_contact_relation") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "emergency_contact_relation")}</Form.Label>
                    <FormSelect
                      options={relationOptions}
                      name={`eme_relation`}
                      isSearchable={true}
                      valueDefault={relationOptions.find((data) => data.value === values.eme_relation)}
                      onChange={(e) => {
                        setFieldValue("eme_relation", e.value);
                      }}
                    />
                    {errors.eme_relation && touched.eme_relation && <div className="op-error-message">{errors.eme_relation}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "emergency_contact_mobile_number") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label className="text-uppercase">{setBuyerLabelField(record.buyer_role, "emergency_contact_mobile_number")}</Form.Label>
                    <InputGroup>
                      <FormSelect
                        options={prefixOptions}
                        valueDefault={prefixOptions.find((data) => data.value === values.eme_mobile_number_prefix)}
                        onChange={(e) => {
                          setFieldValue("eme_mobile_number_prefix", e.target.value);
                        }}
                        width="80px"
                        borderRight={true}
                      />
                      <Form.Control
                        className="text-uppercase"
                        name={`eme_mobile_number`}
                        type="text"
                        value={values.eme_mobile_number}
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (e.target.value === "" || re.test(e.target.value)) {
                            setFieldValue("eme_mobile_number", e.target.value);
                          }
                        }}
                      />
                    </InputGroup>
                    {errors.eme_mobile_number && touched.eme_mobile_number && <div className="op-error-message">{errors.eme_mobile_number}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "emergency_contact_email") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group>
                    <Form.Label>{setBuyerLabelField(record.buyer_role, "emergency_contact_email")}</Form.Label>
                    <Form.Control
                      type="email"
                      name={`eme_email`}
                      value={values.eme_email}
                      onChange={(e) => {
                        setFieldValue("eme_email", e.target.value);
                      }}
                    />
                    {errors.eme_email && touched.eme_email && <div className="op-error-message">{errors.eme_email}</div>}
                  </Form.Group>
                </Col>
              )}
            </Row>

            <Row>
              {setBuyerShowField(record.buyer_role, "identification_card_front") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group className="w-100">
                    <Form.Label>{setBuyerLabelField(record.buyer_role, "identification_card_front")}</Form.Label>
                    {idFrontInit && (
                      <FilePond
                        allowMultiple={false}
                        allowProcess={values.idFrontAllowProcess}
                        maxFiles={1}
                        name="idFrontName"
                        files={values.idFront}
                        acceptedFileTypes={["image/*", "application/pdf"]}
                        credits={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "idFront",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          if (!values.idFrontAllowProcess) {
                            setFieldValue("idFrontAllowProcess", true);
                            setIdFrontInit(false);
                            setTimeout(() => setIdFrontInit(true), 1);
                          }

                          setFieldValue("idFront", []);
                          setFieldValue("idFrontName", "");
                        }}
                        instantUpload={false}
                        server={{
                          fetch: async (url, load, error) => {
                            try {
                              const decodedUrl = decodeURIComponent(url);
                              const response = await fetch(decodedUrl);
                              const blob = await response.blob();
                              load(blob);
                            } catch (err) {
                              error(err.message);
                            }
                          },
                          process: (fieldName, file, metadata, load) => {
                            const formData = new FormData();
                            const reader = new FileReader();

                            formData.append("folder_name", "ic");
                            formData.append("utoken", session.user_ac_token);
                            formData.append("ctoken", session.company_token);
                            formData.append("company_id", session.company_id);
                            formData.append("file_type", file.type.split("/")[1]);
                            formData.append("mime_type", file.type);
                            formData.append("old_file", record.buyer_id_front);

                            reader.onload = (e) => {
                              formData.append("data", e.target.result);
                              axios.post(`${session.hostUrl}/${session.hostUrlType}/php/update_assets_bookingv2.php`, formData).then((response) => {
                                const data = response.data;
                                if (Number(data.status) === 0) {
                                  load(data);
                                  setFieldValue("idFrontName", data.file_name);
                                }
                              });
                            };
                            reader.readAsDataURL(file);
                          },
                        }}
                      />
                    )}

                    {errors.idFrontName && touched.idFrontName && <div className="op-error-message">{errors.idFrontName}</div>}
                  </Form.Group>
                </Col>
              )}

              {setBuyerShowField(record.buyer_role, "identification_card_back") && (
                <Col xxl={6} className="mb-3">
                  <Form.Group className="w-100">
                    <Form.Label>{setBuyerLabelField(record.buyer_role, "identification_card_back")}</Form.Label>
                    {idBackInit && (
                      <FilePond
                        allowMultiple={false}
                        allowProcess={values.idBackAllowProcess}
                        maxFiles={1}
                        name="idBackName"
                        files={values.idBack}
                        acceptedFileTypes={["image/*", "application/pdf"]}
                        credits={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "idBack",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          if (!values.idBackAllowProcess) {
                            setFieldValue("idBackAllowProcess", true);
                            setIdBackInit(false);
                            setTimeout(() => setIdBackInit(true), 1);
                          }

                          setFieldValue("idBack", []);
                          setFieldValue("idBackName", "");
                        }}
                        instantUpload={false}
                        server={{
                          fetch: async (url, load, error) => {
                            try {
                              const decodedUrl = decodeURIComponent(url);
                              const response = await fetch(decodedUrl);
                              const blob = await response.blob();
                              load(blob);
                            } catch (err) {
                              error(err.message);
                            }
                          },
                          process: (fieldName, file, metadata, load) => {
                            const formData = new FormData();
                            const reader = new FileReader();

                            formData.append("folder_name", "ic");
                            formData.append("utoken", session.user_ac_token);
                            formData.append("ctoken", session.company_token);
                            formData.append("company_id", session.company_id);
                            formData.append("file_type", file.type.split("/")[1]);
                            formData.append("mime_type", file.type);
                            formData.append("old_file", record.buyer_id_back);

                            reader.onload = (e) => {
                              formData.append("data", e.target.result);
                              axios.post(`${session.hostUrl}/${session.hostUrlType}/php/update_assets_bookingv2.php`, formData).then((response) => {
                                const data = response.data;
                                if (Number(data.status) === 0) {
                                  load(data);
                                  setFieldValue("idBackName", data.file_name);
                                }
                              });
                            };
                            reader.readAsDataURL(file);
                          },
                        }}
                      />
                    )}

                    {errors.idBackName && touched.idBackName && <div className="op-error-message">{errors.idBackName}</div>}
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn op-button op-primary-color text-light shadow">
              Save
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default EditBuyer;
