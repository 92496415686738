import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
// import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const MeetupSetting = () => {

    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [userList, setUserList] = useState([]);

    const getAllUser = () => {
        axios.get('ws/ws_user.php',{
            params: {
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var list = data.record.map(data => {
                    return {label: data.user_name, value: data.user_id}
                });

                setUserList(list);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingMeetup_headername_1'),
            field: "slug",
            filter: true,
            resizable: true,
            width: 200            
        },
        {
            headerName: t('SettingMeetup_headername_2'),
            field: "hash",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingMeetup_headername_3'),
            field: "user_name",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingMeetup_headername_4'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center p-4">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.meetup_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-2" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = useCallback(() => {
        
        axios.get('ws/ws_setting.php',{
            params: {
                task: "getMeetupRecords",
                searchStr: "",
                meetup_id: "",
                user_id: "",
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setIsLoading(false);
                setRecordFound(data.totalRecords);
                setRowData(data.record);
                setIsGrid(true);
            }else{
                setIsLoading(false);
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
        });
    }, [session]);


    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        slug_title: yup.string().required(t('SettingMeetup_required_field')),
        user_id: yup.string().required(t('SettingMeetup_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_setting.php',{
            params:{
                task: "addMeetup",
                slug: values.slug_title,
                user_id: values.user_id,
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingMeetup_alert_succes_title'),
                    text: t('SettingMeetup_alert_add_text'),
                    timer: 1500
                }).then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingMeetup_alert_failed_title'),
                    text: t('SettingMeetup_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleEdit = (data) => {
        setEditInitVal(
            {   
                meetup_id: data.meetup_id,
                slug_title_edit: data.slug,
                user_id_edit: data.user_id
            }
        );

        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        slug_title_edit: yup.string().required(t('SettingMeetup_required_field')),
        user_id_edit: yup.string().required(t('SettingMeetup_required_field'))
    });

    const sendEdit = (values) => {
        axios.get('ws/ws_setting.php',{
            params:{
                task: "updateMeetup",
                meetup_id: values.meetup_id,
                slug: values.slug_title_edit,
                user_id: values.user_id_edit,
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingMeetup_alert_succes_title'),
                    text: t('SettingMeetup_alert_update_text'),
                    timer: 1500
                }).then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingMeetup_alert_failed_title'),
                    text: t('SettingMeetup_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('Setting_alert_warning_title'),
            text: t('SettingMeetup_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingMeetup_alert_warning_confirm'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        }).then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_setting.php',{
                    params:{
                        task: "deleteMeetup",
                        meetup_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingMeetup_alert_succes_title'),
                            text: t('SettingMeetup_alert_delete_text'),
                            timer: 1500
                        }).then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingMeetup_alert_failed_title'),
                            text: t('SettingMeetup_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('slug');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader />):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingMeetup_btn_add')}</button>
                            <Form.Group style={{width: "300px"}}>

                                <InputGroup >
                                    <Form.Control
                                        type="text"
                                        placeholder={t('SettingMeetup_search_placeholder')}
                                        onChange={(e) => {
                                            handleSearch(e.target.value);
                                        }}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                    </InputGroup.Text>
                                </InputGroup>

                            </Form.Group>
                            <div className="px-2 ms-auto">{recordFound === 1 ? t("SettingMeetup_record_one", {count: recordFound}):recordFound > 1 ? t("SettingMeetup_record_many", {count: recordFound}):t("SettingMeetup_record_no")}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                enableCellTextSelection={true}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {/* {!isGrid && <NoRecord message="No record at the moment." width={200}/>} */}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    slug_title: "",
                                    user_id: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingMeetup_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingMeetup_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.slug_title && touched.slug_title}
                                                onChange={handleChange('slug_title')}
                                            />
                                            {errors.slug_title && touched.slug_title && <div className="op-error-message">{errors.slug_title}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingMeetup_modalBody_add_title_2')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingMeetup_SelectUser')}
                                                options={userList}
                                                isInvalid={errors.user_id && touched.user_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('user_id')(e ? e.value:"")}}
                                            />
                                            {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingMeetup_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingMeetup_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingMeetup_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.slug_title_edit && touched.slug_title_edit}
                                                value={values.slug_title_edit}
                                                onChange={handleChange('slug_title_edit')}
                                            />
                                            {errors.slug_title_edit && touched.slug_title_edit && <div className="op-error-message">{errors.slug_title_edit}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingMeetup_modalBody_edit_title_2')}</Form.Label>
                                            <FormSelect
                                                placeholder="Select User"
                                                options={userList}
                                                valueDefault={userList.filter(option => option.value === values.user_id_edit)}
                                                isInvalid={errors.user_id_edit && touched.user_id_edit}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('user_id_edit')(e ? e.value:"")}}
                                            />
                                            {errors.user_id_edit && touched.user_id_edit && <div className="op-error-message">{errors.user_id_edit}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingMeetup_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default MeetupSetting;