import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";

const FormSelect = ({ options, value, valueDefault, onChange, placeholder = "", shadow, icon, width, border = true, isInvalid = false, isSearchable = false, className = "", isClearable= false, isMulti= false, isDisabled= false }) => {
  const selectStyles = {
    borderRadius: "0.375rem",
    control: (provided, state) => ({
      ...provided,
      minHeight: "10px",
      fontSize: "12px",
      width: width,
      borderRadius: "0.375rem",
      border: border ? (state.isFocused ? "1px solid var(--op-primary-color)" : isInvalid ? "1px solid var(--op-primary-color)" : provided.border) : "none" ,
      boxShadow: state.isFocused ? "0 0 0 1px var(--op-primary-color)" : isInvalid ? "0 0 0 .25rem var(--op-primary-hover-color)" : provided.boxShadow,
      "&:hover": {
        backgroundolor: 'var(--op-primary-hover-color)',
        border: "1px solid hsl(0, 0%, 80%)",
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      fontSize: "12px",
      marginRight: "5px",
    }),
    options: (provided) => ({
      ...provided,
      zIndex: 2,
    }),

    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} />
    </components.DropdownIndicator>
  );

  return (
    <Select
      className={className + (shadow ? " shadow-sm" : "")}
      defaultValue={valueDefault ? valueDefault : null}
      value={value}
      options={options}
      styles={selectStyles}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: "var(--op-primary-hover-color)",
          primary: "var(--op-primary-color)",
        },
      })}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default FormSelect;
