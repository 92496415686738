import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Stack, Button } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import axios from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { toJpeg } from "html-to-image";
import { faDown } from "@fortawesome/pro-solid-svg-icons";
import { io } from "socket.io-client";
import iziToast from "izitoast";

const SalesChartLevel = () => {
  const init = useRef(false);
  const { session } = useAuth();
  const { deal_id, prospect_id, rea_phase_id, level_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [unitList, setUnitList] = useState({});
  const navigate = useNavigate();
  const imgRef = useRef(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search).get("search");
  const [searchData, setSearchData] = useState({});
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [download, setDownload] = useState(false);
  const salesChartRef2 = useRef();
  const [salesChartImage, seSalesChartImage] = useState("");
  const [unitRecord, setUnitRecord] = useState([]);

  // GET FUNCTION ------------------------------

  const getUnitListing = async () => {
    try {
      const response = await axios.get("ws/ws_rea_unit.php", {
        params: {
          task: "GetUnitListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: rea_phase_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        if (searchParams) {
          const decodeSearch = JSON.parse(sessionStorage.getItem("findUnit"));
          setSearchData(decodeSearch);
        }

        setUnitRecord(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChart = async () => {
    try {
      const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: session.company_id,
          filename: "booking_map",
        },
      });

      const data = response.data;
      if (data.status === 0 && !Array.isArray(data.record)) {
        const clientMap = data.record[rea_phase_id];
        if (clientMap) {
          setTimeout(() => setUnitLot(clientMap), 1000);
        } else {
          setIsSalesChart(false);
        }
      } else {
        setIsSalesChart(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChartImage = async (record) => {
    try {
      const url = record.split(".");
      const urlType = url[url.length - 1];

      const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/php/api_get_file_content.php`, {
        params: {
          filepath: `../assets/rea_booking/${session.company_id}/project/${record}`,
        },
      });

      const data = response.data;
      if (data && data.content) {
        const base64Mode = `data:image/${urlType};base64,`;
        seSalesChartImage(base64Mode + data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUnitOwner = async (rea_unit_id) => {
    try {
      const response = await axios.get("ws/ws_rea_unit.php", {
        params: {
          task: "CheckUnitOwner",
          rea_unit_id: rea_unit_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION -----------------------------------------

  const setUnitLot = async (clientMap) => {
    if (clientMap) {
      const recordLevel = clientMap.level.find((record) => record.level_title === level_id);
      setUnitList(recordLevel);
      await getSalesChartImage(recordLevel.sales_chart);
      setTimeout(() => setIsSalesChart(true), 500);
    } else {
      setIsSalesChart(false);
    }
  };

  const setUnitColor2 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 1)";
    } else if (status === "booked") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(255, 193, 7, 0.5)" : "rgba(220, 53, 69, 1)";
    } else if (status === "reserved") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(13, 110, 253, 0.5)" : "rgba(220, 53, 69, 1)";
    } else if (status === "sold") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(220, 53, 69, 0.5)" : "rgba(220, 53, 69, 1)";
    } else if (status === "unavailable") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(153, 153, 153, 0.5)" : "rgba(220, 53, 69, 1)";
    } else if (status === "on_hold") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(253, 126, 20, 0.5)" : "rgba(220, 53, 69, 1)";
    } else {
      return "";
    }
  };

  const setUnitColor3 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 0.5)";
    } else if (status === "booked") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(255, 193, 7, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "reserved") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(13, 110, 253, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "sold") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(220, 53, 69, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "unavailable") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(153, 153, 153, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "on_hold") {
      return Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 ? "rgba(253, 126, 20, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else {
      return "";
    }
  };

  const setFindScaleRect = (coords, image) => {
    const [x1, y1, x2, y2] = coords.map(Number);
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    return [x1 * scaleX, y1 * scaleY, x2 * scaleX, y2 * scaleY];
  };

  const setFindScalePoly = (coords, image) => {
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    const formattedCoords = [];
    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i] * scaleX;
      const y = coords[i + 1] * scaleY;
      formattedCoords.push(`${x}, ${y}`);
    }
    return formattedCoords.join(" ");
  };

  // OPEN FUNCTION ----------------------------------------

  const openUnitDetails = async (unit) => {
    const navigateToUnitDetails = () => {
      navigate(`/sales-chart/unit-details/${deal_id}/${prospect_id}/${unit.rea_unit_id}`);
    };

    const showWarning = (message) => {
      iziToast.warning({
        title: "Caution",
        message,
        timeout: 2000,
      });
    };

    if (unit.unit_status === "available") {
      navigateToUnitDetails();
    } else if (Number(session.booking_viewer_access) === 1) {
      showWarning("You are restricted to view this unit");
    } else if (Number(session.booking_sales_chart_restricted) === 1) {
      const checkOwner = await getUnitOwner(unit.rea_unit_id);
      if (checkOwner) {
        navigateToUnitDetails();
      } else {
        showWarning("This unit is not registered under you, you cannot open this unit");
      }
    } else {
      navigateToUnitDetails();
    }
  };

  // SEND FUNCTION ----------------------------------------

  const sendDownload = () => {
    setDownload(true);
    setTimeout(() => {
      toJpeg(salesChartRef2.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `saleschart.jpg`;
          link.href = dataUrl;
          link.click();
          setDownload(false);
        })
        .catch((err) => {
          setDownload(false);
        });
    }, 1);
  };

  // RENDER FUNCTION --------------------------------------

  const RenderControl = ({ zoomIn, zoomOut }) => (
    <Stack direction="vertical" gap={2} style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
      <Button variant="light" className="shadow-sm" onClick={() => zoomIn()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => zoomOut()}>
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => sendDownload()}>
        <FontAwesomeIcon icon={faDown} />
      </Button>
    </Stack>
  );

  const RenderClientUnitRect = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;

      const coords = record.coords.split(",");
      const scaledCoords = setFindScaleRect(coords, image.current);
      const unitData = unitRecord.find((data) => data.rea_unit_id === record.rea_unit_id);

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <rect key={record.rea_unit_id} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill={setUnitColor3(unitData.unit_status)} onClick={() => openUnitDetails(unitData)} />

          {Number(record.rea_unit_id) === Number(searchData.rea_unit_id) && (
            <circle cx={scaledCoords[0]} cy={(scaledCoords[1] + scaledCoords[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={scaledCoords[0]} cy={scaledCoords[1]} r="5" fill="#000" />
              <text x={scaledCoords[0]} y={scaledCoords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 5 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord, unitList] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const circleScaled = setFindScaleRect(coords, image.current);
      const unitData = unitRecord.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));
      console.log(unitData);
      if (!unitData) return null;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <polygon key={record.rea_unit_id} points={scaledCoords} fill={setUnitColor3(unitData.unit_status)} onClick={() => openUnitDetails(unitData)} />
          {Number(record.rea_unit_id) === Number(searchData.rea_unit_id) && (
            <circle cx={(circleScaled[0] + circleScaled[2]) / 2} cy={(circleScaled[1] + circleScaled[3]) / 2} r="3" fill="#87E400">
              <animate attributeName="r" values="3;5;3" dur="1s" repeatCount="indefinite" />
            </circle>
          )}

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={circleScaled[0]} cy={circleScaled[1]} r="5" fill="#000" />
              <text x={circleScaled[0]} y={circleScaled[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 5 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord, unitList] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitRect2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;

      const coords = record.coords.split(",");
      const unitData = unitRecord.find((data) => data.rea_unit_id === record.rea_unit_id);

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;
      return (
        <>
          <rect key={record.rea_unit_id} width={coords[2] - coords[0]} height={coords[3] - coords[1]} y={coords[1]} x={coords[0]} fill={setUnitColor3(unitData.unit_status)} />

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [unitRecord, download, unitList] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const unitData = unitRecord.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData) return null;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <polygon key={record.rea_unit_id} points={coords} fill={setUnitColor3(unitData.unit_status)} />
          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [unitRecord, download, unitList] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // USEEFFECT FUNCTION ------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getUnitListing();
          await getSalesChart();
          init.current = true;
          setTimeout(() => setLoading(false), 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=saleschart_" + rea_phase_id,
      });

      socket.on("chat message", function () {
        getUnitListing();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [init.current]);

  return (
    <Container className="m-0 p-0" fluid style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-3">
            <h5 className="fw-bold">Site Plan {unitList?.level_title}</h5>
            <Stack direction="horizontal" gap={3} className="ms-auto">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("available") }} className="me-1" /> Available
              </div>

              {Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 && (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("reserved") }} className="me-1" /> Reserved
                </div>
              )}

              {Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 && (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("booked") }} className="me-1" /> Booked
                </div>
              )}

              {Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 && (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("sold") }} className="me-1" /> Sold
                </div>
              )}

              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("unavailable") }} className="me-1" /> Unavailable
              </div>

              {Number(session.booking_sales_chart_restricted) === 0 && Number(session.booking_viewer_access) === 0 && (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("on_hold") }} className="me-1" /> On Hold
                </div>
              )}
            </Stack>
          </Stack>

          <TransformWrapper initialScale={1} initialPositionX={200} wheel={{ disabled: true }}>
            {(utils) => (
              <Container fluid className="position-relative m-0 p-0">
                <RenderControl {...utils} />
                <TransformComponent wrapperStyle={{ width: "100%", height: "75vh", backgroundColor: "#999" }}>
                  <div className="position-relative d-inline-block" style={{ width: 1000 }}>
                    {salesChartImage && (
                      <img
                        ref={imgRef}
                        src={salesChartImage}
                        width={"100%"}
                        onLoad={() => {
                          const { width, height } = imgRef.current;
                          setSvgDimensions({ width, height });
                        }}
                        alt="saleschart"
                      />
                    )}
                    {isSalesChart ? (
                      <svg width={svgDimensions.width} height={svgDimensions.height} style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                        {unitList.unit.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly key={index} record={record} image={imgRef} /> : null))}
                      </svg>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </TransformComponent>
              </Container>
            )}
          </TransformWrapper>
          {download && (
            <div style={{ overflow: "auto", height: 0, width: 0 }}>
              <div ref={salesChartRef2} className="position-relative d-inline-block" style={{ width: imgRef.current ? imgRef.current.naturalWidth : 1000 }}>
                <img src={salesChartImage} width={"100%"} alt="saleschart" />
                <svg width={imgRef.current ? imgRef.current.naturalWidth : 0} height={imgRef.current ? imgRef.current.naturalHeight : 0} style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}>
                  {unitList.unit.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect2 key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly2 key={index} record={record} image={imgRef} /> : null))}
                </svg>
              </div>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default SalesChartLevel;
