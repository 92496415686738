import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Card, Offcanvas } from "react-bootstrap";
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Label, Legend, ResponsiveContainer } from "recharts";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SurveyDashboard = () => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const init = useRef(false);
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [canvasLoad, setCanvasLoad] = useState(false);
    const [surveyData, setSurveyData] = useState([]);
    const formattedThreeMonthAgoDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const formattedTodayDate = moment().format('YYYY-MM-DD');
    const [dateRange, setdateRange] = useState({
        ds: formattedThreeMonthAgoDate,
        de: formattedTodayDate,
    });
    const [OffcanvasData, setOffcanvasData] = useState([]);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [customFieldInfoSurvey, setCustomFieldInfoSurvey] = useState([]);
    const [customFieldInfoSurveyStat, setCustomFieldInfoSurveyStat] = useState([]);
    const [selectedParams, setSelectedParams] = useState(null);
    const [colors, setColors] = useState([]);


    const getSurveyList = async () => {
        try {
            const response = await axios.get("ws/ws_setting.php", {
                params: {
                    ctoken: session.company_token,
                    task: "getAllFormsV2",
                    mode: "Survey",
                    utoken: session.user_ac_token,
                },
            });

            const data = response.data;

            if (data.status === 0) {
                setSurveyData(data.record);
            } else {
                setSurveyData([]);
            }
        } catch (error) {
        }
    };


    const TitleCellRenderer = async (params) => {
        const task = session.hostUrlType === "cloud_staging" ? 'submittedListV2' : 'submittedListV3';
        setCanvasLoad(true);

        try {
            const response = await axios.get("ws/ws_survey_prod.php", {
                params: {
                    ctoken: session.company_token,
                    de: dateRange.de,
                    ds: dateRange.ds,
                    reg_form_id: params.data.reg_form_id,
                    task: task,
                    utoken: session.user_ac_token,
                }
            })

            const data = response.data;

            if (data.status === 0) {
                setOffcanvasData(data.record);
                setCustomFieldInfoSurvey(data.cf_info);
                setCustomFieldInfoSurveyStat(data.stat);

                // Generate colors and store in state
                const generatedColors = data.stat.stat_record.map(() => generateRandomColor());
                setColors(generatedColors);

                setShowOffcanvas(true);
            } else {
                setOffcanvasData([]);
                setCustomFieldInfoSurvey([]);
                setCustomFieldInfoSurveyStat([]);
                setShowOffcanvas(true);
            }
        } catch {
            console.error("Some error happened while fetching data!");
        } finally {
            setCanvasLoad(false);
        }
    };


    const handleCellClick = (params) => {
        setSelectedParams(params);
        TitleCellRenderer(params, dateRange);
    };

    // GRID FUNCTION ----------------------------------------

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: t("SurveyDashboard_No"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("SurveyDashboard_title"),
            field: "form_title",
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: handleCellClick
        },
        { headerName: t("SurveyDashboard_view_count"), field: "view_count", width: 200 },
        { headerName: t("SurveyDashboard_submission"), field: "form_submission", width: 250 },
    ];


    // Offcanvas grid

    const createDynamicColumnsSurvey = (cfInfo) => {
        if (!cfInfo || !Array.isArray(cfInfo.cf_record)) {
            return [];
        }

        return cfInfo.cf_record.map(cf => ({
            headerName: cf.cf_label,
            field: cf.cf_id,
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
            valueGetter: (params) => {
                const record = params.data.cf_val.cf_record ? params.data.cf_val.cf_record.find(item => item.cf_id === cf.cf_id) : [];
                return record ? record.fd_value : '';
            }
        }));
    };

    const gridColumnSurvey = [
        { headerName: t("SurveyDashboard_No"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("SurveyDashboard_date_submitted"),
            field: "survey_submission_date_time",
            width: 250,
        },
        {
            headerName: t("SurveyDashboard_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("SurveyDashboard_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("SurveyDashboard_contact"),
            field: "contact1",
            width: 250,
        },
        ...(customFieldInfoSurvey ? createDynamicColumnsSurvey(customFieldInfoSurvey) : []),
    ]

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    // Download excel
    const handleDownloadLead = () => {
        var params = {
            fileName: "survey-submitted.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    // DYNAMIC PIECHART

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const renderPieCharts = () => {
        if (!customFieldInfoSurveyStat || !customFieldInfoSurveyStat.stat_record) {
            return null; // or handle loading state or error state
        }

        return (
            <div className="d-flex justify-content-center flex-wrap">
                {customFieldInfoSurveyStat.stat_record.map((item, index) => {
                    const entries = Object.values(item).filter(entry => typeof entry === 'object');

                    // Map through entries to create pie chart data
                    const pieChartData = entries.map(entry => ({
                        name: entry.fd_value,
                        value: parseInt(entry.count)
                    }));

                    return (
                        <div key={index} className="mb-4" style={{ width: '45%', margin: '0 auto' }}>
                            <Card className="border-0 shadow-sm">
                                <Card.Header>
                                    <div className="font h5 m-3" style={{ width: '100%' }}>{item.stat_label}</div>
                                </Card.Header>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Legend verticalAlign="top" />
                                        <Pie
                                            data={pieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"  // Adjust positioning as needed
                                            cy="50%"
                                            outerRadius={80}  // Adjust radius as needed
                                            innerRadius={30}
                                            label
                                        >
                                            {pieChartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <RechartsTooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Card>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleFilterButtonClick = () => {
        if (selectedParams) {
            TitleCellRenderer(selectedParams, dateRange);
        }
    };

    // USEEFFECT ------------------------------------------------------

    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getSurveyList();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session]);


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <Row>
                        <h3>
                            {t("SurveyDashboard_survey_dashboard")}
                        </h3>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={surveyData} defaultColDef={{ flex: 1 }} rowHeight={70} pagination={true} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Offcanvas
                        show={showOffcanvas}
                        onHide={() => setShowOffcanvas(false)}
                        placement="bottom"
                        style={{
                            left: 57,
                            height: '100vh',
                        }}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>{t("SurveyDashboard_survey_submitted")}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Form.Group className="d-flex justify-content-between mb-3">
                                <Form.Group className="mx-3 d-flex align-items-center">
                                    <Form.Group className="me-3">
                                        <Form.Control
                                            type="date"
                                            placeholder={t("SurveyDashboard_from")}
                                            name="ds"
                                            className="shadow-sm"
                                            style={{
                                                width: '200px'
                                            }}
                                            value={moment(dateRange.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                            onChange={(e) => {
                                                onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="me-3">
                                        <Form.Control
                                            type="date"
                                            placeholder={t("SurveyDashboard_to")}
                                            name="de"
                                            className="shadow-sm"
                                            style={{
                                                width: '200px'
                                            }}
                                            value={moment(dateRange.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                            onChange={(e) => {
                                                onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                            }}
                                        />
                                    </Form.Group>
                                    <Button variant="primary" onClick={handleFilterButtonClick}>
                                        {t("SurveyDashboard_filter")}
                                    </Button>
                                </Form.Group>
                                <div className="d-flex justify-content-end">
                                    <Button variant="secondary" onClick={handleDownloadLead}>
                                        {t("SurveyDashboard_download")}
                                    </Button>
                                </div>
                            </Form.Group>

                            {canvasLoad ? (
                                <Loader />
                            ) : (
                                <>
                                    <div>
                                        {renderPieCharts()}
                                    </div>

                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact ref={gridRef} columnDefs={gridColumnSurvey} rowData={OffcanvasData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
            )}
        </Container>
    );
};

export default SurveyDashboard;