import {
  MobilePrefix,
  financingList as financing,
  firstTimeBuyerList as first_time_buyer,
  bumiputeraList as bumiputera,
  paymentMethodList as payment_method,
  salutationList as salutation,
  identityTypeList as identity_type,
  occupationList as occupation,
  genderList as gender,
  raceList as race,
  nationalityList as nationality,
  maritalStatusList as marital_status,
  relationList as relation,
} from "../../lib/js/Function";

export const bookingDefaultFormat = {
  booking_fee_label: "Booking Fee",
  booking_fee_required: "1",
  booking_fee_show: "1",
  bumi_discount_label: "Bumi Discount",
  bumi_discount_show: "1",
  bumiputera_label: "Bumiputera",
  bumiputera_required: "1",
  bumiputera_show: "1",
  financing_label: "Financing",
  financing_required: "1",
  financing_show: "1",
  first_time_buyer_label: "First Time Buyer",
  first_time_buyer_required: "1",
  first_time_buyer_show: "1",
  net_price_label: "SPA Price",
  net_price_show: "1",
  payment_method_label: "Payment Method",
  payment_method_required: "1",
  payment_method_show: "1",
  payment_receipt_label: "Payment Receipt",
  payment_receipt_required: "1",
  payment_receipt_show: "1",
  payment_reference_label: "Payment Reference",
  payment_reference_required: "1",
  payment_reference_show: "1",
  phase_label: "Phase",
  phase_show: "1",
  project_label: "Project",
  project_show: "1",
  rebate_package_label: "Rebate Package",
  rebate_package_show: "1",
  selling_price_label: "Selling Price",
  selling_price_show: "1",
  source_label: "Source",
  source_required: "1",
  source_show: "1",
  unit_label: "Unit",
  unit_show: "1",
  unit_type_label: "Unit Type",
  unit_type_show: "1",
};

export const mainBuyerDefaultFormat = {
  address1_label: "Address 1",
  address1_required: "1",
  address1_show: "1",
  address2_label: "Address 2",
  address2_required: "1",
  address2_show: "1",
  address3_label: "Address 3",
  address3_required: "1",
  address3_show: "1",
  city_label: "City",
  city_required: "1",
  city_show: "1",
  country_label: "Country",
  country_required: "1",
  country_show: "1",
  dob_label: "Date of Birth",
  dob_required: "1",
  dob_show: "1",
  email_address_label: "Email Address",
  email_address_required: "1",
  email_address_show: "1",
  emergency_contact_email_label: "Emergency Contact Email",
  emergency_contact_email_required: "1",
  emergency_contact_email_show: "1",
  emergency_contact_fullname_label: "Emergency Contact Full Name",
  emergency_contact_fullname_required: "1",
  emergency_contact_fullname_show: "1",
  emergency_contact_mobile_number_label: "Emergency Contact Mobile Number",
  emergency_contact_mobile_number_required: "1",
  emergency_contact_mobile_number_show: "1",
  emergency_contact_relation_label: "Emergency Contact Relation",
  emergency_contact_relation_required: "1",
  emergency_contact_relation_show: "1",
  fax_number_label: "Fax Number",
  fax_number_required: "1",
  fax_number_show: "1",
  fullname_label: "Full Name",
  fullname_required: "1",
  fullname_show: "1",
  gender_label: "Gender",
  gender_required: "1",
  gender_show: "1",
  identification_card_back_label: "Identification Card (Back)",
  identification_card_back_required: "1",
  identification_card_back_show: "1",
  identification_card_front_label: "Identification Card (Front)",
  identification_card_front_required: "1",
  identification_card_front_show: "1",
  identification_number_label: "Identification Number",
  identification_number_required: "1",
  identification_number_show: "1",
  identification_type_label: "Identification Type",
  identification_type_required: "1",
  identification_type_show: "1",
  marital_status_label: "Marital Status",
  marital_status_required: "1",
  marital_status_show: "1",
  mobile_number_label: "Mobile Number",
  mobile_number_required: "1",
  mobile_number_show: "1",
  monthly_income_label: "Monthly Income",
  monthly_income_required: "1",
  monthly_income_show: "1",
  nationality_label: "Nationality",
  nationality_required: "1",
  nationality_show: "1",
  occupation_label: "Occupation",
  occupation_required: "1",
  occupation_show: "1",
  office_number_label: "Office Number",
  office_number_required: "1",
  office_number_show: "1",
  postcode_label: "Postcode",
  postcode_required: "1",
  postcode_show: "1",
  race_label: "Race",
  race_required: "1",
  race_show: "1",
  salutation_label: "Salutation",
  salutation_required: "1",
  salutation_show: "1",
  state_label: "State",
  state_required: "1",
  state_show: "1",
};

export const jointBuyerDefaultFormat = {
  address1_label: "Address 1",
  address1_required: "1",
  address1_show: "1",
  address2_label: "Address 2",
  address2_required: "1",
  address2_show: "1",
  address3_label: "Address 3",
  address3_required: "1",
  address3_show: "1",
  city_label: "City",
  city_required: "1",
  city_show: "1",
  country_label: "Country",
  country_required: "1",
  country_show: "1",
  dob_label: "Date of Birth",
  dob_required: "1",
  dob_show: "1",
  email_address_label: "Email Address",
  email_address_required: "1",
  email_address_show: "1",
  emergency_contact_email_label: "Emergency Contact Email",
  emergency_contact_email_required: "1",
  emergency_contact_email_show: "1",
  emergency_contact_fullname_label: "Emergency Contact Full Name",
  emergency_contact_fullname_required: "1",
  emergency_contact_fullname_show: "1",
  emergency_contact_mobile_number_label: "Emergency Contact Mobile Number",
  emergency_contact_mobile_number_required: "1",
  emergency_contact_mobile_number_show: "1",
  emergency_contact_relation_label: "Emergency Contact Relation",
  emergency_contact_relation_required: "1",
  emergency_contact_relation_show: "1",
  fax_number_label: "Fax Number",
  fax_number_required: "1",
  fax_number_show: "1",
  fullname_label: "Full Name",
  fullname_required: "1",
  fullname_show: "1",
  gender_label: "Gender",
  gender_required: "1",
  gender_show: "1",
  identification_card_back_label: "Identification Card (Back)",
  identification_card_back_required: "1",
  identification_card_back_show: "1",
  identification_card_front_label: "Identification Card (Front)",
  identification_card_front_required: "1",
  identification_card_front_show: "1",
  identification_number_label: "Identification Number",
  identification_number_required: "1",
  identification_number_show: "1",
  identification_type_label: "Identification Type",
  identification_type_required: "1",
  identification_type_show: "1",
  marital_status_label: "Marital Status",
  marital_status_required: "1",
  marital_status_show: "1",
  mobile_number_label: "Mobile Number",
  mobile_number_required: "1",
  mobile_number_show: "1",
  monthly_income_label: "Monthly Income",
  monthly_income_required: "1",
  monthly_income_show: "1",
  nationality_label: "Nationality",
  nationality_required: "1",
  nationality_show: "1",
  occupation_label: "Occupation",
  occupation_required: "1",
  occupation_show: "1",
  office_number_label: "Office Number",
  office_number_required: "1",
  office_number_show: "1",
  postcode_label: "Postcode",
  postcode_required: "1",
  postcode_show: "1",
  race_label: "Race",
  race_required: "1",
  race_show: "1",
  salutation_label: "Salutation",
  salutation_required: "1",
  salutation_show: "1",
  state_label: "State",
  state_required: "1",
  state_show: "1",
};

export const modulesDefaultFormat = {
  is_calc_booking_fee: 1,
  is_calc_bumi: 1,
  is_document: 1,
  is_financier_info: 1,
  is_location_customer: 1,
  is_location_salesperson: 1,
  is_ql_contacts_customer: 1,
  is_ql_contacts_salesperson: 1,
  is_ql_files_customer: 1,
  is_ql_files_salesperson: 1,
  is_ql_floor_plan_customer: 1,
  is_ql_floor_plan_salesperson: 1,
  is_ql_saleschart_customer: 1,
  is_ql_saleschart_salesperson: 1,
  is_ql_unit_customer: 1,
  is_ql_unit_salesperson: 1,
  is_ql_virtual_tour_customer: 1,
  is_ql_virtual_tour_salesperson: 1,
  is_site_plan_customer: 1,
  is_site_plan_salesperson: 1,
  is_solicitor_info: 1,
  is_spa_info: 1,
  is_unit_summary_customer: 1,
  is_unit_summary_salesperson: 1,
};

export const setModulesValues = ({
  calc_bumi_form = true,
  calc_booking_fee = true,
  internal_sales_chart = true, // deprecated
  public_sales_chart = true, // deprecated
  public_unit_type = true, // deprecated
  QL_sales_chart = { salesperson: true, customer: true },
  QL_virtual_tour = { salesperson: true, customer: true },
  QL_units = { salesperson: true, customer: true },
  QL_floor_plans = { salesperson: true, customer: true },
  QL_files = { salesperson: true, customer: true },
  QL_contacts = { salesperson: true, customer: true },
  location = { salesperson: true, customer: true },
  site_plan = { salesperson: true, customer: true },
  unit_summary = { salesperson: true, customer: true },
  unit_summary_mode = "unit_type",
  unit_summary_label = { unit_available: true, builtup_area: true, land_area: true, price: true, floor_plan: true, bedroom: true, bathroom: true, reservation_fee: true },
  stages = { reserved: true, booked: true, spa_signed: true, spa_stamped: true, spa_sold: true },
  modules = { spa_information: true, solicitor_information: true, financier_information: true, documents: true },
  prefixList = MobilePrefix,
  financingList = financing,
  firstTimeBuyerList = first_time_buyer,
  bumiputeraList = bumiputera,
  paymentMethodList = payment_method,
  salutationList = salutation,
  identityTypeList = identity_type,
  occupationList = occupation,
  genderList = gender,
  raceList = race,
  nationalityList = nationality,
  maritalStatusList = marital_status,
  relationList = relation,
}) => {
  return {
    calc_bumi_form,
    calc_booking_fee,
    internal_sales_chart,
    public_sales_chart,
    public_unit_type,
    QL_sales_chart,
    QL_virtual_tour,
    QL_units,
    QL_floor_plans,
    QL_files,
    QL_contacts,
    site_plan,
    location,
    unit_summary,
    unit_summary_mode,
    unit_summary_label,
    stages,
    modules,
    prefixList,
    financingList,
    firstTimeBuyerList,
    bumiputeraList,
    paymentMethodList,
    salutationList,
    identityTypeList,
    occupationList,
    genderList,
    raceList,
    nationalityList,
    maritalStatusList,
    relationList,
  };
};

