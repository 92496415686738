import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Dropdown, Modal, CloseButton, Card, Offcanvas } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import moment from "moment";
import Loader from "../includes/Loader";
import { AgGridReact, useGridFilter } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { faEllipsis } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { FilePond, registerPlugin } from "react-filepond";
import * as XLSX from "xlsx";
import { faFilter, faFilterList } from "@fortawesome/pro-duotone-svg-icons";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";

const RedemptionTransactional = () => {
  const { Formik } = formik;
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [success, setSuccess] = useState(0);

  //  AG GRID FUNCTION ==================================================

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "85vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Email Send ID",
      field: "email_send_id",
    },
    {
      headerName: "Date Created",
      field: "date_time_create",
    },
    {
      headerName: "Email Subject",
      field: "email_subject",
    },
    {
      headerName: "Email Compose",
      field: "email_compose",
    },
    {
      headerName: "Email Ref ID",
      field: "email_ref_id",
    },

    {
      headerName: "Sender ID",
      field: "sender_id",
    },
    {
      headerName: "Receiver Email",
      field: "receiver_email",
    },
    {
      headerName: "Receiver Name",
      field: "receiver_name",
    },
    {
      headerName: "Deal ID",
      field: "deal_id",
    },
    {
      headerName: "Prospect ID",
      field: "prospect_id",
    },
    {
      headerName: "Company ID",
      field: "company_id",
    },
    {
      headerName: "Email Delivered",
      field: "email_delivered",
    },
    {
      headerName: "Scheduled",
      field: "scheduled",
    },
    {
      headerName: "Trigger Date Time",
      field: "trigger_date_time",
    },
    {
      headerName: "Trigger Successful",
      field: "trigger_successful",
    },
    {
      headerName: "Campaign ID",
      field: "campaign_id",
    },
    {
      headerName: "Provider",
      field: "provider",
    },
    {
      headerName: "Email Data",
      field: "email_data",
      minWidth: 500,
    },
    {
      headerName: "Email Provider Response",
      field: "email_provider_response",
      minWidth: 500,
    },
    {
      headerName: "Action",
      field: "",
      headerClass: "center",
      cellClass: "center",
      pinned: "right",
      maxWidth: 100,
      cellRenderer: (params) => {
        if (params.data) {
          if (!params.data.email_provider_response && Number(params.data.email_delivered) === 0) {
            return (
              <Button variant="" className="op-primary-color text-light" onClick={() => resend(params.data)}>
                Resend
              </Button>
            );
          } else {
            if (Number(params.data.email_delivered) === 0) {
              const json = JSON.parse(params.data.email_provider_response);
              if (json.code !== 200) {
                return (
                  <Button variant="" className="op-primary-color text-light" onClick={() => resend(params.data)}>
                    Resend
                  </Button>
                );
              }
            }
          }
        }
      },
    },
  ];

  const gridDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("https://www.nexcrmapis.com/cloud_staging/ext/glRedemption/api_internal_email.php", {
            params: {
              task: "List",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setTotal(Number(data.totalRecord));
              } else {
                {
                  setTotal(Number(0));
                }
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, success]);

  const gridColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      filter: true,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.email_send_id.toString();
  }, []);

  // SEND FUNCTION ======================================================

  const resend = async (record) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ext/glRedemption/api_internal_email.php", {
        params: {
          task: "Resend",
          email_send_id: record.email_send_id,
          email_data: record.email_data,
          email_compose: record.email_compose,
          email_subject: record.email_subject,
          receiver_email: record.receiver_email,
          receiver_name: record.receiver_name,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setSuccess(Math.random());
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      } else {
        setSuccess(Math.random());
        Swal.fire({
          icon: "error",
          text: data.message,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={gridRef}
                    columnDefs={gridColumn}
                    datasource={gridDataSource}
                    defaultColDef={gridColDef}
                    getRowId={gridRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}

    </Container>
  );
};

export default RedemptionTransactional;
