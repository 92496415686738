import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Modal, Offcanvas, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";

import { MobilePrefix, bumiputeraList, financingList, firstTimeBuyerList, genderList, identityTypeList, maritalStatusList, nationalityList, occupationList, paymentMethodList, raceList, relationList, salutationList } from "../../lib/js/Function";
import Swal from "sweetalert2/dist/sweetalert2";

const defaultJSON = {
  prefixList: MobilePrefix,
  financingList: financingList,
  firstTimeBuyerList: firstTimeBuyerList,
  bumiputeraList: bumiputeraList,
  paymentMethodList: paymentMethodList,
  salutationList: salutationList,
  identityTypeList: identityTypeList,
  occupationList: occupationList,
  genderList: genderList,
  raceList: raceList,
  nationalityList: nationalityList,
  maritalStatusList: maritalStatusList,
  relationList: relationList,
};

const defaultJSON2 = `{
  prefixList: [], // Set prefix list in the booking form
  financingList: [], // Set financing list in the booking form
  firstTimeBuyerList: [], // Set first time buyer list in the booking form
  bumiputeraList: [], // Set bumiputera list in the booking form
  paymentMethodList: [], // Set payment method list in the booking form
  salutationList: [], // Set salutation list in the booking form
  identityTypeList: [], // Set identification type list in the booking form
  occupationList: [], // Set occupation list in the booking form
  genderList: [], // Set gender list in the booking form
  raceList: [], // Set race list in the booking form
  nationalityList: [], // Set nationality list in the booking form
  maritalStatusList: [], // Set marital status list in the booking form
  relationList: [], // Set relation list in the booking form
}`;

const BookingFieldList = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("{}");
  const [code2, setCode2] = useState("{}");
  const [isModal, setIsModal] = useState(false);

  const getData = async () => {
    try {
      const formData = new FormData();
      formData.append("task", "read");
      formData.append("company_id", session.company_id);
      formData.append("file_name", "booking_modules");
      formData.append("default_json", JSON.stringify(defaultJSON));

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_json_editor.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        if (!Array.isArray(data.record)) {
          setCode(JSON.stringify(data.record, null, 2));
          setCode2(JSON.stringify(data.record, null, 2));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCommand = (editor) => {
    editor.commands.addCommand({
      name: "foldAll",
      bindKey: { win: "Ctrl-K Ctrl-0", mac: "Cmd-K Cmd-0" }, // Key binding
      exec: (editor) => {
        editor.getSession().foldAll();
      },
    });
  };

  const sendCode = async () => {
    try {
      const formData = new FormData();
      formData.append("task", "update");
      formData.append("company_id", session.company_id);
      formData.append("file_name", "booking_modules");
      formData.append("json", JSON.stringify(code));

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_json_editor.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        setCode2(code);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-2" gap={2}>
            <Button variant="" className="op-primary-color text-light" onClick={setIsModal}>
              JSON Overview
            </Button>
            <Button variant="success" disabled={code === code2} onClick={sendCode}>
              Save
            </Button>
          </Stack>

          <AceEditor
            onLoad={getCommand}
            placeholder="Placeholder Text"
            mode="json"
            theme="monokai"
            onChange={setCode}
            fontSize={14}
            lineHeight={19}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={code}
            style={{ width: "100%", height: "80vh" }}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showPrintMargin: false,
              tabSize: 2,
            }}
          />
        </div>
      )}

      <Offcanvas show={isModal} onHide={setIsModal} placement="bottom" style={{ height: "100vh", left: 57 }}>
        <Offcanvas.Header className="position-relative" closeButton>
          <Offcanvas.Title>JSON Overview Documentation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="mb-3">
            <h6>1.&nbsp;&nbsp;Learn the JSON format</h6>
            <AceEditor
              placeholder="Placeholder Text"
              mode="json"
              theme="monokai"
              fontSize={14}
              lineHeight={19}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine={true}
              value={defaultJSON2}
              readOnly={true}
              style={{ width: "80%", height: "50vh" }}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showPrintMargin: false,
                tabSize: 2,
              }}
            />
          </section>

          <section>
            <h6>
              2.&nbsp;&nbsp;<span className="font-monospace">"unit_summary_mode"</span> Posibble Options
            </h6>
            <ul>
              <li>
                <span className="font-monospace">unit_type</span> - Use for making the unit summary in the unit type mode
              </li>
              <li>
                <span className="font-monospace">bedroom</span> - Use for making the unit summary in the bedroom mode
              </li>
            </ul>
          </section>

          <section className="mb-3">
            <h6>3.&nbsp;&nbsp;Example JSON Setup</h6>
            <AceEditor
              placeholder="Placeholder Text"
              mode="json"
              theme="monokai"
              fontSize={14}
              lineHeight={19}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine={true}
              value={JSON.stringify(defaultJSON, null, 2)}
              readOnly={true}
              style={{ width: "80%", height: "50vh" }}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showPrintMargin: false,
                tabSize: 2,
              }}
            />
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default BookingFieldList;
