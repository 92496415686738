import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const FBFormSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [fbPageList, setFbPageList] = useState([]);
    var fbPageList2 = [];

    const [fbFormList, setFbFormList] = useState([]);
    var fbFormList2 = [];

    const [teamList, setTeamList] = useState([]);
    const [dsList, setDsList] = useState([]);

    const getAllFacebookPageForm = () => {
        
        axios.get("ws/ws_facebook.php",{
            params: {
                task: "getFBForms",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
          let data = res.data;
          
          if (Number(data.status) === 0) {
            
            var list1 = data.record.map((data) => {
                return {label: data.fb_page_name, value: data.fb_lead_pages_id, forms_record: data.forms.data};
            });

            data.record.forEach(data => {
                fbPageList2.push({label: data.fb_page_name, value: data.fb_lead_pages_id});
            });

            setFbPageList(list1);
            handleFBForm(list1);
          }else{
            setFbPageList([]);
            handleFBForm([]);
          }
        });
    }

    const handleFBForm = (data) => {

        if (data.length > 0) {
            var list = [];

            data.forEach(ele => {
                if (ele.forms_record) {
                    ele.forms_record.forEach(data => {
                        list.push({label: data.name, value: data.id, lead_page_id: ele.value});
                        fbFormList2.push({label: data.name, value: data.id, lead_page_id: ele.value});
                    });
                }
            });
            
            setFbFormList(list);   
        }else{
            setFbFormList([]);
        }
    }

    const getAllTeam = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "getUserTags",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var list = data.record.map(rec => {
                    return {label: rec.user_tag_title, value: rec.tag_token}
                });

                setTeamList(list);
            }else{
                setTeamList([]);
            }
        });
    }

    const getAllSource = () => {
        
        axios.get('ws/ws_source.php',{
            params: {
                task: 2,
                disp: "show",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dsList = data.record.map((record)=>{
                    return {label: record.ds_title, value: record.ds_token};
                });
                setDsList(dsList);
            }else{
                setDsList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingFBForm_headername_1'),
            field: "form_title",
            filter: true,
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingFBForm_headername_2'),
            field: "form_id",
            resizable: true,
            flex: 1,
            minWidth: 300
        },
        {
            headerName: t('SettingFBForm_headername_3'),
            field: "ds_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingFBForm_headername_4'),
            field:"user_tag_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingFBForm_headername_5'),
            resizable: true,
            width: 80,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <Form.Check
                            type="switch"
                            className="d-flex align-items-center justify-content-center"
                            checked={Number(params.data.inactive) === 0 ? true : false}
                            onChange={() => {markAsActive(params.data)}}
                        />
                    );
                }
            }
        },
        {
            headerName: t('SettingFBForm_headername_6'),
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.fbforms_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_facebook.php',{
            params:{
                task: "listForms",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {

                const filteredRows = data.record;

                setIsLoading(false);
                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
            }else{
                setIsLoading(false);
                setRecordFound(0);
                setRowData([]);
                setIsGrid(true);
            }
        })
        .catch(error => {
            setIsLoading(false);
            setRecordFound(0);
            setRowData([]);
            setIsGrid(true);
        });

    };

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllFacebookPageForm();
            getAllTeam();
            getAllSource();
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const markAsActive = (data) => {
        var mode = Number(data.inactive) === 0 ? 1 : 0;
        
        axios.get('ws/ws_facebook.php', {
            params: {
                task: 'formActive',
                fbforms_id: data.fbforms_id,
                mode: mode,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const addSchema = yup.object().shape({
        form_title: yup.string().required(t('SettingFBForm_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_facebook.php',{
            params: {
                task: 'addForm',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                title: values.form_title
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingFBForm_alert_success_title'),
                    text: t('SettingFBForm_alert_success_add_text'),
                    timer: 1500
                }).then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingFBForm_alert_failed_title'),
                    text: t('SettingFBForm_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        form_title_edit: yup.string().required(t('SettingFBForm_required_field')),
        form_fb_page_id: yup.string().required(t('Setting_requiredFd')),
        form_fb_form_id: yup.string().required(t('Setting_requiredFd')),
        team_id: yup.string().required(t('Setting_requiredFd')),
        ds_id: yup.string().required(t('Setting_requiredFd'))
    });

    const handleEdit = (data) => {
        var form_fb_page_id = "";
        var form_fb_form_id = "";

        fbPageList2.forEach(rec => {
            if (rec.value === data.fb_lead_pages_id) {
                form_fb_page_id = rec.value;
            }
        });

        fbFormList2.forEach(rec => {
            if (rec.value === data.form_id) {
                form_fb_form_id = rec.value;
            }
        });

        setEditInitVal({
            form_id: data.fbforms_id,
            form_fb_page_id: form_fb_page_id,//data.fb_lead_pages_id ,
            form_fb_form_id: form_fb_form_id,//data.form_id, 
            form_title_edit: data.form_title,
            team_id: data.user_tag_token,
            ds_id: data.ds_token
        });

        setIsEdit(true);
    }

    const sendEdit = (values) => {
        
        axios.get('ws/ws_facebook.php',{
            params: {
                task: 'updateFbForm',
                formtitle: values.form_title_edit,
                source: values.ds_id,
                fbpage: values.form_fb_page_id,
                usertag: values.team_id,
                fbform: values.form_fb_form_id,
                fbforms_id: values.form_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingFBForm_alert_success_title'),
                    text: t('SettingFBForm_alert_success_edit_text'),
                    timer: 1500
                }).then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingFBForm_alert_failed_title'),
                    text: t('SettingFBForm_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {

        Swal.fire({
            icon: "warning",
            title: t('SettingFBForm_alert_warning_title'),
            text: t('SettingFBForm_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingFBForm_alert_warning_confirm'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        }).then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_facebook.php',{
                    params: {
                        task: "delForm",
                        fbforms_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
        
                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingFBForm_alert_success_title'),
                            text: t('SettingFBForm_alert_success_delete_text'),
                            timer: 1500,
                        }).then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingFBForm_alert_failed_title'),
                            text: t('SettingFBForm_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('form_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingFBForm_btn_add')}</button>
                            <Form.Group style={{width: "300px"}}>

                                <InputGroup >
                                    <Form.Control
                                        type="text"
                                        placeholder={t('SettingFBForm_search_placeholder')}
                                        onChange={(e) => {
                                            handleSearch(e.target.value);
                                        }}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon"/>
                                    </InputGroup.Text>
                                </InputGroup>

                            </Form.Group>
                            <div className="px-2 ms-auto">{Number(recordFound) === 1 ? t('SettingFBForm_record', {count: recordFound}) : Number(recordFound) > 1 ? t('SettingFBForm_record', {count: recordFound}) : t('SettingFBForm_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    form_title: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingFBForm_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingFBForm_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.form_title && touched.form_title}
                                                onChange={handleChange("form_title")}
                                            />
                                            {errors.form_title && touched.form_title && <div className="op-error-message">{errors.form_title}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingFBForm_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingFBForm_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingFBForm_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.form_title_edit}
                                                isInvalid={errors.form_title_edit && touched.form_title_edit}
                                                onChange={handleChange('form_title_edit')}
                                            />
                                            {errors.form_title_edit && touched.form_title_edit && <div className="op-error-message">{errors.form_title_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingFBForm_modalBody_edit_title_2')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingFBForm_modalBody_edit_title_2_placeholder')}
                                                options={fbPageList}
                                                valueDefault={fbPageList.find(option => option.value === values.form_fb_page_id)}
                                                isInvalid={errors.form_fb_page_id && touched.form_fb_page_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {
                                                    handleChange("form_fb_page_id")(e ? e.value:"");
                                                }}
                                            />
                                            {errors.form_fb_page_id && touched.form_fb_page_id && <div className="op-error-message">{errors.form_fb_page_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingFBForm_modalBody_edit_title_3')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingFBForm_modalBody_edit_title_3_placeholder')}
                                                options={values.form_fb_page_id ? fbFormList.filter(opt => opt.lead_page_id === values.form_fb_page_id) : fbFormList}
                                                valueDefault={fbFormList.filter(opt => opt.value === values.form_fb_form_id)}
                                                isInvalid={errors.form_fb_form_id && touched.form_fb_form_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('form_fb_form_id')(e ? e.value:"")}}
                                            />
                                            {errors.form_fb_form_id && touched.form_fb_form_id && <div className="op-error-message">{errors.form_fb_form_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingFBForm_modalBody_edit_title_4')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingFBForm_modalBody_edit_title_4_placeholder')}
                                                options={dsList}
                                                valueDefault={dsList.filter(option => option.value === values.ds_id)}
                                                isInvalid={errors.ds_id && touched.ds_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('ds_id')(e ? e.value:"")}}
                                            />
                                            {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingFBForm_modalBody_edit_title_5')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingFBForm_modalBody_edit_title_5_placeholder')}
                                                options={teamList}
                                                valueDefault={teamList.filter(option => option.value === values.team_id)}
                                                isInvalid={errors.team_id && touched.team_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('team_id')(e ? e.value:"")}}
                                            />
                                            {errors.team_id && touched.team_id && <div className="op-error-message">{errors.team_id}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingFBForm_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );
}

export default FBFormSetting;