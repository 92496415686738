import { useContext, useState, useEffect, createContext } from "react";
import Cookies from "js-cookie";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2/dist/sweetalert2";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

export const AuthProvider = ({ children, onAuth }) => {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [invalid, setInvalid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkUserStatus();
  }, []);

  const loginUser = async (userInfo) => {
    setLoading(true);

    const encryptSecretKey = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    const encryptSecretIV = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
    const encryptedPassword = CryptoJS.AES.encrypt(userInfo.password, encryptSecretKey, { mode: CryptoJS.mode.CBC, iv: encryptSecretIV }).toString();
    try {
      const response = await axios.post(
        "https://app.outperformhq.io/cloud/auth_security.php",
        {
          login: userInfo.email,
          key: encryptedPassword,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const data = response.data;
      const accountDetails = response.data.record;
      if (Number(data.status) === 0) {
        const userData = {
          hostUrl: "https://app.outperformhq.io",
          hostUrlType: "cloud",
          hostUrlApi: "https://www.nexcrmapis.com",
          hostUrlApiType: "cloud",
          baseUrl: "https://app-r1.outperformhq.io/",
          origin: "",
          remember: userInfo.remember,
          role_id: accountDetails.role_id,
          user_id: accountDetails.user_id,
          user_token: accountDetails.user_token,
          super_admin: accountDetails.super_admin,
          user_email: accountDetails.user_email,
          user_name: accountDetails.user_name,
          avanser: accountDetails.avanser,
          avanser_enabled: accountDetails.avanser_enabled,
          company_id: accountDetails.company_id,
          company_title: accountDetails.company_title,
          company_type_id: accountDetails.company_type_id,
          company_token: accountDetails.company_token,
          user_ac_token: accountDetails.user_ac_token,
          company_universal_contact: accountDetails.company_universal_contact,
          readwrite_permission: accountDetails.readwrite_permission,
          restrict_download: accountDetails.restrict_download,
          universal_calendar: accountDetails.universal_calendar,
          company_lead_assignment_type: accountDetails.company_lead_assignment_type,
          company_lead_assignment_autoreroute: accountDetails.company_lead_assignment_autoreroute,
          company_lead_default_admin: accountDetails.company_lead_default_admin,
          company_lead_pointer_current: accountDetails.company_lead_pointer_current,
          open_deal_list: accountDetails.open_deal_list,
          subscription_tier: accountDetails.subscription_tier,
          inbound_analytics: accountDetails.inbound_analytics,
          layout_mode: accountDetails.layout_mode,
          curation_enabled: accountDetails.curation_enabled,
          google_calendar: accountDetails.google_calendar,
          product_feature: accountDetails.product_feature,
          auto_open_deal: accountDetails.auto_open_deal,
          contributor_all_access: accountDetails.contributor_all_access,
          lead_transfer_report: accountDetails.lead_transfer_report,
          contact_disable_blank_contact: accountDetails.contact_disable_blank_contact,
          whatsapp_conversation: accountDetails.whatsapp_conversation,
          whatsapp_campaign: accountDetails.whatsapp_campaign,
          deal_custom_action: accountDetails.deal_custom_action,
          deal_custom_action_url: accountDetails.deal_custom_action_url,
          deal_custom_action_pipe: accountDetails.deal_custom_action_pipe,
          contributor_no_edit_contact: accountDetails.contributor_no_edit_contact,
          stage_forward_only: accountDetails.stage_forward_only,
          blueprint_ai: accountDetails.blueprint_ai,
          chatbot_ai: accountDetails.chatbot_ai,
          country: accountDetails.country,
          page_user_restrict: accountDetails.page_user_restrict,
          page_settings_restrict: accountDetails.page_settings_restrict,
          event_rsvp: accountDetails.event_rsvp,
          survey: accountDetails.survey,
          booking_module: accountDetails.booking_module,
          default_lang: accountDetails.default_lang,
          activity_duration_setting: accountDetails.activity_duration_setting,
          auto_load_screen: accountDetails.auto_load_screen,
          booking_disable_status_changes: accountDetails.booking_disable_status_changes,
          booking_sales_chart_restricted: accountDetails.booking_sales_chart_restricted,
          booking_viewer_access: accountDetails.booking_viewer_access,
        };

        if (accountDetails.ns_id !== null) {
          userData.naming_scheme = 1;
          userData.top_menu_deal = accountDetails.top_menu_deal;
          userData.deal_variation = accountDetails.deal_variation;
          userData.dealpage_adddeal = accountDetails.dealpage_adddeal;
          userData.stat_dealsadded = accountDetails.stat_dealsadded;
          userData.stat_dealswon = accountDetails.stat_dealswon;
          userData.stat_dealslost = accountDetails.stat_dealslost;
          userData.stat_dealsclosed = accountDetails.stat_dealsclosed;
          userData.stat_ds_opendeals = accountDetails.stat_ds_opendeals;
          userData.stat_ds_wondeals = accountDetails.stat_ds_wondeals;
          userData.stat_pipeline_opendeals = accountDetails.stat_pipeline_opendeals;
          userData.stat_wondeals_rep = accountDetails.stat_wondeals_rep;
          userData.stat_addeddeals_rep = accountDetails.stat_addeddeals_rep;
          userData.adddeal_title = accountDetails.adddeal_title;
          userData.adddeal_source = accountDetails.adddeal_source;
          userData.adddeal_value = accountDetails.adddeal_value;
        } else {
          userData.naming_scheme = 0;
        }

        if (userData.role_id === "1") {
          userData.uid_cookies = "all";
          userData.uid_cookiesid = "";
          userData.uid_cookies_name = "All";
        } else {
          userData.uid_cookies = userData.user_id;
          userData.uid_cookiesid = `&user=${userData.user_id}`;
          userData.uid_cookies_name = userData.user_name;
        }

        userData.pipe_pipeid = accountDetails.default_pipeline !== 0 ? accountDetails.default_pipeline : "default";
        userData.pipe_pipename = accountDetails.default_pipeline !== 0 ? "Default" : "Default";
        userData.profile_pic = accountDetails.user_avatar_url && accountDetails.user_avatar_url !== "" ? accountDetails.user_avatar_url : "assets/account_avatar/genericavatar.jpg";

        axios
          .get("ws/ws_setting.php", {
            params: {
              task: 5,
              company: accountDetails.company_id,
            },
          })
          .then((response) => {
            const settingData = response.data;
            userData.default_cs_id = settingData.record && settingData.record.length > 0 ? settingData.record[0].cs_id : null;
          });

        let expirationDate = new Date();
        if (userInfo.remember) {
          expirationDate.setTime(expirationDate.getTime() + 7 * 24 * 60 * 60 * 1000);
        } else {
          expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
        }

        Cookies.set("user_record", JSON.stringify(userData), { expires: expirationDate });
        setTimeout(() => {
          setSession(userData);
          onAuth(userData);
          setInvalid(false);
          setLoading(false);
        }, 1000);
      } else {
        setSession(null);
        onAuth(null);
        setInvalid(true);
        setLoading(false);
        return false;
      }
    } catch (error) {
      setSession(null);
      onAuth(null);
      setInvalid(true);
      setLoading(false);
      return false;
    }
  };

  const logoutUser = () => {
    Cookies.remove("user_record");
    setSession(null);
    onAuth(null);
    setInvalid(false);
  };

  const checkUserStatus = async () => {
    try {
      const accountDetails = JSON.parse(Cookies.get("user_record"));
      if (accountDetails) {
        setSession(accountDetails);
        onAuth(accountDetails);
        setInvalid(false);
      } else {
        setSession(null);
        onAuth(null);
        setInvalid(false);
      }
    } catch (error) {
      setSession(null);
      onAuth(null);
      setInvalid(false);
    }

    setLoading(false);
  };

  const switchAccount = async (company_id) => {
    setLoading(true);
    try {
      const response = await axios.get("ws/ws_login.php", {
        params: {
          task: "multiAccLoginV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const accountDetails = response.data.record;
        const userData = {
          hostUrl: "https://app.outperformhq.io",
          hostUrlType: "cloud",
          hostUrlApi: "https://www.nexcrmapis.com",
          hostUrlApiType: "cloud",
          baseUrl: "https://app-r1.outperformhq.io/",
          origin: "",
          remember: false,
          role_id: accountDetails.role_id,
          user_id: accountDetails.user_id,
          user_token: accountDetails.user_token,
          super_admin: 1,
          user_email: accountDetails.user_email,
          user_name: accountDetails.user_name,
          avanser: accountDetails.avanser,
          avanser_enabled: accountDetails.avanser_enabled,
          company_id: accountDetails.company_id,
          company_title: accountDetails.company_title,
          company_type_id: accountDetails.company_type_id,
          company_token: accountDetails.company_token,
          user_ac_token: accountDetails.user_ac_token,
          company_universal_contact: accountDetails.company_universal_contact,
          readwrite_permission: accountDetails.readwrite_permission,
          restrict_download: accountDetails.restrict_download,
          universal_calendar: accountDetails.universal_calendar,
          company_lead_assignment_type: accountDetails.company_lead_assignment_type,
          company_lead_assignment_autoreroute: accountDetails.company_lead_assignment_autoreroute,
          company_lead_default_admin: accountDetails.company_lead_default_admin,
          company_lead_pointer_current: accountDetails.company_lead_pointer_current,
          open_deal_list: accountDetails.open_deal_list,
          subscription_tier: accountDetails.subscription_tier,
          inbound_analytics: accountDetails.inbound_analytics,
          layout_mode: accountDetails.layout_mode,
          curation_enabled: accountDetails.curation_enabled,
          google_calendar: accountDetails.google_calendar,
          product_feature: accountDetails.product_feature,
          auto_open_deal: accountDetails.auto_open_deal,
          contributor_all_access: accountDetails.contributor_all_access,
          lead_transfer_report: accountDetails.lead_transfer_report,
          contact_disable_blank_contact: accountDetails.contact_disable_blank_contact,
          whatsapp_conversation: accountDetails.whatsapp_conversation,
          whatsapp_campaign: accountDetails.whatsapp_campaign,
          deal_custom_action: accountDetails.deal_custom_action,
          deal_custom_action_url: accountDetails.deal_custom_action_url,
          deal_custom_action_pipe: accountDetails.deal_custom_action_pipe,
          contributor_no_edit_contact: accountDetails.contributor_no_edit_contact,
          stage_forward_only: accountDetails.stage_forward_only,
          blueprint_ai: accountDetails.blueprint_ai,
          chatbot_ai: accountDetails.chatbot_ai,
          country: accountDetails.country,
          page_user_restrict: accountDetails.page_user_restrict,
          page_settings_restrict: accountDetails.page_settings_restrict,
          event_rsvp: accountDetails.event_rsvp,
          survey: accountDetails.survey,
          booking_module: accountDetails.booking_module,
          default_lang: accountDetails.default_lang,
          activity_duration_setting: accountDetails.activity_duration_setting,
          auto_load_screen: accountDetails.auto_load_screen,
          booking_disable_status_changes: accountDetails.booking_disable_status_changes,
          booking_sales_chart_restricted: accountDetails.booking_sales_chart_restricted,
          booking_viewer_access: accountDetails.booking_viewer_access,
        };

        if (accountDetails.ns_id !== null) {
          userData.naming_scheme = 1;
          userData.top_menu_deal = accountDetails.top_menu_deal;
          userData.deal_variation = accountDetails.deal_variation;
          userData.dealpage_adddeal = accountDetails.dealpage_adddeal;
          userData.stat_dealsadded = accountDetails.stat_dealsadded;
          userData.stat_dealswon = accountDetails.stat_dealswon;
          userData.stat_dealslost = accountDetails.stat_dealslost;
          userData.stat_dealsclosed = accountDetails.stat_dealsclosed;
          userData.stat_ds_opendeals = accountDetails.stat_ds_opendeals;
          userData.stat_ds_wondeals = accountDetails.stat_ds_wondeals;
          userData.stat_pipeline_opendeals = accountDetails.stat_pipeline_opendeals;
          userData.stat_wondeals_rep = accountDetails.stat_wondeals_rep;
          userData.stat_addeddeals_rep = accountDetails.stat_addeddeals_rep;
          userData.adddeal_title = accountDetails.adddeal_title;
          userData.adddeal_source = accountDetails.adddeal_source;
          userData.adddeal_value = accountDetails.adddeal_value;
        } else {
          userData.naming_scheme = 0;
        }

        if (userData.role_id === "1") {
          userData.uid_cookies = "all";
          userData.uid_cookiesid = "";
          userData.uid_cookies_name = "All";
        } else {
          userData.uid_cookies = userData.user_id;
          userData.uid_cookiesid = `&user=${userData.user_id}`;
          userData.uid_cookies_name = userData.user_name;
        }

        userData.pipe_pipeid = accountDetails.default_pipeline !== 0 ? accountDetails.default_pipeline : "default";
        userData.pipe_pipename = accountDetails.default_pipeline !== 0 ? "Default" : "Default";
        userData.profile_pic = accountDetails.user_avatar_url && accountDetails.user_avatar_url !== "" ? accountDetails.user_avatar_url : "assets/account_avatar/genericavatar.jpg";

        axios
          .get("ws/ws_setting.php", {
            params: {
              task: 5,
              company: accountDetails.company_id,
            },
          })
          .then((response) => {
            const settingData = response.data;
            userData.default_cs_id = settingData.record && settingData.record.length > 0 ? settingData.record[0].cs_id : null;
          });

        let expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("user_record", JSON.stringify(userData), { expires: expirationDate });

        setSession(userData);
        setInvalid(false);
        setLoading(false);
        navigate("/deal/list");
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Could not change account. Please contact your IT Support",
        });

        setInvalid(true);
        setLoading(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });

      setInvalid(true);
      setLoading(false);
    }
  };

  const contextData = {
    session,
    invalid,
    loginUser,
    logoutUser,
    switchAccount,
  };

  return <AuthContext.Provider value={contextData}>{loading ? <Loader /> : children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
