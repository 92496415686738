import React, { useEffect, useState } from "react";
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { Card, Col, Container, Row, Stack, Form, Button, Modal } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { AgGridReact } from "ag-grid-react";
import { FormDate, FormSelect } from "../includes/FormCustom";

const CallInsight = () => {
  const { session } = useAuth();
  const [callInsight, setCallInsight] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartColor, setChartColor] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState([{ value: '', label: 'All User' }]);
  const [selectedUser, setSelectedUser] = useState({ value: '', label: 'All User' });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // AudioRenderer component refactored here
  const AudioRenderer = ({ data }) => {
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);

    const handleButtonClick = () => {
      setShowAudioPlayer(true);
    };

    if (data && data.audio_url) {
      return (
        <div style={{ whiteSpace: 'normal' }}>
          {showAudioPlayer ? (
            <div>
              <audio controls>
                <source src={data.audio_url} type="audio/ogg" />
              </audio>
            </div>
          ) : (
            <Button onClick={handleButtonClick}>Show Audio</Button>
          )}
        </div>
      );
    }
    return null;
  };

  const openModal = (field, payload) => {
    let matchedRecord = null;

    callInsight.forEach(recordObj => {
      if (recordObj.field === field) {
        matchedRecord = recordObj.record.find(record => record.name === payload.name);
      }
    });

    if (matchedRecord) {
      setShowModal(true);
      getResponseData(field, matchedRecord.name);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setResponseData([]);
  };

  const getCallInsight = (user_id, start_date, end_date) => {
    axios
      .get("ws/ws_call_insight.php", {
        params: {
          task: "getCallInsight3",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user: user_id,
          ds: start_date,
          de: end_date
        },
      })
      .then((response) => {
        const data = response.data;
        setLoading(true);
        if (data.status === 0) {
          setCallInsight(data.record);
        } else {
          setCallInsight([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching call insight:", error);
        setCallInsight([]);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  };

  const getResponseData = (field, recordValue) => {
    axios.get("/ws/ws_call_insight.php", {
      params: {
        task: "getCallInsightDetails",
        utoken: session.user_ac_token,
        ctoken: session.company_token,
        field: field,
        record_value: recordValue,
        ds: startDate || "",
        de: endDate || "",
        user: selectedUser.value
      }
    })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          setResponseData(data.record);
        } else {
          setResponseData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching response data:", error);
        setResponseData([]);
      });
  };

  const getUser = () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user_tag: "",
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          const transformedUsers = data.record.map(user => ({
            label: user.user_name,
            value: user.user_id
          }));
          setUser([{ value: '', label: 'All User' }, ...transformedUsers]);
        } else {
          setUser([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setUser([]);
      });
  };

  const getRandomColor = () => {
    var color = [];
    for (var i = 0; i < 100; i++) {
      color.push('#' + Math.floor(Math.random() * 16777215).toString(16));
    }
    setChartColor(color);
  };

  const renderLegend = (props, field) => {
    const { payload } = props;
    var totalResponse = 0;
    payload.forEach((record) => {
      totalResponse += record.payload.value;
    });

    return (
      <div className="legend-container" style={{ maxHeight: "200px", overflowY: "auto", scrollbarWidth: 'none' }}>
        <table className="table">
          <tbody>
            {payload.map((record, index) => {
              return (
                <tr key={index} style={{ borderBottom: 'transparent' }}>
                  <td style={{ width: '200px' }}>
                    <FontAwesomeIcon icon={faSquare} style={{ color: record.color }} size="lg" className="me-2" />
                    {record.payload.name}
                  </td>
                  <td>
                    <span onClick={() => openModal(field, record.payload)} style={{ cursor: 'pointer' }}>
                      {record.payload.value} Response - {Math.round((record.payload.value / totalResponse) * 100)}%
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const prospectRenderer = (params) => {
    if (params.data) {
      const handleClick = () => {
        if (params.data.prospect_id) {
          window.open("/prospect/" + params.data.prospect_id, "_blank");
        }
      };

      return (
        <div style={{ whiteSpace: 'normal', cursor: params.data.prospect_url ? 'pointer' : 'default' }} onClick={handleClick}>
          {params.value}
        </div>
      );
    }
    return params.value;
  };

  const getFieldLabel = (field) => {
    const matchedInsight = callInsight.find(insight => insight.field === field);
    return matchedInsight ? matchedInsight.label : field;
  };

  const gridColumn = [
    {
      headerName: 'Name',
      field: 'prospect_name',
      cellRenderer: prospectRenderer,
      cellStyle: {
        maxWidth: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      filter: "agTextColumnFilter"
    },
    {
      headerName: 'Audio Recording',
      field: '',
      cellRenderer: (params) => <AudioRenderer data={params.data} />,
      filter: "agTextColumnFilter",
      width: 350,
    },
    {
      headerName: getFieldLabel('analytics_param_1'),
      field: 'analytics_param_1',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
    {
      headerName: getFieldLabel('analytics_param_2'),
      field: 'analytics_param_2',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
    {
      headerName: getFieldLabel('analytics_param_3'),
      field: 'analytics_param_3',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
    {
      headerName: getFieldLabel('analytics_param_4'),
      field: 'analytics_param_4',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
    {
      headerName: getFieldLabel('analytics_param_5'),
      field: 'analytics_param_5',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
    {
      headerName: getFieldLabel('analytics_param_6'),
      field: 'analytics_param_6',
      filter: "agTextColumnFilter",
      width: 250,
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: '1.2'
      }
    },
  ];

  const ResponseModal = () => (
    <Modal show={showModal} onHide={closeModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title as={"h6"}>Response Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ width: "100%", height: "70vh" }}>
          <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
            <AgGridReact columnDefs={gridColumn} rowData={responseData} rowHeight={85} pagination={true} paginationPageSize={500} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  useEffect(() => {
    getCallInsight(selectedUser.value, startDate, endDate);
    getRandomColor();
    getUser();
  }, []);

  return (
    <Container fluid className="p-4" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
      <Row>
        <Col xxl={12} className="mb-3">
          <Form className="w-100">
            <Stack direction="horizontal" gap={3}>
              <Form.Group className="d-flex ms-auto">
                <FormSelect
                  options={user}
                  valueDefault={{ value: "", label: "All User" }}
                  onChange={(selectedOption) => setSelectedUser(selectedOption)}
                  placeholder="Select sales rep"
                  width="200px"
                  isSearchable
                />
              </Form.Group>

              <Form.Group>
                <FormDate
                  placeholder="From"
                  isClearable
                  onChange={(date) => setStartDate(date)}
                />
              </Form.Group>

              <Form.Group>
                <FormDate
                  placeholder="To"
                  isClearable
                  onChange={(date) => setEndDate(date)}
                />
              </Form.Group>

              <Button
                variant=""
                className="btn op-button op-primary-color text-light"
                onClick={() => getCallInsight(selectedUser ? selectedUser.value : null, startDate, endDate)}
              >
                Apply
              </Button>
            </Stack>
          </Form>
        </Col>
        {loading ? (
          <Loader />
        ) : (
          <>
            {callInsight.map((record, index) => (
              <Col xxl={6} key={index} className="mb-3">
                <Card className="shadow-sm">
                  <Card.Body>
                    <Card.Title as={"h6"}>{record.label}</Card.Title>
                    <PieChart width={600} height={300}>
                      <Pie dataKey="value" isAnimationActive={true} data={record.record} cx={120} cy={"50%"} innerRadius={40} outerRadius={80}>
                        {record.record.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={chartColor[index % chartColor.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend layout="vertical" verticalAlign="top" align="right" content={props => renderLegend(props, record.field)} />
                      <Legend
                        verticalAlign="middle"
                        align="right"
                        wrapperStyle={{
                          right: 0,
                          top: 0,
                          width: "100%",
                          paddingBottom: 10,
                        }}
                      />
                    </PieChart>
                  </Card.Body>
                </Card>
              </Col>
            ))}
            <ResponseModal />
          </>
        )}
      </Row>
    </Container>
  );
};

export default CallInsight;
