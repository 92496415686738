import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack, Card, Modal } from "react-bootstrap";
import { AreaChart, Area, PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar } from "recharts";
import { FormSelect, FormDate } from "../includes/FormCustom";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-duotone-svg-icons";
import Swal from 'sweetalert2';

const projectLabelMap = {
    garden: "Gamuda Gardens",
    cove: "Gamuda Cove",
    jadehill: "Jade Hills",
    horizon: "Horizon Hills",
    twentyfive7: "twentyfive.7",
    bantayan: "Bukit Bantayan",
    highpark: "Highpark Suites"
};


const CustomerService2 = () => {
    const init = useRef(false);
    const targetRef = useRef();
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [CS2Data, setCS2Data] = useState([]);
    const [CS2DataRecord, setCS2DataRecord] = useState([]);
    const [CS2DataProject, setCS2DataProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({ value: "all", label: "All Projects" });
    const [user, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ value: "", label: "All User" });
    const [chartLoading, setChartLoading] = useState(true);
    const [userNonHq, setUserNonHq] = useState([]);

    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [modalData2, setModalData2] = useState([]);
    const [modalData3, setModalData3] = useState([]);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const [modalDataDetails, setModalDataDetails] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [chartSubmittedSize, setChartSubmittedSize] = useState(6);
    const [chartResponseSize, setChartResponseSize] = useState(6);
    const [chart1Size, setChart1Size] = useState(6);
    const [chart2Size, setChart2Size] = useState(6);
    const [chart3Size, setChart3Size] = useState(6);
    const [chart4Size, setChart4Size] = useState(6);
    const [chart5Size, setChart5Size] = useState(6);
    const [chart6Size, setChart6Size] = useState(6);
    const [chart7Size, setChart7Size] = useState(6);
    const [chart8Size, setChart8Size] = useState(6);
    const [chart9Size, setChart9Size] = useState(6);
    const [chart10Size, setChart10Size] = useState(6);
    const [chart11Size, setChart11Size] = useState(6);
    const [chart12Size, setChart12Size] = useState(6);
    const [chart13Size, setChart13Size] = useState(6);
    const [chart14Size, setChart14Size] = useState(6);

    const [openProject, setOpenProject] = useState("");


    // Date format
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const [dateRange, setdateRange] = useState({
        ds: formatDate(startOfYear),
        de: formatDate(today),
    });



    // Get Function
    const getCS2Data = async () => {
        try {
            let projectValue = "";

            if (session.company_id == 180) {
                projectValue = "gardens";
            } else if (session.company_id == 190) {
                projectValue = "jadehill";
            } else if (session.company_id == 200) {
                projectValue = "cove";
            } else if (session.company_id == 203) {
                projectValue = "twentyfive7";
            } else if (session.company_id == 210) {
                projectValue = "horizon";
            } else if (session.company_id == 212) {
                projectValue = "bantayan";
            } else if (session.company_id == 213) {
                projectValue = "highpark";
            } else if (session.company_id == 251) {
                projectValue = "all"
            }

            setOpenProject(projectValue);

            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    project: session.company_id == '251' ? selectedProject.value : projectValue,
                    task: "CS2",
                    // uid: selectedUser.value || ""
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setCS2Data(response.data);
                setCS2DataRecord(response.data.record);
                setCS2DataProject(response.data.project);
                setChartLoading(false);
            }

        } catch (error) {

        }
    };

    const getUser = async (selectedOption) => {
        try {
            const response = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: selectedOption.ctoken,
                    task: '4a',
                    utoken: selectedOption.utoken
                },
            });

            if (response.data.status == '0') {
                const userOptions = response.data.record.map(user => ({
                    value: user.user_id,
                    label: user.user_name
                }));

                userOptions.unshift({ value: "", label: "All Users" });
                setUser(userOptions);
            }

        } catch (error) {

        }
    };

    const getUserNonHq = async () => {
        try {
            const response = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: session.company_token,
                    task: '4a',
                    utoken: session.user_ac_token,
                    user_tag: ''
                },
            });

            if (response.data.status == '0') {
                const userOptions = response.data.record.map(user => ({
                    value: user.user_id,
                    label: user.user_name
                }));

                userOptions.unshift({ value: "", label: "All Users" });
                setUserNonHq(userOptions);
            }

        } catch (error) {

        }
    };

    // Open Modal Data
    const getModalData2 = async () => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS2_SurveySent",
                    // uid: selectedUser.value
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setModalData2(response.data.record);
            }

        } catch (error) {

        }
    };

    const getModalData3 = async () => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS2_ResponseReceived",
                    // uid: selectedUser.value
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setModalData3(response.data.record);
            }

        } catch (error) {

        }
    };

    const getModalDataDetails = async (cf_id, value) => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    cf: cf_id,
                    de: dateRange.de,
                    ds: dateRange.ds,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS2_Details",
                    // uid: selectedUser.value,
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value,
                    val: value
                },
            });

            if (response.data.status == '0' && response.data.record) {
                setModalDataDetails(response.data.record);
                setShowModalDetails(true);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'No record found!',
                    timer: 1700
                });
            }

        } catch (error) {

        }
    };

    // Chart Data Display

    const COLORSresponse = ['#18392b', '#fa8128', '#82eefd', '#39e75f', '#fff600', '#743089', '#ff4500'];
    const COLORSpieYesNoReq = ['#82eefd', '#ec9706', '#ff69b4'];
    const COLORSpieYesNo = ['#82eefd', '#ff69b4'];
    const COLORSpie8 = ['#39e75f', '#ff69b4', '#82eefd', '#ec9706', '#663399'];
    const COLORSpie10 = ['#ff69b4', '#82eefd', '#ec9706', '#663399', '#39e75f'];
    const COLORSpieQ11 = ['#ff69b4', '#0f98fd'];

    const pieChartDataResponse = useMemo(() => {
        const projectData = CS2DataProject.response || {};

        if (session.company_id == '251') {
            if (selectedProject.value == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[selectedProject.value] || selectedProject.value,
                    value: projectData[selectedProject.value] || 0
                }];
            }
        } else {
            if (openProject == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[openProject] || openProject,
                    value: projectData[openProject] || 0
                }];
            }
        }
    }, [CS2DataProject]);

    const pieChartDataSubmitted = useMemo(() => {
        const projectData = CS2DataProject.sent || {};

        if (session.company_id == '251') {
            if (selectedProject.value == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[selectedProject.value] || selectedProject.value,
                    value: projectData[selectedProject.value] || 0
                }];
            }
        } else {
            if (openProject == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[openProject] || openProject,
                    value: projectData[openProject] || 0
                }];
            }
        }
    }, [CS2DataProject]);


    const barChartData = useMemo(() => {
        const questions = ['q0', 'q1', 'q2'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            belowAverage: parseInt(item.field_Below_Average, 10),
            excellent: parseInt(item.field_Excellent, 10),
            good: parseInt(item.field_Good, 10),
            poor: parseInt(item.field_Poor, 10),
            satisfactory: parseInt(item.field_Satisfactory, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const pieChartData2 = useMemo(() => {
        const questions = ['q3'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Not Required',
                value: parseInt(item.field_Not_Required, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Yes',
                value: parseInt(item.field_Yes, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const pieChartData3 = useMemo(() => {
        const questions = ['q4'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Yes',
                value: parseInt(item.field_Yes, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const barChartData4 = useMemo(() => {
        const questions = ['q5', 'q6', 'q7'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            belowAverage: parseInt(item.field_Below_Average, 10),
            excellent: parseInt(item.field_Excellent, 10),
            good: parseInt(item.field_Good, 10),
            poor: parseInt(item.field_Poor, 10),
            satisfactory: parseInt(item.field_Satisfactory, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const barChartData5 = useMemo(() => {
        const questions = ['q8', 'q9', 'q10', 'q11', 'q12'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            belowAverage: parseInt(item.field_Below_Average, 10),
            excellent: parseInt(item.field_Excellent, 10),
            good: parseInt(item.field_Good, 10),
            poor: parseInt(item.field_Poor, 10),
            satisfactory: parseInt(item.field_Satisfactory, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const pieChartData6 = useMemo(() => {
        const questions = ['q13'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Yes',
                value: parseInt(item.field_Yes, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const pieChartData7 = useMemo(() => {
        const questions = ['q14'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Yes',
                value: parseInt(item.field_Yes, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const pieChartData8 = useMemo(() => {
        const questions = ['q15'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Differential Sum Assistance - Instalment up to 5 years at 0 interest_rate',
            value: parseInt(item.field_Differential_Sum_Assistance__Instalment_up_to_5_years_at_0_interest_rate, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Furnishing Package',
                value: parseInt(item.field_Furnishing_Package, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Guaranteed Returns Rental Yield',
                value: parseInt(item.field_Guaranteed_ReturnsRental_Yield, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Loan Instalment Payment Assistance',
                value: parseInt(item.field_Loan_Instalment_Payment_Assistance, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Low Down Payment',
                value: parseInt(item.field_Low_Down_Payment, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const barChartData9 = useMemo(() => {
        const questions = ['q16', 'q17', 'q18', 'q19'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            field1: parseInt(item.field_1, 10),
            field2: parseInt(item.field_2, 10),
            field3: parseInt(item.field_3, 10),
            field4: parseInt(item.field_4, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const pieChartData10 = useMemo(() => {
        const questions = ['q20'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Single',
            value: parseInt(item.field_Single, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Spouse',
                value: parseInt(item.field_Spouse, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Spouse and kids',
                value: parseInt(item.field_Spouse_and_kids, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Parents',
                value: parseInt(item.field_Parents, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Siblings Friends',
                value: parseInt(item.field_SiblingsFriends, 10),
                cf_id: item.cf_id
            })),
        );
    }, [CS2DataRecord]);

    const pieChartData11 = useMemo(() => {
        const questions = ['q21'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Upgrade to a bigger unit',
            value: parseInt(item.field_Upgrade_to_a_bigger_unit, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Downsize to a smaller unit with convenient facilities',
                value: parseInt(item.field_Downsize_to_a_smaller_unit_with_convenient_facilities, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    const barChartData12 = useMemo(() => {
        const questions = ['q22', 'q23', 'q24', 'q25'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            not: parseInt(item.field_Not_preferred, 10),
            least: parseInt(item.field_Least_preferred, 10),
            neutral: parseInt(item.field_Neutral, 10),
            somewhat: parseInt(item.field_Somewhat_preferred, 10),
            most: parseInt(item.field_Most_preferred, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const barChartData13 = useMemo(() => {
        const questions = ['q26', 'q27', 'q28', 'q29', 'q30'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            field1: parseInt(item.field_1, 10),
            field2: parseInt(item.field_2, 10),
            field3: parseInt(item.field_3, 10),
            field4: parseInt(item.field_4, 10),
            field5: parseInt(item.field_5, 10),
            cf_id: item.cf_id
        }));
    }, [CS2DataRecord]);

    const pieChartData14 = useMemo(() => {
        const questions = ['q31'];
        const relevantData = questions.map(question => CS2DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Responses',
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Feedback',
                value: parseInt(item.total, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS2DataRecord]);

    //  DATE RANGE FILTER
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    // Handle print
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        content: () => targetRef.current,
    });

    // Apply Button
    const handleApplyButtonClick = async () => {
        setLoading(true);
        setChartLoading(true);

        try {
            await getCS2Data();
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    // Open Modal
    const handleOpenModal2 = () => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            setShowModal2(true);
        }
    };

    const handleOpenModal3 = () => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            setShowModal3(true);
        }
    };

    const handleBarClick = (data, key) => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            getModalDataDetails(data.cf_id, key);
            setChartData(data)
            // setShowModalDetails(true);
        }
    };

    const handleCloseModal = () => {
        setShowModalDetails(false);
        setModalDataDetails([]);
    };


    // GRID COLUMN
    const setDateFormat = (params) => {
        const dateTime = params.data.date;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    const gridColumn = [
        { headerName: "No", field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        {
            headerName: "Prospect Name",
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var dealId = params.data.deal_id;
                    window.open(`${session.origin}/deal/${dealId}`, "_blank");
                }
            }
        },
        { headerName: "Sales Rep", field: "sales_person", filter: 'agTextColumnFilter', width: 300 },
        { headerName: "Date Time Create", field: "date", width: 300, cellRenderer: setDateFormat },
    ];

    const gridColumnResponse = [
        { headerName: "No", field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        {
            headerName: "Prospect Name",
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var dealId = params.data.deal_id;
                    window.open(`${session.origin}/deal/${dealId}`, "_blank");
                }
            }
        },
        { headerName: "Sales Rep", field: "user_name", filter: 'agTextColumnFilter', width: 300 },
        { headerName: "Date Time Create", field: "date", width: 300, cellRenderer: setDateFormat },
    ];

    const gridColumnChart = [
        { headerName: "No", field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        { headerName: "Company Title", field: "company_title", filter: 'agTextColumnFilter', width: 250 },
        {
            headerName: "Prospect Name",
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var recordId = params.data.record_id;
                    window.open(`${session.origin}/prospect-details-gl/${recordId}`, "_blank");
                }
            }
        },
        { headerName: "Sales Rep", field: "user_name", filter: 'agTextColumnFilter', width: 300 },
        { headerName: "Date Time Create", field: "date", width: 200, cellRenderer: setDateFormat },
        {
            headerName: "Answer",
            field: "fd_value",
            filter: 'agTextColumnFilter',
            width: 200,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' },
        },
    ];

    // Size Expand
    const handleExpand = (chart) => {
        if (chart === "chartSubmitted") {
            if (chartSubmittedSize === 6) {
                setChartSubmittedSize(12);
            } else {
                setChartSubmittedSize(6);
            }
        } else if (chart === "chartReponse") {
            if (chartResponseSize === 6) {
                setChartResponseSize(12);
            } else {
                setChartResponseSize(6);
            }
        } else if (chart === "chart1") {
            if (chart1Size === 6) {
                setChart1Size(12);
            } else {
                setChart1Size(6);
            }
        } else if (chart === "chart2") {
            if (chart2Size === 6) {
                setChart2Size(12);
            } else {
                setChart2Size(6);
            }
        } else if (chart === "chart3") {
            if (chart3Size === 6) {
                setChart3Size(12);
            } else {
                setChart3Size(6);
            }
        } else if (chart === "chart4") {
            if (chart4Size === 6) {
                setChart4Size(12);
            } else {
                setChart4Size(6);
            }
        } else if (chart === "chart5") {
            if (chart5Size === 6) {
                setChart5Size(12);
            } else {
                setChart5Size(6);
            }
        } else if (chart === "chart6") {
            if (chart6Size === 6) {
                setChart6Size(12);
            } else {
                setChart6Size(6);
            }
        } else if (chart === "chart7") {
            if (chart7Size === 6) {
                setChart7Size(12);
            } else {
                setChart7Size(6);
            }
        } else if (chart === "chart8") {
            if (chart8Size === 6) {
                setChart8Size(12);
            } else {
                setChart8Size(6);
            }
        } else if (chart === "chart9") {
            if (chart9Size === 6) {
                setChart9Size(12);
            } else {
                setChart9Size(6);
            }
        } else if (chart === "chart10") {
            if (chart10Size === 6) {
                setChart10Size(12);
            } else {
                setChart10Size(6);
            }
        } else if (chart === "chart11") {
            if (chart11Size === 6) {
                setChart11Size(12);
            } else {
                setChart11Size(6);
            }
        } else if (chart === "chart12") {
            if (chart12Size === 6) {
                setChart12Size(12);
            } else {
                setChart12Size(6);
            }
        } else if (chart === "chart13") {
            if (chart13Size === 6) {
                setChart13Size(12);
            } else {
                setChart13Size(6);
            }
        } else if (chart === "chart14") {
            if (chart14Size === 6) {
                setChart14Size(12);
            } else {
                setChart14Size(6);
            }
        }
    }

    const projectTokens = {
        gardens: { utoken: "822b901dd4b40696fbab9a6137411757", ctoken: "48ade9786a2d11568db7aad9657a90de" },
        jadehill: { utoken: "0ee48faae67e81da9c0146c2de2a5926", ctoken: "3f684e70f4379ce5dc998a5b1d433751" },
        cove: { utoken: "243d7203cd06bb5d2fadbdafa32e213e", ctoken: "c7a341f4b174827d2f2b88a947554855" },
        twentyfive7: { utoken: "167e46363fa4c5320ce73bc1d90c33ab", ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6" },
        bantayan: { utoken: "120830d144cc961cb0ca2e047d0c05d3", ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec" },
        highpark: { utoken: "38cecfadf5b89105aa879bab98b5fff0", ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8" },
        horizon: { utoken: "401c13774894dd9edeef77a2523faa39", ctoken: "9e326bbe084ccb68e4ec27dab66f7731" },
    };


    // UseEffect
    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getCS2Data();
                    await getUser();
                    getUserNonHq();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session]);

    useEffect(() => {
        const allChartsLoaded = () => {
            return !chartLoading;
        };

        if (allChartsLoaded()) {
            setLoading(false);
        }
    }, [chartLoading]);

    useEffect(() => {
        if (showModal2) {
            getModalData2();
        } else if (showModal3) {
            getModalData3();
        }
    }, [showModal2, showModal3]);



    return (
        <Container fluid className="p-0 m-0">
            <div className="p-4" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
                <Stack className="mb-3" direction="horizontal" gap={3}>
                    {session.company_id == '251' ? (
                        <>
                            <Form.Group>
                                <FormSelect
                                    options={[
                                        { value: "all", label: "All Projects" },
                                        ...(CS2DataProject.sent
                                            ? Object.keys(CS2DataProject.sent).map(project => {
                                                const tokens = projectTokens[project] || {};
                                                return {
                                                    value: project,
                                                    label: projectLabelMap[project] || project.charAt(0).toUpperCase() + project.slice(1),
                                                    utoken: tokens.utoken || "",  // Adding specific utoken
                                                    ctoken: tokens.ctoken || "",  // Adding specific ctoken
                                                };
                                            })
                                            : [])
                                    ]}
                                    valueDefault={selectedProject.value}
                                    onChange={(selectedOption) => {
                                        setSelectedProject(selectedOption);
                                        getUser(selectedOption)
                                    }}
                                    placeholder="Select Project"
                                    border={false}
                                    shadow={true}
                                    width="200px"
                                />
                            </Form.Group>

                            {selectedProject.value !== "all" && (
                                <Form.Group>
                                    <FormSelect
                                        options={user}
                                        value={selectedUser || { value: "", label: "All User" }}
                                        onChange={(selectedOption) => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder="Select User"
                                        border={false}
                                        shadow={true}
                                        width="200px"
                                    />
                                </Form.Group>
                            )}
                        </>
                    ) : (
                        <>
                            {session.role_id !== '3' && (
                                <Form.Group>
                                    <FormSelect
                                        options={userNonHq}
                                        value={selectedUser || { value: "", label: "All User" }}
                                        onChange={(selectedOption) => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder="Select User"
                                        border={false}
                                        shadow={true}
                                        width="200px"
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}

                    <Form.Group className="ms-auto">
                        <FormDate placeholder="from" className="shadow-sm border-0" value={moment(dateRange.ds, 'DD-MM-YYYY').format('DD/MM/YYYY')} onChange={(date) => onchangeDateRange("ds", date)} />
                    </Form.Group>

                    <Form.Group>
                        <FormDate placeholder="to" className="shadow-sm border-0" value={moment(dateRange.de, 'DD-MM-YYYY').format('DD/MM/YYYY')} onChange={(date) => onchangeDateRange("de", date)} />
                    </Form.Group>

                    <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
                        Apply
                    </Button>

                    <Button variant="" className="op-primary-color text-light" onClick={handlePrint}>
                        Print
                    </Button>
                </Stack>

                {chartLoading ? (
                    <Loader />
                ) : (
                    <div ref={targetRef}>
                        <Stack direction="horizontal" gap={3} className="mb-4">
                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        Total Survey Sent
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2 deal-count" style={{ cursor: "pointer" }} onClick={handleOpenModal2}>
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS2Data.total_survey_sent}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Modal show={showModal2} onHide={() => setShowModal2(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Total Survey Sent</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumn} rowData={modalData2} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        Total Response Received
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2 deal-count" style={{ cursor: "pointer" }} onClick={handleOpenModal3}>
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS2DataRecord.response_received}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Modal show={showModal3} onHide={() => setShowModal3(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Total Response Received</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumnResponse} rowData={modalData3} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </Stack>

                        <Stack direction="horizontal" gap={3}>
                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        Survey Submission Time (Ave Days)
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2">
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS2Data.total_aveSubmissionTime}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        Survey Response Time (Ave Days)
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2">
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS2Data.total_aveResponseTime}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Stack>

                        <Row>
                            <Col sm={chartSubmittedSize} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                Survey Submitted for Each Project
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chartSubmitted') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartDataSubmitted}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {pieChartDataSubmitted.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chartResponseSize} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                Response Received for Each Project
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chartReponse') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartDataResponse}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {pieChartDataResponse.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart1Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                1. How well was the information provided to you in aiding your home purchase?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart1') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={150} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="excellent" fill="#39e75f" stackId="a" name="Excellent" onClick={(data) => handleBarClick(data, 'Excellent')} />
                                                <Bar dataKey="good" fill="#4361ee" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="satisfactory" fill="#663399" stackId="a" name="Satisfactory" onClick={(data) => handleBarClick(data, 'Satisfactory')} />
                                                <Bar dataKey="belowAverage" fill="#ec9706" stackId="a" name="Below Average" onClick={(data) => handleBarClick(data, 'Below Average')} />
                                                <Bar dataKey="poor" fill="#a91b0d" stackId="a" name="Poor" onClick={(data) => handleBarClick(data, 'Poor')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart2Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                2. Were you introduced to other product / project of Gamuda Land by our Sales Personnel?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart2') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData2}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData2.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieYesNoReq[index % COLORSpieYesNoReq.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart3Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                3. Were you briefed on the benefits of being a GL Friends?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart3') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData3}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData3.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieYesNo[index % COLORSpieYesNo.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart4Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                4. How would you rate your experience during the sales process as below?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart4') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData4} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={170} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="excellent" fill="#39e75f" stackId="a" name="Excellent" onClick={(data) => handleBarClick(data, 'Excellent')} />
                                                <Bar dataKey="good" fill="#4361ee" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="satisfactory" fill="#663399" stackId="a" name="Satisfactory" onClick={(data) => handleBarClick(data, 'Satisfactory')} />
                                                <Bar dataKey="belowAverage" fill="#ec9706" stackId="a" name="Below Average" onClick={(data) => handleBarClick(data, 'Below Average')} />
                                                <Bar dataKey="poor" fill="#a91b0d" stackId="a" name="Poor" onClick={(data) => handleBarClick(data, 'Poor')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart5Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                5. How would you rate our Relationship Associates/ Sales Person based on:
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart5') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData5} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={180} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="excellent" fill="#39e75f" stackId="a" name="Excellent" onClick={(data) => handleBarClick(data, 'Excellent')} />
                                                <Bar dataKey="good" fill="#4361ee" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="satisfactory" fill="#663399" stackId="a" name="Satisfactory" onClick={(data) => handleBarClick(data, 'Satisfactory')} />
                                                <Bar dataKey="belowAverage" fill="#ec9706" stackId="a" name="Below Average" onClick={(data) => handleBarClick(data, 'Below Average')} />
                                                <Bar dataKey="poor" fill="#a91b0d" stackId="a" name="Poor" onClick={(data) => handleBarClick(data, 'Poor')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart6Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                6. Would you recommend your Relationship Personnel to attend to your families or friends who are interested to purchase a property?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart6') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData6}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData6.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieYesNo[index % COLORSpieYesNo.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart7Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                7. Would you recommend Gamuda Land properties to your family or friends?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart7') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData7}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData7.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieYesNo[index % COLORSpieYesNo.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart8Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                8. What type of deals will motivate you to purchase?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart8') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData8}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData8.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie8[index % COLORSpie8.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart9Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                9. How would you rank the importance of these elements in a township when you purchase a property. (Please rank in a manner whereby "1" is the most important and "4" is the least important)
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart9') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData9} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={150} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="field1" fill="#39e75f" stackId="a" name="1" onClick={(data) => handleBarClick(data, '1')} />
                                                <Bar dataKey="field2" fill="#4361ee" stackId="a" name="2" onClick={(data) => handleBarClick(data, '2')} />
                                                <Bar dataKey="field3" fill="#82eefd" stackId="a" name="3" onClick={(data) => handleBarClick(data, '3')} />
                                                <Bar dataKey="field4" fill="#ec9706" stackId="a" name="4" onClick={(data) => handleBarClick(data, '4')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart10Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                10. Were you introduced to other product / project of Gamuda Land by our Relationship Associate?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart10') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData10}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData10.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie10[index % COLORSpie10.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart11Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                11. What would be your ideal home in the next 10 years?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart11') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData11}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData11.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieQ11[index % COLORSpieQ11.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart12Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                12. From your visit to our show units, how would you rate these areas?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart12') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData12} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={100} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="not" fill="#39e75f" stackId="a" name="Not preferred" onClick={(data) => handleBarClick(data, 'Not preferred')} />
                                                <Bar dataKey="least" fill="#4361ee" stackId="a" name="Least preferred" onClick={(data) => handleBarClick(data, 'Least preferred')} />
                                                <Bar dataKey="neutral" fill="#663399" stackId="a" name="Neutral" onClick={(data) => handleBarClick(data, 'Neutral')} />
                                                <Bar dataKey="somewhat" fill="#ec9706" stackId="a" name="Somewhat preferred" onClick={(data) => handleBarClick(data, 'Somewhat preferred')} />
                                                <Bar dataKey="most" fill="#708090" stackId="a" name="Most preferred" onClick={(data) => handleBarClick(data, 'Most preferred')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart13Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                13. On a scale of 1-5, please select in order of importance to you on the Interior Architecture/ Design?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart13') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData13} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={150} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="field1" fill="#39e75f" stackId="a" name="1" onClick={(data) => handleBarClick(data, '1')} />
                                                <Bar dataKey="field2" fill="#4361ee" stackId="a" name="2" onClick={(data) => handleBarClick(data, '2')} />
                                                <Bar dataKey="field3" fill="#82eefd" stackId="a" name="3" onClick={(data) => handleBarClick(data, '3')} />
                                                <Bar dataKey="field4" fill="#663399" stackId="a" name="4" onClick={(data) => handleBarClick(data, '4')} />
                                                <Bar dataKey="field5" fill="#ec9706" stackId="a" name="5" onClick={(data) => handleBarClick(data, '5')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart14Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                14. Any additional feedback/suggestions on our show unit?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart14') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData14}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData14.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpieQ11[index % COLORSpieQ11.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Modal show={showModalDetails} onHide={handleCloseModal} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{chartData.title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumnChart} rowData={modalDataDetails} rowHeight={80} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </Row>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CustomerService2;