import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const DealSourceSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [buList, setBuList] = useState([]);
    const [dsCatList, setDsCatList] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const getAllBU = () => {
        
        axios.get('ws/ws_setting.php',{
            params: {
                task: "getAllBU",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var list = data.record.map(record => {
                    return {label: record.unit_title, value: record.business_unit_id}
                });

                setBuList(list);
            }else{
                setBuList([]);
            }
        })
        .catch(error => {
            setBuList([]);
        });
    }

    const getAllTeam = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "getUserTags",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var list = data.record.map(rec => {
                    return {label: rec.user_tag_title, value: rec.user_tag_id}
                });

                setTeamList(list);
            }else{
                setTeamList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingDealSource_headername_1'),
            field: "ds_title",
            resizable: true,
            filter: true,
            width: 250,
        },
        {
            headerName: t('SettingDealSource_headername_2'),
            field: "ds_token",
            resizable: true,
            width: 300
        },
        {
            headerName: t('SettingDealSource_headername_3'),
            field: "call_tracking_number",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingDealSource_headername_4'),
            field: "unit_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingDealSource_headername_5'),
            field: "source_category_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingDealSource_headername_6'),
            field: "user_tag_title",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingDealSource_headername_7'),
            field: "tag_token",
            resizable: true,
            width: 300
        },
        {
            headerName: t('SettingDealSource_headername_8'),
            resizable:  true,
            width: 150,
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    var showMobile = params.data.show_mobile;
                    return (
                        <Form.Check
                            type="switch"
                            className="d-flex align-items-center justify-content-center"
                            checked={Number(showMobile) === 1 ? true:false}
                            onChange={() => {handleShowMobile(params.data.ds_id, showMobile)}}
                        />
                    );
                }
            }
        },
        {
            headerName: t('SettingDealSource_headername_9'),
            resizable: true,
            width: 80,
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    var isCheck = params.data.ds_display;
                    return (
                        <div className="d-flex align-items-center justify-content-center"> 
                            <Form.Check
                                type="switch"
                                className="d-flex align-items-center justify-content-center"
                                checked={isCheck === "show" ? true:false}
                                onChange={() => {markAsActive(params.data.ds_id, isCheck)}}
                            />
                        </div>
                    );
                }
            }
        },
        {
            headerName: t('SettingDealSource_headername_10'),
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.ds_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {

        axios.get('ws/ws_source.php',{
            params:{
                task: 2,
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {

                var list = data.source_cat.map(rec => {
                    return {label: rec.source_category_title, value: rec.source_category_id};
                });

                setDsCatList(list);

                // const filteredRows = searchVal ? data.record.filter((row) => row.ds_title.toLowerCase().includes(searchVal.toLowerCase())) : data.record;
                const filteredRows = data.record;

                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
                setIsLoading(false);
            }else{

                setDsCatList([]);

                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {

            setDsCatList([]);

            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });
    }

    const handleShowMobile = (id, checked) => {
        axios.get('ws/ws_source.php', {
            params: {
                task: 'UpdateShowMobile',
                show_mobile: checked,
                ds_id: id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            console.log(error.message);
        });
    }

    const markAsActive = (id, checked) =>{
        var ds_display = checked === "show" ? "hide" : "show";
        axios.get('ws/ws_source.php',{
            params:{
                task: 6,
                dsid: id,
                mode: ds_display
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingDealSource_alert_failedTitle'),
                    text: t('SettingDealSource_alert_failedText')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllBU();
            getAllTeam();
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        source_name: yup.string().required(t('SettingDealSource_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_source.php',{
            params:{
                task: 1,
                company: session.company_id,
                title: values.source_name
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingDealSource_alert_successTitle'),
                    text: t('SettingDealSource_alert_successText'),
                    timer: 1500
                }).then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingDealSource_alert_failedTitle'),
                    text: t('SettingDealSource_alert_failedText')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('SettingDealSource_alert_delete_title'),
            text: t('SettingDealSource_alert_delete_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingDealSource_alert_delete_confirmText')
        }).then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_source.php',{
                    params:{
                        task: 4,
                        source: id
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingDealSource_alert_successTitle'),
                            text: t('SettingDealSource_alert_success_deleteTitle'),
                            timer: 1500
                        }).then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingDealSource_alert_failedTitle'),
                            text: t('SettingDealSource_alert_failedText')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleEdit = (data) => {
        setEditInitVal(
            {   
                ds_id: data.ds_id,
                ds_title: data.ds_title,
                ds_token: data.ds_token,
                call_tracking: data.call_tracking_number,
                ad_budget: data.total_budget,
                bu_id: Number(data.business_unit_id) === 0 ? '' : data.business_unit_id,
                ds_category: Number(data.source_category_id) === 0 ? '' : data.source_category_id,
                team_id: Number(data.user_tag_id) === 0 ? '' : data.user_tag_id 
            }
        );

        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        ds_title: yup.string().required(t('SettingDealSource_required_field')),
        bu_id: yup.string().required(t('SettingDealSource_required_field')),
        ds_category: yup.string().required(t('SettingDealSource_required_field')),
        team_id: yup.string().required(t('SettingDealSource_required_field'))
    });

    const sendEdit = (values) => {
        
        axios.get('ws/ws_source.php',{
            params: {
                task: 3,
                title: values.ds_title,
                call_tracking_number: values.call_tracking,
                total_budget: values.ad_budget,
                source: values.ds_id,
                sourcebu: values.bu_id,
                sourcecat: values.ds_category,
                usertag: values.team_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingDealSource_alert_successTitle'),
                    text: t('SettingDealSource_alert_success_editSuccess'),
                    timer: 1500
                }).then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else if(Number(data.status) === 2){
                Swal.fire({
                    icon: "warning",
                    title: t('SettingDealSource_alert_failedTitle'),
                    text: data.message
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingDealSource_alert_failedTitle'),
                    text: t('SettingDealSource_alert_failedText')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('ds_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingDealSource_add_deal_source')}</button>
                            <Form.Group style={{width: "300px"}}>

                                <InputGroup>
                                    <Form.Control
                                        placeholder={t('SettingDealSource_searchFd_placeholder')}
                                        type="text"
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon"/>
                                    </InputGroup.Text>
                                </InputGroup>

                            </Form.Group>
                            <div className="px-2 ms-auto">Result: {recordFound === 1 ? t('SettingDealSource_record_one', {count: recordFound}): recordFound > 1 ? t('SettingDealSource_record_many', {count: recordFound}) : t('SettingDealSource_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                enableCellTextSelection={true}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    source_name: ""
                                }
                            }
                        >

                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingDealSource_modalHeader_add')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingDealSource_modalBody_add_label1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.source_name && touched.source_name}
                                                onChange={handleChange('source_name')}
                                            />
                                            {errors.source_name && touched.source_name && <div className="op-error-message">{errors.source_name}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingDealSource_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}

                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingDealSource_modalHeader_edit')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.ds_title}
                                                isInvalid={errors.ds_title && touched.ds_title}
                                                onChange={handleChange('ds_title')}
                                            />
                                            {errors.ds_title && touched.ds_title && <div className="op-error-message">{errors.ds_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label2')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.ds_token}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label3')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.call_tracking}
                                                onChange={handleChange("call_tracking")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label4')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.ad_budget}
                                                onChange={handleChange('ad_budget')}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label5')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingDealSource_dropdown_placeholder_bu')}
                                                options={buList}
                                                valueDefault={buList.filter(option => option.value === values.bu_id)}
                                                isInvalid={errors.bu_id && touched.bu_id}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => {handleChange('bu_id')(e ? e.value:"")}}
                                            />
                                            {errors.bu_id && touched.bu_id && <div className="op-error-message">{errors.bu_id}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label6')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingDealSource_dropdown_placeholder_dsCat')}
                                                options={dsCatList}
                                                valueDefault={dsCatList.filter(option => option.value === values.ds_category)}
                                                isInvalid={errors.ds_category && touched.ds_category}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => {handleChange("ds_category")(e ? e.value:"")}}
                                            />
                                            {errors.ds_category && touched.ds_category && <div className="op-error-message">{errors.ds_category}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingDealSource_modalBody_edit_label7')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingDealSource_dropdown_placeholder_team')}
                                                options={teamList}
                                                valueDefault={teamList.filter(option => option.value === values.team_id)}
                                                isInvalid={errors.team_id && touched.team_id}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => {handleChange('team_id')(e ? e.value:"")}}
                                            />
                                            {errors.team_id && touched.team_id && <div className="op-error-message">{errors.team_id}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-buton op-primary-color text-light">{t('SettingDealSource_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default DealSourceSetting;