import { useState, useEffect, useMemo, useRef} from "react";
import { Stack, Form, InputGroup } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import "../lib/scss/op-report-style.scss";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
import {FormSelect } from "../includes/FormCustom";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";

const DuplicateLeadReport = () =>{

    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();

    const [startDate, setStartDate] = useState(moment(new Date(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`)).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [userID, setUserID] = useState("");
    const [dsID, setDsID] = useState("");
    const [searchVal, setSearchVal] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [trigger, setTrigger] = useState("");
    const [isReport, setIsReport] = useState(false);
    const [recordFound, setRecordFound] = useState("");

    const [userList, setUserList] = useState([]);
    const [dsList, setDsList] = useState([]);

    const { t } = useTranslation();

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const getAllUser = () =>{
        
        axios.get("ws/ws_user.php",{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res =>{
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((data)=>{
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const getAllDSList = () =>{

        axios.get("ws/ws_source.php",{
            params:{
                task: 2,
                company: session.company_id
            }
        })
        .then(res =>{
            let data = res.data;

            if (Number(data.status) === 0) {
                var dsData = data.record.map((data)=>{
                    return {label: data.ds_title, value: data.ds_id};
                });

                setDsList(dsData);
            }else{
                setDsList([]);
            }
        });
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            getAllDSList();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const reportColumn = [
        {
            headerName: t('Report_DuplicateLead_HeaderName1'),
            field: "date_time_create",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    return params.data.date_time_create;
                }else{
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            }
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName2'),
            field: "prospect_name",
            resizable: true,
            width: 200,
            onCellClicked: function (params) {
                if (params.data) {
                    window.open("/prospect/"+params.data.prospect_id, "_blank");
                }
            }
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName3'),
            field: "user_name",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName4'),
            field: "ds_title",
            resizable: true,
            width: "300"
        },
        {
            headerName : t('Report_DuplicateLead_HeaderName5'),
            field: "ori_ds_title",
            resizable: true,
            width: "300"
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName6'),
            field: "new_deal_created",
            resizable: true,
            width: 200,
            valueGetter: (params) => {
                if (params.data) {
                    if (Number(params.data.new_deal_created) === 0) {
                        return "No";
                    }else if (Number(params.data.new_deal_created) === 1) {
                        return "Yes";
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (Number(params.data.new_deal_created) === 0) {
                        return "No";
                    }else if (Number(params.data.new_deal_created) === 1) {
                        return "Yes";
                    }
                }
            }
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName7'), 
            field: "name",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName8'), 
            field: "contact",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName9'), 
            field: "email",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_DuplicateLead_HeaderName10'), 
            field: "content",
            resizable: true,
            width: 200
        }
    ];

    const reportDataSource = useMemo(()=>{
        return {
            rowCount: undefined,
            getRows: (params)=>{
                
                axios.get("ws/ws_duplicate_lead_report.php",{
                    params:{
                        task: "getDuplicateLeads",
                        ds: startDate,
                        de: endDate,
                        user: userID,
                        prospectname: searchVal,
                        source: dsID,
                        startRow: params.startRow,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res =>{
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        if (Number(data.totalRecord) > 0) {
                            setIsReport(true);
                        }else{
                            setIsReport(false);
                        }
                    }else{
                        setIsReport(false);
                        Swal.fire({
                            icon: 'error',
                            title: t('Report_alert_Title'),
                            text: t('Report_alert_Text')
                        });
                    }

                    setTimeout(() => {
                        var dealInfo = [];
                        var totalRecord = 0;
                        if (Number(data.status) === 0) {
                            dealInfo = data.record;
                            totalRecord = Number(data.totalRecord);
                            setRecordFound(totalRecord);
                        } else {
                            dealInfo = [];
                            totalRecord = 0;
                            setRecordFound(totalRecord);
                        }

                        var rowsThisPage = dealInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);
                });
            }
        }
    },[trigger,session]); // eslint-disable-line react-hooks/exhaustive-deps

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const handleDownload = () =>{

        var params = {
            fileName : 'duplicate-lead-report.csv',
            processCellCallback : function(params) {

                if(params.column.colId === 'auto_increment_num'){
                    return params.node.data.temprecord_id;
                }else if(params.column.colId === 'deal_title'){
                    return params.node.data.deal_title;
                }else{
                    var res = params.value;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return res;
                }

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <div>
            {isLoading ? (
                <Loader/>
            ):(
                <div className="report-main">
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t('Report_FilterCriteria')}</div>
                        </section>
                        <section className="content">
                            <Form.Group className="mb-3">
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Report_DuplicateLead_SearchByProspectName')}
                                        onChange={(e) => {setSearchVal(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass}/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="date" value={startDate} onChange={(e)=>{setStartDate(moment(new Date(e.target.value)).format("YYYY-MM-DD"))}}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="date" value={endDate} onChange={(e)=>{setEndDate(moment(new Date(e.target.value)).format("YYYY-MM-DD"))}}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_AllUser')} 
                                    options={userList}
                                    isClearable={true} 
                                    isSearchable={true}
                                    onChange={(e)=>{e !== null ? setUserID(e.value):setUserID("")}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_AllDS')} 
                                    options={dsList}
                                    isClearable={true} 
                                    isSearchable={true}
                                    onChange={(e)=>{e !== null ? setDsID(e.value):setDsID("")}}
                                />
                            </Form.Group>
                        </section>
                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={() => {
                                    setTrigger(trigger + 1);
                                    }}
                                >
                                    {t('Report_ViewReport')}
                                </button>
                                <button className="btn btn-dark" onClick={handleDownload}>{t('Report_Download')}</button>
                            </div>
                        </section>
                    </div>
                    <div className="report-content">
                        <section className="w-100">
                            {isReport && <Stack direction="horizontal">
                                <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                            </Stack>}
                        </section>
                        <section className="w-100">
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        datasource={reportDataSource}
                                        defaultColDef={reportColDef}
                                        rowSelection={"multiple"}
                                        rowModelType={"infinite"}
                                        rowHeight={70}
                                        cacheBlockSize={30}
                                        cacheOverflowSize={2}
                                        maxConcurrentDatasourceRequests={1}
                                        infiniteInitialRowCount={1}
                                        maxBlocksInCache={50}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                        suppressRowClickSelection={true}
                                        animateRows={true}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DuplicateLeadReport;