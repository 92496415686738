import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Modal, Image } from "react-bootstrap";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import { FilePond, registerPlugin } from "react-filepond";

const RedemptionCategory = () => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [isEditCategory, setIsEditCategory] = useState(false);

  // SCHEMA FUNCTION ====================================================

  const newCategorySchema = yup.object().shape({
    category_name: yup.string().required("This field is required"),
    form_title: yup.string().required("This field is required"),
    birthmonth_template_id: yup.string().required("This field is required"),
    reminder_template_id: yup.string().required("This field is required"),
  });

  const editCategorySchema = yup.object().shape({
    category_name: yup.string().required("This field is required"),
    form_title: yup.string().required("This field is required"),
    form_desc: yup.string().required("This field is required"),
    birthmonth_template_id: yup.string().required("This field is required"),
    reminder_template_id: yup.string().required("This field is required"),
    banner_image: yup.array().min(1, "This field is required").required("This field is required"),
  });

  // GET FUNCTION =======================================================

  const getCategory = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "CategoryList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.forEach(async (record) => {
          if (record.banner_image) {
            record.banner_base64 = await getBanner(record.banner_image);
          }
        });
        setCategoryList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBanner = async (record) => {
    try {
      const url = record.split(".");
      const urlType = url[url.length - 1];

      const response = await axios.get(`https://www.nexcrmapis.com/cloud_staging/ext/glRedemption/api_get_file_content.php`, {
        params: {
          filepath: `../../../cloud_dev/ext/glRedemption/images/${record}`,
        },
      });

      const data = response.data;
      if (data && data.content) {
        const base64Mode = `data:image/${urlType};base64,`;
        return base64Mode + data.content;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ======================================================

  const sendActivation = async (category) => {
    try {
      Swal.fire({
        icon: "question",
        text: `Are you sure you want to activate '${category.category_name}' as the active category this month?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.get("ext/glRedemption/api_crud_category.php", {
            params: {
              task: "ActivationCategory",
              category_id: category.category_id,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          });

          const data = response.data;
          if (data.status === 0) {
            getCategory();
            Swal.fire({
              icon: "success",
              text: `Successfully mark '${category.category_name}' as active`,
              timer: 2000,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: `Something wrong with your entry, please try again or contact our support IT`,
            });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sendNewCategory = async (values) => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "NewCategory",
          category_name: values.category_name,
          enable_location: 1,
          form_title: values.form_title,
          form_desc: values.form_desc,
          birthmonth_template_id: values.birthmonth_template_id,
          reminder_template_id: values.reminder_template_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getCategory();
        setIsNewCategory(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created a new category",
          timer: 2000,
        });
      } else {
        setIsNewCategory(false);
        Swal.fire({
          icon: "success",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditCategory = async (values) => {
    try {
      var formData = new FormData();
      formData.append("category_id", categoryData.category_id);
      formData.append("category_name", values.category_name);
      formData.append("enable_location", 1);
      formData.append("form_title", values.form_title);
      formData.append("form_desc", values.form_desc);
      formData.append("birthmonth_template_id", values.birthmonth_template_id);
      formData.append("reminder_template_id", values.reminder_template_id);
      formData.append("task", "EditCategory");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);

      if (values.banner_image.length > 0 && values.banner_status === 0) {
        var regex = /[^\\]*\.(\w+)$/;
        var filename = values.banner_image[0].name.match(regex);
        var reader = new FileReader();
        reader.onload = async (event) => {
          var base64Image = event.target.result.split(",")[1];
          filename = filename[0].split(".");
          filename = filename[0].replace(/ /g, "_");
          formData.append("banner_image", base64Image);
          formData.append("banner_mime", values.banner_image[0].type);
          formData.append("banner_name", filename);

          const response = await axios.post("ext/glRedemption/api_crud_category.php", formData);
          const data = response.data;
          if (data.status === 0) {
            setIsEditCategory(false);
            getCategory();
            Swal.fire({
              icon: "success",
              text: "Successfully updated the category",
              timer: 2000,
            });
          } else {
            setIsEditCategory(false);
            Swal.fire({
              icon: "error",
              text: "Something wrong with your entry, please try again or contact our support IT",
            });
          }
        };
        reader.readAsDataURL(values.banner_image[0]);
      } else {
        const response = await axios.post("ext/glRedemption/api_crud_category.php", formData);
        const data = response.data;
        if (data.status === 0) {
          setIsEditCategory(false);
          getCategory();
          Swal.fire({
            icon: "success",
            text: "Successfully updated the category",
            timer: 2000,
          });
        } else {
          setIsEditCategory(false);
          Swal.fire({
            icon: "error",
            text: "Something wrong with your entry, please try again or contact our support IT",
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // AG GRID ============================================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      width: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Form Banner",
      cellClass: "center",
      headerClass: "center",
      field: "banner_image",
      autoHeight: true,
      cellRenderer: (params) => {
        if (params.data.banner_image) {
          var imageUrl = `${session.hostUrlApi}/cloud_dev/ext/glRedemption/images/${params.data.banner_image}`;
          return <Image src={imageUrl} width={150} height="auto" />;
        }

        return "No Image";
      },
    },
    {
      headerName: "Category Name",
      field: "category_name",
    },
    {
      headerName: "Form Title",
      field: "form_title",
    },
    {
      headerName: "Form Description",
      field: "form_desc",
    },
    {
      headerName: "Birth Month Email Template ID (MTARGET)",
      field: "birthmonth_template_id",
    },
    {
      headerName: "Reminder Email Template ID (MTARGET)",
      field: "reminder_template_id",
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      width: 150,
      cellRenderer: (params) => {
        return (
          <div className="d-flex flex-row-reverse w-100">
            <button
              className="op-label bg-primary text-light"
              onClick={() => {
                setCategoryData(params.data);
                setIsEditCategory(true);
              }}
            >
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </button>
            {Number(params.data.inactive) === 0 ? (
              <button className="op-label bg-success text-light" disabled={true}>
                Active
              </button>
            ) : (
              <button className="op-label bg-danger text-light" onClick={() => sendActivation(params.data)}>
                Inactive
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.category_id.toString();
  }, []);

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getCategory();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12} className="mb-3">
              <Stack direction="horizontal" gap={2}>
                <Button variant="" className="btn op-primary-color text-light" onClick={setIsNewCategory}>
                  New Category
                </Button>
                <p className="ms-auto">{categoryList.length === 0 ? "No record" : categoryList.length === 1 ? "1 record" : `${categoryList.length} records`}</p>
              </Stack>
            </Col>
            <Col xxl={12}>
              {categoryList.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact ref={gridRef} rowSelection="multiple" rowData={categoryList} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={100} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={"No record for the moment"} description="" width={300} height={250} mt={false} />
              )}
            </Col>
          </Row>
        </div>
      )}

      <Modal show={isNewCategory} onHide={setIsNewCategory} size="lg">
        <Formik
          validationSchema={newCategorySchema}
          onSubmit={sendNewCategory}
          initialValues={{
            category_name: "",
            form_title: "",
            form_desc: "",
            birthmonth_template_id: "",
            reminder_template_id: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control name="category_name" type="text" value={values.category_name} isInvalid={errors.category_name && touched.category_name} onChange={handleChange("category_name")} />
                  {errors.category_name && touched.category_name && <div className="op-error-message">{errors.category_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Form Title</Form.Label>
                  <Form.Control name="form_title" type="text" value={values.form_title} isInvalid={errors.form_title && touched.form_title} onChange={handleChange("form_title")} />
                  {errors.form_title && touched.form_title && <div className="op-error-message">{errors.form_title}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Form Description</Form.Label>
                  <Form.Control name="form_desc" type="text" value={values.form_desc} onChange={handleChange("form_desc")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Birth Month Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="birthmonth_template_id" type="text" value={values.birthmonth_template_id} isInvalid={errors.birthmonth_template_id && touched.birthmonth_template_id} onChange={handleChange("birthmonth_template_id")} />
                  {errors.birthmonth_template_id && touched.birthmonth_template_id && <div className="op-error-message">{errors.birthmonth_template_id}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Reminder Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="reminder_template_id" type="text" value={values.reminder_template_id} isInvalid={errors.reminder_template_id && touched.reminder_template_id} onChange={handleChange("reminder_template_id")} />
                  {errors.reminder_template_id && touched.reminder_template_id && <div className="op-error-message">{errors.reminder_template_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEditCategory} onHide={setIsEditCategory} size="lg">
        <Formik
          validationSchema={editCategorySchema}
          onSubmit={sendEditCategory}
          initialValues={{
            category_name: categoryData.category_name || "",
            form_title: categoryData.form_title || "",
            form_desc: categoryData.form_desc || "",
            birthmonth_template_id: categoryData.birthmonth_template_id || "",
            reminder_template_id: categoryData.reminder_template_id || "",
            banner_image: categoryData.banner_image ? [{ source: categoryData.banner_base64 }] : [],
            banner_status: categoryData.banner_image ? 1 : 0,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit Category</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control name="category_name" type="text" value={values.category_name} isInvalid={errors.category_name && touched.category_name} onChange={handleChange("category_name")} />
                  {errors.category_name && touched.category_name && <div className="op-error-message">{errors.category_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Form Title</Form.Label>
                  <Form.Control name="form_title" type="text" value={values.form_title} isInvalid={errors.form_title && touched.form_title} onChange={handleChange("form_title")} />
                  {errors.form_title && touched.form_title && <div className="op-error-message">{errors.form_title}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Form Description</Form.Label>
                  <Form.Control name="form_desc" type="text" value={values.form_desc} onChange={handleChange("form_desc")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Birth Month Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="birthmonth_template_id" type="text" value={values.birthmonth_template_id} isInvalid={errors.birthmonth_template_id && touched.birthmonth_template_id} onChange={handleChange("birthmonth_template_id")} />
                  {errors.birthmonth_template_id && touched.birthmonth_template_id && <div className="op-error-message">{errors.birthmonth_template_id}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Reminder Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="reminder_template_id" type="text" value={values.reminder_template_id} isInvalid={errors.reminder_template_id && touched.reminder_template_id} onChange={handleChange("reminder_template_id")} />
                  {errors.reminder_template_id && touched.reminder_template_id && <div className="op-error-message">{errors.reminder_template_id}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Banner Image</Form.Label>
                  <FilePond
                    allowMultiple={false}
                    allowProcess={false}
                    allowDrop={false}
                    allowRevert={false}
                    name={`banner_image`}
                    maxFiles={1}
                    files={values.banner_image}
                    credits={false}
                    instantUpload={false}
                    onremovefile={() => {
                      setFieldValue("banner_image", []);
                      setFieldValue("banner_status", 0);
                    }}
                    onupdatefiles={(fileItems) => {
                      setFieldValue("banner_status", 0);
                      setFieldValue(
                        "banner_image",
                        fileItems.map((fileItem) => fileItem.file)
                      );
                    }}
                  />
                  {errors.banner_image && touched.banner_image && <div className="op-error-message">{errors.banner_image}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default RedemptionCategory;
