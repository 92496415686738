import { useState, useRef, useEffect, useMemo } from "react";
import { Modal, Form, Stack, InputGroup, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
// import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { faBox, faUpload } from "@fortawesome/pro-light-svg-icons";
// import { useTranslation } from "react-i18next";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";

const SettingProductItem = ({ id }) => {
    const { session } = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    const [loading, setLoading] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [recordFound, setRecordFound] = useState(0);

    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [initVal, setInitVal] = useState({});

    const { Formik } = formik;

    const containerStyle = useMemo(() => ({ width: "100%", height: "78vh", paddingTop: 10 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridColDef = useMemo(() => {
        return {
        resizable: true,
        sortable: false,
        };
    }, []);

    const [gridColumn] = useState([
        {
            headerName: 'Image',
            width: 200,
            headerClass: 'center',
            cellClass: 'center',
            autoHeight: true,
            cellRenderer: (params) => {
                if (params.data) {
                    
                    if (params.data.product_image) {
                        return(
                            <div>
                                <img src={`${session.hostUrl}/${session.hostUrlType}/assets/product_image/${params.data.product_image}`} width={'80px'}/>
                            </div>
                        );
                    } else {
                        return(
                            <div>
                                <FontAwesomeIcon icon={faBox} size="3x"/>
                            </div>
                        );
                    }
                }
            }
        },
        {
            headerName: 'SKU Code',
            field: 'sku_code',
            autoHeight: true,
            wrapText: true,
            width: 200
        },
        {
            headerName: 'Title',
            field: 'product_title',
            filter: true,
            autoHeight: true,
            wrapText: true,
            flex: 1,
            minWidth: 300
        },
        {
            headerName: 'Description',
            field: 'product_description',
            autoHeight: true,
            wrapText: true,
            flex: 1,
            minWidth: 300
        },
        {
            headerName: 'Quantity',
            field: 'total_qty',
            headerClass: 'center',
            cellClass: 'center',
            autoHeight: true,
            wrapText: true,
            pinned: 'right',
            width: 120
        },
        {
            headerName: 'Current',
            field: 'curr_qty',
            headerClass: 'center',
            cellClass: 'center',
            autoHeight: true,
            wrapText: true,
            pinned: 'right',
            width: 120
        },
        {
            headerName: 'Price (RM)',
            field: 'price',
            headerClass: 'center',
            cellClass: 'center',
            autoHeight: true,
            wrapText: true,
            pinned: 'right',
            width: 120
        },
        {
            headerName: 'Action',
            width: 100,
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon me-2" onClick={() => {handleDelete(params.data)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        axios.get("ws/ws_product.php", {
            params: {
                task: 'getProducts',
                cat_id: id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setRowData([]);
            setRecordFound(0);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (e) => {
        const searchText = e.target.value;
        const filterInstance = gridRef.current.api.getFilterInstance('product_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    const addSchema = yup.object().shape({
        product_title: yup.string().required('This field is required'),
        warranty_dur: yup.number().typeError('Must be a number type').notRequired(),
        total_qty: yup.number().typeError('Must be a number type').notRequired(),
        current_qty: yup.number().typeError('Must be a number type').notRequired(),
        price: yup.number().typeError('Must be a number type').required('This field is required'),
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_product.php', {
            params: {
                task: "addProduct",
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                sku_code: values.sku_code,
                product_title : values.product_title,
                product_image : "",
                product_description: values.product_desc,
                waranty_code : values.warranty_code,
                waranty_duration_days: values.warranty_dur,
                total_qty: values.total_qty,
                curr_qty: values.current_qty,
                booking_status: "",
                condo_floor: "",
                price: values.price,
                product_cat_id: id,
                brand_id: ""
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully added',
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    const handleEdit = (data) => {

        var image = new Image();
        var url_image = `${session.hostUrl}/${session.hostUrlType}/assets/product_image/${data.product_image}`;
        image.src = url_image;

        var isImage = true;
        
        if (image.width == 0) {
            isImage = false;
        } else {
            isImage = true;
        }

        var initData = {
            sku_code: data.sku_code,
            product_id: data.product_id,
            product_title: data.product_title,
            product_desc: data.product_description,
            warranty_code: data.warranty_code,
            warranty_dur: data.waranty_duration_days,
            total_qty: data.total_qty,
            current_qty: data.curr_qty,
            price: data.price,
            product_img_src: isImage ? data.product_image : '',
            product_image: '',
            product_image_name: isImage ? data.product_image : '',
            changeImg: isImage ? false : true,
            disableSubmit: false
        };

        setInitVal(initData);
        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        product_title: yup.string().required('This field is required'),
        warranty_dur: yup.number().typeError('Must be number type').notRequired(),
        total_qty: yup.number().typeError('Must be number type').notRequired(),
        current_qty: yup.number().typeError('Must be number type').notRequired(),
        price: yup.number().typeError('Must be number type').required('This field is required')
    });

    const sendEdit = (values) => {
        
        axios.get('ws/ws_product.php', {
            params: {
                task: "updateProduct",
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                product_id: values.product_id,
                sku_code: values.sku_code,
                product_title : values.product_title,
                product_image : values.product_image_name,
                product_description: values.product_desc,
                waranty_code : values.warranty_code,
                waranty_duration_days: values.warranty_dur,
                total_qty: values.total_qty,
                curr_qty: values.current_qty,
                booking_status: "",
                condo_floor: "",
                price: values.price,
                product_cat_id: id,
                brand_id: ""
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully updated',
                    timer: 1500
                })
                .then(() => {
                    getGridData();
                    setIsEdit(false);
                    setLoading(true);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again contact your IT Support'
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })
        });
    }

    const handleDelete = (data) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'This producet will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel'
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get("ws/ws_product.php", {
                    params: {
                        task: "deleteProduct",
                        product_id: data.product_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Successfully deleted',
                            timer: 1500
                        })
                        .then(() => {
                            getGridData();
                        });
                    }else if(Number(data.status) === 2){
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'This product cant be deleted because it is being used in the system. Please contact Outperform Support Center'
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Please try again or contact your IT Support'
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: error.message
                    });
                });
            }
        });
    }

    return(
        <div>
            {loading ? <Loader/> : 
                <div className="py-3 px-3">
                    <Stack direction="horizontal" gap={2} className="mb-3">
                        <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>Add Product</button>
                        <Form.Group style={{width: '280px'}}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by title"
                                    onChange={handleSearch}
                                />
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" className="fontAwesomeIcon"/>
                                </InputGroup.Text>       
                            </InputGroup>
                        </Form.Group>
                        <div className="ms-auto">
                            {recordFound === 1 ? `${recordFound} record found.` : recordFound > 1 ? `${recordFound} records found.` : `No record found.`}
                        </div>
                    </Stack>

                    <div style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact 
                                ref={gridRef}
                                columnDefs={gridColumn} 
                                rowData={rowData} 
                                defaultColDef={gridColDef} 
                                rowHeight={80} 
                                pagination={false} 
                            />
                        </div>
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    sku_code: '',
                                    product_title: '',
                                    product_desc: '',
                                    warranty_code: '',
                                    warranty_dur: '',
                                    total_qty: '',
                                    current_qty: '',
                                    price: ''
                                }
                            }
                        >
                            {({ handleSubmit, setFieldValue, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={'h6'}>Add Product</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>SKU Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e) => {setFieldValue('sku_code', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Product Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.product_title && touched.product_title}
                                                onChange={(e) => {setFieldValue('product_title', e.target.value)}}
                                            />
                                            {errors.product_title && touched.product_title && <div className="op-error-message">{errors.product_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Product Description</Form.Label>
                                            <Form.Control
                                                as={'textarea'}
                                                rows={5}
                                                onChange={(e) => {setFieldValue('product_desc', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Warranty Code</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={(e) => {setFieldValue('warranty_code', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Warranty duration days</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.warranty_dur && touched.warranty_dur}
                                                onChange={(e) => {setFieldValue('warranty_dur', e.target.value)}}
                                            />
                                            {errors.warranty_dur && touched.warranty_dur && <div className="op-error-message">{errors.warranty_dur}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Total Quantity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.total_qty && touched.total_qty}
                                                onChange={(e) => {setFieldValue('total_qty', e.target.value)}}
                                            />
                                            {errors.total_qty && touched.total_qty && <div className="op-error-message">{errors.total_qty}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Current Quantity</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.current_qty && touched.current_qty}
                                                onChange={(e) => {setFieldValue('current_qty', e.target.value)}}
                                            />
                                            {errors.current_qty && touched.current_qty && <div className="op-error-message">{errors.current_qty}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Price</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.price && touched.price}
                                                onChange={(e) => {setFieldValue('price', e.target.value)}}
                                            />
                                            {errors.price && touched.price && <div className="op-error-message">{errors.price}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={initVal}
                        >
                            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={'h6'}>Edit Product</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row sm={12}>
                                            <Col sm={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>SKU Code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.sku_code}
                                                        onChange={(e) => {setFieldValue('sku_code', e.target.value)}}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Product Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.product_title}
                                                        isInvalid={errors.product_title && touched.product_title}
                                                        onChange={(e) => {setFieldValue('product_title', e.target.value)}}
                                                    />
                                                    {errors.product_title && touched.product_title && <div className="op-error-message">{errors.product_title}</div>}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Product Description</Form.Label>
                                                    <Form.Control
                                                        as={'textarea'}
                                                        rows={5}
                                                        value={values.product_desc}
                                                        onChange={(e) => {setFieldValue('product_desc', e.target.value)}}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Warranty Code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.warranty_code}
                                                        onChange={(e) => {setFieldValue('warranty_code', e.target.value)}}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Warranty Duration Days</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.warranty_dur}
                                                        isInvalid={errors.warranty_dur && touched.warranty_dur}
                                                        onChange={(e) => {setFieldValue('warranty_dur', e.target.value)}}
                                                    />
                                                    {errors.warranty_dur && touched.warranty_dur && <div className="op-error-message">{errors.warranty_dur}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col sm={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Total Quantity</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.total_qty}
                                                        isInvalid={errors.total_qty && touched.total_qty}
                                                        onChange={(e) => {setFieldValue('total_qty', e.target.value)}}
                                                    />
                                                    {errors.total_qty && touched.total_qty && <div className="op-error-message">{errors.total_qty}</div>}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Current Quantity</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.current_qty}
                                                        isInvalid={errors.current_qty && touched.current_qty}
                                                        onChange={(e) => {setFieldValue('current_qty', e.target.value)}}
                                                    />
                                                    {errors.current_qty && touched.current_qty && <div className="op-error-message">{errors.current_qty}</div>}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Price (RM)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.price}
                                                        isInvalid={errors.price && touched.price}
                                                        onChange={(e) => {setFieldValue('price', e.target.value)}}
                                                    />
                                                    {errors.price && touched.price && <div className="op-error-message">{errors.price}</div>}
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Product image</Form.Label>
                                                    {values.product_img_src && !values.changeImg &&
                                                        <div className="border rounded border-light shadow-sm bg-body d-flex justify-content-center align-items-center my-2">
                                                            <img src={`${session.hostUrl}/${session.hostUrlType}/assets/product_image/${values.product_img_src}`} width={'80%'}/>
                                                        </div>
                                                    }

                                                    {
                                                        values.changeImg &&
                                                        <FilePond
                                                            allowMultiple={false}
                                                            maxFiles={1}
                                                            name="file"
                                                            files={values.product_image}
                                                            credits={false}
                                                            instantUpload={false}
                                                            acceptedFileTypes={["image/jpg", "image/png", "image/jpeg"]}
                                                            onupdatefiles={(fileItems) => {
                                                                if (fileItems && fileItems.length) {
                                                                    if (fileItems[0].file.type === "image/jpg" || fileItems[0].file.type === "image/jpeg" || fileItems[0].file.type === "image/png") {
                                                                        setFieldValue(
                                                                            "product_image",
                                                                            fileItems.map((fileItem) => fileItem.file)
                                                                        );
                                                                        setFieldValue('disableSubmit', true);
                                                                    }
                                                                }
                                                            }}
                                                            onremovefile={() => {
                                                                setFieldValue(
                                                                    "product_image",
                                                                    null
                                                                );
                                                                setFieldValue('disableSubmit', false);
                                                            }}
                                                            server={{
                                                                process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                                  const formData = new FormData();
                                                                  const reader = new FileReader();
                                                                var filename = file.name.split('.');
                                                                  formData.append("task", "UploadImage");
                                                                  formData.append("old_image", values.product_img_src);
                                                                  formData.append("product_image", filename[0]);
                    
                                                                  reader.onload = (e) => {
                                                                    formData.append("data", e.target.result);
                                                                    axios.post(`${session.hostUrl}/${session.hostUrlType}/php/product_image_media.php`, formData).then((response) => {
                                                                      const data = response.data;
                                                                      if (Number(data.status) === 0) {
                                                                        load(data);
                                                                        setFieldValue("product_image_name", data.file_name);
                                                                        setFieldValue('disableSubmit', false);
                                                                      }
                                                                    });
                                                                  };
                                                                  reader.readAsDataURL(file);
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    <Stack direction="horizontal" gap={2}>
                                                        {!values.changeImg &&
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-primary ms-auto" 
                                                                onClick={() => {
                                                                    setFieldValue('changeImg', true);
                                                                    setFieldValue("product_image", null);
                                                                    setFieldValue('disableSubmit', true);
                                                                }}
                                                                >
                                                                Change
                                                            </button>
                                                        }
                                                        {values.changeImg &&
                                                            <>
                                                                {values.product_img_src && <button type="button" className="btn btn-danger ms-auto" onClick={() => {setFieldValue('product_image', values.product_img_src); setFieldValue('changeImg', false); setFieldValue('disableSubmit', false);}}>
                                                                    Cancel
                                                                </button>}
                                                            </>
                                                        }

                                                    </Stack>
                                                </Form.Group>
                                                <div className="op vertical fluid divider"></div>
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light" disabled={values.disableSubmit}>Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            }
        </div>
    );
}

export default SettingProductItem;