import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SettingWabaListElement from "./SettingWabaListElement";

const MySwal = withReactContent(Swal);

const Waba = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [wabalist, setWabaList] = useState([]);
    const [isAddWaba, setisAddWaba] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [wabaTextData, setWabaTextData] = useState({});
    const [isWabaList, setIsWabaList] = useState(false);

    const CHARACTER_LIMIT = 50;


    // Validation Schema
    const validationSchema = yup.object().shape({
        body_text: yup
            .string()
            .required("Body text is required")
            .max(CHARACTER_LIMIT, `Body text cannot exceed ${CHARACTER_LIMIT} characters`),
        type: yup.string().required("Type is required")
    });

    // GET FUNCTION --------------------------------------------------------
    const getWabaList = () => {
        try {
            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                    params: {
                        task: "getWABAInteractive",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    setWabaList(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.value;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Body Text",
            field: "body_text",
            cellClass: "cursor-pointer",
            width: 450,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'auto', 'line-height': '1.2' },
            onCellClicked: function (params) {
                if (params.data) {
                    setIsWabaList(true);
                    setWabaTextData(params.data);
                }
            }
        },
        { headerName: "Image URL", field: "image_url", width: 300 },
        { headerName: "Type", headerClass: "center", field: "type", width: 150, cellClass: "center" },
        { headerName: "Date Time Create", field: "", width: 200, cellRenderer: setDateFormat },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
        { headerName: "List Button Title", field: "list_button_title", width: 300 },
        { headerName: "List Section Title", field: "list_section_title", width: 300 },
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "updateWABAInteractive",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    waba_interactive_id: values.whatsapp_interactive_id,
                    type: values.type,
                    body_text: values.body_text,
                    image_url: values.image_url,
                    list_button_title: values.list_button_title,
                    list_section_title: values.list_section_title
                }
            });

            if (response.data.status == '0') {
                setShowModal(false);
                getWabaList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.whatsapp_interactive_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "deleteWABAInteractive",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    waba_interactive_id: params.whatsapp_interactive_id,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getWabaList();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "setWABAInteractive",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    type: values.type,
                    body_text: values.body_text,
                    image_url: values.image_url,
                    list_button_title: values.list_button_title,
                    list_section_title: values.list_section_title
                }
            });

            if (response.data.status == '0') {
                setisAddWaba(false);
                getWabaList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getWabaList();
        }
    }, []);

    const toggleAddWabaModal = () => {
        setisAddWaba(prevState => !prevState);
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddWabaModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> WABA
                    </button>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : wabalist} rowHeight={100} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddWaba} onHide={toggleAddWabaModal}>
                        <Formik
                            initialValues={{
                                body_text: "",
                                image_url: "",
                                type: "list",
                                list_button_title: "",
                                list_section_title: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add WABA</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="mb-3">
                                            <div className="mb-2">Type</div>
                                            <Form.Check
                                                inline
                                                label="List"
                                                name="type"
                                                type="radio"
                                                value="list"
                                                checked={values.type === "list"}
                                                onChange={() => setFieldValue("type", "list")}
                                                isInvalid={touched.type && !!errors.type}
                                            />
                                            <Form.Check
                                                inline
                                                label="Reply"
                                                name="type"
                                                type="radio"
                                                value="reply"
                                                checked={values.type === "reply"}
                                                onChange={() => setFieldValue("type", "reply")}
                                                isInvalid={touched.type && !!errors.type}
                                            />
                                            {touched.type && !!errors.type && (
                                                <div className="text-danger">{errors.type}</div>
                                            )}
                                        </div>

                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Body Text
                                                <span className="text-muted ms-2">({values.body_text.length}/{CHARACTER_LIMIT})</span>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="body_text"
                                                value={values.body_text}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= CHARACTER_LIMIT) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                maxLength={CHARACTER_LIMIT}
                                                isInvalid={touched.body_text && !!errors.body_text}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.body_text}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {values.type !== "list" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>Image URL</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="image_url"
                                                    value={values.image_url}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {values.type !== "reply" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>List Button Title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="list_button_title"
                                                    value={values.list_button_title}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {values.type !== "reply" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>List Section Title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="list_section_title"
                                                    value={values.list_section_title}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                body_text: currentData ? currentData.body_text : "",
                                image_url: currentData ? currentData.image_url : "",
                                type: currentData ? currentData.type : "",
                                whatsapp_interactive_id: currentData ? currentData.whatsapp_interactive_id : "",
                                list_button_title: currentData ? currentData.list_button_title : "",
                                list_section_title: currentData ? currentData.list_section_title : ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Body Text
                                                <span className="text-muted ms-2">({values.body_text.length}/{CHARACTER_LIMIT})</span>
                                            </Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="body_text"
                                                value={values.body_text}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= CHARACTER_LIMIT) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                maxLength={CHARACTER_LIMIT}
                                                isInvalid={touched.body_text && !!errors.body_text}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.body_text}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {values.type !== "list" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>Image URL</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="image_url"
                                                    value={values.image_url}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {values.type !== "reply" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>List Button Title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="list_button_title"
                                                    value={values.list_button_title}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {values.type !== "reply" && (
                                            <Form.Group className="mb-3">
                                                <Form.Label>List Section Title</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="list_section_title"
                                                    value={values.list_section_title}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        )}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Offcanvas
                        show={isWabaList}
                        onHide={setIsWabaList}
                        placement="bottom"
                        style={{
                            width: '75%',
                            margin: '0 auto',
                            position: 'fixed',
                            top: '30px',
                            left: '0',
                            right: '0',
                            height: '90vh',
                            backgroundColor: 'rgb(243, 243, 243)', // Move background color here for consistency
                            borderRadius: '5px'
                        }}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title as={"h6"}>WABA Element</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body style={{ backgroundColor: "rgb(243, 243, 243)" }}>
                            <SettingWabaListElement wabaData={wabaTextData} />
                        </Offcanvas.Body>
                    </Offcanvas>

                </div>
            )}
        </Container>
    );
}

export default Waba;
