import { useEffect, useRef, useState, forwardRef, useMemo, useCallback } from "react";
import { Container, Row, Col, Stack, Button, Dropdown, Offcanvas, Form, Modal } from "react-bootstrap";
import Loader from "../includes/Loader";
import Loader2 from "../includes/Loader2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormSelect } from "../includes/FormCustom";
import { faBullhorn, faCircleDown } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import NoRecord from "../includes/NoRecord";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { FilePond } from "react-filepond";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import iziToast from "izitoast";
const CurationDetails = () => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const { curation_id } = useParams();
  const { Formik } = formik;
  const navigate = useNavigate();
  const curationInit = useRef(false);
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [isGrid, setIsGrid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [gridCount, setGridCount] = useState(0);
  const [curationData, setCurationData] = useState({ label: "", value: "" });
  const [isBulkEmail, setIsBulkEmail] = useState(false);
  const [isBulkSMS, setIsBulkSMS] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [isDataImport, setIsDataImport] = useState(false);
  const [prospectName, setProspectName] = useState("");
  const [isSuccess, setIsSuccess] = useState(0);
  const [importCurationCount, setImportCurationCount] = useState(0);
  const [importCurationData, setImportCurationData] = useState([]);
  const [importCurationRecord, setImportCurationRecord] = useState([]);
  const [curationInfo, setCurationInfo] = useState({
    company_id: session.company_id,
    curation_id: "",
    curation_source: "other",
    curation_title: "",
    date_time_create_curation: "0000-00-00 00:00:00",
    dynamic_list: "0",
    dynamic_triggered_date_time: "0000-00-00 00:00:00",
    user_id: session.user_id,
  });

  const [whatsappTemplate, setWhatsappTemplate] = useState([]);

  const curationRef = useRef();
  const [user, setUser] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const bulkEmailSchema = yup.object().shape({
    email_template_name: yup.string().required(t("Curation_error_message")),
    campaign_title: yup.string().required(t("Curation_error_message")),
    confirmation: yup
      .string()
      .required(t("Curation_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const bulkSMSSchema = yup.object().shape({
    sms_template: yup.string().required(t("Curation_error_message")),
    sms_compose: yup.string().required(t("Curation_error_message")),
    campaign_title: yup.string().required(t("Curation_error_message")),
    confirmation: yup
      .string()
      .required(t("Curation_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  // GET FUNCTION -------------------------------------------------------------------------

  const getViewCuration = async () => {
    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "ViewCuration",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          curation_id: curation_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setCurationInfo(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUser = async () => {
    const response = await axios.get("ws/ws_user.php", {
      params: {
        task: "4a",
        ctoken: session.company_token,
        utoken: session.user_ac_token,
      },
    });

    const data = response.data;
    if (data.status === 0) {
      var user = data.record.map((record) => ({ label: record.user_name, value: record.user_id }));
      setUser(user);
    }
  };

  const getEmailTemplate = async () => {
    try {
      const response = await axios.get("ws/ws_email.php", {
        params: {
          task: "getEmailTemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({ label: record.email_template_title, value: record.email_template_id }));
        setEmailTemplate(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWhatsappTemplate = async () => {
    try {
      const response = await axios.get("ws/ws_custom_interaction.php", {
        params: {
          task: "getWhatsappInteractions",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({ label: record.interaction_name, value: record.custom_interaction_id, description: record.interaction_title }));
        setWhatsappTemplate(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ------------------------------------------------------------------------

  const sendBulkEmail = (values) => {
    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    axios
      .post("ws/ws_email_post.php", {
        params: {
          task: "sendBulkEmail",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          emailTemplate: values.email_template_name,
          prospect: prospect_id.toString(),
          campaignTitle: values.campaign_title,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setIsBulkEmail(false);
          Swal.fire({
            icon: "success",
            title: t("Curation_successful"),
            text: t("Curation_bulk_sms_has_been_scheduled"),
            timer: 1500,
          });
        } else if (data.status === 441) {
          setIsBulkEmail(false);
          Swal.fire({
            icon: "error",
            title: t("Curation_failed"),
            text: t("Curation_your_account_isnt_enabled_for_bulk_sms"),
          });
        } else {
          setIsBulkEmail(false);
          Swal.fire({
            icon: "error",
            title: t("Curation_failed"),
            text: t("Curation_error_popup_message"),
          });
        }
      });
  };

  const sendBulkSMS = (values) => {
    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    axios
      .post("ws/ws_sms_post.php", {
        params: {
          task: "sendBulkSMS",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          message: values.sms_compose,
          prospect: prospect_id.toString(),
          campaignTitle: values.campaign_title,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setIsBulkEmail(false);
          Swal.fire({
            icon: "success",
            title: t("Curation_successful"),
            text: t("Curation_bulk_sms_has_been_scheduled"),
            timer: 1500,
          });
        } else if (data.status === 441) {
          setIsBulkEmail(false);
          Swal.fire({
            icon: "error",
            title: t("Curation_failed"),
            text: t("Curation_your_account_isnt_enabled_for_bulk_sms"),
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("Curation_failed"),
            text: t("Curation_error_popup_message"),
          });
        }
      });
  };

  const sendRemoveValidate = () => {
    setLoading2(true);
    if (Number(session.company_id) === 251 && (curationInfo.curation_source === "segmentation" || curationInfo.curation_source === "gl_play")) {
      sendRemoveMtarget();
    } else {
      sendRemoveSelected();
    }
  };

  const sendRemoveMtarget = async () => {
    try {
      const prospectName = rowData.map((item) => {
        return item.prospect_name;
      });

      const prospectEmail = rowData.map((item) => {
        if (item.email1) {
          return item.email1;
        } else if (item.email2) {
          return item.email2;
        } else {
          return item.email3;
        }
      });

      const prospectId = rowData.map((item) => {
        return item.prospect_id;
      });

      const formData = new FormData();
      formData.append("task", "RemoveProspectCurationMTARGET");
      formData.append("prospect_ids", prospectId.join("[-0-]"));
      formData.append("prospect_names", prospectName.join("[-0-]"));
      formData.append("prospect_emails", prospectEmail.join("[-0-]"));
      formData.append("curation_id", curation_id);
      formData.append("ctoken", session.company_token);
      formData.append("utoken", session.user_ac_token);

      const response = await axios.post("ws/ws_curation_segmentation.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsSuccess(Math.random());
        setCurationData(curationData);
        setRowCount(0);
        setRowData([]);
        setIsRowSelected(false);
        setIsChecked(false);
        setIsIndeterminate(false);
        iziToast.success({
          title: "Success",
          message: "Successfully removed all the selected prospect",
          timer: 2000,
        });
      } else {
        setLoading2(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry. please try again or contact our IT support",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
    }
  };

  const sendRemoveSelected = async () => {
    var prospect_id = rowData.map((record) => {
      return record.prospect_id;
    });

    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "removeCurationProspect",
          curation: curation_id,
          prospects: prospect_id.toString(),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          text: t("Curation_the_selected_prospect_has_been_removed"),
          timer: 1500,
        });
        setIsSuccess(Math.random());
        setCurationData(curationData);
        setRowCount(0);
        setRowData([]);
        setIsRowSelected(false);
        setIsChecked(false);
        setIsIndeterminate(false);
      } else {
        Swal.fire({
          icon: "error",
          text: t("Curation_error_popup_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
    }
  };

  const sendExportCuration = async () => {
    setLoading2(true);
    try {
      const response = await axios.get("ws/ws_curation.php", {
        params: {
          task: "DownloadCurationData",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          curation_id: curation_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/curation_file/${data.filename}`);
      } else {
        iziToast.error({
          title: "Error",
          message: "Failed to export the curation data",
          timeout: 5000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading2(false);
    }
  };

  // RENDER FUNCTION ----------------------------------------------------------------------

  const RenderHeaderCheckbox = forwardRef((props, ref) => {
    const { api } = props;

    const handleClick = () => {
      if (isIndeterminate) {
        if (api) {
          api.forEachNode((node) => node.setSelected(false));
        }
      } else {
        setIsChecked((prevChecked) => {
          const newChecked = !prevChecked;
          setIsIndeterminate(false);

          if (api) {
            api.forEachNode((node) => node.setSelected(newChecked));
          }

          return newChecked;
        });
      }
    };

    return (
      <div ref={ref} className="ag-header-cell" onClick={handleClick} style={{ padding: 0 }}>
        <div>
          {isChecked && <span className="ag-icon ag-icon-checkbox-checked"></span>}
          {!isChecked && !isIndeterminate && <span className="ag-icon ag-icon-checkbox-unchecked"></span>}
          {isIndeterminate && <span className="ag-icon ag-icon-checkbox-indeterminate"></span>}
        </div>
      </div>
    );
  });

  const RenderBulkAction = ({ count }) => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button variant="light" className="shadow-sm" onClick={openDropdown}>
          <FontAwesomeIcon icon={faBullhorn} size="lg" />
        </Button>
      );
    });

    const modalBulkEmail = () => {
      if (count > 2000) {
        Swal.fire({
          icon: "warning",
          title: t("Curation_failed"),
          text: t("Curation_this_action_can_only_performed_2000_records"),
        });
      } else {
        setIsBulkEmail(true);
      }
    };

    const modalBulkSMS = () => {
      if (count > 2000) {
        Swal.fire({
          icon: "warning",
          title: t("Curation_failed"),
          text: t("Curation_this_action_can_only_performed_2000_records"),
        });
      } else {
        setIsBulkSMS(true);
      }
    };

    const modalRemove = () => {
      if (count > 2000) {
        Swal.fire({
          icon: "warning",
          title: t("Curation_failed"),
          text: t("Curation_this_action_can_only_performed_2000_records"),
        });
      } else {
        Swal.fire({
          title: t("Curation_are_you_sure"),
          text: t("Curation_please_confirm_that_you_would_like_to_remove_prospect", { count: count }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "var(--op-primary-color)",
          cancelButtonColor: "#ddd",
          confirmButtonText: "Remove",
        }).then((result) => {
          if (result.isConfirmed) {
            sendRemoveValidate();
          }
        });
      }
    };

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="start" size={100}>
            <Dropdown.Header className="fw-semibold op-text-medium">{t("Curation_bulk_action")}</Dropdown.Header>
            <Dropdown.Item onClick={modalBulkEmail}>{t("Curation_email_all_selected")}</Dropdown.Item>
            <Dropdown.Item onClick={modalBulkSMS}>{t("Curation_sms_all_selected")}</Dropdown.Item>
            <Dropdown.Item onClick={modalRemove}>{t("Curation_remove_all_selected")}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <h6 className="op-text-medium mt-2 me-2">{t("Curation_bulk_record", { count: rowCount })}</h6>
      </>
    );
  };

  // GRID SETUP ---------------------------------------------------------------------------

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh" : "0vh", paddingTop: 20 }), [isGrid]);
  const containerStyle2 = useMemo(() => ({ width: "100%", height: "50vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerComponent: RenderHeaderCheckbox,
      headerName: "",
      field: "",
      checkboxSelection: true,
      resizable: false,
      width: 50,
      maxWidth: 50,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return "";
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("Curation_prospect_name"),
      field: "prospect_name",
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("Curation_prospect_contact"),
      field: "",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.contact1) {
            return params.data.contact1;
          } else if (params.data.contact2) {
            return params.data.contact2;
          } else if (params.data.contact3) {
            return params.data.contact3;
          } else if (params.data.contact4) {
            return params.data.contact4;
          } else if (params.data.contact5) {
            return params.data.contact5;
          } else if (params.data.prospect_contact) {
            return params.data.prospect_contact;
          } else {
            return "";
          }
        }
      },
    },
    {
      headerName: t("Curation_prospect_email"),
      field: "",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.email1) {
            return params.data.email1;
          } else if (params.data.email2) {
            return params.data.email2;
          } else if (params.data.email3) {
            return params.data.email3;
          } else if (params.data.prospect_email) {
            return params.data.prospect_email;
          } else {
            return "";
          }
        }
      },
    },
    {
      headerName: t("Curation_organization"),
      field: "organization_title",
    },
    {
      headerName: t("Curation_designation"),
      field: "designation_title",
    },
    {
      headerName: t("Curation_lead_source"),
      field: "ds_title",
    },
  ];

  const curationRowId = useCallback(function (params) {
    return Math.random().toString();
  }, []);

  const curationSelect = useCallback(() => {
    setImportCurationRecord(curationRef.current.api.getSelectedRows());
  }, []);

  const importCurationColumn = [
    {
      headerName: "No",
      field: "",
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone",
      field: "phone",
    },
  ];

  const gridDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: async (params) => {
        try {
          const response = await axios.get("ws/ws_curation.php", {
            params: {
              task: "listCurationContactsV2",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              curation: curation_id,
              startRow: params.startRow,
              endRow: params.endRow,
              prospect_name: prospectName,
              prospect_rule: "contains",
            },
          });
          gridRef.current.api.deselectAll();
          const data = response.data;
          setIsGrid(Number(data.totalRecord) > 0);
          setGridCount(Number(data.totalRecord));
          setTimeout(() => {
            const recordInfo = data.status === 0 ? data.record : [];
            const totalRecord = Number(data.totalRecord);
            const rowsThisPage = recordInfo;
            const lastRow = Number(totalRecord) <= params.endRow ? totalRecord : -1;

            params.successCallback(rowsThisPage, lastRow);
          }, 500);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsGrid(false);
        }
      },
    };
  }, [curationData, curationInit.init, session, isSuccess, prospectName]);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback((params) => {
    return params.data.curation_list_id.toString();
  }, []);

  const gridSelected = useCallback((params) => {
    const selectedNodes = gridRef.current.api.getSelectedRows();
    const atLeastOneSelected = selectedNodes.length > 0;
    const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

    setIsChecked(allSelected);
    setIsIndeterminate(atLeastOneSelected && !allSelected);

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  // Preview excel import curation --------------------------------------------------------

  const getImportCuration = async (values) => {
    try {
      var recordReader = new FileReader();
      recordReader.onload = () => {
        var recordFileData = recordReader.result;
        var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
        var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Import Curation"]);
        var recordData = recordRow;
        setImportCurationCount(recordRow.length);

        var headerRows = [];
        var recordWorksheet = recordWorkBook.Sheets["Import Curation"];

        if (recordWorksheet) {
          var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
          for (var R = range.s.r; R <= range.e.r; ++R) {
            var row = [];
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cellAddress = XLSX.utils.encode_cell({
                r: R,
                c: C,
              });
              var cell = recordWorksheet[cellAddress];
              var cellValue = cell ? cell.v : "";
              row.push(cellValue);
            }
            headerRows.push(row);
          }
        }

        if (!recordWorksheet) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Import Curation'.</p></div>`,
          });
          return;
        }

        var expectedColumns = ["Name", "Email", "Phone"];
        var currentColumns = headerRows[0];

        if (currentColumns) {
          var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
          if (missingColumns.length > 0) {
            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
            for (var i = 0; i < missingColumns.length; i++) {
              alertMessage += `<li>${missingColumns[i]}</li>\n`;
            }
            alertMessage += `</ul></div>`;
            Swal.fire({
              icon: "warning",
              html: alertMessage,
            });
            return;
          }
        }

        if (!recordRow.length) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
          });
          return;
        }

        for (let i = 0; i < recordData.length; i++) {
          recordData[i].index = i + 1;
        }

        const bulkData = recordData.map(({ Name: name = "", Email: email = "", Phone: phone = "", index: index }) => ({
          name,
          email,
          phone,
          index,
        }));

        setImportCurationData(bulkData);
      };
      recordReader.readAsArrayBuffer(values.file[0]);
    } catch (error) {
      console.error(error);
    }
  };

  // Save Curation ------------------------------------------------------------------------

  const checkExistingProspect = async (phoneNumber) => {
    setLoading(true);
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/ws_prospect.php", {
        params: {
          task: "8a",
          company: session.company_id,
          uid: session.user_id,
          m: "yes",
          page: "",
          size: "",
          q: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user: "",
        },
      });

      const existingProspects = response.data.record;

      if (!Array.isArray(existingProspects) || existingProspects.length === 0) {
        console.warn("No existing prospects found");
        return false;
      }

      const contactNumbers = existingProspects
        .map((record) => {
          if (Array.isArray(record.contacts)) {
            return record.contacts.map((contact) => contact?.contact_num).filter((num) => num);
          }
          return [];
        })
        .flat();

      const phoneNumberExists = contactNumbers.includes(phoneNumber);
      return phoneNumberExists;
    } catch (error) {
      console.error("Error checking existing prospect:", error);
      return false;
    }
  };

  const createProspect = async (name, phone) => {
    try {
      // Make GET request to create a new prospect
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "addNewProspectV2",
          name: name,
          des: "",
          des_title: "",
          contact: phone,
          contact2: "",
          contact3: "",
          contact4: "",
          email: "",
          email2: "",
          email3: "",
          organization: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      if (response.data.status === 0) {
        console.log("New prospect created successfully");
      } else {
        console.error("Failed to create new prospect:", response.data.message);
      }
    } catch (error) {
      console.error("Error creating prospect:", error);
    }
  };

  const sendImportCuration = async (values) => {
    try {
      if (importCurationRecord.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "No record has been selected, please choose which voucher record that you want to bulk upload",
        });
        return;
      }

      var name = importCurationRecord.map((item) => (item.name ? item.name : "")).filter((item) => item !== "");
      var email = importCurationRecord.map((item) => (item.email ? item.email : "")).filter((item) => item !== "");
      var phone = importCurationRecord.map((item) => (item.phone ? item.phone : "")).filter((item) => item !== "");

      var nameVal = name.join("|");
      var emailVal = email.join("|");
      var phoneVal = phone.join("|");

      const formData = new FormData();
      formData.append("task", "BulkImportCuration");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("title", "");
      formData.append("name", nameVal);
      formData.append("email", emailVal);
      formData.append("contact", phoneVal);
      formData.append("curation", curation_id);
      formData.append("owner", values.user);

      const res = await axios.post("ws/ws_data_upload.php", formData);
      const data = res.data;

      if (data.status === 0) {
        setIsDataImport(false);
        setImportCurationRecord(
          importCurationRecord.map((item, index) => ({
            ...item,
            index: index + 1,
          }))
        );
        setImportCurationCount(importCurationRecord.length);

        // Extract phone numbers
        const phoneNumbers = phoneVal.split("|").map((num) => num.trim());

        // Check existing prospects in parallel
        const checkPromises = phoneNumbers.map((phoneNumber) => checkExistingProspect(phoneNumber));

        const results = await Promise.all(checkPromises);

        // Create new prospects for non-existing numbers
        const createPromises = phoneNumbers.map((phoneNumber, index) => {
          if (!results[index]) {
            const { name } = importCurationRecord[index];
            return createProspect(name, phoneNumber);
          }
          return Promise.resolve();
        });

        await Promise.all(createPromises);

        Swal.fire({
          icon: "success",
          title: t("Curation_successful"),
          text: "New Curation Imported Successfully",
          timer: 1500,
        });

        setRefreshKey((prevKey) => prevKey + 1);
      }
    } catch (error) {
      console.error("Error during import:", error);
    } finally {
      setLoading(false);
    }
  };

  // USEEFFECT FUNCTION -------------------------------------------------------------------

  useEffect(() => {
    if (!init.current) {
      getViewCuration();
      getUser();
      getEmailTemplate();
      getWhatsappTemplate();
      init.current = true;

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [refreshKey, session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container key={refreshKey} fluid className="px-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col xxl={12}>
            <Stack direction="horizontal" gap={2}>
              {curationInfo.curation_source !== "segmentation" && curationInfo.curation_source !== "gl_play" && (
                <Button variant="" className="btn op-button op-primary-color text-light" onClick={setIsDataImport}>
                  Import Curation
                </Button>
              )}

              {Number(session.company_id) === 251 && curationInfo.curation_source === "segmentation" && (
                <Button variant="" className="btn op-button op-primary-color text-light" onClick={() => navigate(`/gamuda/segment/${curation_id}`)}>
                  Segment
                </Button>
              )}

              <Button variant="light" className="shadow-sm border" onClick={sendExportCuration}>
                <FontAwesomeIcon icon={faCircleDown} size="lg" />
              </Button>

              {isRowSelected && <RenderBulkAction count={rowCount} />}
              <h6 className="ms-auto op-text-medium mt-2 me-2"> {gridCount > 1 ? t("Curation_record", { count: gridCount }) : t("Curation_no_record")}</h6>
              {Number(session.company_id) === 251 && <Form.Control value={prospectName} placeholder="Search Prospect" onChange={(e) => setProspectName(e.target.value)} style={{ width: 300 }} />}
            </Stack>
          </Col>
          <Col xxl={12}>
            <div style={containerStyle}>
              <div className="ag-theme-quartz ag-op" style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  onSelectionChanged={gridSelected}
                  columnDefs={gridColumn}
                  datasource={gridDataSource}
                  defaultColDef={gridColDef}
                  rowSelection={"multiple"}
                  rowModelType={"infinite"}
                  rowHeight={70}
                  cacheBlockSize={100}
                  cacheOverflowSize={2}
                  maxConcurrentDatasourceRequests={2}
                  infiniteInitialRowCount={6}
                  maxBlocksInCache={2}
                  getRowId={gridRowId}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={false}
                  suppressRowClickSelection={true}
                  animateRows={true}
                />
              </div>
            </div>

            {!isGrid && <NoRecord message={t("Curation_no_record_message")} description={t("Curation_no_record_desc")} width={300} />}
          </Col>
        </Row>
      )}

      {loading2 && (
        <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: 100000000, top: 0, left: 0 }}>
          <div style={{ background: "#000", opacity: 0.2, width: "100%", height: "100%", position: "absolute", zIndex: 1 }}></div>
          <div style={{ position: "absolute", top: "40%", left: "48%", zIndex: 2 }}>
            <Loader2 />
          </div>
        </div>
      )}

      <Modal show={isDataImport} onHide={setIsDataImport} size="lg">
        <Formik
          onSubmit={getImportCuration}
          initialValues={{
            file: [],
            user: "",
            user_show: true,
            submit_disabled: true,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Import</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xxl={12}>
                    <Form.Group>
                      <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        name="file"
                        credits={false}
                        acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        instantUpload={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "file",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          setFieldValue("file", []);
                          setFieldValue("status_show", false);
                          setFieldValue("status", "");
                          setFieldValue("submit_disabled", true);
                          setImportCurationCount(0);
                          setImportCurationData([]);
                          setImportCurationRecord([]);
                          setTimeout(() => {
                            setFieldValue("status_show", true);
                          }, 1);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xxl={12}>
                    <Form.Text className="text-muted d-flex justify-content-center" style={{ marginBottom: "20px" }}>
                      Please note, this file uploader only accepts excel files (xls or xlsx). Please download and use our template and enter your list into it.
                    </Form.Text>

                    {values.user_show && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={user}
                          name="user"
                          placeholder="Select user"
                          disabled={values.file.length === 0}
                          onChange={(e) => {
                            setFieldValue("user", e.value);
                            setFieldValue("preview_disabled", false);
                          }}
                        />
                      </Form.Group>
                    )}

                    <Form.Group className="d-flex justify-content-center">
                      <Button type="button" variant="" className="op-primary-color text-light me-2" disabled={values.user === ""} onClick={() => setFieldValue("submit_disabled", false)}>
                        Preview
                      </Button>
                      <Button type="button" variant="" className="op-primary-color text-light" onClick={() => window.open("https://www.nexcrmapis.com/cloud_staging/ext/general/images/Outperform_Import_Curation_Template.xlsx", "_blank")}>
                        Template
                      </Button>
                    </Form.Group>
                  </Col>

                  {importCurationCount > 0 && (
                    <Col xxl={12}>
                      <p className="mb-2">{importCurationCount === 0 ? "No record" : importCurationCount === 1 ? "1 record" : `${importCurationCount} records`}</p>
                      <div style={containerStyle2}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                          <AgGridReact
                            ref={curationRef}
                            rowSelection="multiple"
                            rowData={importCurationData}
                            columnDefs={importCurationColumn}
                            getRowId={curationRowId}
                            onSelectionChanged={curationSelect}
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={false}
                            suppressRowClickSelection={true}
                            animateRows={true}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="" className="op-primary-color text-light" disabled={values.submit_disabled} onClick={() => sendImportCuration(values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Offcanvas show={isBulkEmail} placement={"end"} onHide={setIsBulkEmail}>
        <Formik
          validationSchema={bulkEmailSchema}
          onSubmit={sendBulkEmail}
          initialValues={{
            email_template_name: "",
            campaign_title: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Curation_bulk_email")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {t("Curation_bulk_action_record", { count: rowCount })}</h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="email_template_name"
                    onChange={(info) => {
                      handleChange("email_template_name")(info.value);
                    }}
                    isInvalid={errors.email_template_name && touched.email_template_name}
                    options={emailTemplate}
                    placeholder={t("Curation_select_template")}
                  />
                  {errors.email_template_name && touched.email_template_name && <div className="op-error-message">{errors.email_template_name}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control name="campaign_title" value={values.campaign_title} onChange={handleChange("campaign_title")} isInvalid={errors.campaign_title && touched.campaign_title} type="text" placeholder={t("Curation_campaign_title")} />
                  {errors.campaign_title && touched.campaign_title && <div className="op-error-message">{errors.campaign_title}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("Curation_are_you_sure_to_perform_this")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("Curation_type_yes_to_confirm")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("Curation_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isBulkSMS} placement={"end"} onHide={setIsBulkSMS}>
        <Formik
          validationSchema={bulkSMSSchema}
          onSubmit={sendBulkSMS}
          initialValues={{
            sms_template: "",
            sms_compose: "",
            campaign_title: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Curation_bulk_email")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {t("Curation_bulk_action_record", { count: rowCount })}</h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    onChange={(info) => {
                      handleChange("sms_template")(info.value);
                      handleChange("sms_compose")(info.description);
                    }}
                    isInvalid={errors.sms_template && touched.sms_template}
                    options={whatsappTemplate}
                    placeholder={t("Curation_select_template")}
                  />
                  {errors.email_template_name && touched.email_template_name && <div className="op-error-message">{errors.email_template_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control as="textarea" rows={3} value={values.sms_compose} onChange={handleChange("sms_compose")} isInvalid={errors.sms_compose && touched.sms_compose} type="text" />
                  {errors.sms_compose && touched.sms_compose && <div className="op-error-message">{errors.sms_compose}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control value={values.campaign_title} onChange={handleChange("campaign_title")} isInvalid={errors.campaign_title && touched.campaign_title} type="text" placeholder={t("Curation_campaign_title")} />
                  {errors.campaign_title && touched.campaign_title && <div className="op-error-message">{errors.campaign_title}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("Curation_are_you_sure_to_perform_this")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("Curation_type_yes_to_confirm")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("Curation_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </Container>
  );
};

export default CurationDetails;
