import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import { useTranslation } from "react-i18next"; 

const SourceCategorySetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);

    const [sourceIconList, setSourceIconList] = useState([]);

    const getAllIconTitle = () => {

        var data = [
            "avanser.png",
            "billboard.jpg",
            "callicon.jpg",
            "default.jpg",
            "fb.png",
            "googlead.jpg",
            "iproperty.png",
            "linkedin.jpg",
            "nexcall.jpg",
            "nexcall.png",
            "pg.png",
            "propsocial.png",
            "prospecting.jpg",
            "pv-website.png",
            "starproperty.png",
            "walkin.jpg",
            "website.jpg",
            "website1.jpg",
            "whatsapp.jpg",
            "zalo.jpg",
            "tiktok.jpg",
            "xiaohongshu.jpg"
        ];

        var dataList = data.map(data => {
            return {label: data, value: data};
        });

        setSourceIconList(dataList);

    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingSourceCategory_headername_1'),
            field: "date_time_create",
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(params.data.date_time_create).format('lll');
                }
            }
        },
        {
            headerName: t('SettingSourceCategory_headername_2'),
            field: "source_category_title",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingSourceCategory_headername_3'),
            field: "",
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return (
                        <div>
                            <img src={`${session.hostUrl}/${session.hostUrlType}`+"/assets/source_cat/" + params.data.icon_title} className="rounded-circle me-2" width={30} height={30} alt="icon1"/>
                            {params.data.icon_title} 
                        </div>
                    );
                }
            }
        },
        {
            headerName: t('SettingSourceCategory_headername_4'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.source_category_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_source_category.php',{
            params:{
                task: "getSourceCategoryListing",
                source_category_id: "",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                data.record.sort(function(a, b) { return new Date(b.date_time_create) - new Date(a.date_time_create) });
                setRecordFound(data.totalRecord);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllIconTitle();
            getGridData();
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        title: yup.string().required(t('SettingSourceCategory_required_field')),
        icon_title: yup.string().required(t('SettingSourceCategory_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_source_category.php', {
            params:{
                task: "addSourceCategory",
                source_category_title: values.title,
                icon_title: values.icon_title,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingSourceCategory_alert_success_title'),
                    text: t('SettingSourceCategory_alert_success_add_text'),
                    timer: 1500
                }).then(result => {
                    setIsLoading(true);
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingSourceCategory_alert_failed_title'),
                    text: t('SettingSourceCategory_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('SettingSourceCategory_alert_warning_title'),
            text: t('SettingSourceCategory_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_source_category.php', {
                    params:{
                        task: "deleteSourceCategory",
                        source_category_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingSourceCategory_alert_success_title'),
                            text: t('SettingSourceCategory_alert_success_delete_text'),
                            timer: 1500
                        }).then(result => {
                            setIsLoading(true);
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingSourceCategory_alert_failed_title'),
                            text: t('SettingSourceCategory_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <div className="mt-3 mb-3">
                            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                                <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingSourceCategory_btn_add')}</button>
                                <div className="px-2 ms-auto">Result: {recordFound === 0 ? t('SettingSourceCategory_record_no') : t('SettingSourceCategory_record', {count: recordFound})}</div>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={reportColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={80}
                                    pagination={false}
                                    // paginationPageSize={30}
                                    // paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                        {!isGrid && <NoRecord message="No record at the moment." width={200}/>}
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    title: "",
                                    icon_title: "",
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, values, errors, touched })=>(
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingSourceCategory_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingSourceCategory_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.title && touched.title}
                                                onChange={handleChange('title')}
                                            />
                                            {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingSourceCategory_modalBody_add_title_2')}</Form.Label>

                                            <FormSelect
                                                placeholder={t('SettingSourceCategory_modalBody_add_title_2_placeholder')}
                                                options={sourceIconList}
                                                isInvalid={errors.icon_title && touched.icon_title}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e)=>{handleChange("icon_title")(e ? e.value:"")}}
                                            />
                                            
                                            {errors.icon_title && touched.icon_title && <div className="op-error-message">{errors.icon_title}</div>}
                                        </Form.Group>
                                        {
                                            values.icon_title && 
                                                <div className="w-100 border rounded d-flex justify-content-center align-items-center">
                                                    <img src={`${session.hostUrl}/${session.hostUrlType}`+"/assets/source_cat/" + values.icon_title} width={60} height={60} alt="icon2"/>
                                                </div>    
                                        }
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingSourceCategory_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );
}

export default SourceCategorySetting;