import { useState, useEffect, useRef, useMemo } from "react";
import { Col, Container, Row, Stack, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect } from "../includes/FormCustom";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { useAuth } from "../auth/AuthContext";
import Tippy from "@tippyjs/react";
import { faEllipsisStrokeVertical } from "@fortawesome/pro-regular-svg-icons";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const OrganizationList = () => {
    const { t } = useTranslation();
    const { Formik } = formik;
    const { session } = useAuth();
    const cToken = session.company_token;
    const uToken = session.user_ac_token;
    const initialized = useRef(false);
    const [user, setUser] = useState([]);
    const [isLoading, setisLoading] = useState(true);
    const [isAddOrganization, setisAddOrganization] = useState(false);
    const [isEditOrganization, setIsEditOrganization] = useState(false);
    // const [isFindDesignation, setIsFindDesignation] = useState(false);
    const [isContact, setisContact] = useState(true);
    const [userSearch, setUserSearch] = useState("");
    const [contactSearch, setContactSearch] = useState("");
    const [isContactSearch, setisContactSearch] = useState(0);
    const [trigger, setTrigger] = useState(0);
    // const [prosCF, setProsCF] = useState([]);
    const [prosCfInitVal, setProsCfInitVal] = useState({});
    const [countryData, setCountryData] = useState([]);
    const [industryList, setIndustryList] = useState([]);
    const [servicesData, setServicesData] = useState([]);
    const [workingHourData, setWorkingHourData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [selectedWorkingHour, setSelectedWorkingHour] = useState(null);
    // const [stateData, setStateData] = useState([]);
    // const [cityData, setCityData] = useState([]);
    const [allCustomFieldData, setAllCustomFieldData] = useState([]);
    const [getInArrayData, setGetInArrayData] = useState([]);

    // SCHEMA ------------------------------------------------------

    const addOrganizationSchema = yup.object().shape({
        organization_title: yup.string().required("Organization name is required"),
    });

    const editOrganizationSchema = yup.object().shape({
        organizationName: yup.string().required("Organization name is required"),
        // webUrls: yup.string().required("Web url is required"),
        // phoneNumber: yup.string().required("Phone number required"),

    });

    // GET FUNCTION ------------------------------------------------

    const getUser = (loginData) => {
        axios
            .get("ws/ws_user.php", {
                params: {
                    task: "4b",
                    ctoken: loginData.company_token,
                    utoken: loginData.user_ac_token,
                },
            })
            .then((response) => {
                var data = response.data;
                if (data.status === 0) {
                    data.record.forEach((record) => {
                        record.label = record.user_name;
                        record.value = record.user_id;
                    });

                    setUser(data.record);
                }
            });
    };

    // const getExistingDesignation = (inputValue, callback) => {
    //     setIsFindDesignation(false);
    //     axios
    //         .get("ws/ws_prospect.php", {
    //             params: {
    //                 task: 11,
    //                 company: session.company_id,
    //                 q: inputValue,
    //             },
    //         })
    //         .then((response) => {
    //             var data = response.data;
    //             if (data.status === 0) {
    //                 var options = data.record
    //                     .filter((record) => {
    //                         return Number(record.designation_id) > 0;
    //                     })
    //                     .map((record) => ({
    //                         label: record.designation_title,
    //                         value: record.designation_id,
    //                         customLabel: "",
    //                     }));

    //                 var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
    //                 if (newOptions) {
    //                     options.unshift({
    //                         label: inputValue,
    //                         value: 0,
    //                         customLabel: newOptions.msg,
    //                         contact1: "",
    //                         contact_id: "",
    //                     });
    //                 }

    //                 callback(options);
    //                 setIsFindDesignation(true);
    //             } else {
    //                 var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
    //                 callback(options);
    //                 setIsFindDesignation(true);
    //             }
    //         });
    // };

    const getIndustry = async () => {
        axios
            .get("ws/ws_organization.php", {
                params: {
                    company_id: session.company_id,
                    task: "getOrganizations",
                },
            })
            .then((response) => {
                const data = response.data;
                const options = data.record.map((record) => ({
                    label: record.in_title,
                    value: record.in_id,
                }));
                setIndustryList(options)
            });
    };

    const getCountry = async () => {
        axios
            .get("ws/ws_organization.php", {
                params: {
                    task: 10,
                },
            })
            .then((response) => {
                const data = response.data;
                const options = data.record.map((record) => ({
                    label: record.country_nicename,
                    value: record.country_id,
                }));
                setCountryData(options);
            });
    };

    const getServices = async () => {
        axios
            .get("ws/ws_custom_field.php", {
                params: {
                    area: "organization",
                    company: session.company_id,
                    task: 5,
                    v_add: "yes"
                },
            })
            .then((response) => {
                const data = response.data;
                setGetInArrayData(data);
                // const serviceRecord = data.record.find(record => record.cf_id === '3057');
                // const workingHourRecord = data.record.find(record => record.cf_id === '3061');
                // const areaRecord = data.record.find(record => record.cf_id === '3062');
                const serviceRecord = data.record ? data.record.find(record => record.cf_id === '3057') : null;
                const workingHourRecord = data.record ? data.record.find(record => record.cf_id === '3061') : null;
                const areaRecord = data.record ? data.record.find(record => record.cf_id === '3062') : null;


                if (serviceRecord) {
                    const cfValue = JSON.parse(serviceRecord.cf_value);

                    // Transform the options array
                    const options = Object.values(cfValue).map((value, index) => ({
                        value: value,
                        label: value
                    }));

                    setServicesData(options);
                }

                if (workingHourRecord) {
                    const cfValue = JSON.parse(workingHourRecord.cf_value);

                    // Transform the options array
                    const options = Object.values(cfValue).map((value, index) => ({
                        value: value,
                        label: value
                    }));

                    setWorkingHourData(options);
                }

                if (areaRecord) {
                    const cfValue = JSON.parse(areaRecord.cf_value);

                    // Transform the options array
                    const options = Object.values(cfValue).map((value, index) => ({
                        value: value,
                        label: value
                    }));

                    setAreaData(options);
                }
            });
    };


    const getState = async () => {
        axios
            .get("ws/ws_listplace.php", {
                params: {
                    task: "listState",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                },
            })
            .then((response) => {
                // const data = response.data;
                // const options = data.record.map((record) => ({
                //     label: record.state_title,
                //     value: record.state_id,
                //     country: record.country_id,
                // }));
                // setStateData(options);
            });
    };

    const getCity = async () => {
        axios
            .get("ws/ws_listplace.php", {
                params: {
                    task: "listCity",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                },
            })
            .then((response) => {
                // const data = response.data;
                // const options = data.record.map((record) => ({
                //     label: record.city_title,
                //     value: record.city_id,
                //     state: record.state_id,
                // }));
                // setCityData(options);
            });
    };

    // HANDLE FUNCTION ---------------------------------------------

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            setisContactSearch(Math.random());
        }
    };

    // SEND FUNCTION -----------------------------------------------
    const sendAddOrganization = async (values) => {

        axios.get("ws/ws_organization.php", {
            params: {
                task: '1a',
                utoken: uToken,
                ctoken: cToken,
                title: values.organization_title,
                address: values.organization_address,
                weburl: values.organization_web_url,
                industry: values.in_id,
                country: values.country_id,
                contact: values.organization_contact,
            }
        }).then((firstResponse) => {
            const customFieldData = [
                { cf: getInArrayData.record[0].cf_id, fd_value: '', record: firstResponse.data.record, value: values.company_services },
                { cf: getInArrayData.record[1].cf_id, fd_value: '', record: firstResponse.data.record, value: values.company_date },
                { cf: getInArrayData.record[2].cf_id, fd_value: '', record: firstResponse.data.record, value: values.company_address },
                { cf: getInArrayData.record[3].cf_id, fd_value: '', record: firstResponse.data.record, value: values.working_hours },
                { cf: getInArrayData.record[4].cf_id, fd_value: '', record: firstResponse.data.record, value: values.company_area },
            ];

            const customFieldRequests = customFieldData.map((field) =>
                axios.get("ws/ws_custom_field.php", {
                    params: {
                        task: "3",
                        value: field.value,
                        cf: field.cf,
                        record: field.record,
                        fd_value: field.fd_value,
                    }
                })
            );

            return Promise.all([firstResponse, ...customFieldRequests]);
        }).then(([firstResponse, ...customFieldResponses]) => {
            const firstData = firstResponse.data;
            const allCustomFieldStatus = customFieldResponses.every(response => response.data.status === 0);

            if (firstData.status === 0 && allCustomFieldStatus) {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationList_success"),
                    text: t("OrganizationList_successfully_added_organization"),
                    timer: 1500
                }).then((result) => {
                    setisAddOrganization(false);
                    setTrigger(trigger + 1);
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("OrganizationList_oops"),
                    text: t("OrganizationList_something_wrong_with_your_entry_please_try_again_or_contact_our_developer"),
                    timer: 1500,
                });
            }
        }).catch(error => {
            console.error("Error:", error);
        });
    };

    const sendEditOrganization = (values) => {
        axios.get("ws/ws_organization.php", {
            params: {
                address: values.address,
                company: session.company_id,
                contact: values.phoneNumber,
                country: values.country,
                industry: values.industry,
                organization: values.organizationId,
                owner: values.owner,
                task: "2",
                title: values.organizationName,
                weburl: values.webUrls
            }
        }).then((firstResponse) => {
            const customFieldData = [
                { cf: allCustomFieldData[0].cf_id, fd: allCustomFieldData[0].fd_id, record: allCustomFieldData[0].record_id, value: values.getService },
                { cf: allCustomFieldData[1].cf_id, fd: allCustomFieldData[1].fd_id, record: allCustomFieldData[1].record_id, value: values.companyName },
                { cf: allCustomFieldData[2].cf_id, fd: allCustomFieldData[2].fd_id, record: allCustomFieldData[2].record_id, value: values.getDate },
                { cf: allCustomFieldData[3].cf_id, fd: allCustomFieldData[3].fd_id, record: allCustomFieldData[3].record_id, value: values.companyAddress },
                { cf: allCustomFieldData[4].cf_id, fd: allCustomFieldData[4].fd_id, record: allCustomFieldData[4].record_id, value: values.workingHours },
                { cf: allCustomFieldData[5].cf_id, fd: allCustomFieldData[5].fd_id, record: allCustomFieldData[5].record_id, value: values.area },
            ];

            const customFieldRequests = customFieldData.map((field) =>
                axios.get("ws/ws_custom_field.php", {
                    params: {
                        task: "12",
                        fd: field.fd,
                        value: field.value,
                        cf: field.cf,
                        record: field.record
                    }
                })
            );

            return Promise.all([firstResponse, ...customFieldRequests]);
        }).then(([firstResponse, ...customFieldResponses]) => {
            const firstData = firstResponse.data;
            const allCustomFieldStatus = customFieldResponses.every(response => response.data.status === 0);

            if (firstData.status === 0 && allCustomFieldStatus) {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationList_success"),
                    text: t("OrganizationList_successfully_updated_organization"),
                    timer: 1500
                }).then((result) => {
                    setIsEditOrganization(false);
                    setTrigger(trigger + 1);
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("OrganizationList_oops"),
                    text: t("OrganizationList_something_wrong_with_your_entry_please_try_again_or_contact_our_developer"),
                    timer: 1500,
                });
            }
        }).catch(error => {
            console.error("Error:", error);
        });
    };


    // const sendCheckProspect = async (contact) => {
    //     try {

    //         const res = await axios.get("https://www.nexcrmapis.com/cloud/ws/ws_prospect.php", {
    //             params: {
    //                 task: 13,
    //                 company: session.company_id,
    //                 type: contact.contact_type.value,
    //                 mobile: contact.contact,
    //                 people: "",
    //             },
    //         })

    //         let data = res.data;

    //         if (Number(data.status === 0)) {
    //             return true;
    //         } else {
    //             return false;
    //         }

    //     } catch (error) {
    //         return false;
    //     }
    // };

    // GRID SETUP --------------------------------------------------

    const gridRef = useRef(null);
    const containerStyle = useMemo(() => ({ width: "100%", height: isContact ? "80vh" : "0vh", paddingTop: 20 }), []); // eslint-disable-line react-hooks/exhaustive-deps
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [gridCount, setGridCount] = useState(0);

    const RenderProspectAction = (params) => {

        const tippyRef = useRef();
        const [visible, setVisible] = useState(false);

        const show = () => setVisible(true);
        const hide = () => setVisible(false);

        const dropDownContent = (
            <div className="op-ag-action">
                <div onClick={() => onClickHandler("edit")} className="op-ag-action-item">
                    {t("OrganizationList_edit_organization")}
                </div>
                <div onClick={() => onClickHandler("delete")} className="op-ag-action-item">
                    {t("OrganizationList_delete_organization")}
                </div>
            </div>
        );

        const onClickHandler = (option) => {
            hide();

            if (option === "delete") {
                sendDeleteProspect(params.data);
            }

            if (option === "edit") {
                getSelectedOrganization(params.data);
            }
        };

        return (
            <Tippy ref={tippyRef} content={dropDownContent} visible={visible} onClickOutside={hide} allowHTML={true} arrow={false} appendTo={document.body} interactive={true} placement="left">
                <button className="btn btn-light" onClick={visible ? hide : show}>
                    <FontAwesomeIcon icon={faEllipsisStrokeVertical} size="lg" />
                </button>
            </Tippy>
        );

    }

    const sendDeleteProspect = (data) => {

        Swal.fire({
            icon: "warning",
            title: t("OrganizationList_are_you_sure"),
            text: t("OrganizationList_this_organization_will_be_deleted"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("OrganizationList_yes_delete_it")
        }).then((result) => {

            if (result.isConfirmed) {

                axios.get("ws/ws_organization.php", {
                    params: {
                        task: "6",
                        organization: data.organization_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                }).then((res) => {
                    let data2 = res.data;

                    if (data2.status === 0) {
                        Swal.fire({
                            title: t("OrganizationList_deleted"),
                            text: t("OrganizationList_organization_has_been_deleted"),
                            icon: "success",
                            timer: 1500
                        }).then((result) => {
                            setTrigger(trigger + 1);
                        });
                    } else {
                        Swal.fire({
                            title: t("OrganizationList_warning"),
                            // text: `Can't delete this organization due to deal dependency. If you delete ${data.organization_title}, it will permanently deleted from Outperform`,
                            text: t("OrganizationList_cant_delete_this_organization_due_to_deal_dependency", { title: data.organization_title }),
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t("OrganizationList_force_delete")
                        }).then((result) => {

                            if (result.isConfirmed) {
                                ForceDelete(data, "yes");
                            }
                        });
                    }
                });
            }
        });
    }

    const ForceDelete = (data, force) => {

        axios.get("ws/ws_prospect.php", {
            params: {
                task: "10",
                prospect: data.prospect_id,
                forceDelete: force,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        }).then((res) => {

            let data = res.data;

            if (data.status === 0) {

                Swal.fire({
                    title: t("OrganizationList_deleted"),
                    text: t("OrganizationList_prospect_has_been_deleted"),
                    icon: "success",
                    timer: 1500
                }).then((result) => {
                    setTrigger(trigger + 1);
                });

            } else {

                Swal.fire({
                    title: t("OrganizationList_failed"),
                    text: t("OrganizationList_please_try_again_or_contact_your_it_support"),
                    icon: "warning"
                });
            }

        });

    }

    const getSelectedOrganization = (organizationEditData) => {
        axios.get("ws/ws_organization.php", {
            params: {
                task: 3,
                organization: organizationEditData.organization_id,
                pipe: "",
            }
        })
            .then(res => {
                let data = res.data;

                // let fdValueOfTargetCustomField;
                let customCompanyAddress;
                let defaultWorkingHoursLabel;
                let defaultAreaLabel;
                let customDate;
                let customCompanyName
                let customService;

                if (data.status === 0) {
                    if (Number(data.record.custom_field) > 0) {
                        // setProsCF(data.record.custom_field_data);
                    } else {
                        // setProsCF([]);
                    }

                    // custom field
                    const customFieldData = data.record.custom_field_data;
                    setAllCustomFieldData(customFieldData);

                    const targetArea = "3062";
                    const targetCustomArea = customFieldData.find((customField) => customField.cf_id === targetArea);
                    if (targetCustomArea) {
                        const defaultAreaValue = targetCustomArea.fd_value;
                        defaultAreaLabel = defaultAreaValue;
                    }

                    const targetWorkingHours = "3061";
                    const targetCustomWorkingHours = customFieldData.find((customField) => customField.cf_id === targetWorkingHours);
                    if (targetCustomWorkingHours) {
                        const workingHours = targetCustomWorkingHours.fd_value;
                        defaultWorkingHoursLabel = workingHours;
                    }

                    const targetCompanyAddress = "3060";
                    const targetCustomCompanyAddress = customFieldData.find((customField) => customField.cf_id === targetCompanyAddress);
                    if (targetCustomCompanyAddress) {
                        customCompanyAddress = targetCustomCompanyAddress.fd_value;
                    }

                    const targetDate = "3059";
                    const targetCustomDate = customFieldData.find((customField) => customField.cf_id === targetDate);
                    if (targetCustomDate) {
                        customDate = targetCustomDate.fd_value;
                    }

                    const targetName = "3058";
                    const targetCompanyName = customFieldData.find((customField) => customField.cf_id === targetName);
                    if (targetCompanyName) {
                        customCompanyName = targetCompanyName.fd_value;
                    }

                    const targetService = "3057";
                    const targetCustomService = customFieldData.find((customField) => customField.cf_id === targetService);
                    if (targetCustomService) {
                        const defaultServiceValue = targetCustomService.fd_value;
                        customService = defaultServiceValue;
                    }


                    setProsCfInitVal(
                        {
                            owner: data.record.owner_id,
                            organizationId: data.record.organization_id,
                            organizationName: data.record.organization_title,
                            industry: data.record.in_id,
                            webUrls: data.record.organization_web_url,
                            phoneNumber: data.record.organization_contact,
                            address: data.record.organization_address,
                            country: data.record.organization_country_id,
                            companyName: customCompanyName,
                            companyAddress: customCompanyAddress,
                            workingHours: defaultWorkingHoursLabel,
                            area: defaultAreaLabel,
                            getDate: customDate,
                            getService: customService
                        }
                    );

                    setIsEditOrganization(true);
                } else {
                    // setProsCF([]);
                    setIsEditOrganization(true);
                }
            });
    };

    const gridColumn = [
        {
            headerName: t("OrganizationList_organization"),
            field: "",
            minWidth: 300,
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                    var initials = params.data.organization_title.split("")[0];
                    return (
                        <div className="d-flex">
                            <div className="avatar me-2">
                                <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                                    <div className="avatar-txt text-uppercase">{initials}</div>
                                </div>
                            </div>
                            <span>{params.data.organization_title}</span>
                        </div>
                    );

                    // return params.data.organization_title;
                } else {
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            },
            onCellClicked: function (params) {
                if (params.data) {
                    window.open(`${session.origin}/organizations/${params.data.organization_id}`, "_blank");
                }
            },
        },
        {
            headerName: t("OrganizationList_address"),
            field: "",
            minWidth: 200,
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                    var address = params.data.organization_address;
                    return (
                        <div className="d-flex">
                            <div style={{ padding: "8px 10px", lineHeight: 1 }}>
                                {address}
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            headerName: t("OrganizationList_website"),
            field: "",
            minWidth: 200,
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                    var webUrl = params.data.organization_web_url;
                    return (
                        <div className="d-flex">
                            <div style={{ padding: "8px 10px", lineHeight: 1 }}>
                                {webUrl}
                            </div>
                        </div>
                    );
                }
            },
        },
        { headerName: t("OrganizationList_contact"), field: "organization_contact" },
        { headerName: t("OrganizationList_industry"), field: "in_title" },
        { headerName: t("OrganizationList_country"), field: "country_name" },
        { headerName: t("OrganizationList_owned_by"), field: "user_name" },
        { headerName: "", field: "", cellClass: "center", cellRenderer: RenderProspectAction, pinned: "right" }
    ];

    const gridDataSource = useMemo(() => {
        return {
            rowCount: undefined,
            getRows: (params) => {
                axios
                    .get("ws/ws_organization.php", {
                        params: {
                            task: "4",
                            utoken: session.user_ac_token,
                            ctoken: session.company_token,
                            company: session.company_id,
                            page: Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
                            size: params.endRow - params.startRow,
                            sorting: "",
                            sortinType: "",
                            q: contactSearch,
                            user: userSearch,
                        },
                    })
                    .then((response) => {
                        const data = response.data;

                        if (data.status === 0) {
                            if (Number(data.totalCount) > 0) {
                                setisContact(true);
                            } else {
                                setisContact(false);
                            }
                        } else {
                            setisContact(false);
                        }

                        setGridCount(Number(data.totalCount));
                        setTimeout(() => {
                            var gridInfo = [];
                            var totalRecord = 0;
                            if (data.status === 0) {
                                gridInfo = data.record;
                                totalRecord = Number(data.totalCount);
                            } else {
                                gridInfo = [];
                                totalRecord = 0;
                            }

                            var rowsThisPage = gridInfo;
                            var lastRow = -1;

                            if (Number(totalRecord) <= params.endRow) {
                                lastRow = totalRecord;
                            }

                            params.successCallback(rowsThisPage, lastRow);
                        }, 500);
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            },
        };
    }, [userSearch, isContactSearch, session, trigger]); // eslint-disable-line react-hooks/exhaustive-deps

    const gridColDef = useMemo(() => {
        return {
            sortable: false,
            filter: false,
            suppressRowClickSelection: false
        };
    }, []);


    // USEEFFECT FUNCTION ------------------------------------------

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getUser(session);
            getIndustry();
            getCountry();
            getServices();
            getState();
            getCity();

            setTimeout(() => {
                setisLoading(false);
            }, 500);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container fluid className="p-4">
            {isLoading ? (
                <Loader />
            ) : (
                <Row>
                    <Col xxl={12}>
                        <Stack direction="horizontal" gap={3}>
                            <button type="button" className="btn op-button op-primary-color text-light" onClick={setisAddOrganization}>
                                <FontAwesomeIcon icon={faPlus} className="me-2" />
                                {t("OrganizationList_organization")}
                            </button>
                            <h6 className="ms-auto op-text-medium mt-2 ms-auto"> {gridCount > 1 ? gridCount + " Contacts" : gridCount === 1 ? gridCount + " Contact" : "No contact"}</h6>
                            {Number(session.role_id) > 0 && <FormSelect options={user} placeholder={t("OrganizationList_select_user")} isClearable={true} onChange={(e) => { e !== null ? setUserSearch(e.value) : setUserSearch("") }} width="250px" border={false} shadow={true} />}
                            <div className="d-flex">
                                <Form.Control type="text" className="border-0 me-1 shadow-sm" onChange={(e) => setContactSearch(e.target.value)} onKeyDown={handleKeyPress} placeholder={t("OrganizationList_search")} style={{ width: 250 }} />
                                <button type="button" className="btn btn-light shadow-sm" onClick={() => setisContactSearch(Math.random())}>
                                    <FontAwesomeIcon icon={faSearch} size="lg" />
                                </button>
                            </div>
                        </Stack>
                    </Col>
                    <Col xxl={12}>
                        <div style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={gridColumn}
                                    datasource={gridDataSource}
                                    defaultColDef={gridColDef}
                                    rowSelection={"multiple"}
                                    rowModelType={"infinite"}
                                    rowHeight={70}
                                    cacheBlockSize={50}
                                    cacheOverflowSize={2}
                                    maxConcurrentDatasourceRequests={2}
                                    infiniteInitialRowCount={10}
                                    maxBlocksInCache={2}
                                    // getRowId={gridRowId}
                                    pagination={true}
                                    paginationPageSize={50}
                                    paginationPageSizeSelector={false}
                                    suppressRowClickSelection={true}
                                    animateRows={true}
                                />
                            </div>
                        </div>
                        {!isContact && <NoRecord message={t("OrganizationList_no_matching_result_found_based_on_criteria")} description="Consider resetting your filters by making adjustments to your current filter settings." width={300} />}
                    </Col>
                </Row>
            )}

            <Modal show={isAddOrganization} onHide={setisAddOrganization}>
                <Formik
                    validationSchema={addOrganizationSchema}
                    onSubmit={(values) => sendAddOrganization(values)}
                    initialValues={{
                        in_id: '',
                        company_address: '',
                        company_services: '',
                        company_date: '',
                        company_area: '',
                        working_hours: '',
                        organization_title: '',
                        organization_web_url: '',
                        organization_contact: ''
                    }}
                >
                    {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title className="op-text-bigger">{t("OrganizationList_add_new_organization")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_organization_name")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("organization_title")}
                                        name="organization_title"
                                        placeholder={t("OrganizationList_organization_name")}
                                        isInvalid={touched.organization_title && !!errors.organization_title}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.organization_title}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_industry")}</Form.Label>
                                    <FormSelect
                                        options={industryList}
                                        placeholder={t("OrganizationList_select_industry")}
                                        onChange={(e) => {
                                            handleChange("in_id")(e.value)
                                        }} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_website_url")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("organization_web_url")}
                                        name="organization_web_url"
                                        placeholder={t("OrganizationList_website_url")}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_phone_number")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("organization_contact")}
                                        name="organization_contact"
                                        placeholder={t("OrganizationList_organization_phone_number")}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_organization_address")}</Form.Label>
                                    <Form.Control as={"textarea"} rows={3} type="text" onChange={handleChange("organization_address")} placeholder={t("OrganizationList_organization_address")} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_country")}</Form.Label>
                                    <FormSelect
                                        options={countryData}
                                        placeholder={t("OrganizationList_select_country")}
                                        onChange={(e) => {
                                            handleChange("country_id")(e.value)
                                        }} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_services")}</Form.Label>
                                    <FormSelect
                                        options={servicesData}
                                        placeholder={t("OrganizationList_company_services")}
                                        onChange={(e) => {
                                            handleChange("company_services")(e.value)
                                        }} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_date")}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        onChange={(e) => handleChange("company_date")(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_address")}</Form.Label>
                                    <Form.Control as={"textarea"} rows={3} type="text" onChange={handleChange("company_address")} placeholder={t("OrganizationList_company_address")} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("OrganizationList_working_hours")}</Form.Label>
                                    <Stack className="mb-2" direction="horizontal">
                                        {Array.isArray(workingHourData) && workingHourData.map((option) => (
                                            <Form.Check
                                                key={option.value}
                                                type="radio"
                                                id={`workingHour-${option.value}`}
                                                label={option.label}
                                                value={option.value}
                                                checked={selectedWorkingHour === option.value}
                                                onChange={(e) => {
                                                    setSelectedWorkingHour(e.target.value);
                                                    handleChange("working_hours")(option.label);
                                                }}
                                                className="me-2"
                                            />
                                        ))}
                                    </Stack>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_area")}</Form.Label>
                                    <FormSelect
                                        options={areaData}
                                        placeholder={t("OrganizationList_select_company_area")}
                                        onChange={(e) => {
                                            handleChange("company_area")(e.value)
                                        }} />
                                </Form.Group>

                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                    {t("OrganizationList_submit")}
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal show={isEditOrganization} onHide={setIsEditOrganization}>
                <Formik
                    validationSchema={editOrganizationSchema}
                    onSubmit={(values) => sendEditOrganization(values, values.owner, values.organizationId)}
                    initialValues={prosCfInitVal}
                >
                    {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title className="op-text-bigger">{t("OrganizationList_edit_organization")}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_organization_name")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("organizationName")}
                                        placeholder={t("OrganizationList_organization_name")}
                                        value={values.organizationName}
                                        isInvalid={errors.organizationName && touched.organizationName}
                                    />
                                    {errors.organizationName && touched.organizationName && <div className="op-error-message">{errors.organizationName}</div>}
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_industry")}</Form.Label>
                                    <FormSelect
                                        options={Array.isArray(industryList) ? industryList : []}
                                        placeholder={t("OrganizationList_select_industry")}
                                        onChange={(e) => {
                                            handleChange("industry")(e.value)
                                        }}
                                        valueDefault={Array.isArray(industryList) ? industryList.find(option => option.value === String(values.industry)) : undefined}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_website_url")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("webUrls")}
                                        placeholder={t("OrganizationList_website_url")}
                                        value={values.webUrls}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_phone_number")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleChange("phoneNumber")}
                                        placeholder={t("OrganizationList_organization_phone_number")}
                                        value={values.phoneNumber}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_organization_address")}</Form.Label>
                                    <Form.Control
                                        as={"textarea"}
                                        rows={3} type="text"
                                        onChange={handleChange("address")}
                                        placeholder={t("OrganizationList_organization_address")}
                                        value={values.address}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_country")}</Form.Label>
                                    <FormSelect
                                        options={Array.isArray(countryData) ? countryData : []}
                                        placeholder={t("OrganizationList_select_country")}
                                        onChange={(e) => {
                                            handleChange("country")(e.value)
                                        }}
                                        valueDefault={Array.isArray(countryData) ? countryData.find(option => option.value === String(values.country)) : undefined}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_services")}</Form.Label>
                                    <FormSelect
                                        options={Array.isArray(servicesData) ? servicesData : []}
                                        placeholder={t("OrganizationList_company_services")}
                                        onChange={(e) => {
                                            handleChange("getService")(e.value);
                                        }}
                                        valueDefault={Array.isArray(servicesData) ? servicesData.find(option => option.value === String(values.getService)) : undefined}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_name")}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={(e) => handleChange("companyName")(e.target.value)}
                                        placeholder={t("OrganizationList_company_name")}
                                        value={values.companyName}
                                    />

                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_date")}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={values.getDate}
                                        onChange={(e) => handleChange("getDate")(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_address")}</Form.Label>
                                    <Form.Control
                                        as={"textarea"}
                                        rows={3}
                                        type="text"
                                        onChange={handleChange("companyAddress")}
                                        placeholder={t("OrganizationList_company_address")}
                                        value={values.companyAddress}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>{t("OrganizationList_working_hours")}</Form.Label>
                                    <Stack className="mb-2" direction="horizontal">
                                        {Array.isArray(workingHourData) && workingHourData.map((option) => (
                                            <Form.Check
                                                key={option.value}
                                                type="radio"
                                                id={`workingHour-${option.value}`}
                                                label={option.label}
                                                value={option.value}
                                                checked={values.workingHours === option.value}
                                                onChange={(e) => {
                                                    handleChange("workingHours")(e.target.value);
                                                }}
                                                className="me-2"
                                            />
                                        ))}
                                    </Stack>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>{t("OrganizationList_company_area")}</Form.Label>
                                    <FormSelect
                                        options={Array.isArray(areaData) ? areaData : []}
                                        placeholder={t("OrganizationList_select_company_area")}
                                        onChange={(e) => {
                                            handleChange("area")(e.value);
                                        }}
                                        valueDefault={Array.isArray(areaData) ? areaData.find(option => option.value === String(values.area)) : undefined}
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                    {t("OrganizationList_update")}
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Container >
    );
};

export default OrganizationList;
