import { useState, useRef, useEffect, useCallback } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const DealPerformanceReport = () => {
    const init = useRef(false);
    const { session } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [buList, setBuList] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [buID, setBuID] = useState("");

    const { t } = useTranslation();

    const getAllBU = () => {
        
        axios.get("ws/ws_setting.php",{
            params:{
                task: "getAllBU",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var buData = data.record.map((data)=>{
                    return {label: data.unit_title, value: data.business_unit_id};
                });

                setBuList(buData);
            }else{
                setBuList([]);
            }
        }).catch(error => {
            setBuList([]);
        })
    }

    const getChartData = useCallback(()=>{

        axios.get("ws/ws_leadroute.php", {
            params:{
                task: "inboundLeadsReportV3",
                ds: startDate,
                de: endDate,
                business_unit_id: buID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then((res)=>{
            let data = res.data;

            if (data.record) {

                var chartData = data.record.map((record)=>{
                    return {
                        name: record.user_name,
                        data1: record.TOTAL_ACCEPTED,
                        data2: record.TOTAL_CALLS,
                        data3: record.TOTAL_CALLS_CONVERTED,
                        data4: record.TOTAL_OPEN_DEALS,
                        data5: record.TOTAL_FRESH_DEAL,
                        data6: record.TOTAL_ACTIVE_DEAL,
                        data7: record.TOTAL_INACTIVE_DEAL,
                        data8: record.TOTAL_WON_DEALS,
                        data9: record.TOTAL_LOST_DEALS,
                        data10: record.TOTAL_LEADS,
                    };
                });

                setChartData(chartData);

            }else{
                setChartData([]);
            }
        });
    }, [startDate, endDate, buID, session]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div style={{color: payload[0].color}}>{`${t('Report_DealPerformance_BarName1')}: ${payload[0].value}`}</div>
                        <div style={{color: payload[1].color}}>{`${t('Report_DealPerformance_BarName2')}: ${payload[1].value}`}</div>
                        <div style={{color: payload[2].color}}>{`${t('Report_DealPerformance_BarName3')}: ${payload[2].value}`}</div>
                        <div style={{color: payload[3].color}}>{`${t('Report_DealPerformance_BarName4')}: ${payload[3].value}`}</div>
                        <div style={{color: payload[4].color}}>{`${t('Report_DealPerformance_BarName5')}: ${payload[4].value}`}</div>
                        <div style={{color: payload[5].color}}>{`${t('Report_DealPerformance_BarName6')}: ${payload[5].value}`}</div>
                        <div style={{color: payload[6].color}}>{`${t('Report_DealPerformance_BarName7')}: ${payload[6].value}`}</div>
                        <div style={{color: payload[7].color}}>{`${t('Report_DealPerformance_BarName8')}: ${payload[7].value}`}</div>
                        <div style={{color: payload[8].color}}>{`${t('Report_DealPerformance_BarName9')}: ${payload[8].value}`}</div>
                        <div style={{color: payload[9].color}}>{`${t('Report_DealPerformance_BarName10')}: ${payload[9].value}`}</div>
                    </Card.Body>
                </Card>

            );
        }
        
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllBU();
            getChartData();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            {isLoading ? (<Loader />):(
                <Container fluid>
                    <div className="mb-4 mt-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control 
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <FormSelect
                                    placeholder={t('Report_AllBU')}
                                    options={buList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setBuID(e ? e.value : "")}}
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light shadow ms-auto" onClick={()=>{getChartData()}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div>
                        <Card>
                            <Card.Body className="shadow-sm border-0">
                                <ResponsiveContainer width="100%" height={500}>
                                    <BarChart
                                    width={500}
                                    height={300}
                                    layout="vertical"
                                    data={chartData}
                                    >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number"/>
                                    <YAxis dataKey="name" type="category" />
                                    <Tooltip content={<CustomTooltip />}/>
                                    <Legend />
                                    <Bar dataKey="data1" stackId="a" fill="#0089cb" name={t('Report_DealPerformance_BarName1')} />
                                    <Bar dataKey="data2" stackId="a" fill="#79d3ff" name={t('Report_DealPerformance_BarName2')}/>
                                    <Bar dataKey="data3" stackId="a" fill="#00bf9d" name={t('Report_DealPerformance_BarName3')} />
                                    <Bar dataKey="data4" stackId="a" fill="#8884d8" name={t('Report_DealPerformance_BarName4')} />
                                    <Bar dataKey="data5" stackId="a" fill="#8bffea" name={t('Report_DealPerformance_BarName5')} />
                                    <Bar dataKey="data6" stackId="a" fill="#e4c272" name={t('Report_DealPerformance_BarName6')} />
                                    <Bar dataKey="data7" stackId="a" fill="#ff0000" name={t('Report_DealPerformance_BarName7')} />
                                    <Bar dataKey="data8" stackId="a" fill="#ffca00" name={t('Report_DealPerformance_BarName8')} />
                                    <Bar dataKey="data9" stackId="a" fill="#00ff09" name={t('Report_DealPerformance_BarName9')} />
                                    <Bar dataKey="data10" stackId="a" fill="#82ca9d" name={t('Report_DealPerformance_BarName10')} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            )}
        </div>
    );

}

export default DealPerformanceReport;