import { useState, useRef, useEffect } from "react";
import { Container, Nav  } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faUser } from "@fortawesome/pro-solid-svg-icons";
import SettingBulkUpdateBuyer from "./SettingBulkUpdateBuyer";
import SettingBulkUpdateLead from "./SettingBulkUpdateLead";

const SettingBulkUpdate = () => {
    const init = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isTab, setIsTab] = useState("buyer");

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const RenderTab = ({ tab }) => {
        switch (tab) {
            case 'buyer':
                
                return(
                    <div className="mt-3">
                        <SettingBulkUpdateBuyer/>
                    </div>
                );
            case 'lead':

                return(
                    <div className="mt-3">
                        <SettingBulkUpdateLead/>
                    </div>
                );
            default:
                return null;
        }
    }

    return(
        <Container fluid>
            {isLoading ? <Loader/> : 
            <div className="my-3 mx-1">
                <Nav variant="underline" defaultActiveKey="buyer" style={{ borderBottom: "1px solid #ddd" }}>
                    <Nav.Item>
                        <Nav.Link className="nav-link px-2 py-3" eventKey={"buyer"} activeclassname="active" onClick={() => {setIsTab("buyer")}}>
                            <FontAwesomeIcon icon={faUser} className="me-2" size="lg"/>
                            Buyer
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="nav-link px-2 py-3" eventKey={"lead"} activeclassname="active" onClick={() => {setIsTab("lead")}}>
                            <FontAwesomeIcon icon={faHandshake} className="me-2" size="lg"/>
                            Leads
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <RenderTab tab={isTab}/>
            </div>
            }
        </Container>
    )

}

export default SettingBulkUpdate;

