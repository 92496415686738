import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Stack, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
// import NoRecord from "../includes/NoRecord";
import moment from "moment";
import { useTranslation } from "react-i18next";

const DeviceNotification = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [userList, setUserList] = useState([]);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const getAllUser = () => {
        
        axios.get("ws/ws_user.php",{
            params:{
                task: "4a",
                user_tag: "",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var userData = data.record.map(data=>{
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingDeviceNot_headername_1'),
            field: "date_time_create_inapp",
            resizable: true,
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(params.data.date_time_create_inapp).format('lll');
                }
            }
        },
        {
            headerName: t('SettingDeviceNot_headername_2'),
            field: "user_name",
            resizable: true,
        },
        {
            headerName: t('SettingDeviceNot_headername_3'),
            field:"device_token",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingDeviceNot_headername_4'),
            resizable: true,
            width: 100,
            cellRenderer: (params)=>{
                if (params.data) {
                    return(
                        <div>
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={()=>{handleDelete(params.data.inapp_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const handleDelete = (id) => {
        Swal.fire(
            {
                icon: "warning",
                title: t('SettingDeviceNot_alert_delete_title'),
                text: t('SettingDeviceNot_alert_delete_text'),
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t('SettingDeviceNot_alert_delete_confirmText')
            }
        ).then(result=>{
            if (result.isConfirmed) {

                axios.get('ws/ws_device.php',{
                    params:{
                        task: "remove",
                        inapp_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingDeviceNot_alert_successTitle'),
                            text: t('SettingDeviceNot_alert_successText'),
                            timer: 1500
                        }).then(result=>{
                            setIsLoading(true);
                            getGridData();
                        });
                    }else{
                        Swal.fire(
                            {
                                icon: "error",
                                title: t('SettingDeviceNot_alert_failedTitle'),
                                text: t('SettingDeviceNot_alert_failedText')
                            }
                        );
                    }
                })
                .catch(error => {
                    Swal.fire(
                        {
                            icon: "error",
                            title: error.message
                        }
                    );
                });
            }
        });
    }

    const getGridData = (userID)=>{

        axios.get('ws/ws_device.php',{
            params:{
                task: "list",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var gridData = [];
                data.record.sort(function(a, b) { return new Date(b.date_time_create_inapp) - new Date(a.date_time_create_inapp) });
                if (userID) {
                    data.record.forEach((data)=>{
                        if (data.user_id === userID) {
                            gridData.push(data);
                        }
                    });
                    setRecordFound(gridData.length);
                    setRowData(gridData);

                    if (gridData.length > 0) {
                        setIsGrid(true);
                    }else{
                        setIsGrid(false);
                    }
                }else{
                    setRecordFound(data.record.length);
                    setRowData(data.record);
                    setIsGrid(true);
                }

                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);

                setIsLoading(false);
            }
        });

    };

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            getGridData("");
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            { isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mb-3 mt-3">
                        <div className="mb-3 mt-3">
                            <Stack className="d-flex justify-content-center align-items-center" direction="horizontal" gap={1}>
                                <Form.Group style={{width: '250px'}}>
                                    <FormSelect
                                        placeholder={t('SettingDeviceNot_dropdown_placeholder_user')}
                                        options={userList}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(e)=>{
                                            getGridData(e ? e.value:"");
                                        }}
                                    />
                                </Form.Group>
                                <div className="px-2 ms-auto">{recordFound === 1 ? t('SettingDeviceNot_record_one', {count: recordFound}): recordFound > 1 ? t('SettingDeviceNot_record_many', {count: recordFound}):t('SettingDeviceNot_record_no')}</div>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={reportColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={80}
                                    pagination={false}
                                    enableCellTextSelection={true}
                                    // paginationPageSize={30}
                                    // paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                        {/* {!isGrid && <NoRecord message="No record at the moment." width={200}/>} */}
                    </div>
                </Container>
            ) }
        </div>
    );

}

export default DeviceNotification;