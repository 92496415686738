import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceThinking } from "@fortawesome/pro-duotone-svg-icons";
const NoRecord = ({ message, description, height = 300, iconSize = 100, mt = true }) => {
  return (
    <div className={`d-flex justify-content-center align-items-center ${mt ? "mt-5" : ""}`} style={{ height: height }}>
      <div className="text-center">
        <FontAwesomeIcon icon={faFaceThinking} style={{ "--fa-primary-color": "#183153", "--fa-secondary-color": "#ffd43b", "--fa-secondary-opacity": "1", fontSize: iconSize }} size="2xl" />
        <div className="mt-3" style={{ fontSize: 20, fontWeight: "bold" }}>
          {message}
        </div>
        <div className="mt-1" style={{ fontSize: 15 }}>
          {description}
        </div>
      </div>
    </div>
  );
};

export default NoRecord;
