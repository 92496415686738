import React, { useState, useEffect, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import axios from "../api/axios";
import moment from "moment";
import '../lib/css/LeadAgingReport.css';
import { FormSelect, FormDate } from "../includes/FormCustom";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from 'react-to-print';


const LeadAgingReport = () => {
    const { t } = useTranslation();
    const targetRef = useRef();
    const initialized = useRef(false);
    const { session } = useAuth();
    const cToken = session.company_token;
    const uToken = session.user_ac_token;
    const [extractedData, setExtractedData] = useState([]);
    const formattedTodayDate = moment().format('YYYY-MM-DD');
    const formattedFiveYearsAgoDate = moment().subtract(5, 'years').format('YYYY-MM-DD');
    const [dateRange, setdateRange] = useState({
        ds: formattedFiveYearsAgoDate,
        de: formattedTodayDate,
    });
    const [loadingGrid1, setLoadingGrid1] = useState(true);

    // Gamuda state
    const formattedTodayDateGL = moment().format('YYYY-MM-DD');
    const formattedOneMonthAgoDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
    const [dateRangeGL, setdateRangeGL] = useState({
        ds: formattedOneMonthAgoDate,
        de: formattedTodayDateGL,
    });
    const [extractedDataGL, setExtractedDataGL] = useState([]);
    const [selectedGLProject, setSelectedGLProject] = useState('all');
    const [GLPipelineOptions, setGLPipelineOptions] = useState([]);
    const [selectedGLPipeline, setSelectedGLPipeline] = useState('');
    const [selectedGLSourceCat, setSelectedGLSourceCat] = useState('0');
    const [pipelineSelectKey, setPipelineSelectKey] = useState(0);
    const [loadingGLGrid, setLoadingGLGrid] = useState(false);
    const [selectedGamudaOptions, setSelectedGamudaOptions] = useState('');
    const [columnDefsToUse, setColumnDefsToUse] = useState('');
    const [columnDefsToUse2, setColumnDefsToUse2] = useState('');
    const [getGamudaTask, setGetGamudaTask] = useState('');
    const [getGamudaCompId, setGetGamudaCompId] = useState('');


    const gamudaOptions = [
        {
            label: "All Project",
            value: "all",
            ctoken: "4d10baf95d0fde0172fb8c7fd10be267",
            utoken: "38cecfadf5b89105aa879bab98b5fff0",
        },
        {
            label: "Gamuda Gardens",
            value: "gardens",
            ctoken: "48ade9786a2d11568db7aad9657a90de",
            utoken: "a04fcdaedc1a3e10695d468258e3f217",
        },
        {
            label: "Gamuda Cove",
            value: "cove",
            ctoken: "c7a341f4b174827d2f2b88a947554855",
            utoken: "243d7203cd06bb5d2fadbdafa32e213e",
        },
        {
            label: "Gamuda Jade Hills",
            value: "jadehill",
            ctoken: "3f684e70f4379ce5dc998a5b1d433751",
            utoken: "f18e8821d0a8130bbb43f7b158fccedc",
        },
        {
            label: "Gamuda Kundang Est",
            value: "kundang",
            ctoken: "a0e169f6cc0fb4190de3250f4e328460",
            utoken: "578fc134bda3733b1beca2f53d1de746",
        },
        {
            label: "Gamuda 257 Kemuning",
            value: "kemuning",
            ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6",
            utoken: "5fd3e152170afe3aefa48cb14fd970d8",
        },
        {
            label: "Gamuda Bukit Bantayan",
            value: "bantayan",
            ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec",
            utoken: "b3376001d54890bc0858e9a66fd4e0fe",
        },
        {
            label: "Gamuda Highpark Suites",
            value: "highpark",
            ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8",
            utoken: "ea404a7b4d4ca65745305808635ce6c3",
        },
    ];

    const gamudaApiOptions = [
        { label: "Overall", value: "" },
        { label: "AI Outreach", value: "ai_outreach" },
    ];

    const gamudaSourceCategory = [
        { label: "All Source Category", value: "0" },
        { label: "Inbound Leads", value: "inbound_leads" },
        { label: "Walk In", value: "walk_in" },
        { label: "Event and Roadshow", value: "event_and_roadshow" },
        { label: "Traditional Media", value: "traditional_media" },
    ];


    const fetchData = async () => {
        setLoadingGrid1(true);

        const responce = 'ext/gamuda/api.php?';
        const params = {
            ctoken: cToken,
            de: dateRange.de,
            ds: dateRange.ds,
            task: 'getAgingReportV3',
            uid: '0',
            utoken: uToken
        }

        try {
            const response = await axios.get(responce, { params });
            const data = response.data;

            const extractedData = data.record.secondary_info;
            const filteredData = extractedData.filter(item => item.total_leads);
            const sortedData = filteredData.sort((a, b) => a.user_name.localeCompare(b.user_name));
            setExtractedData(sortedData);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingGrid1(false);
        }
    };

    const getUpdatedData = (dateRange = {}) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;

        const responce = 'ext/gamuda/api.php?';
        const params = {
            ctoken: cToken,
            de: endDate,
            ds: startDate,
            task: 'getAgingReportV3',
            uid: '0',
            utoken: uToken
        }

        axios.get(responce, { params })
            .then((response) => {
                const extractedData = response.data.record.secondary_info;
                const filteredData = extractedData.filter(item => item.total_leads);
                const sortedData = filteredData.sort((a, b) => a.user_name.localeCompare(b.user_name));
                setExtractedData(sortedData);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            })
            .finally(() => {
                setLoadingGrid1(false);
            });
    };

    function customRound(value) {
        const decimalPart = value % 1;
        if (decimalPart >= 0.5) {
            return Math.ceil(value);
        } else {
            return Math.floor(value);
        }
    }

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const handleApplyButtonClick = () => {
        setLoadingGrid1(true)
        const dateRangeValues = {
            ds: dateRange.ds,
            de: dateRange.de,
        };

        getUpdatedData(dateRangeValues);
    }

    const numberFormatter = (params) => (params.value !== null && params.value !== undefined ? params.value : 0);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100vh", paddingTop: 10, paddingBottom: 10 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    // COLUMNDEF
    const calculateTotalForRow = (data) => {
        const totalRow = {
            user_name: "Total",
            total_leads: 0,
            total_attended_leads: 0,
            total_unattended_leads: 0,
            total_unattended_leads_aging: {
                "5Days": 0,
                "10Days": 0,
                "15Days": 0,
                "30Days": 0,
                "moreThan30Days": 0,
            },
        };

        data.forEach((item) => {
            totalRow.total_leads += item.total_leads || 0;
            totalRow.total_attended_leads += parseInt(item.total_attended_leads) || 0;
            totalRow.total_unattended_leads += parseInt(item.total_unattended_leads) || 0;

            if (item.total_unattended_leads_aging) {
                ["5Days", "10Days", "15Days", "30Days", "moreThan30Days"].forEach((category) => {
                    totalRow.total_unattended_leads_aging[category] += parseInt(item.total_unattended_leads_aging?.[category]) || 0;
                });
            }
        });

        return totalRow;
    };

    const columnDefs = [
        { headerName: t("LeadAging_sales_rep"), field: "user_name", width: 200, headerClass: 'header-spacing' },
        { headerName: t("LeadAging_total_deals"), field: "total_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_total_attended_leads"), field: "total_attended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_total_unattended_leads"), field: "total_unattended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        {
            headerName: t("LeadAging_pending_aging"), headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_unattended_leads_aging.5Days', width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_unattended_leads_aging.10Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_unattended_leads_aging.15Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_unattended_leads_aging.30Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_unattended_leads_aging.moreThan30Days", width: 150, valueFormatter: numberFormatter },
            ]
        },
    ];

    // COLUMNDEF 2
    const timeFormatter = (params) => {
        if (params.value !== null && params.value !== undefined) {
            return customRound(params.value).toString();
        } else {
            return "0";
        }
    };

    const callDurationFormatter = (params) => {
        if (params.value && params.value.total_duration && params.value.total_calls) {
            const averageCallDuration = customRound(params.value.total_duration / params.value.total_calls);
            return averageCallDuration.toString();
        } else {
            return "0";
        }
    };

    let totalFollowUp = 0;

    const followUpFormatter = (params) => {
        const isTotalRow = params.node.rowIndex === params.api.getDisplayedRowCount() - 1;

        if (!isTotalRow) {
            if (params.value && params.value.total_activities && params.data?.total_average_call_duration) {
                const totalCalls = params.data.total_average_call_duration.total_calls || 0;
                const totalAttendedLeads = params.data.total_attended_leads || 0;
                const averageFollowUp = totalAttendedLeads > 0
                    ? ((params.value.total_activities * 1 + totalCalls * 1) / totalAttendedLeads)
                    : 0;

                totalFollowUp += parseFloat(averageFollowUp);
                return isFinite(averageFollowUp) ? averageFollowUp.toFixed(2).toString() : "0.00";
            } else {
                return "0.00";
            }
        } else {
            const getTotalAverage = totalFollowUp / params.node.rowIndex;
            return isFinite(getTotalAverage) ? getTotalAverage.toFixed(2).toString() : "0.00";
        }
    };

    const calculateTotalForRow2 = (data) => {
        const totalRow = {
            id: "total",
            user_name: "Total",
            total_attended_leads: 0,
            total_attended_response: 0,
            total_average_call_duration: {
                total_duration: 0,
                total_calls: 0,
            },
            total_average_followup_per_lead: {
                total_calls: 0,
                total_activities: 0,
            },
            total_attended_leads_aging: {
                "5Days": 0,
                "10Days": 0,
                "15Days": 0,
                "30Days": 0,
                "moreThan30Days": 0,
            },
        };

        data.forEach((item) => {
            totalRow.total_attended_leads += (parseInt(item.total_attended_leads) || 0);
            totalRow.total_attended_response += (customRound(item.total_attended_response) || 0);

            const averageCallDuration = customRound(item.total_average_call_duration ? customRound(item.total_average_call_duration.total_duration / item.total_average_call_duration.total_calls) : 0);
            totalRow.total_average_call_duration.total_duration += averageCallDuration;
            totalRow.total_average_call_duration.total_calls += 1;

            const activities = item.total_average_followup_per_lead + item.total_average_followup_per_lead?.total_activities || 0;
            const callDuration = (item.total_average_call_duration || 0) + (item.total_average_call_duration?.total_calls || 0);
            const totalAttendedLeads = item.total_attended_leads;

            const averageFollowupPerLead = item.total_average_call_duration ? (activities * 1 + callDuration * 1) / totalAttendedLeads || 0 : 0;

            totalRow.total_average_followup_per_lead.total_activities += averageFollowupPerLead;
            totalRow.total_average_followup_per_lead.total_calls += 1;

            // Calculate total for each aging category
            ["5Days", "10Days", "15Days", "30Days", "moreThan30Days"].forEach((category) => {
                totalRow.total_attended_leads_aging[category] += parseInt(item.total_attended_leads_aging?.[category]) || 0;
            });
        });
        return totalRow;
    };

    const columnDefs2 = [
        { headerName: t("LeadAging_sales_rep"), field: "user_name", width: 180, headerClass: 'header-spacing' },
        { headerName: t("LeadAging_total_attended_leads"), field: "total_attended_leads", width: 150, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_first_response_time"), field: "total_attended_response", width: 180, headerClass: 'header-spacing', valueFormatter: timeFormatter },
        {
            headerName: t("LeadAging_average_call_duration"),
            field: "total_average_call_duration",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: callDurationFormatter
        },
        {
            headerName: t("LeadAging_average_no_follow_up_per_lead"),
            field: "total_average_followup_per_lead", // Assuming "total_average_followup_per_lead" is an object with "total_activities" and "total_calls"
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: followUpFormatter
        },
        {
            headerName: "Aging", headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_attended_leads_aging.5Days', width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_attended_leads_aging.10Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_attended_leads_aging.15Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_attended_leads_aging.30Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_attended_leads_aging.moreThan30Days", width: 100, valueFormatter: numberFormatter },
            ]
        },
    ];

    // Gamuda Land API ----------------------------------------------------------------------------------------
    const gamudaCompId = [
        { label: "Gamuda Gardens", value: "gardens", id: "180" },
        { label: "Gamuda Jade Hills", value: "jadehill", id: "190" },
        { label: "Gamuda Cove", value: "cove", id: "200" },
        { label: "Gamuda 257 Kemuning", value: "kemuning", id: "203" },
        { label: "Gamuda Horizon Hills", value: "horizon", id: "210" },
        { label: "Gamuda Bukit Bantayan", value: "bantayan", id: "212" },
        { label: "Gamuda Highpark Suites", value: "highpark", id: "213" },
        { label: "Gamuda HQ", value: "all", id: "251" },
    ];

    const onchangeDateRangeGL = (mode, value) => {
        setdateRangeGL((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const containerStyleGL = useMemo(() => ({ width: "100%", height: "75vh", paddingTop: 10, paddingBottom: 10 }), []);
    const gridStyleGL = useMemo(() => ({ height: "100%", width: "100%" }), []);


    // Get function
    const fetchDataGL = async () => {
        setLoadingGrid1(true);

        const company = gamudaCompId.find(item => item.id === session.company_id);
        setGetGamudaCompId(
            {
                id: company.id,
                value: company.value
            }
        );

        try {
            const response = await axios.get("ext/gamuda/api.php", {
                params: {
                    category_type: 0,
                    ctoken: cToken,
                    de: dateRangeGL.de,
                    ds: dateRangeGL.ds,
                    pipeline_id: 0,
                    project: company.value,
                    source_category_id: "",
                    task: 'getAgingReportV7_GLHQ_overall',
                    uid: '0',
                    utoken: uToken
                }
            })

            const data = response.data;

            if (data.record) {
                const extractedData = data.record.secondary_info;
                const filteredData = extractedData.filter(item => item.total_leads);

                if (filteredData.length > 0 && filteredData[0].user_name) {
                    const sortedData = filteredData.sort((a, b) => a.user_name.localeCompare(b.user_name));
                    setExtractedDataGL(sortedData);
                } else if (filteredData.length > 0 && filteredData[0].company_title) {
                    const sortedData = filteredData.sort((a, b) => a.company_title.localeCompare(b.company_title));
                    setExtractedDataGL(sortedData);
                } else {
                    setExtractedDataGL([]);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingGrid1(false);
        }
    };


    const gamudaProject = [
        { project: "gardens", ctoken: "48ade9786a2d11568db7aad9657a90de", utoken: "a04fcdaedc1a3e10695d468258e3f217" },
        { project: "cove", ctoken: "c7a341f4b174827d2f2b88a947554855", utoken: "243d7203cd06bb5d2fadbdafa32e213e" },
        { project: "jadehill", ctoken: "3f684e70f4379ce5dc998a5b1d433751", utoken: "f18e8821d0a8130bbb43f7b158fccedc" },
        { project: "kundang", ctoken: "a0e169f6cc0fb4190de3250f4e328460", utoken: "578fc134bda3733b1beca2f53d1de746" },
        { project: "kemuning", ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6", utoken: "5fd3e152170afe3aefa48cb14fd970d8" },
        { project: "bantayan", ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec", utoken: "b3376001d54890bc0858e9a66fd4e0fe" },
        { project: "highpark", ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8", utoken: "ea404a7b4d4ca65745305808635ce6c3" },
        { project: "all", ctoken: "4d10baf95d0fde0172fb8c7fd10be267", utoken: "38cecfadf5b89105aa879bab98b5fff0" }
    ];

    const getTokenByProject = (selectedProject) => {
        return gamudaProject.find(token => token.project === selectedProject) || {};
    }

    const handleApplyButtonClickGL = () => {
        setLoadingGLGrid(true)
        const dateRangeValues = {
            ds: dateRangeGL.ds,
            de: dateRangeGL.de,
        };

        let optionToken = {};
        let token = {};

        if (getGamudaCompId.value !== selectedGLProject && selectedGLProject !== 'all') {
            if (selectedGLProject == "gardens") {
                optionToken = {
                    ctoken: "48ade9786a2d11568db7aad9657a90de",
                    utoken: "a04fcdaedc1a3e10695d468258e3f217",
                    project: "gardens"
                }
            } else if (selectedGLProject == "cove") {
                optionToken = {
                    ctoken: "c7a341f4b174827d2f2b88a947554855",
                    utoken: "243d7203cd06bb5d2fadbdafa32e213e",
                    project: "cove"
                }
            } else if (selectedGLProject == "jadehill") {
                optionToken = {
                    ctoken: "3f684e70f4379ce5dc998a5b1d433751",
                    utoken: "f18e8821d0a8130bbb43f7b158fccedc",
                    project: "jadehill"
                }
            } else if (selectedGLProject == "kundang") {
                optionToken = {
                    ctoken: "a0e169f6cc0fb4190de3250f4e328460",
                    utoken: "578fc134bda3733b1beca2f53d1de746",
                    project: "kundang"
                }
            } else if (selectedGLProject == "kemuning") {
                optionToken = {
                    ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6",
                    utoken: "5fd3e152170afe3aefa48cb14fd970d8",
                    project: "kemuning"
                }
            } else if (selectedGLProject == "bantayan") {
                optionToken = {
                    ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec",
                    utoken: "b3376001d54890bc0858e9a66fd4e0fe",
                    project: "bantayan"
                }
            } else if (selectedGLProject == "highpark") {
                optionToken = {
                    ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8",
                    utoken: "ea404a7b4d4ca65745305808635ce6c3",
                    project: "highpark"
                }
            } else if (selectedGLProject == "all") {
                optionToken = {
                    ctoken: "4d10baf95d0fde0172fb8c7fd10be267",
                    utoken: "38cecfadf5b89105aa879bab98b5fff0",
                    project: "all"
                }
            }
        } else {
            token = getTokenByProject(getGamudaCompId.value);
        }

        getUpdatedDataGL(dateRangeValues, token, optionToken, selectedGLPipeline, selectedGamudaOptions, selectedGLSourceCat);
    }

    const getUpdatedDataGL = async (dateRange = {}, token, optionToken, selectedPipeline, selectedOptions, selectedSource) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;

        const getTask = selectedOptions === 'ai_outreach' ? 'getAgingReportV6_GLHQ_outreach' : 'getAgingReportV7_GLHQ_overall';
        setGetGamudaTask(getTask);
        setColumnDefsToUse(getTask == 'getAgingReportV6_GLHQ_outreach' ? columnDefsGLOutreach : columnDefsGL);
        setColumnDefsToUse2(getTask == 'getAgingReportV6_GLHQ_outreach' ? columnDefs2GLOutreach : columnDefs2GL)

        try {
            const response = await axios.get("ext/gamuda/api.php", {
                params: {
                    category_type: selectedSource,
                    ctoken: token.ctoken || optionToken.ctoken,
                    de: endDate,
                    ds: startDate,
                    pipeline_id: selectedPipeline,
                    project: token.project || optionToken.project,
                    source_category_id: "",
                    task: getTask,
                    uid: '0',
                    utoken: token.utoken || optionToken.utoken
                }
            })

            if (response.data.status === 0) {
                const extractedData = response.data.record.secondary_info || '';

                if (!extractedData) {
                    setExtractedDataGL([])
                } else {
                    const filteredData = extractedData.filter(item => item.total_leads);

                    if (filteredData && filteredData[0].user_name) {
                        const sortedData = filteredData.sort((a, b) => a.user_name.localeCompare(b.user_name));
                        setExtractedDataGL(sortedData);
                    } else if (filteredData && filteredData[0].company_title) {
                        const sortedData = filteredData.sort((a, b) => a.company_title.localeCompare(b.company_title));
                        setExtractedDataGL(sortedData);
                    } else {
                        setExtractedDataGL([]);
                    }
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoadingGLGrid(false);
        }
    };

    const calculateTotalForRowGL = (data) => {
        const totalRow = {
            company_title: "Total",
            total_leads: 0,
            total_attended_leads: 0,
            total_unattended_leads: 0,
            total_unattended_leads_aging: {
                "5Days": 0,
                "10Days": 0,
                "15Days": 0,
                "30Days": 0,
                "moreThan30Days": 0,
            },
        };

        data.forEach((item) => {
            totalRow.total_leads += item.total_leads || 0;
            totalRow.total_attended_leads += parseInt(item.total_attended_leads) || 0;
            totalRow.total_unattended_leads += parseInt(item.total_unattended_leads) || 0;

            if (item.total_unattended_leads_aging) {
                ["5Days", "10Days", "15Days", "30Days", "moreThan30Days"].forEach((category) => {
                    totalRow.total_unattended_leads_aging[category] += parseInt(item.total_unattended_leads_aging?.[category]) || 0;
                });
            }
        });

        return totalRow;
    };

    const calculateTotalForRow2GL = (data) => {
        const totalRow = {
            id: "total",
            company_title: "Total",
            total_attended_leads: 0,
            total_attended_response: 0,
            total_average_call_duration: {
                total_duration: 0,
                total_calls: 0,
            },
            total_average_followup_per_lead: {
                total_calls: 0,
                total_activities: 0,
            },
            total_attended_leads_aging: {
                "5Days": 0,
                "10Days": 0,
                "15Days": 0,
                "30Days": 0,
                "moreThan30Days": 0,
            },
        };

        data.forEach((item) => {
            totalRow.total_attended_leads += (parseInt(item.total_attended_leads) || 0);
            totalRow.total_attended_response += (customRound(item.total_attended_response) || 0);

            const averageCallDuration = customRound(item.total_average_call_duration ? customRound(item.total_average_call_duration.total_duration / item.total_average_call_duration.total_calls) : 0);
            totalRow.total_average_call_duration.total_duration += averageCallDuration;
            totalRow.total_average_call_duration.total_calls += 1;

            const activities = item.total_average_followup_per_lead + item.total_average_followup_per_lead?.total_activities || 0;
            const callDuration = (item.total_average_call_duration || 0) + (item.total_average_call_duration?.total_calls || 0);
            const totalAttendedLeads = item.total_attended_leads;

            const averageFollowupPerLead = item.total_average_call_duration ? (activities * 1 + callDuration * 1) / totalAttendedLeads || 0 : 0;

            totalRow.total_average_followup_per_lead.total_activities += averageFollowupPerLead;
            totalRow.total_average_followup_per_lead.total_calls += 1;

            // Calculate total for each aging category
            ["5Days", "10Days", "15Days", "30Days", "moreThan30Days"].forEach((category) => {
                totalRow.total_attended_leads_aging[category] += parseInt(item.total_attended_leads_aging?.[category]) || 0;
            });
        });
        return totalRow;
    };

    // COLUMNDEF
    const columnDefsGL = [
        { headerName: "Company Title", field: "company_title", width: 200, headerClass: 'header-spacing' },
        { headerName: t("LeadAging_total_deals"), field: "total_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_total_attended_leads"), field: "total_attended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_total_unattended_leads"), field: "total_unattended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        {
            headerName: t("LeadAging_pending_aging"), headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_unattended_leads_aging.5Days', width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_unattended_leads_aging.10Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_unattended_leads_aging.15Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_unattended_leads_aging.30Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_unattended_leads_aging.moreThan30Days", width: 150, valueFormatter: numberFormatter },
            ]
        },
    ];

    const columnDefs2GL = [
        { headerName: "Company Title", field: "company_title", width: 180, headerClass: 'header-spacing' },
        { headerName: t("LeadAging_total_attended_leads"), field: "total_attended_leads", width: 150, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_first_response_time"), field: "total_attended_response", width: 180, headerClass: 'header-spacing', valueFormatter: timeFormatter },
        {
            headerName: t("LeadAging_average_call_duration"),
            field: "total_average_call_duration",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: callDurationFormatter
        },
        {
            headerName: t("LeadAging_average_no_follow_up_per_lead"),
            field: "total_average_followup_per_lead", // Assuming "total_average_followup_per_lead" is an object with "total_activities" and "total_calls"
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: followUpFormatter
        },
        {
            headerName: "Aging", headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_attended_leads_aging.5Days', width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_attended_leads_aging.10Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_attended_leads_aging.15Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_attended_leads_aging.30Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_attended_leads_aging.moreThan30Days", width: 100, valueFormatter: numberFormatter },
            ]
        },
    ];

    // COLUMNDEF (outreach)
    const columnDefsGLOutreach = [
        { headerName: "Project", field: "company_title", width: 200, headerClass: 'header-spacing' },
        { headerName: "Total Qualified Leads (Qualified + Tele Qualified)", field: "total_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: "Total Contacted", field: "total_attended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: "Total Uncontacted", field: "total_unattended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        {
            headerName: t("LeadAging_pending_aging"), headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_unattended_leads_aging.5Days', width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_unattended_leads_aging.10Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_unattended_leads_aging.15Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_unattended_leads_aging.30Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_unattended_leads_aging.moreThan30Days", width: 150, valueFormatter: numberFormatter },
            ]
        },
    ];

    const columnDefsGLOutreachUser = [
        { headerName: t("LeadAging_sales_rep"), field: "user_name", width: 200, headerClass: 'header-spacing' },
        { headerName: "Total Qualified Leads (Qualified + Tele Qualified)", field: "total_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: "Total Contacted", field: "total_attended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: "Total Uncontacted", field: "total_unattended_leads", width: 180, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        {
            headerName: t("LeadAging_pending_aging"), headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_unattended_leads_aging.5Days', width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_unattended_leads_aging.10Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_unattended_leads_aging.15Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_unattended_leads_aging.30Days", width: 120, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_unattended_leads_aging.moreThan30Days", width: 150, valueFormatter: numberFormatter },
            ]
        },
    ];

    const columnDefs2GLOutreach = [
        { headerName: "Projects", field: "company_title", width: 180, headerClass: 'header-spacing' },
        { headerName: "Total Contacted", field: "total_attended_leads", width: 150, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_first_response_time"), field: "total_attended_response", width: 180, headerClass: 'header-spacing', valueFormatter: timeFormatter },
        {
            headerName: t("LeadAging_average_call_duration"),
            field: "total_average_call_duration",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: callDurationFormatter
        },
        {
            headerName: t("LeadAging_average_no_follow_up_per_lead"),
            field: "total_average_followup_per_lead",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: followUpFormatter
        },
        {
            headerName: "Aging", headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_attended_leads_aging.5Days', width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_attended_leads_aging.10Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_attended_leads_aging.15Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_attended_leads_aging.30Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_attended_leads_aging.moreThan30Days", width: 100, valueFormatter: numberFormatter },
            ]
        },
    ];

    const columnDefs2GLOutreachUser = [
        { headerName: t("LeadAging_sales_rep"), field: "user_name", width: 180, headerClass: 'header-spacing' },
        { headerName: "Total Contacted", field: "total_attended_leads", width: 150, headerClass: 'header-spacing', valueFormatter: numberFormatter },
        { headerName: t("LeadAging_first_response_time"), field: "total_attended_response", width: 180, headerClass: 'header-spacing', valueFormatter: timeFormatter },
        {
            headerName: t("LeadAging_average_call_duration"),
            field: "total_average_call_duration",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: callDurationFormatter
        },
        {
            headerName: t("LeadAging_average_no_follow_up_per_lead"),
            field: "total_average_followup_per_lead",
            width: 180,
            headerClass: 'header-spacing',
            valueFormatter: followUpFormatter
        },
        {
            headerName: "Aging", headerClass: "custom-header-center header-spacing days-header",
            children: [
                { headerName: t("LeadAging_5_days"), field: 'total_attended_leads_aging.5Days', width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_10_days"), field: "total_attended_leads_aging.10Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_15_days"), field: "total_attended_leads_aging.15Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_30_days"), field: "total_attended_leads_aging.30Days", width: 100, valueFormatter: numberFormatter },
                { headerName: t("LeadAging_more_than_30_days"), field: "total_attended_leads_aging.moreThan30Days", width: 100, valueFormatter: numberFormatter },
            ]
        },
    ];


    // Onchange function
    const handleProjectChange = async (selectedValue) => {
        setGLPipelineOptions([]);
        setSelectedGLPipeline("");
        try {
            const response = await axios.get("ws/ws_pipeline.php", {
                params: {
                    ctoken: selectedValue.ctoken,
                    task: 'getAllPipelines',
                    utoken: selectedValue.utoken
                }
            })

            const data = response.data;
            if (data.status == '0') {
                const transformedData = data.record.map(pipe => ({
                    label: pipe.pipeline_title,
                    value: pipe.pipeline_id
                }));

                transformedData.unshift({ label: "All Pipeline", value: "0" });
                setGLPipelineOptions(transformedData);
                setSelectedGLPipeline(transformedData[0].value);
                setPipelineSelectKey(prevKey => prevKey + 1);
            }
        } catch {

        }
    };

    const totalRow = extractedDataGL.length > 0
        ? extractedDataGL[0].company_title
            ? calculateTotalForRowGL(extractedDataGL)
            : calculateTotalForRow(extractedDataGL)
        : null;

    const totalRow2 = extractedDataGL.length > 0
        ? extractedDataGL[0].company_title
            ? calculateTotalForRow2GL(extractedDataGL)
            : calculateTotalForRow2(extractedDataGL)
        : null;


    // USEEFFECT
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;

            if (session.company_id == '251' ||
                session.company_id == '180' ||
                session.company_id == '190' ||
                session.company_id == '200' ||
                session.company_id == '203' ||
                session.company_id == '210' ||
                session.company_id == '212' ||
                session.company_id == '213') {
                fetchDataGL();
            } else {
                fetchData();
            }

        }
    }, [session]);

    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        content: () => targetRef.current,
    });


    useEffect(() => {
        if (extractedDataGL.length > 0) {
            let finalColumnDefs;
            let finalColumnDefs2;
            if (extractedDataGL[0].user_name && getGamudaTask == 'getAgingReportV6_GLHQ_outreach') {
                finalColumnDefs = columnDefsGLOutreachUser;
                finalColumnDefs2 = columnDefs2GLOutreachUser;
            } else if (extractedDataGL[0].company_title && getGamudaTask == 'getAgingReportV6_GLHQ_outreach') {
                finalColumnDefs = columnDefsGLOutreach;
                finalColumnDefs2 = columnDefs2GLOutreach;
            } else if (extractedDataGL[0].user_name && getGamudaTask == 'getAgingReportV7_GLHQ_overall') {
                finalColumnDefs = columnDefs;
                finalColumnDefs2 = columnDefs2;
            } else if (extractedDataGL[0].company_title) {
                finalColumnDefs = columnDefsGL;
                finalColumnDefs2 = columnDefs2GL;
            } else if (extractedDataGL[0].user_name) {
                finalColumnDefs = columnDefs;
                finalColumnDefs2 = columnDefs2;
            }
            setColumnDefsToUse(finalColumnDefs);
            setColumnDefsToUse2(finalColumnDefs2);
        }
    }, [extractedDataGL, getGamudaTask]);

    return (
        <Container fluid className="p-0 m-0">
            {loadingGrid1 ? (
                <Loader />
            ) : (
                <div className="py-3 px-5 container-fluid" style={{ height: '90vh', overflow: 'auto' }}>
                    {
                        session.company_id == '251' ||
                            session.company_id == '180' ||
                            session.company_id == '190' ||
                            session.company_id == '200' ||
                            session.company_id == '203' ||
                            session.company_id == '210' ||
                            session.company_id == '212' ||
                            session.company_id == '213' ? (
                            <Row>
                                <Col xl={12} className="d-flex justify-content-between">
                                    <div>
                                        {session.company_id == '251' ? (
                                            <Form.Group className="d-flex">
                                                <Form.Group className="me-2">
                                                    <FormSelect
                                                        className="mb-3 w-90"
                                                        placeholder="Select Project"
                                                        options={gamudaOptions}
                                                        width="200px"
                                                        valueDefault={gamudaOptions[0]}
                                                        onChange={(selectedOption) => {
                                                            setSelectedGLProject(selectedOption.value);
                                                            handleProjectChange(selectedOption);
                                                        }}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group className="me-2">
                                                    <FormSelect
                                                        className="mb-3 w-90"
                                                        placeholder="Select Options"
                                                        options={gamudaApiOptions}
                                                        width="200px"
                                                        valueDefault={gamudaApiOptions[0]}
                                                        onChange={(selectedOption) => {
                                                            setSelectedGamudaOptions(selectedOption.value);
                                                        }}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                {selectedGLProject !== "all" && (
                                                    <Form.Group className="me-2">
                                                        <FormSelect
                                                            className="mb-3 w-90"
                                                            placeholder="Select Source Category"
                                                            options={gamudaSourceCategory}
                                                            width="200px"
                                                            valueDefault={selectedGLSourceCat}
                                                            onChange={(selectedOption) => {
                                                                setSelectedGLSourceCat(selectedOption.value);
                                                            }}
                                                            isSearchable
                                                        />
                                                    </Form.Group>
                                                )}
                                                {selectedGLProject !== "all" && selectedGamudaOptions !== "ai_outreach" && (
                                                    <Form.Group className="me-2">
                                                        <FormSelect
                                                            key={pipelineSelectKey}
                                                            className="mb-3 w-90"
                                                            placeholder="Select Pipeline"
                                                            options={GLPipelineOptions}
                                                            width="200px"
                                                            valueDefault={selectedGLPipeline}
                                                            onChange={(selectedOption) => {
                                                                setSelectedGLPipeline(selectedOption.value);
                                                            }}
                                                            isSearchable
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Form.Group>
                                        ) : (
                                            <Form.Group className="d-flex">
                                                <Form.Group className="me-2">
                                                    <FormSelect
                                                        className="mb-3 w-90"
                                                        placeholder="Select Options"
                                                        options={gamudaApiOptions}
                                                        width="200px"
                                                        valueDefault={gamudaApiOptions[0]}
                                                        onChange={(selectedOption) => {
                                                            setSelectedGamudaOptions(selectedOption.value);
                                                        }}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                <Form.Group className="me-2">
                                                    <FormSelect
                                                        className="mb-3 w-90"
                                                        placeholder="Select Source Category"
                                                        options={gamudaSourceCategory}
                                                        width="200px"
                                                        valueDefault={selectedGLSourceCat}
                                                        onChange={(selectedOption) => {
                                                            setSelectedGLSourceCat(selectedOption.value);
                                                        }}
                                                        isSearchable
                                                    />
                                                </Form.Group>
                                                {selectedGamudaOptions !== "ai_outreach" && (
                                                    <Form.Group className="me-2">
                                                        <FormSelect
                                                            key={pipelineSelectKey}
                                                            className="mb-3 w-90"
                                                            placeholder="Select Pipeline"
                                                            options={GLPipelineOptions}
                                                            width="200px"
                                                            valueDefault={selectedGLPipeline}
                                                            onChange={(selectedOption) => {
                                                                setSelectedGLPipeline(selectedOption.value);
                                                            }}
                                                            isSearchable
                                                        />
                                                    </Form.Group>
                                                )}
                                            </Form.Group>
                                        )}
                                    </div>
                                    <div className="text-family float-end mb-4" id="date">
                                        <Form.Group className="d-flex">
                                            <Form.Group className="me-2">
                                                <Form.Control
                                                    type="date"
                                                    placeholder={t("LeadAging_from")}
                                                    className="shadow-sm border-0"
                                                    value={moment(dateRangeGL.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        onchangeDateRangeGL("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="me-2">
                                                <Form.Control
                                                    type="date"
                                                    placeholder={t("LeadAging_to")}
                                                    className="shadow-sm border-0"
                                                    value={moment(dateRangeGL.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        onchangeDateRangeGL("de", moment(e.target.value).format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Group>

                                            <Button variant="primary" className="btn shadow-sm" onClick={handleApplyButtonClickGL}>
                                                {t("LeadAging_applybtn")}
                                            </Button>
                                            <Button variant="success" className="text-light btn shadow-s ms-2" onClick={handlePrint}>
                                                Print
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>

                                {loadingGLGrid ? (
                                    <Loader />
                                ) : (
                                    <>
                                        <div ref={targetRef}>
                                            <Col xl={12}>
                                                <p className="lead-report-title">{t("LeadAging_title_1")}</p>
                                                <div style={containerStyleGL}>
                                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleGL }}>
                                                        <AgGridReact
                                                            columnDefs={columnDefsToUse}
                                                            rowData={extractedDataGL.length > 0 ? [...extractedDataGL, totalRow] : []}
                                                            rowHeight={40}
                                                            paginationPageSizeSelector={false}
                                                            animateRows={true}
                                                            defaultColDef={{ flex: 1 }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xl={12}>
                                                <p className="lead-report-title mt-3">{t("LeadAging_title_2")}</p>
                                                <div style={containerStyleGL}>
                                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleGL }}>
                                                        <AgGridReact
                                                            columnDefs={columnDefsToUse2}
                                                            rowData={extractedDataGL.length > 0 ? [...extractedDataGL, totalRow2] : []}
                                                            rowHeight={40}
                                                            paginationPageSizeSelector={false}
                                                            animateRows={true}
                                                            defaultColDef={{ flex: 1 }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </div>
                                    </>
                                )}
                            </Row>
                        ) : (
                            <Row>
                                <Col xl={12}>
                                    <div className="text-family float-end mb-4" id="date">
                                        <Form.Group className="d-flex">
                                            <Form.Group className="me-2">
                                                <Form.Control
                                                    type="date"
                                                    placeholder={t("LeadAging_from")}
                                                    className="shadow-sm border-0"
                                                    value={moment(dateRange.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group className="me-2">
                                                <Form.Control
                                                    type="date"
                                                    placeholder={t("LeadAging_to")}
                                                    className="shadow-sm border-0"
                                                    value={moment(dateRange.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                    onChange={(e) => {
                                                        onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                                    }}
                                                />
                                            </Form.Group>

                                            <Button variant="primary" className="btn shadow-sm" onClick={handleApplyButtonClick}>
                                                {t("LeadAging_applybtn")}
                                            </Button>
                                            <Button variant="success" className="text-light btn shadow-sm ms-2" onClick={handlePrint}>
                                                Print
                                            </Button>
                                        </Form.Group>
                                    </div>
                                </Col>

                                <div ref={targetRef}>
                                    <Col xl={12}>
                                        <p className="lead-report-title">{t("LeadAging_title_1")}</p>
                                        <div style={containerStyle}>
                                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                <AgGridReact
                                                    columnDefs={columnDefs}
                                                    rowData={extractedData.length > 0 ? [...extractedData, calculateTotalForRow(extractedData)] : []}
                                                    rowHeight={40}
                                                    paginationPageSizeSelector={false}
                                                    animateRows={true}
                                                    defaultColDef={{ flex: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xl={12}>
                                        <p className="lead-report-title mt-3">{t("LeadAging_title_2")}</p>
                                        <div style={containerStyle}>
                                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                <AgGridReact
                                                    columnDefs={columnDefs2}
                                                    rowData={extractedData.length > 0 ? [...extractedData, calculateTotalForRow2(extractedData)] : []}
                                                    rowHeight={40}
                                                    paginationPageSizeSelector={false}
                                                    animateRows={true}
                                                    defaultColDef={{ flex: 1 }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </div>
                            </Row>
                        )}
                </div >
            )}
        </Container>
    );
}

export default LeadAgingReport;
