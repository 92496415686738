import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import NoRecord from "../includes/NoRecord";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";

const LeadAcceptanceReport = () => {
    const init = useRef(false);
    const { session } = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState([]);

    const [startDate, setStartDate] = useState(moment().subtract(1, 'year').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    const [rowData, setRowData] = useState([]);
    const [recordFound, setRecordFound] = useState(0);
    const [isGrid, setIsGrid] = useState(false);

    const { t } = useTranslation();

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "40vh" : "0vh", paddingTop: 5 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const gridColumn = [
        {
            headerName: t('Report_LeadAcceptance_HeaderName1'),
            field: "user_name",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: t('Report_LeadAcceptance_HeaderName2'),
            field: "TOTAL_ACCEPTED",
            headerClass: 'center',
            cellClass: 'center',
            minWidth: 250
        },
        {
            headerName: t('Report_LeadAcceptance_HeaderName3'),
            headerClass: 'center',
            cellClass: 'center',
            field: "TOTAL_LEADS",
            minWidth: 250
        }
    ];

    const getChartData = useCallback(()=>{
        setIsLoading(true);
        
        axios.get("ws/ws_leadroute.php",{
            params:{
                task: "leadResponseReport",
                ds: startDate,
                de: endDate,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;
            
            if (data.record) {
                var dataChart = [];
                var gridData = [];

                for (let index = 0; index < data.totalUsers; index++) {
                    
                    dataChart.push({name: data.record[index].user_name, value: data.record[index].acceptanceRate_stat});
                    gridData.push(data.record[index]);
                }

                setRowData(gridData);
                setRecordFound(gridData.length);

                setChartData(dataChart);
                setIsLoading(false);
                setIsGrid(true);
            }else{
                setRowData([]);
                setRecordFound(0);

                setChartData([]);
                setIsLoading(false);
                setIsGrid(false);
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })
            .then(result => {
                setRowData([]);
                setRecordFound(0);

                setChartData([]);
                setIsLoading(false);
                setIsGrid(false);
            });
        });
    }, [startDate, endDate, session]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div>{`${t('Report_LeadAcceptance_BarName1')}:`}</div>
                        <div>{`${payload[0].value}%`}</div>
                    </Card.Body>
                </Card>

            );
        }
        
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getChartData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            { isLoading ? (<Loader />):(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mb-2 mt-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light shadow ms-auto" onClick={()=>{getChartData()}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-3">
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={400}>
                	                <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={chartData}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number"/>
                                        <YAxis dataKey="name" type="category" />
                                        <Tooltip content={<CustomTooltip />}/>
                                        <Legend />
                                        <Bar dataKey="value" stackId="a" fill="#0089cb" name={`${t('Report_LeadAcceptance_BarName1')} (%)`} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="mb-3">
                        <Stack direction="horizontal" gap={1} className="mb-0">
                            <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                        </Stack>

                        <div style={containerStyle} className="mb-3">
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    columnDefs={gridColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={50}
                                    pagination={true}
                                    paginationPageSize={30}
                                    paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                        {!isGrid && <NoRecord message="No record at the moment." width={200}/>}
                    </div>
                </Container>
            ) }
        </div>
    );
}

export default LeadAcceptanceReport;