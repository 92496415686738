import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../lib/css/Project.css";
import { Card, Row, Col, Stack, Container } from "react-bootstrap";
import Loader from "../includes/Loader";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useKit } from "./CustomerKit";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useTranslation } from "react-i18next";
import "../lib/scss/saleskit-phase.scss";

const CustomerKitPhase = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { projectData, hostUrl, hostUrlType, company_id } = useKit();
  const { project_id, key } = useParams();
  const [phaseRecord, setPhaseRecord] = useState([]);
  const [projectInfo, setProjectInfo] = useState([]);
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState({});

  // SET FUNCTION ----------------------------------

  const setPhaseImage = (image) => {
    if (image) {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `${hostUrl}/${hostUrlType}/assets/rea_booking/generic.jpg`;
    }
  };

  // OPEN FUNCTION ---------------------------------

  const openPhaseDetails = (record) => {
    setTimeout(() => {
      sessionStorage.setItem("rea_phase_id", record.rea_phase_id);
      navigate(`/rea-sales-kit/${key}/${project_id}/${record.phase_id}`);
    }, 500);
  };

  // USEEFFECT FUNCTION ----------------------------

  useEffect(() => {
    const projectRecord = projectData.find((record) => record.project_id === project_id);
    setProjectInfo(projectRecord);
    setPhaseRecord(projectRecord.phase_record);
    setLoading(false);
  }, []);

  return (
    <Container className="saleskit-phase">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <h6 className="mb-0">{projectInfo.project_name}</h6>
          <p>{phaseRecord.length > 0 ? `${phaseRecord.length} phases` : 'No phases at the moment'}</p>
          <Row className="g-0">
            {phaseRecord.length > 0 &&
              phaseRecord.map((record, index) => (
                <Col xxl={12} className="mb-3" key={index}>
                  <Card className="shadow-sm border-0 h-100">
                    <Row className="g-0">
                      <Col xxl={7} className="cursor-pointer" onClick={() => openPhaseDetails(record)}>
                        <Swiper
                          spaceBetween={0}
                          navigation={false}
                          thumbs={{ swiper: thumbsSwiper[index] }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="swiper-main"
                          style={{
                            "--swiper-navigation-color": "#fff",
                            "--swiper-pagination-color": "#fff",
                          }}
                        >
                          {record.phase_image.split(",").map((data, i) => (
                            <SwiperSlide key={i}>
                              <img src={setPhaseImage(data)} className="cursor-pointer" alt="" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Col>
                      <Col xxl={5} className="p-3">
                        <Stack direction="vertical" className="h-100">
                          <h5 className="cursor-pointer" onClick={() => openPhaseDetails(record)}>
                            {record.phase_name}
                          </h5>
                          {record.phase_location && <p className="mb-0 text-muted">{record.phase_location}</p>}
                          {record.phase_desc && <p className="mb-0">{record.phase_desc}</p>}
                          {(record.phase_type || record.completion_date !== "0000-00-00 00:00:00") && (
                            <div className="d-flex mt-2">
                              {record.phase_type && <div className="border rounded p-2 me-2">{record.phase_type}</div>}
                              {record.completion_date !== "0000-00-00 00:00:00" && <div className="border rounded p-2">{moment(new Date(record.completion_date)).format("ll")}</div>}
                            </div>
                          )}

                          <div className="mt-auto">
                            <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [index]: swiper }))} spaceBetween={10} slidesPerView={4} freeMode={true} watchSlidesProgress={true} modules={[FreeMode, Navigation, Thumbs]} className="swiper-thumbnail">
                              {record.phase_image.split(",").map((data, i) => (
                                <SwiperSlide key={i}>
                                  <img src={setPhaseImage(data)} className="cursor-pointer" alt="" />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </Stack>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default CustomerKitPhase;
