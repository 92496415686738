import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";


const BookingUploadDocuments = ({ projectid, phaseid, bookingid, onSubmit }) => {
  registerPlugin(FilePondPluginImagePreview);
  registerPlugin(FilePondPluginPdfPreview);
  registerPlugin(FilePondPluginFileValidateType);

  const { session } = useAuth();

  const sendDocuments = (fieldName, file, metadata, load, error, progress, abort) => {
    const formData = new FormData();
    const reader = new FileReader();

    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);
    formData.append("file_title", file.name.split(".pdf")[0]);
    formData.append("file_type", file.type.split("/")[1]);
    formData.append("company_id", session.company_id);
    formData.append("rea_project_id", projectid);
    formData.append("rea_phase_id", phaseid);
    formData.append("booking_id", bookingid);
    
    reader.onload = (e) => {
      formData.append("data", e.target.result);
      axios.post(`${session.baseUrl}api/api_upload_documents.php`, formData).then((response) => {
        const data = response.data;
        if (Number(data.status) === 0) {
          onSubmit(Math.random());
          load(data);
        }
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="text-uppercase">Upload Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilePond
          credits={false}
          allowMultiple={true}
          acceptedFileTypes={["application/pdf"]}
          instantUpload={false}
          server={{
            process: sendDocuments,
          }}
        />
      </Modal.Body>
    </>
  );
};

export default BookingUploadDocuments;
