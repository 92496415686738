import loaderImage from "../assets/images/loader.gif";

const LoaderAction = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%" }}>
      <div className="rounded shadow-sm" style={{ backgroundColor: "#eeeeee" }}>
        <img src={loaderImage} alt="loader" width={100} height={100} />
      </div>
    </div>
  );
};

export default LoaderAction;
