import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack, Card, Modal } from "react-bootstrap";
import { AreaChart, Area, PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar } from "recharts";
import { FormSelect, FormDate } from "../includes/FormCustom";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-duotone-svg-icons";
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";

const projectLabelMap = {
    gardens: "Gamuda Gardens",
    cove: "Gamuda Cove",
    jadehill: "Jade Hills",
    horizon: "Horizon Hills",
    twentyfive7: "twentyfive.7",
    bantayan: "Bukit Bantayan",
    highpark: "Highpark Suites"
};


const CustomerService1 = () => {
    const { t } = useTranslation();
    const init = useRef(false);
    const targetRef = useRef();
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [CS1Data, setCS1Data] = useState([]);
    const [CS1DataRecord, setCS1DataRecord] = useState([]);
    const [CS1DataProject, setCS1DataProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState({ value: "all", label: "All Projects" });
    const [user, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({ value: "", label: "All User" });
    const [chartLoading, setChartLoading] = useState(true);
    const [userNonHq, setUserNonHq] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [modalData2, setModalData2] = useState([]);
    const [modalData3, setModalData3] = useState([]);

    const [showModalDetails, setShowModalDetails] = useState(false);
    const [modalDataDetails, setModalDataDetails] = useState([]);
    const [chartData, setChartData] = useState([]);

    const [chartSubmittedSize, setChartSubmittedSize] = useState(6);
    const [chartResponseSize, setChartResponseSize] = useState(6);
    const [chart1Size, setChart1Size] = useState(6);
    const [chart2Size, setChart2Size] = useState(6);
    const [chart3Size, setChart3Size] = useState(6);
    const [chart4Size, setChart4Size] = useState(6);
    const [chart5Size, setChart5Size] = useState(6);
    const [chart6Size, setChart6Size] = useState(6);
    const [chart7Size, setChart7Size] = useState(6);
    const [chart8Size, setChart8Size] = useState(6);
    const [chart9Size, setChart9Size] = useState(6);
    const [chart10Size, setChart10Size] = useState(6);

    const [openProject, setOpenProject] = useState("");


    // Date format
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const today = new Date();
    const startOfYear = new Date(today.getFullYear(), 0, 1);

    const [dateRange, setdateRange] = useState({
        ds: formatDate(startOfYear),
        de: formatDate(today),
    });


    // Get Function
    const getCS1Data = async () => {
        try {
            let projectValue = "";

            if (session.company_id == 180) {
                projectValue = "gardens";
            } else if (session.company_id == 190) {
                projectValue = "jadehill";
            } else if (session.company_id == 200) {
                projectValue = "cove";
            } else if (session.company_id == 203) {
                projectValue = "twentyfive7";
            } else if (session.company_id == 210) {
                projectValue = "horizon";
            } else if (session.company_id == 212) {
                projectValue = "bantayan";
            } else if (session.company_id == 213) {
                projectValue = "highpark";
            } else if (session.company_id == 251) {
                projectValue = "all"
            }

            setOpenProject(projectValue);
            console.log(session)

            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : projectValue,
                    task: "CS1",
                    // uid: selectedUser.value || ""
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setCS1Data(response.data);
                setCS1DataRecord(response.data.record);
                setCS1DataProject(response.data.project);
                setChartLoading(false);
            }

        } catch (error) {

        }
    };

    const getUser = async (selectedOption) => {
        try {
            const response = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: selectedOption.ctoken || '',
                    task: '4a',
                    utoken: selectedOption.utoken || ''
                },
            });

            if (response.data.status == '0') {
                const userOptions = response.data.record.map(user => ({
                    value: user.user_id,
                    label: user.user_name
                }));

                userOptions.unshift({ value: "", label: "All Users" });
                setUser(userOptions);
            }

        } catch (error) {

        }
    };

    const getUserNonHq = async () => {
        try {
            const response = await axios.get("ws/ws_user.php", {
                params: {
                    ctoken: session.company_token,
                    task: '4a',
                    utoken: session.user_ac_token,
                    user_tag: ''
                },
            });

            if (response.data.status == '0') {
                const userOptions = response.data.record.map(user => ({
                    value: user.user_id,
                    label: user.user_name
                }));

                userOptions.unshift({ value: "", label: "All Users" });
                setUserNonHq(userOptions);
            }

        } catch (error) {

        }
    };

    // Open Modal Data
    const getModalData = async () => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS1_Walkin",
                    // uid: selectedUser.value
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setModalData(response.data.record);
            }

        } catch (error) {

        }
    };

    const getModalData2 = async () => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS1_SurveySent",
                    // uid: selectedUser.value
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setModalData2(response.data.record);
            }

        } catch (error) {

        }
    };

    const getModalData3 = async () => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS1_ResponseReceived",
                    // uid: selectedUser.value
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value
                },
            });

            if (response.data.status == '0') {
                setModalData3(response.data.record);
            }

        } catch (error) {

        }
    };

    const getModalDataDetails = async (cf_id, value) => {
        try {
            const response = await axios.get("ext/gamuda/api_survey.php", {
                params: {
                    cf: cf_id,
                    de: dateRange.de,
                    ds: dateRange.ds,
                    // project: selectedProject.value,
                    project: session.company_id == '251' ? selectedProject.value : openProject,
                    task: "CS1_Details",
                    // uid: selectedUser.value,
                    uid: session.role_id == '3' ? session.user_id : selectedUser.value,
                    val: value
                },
            });

            if (response.data.status === 0 && response.data.record) {
                setModalDataDetails(response.data.record);
                setShowModalDetails(true);
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'No record found!',
                    timer: 1700
                });
            }

        } catch (error) {

        }
    };

    // Chart Data Display

    const COLORSresponse = ['#18392b', '#fa8128', '#82eefd', '#39e75f', '#fff600', '#743089', '#ff4500'];

    const pieChartDataResponse = useMemo(() => {
        const projectData = CS1DataProject.response || {};
        // if (selectedProject.value === "all") {
        //     return Object.keys(projectData).map(key => ({
        //         name: projectLabelMap[key] || key,
        //         value: projectData[key]
        //     }));
        // } else {
        //     return [{
        //         name: projectLabelMap[selectedProject.value] || selectedProject.value,
        //         value: projectData[selectedProject.value] || 0
        //     }];
        // }

        if (session.company_id == '251') {
            if (selectedProject.value == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[selectedProject.value] || selectedProject.value,
                    value: projectData[selectedProject.value] || 0
                }];
            }
        } else {
            if (openProject == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[openProject] || openProject,
                    value: projectData[openProject] || 0
                }];
            }
        }
    }, [CS1DataProject]);

    const pieChartDataSubmitted = useMemo(() => {
        const projectData = CS1DataProject.sent || {};
        // if (selectedProject.value === "all") {
        //     return Object.keys(projectData).map(key => ({
        //         name: projectLabelMap[key] || key,
        //         value: projectData[key]
        //     }));
        // } else {
        //     return [{
        //         name: projectLabelMap[selectedProject.value] || selectedProject.value,
        //         value: projectData[selectedProject.value] || 0
        //     }];
        // }

        if (session.company_id == '251') {
            if (selectedProject.value == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[selectedProject.value] || selectedProject.value,
                    value: projectData[selectedProject.value] || 0
                }];
            }
        } else {
            if (openProject == "all") {
                return Object.keys(projectData).map(key => ({
                    name: projectLabelMap[key] || key,
                    value: projectData[key]
                }));
            } else {
                return [{
                    name: projectLabelMap[openProject] || openProject,
                    value: projectData[openProject] || 0
                }];
            }
        }
    }, [CS1DataProject]);


    const barChartData = useMemo(() => {
        const questions = ['q0', 'q1', 'q2'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            Yes: parseInt(item.field_Yes, 10),
            No: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const barChartData2 = useMemo(() => {
        const questions = ['q3', 'q4', 'q5', 'q6', 'q7', 'q8'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            Yes: parseInt(item.field_Yes, 10),
            No: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const barChartData3 = useMemo(() => {
        const questions = ['q9'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            average: parseInt(item.field_Average, 10),
            good: parseInt(item.field_Good, 10),
            notSatisfied: parseInt(item.field_Not_Satisfied, 10),
            satisfied: parseInt(item.field_Satisfied, 10),
            verySatisfied: parseInt(item.field_Very_Satisfied, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const pieChartData4 = useMemo(() => {
        const questions = ['q10'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Yes',
            value: parseInt(item.field_Yes, 10),
            cf_id: item.cf_id
        })).concat(relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })));
    }, [CS1DataRecord]);

    const COLORSpie4 = ['#39e75f', '#4361ee'];

    const barChartData5 = useMemo(() => {
        const questions = ['q11', 'q12', 'q13'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            belowAverage: parseInt(item.field_Below_Average, 10),
            excellent: parseInt(item.field_Excellent, 10),
            good: parseInt(item.field_Good, 10),
            poor: parseInt(item.field_Poor, 10),
            satisfactory: parseInt(item.field_Satisfactory, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const barChartData6 = useMemo(() => {
        const questions = ['q14'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            belowAverage: parseInt(item.field_Below_Average, 10),
            excellent: parseInt(item.field_Excellent, 10),
            good: parseInt(item.field_Good, 10),
            poor: parseInt(item.field_Poor, 10),
            satisfactory: parseInt(item.field_Satisfactory, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const pieChartData7 = useMemo(() => {
        const questions = ['q15'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Attractive Deals Offered',
            value: parseInt(item.field_Attractive_Deals_Offered, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Brand Reputation Quality',
                value: parseInt(item.field_Brand_Reputation_Quality, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Location of the Project',
                value: parseInt(item.field_Location_of_the_Project, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Recommendation by Family/Friends',
                value: parseInt(item.field_Recommendation_by_Family_Friends, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Township Development',
                value: parseInt(item.field_Township_Development, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS1DataRecord]);

    const COLORSpie7 = ['#39e75f', '#4361ee', '#663399', '#ec9706', '#a91b0d'];

    const pieChartData8 = useMemo(() => {
        const questions = ['q16'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'Differential Sum Assistance - Instalment up to 5 years at 0 interest_rate',
            value: parseInt(item.field_Differential_Sum_Assistance__Instalment_up_to_5_years_at_0_interest_rate, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Furnishing Package',
                value: parseInt(item.field_Furnishing_Package, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Guaranteed Returns Rental Yield',
                value: parseInt(item.field_Guaranteed_ReturnsRental_Yield, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Loan Instalment Payment Assistance',
                value: parseInt(item.field_Loan_Instalment_Payment_Assistance, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Low Down Payment',
                value: parseInt(item.field_Low_Down_Payment, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS1DataRecord]);

    const barChartData9 = useMemo(() => {
        const questions = ['q17', 'q18', 'q19', 'q20'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            field1: parseInt(item.field_1, 10),
            field2: parseInt(item.field_2, 10),
            field3: parseInt(item.field_3, 10),
            field4: parseInt(item.field_4, 10),
            cf_id: item.cf_id
        }));
    }, [CS1DataRecord]);

    const pieChartData10 = useMemo(() => {
        const questions = ['q21'];
        const relevantData = questions.map(question => CS1DataRecord[question]).filter(Boolean);

        return relevantData.map(item => ({
            title: item.title,
            name: 'No',
            value: parseInt(item.field_No, 10),
            cf_id: item.cf_id
        })).concat(
            relevantData.map(item => ({
                title: item.title,
                name: 'Not Required',
                value: parseInt(item.field_Not_Required, 10),
                cf_id: item.cf_id
            })),
            relevantData.map(item => ({
                title: item.title,
                name: 'Yes',
                value: parseInt(item.field_Yes, 10),
                cf_id: item.cf_id
            }))
        );
    }, [CS1DataRecord]);

    const COLORSpie10 = ['#82eefd', '#ec9706', '#ff69b4'];

    //  DATE RANGE FILTER
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    // Handle print
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        content: () => targetRef.current,
    });

    // Apply Button
    const handleApplyButtonClick = async () => {
        setLoading(true);
        setChartLoading(true);

        try {
            await getCS1Data();
        } catch (error) {

        } finally {
            setLoading(false); // Set the loading state for the entire component to false
        }
    };

    // Open Modal
    const handleOpenModal = () => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            setShowModal(true);
        }
    };

    const handleOpenModal2 = () => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            setShowModal2(true);
        }
    };

    const handleOpenModal3 = () => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            setShowModal3(true);
        }
    };

    const handleBarClick = (data, key) => {
        if (session.company_id == '251' && selectedProject.value === "all") {
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Please select a project!',
                timer: 1500
            });
            return;
        } else {
            getModalDataDetails(data.cf_id, key);
            setChartData(data)
            // setShowModalDetails(true);
        }
    };

    const handleCloseModal = () => {
        setShowModalDetails(false);
        setModalDataDetails([]);
    };


    // GRID COLUMN
    const setDateFormat = (params) => {
        const dateTime = params.data.date;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    const gridColumn = [
        { headerName: t("CS1_no"), field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        {
            headerName: t("CS1_prospect_name"),
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var dealId = params.data.deal_id;
                    window.open(`${session.origin}/deal/${dealId}`, "_blank");
                }
            }
        },
        { headerName: t("CS1_sales_rep"), field: "sales_person", filter: 'agTextColumnFilter', width: 300 },
        { headerName: t("CS1_date_time_create"), field: "date", filter: 'agTextColumnFilter', width: 300, cellRenderer: setDateFormat },
    ];

    const gridColumnResponse = [
        { headerName: t("CS1_no"), field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        {
            headerName: t("CS1_prospect_name"),
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var dealId = params.data.deal_id;
                    window.open(`${session.origin}/deal/${dealId}`, "_blank");
                }
            }
        },
        { headerName: t("CS1_sales_rep"), field: "user_name", filter: 'agTextColumnFilter', width: 300 },
        { headerName: t("CS1_date_time_create"), field: "date", filter: 'agTextColumnFilter', width: 300, cellRenderer: setDateFormat },
    ];

    const gridColumnChart = [
        { headerName: t("CS1_no"), field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
        { headerName: t("CS1_company_title"), field: "company_title", filter: 'agTextColumnFilter', width: 250 },
        {
            headerName: t("CS1_prospect_name"),
            field: "prospect_name",
            filter: 'agTextColumnFilter',
            width: 250,
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var recordId = params.data.record_id;
                    window.open(`${session.origin}/deal/${recordId}`, "_blank");
                }
            }
        },
        { headerName: t("CS1_sales_rep"), field: "user_name", filter: 'agTextColumnFilter', width: 300 },
        { headerName: t("CS1_date_time_create"), field: "date", filter: 'agTextColumnFilter', width: 200, cellRenderer: setDateFormat },
        { headerName: t("CS1_answer"), field: "fd_value", filter: 'agTextColumnFilter', width: 120 },
    ];

    // Size Expand
    const handleExpand = (chart) => {
        if (chart === "chartSubmitted") {
            if (chartSubmittedSize === 6) {
                setChartSubmittedSize(12);
            } else {
                setChartSubmittedSize(6);
            }
        } else if (chart === "chartReponse") {
            if (chartResponseSize === 6) {
                setChartResponseSize(12);
            } else {
                setChartResponseSize(6);
            }
        } else if (chart === "chart1") {
            if (chart1Size === 6) {
                setChart1Size(12);
            } else {
                setChart1Size(6);
            }
        } else if (chart === "chart2") {
            if (chart2Size === 6) {
                setChart2Size(12);
            } else {
                setChart2Size(6);
            }
        } else if (chart === "chart3") {
            if (chart3Size === 6) {
                setChart3Size(12);
            } else {
                setChart3Size(6);
            }
        } else if (chart === "chart4") {
            if (chart4Size === 6) {
                setChart4Size(12);
            } else {
                setChart4Size(6);
            }
        } else if (chart === "chart5") {
            if (chart5Size === 6) {
                setChart5Size(12);
            } else {
                setChart5Size(6);
            }
        } else if (chart === "chart6") {
            if (chart6Size === 6) {
                setChart6Size(12);
            } else {
                setChart6Size(6);
            }
        } else if (chart === "chart7") {
            if (chart7Size === 6) {
                setChart7Size(12);
            } else {
                setChart7Size(6);
            }
        } else if (chart === "chart8") {
            if (chart8Size === 6) {
                setChart8Size(12);
            } else {
                setChart8Size(6);
            }
        } else if (chart === "chart9") {
            if (chart9Size === 6) {
                setChart9Size(12);
            } else {
                setChart9Size(6);
            }
        } else if (chart === "chart10") {
            if (chart10Size === 6) {
                setChart10Size(12);
            } else {
                setChart10Size(6);
            }
        }
    }

    const projectTokens = {
        gardens: { utoken: "822b901dd4b40696fbab9a6137411757", ctoken: "48ade9786a2d11568db7aad9657a90de" },
        jadehill: { utoken: "0ee48faae67e81da9c0146c2de2a5926", ctoken: "3f684e70f4379ce5dc998a5b1d433751" },
        cove: { utoken: "243d7203cd06bb5d2fadbdafa32e213e", ctoken: "c7a341f4b174827d2f2b88a947554855" },
        twentyfive7: { utoken: "167e46363fa4c5320ce73bc1d90c33ab", ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6" },
        bantayan: { utoken: "120830d144cc961cb0ca2e047d0c05d3", ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec" },
        highpark: { utoken: "38cecfadf5b89105aa879bab98b5fff0", ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8" },
        horizon: { utoken: "401c13774894dd9edeef77a2523faa39", ctoken: "9e326bbe084ccb68e4ec27dab66f7731" },
    };



    // UseEffect
    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getCS1Data();
                    await getUser();
                    getUserNonHq();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session]);

    useEffect(() => {
        const allChartsLoaded = () => {
            return !chartLoading;
        };

        if (allChartsLoaded()) {
            setLoading(false);
        }
    }, [chartLoading]);

    useEffect(() => {
        if (showModal) {
            getModalData();
        } else if (showModal2) {
            getModalData2();
        } else if (showModal3) {
            getModalData3();
        }
    }, [showModal, showModal2, showModal3]);



    return (
        <Container fluid className="p-0 m-0">
            <div className="p-4" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
                <Stack className="mb-3" direction="horizontal" gap={3}>
                    {session.company_id == '251' ? (
                        <>
                            <Form.Group>
                                <FormSelect
                                    options={[
                                        { value: "all", label: "All Projects" },
                                        ...(CS1DataProject.sent
                                            ? Object.keys(CS1DataProject.sent).map(project => {
                                                const tokens = projectTokens[project] || {};
                                                return {
                                                    value: project,
                                                    label: projectLabelMap[project] || project.charAt(0).toUpperCase() + project.slice(1),
                                                    utoken: tokens.utoken || "",  // Adding specific utoken
                                                    ctoken: tokens.ctoken || "",  // Adding specific ctoken
                                                };
                                            })
                                            : [])
                                    ]}
                                    valueDefault={selectedProject.value}
                                    onChange={(selectedOption) => {
                                        setSelectedProject(selectedOption);
                                        getUser(selectedOption)
                                    }}
                                    placeholder={t("CS1_select_project")}
                                    border={false}
                                    shadow={true}
                                    width="200px"
                                />
                            </Form.Group>

                            {selectedProject.value !== "all" && (
                                <Form.Group>
                                    <FormSelect
                                        options={user}
                                        value={selectedUser || { value: "", label: "All User" }}
                                        onChange={(selectedOption) => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder={t("CS1_select_user")}
                                        border={false}
                                        shadow={true}
                                        width="200px"
                                    />
                                </Form.Group>
                            )}
                        </>
                    ) : (
                        <>
                            {session.role_id !== '3' && (
                                <Form.Group>
                                    <FormSelect
                                        options={userNonHq}
                                        value={selectedUser || { value: "", label: "All User" }}
                                        onChange={(selectedOption) => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder={t("CS1_select_user")}
                                        border={false}
                                        shadow={true}
                                        width="200px"
                                    />
                                </Form.Group>
                            )}
                        </>
                    )}

                    <Form.Group className="ms-auto">
                        <FormDate placeholder={t("CS1_from")} className="shadow-sm border-0" value={moment(dateRange.ds, 'DD-MM-YYYY').format('DD/MM/YYYY')} onChange={(date) => onchangeDateRange("ds", date)} />
                    </Form.Group>

                    <Form.Group>
                        <FormDate placeholder={t("CS1_to")} className="shadow-sm border-0" value={moment(dateRange.de, 'DD-MM-YYYY').format('DD/MM/YYYY')} onChange={(date) => onchangeDateRange("de", date)} />
                    </Form.Group>

                    <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
                        {t("CS1_apply")}
                    </Button>

                    <Button variant="" className="op-primary-color text-light" onClick={handlePrint}>
                        {t("CS1_print")}
                    </Button>
                </Stack>

                {chartLoading ? (
                    <Loader />
                ) : (
                    <div ref={targetRef}>
                        <Stack direction="horizontal" gap={3} className="mb-4">
                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        {t("CS1_total_walk_in_leads")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2 deal-count" style={{ cursor: "pointer" }} onClick={handleOpenModal}>
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS1Data.total_walkin}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("CS1_total_walk_in_leads")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumn} rowData={modalData} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        {t("CS1_total_survey_sent")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2 deal-count" style={{ cursor: "pointer" }} onClick={handleOpenModal2}>
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS1Data.total_survey_sent}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Modal show={showModal2} onHide={() => setShowModal2(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("CS1_total_survey_sent")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumn} rowData={modalData2} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        {t("CS1_total_response_received")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2 deal-count" style={{ cursor: "pointer" }} onClick={handleOpenModal3}>
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS1DataRecord.response_received}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Modal show={showModal3} onHide={() => setShowModal3(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>{t("CS1_total_response_received")}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumnResponse} rowData={modalData3} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </Stack>

                        <Stack direction="horizontal" gap={3}>
                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        {t("CS1_survey_submission_time")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2">
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS1Data.total_aveSubmissionTime}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card className="border-0 shadow-sm w-100">
                                <Card.Header className="bg-light">
                                    <Card.Title as={"h6"} className="m-2">
                                        {t("CS1_survey_response_time")}
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="py-2">
                                            <div className="text-danger" style={{ fontSize: "18px" }}>
                                                {CS1Data.total_aveResponseTime}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Stack>

                        <Row>
                            <Col sm={chartSubmittedSize} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                {t("CS1_survey_submitted_for_each_project")}
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chartSubmitted') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartDataSubmitted}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {pieChartDataSubmitted.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chartResponseSize} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                {t("CS1_response_received_for_each_project")}
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chartReponse') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartDataResponse}
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {pieChartDataResponse.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart1Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                1. How was your journey to the sales gallery?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart1') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={180} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="Yes" fill="#39e75f" stackId="a" name="Yes" onClick={(data) => handleBarClick(data, 'Yes')} />
                                                <Bar dataKey="No" fill="#4361ee" stackId="a" name="No" onClick={(data) => handleBarClick(data, 'No')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart2Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                2. How was the service provided to you during the visit?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart2') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData2} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={250} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="Yes" fill="#39e75f" stackId="a" name="Yes" onClick={(data) => handleBarClick(data, 'Yes')} />
                                                <Bar dataKey="No" fill="#4361ee" stackId="a" name="No" onClick={(data) => handleBarClick(data, 'No')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart3Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                3. How would you like to rate our Relationship Associate attire?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart3') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData3} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={100} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="verySatisfied" fill="#39e75f" stackId="a" name="Very Satisfied" onClick={(data) => handleBarClick(data, 'Very Satisfied')} />
                                                <Bar dataKey="satisfied" fill="#4361ee" stackId="a" name="Satisfied" onClick={(data) => handleBarClick(data, 'Satisfied')} />
                                                <Bar dataKey="good" fill="#663399" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="average" fill="#ec9706" stackId="a" name="Average" onClick={(data) => handleBarClick(data, 'Average')} />
                                                <Bar dataKey="notSatisfied" fill="#a91b0d" stackId="a" name="Not Satisfied" onClick={(data) => handleBarClick(data, 'Not Satisfied')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart4Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                4. Did the Relationship Associate brief you using the digital sales kit?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart4') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData4}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData4.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie4[index % COLORSpie4.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart5Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                5. How well was the information provided to you in aiding your home purchase?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart5') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData5} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={100} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="excellent" fill="#39e75f" stackId="a" name="Excellent" onClick={(data) => handleBarClick(data, 'Excellent')} />
                                                <Bar dataKey="good" fill="#4361ee" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="satisfactory" fill="#663399" stackId="a" name="Satisfactory" onClick={(data) => handleBarClick(data, 'Satisfactory')} />
                                                <Bar dataKey="belowAverage" fill="#ec9706" stackId="a" name="Below Average" onClick={(data) => handleBarClick(data, 'Below Average')} />
                                                <Bar dataKey="poor" fill="#a91b0d" stackId="a" name="Poor" onClick={(data) => handleBarClick(data, 'Poor')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart6Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                6. How would you rate the overall experience with our Relationship Associates?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart6') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData6} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={100} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="excellent" fill="#39e75f" stackId="a" name="Excellent" onClick={(data) => handleBarClick(data, 'Excellent')} />
                                                <Bar dataKey="good" fill="#4361ee" stackId="a" name="Good" onClick={(data) => handleBarClick(data, 'Good')} />
                                                <Bar dataKey="satisfactory" fill="#663399" stackId="a" name="Satisfactory" onClick={(data) => handleBarClick(data, 'Satisfactory')} />
                                                <Bar dataKey="belowAverage" fill="#ec9706" stackId="a" name="Below Average" onClick={(data) => handleBarClick(data, 'Below Average')} />
                                                <Bar dataKey="poor" fill="#a91b0d" stackId="a" name="Poor" onClick={(data) => handleBarClick(data, 'Poor')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart7Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                7. What do you consider the most when deciding to purchase a property?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart7') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData7}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData7.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie7[index % COLORSpie7.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart8Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                8. What type of deals will motivate you to purchase?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart8') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData8}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData8.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie7[index % COLORSpie7.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart9Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                9. How would you rank the importance of these elements in a township when you purchase a property. (Please rank in a manner whereby "1" is the most important and "4" is the least important)
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart9') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={barChartData9} layout="vertical">
                                                <XAxis type="number" />
                                                <YAxis dataKey="title" type="category" width={150} />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                                <Bar dataKey="field1" fill="#39e75f" stackId="a" name="1" onClick={(data) => handleBarClick(data, '1')} />
                                                <Bar dataKey="field2" fill="#4361ee" stackId="a" name="2" onClick={(data) => handleBarClick(data, '2')} />
                                                <Bar dataKey="field3" fill="#82eefd" stackId="a" name="3" onClick={(data) => handleBarClick(data, '3')} />
                                                <Bar dataKey="field4" fill="#ec9706" stackId="a" name="4" onClick={(data) => handleBarClick(data, '4')} />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={chart10Size} className="mt-3">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between align-items-center">
                                            <div className="font h6 m-2">
                                                10. Were you introduced to other product / project of Gamuda Land by our Relationship Associate?
                                            </div>
                                            <div className="me-2" style={{ cursor: "pointer" }} onClick={() => { handleExpand('chart10') }}>
                                                <FontAwesomeIcon icon={faExpand} size="xl" />
                                            </div>
                                        </Stack>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={pieChartData10}
                                                    dataKey="value"
                                                    nameKey="name"
                                                    cx="50%"
                                                    cy="50%"
                                                    outerRadius={100}
                                                    innerRadius={50}
                                                    fill="#8884d8"
                                                    onClick={(data, index) => handleBarClick(data, data.name)}
                                                >
                                                    {pieChartData10.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORSpie10[index % COLORSpie10.length]} />
                                                    ))}
                                                </Pie>
                                                <Legend verticalAlign="top" height={35} />
                                                <Tooltip />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Modal show={showModalDetails} onHide={handleCloseModal} size="xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>{chartData.title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{ width: "100%", height: "80vh" }}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                            <AgGridReact columnDefs={gridColumnChart} rowData={modalDataDetails} rowHeight={50} pagination={true} paginationPageSize={100} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </Row>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CustomerService1;