import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button, Card, Col, Container, Nav, Form, Row, Stack, Modal, Carousel, Image, Offcanvas } from "react-bootstrap";
import { setCurrency } from "../lib/js/Function";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import "../lib/css/OffCanvas.css";

const MortgageCalculator = ({ getUnitData }) => {
    const [unitPrice, setUnitPrice] = useState('');
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState('');
    const [MOTAmount, setMOTAmount] = useState('');

    const [loanPercentage, setLoanPercentage] = useState('90');
    const [loanAmount, setLoanAmount] = useState('');
    const [loanTenure, setLoanTenure] = useState('30');
    const [loanInterest, setLoanInterest] = useState('4.4');
    const [monthlyPayment, setMonthlyPayment] = useState('');

    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [exchangeRates, setExchangeRates] = useState({});

    useEffect(() => {
        setUnitPrice(getUnitData.unit_selling_price);
        calculateTotalAmount(unitPrice, discount);
        calculateMOTPrice(unitPrice);
        calculateMonthlyPayment(totalAmount, loanPercentage, loanTenure, loanInterest);
    }, [getUnitData.unit_selling_price, unitPrice, discount, totalAmount, loanPercentage, loanTenure, loanInterest]);

    const calculateTotalAmount = (unitprice, discInput) => {
        const price = parseFloat(unitprice);
        const disc = parseFloat(discInput);

        if (!isNaN(price) && !isNaN(disc)) {
            const total = price - (price * (disc / 100));
            setTotalAmount(total.toFixed(2));
        } else {
            setTotalAmount('');
        }
    };

    const calculateMOTPrice = (unitprice) => {
        const price = parseFloat(unitprice);

        if (!isNaN(price)) {
            let MOTAmount = 0;

            if (price <= 100000) {
                MOTAmount = price * 0.01;
            } else if (price <= 500000) {
                MOTAmount = (100000 * 0.01) + ((price - 100000) * 0.02);
            } else if (price <= 1000000) {
                MOTAmount = (100000 * 0.01) + (400000 * 0.02) + ((price - 500000) * 0.03);
            } else {
                MOTAmount = (100000 * 0.01) + (400000 * 0.02) + (500000 * 0.03) + ((price - 1000000) * 0.04);
            }

            setMOTAmount(MOTAmount.toFixed(2));
        } else {
            setMOTAmount('');
        }
    }

    useEffect(() => {
        calculateMonthlyPayment(totalAmount, loanPercentage, loanTenure, loanInterest);
    }, [loanPercentage, loanTenure, loanInterest]);

    const calculateMonthlyPayment = (totalamount, loanpercent, loantenure, loaninterest) => {
        const percent = parseFloat(loanpercent) / 100;
        const getLoanAmount = totalamount * percent;
        setLoanAmount(getLoanAmount);

        const monthlyInterestRate = (parseFloat(loaninterest) / 100) / 12;
        const monthsOfPayment = loantenure * 12;

        const monthlyPaymentAmount = (getLoanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, monthsOfPayment))) / (Math.pow(1 + monthlyInterestRate, monthsOfPayment) - 1);

        const roundedMonthlyPayment = monthlyPaymentAmount.toFixed(2);
        setMonthlyPayment(roundedMonthlyPayment);
    }

    // exchange rate function

    const fetchExchangeRates = () => {
        fetch(`https://open.er-api.com/v6/latest/MYR`)
            .then(response => response.json())
            .then(data => {
                if (data.rates) {
                    setExchangeRates(data.rates);
                }
            })
            .catch(error => console.error('Error fetching exchange rates:', error));
    };

    const convertTotalAmount = (currency) => {
        const rate = exchangeRates[currency];
        return totalAmount * rate;
    };

    useEffect(() => {
        fetchExchangeRates();
    }, [selectedCurrency]);

    return (
        <section>
            <Offcanvas.Header closeButton style={{ height: '10vh' }}>
                <Offcanvas.Title>Mortgage Calculator</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ backgroundColor: 'rgb(238, 238, 238)', height: '90vh' }}>
                <div className="mx-5">
                    <div className="bg-white" style={{ padding: '15px' }}>
                        <h6 className="fw-bold mb-3">Transaction details</h6>
                        <div className="position-relative">
                            <label className="canvas-label">Unit price (RM)</label>
                            <div className="d-flex mb-3">
                                <Form.Control
                                    type="text"
                                    style={{ width: '400px' }}
                                    defaultValue={setCurrency(getUnitData.unit_selling_price)}
                                    onChange={(e) => setUnitPrice(e.target.value)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="position-relative">
                            <label className="canvas-label">Discount (%)</label>
                            <div className="d-flex mb-3">
                                <Form.Control
                                    type="text"
                                    style={{ width: '400px' }}
                                    value={discount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const newValue = value.replace(/[^0-9.]/g, '');
                                        setDiscount(newValue)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <h6 className="fw-bold py-2 border-bottom">
                                MOT price
                            </h6>
                            <div className="py-2 d-flex justify-content-between">
                                <h6>MOT Amount (RM):</h6>
                                <h6 className="fw-bold">{setCurrency(MOTAmount)}</h6>
                            </div>
                        </div>
                        <div>
                            <h6 className="fw-bold py-2 border-bottom">Transaction price</h6>
                            <div className="py-2 d-flex justify-content-between">
                                <h6>Total Amount (RM):</h6>
                                <h6 className="fw-bold">{setCurrency(totalAmount)}</h6>
                            </div>
                        </div>
                        <div className="py-2">
                            <h6 className="fw-bold">Price in other currency:</h6>
                            <div className="lh-1">
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Australia:</div>
                                    <div>AUD {convertTotalAmount('AUD').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Brunei:</div>
                                    <div>S$ {convertTotalAmount('BND').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">United State:</div>
                                    <div>$ {convertTotalAmount('USD').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">China:</div>
                                    <div>¥ {convertTotalAmount('CNY').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Hong Kong:</div>
                                    <div>HK$ {convertTotalAmount('HKD').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Indonesia:</div>
                                    <div>Rp {convertTotalAmount('IDR').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Japan:</div>
                                    <div>¥ {convertTotalAmount('JPY').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Thailand:</div>
                                    <div>฿ {convertTotalAmount('THB').toLocaleString('en-US')}</div>
                                </div>
                                <div className="py-2 d-flex justify-content-between">
                                    <div className="text-secondary">Vietnam:</div>
                                    <div>₫ {convertTotalAmount('VND').toLocaleString('en-US')}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h6 className="fw-bold py-2 border-bottom">Loan details</h6>
                            <div>
                                <div className="position-relative">
                                    <label className="canvas-loan-label">Loan percentage (%)</label>
                                    <div className="d-flex mb-3">
                                        <Form.Control
                                            type="text"
                                            style={{ width: '400px' }}
                                            value={loanPercentage}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newValue = value.replace(/[^0-9.]/g, '');
                                                setLoanPercentage(newValue)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <label className="canvas-loan-label">Loan amount (RM)</label>
                                    <div className="d-flex mb-3">
                                        <Form.Control
                                            type="text"
                                            style={{ width: '400px' }}
                                            value={loanAmount ? setCurrency(loanAmount) : ''}
                                            disabled
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newValue = value.replace(/[^0-9.]/g, '');
                                                setLoanAmount(newValue)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <label className="canvas-loan-label">Loan tenure (years)</label>
                                    <div className="d-flex mb-3">
                                        <Form.Control
                                            type="text"
                                            style={{ width: '400px' }}
                                            value={loanTenure}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newValue = value.replace(/[^0-9.]/g, '');
                                                setLoanTenure(newValue)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="position-relative">
                                    <label className="canvas-loan-label">Loan interest (p.a.)</label>
                                    <div className="d-flex mb-3">
                                        <Form.Control
                                            type="text"
                                            style={{ width: '400px' }}
                                            value={loanInterest}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const newValue = value.replace(/[^0-9.]/g, '');
                                                setLoanInterest(newValue)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-3" style={{ height: '60px', backgroundColor: '#6b5f5f' }}>
                        <div className="text-end text-white">
                            <h8 className="fw-bold">Monthly Payment</h8>
                            <h3 className="mt-2">{monthlyPayment && !isNaN(monthlyPayment) ? setCurrency(monthlyPayment) : ''}</h3>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </section>
    )
}

export default MortgageCalculator;