import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import { useTranslation } from "react-i18next";
import PipelineStageSetting from "./SettingPipelineStage";

const PipelineSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [isCsList, setIsCsList] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState({});

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingPipeline_headerName1'),
            field: "pipeline_title",
            filter: true,
            resizable: true,
            width: 300,
            autoHeight: true,
            wrapText: true,
            onCellClicked: function (params) {
                if (params.data) {
                    setIsCsList(true);
                    setSelectedPipeline(params.data);
                }
            }
        },
        {
            headerName: t('SettingPipeline_headerName2'),
            field: "pipeline_index",
            resizable: true,
            width: 180
        },
        {
            headerName: t('SettingPipeline_headerName3'),
            field: "naming_won",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingPipeline_headerName4'),
            field: "naming_lost",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingPipeline_headerName5'),
            field: "isactive",
            resizable: true,
            width: 180
        },
        {
            headerName: t('SettingPipeline_headerName6'),
            resizable: true,
            width: 180,
            cellRenderer: (params) => {
                if (params.data) {
                    return Number(params.data.final_conversion) === 0 ? "No":"Yes";
                }
            }
        },
        {
            headerName: t('SettingPipeline_headerName7'),
            field: "isdefault",
            resizable: true,
            width: 100
        },
        {
            headerName: t('SettingPipeline_headerName8'),
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.pipeline_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data.pipeline_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_pipeline.php',{
            params: {
                task: "getAllPipelines",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {

                const filteredRows = data.record;

                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        pipeline_title: yup.string().required(t('Setting_requiredFd')),
        naming_won: yup.string().required(t('Setting_requiredFd')),
        naming_lost: yup.string().required(t('Setting_requiredFd'))
    });

    const sendAdd = (values) => {

        axios.get('ws/ws_pipeline.php', {
            params: {
                task: 'addPipeline',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                title: values.pipeline_title,
                nameWon: values.naming_won,
                nameLost: values.naming_lost,
                inactive: values.pipeline_status,
                isdefault: values.pipeline_default
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Add'),
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        pipeline_title_edit: yup.string().required(t('Setting_requiredFd')),
        naming_won_edit: yup.string().required(t('Setting_requiredFd')),
        naming_lost_edit: yup.string().required(t('Setting_requiredFd'))
    });

    const handleEdit = (id) => {

        axios.get('ws/ws_pipeline.php',{
            params: {
                task: "getPipelineDetails",
                pipeid: id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setEditInitVal(
                    {
                        pipeline_id: data.record.pipeline_id,
                        pipeline_title_edit: data.record.pipeline_title,
                        naming_won_edit: data.record.naming_won,
                        naming_lost_edit: data.record.naming_lost,
                        pipeline_status_edit: data.record.inactive,
                        pipeline_final_conv_edit: data.record.final_conversion,
                        pipeline_default_edit: data.record.isdefault

                    }
                );

                setIsEdit(true);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
        });

    }

    const sendEdit = (values) => {
        axios.get("ws/ws_pipeline.php", {
            params: {
                task: 'updatePipeline',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                title: values.pipeline_title_edit,
                nameWon: values.naming_won_edit,
                nameLost: values.naming_lost_edit,
                inactive: values.pipeline_status_edit,
                finalConversion: values.pipeline_final_conv_edit,
                isdefault: values.pipeline_default_edit,
                pipeid: values.pipeline_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 2) {
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }else{
                Swal.fire({
                    icon: "success",
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Edit'),
                    timer: 1500
                })
                .then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('Setting_Alert_Warning_Title'),
            text: t("Setting_Alert_Warning_Text"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('Setting_Alert_Warning_ConfirmText'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        })
        .then(result => {
            if (result.isConfirmed) {
                
                axios.get('ws/ws_pipeline.php',{
                    params: {
                        task: "deletePipeline",
                        pipeid: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('Setting_Alert_Success_Title'),
                            text: t('Setting_Alert_Success_Text_Delete'),
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('Setting_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('pipeline_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader />):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingPipeline_AddPipeline')}</button>
                            <Form.Group style={{width: 300}}>
                            <InputGroup>
                                    <Form.Control
                                        placeholder={t('SettingPipeline_Search_Placeholder')}
                                        type="text"
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="ms-auto">{recordFound === 1 ? t('Setting_Record', {count: recordFound}) : recordFound > 1 ? t('Setting_Record', {count: recordFound}) : t('Setting_Record_NoRecord')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}
                    
                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    pipeline_title: "",
                                    naming_won: "",
                                    naming_lost: "",
                                    pipeline_status: "",
                                    pipeline_default: ""
                                }
                            }
                        >

                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingPipeline_Modal_Title_Add')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_PipelineTitle')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.pipeline_title && touched.pipeline_title}
                                                onChange={handleChange("pipeline_title")}
                                            />
                                            {errors.pipeline_title && touched.pipeline_title && <div className="op-error-message">{errors.pipeline_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_NamingWon')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.naming_won && touched.naming_won}
                                                onChange={handleChange("naming_won")}
                                            />
                                            {errors.naming_won && touched.naming_won && <div className="op-error-message">{errors.naming_won}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_NamingLost')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.naming_lost && touched.naming_lost}
                                                onChange={handleChange("naming_lost")}
                                            />
                                            {errors.naming_lost && touched.naming_lost && <div className="op-error-message">{errors.naming_lost}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_Status')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label={t('SettingPipeline_ModalBody_Active')}
                                                    name="status"
                                                    type="radio"
                                                    value={0}
                                                    onChange={handleChange('pipeline_status')}
                                                />
                                                <Form.Check
                                                    inline
                                                    label={t('SettingPipeline_ModalBody_Inactive')}
                                                    name="status"
                                                    type="radio"
                                                    value={1}
                                                    onChange={handleChange('pipeline_status')}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingPipeline_ModalBody_Default')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_Yes')}
                                                    name="default"
                                                    value={1}
                                                    onChange={handleChange('pipeline_default')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_No')}
                                                    name="default"
                                                    value={0}
                                                    onChange={handleChange('pipeline_default')}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingPipeline_ModalFooter_Submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}

                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingPipeline_Modal_Title_Edit')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_PipelineTitle')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.pipeline_title_edit}
                                                isInvalid={errors.pipeline_title_edit && touched.pipeline_title_edit}
                                                onChange={handleChange('pipeline_title_edit')}
                                            />
                                            {errors.pipeline_title_edit && touched.pipeline_title_edit && <div className="op-error-message">{errors.pipeline_title_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_NamingWon')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.naming_won_edit}
                                                isInvalid={errors.naming_won_edit && touched.naming_won_edit}
                                                onChange={handleChange('naming_won_edit')}
                                            />
                                            {errors.naming_won_edit && touched.naming_won_edit && <div className="op-error-message">{errors.naming_won_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_NamingLost')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.naming_lost_edit}
                                                isInvalid={errors.naming_lost_edit && touched.naming_lost_edit}
                                                onChange={handleChange('naming_lost_edit')}
                                            />
                                            {errors.naming_lost_edit && touched.naming_lost_edit && <div className="op-error-message">{errors.naming_lost_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_Status')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_Active')}
                                                    value={0}
                                                    checked={Number(values.pipeline_status_edit) === 0 ? true:false}
                                                    name="statusedit"
                                                    onChange={handleChange('pipeline_status_edit')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_Inactive')}
                                                    value={1}
                                                    checked={Number(values.pipeline_status_edit) === 1 ? true:false}
                                                    name="statusedit"
                                                    onChange={handleChange('pipeline_status_edit')}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipeline_ModalBody_FinalConv')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_Yes')}
                                                    value={1}
                                                    name="finalconv"
                                                    checked={Number(values.pipeline_final_conv_edit) === 1 ? true:false}
                                                    onChange={handleChange('pipeline_final_conv_edit')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_No')}
                                                    value={0}
                                                    name="finalconv"
                                                    checked={Number(values.pipeline_final_conv_edit) === 0 ? true:false}
                                                    onChange={handleChange('pipeline_final_conv_edit')}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingPipeline_ModalBody_Default')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_Yes')}
                                                    value={1}
                                                    checked={Number(values.pipeline_default_edit )=== 1 ? true:false}
                                                    name="defaultedit"
                                                    onChange={handleChange('pipeline_default_edit')}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipeline_ModalBody_No')}
                                                    value={0}
                                                    checked={Number(values.pipeline_default_edit) === 0 ? true:false}
                                                    name="defaultedit"
                                                    onChange={handleChange('pipeline_default_edit')}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingPipeline_ModalFooter_Submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Offcanvas show={isCsList} onHide={setIsCsList} placement="bottom" style={{left: 57, height: "100vh"}}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title as={"h6"}>Pipeline: {selectedPipeline.pipeline_title}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <PipelineStageSetting pipeline_id={selectedPipeline.pipeline_id}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>
            )}
        </div>
    );

}

export default PipelineSetting;