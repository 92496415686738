import { useState, useEffect, useMemo, useRef, forwardRef, useCallback } from "react";
import { Stack, Form, Dropdown, Offcanvas, Container, InputGroup, FormCheck } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import {FormSelect} from "../includes/FormCustom";

import "../lib/scss/op-report-style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
// import { useNavigate } from "react-router-dom";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const ContactReport = () => {
  const { Formik } = formik;
  // const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchVal, setSearchVal] = useState("");
  const [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [userID, setUserID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { session } = useAuth();
  const init = useRef(false);
  const gridRef = useRef();
  const [isReport, setIsReport] = useState(false);
  const [recordFound, setRecordFound] = useState("");
  const [trigger, setTrigger] = useState("");
  const [userList, setUserList] = useState([]);
  const [curationList, setCurationList] = useState([]);
  const [prosCFList, setProsCFList] = useState([]);
  const [cfNeedVal, setCfNeedVal] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  // const [isSelectAll, setIsSelectAll] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [isAddToCur, setIsAddToCur] = useState(false);

  const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const getAllUser = (loginData) => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var userData = data.record.map((data) => {
            return { label: data.user_name, value: data.user_id };
          });

          setUserList(userData);
        } else {
          setUserList([]);
        }
      });
  };

  const getAllCuration = (loginData) => {
    axios
      .get("ws/ws_curation.php", {
        params: {
          task: "listCurationV2",
          mode: 'contact_report',
          utoken: loginData.user_ac_token,
          ctoken: loginData.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          var curationData = data.record.map((data) => {
            return { label: data.curation_title, value: data.curation_id };
          });

          var newArray = [{label: t('Report_CreateNewCuration'), value: 'create'}];
          var combineArray = [...newArray, ...curationData];
          setCurationList(combineArray);
        } else {
          setCurationList([{label: t('Report_CreateNewCuration'), value: 'create'}]);
        }
      });
  };

  const getAllProspectCF = (loginData) => {
    axios
      .get("ws/ws_custom_field.php", {
        params: {
          task: "cfListContactReport",
          area: "person",
          company: loginData.company_id,
          type: "select",
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setProsCFList(data.record);
        } else {
          setProsCFList([]);
        }
      });
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getAllUser(session);
      getAllCuration(session);
      getAllProspectCF(session);
      setIsLoading(false);
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  const addToCurSchema = yup.object().shape({
    curation_id: yup.string().required(t('Report_RequiredFd')),
    curation_title: yup.string().notRequired().when('curation_id', ([curation_id], schema) => {
      if (curation_id === 'create')
        return yup.string().required(t('Report_RequiredFd'));
      return schema;
    }),
  });

  const handleClick = () => {
    if (isIndeterminate) {
      if (gridRef.current.api) {
        gridRef.current.api.forEachNode((node) => node.setSelected(false));
      }
    } else {
      setIsChecked((prevChecked) => {
        const newChecked = !prevChecked;
        setIsIndeterminate(false);

        if (gridRef.current.api) {
          gridRef.current.api.forEachNode((node) => node.setSelected(newChecked));
        }

        return newChecked;
      });
    }
  };

  const onReportSelected = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;
    const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

    setIsChecked(allSelected);
    setIsIndeterminate(atLeastOneSelected && !allSelected);

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  const [reportColumn] = useState([
    {
      // headerComponent: CustomHeaderCheckbox,
      headerName: "",
      field: "",
      checkboxSelection: true,
      resizable: false,
      hide: false,
      width: 50,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return "";
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t('Report_Contact_HeaderName1'),
      field: "prospect_date_time_create",
      resizable: true,
      width: 200,
      hide: false,
    },
    {
      headerName: t('Report_Contact_HeaderName2'),
      field: "prospect_name",
      resizable: true,
      width: 250,
      hide: false,
      onCellClicked: function (params) {
        	if (params.data) {
            window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
          }
      }
    },
    {
      headerName: t('Report_Contact_HeaderName3'),
      field: "prospect_designation",
      resizable: true,
      width: 200,
      hide: false,
    },
    {
      headerName: t('Report_Contact_HeaderName4'),
      field: "owner_name",
      resizable: true,
      flex: 1,
      minWidth: 200,
      hide: false,
    },
    {
      headerName: t('Report_Contact_HeaderName5'),
      field: "prospect_contact",
      resizable: true,
      width: 250,
      hide: false,
    },
    {
      headerName: t('Report_Contact_HeaderName6'),
      field: "prospect_email",
      resizable: false,
      minWidth: 200,
      flex: 2,
      hide: false,
    },
  ]);

  // const dealRowId = useCallback(function (params) {
  //   return params.data.prospect_id.toString();
  // }, []);

  const reportDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        var cfID = [];
        var cfVal = [];
        if (prosCFList.length > 0) {
          prosCFList.forEach((data) => {
            cfID.push(data.cf_id);
            cfVal.push(data.fd_value);
          });
        }

        axios
          .get(`ws/ws_contactreport.php`, {
            params: {
              task: "getContactDataV2",
              ds: startDate,
              de: endDate,
              prospectname: searchVal,
              user: userID,
              cfid: cfID.toString(),
              cfvalue: cfVal.toString(),
              cfNeeded: cfNeedVal,
              startRow: params.startRow,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            if (Number(data.status) === 0) {
              if (Number(data.totalRecord) > 0) {
                setIsReport(true);
              } else {
                setIsReport(false);
              }
            } else {
              setIsReport(false);
              Swal.fire({
                icon: 'error',
                title: t('Report_alert_Title'),
                text: t('Report_alert_Text')
              });
            }

            setTimeout(() => {
              var reportInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                reportInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setRecordFound(totalRecord);

                var colDef = [];

                if (Number(data.prospect_custom_field_cf_info_total) > 0) {
                  data.prospect_custom_field_cf_info.forEach((data) => {
                    if (data.cf_area === "person") {
                      colDef.push({
                        headerName: data.cf_label,
                        field: "",
                        width: 200,
                        hide: true,
                        valueGetter: (params) => {
                          if (params.data !== undefined) {
                            if (Number(params.data.prospect_custom_field) > 0) {
                              for (let index = 0; index < params.data.prospect_custom_field; index++) {
                                if (params.data.prospect_custom_field_data[index].cf_id === data.cf_id) {
                                  return params.data.prospect_custom_field_data[index].fd_value;
                                }
                              }
                            } else {
                              return "";
                            }
                          } else {
                            return "";
                          }
                        },
                        cellRenderer: (params) => {
                          if (params.data !== undefined) {
                            if (Number(params.data.prospect_custom_field) > 0) {
                              for (let index = 0; index < params.data.prospect_custom_field; index++) {
                                if (params.data.prospect_custom_field_data[index].cf_id === data.cf_id) {
                                  return params.data.prospect_custom_field_data[index].fd_value;
                                }
                              }
                            } else {
                              return "";
                            }
                          } else {
                            return "";
                          }
                        },
                      });
                    }
                  }); 
                }

                // setReportColumn((prev) =>[...prev, colDef]);

                if (data.SHOW_CF === 0) {
                  colDef.forEach((data) => {
                    if (data.headerName !== "" && data.field === "") {
                      data.hide = true;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
                } else {
                  colDef.forEach((data) => {
                    if (data.field === "") {
                      data.hide = false;
                    }
                  });

                  gridRef.current.api.setGridOption("columnDefs", [...reportColumn, ...colDef]);
                }
              } else {
                reportInfo = [];
                totalRecord = 0;
                setRecordFound(totalRecord);
              }

              var rowsThisPage = reportInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }

              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [trigger, session]);  // eslint-disable-line react-hooks/exhaustive-deps

  const reportColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const RenderBulkActionToggle = forwardRef(({ children, onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    return (
      <button ref={ref} onClick={openDropdown} className="btn btn-light shadow">
        <FontAwesomeIcon icon={faBullhorn} size="lg" />
        {children}
      </button>
    );
  });

  const RenderBulkActionMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Dropdown.Header className="fw-semibold op-text-medium">{t('Report_BulkAction')}</Dropdown.Header>
        <Dropdown.Item onClick={openAddToCuration} disabled={Number(session.company_id) === 251 ? true : false}>{t('Report_Add_To_Curation')}</Dropdown.Item>
      </div>
    );
  });

  const openAddToCuration = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t('Report_Contact_OpenCurAlert_Title'),
        text: t('Report_Contact_OpenCurAlert_Text'),
      });
    } else {
      setIsAddToCur(true);
    }
  };

  const handleDownload = () => {
    var params = {
      fileName: "contact-report.csv",
      processCellCallback: function (params) {
        
        var res = params.value;
        if (res !== undefined && res !== null && res !== "") {
          res = res.replace(/<br>/g, "\r\n");
          res = res.replace(/<li>/g, "\r\n");
          res = res.replace(/<[^>]+>/g, "");
          res = res.replace(/&nbsp;/g, "");
        }

        return res;
      },
    };

    gridRef.current.api.exportDataAsCsv(params);
  };

  const sendAddCuration = async (values) => {
    if (values.curation_id === 'create') {
      triggerNewCuration(values);
    }else{
      triggerAddToCuration(values);
    }
  };

  const triggerNewCuration = (values) => {
    axios.get('ws/ws_curation.php', {
      params: {
        task: 'addCurationV2',
        curation_title: values.curation_title,
        curation_source: 'contact_report',
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        values.curation_id = data.record;
        triggerAddToCuration(values);
      }else{
        Swal.fire({
          icon: 'error',
          title: t('Report_Contact_Cur_Failed_Title'),
          text: t('Report_Contact_Cur_Failed_Text'),
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  const triggerAddToCuration = async(values) => {
    
    var prospectID = rowData.map((data) => {
      return data.data.prospect_id;
    });

    var curationApiData = new FormData();

    curationApiData.append('task', 'addCurationProspect');
    curationApiData.append('curation', values.curation_id);
    curationApiData.append('prospects', prospectID.toString());
    curationApiData.append('utoken',session.user_ac_token);
    curationApiData.append('ctoken',session.company_token);

    try {
      var response = await axios.post("ws/ws_curation.php",curationApiData);

      let data = response.data;

      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: t('Report_Contact_Cur_Success_Title'),
          text: t('Report_Contact_Cur_Success_Text'),
          timer: 1500
        }).then(result => {
          setIsAddToCur(false);
          gridRef.current.api.forEachNode((node) => node.setSelected(false));
        });
      }else{
        Swal.fire({
          icon: "error",
          title: t('Report_Contact_Cur_Failed_Title'),
          text: t('Report_Contact_Cur_Failed_Text'),
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  }

  return (
    <Container fluid style={{ padding: 0 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="report-main">
          <div className="report-filter">
            <section className="header shadow-sm">
              <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t('Report_FilterCriteria')}</div>
            </section>
            <section className="content">
              <Form.Group className="mb-3">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={t('Report_Contact_SearchByProspectName')}
                    value={searchVal}
                    onChange={(e) => {setSearchVal(e.target.value)}}
                  />
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="lg"/>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <FormSelect
                  placeholder={t('Report_AllUser')}
                  options={userList}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    e !== null ? setUserID(e.value) : setUserID("Report_CustomField");
                  }}
                />
              </Form.Group>

              <div className="op horizontal-start divider op-text-medium my-3">{t('Report_CustomField')}</div>

              <Form.Group className="mb-3">
                <Form.Check
                  type="switch"
                  label={t('Report_ShowAllField')}
                  onChange={(e) => {
                    e.target.checked === true ? setCfNeedVal(1) : setCfNeedVal(0);
                  }}
                />
              </Form.Group>

              {prosCFList.map((record, i) => (
                <Form.Group key={i} className="mb-3">
                  <FormSelect
                    options={Object.values(JSON.parse(record.cf_value)).map((record) => ({ label: record, value: record }))}
                    placeholder={record.cf_label}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      e !== null ? (record.fd_value = e.value) : (record.fd_value = "");
                    }}
                  />
                </Form.Group>
              ))}
            </section>
            <section className="footer">
              <div className="d-flex justify-content-center align-items-center w-100">
                <button
                  className="btn op-button op-primary-color text-light shadow me-2"
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                >
                 {t('Report_ViewReport')}
                </button>
                <button className="btn btn-dark" onClick={handleDownload}>
                  {t('Report_Download')}
                </button>
              </div>
            </section>
          </div>

          <div className="report-content">
            <section className="w-100">
              <Stack direction="horizontal">
                {isReport && <div className="op-label bg-light shadow-sm">
                  <FormCheck type="checkbox" onClick={handleClick} checked={isChecked}/>
                </div>}
                {isRowSelected === true ? (
                  <div className="d-flex">
                    <Dropdown>
                      <Dropdown.Toggle as={RenderBulkActionToggle} />
                      <Dropdown.Menu className="shadow border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                    </Dropdown>
                    <h6 className="op-text-medium mt-2 ms-2">
                      {rowCount} {rowCount > 1 ? t('Report_Contact_Rows') : t('Report_Contact_Row')} {t('Report_Selected')}
                    </h6>
                  </div>
                ) : null}
                {isReport && <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>}
              </Stack>
            </section>

            <section className="w-100">
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={gridRef}
                    onSelectionChanged={onReportSelected}
                    columnDefs={reportColumn}
                    datasource={reportDataSource}
                    defaultColDef={reportColDef}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={1}
                    infiniteInitialRowCount={1}
                    maxBlocksInCache={200}
                    // getRowId={dealRowId}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      )}

      <Offcanvas show={isAddToCur} onHide={setIsAddToCur} backdrop="static" placement="end">
        <Formik
          validationSchema={addToCurSchema}
          onSubmit={sendAddCuration}
          initialValues={{
            curation_id: "",
            curation_title: "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t('Report_Contact_Canvas_Header')}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold mb-3">{rowCount > 1 ? rowCount + ` ${t('Report_Contact_Canvas_records')}` : rowCount + ` ${t('Report_Contact_Canvas_record')}`} {t('Report_Contact_Canvas_recordAffected')}</h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="curation_id"
                    placeholder={t('Report_Contact_Canvas_SelectCur')}
                    options={curationList}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      setFieldValue('curation_id', e ? e.value : '');
                      setFieldValue('curation_title', '');
                    }}
                    isInvalid={errors.curation_id && touched.curation_id}
                  />
                  {errors.curation_id && touched.curation_id && <div className="op-error-message">{errors.curation_id}</div>}
                </Form.Group>
                {values.curation_id === 'create' &&
                  <Form.Group>
                    <Form.Label>{t('Report_CurationTitle')}</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={errors.curation_title && touched.curation_title}
                      onChange={(e) => {setFieldValue('curation_title', e.target.value)}}
                    />
                    {errors.curation_title && touched.curation_title && <div className="op-error-message">{errors.curation_title}</div>}
                  </Form.Group>
                }
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t('Report_Contact_Canvas_Footer')}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>
    </Container>
  );
};

export default ContactReport;
