import React, { useEffect, useState, useRef, useMemo, forwardRef, useCallback } from "react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { Card, Col, Container, Row, Stack, Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FormDate, FormSelect } from "../includes/FormCustom";
import { faTrash, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import * as formik from "formik";
import * as yup from "yup";
import moment from "moment";
import "../lib/scss/op-gl-play-report.scss";
import Swal from "sweetalert2";


const GLPlayReport = () => {
    const gridRef = useRef();
    const { session } = useAuth();
    const { Formik } = formik;
    const initialized = useRef(false);
    const [loading, setLoading] = useState(true);
    const formattedOneMonthAgoDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const formattedTodayDate = moment().format('YYYY-MM-DD');
    const [dateRange, setdateRange] = useState({
        ds: formattedOneMonthAgoDate,
        de: formattedTodayDate,
    });
    const [GLReport, setGLReport] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [isIndeterminate, setIsIndeterminate] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [isImportModal, setisImportModal] = useState(false);
    const [curationList, setCurationList] = useState([]);
    const [importProspect, setImportProspect] = useState('');
    const [defaultCurationTitle, setDefaultCurationTitle] = useState("");
    const [refreshKey, setRefreshKey] = useState(0);


    const getGLReport = async (values) => {
        try {
            var res = []
            if (values) {
                res = await axios.get("ext/gamuda/api_gl_play_report.php", {
                    params: {
                        task: 'ViewReport',
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        ds: dateRange.ds,
                        de: dateRange.de,
                        amount_start: values.range_from || '',
                        amount_end: values.range_to || ''
                    }
                })
            } else {
                res = await axios.get("ext/gamuda/api_gl_play_report.php", {
                    params: {
                        task: 'ViewReport',
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        ds: dateRange.ds,
                        de: dateRange.de,
                        amount_start: '',
                        amount_end: ''
                    }
                })
            }

            const data = res.data;

            if (data.status === 0) {
                setGLReport(data.record);
            } else {
                setGLReport([]);
            }
        } catch {

        }
    }

    const getCurationList = async () => {
        try {
            const res = await axios.get("ws/ws_curation.php", {
                params: {
                    task: 'listCurationV2',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    mode: 'gl_play'
                }
            })

            const data = res.data;

            let curationListing = [];

            if (data.status === 0) {
                curationListing = data.record.map(cur => ({
                    value: cur.curation_id,
                    label: cur.curation_title
                }))

                curationListing.unshift({
                    value: "0",
                    label: "Create New Curation"
                })

                setCurationList(curationListing);
            } else {
                curationListing.unshift({
                    value: "0",
                    label: "Create New Curation"
                })
            }
        } catch {

        }
    }


    // Side bar function ------------------------------------
    const handleFormSubmit = (values) => {
        getGLReport(values);
    };

    const handleImportCuration = async (values) => {
        setLoading(true);
        try {
            const res = await axios.get("ext/gamuda/api_gl_play_report.php", {
                params: {
                    task: 'ImportCuration',
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    curation_id: values.curation_id,
                    curation_title: values.curation_title,
                    prospects: importProspect
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setisImportModal(false);
                setRefreshKey(prevKey => prevKey + 1);

                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully imported',
                    timer: 1500
                });
            } else {
                setisImportModal(false);
                setRefreshKey(prevKey => prevKey + 1);

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Error happened when importing data',
                    timer: 1500
                });
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Some error happened',
                timer: 1500
            });
        } finally {
            setLoading(false);
        }
    }

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "83vh" }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const CustomHeaderCheckbox = forwardRef((props, ref) => {
        const { api } = props;

        const handleClick = () => {
            if (isIndeterminate) {
                if (api) {
                    api.forEachNode((node) => node.setSelected(false));
                }
            } else {
                setIsChecked((prevChecked) => {
                    const newChecked = !prevChecked;
                    setIsIndeterminate(false);

                    if (api) {
                        api.forEachNode((node) => node.setSelected(newChecked));
                    }

                    return newChecked;
                });
            }
        };

        return (
            <div ref={ref} className="ag-header-cell" onClick={handleClick} style={{ padding: 0 }}>
                <div>
                    {isChecked && <span className="ag-icon ag-icon-checkbox-checked"></span>}
                    {!isChecked && !isIndeterminate && <span className="ag-icon ag-icon-checkbox-unchecked"></span>}
                    {isIndeterminate && <span className="ag-icon ag-icon-checkbox-indeterminate"></span>}
                </div>
            </div>
        );
    });

    const emailRenderer = (params) => {
        if (params.data) {
            const getEmail = params.data.email1 || params.data.email2 || params.data.email3;

            return (
                <span>
                    {getEmail}
                </span>
            )
        }
    }

    const phoneRenderer = (params) => {
        if (params.data) {
            const getContact = params.data.contact1 || params.data.contact2 || params.data.contact3;

            return (
                <span>
                    {getContact}
                </span>
            )
        }
    }

    const gridColumn = [
        {
            headerComponent: CustomHeaderCheckbox,
            headerName: "",
            field: "",
            checkboxSelection: true,
            resizable: false,
            width: 50,
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                    return "";
                } else {
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            },
        },
        { headerName: "Order Date", field: "date_time_create", width: 250, filter: 'agTextColumnFilter' },
        {
            headerName: "Name",
            field: "purchaser_name",
            width: 250,
            filter: 'agTextColumnFilter',
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            },
            cellClass: 'cursor-pointer',
            onCellClicked: function (params) {
                if (params.data) {
                    var prospectId = params.data.prospect_id;
                    window.open(`${session.origin}/prospect/${prospectId}`, "_blank");
                }
            }
        },
        { headerName: "Email", field: "", width: 250, filter: 'agTextColumnFilter', cellRenderer: emailRenderer },
        { headerName: "Phone Number", field: "", width: 250, filter: 'agNumberColumnFilter', cellRenderer: phoneRenderer },
        { headerName: "Order Number", field: "glplay_order_id", width: 250, filter: 'agTextColumnFilter' },
        { headerName: "Amount", field: "order_amount", width: 250 },
        {
            headerName: "PDPA",
            field: "pdpa",
            width: 250,
            cellRenderer: (params) => {
                return params.data.pdpa === null ? 'allow' : params.data.pdpa;
            }
        },
        {
            headerName: "Bulk Email",
            field: "email_bulk",
            width: 250,
            cellRenderer: (params) => {
                return params.data.email_bulk === null ? 'allow' : params.data.email_bulk;
            }
        },
    ];

    const onDealSelected = useCallback((params) => {
        const selectedNodes = params.api.getSelectedNodes();
        const atLeastOneSelected = selectedNodes.length > 0;
        const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

        setIsChecked(allSelected);
        setIsIndeterminate(atLeastOneSelected && !allSelected);

        setIsRowSelected(atLeastOneSelected);
        setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
        setRowData(atLeastOneSelected ? selectedNodes : []);
    }, []);

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };


    // USEEFFECT
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getGLReport();
        }
    }, []);

    const toggleImportModal = () => {
        if (rowData.length > 0) {
            const allRowsValid = rowData.every(row => row.pdpa !== null && row.pdpa !== undefined && row.bulk_email !== null && row.bulk_email !== undefined);

            const prospectIds = new Set(rowData.map(row => row.data.prospect_id));
            const uniqueProspects = Array.from(prospectIds);
            const joinedProspects = uniqueProspects.join('|');

            setImportProspect(joinedProspects);

            const defaultTitle = `curation_${dateRange.ds}_${dateRange.de}`;
            setDefaultCurationTitle(defaultTitle);


            if (!allRowsValid) {
                getCurationList()
                setisImportModal(prevState => !prevState);
            } else {
                alert("Some selected rows are missing PDPA or Bulk Email information.");
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Some selected PDPA or bulk email is disallowed',
                });
            }
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Warning',
                text: 'Please select rows to send to Mtarget.',
                timer: 1500
            });
        }
    };

    const handleDownload = () => {
        var params = {
          fileName: "gl_play_report.csv",
          processCellCallback: function (params) {
            if (params.column.colId === "auto_increment_num") {
              return params.node.data.temprecord_id;
            } else if (params.column.colId === "deal_title") {
              return params.node.data.deal_title;
            } else {
              var res = params.value;
              if (res!== undefined && res !== null && res !== "") {
                res = res.replace(/<br>/g, "\r\n");
                res = res.replace(/<li>/g, "\r\n");
                res = res.replace(/<[^>]+>/g, "");
                res = res.replace(/&nbsp;/g, "");
              }
    
              return res;
            }
          },
        };
    
        gridRef.current.api.exportDataAsCsv(params);
      };


    return (
        <Container key={refreshKey} fluid style={{ height: "calc(100vh - 56px)", overflow: "auto", padding: "0px 10px 0px 0px" }}>
            <section className="content-body-gl">
                <div className="section-sidebar-gl me-3">
                    <Stack direction="vertical">
                        <Formik
                            initialValues={{
                                ds: "",
                                de: "",
                                range_from: "",
                                range_to: "",
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            {({ values, handleChange, handleSubmit, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group className="mx-3 my-4">
                                        <Form.Label className="mb-2">Select Date</Form.Label>
                                        <Form.Group className="mb-2">
                                            <Form.Control
                                                type="date"
                                                placeholder="From"
                                                name="ds"
                                                className="shadow-sm"
                                                value={moment(dateRange.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control
                                                type="date"
                                                placeholder="To"
                                                name="de"
                                                className="shadow-sm"
                                                value={moment(dateRange.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                    onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                                }}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="mx-3 my-4">
                                        <Form.Label>Amount Range</Form.Label>
                                        <Form.Group>
                                            <Form.Control
                                                className="mb-2"
                                                type="text"
                                                name="range_from"
                                                placeholder="From range"
                                                value={values.range_from}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <Form.Control
                                                className="mb-2"
                                                type="text"
                                                name="range_to"
                                                placeholder="To range"
                                                value={values.range_to}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group className="d-flex justify-content-between mx-3">
                                        <Button variant="success" type="submit" style={{ width: "100px" }}>
                                            View
                                        </Button>
                                        <Button variant="secondary" type="button" style={{ width: "100px" }} onClick={handleDownload}>
                                            Download
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </Stack>
                </div>

                <Col>
                    <div className="d-flex align-items-center" style={{ height: "60px" }}>
                        <Form.Group className="d-flex">
                            <Button disabled={!isRowSelected} onClick={toggleImportModal}>
                                Send to MTarget
                            </Button>

                            <h6 className="op-text-medium mt-2 ms-3">
                                {rowCount} rows selected
                            </h6>
                        </Form.Group>
                    </div>
                    <div style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={gridColumn}
                                rowData={GLReport}
                                onSelectionChanged={onDealSelected}
                                rowSelection={"multiple"}
                                rowHeight={80}
                                pagination={true}
                            />
                        </div>
                    </div>
                </Col>

                <Modal show={isImportModal} onHide={toggleImportModal} size="md">
                    <Formik
                        initialValues={{
                            curation_options: curationList.length > 0 ? curationList[0].value : "",
                            curation_title: defaultCurationTitle,
                            curation_id: "",
                        }}
                        onSubmit={handleImportCuration}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            touched,
                            setFieldValue
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header closeButton>
                                    <Modal.Title className="op-text-bigger">Add Curation</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Stack className="mb-3">
                                        <Form.Group>
                                            <Form.Label>
                                                Curation Title
                                            </Form.Label>
                                            <FormSelect
                                                options={curationList}
                                                placeholder="Select an option"
                                                border={false}
                                                shadow={true}
                                                valueDefault={curationList.find(opt => opt.value === values.curation_options)}
                                                onChange={(selectedOption) => {
                                                    const selectedValue = selectedOption.value;
                                                    setFieldValue("curation_options", selectedValue);

                                                    // Update curation_title based on selected option
                                                    if (selectedValue === "0") {
                                                        setFieldValue("curation_title", defaultCurationTitle);
                                                        setFieldValue("curation_id", "");
                                                    } else {
                                                        const selectedCuration = curationList.find(cur => cur.value === selectedValue);
                                                        setFieldValue("curation_title", selectedCuration && selectedCuration.label ? selectedCuration.label : "");
                                                        setFieldValue("curation_id", selectedValue);
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    {/* Conditionally show/hide based on selected option */}
                                    {values.curation_options === "0" && (
                                        <Form.Group>
                                            <Form.Label>
                                                Curation Title
                                            </Form.Label>
                                            <Form.Control
                                                placeholder="Enter a title"
                                                type="text"
                                                name="curation_title"
                                                value={values.curation_title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    )}
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                        Submit
                                    </button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>

                </Modal>
            </section>
        </Container>
    );
};

export default GLPlayReport;
