import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup  } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import { useTranslation } from "react-i18next";
import NoteEditor from "../includes/NoteEditor";

const EmailTemplateSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingEmailTemplate_headername_1'),
            field: "email_template_title",
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: t('SettingEmailTemplate_headername_2'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                var isCheck = params.data.inactive;
                return(
                    <div className="d-flex justify-content-center align-items-center">
                        <Form.Check
                            type="switch"
                            className="d-flex align-items-center justify-content-center"
                            checked={Number(isCheck) === 0 ? true:false}
                            onChange={() => {markAsActive(params.data)}}
                        />
                    </div>
                );
            }
        },
        {
            headerName: t('SettingEmailTemplate_headername_3'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.email_template_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data.email_template_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {

        axios.get('ws/ws_email.php',{
            params: {
                task: "getAllEmailTemplate",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data) {
                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(true);
            setIsLoading(false);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const markAsActive = (data) => {

        var email_status = data.inactive;

        if (Number(email_status) === 0) {
            email_status = 1;
        }else{
            email_status = 0;
        }

        axios.get('ws/ws_email.php',{
            params:{
                task: 'setEmailTemplateStatus',
                template_id: data.email_template_id,
                status: email_status,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingEmailTemplate_alert_failed_title'),
                    text: t('SettingEmailTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const addSchema = yup.object().shape({
        template_title: yup.string().required(t('SettingEmailTemplate_required_field')),
        template_email_subject: yup.string().required(t('SettingEmailTemplate_required_field')),
        template_compose: yup.string().required(t('SettingEmailTemplate_required_field'))
    });

    const sendAdd = async(values) => {
        const params = new FormData();

        params.append('task','saveTemplate');
        params.append('title', values.template_title);
        params.append('subject', values.template_email_subject);
        params.append('message', values.template_compose);
        params.append('utoken', session.user_ac_token);
        params.append('ctoken', session.company_token);

        try {
            const res = await axios.post('ws/ws_email_post.php',params);

            let data = res.data;

            if (data) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingEmailTemplate_alert_success_title'),
                    text: t('SettingEmailTemplate_alert_success_add_text'),
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }
        
    }

    const editSchema = yup.object().shape({
        template_title_edit: yup.string().required(t('SettingEmailTemplate_required_field')),
        template_email_subject_edit: yup.string().required(t('SettingEmailTemplate_required_field')),
        template_compose_edit: yup.string().required(t('SettingEmailTemplate_required_field'))
    });

    const handleEdit = (id) => {
        
        axios.get('ws/ws_email.php',{
            params: {
                task: "getTemplateData",
                template_id: id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                
                setEditInitVal(
                    {
                        template_id: data.record.email_template_id,
                        template_title_edit: data.record.email_template_title,
                        template_email_subject_edit: data.record.email_subject,
                        template_compose_edit: data.record.email_message
                    }
                );

                setIsEdit(true);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingEmailTemplate_alert_failed_title'),
                    text: t('SettingEmailTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                Title: error.message
            });
        });
    }

    const sendEdit = async(values) => {
        var params = new FormData();

        params.append('task', 'updateTemplate');
        params.append('templateid', values.template_id);
        params.append('template_title', values.template_title_edit);
        params.append('subject', values.template_email_subject_edit);
        params.append('message', values.template_compose_edit);
        params.append('utoken', session.user_ac_token);
        params.append('ctoken', session.company_token);

        try {

            const res = await axios.post('ws/ws_email_post.php', params);

            let data = res.data;

            if (data) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingEmailTemplate_alert_success_title'),
                    text: t('SettingEmailTemplate_alert_success_edit_text'),
                    timer: 1500
                })
                .then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }
            
        } catch (error) {
            
            Swal.fire({
                icon: "error",
                title: error.message
            });

        }

    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('Setting_alert_warning_title'),
            text: t('Setting_Alert_Warning_Text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('Setting_Alert_Warning_ConfirmText'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_email.php', {
                    params: {
                        task: 'DeleteEmailTemplate',
                        email_template_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: t('Setting_Alert_Success_Title'),
                            text: t('Setting_Alert_Success_Text_Delete'),
                            timer: 1500
                        })
                        .then(() => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('Setting_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    console.log(error.message);
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('email_template_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }


    return(
        <div>
            {isLoading ? (<Loader />):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingEmailTemplate_btn_add')}</button>
                            <Form.Group style={{width: 300}}>
                            <InputGroup>
                                    <Form.Control
                                        placeholder={t('SettingEmailTemplate_search_placeholder')}
                                        type="text"
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="ms-auto">{recordFound === 0 ? t('SettingEmailTemplate_record_no'):t('SettingEmailTemplate_record',{count: recordFound})}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd} size="lg">
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    template_title: "",
                                    template_email_subject: "",
                                    template_compose: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingEmailTemplate_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_title && touched.template_title}
                                                onChange={handleChange('template_title')}
                                            />
                                            {errors.template_title && touched.template_title && <div className="op-error-message">{errors.template_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_add_title_2')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_email_subject && touched.template_email_subject}
                                                onChange={handleChange('template_email_subject')}
                                            />
                                            {errors.template_email_subject && touched.template_email_subject && <div className="op-error-message">{errors.template_email_subject}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_add_title_3')}</Form.Label>

                                            <div>
                                                <NoteEditor value={values.template_compose} onChangeValue={(value) => {handleChange('template_compose')(value)}}/>
                                                {errors.template_compose && touched.template_compose && <div className="op-error-message">{errors.template_compose}</div>}
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingEmailTemplate_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingEmailTemplate_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_title_edit && touched.template_title_edit}
                                                value={values.template_title_edit}
                                                onChange={handleChange('template_title_edit')}
                                            />
                                            {errors.template_title_edit && touched.template_title_edit && <div className="op-error-message">{errors.template_title_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_edit_title_2')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_email_subject_edit && touched.template_email_subject_edit}
                                                value={values.template_email_subject_edit}
                                                onChange={handleChange('template_email_subject_edit')}
                                            />
                                            {errors.template_email_subject_edit && touched.template_email_subject_edit && <div className="op-error-message">{errors.template_email_subject_edit}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingEmailTemplate_modalBody_edit_title_3')}</Form.Label>
                                            <div>
                                                <NoteEditor value={values.template_compose_edit} onChangeValue={(value) => {handleChange('template_compose_edit')(value)}}/>
                                                {errors.template_compose_edit && touched.template_compose_edit && <div className="op-error-message">{errors.template_compose_edit}</div>}
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingEmailTemplate_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default EmailTemplateSetting;