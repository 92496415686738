import { useState, useRef, useEffect } from "react";
import { Stack, Modal, Form, ListGroup, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faPlus, faUser } from "@fortawesome/pro-duotone-svg-icons";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormSelectCreatable } from "../../includes/FormCustom";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";

const ReferralComponent = ({ prospectID, dealID }) => {
  const { session } = useAuth();
  const init = useRef(false);
  const { Formik } = formik;

  const [introducerList, setIntroducerList] = useState([]);
  const [refereeList, setRefereeList] = useState([]);

  const [isAddInroducer, setIsAddIntroducer] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);

  const getAllIntroducer = () => {
    axios
      .get("ws/ws_prospect_introducer.php", {
        params: {
          task: "GetProspectIntroducer",
          prospect_id: prospectID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status_introducer) === 0) {
          setIntroducerList(data.record_introducer);
        } else {
          setIntroducerList([]);
        }

        if (Number(data.status_referee) === 0) {
          setRefereeList(data.record_referee);
        } else {
          setRefereeList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setIntroducerList([]);
        setRefereeList([]);
      });
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getAllIntroducer();
    }
  }, []);

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);

    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
            }));

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
        }
      });
  };

  const addSchema = yup.object().shape({
    introducer_id: yup.string().required("This field is required"),
  });

  const sendAdd = (values) => {
    axios
      .get("ws/ws_prospect_introducer.php", {
        params: {
          task: "CreateProspectIntroducer",
          introducer_id: values.introducer_id,
          prospect_id: prospectID,
          deal_id: dealID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (Number(data.status) === 0) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully added introducer",
            timer: 1500,
          }).then(() => {
            getAllIntroducer();
            setIsAddIntroducer(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Please try again or contact your IT Support",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (values) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This introducer will be removed from this prospect",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_prospect_introducer.php", {
            params: {
              task: "RemoveProspectIntroducer",
              prospect_id: prospectID,
              introducer_id: values.introducer_id,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data = res.data;

            if (Number(data.status) === 0) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Successfully remove introducer from this prospect",
                timer: 1500,
              }).then(() => {
                getAllIntroducer();
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Please try again or contact your IT Support",
              });
            }
          });
      }
    });
  };

  return (
    <div>
      <ListGroup>
        <ListGroup.Item className="mb-3 border rounded">
          <Stack direction="horizontal" gap={2} className="mb-3 align-items-center">
            <div className="fw-bold">Introducer</div>
            <Button variant="light" size="sm" className="border shadow-sm ms-auto" onClick={setIsAddIntroducer} style={{ padding: "0 5px" }}>
              <FontAwesomeIcon icon={faPlus} size="sm" />
            </Button>
          </Stack>
          {introducerList.length > 0 &&
            introducerList.map((data, index) => (
              <Stack direction="horizontal" gap={2} key={index} className="mb-2">
                <FontAwesomeIcon icon={faUser} size="" className="me-2" />
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(`/prospect/${data.prospect_id}`, "_blank");
                  }}
                >
                  {data.introducer_name}
                </span>
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  size="lg"
                  className="ms-auto cursor-pointer"
                  onClick={() => {
                    handleDelete(data);
                  }}
                />
              </Stack>
            ))}
          {introducerList.length === 0 && <p className="mb-0">None at the moment</p>}
        </ListGroup.Item>
        <ListGroup.Item className="border rounded">
          <div className="fw-bold">Referee</div>
          {refereeList.length > 0 &&
            refereeList.map((data, index) => (
              <Stack direction="horizontal" gap={2} key={index} className="mb-2">
                <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(`/prospect/${data.prospect_id}`, "_blank");
                  }}
                >
                  {data.referee_name}
                </span>
              </Stack>
            ))}
          {refereeList.length === 0 && <p className="mb-0">None at the moment</p>}
        </ListGroup.Item>
      </ListGroup>

      <Modal show={isAddInroducer} onHide={setIsAddIntroducer}>
        <Formik
          validationSchema={addSchema}
          onSubmit={sendAdd}
          initialValues={{
            introducer_id: "",
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add Introducer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>Prospect Name</Form.Label>
                  <FormSelectCreatable
                    loadOptions={getProspect}
                    isLoading={isFindProspect}
                    isInvalid={errors.introducer_id && touched.introducer_id}
                    onChange={(e) => {
                      setFieldValue("introducer_id", e ? e.value : "");
                    }}
                  />
                  {errors.introducer_id && touched.introducer_id && <div className="op-error-message">{errors.introducer_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Submit
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ReferralComponent;
