import { useState, useRef, useEffect, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown, Container, CloseButton, ButtonGroup, ToggleButton, Accordion, useAccordionButton, Button } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { setLatestTime, setDateTimeStr, setDateTimeStrVN } from "../../lib/js/Function";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faBuilding, faCalendarClock, faCalendarDay, faChevronDown, faCircleArrowDownRight, faCircleCheck, faCircleMinus, faCircleUser, faDollarSign, faEnvelope, faEnvelopeCircleCheck, faEnvelopeOpen, faEnvelopeOpenText, faFlag, faLink, faListCheck, faLocationCheck, faLockKeyholeOpen, faNoteSticky, faPeopleArrows, faPhone, faPhoneArrowDownLeft, faPhoneArrowUpRight, faPhoneVolume, faSave, faStopwatch, faUser, faUsers } from "@fortawesome/pro-duotone-svg-icons";
import { faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import "../../lib/scss/op-deal-details.scss";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEllipsis, faFile } from "@fortawesome/pro-solid-svg-icons";
import ReactHtmlParser from "html-react-parser";
import NoteEditor from "../../includes/NoteEditor";
import { useTranslation } from "react-i18next";

const ActivityTimeline = ({ prospectID }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const editorRefNote = useRef(null);
    const editorRefActivity = useRef(null);
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState("notes");
    const [isActiveTab, setIsActiveTab] = useState(false);

    const [userList, setUserList] = useState([]);

    const [timelineData, setTimelineData] = useState([]);

    const [isEditActivity, setIsEditActivity] = useState(false);
    const [editActivityVal, setEditActivityVal] = useState({});

    const [isEditNote, setIsEditNote] = useState(false);
    const [editNoteVal, setEditNoteVal] = useState({});

    var vietnam = [402, 412];

    const durationList = [
        { label: "5 Mins", value: "5min" },
        { label: "10 Mins", value: "10min" },
        { label: "15 Mins", value: "15min" },
        { label: "20 Mins", value: "20min" },
        { label: "30 Mins", value: "30min" },
        { label: "35 Mins", value: "35min" },
        { label: "40 Mins", value: "40min" },
        { label: "45 Mins", value: "45min" },
        { label: "50 Mins", value: "50min" },
        { label: "55 Mins", value: "55min" },
        { label: "1 Hour", value: "60min" },
        { label: "1 Hour 30 Mins", value: "90min" },
        { label: "2 Hours", value: "120min" },
        { label: "2 Hours 30 Mins", value: "150min" },
        { label: "3 Hours", value: "180min" },
        { label: "3 Hours 30 Mins", value: "210min" },
        { label: "4 Hours", value: "240min" },
    ];
      
      const timings = [
        { label: "08:00", value: "08:00" },
        { label: "08:15", value: "08:15" },
        { label: "08:30", value: "08:30" },
        { label: "08:45", value: "08:45" },
        { label: "09:00", value: "09:00" },
        { label: "09:15", value: "09:15" },
        { label: "09:30", value: "09:30" },
        { label: "09:45", value: "09:45" },
        { label: "10:00", value: "10:00" },
        { label: "10:15", value: "10:15" },
        { label: "10:30", value: "10:30" },
        { label: "10:45", value: "10:45" },
        { label: "11:00", value: "11:00" },
        { label: "11:15", value: "11:15" },
        { label: "11:30", value: "11:30" },
        { label: "11:45", value: "11:45" },
        { label: "12:00", value: "12:00" },
        { label: "12:15", value: "12:15" },
        { label: "12:30", value: "12:30" },
        { label: "12:45", value: "12:45" },
        { label: "13:00", value: "13:00" },
        { label: "13:15", value: "13:15" },
        { label: "13:30", value: "13:30" },
        { label: "13:45", value: "13:45" },
        { label: "14:00", value: "14:00" },
        { label: "14:15", value: "14:15" },
        { label: "14:30", value: "14:30" },
        { label: "14:45", value: "14:45" },
        { label: "15:00", value: "15:00" },
        { label: "15:15", value: "15:15" },
        { label: "15:30", value: "15:30" },
        { label: "15:45", value: "15:45" },
        { label: "16:00", value: "16:00" },
        { label: "16:15", value: "16:15" },
        { label: "16:30", value: "16:30" },
        { label: "16:45", value: "16:45" },
        { label: "17:00", value: "17:00" },
        { label: "17:15", value: "17:15" },
        { label: "17:30", value: "17:30" },
        { label: "17:45", value: "17:45" },
        { label: "18:00", value: "18:00" },
        { label: "18:15", value: "18:15" },
        { label: "18:30", value: "18:30" },
        { label: "18:45", value: "18:45" },
        { label: "19:00", value: "19:00" },
        { label: "19:15", value: "19:15" },
        { label: "19:30", value: "19:30" },
        { label: "19:45", value: "19:45" },
        { label: "20:00", value: "20:00" },
        { label: "20:15", value: "20:15" },
        { label: "20:30", value: "20:30" },
        { label: "20:45", value: "20:45" },
        { label: "21:00", value: "21:00" },
        { label: "21:15", value: "21:15" },
        { label: "21:30", value: "21:30" },
        { label: "21:45", value: "21:45" },
        { label: "22:00", value: "22:00" },
    ];

    const getAllUser = () => {
        axios.get("ws/ws_user.php", {
            params:{
                task: "4b",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res =>{
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((data)=>{
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const getTimeline = () => {
        axios.get('ws/ws_prospect.php',{
            params: {
                task: 'getProspectTimelineV3',
                prospect: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token,
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                setTimelineData(data.record);
            }else{
                setTimelineData([]);
            }
        })
        .catch(error => {
            setTimelineData([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
            getTimeline();
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const onchangeActionTabs = (key) => {
        setActiveTab(key);
        setIsActiveTab(false);
    }

    const RenderActionTab = ({ tab, active }) => {
        switch (tab) {
            case "notes":
                
                if (active) {
                    return(
                        <Formik
                            validationSchema={addNoteSchema}
                            onSubmit={sendAddNote}
                            initialValues={
                                {
                                    note: "",
                                    user: session.user_id
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Card.Body>
                                        <NoteEditor value={values.note} onChangeValue={(value) => handleChange("note")(value)} />
                                        {errors.note && touched.note && <div className="op-error-message">{errors.note}</div>}
                                    </Card.Body>
                                    <Card.Footer className="d-flex flex-row-reverse">
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_NoteTab_CardFooter_Submit')}</button>
                                    </Card.Footer>
                                </Form>
                            )}
                        </Formik>
                    );
                }else{
                    return(
                        <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                            {t('ProspectDetGL_NoteTab_TakeNote')}
                        </Card.Body>
                    );
                }
            
            case "activity":

                if (active) {
                    return(
                        <Formik
                            validationSchema={addActivitySchema}
                            onSubmit={sendAddActivity}
                            initialValues={
                                {
                                    dat: "",
                                    title: "",
                                    date: moment(new Date()).format("YYYY-MM-DD"),
                                    time: "",
                                    duration: setLatestTime(timings).value,
                                    assignUser: Number(session.role_id) === 1 ? "" : session.user_id,
                                    content: "",
                                    mad: "no"
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Card.Body>
                                        <div className="d-flex mb-3">
                                            <div style={{ width: "5%" }}></div>
                                            <div style={{ width: "95%" }}>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t('ProspectDetGL_ActivityTab_Activity_Placeholder')}
                                                        isInvalid={errors.title && touched.title}
                                                        onChange={handleChange('title')}
                                                    />
                                                    {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-3">
                                            <div style={{width: "5%"}}></div>
                                            <div style={{width: "95%"}}>
                                                <Form.Group>
                                                    <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.dat === "1"} onChange={() => handleChange("dat")("1")}>
                                                            <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                                            {t('ProspectDetGL_ActivityTab_Activity_Call')}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.dat === "2"} onChange={() => handleChange("dat")("2")}>
                                                            <FontAwesomeIcon icon={faUser} className="me-1" />
                                                            {t('ProspectDetGL_ActivityTab_Activity_Meeting')}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.dat === "3"} onChange={() => handleChange("dat")("3")}>
                                                            <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                                            {t('ProspectDetGL_ActivityTab_Activity_Task')}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.dat === "4"} onChange={() => handleChange("dat")("4")}>
                                                            <FontAwesomeIcon icon={faFlag} className="me-1" />
                                                            {t('ProspectDetGL_ActivityTab_Activity_Deadline')}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.dat === "5"} onChange={() => handleChange("dat")("5")}>
                                                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                                            {t('ProspectDetGL_ActivityTab_Activity_Email')}
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                    {errors.dat && touched.dat && <div className="op-error-message">{errors.dat}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div className="d-flex mb-3">
                                            <div className="d-flex justify-content-center align-items-center" style={{width: "5%"}}>
                                                <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                                            </div>
                                            <div style={{width: "95%"}}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type="date"
                                                                value={values.date}
                                                                isInvalid={errors.date && touched.date}
                                                                onChange={handleChange('date')}
                                                            />
                                                            {errors.date && touched.date && <div className="op-error-message">{errors.date}</div>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <FormSelect
                                                                placeholder={t('ProspectDetGL_ActivityTab_Activity_Time')}
                                                                options={timings}
                                                                valueDefault={timings.filter(opt => opt.value === values.time)}
                                                                isSearchable={true}
                                                                isInvalid={errors.time && touched.time}
                                                                onChange={(e) => {setFieldValue('time', e ? e.value : "")}}
                                                            />
                                                            {errors.time && touched.time && <div className="op-error-message">{errors.time}</div>}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <FormSelect
                                                                placeholder={t('ProspectDetGL_ActivityTab_Activity_Duration')}
                                                                options={durationList}
                                                                isSearchable={true}
                                                                onChange={(e) => {setFieldValue('duration', e ? e.value : "")}}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        {Number(session.role_id) === 1 && <div className="d-flex mb-3">
                                            <div className="d-flex justify-content-center align-items-center" style={{width: "5%"}}>
                                                <FontAwesomeIcon icon={faCircleUser} size="lg" />
                                            </div>
                                            <div style={{width: "95%"}}>
                                                <Form.Group>
                                                    <FormSelect
                                                        placeholder={t('ProspectDetGL_ActivityTab_Activity_AssignUser')}
                                                        options={userList}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={(e) => {setFieldValue('assignUser', e ? e.value : "")}}
                                                        isInvalid={errors.assignUser && touched.assignUser}
                                                    />
                                                    {errors.assignUser && touched.assignUser && <div className="op-error-message">{errors.assignUser}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>}
                                        <div className="d-flex">
                                            <div className="d-flex justify-content-center" style={{width: "5%"}}>
                                                <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                                            </div>
                                            <div style={{width: "95%"}}>
                                                <NoteEditor value={values.content} onChangeValue={(value) => handleChange("content")(value)} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_ActivityTab_CardFooter_Submit')}</button>
                                        <Form.Check
                                            type="switch"
                                            label={t('ProspectDetGL_ActivityTab_CardFooter_MarkAsDone')}
                                            className="d-flex justify-content-center align-items-center me-3"
                                            onChange={(e) => {e.target.checked ? setFieldValue('mad', 'yes') : setFieldValue('mad', 'no')}}
                                        />
                                    </Card.Footer>
                                </Form>
                            )}
                        </Formik>
                    );
                }else{
                    return(
                        <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                            {t('ProspectDetGL_NoteTab_CreateActivity')}
                        </Card.Body>
                    );
                }
        
            default:
                return null;
        }
    }

    const addNoteSchema = yup.object().shape({
        note: yup.string().required(t('ProspectDetGL_requiredField'))
    });

    const sendAddNote = async(values) => {
        var params = new FormData();

        params.append('task', 'addProspectOrgNotes');
        params.append('user', values.user);
        params.append('content', values.note);
        params.append('prospectid', prospectID);
        params.append('type', 'prospect');
        params.append('utoken', session.user_ac_token);
        params.append('ctoken', session.company_token);

        try {

            let res = await axios.post('ws/ws_post_note.php',params);

            let data = res.data;

            if (data) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectDetGL_Alert_Success_Title'),
                    text: t('ProspectDetGL_Alert_Success_Text_add'),
                    timer: 1500
                })
                .then(result => {
                    setIsActiveTab(false);
                    getTimeline();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_Alert_Failed_Title'),
                    text: t('ProspectDetGL_Alert_Failed_Text')
                });
            }
            
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const addActivitySchema = yup.object().shape({
        dat: yup.string().required(t('ProspectDetGL_requiredField')),
        title: yup.string().required(t('ProspectDetGL_requiredField')),
        date: yup.string().required(t('ProspectDetGL_requiredField')),
        time: yup.string().required(t('ProspectDetGL_requiredField')),
        assignUser: yup.string().required(t('ProspectDetGL_requiredField')),
    });

    const sendAddActivity = (values) => {
        axios.get('ws/ws_deal.php',{
            params: {
                task: 2,
                create_user: session.user_id,
                dated: values.date,
                timed: values.time,
                duration: values.duration,
                title: values.title,
                content: values.content,
                mad: values.mad,
                assign_user: values.assignUser,
                dat: values.dat,
                deal: '',
                organization: "",
                people: prospectID
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectDetGL_Alert_Success_Title'),
                    text: t('ProspectDetGL_Alert_Success_Text_add'),
                    timer: 1500
                })
                .then(result => {
                    setIsActiveTab(false);
                    getTimeline();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_Alert_Failed_Title'),
                    text: t('ProspectDetGL_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const RenderAccordionButton = ({ children, eventKey }) => {
        const [isOpen, setIsOpen] = useState(false);
        const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
        return (
          <button onClick={decoratedOnClick} className="accordion-button">
            <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
            <div className="mt-1">{children}</div>
          </button>
        );
    };

    const setTimelineDateTime = (record) => {
        var icon = "";
        var content = "";

        if (record.record_type === "Note_Prospect" || record.record_type === "Note") {
            icon = record.CREATE_USER ? faUser : "";
            content = record.CREATE_USER;
        } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
            icon = faUser;
            content = record.ASSIGN_USER;
        } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity")) {
            icon = faBuilding;
            content = "";
        } else if (record.record_type === "Email" && record.record_comment !== "") {
            icon = faEnvelopeOpenText;
            content = record.record_comment;
        }else if (record.record_type === 'Deal' || record.record_type === 'Archieve') {
            icon = faDollarSign;
            content = record.deal_title;
        }

        return (
            <Stack direction="horizontal" gap={3}>
              <div>{vietnam.includes(Number(session.company_id)) && record.record_type !== 'Activity' && record.record_type !== 'Note_Prospect' && record.record_type !== 'Note' ? setDateTimeStrVN(record.record_date_time) : setDateTimeStr(record.record_date_time)}</div>
              <div 
                className="d-flex align-items-center" 
                style={{cursor: (record.record_type === 'Deal' || record.record_type === 'Archieve') ? "pointer" : ""}}
                onClick={() => {handleOpenDeal(record.record_type, record)}}
              >
                {icon ? (
                  <FontAwesomeIcon icon={icon} className={(record.record_type === 'Deal' || record.record_type === 'Archieve') ? "me-1" : "me-2"} />
                ):""}
                <div className="inline">{content}</div>
              </div>
            </Stack>
        );
    }

    const handleOpenDeal = (type, data) => {
        if (type === 'Deal' || type === 'Archieve') {
            window.open(session.origin + '/deal/' + data.deal_id,'_blank');   
        }
    }

    const RenderTimelineIcon = ({ record }) => {
        if (record.record_type === "Activity") {
            if (Number(record.record_activity_type_id) === 1) {
              return <FontAwesomeIcon icon={faPhone} />;
            } else if (Number(record.record_activity_type_id) === 2) {
              return <FontAwesomeIcon icon={faUsers} />;
            } else if (Number(record.record_activity_type_id) === 3) {
              return <FontAwesomeIcon icon={faListCheck} />;
            } else if (Number(record.record_activity_type_id) === 4) {
              return <FontAwesomeIcon icon={faFlag} />;
            } else if (Number(record.record_activity_type_id) === 5) {
              return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
            } else if (Number(record.record_activity_type_id) === 6) {
              return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
            } else if (Number(record.record_activity_type_id) === 7) {
              return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
            } else if (Number(record.record_activity_type_id) === 8) {
              return <FontAwesomeIcon icon={faWhatsapp} />;
            } else if (Number(record.record_activity_type_id) === 9) {
              return <FontAwesomeIcon icon={faLink} />;
            } else if (Number(record.record_activity_type_id) === 10) {
              return <FontAwesomeIcon icon={faLocationCheck} />;
            } else if (Number(record.record_activity_type_id) === 11) {
              return <FontAwesomeIcon icon={faCircleMinus} />;
            }
        } else if (record.record_type === "CustomActivity") {
            return <FontAwesomeIcon icon={faListCheck} />;
        } else if (record.record_type === "NoEditActivity") {
            if (Number(record.record_activity_type_id) === 1) {
              return <FontAwesomeIcon icon={faPhone} />;
            } else if (Number(record.record_activity_type_id) === 2) {
              return <FontAwesomeIcon icon={faUsers} />;
            } else if (Number(record.record_activity_type_id) === 3) {
              return <FontAwesomeIcon icon={faListCheck} />;
            } else if (Number(record.record_activity_type_id) === 4) {
              return <FontAwesomeIcon icon={faFlag} />;
            } else if (Number(record.record_activity_type_id) === 5) {
              return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
            } else if (Number(record.record_activity_type_id) === 6) {
              return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
            } else if (Number(record.record_activity_type_id) === 7) {
              return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
            } else if (Number(record.record_activity_type_id) === 8) {
              return <FontAwesomeIcon icon={faWhatsapp} />;
            } else if (Number(record.record_activity_type_id) === 9) {
              return <FontAwesomeIcon icon={faLink} />;
            } else if (Number(record.record_activity_type_id) === 10) {
              return <FontAwesomeIcon icon={faLocationCheck} />;
            } else if (Number(record.record_activity_type_id) === 11) {
              return <FontAwesomeIcon icon={faCircleMinus} />;
            }
        } else if (record.record_type === "File") {
            return <FontAwesomeIcon icon={faFile} />;
        } else if (record.record_type === "Ownership") {
            return <FontAwesomeIcon icon={faPeopleArrows} />;
        } else if (record.record_type === "Status") {
            return <FontAwesomeIcon icon={faCircleArrowDownRight} />;
        } else if (record.record_type === "Archieve") {
            return <FontAwesomeIcon icon={faBoxArchive} />;
        } else if (record.record_type === "Reopen") {
            return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
        } else if (record.record_type === "Email") {
            if (record.record_comment) {
              return <FontAwesomeIcon icon={faEnvelope} />;
            } else {
              return <FontAwesomeIcon icon={faEnvelopeOpen} />;
            }
        } else if (record.record_type === "Note_Prospect") {
            return <FontAwesomeIcon icon={faNoteSticky} />;
        } else if (record.record_type === "Note") {
              return <FontAwesomeIcon icon={faNoteSticky} />;
        } else if(record.record_type === "Deal"){
              return <FontAwesomeIcon icon={faSave}/>
        } else {
            return "";
        }
    }

    const RenderTimelineHeader = ({ record }) => {
        if (record.record_type === 'Deal') {
            return(
                <div style={{ overflow: "auto", width: "100%" }}>
                    <div>{record.deal_title} - Deal Created</div>
                </div>
            );
        }else if (record.record_type === 'Note' || record.record_type === 'Note_Prospect') {
            return(
                <div style={{ overflow: "auto", width: "100%" }}>
                    <div>Note</div>
                </div>
            );
        }else if (record.record_type === 'Activity') {
            return(
                <div style={{ overflow: "auto", width: "100%" }}>
                    <div>{record.record_activity_type} - {record.record_title}</div>
                </div>
            );
        }else if (record.record_type === 'Archieve' || record.record_type === 'Reopen' || record.record_type === 'Status') {
            return(
                <div style={{ overflow: "auto", width: "100%" }}>
                    <div>{record.record_description}</div>
                </div>
            );
        }else if (record.record_type === "Ownership") {
            return(
                <div style={{ overflow: "auto", width: "100%" }}>
                    <div>Deal Transfer to {record.ASSIGN_USER}</div>
                </div>
            );
        }
    }

    const RenderTimelineDescription = ({ record }) => {
        if (record.record_type === "Activity") {
            return(
                <div className="my-2">
                    {ReactHtmlParser(record.record_description)}
                </div>
            );
        }else if (record.record_type === "Note" || record.record_type === "Note_Prospect") {
            return(
                <div className="my-2">
                    {ReactHtmlParser(record.record_description)}
                </div>
            );
        }else if (record.record_type === "Archieve") {
            return(
                <div className="my-2">
                    {ReactHtmlParser(record.record_comment)}
                </div>
            );
        }
    }

    const handleMarkAsDone = (record) => {
        if (record.record_activity_type_id === 6 || record.record_activity_type_id === 7) {
            
            Swal.fire({
                icon: "error",
                title: "Restrict",
                text: "Sorry, this activity can't be edited."
            });

        }else{

            var mad  = record.record_activity_mad === 'yes' ? 'no':'yes';

            axios.get('ws/ws_deal.php', {
                params:{
                    task: "16",
                    activity: record.record_activity_id,
                    status: mad,
                    user: session.user_id
                }
            })
            .then(res => {
                let data = res.data;

                if (Number(data.status) === 0) {
                    setTimelineData((prev) => {
                        return prev.map((info) => {
                            if (info.record_activity_id === record.record_activity_id) {
                                return {
                                    ...info,
                                    record_activity_mad: mad,
                                }
                            }
                            return info
                        });
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: t('ProspectDetGL_Alert_Failed_Title'),
                        text: t('ProspectDetGL_Alert_Failed_Text')
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                });
            });
        }
    }

    const RenderTimelineAction = ({ record }) => {
        const customToggle = forwardRef(({onClick}, ref) => {
            const openDropdown = (e) => {
                e.preventDefault();
                onClick(e);
            };

            return (
                <Button ref={ref} variant="light" onClick={openDropdown}>
                  <FontAwesomeIcon icon={faEllipsis} size="lg" />
                </Button>
            );
        });

        return(
            <Dropdown size={100}>
                <Dropdown.Toggle as={customToggle} />
                <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
                    {record.record_type === 'Activity' && <Dropdown.Item onClick={() => {handleEditActivity(record)}}>{t('ProspectDetGL_EditActivity')}</Dropdown.Item>}
                    {record.record_type === 'Activity' && <Dropdown.Item onClick={() => {handleDeleteActivity(record)}}>{t('ProspectDetGL_DeleteActivity')}</Dropdown.Item>}
                    {(record.record_type === 'Note' || record.record_type === 'Note_Prospect') && <Dropdown.Item onClick={() => {handleEditNote(record)}}>{t('ProspectDetGL_EditNote')}</Dropdown.Item>}
                    {(record.record_type === 'Note' || record.record_type === 'Note_Prospect') && <Dropdown.Item onClick={() => {handleDeleteNote(record)}}>{t('ProspectDetGL_DeleteNote')}</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    const handleEditActivity = (data) => {
        
        axios.get('ws/ws_deal.php', {
            params: {
                task: "18",
                activity: data.record_activity_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var timeOri = data.record.activity_timed.split(":");
                var timeNew = `${timeOri[0]}:${timeOri[1]}`;

                setEditActivityVal({
                    activity_dealid: data.record.deal_id,
                    activity_id: data.record.activity_id,
                    activity_title: data.record.activity_title,
                    activity_type: data.record.dat_id,
                    activity_date: data.record.activity_dated,
                    activity_time: timeNew,
                    activity_dur: data.record.activity_duration,
                    activity_user: data.record.assign_user_id,
                    activity_note: data.record.activity_content,
                    activity_mad: data.record.activity_mark_as_done
                });
                setIsEditActivity(true);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_Alert_Failed_Title'),
                    text: t('ProspectDetGL_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });

    }

    const editActivitySchema = yup.object().shape({
        activity_title: yup.string().required(t('ProspectDetGL_requiredField')),
        activity_type: yup.string().required(t('ProspectDetGL_requiredField')),
        activity_date: yup.string().required(t('ProspectDetGL_requiredField')),
        activity_time: yup.string().required(t('ProspectDetGL_requiredField'))
    });

    const sendEditActivity = (values) => {
        axios.get('ws/ws_deal.php', {
            params:{
                task: 11,
                user: session.user_id,
                dt: values.activity_date
            }
        })
        .then(res => {
            // let data = res.data;

            // if (data.status == 0) {
                
                axios.get('ws/ws_deal.php', {
                    params: {
                        task: 12,
                        create_user: session.user_id,
                        dated: values.activity_date,
                        timed: values.activity_time,
                        duration: values.activity_dur,
                        title: values.activity_title,
                        content: values.activity_note,
                        mad: values.activity_mad,
                        dat: values.activity_type,
                        ca_id: 0,
                        assign_user: values.activity_user,
                        aid: values.activity_id
                    }
                })
                .then(res => {
                    let info = res.data;

                    if (Number(info.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('ProspectDetGL_Alert_Success_Title'),
                            text: t('ProspectDetGL_Alert_Success_Text_Edit'),
                            timer: 1500
                        })
                        .then(result => {
                            setIsEditActivity(false);
                            getTimeline();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('ProspectDetGL_Alert_Failed_Title'),
                            text: t('ProspectDetGL_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });

            // }else{
            //     Swal.fire({
            //         icon: "error",
            //         title: "Failed",
            //         text: "Please try again or contact your IT Support"
            //     });
            // }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDeleteActivity = (data) => {
        Swal.fire({
            icon: "warning",
            title: t('ProspectDetGL_Alert_Warning_Title'),
            text: t('ProspectDetGL_Alert_Warning_Text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('ProspectDetGL_Alert_Warning_Confirm'),
            cancelButtonText: t('ProspectDetGL_Alert_Warning_Cancel')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_deal.php', {
                    params:{
                        task: 23,
                        activity: data.record_activity_id
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('ProspectDetGL_Alert_Success_Title'),
                            text: t('ProspectDetGL_Alert_Success_Text_Deleted'),
                            timer: 1500
                        })
                        .then(result => {
                            getTimeline();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('ProspectDetGL_Alert_Failed_Title'),
                            text: t('ProspectDetGL_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleEditNote = (rec) => {
        var type;
        var dealID;

        if (rec.record_type === 'Note') {
            type = "deal";
            dealID = rec.deal_id;
        }else{
            type = "prospect";
            dealID = "";
        }

        axios.get('ws/ws_deal.php', {
            params:{
                task: 19,
                note: rec.record_activity_id,
                type: type,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setEditNoteVal({
                    activity_dealid: dealID,
                    activity_type: type,
                    activity_id: rec.record_activity_id,
                    activity_note: data.record.note_content,
                });

                setIsEditNote(true);
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed to open edit note",
                    text: "Please try again or contact your IT Support"
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
        });
    }

    const editNoteSchema = yup.object().shape({
        activity_note: yup.string().required(t('ProspectDetGL_requiredField'))
    });

    const sendEditNote = async(values) => {
        var params = new FormData();

        if (values.activity_type === 'deal') {
            params.append("task", "editNotes");
            params.append("note", values.activity_id);
            params.append("deal", values.activity_dealid);
            params.append("content", values.activity_note);
            params.append("utoken", session.user_ac_token);
            params.append("ctoken", session.company_token);
        }else{
            params.append("task", "editProspectOrgNotes");
            params.append("source", values.activity_type);
            params.append("user", session.user_id);
            params.append("content", values.activity_note);
            params.append("note", values.activity_id);
            params.append("utoken", session.user_ac_token);
            params.append("ctoken", session.company_token);
        }

        try {

            const res = await axios.post('ws/ws_post_note.php', params);

            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectDetGL_Alert_Success_Title'),
                    text: t('ProspectDetGL_Alert_Success_Text_Edit'),
                    timer: 1500
                })
                .then(result => {
                    setIsEditNote(false);
                    getTimeline();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_Alert_Failed_Title'),
                    text: t('ProspectDetGL_Alert_Failed_Text')
                });
            }
            
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }

    }

    const handleDeleteNote = (data) => {
        var paramsData;

        if (data.record_type === 'Note') {
            paramsData = {
                task: 24,
                note: data.record_activity_id,
                prospectNote: 0,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            };
        }else{
            paramsData = {
                task: 24,
                note: data.record_activity_id,
                type: "prospect",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            };
        }

        Swal.fire({
            icon: "warning",
            title: t('ProspectDetGL_Alert_Warning_Title'),
            text: t('ProspectDetGL_Alert_Warning_Text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('ProspectDetGL_Alert_Warning_Confirm'),
            cancelButtonText: t('ProspectDetGL_Alert_Warning_Cancel')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_deal.php', {
                    params: paramsData
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('ProspectDetGL_Alert_Success_Title'),
                            text: t('ProspectDetGL_Alert_Success_Text_Deleted'),
                            timer: 1500
                        })
                        .then(result => {
                            getTimeline();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('ProspectDetGL_Alert_Failed_Title'),
                            text: t('ProspectDetGL_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    return(
        <Container fluid className="p-4">
            <Card>
                <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                        <Nav.Item>
                            <Nav.Link eventKey="notes">
                            <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg"/>
                            {t('ProspectDetGL_NoteTab')}
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="activity">
                            <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                            {t('ProspectDetGL_ActivityTab')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                </Card.Header>
                <RenderActionTab tab={activeTab} active={isActiveTab}/>
            </Card>

            <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                        <RenderAccordionButton eventKey="0">{t('ProspectDetGL_Timeline')}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0">
                        <div className="accordion-body">
                            <VerticalTimeline layout="1-column-left" style={{ width: "100%" }}>
                                {timelineData.map((record, index) => (
                                    <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                        <Row>
                                            <Col sm={10}>
                                                <div className="op-text-bigger fw-bold">
                                                    <RenderTimelineHeader record={record}/>
                                                </div>
                                                <div className="op-text-medium">
                                                    <RenderTimelineDescription record={record} />
                                                </div>
                                            </Col>
                                            <Col sm={1}>
                                                {
                                                    record.record_type === "Activity" && 

                                                    <div className="w-100 d-flex justify-content-center mt-2" style={{ cursor: "pointer" }} onClick={() => {handleMarkAsDone(record)}}>
                                                        { record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" /> }
                                                    </div>
                                                }
                                            </Col>
                                            <Col sm={1}>
                                                {
                                                    (record.record_type === 'Activity' || record.record_type === 'Note' || record.record_type === 'Note_Prospect') && !record.record_description.includes("Parent Deal") &&
                                                    <RenderTimelineAction record={record}/>
                                                }
                                            </Col>
                                        </Row>
                                    </VerticalTimelineElement>
                                ))}
                            </VerticalTimeline>
                        </div>
                    </Accordion.Collapse>
                </div>
            </Accordion>

            <Modal show={isEditNote} onHide={setIsEditNote} size="lg">
                <Formik
                    validationSchema={editNoteSchema}
                    onSubmit={sendEditNote}
                    initialValues={editNoteVal}
                >
                    {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title as={"h6"}>{t('ProspectDetGL_EditNote')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="mb-3">
                                    <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} height={300}/>
                                    {errors.activity_note && touched.activity_note && <div className="op-error-message">{errors.activity_note}</div>}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

            <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
                <Formik
                    validationSchema={editActivitySchema}
                    onSubmit={sendEditActivity}
                    initialValues={editActivityVal}
                >
                    {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title as={"h6"}>{t('ProspectDetGL_EditActivity')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex mb-3">
                                    <div style={{width: "5%"}}></div>
                                    <div style={{width: "95%"}}>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                placeholder="Activity Title"
                                                value={values.activity_title}
                                                isInvalid={errors.activity_title && touched.activity_title}
                                                onChange={handleChange('activity_title')}
                                            />
                                            {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div style={{width: "5%"}}></div>
                                    <div style={{width: "95%"}}>
                                        <Form.Group>
                                            <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                                <ToggleButton variant="" type="radio" name="actvt_type" id="actvt_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                                                    <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                                    Call
                                                </ToggleButton>
                                                <ToggleButton variant="" type="radio" name="actvt_type" id="actvt_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                                                    <FontAwesomeIcon icon={faUser} className="me-1" />
                                                    Meeting
                                                </ToggleButton>
                                                <ToggleButton variant="" type="radio" name="actvt_type" id="actvt_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                                                    <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                                    Task
                                                </ToggleButton>
                                                <ToggleButton variant="" type="radio" name="actvt_type" id="actvt_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                                                    <FontAwesomeIcon icon={faFlag} className="me-1" />
                                                    Deadline
                                                </ToggleButton>
                                                <ToggleButton variant="" type="radio" name="actvt_type" id="actvt_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                                                    <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                                    Email
                                                </ToggleButton>
                                            </ButtonGroup>
                                            {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="d-flex mb-3">
                                    <div className="d-flex justify-content-center align-items-center" style={{width: "5%"}}>
                                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                                    </div>
                                    <div style={{width: "95%"}}>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="date"
                                                        value={values.activity_date}
                                                        isInvalid={errors.activity_date && touched.activity_date}
                                                        onChange={handleChange('activity_date')}
                                                    />
                                                    {errors.activity_date && touched.activity_date && <div className="op-error-message">{errors.activity_date}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <FormSelect
                                                        placeholder="time"
                                                        options={timings}
                                                        valueDefault={timings.filter(opt => opt.value === values.activity_time)}
                                                        isSearchable={true}
                                                        isInvalid={errors.activity_time && touched.activity_time}
                                                        onChange={(e) => {setFieldValue('activity_time', e ? e.value : "")}}
                                                    />
                                                    {errors.activity_time && touched.activity_time && <div className="op-error-message">{errors.activity_time}</div>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <FormSelect
                                                        placeholder="duration"
                                                        options={durationList}
                                                        valueDefault={durationList.filter(opt => opt.value === values.activity_dur)}
                                                        isSearchable={true}
                                                        onChange={(e) => {setFieldValue('activity_dur', e ? e.value : "")}}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                {Number(session.role_id) === 1 && <div className="d-flex mb-3">
                                    <div className="d-flex justify-content-center align-items-center" style={{width: "5%"}}>
                                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                                    </div>
                                    <div style={{width: "95%"}}>
                                        <Form.Group>
                                            <FormSelect
                                                placeholder="Assign User"
                                                options={userList}
                                                valueDefault={userList.filter(opt => opt.value === values.activity_user)}
                                                isSearchable={true}
                                                isInvalid={errors.activity_user && touched.activity_user}
                                                onChange={(e) => {setFieldValue('activity_user', e ? e.value : "")}}
                                            />
                                            {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                                        </Form.Group>
                                    </div>
                                </div>}
                                <div className="d-flex mb-3">
                                    <div className="d-flex justify-content-center" style={{width: "5%"}}>
                                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                                    </div>
                                    <div style={{width: "95%"}}>
                                        <Form.Group>
                                            <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} height={300}/>
                                        </Form.Group>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Form.Check
                                    type="switch"
                                    label="Mark as done"
                                    className="d-flex justify-content-center align-items-center me-3"
                                    checked={values.activity_mad === 'yes' ? true : false}
                                    onChange={(e) => {e.target.checked ? setFieldValue('activity_mad', 'yes') : setFieldValue('activity_mad', 'no')}}
                                />
                                <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Container>
    );
}

export default ActivityTimeline;