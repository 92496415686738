import { useState, useRef, useEffect, useCallback, forwardRef, useLayoutEffect, useMemo } from "react";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button, OverlayTrigger, Tooltip, ListGroup, ToggleButtonGroup, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faPlus, faStar, faChevronDown, faTriangleExclamation, faCircleXmark, faBrowser, faCircle, faRobot, faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons";
import {
  faBriefcase,
  faChartBar,
  faChartLine,
  faChartLineDown,
  faChartLineUp,
  faEnvelopeOpenText,
  faFlag,
  faHandshake,
  faPenToSquare,
  faPhoneVolume,
  faRightLeft,
  faStopwatch,
  faUser,
  faUsers,
  faCalendarClock,
  faCircleUser,
  faNoteSticky,
  faPaperclipVertical,
  faEnvelope,
  faCalendarDay,
  faBarsStaggered,
  faBuilding,
  faShieldExclamation,
  faHouseCircleCheck,
  faBallot,
  faUserTie,
  faPhone,
  faListCheck,
  faEnvelopeCircleCheck,
  faPhoneArrowDownLeft,
  faPhoneArrowUpRight,
  faLink,
  faLocationCheck,
  faCircleMinus,
  faPeopleArrows,
  faCircleArrowRight,
  faBoxArchive,
  faLockKeyholeOpen,
  faEnvelopeOpen,
  faFile,
  faFileImage,
  faFloppyDisk,
  faHouseBuilding,
  faHouseChimneyWindow,
  faHouse,
  faLocationDot,
  faBox,
  faMicrophone,
} from "@fortawesome/pro-duotone-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { Editor } from "@tinymce/tinymce-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FilePond, registerPlugin } from "react-filepond";
import { prefixList, setCurrency, setDateStr, setDateTimeStr, setDateTimeStrVN } from "../lib/js/Function";
import axios from "../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import "../lib/scss/op-deal-details.scss";
import "../lib/scss/op-step.scss";
import "../lib/scss/op-rating.scss";
import "react-vertical-timeline-component/style.min.css";
import "../lib/css/op-label.css";
import ReactHtmlParser from "html-react-parser";
import { FormSelectCreatable, FormCustomField, FormSelect, FormDate } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { debounce } from "lodash";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import ProductComponent from "./deal_action/ProductComponent";
import { Fab, Action } from "react-tiny-fab";
import AudioNotes from "./ai_action/AudioNotes";
import EditPersonGl from "./gamuda/EditPersonGl";
import EditPersonGlVN from "./gamuda/EditPersonGLVN";
import NoteEditor from "../includes/NoteEditor";
import "../lib/css/ClickAbleGrid.css";
import { useTranslation } from "react-i18next";
import MeetupLog from "./timeline_component/meetupLog";
import EditReserve from "./gamuda/EditReserve";

const newDate = new Date();
const latestDate = moment(newDate).format("YYYY-MM-DD");
var vietnam = ['402', '412'];
var hotLeadsPipeline = [623, 51, 107, 119, 136, 717, 155, 298, 513];

const durationList = [
  { label: "5 Mins", value: "5min" },
  { label: "10 Mins", value: "10min" },
  { label: "15 Mins", value: "15min" },
  { label: "20 Mins", value: "20min" },
  { label: "30 Mins", value: "30min" },
  { label: "35 Mins", value: "35min" },
  { label: "40 Mins", value: "40min" },
  { label: "45 Mins", value: "45min" },
  { label: "50 Mins", value: "50min" },
  { label: "55 Mins", value: "55min" },
  { label: "1 Hour", value: "60min" },
  { label: "1 Hour 30 Mins", value: "90min" },
  { label: "2 Hours", value: "120min" },
  { label: "2 Hours 30 Mins", value: "150min" },
  { label: "3 Hours", value: "180min" },
  { label: "3 Hours 30 Mins", value: "210min" },
  { label: "4 Hours", value: "240min" },
];

const timings = [
  { label: "8:00", value: "8:00" },
  { label: "8:15", value: "8:15" },
  { label: "8:30", value: "8:30" },
  { label: "8:45", value: "8:45" },
  { label: "9:00", value: "9:00" },
  { label: "9:15", value: "9:15" },
  { label: "9:30", value: "9:30" },
  { label: "9:45", value: "9:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];

const DealDetailsGL = () => {
  const { deal_id } = useParams();
  const { Formik } = formik;
  const editorRef = useRef(null);
  const editorRefActivity = useRef(null);
  const editorRefMessages = useRef(null);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("notes");
  const [isActiveTab, setIsActiveTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isContributor, setIsContributor] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isWon, setIsWon] = useState(false);
  const [isLost, setIsLost] = useState(false);
  const [isChangeProspect, setIsChangeProspect] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const [isGenerateCall, setIsGenerateCall] = useState(false);

  const { session, logoutUser } = useAuth();
  const [deal, setDeal] = useState(null);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState([]);
  const [booking, setBooking] = useState([]);
  const [lostReason, setLostReason] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const [timeline, setTimeline] = useState([]);
  const [callAnalytics, setCallAnalytics] = useState([]);
  const [rating, setRating] = useState(null);
  const [hoverRating, setHoverRating] = useState(null);
  const [pipeBtn, setPipeBtn] = useState({ won: "", lost: "" });
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [dealSource, setDealSource] = useState([]);
  const [isEditDeal, setIsEditDeal] = useState(false);
  const [isAddContributor, setIsAddContributor] = useState(false);
  const [isAddForm, setisAddForm] = useState(false);
  const [form, setForm] = useState([]);
  const [userNotes, setUserNotes] = useState([]);
  const [userActivity, setUserActivity] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [whatsappTemplate, setWhatsappTemplate] = useState([]);
  const [isEditActivity, setIsEditActivity] = useState(false);
  const [initEditActivity, setInitEditActivity] = useState(null);

  const [isEditNote, setisEditNote] = useState(false);
  const [initEditNotes, setInitEditNotes] = useState(null);
  const [isDeleteDeal, setIsDeleteDeal] = useState();
  const [width, setWidth] = useState("100%");
  const [leadScoreList, setLeadScoreList] = useState([]);
  const [newLeadScoreList, setNewLeadScoreList] = useState([]);
  const [newLdScoreTotal, setNewLdScoreTotal] = useState();
  const [isLeadScore, setIsLeadScore] = useState(false);
  const [isEditDealInfo, setIsEditDealInfo] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [isOpenDeal, setIsOpenDeal] = useState(false);
  const [isWonDeal, setIsWonDeal] = useState(false);
  const [isLostDeal, setIsLostDeal] = useState(false);
  const [isArchievedDeal, setIsArchievedDeal] = useState(false);
  const [dealProduct, setDealProduct] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isAddProduct, setisAddProduct] = useState(false);
  const [isCallLead, setIsCallLead] = useState(false);

  const [meetupLink, setMeetupLink] = useState({});
  const [activityData, setActivityData] = useState({});
  const [isCancelMeetup, setIsCancelMeetup] = useState(false);
  const [cancelMeetupReasonList, setCancelMeetupReasonList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [meetingDurationList, setMeetingDurationList] = useState([]);
  const [meetingTimingsList, setMeetingTimingsList] = useState([]);

  const [bookTimeout, setBookTimeout] = useState("");

  const [isTransferOwner, setIsTransferOwner] = useState(false);

  const [isReserve, setIsReserve] = useState(false);

  const transferDealSchema = yup.object().shape({
    user: yup.string().required("User is required"),
  });

  const [introducerList,setIntroducerList] = useState([]);

  const [isVoiceNote, setIsVoiceNote] = useState(false);

  const changeProspectSchema = yup.object().shape({
    prospect: yup.string().required("Prospect is required"),
  });

  const [deepLink, setDeepLink] = useState("");
  const deepLinkRef = useRef(null);

  const editProspectSchema = yup.object().shape({
    prospect_name: yup.string().required("Prospect name is required"),
    owner_id: yup.string().required("Prospect owner is required"),
    contact1Prefix: yup.string().required("Prefix Contact Number 1 is required"),
    contact1: yup
      .string()
      .required("Contact Number 1 is required")
      .test("checkDuplicateContact1", "Contact number already exist", async (value) => {
        const result = await debounceDuplicateContact(value, 1);
        return result;
      }),
    contact2: yup.string().test("checkDuplicateContact2", "Contact number already exist", async (value) => {
      return await debounceDuplicateContact(value, 2);
    }),
    contact3: yup.string().test("checkDuplicateContact3", "Contact number already exist", async (value) => {
      return await debounceDuplicateContact(value, 3);
    }),
    contact4: yup.string().test("checkDuplicateContact4", "Contact number already exist", async (value) => {
      return await debounceDuplicateContact(value, 4);
    }),
  });

  const editDealSchema = yup.object().shape({
    ds_id: yup.string().required("Source is required"),
  });

  const addContributorSchema = yup.object().shape({
    user_id: yup.string().required("User is required"),
  });

  const addFormSchema = yup.object().shape({
    form_id: yup.string().required("Form is required"),
  });

  const notesSchema = yup.object().shape({
    notes: yup.string().required("Notes is required"),
  });

  const addActivitySchema = yup.object().shape({
    activity_title: yup.string().required("Activity Title is required"),
    activity_user: yup.string().required("User is required"),
  });

  const editActivitySchema = yup.object().shape({
    activity_title: yup.string().required("Activity Title is required"),
    activity_user: yup.string().required("User is required"),
  });

  const fileUploadSchema = yup.object().shape({
    file: yup.array().min(1, "File is required"),
    title: yup.string().required("File title is required"),
  });

  const addMessageschema = yup.object().shape({
    email_content: yup.string().required("Message content is required"),
    email_template: yup.string().when("message_type", {
      is: "email",
      then: () => yup.string().required("Template is required"),
    }),
    whatsapp_template: yup.string().when("message_type", {
      is: "whatsapp",
      then: () => yup.string().required("Template is required"),
    }),
    email_subject: yup.string().when(["message_type", "email_template"], {
      is: (messageType, emailTemplate) => messageType === "email" && emailTemplate,
      then: () => yup.string().required("Email subject is required"),
    }),
  });

  const editNotesSchema = yup.object().shape({
    notes: yup.string().required("Notes is required"),
  });

  const editDealInfoSchema = yup.object().shape({
    deal_title: yup.string().required("Deal title is required"),
    deal_added: yup.string().required("Deal added is required"),
    deal_price: yup.number().typeError('Must be number type').required('This field is required'),
    deal_close: yup.string().when("deal_archieve", {
      is: (value) => value === "won" || value === "lost",
      then: yup.string().required("Deal close is required"),
    }),
  });

  // GET FUNCTION ----------------------------------------------------------------

  const getDeals = async () => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "getDealDetails",
          type: "view",
          deal: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (Number(data.status) === 0) {
        if (!initialized.current) {
          getbookTimeout(data.record);
        }
        const template = ["5000","5001","5002","5003","5004","2850","5005","5006","5007","5008","5009","5010","5011","5012","5013","5014","5015","5016","5017"];

        const sortByObject = template
        .reduce((obj, item, index) => {
            return {
            ...obj,
            [item]: index,
            };
        }, {});

        getNewLeadScore(data.record.prospect_id, false);

        data.record.custom_field_person_data.sort((a,b) => sortByObject[a.cf_id] - sortByObject[b.cf_id]);
        var compulsoryCFIDs = ["5007", "5008", "2681", "5009", "5001", "5014", "5013", "5000", "5010", "5015", "5002", "5005", "5011"];

        data.record.custom_field_person_data.map((rec, index) => {
          if (rec.cf_id === '5002') {
            rec.cf_label = rec.cf_label + " (Newest)";
          }

          if (rec.cf_id === '5003') {
            rec.cf_label = rec.cf_label + " (Older)";
          }

          if (Number(rec.cf_id) === 5011) {
            rec.fd_value = rec.fd_value === 'True' ? 'Yes' : rec.fd_value === 'False' ? 'No' : rec.fd_value;
          }
        });

        data.record.custom_field_person_data.map((rec, index) => {
          if (compulsoryCFIDs.includes(rec.cf_id)) {
            rec.cf_label = rec.cf_label + " *";
          }
        });

        setDeal(data.record);
        setRating(Number(data.record.deal_priority));

        const contributor = Number(data.record.contributor_count) > 0 && data.record.contributor.find((record) => record.user_id === data.record.owner_id);
        setIsContributor(Number(data.record.contributor_count) > 0 && !contributor);
        setIsOwner(Number(data.record.owner_id) === Number(session.user_id));
        getAnalytics(data.record);
        getIntroducer(data.record.prospect_id);
      } else {
        logoutUser();
      }
    } catch (error) {
      console.error(error);
      logoutUser();
    }
  };

  const contactNumFormat = (prefix, number) => {
    if (prefix == 60 && number) {
        return "+" + prefix + number.substring(1);
    }else if (prefix != 60 && prefix && number) {
        return "+" + prefix + number;
    }else if (prefix == '' && number) {
        return number;
    }else if (number) {
        return number;
    }else{
        return "-";
    }
}

  const getIntroducer = async (id) => {
    try {
      const res = await axios.get('ws/ws_prospect_introducer.php',{
          params:{
            task: "GetProspectIntroducer",
            prospect_id: id,
            utoken: session.user_ac_token,
            ctoken: session.company_token
          }
        })
      
      let data = res.data;

      if (data.status_introducer == 0) {
        setIntroducerList(data.record_introducer);
      }else{
        setIntroducerList([]);
      }
    } catch (error) {
      setIntroducerList([]);
    }
  }

  const getUser = async () => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.user_name + " (" + record.role_title + ")";
            record.value = record.user_id;
          });

          var userNotes = data.record.map((record) => ({
            label: record.label,
            value: record.value,
          }));

          userNotes.unshift({
            label: "None",
            value: "",
          });

          var userActivityRecord = data.record.map((record) => ({
            label: record.label,
            value: record.value,
          }));

          userActivityRecord.unshift({
            label: "Select user",
            value: "",
          });

          setUserActivity(userActivityRecord);
          setUserNotes(userNotes);
          setUser(data.record);
        }
      });
  };

  const getCrmStatus = async () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: session.company_id,
          dealid: deal_id,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.record) {
          setPipeBtn((prevState) => ({
            ...prevState,
            won: data.pipe_naming_won,
            lost: data.pipe_naming_lost,
          }));
          setStatus(data.record);
        }
      });
  };

  const getLostReason = async () => {
    axios
      .get("ws/ws_deal_close.php", {
        params: {
          task: "getLostReason",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status == 0) {
          data.record.forEach((record) => {
            record.label = record.reason_title;
            record.value = record.lost_reason_id;
          });
          setLostReason(data.record);
        }
      });
  };

  const getBooking = async () => {
    axios
      .get("ws/ws_rea.php", {
        params: {
          task: "BookingDeal",
          deal_id: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setBooking(data.record);
        }
      });
  };

  const getAnalytics = async (deals) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "prospectHistory",
          prospect: deals.prospect_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        setAnalytics(response.data);
      });
  };

  const getTimeline = async () => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 13,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
        },
      })
      .then(async (response) => {
        var data = response.data;
        if (Number(data.status) === 0) {
          data.record.forEach(record => {
            record.meetup_record_description = "";
          })

          const processedRecords = await Promise.all(
            data.record.map(async (activity, index) => {
             
              if (activity.record_type === "Activity" && Number(activity.record_activity_type_id) === 2) {
                const [meetupCancel, meetupReschedule] = await Promise.all([getMeetupCancel(activity.record_activity_id), getMeetupReschedule(activity.record_activity_id)]);
                
                if (meetupCancel.status === 0 && Number(meetupCancel.record.cancel) === 1) {
                  let desc = `Cancelled by ${meetupCancel.record.cancel_user_name} on ${moment(new Date(meetupCancel.record.cancel_date_time)).format("LLL")}`;
                  if (meetupCancel.record.cancel_reason) {
                    desc += `\nReason: ${meetupCancel.record.cancel_reason}`;
                  }
                  if (meetupCancel.record.cancel_remark) {
                    desc += `\nRemark: ${meetupCancel.record.cancel_remark}`;
                  }
                  activity.meetup_record_description = desc;
                  activity.mad_show = false;
                } else if (meetupReschedule.status === 0) {
                  if (meetupReschedule.record.length > 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    const previousMeetupRecord = meetupReschedule.record[1];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name} \nPrevious schedule on ${moment(new Date(previousMeetupRecord.chosen_date + " " + previousMeetupRecord.chosen_time)).format("LLL")}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    }
                  } else if (meetupReschedule.record.length === 1) {
                    const meetupRecord = meetupReschedule.record[0];
                    if (meetupRecord.updated_by === "user" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.user_name}`;
                    } else if (meetupRecord.updated_by === "prospect" && Number(meetupRecord.inactive) === 0 && meetupRecord.mode !== "Created") {
                      
                      activity.meetup_record_description = `Reschedule by ${meetupRecord.prospect_name}`;
                    } else {
                     
                      activity.meetup_record_description = "";
                    }
                  } else {
                   
                    activity.meetup_record_description = "";
                  }
                  if (index === data.record.length - 1) {
                    activity.mad_show = true;
                  } else {
                    activity.mad_show = false;
                  }
                } else {
                  activity.mad_show = true;
                  activity.meetup_record_description = "";
                }

                activity.meetup_cancel = meetupCancel;
                activity.meetup_reschedule = meetupReschedule;
              } else {
                activity.meetup_record_description = "";
              }

              activity.mad_show = false;
              return activity;
            })
          );

          const meetingRecord = processedRecords.filter((record) => Number(record.record_activity_type_id) === 2);
          if (meetingRecord && meetingRecord.length > 0) {
            const meetingRecordLatest = getLatestRecord(meetingRecord);
            processedRecords.forEach((record) => {
              if (record.record_activity_id === meetingRecordLatest.record_activity_id) {
                record.mad_show = true;
              }
            });
          }

          setTimeline(data.record);
        } else {
          setTimeline([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching timeline:", error);
        setTimeline([]);
      });
  };

  const getSentimentAnalysis = (activity) => {
    return new Promise((resolve, reject) => {
      axios
        .get("ws/ws_sentiment_analysis.php", {
          params: {
            task: "get_call_insight_timeline",
            company_id: session.company_id,
            call_data_id: activity.record_activity_id,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const getExistingProspect = async (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectsForListbox",
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = Object.values(data.record)
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
            }));

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getExistingDesignation = async (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: session.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getDuplicateContact = async (contact, index) => {
    let isDuplicateExists;

    const contactFields = [`contact1`, `contact2`, `contact3`, `contact4`];
    const fieldToCheck = contactFields[index - 1];

    if (index >= 1 && index <= 4 && deal[fieldToCheck] === contact) {
      return true;
    }

    try {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          company: session.company_id,
          people: deal.prospect_id || "",
          type: "",
          mobile: contact,
        },
      });

      const data = response.data;
      isDuplicateExists = Number(data.status) !== 1;
    } catch (error) {
      isDuplicateExists = false;
    }

    return isDuplicateExists;
  };

  const getDealSource = async () => {
    axios
      .get("ws/ws_source.php", {
        params: {
          task: 2,
          disp: "show",
          company: session.company_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOptions = data.record.map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

          setDealSource(recordOptions);
        }
      });
  };

  const getForm = async () => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "getAllCustomForms",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOptions = data.custom_form.map((record) => ({
            label: record.form_title,
            value: record.custom_form_id,
          }));

          setForm(recordOptions);
        }
      });
  };

  const debounceDuplicateContact = debounce(getDuplicateContact, 1000);

  const getCountry = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.country_title,
          value: record.country_id,
        }));
        setCountry(options);
      });
  };

  const getState = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.state_title,
          value: record.state_id,
          country: record.country_id,
        }));
        setState(options);
      });
  };

  const getCity = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.city_title,
          value: record.city_id,
          state: record.state_id,
        }));
        setCity(options);
      });
  };

  const getEmailTemplate = async () => {
    axios
      .get("ws/ws_email.php", {
        params: {
          task: "getEmailTemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const recordOption = data.record.map((record) => ({
            label: record.email_template_title,
            value: record.email_template_id,
          }));

          setEmailTemplate(recordOption);
        } else {
          setEmailTemplate([]);
        }
      });
  };

  const getWhatsappTemplate = async () => {
    axios
      .get("ws/ws_custom_interaction.php", {
        params: {
          task: "getWhatsappInteractions",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.interaction_title = record.interaction_title.replace("\n", "%0a");
          });

          const recordOption = data.record.map((record) => ({
            label: record.interaction_name,
            value: record.custom_interaction_id,
            template: record.interaction_title,
          }));

          setWhatsappTemplate(recordOption);
        } else {
          setWhatsappTemplate([]);
        }
      });
  };

  const getProduct = async () => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          task: "getDealProducts",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const updatedProducts = data.product.map((record) => ({
          deal_product_id: record.deal_product_id,
          deal_id: record.deal_id,
          product_id: record.product_id,
          total_qty: record.total_qty,
          product_title: record.product_title,
          discount: record.discount,
          remark: record.remark,
          final_price: record.final_price,
          serial_num: record.serial_num,
          product_price: (Number(record.final_price) + Number(record.discount)) / Number(record.total_qty),
        }));

        setCurrentUser(response);
        setProductCount(data.totalProduct);
        setDealProduct(updatedProducts);
      } else {
        setCurrentUser(response);
        setDealProduct([]);
        setProductCount(0);
      }
    } catch (error) {
      console.error(error);
      setDealProduct([]);
      setProductCount(0);
    }
  };

  const getAllProduct = async () => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          bu_id: 0,
          ctoken: session.company_token,
          task: "getAllProducts",
          utoken: session.user_ac_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setProductList(data.prod_cat);
      }
    } catch {
      setProductCount(0);
    }
  };

  const getNewLeadScore = (prosID, isOpen) => {
    
    axios.get('ws/ws_lead_score.php', {
      params: {
        task: 'getLeadScore_GL',
        deal_id: deal_id,
        prospect_id: prosID,
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
    .then(res => {
      let data = res.data;

      if (data.scoreMatrixDetails && data.scoreMatrixDetails.length > 0) {
        var listData = [];

        // data.scoreMatrixDetails.forEach(rec => {
        //   if (Number(rec.score) <= Number(rec.maxScore)) {
        //     listData.push(rec);
        //   }
        // });

        var rec = data.scoreMatrixDetails;

        for (let index = 0; index < rec.length; index++) {
          if (Number(rec[index].score) <= Number(rec[index].maxScore)) {
            listData.push(rec[index]);
          }
        }

        setNewLdScoreTotal(data.totalScore);
        setNewLeadScoreList(listData);
        setIsLeadScore(isOpen);
      }else{
        setNewLdScoreTotal(0);
        setNewLeadScoreList([]);
        setIsLeadScore(isOpen);
      }
    })
    .catch(error => {
      setNewLdScoreTotal(0);
      setNewLeadScoreList([]);
      setIsLeadScore(isOpen);
    });
  }

  // ONCHANGE FUNCTION -----------------------------------------------------------

  const onchangeActionTabs = (key) => {
    setActiveTab(key);
    setIsActiveTab(false);
  };

  const onchangePriority = (priority) => {
    if ((deal.deal_archieve === "" && Number(deal.owner_id) === Number(session.user_id)) || Number(session.role_id) === 1) {
      setRating(priority);
      axios.get("ws/ws_deal.php", {
        params: {
          task: 34,
          priority: priority,
          deal: deal_id,
        },
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You don't have permission to do this action",
      });
    }
  };

  const [stageLock, setStageLock] = useState(false);
  const [dealWonList, setDealWonList] = useState([]);

  const onchangeStage = (status) => {
    if (setCheckOwner()) {
      axios
        .get("ws/ws_stage.php", {
          params: {
            task: "checkStageEnabled",
            csid: status.cs_id,
            dealid: deal_id,
            prospectid: deal.hq_prospect_id && deal.hq_prospect_id != 0 ? deal.hq_prospect_id : deal.prospect_id,
          },
        })
        .then((response) => {
          var data = response.data;
          if (data.status === 0) {
            axios
              .get("ws/ws_deal.php", {
                params: {
                  task: 7,
                  user: session.user_id,
                  deal: deal_id,
                  status: status.cs_id,
                  utoken: session.user_ac_token,
                  ctoken: session.company_token,
                },
              })
              .then((response) => {
                getDeals(session);
                getTimeline();
              });
          } else {
            setDealWonList(data.record);
            setStageLock(true);
            // var element = data.record.map((record, index) => (
            //   <h6 key={index} class="d-flex">
            //     <FontAwesomeIcon icon={faShieldExclamation} class="me-2" size="lg" /> {record.title}
            //   </h6>
            // ));

            // Swal.fire({
            //   icon: "warning",
            //   title: "Stage locked. Please fulfill these conditions to proceed",
            //   content: {
            //     element: "div",
            //     attributes: {
            //       innerHTML: element,
            //     },
            //   },
            // });
          }
        });
    }
  };

  // SEND FUNCTION ---------------------------------------------------------------

  const sendTransferDeal = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "transferOwnership",
          deal: deal_id,
          user: values.user,
          transferoption: values.transferoption,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        if (Number(session.user_id) !== Number(user)) {
          sendTransferDealInfo(values);
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Deal has been transferred.",
            timer: 2000,
          });
          getDeals(session);
        }
      });
  };

  const sendTransferDealInfo = (values) => {
    axios
      .get("ws/ws_system.php", {
        params: {
          task: 5,
          action: 1,
          type: "deal",
          type_id: deal_id,
          sender: session.user_id,
          sender_type: session.role_id,
          receiver: values.user,
          receiver_type: values.role_id,
          company: session.company_id,
          prefix: "transfer",
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Deal has been transferred.",
          timer: 2000,
        });
        getDeals(session);
        setIsTransferOwner(false);
      });
  };

  const CompanyRemarkList = [
    { label: "BANDAR SERAI DEVELOPMENT SDN BHD", value: "140" },
    { label: "DINAMIK ATLANTIK SDN BHD", value: "134" },
    { label: "GAMUDA LAND (BOTANIC) SDN BHD", value: "12" },
    { label: "GAMUDA LAND (KEMUNING) SDN BHD", value: "59" },
    { label: "GAMUDA LAND (T12) SDN BHD", value: "1155" },
    { label: "HICOM-GAMUDA DEVELOPMENT SDN BHD", value: "32" },
    { label: "HIGHPARK DEVELOPMENT SDN BHD", value: "139" },
    { label: "HORIZON HILLS DEVELOPMENT SDN BHD", value: "2" },
    { label: "IDAMAN ROBERTSON SDN BHD", value: "137" },
    { label: "JADE HOMES SDN BHD", value: "144" },
    { label: "MADGE MANSIONS SDN BHD", value: "138" },
    { label: "VALENCIA DEVELOPMENT SDN. BHD.", value: "141" }
  ];

  const handleWon = () => {

    var prospectID = deal.hq_prospect_id && Number(deal.hq_prospect_id) !== 0 ? deal.hq_prospect_id : deal.prospect_id;

    if (Number(deal.pipeline_id) == 623 || Number(deal.pipeline_id) == 51 || Number(deal.pipeline_id) == 107 || Number(deal.pipeline_id) == 119 || Number(deal.pipeline_id) == 136 || Number(deal.pipeline_id) == 717 || Number(deal.pipeline_id) == 155 || Number(deal.pipeline_id) == 298 || Number(deal.pipeline_id) == 513) {
      
      if (introducerList.length > 0) {

        if (introducerList[0].introducer_id === prospectID) {
          
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: 'Unable to proceed. The introducer and referee cannot be the same person. Please try again with a different referral.'
          });

        }else{

          setIsWon(true);

        }

      }else{

        setIsWon(true);

      }

    }else{

      setIsWon(true);

    }

  }

  const sendDealWonValidationSchema = yup.object().shape({
    company_id: yup.string().required('This field is required')
  });

  const sendDealWon = (values) => {
    if (values.type == 'hotLead') {
        
        axios.get("ws/ws_stage.php", {
            params: {
                task: "checkStageEnabled",
                csid: values.cs_id,
                dealid: deal_id,
                prospectid: deal.hq_prospect_id && deal.hq_prospect_id != 0 ? deal.hq_prospect_id : deal.prospect_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {

                axios.get("ext/gamuda/api_rr_prospectivebuyerinsert.php",{
                    params:{
                        dealID: deal_id,
                        prospectID: values.prosID,
                        companyID: values.company_id
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                          icon: 'success',
                          title: 'Success',
                          text: 'Successfully Buy Lot',
                        })
                        .then(() => {
                          window.open(data.deep_link,"_blank");
                          getBookTimeoutBuyLot();
                          getDeals();
                          setIsWon(false);
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            text: data.message
                        });
                    }
                });

            }else{
              setDealWonList(data.record);
              setStageLock(true);
              // var element = data.record.map((record, index) => (
              //     <h6 key={index} class="d-flex">
              //       <FontAwesomeIcon icon={faShieldExclamation} class="me-2" size="lg" /> {record.title}
              //     </h6>
              //   ));
    
              //   Swal.fire({
              //     icon: "warning",
              //     title: "Stage locked. Please fulfill these conditions to proceed",
              //     html: element,
              //     content: {
              //       element: "div",
              //       attributes: {
              //         innerHTML: element,
              //       },
              //     },
              //   });
            }
        });

    }else{

        axios
        .get("ws/ws_deal.php", {
            params: {
            task: 28,
            deal: deal_id,
            user: session.user_id,
            remark: values.remark,
            archieve: encodeURIComponent(values.deal_archieve),
            company_id: session.company_id,
            lostReasonID: "",
            productsAdded: "",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            },
        })
        .then((response) => {
            var data = response.data;
            if (data.status == 0) {
            getDeals();
            setIsWon(false);
            Swal.fire({
                icon: "success",
                title: t('DealDetailGL_Alert_SuccessTitle'),
                // text: "The deal has been marked as won",
                timer: 2000,
            });
            } else {
            Swal.fire({
                icon: "error",
                title: t('DealDetailGL_Alert_FailedTitle'),
                text: data.error,
            });
            }
        });

    }
  };

  const getBookTimeoutBuyLot = () => {
    axios.get("ext/gamuda/api_deal_booking_timeout.php", {
      params: {
        task: 'BookingEndTimeout',
        unique_id: bookTimeout,
        prospect_id: deal.hq_prospect_id && Number(deal.hq_prospect_id) === 0 ? deal.hq_prospect_id : deal.prospect_id,
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
  }

  const sendDealLost = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: session.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: session.company_id,
          lostReasonID: values.lostReasonID,
          productsAdded: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status == 0) {
          getDeals();
          setIsLost(false);
          Swal.fire({
            icon: "success",
            title: t('DealDetailGL_Alert_SuccessTitle'),
            // text: "The deal has been marked as lost",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            text: data.error,
            timer: 2000,
          });
        }
      });
  };

  const sendChangeProspect = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "changeDealProspect",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal_id,
          prospectid: values.prospect,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            text: "The prospect chosen has not been changed",
          });
        } else {
          Swal.fire({
            icon: "success",
            text: "Prospect updated successfully! Changes have been applied.",
            timer: 1500,
          });
          getDeals(session);
        }
      });
  };

  const sendCallAnalytics = (url, record_activity_id) => {
    if (isGenerateCall) {
      Swal.fire({
        icon: "warning",
        title: "opps...",
        text: "We're receiving numerous calls. Kindly hold for a brief moment. Thank you!",
        timer: 2000,
      });
      return;
    }

    setIsGenerateCall(true);
    axios
      .get(`${session.hostUrl}/${session.hostUrlType}/ws/ws_sentiment_analysis.php`, {
        params: {
          task: "glomac_call_insight_v2",
          url: encodeURIComponent(url),
          company_id: session.company_id,
          call_data_id: record_activity_id,
        },
      })
      .then((response) => {
        var data = response.data;
        const newCallInsight = {
          record_activity_id: record_activity_id,
          analytics_param_1: data.analytics_param_1 || "",
          analytics_param_2: data.analytics_param_2 || "",
          analytics_param_3: data.analytics_param_3 || "",
          analytics_param_4: data.analytics_param_4 || "",
          analytics_param_5: data.analytics_param_5 || "",
          analytics_param_6: data.analytics_param_6 || "",
          analytics_param_7: data.analytics_param_7 || "",
          analytics_param_8: data.analytics_param_8 || "",
          analytics_param_9: data.analytics_param_9 || "",
          analytics_param_10: data.analytics_param_10 || "",
          analytics_field_1: data.analytics_field_1 || "",
          analytics_field_2: data.analytics_field_2 || "",
          analytics_field_3: data.analytics_field_3 || "",
          analytics_field_4: data.analytics_field_4 || "",
          analytics_field_5: data.analytics_field_5 || "",
          analytics_field_6: data.analytics_field_6 || "",
          analytics_field_7: data.analytics_field_7 || "",
          analytics_field_8: data.analytics_field_8 || "",
          analytics_field_9: data.analytics_field_9 || "",
          analytics_field_10: data.analytics_field_10 || "",
        };

        setCallAnalytics((prevCallAnalytics) => [...prevCallAnalytics, { ...newCallInsight }]);
        setIsGenerateCall(false);
      });
  };

  const sendEditProspect = (values) => {
    var customField = values.cf_person;
    var cf_id = customField.map((record) => record.name);
    var cf_label = customField.map((record) => record.cf_label);
    var prospect_id = Array(customField.length).fill(deal.prospect_id);
    var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      })
      .filter((value) => value !== null);

    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 2,
          des_title: values.designation_title,
          des: values.designation_title,
          name: values.prospect_name,
          organization: "",
          prospect_address: "",
          prospect: deal.prospect_id,
          owner_id: values.owner_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          axios
            .get("ws/ws_edit_prospect_details.php", {
              params: {
                task: "updateContacts",
                prospect_id: deal.prospect_id,
                contact1_prefix: values.contact1Prefix,
                contact1: values.contact1,
                contact1_old: "",
                contact2_prefix: values.contact2Prefix,
                contact2: values.contact2,
                contact3_prefix: values.contact3Prefix,
                contact3: values.contact3,
                contact4_prefix: values.contact4Prefix,
                contact4: values.contact4,
                email1: values.email1,
                email2: values.email2,
                address1: values.address1,
                address2: values.address2,
                address3: values.address3,
                state_id: values.state_id,
                city_id: values.city_id,
                postcode: values.postcode,
                country_id: values.country_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token,
              },
            })
            .then((response) => {
              const data = response.data;
              const prospectIds = [];
              if (data.status === 0) {
                if (customField.length > 0) {
                  axios
                    .get("ws/ws_custom_field.php", {
                      params: {
                        task: "updateOrAddCF",
                        record: prospect_id.toString(),
                        hq_prospect_id: "",
                        cf_label: cf_label.toString().replaceAll(" ", "_"),
                        cf: cf_id.toString(),
                        value: fd_values.toString(),
                        user_id: session.user_id,
                        company_id: session.company_id,
                        secret_key: session.company_token,
                      },
                    })
                    .then((response) => {
                      const data = response.data;
                      if (data.status === 0) {
                        Swal.fire({
                          icon: "success",
                          text: "The prospect has been updated successfully",
                          timer: 1500,
                        });
                        setIsEditProspect(false);
                        getDeals();
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Opps...",
                          text: "There is some of information has been updated but there is something wrong with your entry, please try again.",
                        });
                        setIsEditProspect(false);
                        getDeals();
                      }
                    });
                } else {
                  Swal.fire({
                    icon: "success",
                    text: "The prospect has been updated successfully",
                    timer: 1500,
                  });
                  setIsEditProspect(false);
                  getDeals();
                }
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Opps...",
                  text: "There is some of information has been updated but there is something wrong with your entry, please try again.",
                });
                getDeals();
                setIsEditProspect(false);
              }
            });
        } else {
          Swal.fire({
            icon: "error",
            title: "Opps...",
            text: "Something wrong with your entry, please try again.",
          });
          setIsEditProspect(false);
        }
      });
  };

  const sendEditDeal = (values) => {
    var customField = values.cf_deal;
    var cf_id = customField.map((record) => record.name);
    var deal_id = Array(customField.length).fill(deal.deal_id);
    var fd_values = customField
      .map((record) => {
        if (Array.isArray(record.fd_values)) {
          var filteredValues = record.fd_values.filter((value) => Object.keys(value).length > 0);
          return filteredValues.length > 0 ? filteredValues.join("|") : null;
        } else {
          return record.fd_values;
        }
      })
      .filter((value) => value !== null);

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 35,
          deal: deal.deal_id,
          source: values.ds_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          if (customField.length > 0) {
            axios
              .get("ws/ws_custom_field.php", {
                params: {
                  task: "updateOrAddCF",
                  secret_key: session.company_id,
                  value: fd_values.toString(),
                  cf: cf_id.toString(),
                  record: deal_id.toString(),
                },
              })
              .then((response) => {
                const data = response.data;
                if (data.status === 0) {
                  Swal.fire({
                    icon: "success",
                    title: t('DealDetailGL_Alert_SuccessTitle'),
                    // text: "The deal has been updated successfully",
                    timer: 1500,
                  });
                  getDeals();
                  setIsEditDeal(false);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: t('DealDetailGL_Alert_FailedTitle'),
                    // text: "There is some of information has been updated but there is something wrong with your entry, please try again.",
                  });
                  getDeals();
                  setIsEditDeal(false);
                }
              });
          } else {
            Swal.fire({
              icon: "success",
              title: t('DealDetailGL_Alert_SuccessTitle'),
              timer: 1500,
            });
            getDeals();
            setIsEditDeal(false);
          }
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "Something wrong with your entry, please try again.",
          });
          setIsEditDeal(false);
        }
      });
  };

  const sendAddContributor = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "addDealContributor",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal.deal_id,
          userid: values.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            text: "The contributer addition is success",
            timer: 1500,
          });
          getDeals();
          setIsAddContributor(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oppps...",
            text: "The user chosen has already been added to this deal",
          });
          getDeals();
          setIsAddContributor(false);
        }
      });
  };

  const sendAddForm = (values) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "addCustomFormV2",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal.deal_id,
          custom_form_id: values.form_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t('DealDetailGL_Alert_SuccessTitle'),
            // text: "The contributer addition is success",
            timer: 1500,
          });
          getDeals();
          setIsAddContributor(false);
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "The form chosen has already been added to this deal",
          });
          getDeals();
          setIsAddContributor(false);
        }
      });
  };

  const sendNewNotes = async (values) => {
    const formData = new FormData();
    formData.append("task", "postNote");
    formData.append("region", session.country);
    formData.append("content", values.notes);
    formData.append("notify_user", values.user);
    formData.append("deal", deal_id);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Successfully created a new notes",
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Something wrong with your entry, please try again.",
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "Something wrong with your entry, please try again.",
      });
    }
  };

  const sendAddActivity = async (values) => {
    try {

      if (Number(values.activity_type) === 2 && Number(meetupLink.status) === 0) {
        const checkDealMeeting = await getMeetingDealStatus();
        if (!checkDealMeeting) {
          setIsSubmitting(false);
          return;
        }

        const checkMeetingAvailability = await getMeetingAvailability(values);
        if (!checkMeetingAvailability) {
          setIsSubmitting(false);
          return;
        }
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addNewActivity",
          region: session.country,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          caid: "",
          assign_user: values.activity_user,
          deal: deal_id,
          people: deal.prospect_id,
          organization: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        if (values.google_calendar) {
          const date = moment(values.activity_date, "DD-MM-YYYY");
          const newDateStart = moment(date.format("YYYY-MM-DD") + " " + values.activity_type, "YYYY-MM-DD HH:mm");
          const dateStart = newDateStart.toISOString();
          const formattedDateStart = dateStart.substring(0, 19) + "Z";
          const duration = values.activity_duration ? parseInt(values.activity_duration.match(/\d+/)[0]) : 60;
          const newDateEnd = newDateStart.clone().add(duration, "minutes");
          const dateEnd = newDateEnd.toISOString();
          const formattedDateEnd = dateEnd.substring(0, 19) + "Z";
          const title = values.activity_title + " with " + deal.prospect_name;
          const googleCalendarLink = "https://www.google.com/calendar/render?action=TEMPLATE&text=" + encodeURIComponent(title) + "&details=" + encodeURIComponent(values.activity_notes) + "&location=&dates=" + formattedDateStart + "/" + formattedDateEnd;
          window.open(googleCalendarLink);
        }

        if (Number(values.activity_type) === 2 && Number(meetupLink.status) === 0) {
          sendCreateMeetup(values, data.record);
        } else {
          setIsActiveTab(false);
          getTimeline(false);
          setIsSubmitting(false);
          Swal.fire({
            icon: "success",
            title: t("DealDetails_success"),
            text: t("DealDetails_successfully_created_a_new_activity"),
            timer: 1500,
          });
        }
      } else {
        setIsActiveTab(false);

        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Something wrong with your entry, please try again.",
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
      setIsActiveTab(false);

      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "Something wrong with your entry, please try again.",
      });
    }
  };

  const sendAddFile = async (values) => {
    const formData = new FormData();
    formData.append("deal_id", deal_id);
    formData.append("user_id", session.user_id);
    formData.append("file_title", values.title);
    formData.append("file", values.file[0]);

    try {
      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/upload_file.php`, formData);
      const data = response.data;

      if (data.status === 0) {
        setIsActiveTab(false);
        getTimeline(false);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Successfully upload the file",
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);

        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Something wrong with your entry, please try again.",
        });
      }
    } catch (error) {
      console.error("Error during HTTP request:", error);
    }
  };

  const sendAddMessages = (values) => {
    if (values.message_type === "email") {
      sendEmail(values);
    } else {
      sendWhatsapp(values);
    }
  };

  const sendEmail = async (values) => {
    try {
      let receiverEmail = "";
      if (deal.email1) {
        receiverEmail = deal.email1;
      } else if (deal.email2) {
        receiverEmail = deal.email2;
      } else if (deal.prospect_email_info && deal.prospect_email_info.length > 0) {
        receiverEmail = deal.prospect_email_info[0].email_title;
      }

      let modifiedEmailContent = values.email_content.split("../textarea_upload/").join(`${session.hostUrl}/${session.hostUrlType}/textarea_upload/`).split("../../ext/").join(`${session.hostURL}/${session.hostUrlType}/ext/`).split("../").join(`${session.hostURL}/${session.hostUrlType}`);

      const formData = new FormData();
      formData.append("task", "sendEmail");
      formData.append("emailContent", modifiedEmailContent);
      formData.append("deal", deal_id);
      formData.append("receiverEmail", receiverEmail);
      formData.append("receiverName", deal.prospect_name);
      formData.append("subject", values.email_subject);
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      await axios.post("ws/ws_email_post.php", formData);

      setIsActiveTab(false);
      getTimeline();
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "The email has been scheduled",
        timer: 2000,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "An error occurred while sending the email, please try again or contact our developer",
      });
    }
  };

  const sendWhatsapp = (values) => {
    try {
      let numToContact = "";
      if (deal.contact1) {
        numToContact = deal.contact1;
      } else if (deal.contact2) {
        numToContact = deal.contact2;
      } else if (deal.prospect_contact_count > 0) {
        numToContact = deal.prospect_contact_info[0].contact_num;
      } else {
        throw new Error("There is no contact number for this prospect");
      }

      if (numToContact.charAt(0) === "0") {
        numToContact = "6" + numToContact;
      }

      numToContact = numToContact.replace(/-/g, "");
      numToContact = numToContact.replace(/ /g, "");

      window.open(`https://web.whatsapp.com/send?phone=${numToContact}&text=${values.message_content}`);
      axios
        .get("ws/ws_custom_interaction.php", {
          params: {
            task: "setInteraction",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            interaction_id: values.whatsapp_template,
            source: "deal",
            type_id: deal_id,
            prospect_id: deal.prospect_id,
            message: values.message_content,
            hashid: "",
          },
        })
        .then((response) => {
          setIsActiveTab(false);
          getTimeline();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "The WhatsApp message has been setup",
            timer: 1500,
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oppss...",
            text: "An error occurred while setting up the WhatsApp message, please try again or contact our developer",
          });
        });
    } catch {
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "An error occurred while setting up the WhatsApp message, please try again or contact our developer",
      });
    }
  };

  const sendMarkAsDone = async (record) => {
    try {

      var okToMark = setCheckOkToEdit(record.record_create_user_id, record.record_assign_user_id);
      if (okToMark) {
        var newValue = record.record_activity_mad === "yes" ? "no" : "yes";
        await axios.get("ws/ws_deal.php", {
            params: {
              task: 16,
              activity: record.record_activity_id,
              status: newValue,
              user: session.user_id,
              deal_id: deal_id,
            },
        })
          // .then((response) => {
          //   setTimeline((prevState) => {
          //     return prevState.map((info) => {
          //       if (info.record_activity_id === record.record_activity_id) {
          //         return {
          //           ...info,
          //           record_activity_mad: newValue,
          //         };
          //       }
          //       return info;
          //     });
          //   });
          // });
        getTimeline();
      } else {
        Swal.fire({
          icon: "warning",
          title: "Oppps...",
          text: "Sorry, you can't edit this task.",
        });
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  const cancelMeetupSchema = yup.object().shape({
    reason: yup.string().required(t("DealDetails_error_message")),
  });

  const sendCancelMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "cancelMeetup",
          deal_id: deal_id,
          activity_id: activityData.record_activity_id,
          cancel_reason: values.reason,
          cancel_remark: values.remark,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getTimeline();
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "success",
          text: "Successfully cancel the appointment",
          timer: 2000,
        });
      } else {
        setIsCancelMeetup(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingDealStatus = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkActiveMeeting",
          deal_id: deal_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        return true;
      } else {
        Swal.fire({
          icon: "warning",
          text: `You have an active meeting scheduled in this deal.`,
        });
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetingAvailability = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "checkMeetingAvailability",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          duration: values.activity_duration,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const findRecord = data.record.find((record) => record.activity_mark_as_done === "no");
        if (findRecord) {
          const newDate = new Date(findRecord.activity_dated + " " + findRecord.activity_timed);
          const momentDate = moment(newDate).format("LLL");
          Swal.fire({
            icon: "warning",
            text: `You have an existing meeting scheduled with ${findRecord.prospect_name} on ${momentDate}. Kindly arrange an alternative date and time for this meeting.`,
          });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetupCancel = async (activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupInfo",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetupReschedule = async (activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupRescheduleLog",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
          activity_id: activity_id,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };


  function getLatestRecord(records) {
    if (records.length === 0) return null;

    return records.reduce((latest, record) => {
      const latestDate = new Date(latest.record_date_time_created);
      const currentDate = new Date(record.record_date_time_created);
      return currentDate > latestDate ? record : latest;
    });
  }

  const getCancelMeetupReason = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupCancelReason",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOption = data.record.map((record) => ({
          label: record.cancel_reason,
          value: record.cancel_reason_id,
        }));

        setCancelMeetupReasonList(recordOption);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMeetup = async () => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "getMeetupLink",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal_id: deal_id,
        },
      });

      const data = response.data;
      setMeetupLink(data);
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateMeetup = async (values, activity_id) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "createMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          activity_id: activity_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status == 0) {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      } else {
        setIsSubmitting(false);
        setIsActiveTab(false);
        getTimeline(false);
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_created_a_new_activity"),
          timer: 1500,
        });
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
    }
  };

  const sendRescheduleMeetup = async (values) => {
    try {
      const response = await axios.get("ws/ws_meetup.php", {
        params: {
          task: "rescheduleMeetup",
          deal_id: deal_id,
          prospect_id: deal.prospect_id,
          chosen_date: values.activity_date,
          chosen_time: values.activity_time,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsEditActivity(false);
        setIsSubmitting(false);
        getTimeline();
        Swal.fire({
          icon: "success",
          title: t("DealDetails_success"),
          text: t("DealDetails_successfully_update_the_activity"),
          timer: 1500,
        });
      } else {
        setIsEditActivity(false);
        setIsSubmitting(false);
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t("DealDetails_failed"),
          text: t("DealDetails_error_api_message"),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditActivity = async (values) => {
    
    try {
      if (Number(values.activity_type) === 2 && Number(meetupLink.status) === 0) {
        const checkMeetingAvailability = await getMeetingAvailability(values);
        if (!checkMeetingAvailability) {
          setIsSubmitting(false);
          return;
        }
      }

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: 12,
          create_user: session.user_id,
          dated: values.activity_date,
          timed: values.activity_time,
          duration: values.activity_duration,
          title: values.activity_title,
          content: values.activity_notes,
          mad: values.mad ? "yes" : "no",
          dat: values.activity_type,
          ca_id: values.ca_id,
          assign_user: values.activity_user,
          aid: values.record_activity_id,
        },
      });

      const data = response.data;

      if (data.status === 0) {
          if (Number(values.record_activity_type_id) === 2 && Number(activityData.meetup_cancel.status) === 0 && Number(activityData.meetup_cancel.record.cancel) === 0) {
            sendRescheduleMeetup(values);
          } else {
            setIsSubmitting(false);
      
            setIsEditActivity(false);
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t('DealDetailGL_Alert_SuccessTitle'),
              // text: "Successfully update the activity",
              timer: 1500,
            });
          }
      } else {
        setIsActiveTab(false);
        setIsSubmitting(false);
        setIsEditActivity(false);
        Swal.fire({
          icon: "error",
          title: t('DealDetailGL_Alert_FailedTitle'),
          // text: "Something wrong with your entry, please try again.",
        });
      }
    } catch (error) {
      setIsEditActivity(false);
      Swal.fire({
        icon: "error",
        title: t('DealDetailGL_Alert_FailedTitle'),
        // text: "Something wrong with your entry, please try again.",
      });
    }
  };

  const sendDeleteActivity = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Please confirm that you would like to remove ${record.record_activity_title}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "23",
              activity: record.record_activity_id,
            },
          })
          .then((response) => {
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t('DealDetailGL_Alert_SuccessTitle'),
              // title: "The activity has been removed",
              timer: 1500,
            });
          });
      }
    });
  };

  const sendEditNotes = async (values) => {
    const formData = new FormData();
    formData.append("task", "editNotes");
    formData.append("content", values.notes);
    formData.append("note", values.record_activity_id);
    formData.append("deal", deal_id);
    formData.append("utoken", session.user_ac_token);
    formData.append("ctoken", session.company_token);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsActiveTab(false);
        getTimeline(false);
        setisEditNote(false);
        Swal.fire({
          icon: "success",
          title: t('DealDetailGL_Alert_SuccessTitle'),
          // text: "Successfully updated the notes",
          timer: 1500,
        });
      } else {
        setIsActiveTab(false);
        Swal.fire({
          icon: "error",
          title: t('DealDetailGL_Alert_FailedTitle'),
          // text: "Something wrong with your entry, please try again.",
        });
      }
    } catch {
      setIsActiveTab(false);
      Swal.fire({
        icon: "error",
        title: t('DealDetailGL_Alert_FailedTitle'),
        // text: "Something wrong with your entry, please try again.",
      });
    }
  };

  const sendDeleteNotes = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Please confirm that you would like to remove this note`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "24",
              note: record.record_activity_id,
              prospectNote: "",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t('DealDetailGL_Alert_SuccessTitle'),
              // title: "The activity has been removed",
              timer: 1500,
            });
          });
      }
    });
  };

  const sendDeleteFiles = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: `Please confirm that you would like to remove this file`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--op-primary-color)",
      cancelButtonColor: "#ddd",
      confirmButtonText: "Remove",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_deal.php", {
            params: {
              task: "25",
              file: record.record_activity_id,
            },
          })
          .then((response) => {
            getTimeline();
            Swal.fire({
              icon: "success",
              title: t('DealDetailGL_Alert_SuccessTitle'),
              // title: "The activity has been removed",
              timer: 1500,
            });
          });
      }
    });
  };

  const sendDeleteDeal = (values) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 28,
          deal: deal_id,
          user: session.user_id,
          remark: values.remark,
          archieve: encodeURIComponent(values.deal_archieve),
          company_id: session.company_id,
          lostReasonID: "",
          productsAdded: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status == 0) {
          getDeals();
          Swal.fire({
            icon: t('DealDetailGL_Alert_SuccessTitle'),
            // text: "The deal has been deleted",
            timer: 1500,
          })
          .then(() => {
            setIsDeleteDeal(false);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            text: data.error,
          });
        }
      });
  };

  const sendEditDealInfo = (values) => {
    var updateCloseDate = 0;
    if (deal.deal_archieve === "won" || deal.deal_archieve === "lost") {
      updateCloseDate = 1;
    }

    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 4,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          title: values.deal_title,
          value: values.deal_price,
          close: values.deal_expected_close,
          added: values.deal_added,
          archieve: values.deal_archieve,
          dealclosed: values.deal_close,
          deal: deal.deal_id,
          updateAddedDate: 1,
          updateExpireDate: 1,
          updateCloseDate: updateCloseDate,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          setIsEditDealInfo(false);
          getDeals();

          Swal.fire({
            icon: "success",
            title: t('DealDetailGL_Alert_SuccessTitle'),
            // text: "The deal has been updated",
            timer: 2000,
          });
        } else {
          setIsEditDealInfo(false);
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "Something wrong with your entry, please try again or contact our developer",
          });
        }
      });
  };

  const sendReopenDeal = () => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "reopenDeal",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          user: session.user_id,
          deal: deal_id,
          productsAdded: productCount,
        },
      })
      .then((response) => {
        const data = response.data;
        if (Number(data.status) === 0) {
          getDeals();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "The deal successfully reopen",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oppss...",
            text: "Something wrong to repoen the deal, please try again or contact our developer",
          });
        }
      });
  };

  const sendDeleteContributor = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: "delContributor",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          dealid: deal_id,
          userid: record.user_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: "Oppss...",
            text: "Something wrong to remove contributor, please try again or contact our developer",
          });
        } else {
          getDeals();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully removed the contributor",
            timer: 2000,
          });
        }
      });
  };

  const sendDeleteForm = (record) => {
    axios
      .get("ws/ws_custom_form.php", {
        params: {
          task: "removeCustomForm",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          deal: deal_id,
          custom_form_deal_id: record.custom_form_deal_id,
          custom_form_id: record.custom_form_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 1) {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "Something wrong to remove the form, please try again or contact our developer",
          });
        } else {
          getDeals();
          Swal.fire({
            icon: "success",
            title: t('DealDetailGL_Alert_SuccessTitle'),
            // text: "Successfully removed the form",
            timer: 2000,
          });
        }
      });
  };

  const sendDeleteProduct = (record) => {
    axios
      .get("ws/ws_product.php", {
        params: {
          task: "delDealProduct",
          ctoken: session.company_token,
          utoken: session.user_ac_token,
          deal: deal.deal_id,
          product: record.product_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          getProduct();
          getAllProduct();
          getTimeline();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully removed the product",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oppss...",
            text: "Something wrong with your entry, please try again or contact our developer",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Something wrong with your entry, please try again or contact our developer",
        });
      });
  };

  // SET CHECK OWNER -------------------------------------------------------------

  const setCheckOwner = () => {
    if (deal.deal_archieve == "" && (isOwner || Number(session.role_id) === 1)) {
      return true;
    } else {
      return false;
    }
  };

  const setCheckValidUser = () => {
    var valid = true;
    if (deal.deal_archieve == "" && (Number(deal.owner_id) == Number(session.user_id) || Number(session.role_id) == 1)) {
      valid = true;
    } else if (deal.deal_archieve == "" && isContributor) {
      valid = true;
    } else {
      valid = false;
    }

    if (Number(session.role_id) == 1) {
      valid = true;
    }
    return valid;
  };

  const setCheckValidAdminUser = () => {
    if (Number(session.role_id) == 1) {
      return true;
    } else {
      return false;
    }
  };

  const setFilename = (x) => {
    var str = x.toString();
    var strSplit = str.split("/");
    var index = strSplit.length - 1;
    var output = strSplit[index];
    return output;
  };

  const setTimelineDateTime = (record) => {
    var icon = "";
    var content = "";

    if (record.record_type === "Note") {
      content = record.CREATE_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      content = record.ASSIGN_USER;
      icon = <FontAwesomeIcon icon={faUser} size="lg" className="me-2" />;
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && deal.organization_title !== "0") {
      content = deal.organization_title;
      icon = <FontAwesomeIcon icon={faBuilding} size="lg" className="me-2" />;
    } else if (record.record_type === "Email" && record.record_comment !== "") {
      content = record.record_comment;
      icon = <FontAwesomeIcon icon={faEnvelopeOpen} size="lg" className="me-2" />;
    }

    return (
      <Stack direction="horizontal" gap={3}>
        <div>{vietnam.includes(session.company_id) && record.record_type !== 'Activity' && record.record_type !== 'Note_Prospect' && record.record_type !== 'Note' ? setDateTimeStrVN(record.record_date_time) : setDateTimeStr(record.record_date_time)}</div>
        <div className="d-flex">
          {icon}
          {content}
        </div>
      </Stack>
    );
  };

  const setCheckOkToEdit = (uid, uid2) => {
    var valid = true;
    if (isContributor) {
      if (uid != session.user_id) {
        valid = false;
      }

      if (uid2 != null && uid2 != session.user_id) {
        valid = false;
      }
    }
    return valid;
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "warning";
    } else if (status === "reserved") {
      return "primary";
    } else if (status === "sold") {
      return "danger";
    } else {
      return "";
    }
  };

  const setDealTitle = (input) => {
    const maxLength = 50;
    if (input.length > maxLength) {
      const truncatedInput = input.substring(0, maxLength) + "...";
      return (
        <OverlayTrigger placement="bottom" trigger={["hover", "hover"]} overlay={<Tooltip>{input}</Tooltip>}>
          <span>{truncatedInput}</span>
        </OverlayTrigger>
      );
    } else {
      return input;
    }
  };

  const setTimelineAction = (record) => {
    const hasReadWritePermission = Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1;
    if (hasReadWritePermission) {
      if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp") {
        if (Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1 || record.record_activity_mad === "yes") {
          return false;
        } else {
          return true;
        }
      } else if (record.record_type === "Note") {
        return true;
      } else if (record.record_type === "File") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const setWidthTimeline = (record) => {
    if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === true) {
      return "90%";
    } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity") && setTimelineAction(record) === false) {
      return "95%";
    } else if ((record.record_type !== "Activity" || record.record_type !== "CustomActivity") && setTimelineAction(record) === true) {
      return "95%";
    } else {
      return "100%";
    }
  };

  const sendCallLead = async () => {
    setIsCallLead(true);

    let contactNumber = "";
    const prospectContactInfo = deal.prospect_contact_info;

    for (let i = 1; i <= 5; i++) {
      const contact = deal[`contact${i}`];
      if (contact) {
        contactNumber = contact;
        break;
      } else if (prospectContactInfo && prospectContactInfo.length > 0) {
        contactNumber = prospectContactInfo[0].contact_num;
      }
    }

    if (!contactNumber) {
      setIsCallLead(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "There aren't any contact numbers",
      });
      return;
    }

    try {
      const response = await axios.get("ws/ws_avanser.php", {
        params: {
          task: 1,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          contact: contactNumber,
        },
      });

      setTimeout(() => setIsCallLead(false), 10000);
    } catch (error) {
      console.error(error);
    }
  };

  // MODAL TRIGGER ---------------------------------------------------------------

  const modalEditActivity = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 18,
          activity: record.record_activity_id,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var activityTime = data.record.activity_timed.split(":");
          var activityTimeValue = activityTime[0] + ":" + activityTime[1];
          const editValue = {
            activity_title: data.record.activity_title,
            activity_type: data.record.dat_id,
            activity_date: data.record.activity_dated,
            activity_time: activityTimeValue,
            activity_time_val: activityTimeValue,
            activity_duration: data.record.activity_duration,
            activity_notes: data.record.activity_content,
            activity_notes2: data.record.activity_content,
            activity_linked: "deal",
            activity_user: data.record.assign_user_id,
            deal_id: deal_id,
            prospect_id: deal.prospect_id,
            mad: data.record.activity_mark_as_done === "yes" ? true : false,
            ca_id: data.record.ca_id,
            record_activity_id: record.record_activity_id,
            record_activity_type_id: record.record_activity_type_id
          };

          setInitEditActivity(editValue);
          setActivityData(record);
          setIsEditActivity(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "Sorry, cannot edit the activity at the moment, please contact our developer. Thank you.",
          });
        }
      });
  };

  const modalEditNotes = (record) => {
    axios
      .get("ws/ws_deal.php", {
        params: {
          task: 19,
          type: "deal",
          note: record.record_activity_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          const editValue = {
            notes: data.record.note_content,
            notes2: data.record.note_content,
            notes_id: data.record.note_id,
            user_id: data.record.user_id,
            record_activity_id: record.record_activity_id,
          };

          setInitEditNotes(editValue);
          setisEditNote(true);
        } else {
          Swal.fire({
            icon: "error",
            title: t('DealDetailGL_Alert_FailedTitle'),
            // text: "Sorry, cannot edit the notes at the moment, please contact our developer. Thank you.",
          });
        }
      });
  };

  // RENDER FUNCTION -------------------------------------------------------------

  const RenderDealAction = () => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" className="shadow-sm border" onClick={openDropdown}>
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn inset-top-35" align="end" size={100} style={{ inset: "35px 0px auto auto" }}>
          <Dropdown.Item onClick={setIsEditDealInfo}>{t('DealDetailGL_EditDeal')}</Dropdown.Item>
          <Dropdown.Item onClick={setIsDeleteDeal}>{t('DealDetailGL_DeleteDeal')}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const setGenerateDurationList = (increment) => {
    const durationList = [];
    const maxDuration = 4 * 60; // 4 hours in minutes

    const addDuration = (minutes) => {
      if (minutes < 60) {
        durationList.push({ label: `${minutes} Mins`, value: `${minutes}` });
      } else {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        if (mins === 0) {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""}`, value: `${minutes}` });
        } else {
          durationList.push({ label: `${hours} Hour${hours > 1 ? "s" : ""} ${mins} Mins`, value: `${minutes}` });
        }
      }
    };

    for (let i = increment; i <= maxDuration; i += increment) {
      addDuration(i);
    }

    return durationList;
  };

  const setGenerateTimings = (startHour, endHour, interval) => {
    const timings = [];
    let currentTime = new Date();
    currentTime.setHours(startHour, 0, 0, 0);
    while (currentTime.getHours() < endHour || (currentTime.getHours() === endHour && currentTime.getMinutes() === 0)) {
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timings.push({ label: timeString, value: timeString });
      currentTime.setMinutes(currentTime.getMinutes() + interval);
    }
    return timings;
  };

  const setTimelineMAD = (record) => {
    if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
      if (Number(record.record_activity_type_id) === 2 && record.meetup_cancel.status === 0 && Number(record.meetup_cancel.record.cancel) === 1) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const RenderTimelineAction = ({ record }) => {
    const CustomToggle = forwardRef(({ onClick }, ref) => {
      const openDropdown = (e) => {
        e.preventDefault();
        onClick(e);
      };

      return (
        <Button ref={ref} variant="light" onClick={openDropdown} className="float-end">
          <FontAwesomeIcon icon={faEllipsis} size="lg" />
        </Button>
      );
    });

    return (
      <Dropdown size={100}>
        <Dropdown.Toggle as={CustomToggle} />
        <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && <Dropdown.Item onClick={() => modalEditActivity(record)}>{t('DealDetailGL_Timeline_EditActivity')}</Dropdown.Item>}
          {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteActivity(record)}>{t('DealDetailGL_Timeline_DeleteActivity')}</Dropdown.Item>}
          {record.record_type === "Note" && <Dropdown.Item onClick={() => modalEditNotes(record)}>{t('DealDetailGL_Timeline_EditNote')}</Dropdown.Item>}
          {record.record_type === "Note" && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteNotes(record)}>{t('DealDetailGL_Timeline_DeleteNote')}</Dropdown.Item>}
          {record.record_type === "File" && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => sendDeleteFiles(record)}>{t('DealDetailGL_Timeline_DeleteFile')}</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const RenderAccordionButton = ({ children, eventKey }) => {
    const [isOpen, setIsOpen] = useState(false);
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
    return (
      <button onClick={decoratedOnClick} className="accordion-button">
        <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
        <div className="mt-1">{children}</div>
      </button>
    );
  };

  const RenderActionTab = useCallback(
    ({ tab, active, userListNotes, activityUser, isSubmitting }) => {
      switch (tab) {
        case "notes":
          if (active) {
            return (
              <Formik
                validationSchema={notesSchema}
                onSubmit={sendNewNotes}
                initialValues={{
                  notes: "",
                  user: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <NoteEditor value={values.notes} onChangeValue={(value) => {handleChange('notes')(value)}}/>
                        {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FormSelect options={userListNotes} valueDefault={userListNotes[0]} placeholder="Select user" onChange={(e) => handleChange("user")(e.value)} />
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light">
                        {t('DealDetailGL_Bar_Form_Modal_Save')}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t('DealDetails_click_here_to_take_note')}
              </Card.Body>
            );
          }
          case "activity":
            if (active) {
              return (
                <Formik
                  validationSchema={addActivitySchema}
                  onSubmit={sendAddActivity}
                  initialValues={{
                    activity_title: "",
                    activity_type: "1",
                    activity_date: latestDate,
                    activity_time: setLatestTime(timings).value,
                    activity_time_show: true,
                    activity_time_val: setLatestTime(timings),
                    activity_duration: "",
                    activity_duration_show: true,
                    activity_notes: "",
                    activity_linked: "deal",
                    activity_user: session.user_id,
                    deal_id: "",
                    prospect_id: "",
                    mad: false,
                    google_calendar: false,
                  }}
                >
                  {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Card.Body id="notes_collapse">
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <Form.Control type="text" placeholder={t("DealDetails_activity_title")} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                              {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                            </Form.Group>
                          </div>
                        </div>
  
                        <div className="d-flex mb-3">
                          <div style={{ width: "5%" }}></div>
                          <Form.Group>
                            <ButtonGroup className="activity-tab">
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_1"
                                value="1"
                                checked={values.activity_type === "1"}
                                onChange={() => {
                                  handleChange("activity_type")("1");
                                  setFieldValue("activity_duration_show", false);
                                  setFieldValue("activity_time_show", false);
                                  setFieldValue("activity_time_val", setLatestTime(timings).value);
                                  setTimeout(() => {
                                    setFieldValue("activity_duration_show", true);
                                    setFieldValue("activity_time_show", true);
                                  }, 1);
                                }}
                              >
                                <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                {t("DealDetails_call")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_2"
                                value="2"
                                checked={values.activity_type === "2"}
                                onChange={() => {
                                  handleChange("activity_type")("2");
                                  setFieldValue("activity_duration_show", false);
                                  setFieldValue("activity_time_show", false);
                                  setFieldValue("activity_time_val", setLatestTime(meetingTimingsList).value);
                                  setTimeout(() => {
                                    setFieldValue("activity_duration_show", true);
                                    setFieldValue("activity_time_show", true);
                                  }, 1);
                                }}
                              >
                                <FontAwesomeIcon icon={faUsers} className="me-1" />
                                {t("DealDetails_meeting")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_3"
                                value="3"
                                checked={values.activity_type === "3"}
                                onChange={() => {
                                  handleChange("activity_type")("3");
                                  setFieldValue("activity_duration_show", false);
                                  setFieldValue("activity_time_show", false);
                                  setFieldValue("activity_time_val", setLatestTime(timings).value);
                                  setTimeout(() => {
                                    setFieldValue("activity_duration_show", true);
                                    setFieldValue("activity_time_show", true);
                                  }, 1);
                                }}
                              >
                                <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                {t("DealDetails_task")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_4"
                                value="4"
                                checked={values.activity_type === "4"}
                                onChange={() => {
                                  handleChange("activity_type")("4");
                                  setFieldValue("activity_duration_show", false);
                                  setFieldValue("activity_time_show", false);
                                  setFieldValue("activity_time_val", setLatestTime(timings).value);
                                  setTimeout(() => {
                                    setFieldValue("activity_duration_show", true);
                                    setFieldValue("activity_time_show", true);
                                  }, 1);
                                }}
                              >
                                <FontAwesomeIcon icon={faFlag} className="me-1" />
                                {t("DealDetails_deadline")}
                              </ToggleButton>
                              <ToggleButton
                                variant=""
                                type="radio"
                                name="activity_type"
                                id="activity_type_5"
                                value="5"
                                checked={values.activity_type === "5"}
                                onChange={() => {
                                  handleChange("activity_type")("5");
                                  setFieldValue("activity_duration_show", false);
                                  setFieldValue("activity_time_show", false);
                                  setFieldValue("activity_time_val", setLatestTime(timings).value);
                                  setTimeout(() => {
                                    setFieldValue("activity_duration_show", true);
                                    setFieldValue("activity_time_show", true);
                                  }, 1);
                                }}
                              >
                                <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                {t("DealDetails_email")}
                              </ToggleButton>
                            </ButtonGroup>
                          </Form.Group>
                        </div>
  
                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                              </Form.Group>
                              <Form.Group as={Col}>
                                {values.activity_time_show && (
                                  <FormSelect
                                    options={values.activity_type === "2" ? meetingTimingsList : timings}
                                    valueDefault={values.activity_type === "2" ? setLatestTime(meetingTimingsList) : setLatestTime(timings)}
                                    placeholder={t("DealDetails_select_time")}
                                    onChange={(e) => handleChange("activity_time")(e.value)}
                                  />
                                )}
                              </Form.Group>
                              <Form.Group as={Col}>
                                {values.activity_duration_show && <FormSelect options={values.activity_type === "2" ? meetingDurationList : durationList} placeholder={t("DealDetails_select_duration")} onChange={(e) => handleChange("activity_duration")(e.value)} />}
                                {errors.activity_duration && touched.activity_duration && <div className="op-error-message">{errors.activity_duration}</div>}
                              </Form.Group>
                            </Row>
                          </div>
                        </div>
  
                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faCircleUser} size="lg" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group>
                              <FormSelect placeholder={t("DealDetails_select_user")} options={activityUser} valueDefault={activityUser.find((record) => record.value === session.user_id)} onChange={(e) => handleChange("activity_user")(e.value)} />
                              {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                            </Form.Group>
                          </div>
                        </div>
  
                        <div className="d-flex mb-3">
                          <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                            <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                          </div>
                          <div style={{ width: "95%" }}>
                            <Form.Group className="activity-notes">
                              <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Footer className="d-flex flex-row-reverse align-items-center">
                        <button type="submit" className="btn op-button op-primary-color text-light" disabled={isSubmitting}>
                          {t("DealDetails_save")}
                        </button>
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_mark_as_done")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "mad", value: e.target.checked },
                            });
                          }}
                        />
                        <Form.Check
                          type="switch"
                          label={t("DealDetails_add_to_google_calendar")}
                          className="d-flex justify-content-center align-items-center me-3"
                          onChange={(e) => {
                            handleChange({
                              target: { name: "google_calendar", value: e.target.checked },
                            });
                          }}
                        />
                      </Card.Footer>
                    </Form>
                  )}
                </Formik>
              );
            } else {
              return (
                <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                  {t("DealDetails_click_here_to_add_an_activity")}
                </Card.Body>
              );
            }
        case "files":
          if (active) {
            return (
              <Formik
                validationSchema={fileUploadSchema}
                onSubmit={sendAddFile}
                initialValues={{
                  file: [],
                  title: "",
                }}
              >
                {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body className="note-notes">
                      <Form.Group className="mb-3">
                        <Form.Control type="text" placeholder={t('DealDetailGL_Files_Title')} onChange={handleChange("title")} />
                        {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                      </Form.Group>
                      <Form.Group>
                        <FilePond
                          allowMultiple={false}
                          maxFiles={1}
                          branding={false}
                          onupdatefiles={(fileItems) => {
                            setFieldValue(
                              "file",
                              fileItems.map((fileItem) => fileItem.file)
                            );
                          }}
                        />
                        {errors.file && touched.file && <div className="op-error-message">{errors.file}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse">
                      <button type="submit" className="btn op-button op-primary-color text-light">
                      {t('DealDetailGL_Bar_Form_Modal_Save')}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t('DealDetails_click_here_to_upload_file')}
              </Card.Body>
            );
          }
        case "messages":
          if (active) {
            return (
              <Formik
                validationSchema={addMessageschema}
                initialValues={{
                  message_type: "email",
                  email_template: "",
                  whatsapp_template: "",
                  email_subject: "",
                  email_content: "",
                  email_content_val: "",
                }}
                onSubmit={sendAddMessages}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card.Body id="notes_collapse">
                      <Form.Group className="mb-3">
                        <ToggleButtonGroup
                          className="activity-tab"
                          defaultValue="email"
                          type="radio"
                          name="message_type"
                          onChange={(value) => {
                            setFieldValue("message_type", value);
                            setFieldValue("email_template", "");
                            setFieldValue("whatsapp_template", "");
                            setFieldValue("email_subject", "");
                            setFieldValue("email_content", "");
                            setFieldValue("email_content_val", "");
                          }}
                        >
                          <ToggleButton variant="" id="message_type_1" value="email">
                            <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-2" />
                            {t('DealDetailGL_Messages_Email')}
                          </ToggleButton>
                          <ToggleButton variant="" id="message_type_2" value="whatsapp">
                            <FontAwesomeIcon icon={faWhatsapp} size="lg" className="me-1" />
                            {t('DealDetailGL_Messages_Whatsapp')}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Form.Group>

                      {values.message_type === "email" && (
                        <Form.Group className="mb-3">
                          <FormSelect
                            options={emailTemplate}
                            placeholder={t('DealDetailGL_Messages_Template')}
                            onChange={(e) => {
                              setFieldValue("email_template", e.value);
                              axios
                                .get("ws/ws_email.php", {
                                  params: {
                                    task: "getTemplateData",
                                    utoken: session.user_ac_token,
                                    ctoken: session.company_token,
                                    template_id: e.value,
                                  },
                                })
                                .then((response) => {
                                  const data = response.data;
                                  if (data.status === 0) {
                                    setFieldValue("email_subject", data.record.email_subject);
                                    setFieldValue("email_content", data.record.email_message);
                                    setFieldValue("email_content_val", data.record.email_message);
                                  }
                                });
                            }}
                          />
                          {values.message_type === "email" && errors.email_template && touched.email_template && <div className="op-error-message">{errors.email_template}</div>}
                        </Form.Group>
                      )}

                      {values.message_type === "whatsapp" && (
                        <Form.Group className="mb-3">
                          <FormSelect
                            options={whatsappTemplate}
                            placeholder={t('DealDetailGL_Messages_Template')}
                            onChange={(e) => {
                              var userContact = "";
                              for (var i = 0; i < user.length; i++) {
                                if (user[i].user_id === deal.owner_id) {
                                  userContact = user[i].user_contact;
                                  break;
                                }
                              }
                              var link_pName = deal.prospect_name.replaceAll(" ", "_");
                              var link_dTitle = deal.deal_title.replaceAll(" ", "_");
                              var link_userName = deal.user_name.replaceAll(" ", "_");

                              if (deal.meetup_available === 1) {
                                var meetupStr = `https://app.outperformhq.io/meetup/${deal.meetup_info.hash.substring(0, 8)}/${deal.meetup_info.slug}/${deal.meetup_info.base10}`;
                                e.template = e.template.replace("<meetup_link>", meetupStr);
                              }

                              e.template = e.template.replace("<prospect_name>", deal?.prospect_name || "");
                              e.template = e.template.replace("<deal_title>", deal?.deal_title || "");
                              e.template = e.template.replace("<prospect_address>", deal?.prospect_address || "");
                              e.template = e.template.replace("<ds_title>", deal?.ds_title || "");
                              e.template = e.template.replace("<deal_hash>", deal?.hash || "");
                              e.template = e.template.replace("<organization_title>", deal?.organization_title || "");
                              e.template = e.template.replace("<organization_address>", deal?.organization_address || "");
                              e.template = e.template.replace("<user_name>", deal?.user_name || "");
                              e.template = e.template.replace("<user_contact>", userContact);
                              e.template = e.template.replace("<designation_title>", deal?.designation_title || "");
                              e.template = e.template.replace("<source_category_title>", deal?.source_category_title || "");
                              e.template = e.template.replace("<prospect_contact>", deal.prospect_contact_count > 0 ? deal.prospect_contact_info[0].contact_num : "");

                              e.template = e.template.replace("<prospect_name_link>", link_pName);
                              e.template = e.template.replace("<deal_title_link>", link_dTitle);
                              e.template = e.template.replace("<user_name_link>", link_userName);
                              e.template = e.template.replace("%0a", "");

                              setFieldValue("whatsapp_template", e.value);
                              setFieldValue("email_content", ReactHtmlParser(e.template.trim()));
                              setFieldValue("email_content_val", ReactHtmlParser(e.template.trim()));
                            }}
                          />
                          {values.message_type === "whatsapp" && errors.whatsapp_template && touched.whatsapp_template && <div className="op-error-message">{errors.whatsapp_template}</div>}
                        </Form.Group>
                      )}

                      {values.message_type === "email" && (
                        <Form.Group className="mb-3">
                          <Form.Control type="text" placeholder={t('DealDetailGL_Messages_EmailSubj')} value={values.email_subject} onChange={handleChange("email_subject")} />
                          {errors.email_subject && touched.email_subject && <div className="op-error-message">{errors.email_subject}</div>}
                        </Form.Group>
                      )}

                      <Form.Group className="activity-notes">
                        <NoteEditor value={values.email_content_val} onChangeValue={(value) => {handleChange("email_content")(value)}}/>
                        {/* <Editor
                          apiKey="p07wwarf2518skyuowmfspnbq36o29avgd900ji03uvbp31d"
                          onInit={(evt, editor) => (editorRefMessages.current = editor)}
                          initialValue={values.email_content_val}
                          onEditorChange={(value) => handleChange("email_content")(value)}
                          init={{
                            plugins:
                              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                            menubar: "file edit view insert format tools table tc help",
                            toolbar:
                              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                            branding: false,
                            quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                            toolbar_mode: "sliding",
                            height: 250,
                            min_height: 250,
                            max_height: 400,
                            statusbar: true,
                            autoresize_overflow_padding: 5,
                            autoresize_bottom_margin: "25s",
                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
                          }}
                        /> */}
                        {errors.email_content && touched.email_content && <div className="op-error-message">{errors.email_content}</div>}
                      </Form.Group>
                    </Card.Body>
                    <Card.Footer className="d-flex flex-row-reverse align-items-center">
                      <button type="submit" className="btn op-button op-primary-color text-light">
                      {t('DealDetailGL_Bar_Form_Modal_Save')}
                      </button>
                    </Card.Footer>
                  </Form>
                )}
              </Formik>
            );
          } else {
            return (
              <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                {t('DealDetails_click_here_to_send_message')}
              </Card.Body>
            );
          }
        default:
          return null;
      }
    },
    [editorRef.current, isActiveTab]
  );

  const RenderTransferOwnership = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div ref={ref} style={{ ...style, width: 300, padding: 0, border: "none", top: 40 }} className={`${className} animate slideIn`} aria-labelledby={labeledBy}>
        <Card>
          <Formik
            validationSchema={transferDealSchema}
            onSubmit={sendTransferDeal}
            initialValues={{
              user: "",
              role_id: "",
              transferOption: 2,
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  {t('DealDetailGL_TransferOwner_Modal_Title')}
                  <Dropdown.Item as={CloseButton} />
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3">
                    <FormSelect
                      name="user"
                      value={values.user}
                      onChange={(info) => {
                        handleChange("user")(info.value);
                        handleChange("role_id")(info.role_id);
                      }}
                      isInvalid={errors.user && touched.user}
                      options={user}
                      placeholder={t('DealDetailGL_TransferOwner_Modal_UserPlaceholder')}
                    />
                    {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Check type="radio" name="transferOption" value={2} onChange={handleChange("transferOption")} label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t('DealDetailGL_Deal')} & ${t('DealDetailGL_Prospect')}`} checked />
                    {session.layout_mode === "real_estate" && <Form.Check type="radio" name="transferOption" value={1} onChange={handleChange("transferOption")} label={`${Number(session.naming_scheme) === 1 ? session.top_menu_deal : t('DealDetailGL_Deal')} only`} />}
                  </Form.Group>
                </Card.Body>
                <Card.Footer className="d-flex flex-row-reverse">
                  <button type="submit" className="btn op-button op-primary-color text-light">
                    {t('DealDetailGL_TransferOwner_Modal_Footer_Save')}
                  </button>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </div>
    );
  });

  const RenderTimelineIcon = ({ record }) => {
    if (record.record_type === "Activity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "CustomActivity") {
      return <FontAwesomeIcon icon={faListCheck} />;
    } else if (record.record_type === "NoEditActivity") {
      if (Number(record.record_activity_type_id) === 1) {
        return <FontAwesomeIcon icon={faPhone} />;
      } else if (Number(record.record_activity_type_id) === 2) {
        return <FontAwesomeIcon icon={faUsers} />;
      } else if (Number(record.record_activity_type_id) === 3) {
        return <FontAwesomeIcon icon={faListCheck} />;
      } else if (Number(record.record_activity_type_id) === 4) {
        return <FontAwesomeIcon icon={faFlag} />;
      } else if (Number(record.record_activity_type_id) === 5) {
        return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
      } else if (Number(record.record_activity_type_id) === 6) {
        return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
      } else if (Number(record.record_activity_type_id) === 7) {
        return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
      } else if (Number(record.record_activity_type_id) === 8) {
        return <FontAwesomeIcon icon={faWhatsapp} />;
      } else if (Number(record.record_activity_type_id) === 9) {
        return <FontAwesomeIcon icon={faLink} />;
      } else if (Number(record.record_activity_type_id) === 10) {
        return <FontAwesomeIcon icon={faLocationCheck} />;
      } else if (Number(record.record_activity_type_id) === 11) {
        return <FontAwesomeIcon icon={faCircleMinus} />;
      }
    } else if (record.record_type === "File") {
      return <FontAwesomeIcon icon={faFile} />;
    } else if (record.record_type === "Ownership") {
      return <FontAwesomeIcon icon={faPeopleArrows} />;
    } else if (record.record_type === "Status") {
      return <FontAwesomeIcon icon={faCircleArrowRight} />;
    } else if (record.record_type === "Archieve") {
      return <FontAwesomeIcon icon={faBoxArchive} />;
    } else if (record.record_type === "Reopen") {
      return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
    } else if (record.record_type === "Email") {
      if (record.record_comment) {
        return <FontAwesomeIcon icon={faEnvelope} />;
      } else {
        return <FontAwesomeIcon icon={faEnvelopeOpen} />;
      }
    } else if (record.record_type === "Note") {
      return <FontAwesomeIcon icon={faNoteSticky} />;
    } else {
      return "";
    }
  };

  const RenderTimelineHeader = ({ record }) => {
    let element = "";
    let location = "";

    switch (record.record_type) {
      case "Ownership":
        element = `Deal transfer to ${record.ASSIGN_USER}`;
        break;
      case "Status":
      case "Archieve":
      case "Reopen":
        element = record.record_description;
        break;
      case "File":
        return <RenderFile url={`${session.hostUrl}/${session.hostUrlType}/${record.record_file_url}`} title={`${record.record_title}.${record.record_file_ext}`} />;
      case "Activity":
      case "CustomActivity":
      case "NoEditActivity":
        element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
        if (record.record_comment !== ",") {
          location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" class="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
        }
        break;
      case "Note":
        element = record.record_title;
        element = ReactHtmlParser(element);
        return (
          <div style={{ overflow: "auto" }}>
            <div dangerouslySetInnerHTML={{ __html: element }}></div>
          </div>
        );
      case "Email":
        element = record.record_title;
        break;
      default:
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
  };

  const RenderTimelineDescription = ({ record }) => {
    const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id, record_activity_type_id, meetup_record_description } = record;
    
    if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
      if (record_activity_type === "Call" && record_file_url) {
        
        return (
          <div style={{ padding: "10px 0" }}>
            <RenderAudio url={record_file_url} />
          </div>
        );
      } else {
        return (<Stack direction="vertical" >
        {Number(record_activity_type_id) === 2 && (
          <div className="d-flex align-items-center">
            <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(meetup_record_description) }} /> 
            {Number(meetupLink.status) === 0 && <div className="ms-4">
              <MeetupLog dealID={deal_id} activityID={record_activity_id}/>
            </div>}
          </div>
        )}
        <div style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} /> 
      </Stack>);
      }
    } else if (record_type === "Archieve") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
    } else if (record_type === "Email") {
      return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
    } else {
      return "";
    }
  };

  const RenderTimelineDocument = ({ record }) => {
    if (record.record_file_ext === "image" && record.record_file_url !== "") {
      return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
      return <RenderAudio url={record.record_file_url} />;
    } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
      return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
    } else {
      return "";
    }
  };

  const RenderFile = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank">
        <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderImage = ({ url = "", title = "" }) => {
    return (
      <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank">
        <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
        {title}
      </a>
    );
  };

  const RenderAudio = ({ url = "" }) => {
    return (
      <audio controls className="w-100">
        <source src={url} type="audio/ogg" />
      </audio>
    );
  };

  const RenderFloatingButton = () => {
    // const component =
    //   position: {
    //     bottom: 0,
    //     left: 0,
    //   },
    //   event: 'hover',
    //   alwaysShowTitle: true,
    //   mainButtonStyles: {
    //     backgroundColor: '#27ae60',
    //   },
    //   actionButtonStyles: {
    //     backgroundColor: '#16a085',
    //   },
    // };

    return (
      <Fab mainButtonStyles={{ backgroundColor: "var(--op-primary-color)" }} style={{ bottom: 0, right: 0 }} icon={<FontAwesomeIcon icon={faRobot} style={{ color: "#fff" }} />} event="hover" alwaysShowTitle={false}>
        <Action
          style={{ backgroundColor: "#fff", color: "#000" }}
          text="Take Notes with voice note"
          onClick={setIsVoiceNote}
        >
          <FontAwesomeIcon icon={faMicrophone} size="xl" />
        </Action>
      </Fab>
    );
  };

  // GRID SETUP -------------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: "50vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const leadScoreColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
    },
    {
      headerName: "Date",
      field: "",
      cellRenderer: (params) => {
        if (params.data) {
          const newDate = new Date(params.data.date_time_create);
          return moment(newDate).format("ll");
        }
      },
    },
    {
      headerName: "Score Title",
      field: "score_title",
    },
    {
      headerName: "Score Type",
      field: "score_type",
    },
    {
      headerName: "Score Value",
      field: "score_value",
    },
  ];

  const [newLeadScoreColumn] = useState([
    {
      headerName: 'Score Category',
      field: 'scoreCat',
      flex: 1,
      minWidth: 300,
    },
    {
      headerName: 'Score',
      width: 100,
      cellRenderer: (params) => {
        if (params.data) {
          return params.data.assigned_score;
        }
      }
    }
  ]);

  const openDealColumn = [
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName1'),
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName2'),
      field: "deal_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName3'),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName4'),
      field: "ds_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName5'),
      field: "archieve_remark",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName6'),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const wonDealColumn = [
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName1'),
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName2'),
      field: "deal_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName3'),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName4'),
      field: "ds_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName5'),
      field: "archieve_remark",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName6'),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const lostDealColumn = [
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName1'),
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName2'),
      field: "deal_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName3'),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName4'),
      field: "ds_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName5'),
      field: "archieve_remark",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName6'),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const archievedDealColumn = [
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName1'),
      headerClass: "center",
      cellClass: "center",
      field: "",
      width: 50,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" />;
        }
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName2'),
      field: "deal_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName3'),
      field: "deal_value",
      cellRenderer: (params) => {
        return setCurrency(params.data.deal_value);
      },
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName4'),
      field: "ds_title",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName5'),
      field: "archieve_remark",
    },
    {
      headerName: t('DealDetailGL_Bar_Analytic_HeaderName6'),
      field: "deal_expected_close_date",
      cellRenderer: (params) => {
        if (params.data && params.data.deal_expected_close_date) {
          const newDate = new Date(params.data.deal_expected_close_date);
          return moment(newDate).format("ll");
        }
      },
    },
  ];

  const rowId = useMemo(() => {
    return (params) => params.index;
  }, []);

  // OPEN FUNCTION ----------------------------------------------------------------

  const openLeadScore = () => {
    // axios
    //   .get("ws/ws_rubric_score.php", {
    //     params: {
    //       task: "getRubricScoreLogListing",
    //       utoken: session.user_ac_token,
    //       ctoken: session.company_token,
    //       ds_id: "",
    //       prospect_id: deal.prospect_id,
    //       rubric_score_log_id: "",
    //       rubric_token: "",
    //       searchStr: "",
    //       user_id: deal.owner_id,
    //     },
    //   })
    //   .then((response) => {
    //     const { status, record } = response.data;
    //     setLeadScoreList(status === 0 ? record : []);
    //     setIsLeadScore(true);
    //   });
  };

  const openForm = (record) => {
    if (record.form_url) {
      window.open(`${record.form_url}?utoken=${session.user_ac_token}&token=${session.company_token}&deal=${deal_id}`, "_blank");
    } else {
      Swal.fire({
        icon: "error",
        title: t('DealDetailGL_Alert_FailedTitle'),
        // text: "Form URL isnt defined",
      });
    }
  };

  const getbookTimeout = (dealData) => {
    axios.get('ext/gamuda/api_deal_booking_timeout.php', {
      params: {
        task: 'BookingStartTimeout',
        deal_id: deal_id,
        prospect_id: dealData.hq_prospect_id && Number(dealData.hq_prospect_id) === 0 ? dealData.hq_prospect_id : dealData.prospect_id,
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        setBookTimeout(data.unique_id);
      }else{
        setBookTimeout("");
      }
    })
    .catch(error => {
      console.log(error);
      setBookTimeout("");
    });
  }

  // USEEFFECT FUNCTION -----------------------------------------------------------

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        try {
          await getDeals();
          await getUser();
          await getCrmStatus();
          await getLostReason();
          await getBooking();
          await getTimeline();
          await getCountry();
          await getState();
          await getCity();
          await getDealSource();
          await getForm();
          await getEmailTemplate();
          await getWhatsappTemplate();
          await getAllProduct();
          await getCancelMeetupReason();
          await getMeetup();

          if (session.layout_mode === "real-estate" || Number(session.product_feature) === 1) {
            await getProduct();
          }

          setTimeout(() => {
            setIsLoading(false);
            initialized.current = true;
          }, 500);
        } catch (error) {
          setIsLoading(false);
        }
      }
    };

    initializeData();
  }, [deal_id]);

  useEffect(() => {
    console.log(session);
    if (session) {
      setMeetingDurationList(setGenerateDurationList(Number(session.activity_duration_setting)));
      setMeetingTimingsList(setGenerateTimings(8, 22, Number(session.activity_duration_setting)));
    }
  }, [session]);

  useLayoutEffect(() => {
    function updateWidth() {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth - 50);
      }
    }

    window.addEventListener("resize", updateWidth);
    updateWidth();
  }, [containerRef.current]);

  return (
    <Container fluid style={{ padding: 0 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="main">
          <div className="section-header">
            <Row>
              <Col sm={12} className="py-3 px-4">
                <Stack direction="horizontal" gap={2}>
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="op-text-bigger fs-5 me-2">{setDealTitle(deal.deal_title)}</div>
                      <div
                        className={`op-label me-2 text-light d-flex align-items-center ${deal.deal_status === "new" ? "bg-danger" : deal.deal_status === "inactive" ? "bg-warning" : deal.deal_status === "active" ? "bg-success" : ""}`}
                        style={{ padding: "5px 10px", borderRadius: 5, position: "relative", fontSize: 10 }}
                      >
                        {deal.deal_status === "new" ? t('DealDetailGL_ContactLead') : deal.deal_status === "inactive" ? t('DealDetailGL_FollowUp') : deal.deal_status === "active" ? t('DealDetailGL_Progressing') : ""}
                      </div>
                      {session.subscription_tier && Number(session.subscription_tier) > 2 && (
                        <div className="op-label circular op-primary-color text-light cursor-pointer" onClick={() => getNewLeadScore(deal.prospect_id, true)}>
                          {newLdScoreTotal}
                        </div>
                      )}
                    </div>
                    <Stack className="mt-1" direction="horizontal" gap={2}>
                      {Number(session.company_type_id) === 0 && deal.organization_title !== "0" && (
                        <div className="op-text-medium">
                          <FontAwesomeIcon icon={faBuilding} size="lg" className="me-2" />
                          <b>{deal.organization_title}</b>
                        </div>
                      )}

                      <div className="op-text-medium">
                        <b>{vietnam.includes(session.company_id) ? new Intl.NumberFormat('en-US').format(deal.deal_value) + " VND": setCurrency(deal.deal_value)}</b>
                      </div>
                      <div className="op-text-medium">
                        <FontAwesomeIcon icon={faFlag} size="lg" className="me-2" />
                        {t('DealDetailGL_ExpectedCloseDeal')} <b>{setDateStr(deal.deal_expected_close_date) || "No date"}</b>
                      </div>
                      <div className="op-text-medium">
                        <FontAwesomeIcon icon={faBarsStaggered} size="lg" className="me-2" />
                        {t('DealDetailGL_Pipeline')}
                        <OverlayTrigger
                          trigger={['click', 'focus']}
                          placement="right"
                          rootClose
                          overlay={
                            <Popover>
                                <Popover.Body className="p-0">
                                    <div className="py-2 px-4">
                                      {deal.pipeline_title}
                                    </div>
                                </Popover.Body>
                            </Popover>
                          }
                        >
                          <b style={{cursor: 'pointer'}}> {deal.pipeline_title.split('(')[0]}...</b>
                        </OverlayTrigger>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="op-text-medium me-2">{t('DealDetailGL_Priority')}</span>

                        {[...Array(5)].map((star, index) => {
                          const currentRating = index + 1;
                          return (
                            <label key={index}>
                              <input className="d-none" type="radio" name="rating" value={currentRating} onClick={() => onchangePriority(currentRating)} />
                              <FontAwesomeIcon icon={faStar} style={{ color: currentRating <= (hoverRating || rating) ? "#ffd43b" : "#ddd", cursor: "pointer" }} onMouseEnter={() => setHoverRating(currentRating)} onMouseLeave={() => setHoverRating(null)} />
                            </label>
                          );
                        })}
                      </div>
                    </Stack>
                  </div>

                  <Button variant="light" className="shadow-sm border rounded-circle me-1 ms-auto d-flex justify-content-center align-items-center" onClick={() => sendCallLead()}>
                    <div className="d-flex align-items-center justify-content-center" style={{ padding: "5px 0px" }}>
                      <FontAwesomeIcon icon={faPhone}  />
                    </div>
                  </Button>

                  <div className="d-flex align-items-center justify-content-center">
                    {/* <img className="rounded-circle me-2" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/genericavatar.jpg`} width={30} height={30} /> */}
                    <div className="avatar me-1">
                        <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                            <div className="avatar-txt text-uppercase fs-5">{deal.user_name.split('')[0]}</div>
                        </div>
                    </div>
                    <div className="h-100" style={{ lineHeight: 1.2 }}>
                      <span className="op-text-bigger fw-bold">{deal.user_name}</span> <br />
                      <span className="op-text-medium">{!isOwner ? t('DealDetailGL_Contributor') : t('DealDetailGL_Owner')}</span>
                    </div>
                    {(!isContributor || setCheckOwner()) && session.role_id == 1 && (
                      <Dropdown show={isTransferOwner} className="ms-1">
                        <Dropdown.Toggle className="btn btn-light" onClick={() => setIsTransferOwner(!isTransferOwner)} />
                        <Dropdown.Menu as={RenderTransferOwnership} />
                      </Dropdown>
                    )}
                  </div>

                  {hotLeadsPipeline.includes(Number(deal.pipeline_id)) && session.hostUrlType === 'cloud_staging' &&
                    <div>
                      <button type="button" className="btn btn-success" onClick={setIsReserve}>RESERVE</button>
                    </div>
                  }

                  {setCheckOwner() ? (
                    <div>
                      <button type="button" className="btn btn-success me-1" onClick={handleWon}>
                        {pipeBtn.won}
                      </button>
                      <button type="button" className="btn btn-danger me-1" onClick={setIsLost}>
                        {pipeBtn.lost}
                      </button>
                    </div>
                  ) : (
                    <>
                      <button className="btn btn-info text-light" onClick={() => sendReopenDeal()}>
                        {t('DealDetailGL_Reopen')}
                      </button>
                      {deal.deal_archieve === "won" && <button className="btn btn-success">{t('DealDetailGL_Won')}</button>}
                      {deal.deal_archieve === "lost" && <button className="btn btn-success">{t('DealDetailGL_Lost')}</button>}
                      {deal.deal_archieve === "deleted" && <button className="btn btn-success">{t('DealDetailGL_Deleted')}</button>}
                      {deal.deal_archieve === "won" && Number(deal.pipeline_id) === Number(session.deal_custom_action_pipe) && Number(session.role_id) === 1 && <button class="btn btn-info">{session.deal_custom_action}</button>}
                    </>
                  )}

                  <RenderDealAction />
                </Stack>
              </Col>
              <Col sm={12} className="pb-3 px-4">
                <div className="op steps w-100">
                  {status &&
                    deal &&
                    status.map((record, index) => (
                      <button key={index} className={`link step ${record.cs_index <= deal.cs_index ? "active" : ""}`} onClick={() => onchangeStage(record)}>
                        {record.cs_index <= deal.cs_index && <FontAwesomeIcon className="me-3" icon={faCircleCheck} style={{ color: "#fff" }} size="xl" />} <br />
                        <div className="content">
                          <div className="title text-start">{record.cs_title}</div>
                          {Number(record.max_days) > 0 && Number(record.stageDuration) > Number(record.max_days) && (
                            <div className="description d-flex text-light">
                              <FontAwesomeIcon icon={faTriangleExclamation} className="me-1" style={{ color: "#fff" }} size="sm" />
                              Overdue by {record.stageOverdue} days
                            </div>
                          )}
                        </div>
                      </button>
                    ))}
                </div>
              </Col>
            </Row>
          </div>
          <div className="section-body">
            <div className="section-sidebar">
              <Accordion defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]} alwaysOpen>
                {(Number(deal.final_conversion) === 1 || Number(deal.real_estate_final) === 1) && Number(session.subscription_tier) > 2 && Number(session.booking_module) === 1 && session.layout_mode === "real-estate" && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">Unit Booking</RenderAccordionButton>
                      <button type="button" className="btn btn-light btn-outline border shadow-sm me-2" onClick={() => navigate(`/sales-chart/unit/${deal.deal_id}/${deal.prospect_id}`)}>
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body">
                        {booking.length ? (
                          <ListGroup>
                            {booking.map((record, index) => (
                              <ListGroup.Item key={index} className="d-flex justify-content-between">
                                <div>
                                  <div className="d-flex mb-2">
                                    <div className="d-flex justify-content-center" style={{ width: 30 }}>
                                      <FontAwesomeIcon icon={faBuilding} size="lg" className="me-2" />
                                    </div>
                                    {record.project_name}
                                  </div>
                                  <div className="d-flex mb-2">
                                    <div className="d-flex justify-content-center" style={{ width: 30 }}>
                                      <FontAwesomeIcon icon={faHouseBuilding} size="lg" className="me-2" />
                                    </div>

                                    {record.phase_name}
                                  </div>
                                  <div className="d-flex mb-2">
                                    <div className="d-flex justify-content-center" style={{ width: 30 }}>
                                      <FontAwesomeIcon icon={faHouseChimneyWindow} size="lg" className="me-2" />
                                    </div>
                                    {record.unit_title} {record.unit_type}
                                  </div>
                                  <div className="d-flex mb-2">
                                    <div className="d-flex justify-content-center" style={{ width: 30 }}>
                                      <FontAwesomeIcon icon={faHouse} size="lg" className="me-2" />
                                    </div>
                                    {record.unit_number}
                                  </div>
                                </div>
                                <div>
                                  <Button className="text-capitalize fw-bold text-light btn-sm" variant={setStatusColor(record.booking_status)} style={{ fontSize: 10 }}>
                                    {record.booking_status}
                                  </Button>
                                </div>
                              </ListGroup.Item>
                            ))}
                          </ListGroup>
                        ) : (
                          "None at the moment"
                        )}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                {session.layout_mode === "standard" && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">Product</RenderAccordionButton>
                      {Number(session.role_id) === 1 && (
                        <button type="button" className="btn btn-light btn-outline border shadow-sm me-2" onClick={setisAddProduct}>
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      )}
                    </div>

                    <Accordion.Collapse eventKey="0">
                      <div className="accordion-body">
                        {Number(productCount) > 0
                          ? dealProduct.map((record, index) => (
                              <Stack className="mb-2" direction="horizontal" key={index}>
                                <FontAwesomeIcon icon={faBox} size="lg" className="me-2" />
                                {record.product_title} - {record.total_qty}x <br /> {setCurrency(record.discount)}(Discount) {setCurrency(record.final_price)}(Total Price)
                                <FontAwesomeIcon className="ms-auto cursor-pointer" icon={faCircleXmark} size="lg" onClick={() => sendDeleteProduct(record)} />
                              </Stack>
                            ))
                          : "None at the moment"}
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}
                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="1">{t('DealDetailGL_Bar_Prospect')}</RenderAccordionButton>
                    {(setCheckValidUser() || setCheckValidAdminUser()) && (
                      <div className="btn-group shadow-sm">
                        <button type="button" className="btn btn-light border" onClick={setIsChangeProspect}>
                          <FontAwesomeIcon icon={faRightLeft} />
                        </button>
                        <button type="button" className="btn btn-light border" onClick={() =>setIsEditProspect(true)}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </div>
                    )}
                  </div>

                  <Accordion.Collapse eventKey="1">
                    <div className="accordion-body">
                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faUser} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_ProspectName')}
                        </div>
                        <span 
                          className="w-50 text-break text-end clickable" 
                          style={{cursor: 'pointer', textTransform: 'uppercase'}} 
                          onClick={
                            () => {
                              window.open(`${session.origin}/prospect/${(!deal.hq_prospect_id || deal.hq_prospect_id === '0') ? deal.prospect_id : session.company_id === '251' ? deal.hq_prospect_id : deal.prospect_id}`,'_blank')
                            }
                          }
                        >
                          {deal.prospect_name ? deal.prospect_name : "-"}
                          {/* <Link className="text-dark text-decoration-none" to={`/prospect/${deal.prospect_id}`}>
                            {deal.prospect_name ? deal.prospect_name : "-"}
                          </Link> */}
                        </span>
                      </div>

                      {deal &&
                        deal.custom_field_person > 0 &&
                        deal.custom_field_person_data.map((record, index) => (
                          <div className="d-flex p-2 w-100" key={index}>
                            <div className="d-flex w-75">
                              <div className="me-2 text-center" style={{ width: 15 }}>
                                <FontAwesomeIcon icon={faBallot} size="lg" />
                              </div>
                              {(record.cf_id == '5014' && vietnam.includes(session.company_id)) ? 'Monthly Income *' : record.cf_label}
                            </div>
                            {record.fd_value ? <span className="w-50 text-break text-end">{(record.cf_id == '5001' && record.fd_value == 'NRIC No.' && vietnam.includes(session.company_id)) ? 'Vietnam NRIC No.' : record.fd_value}</span> : <span className="w-50 text-break text-end">-</span>}
                            
                          </div>
                        ))}

                      {/* <div className="d-flex justify-content-between p-2 w-100">
                        <div className="d-flex w-50">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faBriefcase} size="lg" />
                          </div>
                          Designation
                        </div>
                        <span className="w-50 text-end">{deal.designation_title ? deal.designation_title : "-"}</span>
                      </div> */}

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faPhone} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_ContactNo')} 1 *
                        </div>
                        <span className="w-50 text-break text-end">{contactNumFormat(deal.contact1_prefix, deal.contact1)}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faPhone} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_ContactNo')} 2
                        </div>
                        <span className="w-50 text-break text-end">{contactNumFormat(deal.contact2_prefix, deal.contact2)}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faPhone} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_ContactNo')} 3
                        </div>
                        <span className="w-50 text-break text-end">{contactNumFormat(deal.contact3_prefix, deal.contact3)}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faPhone} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_ContactNo')} 4
                        </div>
                        <span className="w-50 text-break text-end">{contactNumFormat(deal.contact4_prefix, deal.contact4)}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_Email')} 1 *
                        </div>
                        <span className="w-50 text-break text-end">{deal.email1 ? deal.email1 : deal.prospect_email_count > 0 ? deal.prospect_email_info[0].email_title : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faEnvelope} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_Email')} 2
                        </div>
                        <span className="w-50 text-end text-wrap">{deal.email2 ? deal.email2 : deal.prospect_email_count > 1 ? deal.prospect_email_info[1].email_title : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_AddressLine')} 1 *
                        </div>
                        <span className="w-50 text-break text-end" style={{textTransform: 'uppercase'}}>{deal.address_line1 ? deal.address_line1 : deal.prospect_address ? deal.prospect_address : ""}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_AddressLine')} 2 *
                        </div>
                        <span className="w-50 text-break text-end" style={{textTransform: 'uppercase'}}>{deal.address_line2 ? deal.address_line2 : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_AddressLine')} 3
                        </div>
                        <span className="w-50 text-break text-end" style={{textTransform: 'uppercase'}}>{deal.address_line3 ? deal.address_line3 : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-50">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_PostalCode')} *
                        </div>
                        <span className="w-50 text-break text-end">{deal.postal_code ? deal.postal_code : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_City')} *
                        </div>
                        <span className="w-50 text-break text-end" style={{textTransform: 'uppercase'}}>{deal.city_title ? deal.city_title : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_State')} *
                        </div>
                        <span className="w-50 text-break text-end">{deal.state_title ? deal.state_title : "-"}</span>
                      </div>

                      <div className="d-flex p-2 w-100">
                        <div className="d-flex w-75">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faLocationDot} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Prospect_Country')} *
                        </div>
                        <span className="w-50 text-break text-end" style={{textTransform: 'uppercase'}}>{deal.country_title ? deal.country_title : "-"}</span>
                      </div>

                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="2">{t('DealDetailGL_Bar_Deal')}</RenderAccordionButton>
                    <button type="button" className="btn btn-light btn-outline border shadow-sm me-2" onClick={setIsEditDeal}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                  </div>

                  <Accordion.Collapse eventKey="2">
                    <div className="accordion-body">
                      <div className="d-flex justify-content-between p-2 w-100">
                        <div className="d-flex w-50">
                          <div className="me-2 text-center" style={{ width: 15 }}>
                            <FontAwesomeIcon icon={faHandshake} size="lg" />
                          </div>
                          {t('DealDetailGL_Bar_Deal_Source')}
                        </div>
                        <span className="w-50 text-end">{deal.ds_title ? deal.ds_title : "-"}</span>
                      </div>

                      {deal &&
                        deal.custom_field > 0 &&
                        deal.custom_field_data.map((record, index) => (
                          <div key={index}>
                            {record.cf_view_detail === "yes" && (
                              <div className="d-flex justify-content-between p-2 w-100">
                                <div className="d-flex w-50">
                                  <div className="me-2 text-center" style={{ width: 15 }}>
                                    <FontAwesomeIcon icon={faBallot} size="lg" />
                                  </div>
                                  {record.cf_label}
                                </div>
                                <span className="w-50 text-end">{record.fd_value ? record.fd_value : "-"}</span>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </Accordion.Collapse>
                </div>

                {Number(Number(session.subscription_tier) > 2) && (
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="3">{t('DealDetailGL_Bar_Analytic')}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="3">
                      <div className="accordion-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center w-50 cursor-pointer" onClick={setIsOpenDeal}>
                            <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                            <div className="ms-2">
                              <span className="op-text-bigger fw-bold">{vietnam.includes(session.company_id) ? new Intl.NumberFormat('en-US').format(analytics.open_amount) + " VND" : setCurrency(analytics.open_amount)}</span> <br />
                              <span className="op-text-medium">{analytics.total_open} {t('DealDetailGL_Bar_Analytic_Inbound')}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-50 cursor-pointer" onClick={setIsWonDeal}>
                            <FontAwesomeIcon icon={faChartLine} size="2xl" />
                            <div className="ms-2">
                              <span className="op-text-bigger fw-bold">{vietnam.includes(session.company_id) ? new Intl.NumberFormat('en-US').format(analytics.won_amount) + " VND": setCurrency(analytics.won_amount)}</span> <br />
                              <span className="op-text-medium">{analytics.total_won} {t('DealDetailGL_Bar_Analytic_Sales')}</span>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center pt-4">
                          <div className="d-flex align-items-center w-50 cursor-pointer" onClick={setIsLostDeal}>
                            <FontAwesomeIcon icon={faChartLineDown} size="2xl" />
                            <div className="ms-2">
                              <span className="op-text-bigger fw-bold">{vietnam.includes(session.company_id) ? new Intl.NumberFormat('en-US').format(analytics.lost_amount) + " VND" : setCurrency(analytics.lost_amount)}</span> <br />
                              <span className="op-text-medium">{analytics.total_lost} {t('DealDetailGL_Bar_Analytic_Dropped')}</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center w-50 cursor-pointer" onClick={setIsArchievedDeal}>
                            <FontAwesomeIcon icon={faChartBar} size="2xl" />
                            <div className="ms-2">
                              <span className="op-text-bigger fw-bold">{vietnam.includes(session.company_id) ? new Intl.NumberFormat('en-US').format(analytics.deleted_amount) + " VND": setCurrency(analytics.deleted_amount)}</span> <br />
                              <span className="op-text-medium">{analytics.total_deleted} {t('DealDetailGL_Bar_Analytic_Archived')}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                )}

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="4">{t('DealDetailGL_Bar_Contributor')}</RenderAccordionButton>
                    {/* {!vietnam.includes(session.company_id) && <button type="button" className="btn btn-light btn-outline border shadow-sm me-2" onClick={setIsAddContributor}>
                       <FontAwesomeIcon icon={faPlus} />
                    </button>} */}
                  </div>

                  <Accordion.Collapse eventKey="4">
                    <div className="accordion-body">
                      {Number(deal.contributor_count) > 0
                        ? deal.contributor.map((record, index) => (
                            <Stack className="mb-2" direction="horizontal" key={index}>
                              <FontAwesomeIcon icon={faUserTie} size="lg" className="me-2" />
                              <span>{record.user_name}</span>
                              {/* <FontAwesomeIcon className="ms-auto cursor-pointer" icon={faCircleXmark} size="lg" onClick={() => sendDeleteContributor(record)} /> */}
                            </Stack>
                          ))
                        : t('DealDetailGL_Bar_Contributor_None')}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="6">{t('DealDetailGL_Bar_Referral')}</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="6">
                    <div className="accordion-body">
                      {introducerList.length > 0 ? introducerList.map((data, index) => (
                        <Stack direction="horizontal" className="mb-2" key={index}>
                            <FontAwesomeIcon icon={faUser} size="lg" className="me-2"/>
                            <div>{data.introducer_name}</div>
                        </Stack>
                      )) : t('DealDetailGL_Bar_Referral_None')}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="5">{t('DealDetailGL_Bar_Form')}</RenderAccordionButton>
                    <button type="button" className="btn btn-light btn-outline border shadow-sm me-2" onClick={setisAddForm}>
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>

                  <Accordion.Collapse eventKey="5">
                    <div className="accordion-body">
                      {Number(deal.totalFormsCreated) > 0
                        ? deal.custom_form_created.map((record, index) => (
                            <Stack className="mb-2" direction="horizontal" key={index}>
                              <FontAwesomeIcon icon={faBrowser} size="lg" className="me-2" />
                              <a className="text-dark text-decoration-none" onClick={() => openForm(record)}>
                                {record.form_deal_title}
                              </a>
                              <FontAwesomeIcon className="ms-auto cursor-pointer" onClick={() => sendDeleteForm(record)} icon={faCircleXmark} size="lg" />
                            </Stack>
                          ))
                        : t('DealDetailGL_Bar_Form_None')}
                    </div>
                  </Accordion.Collapse>
                </div>

                <div className="sidebar-accordion">
                  <div className="d-flex justify-content-between align-items-center">
                    <RenderAccordionButton eventKey="5">Meetup</RenderAccordionButton>
                  </div>

                  <Accordion.Collapse eventKey="5">
                    <div className="accordion-body">
                      {meetupLink.status === 0 ? (
                        <Button variant="link" className="text-dark text-decoration-none m-0 p-0" href={meetupLink.meetup_link} target="_blank">
                          Meetup Link
                        </Button>
                      ) : (
                        t("DealDetails_none_at_the_moment")
                      )}
                    </div>
                  </Accordion.Collapse>
                </div>
              </Accordion>
            </div>
            <div className="section-content position-relative" ref={containerRef}>
              <Container fluid className="p-4">
                <Card>
                  <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                    <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                      <Nav.Item>
                        <Nav.Link eventKey="notes">
                          <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                          {t('DealDetailGL_Notes')}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="activity">
                          <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                          {t('DealDetailGL_Activity')}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="files">
                          <FontAwesomeIcon icon={faPaperclipVertical} className="me-2" size="lg" />
                          {t('DealDetailGL_Files')}
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item>
                        <Nav.Link eventKey="messages">
                          <FontAwesomeIcon icon={faEnvelope} className="me-2" size="lg" />
                          {t('DealDetailGL_Messages')}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                  </Card.Header>
                  <RenderActionTab tab={activeTab} active={isActiveTab} userListNotes={userNotes} activityUser={userActivity} isSubmitting={isSubmitting}/>
                </Card>

                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                  <div className="sidebar-accordion">
                    <div className="d-flex justify-content-between align-items-center">
                      <RenderAccordionButton eventKey="0">{t('DealDetailGL_Timeline')}</RenderAccordionButton>
                    </div>

                    <Accordion.Collapse eventKey="0" style={{ width: width }}>
                      <div className="accordion-body">
                        <VerticalTimeline layout="1-column-left">
                          {timeline && timeline.length
                            ? timeline.map((record, index) => (
                                <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                  <Stack direction="horizontal" gap={1} style={{ alignItems: "normal" }}>
                                    <div style={{ width: setWidthTimeline(record) }}>
                                      <div className={`op-text-bigger fw-bold`}>
                                        <RenderTimelineHeader record={record} />
                                      </div>
                                      <div style={{ fontSize: 11, wordWrap: "break-word" }}>
                                        <RenderTimelineDescription record={record} />
                                      </div>
                                      <RenderTimelineDocument record={record} />
                                    </div>

                                    {/* {record.record_type === "Activity" || record.record_type === "CustomActivity" ? (
                                      <div className="mt-1" style={{ width: "5%" }}>
                                        <div className="w-100 d-flex justify-content-center float-end" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                          {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                        </div>
                                      </div>
                                    ) : null} */}

                                    {setTimelineMAD(record) ? (
                                        <div className="mt-1 me-1" style={{ width: "3%" }}>
                                          <div className="w-100 d-flex justify-content-center float-end" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                            {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                          </div>
                                        </div>
                                    ) : null}

                                    {record.record_type === "Activity" && Number(record.record_activity_type_id) === 2 && Number(meetupLink.status) === 0 && Number(record.meetup_cancel.status) === 0 && Number(record.meetup_cancel.record.cancel) === 0 && record.record_activity_mad === "no" ? (
                                        <div className="mt-1 me-2">
                                          <div
                                            className="w-100 d-flex justify-content-center"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setActivityData(record);
                                              setIsCancelMeetup(true);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faCircleXmark} size="2xl" style={{ color: "#dc3545" }} />
                                          </div>
                                        </div>
                                      ) : null}

                                    {setTimelineAction(record) ? (
                                      <div>
                                        <div className="float-end">
                                          <RenderTimelineAction record={record} />
                                        </div>
                                      </div>
                                    ) : null}
                                  </Stack>
                                </VerticalTimelineElement>
                              ))
                            : null}
                          <VerticalTimelineElement date={moment(new Date(deal.deal_date_time_create)).format("lll")} iconStyle={{ background: "#fff" }} icon={<FontAwesomeIcon icon={faFloppyDisk} />}>
                            <div className="op-text-bigger fw-bold">Deal created</div>
                          </VerticalTimelineElement>
                        </VerticalTimeline>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </Accordion>
              </Container>
            </div>
          </div>
          
          {Number(session.company_id) === 106 && <RenderFloatingButton />}

          <Modal show={stageLock} onHide={setStageLock} size="lg">
              <Modal.Header closeButton>
                <Modal.Title as={'h6'}>Stage locked. Please fulfill these conditions to proceed</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{margin: "auto", width: "60%", padding: "10px"}}>
                  {
                    dealWonList.map(rec =>{
                      return <h6> <FontAwesomeIcon icon={faShieldExclamation}/> {rec.title}</h6>;
                    })
                  }
                </div>
              </Modal.Body>
          </Modal>
          
          <Modal show={isWon} onHide={setIsWon}>
            
            { (deal.won_cs != 0 && deal.won_cs != deal.cs_id) ? (<>
                    <Modal.Header closeButton>
                        <Modal.Title as={"h6"}>Oops, you can't convert this deal yet.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-5 mt-4 text-center">
                            <h6><FontAwesomeIcon icon={faShieldExclamation} /> {deal.won_cs_message ? deal.won_cs_message : 'vui lòng chuyển sang đặt trước'}</h6>
                        </Form.Group>
                    </Modal.Body>
                </>):( Number(deal.pipeline_id) == 623 || Number(deal.pipeline_id) == 51 || Number(deal.pipeline_id) == 107 || Number(deal.pipeline_id) == 119 || Number(deal.pipeline_id) == 136 || Number(deal.pipeline_id) == 717 || Number(deal.pipeline_id) == 155 || Number(deal.pipeline_id) == 298 || Number(deal.pipeline_id) == 513) ? (
                <Formik
                    validationSchema={sendDealWonValidationSchema}
                    onSubmit={sendDealWon}
                    initialValues={
                        {
                            company_id: "",
                            cs_id: deal.cs_id,
                            prosID: (deal.hq_prospect_id && deal.hq_prospect_id != 0) ? deal.hq_prospect_id : deal.prospect_id,
                            type: "hotLead"
                        }
                    }
                >
                    {({ handleSubmit, handleChange, errors, touched, setFieldValue }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title as={"h6"}>Choose company to proceed</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group>
                                    <FormSelect
                                        placeholder={"Company"}
                                        options={CompanyRemarkList}
                                        isInvalid={errors.company_id && touched.company_id}
                                        isSearchable={true}
                                        isClearable={true}
                                        onChange={(e) => setFieldValue('company_id', e ? e.value:"")}
                                    />
                                    {errors.company_id && touched.company_id && <div className="op-error-message">{errors.company_id}</div>}
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Formik
                    onSubmit={sendDealWon}
                    initialValues={{
                        remark: "",
                        deal_archieve: "won",
                        type: "noHotLead"
                    }}
                >
                    {({ handleSubmit, handleChange, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title as={"h6"}>{t('DealDetailGL_DealWon_Modal_Title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                            <Form.Control as="textarea" placeholder={t('DealDetailGL_DealWon_Modal_RemarkPlaceholder')} rows={3} onChange={handleChange("remark")} />
                            <Form.Label>{t('DealDetailGL_DealWon_Modal_Remark')}</Form.Label>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn op-button op-primary-color text-light shadow">
                            {t('DealDetailGL_DealWon_Modal_Footer')}
                            </button>
                        </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            )}
          </Modal>

          <Modal show={isLost} onHide={setIsLost} >
            <Formik
              onSubmit={sendDealLost}
              initialValues={{
                remark: "",
                deal_archieve: "lost",
                lostReasonID: "",
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_DealLost_Modal_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <FormSelect options={lostReason} placeholder={t('DealDetailGL_DealLost_Modal_ReasonPlaceholder')} onChange={(info) => handleChange("lostReasonID")(info.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t('DealDetailGL_DealLost_Modal_RemarkPlaceholder')} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t('DealDetailGL_DealLost_Modal_Remark')}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_DealLost_Modal_Footer')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isDeleteDeal} onHide={setIsDeleteDeal}>
            <Formik
              onSubmit={sendDeleteDeal}
              initialValues={{
                remark: "",
                deal_archieve: "deleted",
              }}
            >
              {({ handleSubmit, handleChange }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_DeleteDeal_Modal_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder={t('DealDetailGL_DeleteDeal_Modal_Remark_Placeholder')} rows={3} onChange={handleChange("remark")} />
                      <Form.Label>{t('DealDetailGL_DeleteDeal_Modal_Remark')}</Form.Label>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_DeleteDeal_Modal_Footer')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isChangeProspect} onHide={setIsChangeProspect}>
            <Formik
              onSubmit={sendChangeProspect}
              validationSchema={changeProspectSchema}
              initialValues={{
                prospect: "",
              }}
            >
              {({ handleSubmit, handleChange, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_Modal_ChangeProspect_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <FormSelectCreatable
                        placeholder={t('DealDetailGL_Modal_ChangeProspect_placeholder_findProspect')}
                        loadOptions={getExistingProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect && touched.prospect}
                        width="100%"
                        onChange={(info) => {
                          handleChange({
                            target: { name: "prospect", value: info.value },
                          });
                        }}
                      />
                      {errors.prospect && touched.prospect && <div className="op-error-message">{errors.prospect}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_Modal_ChangeProspect_Footer')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          {isEditProspect && !vietnam.includes(session.company_id) && <EditPersonGl 
            prospect_id={(deal.hq_prospect_id && Number(deal.hq_prospect_id) !== 0) ? deal.hq_prospect_id : deal.prospect_id}
            pipeline_id={deal.pipeline_id}
            closeModal={setIsEditProspect}
            onSuccess={()=>{getDeals(); setIsEditProspect(false);}}
          />}

          {isEditProspect && vietnam.includes(session.company_id) && <EditPersonGlVN 
            prospect_id={(deal.hq_prospect_id && Number(deal.hq_prospect_id) !== 0) ? deal.hq_prospect_id : deal.prospect_id}
            pipeline_id={deal.pipeline_id}
            closeModal={setIsEditProspect}
            onSuccess={()=>{getDeals(); setIsEditProspect(false);}}
          />}

          {isReserve &&
            <EditReserve
              isOpen={setIsReserve}
              dataSet={deal}
              onSuccess={() => {getDeals()}}
            />
          }

          <Modal show={isEditDeal} onHide={setIsEditDeal}>
            <Formik
              onSubmit={sendEditDeal}
              validationSchema={editDealSchema}
              initialValues={{
                dealSource: deal && Number(deal.ds_id) > 0 ? { label: deal.ds_title, value: deal.ds_id } : "",
                ds_id: deal && Number(deal.ds_id) > 0 ? deal.ds_id : "",
                cf_deal:
                  deal && deal.custom_field > 0
                    ? deal.custom_field_data.map((record) => ({
                        cf_id: record.cf_id,
                        name: record.cf_id,
                        fd_values: record.ctf_title === "Checkbox" ? record.fd_value.split(",") : record.fd_value,
                        cf_label: record.cf_label,
                      }))
                    : [],
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_EditDealDetail_Modal_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_EditDealDetail_Modal_Source')} *</Form.Label>
                      <FormSelect options={dealSource} valueDefault={values.dealSource} onChange={(e) => handleChange("ds_id")(e.value)} />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>

                    {deal &&
                      deal.custom_field > 0 &&
                      deal.custom_field_data.map((record, index) => (
                        <FormCustomField
                          key={record.cf_id}
                          id={record.cf_id}
                          label={record.cf_label}
                          options={record.cf_value}
                          type={record.ctf_title}
                          name={record.cf_id}
                          placeholder=""
                          firstOptions={false}
                          valueDefault={false}
                          isClearable={true}
                          value={values.cf_deal[index].fd_values}
                          onChange={(info) => {
                            const updatedCustomFields = [...values.cf_deal];
                            if (record.ctf_title === "Select") {
                              updatedCustomFields[index].fd_values = info.value;
                            } else if (record.ctf_title === "Radio") {
                              const { value, checked } = info.target;
                              updatedCustomFields[index].fd_values = checked ? value : "";
                            } else if (record.ctf_title === "Checkbox") {
                              const { value, checked } = info.target;
                              if (checked) {
                                updatedCustomFields[index].fd_values.push(value);
                              } else {
                                const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                                if (indexToRemove !== -1) {
                                  updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                                }
                              }
                            } else if (record.ctf_title === "Date") {
                              updatedCustomFields[index].fd_values = info;
                            } else {
                              updatedCustomFields[index].fd_values = info.target.value;
                            }

                            setFieldValue("cf_deal", updatedCustomFields);
                          }}
                        />
                      ))}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_EditDealDetail_Modal_Footer')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddContributor} onHide={setIsAddContributor}>
            <Formik
              onSubmit={sendAddContributor}
              validationSchema={addContributorSchema}
              initialValues={{
                user_id: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Add Contributor</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>User *</Form.Label>
                      <FormSelect options={user} onChange={(e) => handleChange("user_id")(e.value)} />
                      {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Save
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isAddForm} onHide={setisAddForm}>
            <Formik
              onSubmit={sendAddForm}
              validationSchema={addFormSchema}
              initialValues={{
                form_id: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_Bar_Form_Modal_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_Bar_Form_Modal_Form')} *</Form.Label>
                      <FormSelect options={form} onChange={(e) => handleChange("form_id")(e.value)} />
                      {errors.form_id && touched.form_id && <div className="op-error-message">{errors.form_id}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_Bar_Form_Modal_Save')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
            <Formik onSubmit={sendEditActivity} validationSchema={editActivitySchema} initialValues={initEditActivity}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Activity</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder="Activity title" value={values.activity_title} isInvalid={errors.activity_title && touched.activity_title} onChange={handleChange("activity_title")} />
                          {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "5%" }}></div>
                      <Form.Group>
                        <ButtonGroup className="activity-tab">
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                            <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                            Call
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                            <FontAwesomeIcon icon={faUsers} className="me-1" />
                            Meeting
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                            <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                            Task
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                            <FontAwesomeIcon icon={faFlag} className="me-1" />
                            Deadline
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                            Email
                          </ToggleButton>
                        </ButtonGroup>
                      </Form.Group>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Row>
                          <Form.Group as={Col}>
                            <Form.Control type="date" value={values.activity_date} onChange={handleChange("activity_date")} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect options={values.activity_type === "2" ? meetingTimingsList : timings} valueDefault={values.activity_type === "2" ? meetingTimingsList.find((record) => record.value === values.activity_time) : timings.find((record) => record.value === values.activity_time)} placeholder="Select time" onChange={(e) => handleChange("activity_time")(e.value)} />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <FormSelect options={values.activity_type === "2" ? meetingDurationList : durationList} valueDefault={values.activity_type === "2" ? meetingDurationList.find((record) => record.value === values.activity_duration) : durationList.find((record) => record.value === values.activity_duration)} placeholder="Select duration" onChange={(e) => handleChange("activity_duration")(e.value)} />
                          </Form.Group>
                        </Row>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faCircleUser} size="lg" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group>
                          <FormSelect placeholder="Select user" options={userActivity} valueDefault={userActivity.find((record) => record.value === values.activity_user)} onChange={(e) => handleChange("activity_user")(e.value)} />
                          {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                        <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                      </div>
                      <div style={{ width: "95%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_notes} onChangeValue={(value) => {handleChange('activity_notes')(value)}}/>
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Form.Check
                      type="switch"
                      label="Mark as done"
                      className="d-flex justify-content-center align-items-center me-3"
                      checked={values.mad}
                      onChange={(e) => {
                        handleChange({
                          target: { name: "mad", value: e.target.checked },
                        });
                      }}
                    />
                    <button type="submit" className="btn op-button op-primary-color text-light shadow" disabled={isSubmitting}>
                      Save Changes
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditNote} onHide={setisEditNote} size="lg">
            <Formik onSubmit={sendEditNotes} validationSchema={editNotesSchema} initialValues={initEditNotes}>
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Notes</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <NoteEditor value={values.notes} onChangeValue={(value) => {handleChange('notes')(value)}} height={300}/>
                      {errors.notes && touched.notes && <div className="op-error-message">{errors.notes}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Save Changes
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isLeadScore} onHide={setIsLeadScore} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>Rubric Score ({newLdScoreTotal})</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {newLeadScoreList ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={newLeadScoreList} columnDefs={newLeadScoreColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message="No record at the moment" description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isEditDealInfo} onHide={setIsEditDealInfo}>
            <Formik
              onSubmit={sendEditDealInfo}
              validationSchema={editDealInfoSchema}
              initialValues={{
                deal_title: deal.deal_title || "",
                deal_price: deal.deal_value || 0,
                deal_expected_close: deal && deal.deal_expected_close_date !== "0000-00-00" ? moment(new Date(deal.deal_expected_close_date)).format("YYYY-MM-DD") : "",
                deal_added: moment(new Date(deal.deal_date_time_create)).format("YYYY-MM-DD"),
                deal_close: deal.deal_archieve === "won" ? moment(new Date(deal.deal_archieve_won_log)).format("YYYY-MM-DD") : deal.deal_archieve === "lost" ? moment(new Date(deal.deal_archieve_lost_log)).format("YYYY-MM-DD") : "",
                deal_archieve: deal.deal_archieve,
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('DealDetailGL_EditDeal_Modal_Title')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_EditDeal_Modal_DealTitle')} *</Form.Label>
                      <Form.Control type="text" value={values.deal_title} onChange={(e) => handleChange("deal_title")(e.target.value)} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_EditDeal_Modal_DealPrice')}</Form.Label>
                      <Form.Control
                        type="text"
                        value={values.deal_price}
                        isInvalid={errors.deal_price && touched.deal_price}
                        onChange={(e) => {
                          // const re = /^[0-9\b]+$/;
                          // if (e.target.value === "" || re.test(e.target.value)) {
                            handleChange("deal_price")(e.target.value);
                          // }
                        }}
                      />
                      {errors.deal_price && touched.deal_price && <div className="op-error-message">{errors.deal_price}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_EditDeal_Modal_DealExpectedClose')}</Form.Label>
                      <FormDate value={values.deal_expected_close ? moment(new Date(values.deal_expected_close)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_expected_close")(date)} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t('DealDetailGL_EditDeal_Modal_DealAdded')} *</Form.Label>
                      <FormDate value={values.deal_added ? moment(new Date(values.deal_added)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_added")(date)} />
                      {errors.deal_added && touched.deal_added && <div className="op-error-message">{errors.deal_added}</div>}
                    </Form.Group>

                    {(deal.deal_archieve === "won" || deal.deal_archieve === "lost") && (
                      <Form.Group className="mb-3">
                        <Form.Label>{t('DealDetailGL_EditDeal_Modal_DealClosed')} *</Form.Label>
                        <FormDate value={values.deal_close ? moment(new Date(values.deal_close)).format("DD/MM/YYYY") : ""} onChange={(date) => handleChange("deal_close")(date)} />
                        {errors.deal_close && touched.deal_close && <div className="op-error-message">{errors.deal_close}</div>}
                      </Form.Group>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t('DealDetailGL_EditDeal_Modal_SaveChanges')}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isOpenDeal} onHide={setIsOpenDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t('DealDetailGL_Bar_Analytic_Inbound_Modal_Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_open && analytics.record_open.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_open} columnDefs={openDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t('DealDetailGL_Bar_Analytic_NoRecord')} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isWonDeal} onHide={setIsWonDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t('DealDetailGL_Bar_Analytic_Won_Modal_Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_won && analytics.record_won.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_won} columnDefs={wonDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t('DealDetailGL_Bar_Analytic_NoRecord')} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isLostDeal} onHide={setIsLostDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t('DealDetailGL_Bar_Analytic_Lost_Modal_Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_lost && analytics.record_lost.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_lost} columnDefs={lostDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t('DealDetailGL_Bar_Analytic_NoRecord')} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isArchievedDeal} onHide={setIsArchievedDeal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title as={"h6"}>{t('DealDetailGL_Bar_Analytic_Archieve_Modal_Title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
              {analytics.record_deleted && analytics.record_deleted.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact rowData={analytics.record_deleted} columnDefs={archievedDealColumn} getRowId={rowId} pagination={true} paginationPageSize={100} paginationPageSizeSelector={false} suppressRowClickSelection={true} animateRows={true} />
                  </div>
                </div>
              ) : (
                <NoRecord message={t('DealDetailGL_Bar_Analytic_NoRecord')} description="" width={300} height={250} mt={false} />
              )}
            </Modal.Body>
          </Modal>

          <Modal show={isAddProduct} onHide={() => setisAddProduct(false)} className="product-modal" size="xl">
            <ProductComponent
              dealProductList={dealProduct}
              productList={productList}
              getuser={currentUser}
              onSubmit={() => {
                getProduct();
                getAllProduct();
                getTimeline();
                setisAddProduct(false);
              }}
            />
          </Modal>

          <Modal show={isCallLead} onHide={setIsCallLead}>
            <Modal.Body>
              <div className="d-flex flex-column-reverse positon-relative w-100">
                <CloseButton className="ms-auto" onClick={() => setIsCallLead(false)} />
              </div>
              <div className="call-lead">
                <div className="call-pulse">
                  <FontAwesomeIcon icon={faPhone} beatFade size="3x" style={{ color: "#fff" }} />
                </div>
              </div>
              <h5 className="text-center call-text">Calling {deal.prospect_name}</h5>
            </Modal.Body>
          </Modal>

          <Modal show={isVoiceNote} onHide={setIsVoiceNote} className="product-modal" size="lg">
            <AudioNotes
              getDealId={deal_id}
              onSubmit={() => {
                setIsVoiceNote(false)
                getTimeline()
              }}
            />
          </Modal>

          <Modal show={isCancelMeetup} onHide={setIsCancelMeetup}>
            <Formik
              onSubmit={sendCancelMeetup}
              validationSchema={cancelMeetupSchema}
              initialValues={{
                remark: "",
                reason: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Appointment Cancellation Reason</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="px-4">
                    <Form.Group className="mb-3">
                      <Form.Label>Cancel Reason</Form.Label>
                      <FormSelect name="reason" options={cancelMeetupReasonList} onChange={(e) => setFieldValue("reason", e.label)} />
                      {errors.reason && touched.reason && <div className="op-error-message">{errors.reason}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Remark</Form.Label>
                      <Form.Control as="textarea" value={values.organization_title} name="organization_title" onChange={handleChange("remark")} style={{ height: 100, resize: "none" }} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("DealDetails_save")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}
    </Container>
  );
};

export default DealDetailsGL;
