import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Modal} from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
const RedemptionVendor = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [vendorData, setVendorData] = useState({});
  const [isNewVendor, setIsNewVendor] = useState(false);
  const [isEditVendor, setIsEditVendor] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryList2, setCategoryList2] = useState([]);
  const [categoryData, setCategoryData] = useState({ label: "All Category", value: "" });
  const [availableDaysList] = useState([
    { label: "30 Days", value: 30 },
    { label: "45 Days", value: 45 },
    { label: "60 Days", value: 60 },
    { label: "90 Days", value: 90 },
  ]);

  const [locationList] = useState([
    { label: "Johor", value: "Johor" },
    { label: "Kedah", value: "Kedah" },
    { label: "Kelantan", value: "Kelantan" },
    { label: "Negeri Sembilan", value: "Negeri Sembilan" },
    { label: "Pahang", value: "Pahang" },
    { label: "Penang", value: "Penang" },
    { label: "Perak", value: "Perak" },
    { label: "Perlis", value: "Perlis" },
    { label: "Sabah", value: "Sabah" },
    { label: "Sarawak", value: "Sarawak" },
    { label: "Selangor", value: "Selangor" },
    { label: "Terengganu", value: "Terengganu" },
    { label: "Wilayah Persekutuan", value: "Wilayah Persekutuan" },
  ]);

  // SCHEMA FUNCTION ====================================================

  const newVendorSchema = yup.object().shape({
    vendor_name: yup.string().required("This field is required"),
    category_id: yup.string().required("This field is required"),
    locations: yup.string().required("This field is required"),
    successful_template_id: yup.string().required("This field is required"),
    redemption_available_day: yup.string().required("This field is required"),
  });

  const editVendorSchema = yup.object().shape({
    vendor_name: yup.string().required("This field is required"),
    category_id: yup.string().required("This field is required"),
    locations: yup.string().required("This field is required"),
    successful_template_id: yup.string().required("This field is required"),
    redemption_available_day: yup.string().required("This field is required"),
  });

  // GET FUNCTION =======================================================

  const getVendor = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_vendor.php", {
        params: {
          task: "VendorList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      console.log(data);
      if (Number(data.status) === 0) {
        setVendorList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCategory = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "CategoryList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.category_name,
          value: record.category_id,
        }));

        recordOptions.unshift({
          label: "All Category",
          value: "",
        });

        const recordOptions2 = data.record.map((record) => ({
          label: record.category_name,
          value: record.category_id,
        }));

        setCategoryList(recordOptions);
        setCategoryList2(recordOptions2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ======================================================

  const sendNewVendor = async (values) => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_vendor.php", {
        params: {
          task: "NewVendor",
          vendor_name: values.vendor_name,
          category_id: values.category_id,
          locations: values.locations,
          term_condition_url: "",
          privacy_policy_url: "",
          successful_template_id: values.successful_template_id,
          redemption_available_day: values.redemption_available_day,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getVendor();
        setIsNewVendor(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created a new vendor",
          timer: 2000,
        });
      } else {
        setIsNewVendor(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEditVendor = async (values) => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_vendor.php", {
        params: {
          task: "EditVendor",
          vendor_id: vendorData.vendor_id,
          vendor_name: values.vendor_name,
          category_id: values.category_id,
          locations: values.locations,
          term_condition_url: "",
          privacy_policy_url: "",
          successful_template_id: values.successful_template_id,
          redemption_available_day: values.redemption_available_day,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        getVendor();
        setIsEditVendor(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated the vendor",
          timer: 2000,
        });
      } else {
        setIsEditVendor(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // AG GRID ============================================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Vendor Name",
      wrapText: true,
      autoHeight: true,
      cellStyle: { lineHeight: 1.5 },
      field: "vendor_name",
    },
    {
      headerName: "Category Name",
      field: "category_name",
      wrapText: true,
      autoHeight: true,
      cellStyle: { lineHeight: 1.5 },
    },
    {
      headerName: "Redemption Available Days",
      field: "redemption_available_day",
      wrapText: true,
      autoHeight: true,
      cellStyle: { lineHeight: 1.5 },
      cellRenderer: (params) => {
        return `${params.data.redemption_available_day} days`;
      },
    },
    {
      headerName: "Location Name",
      field: "locations",
      wrapText: true,
      autoHeight: true,
      cellStyle: { lineHeight: 1.5 },
    },
    {
      headerName: "Successful Email Template ID (MTARGET)",
      field: "successful_template_id",
      wrapText: true,
      autoHeight: true,
      cellStyle: { lineHeight: 1.5 },
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      maxWidth: 70,
      cellRenderer: (params) => {
        return (
          <div className="d-flex flex-row-reverse w-100">
            <button
              className="op-label bg-primary text-light"
              onClick={() => {
                setVendorData(params.data);
                setIsEditVendor(true);
              }}
            >
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.vendor_id.toString();
  }, []);

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getVendor();
          await getCategory();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col xxl={12} className="mb-3">
              <Stack direction="horizontal" gap={2}>
                <Button variant="" className="btn op-primary-color text-light" onClick={setIsNewVendor}>
                  New Vendor
                </Button>
                <Stack className="ms-auto p-0" direction="horizontal" gap={3}>
                  <p className="ms-auto mb-0">{vendorList.length === 0 ? "No record" : vendorList.length === 1 ? "1 record" : `${vendorList.length} records`}</p>
                  <FormSelect width="250px" options={categoryList} valueDefault={categoryData} onChange={(e) => setCategoryData(e)} />
                </Stack>
              </Stack>
            </Col>
            <Col xxl={12}>
              {vendorList.length > 0 ? (
                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact
                      ref={gridRef}
                      rowSelection="multiple"
                      rowData={categoryData.value !== "" ? vendorList.filter((record) => Number(record.category_id) === Number(categoryData.value)) : vendorList}
                      columnDefs={gridColumn}
                      getRowId={gridRowId}
                      defaultColDef={{ flex: 1 }}
                      rowHeight={50}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      suppressRowClickSelection={true}
                      animateRows={true}
                    />
                  </div>
                </div>
              ) : (
                <NoRecord message={"No record for the moment"} description="" width={300} height={250} mt={false} />
              )}
            </Col>
          </Row>
        </div>
      )}

      <Modal show={isNewVendor} onHide={setIsNewVendor} size="lg">
        <Formik
          validationSchema={newVendorSchema}
          onSubmit={sendNewVendor}
          initialValues={{
            vendor_name: "",
            category_id: "",
            locations: "",
            successful_template_id: "",
            redemption_available_day: "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Vendor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Vendor Name</Form.Label>
                  <Form.Control name="vendor_name" type="text" value={values.vendor_name} isInvalid={errors.vendor_name && touched.vendor_name} onChange={handleChange("vendor_name")} />
                  {errors.vendor_name && touched.vendor_name && <div className="op-error-message">{errors.vendor_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <FormSelect name="category_id" options={categoryList2} onChange={(e) => setFieldValue("category_id", e.value)} />
                  {errors.category_id && touched.category_id && <div className="op-error-message">{errors.category_id}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <FormSelect
                    name="locations"
                    isMulti={true}
                    options={locationList}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue("locations", e.map((record) => record.value).toString());
                      } else {
                        setFieldValue("locations", "");
                      }
                    }}
                  />
                  {errors.locations && touched.locations && <div className="op-error-message">{errors.locations}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Redemption Available Days</Form.Label>
                  <FormSelect name="redemption_available_day" options={availableDaysList} onChange={(e) => setFieldValue("redemption_available_day", e.value)} />
                  {errors.redemption_available_day && touched.redemption_available_day && <div className="op-error-message">{errors.redemption_available_day}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Successful Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="successful_template_id" type="text" value={values.successful_template_id} isInvalid={errors.successful_template_id && touched.successful_template_id} onChange={handleChange("successful_template_id")} />
                  {errors.successful_template_id && touched.successful_template_id && <div className="op-error-message">{errors.successful_template_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEditVendor} onHide={setIsEditVendor} size="lg">
        <Formik
          validationSchema={editVendorSchema}
          onSubmit={sendEditVendor}
          initialValues={{
            vendor_name: vendorData.vendor_name,
            category_id: vendorData.category_id,
            category_value: vendorData.category_id ? categoryList2.find((record) => Number(record.value) === Number(vendorData.category_id)) : "",
            locations: vendorData.locations,
            locations_value: vendorData.locations ? vendorData.locations.split(",").map((record) => ({ label: record, value: record })) : [],
            successful_template_id: vendorData.successful_template_id,
            redemption_available_day: vendorData.redemption_available_day,
            redemption_available_day_value: vendorData.redemption_available_day ? { label: `${vendorData.redemption_available_day} Days`, value: vendorData.redemption_available_day } : "",
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit Vendor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Vendor Name</Form.Label>
                  <Form.Control name="vendor_name" type="text" value={values.vendor_name} isInvalid={errors.vendor_name && touched.vendor_name} onChange={handleChange("vendor_name")} />
                  {errors.vendor_name && touched.vendor_name && <div className="op-error-message">{errors.vendor_name}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Category</Form.Label>
                  <FormSelect
                    name="category_id"
                    valueDefault={values.category_value}
                    options={categoryList2}
                    onChange={(e) => {
                      setFieldValue("category_id", e.value);
                      setFieldValue("category_value", e);
                    }}
                  />
                  {errors.category_id && touched.category_id && <div className="op-error-message">{errors.category_id}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <FormSelect
                    name="locations"
                    isMulti={true}
                    valueDefault={values.locations_value}
                    options={locationList}
                    onChange={(e) => {
                      if (e) {
                        setFieldValue("locations", e.map((record) => record.value).toString());
                        setFieldValue("locations_value", e);
                      } else {
                        setFieldValue("locations", "");
                        setFieldValue("locations_value", []);
                      }
                    }}
                  />
                  {errors.locations && touched.locations && <div className="op-error-message">{errors.locations}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Redemption Available Days</Form.Label>
                  <FormSelect
                    name="redemption_available_day"
                    valueDefault={values.redemption_available_day_value}
                    options={availableDaysList}
                    onChange={(e) => {
                      setFieldValue("redemption_available_day", e.value);
                      setFieldValue("redemption_available_day_value", e);
                    }}
                  />
                  {errors.redemption_available_day && touched.redemption_available_day && <div className="op-error-message">{errors.redemption_available_day}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Successful Email Template ID (MTARGET)</Form.Label>
                  <Form.Control name="successful_template_id" type="text" value={values.successful_template_id} isInvalid={errors.successful_template_id && touched.successful_template_id} onChange={handleChange("successful_template_id")} />
                  {errors.successful_template_id && touched.successful_template_id && <div className="op-error-message">{errors.successful_template_id}</div>}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default RedemptionVendor;
