import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import { useTranslation } from "react-i18next";

const PipelineStageSetting = ({ pipeline_id }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingPipelineStage_HeaderName1'),
            field: "cs_title",
            filter: true,
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingPipelineStage_HeaderName2'),
            field: "cs_index",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingPipelineStage_HeaderName3'),
            field: "cs_probability",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingPipelineStage_HeaderName4'),
            field: "isactive",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingPipelineStage_HeaderName5'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.cs_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data.cs_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData =() => {
        
        axios.get('ws/ws_pipeline.php', {
            params: {
                task: "getStages",
                pipeid: pipeline_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {

            let data = res.data;

            if (data.status === 0) {
                const filteredRows = data.record;

                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(true);
                setIsLoading(false);
            }

        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(true);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        cs_title: yup.string().required(t('Setting_requiredFd')),
    });

    const sendAdd = (values) => {
        
        axios.get('ws/ws_pipeline.php', {
            params: {
                task: 1,
                company: session.company_id,
                title: values.cs_title,
                probability: values.cs_probability,
                index: values.cs_index,
                inactive: values.cs_status,
                pipeid:pipeline_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Add'),
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleEdit = (id) => {
        
        axios.get('ws/ws_pipeline.php',{
            params: {
                task: "5",
                cs: id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setEditInitVal(
                    {
                        cs_id: data.record.cs_id,
                        cs_title_edit: data.record.cs_title,
                        cs_probability_edit: data.record.cs_probability,
                        cs_max_days: data.record.max_days,
                        cs_status_edit: data.record.inactive,
                        cs_token: data.record.cs_token
                    }
                );
                setIsEdit(true);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        cs_title_edit: yup.string().required(t('Setting_requiredFd'))
    });

    const sendEdit = (values) => {
        axios.get('ws/ws_pipeline.php',{
            params: {
                task: 2,
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                cs: values.cs_id,
                title: values.cs_title_edit,
                probability: values.cs_probability_edit,
                isactive: values.cs_status_edit,
                maxdays: values.cs_max_days
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Edit'),
                    timer: 1500
                })
                .then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {

        Swal.fire({
            icon: "warning",
            title: t('Setting_Alert_Warning_Title'),
            text: t('Setting_Alert_Warning_Text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('Setting_Alert_Warning_ConfirmText'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        }).then(result => {
            if (result.isConfirmed) {
                
                axios.get('ws/ws_pipeline.php', {
                    params: {
                        task: "4",
                        cs: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('Setting_Alert_Success_Title'),
                            text: t('Setting_Alert_Success_Text_Delete'),
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }else if(data.status === 1){
                        Swal.fire({
                            icon: "error",
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('SettingPipelineStage_Alert_DeleteText1')
                        });
                    }else if(data.status === 2){
                        Swal.fire({
                            icon: "error",
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('SettingPipelineStage_Alert_DeleteText2')
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('Setting_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });

    }

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('cs_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingPipelineStage_AddStage')}</button>
                            <Form.Group style={{width: 300}}>
                                <InputGroup>
                                        <Form.Control
                                            placeholder={t('SettingPipelineStage_SearchText')}
                                            type="text"
                                            onChange={(e) => {handleSearch(e.target.value)}}
                                        />
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                        </InputGroup.Text>
                                    </InputGroup>
                            </Form.Group>
                            <div className="ms-auto">{recordFound === 1 ? t('Setting_Record', {count: recordFound}) : recordFound > 1 ? t('Setting_Record', {count: recordFound}) : t('Setting_Record_NoRecord')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    cs_title: "",
                                    cs_probability: "",
                                    cs_status: "",
                                    cs_index: rowData.length + 1
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingPipelineStage_Modal_Title_Add')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipelineStage_Modal_StageName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.cs_title && touched.cs_title}
                                                onChange={handleChange('cs_title')}
                                            />
                                            {errors.cs_title && touched.cs_title && <div className="op-error-message">{errors.cs_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipelineStage_Modal_DealProb')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange('cs_probability')}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingPipelineStage_Modal_StageStatus')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipelineStage_Modal_Active')}
                                                    name="status"
                                                    value={0}
                                                    onChange={handleChange("cs_status")}
                                                />
                                                <Form.Check
                                                    inline
                                                    type="radio"
                                                    label={t('SettingPipelineStage_Modal_Inactive')}
                                                    name="status"
                                                    value={1}
                                                    onChange={handleChange("cs_status")}
                                                />
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingPipelineStage_Modal_Footer_Submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingPipelineStage_Modal_Title_Edit')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipelineStage_Modal_StageName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.cs_title_edit}
                                                isInvalid={errors.cs_title_edit && touched.cs_title_edit}
                                                onChange={handleChange('cs_title_edit')}
                                            />
                                            {errors.cs_title_edit && touched.cs_title_edit && <div className="op-error-message">{errors.cs_title_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipelineStage_Modal_DealProb')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.cs_probability_edit}
                                                onChange={handleChange('cs_probability_edit')}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Max days in this stage (0 for unlimited)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.cs_max_days}
                                                onChange={handleChange('cs_max_days')}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingPipelineStage_Modal_StageStatus')}</Form.Label>
                                            <div>
                                                <Form.Check
                                                    type="radio"
                                                    label={t('SettingPipelineStage_Modal_Active')}
                                                    name="stage_edit"
                                                    value={0}
                                                    checked={Number(values.cs_status_edit) === 0 ? true:false}
                                                    onChange={handleChange('cs_status_edit')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label={t('SettingPipelineStage_Modal_Inactive')}
                                                    name="stage_edit"
                                                    value={1}
                                                    checked={Number(values.cs_status_edit) === 1 ? true:false}
                                                    onChange={handleChange('cs_status_edit')}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingPipelineStage_Modal_CS_Token')}</Form.Label>
                                            <div>
                                                <Form.Label>{values.cs_token}</Form.Label>
                                            </div>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingPipelineStage_Modal_Footer_Submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default PipelineStageSetting;