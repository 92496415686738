import { useState, useEffect, useMemo, useRef, forwardRef, useCallback } from "react";
import { Col, Row, Stack, Form, Dropdown, Offcanvas, Container, InputGroup } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import FormSelect from "../includes/FormSelect";
import "../lib/scss/op-report-style.scss";
import "../lib/css/settingStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faFilterList, faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import moment from "moment";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { setCurrency } from "../lib/js/Function";

const GLTierList = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    const [searchVal, setSearchVal] = useState("");
    const [tier, setTier] = useState("");

    const [isFilter, setIsFilter] = useState(false);

    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh": "80vh" , paddingTop: 20 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [reportColumn] = useState([
        {
            headerName: "Name",
            field: "prospect_name",
            flex: 1,
            minWidth: 300,
            sortable: true,
            unSortIcon: true
        },
        {
            headerName: "Identification No.",
            field: "NRIC",
            resizable: true,
            width: "250"
        },
        {
            headerName: "Loyalty Tier",
            field: "loyaltyCategory",
            resizable: true,
            width: "200",
            sortable: true,
            unSortIcon: true
        },
        {
            headerName: "Total Accumulative Value",
            resizable: true,
            hide: false,
            width: "250",
            cellRenderer: (params) => {
                if (params.data) {
                    return setCurrency(params.data.accumPurchaseValue);
                }
            }
        },
        {
            headerName: "Mobile App Registration",
            field: "mobileAppRegisterStatus",
            resizable: true,
            width: "250",
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.mobileAppRegisterStatus) {
                        return params.data.mobileAppRegisterStatus == "True" ? "Yes" : "No"; 
                    }else{
                        return "No";
                    }
                }
            }
        },
        {
            headerName: "Registration Date",
            resizable: true,
            hide: false,
            width: "250",
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(new Date(params.data.mobileAppRegisterDate)).format("lll");
                }
            }
        },
    ]);

    const getTierList = () => {

        axios.get('ext/gamudaLifestyle/api_loyalty.php',{
            params: {
                task: "filter",
                page: "1",
                size: "100",
                q: searchVal,
                tier: tier,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                // Swal.fire({
                //     icon: "error",
                //     title: "Ooops",
                //     text: "There aren't any data available for your chosen Date Range or Filters. Try tweaking your Date Range & Filters"
                // })
                // .then(result => {
                //     setRowData([]);
                //     setRecordFound(0);
                //     setIsGrid(false);
                //     setIsLoading(false);
                // });
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);
            });
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            if (session.role_id == 1) {
                getTierList();
            }else{
                setIsLoading(false);
            }
        }
    }, []);

    const tierList = [
        {label: "Arrived", value: "Arrived"},
        {label: "Ascend", value: "Ascend"},
        {label: "Aspire", value: "Aspire"},
        {label: "Acquaint", value: "Acquaint"},
    ];

    const handleKeyDown = (event) => {
        if (event.key == "Enter" && session.role_id == 1) {
            setIsLoading(true);
            getTierList();
        }

        if (event.key == "Enter" && session.role_id != 1) {
            if (searchVal != "") {
                setIsLoading(true);
                getTierList();
            }else{
                setRowData([]);
                setRecordFound(0);
            }
        }
    }

    const handleSearch = () => {
        if (session.role_id == 1) {
            setIsLoading(true);
            getTierList();   
        }else{
            if (searchVal != "") {
                setIsLoading(true);
                getTierList();
            }else{
                setRowData([]);
                setRecordFound(0);
            }
        }
    }

    const handleFilter = () => {
        if (session.role_id == 1) {
            setIsLoading(true);
            getTierList();
            setIsFilter(false);
        }else{
            if (tier != "") {
                setIsLoading(true);
                getTierList();
                setIsFilter(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsFilter(false);
            }
        }
    }

    return(
        <Container fluid style={{padding: 0}}>
            {isLoading ? <Loader/> : 
                <div className="p-4">
                    <div>
                        <Stack direction="horizontal">
                            <div className="me-2 ms-auto">
                                Result: {recordFound == 0 ? "No record found." : recordFound > 1 ? `${recordFound} records found.` : `${recordFound} record found.`}
                            </div>
                            <div className="me-2">
                                <Form.Group>
                                    <InputGroup className="border-0 shadow-sm">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search by Name"
                                            value={searchVal}
                                            style={{width: "250px"}}
                                            onKeyDown={handleKeyDown}
                                            onChange={(e) => {setSearchVal(e.target.value)}}
                                        />
                                        <InputGroup.Text>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" className="fontAwesomeIcon" onClick={handleSearch}/>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div>
                                <button type="button" className="btn btn-light shadow-sm" onClick={setIsFilter}>
                                    <FontAwesomeIcon icon={faFilterList} size="lg"/>
                                </button>
                            </div>
                        </Stack>
                    </div>
                    <div>
                        <div style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={reportColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={60}
                                    pagination={true}
                                    paginationPageSize={100}
                                    paginationPageSizeSelector={false}
                                    noRowsOverlayComponent={() => {return(<div>No Records Found</div>)}}
                                />
                            </div>
                        </div>
                    </div>

                    <Offcanvas show={isFilter} onHide={setIsFilter} placement="end">
                       <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Filter Criteria</Offcanvas.Title>
                       </Offcanvas.Header>
                       <Offcanvas.Body>
                            <Form.Group>
                                <FormSelect
                                    placeholder="Loyalty Tier"
                                    options={tierList}
                                    valueDefault={tierList.filter(opt => opt.value == tier)}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e) => {setTier(e ? e.value : "")}}
                                />
                            </Form.Group>
                       </Offcanvas.Body>
                       <div className="offcanvas-footer">
                            <button type="button" className="btn op-button op-primary-color text-light" onClick={handleFilter}>Apply</button>
                       </div>
                    </Offcanvas>
                </div>
            }
        </Container>
    );
}

export default GLTierList;