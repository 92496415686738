import React, { useState } from "react";
import SearchInput from "./SearchInput";
import SearchDropdown from "./SearchDropdown";
const SearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };

  const handleSearchClose = () => {
    setIsSearchOpen(false);
  };

  return (
    <React.Fragment>
      <div className="op-search-container">
        <div className="op-search-wrapper">
          <SearchInput
            searchRef={isSearchOpen}
            handleSearch={handleSearch}
            handleSearchShow={setIsSearchOpen}
            handleSearchClose={handleSearchClose}
            loading={loading}
          />
        </div>
        {/* <SearchDropdown /> */}
        {isSearchOpen && <SearchDropdown onSearch={searchTerm} onLoading={(value) => setLoading(value)} handleSearchClose={(value) => setIsSearchOpen(value)} />}
      </div>
      
      
    </React.Fragment>
  );
};

export default SearchBar;
