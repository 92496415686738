import { useAuth } from "../../auth/AuthContext";
import axios from "../../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { Container, Row, Col, Stack, Button, Accordion, useAccordionButton, Card, Nav, Form, Modal, Image, ButtonGroup, InputGroup, Dropdown } from "react-bootstrap";

const EditSolicitorInformation = ({ bookingData, spaRecord, onDone }) => {
  const { session } = useAuth();
  const { Formik } = formik;

  const sendSolicitor = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "EditSpa",
          spa_type: "solicitor",
          rea_booking_id: bookingData.rea_booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          solicitorName: values.solicitor_name,
          solicitorContactNum: values.solicitor_contact,
          solicitorAddress: values.solicitor_address,
          solicitorPic: values.solicitor_pic,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          text: "Successfully updated the solicitor information",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          text: "Failed to update the solicitor information, please try again or contact our developer",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error,
      });
    }
  };

  return (
    <Formik
      onSubmit={sendSolicitor}
      initialValues={{
        solicitor_name: spaRecord.solicitorName ? spaRecord.solicitorName : bookingData.solicitor_info,
        solicitor_contact: spaRecord.solicitorContactNum ? spaRecord.solicitorContactNum : bookingData.solicitor_contact,
        solicitor_address: spaRecord.solicitorAddress ? spaRecord.solicitorAddress : bookingData.solicitor_address,
        solicitor_pic: spaRecord.solicitorPic ? spaRecord.solicitorPic : bookingData.solicitor_pic,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase" as={"h6"}>
              Edit Solicitor Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Solicitor Name</Form.Label>
              <Form.Control className="text-uppercase" type="text" value={values.solicitor_name} onChange={(e) => handleChange("solicitor_name")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Solicitor Contact</Form.Label>
              <Form.Control type="text" value={values.solicitor_contact} onChange={(e) => handleChange("solicitor_contact")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Solicitor Address</Form.Label>
              <Form.Control className="text-uppercase" as="textarea" rows={3} value={values.solicitor_address} onChange={(e) => handleChange("solicitor_address")(e.target.value)} style={{ resize: "none" }} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Solicitor PIC</Form.Label>
              <Form.Control type="text" value={values.solicitor_pic} onChange={(e) => handleChange("solicitor_pic")(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
              Save
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default EditSolicitorInformation;
