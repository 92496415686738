import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import moment from "moment";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const SettingLeadScore = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [recScoreType, setRecScoreType] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [editInit, setEditInit] = useState({});

    const scoreTypeList = [
        {label: "Inbound Lead", value: "receive_inbound_leads"},
        {label: "call answer more than 1 min", value: "answer_phone_call_duration_more_than_one_minute"},
        {label: "call answer less than 1 min", value: "answer_phone_call_duration_less_than_one_minute"},
        {label: "whatsapp outreach lead qualification", value: "ai_outreach_lead_qualified"},
        {label: "Meeting", value: "meeting_completed"}
    ];
    const [dsList, setDsList] = useState([]);
    const [dsCatList, setDsCatList] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: "Date time create",
            field: "date_time_create",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(params.data.date_time_create).format("lll");
                }
            }
        },
        {
            headerName: "Score title",
            field: "score_title",
            filter: true,
            resizable: true,
            width: 200
        },
        {
            headerName: "Score type",
            field: "score_type",
            resizable: true,
            width: 200
        },
        {
            headerName: "Maximum score",
            field: "score_cap",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 200
        },
        {
            headerName: "Source Category",
            field: "source_category_title",
            resizable: true,
            width: 200
        },
        {
            headerName: "Deal Source",
            field: "ds_title",
            resizable: true,
            width: 200
        },
        {
            headerName: "Rubric Token",
            field: "rubric_token",
            resizable: true,
            width: 300
        },
        {
            headerName: "Score Value",
            field: "score_value",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 200
        },
        {
            headerName: "Action",
            width: 150,
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        axios.get("ws/ws_rubric_score.php",{
            params: {
                task: "getRubricScoreListing",
                searchStr: "",
                score_type: "",
                rubric_token: "",
                rubric_score_id: "",
                ds_id: "",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                // data.record.sort((a,b) => {return new Date(b.date_time_create) - new Date(a.date_time_create)});
                var selectedScoreType = [];

                data.record.forEach(data => {
                    if (data.score_type !== 'receive_inbound_leads') {
                        selectedScoreType.push(data.score_type);
                    }
                });

                setRecScoreType(selectedScoreType);
                setRowData(data.record);
                setRecordFound(data.totalRecord);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecScoreType([]);
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecScoreType([]);
            setRowData([]);
            setRecordFound(0);
            setIsGrid(false);
            setIsLoading(false);
        });
    }

    const getAllDs = () => {
        axios.get("ws/ws_source.php", {
            params: {
                task: 2,
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {

                var dataList = data.record.map(data => {
                    return {label: data.ds_title, value: data.ds_id, cat_id: data.source_category_id};
                });

                var dataList2 = data.source_cat.map(data => {
                    return {label: data.source_category_title, value: data.source_category_id};
                });

                setDsList(dataList);
                setDsCatList(dataList2);
            }else{
                setDsList([]);
                setDsCatList([]);
            }
        })
        .catch(error => {
            setDsList([]);
            setDsCatList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
            getAllDs();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        score_type: yup.string().required("This field is required"),
        rubric_title: yup.string().required("This field is required"),
        max_score: yup.number().typeError('Must be a number type').required('This field is required'),
        rubric_score: yup.number().typeError('Must be a number type').required('This field is required'),
        ds_cat_id: yup.string().notRequired().when('score_type', ([score_type], schema) => {
            if (score_type === 'receive_inbound_leads')
                return yup.string().required('This field is required');
            return schema;
        }),
        ds_id: yup.string().notRequired().when('score_type', ([score_type], schema) => {
            if (score_type === 'receive_inbound_leads')
                return yup.string().required('This field is required');
            return schema;
        })
    });

    const sendAdd = (values) => {
        var allow = 1;
        var allow2 = 1;

        if (Number(values.max_score) < Number(values.rubric_score)) {
            allow = 0;
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Score exceed max score'
            });
        }

        if (rowData.length > 0) {
            rowData.forEach(data => {
                if (data.score_type === values.score_type) {
                    if (Number(data.score_cap) !== Number(values.max_score)) {
                        allow2 = 0;
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Maximum score must be same as other for the same score type'
                        });
                    }
                }
            });   
        }

        if (Number(allow) === 1 && Number(allow2) === 1) {
            axios.get('ws/ws_rubric_score.php', {
                params:{
                    task: "addRubricScore",
                    score_title: values.rubric_title,
                    score_value: values.rubric_score,
                    score_cap: values.max_score,
                    score_type: values.score_type,
                    source_category_id: values.score_type === "receive_inbound_leads" ? values.ds_cat_id : "",
                    ds_id: values.score_type === "receive_inbound_leads" ? values.ds_id : "",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (Number(data.status) === 0) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Successfuly added',
                        timer: 1500
                    })
                    .then(result => {
                        setIsAdd(false);
                        getGridData();
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "failed",
                        text: "Please try again or contact your IT Support"
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: error.message,
                });
            });
        }
    }

    const handleEdit = (data) => {

        setEditInit({
            score_token: data.rubric_token,
            score_title: data.score_title,
            score_type: data.score_type,
            max_score: data.score_cap,
            score: data.score_value
        });

        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        score_title: yup.string().required('This field is required'),
        score: yup.number().typeError('Must be a number type').required('This field is required'),
        max_score: yup.number().typeError('Must be a number type').required('This field is required')
    });

    const sendEdit = (values) => {

        var allow = 1;
        var allow2 = 1;

        if (Number(values.max_score) < Number(values.score)) {
            allow = 0;
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Score exceed max score'
            });
        }

        if (rowData.length > 0) {
            rowData.forEach(data => {
                if (data.score_type === values.score_type) {
                    if (Number(data.score_cap) !== Number(values.max_score)) {
                        allow2 = 0;
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Maximum score must be same as other for the same score type'
                        });
                    }
                }
            });
        }

        if (Number(allow) === 1 && Number(allow2) === 1) {
            axios.get('ws/ws_rubric_score.php', {
                params:{
                    task: "updateRubricScore",
                    rubric_token: values.score_token,
                    score_title: values.score_title,
                    score_value: values.score,
                    score_cap: values.max_score,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (Number(data.status) === 0) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Successfully updated',
                        timer: 1500
                    })
                    .then(result => {
                        getGridData();
                        setIsEdit(false);
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Please try again or contact your IT Support'
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: error.message
                });
            });
        }
    }

    const handleDelete = (data) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: 'This record will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel"
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_rubric_score.php', {
                    params:{
                        task: "deleteRubricScore",
                        rubric_token: data.rubric_token,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Successfully deleted',
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: 'Please try again or contact your IT Support'
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('score_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? <Loader/> : 
                <Container fluid className="p-4">
                    <Stack direction="horizontal" gap={2}>
                        <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>Add Score</button>
                        <Form.Group style={{width: '250px'}}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Search by score title"
                                    onChange={(e) => {handleSearch(e.target.value)}}
                                />
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon"/>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <div className="ms-auto">
                            {Number(recordFound) === 1 ? `${recordFound} record found.` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}
                        </div>
                    </Stack>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={{
                                score_type: "",
                                ds_cat_id: "",
                                ds_id: "",
                                rubric_title: "",
                                rubric_score: "",
                                max_score: "",
                                rec_score_type: recScoreType.length > 0 ? recScoreType.map(data => {
                                    return data;
                                }) : "",
                                rec_ds_id: rowData.length > 0 ? rowData.map(data => {
                                    return Number(data.ds_id);
                                }) : "",
                            }}
                        >
                            {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={'h6'}>Add Rubric Score</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score Type</Form.Label>
                                            <FormSelect
                                                placeholder="Select score type"
                                                options={scoreTypeList.filter(opt => !values.rec_score_type.includes(opt.value))}
                                                isInvalid={errors.score_type && touched.score_type}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('score_type', e ? e.value : "")}}
                                            />
                                            {errors.score_type && touched.score_type && <div className="op-error-message">{errors.score_type}</div>}
                                        </Form.Group>
                                        {values.score_type === "receive_inbound_leads" && <Form.Group className="mb-3">
                                            <Form.Label>Source Category</Form.Label>
                                            <FormSelect
                                                placeholder="Select source category"
                                                options={dsCatList}
                                                isInvalid={errors.ds_cat_id && touched.ds_cat_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('ds_cat_id', e ? e.value : "")}}
                                            />
                                            {errors.ds_cat_id && touched.ds_cat_id && <div className="op-error-message">{errors.ds_cat_id}</div>}
                                        </Form.Group>}
                                        {values.score_type === "receive_inbound_leads" && <Form.Group className="mb-3">
                                            <Form.Label>Deal Source</Form.Label>
                                            <FormSelect
                                                placeholder="Select deal source"
                                                options={
                                                    values.ds_cat_id && values.rec_ds_id ? dsList.filter(opt => Number(opt.cat_id) === Number(values.ds_cat_id) && !values.rec_ds_id.includes(Number(opt.value))) :  values.ds_cat_id ?
                                                    dsList.filter(opt => Number(opt.cat_id) === Number(values.ds_cat_id)) : dsList.filter(opt => !values.rec_ds_id.includes(Number(opt.value)))
                                                }
                                                isInvalid={errors.ds_id && touched.ds_id}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {setFieldValue('ds_id', e ? e.value : "")}}
                                            />
                                            {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                                        </Form.Group>}
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.rubric_title && touched.rubric_title}
                                                onChange={(e) => {setFieldValue('rubric_title', e.target.value)}}
                                            />
                                            {errors.rubric_title && touched.rubric_title && <div className="op-error-message">{errors.rubric_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Maximum Score</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.max_score && touched.max_score}
                                                onChange={(e) => {setFieldValue('max_score', e.target.value)}}
                                            />
                                            {errors.max_score && touched.max_score && <div className="op-error-message">{errors.max_score}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.rubric_score && touched.rubric_score}
                                                onChange={(e) => {setFieldValue('rubric_score', e.target.value)}}
                                            />
                                            {errors.rubric_score && touched.rubric_score && <div className="op-error-message">{errors.rubric_score}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}

                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInit}
                        >
                            {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={'h6'}>Edit Lead Score</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.score_title}
                                                isInvalid={errors.score_title && touched.score_title}
                                                onChange={(e) => {setFieldValue('score_title', e.target.value)}}
                                            />
                                            {errors.score_title && touched.score_title && <div className="op-error-message">{errors.score_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Maximum Score</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.max_score}
                                                isInvalid={errors.max_score && touched.max_score}
                                                onChange={(e) => {setFieldValue('max_score', e.target.value)}}
                                            />
                                            {errors.max_score && touched.max_score && <div className="op-error-message">{errors.max_score}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.score}
                                                isInvalid={errors.score && touched.score}
                                                onChange={(e) => {setFieldValue('score', e.target.value)}}
                                            />
                                            {errors.score && touched.score && <div className="op-error-message">{errors.score}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            }
        </div>
    );
}

export default SettingLeadScore;