import { useState, useRef, useEffect } from "react";
import { Row, Col, Stack, Card, Form, Modal } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpenText, faEnvelopesBulk, faFax, faPenToSquare, faPhone, faShieldCheck } from "@fortawesome/pro-duotone-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const ContactPref = ({ prospectID, prospectEmail }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [contactPrefData, setContactPrefData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    var contactPrefList = [
        {label: t('ProspectDetGL_yes'), value: "1"},
        {label: t('ProspectDetGL_no'), value: "0"}
    ];

    const vietnam = ["402", "412"];

    const getContactPreference = () => {

        axios.get('ws/ws_contact_preferences.php', {
            params:{
                task: 2,
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setContactPrefData(data.record[0]);
            }else{
                setContactPrefData([]);
            }
        })
        .catch(error => {
            setContactPrefData([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getContactPreference();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const sendSubmit = (values) => {
        
        axios.get('ws/ws_contact_preferences.php', {
            params:{
                task: "1",
                prospect_id: prospectID,
                email: values.email,
                email_bulk: values.bulkEmail,
                phone: values.phone,
                fax: values.fax,
                mail: values.mail,
                whatsapp: values.whatsapp,
                pdpa: values.pdpa,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectDetGL_ContactPref_Alert_Success_Title'),
                    text: t('ProspectDetGL_ContactPref_Alert_Success_Text'),
                    timer: 1500
                })
                .then(result => {
                    getContactPreference();
                    setShowModal(false);

                    if (Number(values.bulkEmail) === 0) {
                        triggerUnSub(prospectEmail);
                    }
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_ContactPref_Alert_Failed_Title'),
                    text: t('ProspectDetGL_ContactPref_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire(
                {
                    icon: "error",
                    title: error.message
                }
            );
        });
    }

    const triggerUnSub = (email) => {
        
        axios.get('ext/gamuda/api_dynamic_unsubscribe.php',{
            params: {
                email: email
            }
        });
    }

    return(
        <>
            <Card>
                <Card.Header className="rounded border-0 shadow-sm" style={{backgroundColor: "#fff", padding: 0}}>
                    <div style={{padding: "10px 20px"}}>
                        <Stack direction="horizontal" gap={1}>
                            <h6 className="mt-2">Contact Preference</h6>
                            <button 
                                type="button" 
                                className="btn btn-light border ms-auto"
                                disabled={Number(session.company_id) !== 251 ? true : false}
                                onClick={setShowModal}
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </button>
                        </Stack>
                    </div>
                </Card.Header>
            </Card>

            <Row className="mt-5">
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_ContactPref_Email')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.email === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.email === "Disallow" ? t('ProspectDetGL_no') : contactPrefData.email}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faEnvelope} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_ContactPref_BulkEmail')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.email_bulk === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.email_bulk === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.email_bulk}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faEnvelopesBulk} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_ContactPref_Phone')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.phone === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.phone === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.phone}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faPhone} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_ContactPref_Fax')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.fax === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.fax === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.fax}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faFax} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_ContactPref_Mail')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.mail === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.mail === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.mail}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{vietnam.includes(session.company_id) ? t('ProspectDetGL_ContactPref_Whatsapp') : t('ProspectDetGL_ContactPref_Whatsapp')}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.whatsapp === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.whatsapp === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.whatsapp}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={vietnam.includes(session.company_id) ? faWhatsapp : faWhatsapp} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={3}>
                    <Card className="shadow-sm border-0">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                            <div>
                                <Card.Title as={"h6"} className="fw-bold">{vietnam.includes(session.company_id) ? 'PDPA/PDPD' : 'PDPA'}</Card.Title>
                                <Card.Text style={{fontSize: 13}}>{contactPrefData.pdpa === 'Allow' ? t('ProspectDetGL_yes') : contactPrefData.pdpa === 'Disallow' ? t('ProspectDetGL_no') : contactPrefData.pdpa}</Card.Text>
                            </div>
                            <FontAwesomeIcon icon={faShieldCheck} size="2xl"/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} onHide={setShowModal}>
                <Formik
                    onSubmit={sendSubmit}
                    initialValues={
                        {
                            email: contactPrefData.email === "Allow" ? "1" : "0",
                            bulkEmail: contactPrefData.email_bulk === "Allow" ? "1" : "0",
                            phone: contactPrefData.phone === "Allow" ? "1" : "0",
                            fax: contactPrefData.fax === "Allow" ? "1" : "0",
                            mail: contactPrefData.mail === "Allow" ? "1" : "0",
                            whatsapp: contactPrefData.whatsapp === "Allow" ? "1" : "0",
                            pdpa: contactPrefData.pdpa === "Allow" ? "1" : "0"
                        }
                    }
                >
                    {({ handleChange, handleSubmit, errors, touched, values }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title as={"h6"}>{t('ProspectDetGL_Modal_Edit_Title')}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                
                                <Form.Group className="mb-3 mt-3 px-4">

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{t('ProspectDetGL_ContactPref_Email')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.email)}
                                                onChange={(e) => {handleChange('email')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{t('ProspectDetGL_ContactPref_BulkEmail')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.bulkEmail)}
                                                onChange={(e) => {handleChange('bulkEmail')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{t('ProspectDetGL_ContactPref_Phone')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.phone)}
                                                onChange={(e) => {handleChange('phone')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{t('ProspectDetGL_ContactPref_Fax')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.fax)}
                                                onChange={(e) => {handleChange('fax')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{t('ProspectDetGL_ContactPref_Mail')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.mail)}
                                                onChange={(e) => {handleChange('mail')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{vietnam.includes(session.company_id) ? t('ProspectDetGL_ContactPref_Whatsapp') : t('ProspectDetGL_ContactPref_Whatsapp')} :</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                value={contactPrefList.filter(opt => opt.value === values.whatsapp)}
                                                onChange={(e) => {handleChange('whatsapp')(e ? e.value : "")}}
                                            />
                                        </Form.Group>
                                    </Stack>

                                    <Stack direction="horizontal" gap={1} style={{width: "100%"}} className="d-flex justify-content-between mb-3">
                                        <Form.Group style={{width: "20%"}}>
                                            <Form.Label>{vietnam.includes(session.company_id) ? 'PDPA/PDPD :' : 'PDPA :'}</Form.Label>
                                        </Form.Group>
                                        <Form.Group style={{width: "80%"}}>
                                            <FormSelect
                                                options={contactPrefList}
                                                valueDefault={contactPrefList.filter(opt => opt.value === values.pdpa)}
                                                onChange={(e) => {
                                                    handleChange('pdpa')(e ? e.value : "");
                                                    handleChange('email')(e.value === '0' ? '0' : contactPrefData.email === "Allow" ? "1" : "0");
                                                    handleChange('bulkEmail')(e.value === '0' ? '0' : contactPrefData.email_bulk === "Allow" ? "1" : "0");
                                                    handleChange('phone')(e.value === '0' ? '0' : contactPrefData.phone === "Allow" ? "1" : "0");
                                                    handleChange('fax')(e.value === '0' ? '0' : contactPrefData.fax === "Allow" ? "1" : "0");
                                                    handleChange('mail')(e.value === '0' ? '0' : contactPrefData.mail === "Allow" ? "1" : "0");
                                                    handleChange('whatsapp')(e.value === '0' ? '0' : contactPrefData.whatsapp === "Allow" ? "1" : "0");
                                                }}
                                            />
                                        </Form.Group>
                                    </Stack>

                                </Form.Group>
                                
                            </Modal.Body>
                            <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_Modal_Footer_Submit')}</button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}

export default ContactPref;
