export const routeLevelAccess = (location, session, params) => {
  const { deal_id, prospect_id, chat_instance_id, rea_unit_id, booking_id, rea_project_id, rea_phase_id, level_id, carpark_id, curation_id } = params;

  let access = true;
  switch (location) {
    case "/curation":
    case `/curation/list`:
    case `/curation/${curation_id}`:
      access = Number(session.curation_enabled) !== 0;
      break;
    case "/lead-aging":
      access = [251, 180, 190, 202, 203, 212, 213, 341, 106, 2, 400, 409, 210, 200, 385].includes(Number(session.company_id));
      break;
    case "/ai-outreach":
      access = Number(session.blueprint_ai) === 1;
      break;
    case "/report-transfer-lead":
      access = Number(session.role_id) === 1 && Number(session.lead_transfer_report) !== 0;
      break;
    case "/report-inbound-analytics":
      access = Number(session.role_id) === 1 && Number(session.inbound_analytics) === 1;
      break;
    case "/report-inbound-lead-performance":
      access = Number(session.role_id) === 1 && Number(session.layout_mode) === "real-estate" && (Number(session.company_lead_assignment_autoreroute) === 1 || Number(session.company_lead_assignment_autoreroute) === 0);
      break;
    case "/report-contact":
    case "/report-deal":
    case "/report-duplicate-lead":
    case "/report-productivity":
    case "/report-activity":
    case "/report-deal-engagement":
    case "/report-deal-performance":
    case "/report-lead-acceptance":
    case "/report-lead-route":
    case "/report-marketing-analytics":
    case "/report-schedule-deal":
    case "/users":
      access = Number(session.role_id) === 1;
      break;
    case `/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`:
    case `/booking-details/${booking_id}/${deal_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`:
    case `/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`:
    case `/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${level_id}`:
    case `/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${carpark_id}`:
    case `/booking/dashboard`:
    case `/booking/list`:
    case `/booking/project/${deal_id}/${prospect_id}`:
    case `/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`:
    case `/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`:
      access = Number(session.booking_module) === 1;
      break;
    case `/conversation`:
    case `/conversation/${chat_instance_id}`:
    case `/settings/waba`:
    case `/settings/blueprint2`:
    case `/settings/conversation-flow`:
      access = Number(session.whatsapp_conversation) !== 0;
      break;
    case `/call-analytics`:
    case `/call-insight`:
      access = Number(session.avanser_enabled) === 1;
      break;
    case `/settings/deal-source`:
    case `/settings/source-category`:
    case `/settings/business-unit`:
    case `/settings/avanser-call`:
    case `/settings/device-notification`:
    case `/settings/facebook-token`:
    case `/settings/lost-reason`:
      access = Number(session.role_id) === 1;
      break;
    case `/gamuda/redemption/dashboard`:
    case `/gamuda/redemption/voucher`:
    case `/gamuda/redemption/category`:
    case `/gamuda/redemption/vendor`:
    case `/gamuda/redemption/email`:
    case `/gamuda/report-incoming-log`:
    case `/gamuda/report-outgoing-log`:
    case `/gamuda/report-gl-play`:
    case `/gamuda/segment/${curation_id}`:
      access = Number(session.company_id) === 251;
      break;
    case `/settings/products`:
      access = Number(session.product_feature) === 1 && session.layout_mode !== "real-estate";
      break;
    case `/settings/bulk-update`:
      access = Number(session.company_id) === 251;
      break;
    case `/gamuda/list-tier`:
      access = [251, 180, 200, 190, 203, 202, 212, 213, 210].includes(Number(session.company_id));
      break;
    case `/event-rsvp`:
      access = Number(session.event_rsvp) === 1;
      break;
    case `/survey-dashboard`:
    case `/settings/survey-settings`:
      access = Number(session.survey) === 1 && Number(session.role_id) === 1;
      break;
    default:
      access = true;
      break;
  }
  return access;
};

export const routePath = (location, params) => {
  const { deal_id, prospect_id, chat_instance_id, rea_unit_id, booking_id, rea_project_id, rea_phase_id, level_id, carpark_id, curation_id, organization_id, key, project_id, phase_id } = params;
  const paths = {
    [`/deal/list`]: "Deals",
    [`/deal/${deal_id}`]: "Deal Details",
    [`/prospect/list`]: "Contact",
    [`/prospect/${prospect_id}`]: "Contact Details",
    [`/activity/list`]: "Activity",
    [`/activity/calendar`]: "Calendar",
    [`/curation`]: "Curation",
    [`/curation/list`]: "Curations",
    [`/curation/${curation_id}`]: "Curation Details",
    [`/users`]: "User",
    [`/report-contact`]: "Contact Report",
    [`/report-deal`]: "Deal Report",
    [`/report-transfer-lead`]: "Transfer Lead Report",
    [`/report-duplicate-lead`]: "Duplicate Lead Report",
    [`/report-productivity`]: "Productivity Report",
    [`/report-activity`]: "Activity Report",
    [`/report-deal-engagement`]: "Deal Engagement Report",
    [`/report-deal-performance`]: "Deal Performance Report",
    [`/report-inbound-analytics`]: "Inbound Analytics Report",
    [`/report-inbound-lead-performance`]: "Inbound Lead Performance",
    [`/report-lead-acceptance`]: "Lead Acceptance Report",
    [`/report-lead-route`]: "Lead Routing",
    [`/report-marketing-analytics`]: "Marketing Analytics",
    [`/report-schedule-deal`]: "Schedule Deal Report",
    [`/report-open-deal`]: "Status Summary Report",
    [`/report-won-deal`]: "Won Deal Report",
    [`/report-lost-deal`]: "Lost Deal Report",
    [`/whatsapp-campaign`]: "Whatsapp Campaign",
    [`/statistics`]: "Statistics",
    [`/lead-aging`]: "Lead Aging Report",
    [`/ai-outreach`]: "AI Outreach Dashboard",
    [`/call-insight`]: "Call Insight Dashboard",
    [`/call-analytics`]: "Call Analytics",
    [`/conversation`]: "Conversation",
    [`/conversation/${chat_instance_id}`]: "Conversation",
    [`/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`]: "Booking Form",
    [`/booking-details/${booking_id}/${deal_id}`]: "Booking Details",
    [`/sales-chart/unit/${deal_id}/${prospect_id}`]: "Sales Kit",
    [`/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`]: "Sales Kit",
    [`/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`]: "Unit Details",
    [`/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(level_id)}`]: "Sales Kit Level",
    [`/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(carpark_id)}`]: "Sales Kit Car Park",
    [`/booking/dashboard`]: "Booking Dashboard",
    [`/booking/list`]: "Booking",
    [`/booking/project/${deal_id}/${prospect_id}`]: "Project",
    [`/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`]: "Phase",
    [`/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`]: "Phase Details",
    [`/booking/approval`]: "Booking Approval",
    [`/booking/interest`]: "Interest",
    [`/settings/facebook-token`]: "Facebook Token Settings",
    [`/settings/business-unit`]: "Business Unit Settings",
    [`/settings/source-category`]: "Source Category Settings",
    [`/settings/lost-reason`]: "Lost Reason Settings",
    [`/settings/device-notification`]: "Device Notification Settings",
    [`/settings/avanser-call`]: "Avanser Call Settings",
    [`/settings/deal-source`]: "Deal Source Settings",
    [`/settings/pipeline`]: "Pipeline Settings",
    [`/settings/fields/deal`]: "Custom Fields",
    [`/settings/fields/person`]: "Custom Fields",
    [`/settings/fields/organization`]: "Custom Fields",
    [`/settings/fields/booking`]: "Custom Fields",
    [`/settings/fb-connection`]: "FB Connection",
    [`/settings/permission`]: "Permission",
    [`/settings/survey-settings`]: "Survey Settings",
    [`/organizations/list`]: "Organizations",
    [`/organizations/${organization_id}`]: "Organization Details",
    [`/event-rsvp`]: "Event RSVP",
    [`/survey-dashboard`]: "Survey Dashboard",
    [`/cs1`]: "Customer Survey 1",
    [`/cs2`]: "Customer Survey 2",
    [`/campaign-dashboard`]: "Campaign Dashboard",
    [`/settings/email-template`]: "Email Template Settings",
    [`/settings/whatsapp-template`]: "Whatsapp Template Settings",
    [`/settings/smartlink-template`]: "Smartlink Template Settings",
    [`/settings/fb-form`]: "Facebook Forms",
    [`/settings/team`]: "Team Settings",
    [`/settings/meetup`]: "Meetup Settings",
    [`/settings/notification`]: "Notification Settings",
    [`/settings/waba`]: "Waba Settings",
    [`/settings/blueprint2`]: "Blueprint2 Settings",
    [`/settings/conversation-flow`]: "Conversation Flow Settings",
    [`/settings/marketing-form`]: "Marketing Form Settings",
    [`/settings/lead-score`]: "Lead Score Settings",
    [`/settings/campaign-settings`]: "Campaign Settings",
    [`/gamuda/list-tier`]: "Gamuda Tier",
    [`/gamuda/redemption/dashboard`]: "Redemption Dashboard",
    [`/gamuda/redemption/voucher`]: "Redemption Voucher",
    [`/gamuda/redemption/category`]: "Redemption Category",
    [`/gamuda/redemption/vendor`]: "Redemption Vendor",
    [`/gamuda/redemption/email`]: "Redemption Email Subject",
    [`/gamuda/list-tier`]: "Gamuda Tier",
    [`/gamuda/report-incoming-log`]: "Incoming Log",
    [`/gamuda/report-outgoing-log`]: "Outgoing Log",
    [`/gamuda/report-gl-play`]: "GL Play",
    [`/rea-sales-kit/${key}`]: "Sales Kit",
    [`/rea-sales-kit/${key}/project`]: "Project",
    [`/rea-sales-kit/${key}/${project_id}`]: "Project Details",
    [`/rea-sales-kit/${key}/${project_id}/${phase_id}`]: "Phase Details",
    [`/rea-sales-kit/${key}/${project_id}/${phase_id}/saleschart`]: "Sales Chart",
    [`/rea-sales-kit/${key}/${project_id}/${rea_project_id}/${rea_unit_id}`]: "Unit Details",
    [`/admin-settings/waba`]: "Waba Settings",
    [`/admin-settings/blueprint2`]: "Blueprint2 Settings",
    [`/admin-settings/conversation-flow`]: "Conversation Flow Settings",
    [`/admin-settings/project`]: "Project Settings",
    [`/admin-settings/phase`]: "Phase Settings",
    [`/admin-settings/type`]: "Unit Type Settings",
    [`/admin-settings/unit`]: "Unit Settings",
    [`/admin-settings/modules`]: "Booking Modules",
    [`/admin-settings/coords`]: "Sales Chart Coords",
    [`/admin-settings/form-format`]: "Booking Form Format",
    [`/admin-settings/facilities`]: "Facilties",
    [`/admin-settings/amenities`]: "Amenities",
    [`/admin-settings/files`]: "Brochures and Files",
    [`/admin-settings/contact`]: "Contacts",
    [`/admin-settings/fact-sheet`]: "AI Fact Sheet",
    [`/settings/products`]: "Products",
    [`/settings/bulk-update`]: "Bulk Contacts",
    [`/settings/getresponse-connection`]: "GetResponse Connection",
    default: "Outperform",
  };

  return paths[location] || paths.default;
};

export const routeChild = (location, params) => {
  const { deal_id, prospect_id, rea_unit_id, booking_id, rea_project_id, rea_phase_id, level_id, carpark_id, curation_id } = params;
  let access = true;
  switch (location) {
    case `/booking-details/${booking_id}/${deal_id}`:
    case `/booking/project/${rea_project_id}/${deal_id}/${prospect_id}`:
    case `/booking/phase/${rea_project_id}/${rea_phase_id}/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit-details/${deal_id}/${prospect_id}/${rea_unit_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}`:
    case `/sales-chart/unit/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}`:
    case `/sales-chart/level/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(level_id)}`:
    case `/sales-chart/carpark/${deal_id}/${prospect_id}/${rea_project_id}/${rea_phase_id}/${encodeURIComponent(carpark_id)}`:
    case `/booking-form/${rea_unit_id}/${deal_id}/${prospect_id}`:
    case `/curation/${curation_id}`:
    case `/gamuda/segment/${curation_id}`:
      access = true;
      break;
    default:
      access = false;
      break;
  }
  return access;
};
