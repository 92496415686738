import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Form, ListGroup } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import { FormSelect } from "../../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";

const BookngPermission = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [permission, setPermission] = useState({
    booking_permission_id: "",
    update_booking_info: "reserved",
    update_payment_info: "reserved",
    update_additional_info: "reserved",
    create_additional_buyer: "reserved",
    update_buyer: "reserved",
    update_spa_info: "spa_signed",
    update_solicitor_info: "spa_signed",
    update_financier_info: "spa_signed",
    upload_documents: "reserved",
    update_signature: "reserved",
    company_id: session.company_id,
  });

  const permissionList = [
    { label: "Until Reserved", value: "reserved" },
    { label: "Until Booking Confirm", value: "booking_confirmed" },
    { label: "Until SPA Signed", value: "spa_signed" },
    { label: "Until SPA Stamped", value: "spa_stamped" },
    { label: "Until SPA Sold", value: "spa_sold" },
  ];

  // USEEFFECT FUNCTION ----------------------

  const getData = useCallback(async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPermission",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setPermission(data.record);
        setUser(data.user);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onchangeData = useCallback(
    async (column, value) => {
      const recordUpdate = { ...permission };
      recordUpdate[column] = value;
      setPermission(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdatePermission",
            column: column,
            value: value,
            booking_permission_id: permission.booking_permission_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [permission, session]
  );

  const onchangeData2 = useCallback(
    async (column, index, values) => {
      const recordValue = Number(values[column]) === 1 ? 0 : 1;
      const recordUpdate = [...user];
      recordUpdate[index][column] = Number(values[column]) === 1 ? 0 : 1;
      setUser(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateUser",
            column: column,
            value: recordValue,
            user_id: values.user_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [user, session]
  );

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "User Name",
      field: "user_name",
    },
    {
      headerName: "Role",
      field: "role_id",
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => (Number(params.data.role_id) === 1 ? "Admin" : Number(params.data.role_id) === 2 ? "Manager" : "Sales Rep"),
    },
    {
      headerName: "Booking Status Changes Restricted",
      headerClass: "center",
      cellClass: "center",
      field: "booking_disable_status_changes",
      cellRenderer: (params) => <Form.Check type="switch" className="d-flex align-items-center justify-content-center" checked={Number(params.data.booking_disable_status_changes) === 1} onChange={() => onchangeData2("booking_disable_status_changes", params.rowIndex, params.data)} />,
    },
    {
      headerName: "Sales Chart Restricted",
      headerClass: "center",
      cellClass: "center",
      field: "booking_sales_chart_restricted",
      cellRenderer: (params) => <Form.Check type="switch" className="d-flex align-items-center justify-content-center" checked={Number(params.data.booking_sales_chart_restricted) === 1} onChange={() => onchangeData2("booking_sales_chart_restricted", params.rowIndex, params.data)} />,
    },
    {
      headerName: "Viewer Access",
      headerClass: "center",
      cellClass: "center",
      field: "booking_viewer_access",
      cellRenderer: (params) => <Form.Check type="switch" className="d-flex align-items-center justify-content-center" checked={Number(params.data.booking_viewer_access) === 1} onChange={() => onchangeData2("booking_viewer_access", params.rowIndex, params.data)} />,
    },
    {
      headerName: "Project Restricted",
      headerClass: "center",
      cellClass: "center",
      field: "rea_project_restricted",
      cellRenderer: (params) => <Form.Check type="switch" className="d-flex align-items-center justify-content-center" checked={Number(params.data.rea_project_restricted) === 1} onChange={() => onchangeData2("rea_project_restricted", params.rowIndex, params.data)} />,
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.user_id.toString();
  }, []);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <section className="mb-4">
            <h6>Booking User Permission</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Booking Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_booking_info)} width={200} onChange={(e) => onchangeData("update_booking_info", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Payment Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_payment_info)} width={200} onChange={(e) => onchangeData("update_payment_info", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Additional Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_additional_info)} width={200} onChange={(e) => onchangeData("update_additional_info", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Create Additional Buyer
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.create_additional_buyer)} width={200} onChange={(e) => onchangeData("create_additional_buyer", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Buyer
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_buyer)} width={200} onChange={(e) => onchangeData("update_buyer", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Upload Documents
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.upload_documents)} width={200} onChange={(e) => onchangeData("upload_documents", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Signature
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_signature)} width={200} onChange={(e) => onchangeData("update_signature", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update SPA Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_spa_info)} width={200} onChange={(e) => onchangeData("update_spa_info", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Solicitor Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_solicitor_info)} width={200} onChange={(e) => onchangeData("update_solicitor_info", e.value)} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Update Financier Information
                <FormSelect options={permissionList} valueDefault={permissionList.find((record) => record.value === permission.update_financier_info)} width={200} onChange={(e) => onchangeData("update_financier_info", e.value)} />
              </ListGroup.Item>
            </ListGroup>
          </section>
          <section>
            <h6>Booking User Access</h6>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact defaultColDef={gridColDef} rowData={user} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={50} suppressRowClickSelection={false} animateRows={true} />
              </div>
            </div>
          </section>
        </div>
      )}
    </Container>
  );
};

export default BookngPermission;
