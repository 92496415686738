import axios from "../../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { Container, Row, Col, Stack, Button, Accordion, useAccordionButton, Card, Nav, Form, Modal, Image, ButtonGroup, InputGroup, Dropdown } from "react-bootstrap";
import { useAuth } from "../../auth/AuthContext";

const EditFinancierInformation = ({ bookingData, spaRecord, onDone }) => {
  const { session } = useAuth();
  const { Formik, useFormikContext } = formik;
  const sendFinancier = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "EditSpa",
          spa_type: "financier",
          rea_booking_id: bookingData.rea_booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          financierName: values.financier_name,
          financierPic: values.financier_pic,
          financierPicContact: values.financier_pic_contact,
          financierBranch: values.financier_branch,
          financierBranchAddress: values.financier_branch_address,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: "Successfully updated the financier information",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Failed to update the financier information, please try again or contact our developer",
        });
      }
    } catch {
      onDone();
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: "Failed to update the financier information, please try again or contact our developer",
      });
    }
  };

  return (
    <Formik
      onSubmit={sendFinancier}
      initialValues={{
        financier_name: spaRecord.financierName,
        financier_pic: spaRecord.financierPic,
        financier_pic_contact: spaRecord.financierPicContact,
        financier_branch: spaRecord.financierBranch,
        financier_branch_address: spaRecord.financierBranchAddress,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="text-uppercase" as={"h6"}>
              Edit Financier Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Financier Name</Form.Label>
              <Form.Control className="text-uppercase" type="text" value={values.financier_name} onChange={(e) => handleChange("financier_name")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Financier PIC</Form.Label>
              <Form.Control className="text-uppercase" type="text" value={values.financier_pic} onChange={(e) => handleChange("financier_pic")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Financier PIC Contact</Form.Label>
              <Form.Control type="text" value={values.financier_pic_contact} onChange={(e) => handleChange("financier_pic_contact")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Financier Branch</Form.Label>
              <Form.Control className="text-uppercase" type="text" value={values.financier_branch} onChange={(e) => handleChange("financier_branch")(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">Financier Branch Address</Form.Label>
              <Form.Control className="text-uppercase" as="textarea" rows={3} value={values.financier_branch_address} onChange={(e) => handleChange("financier_branch_address")(e.target.value)} style={{ resize: "none" }} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn op-button op-primary-color text-light shadow">
              Save
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default EditFinancierInformation;
