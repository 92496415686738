import React, { useState, useEffect, useMemo, useRef } from "react";
import Calendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import * as bootstrap from "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../lib/css/op-calendar.css";
import { Container, Modal, Button, Form, Row, Col, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { useActivity } from "./Activity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faDollarCircle, faPhoneVolume, faStopwatch, faUsers, faEnvelopeOpenText, faFlag } from "@fortawesome/pro-duotone-svg-icons";
import { FormSelect } from "../includes/FormCustom";
import { Editor } from "@tinymce/tinymce-react";
import axios from "../api/axios";
import NoteEditor from "../includes/NoteEditor";
import * as formik from "formik";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";


const durationList = [
  { label: "5 Mins", value: "5min" },
  { label: "10 Mins", value: "10min" },
  { label: "15 Mins", value: "15min" },
  { label: "20 Mins", value: "20min" },
  { label: "30 Mins", value: "30min" },
  { label: "35 Mins", value: "35min" },
  { label: "40 Mins", value: "40min" },
  { label: "45 Mins", value: "45min" },
  { label: "50 Mins", value: "50min" },
  { label: "55 Mins", value: "55min" },
  { label: "1 Hour", value: "60min" },
  { label: "1 Hour 30 Mins", value: "90min" },
  { label: "2 Hours", value: "120min" },
  { label: "2 Hours 30 Mins", value: "150min" },
  { label: "3 Hours", value: "180min" },
  { label: "3 Hours 30 Mins", value: "210min" },
  { label: "4 Hours", value: "240min" },
  { label: "4 Hours 30 Mins", value: "270min" },
  { label: "5 Hours", value: "240min" },
  { label: "5 Hours 30 Mins", value: "330min" },
  { label: "6 Hours", value: "240min" },
  { label: "6 Hours 30 Mins", value: "390min" },
  { label: "7 Hours", value: "240min" },
  { label: "7 Hours 30 Mins", value: "450min" },
  { label: "8 Hours", value: "240min" },
  { label: "8 Hours 30 Mins", value: "510min" },
  { label: "9 Hours", value: "240min" },
  { label: "9 Hours 30 Mins", value: "570min" },
  { label: "10 Hours", value: "240min" },
];

const timings = [
  { label: "8:00", value: "08:00" },
  { label: "8:15", value: "08:15" },
  { label: "8:30", value: "08:30" },
  { label: "8:45", value: "08:45" },
  { label: "9:00", value: "09:00" },
  { label: "9:15", value: "09:15" },
  { label: "9:30", value: "09:30" },
  { label: "9:45", value: "09:45" },
  { label: "10:00", value: "10:00" },
  { label: "10:15", value: "10:15" },
  { label: "10:30", value: "10:30" },
  { label: "10:45", value: "10:45" },
  { label: "11:00", value: "11:00" },
  { label: "11:15", value: "11:15" },
  { label: "11:30", value: "11:30" },
  { label: "11:45", value: "11:45" },
  { label: "12:00", value: "12:00" },
  { label: "12:15", value: "12:15" },
  { label: "12:30", value: "12:30" },
  { label: "12:45", value: "12:45" },
  { label: "13:00", value: "13:00" },
  { label: "13:15", value: "13:15" },
  { label: "13:30", value: "13:30" },
  { label: "13:45", value: "13:45" },
  { label: "14:00", value: "14:00" },
  { label: "14:15", value: "14:15" },
  { label: "14:30", value: "14:30" },
  { label: "14:45", value: "14:45" },
  { label: "15:00", value: "15:00" },
  { label: "15:15", value: "15:15" },
  { label: "15:30", value: "15:30" },
  { label: "15:45", value: "15:45" },
  { label: "16:00", value: "16:00" },
  { label: "16:15", value: "16:15" },
  { label: "16:30", value: "16:30" },
  { label: "16:45", value: "16:45" },
  { label: "17:00", value: "17:00" },
  { label: "17:15", value: "17:15" },
  { label: "17:30", value: "17:30" },
  { label: "17:45", value: "17:45" },
  { label: "18:00", value: "18:00" },
  { label: "18:15", value: "18:15" },
  { label: "18:30", value: "18:30" },
  { label: "18:45", value: "18:45" },
  { label: "19:00", value: "19:00" },
  { label: "19:15", value: "19:15" },
  { label: "19:30", value: "19:30" },
  { label: "19:45", value: "19:45" },
  { label: "20:00", value: "20:00" },
  { label: "20:15", value: "20:15" },
  { label: "20:30", value: "20:30" },
  { label: "20:45", value: "20:45" },
  { label: "21:00", value: "21:00" },
  { label: "21:15", value: "21:15" },
  { label: "21:30", value: "21:30" },
  { label: "21:45", value: "21:45" },
  { label: "22:00", value: "22:00" },
];


const ActivityCalendar = () => {
  const { t } = useTranslation();
  const { ctxActivity } = useActivity();
  const { session } = useAuth();
  const { Formik } = formik;
  const initialized = useRef(false);
  const addActivityRefNotes = useRef(null);
  const [allActivityList, setAllActivityList] = useState([]);
  const [selectedDatTitleIndex, setSelectedDatTitleIndex] = useState("all");
  const currentLocation = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [getFormattedDate, setGetFormattedDate] = useState([]);
  const [getFormattedTime, setGetFormattedTime] = useState([]);
  const [getFormattedDuration, setGetFormattedDuration] = useState([]);
  const [activityUser, setActivityUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ label: "", value: "" });
  const [getEditedData, setGetEditedData] = useState([]);


  useEffect(() => {
    sessionStorage.removeItem("recentActivity");
    sessionStorage.setItem("recentActivity", currentLocation.pathname);
  }, [currentLocation]);

  // users option
  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const response = await axios.get("ws/ws_user.php", {
          params: {
            task: "4b",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;

        if (data && Array.isArray(data.record)) {
          // setUserOptions(data.record);
          return data.record;
        } else {
          console.error("Invalid data format:", data);
          return [];
        }
      } catch (error) {
        console.error("Error fetching user options:", error);
        return [];
      }
    };

    fetchUserOptions()
      .then((options) => {
        // setUserOptions(options);
      })
      .catch((error) => console.error("Error fetching user options:", error));
  }, [session]);

  useEffect(() => {
    if ((ctxActivity && ctxActivity.user) || ctxActivity.user === "") {
      fetchSelectedUserOptions(ctxActivity.user || "", ctxActivity.customActivity || "", ctxActivity.activity || "");
    }
  }, [ctxActivity.user, ctxActivity.customActivity, ctxActivity.activity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((ctxActivity && ctxActivity.customActivity) || ctxActivity.customActivity === "") {
      fetchSelectedActivityOptions(ctxActivity.user || "", ctxActivity.customActivity || "", ctxActivity.activity || "");
    }
  }, [ctxActivity.user, ctxActivity.customActivity, selectedDatTitleIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if ((ctxActivity && ctxActivity.activity) || ctxActivity.activity === "") {
      handleDatTitleClick(ctxActivity.user || "", ctxActivity.customActivity || "", ctxActivity.activity || "");
    }
  }, [ctxActivity.user, ctxActivity.customActivity, ctxActivity.activity]); // eslint-disable-line react-hooks/exhaustive-deps

  // filter calendar activity based on selected user option
  const fetchSelectedUserOptions = async (selectedUserId, selectedActivity, selectedDatTitleIndex) => {
    try {
      setIsLoading(true);
      let url;

      if (selectedUserId && selectedActivity === "" && selectedDatTitleIndex === "") {
        url = `ws/ws_deal.php?task=getActivities&user=${selectedUserId}&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;
      } else if (selectedUserId === "" && selectedActivity && selectedDatTitleIndex === "") {
        url = `ws/ws_deal.php?task=getActivities&ctoken=${session.company_token}&utoken=${session.user_ac_token}&ca_id=${selectedActivity}`;
      } else {
        url = `ws/ws_deal.php?task=getActivities&user=${selectedUserId}&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;

        // Include selected activity and dat_title in the URL if not 'all'
        if (selectedActivity && selectedActivity !== "0") {
          url += `&ca_id=${selectedActivity}`;
        }

        if (selectedDatTitleIndex !== "all") {
          url += `&dat=${selectedDatTitleIndex}`;
        }
      }

      const response = await axios.get(url);

      const data = response.data;

      if (data && Array.isArray(data.record)) {
        const events = data.record.map((activity) => ({
          title: activity.prospect_name || "",
          start: activity.activity_dated + "T" + activity.activity_timed,
          username: activity.user_name,
          actTitle: activity.activity_title || "",
          datTitle: activity.dat_title || "",
          datId: activity.dat_id || "",
          actDone: activity.activity_mark_as_done,
          eventImage: getEventImage(activity.dat_id),
          cancel: activity.cancel || false,
          activityId: activity.activity_id || "",
        }));
        setAllActivityList(events);

        // return events;
      } else {
        console.error("Invalid data format:", data);
        setAllActivityList([]);
        return [];
      }
    } catch (error) {
      console.error("Error fetching selected user data:", error);
      return [];
    } finally {
      // Hide loader when data fetching is completed
      setIsLoading(false);
    }
  };

  // activity option
  useEffect(() => {
    const fetchActivityOptions = async () => {
      try {
        const response = await axios.get(`ws/ws_deal.php?ctoken=${session.company_token}&task=41&utoken=${session.user_ac_token}`);

        const data = await response.data;

        if (data && Array.isArray(data.record)) {
          // setActivityOptions(data.record);
          return data.record;
        } else {
          console.error("Invalid data format:", data);
          return [];
        }
      } catch (error) {
        console.error("Error fetching user options:", error);
        return [];
      }
    };

    fetchActivityOptions()
      .then((options) => {
        // setActivityOptions(options);
      })
      .catch((error) => console.error("Error fetching activity options:", error));
  }, [session]);

  // filter calendar activity based on selected activity option
  const fetchSelectedActivityOptions = async (selectedUser, selectedActivityId, selectedDatTitleIndex) => {
    try {
      setIsLoading(true);

      let url;

      if (selectedActivityId === "" && selectedUser === "" && selectedDatTitleIndex === "") {
        url = `ws/ws_deal.php?task=getActivities&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;
      } else if (selectedActivityId === "" && selectedUser && selectedDatTitleIndex === "") {
        url = `ws/ws_deal.php?task=getActivities&user=${selectedUser}&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;
      } else {
        url = `ws/ws_deal.php?task=getActivities&user=${selectedUser}&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;

        // Include selected dat_title and activity in the URL if not 'all'
        if (selectedDatTitleIndex && selectedDatTitleIndex !== "0") {
          url += `&dat=${selectedDatTitleIndex}`;
        } else {
          url += `&dat=${""}`;
        }

        if (selectedActivityId && selectedActivityId !== "0") {
          url += `&ca_id=${selectedActivityId}`;
        } else {
          url += `&ca_id=${selectedActivityId}`;
        }
      }

      const response = await axios.get(url);
      const data = await response.data;

      if (data && Array.isArray(data.record)) {
        const events = data.record.map((activity) => ({
          title: activity.prospect_name || "",
          start: activity.activity_dated + "T" + activity.activity_timed,
          username: activity.user_name,
          actTitle: activity.activity_title || "",
          datTitle: activity.dat_title || "",
          datId: activity.dat_id || "",
          actDone: activity.activity_mark_as_done,
          eventImage: getEventImage(activity.dat_id),
          cancel: activity.cancel || false,
          activityId: activity.activity_id || "",
        }));
        setAllActivityList(events);

        // return events;
      } else {
        console.error("Invalid data format:", data);
        setAllActivityList([]);
        return [];
      }
    } catch (error) {
      console.error("Error fetching selected activity data:", error);
      return [];
    } finally {
      // Hide loader when data fetching is completed
      setIsLoading(false);
    }
  };

  // get all activity list
  const fetchActivityList = async (start, end, selectedUser, selectedActivity, selectedDatTitleIndex) => {
    try {
      setIsLoading(true);

      let url;
      if (start && end) {
        const selectedMonth = start.getMonth() + 1;
        const selectedYear = start.getFullYear();

        url = `ws/ws_deal.php?task=getActivities&user=${selectedUser}&ctoken=${session.company_token}&utoken=${session.user_ac_token}&ca_id=${selectedActivity}&date=${selectedYear}/${selectedMonth.toString().padStart(2, "0")}/01`;

        if (selectedDatTitleIndex !== "all") {
          url = `ws/ws_deal.php?task=getActivities&user=${selectedUser}&ctoken=${session.company_token}&utoken=${session.user_ac_token}&ca_id=${selectedActivity}&dat=${selectedDatTitleIndex}&date=${selectedYear}/${selectedMonth.toString().padStart(2, "0")}/01`;
        }
      }

      const response = await axios.get(url);
      const data = await response.data;

      if (data && Array.isArray(data.record)) {
        const events = data.record.map((activity) => ({
          title: activity.prospect_name || "",
          start: activity.activity_dated + "T" + activity.activity_timed,
          username: activity.user_name,
          actTitle: activity.activity_title || "",
          datTitle: activity.dat_title || "",
          datId: activity.dat_id || "",
          actDone: activity.activity_mark_as_done,
          eventImage: getEventImage(activity.dat_id),
          cancel: activity.cancel || false,
          activityId: activity.activity_id || "",
        }));

        setAllActivityList(events);
      }
    } catch (error) {
      console.error("Error fetching activity options:", error);
      setAllActivityList([]);
    } finally {
      setIsLoading(false);
    }
  };

  // get the image for event
  const getEventImage = (datId) => {
    if (datId) {
      return `${session.hostUrl}/${session.hostUrlType}/lib/icon/${datId}_black.png`;
    } else {
      return null;
    }
  };

  // get all dat_title (total 5)
  useEffect(() => {
    const fetchAllDatTitle = async () => {
      try {
        const response = await axios.get("ws/ws_setting.php?task=4");
        const data = response.data;

        if (data && Array.isArray(data.record)) {
          // setDatTitleOptions(data.record);
          return data.record;
        } else {
          console.error("Invalid data format:", data);
          return [];
        }
      } catch (error) {
        console.error("Error fetching dat title options:", error);
        return [];
      }
    };

    fetchAllDatTitle().catch((error) => console.error("Error fetching dat title options:", error));
  }, []);

  // handle get content based on selected dat
  const handleDatTitleClick = async (selectedUser, selectedActivity, optionIndex) => {
    try {
      setIsLoading(true);

      let url;

      if (optionIndex === "all" && selectedUser === "" && selectedActivity === "") {
        url = `ws/ws_deal.php?task=getActivities&ctoken=${session.company_token}&utoken=${session.user_ac_token}`;
      } else if (optionIndex === "all" && selectedUser && selectedActivity === "") {
        url = `ws/ws_deal.php?task=getActivities&user=${selectedUser}&ctoken=${session.user_ac_token}&utoken=${session.company_token}`;
      } else if (optionIndex === "all" && selectedUser === "" && selectedActivity) {
        url = `ws/ws_deal.php?task=getActivities&ctoken=${session.company_token}&utoken=${session.user_ac_token}&ca_id=${selectedActivity}`;
      } else {
        url = `ws/ws_deal.php?task=getActivities&dat=${optionIndex === "0" ? "" : optionIndex}&ctoken=${session.company_token}&utoken=${session.user_ac_token}&date=`;

        // Include selected user and activity in the URL
        if (selectedUser) {
          url += `&user=${selectedUser}`;
        }
        if (selectedActivity) {
          url += `&ca_id=${selectedActivity}`;
        }
      }

      const response = await axios.get(url);
      const data = response.data;

      if (data && Array.isArray(data.record)) {
        const events = data.record.map((activity) => ({
          title: activity.prospect_name || "",
          start: activity.activity_dated + "T" + activity.activity_timed,
          username: activity.user_name,
          actTitle: activity.activity_title || "",
          datTitle: activity.dat_title || "",
          datId: activity.dat_id || "",
          actDone: activity.activity_mark_as_done,
          eventImage: getEventImage(activity.dat_id),
          cancel: activity.cancel || false,
          activityId: activity.activity_id || "",
        }));

        setAllActivityList(events);
        setSelectedDatTitleIndex(optionIndex);
      } else {
        console.error("Invalid data format:", data);
        setAllActivityList([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAllActivityList([]);
    } finally {
      setIsLoading(false);
    }
  };

  // styling for event in calendar
  const eventContent = useMemo(() => {
    return (arg) => {
      const allEventImage = arg.event.extendedProps.eventImage;
      const actDone = arg.event.extendedProps.actDone;
      const cancel = arg.event.extendedProps.cancel;

      let textColor = actDone === "yes" ? "green" : "red";
      let bgColor = actDone === "yes" ? "#d0e1c9" : "#f2dede";

      if (cancel === "1") {
        textColor = "black";
        bgColor = "#bbb";
      }

      const cancelText = cancel === "1" ? "Cancelled - " : "";

      return (
        <div style={{ overflow: "hidden", textOverflow: "ellipsis", color: textColor, backgroundColor: bgColor, paddingLeft: allEventImage ? "0" : "20px" }}>
          {arg.event.extendedProps.eventImage && <img src={allEventImage} alt="imageevent" style={{ height: "18px", paddingRight: "5px", paddingBottom: "3px" }} />}
          {cancelText}{arg.timeText}m ({arg.event.extendedProps.username}) {arg.event.title} {arg.event.extendedProps.actTitle}
        </div>
      );
    };
  }, []);

  const getUser = async () => {
    try {
      const response = await axios.get("ws/ws_user.php", {
        params: {
          task: "4b",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        var recordOptions = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        recordOptions.unshift({
          label: "All User",
          value: 0,
        });

        var recordOptions2 = data.record.map((record) => ({
          label: record.user_name,
          value: record.user_id,
        }));

        setActivityUser(recordOptions2);
      } else {
        // console.error(error)
      }
    } catch (error) {
      console.error(error)
    }
  };

  const getEditedActivity = async (activityId) => {
    try {
      const response = await axios.get("ws/ws_deal.php", {
        params: {
          activity: activityId,
          task: "18",
        },
      });

      const data = response.data;

      if (data.status == '0' && data.record) {
        const user = activityUser.find(user => user.value === data.record.assign_user_id);
        setSelectedUser(user);
        setGetEditedData(data.record);
      } else {
        setGetEditedData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // event click open edit
  const handleEventClick = async (info) => {
    const event = {
      title: info.event.title,
      start: info.event.start.toISOString(),
      username: info.event.extendedProps.username,
      actTitle: info.event.extendedProps.actTitle,
      datTitle: info.event.extendedProps.datTitle,
      actDone: info.event.extendedProps.actDone,
      cancel: info.event.extendedProps.cancel,
      datId: info.event.extendedProps.datId,
      activityId: info.event.extendedProps.activityId,
    };

    if (event.start) {
      const jsDate = new Date(event.start);
      const year = jsDate.getFullYear();
      const month = (jsDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
      const day = jsDate.getDate().toString().padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;

      const hours = jsDate.getHours();
      const minutes = jsDate.getMinutes();
      const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

      setGetFormattedDate(formattedDate);
      setGetFormattedTime(formattedTime)
    }

    setSelectedEvent(event);

    await getEditedActivity(event.activityId);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const handleActTitleChange = (e) => {
    setSelectedEvent({ ...selectedEvent, actTitle: e.target.value });
  };

  const handleDescriptionChange = (content) => {
    setSelectedEvent(prevState => ({
      ...prevState,
      description: content
    }));
  };

  const handleDatIdChange = (value) => {
    setSelectedEvent({ ...selectedEvent, datId: value });
  };

  const handleUpdate = async (values) => {
    try {
      const payload = {
        activity_id: selectedEvent.activityId,
        user_id: selectedUser.value,
        ca_id: getEditedData.ca_id,
        description: selectedEvent.description || values.activity_content || getEditedData.activity_content,
        create_user: getEditedData.create_user_id,
        dat_id: selectedEvent.datId,
        dated: getFormattedDate,
        duration: getFormattedDuration || getEditedData.activity_duration,
        mad: "",
        task: "12",
        timed: getFormattedTime,
        title: selectedEvent.actTitle,
      };

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          aid: payload.activity_id,
          assign_user: payload.user_id,
          ca_id: payload.ca_id,
          content: payload.description,
          create_user: payload.create_user,
          dat: payload.dat_id,
          dated: payload.dated,
          duration: payload.duration,
          mad: "",
          task: "12",
          timed: payload.timed,
          title: payload.title,
        },
      })

      var data = response.data;
      if (data.status == '0') {
        handleCloseModal();
        fetchSelectedUserOptions(selectedUser.value, getEditedData.ca_id, selectedDatTitleIndex);
      }
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };


  // when user option or custom activity or dat title is selected will back to current date
  const calendarRef = useRef(null);
  useEffect(() => {
    if (selectedDatTitleIndex || !selectedDatTitleIndex) {
      setTimeout(0);
    }

    return () => clearTimeout();
  }, [selectedDatTitleIndex]);

  useEffect(() => {
    const initializeData = async () => {
      if (!initialized.current) {
        initialized.current = true;
        try {
          await getUser();
        } catch (error) {
          console.error(error);
        }
      }
    };

    initializeData();
  }, []);

  return (
    <Container fluid className="px-3 py-3" style={{ padding: 0 }}>
      <div style={{ height: "75vh" }} className={`calendar-fs ${isLoading ? "loading" : ""}`}>
        <Calendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={allActivityList}
          eventContent={eventContent}
          eventClick={handleEventClick}
          firstDay={1}
          views={{
            dayGridMonth: {
              dayMaxEventRows: 2,
            },
          }}
          headerToolbar={{
            start: "prev,next today",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          dayPopoverFormat={{
            day: "numeric",
            weekday: "long",
            month: "long",
            year: "numeric",
          }}
          eventDidMount={(info) => {
            const actTitle = info.event.extendedProps.actTitle;
            const datTitle = info.event.extendedProps.datTitle;
            const title = datTitle !== "" ? datTitle : actTitle || "";

            const cancel = info.event.extendedProps.cancel;
            const cancelText = cancel == "1" ? " - Cancelled" : "";

            const content = info.event.extendedProps.username + ": " + info.event.title + " " + info.event.extendedProps.actTitle + " " + cancelText;

            const popover = new bootstrap.Popover(info.el, {
              title: title,
              placement: "auto",
              trigger: "hover",
              customClass: "popoverStyle",
              content: content,
              html: true,
            });

            info.el.style.cursor = "pointer";
            return popover;
          }}
          height={"100%"}
          datesSet={(arg) => {
            const title = arg.view.title;
            const [year, month] = title.split(" ");
            const start = new Date(`${year}-${month}-01`);
            const end = new Date(`${year}-${month}-31T23:59:59.999`);

            fetchActivityList(start, end, ctxActivity.user, ctxActivity.customActivity, ctxActivity.activity);
          }}
        />
        {isLoading && <div className="calendar-loader"></div>}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Formik
          onSubmit={handleUpdate}
          initialValues={
            {
              activity_content: getEditedData ? getEditedData.activity_content : "",
            }
          }
        >
          {({ handleSubmit, handleChange, values, errors, touched }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t("ActivityCalendar_event_details")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedEvent && (
                  <div>
                    <div className="d-flex mb-3">
                      {getEditedData.deal_title && (
                        <div className="me-3">
                          <FontAwesomeIcon icon={faDollarCircle} className="me-1" /> {getEditedData.deal_title}
                        </div>
                      )}
                      {selectedEvent.username && (
                        <div>
                          <FontAwesomeIcon icon={faUser} /> {selectedEvent.username}
                        </div>
                      )}
                    </div>

                    <div className="d-flex mb-3">
                      <Form.Group>
                        <ButtonGroup className="activity-tab">
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={selectedEvent.datId === "1"} onChange={() => handleDatIdChange("1")}>
                            <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                            {t("ActivityCalendar_call")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={selectedEvent.datId === "2"} onChange={() => handleDatIdChange("2")}>
                            <FontAwesomeIcon icon={faUsers} className="me-1" />
                            {t("ActivityCalendar_meeting")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={selectedEvent.datId === "3"} onChange={() => handleDatIdChange("3")}>
                            <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                            {t("ActivityCalendar_task")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={selectedEvent.datId === "4"} onChange={() => handleDatIdChange("4")}>
                            <FontAwesomeIcon icon={faFlag} className="me-1" />
                            {t("ActivityCalendar_deadline")}
                          </ToggleButton>
                          <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={selectedEvent.datId === "5"} onChange={() => handleDatIdChange("5")}>
                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                            {t("ActivityCalendar_email")}
                          </ToggleButton>
                        </ButtonGroup>
                      </Form.Group>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "100%" }}>
                        <Form.Group>
                          <Form.Control type="text" placeholder={t("ActivityCalendar_activity_title")} value={selectedEvent.actTitle} onChange={handleActTitleChange} />
                        </Form.Group>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Control
                                type="date"
                                value={getFormattedDate}
                                onChange={(e) => setGetFormattedDate(e.target.value)}
                              />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect
                                options={timings}
                                isSearchable={true}
                                valueDefault={timings.find(option => option.value === getFormattedTime)}
                                placeholder={t("ActivityCalendar_select_time")}
                                onChange={(selectedOption) => setGetFormattedTime(selectedOption.value)}
                              />
                            </Form.Group>
                            <Form.Group as={Col}>
                              <FormSelect
                                options={durationList}
                                valueDefault={durationList.find(option => option.value === getEditedData.activity_duration)}
                                placeholder={t("ActivityCalendar_select_duration")}
                                onChange={(selectedOption) => setGetFormattedDuration(selectedOption.value)}
                              />
                            </Form.Group>
                          </Row>
                        </div>
                      </div>

                      <div>{t("ActivityCalendar_assigned_to")}</div>
                      <div className="d-flex mb-3">
                        <div style={{ width: "100%" }}>
                          <Form.Group>
                            <FormSelect
                              placeholder={t("ActivityCalendar_select_user")}
                              options={activityUser}
                              valueDefault={activityUser.find(option => option.value === getEditedData.assign_user_id)} // Set the value to selectedUser
                              onChange={(selectedOption) => setSelectedUser(selectedOption ? selectedOption : { label: "", value: "" })}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex mb-3">
                      <div style={{ width: "100%" }}>
                        <Form.Group className="activity-notes">
                          <NoteEditor value={values.activity_content} onChangeValue={(value) => handleChange("activity_content")(value)} />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  {t("ActivityCalendar_close")}
                </Button>
                <Button variant="primary" type="submit" >
                  {t("ActivityCalendar_update")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default ActivityCalendar;
