import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Organization = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/organization" || location.pathname === "/organization/") {
      navigate("/organization/list", { replace: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid style={{ margin: 0, padding: 0 }}>
      <Outlet />
    </Container>
  );
};

export default Organization;
