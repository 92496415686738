import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack, Modal, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMagnifyingGlassArrowRight, faHouseCircleCheck, faDownload } from "@fortawesome/pro-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InterestList = () => {
    const { t } = useTranslation();
    const init = useRef(false);
    const gridRef = useRef();
    const { session } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [interestData, setInterestData] = useState([]);
    const [filter, setFilter] = useState({
        project: { label: "All Project", value: "" },
        phase: { label: "All Phase", value: "" },
        unit: { label: "All Unit", value: "" }
    });

    const [projectList, setProjectList] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [isPhase, setIsPhase] = useState(false);
    const [phaseList, setPhaseList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [isUnit, setIsUnit] = useState(false);
    const [applyClicked, setApplyClicked] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [interestList, setInterestList] = useState({
        priority1: [],
        priority2: [],
        priority3: [],
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
    const [user, setuser] = useState([]);
    const [isPageReloaded, setIsPageReloaded] = useState(true);


    const getInterestData = async () => {
        try {
            const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/ws_rea_unit_interest_prod.php", {
                params: {
                    task: "getInterestList",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    rea_phase_id: filter.phase.value || "",
                    rea_unit_id: filter.unit.value,
                    lead_name: "",
                    ds_id: ""
                },
            });

            const data = response.data;
            if (data.status === 0) {
                setInterestData(data.record);
                setLoading(false);
                setIsPageReloaded(false);
            } else {
                setInterestData([]);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const getUser = async () => {
        axios
            .get("ws/ws_user.php", {
                params: {
                    task: "4a",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    user_tag: "",
                },
            })
            .then((response) => {
                var data = response.data;
                if (data.status === 0) {
                    setuser(data.record);
                } else {
                    setuser([]);
                }
            });
    };

    const getProjectList = async () => {
        try {
            const response = await axios.get("ws/ws_rea.php", {
                params: {
                    task: "GetProjectListing",
                    ctoken: session.company_token,
                    utoken: session.user_ac_token,
                },
            });

            const data = response.data;
            if (data.status == 0) {
                const projectOptions = data.record.map((record) => ({
                    label: record.project_name,
                    value: record.rea_project_id,
                }));

                setProjectList(projectOptions);
                setProjectData(data.record);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // ONCHANGE FUNCTION -------------------------------

    const onchangeProject = (project) => {
        return new Promise((resolve, reject) => {
            if (project.value) {
                const projectInfo = projectData.find((record) => record.rea_project_id === project.value);
                const phaseOptions =
                    projectInfo.phase_count > 0
                        ? projectInfo.phase_record.map((record) => ({
                            label: record.phase_name,
                            value: record.rea_phase_id,
                        }))
                        : [];

                setPhaseList(phaseOptions);
                setIsPhase(false);
                setIsUnit(false);

                const defaultPhase = phaseOptions[0] || null;

                setFilter((prevState) => ({
                    ...prevState,
                    project: project,
                    phase: defaultPhase,
                    unit: { label: t("BookingList_all_unit"), value: "" },
                }));

                setTimeout(() => {
                    setIsPhase(true);
                    resolve();
                }, 1);
            } else {
                setPhaseList([]);
                setIsPhase(false);
                setIsUnit(false);
                setFilter((prevState) => ({
                    ...prevState,
                    project: { label: t("BookingList_all_project"), value: "" },
                    phase: { label: t("BookingList_all_phase"), value: "" },
                    unit: { label: t("BookingList_all_unit"), value: "" },
                }));

                resolve();
            }
        });
    };

    const onchangePhase = async (phase) => {
        if (phase.value) {
            try {
                const interestResponse = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/ws_rea_unit_interest_prod.php", {
                    params: {
                        task: "getInterestList",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        rea_phase_id: phase.value || "",
                        rea_unit_id: filter.unit.value,
                        lead_name: "",
                        ds_id: ""
                    },
                });
                const interestData = interestResponse.data.record;

                if (interestData && interestData.length > 0) {
                    const interestlist = Array.from(interestData.reduce((uniqueUnits, record) => {
                        if (!uniqueUnits.has(record.unit_number)) {
                            uniqueUnits.set(record.unit_number, record.interested_unit_id);
                        }
                        return uniqueUnits;
                    }, new Map()).keys()).map(unitNumber => ({
                        label: unitNumber,
                        value: interestData.find(record => record.unit_number === unitNumber).interested_unit_id,
                    }));

                    interestlist.unshift({
                        label: t("BookingList_all_unit"),
                        value: "",
                    });

                    setUnitList(interestlist);
                    setIsUnit(false);
                    setFilter((prevState) => ({
                        ...prevState,
                        phase: phase,
                        unit: { label: t("BookingList_all_unit"), value: "" },
                    }));
                    setTimeout(() => setIsUnit(true), 1);
                } else {
                    setUnitList([]);
                    setIsUnit(false);
                    setFilter((prevState) => ({
                        ...prevState,
                        phase: phase,
                        unit: { label: t("BookingList_all_unit"), value: "" },
                    }));
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            setUnitList([]);
            setIsUnit(false);
            setFilter((prevState) => ({
                ...prevState,
                phase: { label: t("BookingList_all_phase"), value: "" },
                unit: { label: t("BookingList_all_unit"), value: "" },
            }));
        }
    };

    const onchangeUnit = (unit) => {
        setFilter((prevState) => ({ ...prevState, unit: unit }));
    };

    const setUserName = (params, user) => {
        const matchingUser = user.find(user => user.user_id === params.value);

        if (matchingUser) {
            return matchingUser.user_name;
        } else {
            return "";
        }
    }

    // GRID FUNCTION ----------------------------------------

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        { headerName: "Lead Name", field: "lead_name", width: 250 },
        { headerName: "Contact Number", field: "lead_contact", width: 200 },
        { headerName: "Username", field: "user_name", width: 200, cellRenderer: (params) => setUserName(params, user) },
        { headerName: "Unit Number", field: "unit_number", width: 150 },
        { headerName: "Priority", field: "interested_phase_unit_priority", width: 150 },
        { headerName: "Unit Status", field: "unit_status", width: 200 },
        {
            headerName: "",
            field: "",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                const { unit_status } = params.data;
                return (
                    <div className="d-flex">
                        {unit_status === "available" && (
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Book Now</Tooltip>}
                            >
                                <Button data-action="book" variant="primary" className="me-2" style={{ padding: "0.2rem 0.5rem", fontSize: "0.8rem" }}>
                                    <FontAwesomeIcon icon={faHouseCircleCheck} />
                                </Button>
                            </OverlayTrigger>
                        )}
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Interest Priority</Tooltip>}
                        >
                            <Button data-action="find" variant="info" className="me-2" style={{ padding: "0.2rem 0.5rem", fontSize: "0.8rem" }} >
                                <FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />
                            </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <Button data-action="trash" variant="danger" className="me-2" style={{ padding: "0.2rem 0.5rem", fontSize: "0.8rem" }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ];

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "book":
                    handleBookClick(rowData);
                    break;
                case "find":
                    handleFindClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleBookClick = (params) => {
        navigate(`/booking-form/${params.interested_unit_id}/0/${params.prospect_id}`);
    }

    const handleFindClick = (params) => {
        setShowModal(true);

        axios.get("ws/ws_rea_unit_interest.php", {
            params: {
                task: "getUnitInterests",
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                phase_id: params.interested_phase_id,
                prospect_id: params.prospect_id,
                unit_id: params.interested_unit_id
            },
        }).then(response => {
            const data = response.data

            if (data.status === 0) {
                const priority1 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 1);
                const priority2 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 2);
                const priority3 = data.record.filter((record) => Number(record.interested_phase_unit_priority) === 3);
                setInterestList({
                    priority1: priority1,
                    priority2: priority2,
                    priority3: priority3,
                });
            }
        })
    };

    const handleCloseModal = () => {
        setShowModal(false); // Close the modal
    };

    const handleTrashClick = (params) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get("ws/ws_rea_unit_interest.php", {
                    params: {
                        task: "removeUnitInterests",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        phase_id: params.interested_phase_id,
                        prospect_id: params.prospect_id,
                        unit_id: params.interested_unit_id
                    },
                }).then(response => {
                    if (response.data.status == '0') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Item removed successfully!',
                            timer: 1500
                        });

                        const updatedData = interestData.filter(item => item.interested_unit_id !== params.interested_unit_id);
                        setInterestData(updatedData);
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',
                            timer: 1500
                        });
                    }
                }).catch(error => {
                    console.error('Error:', error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        timer: 1500
                    });
                });
            }
        });
    }

    const handleDownload = () => {
        var params = {
            fileName: "dataExport.csv",
            processCellCallback: function (params) {
                var res = params.value;
                if (res != null && res != "") {
                    res = res.replace(/<br>/g, "\r\n");
                    res = res.replace(/<li>/g, "\r\n");
                    res = res.replace(/<[^>]+>/g, "");
                    res = res.replace(/&nbsp;/g, "");
                }

                return res;
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    // SEARCH TERM -----------------------------------------------------

    useEffect(() => {
        const delay = 500;

        const delaySearch = setTimeout(() => {
            setDelayedSearchTerm(searchTerm);
        }, delay);

        return () => clearTimeout(delaySearch);
    }, [searchTerm]);

    const getQuickFilterText = (params) => {
        const textFields = ["lead_name", "lead_contact"];
        const searchTermLowerCase = delayedSearchTerm.toLowerCase(); // Use delayed search term
        return textFields.map((field) => params.data[field]).join(' ').toLowerCase().includes(searchTermLowerCase);
    };

    // USEEFFECT ------------------------------------------------------

    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getProjectList();
                    await getUser();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session]);

    useEffect(() => {
        if (projectList.length > 0) {
            const defaultProject = projectList[0];
            onchangeProject(defaultProject);
        }
    }, [projectList]);

    useEffect(() => {
        if (phaseList.length > 0) {
            const defaultPhase = phaseList[0];
            onchangePhase(defaultPhase);
        }
    }, [phaseList]);


    // Modify useEffect to conditionally fetch interest data
    useEffect(() => {
        const fetchData = async () => {
            await getInterestData();
        };

        if (isPageReloaded) {
            fetchData();
        }

    }, [session, filter.phase, isPageReloaded]);



    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <Row>
                        <Col xxl={12}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <Form.Control type="text" className="me-1 shadow-sm border-0" placeholder={t("Users_search")} style={{ width: 200 }} value={searchTerm || ''} onChange={(e) => setSearchTerm(e.target.value)} />
                                </div>
                                <Stack direction="horizontal" gap={1} style={{ justifyContent: 'flex-end' }}>
                                    {projectList.length > 0 &&
                                        <Form.Group>
                                            <FormSelect
                                                options={projectList}
                                                valueDefault={projectList.filter(opt => opt.value == projectList[0].value)}
                                                onChange={(e) => onchangeProject(e)}
                                                width="150px"
                                                shadow={true}
                                                border={false}
                                            />
                                        </Form.Group>
                                    }

                                    {isPhase > 0 &&
                                        <Form.Group>
                                            <FormSelect
                                                options={phaseList}
                                                valueDefault={filter.phase}
                                                onChange={(e) => onchangePhase(e)}
                                                width="150px"
                                                shadow={true}
                                                border={false}
                                            />
                                        </Form.Group>
                                    }

                                    {isUnit && (
                                        <Form.Group>
                                            <FormSelect
                                                options={unitList}
                                                valueDefault={filter.unit} 
                                                onChange={(e) => onchangeUnit(e)}
                                                width="150px"
                                                shadow={true}
                                                border={false}
                                            />
                                        </Form.Group>
                                    )}

                                    <Button variant="" className="op-primary-color text-light" onClick={() => { setApplyClicked(true); getInterestData(); }}>
                                        Apply
                                    </Button>

                                    <Button className="btn btn-secondary" onClick={handleDownload}>
                                        <FontAwesomeIcon icon={faDownload} />
                                    </Button>
                                </Stack>
                            </div>
                        </Col>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : interestData} rowHeight={100} pagination={true} onCellClicked={onCellClicked} quickFilterText={delayedSearchTerm} getQuickFilterText={getQuickFilterText} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Unit Interest Priority</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {interestList.priority1.length > 0 && (
                                <Col xxl={12} className="mb-5">
                                    <h6 className="text-center mb-3 op-text-bigger">1st Priority ({interestList.priority1.length})</h6>
                                    <ListGroup as="ol">
                                        {interestList.priority1.map((record, index) => (
                                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                                                <Stack direction="horizontal" className="p-2 align-items-baseline">
                                                    <div>
                                                        <div className="fw-bold cursor-pointer">
                                                            {record.lead_name}
                                                        </div>
                                                        <span>{record.lead_contact}</span> <br />
                                                        <span className="text-muted" style={{ fontSize: 10 }}>
                                                            {moment(new Date(record.date_time_create)).format("ll")}
                                                        </span>
                                                    </div>
                                                    <div className="ms-auto h-100">
                                                        <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                                    </div>
                                                </Stack>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                            )}

                            {interestList.priority2.length > 0 && (
                                <Col xxl={12} className="mb-5">
                                    <h6 className="text-center mb-3 op-text-bigger">2nd Priority ({interestList.priority2.length})</h6>
                                    <ListGroup as="ol">
                                        {interestList.priority2.map((record, index) => (
                                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                                                <Stack direction="horizontal" className="p-2 align-items-baseline">
                                                    <div>
                                                        <div className="fw-bold cursor-pointer">
                                                            {record.lead_name}
                                                        </div>
                                                        <span>{record.lead_contact}</span> <br />
                                                        <span className="text-muted" style={{ fontSize: 10 }}>
                                                            {moment(new Date(record.date_time_create)).format("ll")}
                                                        </span>
                                                    </div>
                                                    <div className="ms-auto h-100">
                                                        <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                                    </div>
                                                </Stack>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                            )}

                            {interestList.priority3.length > 0 && (
                                <Col xxl={12} className="mb-5">
                                    <h6 className="text-center mb-3 op-text-bigger">3rd Priority ({interestList.priority3.length})</h6>
                                    <ListGroup as="ol">
                                        {interestList.priority3.map((record, index) => (
                                            <ListGroup.Item as="li" className="shadow-sm" key={index}>
                                                <Stack direction="horizontal" className="p-2 align-items-baseline">
                                                    <div>
                                                        <div className="fw-bold cursor-pointer">
                                                            {record.lead_name}
                                                        </div>
                                                        <span>{record.lead_contact}</span> <br />
                                                        <span className="text-muted" style={{ fontSize: 10 }}>
                                                            {moment(new Date(record.date_time_create)).format("ll")}
                                                        </span>
                                                    </div>
                                                    <div className="ms-auto h-100">
                                                        <p className="m-0 text-end">{record.ds_title || "No Source"}</p>
                                                    </div>
                                                </Stack>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                            )}
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </Container>
    );
};

export default InterestList;
