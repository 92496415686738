import React, { useState, useEffect, useRef, useMemo } from "react";
import { Row, Col, Form, Button, Card, Container } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { FormDate } from "../includes/FormCustom";
import { AreaChart, Area, ResponsiveContainer, Tooltip, YAxis, XAxis, Legend } from 'recharts';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useAuth } from "../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneArrowUpRight, faPhoneArrowDownLeft, faCirclePlay } from "@fortawesome/pro-duotone-svg-icons";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import '../lib/css/CallAnalytics.css';
import { useTranslation } from "react-i18next";


const CallAnalytics = () => {
    const { t } = useTranslation();
    const initialized = useRef(false);
    const { session } = useAuth();
    const cToken = session.company_token;
    const uToken = session.user_ac_token;

    const [user, setuser] = useState([{ value: '', label: 'All User' }]);
    const [selectedUser, setSelectedUser] = useState({ value: '', label: 'All User' });
    const [selectedStatus, setSelectedStatus] = useState({ value: '', label: 'All Status' });
    const [selectedType, setSelectedType] = useState({ value: '', label: 'All Type' });
    const [chart, setChart] = useState({});
    const [gridData, setGridData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(true);

    const formattedOneMonthAgoDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
    const formattedTodayDate = moment().format('YYYY-MM-DD');
    const [dateRange, setdateRange] = useState({
        ds: formattedOneMonthAgoDate,
        de: formattedTodayDate,
    });

    // date column
    const dateFormat = (params) => {
        const dateTime = params.value;
        const providedDate = moment(dateTime);

        if (dateTime === '0000-00-00 00:00:00') {
            return '';
        } else {
            return providedDate.format('DD-MMM-YYYY');
        }

    };

    // type column
    const callTypeRenderer = (params) => {
        if (params.data) {
            if (params.value === 'outbound') {
                const callType = params.data.type || '';
                const iconHtml = <FontAwesomeIcon icon={faPhoneArrowUpRight} size="lg" className="px-2" />;

                return (
                    <div style={{ whiteSpace: 'normal' }}>
                        {iconHtml}
                        {callType}
                    </div>
                );
            } else if (params.value === 'inbound') {
                const callType = params.data.type || '';
                const iconHtml = <FontAwesomeIcon icon={faPhoneArrowDownLeft} size="lg" className="px-2" />;

                return (
                    <div style={{ whiteSpace: 'normal' }}>
                        {iconHtml}
                        {callType}
                    </div>
                );
            }
        }
        return params.value;
    };

    // phone column
    const phoneNumberRenderer = (params) => {
        if (params.data) {
            const number = params.data.caller_number || '';
            const iconHtml = <FontAwesomeIcon icon={faPhone} size="lg" className="px-2" />;

            const handleClick = () => {
                if (number) {
                    callLead(number);
                } else {
                    console.error('No phone number available for calling.');
                }
            };

            return (
                <div className="text-click cursor-pointer" style={{ whiteSpace: 'normal' }} onClick={handleClick}>
                    {iconHtml}
                    <span className="text-primary">
                        {number}
                    </span>
                </div>
            );
        }
        return params.value;
    };

    function callLead(contactNum) {
        var elem = document.getElementById("callPopup");
        elem.style.display = "block";

        if (contactNum !== '') {
            var url = `https://www.nexcrmapis.com/cloud_dev/ws/ws_avanser.php?task=1&utoken=uToken&ctoken=cToken&contact=${contactNum}`;

            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    setTimeout(function () {
                        // var elem = document.getElementById("callPopup");
                        elem.style.display = "none";
                    }, 5000);
                }
            };
            xhttp.open("GET", url, true);
            xhttp.send();
        } else {
            window.alert(t("CallAnalytics_there_arent_any_contact_number"));

            // elem = document.getElementById("callPopup");
            elem.style.display = "none";
        }
    }

    // prospect column
    const prospectRenderer = (params) => {
        if (params.data) {
            const prospect = params.data.prospect_name || '';

            const handleClick = () => {
                if (params.data.target_crm_contact_id) {
                    const url = `${session.origin}/prospect/${params.data.target_crm_contact_id}`;
                    window.open(url, '_blank');
                }
            };

            return (
                <div
                    className="text-click cursor-pointer text-primary"
                    style={{
                        whiteSpace: 'normal',
                    }}
                    onClick={handleClick}
                >
                    {prospect}
                </div>
            );
        }
        return params.value;
    };

    // audio column
    const audioRenderer = (params) => {
        if (params.data) {
            const iconHtml = params.data.audio_url ? <FontAwesomeIcon icon={faCirclePlay} size="lg" /> : null;

            const handleClick = () => {
                if (params.data.audio_url) {
                    window.open(params.data.audio_url, '_blank');
                }
            };

            return (
                <div style={{ whiteSpace: 'normal', cursor: params.data.audio_url ? 'pointer' : 'default' }} onClick={handleClick}>
                    {iconHtml}
                </div>
            );
        }
        return params.value;
    };

    // GET USER OPTION
    const getUser = () => {
        const userurl = 'ws/ws_user.php';
        const params = {
            ctoken: cToken,
            task: '4a',
            utoken: uToken
        };

        axios.get(userurl, { params })
            .then((response) => {
                var data = response.data;
                const users = data.record.map((user) => ({ value: user.user_id, label: user.user_name }));
                setuser([{ value: '', label: 'All User' }, ...users]);
            })
            .catch((error) => {
                console.error("Error fetching user options: ", error);
            });
    };

    // GET AREA CHART
    const getChart = () => {
        const charturl = 'ws/ws_calls.php';
        const params = {
            ctoken: cToken,
            de: dateRange.de,
            ds: dateRange.ds,
            status: '',
            task: 'callActivityReport',
            type: '',
            user: 0,
            utoken: uToken,
        };

        axios.get(charturl, { params })
            .then((response) => {
                const data = response.data.record;

                const formattedData = data.map(item => ({
                    date: item.DATE,
                    call: item.TOTAL_CALLS
                }));
                setChart(formattedData);
            })
            .catch((error) => {
                console.error("Error fetching chart data:", error);
            });
    };

    const getUpdatedChart = (dateRange = {}, selectedUser, selectedStatus, selectedType) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;
        const user = selectedUser.value;
        const status = selectedStatus.value;
        const type = selectedType.value;

        const charturl = 'ws/ws_calls.php';
        const params = {
            ctoken: cToken,
            de: endDate,
            ds: startDate,
            status: status,
            task: 'callActivityReport',
            type: type,
            user: user,
            utoken: uToken,
        };

        axios.get(charturl, { params })
            .then((response) => {
                const data = response.data.record;
                const formattedData = data.map(item => ({
                    date: item.DATE,
                    call: item.TOTAL_CALLS
                }));
                setChart(formattedData);
                setLoading2(false);
            })
            .catch((error) => {
                console.error("Error fetching monthly deal data:", error);
            })
    };

    // GET GRID DATA
    const getGridData = () => {
        const gridurl = 'ws/ws_calls.php';
        const params = {
            task: 'callActivityData',
            utoken: uToken,
            ctoken: cToken,
            user: 0,
            type: '',
            status: '',
            ds: dateRange.ds,
            de: dateRange.de,
        };

        axios.get(gridurl, { params })
            .then((response) => {
                const data = response.data.record;
                if (data) {
                    setGridData(data);
                } else {
                    setGridData([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching grid data:", error);
            });
    }

    const getUpdatedGridData = (dateRange = {}, selectedUser, selectedStatus, selectedType) => {
        const startDate = dateRange.ds;
        const endDate = dateRange.de;
        const user = selectedUser.value;
        const status = selectedStatus.value;
        const type = selectedType.value;

        const gridurl = 'ws/ws_calls.php';
        const params = {
            task: 'callActivityData',
            utoken: uToken,
            ctoken: cToken,
            user: user,
            type: type,
            status: status,
            ds: startDate,
            de: endDate,
        };

        axios.get(gridurl, { params })
            .then((response) => {
                const data = response.data.record;
                if (data) {
                    setGridData(data);
                } else {
                    setGridData([]);
                }
                setLoading2(false);
            })
            .catch((error) => {
                console.error("Error fetching grid data:", error);
            })
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridColumn = [
        { headerName: t("CallAnalytics_date_added"), field: "call_date_time_create", cellRenderer: dateFormat, filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_call_type"), field: "type", cellRenderer: callTypeRenderer, filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_call_status"), field: "status", filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_ad_source"), field: "ds_title", filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_call_duration"), field: "duration", filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_user"), field: "user_name", filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_tracking_number"), field: "tracking_number", filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_caller_number"), field: "caller_number", cellRenderer: phoneNumberRenderer, filter: 'agTextColumnFilter', },
        { headerName: t("CallAnalytics_prospect"), field: "prospect_name", cellRenderer: prospectRenderer, filter: "agTextColumnFilter" },
        { headerName: t("CallAnalytics_audio"), field: "audio_url", cellRenderer: audioRenderer, filter: "agTextColumnFilter" },
        { headerName: t("CallAnalytics_notes"), field: "notes", filter: "agTextColumnFilter" },
    ];

    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    const customStatusOptions = [
        { value: '', label: t("CallAnalytics_all_status") },
        { value: 'Answered', label: t("CallAnalytics_answered") },
        { value: 'Abandoned', label: t("CallAnalytics_abandoned") },
        { value: 'Unanswered', label: t("CallAnalytics_unanswered") },
        { value: 'Missed', label: t("CallAnalytics_missed") },
    ];

    const customTypeOptions = [
        { value: '', label: t("CallAnalytics_all_type") },
        { value: 'inbound', label: t("CallAnalytics_inbound") },
        { value: 'outbound', label: t("CallAnalytics_outbound") },
    ];

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            getUser();
            getChart();
            getGridData();

            setTimeout(() => {
                setLoading(false);
                setLoading2(false);
            }, 500);
        }
    }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleStartDateChange = (date) => {
        onchangeDateRange("ds", date);

        const selectedStartDate = moment(date);
        const selectedEndDate = selectedStartDate.clone().add(1, 'months');

        setdateRange((prevState) => ({
            ...prevState,
            de: selectedEndDate.format('YYYY-MM-DD'),
        }));
    };

    const handleApplyButtonClick = () => {
        setLoading2(true)
        const dateRangeValues = {
            ds: dateRange.ds,
            de: dateRange.de,
        };

        getUpdatedChart(dateRangeValues, selectedUser, selectedStatus, selectedType);
        getUpdatedGridData(dateRangeValues, selectedUser, selectedStatus, selectedType);
    }

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="py-3 px-5 container-fluid font-family" style={{ height: '90vh', overflow: 'auto' }}>
                    <div className="d-flex justify-content-between" id="filter">
                        {loading2 ? (
                            <Loader />
                        ) : (
                            <div className="text-family float-end mb-4" id="date">
                                <Form.Group className="d-flex">
                                    <Form.Group className="me-2" style={{ width: '150px' }}>
                                        <Form.Control
                                            type="date"
                                            placeholder="From"
                                            className="shadow-sm border-0"
                                            value={moment(dateRange.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                            onChange={(e) => {
                                                onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="me-2" style={{ width: '150px' }}>
                                        <Form.Control
                                            type="date"
                                            className="shadow-sm border-0"
                                            value={moment(dateRange.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                            onChange={(e) => {
                                                onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                            }}
                                        />
                                    </Form.Group>
                                </Form.Group>
                            </div>
                        )}

                        <Row>
                            <div className="px-1" style={{ width: '150px' }}>
                                <Form.Group as={Col}>
                                    <FormSelect
                                        options={user}
                                        value={selectedUser}
                                        onChange={selectedOption => {
                                            setSelectedUser(selectedOption);
                                        }}
                                        placeholder={t("CallAnalytics_all_user")}
                                        border={false}
                                        shadow={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1" style={{ width: '150px' }}>
                                <Form.Group as={Col}>
                                    <FormSelect
                                        options={customStatusOptions}
                                        value={selectedStatus}
                                        onChange={selectedOption => {
                                            setSelectedStatus(selectedOption);
                                        }}
                                        placeholder={t("CallAnalytics_all_status")}
                                        border={false}
                                        shadow={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1" style={{ width: '150px' }}>
                                <Form.Group as={Col}>
                                    <FormSelect
                                        options={customTypeOptions}
                                        value={selectedType}
                                        onChange={selectedOption => {
                                            setSelectedType(selectedOption);
                                        }}
                                        placeholder={t("CallAnalytics_all_type")}
                                        border={false}
                                        shadow={true}
                                    />
                                </Form.Group>
                            </div>
                            <div className="px-1" style={{ width: 'auto' }}>
                                <Button variant="primary" className="btn shadow-sm py-1" style={{ width: '70px' }} onClick={handleApplyButtonClick}>{t("CallAnalytics_apply")}</Button>
                            </div>
                        </Row>
                    </div>

                    <div>
                        <div>
                            <Col xl={12} className="mt-3" id="activity">
                                <Card className="border-0 shadow-sm">
                                    <Card.Header>
                                        <div className="font h6">{t("CallAnalytics_activities_added")}</div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <AreaChart data={chart} margin={{ bottom: 50 }}>
                                                <XAxis dataKey="date" interval={0} angle={315} textAnchor="end" />
                                                <YAxis type="number" />
                                                <Area
                                                    type="monotone"
                                                    dataKey="call"
                                                    stroke="#82ca9d"
                                                    fill="#d8d8d8"
                                                    name="Date"
                                                />
                                                <Tooltip />
                                                <Legend verticalAlign="top" height={35} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </div>

                        {gridData &&
                            <div className="call-title mt-5 h6">
                                {gridData.length} {t("CallAnalytics_call_from")} {dateRange.ds} {t("CallAnalytics_to")} {dateRange.de}
                            </div>
                        }

                        <div className="mt-3">
                            <Col xxl={12}>
                                <div style={containerStyle}>
                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                        <AgGridReact
                                            columnDefs={gridColumn}
                                            rowData={gridData}
                                            rowHeight={70}
                                            pagination={true}
                                            paginationPageSize={100}
                                            paginationPageSizeSelector={false}
                                            animateRows={true}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>
                </div >
            )}
        </Container>
    );
}

export default CallAnalytics;
