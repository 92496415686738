import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SettingWabaListElement = ({ wabaData }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [elementData, setElementData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [isAddElement, setisAddElement] = useState(false);
    const [elementType, setElementType] = useState("");
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
    const [uniqueId, setUniqueId] = useState("");

    const CHARACTER_LIMIT = 24;
    const DESCRIPTION_LIMIT = 72;


    // Validation Schema
    const validationSchema = yup.object().shape({
        element_unique_id: yup.string().required("Element unique ID is required"),
        element_title: yup
            .string()
            .required("Element title is required")
            .max(CHARACTER_LIMIT, `Element title cannot exceed ${CHARACTER_LIMIT} characters`),
        element_description: yup
            .string()
            .max(DESCRIPTION_LIMIT, `Element description cannot exceed ${DESCRIPTION_LIMIT} characters`),
    });

    // GET FUNCTION ---------------------------------------------------------

    const getGridData = async () => {
        try {
            const res = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "getWABAInteractiveElement",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    waba_interactive_id: wabaData.whatsapp_interactive_id
                }
            });

            let data = res.data;
            if (data.record && data.record.length > 0) {
                setElementData(data.record);
                setIsGrid(true);

                // Check if the add button should be disabled
                const maxRecords = wabaData.type === "list" ? 10 : 3;
                setIsAddButtonDisabled(data.record.length >= maxRecords);
            } else {
                setElementData([]);
                setIsGrid(false);
            }
        } catch (error) {
            console.error(error);
            setElementData([]);
            setIsGrid(false);
        } finally {
            setIsLoading(false);
        }
    };


    // GRID COLUMN ----------------------------------------------------------

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "70vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
            sortable: false,
            filter: false,
            cellDataType: true,
        };
    }, []);

    const [gridColumn] = useState([
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 80, cellClass: "center" },
        { headerName: "Element TItle", field: "element_title", width: 300, },
        {
            headerName: "Description",
            field: "element_description",
            width: 500,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' },
        },
        { headerName: "Element Unique ID", field: "element_unique_id", width: 300, cellClass: "center", headerClass: "center" },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            width: 250,
            pinned: "right",
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        }
    ]);

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "updateWABAInteractiveElement",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    interactive_elements_id: values.interactive_elements_id,
                    waba_interactive_id: values.whatsapp_interactive_id,
                    element_unique_id: values.element_unique_id,
                    element_title: values.element_title,
                    element_description: values.element_description
                }
            });

            if (response.data.status == '0') {
                setShowModal(false);
                getGridData();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.whatsapp_interactive_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "deleteWABAInteractiveElement",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    interactive_elements_id: params.interactive_elements_id,
                    waba_interactive_id: params.whatsapp_interactive_id,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getGridData();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_waba_interactive.php`, {
                params: {
                    task: "setWABAInteractiveElement",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    waba_interactive_id: wabaData.whatsapp_interactive_id,
                    element_unique_id: values.element_unique_id,
                    element_title: values.element_title,
                    element_description: values.element_description,
                }
            });

            if (response.data.status == '0') {
                setisAddElement(false);
                getGridData();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const toggleAddElementModal = () => {
        const generateUniqueId = () => {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${session.company_id}-${day}${month}${year}_${hours}${minutes}${seconds}`;
        };

        setUniqueId(generateUniqueId());
        setisAddElement(prevState => !prevState);
        setElementType(wabaData.type);
    };

    // USE EFFECT ----------------------------------------------------------

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []);

    
    return (
        <div>
            {isLoading ? (<Loader />) : (
                <Container fluid>
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddElementModal} disabled={isAddButtonDisabled}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Element
                    </button>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={gridColumn}
                                rowData={elementData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={true}
                                paginationPageSize={30}
                                paginationPageSizeSelector={false}
                                onCellClicked={onCellClicked}
                            />
                            {!isGrid && <NoRecord message="No record at the moment." width={200} />}
                        </div>
                    </div>

                    <Modal show={isAddElement} onHide={toggleAddElementModal}>
                        <Formik
                            initialValues={{
                                waba_interactive_id: "",
                                element_unique_id: uniqueId,
                                element_title: "",
                                element_description: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Element</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Element Unique ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="element_unique_id"
                                                value={values.element_unique_id}
                                                onChange={handleChange}
                                                isInvalid={touched.element_unique_id && !!errors.element_unique_id}
                                                disabled
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.element_unique_id}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Element Title
                                                <span className="text-muted ms-2">({values.element_title.length}/{CHARACTER_LIMIT})</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="element_title"
                                                value={values.element_title}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= CHARACTER_LIMIT) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                maxLength={CHARACTER_LIMIT}
                                                isInvalid={touched.element_title && !!errors.element_title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.element_title}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {elementType !== 'reply' ? (
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Element Description
                                                    <span className="text-muted ms-2">({values.element_description.length}/{DESCRIPTION_LIMIT})</span>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    name="element_description"
                                                    value={values.element_description}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= DESCRIPTION_LIMIT) {
                                                            handleChange(e);
                                                        }
                                                    }}
                                                    maxLength={DESCRIPTION_LIMIT}
                                                    isInvalid={touched.element_description && !!errors.element_description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.element_description}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ) : null}

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                element_title: currentData ? currentData.element_title : "",
                                element_description: currentData ? currentData.element_description : "",
                                interactive_elements_id: currentData ? currentData.interactive_elements_id : "",
                                whatsapp_interactive_id: currentData ? currentData.whatsapp_interactive_id : "",
                                element_unique_id: currentData ? currentData.element_unique_id : "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit Element</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Element Title
                                                <span className="text-muted ms-2">({values.element_title.length}/{CHARACTER_LIMIT})</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="element_title"
                                                value={values.element_title}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= CHARACTER_LIMIT) {
                                                        handleChange(e);
                                                    }
                                                }}
                                                maxLength={CHARACTER_LIMIT}
                                                isInvalid={touched.element_title && !!errors.element_title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.element_title}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        {elementType !== 'reply' ? (
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Element Description
                                                    <span className="text-muted ms-2">({values.element_description.length}/{DESCRIPTION_LIMIT})</span>
                                                </Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    name="element_description"
                                                    value={values.element_description}
                                                    disabled={elementType === 'reply'}
                                                    onChange={(e) => {
                                                        if (e.target.value.length <= DESCRIPTION_LIMIT) {
                                                            handleChange(e);
                                                        }
                                                    }}
                                                    maxLength={DESCRIPTION_LIMIT}
                                                    isInvalid={touched.element_description && !!errors.element_description}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.element_description}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ) : null}

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default SettingWabaListElement;