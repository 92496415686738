import { Link } from "react-router-dom";
import "../lib/scss/error.scss";
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import errorImage from "../assets/images/403.png";
import logo from "../assets/images/outperform-logo.png";

const Forbidden = () => {
  return (
    <section className="error-page">
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} width="200" className="d-inline-block align-top" alt="React Bootstrap logo" />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={{ span: 10, offset: 1 }} className="py-5">
                <div className="d-flex justify-content-center">
                  <Image src={errorImage} fluid />
                </div>

                <h4 className="text-center fw-bold mt-4 text-uppercase">We are sorry...</h4>
                <p className="text-center">The page you're trying to access has restricted access. Let's get you back on track. If you need a hand, don't hesitate to reach out to our friendly support team.</p>
                <div className="d-flex justify-content-center">
                  <Link to="/deal/list" className="btn op-primary-color text-light">
                    Back to Outperform
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Forbidden;
