import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Col, Container, Form, Modal, CloseButton, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import Swal from "sweetalert2/dist/sweetalert2";
import { FormSelect } from "../../includes/FormCustom";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import { FilePond, registerPlugin } from "react-filepond";
import * as XLSX from "xlsx";

const UnitSettings = () => {
  registerPlugin(FilePondPluginFileValidateType);
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSuccess, setIsSuccess] = useState(0);
  const [typeOpts, setTypeOpts] = useState([]);
  const [phaseOpts, setPhaseOpts] = useState([]);
  const [projectOpts, setProjectOpts] = useState([]);
  const [selectedType, setSelectedType] = useState({ label: "All Unit Type", value: "" });
  const [selectedPhase, setSelectedPhase] = useState({ label: "All Phase", value: "" });
  const [selectedProject, setSelectedProject] = useState({ label: "All Project", value: "" });
  const [phaseShow, setPhaseShow] = useState(true);
  const [typeShow, setTypeShow] = useState(true);
  const [isBulk, setIsBulk] = useState(false);
  const [bulkCount, setBulkCount] = useState(0);
  const [bulkData, setBulkData] = useState([]);
  const [bulkRecord, setBulkRecord] = useState([]);
  const [initialValues, setInitialValues] = useState({
    rea_unit_id: "",
    rea_phase_id: "",
    rea_project_id: "",
    rea_type_id: "",
    unit_number: "",
    unit_index: "",
    unit_level: "",
    unit_level_title: "",
    unit_facing: "",
    unit_builtup_area: "",
    unit_land_area: "",
    unit_total_bedroom: "",
    unit_total_bathroom: "",
    unit_images: "",
    unit_selling_price: "",
    lot_number: "",
    unit_floor_plan: "",
    unit_car_park_no: "",
    bumi_reserved: "",
    max_discount: "",
    solicitor_info: "",
    solicitor_contact: "",
    solicitor_address: "",
    solicitor_pic: "",
    solicitor_user_id: "",
    remark: "",
    unit_car_park_image: "",
    unit_car_park_title: "",
    unit_level_image: "",
    selling_psf: "",
    net_price_after_disc: "",
    total_carpark: "",
    phaseShow: true,
    typeShow: true,
  });

  const getUnitTypeListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetTypeListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: `${record.unit_title} ${record.unit_type}`,
          value: record.rea_type_id,
          project: record.rea_project_id,
          phase: record.rea_phase_id,
        }));

        const recordOptions2 = data.record.map((record) => ({
          label: `${record.unit_title} ${record.unit_type}`,
          value: record.rea_type_id,
          project: record.rea_project_id,
          phase: record.rea_phase_id,
        }));

        recordOptions2.unshift({
          label: `All Unit Type`,
          value: "",
          project: "",
          phase: "",
        });

        setUnitTypeList(recordOptions);
        setTypeOpts(recordOptions2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPhaseListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.phase_name,
          value: record.rea_phase_id,
          project: record.rea_project_id,
        }));

        const recordOptions2 = data.record.map((record) => ({
          label: record.phase_name,
          value: record.rea_phase_id,
          project: record.rea_project_id,
        }));

        recordOptions2.unshift({
          label: "All Phase",
          value: "",
          project: "",
        });

        setPhaseOpts(recordOptions2);
        setPhaseList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProjectListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetProjectListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.project_name,
          value: record.rea_project_id,
        }));

        const recordOptions2 = data.record.map((record) => ({
          label: record.project_name,
          value: record.rea_project_id,
        }));

        recordOptions2.unshift({
          label: "All Project",
          value: "",
        });

        setProjectOpts(recordOptions2);
        setProjectList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBulk = async (values) => {
    try {
      var recordReader = new FileReader();
      recordReader.onload = () => {
        var recordFileData = recordReader.result;
        var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
        var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Bulk Unit"]);
        var recordData = recordRow;
        setBulkCount(recordRow.length);

        var headerRows = [];
        var recordWorksheet = recordWorkBook.Sheets["Bulk Unit"];

        if (recordWorksheet) {
          var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
          for (var R = range.s.r; R <= range.e.r; ++R) {
            var row = [];
            for (var C = range.s.c; C <= range.e.c; ++C) {
              var cellAddress = XLSX.utils.encode_cell({
                r: R,
                c: C,
              });
              var cell = recordWorksheet[cellAddress];
              var cellValue = cell ? cell.v : "";
              row.push(cellValue);
            }
            headerRows.push(row);
          }
        }

        if (!recordWorksheet) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Bulk Unit'.</p></div>`,
          });
          return;
        }

        var expectedColumns = [
          "unit_number",
          "unit_index",
          "unit_level",
          "unit_level_title",
          "unit_facing",
          "unit_builtup_area",
          "unit_land_area",
          "unit_total_bedroom",
          "unit_total_bathroom",
          "unit_images",
          "unit_selling_price",
          "lot_number",
          "floor_plan",
          "unit_car_park_no",
          "bumi_reserved",
          "solicitor_info",
          "solicitor_contact",
          "solicitor_address",
          "solicitor_pic",
          "remark",
          "solicitor_user_id",
          "max_discount",
          "unit_car_park_image",
          "unit_car_park_title",
          "unit_level_image",
          "selling_psf",
          "net_price_after_disc",
          "total_carpark",
        ];

        var currentColumns = headerRows[0];

        if (currentColumns) {
          var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
          if (missingColumns.length > 0) {
            var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
            for (var i = 0; i < missingColumns.length; i++) {
              alertMessage += `<li>${missingColumns[i]}</li>\n`;
            }
            alertMessage += `</ul></div>`;
            Swal.fire({
              icon: "warning",
              html: alertMessage,
            });
            return;
          }
        }

        if (!recordRow.length) {
          Swal.fire({
            icon: "warning",
            html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
          });
          return;
        }

        for (let i = 0; i < recordData.length; i++) {
          recordData[i].index = i + 1;
        }

        const bulkData = recordData.map(
          ({
            unit_number: unit_number = "",
            unit_index: unit_index = "",
            unit_level: unit_level = "",
            unit_level_title: unit_level_title = "",
            unit_facing: unit_facing = "",
            unit_builtup_area: unit_builtup_area = "",
            unit_land_area: unit_land_area = "",
            unit_total_bedroom: unit_total_bedroom = "",
            unit_total_bathroom: unit_total_bathroom = "",
            unit_images: unit_images = "",
            unit_selling_price: unit_selling_price = "",
            lot_number: lot_number = "",
            floor_plan: floor_plan = "",
            unit_car_park_no: unit_car_park_no = "",
            bumi_reserved: bumi_reserved = "",
            solicitor_info: solicitor_info = "",
            solicitor_contact: solicitor_contact = "",
            solicitor_address: solicitor_address = "",
            solicitor_pic: solicitor_pic = "",
            remark: remark = "",
            solicitor_user_id: solicitor_user_id = "",
            max_discount: max_discount = "",
            unit_car_park_image: unit_car_park_image = "",
            unit_car_park_title: unit_car_park_title = "",
            unit_level_image: unit_level_image = "",
            selling_psf: selling_psf = "",
            net_price_after_disc: net_price_after_disc = "",
            total_carpark: total_carpark = "",
            index: index,
          }) => ({
            unit_number,
            unit_index,
            unit_level,
            unit_level_title,
            unit_facing,
            unit_builtup_area,
            unit_land_area,
            unit_total_bedroom,
            unit_total_bathroom,
            unit_images,
            unit_selling_price,
            lot_number,
            floor_plan,
            unit_car_park_no,
            bumi_reserved,
            solicitor_info,
            solicitor_contact,
            solicitor_address,
            solicitor_pic,
            remark,
            index,
            solicitor_user_id,
            max_discount,
            unit_car_park_image,
            unit_car_park_title,
            unit_level_image,
            selling_psf,
            net_price_after_disc,
            total_carpark
          })
        );

        setBulkData(bulkData);
      };
      recordReader.readAsArrayBuffer(values.file[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreate = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "CreateUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: values.rea_phase_id,
          rea_project_id: values.rea_project_id,
          rea_type_id: values.rea_type_id,
          unit_number: values.unit_number,
          unit_index: values.unit_index,
          unit_level: values.unit_level,
          unit_level_title: values.unit_level_title,
          unit_facing: values.unit_facing,
          unit_builtup_area: values.unit_builtup_area,
          unit_land_area: values.unit_land_area,
          unit_total_bedroom: values.unit_total_bedroom,
          unit_total_bathroom: values.unit_total_bathroom,
          unit_images: values.unit_images,
          unit_selling_price: values.unit_selling_price,
          lot_number: values.lot_number,
          unit_floor_plan: values.unit_floor_plan,
          unit_car_park_no: values.unit_car_park_no,
          bumi_reserved: values.bumi_reserved,
          max_discount: values.max_discount,
          solicitor_info: values.solicitor_info,
          solicitor_contact: values.solicitor_contact,
          solicitor_address: values.solicitor_address,
          solicitor_pic: values.solicitor_pic,
          solicitor_user_id: values.solicitor_user_id,
          remark: values.remark,
          unit_car_park_image: values.unit_car_park_image,
          unit_car_park_title: values.unit_car_park_title,
          unit_level_image: values.unit_level_image,
          selling_psf: values.selling_psf,
          net_price_after_disc: values.net_price_after_disc,
          total_carpark: values.total_carpark,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsSuccess(Math.random());
        setIsCreate(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created",
          timer: 2000,
        });
      } else {
        setIsCreate(false);
        Swal.fire({
          icon: "error",
          text: "Failed to create",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEdit = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdateUnit",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_unit_id: values.rea_unit_id,
          rea_phase_id: values.rea_phase_id,
          rea_project_id: values.rea_project_id,
          rea_type_id: values.rea_type_id,
          unit_number: values.unit_number,
          unit_index: values.unit_index,
          unit_level: values.unit_level,
          unit_level_title: values.unit_level_title,
          unit_facing: values.unit_facing,
          unit_builtup_area: values.unit_builtup_area,
          unit_land_area: values.unit_land_area,
          unit_total_bedroom: values.unit_total_bedroom,
          unit_total_bathroom: values.unit_total_bathroom,
          unit_images: values.unit_images,
          unit_selling_price: values.unit_selling_price,
          lot_number: values.lot_number,
          unit_floor_plan: values.unit_floor_plan,
          unit_car_park_no: values.unit_car_park_no,
          bumi_reserved: values.bumi_reserved,
          max_discount: values.max_discount,
          solicitor_info: values.solicitor_info,
          solicitor_contact: values.solicitor_contact,
          solicitor_address: values.solicitor_address,
          solicitor_pic: values.solicitor_pic,
          solicitor_user_id: values.solicitor_user_id,
          remark: values.remark,
          unit_car_park_image: values.unit_car_park_image,
          unit_car_park_title: values.unit_car_park_title,
          unit_level_image: values.unit_level_image,
          selling_psf: values.selling_psf,
          net_price_after_disc: values.net_price_after_disc,
          total_carpark: values.total_carpark,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setIsSuccess(Math.random());
        setIsEdit(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        setIsEdit(false);
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRemove = async (values) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemoveUnit",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            rea_unit_id: values.rea_unit_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          setIsSuccess(Math.random());
          Swal.fire({
            icon: "success",
            text: "Successfully removed",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to remove " + data.message,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEdit = (values) => {
    setInitialValues({
      rea_unit_id: values.rea_unit_id,
      rea_phase_id: values.rea_phase_id,
      rea_project_id: values.rea_project_id,
      rea_type_id: values.rea_type_id,
      unit_number: values.unit_number,
      unit_index: values.unit_index,
      unit_level: values.unit_level,
      unit_level_title: values.unit_level_title,
      unit_facing: values.unit_facing,
      unit_builtup_area: values.unit_builtup_area,
      unit_land_area: values.unit_land_area,
      unit_total_bedroom: values.unit_total_bedroom,
      unit_total_bathroom: values.unit_total_bathroom,
      unit_images: values.unit_images,
      unit_selling_price: values.unit_selling_price,
      lot_number: values.lot_number,
      unit_floor_plan: values.unit_floor_plan,
      unit_car_park_no: values.unit_car_park_no,
      bumi_reserved: values.bumi_reserved,
      max_discount: values.max_discount,
      solicitor_info: values.solicitor_info,
      solicitor_contact: values.solicitor_contact,
      solicitor_address: values.solicitor_address,
      solicitor_pic: values.solicitor_pic,
      solicitor_user_id: values.solicitor_user_id,
      remark: values.remark,
      unit_car_park_image: values.unit_car_park_image,
      unit_car_park_title: values.unit_car_park_title,
      unit_level_image: values.unit_level_image,
      selling_psf: values.selling_psf,
      net_price_after_disc: values.net_price_after_disc,
      total_carpark: values.total_carpark,
      phaseShow: true,
      typeShow: true,
    });

    setTimeout(() => {
      setIsEdit(true);
    }, 100);
  };

  const setPhaseOptions = (project) => {
    if (project) {
      return phaseList.filter((record) => record.project === project);
    }

    return phaseList;
  };

  const setTypeOptions = (project, phase) => {
    var options = unitTypeList;

    if (project) {
      options = options.filter((record) => record.project === project);
    }

    if (phase) {
      options = options.filter((record) => record.phase === phase);
    }

    return options;
  };

  const setPhaseOptions2 = (project) => {
    if (project) {
      return phaseOpts.filter((record) => record.project === project);
    }

    return phaseList;
  };

  const setTypeOptions2 = (project, phase) => {
    var options = typeOpts;

    if (project) {
      options = options.filter((record) => record.project === project);
    }

    if (phase) {
      options = options.filter((record) => record.phase === phase);
    }

    return options;
  };

  const sendBulk = async (values) => {
    try {
      if (bulkRecord.length === 0) {
        Swal.fire({
          icon: "warning",
          text: "No record has been selected, please choose which unit record that you want to bulk upload",
        });
        return;
      }

      var unit_number = bulkRecord.map((item) => {
        return item.unit_number;
      });

      var unit_index = bulkRecord.map((item) => {
        return item.unit_index;
      });

      var unit_level = bulkRecord.map((item) => {
        return item.unit_level;
      });

      var unit_level_title = bulkRecord.map((item) => {
        return item.unit_level_title;
      });

      var unit_facing = bulkRecord.map((item) => {
        return item.unit_facing;
      });

      var unit_builtup_area = bulkRecord.map((item) => {
        return item.unit_builtup_area;
      });

      var unit_land_area = bulkRecord.map((item) => {
        return item.unit_land_area;
      });

      var unit_total_bedroom = bulkRecord.map((item) => {
        return item.unit_total_bedroom;
      });

      var unit_total_bathroom = bulkRecord.map((item) => {
        return item.unit_total_bathroom;
      });

      var unit_images = bulkRecord.map((item) => {
        return item.unit_images;
      });

      var unit_selling_price = bulkRecord.map((item) => {
        return item.unit_selling_price;
      });

      var lot_number = bulkRecord.map((item) => {
        return item.lot_number;
      });

      var floor_plan = bulkRecord.map((item) => {
        return item.floor_plan;
      });

      var unit_car_park_no = bulkRecord.map((item) => {
        return item.unit_car_park_no;
      });

      var bumi_reserved = bulkRecord.map((item) => {
        return item.bumi_reserved;
      });

      var solicitor_info = bulkRecord.map((item) => {
        return item.solicitor_info;
      });

      var solicitor_contact = bulkRecord.map((item) => {
        return item.solicitor_contact;
      });

      var solicitor_address = bulkRecord.map((item) => {
        return item.solicitor_address;
      });

      var solicitor_pic = bulkRecord.map((item) => {
        return item.solicitor_pic;
      });

      var remark = bulkRecord.map((item) => {
        return item.remark;
      });

      var solicitor_user_id = bulkRecord.map((item) => {
        return item.solicitor_user_id;
      });

      var max_discount = bulkRecord.map((item) => {
        return item.max_discount;
      });

      var unit_car_park_image = bulkRecord.map((item) => {
        return item.unit_car_park_image;
      });

      var unit_car_park_title = bulkRecord.map((item) => {
        return item.unit_car_park_title;
      });

      var unit_level_image = bulkRecord.map((item) => {
        return item.unit_level_image;
      });

      var selling_psf = bulkRecord.map((item) => {
        return item.selling_psf;
      });

      var net_price_after_disc = bulkRecord.map((item) => {
        return item.net_price_after_disc;
      });

      var total_carpark = bulkRecord.map((item) => {
        return item.total_carpark;
      });

      var formData = new FormData();
      formData.append("task", "CreateUnit");
      formData.append("unit_number", unit_number.join("|"));
      formData.append("unit_index", unit_index.join("|"));
      formData.append("unit_level", unit_level.join("|"));
      formData.append("unit_level_title", unit_level_title.join("|"));
      formData.append("unit_facing", unit_facing.join("|"));
      formData.append("unit_builtup_area", unit_builtup_area.join("|"));
      formData.append("unit_land_area", unit_land_area.join("|"));
      formData.append("unit_total_bedroom", unit_total_bedroom.join("|"));
      formData.append("unit_total_bathroom", unit_total_bathroom.join("|"));
      formData.append("unit_images", unit_images.join("|"));
      formData.append("unit_selling_price", unit_selling_price.join("|"));
      formData.append("lot_number", lot_number.join("|"));
      formData.append("floor_plan", floor_plan.join("|"));
      formData.append("unit_car_park_no", unit_car_park_no.join("|"));
      formData.append("bumi_reserved", bumi_reserved.join("|"));
      formData.append("solicitor_info", solicitor_info.join("|"));
      formData.append("solicitor_contact", solicitor_contact.join("|"));
      formData.append("solicitor_address", solicitor_address.join("|"));
      formData.append("solicitor_pic", solicitor_pic.join("|"));
      formData.append("remark", remark.join("|"));
      formData.append("rea_project_id", values.rea_project_id);
      formData.append("rea_phase_id", values.rea_phase_id);
      formData.append("rea_type_id", values.rea_type_id);
      formData.append("solicitor_user_id", solicitor_user_id.join("|"));
      formData.append("max_discount", max_discount.join("|"));
      formData.append("unit_car_park_image", unit_car_park_image.join("|"));
      formData.append("unit_car_park_title", unit_car_park_title.join("|"));
      formData.append("unit_level_image", unit_level_image.join("|"));
      formData.append("selling_psf", selling_psf.join("|"));
      formData.append("net_price_after_disc", net_price_after_disc.join("|"));
      formData.append("total_carpark", total_carpark.join("|"));
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);

      const response = await axios.post("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", formData);
      const data = response.data;
      if (data.status === 0) {
        setIsSuccess(Math.random());
        setIsBulk(false);
        setBulkCount(0);
        setBulkData([]);
        setBulkRecord([]);
        setIsSuccess(Math.random());
        if (data.error_count === 0) {
          Swal.fire({
            icon: "success",
            text: "Successfully created a new voucher",
          });
        } else {
          var alertMessage = `<p className="op-text-medium text-start">${data.success_count} successfully added and ${data.error_count} failed to be added. This unit is already in the system: </p><ul>`;
          for (var i = 0; i < data.error.length; i++) {
            alertMessage += `<li className="op-text-medium text-start">${data.error[i]}</li>\n`;
          }
          alertMessage += `</ul>`;

          Swal.fire({
            icon: data.success_count > 0 ? "success" : "warning",
            html: alertMessage,
          });
        }
      } else {
        setIsBulk(false);
        setBulkCount(0);
        setBulkData([]);
        setBulkRecord([]);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // GRID SETUP ========================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const containerStyle2 = useMemo(() => ({ width: "100%", height: "50vh" }), []);

  const gridColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Unit Number",
      field: "unit_number",
    },
    {
      headerName: "Unit Index",
      field: "unit_index",
    },
    {
      headerName: "Level",
      field: "unit_level",
    },
    {
      headerName: "Level Title",
      field: "unit_level_title",
    },
    {
      headerName: "Facing",
      field: "unit_facing",
    },
    {
      headerName: "Built-Up Area",
      field: "unit_builtup_area",
    },
    {
      headerName: "Land Area",
      field: "unit_land_area",
    },
    {
      headerName: "Bedroom",
      field: "unit_total_bedroom",
    },
    {
      headerName: "Bathroom",
      field: "unit_total_bathroom",
    },
    {
      headerName: "Images",
      field: "unit_images",
    },
    {
      headerName: "Selling Price",
      field: "unit_selling_price",
    },
    {
      headerName: "Lot Number",
      field: "lot_number",
    },
    {
      headerName: "Floor Plan",
      field: "floor_plan",
    },
    {
      headerName: "Car Park No",
      field: "unit_car_park_no",
    },
    {
      headerName: "Bumi Reserved",
      field: "bumi_reserved",
    },
    {
      headerName: "Solicitor Info",
      field: "solicitor_info",
    },
    {
      headerName: "Solicitor Contact",
      field: "solicitor_contact",
    },
    {
      headerName: "Solicitor Address",
      field: "solicitor_address",
    },
    {
      headerName: "Solicitor PIC",
      field: "solicitor_pic",
    },
    {
      headerName: "Remark",
      field: "remark",
    },
    {
      headerName: "Selling PSF",
      field: "selling_psf",
    },
    {
      headerName: "Net Price / After Discount",
      field: "net_price_after_disc",
    },
    {
      headerName: "Total Carpark",
      field: "total_carpark",
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      width: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={() => sendRemove(params.data)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: async (params) => {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "GetUnitListing",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            startRow: params.startRow,
            endRow: params.endRow,
            rea_type_id: selectedType.value,
            rea_phase_id: selectedPhase.value,
            rea_project_id: selectedProject.value,
            isSuccess: isSuccess,
          },
        });

        const data = await response.data;
        setTimeout(() => {
          var unitInfo = [];
          var totalRecord = 0;
          if (data.status === 0) {
            unitInfo = data.record;
            totalRecord = Number(data.totalRecord);
          }

          var rowsThisPage = unitInfo;
          var lastRow = -1;

          if (Number(totalRecord) <= params.endRow) {
            lastRow = totalRecord;
          }
          params.successCallback(rowsThisPage, lastRow);
        }, 500);
      },
    };
  }, [session, isSuccess, selectedProject, selectedPhase, selectedType]);

  const gridColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return Math.random().toString();
  }, []);

  const bulkRef = useRef();
  const bulkColumn = [
    {
      headerName: "No",
      field: "",
      width: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Unit Number",
      field: "unit_number",
    },
    {
      headerName: "Unit Index",
      field: "unit_index",
    },
    {
      headerName: "Level",
      field: "unit_level",
    },
    {
      headerName: "Level Title",
      field: "unit_level_title",
    },
    {
      headerName: "Facing",
      field: "unit_facing",
    },
    {
      headerName: "Built-Up Area",
      field: "unit_builtup_area",
    },
    {
      headerName: "Land Area",
      field: "unit_land_area",
    },
    {
      headerName: "Bedroom",
      field: "unit_total_bedroom",
    },
    {
      headerName: "Bathroom",
      field: "unit_total_bathroom",
    },
    {
      headerName: "Images",
      field: "unit_images",
    },
    {
      headerName: "Selling Price",
      field: "unit_selling_price",
    },
    {
      headerName: "Lot Number",
      field: "lot_number",
    },
    {
      headerName: "Floor Plan",
      field: "unit_floor_plan",
    },
    {
      headerName: "Car Park No",
      field: "unit_car_park_no",
    },
    {
      headerName: "Bumi Reserved",
      field: "bumi_reserved",
    },
    {
      headerName: "Solicitor Info",
      field: "solicitor_info",
    },
    {
      headerName: "Solicitor Contact",
      field: "solicitor_contact",
    },
    {
      headerName: "Solicitor Address",
      field: "solicitor_address",
    },
    {
      headerName: "Solicitor PIC",
      field: "solicitor_pic",
    },
    {
      headerName: "Remark",
      field: "remark",
    },
    {
      headerName: "Selling PSF",
      field: "selling_psf",
    },
    {
      headerName: "Net Price / After Discount",
      field: "net_price_after_sic",
    },
    {
      headerName: "Total Carpark",
      field: "total_carpark",
    },
  ];

  const bulkRowId = useCallback(function (params) {
    return Math.random().toString();
  }, []);

  const bulkSelect = useCallback(() => {
    setBulkRecord(bulkRef.current.api.getSelectedRows());
  }, []);

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getUnitTypeListing();
          await getProjectListing();
          await getPhaseListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-3" gap={2}>
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create Unit
            </Button>

            <Button variant="" className="op-primary-color text-light" onClick={setIsBulk}>
              Bulk Unit
            </Button>

            {projectOpts.length > 0 && (
              <FormSelect
                options={projectOpts}
                valueDefault={selectedProject}
                width="250px"
                onChange={(e) => {
                  setPhaseShow(false);
                  setTypeShow(false);
                  setSelectedProject(e);
                  setSelectedPhase(phaseOpts[0]);
                  setSelectedType(typeOpts[0]);

                  setTimeout(() => {
                    setPhaseShow(true);
                    setTypeShow(true);
                  }, 100);
                }}
              />
            )}

            {phaseOpts.length > 0 && phaseShow && (
              <FormSelect
                options={setPhaseOptions2(selectedProject.value)}
                valueDefault={selectedPhase}
                width="250px"
                onChange={(e) => {
                  setTypeShow(false);
                  setSelectedPhase(e);
                  setSelectedType(typeOpts[0]);
                  setTimeout(() => {
                    setTypeShow(true);
                  }, 100);
                }}
              />
            )}

            {typeOpts.length > 0 && typeShow && (
              <FormSelect
                options={setTypeOptions2(selectedProject.value, selectedPhase.value)}
                valueDefault={selectedType}
                width="250px"
                onChange={(e) => {
                  setSelectedType(e);
                }}
              />
            )}
          </Stack>
          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact
                ref={gridRef}
                columnDefs={gridColumn}
                datasource={gridDataSource}
                defaultColDef={gridColDef}
                getRowId={gridRowId}
                rowSelection={"multiple"}
                rowModelType={"infinite"}
                rowHeight={70}
                cacheBlockSize={100}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={2}
                infiniteInitialRowCount={10}
                maxBlocksInCache={2}
                pagination={true}
                paginationPageSize={100}
                paginationPageSizeSelector={false}
                suppressRowClickSelection={true}
                animateRows={true}
              />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik
          onSubmit={sendCreate}
          initialValues={{
            rea_phase_id: "",
            rea_project_id: "",
            rea_type_id: "",
            unit_number: "",
            unit_index: "",
            unit_level: "",
            unit_level_title: "",
            unit_facing: "",
            unit_builtup_area: "",
            unit_land_area: "",
            unit_total_bedroom: "",
            unit_total_bathroom: "",
            unit_images: "",
            unit_selling_price: "",
            lot_number: "",
            unit_floor_plan: "",
            unit_car_park_no: "",
            bumi_reserved: "",
            max_discount: "",
            solicitor_info: "",
            solicitor_contact: "",
            solicitor_address: "",
            solicitor_pic: "",
            solicitor_user_id: "",
            remark: "",
            unit_car_park_image: "",
            unit_car_park_title: "",
            unit_level_image: "",
            selling_psf: "",
            net_price_after_disc: "",
            total_carpark: "",
            phaseShow: true,
            typeShow: true,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Unit Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Number</Form.Label>
                  <Form.Control type="text" value={values.unit_number} onChange={handleChange("unit_number")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Index</Form.Label>
                  <Form.Control type="text" value={values.unit_index} onChange={handleChange("unit_index")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level</Form.Label>
                  <Form.Control type="text" value={values.unit_level} onChange={handleChange("unit_level")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level Title</Form.Label>
                  <Form.Control type="text" value={values.unit_level_title} onChange={handleChange("unit_level_title")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Facing</Form.Label>
                  <Form.Control type="text" value={values.unit_facing} onChange={handleChange("unit_facing")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Built-Up Area</Form.Label>
                  <Form.Control type="text" value={values.unit_builtup_area} onChange={handleChange("unit_builtup_area")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Land Area</Form.Label>
                  <Form.Control type="text" value={values.unit_land_area} onChange={handleChange("unit_land_area")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Bedroom</Form.Label>
                  <Form.Control type="text" value={values.unit_total_bedroom} onChange={handleChange("unit_total_bedroom")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Bathroom</Form.Label>
                  <Form.Control type="text" value={values.unit_total_bathroom} onChange={handleChange("unit_total_bathroom")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Images</Form.Label>
                  <Form.Control type="text" value={values.unit_images} onChange={handleChange("unit_images")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Selling Price</Form.Label>
                  <Form.Control type="text" value={values.unit_selling_price} onChange={handleChange("unit_selling_price")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Lot Number</Form.Label>
                  <Form.Control type="text" value={values.lot_number} onChange={handleChange("lot_number")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Floor Plan</Form.Label>
                  <Form.Control type="text" value={values.unit_floor_plan} onChange={handleChange("unit_floor_plan")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Car Park No</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_no} onChange={handleChange("unit_car_park_no")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Bumi Reserved</Form.Label>
                  <Form.Control type="text" value={values.bumi_reserved} onChange={handleChange("bumi_reserved")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Max Discount</Form.Label>
                  <Form.Control type="text" value={values.max_discount} onChange={handleChange("max_discount")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Info</Form.Label>
                  <Form.Control type="text" value={values.solicitor_info} onChange={handleChange("solicitor_info")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Contact</Form.Label>
                  <Form.Control type="text" value={values.solicitor_contact} onChange={handleChange("solicitor_contact")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Address</Form.Label>
                  <Form.Control type="text" value={values.solicitor_address} onChange={handleChange("solicitor_address")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor PIC</Form.Label>
                  <Form.Control type="text" value={values.solicitor_pic} onChange={handleChange("solicitor_pic")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor User ID</Form.Label>
                  <Form.Control type="text" value={values.solicitor_user_id} onChange={handleChange("solicitor_user_id")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control type="text" value={values.remark} onChange={handleChange("remark")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Car Park Image</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_image} onChange={handleChange("unit_car_park_image")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Car Park Title</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_title} onChange={handleChange("unit_car_park_title")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level Image</Form.Label>
                  <Form.Control type="text" value={values.unit_level_image} onChange={handleChange("unit_level_image")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Selling PSF</Form.Label>
                  <Form.Control type="text" value={values.selling_psf} onChange={handleChange("selling_psf")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Net Price / After Discount</Form.Label>
                  <Form.Control type="text" value={values.net_price_after_disc} onChange={handleChange("net_price_after_disc")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Total Car Park</Form.Label>
                  <Form.Control type="text" value={values.total_carpark} onChange={handleChange("total_carpark")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect
                    options={projectList}
                    onChange={(e) => {
                      setFieldValue("phaseShow", false);
                      setFieldValue("typeShow", false);
                      setFieldValue("rea_project_id", e.value);
                      setFieldValue("rea_phase_id", "");
                      setFieldValue("rea_type_id", "");
                      setTimeout(() => {
                        setFieldValue("phaseShow", true);
                        setFieldValue("typeShow", true);
                      }, 100);
                    }}
                  />
                </Form.Group>

                {values.phaseShow && (
                  <Form.Group className="mb-3">
                    <Form.Label>Phase</Form.Label>
                    <FormSelect
                      options={setPhaseOptions(values.rea_project_id)}
                      disabled={!values.rea_project_id}
                      onChange={(e) => {
                        setFieldValue("typeShow", false);
                        setFieldValue("rea_phase_id", e.value);
                        setFieldValue("rea_type_id", "");
                        setTimeout(() => {
                          setFieldValue("typeShow", true);
                        }, 100);
                      }}
                    />
                  </Form.Group>
                )}

                {values.typeShow && (
                  <Form.Group className="mb-3">
                    <Form.Label>Unit Type</Form.Label>
                    <FormSelect options={setTypeOptions(values.rea_project_id, values.rea_phase_id)} disabled={!values.rea_project_id || !values.rea_phase_id} onChange={(e) => setFieldValue("rea_type_id", e.value)} />
                  </Form.Group>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEdit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Unit Type</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Unit Number</Form.Label>
                  <Form.Control type="text" value={values.unit_number} onChange={handleChange("unit_number")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Index</Form.Label>
                  <Form.Control type="text" value={values.unit_index} onChange={handleChange("unit_index")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level</Form.Label>
                  <Form.Control type="text" value={values.unit_level} onChange={handleChange("unit_level")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level Title</Form.Label>
                  <Form.Control type="text" value={values.unit_level_title} onChange={handleChange("unit_level_title")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Facing</Form.Label>
                  <Form.Control type="text" value={values.unit_facing} onChange={handleChange("unit_facing")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Built-Up Area</Form.Label>
                  <Form.Control type="text" value={values.unit_builtup_area} onChange={handleChange("unit_builtup_area")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Land Area</Form.Label>
                  <Form.Control type="text" value={values.unit_land_area} onChange={handleChange("unit_land_area")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Bedroom</Form.Label>
                  <Form.Control type="text" value={values.unit_total_bedroom} onChange={handleChange("unit_total_bedroom")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Bathroom</Form.Label>
                  <Form.Control type="text" value={values.unit_total_bathroom} onChange={handleChange("unit_total_bathroom")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Images</Form.Label>
                  <Form.Control type="text" value={values.unit_images} onChange={handleChange("unit_images")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Selling Price</Form.Label>
                  <Form.Control type="text" value={values.unit_selling_price} onChange={handleChange("unit_selling_price")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Lot Number</Form.Label>
                  <Form.Control type="text" value={values.lot_number} onChange={handleChange("lot_number")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Floor Plan</Form.Label>
                  <Form.Control type="text" value={values.unit_floor_plan} onChange={handleChange("unit_floor_plan")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Car Park No</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_no} onChange={handleChange("unit_car_park_no")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Bumi Reserved</Form.Label>
                  <Form.Control type="text" value={values.bumi_reserved} onChange={handleChange("bumi_reserved")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Max Discount</Form.Label>
                  <Form.Control type="text" value={values.max_discount} onChange={handleChange("max_discount")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Info</Form.Label>
                  <Form.Control type="text" value={values.solicitor_info} onChange={handleChange("solicitor_info")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Contact</Form.Label>
                  <Form.Control type="text" value={values.solicitor_contact} onChange={handleChange("solicitor_contact")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor Address</Form.Label>
                  <Form.Control type="text" value={values.solicitor_address} onChange={handleChange("solicitor_address")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor PIC</Form.Label>
                  <Form.Control type="text" value={values.solicitor_pic} onChange={handleChange("solicitor_pic")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Solicitor User ID</Form.Label>
                  <Form.Control type="text" value={values.solicitor_user_id} onChange={handleChange("solicitor_user_id")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control type="text" value={values.remark} onChange={handleChange("remark")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Car Park Image</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_image} onChange={handleChange("unit_car_park_image")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Car Park Title</Form.Label>
                  <Form.Control type="text" value={values.unit_car_park_title} onChange={handleChange("unit_car_park_title")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Unit Level Image</Form.Label>
                  <Form.Control type="text" value={values.unit_level_image} onChange={handleChange("unit_level_image")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Selling PSF</Form.Label>
                  <Form.Control type="text" value={values.selling_psf} onChange={handleChange("selling_psf")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Net Price / After Discount</Form.Label>
                  <Form.Control type="text" value={values.net_price_after_disc} onChange={handleChange("net_price_after_disc")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Total Car Park</Form.Label>
                  <Form.Control type="text" value={values.total_carpark} onChange={handleChange("total_carpark")} />
                </Form.Group>
                
                <Form.Group className="mb-3">
                  <Form.Label>Project</Form.Label>
                  <FormSelect
                    options={projectList}
                    valueDefault={projectList.find((record) => record.value === values.rea_project_id)}
                    onChange={(e) => {
                      setFieldValue("phaseShow", false);
                      setFieldValue("typeShow", false);
                      setFieldValue("rea_project_id", e.value);
                      setFieldValue("rea_phase_id", "");
                      setFieldValue("rea_type_id", "");
                      setTimeout(() => {
                        setFieldValue("phaseShow", true);
                        setFieldValue("typeShow", true);
                      }, 100);
                    }}
                  />
                </Form.Group>

                {values.phaseShow && (
                  <Form.Group className="mb-3">
                    <Form.Label>Phase</Form.Label>
                    <FormSelect
                      options={setPhaseOptions(values.rea_project_id)}
                      valueDefault={phaseList.find((record) => record.value === values.rea_phase_id)}
                      disabled={!values.rea_project_id}
                      onChange={(e) => {
                        setFieldValue("typeShow", false);
                        setFieldValue("rea_phase_id", e.value);
                        setFieldValue("rea_type_id", "");
                        setTimeout(() => {
                          setFieldValue("typeShow", true);
                        }, 100);
                      }}
                    />
                  </Form.Group>
                )}

                {values.typeShow && (
                  <Form.Group className="mb-3">
                    <Form.Label>Unit Type</Form.Label>
                    <FormSelect options={setTypeOptions(values.rea_project_id, values.rea_phase_id)} valueDefault={unitTypeList.find((record) => record.value === values.rea_type_id)} disabled={!values.rea_project_id || !values.rea_phase_id} onChange={(e) => setFieldValue("rea_type_id", e.value)} />
                  </Form.Group>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isBulk} onHide={setIsBulk} size="xl">
        <Formik onSubmit={getBulk} initialValues={{ file: [], rea_project_id: "", rea_phase_id: "", rea_type_id: "", projectShow: true, phaseShow: true, typeShow: true, submit_disabled: true, preview_disabled: true }}>
          {({ handleSubmit, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header>
                <Modal.Title as={"h6"}>Bulk Unit</Modal.Title>
                <CloseButton
                  onClick={() => {
                    setIsBulk(false);
                    setBulkCount(0);
                    setBulkData([]);
                    setBulkRecord([]);
                  }}
                />
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xxl={6}>
                    <Form.Group>
                      <FilePond
                        allowMultiple={false}
                        maxFiles={1}
                        name="file"
                        credits={false}
                        acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        instantUpload={false}
                        onupdatefiles={(fileItems) => {
                          setFieldValue(
                            "file",
                            fileItems.map((fileItem) => fileItem.file)
                          );
                        }}
                        onremovefile={() => {
                          setFieldValue("file", []);
                          setFieldValue("projectShow", false);
                          setFieldValue("phaseShow", false);
                          setFieldValue("typeShow", false);
                          setFieldValue("submit_disabled", true);
                          setFieldValue("rea_project_id", "");
                          setFieldValue("rea_phase_id", "");
                          setFieldValue("rea_type_id", "");
                          setBulkCount(0);
                          setBulkData([]);
                          setBulkRecord([]);
                          setTimeout(() => {
                            setFieldValue("projectShow", true);
                            setFieldValue("phaseShow", true);
                            setFieldValue("typeShow", true);
                          }, 100);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col xxl={6}>
                    {values.projectShow && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={projectList}
                          name="project"
                          placeholder="Select project"
                          disabled={values.file.length === 0}
                          onChange={(e) => {
                            setFieldValue("phaseShow", false);
                            setFieldValue("typeShow", false);
                            setFieldValue("rea_phase_id", "");
                            setFieldValue("rea_type_id", "");
                            setFieldValue("rea_project_id", e.value);

                            setTimeout(() => {
                              setFieldValue("phaseShow", true);
                              setFieldValue("typeShow", true);
                            }, 100);
                          }}
                        />
                      </Form.Group>
                    )}

                    {values.phaseShow && (
                      <Form.Group className="mb-2">
                        <FormSelect
                          options={phaseList}
                          name="phase"
                          placeholder="Select phase"
                          disabled={values.file.length === 0 || !values.rea_project_id}
                          onChange={(e) => {
                            setFieldValue("typeShow", false);
                            setFieldValue("rea_type_id", "");
                            setFieldValue("rea_phase_id", e.value);

                            setTimeout(() => {
                              setFieldValue("typeShow", true);
                            }, 100);
                          }}
                        />
                      </Form.Group>
                    )}

                    {values.typeShow && (
                      <Form.Group className="mb-2">
                        <FormSelect options={unitTypeList} name="unit_type" placeholder="Select unit type" disabled={values.file.length === 0 || !values.rea_project_id || !values.rea_phase_id} onChange={(e) => setFieldValue("rea_type_id", e.value)} />
                      </Form.Group>
                    )}

                    <Form.Group className="d-flex">
                      <Button type="submit" variant="" className="op-primary-color text-light" disabled={values.file.length === 0 || !values.rea_project_id || !values.rea_phase_id || !values.rea_type_id} onClick={() => setFieldValue("submit_disabled", false)}>
                        Preview
                      </Button>
                      <Button type="button" variant="success" className="ms-2" onClick={() => window.open("https://app.outperformhq.io/cloud/assets/rea_booking/Outperform_Bulk_Unit_Template.xlsx", "_blank")}>
                        Template
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col xxl={12} className="mb-3">
                    <p className="m-0 text-center">Please note, this file uploader only accepts excel files (xls or xlsx). Please download and use our template and enter your list into it.</p>
                  </Col>
                  {bulkCount > 0 && (
                    <Col xxl={12}>
                      <p className="mb-2">{bulkCount === 0 ? "No record" : bulkCount === 1 ? "1 record" : `${bulkCount} records`}</p>
                      <div style={containerStyle2}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                          <AgGridReact
                            ref={bulkRef}
                            rowSelection="multiple"
                            rowData={bulkData}
                            columnDefs={bulkColumn}
                            getRowId={bulkRowId}
                            onSelectionChanged={bulkSelect}
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={false}
                            suppressRowClickSelection={true}
                            animateRows={true}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="" className="op-primary-color text-light" disabled={values.submit_disabled} onClick={() => sendBulk(values)}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default UnitSettings;
