import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Modal, Offcanvas, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";

import { MobilePrefix, bumiputeraList, financingList, firstTimeBuyerList, genderList, identityTypeList, maritalStatusList, nationalityList, occupationList, paymentMethodList, raceList, relationList, salutationList } from "../../lib/js/Function";
import Swal from "sweetalert2/dist/sweetalert2";

const defaultJSON = "";
const defaultJSON2 = `{
  "rea_phase_id": { // rea phase id
    "mode_layout": "condo", // property layout condo or landed
    "sales_chart": "parc3_sales_chart_01.png", // sales chart image
    "unit_coords": [
      {
        "rea_unit_id": "1198", // unit id
        "coords": "691,1302,756,1332", // coords of the unit from the sales chart image
        "shape": "rect" // rect or poly
      },
    ],
    "level_availibility": 1, // 1 means there is level custom sales chart and 0 means there is no level, please make 0 if there is no level
    "level": [ // level information, if there is no level setup please make this as empty array
      {
        "sales_chart": "L8-1_p3_eupe.png", // Level sales chart image
        "level_id": 1, // custom level id
        "level_index": 0, // level index
        "level_title": "Level 8", // level title
        "coords": "208,1301,342,1331", // coords
        "shape": "rect", // shape in the main saleschart = rect or poly
        "unit": [ // Unit positioning
          {
            "rea_unit_id": "1198", // unit id
            "coords": "961,706,1137,861", // coords of the unit from the level sales chart image
            "shape": "rect" // rect or poly
          },
        ]
      },
    ],
    "carpark_availability": 1, // 1 means there is carpark custom sales chart and 0 means there is no carpark, please make 0 if there is no carpark
    "carpark_level": [ // carpark information, if there is no carpark setup please make this as empty array
      {
        "carpark_img": "level_grd_parc3_cp.png", // Carpark sales chart image
        "carpark_id": 1, // custom carpark id
        "carpark_index": 0, // carpark index
        "carpark_title": "Level GRD", // carpark title
        "coords": "208,1500,341,1521", // coords title
        "shape": "rect", // shape in the main saleschart = rect or poly
        "unit": [
          {
            "rea_unit_id": "1419", // unit id
            "coords": "609,1323,634,1412", // coords of the unit from the carpark sales chart image
            "shape": "rect" // rect or poly
          }
        ]
      },
    ]
  }
}`;

const BookingMap = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState("{}");
  const [code2, setCode2] = useState("{}");
  const [isModal, setIsModal] = useState(false);

  const getData = async () => {
    try {
      const formData = new FormData();
      formData.append("task", "read");
      formData.append("company_id", session.company_id);
      formData.append("file_name", "booking_map");
      formData.append("default_json", JSON.stringify(defaultJSON));

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_json_editor.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        if (!Array.isArray(data.record)) {
          setCode(JSON.stringify(data.record, null, 2));
          setCode2(JSON.stringify(data.record, null, 2));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCommand = (editor) => {
    editor.commands.addCommand({
      name: "foldAll",
      bindKey: { win: "Ctrl-K Ctrl-0", mac: "Cmd-K Cmd-0" }, // Key binding
      exec: (editor) => {
        editor.getSession().foldAll();
      },
    });
  };

  const sendCode = async () => {
    try {
      const formData = new FormData();
      formData.append("task", "update");
      formData.append("company_id", session.company_id);
      formData.append("file_name", "booking_map");
      formData.append("json", JSON.stringify(code));

      const response = await axios.post(`${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/api_json_editor.php`, formData);
      const data = response.data;
      if (data.status === 0) {
        setCode2(code);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-2" gap={2}>
            <Button variant="" className="op-primary-color text-light" onClick={setIsModal}>
              JSON Overview
            </Button>
            <Button variant="success" disabled={code === code2} onClick={sendCode}>
              Save
            </Button>
          </Stack>

          <AceEditor
            onLoad={getCommand}
            placeholder="Placeholder Text"
            mode="json"
            theme="monokai"
            onChange={setCode}
            fontSize={14}
            lineHeight={19}
            showPrintMargin={false}
            showGutter={true}
            highlightActiveLine={true}
            value={code}
            style={{ width: "100%", height: "80vh" }}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showPrintMargin: false,
              tabSize: 2,
            }}
          />
        </div>
      )}

      <Offcanvas show={isModal} onHide={setIsModal} placement="bottom" style={{ height: "100vh", left: 57 }}>
        <Offcanvas.Header className="position-relative" closeButton>
          <Offcanvas.Title>JSON Overview Documentation</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="mb-3">
            <h6>1.&nbsp;&nbsp;Learn the JSON format</h6>
            <AceEditor
              placeholder="Placeholder Text"
              mode="json"
              theme="monokai"
              fontSize={14}
              lineHeight={19}
              showPrintMargin={false}
              showGutter={false}
              highlightActiveLine={true}
              value={defaultJSON2}
              readOnly={true}
              style={{ width: "80%", height: "50vh" }}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showPrintMargin: false,
                tabSize: 2,
              }}
            />
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default BookingMap;
