import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Approval = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const init = useRef(false);
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    project: { label: t("BookingList_all_project"), value: "" },
  });

  const [approvalData, setApprovalData] = useState([]);

  const getApprovalData = async () => {
    try {
      const response = await axios.get("ws/ws_rea_approval.php", {
        params: {
          task: "GetApprovalList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      console.log(data);
      if (data.status == 0) {
        setApprovalData(data.record);
      } else {
        setApprovalData([]);
      }
    } catch (error) {
      console.error();
    }
  };

  const setDateFormat = (params) => {
    const dateTime = params.value;
    const providedDate = moment(dateTime);

    if (dateTime === "0000-00-00 00:00:00") {
      return "-";
    } else {
      return providedDate.format("ll");
    }
  };

  // OPEN FUNCTION ----------------------------------------

  const openBookingDetails = (params) => {
    // window.open(`${session.origin}/booking-details/${params.data.booking_id}/${params.data.deal_id}`, "_blank");
    navigate(`/booking-details/${params.data.booking_id}/${params.data.deal_id}`);
  };

  // GRID FUNCTION ----------------------------------------

  const setProjectUnit = (params) => {
    if (params && params.data) {
      const project = params.data.project_name || "";
      const phase = params.data.phase_name || "";
      const unit = params.data.unit_number || "";

      const typeContent = `
                <div style="white-space: normal; line-height: 1.5;">
                    <div><strong>Project -</strong> ${project}</div>
                    <div><strong>Phase -</strong> ${phase}</div>
                    <div><strong>Unit -</strong> ${unit}</div>
                </div>
            `;

      return <div dangerouslySetInnerHTML={{ __html: typeContent }} />;
    }
    return "";
  };

  const setRequest = (params) => {
    if (params && params.data) {
      return (
        <p style={{ lineHeight: 1.5, marginBottom: 0 }}>
          <span className="op-text-bigger m-0 fw-bold" style={{ lineHeight: 1 }}>
            {params.data.request_title}
          </span>{" "}
          <br />
          <span className="mb-0">{params.data.request_remark}</span>
        </p>
      );
      // return request_action;
    }
    return "";
  };

  const setAppovalStatus = (params) => {
    const statusMapping = {
      pending: { displayName: "Pending", color: "#a4a4a4" },
      approved: { displayName: "Approved", color: "#21ba45" },
      rejected: { displayName: "Rejected", color: "#db2828" },
      cancelled: { displayName: "Cancelled", color: "#db03fc" },
    };

    const status = (params.data.request_approval || "").toLowerCase();
    const mappedStatus = statusMapping[status] || { displayName: status, color: "" };

    const statusStyle = {
      backgroundColor: mappedStatus.color,
      color: "white",
    };

    return (
      <div className="op-label" style={statusStyle}>
        {mappedStatus.displayName}
      </div>
    );
  };

  const containerStyle = useMemo(() => ({ width: "100%", height: "85vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    { headerName: t("BookingList_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, maxWidth: 75, cellClass: "center" },
    { headerName: "Request Created", field: "date_time_create", maxWidth: 150, cellRenderer: setDateFormat },
    { headerName: "Request Action", field: "", cellRenderer: setRequest, onCellClicked: openBookingDetails },
    { headerName: "Request By", pinned: "right", field: "user_name", headerClass: "center", cellClass: "center", width: 150 },
    { headerName: "Status", field: "", cellClass: "center", pinned: "right", headerClass: "center", cellClass: "center", width: 150, cellRenderer: setAppovalStatus },
    // {
    //     headerName: t("BookingList_buyer_name"),
    //     field: "buyer_name",
    //     width: 250,
    //     cellStyle: { textTransform: "uppercase" },
    //     cellRenderer: function (params) {
    //         return (
    //             <span
    //                 style={{ cursor: 'pointer' }}
    //                 onClick={() => openBookingDetails(params)}
    //             >
    //                 {params.value}
    //             </span>
    //         );
    //     }
    // },

    // { headerName: "Unit Information", field: "", width: 250, cellRenderer: setProjectUnit },
  ];

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          getApprovalData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            {/* <Col xxl={12}>
              <Stack direction="horizontal" style={{ flexDirection: "row-reverse" }}>
                <Form.Group>
                  <FormSelect valueDefault={filter.project} width="200px" shadow={true} border={false} />
                </Form.Group>
              </Stack>
            </Col> */}
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact columnDefs={gridColumn} defaultColDef={{ flex: 1 }} rowData={approvalData} rowHeight={80} pagination={true} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default Approval;
