import React, { useState, useEffect, useRef, forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Form, Row, Stack, Modal, Carousel, Image, Offcanvas } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { faHouseBuilding, faHouseChimneyWindow, faSackDollar, faHouse as faHouse2, faHouseFlag, faBorderBottomRight, faElevator, faStreetView, faBedFront, faBath, faSquareParking, faTags, faUserTie } from "@fortawesome/pro-duotone-svg-icons";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { setCurrency } from "../lib/js/Function";
import { useKit, useOutlet } from "./CustomerKit";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";
import MortgageCalculator from "./MortgageCalculator";
import "../lib/css/OffCanvas.css";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import FsLightbox from "fslightbox-react";
import "../lib/scss/saleskit-unit.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

const CustomerKitUnitDetails = () => {
  const { t } = useTranslation();
  const initialized = useRef(false);
  const [loading, setLoading] = useState(true);
  const [unitData, setUnitData] = useState([]);
  const [unitImageIndex, setUnitImageIndex] = useState(0);
  const [unitImages, setUnitImages] = useState([]);
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [salesChart, setSalesChart] = useState({});
  const [level, setLevel] = useState({});
  const [carpark, setCarpark] = useState({});
  const [floorPlan, setFloorPlan] = useState([]);
  const [floorPlanIndex, setFloorPlanIndex] = useState(0);
  const { Formik } = formik;

  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const openRegisterModal = () => setShowRegisterModal(true);
  const closeRegisterModal = () => setShowRegisterModal(false);

  const navigate = useNavigate();
  const { projectData, hostUrl, hostUrlType, company_id, allProjectData } = useKit();
  const { rea_project_id, project_id, rea_unit_id } = useParams();
  const [sourceRecord, setSourceRecord] = useState([]);
  const [getProjectData, setGetProjectData] = useState([]);
  const [phaseDetails, setPhaseDetails] = useState([]);
  const [isCalculator, setIsCalculator] = useState(false);
  const [isUnit, setIsUnit] = useState(false);
  const [unitSwiper, setUnitSwiper] = useState(false);
  const [unitSwiperIndex, setUnitSwiperIndex] = useState(0);
  const [unitModalSwiperIndex, setUnitModalSwiperIndex] = useState(0);

  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const [floorPlanSwiper, setFloorPlanSwiper] = useState(false);
  const [floorPlanSwiperIndex, setFloorPlanSwiperIndex] = useState(0);
  const [floorPlanModalSwiperIndex, setFloorPlanModalSwiperIndex] = useState(0);

  const setUnitStatusLabel = (status) => {
    if (status === "available") {
      return "Available";
    } else if (status === "booked" || "reserved" || "sold" || "unavailable" || "on_hold") {
      return "Unavailable";
    } else {
      return "";
    }
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked" || "reserved" || "sold" || "unavailable" || "on_hold") {
      return "secondary";
    } else {
      return "";
    }
  };

  const getUnitDetails = () => {
    // selected project
    const selectedProject = projectData.find((project) => project.rea_project_id === project_id);

    // selected phase details
    const selectedPhaseDetails = selectedProject.phase_record[0];

    // selected unit details
    const selectedUnitDetails = selectedProject.unit_record.find((unit) => unit.rea_unit_id === rea_unit_id);

    setPhaseDetails(selectedPhaseDetails);
    setGetProjectData(selectedProject);
    setUnitData(selectedUnitDetails);
    setUnitImages(selectedUnitDetails.unit_images ? selectedUnitDetails.unit_images.split(",") : ["generic.jpg"]);
    getFindUnitSalesChart(selectedUnitDetails.rea_phase_id);
    setLoading(false);

    if (selectedUnitDetails.floor_plan) {
      var floor_plan_images = selectedUnitDetails.floor_plan.split(",");
      setFloorPlan(floor_plan_images);
    } else {
      getTypeListing(selectedUnitDetails.rea_type_id);
    }
  };

  const getTypeListing = async (typeid) => {
    try {
      const response = await axios.get("ws/ws_rea.php", {
        params: {
          task: "GetTypeListing",
          utoken: allProjectData.user_ac_token,
          ctoken: allProjectData.secret_key,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const typeinfo = data.record.find((record) => record.rea_type_id === typeid);
        if (typeinfo.floor_plan) {
          var floor_plan_images = typeinfo.floor_plan.split(",");
          setFloorPlan(floor_plan_images);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFindUnitSalesChart = (phase_id) => {
    axios
      .get(`https://app.outperformhq.io/cloud/assets/rea_booking/api.php`, {
        params: {
          company_id: company_id,
        },
      })
      .then(({ data }) => {
        if (data.status === 0) {
          const recordData = JSON.parse(data.record);
          const clientMap = recordData[phase_id];

          if (clientMap) {
            if (clientMap.level_availibility) {
              setLevel(setUnitLevel(clientMap.level, rea_unit_id) || {});
            }

            if (clientMap.carpark_availability) {
              setCarpark(setUnitCarpark(clientMap.carpark_level, rea_unit_id) || {});
            }

            setIsSalesChart(true);
            setSalesChart(clientMap);
          } else {
            setIsSalesChart(false);
            setSalesChart(null);
          }
        }
      });
  };

  const setUnitAreaNumber = (area) => {
    if (area) {
      const str = area.replace(/\s*sqft/i, "");
      return Number(str);
    }

    return 0;
  };

  const openFindLevel = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: level.level_id,
      carpark_id: carpark.carpark_id,
    };

    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/sales-chart/level/0/0/${unitData.rea_project_id}/${unitData.rea_phase_id}/${level.level_id}?search=true`);
  };

  const openFindCarpark = () => {
    const params = {
      rea_project_id: unitData.rea_project_id,
      rea_phase_id: unitData.rea_phase_id,
      rea_unit_id: unitData.rea_unit_id,
      level_id: level.level_id,
      carpark_id: carpark.carpark_id,
    };

    sessionStorage.removeItem("findUnit");
    sessionStorage.setItem("findUnit", JSON.stringify(params));
    navigate(`/sales-chart/carpark/0/0/${unitData.rea_project_id}/${unitData.rea_phase_id}/${carpark.carpark_id}?search=true`);
  };

  const openFloorPlan = () => {
    setIsFloorPlan(!isFloorPlan);
  };

  const setUnitLevel = (levelList, unitId) => {
    for (var i = 0; i < levelList.length; i++) {
      const level = levelList[i];
      for (var j = 0; j < level.unit.length; j++) {
        const unit = level.unit[j];
        if (unit.rea_unit_id === unitId) {
          return level;
        }
      }
    }
    return null;
  };

  const setUnitCarpark = (carparkList, unitId) => {
    for (var i = 0; i < carparkList.length; i++) {
      const carpark = carparkList[i];
      for (var j = 0; j < carpark.unit.length; j++) {
        const unit = carpark.unit[j];
        if (unit.rea_unit_id === unitId) {
          return carpark;
        }
      }
    }
    return null;
  };

  const setRemoveRM = (value) => {
    if (value) {
      let digitsOnly = value.replace(/\D/g, "");
      return digitsOnly;
    }

    return value;
  };

  const handleOpenRegisterModal = () => {
    openRegisterModal();
  };

  const handleCloseRegisterModal = () => {
    closeRegisterModal();
  };

  const getSourceRecord = async () => {
    const sourceRecord = allProjectData.source_record;
    setSourceRecord(sourceRecord);
  };

  const registerClicked = async (values) => {
    try {
      const response1 = await addNewDeal(values);
      const response2 = await setUnitInterest(values);

      if ((response1.data.status === 0) & (response2.data.status === 0)) {
        handleCloseRegisterModal();
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: t("CustomerKitUnitDetails_successfully_create_new_deal_and_interest"),
          timer: 2000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: t("CustomerKitUnitDetails_failed_to_create_new_deal_and_interest"),
        timer: 2000,
      });
    }
  };

  const addNewDeal = async (values) => {
    try {
      const sourceToken = allProjectData.source_token ? allProjectData.source_token : values.source.value;

      const response = await axios.get("/toolbox/api_addNewDeal.php", {
        params: {
          task: "addNewDeal",
          secret_key: allProjectData.secret_key,
          name: values.name,
          email: values.email,
          contact: values.contact,
          source_token: sourceToken,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const setUnitInterest = async (values) => {
    try {
      const sourceToken = allProjectData.source_token ? allProjectData.source_token : values.source.value;

      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/ws_rea_unit_interest_prod.php", {
        params: {
          task: "setUnitInterestRea",
          phase_id: unitData.rea_phase_id,
          unit_id: rea_unit_id,
          lead_name: values.name,
          lead_contact: values.contact,
          lead_email: values.email,
          unit_priority: "",
          source_token: sourceToken,
          secret_key: allProjectData.secret_key,
          tag_token: allProjectData.tag_token,
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  };

  const setAreaSqm = (area) => {
    if (area) {
      let calc = setUnitAreaNumber(area) / 10.7639;
      return Math.round(calc);
    }

    return 0;
  };

  const setLandAreaSqm = (area) => {
    if (area) {
      let calc = setUnitAreaNumber(area) / 10.7639;
      return Math.round(calc);
    }

    return 0;
  };

  const CarouselImages = forwardRef(({ images, path }, ref) => {
    const [imagesIndex, setImagesIndex] = useState(0);

    return (
      <Container className="m-0 w-100" ref={ref} style={{ height: "100vh" }}>
        <Carousel className="rounded" variant="dark" activeIndex={imagesIndex} onSelect={setImagesIndex} style={{ height: "100%" }}>
          {images.split(",").map((record, index) => (
            <Carousel.Item key={index} className="rounded">
              <Image className="d-block" src={`https://app.outperformhq.io/cloud/${path}/${record}`} width={500} rounded />
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    );
  });

  const RenderImageUnavailable = () => (
    <div className="position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: 400 }}>
      <FontAwesomeIcon icon={faHouse} size="10x" style={{ opacity: 0.05 }} />
      <h6 style={{ position: "absolute" }}>Image Not Available</h6>
    </div>
  );

  // SCHEMA ------------------------------------------------------

  const registerProspectSchema = yup.object().shape({
    name: yup.string().required(t("CustomerKitUnitDetails_name_is_required")),
    contact: yup.string().required(t("CustomerKitUnitDetails_contact_number_is_required")),
    email: yup.string().required(t("CustomerKitUnitDetails_email_address_is_required")).email(),
  });

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getUnitDetails();
      getSourceRecord();
    }
  });

  return (
    <Container className="saleskit-unit">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Container>
            <Row>
              <Col xxl={12} className="mb-2">
                <Row>
                  <Col xxl={8} className="mb-2">
                    <div className="op-text-bigger fs-5 fw-bold">{unitData.unit_number}</div>
                  </Col>
                  <Col xxl={4} className="mb-3 section-header-button">
                    <Stack direction="horizontal" gap={2}>
                      <Button variant={setStatusColor(unitData.unit_status)}>{setUnitStatusLabel(unitData.unit_status)}</Button>
                      {unitData.bumi_reserved == 1 && (
                        <Button variant="" className="shadow-sm border me-2" style={{ backgroundColor: "#eee", color: "#000" }}>
                          BUMI
                        </Button>
                      )}
                      {unitData.bumi_reserved == 0 && (
                        <Button variant="" className="shadow-sm border me-2" style={{ backgroundColor: "#eee", color: "#000" }}>
                          NON BUMI
                        </Button>
                      )}
                    </Stack>
                  </Col>
                </Row>
              </Col>
              <Col xxl={12} className="mb-4">
                <Card className="border-0 shadow-sm w-100 ">
                  <Card.Header className="fw-bold">Unit Price</Card.Header>
                  <Card.Body>
                    <Row>
                      {unitData.unit_selling_price && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faSackDollar} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">SPA Price</span> <br />
                                <span className="text-muted">{setCurrency(unitData.unit_selling_price)}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_selling_price && unitData.unit_builtup_area && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faSackDollar} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Selling PSF</span> <br />
                                <span className="text-muted">{setCurrency(Number(unitData.unit_selling_price) / setUnitAreaNumber(unitData.unit_builtup_area))}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.max_discount && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faSackDollar} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Maximum Discount Allowable</span> <br />
                                <span className="text-muted">{setCurrency(setRemoveRM(unitData.max_discount))}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                  <Card.Footer className="py-3 d-flex justify-content-between align-items-center cursor-pointer" onClick={setIsCalculator}>
                    <span>Mortgage Calculator</span>
                    <span>
                      <FontAwesomeIcon icon={faChevronRight} size="xl" />
                    </span>
                  </Card.Footer>
                </Card>
              </Col>

              <Col xxl={12} className="mb-4">
                <Card className="border-0 shadow-sm w-100 ">
                  <Card.Header className="fw-bold">Unit Details</Card.Header>
                  <Card.Body>
                    <Row>
                      {getProjectData.project_name && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouseBuilding} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Project</span> <br />
                                <span className="text-muted">{getProjectData.project_name || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {phaseDetails.phase_name && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouseChimneyWindow} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Phase</span> <br />
                                <span className="text-muted">{phaseDetails.phase_name}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {(unitData.unit_type || unitData.unit_title) && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouseChimneyWindow} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Unit Type</span> <br />
                                <span className="text-muted">
                                  {unitData.unit_title} {unitData.unit_type}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_number && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouse2} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Unit Number</span> <br />
                                <span className="text-muted">{unitData.unit_number}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_builtup_area && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouseFlag} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Built-Up Area (sqft)</span> <br />
                                <span className="text-muted">{unitData.unit_builtup_area || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_builtup_area && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faHouseFlag} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Built-Up Area (sqm)</span> <br />
                                <span className="text-muted">{setAreaSqm(unitData.unit_builtup_area)}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_land_area && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faBorderBottomRight} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Land Area (sqft)</span> <br />
                                <span className="text-muted">{unitData.unit_land_area || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_land_area && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faBorderBottomRight} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Land Area (sqm)</span> <br />
                                <span className="text-muted">{setLandAreaSqm(unitData.unit_builtup_area)}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_level && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faElevator} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Unit Level</span> <br />
                                <span className="text-muted">{unitData.unit_level || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_facing && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faStreetView} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Facing</span> <br />
                                <span className="text-muted">{unitData.unit_facing || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_total_bedroom && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faBedFront} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Bedroom</span> <br />
                                <span className="text-muted">{unitData.unit_total_bedroom || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_total_bathroom && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faBath} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Bathroom</span> <br />
                                <span className="text-muted">{unitData.unit_total_bathroom || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}

                      {unitData.unit_car_park_no && (
                        <Col xxl={4} className="mb-3">
                          <div className="w-100">
                            <div className="d-flex align-items-center">
                              <div className="me-2" style={{ width: 30 }}>
                                <FontAwesomeIcon icon={faSquareParking} size="2x" />
                              </div>
                              <div>
                                <span className="fw-bold">Car Park</span> <br />
                                <span className="text-muted">{unitData.unit_car_park_no || "-"}</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {(unitData.solicitor_info || unitData.solicitor_contact || unitData.solicitor_address || unitData.solicitor_pic) && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100 ">
                    <Card.Header className="fw-bold">Solicitor Details</Card.Header>
                    <Card.Body>
                      {unitData.solicitor_info && (
                        <section className="mb-3">
                          <div className="w-100">
                            <span className="fw-bold">Solicitor</span> <br />
                            <span className="text-muted">{unitData.solicitor_info || "-"}</span>
                          </div>
                        </section>
                      )}

                      {unitData.solicitor_contact && (
                        <section className="mb-3">
                          <div className="w-100">
                            <span className="fw-bold">Solicitor Contact</span> <br />
                            <span className="text-muted">{unitData.solicitor_contact || "-"}</span>
                          </div>
                        </section>
                      )}

                      {unitData.solicitor_address && (
                        <section className="mb-3">
                          <div className="w-100">
                            <span className="fw-bold">Solicitor Address</span> <br />
                            <span className="text-muted">{unitData.solicitor_address || "-"}</span>
                          </div>
                        </section>
                      )}

                      {unitData.solicitor_pic && (
                        <section>
                          <div className="w-100">
                            <span className="fw-bold">Solicitor PIC</span> <br />
                            <span className="text-muted">{unitData.solicitor_pic || "-"}</span>
                          </div>
                        </section>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {unitData.remark && (
                <Col xxl={12} className="mb-4">
                  <Card className="border-0 shadow-sm w-100 ">
                    <Card.Header className="fw-bold">Remark</Card.Header>
                    <Card.Body>{unitData.remark}</Card.Body>
                  </Card>
                </Col>
              )}

              <Col xxl={6} className="mb-4">
                <Card className="border-0 shadow-sm w-100">
                  <Card.Header className="fw-bold">Unit Gallery</Card.Header>
                  <Card.Body>
                    {unitImages.length > 0 ? (
                      <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                        {unitImages.map((data, i) => (
                          <SwiperSlide key={i}>
                            <img
                              className="cursor-pointer"
                              style={{ height: 400, borderRadius: 5 }}
                              src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${data}`}
                              alt="mediaimage"
                              onClick={() => {
                                setUnitSwiperIndex(i);
                                setUnitSwiper(null);
                                setIsUnit(true);
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    ) : (
                      <RenderImageUnavailable />
                    )}
                  </Card.Body>
                </Card>
              </Col>

              {floorPlan.length > 0 && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Floor Plan</Card.Header>
                    <Card.Body>
                      <Swiper spaceBetween={10} pagination={true} modules={[Pagination]} className="swiperpd3">
                        {floorPlan.map((data, i) => (
                          <SwiperSlide key={i}>
                            <img
                              className="cursor-pointer"
                              style={{ height: 400, borderRadius: 5 }}
                              src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${data}`}
                              alt="mediaimage"
                              onClick={() => {
                                setFloorPlanSwiperIndex(i);
                                setFloorPlanSwiper(null);
                                setIsFloorPlan(true);
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {isSalesChart && salesChart.level_availibility === 1 && level.sales_chart && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Level Site Plan ({level.level_title})</Card.Header>
                    <Card.Body onClick={() => openFindLevel()}>
                      <Image className="cursor-pointer" src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${level.sales_chart}`} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {isSalesChart && salesChart.carpark_availability === 1 && carpark.carpark_img && (
                <Col xxl={6} className="mb-4">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="fw-bold">Car Park Site Plan ({carpark.carpark_title})</Card.Header>
                    <Card.Body onClick={() => openFindCarpark()}>
                      <Image className="cursor-pointer" src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${carpark.carpark_img}`} style={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 5 }} />
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>

            <div className="section-footer">
              <Container>
                <Row>
                  <Col xxl={6}>
                    <div className="op-text-bigger fs-5 fw-bold">{unitData.unit_number}</div>
                  </Col>

                  <Col xxl={6} className="section-footer-reverse">
                    <Button variant="" className="shadow-sm border me-2 op-primary-color text-light" onClick={() => handleOpenRegisterModal()}>
                      Register Now
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>

            <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} size="md">
              <Formik
                validationSchema={registerProspectSchema}
                onSubmit={registerClicked}
                initialValues={{
                  name: "",
                  email: "",
                  contact: "",
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                      <Modal.Title as="h6">{t("CustomerKitUnitDetails_register_now")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div style={{ width: "100%", height: "60vh" }}>
                        <Form.Group className="mb-3">
                          <Form.Label>Name </Form.Label>
                          <Form.Control type="text" isInvalid={errors.name && touched.name} onChange={handleChange("name")} value={values.name} />
                          {errors.name && touched.name && <div className="op-error-message">{errors.name}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email Address </Form.Label>
                          <Form.Control type="text" isInvalid={errors.email && touched.email} onChange={handleChange("email")} value={values.email} />
                          {errors.email && touched.email && <div className="op-error-message">{errors.email}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Contact Number </Form.Label>
                          <Form.Control
                            type="text"
                            onKeyDown={(e) => {
                              // Allow: backspace, delete, tab, escape, enter
                              if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "Escape" || e.key === "Enter") {
                                return;
                              }
                              // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
                              if ((e.ctrlKey === true || e.metaKey === true) && (e.key === "a" || e.key === "c" || e.key === "v" || e.key === "x")) {
                                return;
                              }
                              // Allow: numbers 0-9
                              if (/^[0-9]+$/.test(e.key) === false) {
                                e.preventDefault();
                              }
                            }}
                            isInvalid={errors.contact && touched.contact}
                            onChange={handleChange("contact")}
                            value={values.contact}
                          />
                          {errors.contact && touched.contact && <div className="op-error-message">{errors.contact}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Source</Form.Label>
                          <FormSelect
                            options={sourceRecord.map((record) => ({
                              label: record.ds_title,
                              value: record.ds_token,
                            }))}
                            name={"source"}
                            onChange={(e) => {
                              setFieldValue("source", e);
                            }}
                            valueDefault={allProjectData.source_token !== null ? allProjectData.source_token : ""}
                          />
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="" className="shadow-sm border me-2 op-primary-color text-light" type="submit">
                        Register
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal>

            <Offcanvas show={isCalculator} onHide={setIsCalculator} placement="bottom" style={{ height: "100vh" }}>
              <MortgageCalculator getUnitData={unitData} />
            </Offcanvas>
          </Container>
        </div>
      )}
    </Container>
  );
};
export default CustomerKitUnitDetails;
