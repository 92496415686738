import { useState, useRef, useEffect, useMemo } from "react";
import { Stack, Form, Container, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import Loader from "../includes/Loader";
import "../lib/scss/op-report-style.scss";
import moment from "moment";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";

const OutgoingLogGL = () => {
    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isReport, setIsReport] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [startDate, setStartDate] = useState(moment().subtract(7, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchVal, setSearchVal] = useState("");
    const [thirdParty, setThirdParty] = useState("");

    const thirdPartyList = [
        {label: 'RR', value: 'rr'},
        {label: 'GLL', value: 'gll'},
        {label: 'GL PLAY', value: 'glplay'}
    ];

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "80vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          resizable: true,
          wrapText: true,
          autoHeight: true
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: 'Log Date Time',
            field: 'log_datetime',
            minWidth: 200
        },
        {
            headerName: 'Deal ID',
            field: 'deal_id',
            width: 100
        },
        {
            headerName: 'Prospect ID',
            field: 'prospect_id',
            width: 100
        },
        {
            headerName: '3rd party',
            field: 'provider',
            width: 100
        },
        {
            headerName: 'Log Data',
            width: 350,
            cellRenderer: (params) => {
                if (params.data) {
                    var value = JSON.stringify(params.data.log_data);
                    
                    value = value.replaceAll("{","");
                    value = value.replaceAll("}","");
                    value = value.replaceAll(/[/\\*]/g,"");
                    value = value.replaceAll(",","\n");

                    return(
                        <Form.Group className="py-3 px-1" style={{width: '300px', resize: 'none'}}>
                            <Form.Control
                                as={'textarea'}
                                rows={8}
                                value={value}
                                style={{resize: 'none'}}
                                readOnly
                            />
                        </Form.Group>
                    );
                }
            }
        },
        {
            headerName: 'Payload',
            width: 350,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.payload) {
                        var value = JSON.stringify(params.data.payload);
                    
                        value = value.replaceAll("{","");
                        value = value.replaceAll("}","");
                        value = value.replaceAll(/[/\\*]/g,"");
                        value = value.replaceAll(",","\n");

                        return(
                            <Form.Group className="py-3 px-1" style={{width: '300px', resize: 'none'}}>
                                <Form.Control
                                    as={'textarea'}
                                    rows={8}
                                    value={value}
                                    style={{resize: 'none'}}
                                    readOnly
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        {
            headerName: 'Response',
            field: 'response',
            width: 300
        },
        {
            headerName: 'Log Message',
            field: 'log_message',
            width: 200
        },
        {
            headerName: 'End Point',
            field: 'end_point_called',
            width: 300
        }
    ]);

    const getGridData = () => {
        axios.get('ext/log/gl_log_rr.php', {
            params: {
                date_start: startDate,
                date_end: endDate,
                parameter: searchVal,
                third_party: thirdParty
            }
        })
        .then(res => {
            let data = res.data;

            if (data) {
                setRecordFound(data.length);
                setRowData(data);
                setIsReport(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsReport(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setRecordFound(0);
            setRowData([]);
            setIsReport(true);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []);

    return(
        <div>
            {isLoading ? <Loader/> : 
                <div className="report-main">
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">Filter Criteria:</div>
                        </section>

                        <section className="content">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by data"
                                    onChange={(e) => {setSearchVal(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {setStartDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {setEndDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <FormSelect
                                    placeholder="All Third Party"
                                    options={thirdPartyList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e) => {
                                        setThirdParty(e ? e.value : '');
                                    }}
                                />
                            </Form.Group>
                        </section>

                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={() => {
                                        getGridData();
                                    }}
                                >
                                    View Report
                                </button>
                            </div>
                        </section>
                    </div>
                    <div className="report-content">
                        <section className="w-100">
                            <Stack direction="horizontal">
                                {isReport && <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>}
                            </Stack>
                        </section>
                        <section className="w-100">
                            <div className="mb-3" style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        rowData={rowData}
                                        defaultColDef={reportColDef}
                                        rowHeight={60}
                                        pagination={false}
                                        // paginationPageSize={30}
                                        // paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </div>
    );
}

export default OutgoingLogGL;