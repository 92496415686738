import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Gamuda = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/gamuda" || location.pathname === "/gamuda/") {
      navigate("/gamuda/tier", { replace: true });
    }
  }, [location, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid style={{ margin: 0, padding: 0 }}>
      <Outlet />
    </Container>
  );
};

export default Gamuda;
