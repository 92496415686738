import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Form, ListGroup, Modal } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import * as formik from "formik";

const BookingFormat = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({ column: "", value: "" });
  const [isModal, setIsModal] = useState(false);
  const [booking, setBooking] = useState({
    rea_booking_form_format_id: "",
    company_id: "106",
    booking_fee_label: "Booking Fee",
    booking_fee_show: "1",
    booking_fee_required: "1",
    financing_label: "Financing",
    financing_show: "1",
    financing_required: "1",
    first_time_buyer_label: "First Time Buyer",
    first_time_buyer_show: "1",
    first_time_buyer_required: "1",
    bumiputera_label: "Bumiputera",
    bumiputera_show: "1",
    bumiputera_required: "1",
    payment_method_label: "Payment Method",
    payment_method_show: "1",
    payment_method_required: "1",
    payment_reference_label: "Payment Reference",
    payment_reference_show: "1",
    payment_reference_required: "1",
    payment_receipt_label: "Payment Receipt",
    payment_receipt_show: "1",
    payment_receipt_required: "1",
    source_label: "Source",
    source_show: "1",
    source_required: "1",
    project_label: "Project",
    project_show: "1",
    phase_label: "Phase",
    phase_show: "1",
    unit_type_label: "Unit Type",
    unit_type_show: "1",
    unit_label: "Unit",
    unit_show: "1",
    bumi_discount_label: "Bumi Package",
    bumi_discount_show: "1",
    rebate_package_label: "Rebate Package",
    rebate_package_show: "1",
    selling_price_label: "SPA Price",
    selling_price_show: "1",
    net_price_label: "Net Price",
    net_price_show: "1",
  });

  // USEEFFECT FUNCTION ----------------------

  const getData = useCallback(async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetFormat",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setBooking(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onchangeData = useCallback(
    async (column) => {
      const recordValues = Number(booking[column]) === 1 ? 0 : 1;
      const recordUpdate = { ...booking };
      recordUpdate[column] = recordValues;
      setBooking(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateBookingFormat",
            column: column,
            value: recordValues,
            rea_booking_form_format_id: booking.rea_booking_form_format_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [booking, session]
  );

  const onchangeLabel = useCallback(
    (column) => {
      setInitialValues({ column: column, value: booking[column] });
      setIsModal(true);
    },
    [booking]
  );

  const sendLabel = useCallback(
    async (values) => {
      setIsModal(false);
      const recordUpdate = { ...booking };
      recordUpdate[values.column] = values.value;
      setBooking(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateBookingFormat",
            column: values.column,
            value: values.value,
            rea_booking_form_format_id: booking.rea_booking_form_format_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [booking, session]
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <section className="mb-4">
            <h6>Booking Form</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("booking_fee_label")}>
                  {booking.booking_fee_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.booking_fee_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("booking_fee_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.booking_fee_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("booking_fee_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("financing_label")}>
                  {booking.financing_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.financing_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("financing_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.financing_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("financing_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("first_time_buyer_label")}>
                  {booking.first_time_buyer_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.first_time_buyer_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("first_time_buyer_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.first_time_buyer_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("first_time_buyer_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("bumiputera_label")}>
                  {booking.bumiputera_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.bumiputera_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("bumiputera_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.bumiputera_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("bumiputera_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("payment_method_label")}>
                  {booking.payment_method_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.payment_method_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_method_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.payment_method_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_method_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("payment_reference_label")}>
                  {booking.payment_reference_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.payment_reference_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_reference_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.payment_reference_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_reference_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("payment_receipt_label")}>
                  {booking.payment_receipt_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.payment_receipt_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_receipt_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.payment_receipt_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("payment_receipt_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("source_label")}>
                  {booking.source_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(booking.source_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("source_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(booking.source_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("source_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("project_label")}>
                  {booking.project_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.project_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("project_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("phase_label")}>
                  {booking.phase_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.phase_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("phase_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("unit_type_label")}>
                  {booking.unit_type_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.unit_type_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("unit_type_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("unit_label")}>
                  {booking.unit_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.unit_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("unit_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("bumi_discount_label")}>
                  {booking.bumi_discount_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.bumi_discount_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("bumi_discount_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("rebate_package_label")}>
                  {booking.rebate_package_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.rebate_package_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("rebate_package_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("selling_price_label")}>
                  {booking.selling_price_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.selling_price_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("selling_price_show")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("net_price_label")}>
                  {booking.net_price_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" checked={Number(booking.net_price_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("net_price_show")} />
                </div>
              </ListGroup.Item>
            </ListGroup>
          </section>
        </div>
      )}

      <Modal show={isModal} onHide={setIsModal} size="lg">
        <Formik onSubmit={sendLabel} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Change Label</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Label</Form.Label>
                  <Form.Control type="text" value={values.value} onChange={handleChange("value")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default BookingFormat;
