import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, Offcanvas  } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import TeamUserSetting from "./SettingTeamUser.jsx";
import { useTranslation } from "react-i18next";


const TeamSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [timeoutList, setTimeoutList] = useState([]);
    const [transferMgrList, setTransferMgrList] = useState([]);

    const [isUserList, setIsUserList] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});

    const getAllTimeoutMgr = () => {
        axios.get('ws/ws_user.php',{
            params:{
                task: "4a",
                user_tag: "",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var list = data.record.map(data => {
                    return {label: data.user_name, value: data.user_id};
                });

                var mgrList = [];

                data.record.forEach(data => {
                    if (Number(data.role_id) === 1) {
                        mgrList.push({label: data.user_name, value: data.user_id});
                    }
                });

                setTimeoutList(list);
                setTransferMgrList(mgrList);
            }else{
                setTimeoutList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingTeam_headername_1'),
            field: "user_tag_title",
            resizable: true,
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    setSelectedTeam(params.data);
                    setIsUserList(true);
                }
            }
        },
        {
            headerName: t('SettingTeam_headername_2'),
            field: "tag_token",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingTeam_headername_3'),
            field: "default_admin_name",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingTeam_headername_4'),
            field: "ai_transfer_manager_name",
            resizable: true,
            width: 250
        },
        {
            headerName: t('SettingTeam_headername_5'),
            resizable: true,
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "getUserTagsV2",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllTimeoutMgr();
            getGridData();
        }
    }); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        team_name: yup.string().required(t('SettingTeam_required_field')),
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_user.php',{
            params:{
                task: 'addUserTagV2',
                tag_title: values.team_name,
                default_admin: values.team_timeout_manager,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;
            
            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingTeam_alert_success_title'),
                    text: t('SettingTeam_alert_success_add_text'),
                    timer: 1500
                }).then(result => {
                    
                    setIsLoading(true);
                    setIsAdd(false);
                    getGridData();
                    
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingTeam_alert_failed_title'),
                    text: t('SettingTeam_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        team_title: yup.string().required(t('SettingTeam_required_field')),
    });

    const handleEdit = (data) => {
        setEditInitVal(
            {
                team_id: data.user_tag_id,
                team_title: data.user_tag_title,
                team_timeout_mgr: data.default_admin,
                team_ai_transfer_mgr: data.ai_transfer_manager
            }
        );
        setIsEdit(true);
    }

    const sendEdit = (values) => {
        axios.get('ws/ws_user.php',{
            params:{
                task: 'editUserTagV2',
                user_tag_id: values.team_id,
                tag_title: values.team_title,
                inactive: "",
                default_admin: values.team_timeout_mgr,
                ai_transfer_manager: values.team_ai_transfer_mgr,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingTeam_alert_success_title'),
                    text: t('SettingTeam_alert_success_edit_text'),
                    timer: 1500
                }).then(result => {
                    
                    setIsLoading(true);
                    setIsEdit(false);
                    getGridData();
                    
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingTeam_alert_failed_title'),
                    text: t('SettingTeam_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    return(
        <div>
            {isLoading ? (<Loader />):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingTeam_btn_add')}</button>
                            <div className="px-2 ms-auto">Result: {recordFound === 0 ? t('SettingTeam_record_no'):t('SettingTeam_record',{count: recordFound})}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={100}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    team_name: "",
                                    team_timeout_manager: "",
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingTeam_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingTeam_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.team_name && touched.team_name}
                                                onChange={handleChange('team_name')}
                                            />
                                            {errors.team_name && touched.team_name && <div className="op-error-message">{errors.team_name}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingTeam_modalBody_add_title_2')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingTeam_modalBody_add_title_2_placeholder')}
                                                options={timeoutList}
                                                isClearable={true}
                                                isSearchable={true}
                                                onChange={(e) => {handleChange('team_timeout_manager')(e ? e.value:"")}}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingTeam_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingTeam_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingTeam_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.team_title}
                                                isInvalid={errors.team_title && touched.team_title}
                                                onChange={handleChange('team_title')}
                                            />
                                            {errors.team_title && touched.team_title && <div className="op-error-message">{errors.team_title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingTeam_modalBody_edit_title_2')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingTeam_modalBody_edit_title_2_placeholder')}
                                                options={timeoutList}
                                                valueDefault={timeoutList.filter(option => option.value === values.team_timeout_mgr)}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('team_timeout_mgr')(e ? e.value:"")}}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingTeam_modalBody_edit_title_3')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingTeam_modalBody_edit_title_3_placeholder')}
                                                options={transferMgrList}
                                                valueDefault={transferMgrList.filter(option => option.value === values.team_ai_transfer_mgr)}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('team_ai_transfer_mgr')(e ? e.value:"")}}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingTeam_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Offcanvas show={isUserList} onHide={setIsUserList} placement="bottom" style={{left: 57,height: "100vh"}}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title as={"h6"}>{t('SettingTeam_offcanvas_header_title')}: {selectedTeam.user_tag_title}</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <TeamUserSetting user_tag_id={selectedTeam.user_tag_id}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Container>
            )}
        </div>
    );
}

export default TeamSetting;