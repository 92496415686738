import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiagramSankey as faDiagramSankeyActive,
  faPenField as faPenFieldActive,
  faBell as faBellActive,
  faBullhorn as faBullhornActive,
  faEnvelope as faEnvelopeActive,
  faLink as faLinkActive,
  faPeopleGroup as faPeopleGroupActive,
  faBuildings as faBuildingsActive,
  faMegaphone as faMegaphoneActive,
  faCalendarUsers as faCalendarUsersActive,
  faPersonCircleQuestion as faPersonCircleQuestionActive,
  faPhone as faPhoneActive,
  faMobile as faMobileActive,
  faList12 as faList12Active,
  faUserLock as faUserLockActive,
  faSquarePollVertical as faSquarePollVerticalActive,
  faHundredPoints as faHundredPointsActive,
  faBox as faBoxActive,
  faBullseyePointer as faBullseyePointerActive,
  faDollarCircle as faDollarCircleActive,
  faHandsHoldingDiamond as faHandsHoldingDiamondActive,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faBell,
  faBox,
  faBuildings,
  faBullhorn,
  faBullseyePointer,
  faCalendarUsers,
  faDiagramSankey,
  faDollarCircle,
  faEnvelope,
  faHundredPoints,
  faLink,
  faList12,
  faMegaphone,
  faMobile,
  faPenField,
  faPeopleGroup,
  faPersonCircleQuestion,
  faPhone,
  faSquarePollVertical,
  faUserLock,
  faHandsHoldingDiamond,
} from "@fortawesome/pro-light-svg-icons";
import { faFacebookF, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useAuth } from "../auth/AuthContext";

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useAuth();

  useEffect(() => {
    if (location.pathname === "/settings" || location.pathname === "/settings/") {
      navigate("/settings/deal-source", { replace: true });
    }
  }, [navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0 d-flex" style={{ height: "calc(100vh - 56px)" }}>
      <div className="h-100 overflow-auto op-scrollbar" style={{ width: "15%" }}>
        <Nav variant="pills" className="flex-column sidebar-tab bg-light py-4 px-2 w-100">
          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/pipeline" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faDiagramSankeyActive : faDiagramSankey} size="xl" className="me-2" />
                  </span>
                  <span>Pipeline Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/fields" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faPenFieldActive : faPenField} size="xl" className="me-2" />
                  </span>
                  Custom Field
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/notification" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faBellActive : faBell} size="xl" className="me-2" />
                  </span>
                  <span>Notification Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/deal-source" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faBullhornActive : faBullhorn} size="xl" className="me-2" />
                  </span>
                  <span>Deal Source</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/email-template" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faEnvelopeActive : faEnvelope} size="xl" className="me-2" />
                  </span>
                  <span>Email Template</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/whatsapp-template" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faWhatsapp : faWhatsapp} size="xl" className="me-2" />
                  </span>
                  <span>Whatsapp Template</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/smartlink-template" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faLinkActive : faLink} size="xl" className="me-2" />
                  </span>
                  <span>Smartlink Template</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/fb-connection" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faFacebookF : faFacebookF} size="xl" className="me-2" />
                  </span>
                  <span>FB Connection</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/facebook-token" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faFacebookF : faFacebookF} size="xl" className="me-2" />
                  </span>
                  <span>FB Token</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/fb-form" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faFacebookF : faFacebookF} size="xl" className="me-2" />
                  </span>
                  <span>Facebook Forms</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/marketing-form" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faList12Active : faList12} size="xl" className="me-2" />
                  </span>
                  <span>Marketing Forms</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/team" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faPeopleGroupActive : faPeopleGroup} size="xl" className="me-2" />
                  </span>
                  <span>Team Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/campaign-settings" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faBullhornActive : faBullhorn} size="xl" className="me-2" />
                  </span>
                  <span>Campaign Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/business-unit" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faBuildingsActive : faBuildings} size="xl" className="me-2" />
                  </span>
                  <span>Business Unit Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/source-category" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faMegaphoneActive : faMegaphone} size="xl" className="me-2" />
                  </span>
                  <span>Source Category Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/meetup" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faCalendarUsersActive : faCalendarUsers} size="xl" className="me-2" />
                  </span>
                  <span>Meetup Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/lost-reason" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faPersonCircleQuestionActive : faPersonCircleQuestion} size="xl" className="me-2" />
                  </span>
                  <span>Lost Reason</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/avanser-call" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faPhoneActive : faPhone} size="xl" className="me-2" />
                  </span>
                  <span>Avanser Call Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          {Number(session.survey) === 1 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/settings/survey-settings" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2 text-center" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faSquarePollVerticalActive : faSquarePollVertical} size="xl" className="me-2" />
                    </span>
                    <span>Survey Settings</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/device-notification" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faMobileActive : faMobile} size="xl" className="me-2" />
                  </span>
                  <span>Device Notification</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/lead-score" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faHundredPointsActive : faHundredPoints} size="xl" className="me-2" />
                  </span>
                  <span>Lead Score</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/permission" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faUserLockActive : faUserLock} size="xl" className="me-2" />
                  </span>
                  <span>Permission Settings</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          {Number(session.company_id) === 251 && (
            <Nav.Item className="mb-2">
              <NavLink className="nav-link" to="/settings/bulk-update" activeclassname="active">
                {({ isActive }) => (
                  <div className="d-flex align-items-center py-1">
                    <span className="me-2 text-center" style={{ width: 25 }}>
                      <FontAwesomeIcon icon={isActive ? faBullseyePointerActive : faBullseyePointer} size="xl" className="me-2" />
                    </span>
                    <span>Bulk Update</span>
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          )}

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/upload-leads" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faDollarCircleActive : faDollarCircle} size="xl" className="me-2" />
                  </span>
                  <span>Upload Leads</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/getresponse-connection" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faHandsHoldingDiamondActive : faHandsHoldingDiamond} size="xl" className="me-2" />
                  </span>
                  <span>Getresponse Connection</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/settings/chat-instance" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2 text-center" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faHandsHoldingDiamondActive : faHandsHoldingDiamond} size="xl" className="me-2" />
                  </span>
                  <span>Chat Instance</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>

      <div className="overflow-auto" style={{ width: "85%" }}>
        <Outlet />
      </div>
    </Container>
  );
};

export default Settings;
