import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup, Nav } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";
import { faBuilding, faDollarCircle, faHouse, faPerson, faUser } from "@fortawesome/pro-light-svg-icons";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const CustomField = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/settings/fields" || location.pathname === "/settings/fields/") {
      navigate("/settings/fields/deal", { replace: true });
    }
  }, [navigate, location]);

  useEffect(() => {
    if (!init.current) {
      setLoading(false);
      init.current = true;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid>
          <p className="mt-3">Please bear in mind that customized fields are shared with all users throughout your company.</p>
          <Nav variant="underline" defaultActiveKey="deal" style={{ borderBottom: "1px solid #ddd" }}>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/settings/fields/deal" activeclassname="active">
                <FontAwesomeIcon icon={faDollarCircle} className="me-2" size="lg" />
                Deal
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/settings/fields/person" activeclassname="active">
                <FontAwesomeIcon icon={faUser} className="me-2" size="lg" />
                Person
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/settings/fields/organization" activeclassname="active">
                <FontAwesomeIcon icon={faBuilding} className="me-2" size="lg" />
                Organization
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link px-2" to="/settings/fields/booking" activeclassname="active">
                <FontAwesomeIcon icon={faHouse} className="me-2" size="lg" />
                Booking
              </NavLink>
            </Nav.Item>
          </Nav>
          <div className="mt-3">
            <Outlet />
          </div>
        </Container>
      )}
    </div>
  );
};

export default CustomField;
