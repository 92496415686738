import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Modal, Card } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { faPencil } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
const RedemptionEmail = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState({});
  const [disabled, setDisabled] = useState(true);

  // SCHEMA FUNCTION ====================================================

  const subjectSchema = yup.object().shape({
    birth_month_email_subject: yup.string().required("This field is required"),
    reminder_email_subject: yup.string().required("This field is required"),
    success_email_subject: yup.string().required("This field is required"),
  });

  // GET FUNCTION =======================================================

  const getEmailSubject = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "GetEmailSubject",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setSubject(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ======================================================

  const sendEmailSubject = async (values) => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "UpdateEmailSubject",
          birth_month_email_subject: values.birth_month_email_subject,
          reminder_email_subject: values.reminder_email_subject,
          success_email_subject: values.success_email_subject,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setDisabled(true);
        Swal.fire({
          icon: "success",
          text: "Successfully updated the email subject",
          timer: 2000,
        });
      } else {
        setDisabled(true);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry, please try again or contact our support IT",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getEmailSubject();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Card className="border-0 shadow-sm">
                <Card.Body>
                  <Formik validationSchema={!disabled ? subjectSchema : null} onSubmit={sendEmailSubject} initialValues={{ birth_month_email_subject: subject.birth_month_email_subject, reminder_email_subject: subject.reminder_email_subject, success_email_subject: subject.success_email_subject }}>
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                          <Form.Label>Birth Month Email Subject</Form.Label>
                          <Form.Control name="birth_month_email_subject" disabled={disabled} type="text" value={values.birth_month_email_subject} isInvalid={errors.birth_month_email_subject && touched.birth_month_email_subject} onChange={handleChange("birth_month_email_subject")} />
                          {!disabled && errors.birth_month_email_subject && touched.birth_month_email_subject && <div className="op-error-message">{errors.birth_month_email_subject}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Reminder Email Subject</Form.Label>
                          <Form.Control name="reminder_email_subject" disabled={disabled} type="text" value={values.reminder_email_subject} isInvalid={errors.reminder_email_subject && touched.reminder_email_subject} onChange={handleChange("reminder_email_subject")} />
                          {!disabled && errors.reminder_email_subject && touched.reminder_email_subject && <div className="op-error-message">{errors.reminder_email_subject}</div>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Success Email Subject</Form.Label>
                          <Form.Control name="success_email_subject" disabled={disabled} type="text" value={values.success_email_subject} isInvalid={errors.success_email_subject && touched.success_email_subject} onChange={handleChange("success_email_subject")} />
                          {!disabled && errors.success_email_subject && touched.success_email_subject && <div className="op-error-message">{errors.success_email_subject}</div>}
                        </Form.Group>

                        <Form.Group className="d-flex justify-content-center align-items-center w-100">
                          {disabled ? (
                            <Button type="button" className="op-primary-color text-light" onClick={() => setDisabled(false)}>
                              Edit
                            </Button>
                          ) : (
                            <>
                              <Button variant="danger" type="button" onClick={() => setDisabled(true)}>
                                Cancel
                              </Button>
                              <Button variant="" type="submit" className="op-primary-color text-light ms-2">
                                Save
                              </Button>
                            </>
                          )}
                        </Form.Group>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default RedemptionEmail;
