import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Col, Container, Form, Row, Stack, Modal, Carousel } from "react-bootstrap";
import { FormSelect } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "../api/axios";
import Loader from "../includes/Loader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useKit } from "./CustomerKit";
import { faDown } from "@fortawesome/pro-solid-svg-icons";
import { toJpeg } from "html-to-image";
import "../lib/scss/saleskit-saleschart.scss";

const CustomerKitSalesChart = () => {
  const init = useRef(false);
  const [loading, setLoading] = useState(true);

  const [phaseOption, setPhaseOption] = useState([]);
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [isPhase, setIsPhase] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [unitList, setUnitList] = useState({});
  const navigate = useNavigate();
  const imgRef = useRef(null);

  const { projectData, hostUrl, hostUrlType, company_id } = useKit();
  const { project_id, phase_id, key } = useParams();
  const [projectList, setProjectList] = useState([]);
  const [projectOption, setProjectOption] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [projectInfo, setProjectInfo] = useState({});
  const [phaseInfo, setPhaseInfo] = useState({});
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [download, setDownload] = useState(true);
  const salesChartRef2 = useRef();
  const [salesChartImage, setSalesChartImage] = useState("");

  // GET FUNCTION -----------------------------------------

  const getProjectListing = () => {
    const projectRecord = projectData.find((record) => record.project_id === project_id);
    const recordProject = projectData.map((project) => ({
      label: project.project_name,
      value: project.rea_project_id,
    }));

    const selectedProject = recordProject.find((record) => record.value === projectRecord.rea_project_id);
    setProjectOption(recordProject);
    setProjectList(projectData);
    setSelectedProject(selectedProject);

    const phaseData = projectRecord.phase_record.find((record) => record.phase_id === phase_id);
    const recordPhase = projectRecord.phase_record.map((phase) => ({
      label: phase.phase_name,
      value: phase.rea_phase_id,
      phase_layout: phase.phase_layout,
    }));

    const selectedPhase = recordPhase.find((phase) => phase.value === phaseData.rea_phase_id);
    setPhaseOption(recordPhase);
    setSelectedPhase(selectedPhase);
    setIsPhase(true);
    setTimeout(() => setUnitLot(projectRecord, selectedPhase), 1000);
  };

  const getSalesChart = async () => {
    try {
      const response = await axios(`${hostUrl}/${hostUrlType}/assets/rea_booking/api.php`, {
        params: {
          company_id: company_id,
        },
      });
      const data = response.data;
      if (Number(data.status) === 0) {
        return {
          status: true,
          record: JSON.parse(data.record),
        };
      } else {
        return {
          status: false,
        };
      }
    } catch (error) {
      return {
        status: false,
      };
    }
  };

  const getSalesChartImage = async (record) => {
    try {
      const url = record.split(".");
      const urlType = url[url.length - 1];

      const response = await axios.get(`https://app.outperformhq.io/cloud/php/api_get_file_content.php`, {
        params: {
          filepath: `../assets/rea_booking/${company_id}/project/${record}`,
        },
      });

      const data = response.data;
      if (data && data.content) {
        const base64Mode = `data:image/${urlType};base64,`;
        setSalesChartImage(base64Mode + data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ------------------------------------

  const onchangeProject = (event) => {
    const projectData = projectList.find((record) => record.rea_project_id === event.value);
    const phaseRecord =
      projectData.phase_count &&
      projectData.phase_record.map((record) => ({
        label: record.phase_name,
        value: record.rea_phase_id,
        phase_layout: record.phase_layout,
      }));

    setIsPhase(false);
    setPhaseOption(phaseRecord);
    setSelectedPhase(phaseRecord[0]);
    setSelectedProject(event);
    setTimeout(() => setIsPhase(true), 1);
    setUnitLot(projectData, phaseRecord[0]);
  };

  const onchangePhase = (event) => {
    const projectData = projectList.find((record) => record.rea_project_id === selectedProject.value);
    setSelectedPhase(event);
    setUnitLot(projectData, event);
  };

  // SET FUNCTION -----------------------------------------

  const setUnitLot = async (project, phase) => {
    try {
      const salesChartClient = await getSalesChart();

      if (salesChartClient.status) {
        const clientMap = salesChartClient.record[phase.value];

        setIsSalesChart(false);
        setUnitList(clientMap);
        setTimeout(() => setIsSalesChart(true), 500);

        if (clientMap) {
          await getSalesChartImage(clientMap.sales_chart);
        }
      }
    } catch {}
  };

  const setUnitColor2 = (status) => {
    if (status === "available") {
      return "#198754";
    } else if (["booked", "reserved", "sold", "unavailable"].includes(status)) {
      return "#999";
    } else {
      return "";
    }
  };

  const setUnitColor3 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 0.4)";
    } else if (["booked", "reserved", "sold", "unavailable"].includes(status)) {
      return "rgba(153, 153, 153, 0.6)";
    } else {
      return "";
    }
  };

  const setScaleCoords = (coords, image) => {
    const [x1, y1, x2, y2] = coords.map(Number);
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    return [x1 * scaleX, y1 * scaleY, x2 * scaleX, y2 * scaleY];
  };

  // OPEN FUNCTION ----------------------------------------

  const openUnitDetails = (unit) => {
    if (unit.unit_status === "available") {
      const url = `/rea-sales-kit/${key}/${unit.rea_project_id}/${project_id}/${unit.rea_unit_id}`;
      navigate(url);
    }
  };

  // SEND FUNCTION ----------------------------------------

  const sendDownload = () => {
    setDownload(true);
    setTimeout(() => {
      toJpeg(salesChartRef2.current, { cacheBust: false })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `${selectedPhase.label}_saleschart.jpg`;
          link.href = dataUrl;
          link.click();
          setDownload(false);
        })
        .catch((err) => {
          setDownload(false);
        });
    }, 1);
  };

  // RENDER FUNCTION --------------------------------------

  const RenderControl = ({ zoomIn, zoomOut }) => (
    <Stack direction="vertical" gap={2} style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
      <Button variant="light" className="shadow-sm" onClick={() => zoomIn()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => zoomOut()}>
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => sendDownload()}>
        <FontAwesomeIcon icon={faDown} />
      </Button>
    </Stack>
  );

  const setFindScalePoly = (coords, image) => {
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    const formattedCoords = [];
    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i] * scaleX;
      const y = coords[i + 1] * scaleY;
      formattedCoords.push(`${x}, ${y}`);
    }
    return formattedCoords.join(" ");
  };

  const RenderClientUnitRect = ({ record, image }) => {
    if (!image || !image.current) {
      return null; // Handle null or undefined image ref
    }

    const coords = record.coords.split(",");
    const scaledCoords = setScaleCoords(coords, image.current);
    const projectData = projectList.find((data) => data.rea_project_id === selectedProject.value);
    const unitData = projectData.unit_record.find((data) => data.rea_unit_id === record.rea_unit_id);

    if (!unitData) return;

    if (Number(unitData.on_hold) === 1) {
      unitData.unit_status = "on_hold";
    }

    return (
      <>
        <rect key={record.rea_unit_id} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill={setUnitColor3(unitData.unit_status)} strokeWidth={2} onClick={() => openUnitDetails(unitData)} />
      </>
    );
  };

  const RenderClientUnitPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const projectData = projectList.find((data) => data.rea_project_id === selectedProject.value);
      const unitData = projectData.unit_record.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData) return null;
      if (Number(unitData.on_hold) === 1) {
        unitData.unit_status = "on_hold";
      }

      return (
        <>
          <polygon key={record.rea_unit_id} points={scaledCoords} fill={setUnitColor3(unitData.unit_status)} strokeWidth={2} onClick={() => openUnitDetails(unitData)} />
        </>
      );
    },
    [imgRef, projectList, selectedProject]
  );

  const RenderClientUnitRect2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) {
        return null; // Handle null or undefined image ref
      }

      const coords = record.coords.split(",");
      const projectData = projectList.find((data) => data.rea_project_id === selectedProject.value);
      const unitData = projectData.unit_record.find((data) => data.rea_unit_id === record.rea_unit_id);

      if (!unitData) return;

      if (Number(unitData.on_hold) === 1) {
        unitData.unit_status = "on_hold";
      }
      return (
        <>
          <rect key={record.rea_unit_id} width={coords[2] - coords[0]} height={coords[3] - coords[1]} y={coords[1]} x={coords[0]} fill={setUnitColor3(unitData.unit_status)} />

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [projectList, selectedProject, download] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const projectData = projectList.find((data) => data.rea_project_id === selectedProject.value);
      const unitData = projectData.unit_record.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData) return null;
      if (Number(unitData.on_hold) === 1) {
        unitData.unit_status = "on_hold";
      }

      return (
        <>
          <polygon key={record.rea_unit_id} points={coords} fill={setUnitColor3(unitData.unit_status)} />
          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [projectList, selectedProject, download] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // USEEFFECT --------------------------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          const projectRecord = projectData.find((record) => record.project_id === project_id);
          const phaseRecord = projectRecord.phase_record.find((record) => record.phase_id === phase_id);
          setProjectInfo(projectRecord);
          setPhaseInfo(phaseRecord);
          await getProjectListing();
          init.current = true;
          setTimeout(() => setLoading(false), 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []);

  return (
    <Container className="saleskit-saleschart">
      {loading ? (
        <Loader />
      ) : (
        <Row>
          <Col xxl={12} className="mb-2">
            <Row>
              <Col xxl={12} className="mb-3">
                <Row>
                  <Col xxl={3} className="mb-2">
                    <Form.Group>
                      <FormSelect options={projectOption} valueDefault={selectedProject} onChange={onchangeProject} width="100%" border={true} shadow={true} />
                    </Form.Group>
                  </Col>

                  {isPhase && (
                    <Col xxl={3} className="mb-2">
                      <Form.Group>
                        <FormSelect options={phaseOption} valueDefault={selectedPhase} onChange={onchangePhase} width="100%" border={true} shadow={true} />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xxl={12}>
                <Stack direction="horizontal" gap={3}>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("available") }} className="me-1" /> Available
                  </div>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faCircle} style={{ color: setUnitColor2("unavailable") }} className="me-1" /> Unavailable
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col xxl={12} className="mb-2">
            <div className="w-100">
              <TransformWrapper initialScale={1} initialPositionX={200} wheel={{ disabled: true }}>
                {(utils) => (
                  <Container fluid className="position-relative m-0 p-0">
                    <RenderControl {...utils} />
                    <TransformComponent wrapperStyle={{ width: "100%", height: "75vh", backgroundColor: "#999" }}>
                      <div className="position-relative d-inline-block" style={{ width: 1000 }}>
                        <img
                          ref={imgRef}
                          src={`${hostUrl}/${hostUrlType}/assets/rea_booking/${company_id}/project/${unitList.sales_chart}`}
                          width={"100%"}
                          onLoad={() => {
                            const { width, height } = imgRef.current;
                            setSvgDimensions({ width, height });
                          }}
                        />
                        {isSalesChart ? (
                          <svg width={svgDimensions.width} height={svgDimensions.height} style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                            {unitList.unit_coords.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly key={index} record={record} image={imgRef} /> : null))}
                          </svg>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </TransformComponent>
                  </Container>
                )}
              </TransformWrapper>
              {download && (
                <div style={{ overflow: "auto", height: 0, width: 0 }}>
                  <div ref={salesChartRef2} className="position-relative d-inline-block" style={{ width: imgRef.current ? imgRef.current.naturalWidth : 1000 }}>
                    <img src={salesChartImage} width={"100%"} alt="saleschart" />
                    <svg width={imgRef.current ? imgRef.current.naturalWidth : 0} height={imgRef.current ? imgRef.current.naturalHeight : 0} style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}>
                      {unitList &&
                        unitList.unit_coords &&
                        unitList.unit_coords.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect2 key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly2 key={index} record={record} image={imgRef} /> : null))}
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CustomerKitSalesChart;
