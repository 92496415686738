import { useAuth } from "../../auth/AuthContext";
import { FormSelect } from "../../includes/FormCustom";
import axios from "../../api/axios";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { Button, Form, Modal } from "react-bootstrap";

const EditBookingInformation = ({ bumiputera, bookingData, bookingFormat, financingOptions, firstTimeBuyerOptions, bumiputeraOptions, modules, onDone }) => {
  const { session } = useAuth();
  const { Formik } = formik;

  const bookingInitialValues = {
    bumiputera: bumiputera,
    financing: bookingData.financing,
    first_time_buyer: bookingData.first_time_buyer,
    net_price: bookingData.nett_price,
  };

  const bookingSchema = yup.object().shape({
    bumiputera: yup.string().when([], {
      is: () => Number(bookingFormat.bumiputera_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    financing: yup.string().when([], {
      is: () => Number(bookingFormat.financing_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
    first_time_buyer: yup.string().when([], {
      is: () => Number(bookingFormat.first_time_buyer_required) === 1,
      then: () => yup.string().required("The field is required"),
    }),
  });

  const sendBookingInformation = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "UpdateBookingInfo",
          selling_price: values.selling_price,
          financing: values.financing,
          first_time: values.first_time_buyer,
          bumiputera: values.bumiputera,
          nett_price: values.net_price,
          rea_booking_id: bookingData.rea_booking_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;

      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          text: "Successfully updated the booking information",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          text: "Failed to update booking information, please try again or contact our developer",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        text: error,
      });
    }
  };

  const setFieldLabel = (label, required) => {
    return `${label} ${Number(required) === 1 ? "*" : ""}`;
  };

  return (
    <Formik onSubmit={sendBookingInformation} validationSchema={bookingSchema} initialValues={bookingInitialValues}>
      {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title as={"h6"} className="text-uppercase">
              Edit Booking Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">

            {Number(modules.is_calc_bumi) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.net_price_label, 0)}</Form.Label>
                <Form.Control disabled value={values.net_price} />
              </Form.Group>
            )}
            
            {Number(bookingFormat.bumiputera_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.bumiputera_label, bookingFormat.bumiputera_required)}</Form.Label>
                <FormSelect
                  options={bumiputeraOptions}
                  valueDefault={bumiputeraOptions.find((record) => record.value === values.bumiputera)}
                  onChange={(e) => {
                    var currentPrice = bookingData.unit_selling_price;
                    if (Number(modules.is_calc_booking_fee) === 1 && bookingData.booking_deposit) {
                      currentPrice = currentPrice - Number(bookingData.booking_deposit);
                    }

                    if (Number(modules.is_calc_bumi) === 1 && Number(e.value) === 1) {
                      currentPrice = currentPrice - (currentPrice * Number(bookingData.bumi_package)) / 100;
                    }

                    setFieldValue("bumiputera", e.value);
                    setFieldValue("net_price", currentPrice);
                  }}
                />
                {errors.bumiputera && touched.bumiputera && <div className="op-error-message">{errors.bumiputera}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.financing_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.financing_label, bookingFormat.financing_required)}</Form.Label>
                <FormSelect options={financingOptions} valueDefault={financingOptions.find((record) => record.value === values.financing)} onChange={(e) => handleChange("financing")(e.value)} />
                {errors.financing && touched.financing && <div className="op-error-message">{errors.financing}</div>}
              </Form.Group>
            )}

            {Number(bookingFormat.first_time_buyer_show) === 1 && (
              <Form.Group className="mb-3">
                <Form.Label className="text-uppercase">{setFieldLabel(bookingFormat.first_time_buyer_label, bookingFormat.first_time_buyer_required)}</Form.Label>
                <FormSelect options={firstTimeBuyerOptions} valueDefault={firstTimeBuyerOptions.find((record) => record.value === values.first_time_buyer)} onChange={(e) => handleChange("first_time_buyer")(e.value)} />
                {errors.first_time_buyer && touched.first_time_buyer && <div className="op-error-message">{errors.first_time_buyer}</div>}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default EditBookingInformation;
