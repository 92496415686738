import { useRef } from "react";
import QuillEditor, { Quill } from "react-quill";
import QuillResizeImage from "quill-resize-image";

Quill.register("modules/imageResize", QuillResizeImage);

const NoteEditor = ({ value = "", onChangeValue, height = 150 }) => {
  const quill = useRef();
  const modules = {
    toolbar: {
      container: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ["bold", "italic", "underline", "strike"], ["link", "image"], [{ list: "ordered" }, { list: "bullet" }, { list: "check" }], [{ indent: "-1" }, { indent: "+1" }, { align: [] }], ["clean"]],
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = ["header", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "align", "link", "image", "color", "clean"];
  return <QuillEditor ref={(el) => (quill.current = el)} theme="snow" value={value} formats={formats} modules={modules} bounds={"#root"} onChange={onChangeValue} />;
};

export default NoteEditor;
