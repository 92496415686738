import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const SalesChart = () => {

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/sales-chart" || location.pathname === "/sales-chart/") {
      navigate("/sales-chart/unit/0/0", { replace: true });
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return ( 
    <Container fluid className="m-0 p-0">
      <Outlet />
    </Container>
  );
}
 
export default SalesChart;