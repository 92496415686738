import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Form, Modal, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-solid-svg-icons";
import * as formik from "formik";
import Swal from "sweetalert2/dist/sweetalert2";

const ProjectSettings = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    rea_project_id: "",
    project_name: "",
    project_desc: "",
    project_location: "",
    project_image: "",
    tenure: "",
    developer: "",
    key_points: "",
    source_token: "",
    user_tag_id: "",
  });

  // GET FUNCTION ======================================

  const getProjectListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetProjectListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setProjectList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreate = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "CreateProject",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          project_name: values.project_name,
          project_desc: values.project_desc,
          project_location: values.project_location,
          project_image: values.project_image,
          tenure: values.tenure,
          developer: values.developer,
          key_points: values.key_points,
          source_token: values.source_token,
          user_tag_id: values.user_tag_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getProjectListing();
        setIsCreate(false);
        Swal.fire({
          icon: "success",
          text: "Successfully created",
          timer: 2000,
        });
      } else {
        setIsCreate(false);
        Swal.fire({
          icon: "error",
          text: "Failed to create",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendEdit = async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdateProject",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          project_name: values.project_name,
          project_desc: values.project_desc,
          project_location: values.project_location,
          project_image: values.project_image,
          tenure: values.tenure,
          developer: values.developer,
          key_points: values.key_points,
          rea_project_id: values.rea_project_id,
          source_token: values.source_token,
          user_tag_id: values.user_tag_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getProjectListing();
        setIsEdit(false);
        Swal.fire({
          icon: "success",
          text: "Successfully updated",
          timer: 2000,
        });
      } else {
        setIsEdit(false);
        Swal.fire({
          icon: "error",
          text: "Failed to update",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendRemove = async (values) => {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemoveProject",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            rea_project_id: values.rea_project_id,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getProjectListing();
          Swal.fire({
            icon: "success",
            text: "Successfully removed",
            timer: 2000,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed to remove " + data.message,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openEdit = (values) => {
    setInitialValues({
      rea_project_id: values.rea_project_id,
      project_name: values.project_name,
      project_desc: values.project_desc,
      project_location: values.project_location,
      project_image: values.project_image,
      tenure: values.tenure,
      developer: values.developer,
      key_points: values.key_points,
      source_token: values.source_token,
      user_tag_id: values.user_tag_id,
    });

    setTimeout(() => {
      setIsEdit(true);
    }, 100);
  };

  // GRID SETUP ========================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "78vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      width: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Project Name",
      field: "project_name",
      autoHeight: true,
    },
    {
      headerName: "Project Description",
      field: "project_desc",
    },
    {
      headerName: "Project Location",
      field: "project_location",
    },
    {
      headerName: "Project Image",
      field: "project_image",
    },
    {
      headerName: "Tenure",
      field: "tenure",
    },
    {
      headerName: "Developer",
      field: "developer",
    },
    {
      headerName: "Key Points",
      field: "key_points",
    },
    {
      headerName: "Source Token",
      field: "source_token",
    },
    {
      headerName: "User Tag Id",
      field: "user_tag_id",
    },
    {
      headerName: "",
      pinned: "right",
      field: "",
      width: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2" onClick={() => sendRemove(params.data)}>
              <FontAwesomeIcon icon={faTrash} size="sm" />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_project_id.toString();
  }, []);

  const gridColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
    };
  }, []);

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getProjectListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-3">
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create Project
            </Button>
          </Stack>

          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact
                ref={gridRef}
                rowSelection="multiple"
                defaultColDef={gridColDef}
                rowData={projectList}
                columnDefs={gridColumn}
                getRowId={gridRowId}
                rowHeight={70}
                pagination={true}
                paginationPageSize={100}
                paginationPageSizeSelector={false}
                suppressRowClickSelection={true}
                animateRows={true}
              />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik
          onSubmit={sendCreate}
          initialValues={{
            project_name: "",
            project_desc: "",
            project_location: "",
            project_image: "",
            tenure: "",
            developer: "",
            key_points: "",
            source_token: "",
            user_tag_id: "",
          }}
        >
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control type="text" value={values.project_name} onChange={handleChange("project_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Description</Form.Label>
                  <Form.Control type="text" value={values.project_desc} onChange={handleChange("project_desc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Location</Form.Label>
                  <Form.Control type="text" value={values.project_location} onChange={handleChange("project_location")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Images</Form.Label>
                  <Form.Control type="text" value={values.project_image} onChange={handleChange("project_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Tenure</Form.Label>
                  <Form.Control type="text" value={values.tenure} onChange={handleChange("tenure")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Developer</Form.Label>
                  <Form.Control type="text" value={values.developer} onChange={handleChange("developer")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Key Points</Form.Label>
                  <Form.Control type="text" value={values.key_points} onChange={handleChange("key_points")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Source Token</Form.Label>
                  <Form.Control type="text" value={values.source_token} onChange={handleChange("source_token")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>User Tag Id</Form.Label>
                  <Form.Control type="text" value={values.user_tag_id} onChange={handleChange("user_tag_id")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEdit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit Project</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control type="text" value={values.project_name} onChange={handleChange("project_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Description</Form.Label>
                  <Form.Control type="text" value={values.project_desc} onChange={handleChange("project_desc")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Location</Form.Label>
                  <Form.Control type="text" value={values.project_location} onChange={handleChange("project_location")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Project Images</Form.Label>
                  <Form.Control type="text" value={values.project_image} onChange={handleChange("project_image")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Tenure</Form.Label>
                  <Form.Control type="text" value={values.tenure} onChange={handleChange("tenure")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Developer</Form.Label>
                  <Form.Control type="text" value={values.developer} onChange={handleChange("developer")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Key Points</Form.Label>
                  <Form.Control type="text" value={values.key_points} onChange={handleChange("key_points")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Source Token</Form.Label>
                  <Form.Control type="text" value={values.source_token} onChange={handleChange("source_token")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>User Tag Id</Form.Label>
                  <Form.Control type="text" value={values.user_tag_id} onChange={handleChange("user_tag_id")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default ProjectSettings;
