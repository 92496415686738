import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Form, ListGroup, Modal } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import * as formik from "formik";
import { FormSelect } from "../../includes/FormCustom";

const BookingUnitModules = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [phase, setPhase] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [initialValues, setInitialValues] = useState({ column: "", value: "" });
  const [unit, setUnit] = useState({
    rea_unit_modules_id: "",
    rea_phase_id: "",
    company_id: session.company_id,
    is_find_unit: "1",
    is_bumi_reserved: "1",
    is_lock_unit: "1",
    is_unit_interest: "1",
    is_unit_pricing: "1",
    is_unit_details: "1",
    is_unit_gallery: "1",
    is_floor_plan: "1",
    is_level: "1",
    is_carpark: "1",
    is_solicitor: "1",
    is_remark: "1",
    up_spa_price: "1",
    up_selling_psf: "1",
    up_net_price_after_discount: "1",
    up_max_discount: "1",
    up_mortgage_calculator: "1",
    up_bumi_selling_psf: "1",
    ud_project: "1",
    ud_phase: "1",
    ud_unit_type: "1",
    ud_unit_number: "1",
    ud_bedroom: "1",
    ud_bathroom: "1",
    ud_builtup_area: "1",
    ud_builtup_area_sqm: "1",
    ud_land_area: "1",
    ud_land_area_sqm: "1",
    ud_unit_level: "1",
    ud_facing: "1",
    ud_unit_car_park_no: "1",
  });

  // USEEFFECT FUNCTION ----------------------

  const getPhase = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseLight",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setPhase(data.record);
        getData(data.record[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = useCallback(async (phase) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetUnitFormat",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: phase.value,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setUnit(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onchangeData = useCallback(
    async (column) => {
      const recordValues = Number(unit[column]) === 1 ? 0 : 1;
      const recordUpdate = { ...unit };
      recordUpdate[column] = recordValues;
      setUnit(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateUnitFormat",
            column: column,
            value: recordValues,
            rea_unit_modules_id: unit.rea_unit_modules_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [unit, session]
  );

  const onchangePhase = useCallback((e) => {
    getData(e);
  }, []);

  const onchangeLabel = useCallback(
    (column) => {
      setInitialValues({ column: column, value: unit[column] });
      setIsModal(true);
    },
    [unit]
  );

  const sendLabel = useCallback(
    async (values) => {
      setIsModal(false);

      const recordUpdate = { ...unit };
      recordUpdate[values.column] = values.value;
      setUnit(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateUnitFormat",
            column: values.column,
            value: values.value,
            rea_unit_modules_id: unit.rea_unit_modules_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [unit, session]
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getPhase();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <div className="mb-3">
            <FormSelect options={phase} valueDefault={phase[0]} onChange={onchangePhase} width="250px" />
          </div>

          <section className="mb-4">
            <h6>Unit View</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Find Unit
                <Form.Check type="switch" checked={Number(unit.is_find_unit) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_find_unit")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Bumi Reserved
                <Form.Check type="switch" checked={Number(unit.is_bumi_reserved) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_bumi_reserved")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Block Unit
                <Form.Check type="switch" checked={Number(unit.is_lock_unit) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_lock_unit")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Bumi Reserved
                <Form.Check type="switch" checked={Number(unit.is_unit_interest) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_unit_interest")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unit Pricing
                <Form.Check type="switch" checked={Number(unit.is_unit_pricing) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_unit_pricing")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unit Details
                <Form.Check type="switch" checked={Number(unit.is_unit_details) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_unit_details")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unit Gallery
                <Form.Check type="switch" checked={Number(unit.is_unit_gallery) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_unit_gallery")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Floor Plan
                <Form.Check type="switch" checked={Number(unit.is_floor_plan) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_floor_plan")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Level
                <Form.Check type="switch" checked={Number(unit.is_level) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_level")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Carpark
                <Form.Check type="switch" checked={Number(unit.is_carpark) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_carpark")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Solicitor
                <Form.Check type="switch" checked={Number(unit.is_solicitor) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_solicitor")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Remark
                <Form.Check type="switch" checked={Number(unit.is_remark) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("is_remark")} />
              </ListGroup.Item>
            </ListGroup>
          </section>
          <section className="mb-4">
            <h6>Unit Pricing</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_spa_price_label")}>
                  {unit.up_spa_price_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_spa_price) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_spa_price")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_selling_psf_label")}>
                  {unit.up_selling_psf_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_selling_psf) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_selling_psf")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_net_price_after_disc_label")}>
                  {unit.up_net_price_after_disc_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_net_price_after_discount) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_net_price_after_discount")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_bumi_selling_psf_label")}>
                  {unit.up_bumi_selling_psf_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_bumi_selling_psf) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_bumi_selling_psf")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_max_discount_label")}>
                  {unit.up_max_discount_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_max_discount) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_max_discount")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("up_mortgage_calculator_label")}>
                  {unit.up_mortgage_calculator_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.up_mortgage_calculator) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("up_mortgage_calculator")} />
              </ListGroup.Item>
            </ListGroup>
          </section>
          <section className="mb-4">
            <h6>Unit Details</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_project_label")}>
                  {unit.ud_project_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_project) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_project")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_phase_label")}>
                  {unit.ud_phase_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_phase) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_phase")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_unit_type_label")}>
                  {unit.ud_unit_type_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_unit_type) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_unit_type")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_unit_number_label")}>
                  {unit.ud_unit_number_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_unit_number) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_unit_number")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_bedroom_label")}>
                  {unit.ud_bedroom_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_bedroom) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_bedroom")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_bathroom_label")}>
                  {unit.ud_bathroom_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_bathroom) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_bathroom")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_builtup_area_label")}>
                  {unit.ud_builtup_area_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_builtup_area) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_builtup_area")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_builtup_area_sqm_label")}>
                  {unit.ud_builtup_area_sqm_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_builtup_area_sqm) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_builtup_area_sqm")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_land_area_label")}>
                  {unit.ud_land_area_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_land_area) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_land_area")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_land_area_sqm_label")}>
                  {unit.ud_land_area_sqm_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_land_area_sqm) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_land_area_sqm")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_unit_level_label")}>
                  {unit.ud_unit_level_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_unit_level) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_unit_level")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_facing_label")}>
                  {unit.ud_facing_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_facing) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_facing")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_unit_car_park_no_label")}>
                  {unit.ud_unit_car_park_no_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_unit_car_park_no) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_unit_car_park_no")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("ud_total_carpark_label")}>
                  {unit.ud_total_carpark_label}
                </Button>
                <Form.Check type="switch" checked={Number(unit.ud_total_carpark) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("ud_total_carpark")} />
              </ListGroup.Item>
            </ListGroup>
          </section>
        </div>
      )}

      <Modal show={isModal} onHide={setIsModal} size="lg">
        <Formik onSubmit={sendLabel} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Change Label</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Label</Form.Label>
                  <Form.Control type="text" value={values.value} onChange={handleChange("value")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default BookingUnitModules;
