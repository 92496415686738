import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../../includes/NoRecord";
import Loader from "../../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons";
import { setCurrency } from "../../lib/js/Function";
import ReactHtmlParser from "html-react-parser";
import { setDateTimeStrVN } from "../../lib/js/Function";
import { useTranslation } from "react-i18next";

const LotDetailsVN = ({ prospectID }) => {

    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [purLotRowData, setPurLotRowData] = useState([]);
    const [isPurLot, setIsPurLot] = useState(false);
    const [purLotRecFound, setPurLotRecFound] = useState(0);

    const [purLotHisRowData, setPurLotHisRowData] = useState([]);
    const [isPurLotHis, setIsPurLotHis] = useState(true);
    const [purLotHisRecFound, setPurLotHisRecFound] = useState(0);
    const [purLotHeight, setPurLotHeight] = useState("");

    const [selRowData, setSelRowData] = useState({});
    const [lotInfoData, setLotInfoData] = useState([]);
    const [activeTab, setActiveTab] = useState('contactDet');
    const [cityList, setCityList] = useState([]);

    const [isEditSPA, setIsEditSPA] = useState(false);
    const [isLdSPA, setIsLdSPA] = useState(false);

    const [isEditNameID, setIsEditNameID] = useState(false);
    const [isLdNameID, setIsLdNameID] = useState(false);

    const [isEditCtcDet, setIsEditCtcDet] = useState(false);
    const [isLdCtcDet, setIsLdCtcDet] = useState(false);

    const [isEditDS, setIsEditDS] = useState(false);
    const [isLdEditDS, setIsLdEditDS] = useState(false);

    const [dsList, setDsList] = useState([]);

    const idTypeContactDet = [
        {label: "Vietnam NRIC No", value: "NRIC No."},
        {label: "Passport", value: "Passport"},
        {label: "Reg No", value: "Reg.No"},
        {label: "Other", value: "Others"},
    ];

    const contactPrefixLotDetails = [
        { label: "+84", value: "+84" },
        { label: "+60", value: "+60" },
        { label: "+61", value: "+61" },
        { label: "+65", value: "+65" },
        { label: "+1", value: "+1" },
        { label: "+27", value: "+27" },
        { label: "+33", value: "+33" },
        { label: "+41", value: "+41" },
        { label: "+44", value: "+44" },
        { label: "+49", value: "+49" },
        { label: "+62", value: "+62" },
        { label: "+63", value: "+63" },
        { label: "+66", value: "+66" },
        { label: "+81", value: "+81" },
        { label: "+82", value: "+82" },
        { label: "+86", value: "+86" },
        { label: "+90", value: "+90" },
        { label: "+91", value: "+91" },
        { label: "+92", value: "+92" },
        { label: "+94", value: "+94" },
        { label: "+353", value: "+353" },
        { label: "+357", value: "+357" },
        { label: "+673", value: "+673" },
        { label: "+850", value: "+850" },
        { label: "+852", value: "+852" },
        { label: "+853", value: "+853" },
        { label: "+880", value: "+880" },
        { label: "+886", value: "+886" },
        { label: "+966", value: "+966" },
        { label: "+971", value: "+971" },
        { label: "+973", value: "+973" },
        { label: "+974", value: "+974" },
        { label: "+996", value: "+996" }
    ];

    const purLotGridRef = useRef();
    const containerStylePurLot = useMemo(() => ({ width: "100%", height: purLotHeight, paddingTop: 8 }), [isPurLot, purLotHeight]);
    const gridStylePurLot = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
            sortable: true,
            unSortIcon: true,
        };
    }, []);

    const [purLotColumn, setPurLotColumn]= useState([
        {
            // checkboxSelection: true,
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName1'),
            resizable: true,
            flex: 1,
            minWidth: 260,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.phase ? params.data.phase : ''} ${params.data.unit_name}`;
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    return params.data.phase +" "+ params.data.unit_name
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName2'),
            field: "generation",
            resizable: true,
            width: 150,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName3'),
            resizable: true,
            width: 90,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.role == 'mainBuyer' || params.data.role == 'subsalesMainBuyer') {
                        return "Buyer";
                    }else if(params.data.role == 'jointBuyer' || params.data.role == 'subsalesJointBuyer'){
                        return "Joint Buyer";
                    }else{
                        return params.data.role;
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.role == 'mainBuyer' || params.data.role == 'subsalesMainBuyer') {
                        return "Buyer";
                    }else if(params.data.role == 'jointBuyer' || params.data.role == 'subsalesJointBuyer'){
                        return "Joint Buyer";
                    }else{
                        return params.data.role;
                    }
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName4'),
            resizable: true,
            width: 140,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.pms_ownershipID) {
                        return "PMS";
                    }else{
                        return "PDS";
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.pms_ownershipID) {
                        return "PMS";
                    }else{
                        return "PDS";
                    }
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName5'),
            field: "status",
            resizable: true,
            width: 100
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName6'),
            field: "sales_personnel",
            resizable: true,
            width: 180
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName7'),
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.created_date) {
                        return moment(params.data.created_date).format("lll");
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.created_date) {
                        return setDateTimeStrVN(params.data.created_date);
                    }
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLot_HeaderName8'),
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.modified_date) {
                        return moment(params.data.modified_date).format("lll");
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.modified_date) {
                        return setDateTimeStrVN(params.data.modified_date);
                    }
                }
            }
        }
    ]);

    const getPurchaseLots = () => {
        setIsPurLot(false);
        axios.get('ext/gamuda/api_getPurchaseLots_VN.php', {
            params:{
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record && data.record.length > 0) {
                setPurLotRecFound(data.record.length);
                setPurLotRowData(data.record);
                setIsPurLot(true);
                setPurLotHeight(Number(data.record.length) > 11 ? "52vh" : "30vh");
            }else{
                setPurLotRecFound(0);
                setPurLotRowData([]);
                setIsPurLot(true);
                setPurLotHeight("30vh");
            }
        })
        .catch(error => {
            setPurLotRecFound(0);
            setPurLotRowData([]);
            setIsPurLot(true);
            setPurLotHeight("30vh");
        });
    }

    const purLotHisGridRef = useRef();
    const containerStylePurLotHis = useMemo(() => ({ width: "100%", height: isPurLotHis ? "35vh" : "35vh", paddingTop: 1 }), [isPurLotHis]);
    const gridStylePurLotHist = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [purLotHisColumn, setPurLotHisColumn] =  useState([
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName1'),
            field: "debtorName",
            resizable: true,
            flex: 1,
            minWidth: 250,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    return params.data.debtorName;
                }
            },
            onCellClicked: function(params){
                if (params.data) {
                    window.open(session.origin+"/prospect/"+params.data.outperform_prospect_id,"_blank");
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName2'),
            field: "role",
            resizable: true,
            width: 180
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName3'),
            field: "generation",
            resizable: true,
            width: 180
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName4'),
            resizable: true,
            width: 180,
            comparator: (valueA, valueB) => {
                if (Number(valueA) < Number(valueB)) return -1;
                if (Number(valueA) > Number(valueB)) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (Number(params.data.generation) > 0) {
                        return Number(params.data.generation) - 1;
                    }else{
                        return 0;
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (Number(params.data.generation) > 0) {
                        return Number(params.data.generation) - 1;
                    }else{
                        return 0;
                    }
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName5'),
            field: "sales_personnel",
            resizable: true,
            width: 180
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName6'),
            resizable: true,
            width: 180,
            comparator: (date1, date2) => {
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.salesDate) {
                        return moment( new Date(params.data.salesDate) ).format("ll");
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.salesDate) {
                        return setDateTimeStrVN(params.data.salesDate);
                    }
                }
            }
        },
        {
            headerName: t('ProspectDetGL_lotDet_PurLotHis_HeaderName7'),
            resizable: true,
            width: 180,
            comparator: (date1, date2) => {
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.date_time_create) {
                        return moment( new Date(params.data.date_time_create) ).format("ll");
                    }
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.date_time_create) {
                        return setDateTimeStrVN(params.data.date_time_create);
                    }
                }
            }
        }
    ]);

    const getPurchaseLotInfo = (salesID, pms_ownershipID, subSalesID) => {
        if (salesID) { 
            
            axios.get('ext/gamuda/api_getPurchaseLotInfoV2_vn.php', {
                params:{
                    salesID: salesID,
                    prospect_id: prospectID,
                    pms_ownershipID: pms_ownershipID,
                    subSalesID: subSalesID,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (data.record.status == 0) {
                    setLotInfoData(data.record);

                    var rowData1 = data.record.purchaseHistory.mainBuyerPurchase ? data.record.purchaseHistory.mainBuyerPurchase.map(data => {return data}) : [];
                    var rowData2 = data.record.purchaseHistory.jointBuyerPurchase ? data.record.purchaseHistory.jointBuyerPurchase.map(data => {return data}) : [];
                    var rowData3 = data.record.purchaseHistory.subsalesPurchase ? data.record.purchaseHistory.subsalesPurchase.map(data => {return data}) : [];

                    var completeRowData = [...rowData1, ...rowData2, ...rowData3];

                    setPurLotHisRecFound(completeRowData.length);
                    setPurLotHisRowData(completeRowData);

                }else{
                    setLotInfoData([]);
                    setPurLotHisRecFound(0);
                    setPurLotHisRowData([]);
                }
            })
            .catch(error => {
                setLotInfoData([]);
                setPurLotHisRecFound(0);
                setPurLotHisRowData([]);
            });

        }
    }

    const getAllDS = () => {

        axios.get("ws/ws_source.php", {
            params:{
                task: 2,
                company: session.company_id,
                disp: "show"
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                var dataList = data.record.map(data => {
                    return {label: data.ds_title, value: data.ds_id};
                });

                setDsList(dataList);
            }else{
                setDsList([]);
            }
        })
        .catch(error => {
            setDsList([]);
        });
    }

    const getAllCity = () => {

        axios.get("ext/gamuda/ws_listcity.php", {
            params: {
                task: "listCity_VN"
            }
        })
        .then(res => {
            let data = res.data;

            if (data) {
                var list = data.map(rec => {
                    return {label: rec.rr_value, value: rec.rr_id};
                });

                list.sort((a,b) => a.label.localeCompare(b.label));

                setCityList(list);
            }else{
                setCityList([]);
            }
        })
        .catch(error => {
            setCityList([]);
        });
    }
    
    const firstRender = useCallback((params) => {
        var nodesToSelect = [];
        
        params.api.forEachNode((node, index) => {
            if (Object.keys(selRowData).length > 0) {
                if (selRowData.salesID == node.data.salesID) {
                    nodesToSelect.push(node);
                }
            }else if (index == 0) {
                nodesToSelect.push(node);
            }
        });
        
        params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    }, [selRowData]);

    const handleRowSelect = useCallback(() => {
        
        var selectedRow = purLotGridRef.current.api.getSelectedRows();
        
        getPurchaseLotInfo(selectedRow[0].salesID, selectedRow[0].pms_ownershipID, selectedRow[0].subSalesID);
        var emailSplit = selectedRow[0].email.split(",");

        selectedRow[0].email1 = emailSplit[0] ? emailSplit[0] : "";
        selectedRow[0].email2 = emailSplit[1] ? emailSplit[1] : "";

        var addressSplit = selectedRow[0].address ? ReactHtmlParser(selectedRow[0].address).split(",") : selectedRow[0].address;

        selectedRow[0].address1 = addressSplit[0] ? addressSplit[0] : "";
        selectedRow[0].address2 = addressSplit[1] ? addressSplit[1] : "";
        selectedRow[0].address3 = addressSplit[2] ? addressSplit[2] : "";

        var prefix = "";
        var phone1 = "";
        var prefix2 = "";
        var phone2 = "";

        if (selectedRow[0].phone1) {
            prefix = contactPrefixLotDetails.filter(opt => selectedRow[0].phone1.startsWith(opt.value));
        }

        if (prefix.length > 0) {
            if (prefix[0].value == '+60') {
                phone1 = "0" + selectedRow[0].phone1.substring(prefix[0].value.length);
            }else{
                phone1 = selectedRow[0].phone1.substring(prefix[0].value.length);
            }
        }else{
            phone1 = selectedRow[0].phone1;
        }

        if (selectedRow[0].phone2) {
            prefix2 = contactPrefixLotDetails.filter(opt => selectedRow[0].phone2.startsWith(opt.value));
        }

        if (prefix2.length > 0) {
            if (prefix2 == "+60") {
                phone2 = "0" + selectedRow[0].phone2.substring(prefix2[0].value.length);
            }else{
                phone2 = selectedRow[0].phone2.substring(prefix2[0].value.length);
            }
        }else{
            phone2 = selectedRow[0].phone2;
        }
        
        selectedRow[0].phone1Pref = prefix.length > 0 ? prefix[0].value : "";
        selectedRow[0].phone1Num = phone1;

        selectedRow[0].phone2Pref = prefix2.length > 0 ? prefix2[0].value : "";
        selectedRow[0].phone2Num = phone2;

        setSelRowData(selectedRow[0]);
    }, [selRowData]);

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getPurchaseLots();
            getAllDS();
            getAllCity();
        }
    }, []);

    const onchangeActionTabs = (key) => {
        setActiveTab(key);
    }

    const RenderEditContactDet = () => {

        const CustomToggle = forwardRef(({ onClick }, ref) => {
            const openDropdown = (e) => {
              e.preventDefault();
              onClick(e);
            };
      
            return (
              <button ref={ref} onClick={openDropdown} disabled={disabledCtcDet(selRowData)} className="btn btn-light border">
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            );
        });

        return(
            <Dropdown size={100} className="ms-auto">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100}>
                    {session.role_id == 1 && <Dropdown.Item onClick={setIsEditNameID}>Edit Full Name & ID No.</Dropdown.Item>}
                    <Dropdown.Item onClick={setIsEditCtcDet}>Edit Contact Details</Dropdown.Item>
                    {/* {selRowData != 'Signed' && <Dropdown.Item onClick={setIsEditDS}>Edit Deal Source</Dropdown.Item>} */}
                </Dropdown.Menu>
            </Dropdown>
        );

    }

    const RenderTab = ({tab}) => {
        switch (tab) {
            case 'contactDet':
                
                return(
                    <Card.Body className="p-0">
                        <div className="py-0 px-4 mt-3">
                            <Card className="rounded border-1 shadow-sm mx-2">
                                <Card.Header className="rounded border-0" style={{backgroundColor: "#fff", padding: 0}}>
                                    <div style={{padding: "10px 15px"}}>
                                        <Stack direction="horizontal" gap={1}>
                                            <h6 className="mt-2">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_ContactDetail')}</h6>
                                            <RenderEditContactDet />
                                        </Stack>
                                    </div>
                                </Card.Header>
                            </Card>
                        </div>

                        <Row className="px-4">
                            <Col sm={6} className="px-3">
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_FullName')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.debtorName ? ReactHtmlParser(selRowData.debtorName) : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_IDType')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {selRowData.debtorIDType == 368260 ? "Reg No" : selRowData.debtorIDType == 12512 ? "Vietnam NRIC No." : selRowData.debtorIDType == 14816 ? "Others" : selRowData.debtorIDType == 368259 ? "Passport" : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_IDNo1')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.debtorIDNo ? selRowData.debtorIDNo : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_ContactNo1')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.phone1 ? selRowData.phone1 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_ContactNo2')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.phone2 ? selRowData.phone2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Email1')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.email1 ? selRowData.email1 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Email2')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.email2 ? selRowData.email2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} className="px-3">
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Address1')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address1 ? ReactHtmlParser(selRowData.address1) : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Address2')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address2 ? selRowData.address2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Address3')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address3 ? selRowData.address3 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_District')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.city ? selRowData.city : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Postcode')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.postcode ? selRowData.postcode : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ContactDetTab_Country')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{Object.keys(selRowData).length > 0 ? 'Vietnam' : '-'}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );
            
            case 'purLotHis':

                return(
                    <Card.Body>
                        <div style={containerStylePurLotHis}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStylePurLotHist }}>
                                <AgGridReact
                                    ref={purLotHisGridRef}
                                    columnDefs={purLotHisColumn}
                                    rowData={purLotHisRowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={50}
                                    rowSelection="single"
                                    pagination={false}
                                    // paginationPageSize={30}
                                    // paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                    </Card.Body>
                );

            case 'solicitor':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2 mx-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SolicitorTab_CompanyName')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{(lotInfoData.spaDetails && lotInfoData.spaDetails.solicitorName != 'NULL' && lotInfoData.spaDetails.solicitorName) ? lotInfoData.spaDetails.solicitorName : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SolicitorTab_ContactNo')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{(lotInfoData.spaDetails && lotInfoData.spaDetails.solicitorContactNum) ? lotInfoData.spaDetails.solicitorContactNum : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SolicitorTab_Address')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{(lotInfoData.spaDetails && lotInfoData.spaDetails.solicitorAddress) ? lotInfoData.spaDetails.solicitorAddress : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            case 'financier':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2 mx-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_FinancierTab_BankName')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierName ? `${lotInfoData.spaDetails.financierName} (${lotInfoData.spaDetails.financierBranch})` : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_FinancierTab_BranchContactNo')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierPicContact ? lotInfoData.spaDetails.financierPicContact : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_FinancierTab_BranchAddress')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierBranchAddress ? lotInfoData.spaDetails.financierBranchAddress : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            case 'vpKey':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2 mx-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_VPKeyTab_VPDate')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {!lotInfoData.vpKey ? "-" : new Date(lotInfoData.vpKey.vp_date).getTime() ? moment( new Date(lotInfoData.vpKey.vp_date) ).format("ll") : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_VPKeyTab_KeyCollDate')}</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {!lotInfoData.vpKey ? "-" : new Date(lotInfoData.vpKey.key_collection_date_time).getTime() ? moment( new Date(lotInfoData.vpKey.key_collection_date_time) ).format("ll") : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            default:
                return null;
        }
    }

    const editSPASchema = yup.object().shape({
        spaDate: yup.string().required("This field is required")
    });

    const sendEditSPA = (values) => {
        // setIsLdSPA(true);

        // axios.get("ext/gamuda/api_rr_spaupdate.php", {
        //     params:{
        //         salesID: values.salesID,
        //         spaDate: values.spaDate
        //     }
        // })
        // .then(res => {
        //     let data =  res.data;

        //     if (data.IsSuccess == true) {
        //         if (data.Data[0].IsSuccess == 1) {
        //             Swal.fire({
        //                 icon: "success",
        //                 title: data.Data[0].Message,
        //                 timer: '1500'
        //             })
        //             .then(result => {
        //                 setIsLdSPA(false);
        //                 setIsEditSPA(false);
        //                 getPurchaseLots();
        //             });
        //         }else{
        //             Swal.fire({
        //                 icon: "error",
        //                 title: data.Data[0].Message
        //             })
        //             .then(result => {
        //                 setIsLdSPA(false);
        //             });
        //         }
        //     }else{
        //         Swal.fire({
        //             icon: "error",
        //             title: data.Message
        //         })
        //         .then(result => {
        //             setIsLdSPA(false);
        //         });
        //     }
        // })
        // .catch(error => {
        //     Swal.fire({
        //         icon: "error",
        //         title: error.message
        //     })
        //     .then(result => {
        //         setIsLdSPA(false);
        //     });
        // });
    };

    const editCtcDetNameIDSchema = yup.object().shape({
        fullName: yup.string().required("This field is required"),
        idType: yup.string().required("This field is required"),
        idNum: yup.string().required("This field is required")
    });

    const sendEditCtcDetNameID = (values) => {
        setIsLdNameID(true);
        //debtorName=Nguyễn Quốc Quân&debtorIdNo=B277112016&salesID=240096&debtorID=500121&role=subsalesJointBuyer&idType=12512&prospect_id=

        axios.get('ext/glMigration_vn/api_rr_update_debtor_name_VN.php',{
            params: {
                debtorName: ReactHtmlParser(values.fullName),
                debtorIdNo: values.idNum,
                salesID: values.salesID,
                debtorID: values.debtorID,
                role: values.role,
                idType: values.idType,
                prospect_id: prospectID
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully edited',
                    timer: 1500
                })
                .then(result => {
                    setIsLdNameID(false);
                    setIsEditNameID(false);
                    getPurchaseLots();
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: data.message
                })
                .then(result => {
                    setIsLdNameID(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdNameID(false);
            });
        });
    };

    const editCtcDetSchema = yup.object().shape({
        phone1Pref: yup.string().required("This field is required"),
        phone1Num: yup.number().typeError("Must be a number type").required("This field is required"),
        phone2Num: yup.number().typeError("Must be a number type"),
        email1: yup.string().email('must be valid email').required("This field is required"),
        email2: yup.string().email('must be valid email'),
        address1: yup.string().required("This field is required"),
        cityID: yup.string().required("This field is required"),
        postcode: yup.string().required("This field is required")
    });

    const sendEditCtcDet = (values) => {
        setIsLdCtcDet(true);
        
        axios.get('ext/glMigration_vn/api_rr_buyerprofileedit_VN.php?', {
            params:{
                debtorID: values.debtorID,
                salesID: values.salesID,
                debtorName: ReactHtmlParser(values.fullName),
                contact1: values.phone1Num,
                contact1_prefix: values.phone1Pref,
                contact2: values.phone2Num,
                contact2_prefix: values.phone2Pref,
                email1: values.email1,
                address_line1: `${values.address1},`,
                address_line2: values.address2 ? `${values.address2},` : values.address2,
                address_line3: values.address3,
                city: values.cityID,
                postal_code: values.postcode,
                role: values.role,
                company_id: session.company_id,
                user_id: session.user_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Contact Detail Updated",
                    timer: 1500
                })
                .then(result => {
                    setIsLdCtcDet(false);
                    setIsEditCtcDet(false);
                    getPurchaseLots();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: data.message
                })
                .then(res => {
                    setIsLdCtcDet(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(res => {
                setIsLdCtcDet(false);
            });
        });
    }

    const editDsSchema = yup.object().shape({
        ds_id: yup.string().required("This field is required")
    });

    const disableEditLotInfo = (data) => {
        return true;
        // if (Object.keys(data).length > 0) {
        //     if (session.role_id == 1) {
            
        //         if (data.role == "jointBuyer" || data.role == "subsalesJointBuyer" || data.role == "subsalesMainBuyer") {
        //             return true;
        //         }else{
    
        //             if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
        //                 return true;
        //             }else{
        //                 return false
        //             }
    
        //         }
    
        //     }else{
    
        //         if (data.role == "jointBuyer") {
        //             return true;
        //         }else{
    
        //             if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
        //                 return true;
        //             }else{
        //                 return false;
        //             }
    
        //         }
    
        //     }
        // }else{
        //     return true;
        // }
    }

    const disabledCtcDet = (data) => {
        if (Object.keys(data).length > 0) {
            if (data.status == 'Booked' || data.status == 'Signed') {
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    }

    return(
        <>
            {
                !isPurLot ? (<Loader />) : (
                    <div className="px-3">
                        <div style={containerStylePurLot}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStylePurLot }}>
                                <AgGridReact
                                    ref={purLotGridRef}
                                    columnDefs={purLotColumn}
                                    rowData={purLotRowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={50}
                                    rowSelection="single"
                                    pagination={false}
                                    onFirstDataRendered={firstRender}
                                    onSelectionChanged={handleRowSelect}
                                />
                            </div>
                        </div>

                        <Row className="mt-2 mb-3">
                            <Col sm={6} className="mb-1">
                                <Card className="border-1">
                                    <Card.Header style={{backgroundColor: "#fff", padding: 0}}>
                                        <div style={{padding: "10px 20px"}}>
                                            <Stack direction="horizontal" gap={1}>
                                                <h6 className="mt-2">{t('ProspectDetGL_lotDet_LotInfo_CardHeader')}</h6>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light border ms-auto"
                                                    onClick={setIsEditSPA}
                                                    disabled={disableEditLotInfo(selRowData)}
                                                >
                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                </button>
                                            </Stack>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Row className="py-0 px-4">
                                            <Col sm={6} className="p-2">
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_CountryCode')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{Object.keys(selRowData).length > 0 ? 'Vietnam' : '-'}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ProjName')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.project ? selRowData.project : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_PhaseName')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.phase ? selRowData.phase : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_PhaseCode')}</Card.Title>
                                                            {!selRowData.phase_id && !selRowData.pms_phase_id ? 
                                                                <Card.Text style={{fontSize: 13}}>-</Card.Text> 
                                                                : 
                                                                <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? selRowData.phase_id : selRowData.pms_phase_id }</Card.Text>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_LotUnit')}</Card.Title>
                                                            {!selRowData.unit_name && !selRowData.pms_unit_name ? 
                                                                <Card.Text style={{fontSize: 13}}>-</Card.Text> 
                                                                : 
                                                                <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? selRowData.unit_name : selRowData.pms_unit_name}</Card.Text>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ModuleCode')}</Card.Title>
                                                            {Object.keys(selRowData).length > 0 ? <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? "PDS" : "PMS"}</Card.Text>
                                                                :
                                                                <Card.Text style={{fontSize: 13}}>-</Card.Text>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={6} className="p-2">
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SalesDate')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.salesDate ? moment( new Date( selRowData.salesDate ) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_ProjPrintName')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.project_print_name ? selRowData.project_print_name : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_PhasePrintName')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.phase_print_name ? selRowData.phase_print_name : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SPADate')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? moment( new Date( lotInfoData.spaDetails.spaDate ) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SPAStampedDate')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : new Date(lotInfoData.spaDetails.spaStampDate).getTime() ? moment( new Date(lotInfoData.spaDetails.spaStampDate) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SaleStatus')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.status ? selRowData.status : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">{t('ProspectDetGL_lotDet_LotInfo_SPAPrice')}</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? new Intl.NumberFormat('en-US').format(lotInfoData.spaDetails.spaPrice) + " VND": "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>             
                            </Col>
                            <Col sm={6}>
                                <Card className="border-1 mb-4">
                                    <Card.Header>
                                        <Nav variant="underline" defaultActiveKey="contactDet" onSelect={onchangeActionTabs}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="contactDet">
                                                    {t('ProspectDetGL_lotDet_LotInfo_ContactDetTab')}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="purLotHis">
                                                    {t('ProspectDetGL_lotDet_LotInfo_PurLotHisTab')}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="solicitor">
                                                    {t('ProspectDetGL_lotDet_LotInfo_SolicitorTab')}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="financier">
                                                    {t('ProspectDetGL_lotDet_LotInfo_FinancierTab')}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="vpKey">
                                                    {t('ProspectDetGL_lotDet_LotInfo_VPKeyTab')}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <RenderTab tab={activeTab}/>
                                </Card>
                            </Col>
                        </Row>

                        <Modal show={isEditSPA} onHide={setIsEditSPA}>
                            <Formik
                                validationSchema={editSPASchema}
                                onSubmit={sendEditSPA}
                                initialValues={
                                    {
                                        salesID: selRowData.salesID,
                                        spaDate: !lotInfoData.spaDetails ? "" : new Date(lotInfoData.spaDetails.spaDate).getTime() ? moment( new Date(lotInfoData.spaDetails.spaDate) ).format("YYYY-MM-DD") : ""
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit SPA Date</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{height: "120px"}}>
                                            {isLdSPA ? (<Loader/>) :(
                                                    <Form.Group>
                                                        <Form.Label>SPA Date</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={values.spaDate}
                                                            isInvalid={errors.spaDate && touched.spaDate}
                                                            onChange={handleChange('spaDate')}
                                                        />
                                                        {errors.spaDate && touched.spaDate && <div className="op-error-message">{errors.spaDate}</div>}
                                                    </Form.Group>
                                                )
                                            }
                                        </Modal.Body>
                                        {!isLdSPA && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isEditNameID} onHide={setIsEditNameID}>
                            <Formik
                                validationSchema={editCtcDetNameIDSchema}
                                onSubmit={sendEditCtcDetNameID}
                                initialValues={
                                    {   
                                        debtorID: selRowData.debtorID,
                                        salesID: selRowData.salesID,
                                        role: selRowData.role,
                                        fullName: selRowData.debtorName,
                                        idType: selRowData.debtorIDType,
                                        idNum: selRowData.debtorIDNo
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit Full Name & ID No.</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {isLdNameID ? (<div style={{height: "200px"}}><Loader/></div>) : (
                                                <>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Full Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={ReactHtmlParser(values.fullName)}
                                                            isInvalid={errors.fullName && touched.fullName}
                                                            onChange={handleChange('fullName')}
                                                        />
                                                        {errors.fullName && touched.fullName && <div className="op-error-message">{errors.fullName}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Identification Type</Form.Label>
                                                        <FormSelect
                                                            options={idTypeContactDet}
                                                            valueDefault={idTypeContactDet.filter(opt => opt.value == values.idType)}
                                                            isInvalid={errors.idType && touched.idType}
                                                            onChange={(e) => {handleChange('idType')(e ? e.value : "")}}
                                                        />
                                                        {errors.idType && touched.idType && <div className="op-error-message">{errors.idType}</div>}
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Identification No 1 (Newest)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.idNum}
                                                            isInvalid={errors.idNum && touched.idNum}
                                                            onChange={handleChange('idNum')}
                                                        />
                                                        {errors.idNum && touched.idNum && <div className="op-error-message">{errors.idNum}</div>}
                                                    </Form.Group>
                                                </>
                                            )}
                                        </Modal.Body>
                                        {!isLdNameID && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isEditCtcDet} onHide={setIsEditCtcDet} size="xl">
                            <Formik
                                validationSchema={editCtcDetSchema}
                                onSubmit={sendEditCtcDet}
                                initialValues={
                                    {
                                        debtorID: selRowData.debtorID,
                                        salesID: selRowData.salesID,
                                        role: selRowData.role,
                                        fullName: selRowData.debtorName,
                                        phone1Pref: selRowData.phone1Pref,
                                        phone1Num: selRowData.phone1Num,
                                        phone2Pref: selRowData.phone2Pref,
                                        phone2Num: selRowData.phone2Num,
                                        email1: selRowData.email1,
                                        email2: selRowData.email2,
                                        address1: selRowData.address1 ? ReactHtmlParser(selRowData.address1) : selRowData.address1,
                                        address2: selRowData.address2 ? ReactHtmlParser(selRowData.address2) : selRowData.address2,
                                        address3: selRowData.address3 ? ReactHtmlParser(selRowData.address3) : selRowData.address3,
                                        cityID: selRowData.city,
                                        postcode: selRowData.postcode,
                                        country: "Vietnam"
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit Contact Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-0">
                                            {isLdCtcDet ? (<div style={{height: "65vh"}}><Loader/></div>) : (
                                                <Row style={{overflow: "auto", height: "65vh", width: "100%"}} className="py-0 px-4">
                                                    <Col className="py-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Full Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={ReactHtmlParser(values.fullName)}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Number 1</Form.Label>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <FormSelect
                                                                        options={contactPrefixLotDetails}
                                                                        valueDefault={contactPrefixLotDetails.filter(opt => opt.value == values.phone1Pref)}
                                                                        isSearchable={true}
                                                                        isInvalid={errors.phone1Pref && touched.phone1Pref}
                                                                        onChange={(e) => {handleChange('phone1Pref')(e ? e.value : "")}}
                                                                    />
                                                                    {errors.phone1Pref && touched.phone1Pref && <div className="op-error-message">{errors.phone1Pref}</div>}
                                                                </Col>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={values.phone1Num}
                                                                        isInvalid={errors.phone1Num && touched.phone1Num}
                                                                        onChange={handleChange('phone1Num')}
                                                                    />
                                                                    {errors.phone1Num && touched.phone1Num && <div className="op-error-message">{errors.phone1Num}</div>}
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Number 2</Form.Label>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <FormSelect
                                                                        options={contactPrefixLotDetails}
                                                                        valueDefault={contactPrefixLotDetails.filter(opt => opt.value == values.phone2Pref)}
                                                                        isSearchable={true}
                                                                        onChange={(e) => {handleChange('phone2Pref')(e ? e.value : "")}}
                                                                    />
                                                                </Col>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={values.phone2Num}
                                                                        isInvalid={errors.phone2Num && touched.phone2Pref}
                                                                        onChange={handleChange('phone2Num')}
                                                                    />
                                                                    {errors.phone2Num && touched.phone2Pref && <div className="op-error-message">{errors.phone2Num}</div>}
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Email 1</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.email1}
                                                                isInvalid={errors.email1 && touched.email1}
                                                                onChange={handleChange('email1')}
                                                            />
                                                            {errors.email1 && touched.email1 && <div className="op-error-message">{errors.email1}</div>}
                                                        </Form.Group>
                                                        {/* <Form.Group className="mb-3">
                                                            <Form.Label>Email 2</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.email2}
                                                                isInvalid={errors.email2 && touched.email2}
                                                                onChange={handleChange('email2')}
                                                            />
                                                            {errors.email2 && touched.email2 && <div className="op-error-message">{errors.email2}</div>}
                                                        </Form.Group> */}
                                                    </Col>

                                                    <Col className="border-start py-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 1</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={ReactHtmlParser(values.address1)}
                                                                isInvalid={errors.address1 && touched.address1}
                                                                onChange={handleChange('address1')}
                                                            />
                                                            {errors.address1 && touched.address1 && <div className="op-error-message">{errors.address1}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 2</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={ReactHtmlParser(values.address2)}
                                                                onChange={handleChange('address2')}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 3</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={ReactHtmlParser(values.address3)}
                                                                onChange={handleChange('address3')}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>District/City</Form.Label>
                                                            <FormSelect
                                                                options={cityList}
                                                                valueDefault={cityList.filter(opt => opt.label == values.cityID)}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                isInvalid={errors.cityID && touched.cityID}
                                                                onChange={(e) => {handleChange('cityID')(e ? e.value : "")}}
                                                            />
                                                            {errors.cityID && touched.cityID && <div className="op-error-message">{errors.cityID}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Postcode</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.postcode}
                                                                isInvalid={errors.postcode && touched.postcode}
                                                                onChange={handleChange('postcode')}
                                                            />
                                                            {errors.postcode && touched.postcode && <div className="op-error-message">{errors.postcode}</div>}
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.country}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Modal.Body>
                                        {!isLdCtcDet && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>
                    </div>
                )
            }
        </>
    );
}

export default LotDetailsVN;