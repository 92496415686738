import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Form, Stack, InputGroup, Card, Offcanvas, Row, Col, ListGroup, Image } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import "../../lib/scss/op-step.scss";
import FormSelect from "../../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import ReactHtmlParser from "html-react-parser";

const AddWSCampaignV2 = ({ isOpen, onSuccess }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);

    const [openCanvas, setOpenCanvas] = useState(false);

    const [isCur, setIsCur] = useState(true);
    const [isTemplate, setIsTemplate] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);

    const [curList, setCurList] = useState([]);
    const [wsTempList, setWsTempList] = useState([]);
    const [wsNumList, setWsNumList] = useState([]);

    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [curationData, setCurationData] = useState("");
    const [selWsTemp, setSelWsTemp] = useState("");
    const [selWsNum, setSelWsNum] = useState("");
    const [paramsArr, setParamsArr] = useState([]);
    const [changeMsg, setChangeMsg] = useState("");
    const [headerImg, setHeaderImg] = useState("");
    const [headerImgUrl, setHeaderImgUrl] = useState("");
    const [submitImg, setSubmitImg] = useState(false);
    const [campaignTitle, setCampaignTitle] = useState("");
    const [testCampaign, setTestCampaign] = useState("");
    

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "65vh" : "65vh", paddingTop: 5 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: "Name",
            field: "prospect_name",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Contact",
            field: "contact_num",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Email",
            field: "email_title",
            flex: 1,
            minWidth: 200
        }
    ]);

    const getAllCuration = () => {
        
        axios.get("ws/ws_curation.php", {
            params: {
                task: "listCuration",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(data => {
                    return {label: data.curation_title, value: data.curation_id};
                });

                setCurList(dataList);
            }else{
                setCurList([]);
            }
        })
        .catch(error => {
            setCurList([]);
        });
    }

    const getAllWSTemplate = () => {
        axios.get("ws/ws_conversation.php", {
            params: {
                task: "getWATemplateListing",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(data => {
                    var array = [];
                    for (let index = 0; index < Number(data.whatsapp_template_params); index++) {
                        array.push("");
                    }
                    return {
                        label: data.whatsapp_template_name, 
                        value: ReactHtmlParser(data.message),
                        wsTempOpID: data.whatsapp_template_op_id, 
                        params: array,
                        header: data.whatsapp_template_header_image
                    };
                });

                setWsTempList(dataList);
            }else{
                setWsTempList([]);
            }
        })
        .catch(error => {
            setWsTempList([]);
        });
    }

    const getAllWSNumber = () => {
        axios.get("ws/ws_ai_campaign.php", {
            params: {
                task: 'getAudienceOptions',
                type: 'chat_instance',
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(data => {
                    return {label: `${data.instance_title} - ${data.user_number}`, value: data.chat_instance_id};
                });

                setWsNumList(dataList);
            }else{
                setWsNumList([]);
            }
        })
        .catch(error => {
            setWsNumList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllCuration();
            getAllWSTemplate();
            getAllWSNumber();
            setOpenCanvas(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getCurGrid = (data) => {
        if (data) {
            axios.get('ws/ws_curation.php', {
                params: {
                    task: "listCurationContacts",
                    curation: data.value,
                    startRow: 0,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;
    
                if (Number(data.status) === 0) {
                    setRowData(data.record);
                    setRecordFound(data.record.length);
                    setIsGrid(true);
                }else{
                    setRowData([]);
                    setRecordFound(0);
                    setIsGrid(false);

                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'No prospect for the selected curation'
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: error.message
                });
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
            });
        }else{
            setRowData([]);
            setRecordFound(0);
            setIsGrid(false);
        }
    }

    const paramsOnchange = (index, value) => {
        
        setParamsArr((prevData) => {
            const newFormData = [...prevData];
            newFormData[index] = value;
            return newFormData;
        });
        
    }

    const handleToCur = () => {
        setIsCur(true);
        setIsTemplate(false);
        setIsConfirm(false);
    }

    const handleToTemplate = () => {

        if (curationData && rowData.length > 0) {
            setIsCur(false);
            setIsTemplate(true);
            setIsConfirm(false);   
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please choose curation or make sure there are prospect in the curation'
            });
        }
    }

    const handleToConfirm = () => {

        var selectedWsTemp = selWsTemp.value;

        paramsArr.forEach((value, index) => {
            if (selectedWsTemp.includes(`{{${index+1}}}`)) {
                selectedWsTemp = selectedWsTemp.replaceAll(`{{${index+1}}}`, value);
            }
        });

        setChangeMsg(selectedWsTemp);

        var allowNext = 0;
        
        if (selWsTemp && selWsNum) {
            if (selWsTemp.params.length > 0 && Number(selWsTemp.header) === 1) {

                if (!paramsArr.includes('') && headerImg && headerImgUrl) {
                    allowNext = 1;
                }else{
                    allowNext = 0;
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Please fill in all params and upload header image'
                    });
                }
                
            }else if (selWsTemp.params.length > 0) {

                if (!paramsArr.includes('')) {
                    allowNext = 1;
                }else{
                    allowNext = 0;
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Please fill in all params'
                    });
                }
                
            }else if (Number(selWsTemp.header) === 1) {

                if (headerImg && headerImgUrl) {
                    allowNext = 1;
                }else{
                    allowNext = 0;
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Please upload header image'
                    });
                }

            }else{
                allowNext = 1;
            }

            if (allowNext === 1) {
                setIsCur(false);
                setIsTemplate(false);
                setIsConfirm(true);    
            }  
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please choose whatsapp template and whatsapp number'
            });
        }
    }

    const handleStartCampaign = async() => {
        var prospectID = rowData.map((data) => {
            return data.prospect_id;
        });

        // console.log(selWsTemp.label);
        // console.log(paramsArr.toString().replaceAll(',', '|'));
        // console.log(changeMsg);
        // console.log(curationData.value);
        // console.log(selWsNum.value);
        // console.log(prospectID.toString());
        // console.log(campaignTitle);
        // console.log(headerImgUrl);

        const params = new FormData();

        params.append('task', 'sendBulkWACampaign');
        params.append('template_name', selWsTemp.label);
        params.append('template_params', paramsArr.toString().replaceAll(',', '|'));
        params.append('message', changeMsg);
        params.append('curation', curationData.value);
        params.append('instance', selWsNum.value);
        params.append('prospect', prospectID.toString());
        params.append('campaign_title', campaignTitle);
        params.append('whatsapp_template_header_image', headerImgUrl);
        params.append('utoken', session.user_ac_token);
        params.append('ctoken', session.company_token);

        if (campaignTitle) {
            
            try {
                const res = await axios.post('ws/ws_whatsapp_campaign.php', params);
    
                let data = res.data;
    
                if (data) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Successfully create whatsapp campaign',
                        timer: 1500
                    })
                    .then(() => {
                        onSuccess();
                        isOpen();
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Failed',
                        text: 'Please try again or contact your IT Support'
                    });
                }
            } catch (error) {
                console.log(error);
            }

        }else{
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please insert campaign title'
            });
        }

    }

    const handleTestCampaign = () => {

    }

    return(
        <Offcanvas show={openCanvas} onHide={isOpen} placement="bottom" style={{left: 57, height: "100vh"}} backdrop="static">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Create Whatsapp Campaign</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0" style={{ overflow: "hidden" }}>

                <div className="mt-3 px-5 border-bottom">
                    <div className="op steps w-100 mb-3">
                        <div className="link step active py-2">
                            <div className="content">
                                <div className="title text-start">Curation</div>
                                <div className="description d-flex text-light">
                                    Choose Curation
                                </div>
                            </div>
                        </div>
                        <div className={`link step ${!isConfirm && isTemplate ? 'active' : isConfirm && !isTemplate ? 'active' : ''} py-2`}>
                            <div className="content">
                                <div className="title text-start">Whatsapp Template</div>
                                <div className="description d-flex text-light">
                                    Choose Whatsapp Template
                                </div>
                            </div>
                        </div>
                        <div className={`link step ${isConfirm ? 'active' : ''} py-2`}>
                            <div className="content">
                                <div className="title text-start">Confirmation</div>
                                <div className="description d-flex text-light">
                                    Summary of the info
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex h-100">
                    <div className="p-3 border-end" style={{ width: "20%"}}>
                        {isCur && <div>
                            <Form.Group className="mb-3">
                                <Form.Label>Curation</Form.Label>
                                <FormSelect
                                    placeholder="Select Curation"
                                    options={curList}
                                    valueDefault={curList.filter(opt => opt.value === curationData.value)}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        getCurGrid(e ? e : "");
                                        setCurationData(e ? e : '');
                                    }}
                                />
                            </Form.Group>
                        </div>}
                        {isTemplate && <div>
                            <Form.Group className="mb-3">
                                <Form.Label>Whatsapp Template</Form.Label>
                                <FormSelect
                                    placeholder="Select Whatsapp Template"
                                    options={wsTempList}
                                    valueDefault={wsTempList.filter(opt => opt.wsTempOpID === selWsTemp.wsTempOpID)}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setSelWsTemp(e ? e : '');
                                        setParamsArr(e ? e.params : []);
                                        setHeaderImg('');
                                        setHeaderImgUrl('');
                                        setSubmitImg(false);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Whatsapp Number</Form.Label>
                                <FormSelect
                                    placeholder="Select Whatsapp Number"
                                    options={wsNumList}
                                    valueDefault={wsNumList.filter(opt => opt.value === selWsNum.value)}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e) => {
                                        setSelWsNum(e ? e : '');
                                    }}
                                />
                            </Form.Group>
                        </div>}
                        {isConfirm && <div>
                            <Form.Group className="mb-3">
                                <Form.Label>Campaign Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={campaignTitle}
                                    onChange={(e) => {setCampaignTitle(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Test this campaign</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="contact number eg. 012556221"
                                    value={testCampaign}
                                    onChange={(e) => {setTestCampaign(e.target.value)}}
                                />
                            </Form.Group>
                            <div>
                                <button className="btn op-button op-primary-color text-light" onClick={() => {handleTestCampaign()}}>Send Test</button>
                            </div>
                        </div>}
                    </div>
                    <div className="p-3 h-100" style={{ backgroundColor: "#eeeeee", width: "80%" }}>
                        {isCur && <div>
                            {curationData && <div>{Number(recordFound) > 0 ? `${recordFound} record found.` : "( No record found )"}</div>}
                            <div className="my-2" style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        rowData={rowData}
                                        defaultColDef={reportColDef}
                                        rowHeight={50}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        </div>}
                        {isTemplate && <div>
                            <Card className="rounded-1 shadow-sm">
                                <Card.Header className="text-center">
                                    <Card.Title as={'h6'} className="mt-2">{selWsTemp ? selWsTemp.label : 'No template selected'}</Card.Title>
                                </Card.Header>
                                {selWsTemp &&
                                <Card.Body className="p-0" style={{height: '400px', overflowX: 'hidden', overflowY: 'auto'}}>
                                    <Row sm={12} className="p-0" style={{height: '100%'}}>
                                        <Col sm={6} className="border-end p-4">
                                            <Form.Group>
                                                <Form.Label>Message</Form.Label>
                                            </Form.Group>
                                            <div style={{textAlign: 'justify'}}>
                                                {selWsTemp.value}
                                            </div>
                                        </Col>
                                        <Col sm={6} className="p-4">
                                            <div>
                                                {selWsTemp.params.map((value, index) => (
                                                    <Form.Group key={index} className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="params"
                                                            value={paramsArr[index]}
                                                            onChange={(e) => {paramsOnchange(index, e.target.value)}}
                                                        />
                                                    </Form.Group>
                                                ))}
                                            </div>
                                            {Number(selWsTemp.header) === 1 && <div>
                                                <Form.Group>
                                                    <Form.Label>Header Image</Form.Label>
                                                </Form.Group>
                                                {!submitImg && <FilePond
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="file"
                                                    files={headerImg}
                                                    credits={false}
                                                    instantUpload={false}
                                                    acceptedFileTypes={["image/jpg", "image/png", "image/jpeg"]}
                                                    onupdatefiles={(fileItems) => {
                                                        if (fileItems && fileItems.length) {
                                                            if (fileItems[0].file.type === "image/jpg" || fileItems[0].file.type === "image/jpeg" || fileItems[0].file.type === "image/png") {
                                                                var imgItem = fileItems.map((fileItem) => fileItem.file);
                                                                setHeaderImg(imgItem);
                                                            }
                                                        }
                                                    }}
                                                    onremovefile={() => {
                                                        setHeaderImg("");
                                                        setHeaderImgUrl("");
                                                    }}
                                                    server={{
                                                        process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                            const formData = new FormData();
                                                            const reader = new FileReader();
            
                                                            reader.onload = (e) => {
                                                                formData.append("fileToUpload", e.target.result);
                                                                axios.post('ws/ws_conversation.php', formData, {
                                                                    params: {
                                                                        task: 'getMediaURL',
                                                                        mime_type: headerImg[0].type,
                                                                        file_name: headerImg[0].name,
                                                                        utoken: session.user_ac_token,
                                                                        ctoken: session.company_token
                                                                    }
                                                                }).then((response) => {
                                                                    const data = response.data;
                                                                    if (Number(data.status) === 0) {
                                                                        data.media_url = data.media_url.replaceAll('cloud_dev', session.hostUrlType)
                                                                        load(data);
                                                                        setHeaderImgUrl(data.media_url.replaceAll('cloud_dev', session.hostUrlType));
                                                                        setSubmitImg(true);
                                                                    }else{
                                                                        setSubmitImg(false);
                                                                    }
                                                                });
                                                            };
                                                            reader.readAsDataURL(file);
                                                        },
                                                    }}
                                                />}
                                                {submitImg && <div>
                                                    <div className="border rounded d-flex justify-content-center align-items-center" style={{width: '100%', height: '200px'}}>
                                                        <Image src={headerImgUrl} rounded width={'100%'}/>
                                                    </div>
                                                    <div className="mt-2">
                                                        <button type="button" className="btn btn-light border-1 shadow-sm float-end" onClick={() => {setSubmitImg(false); setHeaderImg(''); setHeaderImgUrl('');}}>Change Image</button>
                                                    </div>
                                                </div>}
                                            </div>}
                                        </Col>
                                    </Row>
                                </Card.Body>}
                            </Card>
                
                        </div>}
                        {isConfirm && <div>
                            <Card className="rounded-1 shadow-sm">
                                <Card.Header className="text-center">
                                    <Card.Title as={'h6'} className="mt-2">Summary</Card.Title>
                                </Card.Header>
                                <Card.Body className="p-0" style={{height: '400px', overflowX: 'hidden', overflowY: 'auto'}}>
                                    <Row sm={12} style={{height: '100%'}}>
                                        <Col sm={6} className="border-end px-4 py-2">
                                            <h6>Curation</h6>
                                            <div className="mt-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Curation Title</Form.Label>
                                                    <div>{curationData.label}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Total Contact</Form.Label>
                                                    <div>{rowData.length}</div>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>List of contact</Form.Label>
                                                    <div>
                                                        {rowData.length > 0 && rowData.map((data, index) => (
                                                            <Form.Group key={index}>
                                                                <Form.Label>
                                                                    {data.prospect_name && data.contact_num ? `${data.prospect_name} - ${data.contact_num}` 
                                                                    : data.prospect_name ? data.prospect_name : ``}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        ))}
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col sm={6} className="px-4 py-2">
                                            <h6>Whatsapp Template</h6>
                                            <div className="mt-3">
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Template Name</Form.Label>
                                                    <div>{selWsTemp.label}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Whatsapp Number</Form.Label>
                                                    <div>{selWsNum.label}</div>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Message</Form.Label>
                                                    <div className="pe-4">
                                                        {/* <div className="d-flex justify-content-center align-items-center" style={{width: '100%', height: '100px'}}>
                                                            <Image src={headerImgUrl} rounded width={'100%'}/>
                                                        </div> */}
                                                        <div style={{textAlign: 'justify', width: '100%'}}>{changeMsg}</div>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </div>}
                    </div>
               
                </div>
               
                
            </Offcanvas.Body>

            <div className="offcanvas-footer">
                {isCur && <Stack direction="horizontal" gap={2}>
                    <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleToTemplate}>Next</button>
                </Stack>}
                {isTemplate && <Stack direction="horizontal" gap={2}>
                    <button className="btn btn-dark" onClick={handleToCur}>Previous</button>
                    <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleToConfirm}>Next</button>
                </Stack>}
                {isConfirm && <Stack direction="horizontal" gap={2}>
                    <button className="btn btn-dark" onClick={handleToTemplate}>Previous</button>
                    <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleStartCampaign}>Start Campaign</button>
                </Stack>}
            </div>
        </Offcanvas>
    );
}

export default AddWSCampaignV2;