import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Container, Modal, Form, Stack, InputGroup, Card, Row, Col, Nav  } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { faHandshake, faUser } from "@fortawesome/pro-solid-svg-icons";
import * as XLSX from "xlsx"; 

const SettingBulkUpdateLead = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);

    const [isLoading, setIsLoading] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);
    const [selectData, setSelectData] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: "55vh", paddingTop: 10 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [dataColumn] = useState([
        {
            headerName: 'No',
            width: 100,
            field: 'no',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            cellRenderer: (params) => {
                if (params.data !== undefined) {
                  return params.rowIndex + 1;
                } else {
                  return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            },
        },
        {
            headerName: "Full name of buyer",
            field: 'name'
        },
        {
            headerName: "Corporate",
            field: 'corporate'
        },
        {
            headerName: "Identification Type",
            field: 'id_type'
        },
        {
            headerName: "Identification No",
            field: 'id_no'
        },
        {
            headerName: "Corporate Type",
            field: 'corporate_type'
        },
        {
            headerName: "PDPA",
            field: 'pdpa'
        },
        {
            headerName: "Contact Prefix",
            field: 'contactPrefix'
        },
        {
            headerName: "Contact No.",
            field: 'contact1'
        },
        {
            headerName: "New Contact Prefix",
            field: 'contactPrefix_new'
        },
        {
            headerName: "New Contact No.",
            field: 'contact1_new'
        },
        {
            headerName: "Email Address",
            field: 'email1'
        },
        {
            headerName: "New Email Address",
            field: 'email1_new'
        },
        {
            headerName: "Address Type",
            field: 'addressType'
        },
        {
            headerName: "Address Line 1",
            field: 'address1'
        },
        {
            headerName: "Address Line 2",
            field: 'address2'
        },
        {
            headerName: "Address Line 3",
            field: 'address3'
        },
        {
            headerName: "Postal Code",
            field: 'postal_code'
        },
        {
            headerName: "City",
            field: 'city'
        },
        {
            headerName: "Country",
            field: 'country'
        },
        {
            headerName: "Email Address 2",
            field: 'email2'
        },
        {
            headerName: "Contact Prefix 2",
            field: 'contact2Prefix'
        },
        {
            headerName: "Contact No. 2",
            field: 'contact2'
        },
        {
            headerName: "Contact Prefix 3",
            field: 'contact3Prefix'
        },
        {
            headerName: "Contact No. 3",
            field: 'contact3'
        },
        {
            headerName: "Contact Prefix 4",
            field: 'contact4Prefix'
        },
        {
            headerName: "Contact No. 4",
            field: 'contact4'
        },
        {
            headerName: "Fax Prefix",
            field: 'fax_prefix'
        },
        {
            headerName: "Fax No",
            field: 'fax_no'
        }
    ]);

    const sendAction = async (values) => {
        try {
            var recordReader = new FileReader();
            recordReader.onload = () => {
                var recordFileData = recordReader.result;
                var recordWorkBook = XLSX.read(recordFileData, { type: "binary" });
                var recordRow = XLSX.utils.sheet_to_row_object_array(recordWorkBook.Sheets["Bulk Update"]);
                var recordData = recordRow;
                setRecordFound(recordRow.length);

                var headerRows = [];
                var recordWorksheet = recordWorkBook.Sheets["Bulk Update"];

                if (recordWorksheet) {
                var range = XLSX.utils.decode_range(recordWorksheet["!ref"]);
                for (var R = range.s.r; R <= range.e.r; ++R) {
                    var row = [];
                    for (var C = range.s.c; C <= range.e.c; ++C) {
                    var cellAddress = XLSX.utils.encode_cell({
                        r: R,
                        c: C,
                    });
                    var cell = recordWorksheet[cellAddress];
                    var cellValue = cell ? cell.v : "";
                    row.push(cellValue);
                    }
                    headerRows.push(row);
                }
                }

                if (!recordWorksheet) {
                Swal.fire({
                    icon: "warning",
                    html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>Your sheet name is wrong. Please rename it to 'Redemption Voucher'.</p></div>`,
                });
                return;
                }

                var expectedColumns = [
                    "Full name of buyer", 
                    "Corporate ", 
                    "Identification Type", 
                    "Identification No", 
                    "Corporate Type", 
                    "PDPA", 
                    "Contact Prefix", 
                    "Contact No", 
                    "New Contact Prefix", 
                    "New Contact No", 
                    "Email Address", 
                    "New Email Address", 
                    "Address Type", 
                    "Address Line 1", 
                    "Address Line 2", 
                    "Address Line 3", 
                    "Postal Code",
                    "Country",
                    "City",
                    "Email Address 2",
                    "Contact Prefix 2",
                    "Contact No 2",
                    "Contact Prefix 3",
                    "Contact No 3",
                    "Contact Prefix 4",
                    "Contact No 4",
                    "Fax Prefix",
                    "Fax No"
                ];
                var currentColumns = headerRows[0];
                
                if (currentColumns) {
                var missingColumns = expectedColumns.filter((column) => !currentColumns.includes(column));
                if (missingColumns.length > 0) {
                    var alertMessage = `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>You're missing the following columns in the template:</p><ul>`;
                    for (var i = 0; i < missingColumns.length; i++) {
                    alertMessage += `<li>${missingColumns[i]}</li>\n`;
                    }
                    alertMessage += `</ul></div>`;
                    Swal.fire({
                    icon: "warning",
                    html: alertMessage,
                    });
                    return;
                }
                }

                if (!recordRow.length) {
                Swal.fire({
                    icon: "warning",
                    html: `<h5>You're uploading the wrong template. Please use the provided template to make an update.</h5><div className="reason"><h6>Reason:</h6><p>There is no data inside the sheet.</p></div>`,
                });
                return;
                }

                for (let i = 0; i < recordData.length; i++) {
                recordData[i].index = i + 1;
                }
                
                const bulkData = recordData.map(({
                    'index': index,
                    'Full name of buyer': name,
                    'Corporate ': corporate,
                    'Identification Type': id_type,
                    'Identification No': id_no,
                    'Corporate Type': corporate_type,
                    'PDPA': pdpa,
                    'Contact Prefix': contactPrefix,
                    'Contact No': contact1,
                    'New Contact Prefix': contactPrefix_new,
                    'New Contact No': contact1_new,
                    'Email Address': email1,
                    'New Email Address': email1_new,
                    'Address Type': addressType,
                    'Address Line 1': address1,
                    'Address Line 2': address2,
                    'Address Line 3': address3,
                    'Postal Code': postal_code,
                    'City': city,
                    'Country': country,
                    'Email Address 2': email2,
                    'Contact Prefix 2': contact2Prefix,
                    'Contact No 2': contact2,
                    'Contact Prefix 3': contact3Prefix,
                    'Contact No 3': contact3,
                    'Contact Prefix 4': contact4Prefix,
                    'Contact No 4': contact4,
                    'Fax Prefix': fax_prefix,
                    'Fax No': fax_no
                    }) => ({
                    index,
                    name,
                    corporate,
                    id_type,
                    id_no,
                    corporate_type,
                    pdpa,
                    contactPrefix,
                    contact1,
                    contactPrefix_new,
                    contact1_new,
                    email1,
                    email1_new,
                    addressType,
                    address1,
                    address2,
                    address3,
                    postal_code,
                    city,
                    country,
                    email2,
                    contact2Prefix,
                    contact2,
                    contact3Prefix,
                    contact3,
                    contact4Prefix,
                    contact4,
                    fax_prefix,
                    fax_no
                }));

                setRowData(bulkData);
            };
            recordReader.readAsArrayBuffer(values.fileItem[0]);
        } catch (error) {
            console.log(error);    
        }
    }

    const sendTemplate = () => {
        window.open("https://app.outperformhq.io/cloud/assets/bulk_template/OutPerform_Bulk_Contact_Update_LeadsTemplate.xlsx","_blank");
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelect = useCallback(() => {
        setSelectData(gridRef.current.api.getSelectedRows());
    }, []);

    const validateEmail = (email) => {
        if (email) {
          var pattern = /^[\w\.-]+@[\w\.-]+\.\w+$/;
          var isValid = pattern.test(email);
          return isValid;
        }
    };

    const sendBuyerData = () => {
        var error = [];

        selectData.forEach(data => {
            if (data.corporate === 'Yes') {

                if (!(data.id_type || data.id_type === 'Reg No' || data.id_type === 'Others')) {
                  error.push('Identification type must be "Reg No" or "Others" for Corporate "Yes" at row ' + data.index);
                }
      
                if (!data.corporate_type) {
                    error.push('Corporate "Yes" must have corporate type at row ' + data.index);
                }
      
            } else if (data.corporate === 'No') {
      
                if (!(data.id_type || data.id_type === 'NRIC No' || data.id_type === 'Passport')) {
                    error.push('Identification type must be "NRIC No" or "Passport" for Corporate "No" at row ' + data.index);
                }
      
            } else {
      
                if (data.id_type) {
                    error.push('Identification type must have Corporate at row ' + data.index);
                }
      
                if (data.corporate_type) {
                  if (data.corporate === 'No') {
                    error.push('Corporate must be Yes for corporate type selection at row ');
                  }
                }
            }

            if (!(data.id_no || data.contact1 || data.email1)) {
                error.push('Incomplete fields, at least one of ID number, contact 1, or email 1 is required at row' + data.index);
            }

            if (data.email1 && !validateEmail(data.email1)) {
                error.push('Invalid email 1 at row' + data.index);
            }
            if (data.email2 && !validateEmail(data.email2)) {
                error.push('Invalid email 2 at row' + data.index);
            }
            if (data.email3 && !validateEmail(data.email3)) {
                error.push('Invalid email 3 at row' + data.index);
            }
            if (data.emailNew && !validateEmail(data.email1_new)) {
                error.push('Invalid new email at row' + data.index);
            }
        });

        var errorMessage = error.map((data) => {
            return `<div>${data}</div>`;
        });

        if (error.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error found',
                html: errorMessage
            });
        }else{
            handleSendUpdate(selectData);
        }
    }

    const handleSendUpdate = async (data) => {
        var params = new FormData();

        const recordResult = {
            name: [],
            corporate: [],
            id_type: [],
            id_no: [],
            corporate_type: [],
            pdpa: [],
            contactPrefix: [],
            contact1: [],
            contactPrefix_new: [],
            contact1_new: [],
            email1: [],
            email1_new: [],
            addressType: [],
            address1: [],
            address2: [],
            address3: [],
            postal_code: [],
            city: [],
            country: [],
            email2: [],
            contact2Prefix: [],
            contact2: [],
            contact3Prefix: [],
            contact3: [],
            contact4Prefix: [],
            contact4: [],
            fax_prefix: [],
            fax_no: [],
        };

        for (let i = 0; i < data.length; i++) {
            const info = data[i];
            for (const key in recordResult) {
              recordResult[key].push(info[key] ? info[key] : '');
            }
        }

        for (const key in recordResult) {
            // recordResult[key] = recordResult[key].join('|');
            // apiParams[key] = recordResult[key];
            
            params.append(key, recordResult[key].join('|'));
        }

        params.append("task", "UpdateLeads");
        params.append("utoken", session.user_ac_token);
        params.append("ctoken", session.company_token);

        try {
            const res = await axios.post("ws/ws_bulk_update.php", params);

            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Successfully bulk update',
                    timer: 1500,
                })
                .then(() => {
                    setRowData([]);
                    setRecordFound(0);
                    setSelectData([]);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return(
        <div>
            {
                isLoading ? <Loader/> : 
                <div className="d-flex justify-content-center align-items-center">

                <Card className="mt-1 w-100 border-0 shadow-sm">
                    <Formik
                        onSubmit={sendAction}
                        initialValues={
                            {
                                isSubmit: true,
                                fileItem: []
                            }
                        }
                    >
                        {({ handleSubmit, setFieldValue, values }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card.Body>
                                    <Form.Group className="mb-3">
                                        <FilePond
                                            allowMultiple={false}
                                            maxFiles={1}
                                            name="file"
                                            files={values.fileItem}
                                            credits={false}
                                            instantUpload={false}
                                            acceptedFileTypes={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                                            onupdatefiles={(item) => {
                                                setFieldValue('fileItem', 
                                                    item.map((data) => data.file)
                                                );
                                            }}
                                            onremovefile={() => {
                                                setFieldValue('fileItem', []);
                                                setRecordFound(0);
                                                setRowData([]);
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group className="text-center mb-3">
                                        <Form.Label>
                                            Please note, the uploader only accepts excel files (xls or xlsx) and only accepts bulk update for 1500 lists. Please download and use our template and enter your list into it.
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group>
                                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                                            <button type="submit" className="btn btn-primary" disabled={values.fileItem.length > 0 ? false : true}>Preview</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => {sendTemplate()}}>Template</button>
                                        </Stack>
                                    </Form.Group>
                                    <Form.Group>
                                        {Number(recordFound) > 0 && 
                                            <div className="text-end">
                                                {Number(recordFound) === 1 ? `${recordFound} record found.` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}
                                            </div>
                                        }
                                        {Number(recordFound) > 0 && (
                                            <div style={containerStyle}>
                                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                                    <AgGridReact
                                                        ref={gridRef}
                                                        rowSelection="multiple"
                                                        defaultColDef={reportColDef}
                                                        rowData={rowData}
                                                        columnDefs={dataColumn}
                                                        onSelectionChanged={handleSelect}
                                                        pagination={true}
                                                        paginationPageSize={100}
                                                        paginationPageSizeSelector={false}
                                                        suppressRowClickSelection={true}
                                                        animateRows={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Form.Group>
                                </Card.Body>
                                <Card.Footer>
                                    <Stack direction="horizontal" gap={2}>
                                        <button type="button" className="btn op-button op-primary-color text-light ms-auto" disabled={selectData.length > 0 ? false : true} onClick={() => {sendBuyerData()}}>Submit</button>
                                    </Stack>
                                </Card.Footer>
                            </Form>
                        )}
                    </Formik>
                </Card>

            </div>
            }
        </div>
    );
}

export default SettingBulkUpdateLead;