import * as React from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2";
import { useState, useCallback, useEffect, useRef } from "react";
import { Row, Col, Modal, Button, FormControl, Stack, Form } from "react-bootstrap";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import "../../lib/css/AudioNotes.css";
import $ from 'jquery';
import Loader from "../../includes/Loader";
import { useTranslation } from "react-i18next";

const AudioNotes = ({ getDealId, onSubmit }) => {
  const { t } = useTranslation();
  const { session } = useAuth();
  const cToken = session.company_token;
  const uToken = session.user_ac_token;

  const [audioBlobURLs, setAudioBlobURLs] = useState([]);
  const [recordingMessage, setRecordingMessage] = useState("");
  const [recordingStatus, setRecordingStatus] = useState("");
  const [isLoading, setisLoading] = useState(false);

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setAudioBlobURLs((prevURLs) => [...prevURLs, url]);
    send(blob);
  };

  const send = async (blob) => {
    setisLoading(true);
    let data = new FormData();

    data.append("task", "getAINotes");
    data.append("blob", blob);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    try {
      const response = await axios.post("https://app.outperformhq.io/cloud_staging/ws/ws_ai_notes.php", data, config);
      setRecordingMessage(response.data.message);
      setRecordingStatus(response.data.status);
    } catch (error) {
      console.error("Error sending audio:", error);
      setRecordingMessage("Error sending audio");
      setRecordingStatus("error");
    } finally {
      setisLoading(false);
      return;
    }
  };

  // save notes
  const handleAddNotes = async () => {
    const formData = new FormData();
    formData.append("task", "postNote");
    formData.append("region", session.country);
    formData.append("content", recordingMessage);
    formData.append("notify_user", session.user_id);
    formData.append("deal", getDealId);
    formData.append("utoken", uToken);
    formData.append("ctoken", cToken);

    try {
      const response = await axios.post("ws/ws_post_note.php", formData);

      const data = response.data;
      if (data.status === 0) {
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: "Successfully created a new notes",
          timer: 2000
        }).then(() => {
          if (onSubmit) {
            onSubmit();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oppss...",
          text: "Failed to save notes. Please try again or contact our developer.",
        });
      }
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to save notes. Please try again.",
      });
    }
  };

  useEffect(() => {
    $('[data-testid="ar_mic"]').hover(
      function () {
        $(this).attr('title', 'Save AI Notes');
      }
    );
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h6">{t("AudioNotes_audio_notes")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        {/* <Stack direction="vertical" gap={4}>
          <div className="w-100 d-flex justify-content-center align-items-center">
            <AudioRecorder 
            onRecordingComplete={(blob) => addAudioElement(blob)} 
            recorderControls={recorderControls} 
            downloadFileExtension="mp3" 
            showVisualizer={true} 
            showPauseButton = {false}
            />
          </div>

          {Number(recordingStatus) === 0 && recordingMessage && (
            <Form.Group className="d-flex">
              <Form.Control as="textarea" rows={4} value={recordingMessage} onChange={(e) => setRecordingMessage(e.target.value)} />
            </Form.Group>
          )}
        </Stack> */}
        <Row>
          <Col className="d-flex justify-content-center align-items-center py-5">
            <AudioRecorder
              onRecordingComplete={(blob) => addAudioElement(blob)}
              recorderControls={recorderControls}
              downloadFileExtension="mp3"
              showVisualizer={true}
              showPauseButton={false}
            />
          </Col>
          {isLoading ? (
            <Loader />
          ) : (
            <Col className={Number(recordingStatus) === 0 && recordingMessage ? '' : 'border bg-light rounded shadow-sm '} style={{marginRight: "10px"}} lg={7}>
              {Number(recordingStatus) === 0 && recordingMessage && (
                <Form.Group className="d-flex">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={recordingMessage}
                    onChange={(e) => setRecordingMessage(e.target.value)}
                    className={Number(recordingStatus) === 0 && recordingMessage ? 'bg-light shadow-sm' : 'border-0'}
                  />
                </Form.Group>
              )}
            </Col>

          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="op-primary-color text-light" onClick={handleAddNotes}>
          {t("AudioNotes_save_notes")}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AudioNotes;
