import React, { useCallback, useEffect, forwardRef, useRef, useState } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Image, Row, Stack, Accordion, ListGroup, Modal, Form, Container, Offcanvas, InputGroup, Dropdown, Badge } from "react-bootstrap";
import axios from "../api/axios";
import moment from "moment";
import { setCurrency } from "../lib/js/Function";
import { useKit } from "./CustomerKit";
import { faBookOpen, faLocationDot, faBagShopping, faGraduationCap, faHouseMedical, faTrain, faCircleCheck, faCameraMovie } from "@fortawesome/pro-solid-svg-icons";
import { GoogleMap, Marker, useJsApiLoader, DirectionsRenderer, DirectionsService } from "@react-google-maps/api";
import EducationMarker from "../assets/images/marker/education_marker.png";
import EntertainmentMarker from "../assets/images/marker/entertainment_marker.png";
import HospitalMarker from "../assets/images/marker/hospital_marker.png";
import PublicTransportMarker from "../assets/images/marker/public_transport_marker.png";
import ShoppingMarker from "../assets/images/marker/shopping_marker.png";
import {
  faMap,
  faHouseLock,
  faPersonBooth,
  faChevronRight,
  faBedFront,
  faBath,
  faEllipsisVertical,
  faHouse,
  faHouseBuilding,
  faMapLocationDot,
  faKeySkeleton,
  faDoorOpen,
  faX,
  faFileCertificate,
  faBuilding,
  faBuildings,
  faWaterLadder,
  faFile,
  faSliders,
  faPhone,
  faImage,
  faPlay,
  faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Loader from "../includes/Loader";
import { FormSelect } from "../includes/FormCustom";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { triggerBase64Download } from "react-base64-downloader";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../lib/scss/saleskit-phase-details.scss";
import { setModulesValues } from "./booking/BookingFormat";

const CustomerKitPhaseDetails = () => {
  const { t } = useTranslation();
  const modulesValues = setModulesValues({});
  const [modules, setModules] = useState(modulesValues);
  const navigate = useNavigate();
  const { projectData, company_id, allProjectData } = useKit();
  const { project_id, phase_id, key } = useParams();
  const [phaseData, setPhaseData] = useState({});
  const [typeList, setTypeList] = useState([]);
  const [salesChartData, setSalesChartData] = useState({});
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [isSalesChartImage, setIsSalesChartImage] = useState(false);
  const [projectInfo, setProjectInfo] = useState({});
  const [location, setLocation] = useState(null);
  const [amenities, setAmenities] = useState("0");
  const [destination, setDestination] = useState(null);
  const [travelResponse, setTravelResponse] = useState(null);
  const [destinationResponse, setDestinationResponse] = useState(false);

  const { Formik } = formik;
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const openRegisterModal = () => setShowRegisterModal(true);
  const closeRegisterModal = () => setShowRegisterModal(false);
  const [sourceRecord, setSourceRecord] = useState([]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [unitList, setUnitList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAmenities, setIsAmenities] = useState(false);
  const [amenitiesData, setAmenitiesData] = useState({});
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyACc3wiPr3UpBXlQuZ4i1BOGEIvpAUY1TY",
  });

  const [isUnit, setIsUnit] = useState(false);
  const [bedroomList, setBedroomList] = useState([]);
  const [isDocument, setIsDocument] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [isFile, setIsFile] = useState(false);
  const [files, setFiles] = useState(null);
  const [isProjectDetails, setIsProjectDetails] = useState(false);
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [isUnitFilter, setIsUnitFilter] = useState(false);
  const [unitFilter, setUnitFilter] = useState([]);
  const [unitSettings, setUnitSettings] = useState({ unit: "", unit_status: "available", unit_type: "", unit_bedroom: "" });
  const [unitTypeList, setUnitTypeList] = useState([]);
  const [unitBedroomList, setUnitBedroomList] = useState([]);
  const [unitFilterLoading, setUnitFilterLoading] = useState(false);
  const [unitStatusList] = useState([
    { label: "All Status", value: "" },
    { label: "Available", value: "available" },
  ]);
  const [isFloorPlan, setIsFloorPlan] = useState(false);
  const [isFloorPlanFilter, setIsFloorPlanFilter] = useState(false);
  const [floorPlanFilter, setFloorPlanFilter] = useState([]);
  const [floorPlanSettings, setFloorPlanSettings] = useState({ unit_type: "", unit_bedroom: "" });
  const [floorPlanFilterLoading, setFloorPlanFilterLoading] = useState(false);
  const [unitFilterCount, setUnitFilterCount] = useState(0);
  const [floorPlanFilterCount, setFloorPlanFilterCount] = useState(0);
  const [contactDetails, setContactDetails] = useState([]);
  const [isContact, setIsContact] = useState(false);

  // GET FUNCTION ---------------------------------

  const getData = async () => {
    try {
      const projectRecord = projectData.find((record) => record.project_id === project_id);
      const phaseRecord = projectRecord.phase_record.find((record) => record.phase_id === phase_id);
      const typeRecord = projectRecord.type_record.filter((record) => record.rea_phase_id === phaseRecord.rea_phase_id);
      const unitRecord = projectRecord.unit_record.filter((record) => record.rea_phase_id === phaseRecord.rea_phase_id);

      typeRecord.forEach((record) => {
        var unitRecord = projectRecord.unit_record.filter((unit) => unit.rea_type_id === record.rea_type_id);
        var unitRecordAvailable = projectRecord.unit_record.filter((unit) => unit.rea_type_id === record.rea_type_id && unit.unit_status === "available");
        record.unit = unitRecord ? unitRecord : [];
        record.unit_count = unitRecord.length > 0 ? unitRecord.length : 0;
        record.unit_available = unitRecordAvailable ? unitRecordAvailable : [];
        record.unit_available_count = unitRecordAvailable.length > 0 ? unitRecordAvailable.length : 0;

        if (unitRecord.length > 0) {
          var lowest_unit_price = unitRecord.reduce((minPrice, unit) => Math.min(minPrice, unit.unit_selling_price), unitRecord[0].unit_selling_price);
          var highest_unit_price = unitRecord.reduce((maxPrice, unit) => Math.max(maxPrice, unit.unit_selling_price), unitRecord[0].unit_selling_price);
          record.price = lowest_unit_price === highest_unit_price ? setCurrency(lowest_unit_price) : setCurrency(lowest_unit_price) + " - " + setCurrency(highest_unit_price);
        } else {
          record.price = setCurrency(0);
        }
      });

      var maxUnitBedroom = unitRecord.reduce((bedroom, unit) => Math.max(bedroom, Number(unit.unit_total_bedroom)), unitRecord[0].unit_total_bedroom);
      var bedroomOpts = setBedroomArray(maxUnitBedroom);
      bedroomOpts.unshift({
        label: "All Bedroom",
        value: "",
      });

      var unitTypeOpts = typeRecord.map((record) => ({
        label: `${record.unit_title} ${record.unit_type}`,
        value: record.rea_type_id,
      }));

      unitTypeOpts.unshift({
        label: "All Unit Type",
        value: "",
      });

      setUnitBedroomList(bedroomOpts);
      setUnitTypeList(unitTypeOpts);
      setProjectInfo(projectRecord);
      setPhaseData(phaseRecord);
      setTypeList(typeRecord);
      setUnitList(unitRecord);
      getSalesChart(phaseRecord.rea_phase_id);
      getBedroomList(phaseRecord.rea_phase_id);
      getAmenities(phaseRecord.rea_phase_id);
      getFiles(phaseRecord.rea_phase_id);
      getContact(phaseRecord.rea_phase_id);
      getFacilities(phaseRecord.rea_phase_id);
      getModules();
      setLocation({
        latitude: Number(phaseRecord.phase_lat),
        longitude: Number(phaseRecord.phase_lon),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getBedroomList = async (phaseId) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/ws_rea_prod.php", {
        params: { task: "GetBedroomList", utoken: allProjectData.user_ac_token, ctoken: allProjectData.secret_key, rea_phase_id: phaseId },
      });

      const data = response.data;
      if (data.status === 0) {
        setBedroomList(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getModules = async () => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_modules",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0 && !Array.isArray(data.record)) {
        setModules(setModulesValues(data.record));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFiles = async (phaseId) => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_files",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0 && !Array.isArray(data.record)) {
        if (data.record[phaseId]) {
          setFiles(data.record[phaseId]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getContact = async (phaseId) => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_contact",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0 && !Array.isArray(data.record)) {
        if (data.record[phaseId]) {
          setContactDetails(data.record[phaseId]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFacilities = async (phaseId) => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_facilities",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0 && !Array.isArray(data.record)) {
        const recordData = data.record[phaseId];
        if (recordData) {
          setFacilitiesList(recordData);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChart = async (phaseId) => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_map",
        },
      });
      const data = response.data;
      if (data.status === 0) {
        const recordData = data.record[phaseId];
        if (recordData) {
          if (!recordData.site_plan) {
            recordData.site_plan = "";
          }
          setSalesChartData(recordData);
          setIsSalesChart(true);
        } else {
          setSalesChartData({});
          setIsSalesChart(false);
        }
      } else {
        setSalesChartData({});
        setIsSalesChart(false);
      }
    } catch (error) {
      setSalesChartData({});
      setIsSalesChart(false);
    }
  };

  const getAmenities = async (phaseId) => {
    try {
      const response = await axios(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_amenities",
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        const recordData = data.record[phaseId];
        if (recordData) {
          setAmenitiesData(recordData);
          setIsAmenities(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDirectionCallback = useCallback((res) => {
    if (res !== null) {
      setTravelResponse(res);
      setDestinationResponse(false);
    }
  }, []);

  // SCHEMA ------------------------------------------------------

  const registerProspectSchema = yup.object().shape({
    name: yup.string().required(t("CustomerKitPhaseDetails_name_is_required")),
    contact: yup.string().required(t("CustomerKitPhaseDetails_contact_number_is_required")),
    email: yup.string().required(t("CustomerKitPhaseDetails_email_address_is_required")).email(),
  });

  // OPEN FUNCTION --------------------------------

  const openSalesChart = () => {
    if (isSalesChart) {
      navigate(`/rea-sales-kit/${key}/${project_id}/${phase_id}/saleschart`);
    } else {
      navigate(`/sales-chart/unit/0/0/${phaseData.rea_project_id}/${phaseData.rea_phase_id}`);
    }
  };

  const openVirtualTour = (record) => {
    window.open(record, "_blank");
  };

  const openAmenities = (event) => {
    if (event) {
      setDestinationResponse(false);
      setTravelResponse(null);
      setDestination(null);
      setAmenities(event);
    }
  };

  const openUnitQuickLink = () => {
    if (modules?.unit_summary_mode === "bedroom") {
      var unitRecord = unitList.reduce((acc, obj) => {
        const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);

      setUnitFilter(unitRecord);
    } else {
      var unitRecord2 = unitList.reduce((acc, obj) => {
        const sectionTitle = `${obj.unit_title} ${obj.unit_type}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);
      setUnitFilter(unitRecord2);
    }

    setUnitFilterCount(unitList.length);
    setUnitSettings({ unit: "", unit_status: "available", unit_type: "", unit_bedroom: "" });
    setIsUnit(true);
  };

  const openUnitUnitType = (data) => {
    let unitRecord = unitList;
    unitRecord = unitRecord.filter((record) => record.unit_status === "available");
    unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);

    var unitRecord2 = unitRecord.reduce((acc, obj) => {
      const sectionTitle = `${obj.unit_title} ${obj.unit_type}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    setUnitFilterCount(unitRecord.length);
    setUnitFilter(unitRecord2);
    setUnitSettings({ unit: "", unit_status: "available", unit_type: data.rea_type_id, unit_bedroom: "" });
    setIsUnit(true);
  };

  const openUnitBedroom = (data) => {
    let unitRecord = unitList;
    unitRecord = unitRecord.filter((record) => record.unit_status === "available");
    unitRecord = unitRecord.filter((record) => Number(record.unit_total_bedroom) === Number(data.bedroom));

    var unitRecord2 = unitRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    setUnitFilterCount(unitRecord.length);
    setUnitFilter(unitRecord2);
    setUnitSettings({ unit: "", unit_status: "available", unit_type: "", unit_bedroom: Number(data.bedroom) });
    setIsUnit(true);
  };

  const openFloorPlanQuickLink = () => {
    var unitRecord = typeList.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilterCount(typeList.length);
    setFloorPlanFilter(unitRecord);
    setFloorPlanSettings({ unit_type: "", unit_bedroom: "" });
    setIsFloorPlan(true);
  };

  const openUnitFloorPlan = (data) => {
    let unitRecord = unitList;
    if (modules.unit_summary_mode === "bedroom") {
      unitRecord = unitRecord.filter((record) => record.unit_status === "available");
      unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);
      var unitRecord2 = unitRecord.reduce((acc, obj) => {
        const sectionTitle = `Bedroom ${obj.unit_total_bedroom}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);

      setUnitFilterCount(unitRecord.length);
      setUnitFilter(unitRecord2);
    } else {
      unitRecord = unitRecord.filter((record) => record.unit_status === "available");
      unitRecord = unitRecord.filter((record) => record.rea_type_id === data.rea_type_id);

      var unitRecord3 = unitRecord.reduce((acc, obj) => {
        const sectionTitle = `${obj.unit_title} ${obj.unit_type}`;
        const existingSection = acc.find((section) => section.section === sectionTitle);

        if (existingSection) {
          existingSection.unit.push(obj);
        } else {
          acc.push({
            section: sectionTitle,
            unit: [obj],
          });
        }

        return acc;
      }, []);
      setUnitFilterCount(unitRecord.length);
      setUnitFilter(unitRecord3);
    }

    setUnitSettings({ unit: "", unit_status: "available", unit_type: data.rea_type_id, unit_bedroom: "" });
    setIsUnit(true);
  };

  const openFloorPlanBedroom = (data) => {
    let typRecord = typeList;
    typRecord = typRecord.filter((record) => Number(record.total_bedroom) === Number(data.bedroom));

    var unitRecord = typRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilterCount(typRecord.length);
    setFloorPlanFilter(unitRecord);
    setFloorPlanSettings({ unit_type: "", unit_bedroom: Number(data.bedroom) });
    setIsFloorPlan(true);
  };

  const openUnit = (record) => {
    navigate("/sales-chart/unit-details/0/0/" + record.rea_unit_id);
  };

  const openFloorPlanImage = (record) => {
    const data = {
      title: record.unit_title + " " + record.unit_type,
      url: `https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${record.floor_plan}`,
      filepath: `../assets/rea_booking/${company_id}/project/${record.floor_plan}`,
      mode: "image",
    };

    setDocumentData(data);
    setIsDocument(true);
  };

  const openFiles = (record) => {
    if (record.mode === "new_tab") {
      window.open(record.url, "_blank");
    } else {
      const document = record.filename.split(".");
      const documenttype = document[document.length - 1];

      const data = {
        title: record.title,
        url: `https://app.outperformhq.io/cloud/assets/rea_booking/pdfjs/web/viewer.html?${company_id}/files/${record.filename}`,
        filepath: record.url.replace("https://app.outperformhq.io/cloud/", "../"),
        mode: documenttype === "pdf" ? "pdf" : "image",
      };
      setDocumentData(data);
      setIsDocument(true);
    }
  };

  // SET FUNCTION ---------------------------------

  const setPhaseImage = (image) => {
    if (image) {
      return `https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${image}`;
    } else {
      return `https://app.outperformhq.io/cloud/assets/rea_booking/generic.jpg`;
    }
  };

  const setArea = (input) => {
    if (input) {
      return input.toLowerCase().replace("sqft", "").trim() + " sqft";
    }

    return "-";
  };

  const setCalculateDistance = (currentLat, currentLon, amenityLat, amenityLon) => {
    const R = 6371;
    const dLat = (amenityLat - currentLat) * (Math.PI / 180);
    const dLon = (amenityLon - currentLon) * (Math.PI / 180);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(currentLat * (Math.PI / 180)) * Math.cos(amenityLat * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000;
    return distance;
  };

  const setFormatDistance = (distance) => {
    if (distance < 1000) {
      return `${distance.toFixed()}m`;
    } else {
      return `${(distance / 1000).toFixed(2)}km`;
    }
  };

  const setBedroomArray = (num) => {
    let array = [];
    for (let i = 1; i <= num; i++) {
      array.push({ label: `Bedroom ${i}`, value: i });
    }
    return array;
  };

  const setFilterCount = (values) => {
    let count = 0;

    if (values.unit) {
      count = count + 1;
    }

    if (values.unit_status) {
      count = count + 1;
    }

    if (values.unit_type) {
      count = count + 1;
    }

    if (values.unit_bedroom) {
      count = count + 1;
    }

    return count;
  };

  const setStatusColor = (status) => {
    if (status === "available") {
      return "success";
    } else if (status === "booked") {
      return "danger";
    } else if (status === "reserved") {
      return "danger";
    } else if (status === "sold") {
      return "danger";
    } else if (status === "unavailable") {
      return "danger";
    } else if (status === "on_hold") {
      return "danger";
    } else {
      return "";
    }
  };

  const handleOpenRegisterModal = () => {
    openRegisterModal();
  };

  const handleCloseRegisterModal = () => {
    closeRegisterModal();
  };

  const getSourceRecord = async () => {
    const sourceRecord = allProjectData.source_record;
    setSourceRecord(sourceRecord);
  };

  const registerClicked = async (values) => {
    try {
      const sourceToken = allProjectData.source_token ? allProjectData.source_token : values.source.value;

      const response = await axios.get("/toolbox/api_addNewDeal.php", {
        params: {
          task: "addNewDeal",
          secret_key: allProjectData.secret_key,
          name: values.name,
          email: values.email,
          contact: values.contact,
          source_token: sourceToken,
        },
      });

      if (response.data.status === 0) {
        handleCloseRegisterModal();
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: t("CustomerKitPhaseDetails_new_deal_successfully_created"),
          timer: 2000,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oppss...",
        text: t("CustomerKitPhaseDetails_failed_to_create_new_deal"),
        timer: 2000,
      });
    }
  };

  // RENDER FUNCTION ---------------------------------------------------

  const RenderUnitType = ({ record, index, label }) => {
    return (
      <Col xxl={4} key={index} className="mb-3">
        <Card className="border-0 shadow-sm h-100">
          <Card.Body>
            <h6 className="op-text-medium fw-bold">
              {record.unit_title} {record.unit_type}
            </h6>
            <Stack direction="vertical" gap={2}>
              {label.unit_available && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faHouseLock} size="lg" className="me-2" />
                    </div>
                    Unit Available
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openUnitUnitType(record)}>
                    <span>
                      {record.unit_available_count}/{record.unit_count}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}

              {label.builtup_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.1 229.4l-43.4-33.6v-38.5c0-2.5-2-4.9-4.9-4.9h-13.5c-2.5 0-4.9 2-4.9 4.9v20.5L257 122.9c-4.1-3.3-10.2-3.3-14.3 0L105.1 229.4c-2.5 2-4.1 4.5-4.5 7.8s.4 6.1 2.5 8.6s4.5 4.1 7.8 4.5s6.1-.4 8.6-2.5l11.5-9V369c0 6.1 4.9 11.1 11.1 11.1h216.3c6.1 0 11.1-4.9 11.1-11.1V238.8l11.5 9c2.5 1.6 5.3 2.5 8.6 2s6.1-2 7.8-4.5c2-2.5 2.9-5.3 2.5-8.6c-.4-2.9-2-5.3-4.5-7.4zM278.3 358H221.8V268.3h56.5V358zm68.8 0H300.4V257.2c0-6.1-4.9-11.1-11.1-11.1H210.7c-6.1 0-11.1 4.9-11.1 11.1V358H153V221.6l.4-.4L250.1 147l.8 .4 96.3 74.1V358zM140.3 116.3c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-15.2-15.2h217.9l-14.3 15.2c-4.5 4.5-4.5 11.5 0 15.6c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l15.2 15.2H141.1l15.2-15.2c4.5-4.5 4.5-11.5 0-15.6c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6l33.6 34zM81.7 319.9l-15.2 15.2V165.9L81.7 181c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6c4.5 4.5 11.5 4.5 15.6 0l15.2-15.2v168.8l-15.2-15.2c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l34 34c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6s-11.5-4.1-15.6 .4z"
                        />
                      </svg>
                    </div>
                    Builtup Area
                  </div>
                  <span>{setArea(record.builtup_area)}</span>
                </div>
              )}

              {label.land_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.9 340.4l-37.7-37.7c-4.9-4.9-12.7-4.9-17.6 0s-4.9 12.7 0 17.6l16.4 16.4H73.1V53.2l16.4 16.4c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7c4.9-4.9 4.9-12.7 0-17.6L69.8 14.3c-4.9-4.9-12.7-4.9-17.6 0L14.5 52c-4.9 4.9-4.9 12.7 0 17.6s12.7 4.9 17.6 0l16.4-16.4v296.1c0 7 5.7 12.7 12.7 12.7H356.6l-16.4 16.4c-4.9 4.9-4.9 12.7 0 17.6c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7l37.7-37.7c4.9-5.3 4.9-13.1 0-18zM152.2 50h12.7c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S145.2 50 152.2 50zm136 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S281.2 50 288.1 50zm-72.1 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S209.1 50 216.1 50zm143.8 0c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V37.3c0-7-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S353.3 50 359.8 50zm25.4 123.3c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V173.3zm0-72.1c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V101.2zM372.5 232.7c-7 0-12.7 5.7-12.7 12.7V258c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7v-12.7c0-7.4-5.7-12.7-12.7-12.7z"
                        />
                      </svg>
                    </div>
                    Land Area
                  </div>
                  <span> {setArea(record.land_area)} </span>
                </div>
              )}

              {label.bedroom && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faBedFront} size="lg" className="me-2" />
                    </div>
                    Bedroom
                  </div>
                  <span>{record.total_bedroom}</span>
                </div>
              )}

              {label.bathroom && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faBath} size="lg" className="me-2" />
                    </div>
                    Bathroom
                  </div>
                  <span>{record.total_bathroom}</span>
                </div>
              )}

              {label.price && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M4 266.6C-1.9 260-1.2 249.8 5.4 244L277.4 4c6.1-5.3 15.2-5.3 21.2 0L512.5 192.8c-5.4-.5-11-.8-16.5-.8c-10.2 0-20.1 .9-29.8 2.5L288 37.3 96 206.7 96 432c0 26.5 21.5 48 48 48l64 0 0-160c0-17.7 14.3-32 32-32l96 0c1.1 0 2.1 .1 3.1 .1c-5.1 10.1-9.4 20.7-12.5 31.9L240 320l0 160 96 0 0-38.6c13.1 28.4 33.5 52.8 58.8 70.6L144 512c-44.2 0-80-35.8-80-80l0-197L26.6 268C20 273.9 9.9 273.2 4 266.6zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Price
                  </div>
                  <span>{record.price}</span>
                </div>
              )}

              {label.reservation_fee && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M0 80C0 53.5 21.5 32 48 32.1l149.4 0c17 0 33.3 6.7 45.3 18.7L407.6 215.7c-9.4 5.5-18.2 11.8-26.4 18.8L220.1 73.4c-6-6-14.1-9.4-22.6-9.4L48 64c-8.8 0-16 7.2-16 16l0 149.5c-.1 8.5 3.3 16.6 9.3 22.6c-7.5 7.5-15.1 15.1-22.6 22.6C6.7 262.8 0 246.5 0 229.5L0 80zM18.7 274.8c7.5-7.6 15.1-15.1 22.6-22.6l176 176c12.5 12.5 32.8 12.5 45.3 0L320 370.7c.2 13.8 2 27.2 5.2 40l-40 40c-25 25-65.5 25-90.5 0c-58.7-58.7-117.3-117.3-176-176zM88 144c0-13.3 10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Reservation Fee
                  </div>
                  <span>{setCurrency(record.reservation_fee)}</span>
                </div>
              )}
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const RenderBedroom = ({ record, index, label }) => {
    return (
      <Col xxl={4} key={index} className="mb-3">
        <Card className="border-0 shadow-sm h-100">
          <Card.Body>
            <h6 className="op-text-medium fw-bold">{record.bedroom} BEDROOM</h6>
            <Stack direction="vertical" gap={2}>
              {label.unit_available && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <FontAwesomeIcon icon={faHouseLock} size="lg" className="me-2" />
                    </div>
                    Unit Available
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openUnitBedroom(record)}>
                    <span>
                      {record.total_unit}/{record.total_unit + record.total_unit_sold}
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}

              {label.builtup_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.1 229.4l-43.4-33.6v-38.5c0-2.5-2-4.9-4.9-4.9h-13.5c-2.5 0-4.9 2-4.9 4.9v20.5L257 122.9c-4.1-3.3-10.2-3.3-14.3 0L105.1 229.4c-2.5 2-4.1 4.5-4.5 7.8s.4 6.1 2.5 8.6s4.5 4.1 7.8 4.5s6.1-.4 8.6-2.5l11.5-9V369c0 6.1 4.9 11.1 11.1 11.1h216.3c6.1 0 11.1-4.9 11.1-11.1V238.8l11.5 9c2.5 1.6 5.3 2.5 8.6 2s6.1-2 7.8-4.5c2-2.5 2.9-5.3 2.5-8.6c-.4-2.9-2-5.3-4.5-7.4zM278.3 358H221.8V268.3h56.5V358zm68.8 0H300.4V257.2c0-6.1-4.9-11.1-11.1-11.1H210.7c-6.1 0-11.1 4.9-11.1 11.1V358H153V221.6l.4-.4L250.1 147l.8 .4 96.3 74.1V358zM140.3 116.3c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-15.2-15.2h217.9l-14.3 15.2c-4.5 4.5-4.5 11.5 0 15.6c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l15.2 15.2H141.1l15.2-15.2c4.5-4.5 4.5-11.5 0-15.6c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6l33.6 34zM81.7 319.9l-15.2 15.2V165.9L81.7 181c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3c4.5-4.5 4.5-11.5 0-15.6l-34-34c-4.5-4.5-11.5-4.5-15.6 0l-34 34c-4.5 4.5-4.5 11.5 0 15.6c4.5 4.5 11.5 4.5 15.6 0l15.2-15.2v168.8l-15.2-15.2c-4.5-4.5-11.5-4.5-15.6 0c-4.5 4.5-4.5 11.5 0 15.6l34 34c2 2 4.9 3.3 7.8 3.3s5.7-1.2 7.8-3.3l34-34c4.5-4.5 4.5-11.5 0-15.6s-11.5-4.1-15.6 .4z"
                        />
                      </svg>
                    </div>
                    Builtup Area
                  </div>
                  <span> {record.min_builtup_area === 0 ? "-" : record.min_builtup_area === record.max_builtup_area ? `${record.max_builtup_area} sqft` : `${record.min_builtup_area} sqft - ${record.max_builtup_area} sqft`}</span>
                </div>
              )}

              {label.land_area && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 410 512" className="me-2">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M395.9 340.4l-37.7-37.7c-4.9-4.9-12.7-4.9-17.6 0s-4.9 12.7 0 17.6l16.4 16.4H73.1V53.2l16.4 16.4c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7c4.9-4.9 4.9-12.7 0-17.6L69.8 14.3c-4.9-4.9-12.7-4.9-17.6 0L14.5 52c-4.9 4.9-4.9 12.7 0 17.6s12.7 4.9 17.6 0l16.4-16.4v296.1c0 7 5.7 12.7 12.7 12.7H356.6l-16.4 16.4c-4.9 4.9-4.9 12.7 0 17.6c2.5 2.5 5.7 3.7 9 3.7s6.6-1.2 9-3.7l37.7-37.7c4.9-5.3 4.9-13.1 0-18zM152.2 50h12.7c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S145.2 50 152.2 50zm136 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S281.2 50 288.1 50zm-72.1 0h20.5c7 0 12.7-5.7 12.7-12.7s-5.7-12.7-12.7-12.7h-20.5c-7 0-12.7 5.7-12.7 12.7S209.1 50 216.1 50zm143.8 0c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V37.3c0-7-5.7-12.7-12.7-12.7h-12.7c-7 0-12.7 5.7-12.7 12.7S353.3 50 359.8 50zm25.4 123.3c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V173.3zm0-72.1c0-7-5.7-12.7-12.7-12.7s-12.7 5.7-12.7 12.7v20.5c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7V101.2zM372.5 232.7c-7 0-12.7 5.7-12.7 12.7V258c0 7 5.7 12.7 12.7 12.7s12.7-5.7 12.7-12.7v-12.7c0-7.4-5.7-12.7-12.7-12.7z"
                        />
                      </svg>
                    </div>
                    Land Area
                  </div>
                  <span> {record.min_land_area === 0 ? "-" : record.min_land_area === record.max_land_area ? `${record.max_land_area} sqft` : `${record.min_land_area} sqft - ${record.max_land_area} sqft`}</span>
                </div>
              )}

              {label.price && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 640 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M4 266.6C-1.9 260-1.2 249.8 5.4 244L277.4 4c6.1-5.3 15.2-5.3 21.2 0L512.5 192.8c-5.4-.5-11-.8-16.5-.8c-10.2 0-20.1 .9-29.8 2.5L288 37.3 96 206.7 96 432c0 26.5 21.5 48 48 48l64 0 0-160c0-17.7 14.3-32 32-32l96 0c1.1 0 2.1 .1 3.1 .1c-5.1 10.1-9.4 20.7-12.5 31.9L240 320l0 160 96 0 0-38.6c13.1 28.4 33.5 52.8 58.8 70.6L144 512c-44.2 0-80-35.8-80-80l0-197L26.6 268C20 273.9 9.9 273.2 4 266.6zM352 368c0-79.5 64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144s-144-64.5-144-144zm32 0c0 61.9 50.1 112 112 112s112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112zm56.1-27c-1.2-18.8 10-30.9 21.8-37.2c5.7-3.1 12-5.1 18-6.3l0-9.5c0-8.8 7.2-16 16-16s16 7.2 16 16l0 9.4c6.3 .9 12.3 2.3 17.9 3.7c8.6 2.1 13.8 10.8 11.7 19.4s-10.8 13.8-19.4 11.7c-9.3-2.3-18.2-4-26.4-4.1c-6.2-.1-13.6 1.3-18.7 4c-2.4 1.3-3.6 2.5-4.2 3.4c-.4 .7-.9 1.6-.8 3.6c0 .5 0 1.9 4.8 4.5c5.6 3 13.5 5.2 23.9 8.2l.2 0c9.3 2.6 21.1 6 30.5 11.5c10.2 6 20 16.1 20.5 32.3c.5 18.2-9.7 30.4-21.7 36.9c-5.8 3.1-12.1 5.1-18.3 6.2l0 10.8c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-11.4c-8.6-1.7-16.7-4.3-23.7-6.6c-1.7-.6-3.4-1.1-5-1.6c-8.4-2.6-13.1-11.6-10.5-20s11.6-13.1 20-10.5c2 .6 3.9 1.2 5.8 1.8c11.5 3.6 20.4 6.5 29.9 6.8c6.7 .2 13.8-1.1 18.5-3.6c2.2-1.2 3.4-2.4 4-3.3c.5-.8 1.1-2.1 1-4.5c0-1.4-.2-3-4.7-5.6c-5.3-3.1-12.9-5.4-23.2-8.3c-.6-.2-1.2-.3-1.8-.5c-8.9-2.5-19.8-5.6-28.6-10.3c-9.9-5.3-20.6-14.7-21.6-30.7z"
                        />
                      </svg>
                    </div>
                    Price
                  </div>
                  <span> {record.min_selling_price === record.max_selling_price ? setCurrency(record.max_selling_price) : `${setCurrency(record.min_selling_price)} - ${setCurrency(record.max_selling_price)}`}</span>
                </div>
              )}

              {label.floor_plan && (
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center text-muted">
                    <div className="text-center" style={{ width: 30 }}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 410 512">
                        <path
                          fill="rgba(33, 37, 41, 0.75)"
                          d="M379.4 25.6H33.3c-3.4 0-6.1 2.8-6.1 6.1v299.7 45.9c0 .1 0 .2 0 .3s0 .2 0 .3c0 3.4 2.8 6.1 6.1 6.1h346.1c3.4 0 6.1-2.8 6.1-6.1V230.6 100.7 31.7c0-3.4-2.8-6.1-6.1-6.1zM334.9 371.7v-2c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v2H220.9V134.5c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v190.8h-25.2c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h25.2v34.1H39.5v-34.1h96.1c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1H39.5V37.9H208.6V82c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1V37.9h152.3v56.7h-12.6c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h12.6v117.6H284.2V106.8h27.9c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1h-34c-3.4 0-6.1 2.8-6.1 6.1v129.9c0 3.4 2.8 6.1 6.1 6.1h44.5v2c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-2h38.4v135H334.9zm-6.1-37.3c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V326c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V297c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1z"
                        />
                      </svg>
                    </div>
                    Floor Plan
                  </div>
                  <Button variant="link" className="text-dark text-decoration-none p-0 m-0 cursor-pointer" onClick={() => openFloorPlanBedroom(record)}>
                    <span>{record.floor_plan}</span>
                    <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                  </Button>
                </div>
              )}
            </Stack>
          </Card.Body>
        </Card>
      </Col>
    );
  };

  const RenderImageUnavailable = () => (
    <div className="position-relative w-100 d-flex align-items-center justify-content-center" style={{ height: 300 }}>
      <FontAwesomeIcon icon={faHouse} size="10x" style={{ opacity: 0.05 }} />
      <h6 style={{ position: "absolute" }}>Image Not Available</h6>
    </div>
  );

  const RenderShareToggle = forwardRef(({ children, onClick }, ref) => (
    <Button
      variant="link"
      className="text-dark text-decoration-none"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Button>
  ));

  const RenderVirtualTour = forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

  // SEND FUNCTION ------------------------------------

  const sendShareWhatsapp = (record) => {
    const text = `Please find the file of project ${phaseData.phase_name} listed below for your reference ${record.title}: \n${record.url}`;
    const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
    window.open(whatsapp, "_blank");
  };

  const sendDownload = async (filename, filepath) => {
    try {
      const response = await axios.get(`https://app.outperformhq.io/cloud/php/api_get_file_content.php`, {
        params: {
          filepath: filepath,
        },
      });

      const data = await response.data;
      const base64 = `data:${data.mime_type};base64,${data.content}`;
      triggerBase64Download(base64, filename);
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ----------------------------

  const onchangeDestination = (record) => {
    setDestination({ latitude: record.geometry.location.lat, longitude: record.geometry.location.lng });
    setDestinationResponse(true);
  };

  const onchangeUnitFilter = (values) => {
    setUnitSettings(values);
    setUnitFilterLoading(true);

    let unitRecord = unitList;

    if (values.unit !== "") {
      unitRecord = unitRecord.filter((record) => record.unit_number.toLowerCase().includes(values.unit.toLowerCase()));
    }

    if (values.unit_status !== "") {
      unitRecord = unitRecord.filter((record) => record.unit_status === values.unit_status);
    }

    if (values.unit_type !== "") {
      unitRecord = unitRecord.filter((record) => record.rea_type_id === values.unit_type);
    }

    if (values.unit_bedroom !== "") {
      unitRecord = unitRecord.filter((record) => Number(record.unit_total_bedroom) === Number(values.unit_bedroom));
    }

    setUnitFilterCount(unitRecord.length);
    const unitRecordGrouped = unitRecord.reduce((acc, obj) => {
      const sectionTitle = modules.unit_summary_mode === "bedroom" ? `Bedroom ${obj.unit_total_bedroom}` : `${obj.unit_title} ${obj.unit_type}`;

      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit: [obj],
        });
      }

      return acc;
    }, []);

    console.log(values, unitRecordGrouped);
    setUnitFilter(unitRecordGrouped);
    setIsUnitFilter(false);

    setTimeout(() => {
      setUnitFilterLoading(false);
    }, 1);
  };

  const onchangeFloorPlanFilter = (values) => {
    setFloorPlanSettings(values);
    setFloorPlanFilterLoading(true);

    let typRecord = typeList;

    if (values.unit_type !== "") {
      typRecord = typRecord.filter((record) => record.rea_type_id === values.unit_type);
    }

    if (values.unit_bedroom !== "") {
      typRecord = typRecord.filter((record) => Number(record.total_bedroom) === Number(values.unit_bedroom));
    }

    setFloorPlanFilterCount(typRecord.length);
    const unitRecordGrouped = typRecord.reduce((acc, obj) => {
      const sectionTitle = `Bedroom ${obj.total_bedroom}`;
      const existingSection = acc.find((section) => section.section === sectionTitle);

      if (existingSection) {
        existingSection.unit_type.push(obj);
      } else {
        acc.push({
          section: sectionTitle,
          unit_type: [obj],
        });
      }

      return acc;
    }, []);

    setFloorPlanFilter(unitRecordGrouped);
    setIsFloorPlanFilter(false);

    setTimeout(() => {
      setFloorPlanFilterLoading(false);
    }, 1);
  };

  // USEEFFECT FUNCTION ---------------------------

  useEffect(() => {
    getData();
    getSourceRecord();
    setLoading(false);
  }, []);

  return (
    <Container className="saleskit-phase-details">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Container fluild className="position-relative section-gallery">
            <Swiper
              navigation={false}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="swiper-main"
              style={{
                "--swiper-navigation-color": "transparent",
                "--swiper-pagination-color": "transparent",
              }}
            >
              {phaseData.phase_image.split(",").map((data, i) => (
                <SwiperSlide key={i}>
                  <img className="cursor-pointer" src={setPhaseImage(data)} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="saleskit-thumbnail-wrapper">
              <div className="bg-light shadow rounded saleskit-thumbnail-container">
                <Swiper
                  onSwiper={(swiper) => setThumbsSwiper(swiper)}
                  spaceBetween={window.innerWidth <= 480 ? 6 : 10}
                  slidesPerView={window.innerWidth <= 480 ? 4 : 8}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="swiper-thumbnail"
                  style={{ scrollbarWidth: "none" }}
                >
                  {phaseData.phase_image.split(",").map((data, i) => (
                    <SwiperSlide key={i} style={{ cursor: "pointer" }}>
                      <img src={setPhaseImage(data)} alt="" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </Container>

          <Container className="section-content">
            <Row>
              <Col xxl={12} className="mb-5">
                <Row>
                  <Col xxl={6} className="d-flex align-items-center">
                    <div className="mb-3">
                      <div className={`op-title h5`}>Development Overview</div>
                      <a className="text-dark text-decoration-none cursor-pointer" onClick={setIsProjectDetails}>
                        More Phase Details <FontAwesomeIcon icon={faChevronRight} size="sm" />
                      </a>
                    </div>
                  </Col>
                  <Col xxl={6}>
                    <Row className="g-2 w-100">
                      <Col className="h-100" xxl={4}>
                        <div className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center card" style={{ height: "10rem" }}>
                          <div className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faHouseBuilding} size="4x" className="text-center" />
                          </div>
                          <p className="mb-0 text-center mt-3">Type</p>
                          <h6 className="text-center">{phaseData.phase_type}</h6>
                        </div>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <div className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center card" style={{ height: "10rem" }}>
                          <div className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faMapLocationDot} size="4x" className="text-center" />
                          </div>
                          <p className="mb-0 text-center mt-3">Location</p>
                          <h6 className="text-center">{phaseData.phase_location}</h6>
                        </div>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <div className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center card" style={{ height: "10rem" }}>
                          <div className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faKeySkeleton} size="4x" className="text-center" />
                          </div>
                          <p className="mb-0 text-center mt-3">Ready By</p>
                          <h6 className="text-center">{phaseData.completion_date !== "0000-00-00 00:00:00" ? moment(new Date(phaseData.completion_date)).format("MMMM YYYY") : "-"}</h6>
                        </div>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <Card className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center" style={{ height: "10rem" }}>
                          <div>
                            <div className="d-flex justify-content-center">
                              <FontAwesomeIcon icon={faFileCertificate} size="4x" className="text-center" />
                            </div>
                            <p className="mb-0 text-center mt-3">Tenure</p>
                            <h6 className="text-center">{projectInfo.tenure || "-"}</h6>
                          </div>
                        </Card>
                      </Col>
                      <Col className="h-100" xxl={4}>
                        <div className="p-3 border-0 shadow-sm d-flex justify-content-center align-items-center card" style={{ height: "10rem" }}>
                          <div className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faDoorOpen} size="4x" className="text-center" />
                          </div>
                          <p className="mb-0 text-center mt-3">Total Units</p>
                          <h6 className="text-center">{unitList.length}</h6>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {(modules?.QL_sales_chart?.customer || modules?.QL_virtual_tour?.customer || modules?.QL_units?.customer || modules?.QL_floor_plans?.customer || modules?.QL_files?.customer || modules?.QL_contacts?.customer) && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-3">Quick Links</div>
                  <Row>
                    {modules?.QL_sales_chart?.customer && isSalesChart && (
                      <Col xxl={3} className="mb-2">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={() => openSalesChart()}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faMap} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Sales Chart</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {modules?.QL_virtual_tour?.customer && phaseData["3d_walkthrough"] && (
                      <Col xxl={3} className="mb-2">
                        <Dropdown>
                          <Dropdown.Toggle as={RenderVirtualTour} style={{ zIndex: 1 }}>
                            <Card className="shadow-sm h-100 cursor-pointer">
                              <Card.Body className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <FontAwesomeIcon icon={faPersonBooth} className="me-2" size="lg" />
                                  <p className="op-text-bigger mb-0">Virtual Tour</p>
                                </div>
                                <FontAwesomeIcon icon={faChevronRight} size="lg" />
                              </Card.Body>
                            </Card>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align={"end"}>
                            {phaseData["3d_walkthrough"].split(",").map((record, index) => (
                              <Dropdown.Item onClick={() => openVirtualTour(record)}>Virtual Tour {index + 1}</Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    )}

                    {modules?.QL_units?.customer && (
                      <Col xxl={3} className="mb-2">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={openUnitQuickLink}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faDoorOpen} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Units</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {modules?.QL_floor_plans?.customer && (
                      <Col xxl={3} className="mb-2">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={openFloorPlanQuickLink}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" className="me-2" viewBox="0 0 410 512">
                                <path
                                  fill="#000"
                                  d="M379.4 25.6H33.3c-3.4 0-6.1 2.8-6.1 6.1v299.7 45.9c0 .1 0 .2 0 .3s0 .2 0 .3c0 3.4 2.8 6.1 6.1 6.1h346.1c3.4 0 6.1-2.8 6.1-6.1V230.6 100.7 31.7c0-3.4-2.8-6.1-6.1-6.1zM334.9 371.7v-2c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v2H220.9V134.5c0-3.4-2.8-6.1-6.1-6.1s-6.1 2.8-6.1 6.1v190.8h-25.2c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h25.2v34.1H39.5v-34.1h96.1c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1H39.5V37.9H208.6V82c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1V37.9h152.3v56.7h-12.6c-3.4 0-6.1 2.8-6.1 6.1s2.8 6.1 6.1 6.1h12.6v117.6H284.2V106.8h27.9c3.4 0 6.1-2.8 6.1-6.1s-2.8-6.1-6.1-6.1h-34c-3.4 0-6.1 2.8-6.1 6.1v129.9c0 3.4 2.8 6.1 6.1 6.1h44.5v2c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-2h38.4v135H334.9zm-6.1-37.3c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V326c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1V297c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1zm0-29.1c-3.4 0-6.1 2.8-6.1 6.1v14.5c0 3.4 2.8 6.1 6.1 6.1s6.1-2.8 6.1-6.1v-14.5c0-3.4-2.8-6.1-6.1-6.1z"
                                />
                              </svg>
                              <p className="op-text-bigger mb-0">Floor Plans</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {modules?.QL_files?.customer && files && (
                      <Col xxl={3} className="mb-2">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={setIsFile}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faFile} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Files</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}

                    {modules?.QL_contacts?.customer && contactDetails.length > 0 && (
                      <Col xxl={3} className="mb-2">
                        <Card className="shadow-sm h-100 cursor-pointer" onClick={setIsContact}>
                          <Card.Body className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <FontAwesomeIcon icon={faPhone} className="me-2" size="lg" />
                              <p className="op-text-bigger mb-0">Contacts</p>
                            </div>
                            <FontAwesomeIcon icon={faChevronRight} size="lg" />
                          </Card.Body>
                        </Card>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}

              {modules?.location?.customer && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-2">
                    Location <br />
                    <span className="op-text-medium text-muted">{phaseData.phase_location}</span>
                  </div>
                  <Row className="g-0">
                    <Col xxl={isAmenities ? 8 : 12}>
                      {location && isLoaded && (
                        <GoogleMap
                          center={{ lat: location.latitude, lng: location.longitude }}
                          zoom={15}
                          mapContainerStyle={{
                            width: "100%",
                            height: "400px",
                          }}
                        >
                          <Marker position={{ lat: location.latitude, lng: location.longitude }} />
                          {Number(amenities) === 0 &&
                            isAmenities &&
                            amenitiesData.education.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: record.geometry.location.lat, lng: record.geometry.location.lng }}
                                options={{
                                  icon: {
                                    url: EducationMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(amenities) === 1 &&
                            isAmenities &&
                            amenitiesData.healthcare.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: record.geometry.location.lat, lng: record.geometry.location.lng }}
                                options={{
                                  icon: {
                                    url: HospitalMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(amenities) === 2 &&
                            isAmenities &&
                            amenitiesData.shopping.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: record.geometry.location.lat, lng: record.geometry.location.lng }}
                                options={{
                                  icon: {
                                    url: ShoppingMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(amenities) === 3 &&
                            isAmenities &&
                            amenitiesData.entertainment.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: record.geometry.location.lat, lng: record.geometry.location.lng }}
                                options={{
                                  icon: {
                                    url: EntertainmentMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {Number(amenities) === 4 &&
                            isAmenities &&
                            amenitiesData.public_transport.map((record, index) => (
                              <Marker
                                key={index}
                                position={{ lat: record.geometry.location.lat, lng: record.geometry.location.lng }}
                                options={{
                                  icon: {
                                    url: PublicTransportMarker,
                                    scaledSize: new window.google.maps.Size(40, 40),
                                  },
                                }}
                              />
                            ))}

                          {travelResponse && isAmenities && <DirectionsRenderer options={{ directions: travelResponse }} />}
                          {destination && destinationResponse && isAmenities && (
                            <DirectionsService
                              options={{
                                destination: { lat: destination.latitude, lng: destination.longitude },
                                origin: { lat: location.latitude, lng: location.longitude },
                                travelMode: "DRIVING",
                              }}
                              callback={getDirectionCallback}
                            />
                          )}
                        </GoogleMap>
                      )}
                    </Col>
                    {isAmenities && (
                      <Col xxl={4}>
                        <div className="amenities-list overflow-auto">
                          <div className="header">Nearby Amenities</div>
                          <Accordion defaultActiveKey="0" flush onSelect={(event) => openAmenities(event)}>
                            {amenitiesData.education && amenitiesData.education.length > 0 && (
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <div style={{ width: 40 }}>
                                    <FontAwesomeIcon icon={faGraduationCap} size="xl" className="me-2" />
                                  </div>
                                  Education
                                </Accordion.Header>
                                <Accordion.Body className="py-0">
                                  <ListGroup>
                                    {amenitiesData.education.map((record, index) => (
                                      <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                        <span>{record.name}</span>
                                        <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.geometry.location.lat, record.geometry.location.lng))}</span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}

                            {amenitiesData.healthcare && amenitiesData.healthcare.length > 0 && (
                              <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                  <div style={{ width: 40 }}>
                                    <FontAwesomeIcon icon={faHouseMedical} size="xl" className="me-2" />
                                  </div>
                                  HealthCare
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup>
                                    {amenitiesData.healthcare.map((record, index) => (
                                      <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                        <span>{record.name}</span>
                                        <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.geometry.location.lat, record.geometry.location.lng))}</span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}

                            {amenitiesData.shopping && amenitiesData.shopping.length > 0 && (
                              <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                  <div style={{ width: 40 }}>
                                    <FontAwesomeIcon icon={faBagShopping} size="xl" className="me-2" />
                                  </div>
                                  Shopping
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup>
                                    {amenitiesData.shopping.map((record, index) => (
                                      <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                        <span>{record.name}</span>
                                        <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.geometry.location.lat, record.geometry.location.lng))}</span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}

                            {amenitiesData.entertainment && amenitiesData.entertainment.length > 0 && (
                              <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                  <div style={{ width: 40 }}>
                                    <FontAwesomeIcon icon={faCameraMovie} size="xl" className="me-2" />
                                  </div>
                                  Entertainment
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup>
                                    {amenitiesData.entertainment.map((record, index) => (
                                      <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                        <span>{record.name}</span>
                                        <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.geometry.location.lat, record.geometry.location.lng))}</span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}

                            {amenitiesData.public_transport && amenitiesData.public_transport.length > 0 && (
                              <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                  <div style={{ width: 40 }}>
                                    <FontAwesomeIcon icon={faTrain} size="xl" className="me-2" />
                                  </div>
                                  Public Transport
                                </Accordion.Header>
                                <Accordion.Body>
                                  <ListGroup>
                                    {amenitiesData.public_transport.map((record, index) => (
                                      <ListGroup.Item className="d-flex justify-content-between cursor-pointer" key={index} onClick={() => onchangeDestination(record)}>
                                        <span>{record.name}</span>
                                        <span>{setFormatDistance(setCalculateDistance(location.latitude, location.longitude, record.geometry.location.lat, record.geometry.location.lng))}</span>
                                      </ListGroup.Item>
                                    ))}
                                  </ListGroup>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}
                          </Accordion>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Col>
              )}

              {modules?.site_plan?.customer && isSalesChart && salesChartData.site_plan && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-4">Site Plan</div>
                  <Card className="shadow-sm border-0 cursor-pointer" onClick={() => openSalesChart()}>
                    <Card.Body>
                      <div className="d-flex justify-content-center">
                        <div className="w-75">
                          <Image src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${salesChartData.site_plan}`} fluid onClick={() => setIsSalesChartImage(!isSalesChartImage)} className="cursor-pointer" />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}

              {modules?.unit_summary?.customer && (
                <Col xxl={12} className="mb-5">
                  <div className="op-title h5 mb-3">Unit Summary</div>
                  {modules?.unit_summary?.unit_summary_mode === "unit_type" ? (
                    <Row>
                      {typeList.map((record, index) => (
                        <RenderUnitType key={index} record={record} label={modules?.unit_summary_label} />
                      ))}
                    </Row>
                  ) : (
                    <Row>
                      {bedroomList.map((record, index) => (
                        <RenderBedroom key={index} record={record} index={index} label={modules?.unit_summary_label} />
                      ))}
                    </Row>
                  )}
                </Col>
              )}
            </Row>
          </Container>

          <div className="section-footer">
            <Container>
              <Row>
                <Col xxl={6}>
                  <div className="op-text-bigger fs-5 fw-bold">{phaseData.phase_name}</div>
                </Col>

                <Col xxl={6} className="section-footer-reverse">
                  <Button variant="" className="shadow-sm border me-2 op-primary-color text-light" onClick={() => handleOpenRegisterModal()}>
                    Register Now
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>

          {/* position-sticky bottom-0 border-top */}
          {/* <Container className="section-footer" style={{ backgroundColor: "#fff" }}>
            <Stack direction="horizontal" gap={5}>
              <div className="w-100 py-4">
                <div className="op-text-bigger fs-5 fw-bold">{phaseData.phase_name}</div>
              </div>
            </Stack>
          </Container> */}

          <Modal show={isUnitFilter} onHide={setIsUnitFilter} size="md">
            <Formik onSubmit={onchangeUnitFilter} initialValues={unitSettings}>
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Unit Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Number</Form.Label>
                      <Form.Control value={values.unit} onChange={handleChange("unit")} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Status</Form.Label>
                      <FormSelect options={unitStatusList} disabled valueDefault={unitStatusList[1]} onChange={(e) => setFieldValue("unit_status", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Type</Form.Label>
                      <FormSelect options={unitTypeList} valueDefault={unitTypeList.find((record) => record.value === values.unit_type)} onChange={(e) => setFieldValue("unit_type", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrooms</Form.Label>
                      <FormSelect options={unitBedroomList} valueDefault={unitBedroomList.find((record) => record.value === values.unit_bedroom)} onChange={(e) => setFieldValue("unit_bedroom", e.value)} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Apply
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isFloorPlanFilter} onHide={setIsFloorPlanFilter} size="md">
            <Formik onSubmit={onchangeFloorPlanFilter} initialValues={floorPlanSettings}>
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Floor Plans Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Type</Form.Label>
                      <FormSelect options={unitTypeList} valueDefault={unitTypeList.find((record) => record.value === values.unit_type)} onChange={(e) => setFieldValue("unit_type", e.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Bedrooms</Form.Label>
                      <FormSelect options={unitBedroomList} valueDefault={unitBedroomList.find((record) => record.value === values.unit_bedroom)} onChange={(e) => setFieldValue("unit_bedroom", e.value)} />
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      Apply
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={showRegisterModal} onHide={handleCloseRegisterModal} size="md">
            <Formik
              validationSchema={registerProspectSchema}
              onSubmit={registerClicked}
              initialValues={{
                name: "",
                email: "",
                contact: "",
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as="h6">Register Now</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "50vh" }}>
                      <Form.Group className="mb-3">
                        <Form.Label>Name </Form.Label>
                        <Form.Control type="text" isInvalid={errors.name && touched.name} onChange={handleChange("name")} value={values.name} />
                        {errors.name && touched.name && <div className="op-error-message">{errors.name}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" isInvalid={errors.email && touched.email} onChange={handleChange("email")} value={values.email} />
                        {errors.email && touched.email && <div className="op-error-message">{errors.email}</div>}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Contact Number </Form.Label>
                        <Form.Control
                          type="text"
                          onKeyDown={(e) => {
                            // Allow: backspace, delete, tab, escape, enter
                            if (e.key === "Backspace" || e.key === "Delete" || e.key === "Tab" || e.key === "Escape" || e.key === "Enter") {
                              return;
                            }
                            // Allow: Ctrl+A/Ctrl+C/Ctrl+V/Ctrl+X
                            if ((e.ctrlKey === true || e.metaKey === true) && (e.key === "a" || e.key === "c" || e.key === "v" || e.key === "x")) {
                              return;
                            }
                            // Allow: numbers 0-9
                            if (/^[0-9]+$/.test(e.key) === false) {
                              e.preventDefault();
                            }
                          }}
                          isInvalid={errors.contact && touched.contact}
                          onChange={handleChange("contact")}
                          value={values.contact}
                        />
                        {errors.contact && touched.contact && <div className="op-error-message">{errors.contact}</div>}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{t("CustomerKitPhaseDetails_source")}</Form.Label>
                        <FormSelect
                          options={sourceRecord.map((record) => ({
                            label: record.ds_title,
                            value: record.ds_token,
                          }))}
                          name={"source"}
                          onChange={(e) => {
                            setFieldValue("source", e);
                          }}
                          valueDefault={allProjectData.source_token !== null ? allProjectData.source_token : ""}
                        />
                      </Form.Group>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="shadow-sm border me-2 op-primary-color text-light" type="submit">
                      Register
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Offcanvas show={isProjectDetails} onHide={setIsProjectDetails} placement="bottom" style={{ height: "100vh", left: 0 }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Phase Details</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <section>
                  <h6 className="fw-bold">
                    <FontAwesomeIcon icon={faBuildings} size="sm" className="me-2" />
                    Phase Information
                  </h6>

                  <Stack direction="vertical" gap={1} className="mt-3">
                    <Stack direction="horizontal">
                      <div style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Type</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.phase_type || "-"}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Tenure</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{projectInfo.tenure || "-"}</span>
                      </div>
                    </Stack>

                    <Stack direction="horizontal">
                      <div style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Total Units</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{unitList.length}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Developer</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{projectInfo.developer || "-"}</span>
                      </div>
                    </Stack>

                    <Stack direction="horizontal">
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Ready by</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.completion_date !== "0000-00-00 00:00:00" ? moment(new Date(phaseData.completion_date)).format("MMMM YYYY") : "-"}</span>
                      </div>
                      <div className="ms-auto" style={{ width: "50%", marginBottom: 10, display: "flex" }}>
                        <label style={{ color: "#999", width: 80, display: "inline-block" }}>Address</label>
                        <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{phaseData.phase_location || "-"}</span>
                      </div>
                    </Stack>
                  </Stack>
                </section>

                {phaseData.key_points && (
                  <section className="mt-3">
                    <label className="fw-bold op-text-bigger">Key Points</label>
                    <ul className="mt-3">{phaseData.key_points && phaseData.key_points.split(",").map((record, index) => <li key={index}>{record}</li>)}</ul>
                  </section>
                )}

                {phaseData.phase_desc && (
                  <section className="mt-3">
                    <label className="fw-bold op-text-bigger">Description</label>
                    <p className="mt-2">{phaseData.phase_desc}</p>
                  </section>
                )}

                <section className="mt-4">
                  <h6 className="fw-bold">
                    <FontAwesomeIcon icon={faWaterLadder} size="sm" className="me-2" />
                    Facilities
                  </h6>
                  {facilitiesList.length > 0 ? (
                    facilitiesList.map((record, sectionIndex) => {
                      let startNumber = 1;
                      for (let i = 0; i < sectionIndex; i++) {
                        startNumber += facilitiesList[i].facilities_record.length;
                      }

                      return (
                        <div key={sectionIndex} style={{ margin: "20px 0" }}>
                          <label style={{ fontWeight: "bold" }}>{record.facilities_title}</label>
                          {record.facilities_record.map((info, i) => (
                            <div key={i}>
                              {String(startNumber + i).padStart(2, "0")}. {info}
                            </div>
                          ))}
                        </div>
                      );
                    })
                  ) : (
                    <div style={{ margin: "20px 0" }}>
                      {phaseData.phase_facilities &&
                        phaseData.phase_facilities.split(",").map((record, i) => (
                          <div key={i}>
                            {String(i + 1).padStart(2, "0")}. {record}
                          </div>
                        ))}
                    </div>
                  )}
                </section>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isUnit} onHide={setIsUnit} placement="bottom" style={{ height: "100vh", left: 0 }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Units</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <Stack direction="horizontal" gap={2} className="w-100">
                  <Button variant="light" className="border shadow-sm" onClick={setIsUnitFilter}>
                    <FontAwesomeIcon icon={faSliders} className="me-1" /> Filter {setFilterCount(unitSettings) > 0 && <Badge bg="secondary">{setFilterCount(unitSettings)}</Badge>}
                  </Button>
                  <p className="m-0">{unitFilterCount > 1 ? `${unitFilterCount} records found` : unitFilterCount === 1 ? "1 record found" : "No record found"}</p>
                </Stack>

                <Row className="mt-3">
                  <Col xxl={10}>
                    <div className="w-100">
                      {unitFilterLoading ? (
                        <Loader />
                      ) : (
                        <ListGroup>
                          {unitFilter.length > 0
                            ? unitFilter.map((record, index) => (
                                <div key={index}>
                                  <label className="h6 fw-bold my-3">{record.section}</label>
                                  {record.unit.map((info, i) => (
                                    <ListGroup.Item key={i} className="mb-3 shadow-sm border cursor-pointer" onClick={() => openUnit(info)}>
                                      <Stack direction="vertical" gap={2}>
                                        <Stack direction="horizontal">
                                          <h6 className="fw-bold">{info.unit_number}</h6>
                                          <div className="d-flex ms-auto">
                                            {Number(info.bumi_reserved) !== 2 && (
                                              <div className="py-1 px-3 rounded text-capitalize me-2" style={{ backgroundColor: "#eee", color: "#000" }}>
                                                {Number(info.bumi_reserved) === 1 ? "BUMI" : "NON BUMI"}
                                              </div>
                                            )}

                                            <Button variant={setStatusColor(info.unit_status)} className="py-1 px-3 text-capitalize">
                                              {info.unit_status !== "available" ? "Unavailalble" : "available"}
                                            </Button>
                                            {/* <div className="py-1 px-3 bg-success text-light rounded text-capitalize">{info.unit_status}</div> */}
                                          </div>
                                        </Stack>
                                        <Stack direction="horizontal">
                                          <div>
                                            <span className="op-text-bigger">{info.unit_title}</span> <br />
                                            <span className="op-text-medium text-muted">{info.unit_type}</span>
                                          </div>
                                          {info.unit_status === "available" && <div className="ms-auto">{setCurrency(info.unit_selling_price)}</div>}
                                        </Stack>
                                      </Stack>
                                    </ListGroup.Item>
                                  ))}
                                </div>
                              ))
                            : "None at the moment"}
                        </ListGroup>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isContact} onHide={setIsContact} placement="bottom" style={{ height: "100vh", left: 0 }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Contacts</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-75">
                <section>
                  <label className="h6 fw-bold">{phaseData.phase_name} Contact Details</label>
                  <Stack direction="vertical" gap={1} className="mt-2">
                    <Stack direction="vertical" gap={2}>
                      {contactDetails.map((record, index) => (
                        <div key={index} style={{ width: "50%", display: "flex" }}>
                          <label style={{ color: "#999", width: 150, display: "inline-block" }}>{record.title}</label>
                          <span style={{ width: 260, marginLeft: 20, display: "inline-block" }}>{record.value || "-"}</span>
                        </div>
                      ))}
                    </Stack>
                  </Stack>
                </section>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isFloorPlan} onHide={setIsFloorPlan} placement="bottom" style={{ height: "100vh", left: 0 }}>
            <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
              <Offcanvas.Title>Floor Plans</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
              <div className="w-100">
                <Stack direction="horizontal" gap={2} className="w-100 align-items-center">
                  <Button variant="light" className="border shadow-sm" onClick={setIsFloorPlanFilter}>
                    <FontAwesomeIcon icon={faSliders} className="me-1" /> Filter {setFilterCount(floorPlanSettings) > 0 && <Badge bg="secondary">{setFilterCount(floorPlanSettings)}</Badge>}
                  </Button>
                  <p className="m-0">{floorPlanFilterCount > 1 ? `${floorPlanFilterCount} records found` : floorPlanFilterCount === 1 ? "1 record found" : "No record found"}</p>
                </Stack>

                {floorPlanFilterLoading ? (
                  <Loader />
                ) : (
                  <>
                    {floorPlanFilter.length > 0 ? (
                      floorPlanFilter.map((record, index) => (
                        <div key={index}>
                          <label className="h6 fw-bold my-3">{record.section}</label>
                          <Row>
                            {record.unit_type.map((info, i) => (
                              <Col key={i} xxl={4} className="mb-4">
                                <Card className="w-100 h-100 shadow-sm position-relative">
                                  <div className="d-flex justify-content-center align-items-center">
                                    {record.floor_plan ? <Card.Img variant="top" src={`https://www.nexcrmapis.com/cloud/assets/rea_booking/${company_id}/project/${info.floor_plan}`} style={{ height: 300, width: "auto" }} onClick={() => openFloorPlanImage(info)} /> : <RenderImageUnavailable />}
                                  </div>

                                  <Card.Footer>
                                    <Stack className="vertical" gap={2}>
                                      <label className="fw-bold" style={{ fontSize: 15 }}>
                                        {info.unit_title} {info.unit_type} {info.builtup_area ? `(${setArea(info.builtup_area)})` : ""}
                                      </label>
                                      <div>
                                        <label className="text-muted">Price Range</label> <br />
                                        <label>{info.price}</label>
                                      </div>
                                      <a className="text-dark text-decoration-none p-0 m-0 cursor-pointer fw-bold mt-1" onClick={() => openUnitFloorPlan(info)}>
                                        <span>
                                          {info.unit_available_count} of {info.unit_count} available
                                        </span>
                                        <FontAwesomeIcon icon={faChevronRight} className="ms-1" size="sm" />
                                      </a>
                                    </Stack>
                                  </Card.Footer>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))
                    ) : (
                      <Col xxl={4}>None at the moment</Col>
                    )}
                  </>
                )}
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isDocument} onHide={setIsDocument} placement="top" style={{ height: "100vh" }}>
            <Offcanvas.Header className="position-relative" closeButton>
              <Offcanvas.Title>{documentData.title}</Offcanvas.Title>
              <div style={{ position: "absolute", right: 50, cursor: "pointer" }}>
                <Dropdown>
                  <Dropdown.Toggle as={RenderShareToggle} style={{ zIndex: 1 }}>
                    <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="lg" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align={"end"}>
                    <Dropdown.Item
                      onClick={() => {
                        const text = `Please find the file of project ${phaseData.phase_name} listed below for your reference type ${documentData.title} floor plan: \n${documentData.url}`;
                        const whatsapp = `whatsapp://send?text=${encodeURIComponent(text)}`;
                        window.open(whatsapp, "_blank");
                      }}
                    >
                      Share via Whatsapp
                    </Dropdown.Item>
                    {documentData.mode === "image" && <Dropdown.Item onClick={() => sendDownload(`${documentData.title}_floor_plan`, documentData.filepath)}>Download</Dropdown.Item>}
                  </Dropdown.Menu>
                </Dropdown>
                {/* <FontAwesomeIcon icon={faShare} size="xl" /> */}
              </div>
            </Offcanvas.Header>
            {documentData.mode === "image" && (
              <Offcanvas.Body>
                <div className="d-flex justify-content-center align-items-center w-100">
                  <Image src={documentData.url} style={{ height: "85vh", width: "auto" }} />
                </div>
              </Offcanvas.Body>
            )}

            {documentData.mode === "pdf" && (
              <Offcanvas.Body className="p-0 overflow-hidden">
                <iframe title="Document" src={documentData.url} style={{ width: "100%", height: "100%" }}></iframe>
              </Offcanvas.Body>
            )}
          </Offcanvas>

          {files && (
            <Offcanvas show={isFile} onHide={setIsFile} placement="bottom" style={{ height: "100vh", left: 0 }}>
              <Offcanvas.Header closeButton style={{ paddingLeft: 50, paddingRight: 50 }}>
                <Offcanvas.Title>Brochures & Files</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{ paddingLeft: 50, paddingRight: 50, paddingTop: 30 }}>
                <div className="w-75">
                  {files.section ? (
                    <>
                      {files.record.map((record, index) => (
                        <div key={index}>
                          <label className="fw-bold">
                            {record.section_title} ({record.files.length})
                          </label>
                          <Row className="mt-3">
                            {record.files.map((info, i) => (
                              <Col xxl={6} key={i} className="mb-3">
                                <div className="border rounded shadow-sm w-100 p-3 bg-light d-flex align-items-center justify-content-between">
                                  <span className="w-75" onClick={() => openFiles(info)}>
                                    {info.title}
                                  </span>
                                  <Dropdown>
                                    <Dropdown.Toggle as={RenderShareToggle} style={{ zIndex: 1 }}>
                                      <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="lg" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align={"end"}>
                                      <Dropdown.Item onClick={() => sendShareWhatsapp(info)}>Share via Whatsapp</Dropdown.Item>
                                      {info.download && <Dropdown.Item>Download</Dropdown.Item>}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      ))}
                    </>
                  ) : (
                    <Row>
                      {files.record.map((record, index) => (
                        <Col xxl={6} key={index} className="mb-3">
                          <div className="border rounded shadow-sm w-100 p-3 bg-light d-flex align-items-center justify-content-between">
                            <span className="w-75" onClick={() => openFiles(record)}>
                              {record.title}
                            </span>
                            <Dropdown>
                              <Dropdown.Toggle as={RenderShareToggle} style={{ zIndex: 1 }}>
                                <FontAwesomeIcon className="cursor-pointer" icon={faEllipsisVertical} size="lg" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu align={"end"}>
                                <Dropdown.Item onClick={() => sendShareWhatsapp(record)}>Share via Whatsapp</Dropdown.Item>
                                {record.download && <Dropdown.Item>Download</Dropdown.Item>}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </div>
      )}
    </Container>
  );
};

export default CustomerKitPhaseDetails;
