import { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import FormSelect from "./FormSelect";

const FormCustomField = ({ className, id, label, options, type, onChange, placeholder, name, isInvalid, firstOptions = 0, value, valueDefault, isDisabled }) => {
  const init = useRef(false);
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!init.current) {
      init.current = true;

      try {
        
        const optionsArr = JSON.parse(options);
        const optionsRecord = Object.values(optionsArr).map((record) => ({
          label: record,
          value: record,
        }));
  
        if (firstOptions) {
          optionsRecord.unshift({
            label: "Select " + label,
            value: "-",
          })
        }
  
        setOptionList(optionsRecord);
        setLoading(false);
      } catch (error) {
        console.error("Error parsing options:", error);
        setLoading(false);
      }
  
      //console.log(valueDefault);
    }
  }, [options]);

  const renderCustomField = () => {
    switch (type) {
      case "Text":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} type="text" placeholder={placeholder} defaultValue={valueDefault} onChange={onChange} isInvalid={isInvalid} />
          </Form.Group>
        );
      case "Textarea":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} as="textarea" onChange={onChange} rows={3} isInvalid={isInvalid} />
          </Form.Group>
        );
      case "Date":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <Form.Control name={name} type="date" onChange={onChange} isInvalid={isInvalid} defaultValue={valueDefault} value={value}/>
          </Form.Group>
        );
      case "Radio":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={`${id}-${type}-${index}`} type={type.toLowerCase()} label={option.label} name={name} value={option.value} onChange={onChange} isInvalid={isInvalid} />
            ))}
          </Form.Group>
        );
      case "Checkbox":
        const onChangeValue = (event) => {
          onChange(event);
        };
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            {optionList.map((option, index) => (
              <Form.Check key={`${id}-${type}-${index}`} type={type.toLowerCase()} label={option.label} name={name} value={option.value} onChange={onChangeValue} isInvalid={isInvalid} />
            ))}
          </Form.Group>
        );
      case "Select":
        return (
          <Form.Group className={`mb-3 ${className}`}>
            <Form.Label>{label}</Form.Label>
            <FormSelect options={optionList} valueDefault={ valueDefault ? optionList.filter(option => option.value === valueDefault) : "" } value={ value } name={name} onChange={onChange} placeholder={placeholder} isInvalid={isInvalid} isSearchable={true} isDisabled={isDisabled}/>
          </Form.Group>
        );
      default:
        console.error("Unsupported field type");
        return null;
    }
  };

  return <>{!loading && renderCustomField()}</>;
};

export default FormCustomField;
