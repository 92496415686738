import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const WhatsAppTemplateSetting = () => {

    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef(null);
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [userList, setUserList] = useState([]);

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "4b",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var list = data.record.map(data => {
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(list);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);


    const [reportColumn] = useState([
        {
            headerName: t('SettingWaTemplate_headername_1'),
            field: "interaction_name",
            filter: true,
            resizable: true,
            width: 300
        },
        {
            headerName: t('SettingWaTemplate_headername_2'),
            field: "interaction_title",
            resizable: true,
            autoHeight: true,
            flex: 1,
            wrapText: true,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.interaction_title) {
                        return(
                            <div style={{width: 'auto'}}>
                                {params.data.interaction_title}
                            </div>
                        );
                    }
                }
            }
        },
        {
            headerName: t('SettingWaTemplate_headername_3'),
            field: "user_name",
            resizable: true,
            width: 125
        },
        {
            headerName: t('SettingWaTemplate_headername_4'),
            resizable: true,
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <Form.Check
                            type="switch"
                            className="d-flex align-items-center justify-content-center"
                            checked={Number(params.data.hide) === 0 ? true:false}
                            onClick={()=>{handleShow(params.data)}}
                        />
                    );
                }
            }
        },
        {
            headerName: t('SettingWaTemplate_headername_5'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.custom_interaction_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={()=>{handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_custom_interaction.php',{
            params: {
                task: "getAllWhatsappInteractions",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                const filteredRows = data.record;
                filteredRows.sort(function(a, b) { return a.custom_interaction_id - b.custom_interaction_id })
                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(true);
            setIsLoading(false);
        });
    }

    const handleShow = (data) => {

        var mode = Number(data.hide) === 0 ? 1:0;

        axios.get('ws/ws_custom_interaction.php',{
            params:{
                task: "setTemplateMode",
                custom_interaction_id: data.custom_interaction_id,
                mode: mode,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingWaTemplate_alert_failed_title'),
                    text: t('SettingWaTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        template_name: yup.string().required(t('SettingWaTemplate_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_custom_interaction.php',{
            params: {
                task: 'addTemplate',
                title: values.template_name,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingWaTemplate_alert_success_title'),
                    text: t('SettingWaTemplate_alert_success_add_text'),
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingWaTemplate_alert_failed_title'),
                    text: t('SettingWaTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const editSchema = yup.object().shape({
        template_name_edit: yup.string().required(t('SettingWaTemplate_required_field')),
    });

    const handleEdit = (data) => {
        setEditInitVal({
            template_id: data.custom_interaction_id,
            template_name_edit: data.interaction_name,
            template_message: data.interaction_title,
            template_user_id: data.user_id
        });
        setIsEdit(true);
    }

    const sendEdit = (values) => {
        axios.get('ws/ws_custom_interaction.php',{
            params: {
                task: 'updateMessage',
                iname: values.template_name_edit,
                ititle: values.template_message,
                userid: values.template_user_id,
                custom_interaction_id: values.template_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingWaTemplate_alert_success_title'),
                    text: t('SettingWaTemplate_alert_success_edit_text'),
                    timer: 1500
                }).then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingWaTemplate_alert_failed_title'),
                    text: t('SettingWaTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: t('SettingWaTemplate_alert_warning_title'),
            text: t('SettingWaTemplate_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingWaTemplate_alert_warning_confirm')
        }).then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_custom_interaction.php',{
                    params: {
                        task: "delMessage",
                        custom_interaction_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingWaTemplate_alert_success_title'),
                            text: t('SettingWaTemplate_alert_success_delete_text'),
                            timer: 1500
                        }).then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingWaTemplate_alert_failed_title'),
                            text: t('SettingWaTemplate_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('interaction_name');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingWaTemplate_btn_add')}</button>
                            <Form.Group style={{width: "300px"}}>
                                <InputGroup>
                                    <Form.Control
                                        placeholder={t('SettingWaTemplate_search_placeholder')}
                                        type="text"
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="px-2 ms-auto">Result: {recordFound === 1 ? t('SettingWaTemplate_record', {count: recordFound}) : recordFound > 1 ? t('SettingWaTemplate_record', {count: recordFound}) : t('SettingWaTemplate_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    template_name: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingWaTemplate_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingWaTemplate_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_name && touched.template_name}
                                                onChange={handleChange('template_name')}
                                            />
                                            {errors.template_name && touched.template_name && <div className="op-error-message">{errors.template_name}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingWaTemplate_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                    <Modal show={isEdit} onHide={setIsEdit} size="lg">
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingWaTemplate_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingWaTemplate_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_name_edit && touched.template_name_edit}
                                                value={values.template_name_edit}
                                                onChange={handleChange('template_name_edit')}
                                            />
                                            {errors.template_name_edit && touched.template_name_edit && <div className="op-error-message">{errors.template_name_edit}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingWaTemplate_modalBody_edit_title_2')}</Form.Label>
                                            <Form.Control 
                                                as="textarea" 
                                                rows={10}
                                                value={values.template_message}
                                                onChange={handleChange('template_message')}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingWaTemplate_modalBody_edit_title_3')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingWaTemplate_modalBody_edit_title_3_placeholder')}
                                                options={userList}
                                                valueDefault={userList.filter(option => option.value === values.template_user_id)}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => {handleChange('template_user_id')(e ? e.value:"0")}}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingWaTemplate_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default WhatsAppTemplateSetting;