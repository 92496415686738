import { useState, useRef, useEffect, useMemo } from "react";
import { Modal, Form, Stack, Container } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const SettingGetResConn = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const [dsList, setDsList] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingGetResConn_HeaderName1'),
            field: 'title',
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: t('SettingGetResConn_HeaderName2'),
            field: 'account_login',
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingGetResConn_HeaderName3'),
            field: 'campaign_id',
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingGetResConn_HeaderName4'),
            field: 'api_key',
            resizable: true,
            autoHeight: true,
            wrapText: true,
            width: 280
        },
        {
            headerName: t('SettingGetResConn_HeaderName5'),
            field: 'ds_title',
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingGetResConn_HeaderName6'),
            field: 'user_tag_title',
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingGetResConn_HeaderName7'),
            resizable: true,
            width: 120,
            headerClass: 'center',
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="p-4">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-2" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        axios.get('ws/ws_getresponse.php', {
            params: {
                task: 'listForms',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsGrid(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
            setRowData([]);
            setRecordFound(0);
            setIsGrid(true);
            setIsLoading(false);
        });
    }

    const getAllSource = () => {
        axios.get('ws/ws_source.php',{
            params: {
                task: 2,
                disp: 'show',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(rec => {
                    return {label: rec.ds_title, value: rec.ds_token};
                });

                setDsList(dataList);
            }else{
                setDsList([]);
            }
        })
        .catch(error => {
            console.log(error);
            setDsList([]);
        });
    }

    const getAllTeam = () => {
        axios.get('ws/ws_user.php', {
            params: {
                task: 'getUserTags',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(rec => {
                    return {label: rec.user_tag_title, value: rec.tag_token};
                });

                setTeamList(dataList);
            }else{
                setTeamList([]);
            }
        })
        .catch(error => {
            console.log(error);
            setTeamList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
            getAllSource();
            getAllTeam();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    const addSchema = yup.object().shape({
        title: yup.string().required(t('Setting_requiredFd'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_getresponse.php', {
            params: {
                task: 'addForm',
                utoken: session.user_ac_token,
                ctoken: session.company_token,
                title: values.title
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Add'),
                    timer: 1500
                })
                .then(() => {
                    getGridData();
                    setIsAdd(false);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleEdit = (data) => {
        var editVal = {
            getresponseID: data.getresponse_id,
            title: data.title,
            acc_login: data.account_login,
            campaignID: data.campaign_id,
            api_key: data.api_key,
            ds_token: data.source_token,
            user_tag: data.user_tag
        };

        setEditInitVal(editVal);
        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        title: yup.string().required(t('Setting_requiredFd')),
        ds_token: yup.string().required(t('Setting_requiredFd')),
        user_tag: yup.string().required(t('Setting_requiredFd'))
    });

    const sendEdit = (values) => {
        axios.get('ws/ws_getresponse.php', {
            params: {
                task: 'updateForm',
                getresponse_id: values.getresponseID,
                formtitle: values.title,
                source: values.ds_token,
                usertag: values.user_tag,
                campaign_id: values.campaignID,
                account_login: values.acc_login,
                api_key: values.api_key,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: 'success',
                    title: t('Setting_Alert_Success_Title'),
                    text: t('Setting_Alert_Success_Text_Edit'),
                    timer: 1500
                })
                .then(() => {
                    getGridData();
                    setIsEdit(false);
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    title: t('Setting_Alert_Failed_Title'),
                    text: t('Setting_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleDelete = (values) => {
        Swal.fire({
            icon: 'warning',
            title: t('Setting_alert_warning_title'),
            text: t('Setting_Alert_Warning_Text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('Setting_Alert_Warning_ConfirmText'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_getresponse.php', {
                    params: {
                        task: 'delForm',
                        getresponse_id: values.getresponse_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: t('Setting_Alert_Success_Title'),
                            text: t('Setting_Alert_Success_Text_Delete'),
                            timer: 1500
                        })
                        .then(()=> {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: t('Setting_Alert_Failed_Title'),
                            text: t('Setting_Alert_Failed_Text')
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
        });
    }

    return(
        <div>
            {
                isLoading ? <Loader/> : 
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button type="button" className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingGetResConn_AddCampaign')}</button>
                            <div>
                                <Form.Group style={{width: 250}}>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('SettingGetResConn_SearchByTitle')}
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                </Form.Group>
                            </div>
                            <div className="ms-auto">
                                {recordFound === 1 ? t('Setting_Record', {count: recordFound}) : recordFound > 1 ? t('Setting_Record', {count: recordFound}) : t('Setting_Record_NoRecord')}
                            </div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={60}
                                pagination={false}
                                enableCellTextSelection={true}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    title: ''
                                }
                            }
                        >
                            {({ handleSubmit, setFieldValue, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingGetResConn_Modal_Title_Add')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingGetResConn_Modal_Title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.title && touched.title}
                                                onChange={(e) => {setFieldValue('title', e.target.value)}}
                                            />
                                            {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingGetResConn_Modal_Footer')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingGetResConn_Modal_Title_Edit')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingGetResConn_Modal_Title')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.title}
                                                isInvalid={errors.title && touched.title}
                                                onChange={(e) => {setFieldValue('title', e.target.value)}}
                                            />
                                            {errors.title && touched.title && <div className="op-error-message">{errors.title}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingGetResConn_Modal_AccountLogin')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.acc_login}
                                                isInvalid={errors.acc_login && touched.acc_login}
                                                onChange={(e) => {setFieldValue('acc_login', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingGetResConn_Modal_CampaignID')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.campaignID}
                                                isInvalid={errors.campaignID && touched.campaignID}
                                                onChange={(e) => {setFieldValue('campaignID', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingGetResConn_Modal_API_Key')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.api_key}
                                                isInvalid={errors.api_key && touched.api_key}
                                                onChange={(e) => {setFieldValue('api_key', e.target.value)}}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingGetResConn_Modal_DealSource')}</Form.Label>
                                            <FormSelect
                                                options={dsList}
                                                valueDefault={dsList.filter(opt => opt.value === values.ds_token)}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.ds_token && touched.ds_token}
                                                onChange={(e) => {setFieldValue('ds_token', e ? e.value : '')}}
                                            />
                                            {errors.ds_token && touched.ds_token && <div className="op-error-message">{errors.ds_token}</div>}
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{t('SettingGetResConn_Modal_TeamAllocation')}</Form.Label>
                                            <FormSelect
                                                options={teamList}
                                                valueDefault={teamList.filter(opt => opt.value === values.user_tag)}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.user_tag && touched.user_tag}
                                                onChange={(e) => {setFieldValue('user_tag', e ? e.value : '')}}
                                            />
                                            {errors.user_tag && touched.user_tag && <div className="op-error-message">{errors.user_tag}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingGetResConn_Modal_Footer')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            }
        </div>
    );
}

export default SettingGetResConn;
