import React, { useEffect } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 as faGrid2Active, faTicket as faTicketActive, faPaperPlane as faPaperPlaneActive, faTags as faTagsActive, faStore as faStoreActive } from "@fortawesome/pro-solid-svg-icons";
import { faGrid2, faPaperPlane, faStore, faTags, faTicket } from "@fortawesome/pro-light-svg-icons";

const Redemption = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/gamuda/redemption" || location.pathname === "/gamuda/redemption/") {
      navigate("/gamuda/redemption/dashboard", { replace: true });
    }
  }, [navigate, location]);

  return (
    <Container fluid className="m-0 p-0 d-flex" style={{ height: "calc(100vh - 56px)" }}>
      <div className="h-100 overflow-auto op-scrollbar" style={{ width: "20%" }}>
        <Nav variant="pills" className="h-100 flex-column sidebar-tab bg-light py-4 px-3">
          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/gamuda/redemption/dashboard" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faGrid2Active : faGrid2} size="xl" />
                  </span>
                  <span>Dashboard</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/gamuda/redemption/voucher" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faTicketActive : faTicket} size="xl" className="me-2" />
                  </span>
                  <span>Voucher</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/gamuda/redemption/category" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faTagsActive : faTags} size="xl" className="me-2" />
                  </span>
                  <span>Category</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/gamuda/redemption/vendor" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faStoreActive : faStore} size="xl" className="me-2" />
                  </span>
                  <span>Vendor</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>

          <Nav.Item className="mb-2">
            <NavLink className="nav-link" to="/gamuda/redemption/email" activeclassname="active">
              {({ isActive }) => (
                <div className="d-flex align-items-center py-1">
                  <span className="me-2" style={{ width: 25 }}>
                    <FontAwesomeIcon icon={isActive ? faPaperPlaneActive : faPaperPlane} size="xl" className="me-2" />
                  </span>
                  <span>Email Subject</span>
                </div>
              )}
            </NavLink>
          </Nav.Item>
        </Nav>
      </div>

      <div className="overflow-auto" style={{ width: "85%" }}>
        <Outlet />
      </div>
    </Container>
  );
};

export default Redemption;
