import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Container, Form, ListGroup, Modal } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import * as formik from "formik";

const BookingMainFormat = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({ column: "", value: "" });
  const [isModal, setIsModal] = useState(false);
  const [buyer, setBuyer] = useState({
    rea_mainBuyer_format_id: "",
    company_id: "0",
    fullname_label: "Full Name",
    fullname_show: "1",
    fullname_required: "1",
    salutation_label: "Salutation",
    salutation_show: "1",
    salutation_required: "1",
    identification_type_label: "Identification Type",
    identification_type_show: "1",
    identification_type_required: "1",
    identification_number_label: "Identification Number",
    identification_number_show: "1",
    identification_number_required: "1",
    occupation_label: "Occupation",
    occupation_show: "1",
    occupation_required: "1",
    monthly_income_label: "Monthly Income",
    monthly_income_show: "1",
    monthly_income_required: "1",
    dob_label: "Date of Birth",
    dob_show: "1",
    dob_required: "1",
    gender_label: "Gender",
    gender_show: "1",
    gender_required: "1",
    race_label: "Race",
    race_show: "1",
    race_required: "1",
    nationality_label: "Nationality",
    nationality_show: "1",
    nationality_required: "1",
    marital_status_label: "Marital Status",
    marital_status_show: "1",
    marital_status_required: "1",
    email_address_label: "Email Address",
    email_address_show: "1",
    email_address_required: "1",
    mobile_number_label: "Mobile Number",
    mobile_number_show: "1",
    mobile_number_required: "1",
    office_number_label: "Office Number",
    office_number_show: "1",
    office_number_required: "1",
    fax_number_label: "Fax Number",
    fax_number_show: "1",
    fax_number_required: "1",
    address1_label: "Address Line 1",
    address1_show: "1",
    address1_required: "1",
    address2_label: "Address Line 2",
    address2_show: "1",
    address2_required: "1",
    address3_label: "Address Line 3",
    address3_show: "1",
    address3_required: "1",
    postcode_label: "Postal Code",
    postcode_show: "1",
    postcode_required: "1",
    country_label: "Country",
    country_show: "1",
    country_required: "1",
    state_label: "State",
    state_show: "1",
    state_required: "1",
    city_label: "City",
    city_show: "1",
    city_required: "1",
    emergency_contact_fullname_label: "Emergency Contact Full Name",
    emergency_contact_fullname_show: "1",
    emergency_contact_fullname_required: "1",
    emergency_contact_relation_label: "Emergency Contact Relation",
    emergency_contact_relation_show: "1",
    emergency_contact_relation_required: "1",
    emergency_contact_mobile_number_label: "Emergency Contact Mobile Number",
    emergency_contact_mobile_number_show: "1",
    emergency_contact_mobile_number_required: "1",
    emergency_contact_email_label: "Emergency Contact Email",
    emergency_contact_email_show: "1",
    emergency_contact_email_required: "1",
    identification_card_front_label: "Identification Card (Front)",
    identification_card_front_show: "1",
    identification_card_front_required: "1",
    identification_card_back_label: "Identification Card (Back)",
    identification_card_back_show: "1",
    identification_card_back_required: "1",
  });

  // USEEFFECT FUNCTION ----------------------

  const getData = useCallback(async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetMainBuyerFormat",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setBuyer(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onchangeData = useCallback(
    async (column) => {
      const recordValues = Number(buyer[column]) === 1 ? 0 : 1;
      const recordUpdate = { ...buyer };
      recordUpdate[column] = recordValues;
      setBuyer(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateMainBuyerFormat",
            column: column,
            value: recordValues,
            rea_mainBuyer_format_id: buyer.rea_mainBuyer_format_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [buyer, session]
  );

  const onchangeLabel = useCallback(
    (column) => {
      setInitialValues({ column: column, value: buyer[column] });
      setIsModal(true);
    },
    [buyer]
  );

  const sendLabel = useCallback(
    async (values) => {
      setIsModal(false);
      const recordUpdate = { ...buyer };
      recordUpdate[values.column] = values.value;
      setBuyer(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateMainBuyerFormat",
            column: values.column,
            value: values.value,
            rea_mainBuyer_format_id: buyer.rea_mainBuyer_format_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [buyer, session]
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <section className="mb-4">
            <h6>Buyer Form</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("fullname_label")}>
                  {buyer.fullname_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.fullname_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("fullname_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.fullname_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("fullname_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("salutation_label")}>
                  {buyer.salutation_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.salutation_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("salutation_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.salutation_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("salutation_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("identification_type_label")}>
                  {buyer.identification_type_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.identification_type_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_type_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.identification_type_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_type_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("identification_number_label")}>
                  {buyer.identification_number_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.identification_number_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_number_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.identification_number_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_number_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("occupation_label")}>
                  {buyer.occupation_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.occupation_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("occupation_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.occupation_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("occupation_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("monthly_income_label")}>
                  {buyer.monthly_income_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.monthly_income_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("monthly_income_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.monthly_income_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("monthly_income_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("dob_label")}>
                  {buyer.dob_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.dob_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("dob_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.dob_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("dob_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("gender_label")}>
                  {buyer.gender_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.gender_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("gender_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.gender_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("gender_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("race_label")}>
                  {buyer.race_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.race_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("race_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.race_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("race_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("nationality_label")}>
                  {buyer.nationality_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.nationality_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("nationality_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.nationality_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("nationality_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("marital_status_label")}>
                  {buyer.marital_status_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.marital_status_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("marital_status_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.marital_status_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("marital_status_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("email_address_label")}>
                  {buyer.email_address_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.email_address_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("email_address_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.email_address_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("email_address_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("mobile_number_label")}>
                  {buyer.mobile_number_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.mobile_number_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("mobile_number_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.mobile_number_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("mobile_number_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("office_number_label")}>
                  {buyer.office_number_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.office_number_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("office_number_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.office_number_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("office_number_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("fax_number_label")}>
                  {buyer.fax_number_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.fax_number_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("fax_number_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.fax_number_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("fax_number_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("address1_label")}>
                  {buyer.address1_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.address1_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address1_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.address1_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address1_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("address2_label")}>
                  {buyer.address2_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.address2_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address2_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.address2_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address2_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("address3_label")}>
                  {buyer.address3_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.address3_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address3_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.address3_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("address3_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("postcode_label")}>
                  {buyer.postcode_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.postcode_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("postcode_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.postcode_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("postcode_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("country_label")}>
                  {buyer.country_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.country_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("country_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.country_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("country_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("state_label")}>
                  {buyer.state_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.state_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("state_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.state_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("state_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("emergency_contact_fullname_label")}>
                  {buyer.emergency_contact_fullname_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.emergency_contact_fullname_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_fullname_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.emergency_contact_fullname_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_fullname_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("emergency_contact_relation_label")}>
                  {buyer.emergency_contact_relation_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.emergency_contact_relation_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_relation_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.emergency_contact_relation_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_relation_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("emergency_contact_mobile_number_label")}>
                  {buyer.emergency_contact_mobile_number_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.emergency_contact_mobile_number_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_mobile_number_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.emergency_contact_mobile_number_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_mobile_number_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("emergency_contact_email_label")}>
                  {buyer.emergency_contact_email_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.emergency_contact_email_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_email_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.emergency_contact_email_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("emergency_contact_email_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("identification_card_front_label")}>
                  {buyer.identification_card_front_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.identification_card_front_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_card_front_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.identification_card_front_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_card_front_required")} />
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <Button variant="link" className="text-dark text-decoration-none px-0" onClick={() => onchangeLabel("identification_card_back_label")}>
                  {buyer.identification_card_back_label}
                </Button>
                <div className="d-flex justify-content-center align-items-center">
                  <Form.Check type="switch" className="me-3 mh-0" label="Show" checked={Number(buyer.identification_card_back_show) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_card_back_show")} />
                  <Form.Check type="switch" label="Required" checked={Number(buyer.identification_card_back_required) === 1} style={{ minHeight: 0 }} onChange={() => onchangeData("identification_card_back_required")} />
                </div>
              </ListGroup.Item>
            </ListGroup>
          </section>
        </div>
      )}

      <Modal show={isModal} onHide={setIsModal} size="lg">
        <Formik onSubmit={sendLabel} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Change Label</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Label</Form.Label>
                  <Form.Control type="text" value={values.value} onChange={handleChange("value")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default BookingMainFormat;
