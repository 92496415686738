import { useState, useRef, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import Loader from "../../includes/Loader";
import "../../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import FormSelect from "../../includes/FormSelect";
import FormCustomField from "../../includes/FormCustomField";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EditCustomerProfileVN = ({ prospectID, closeModal, onSuccess }) => {

    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const [prospectDetail, setProspectDetail] = useState([]);
    const [prosData, setProsData] = useState({});
    const [cfPerson, setCfPerson] = useState([]);
    const [open, setOpen] = useState(false);

    const [corporateOpt, setCorporateOpt] = useState([]);
    const [idTypeOptState, setIdTypeOptState]= useState([]);

    const getProspectDetails = () => {

        axios.get('ws/ws_prospect_view.php', {
            params:{
                task: "viewProspectDetailsV2",
                prospect: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setProspectDetail(data.record);

                const template = ["5000","5001","5002","5003","5004","2850","5005","5006","5007","5008","5009","5010","5011","5012","5013","5014","5015","5016","5017"];

                const sortByObject = template
                .reduce((obj, item, index) => {
                    return {
                    ...obj,
                    [item]: index,
                    };
                }, {});

                var cf_person = [];
                var corporate = "";
                var corporateOpt = "";
                var idType = "";
                var idTypeOpt = "";
                var idNum = "";
                var DOB = '';

                data.record.custom_field_data.map((info) => {
                    if (template.includes(info.cf_id)) {
                        cf_person.push(info);
                    }

                    if (info.cf_id == 5000) {
                        corporate = info.fd_value;
                        corporateOpt = info.cf_value;
                    }

                    if (info.cf_id == 5001) {
                        idType = info.fd_value;
                        idTypeOpt = info.cf_value;
                    }

                    if (info.cf_id == 5002) {
                        idNum = info.fd_value;
                    }

                    if (info.cf_id == 5005) {
                        DOB = info.fd_value;
                    }
                });

                cf_person.sort((a,b) => sortByObject[a.cf_id] - sortByObject[b.cf_id]);

                var option2Arr = JSON.parse(corporateOpt);
                var newOpt2 = Object.values(option2Arr).map(rec => {
                    return {label: rec, value: rec};
                });

                var optionsArr = JSON.parse(idTypeOpt);
                var idTypeOptNew =  Object.values(optionsArr).map(rec => {
                    return {label: rec, value: rec};
                });

                var idTypeOptUpdated = idTypeOptNew.map(obj => {

                    let corporate;

                    if (obj.value === "NRIC No." || obj.value === "Passport") {
                        corporate = 'No';
                    }else{
                        corporate = 'Yes';
                    }

                    return {...obj, corporate};
                });

                idTypeOptUpdated.map(data => {
                    if (data.value == 'NRIC No.') {
                        data.label = 'Vietnam NRIC No.';
                    }
                });

                setCorporateOpt(newOpt2);
                setIdTypeOptState(idTypeOptUpdated);

                var cf_id_val_na = ["5008","5007","5015","5014","5013"];
                var cf_id_val_other = ["5009","5010"];

                setProsData({
                    pros_name: data.record.prospect_name,
                    cf_corporate: corporate,
                    cf_idType: idType,
                    cf_corporate_no_1: cf_id_val_na,
                    cf_corporate_no_2: cf_id_val_other,
                    cf_id_num: idNum,
                    cf_dob: DOB,
                    cf_allow: corporate === 'Yes' ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"],
                    cf_allow2: idType === "NRIC No." ? ["5000","5001","5002","5004","5006"] : idType === "Passport" ? ["5000","5001","5002","5004","5006"] : (idType === "Reg No." || idType === "Others") ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"],
                    cf_person: cf_person.map(rec => ({
                        cf_id: rec.cf_id,
                        label: rec.cf_label,
                        fd_values: rec.cf_id == '5014' ? 'Not Applicable' : rec.fd_value
                    }))
                });

                setCfPerson(cf_person);
                setOpen(true);
                setIsLoading(false);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_EditCustProf_Alert_Failed_Title'),
                    text: t('ProspectDetGL_EditCustProf_Alert_Failed_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getProspectDetails();
        }
    }, []);

    const sendEditSchema = yup.object().shape({
        pros_name: yup.string().required(t('ProspectDetGL_EditCustProf_requiredField')),
        cf_corporate: yup.string().required(t('ProspectDetGL_EditCustProf_requiredField')),
        cf_idType: yup.string().required(t('ProspectDetGL_EditCustProf_requiredField')),
        cf_id_num: yup.string().required(t('ProspectDetGL_EditCustProf_requiredField'))
    });

    const sendEdit = (values) => {
        setIsLoading(true);

        var allowAge = 1;
        var allowIdNum = 1;
        var oriID = '';

        cfPerson.map(data => {
            if (data.cf_id == 5002) {
                oriID = data.fd_value
            }
        });

        values.cf_person.map(data => {
            if (values.cf_idType === "Passport" || values.cf_idType === "NRIC No.") {
                if (data.cf_id === '5006') {
                    var dobYear = new Date(values.cf_dob).getFullYear();
                    var todayYear = new Date().getFullYear();

                    var age = parseInt(todayYear) - parseInt(dobYear);
                    data.fd_values = age;
                    
                }
            }
        });

        if (allowIdNum == 1 && allowAge == 1) {
            handleCheckID(values, oriID);
        }
    }

    const handleCheckID = (values, oriID) => {
        var prosID = "";

        if (prospectDetail.hq_prospect_id && prospectDetail.hq_prospect_id != 0) {
            prosID = prospectDetail.hq_prospect_id;
        }else{
            prosID = prospectDetail.prospect_id;
        }

        if (values.cf_id_num == oriID) {
            
            handleTriggerUpdateApi(values);

        }else{

            axios.get('ext/gamuda/api_check_prospect.php', {
                params:{
                    prospect_id: prosID,
                    ic_no: values.cf_id_num,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (data.status == 0) {
                    handleTriggerUpdateApi(values);
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Failed",
                        text: data.message
                    })
                    .then(result => {
                        setIsLoading(false);
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                })
                .then(result => {
                    setIsLoading(false);
                });
            });

        }

    }

    const handleTriggerUpdateApi = (values) => {

        var prosID = "";

        if (prospectDetail.hq_prospect_id && prospectDetail.hq_prospect_id != 0) {
            prosID = prospectDetail.hq_prospect_id;
        }else{
            prosID = prospectDetail.prospect_id;
        }
    
        axios.get('ws/ws_edit_prospect_details.php', {
            params:{
                task: "updateProfile",
                prospect_id: prosID,
                hq_prospect_id: prospectDetail.hq_prospect_id,
                prospect_name: values.pros_name,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                triggerUpdateCF(values);
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_EditCustProf_Alert_Failed_Title'),
                    text: t('ProspectDetGL_EditCustProf_Alert_Failed_Text')
                })
                .then(result => {
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });

    }

    const triggerUpdateCF = (values) => {
        var cf_id = [];
        var cf_label = [];
        var fd_value = [];
        var record = [];
        var prosID = "";

        if (prospectDetail.hq_prospect_id && prospectDetail.hq_prospect_id != 0) {
            prosID = prospectDetail.hq_prospect_id;
        }else{
            prosID = prospectDetail.prospect_id;
        }

        values.cf_person.map(rec => {
            record.push(prosID);
            cf_id.push(rec.cf_id);
            cf_label.push(rec.cf_label);
            fd_value.push(rec.fd_values);
        });

        axios.get('ws/ws_custom_field.php', {
            params:{
                task: "updateOrAddCF",
                record: record.toString(),
                audithistory_prospectID: prospectID,
                hq_prospect_id: prospectDetail.hq_prospect_id,
                cf_label: cf_label.toString(),
                cf: cf_id.toString(),
                value: fd_value.toString(),
                user_id: session.user_id,
                company_id: session.company_id,
                secret_key: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectDetGL_EditCustProf_Alert_Success_Title'),
                    text: t('ProspectDetGL_EditCustProf_Alert_Success_Text'),
                    timer: 1500
                })
                .then(result => {
                    onSuccess();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectDetGL_EditCustProf_Alert_Failed_Title'),
                    text: t('ProspectDetGL_EditCustProf_Alert_Failed_Text')
                })
                .then(result => {
                    setIsLoading(false);
                });
            }

        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });
    }

    return(
        <Modal show={open} onHide={closeModal}>
            <Formik
                validationSchema={sendEditSchema}
                onSubmit={sendEdit}
                initialValues={prosData}
            >
                {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title as={"h6"}>{t('ProspectDetGL_EditCustProf_Modal_Title')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            {
                                isLoading ? (<div style={{height: "40vh", width: "100%"}}><Loader/></div>) : (
                                    
                                    <div style={{overflow: "auto", height: "65vh", width: "100%"}} className="py-4 px-4">

                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('ProspectDetGL_Sidebar_CustomerProfile_FullName')} *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.pros_name}
                                                isInvalid={errors.pros_name && touched.pros_name}
                                                onChange={handleChange('pros_name')}
                                            />
                                            {errors.pros_name && touched.pros_name && <div className="op-error-message">{errors.pros_name}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Corporate *</Form.Label>
                                            <FormSelect
                                                options={corporateOpt}
                                                valueDefault={corporateOpt.filter(opt => opt.value == values.cf_person[0].fd_values)}
                                                isInvalid={errors.cf_corporate && touched.cf_corporate}
                                                onChange={
                                                    (info)=>{
                                                        cfPerson.map(
                                                            (record, index) => {
                                                                const updatedCustomFields = [...values.cf_person];

                                                                if (record.cf_id == 5000) {
                                                                    updatedCustomFields[index].fd_values = info.value;
                                                                    setFieldValue('cf_corporate', info.value);
                                                                    setFieldValue('cf_allow', info.value === 'Yes' ? ["5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"]:"");
                                                                }

                                                                if (record.cf_id === '5001') {
                                                                    updatedCustomFields[index].fd_values = null;
                                                                    setFieldValue('cf_idType', "");
                                                                }

                                                                if (record.cf_id === '5004') {
                                                                    if (info.value === "No") {
                                                                        updatedCustomFields[index].fd_values = "";
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }

                                                                if (record.cf_id === '5011') {
                                                                    if (info.value === "Yes") {
                                                                        updatedCustomFields[index].fd_values = "No";
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }

                                                                if (values.cf_corporate_no_1.includes(record.cf_id)) {
                                                                    if (info.value === 'Yes') {
                                                                        updatedCustomFields[index].fd_values = "Not Applicable";
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }

                                                                if (values.cf_corporate_no_2.includes(record.cf_id)) {
                                                                    if (info.value === 'Yes') {
                                                                        updatedCustomFields[index].fd_values = "OTHERS";
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }

                                                                if (record.cf_id === '5005') {
                                                                    if (info.value === "Yes") {
                                                                        var newDate = new Date();
                                                                        updatedCustomFields[index].fd_values = moment(newDate).format("YYYY-MM-DD");
                                                                        setFieldValue('cf_dob', moment(newDate).format("YYYY-MM-DD"));
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }

                                                                if (record.cf_id == 5006) {
                                                                    if (info.value == "Yes") {
                                                                        updatedCustomFields[index].fd_values = "";
                                                                    }else{
                                                                        updatedCustomFields[index].fd_values = record.fd_value;
                                                                    }
                                                                }
                                                            }
                                                        )
                                                    }
                                                }
                                            />
                                            {errors.cf_corporate && touched.cf_corporate && <div className="op-error-message">{errors.cf_corporate}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Identification Type *</Form.Label>
                                            <FormSelect
                                                options={
                                                    values.cf_person[0].fd_values === 'Yes' ? idTypeOptState.filter(option => option.corporate === values.cf_person[0].fd_values) :
                                                    values.cf_person[0].fd_values === 'No' ? idTypeOptState.filter(option => option.corporate === values.cf_person[0].fd_values) :
                                                    idTypeOptState
                                                }
                                                value={values.cf_person[1].fd_values ? idTypeOptState.filter(option => option.value === values.cf_person[1].fd_values) : null}
                                                isInvalid={errors.cf_idType && touched.cf_idType}
                                                onChange={
                                                    (info) => {
                                                        const updatedCustomFields = [...values.cf_person];
                                                        cfPerson.map((record, index) => {
                                                            if (record.cf_id === '5001') {
                                                                updatedCustomFields[index].fd_values = info.value;
                                                                setFieldValue('cf_idType', info.value);
                                                                setFieldValue('cf_allow2', info.value === "NRIC No." ? ["5000","5001","5002","5004","5006"] : info.value === "Passport" ? ["5000","5001","5002","5004","5006"] : (info.value === "Reg No." || info.value === "Others") ? ["5000","5001","5002","5003","5005","5006","5007","5008","5009","5010","5011","5013","5014","5015","5016","5017"] : ["5000","5001","5002"]);
                                                            }
                                                        })
                                                    }
                                                }
                                            />
                                            {errors.cf_idType && touched.cf_idType && <div className="op-error-message">{errors.cf_idType}</div>}
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Identification No 1 *</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.cf_person[2].fd_values}
                                                isInvalid={errors.cf_id_num && touched.cf_id_num}
                                                // maxLength={values.cf_idType === 'NRIC No.' ? 12 : ""}
                                                onChange={
                                                    (info) => {
                                                        cfPerson.map((record, index) => {
                                                            const updatedCustomFields = [...values.cf_person];
                                                            if (record.cf_id === '5002') {
                                                                updatedCustomFields[index].fd_values = info.target.value;
                                                                setFieldValue('cf_id_num', info.target.value);
                                                            }
                                                        })
                                                    }
                                                }
                                            />
                                            {errors.cf_id_num && touched.cf_id_num && <div className="op-error-message">{errors.cf_id_num}</div>}
                                        </Form.Group>
                                        {
                                            cfPerson.map((record, index) => (
                                                !values.cf_allow.includes(record.cf_id) && !values.cf_allow2.includes(record.cf_id) &&

                                                <FormCustomField
                                                    key={record.cf_id}
                                                    id={record.cf_id}
                                                    label={record.cf_id == '5014' ? 'Monthly Income' : record.cf_label}
                                                    isDisabled={record.cf_id == '5014' ? true : false}
                                                    options={record.cf_value}
                                                    type={record.ctf_title}
                                                    name={record.cf_id}
                                                    placeholder=""
                                                    valueDefault={(record.cf_id == 5014) ? 'Not Applicable' : values.cf_person[index].fd_values}
                                                    onChange={
                                                        (info) => {
                                                            const updatedCustomFields = [...values.cf_person];
                                                            if (record.ctf_title === 'Select') {
                                                                updatedCustomFields[index].fd_values = info.value;
                                                            }else if(record.ctf_title === 'Date'){
                                                                if (record.cf_id === '5005') {
                                                                    setFieldValue('cf_dob', info.target.value);
                                                                }
                                                                updatedCustomFields[index].fd_values = info.target.value;
                                                            }else{
                                                                updatedCustomFields[index].fd_values = info.target.value;
                                                            }
                                                        }
                                                    }
                                                />
                                            ))
                                        }
                                    </div>

                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_EditCustProf_Modal_Footer_Submit')}</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );

}

export default EditCustomerProfileVN;