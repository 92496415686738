import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import NoRecord from "../includes/NoRecord";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { setCurrency } from "../lib/js/Function";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const MarketingAnalyticReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState([]);
    const [pipeList, setPipeList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);
    
    const  [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [pipeID, setPipeID] = useState("");

    const { t } = useTranslation();

    const vietnam = [402, 412];

    const getAllPipeline = () => {
        
        axios.get("ws/ws_setting.php", {
            params:{
                task: "getAllPipeline",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var pipeData = data.record.map((record)=>{
                    return {label: record.pipeline_title, value: record.pipeline_id};
                });
                setPipeList(pipeData);
            }else{
                setPipeList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [reportColumn] = useState([
        {
            headerName: t('Report_MarketingAnalytic_HeaderName1'),
            field: "ds_title",
            resizable: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: t('Report_MarketingAnalytic_HeaderName2'),
            field: "total_deal",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 250
        },
        {
            headerName: t('Report_MarketingAnalytic_HeaderName3'),
            field: "cpl",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    console.log(vietnam.includes(Number(session.company_id)));
                    return !vietnam.includes(Number(session.company_id)) ? setCurrency(params.data.cpl) : params.data.cpl + " VND";
                }
            }
        },
        {
            headerName: t('Report_MarketingAnalytic_HeaderName4'),
            field: "total_won_deal",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 250
        },
        {
            headerName: t('Report_MarketingAnalytic_HeaderName5'),
            field: "cpconversion",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    return vietnam.includes(Number(session.company_id)) ? params.data.cpconversion + " VND" : setCurrency(params.data.cpconversion);
                }
            }
        },
        {
            headerName: t('Report_MarketingAnalytic_HeaderName6'),
            field: "total_duplicate_deal",
            resizable: true,
            headerClass: "center",
            cellClass: "center",
            width: 250
        }
    ]);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const getChartGridData = useCallback(()=>{

        axios.get("ws/ws_marketing_analytics.php",{
            params:{
                task: "marketingAnalyticsReportV2",
                ds: startDate,
                de: endDate,
                pipeline: pipeID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;
            
            if (Number(data.status) === 0) {
                var chartData = data.record.map((record)=>{
                    return {name: record.ds_title, value: record.total_deal};
                });

                chartData.sort((a,b)=> b['value'] - a['value']);

                var chartData2 = [];

                var arraySize = Number(data.totalRecord) > 10 ? 10 : Number(data.totalRecord);

                for (let index = 0; index < Number(arraySize); index++) {
                    chartData2.push(chartData[index]);
                    
                }

                setChartData(chartData2);

                if (Number(data.totalRecord) > 0) {
                    setRecordFound(data.totalRecord);
                    setIsGrid(true);
                }else{
                    setRecordFound(0);
                    setIsGrid(false);
                }

                setRowData(data.record.sort((a, b)=> b['total_deal'] - a['total_deal']));
            }else{
                chartData([]);
                setIsGrid(false);
                setRecordFound(0);
                setRowData([]);
                Swal.fire({
                    icon: "error",
                    title: t('Report_alert_title'),
                    text: t('Report_alert_Text')
                });
            }
        });
    }, [startDate, endDate, pipeID, session]); // eslint-disable-line react-hooks/exhaustive-deps

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div>{`${t('Report_MarketingAnalytic_TotalDeal')}:`}</div>
                        <div>{`${payload[0].value}`}</div>
                    </Card.Body>
                </Card>

            );
        }
        
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllPipeline();
            getChartGridData();
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = () => {
        var params = {
            fileName : 'Marketing-Analytic-dataExport.csv',
            processCellCallback : function(params) {

                if(params.column.colId === 'auto_increment_num'){
                    return params.node.data.temprecord_id;
                }else{
                    var res = params.value;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return res;
                }

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <div>
            { isLoading ? (<Loader/>):(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mb-4 mt-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group className="ms-auto" style={{width: '250px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllPipeline')}
                                    options={pipeList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setPipeID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light" onClick={()=>{getChartGridData()}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-4">
                        <Card className="shadow-sm border-0">
                            <Card.Header className="d-flex justify-content-center align-items-center py-2">
                                <h6 className="mt-2">{t('Report_MarketingAnalytic_GraphTitle')}</h6>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="98%" height={530}>
                	                <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={chartData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                          }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number"/>
                                        <YAxis dataKey="name" type="category" />
                                        <Tooltip content={<CustomTooltip />}/>
                                        <Bar dataKey="value" stackId="a" fill="#0089cb" name="Deal Source" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div>
                        <div>
                            <Stack className="d-flex" direction="horizontal" gap={1}>
                                <div className="p-2">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                                <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleDownload}>{t('Report_Download')}</button>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={70}
                                pagination={true}
                                paginationPageSize={30}
                                paginationPageSizeSelector={false}
                            />
                            </div>
                        </div>
                        {!isGrid && <NoRecord message={t('Report_Grid_NoRecord_Message')} description={t('Report_Grid_NoRecord_Description')} width={300} />}
                    </div>
                </Container>
            ) }
        </div>
    );

}

export default MarketingAnalyticReport;