import { useState, useRef, useEffect, useMemo } from "react";
import { Stack } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
// import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";

const Referral = ({ prospectID }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const gridRef2 = useRef();

    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isGrid2, setIsGrid2] = useState(true);
    const [recordFound2, setRecordFound2] = useState(0);
    const [rowData2, setRowData2] = useState([]);

    const reportColDef = useMemo(() => {
        return {
          sortable: true,
          unSortIcon: true,
          filter: false,
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "33vh": "33vh" , paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const containerStyle2 = useMemo(() => ({ width: "100%", height: isGrid2 ? "33vh": "33vh" , paddingTop: 10 }), [isGrid2]);
    const gridStyle2 = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        {
            headerName: "Referee Name",
            field: "",
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Source",
            field: "",
            width: 150
        },
        {
            headerName: "Project",
            field: "",
            width: 150
        },
        {
            headerName: "Referee Status",
            field: "",
            width: 150
        },
        {
            headerName: "Fee Status",
            field: "",
            width: 150
        },
        {
            headerName: "Owner",
            field: "",
            width: 160
        },
        {
            headerName: "Created",
            field: "",
            width: 150
        },
        {
            headerName: "Modified",
            field: "",
            width: 150
        }
    ];

    const gridColumn2 = [
        {
            headerName: "Name",
            field: "name",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Identification Number 1 (Newest)",
            field: "idNo",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Contact Number 1",
            field: "contact1",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: 'Owner',
            field: 'owner',
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Remarks",
            field: "remark",
            flex: 1,
            minWidth: 250
        }
    ];

    const getGridData = () => {
        setRowData([]);
        setRecordFound(0);
        setIsGrid(true);
    }

    const getGridData2 = () => {
        axios.get("ws/ws_prospect_introducer.php", {
            params: {
                task: "GetProspectIntroducer",
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;
            if (Number(data.status_introducer) === 0) {
                var dataset = data.record_introducer.map((data) => {
                    return {
                        name: data.introducer_name,
                        idNo: "",
                        contact1: "",
                        remark: "",
                        owner: ""
                    };
                });
                setRowData2(dataset);
            }else{
                setRowData2([]);
            }
        })
        .catch(error => {
            console.log(error);
            setRowData2([]);
        });

        setRecordFound2(0);
        setIsGrid2(true);
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
            getGridData2();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <>
            <Stack direction="horizontal" gap={1}>
                <div className="ms-auto">{Number(recordFound) === 1 ? `${recordFound} record found.` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}</div>
            </Stack>
            <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={gridColumn}
                        rowData={rowData}
                        defaultColDef={reportColDef}
                        rowHeight={50}
                    />
                </div>
            </div>

            <Stack direction="horizontal" gap={1} className="mt-3">
                <div>Referred by</div>
                <div className="ms-auto">{Number(recordFound2) === 1 ? `${recordFound2} record found.` : Number(recordFound2) > 1 ? `${recordFound2} records found.` : `No record found.`}</div>
            </Stack>

            <div style={containerStyle2}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle2 }}>
                    <AgGridReact
                        ref={gridRef2}
                        columnDefs={gridColumn2}
                        rowData={rowData2}
                        defaultColDef={reportColDef}
                        rowHeight={50}
                    />
                </div>
            </div>
        </>
    );
}

export default Referral;
