import { useState, useRef, useEffect, useCallback } from "react";
import { Container, Stack, Card, Form, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, } from 'recharts';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/pro-duotone-svg-icons";
import Loader from "../includes/Loader";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";

const InboundAnalyticReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [dsCatList, setDsCatList] = useState([]);
    const [dsList, setDsList] = useState([]);
    const [buList, setBuList] = useState([]);
    const [pastMonthList, setPastMonthList] = useState([]);

    const [isChart1, setIsChart1] = useState(false);
    const [chart1Bar, setChart1Bar] = useState([]);
    const [chart1Pie, setChart1Pie] = useState([]);

    const [isChart2, setIsChart2] = useState(false);
    const [chart2Bar, setChart2Bar] = useState([]);
    const [chart2Pie, setChart2Pie] = useState([]);

    const [dsCatID, setdsCatID] = useState("");
    const [dsID, setDsID] = useState("");
    const [buID, setBuID] = useState("");
    const [dateRange, setDateRange] = useState("1");
    const [dateRange2, setDateRange2] = useState("");

    const [chart1BarSize, setChart1BarSize] = useState(6);
    const [chart2BarSize, setChart2BarSize] = useState(6);

    const [chart1PieSize, setChart1PieSize] = useState(6);
    const [chart2PieSize, setChart2PieSize] = useState(6);

    const [chart1Color, setChart1Color] = useState([]);
    const [chart2Color, setChart2Color] = useState([]);

    // const RADIAN = Math.PI / 180;

    const dateRangeList = [
        {label: t('Report_InbAnalytic_DateRangeList1'), value: "1"},
        {label: t('Report_InbAnalytic_DateRangeList2'), value: "2"},
        {label: t('Report_InbAnalytic_DateRangeList3'), value: "3"},
        {label: t('Report_InbAnalytic_DateRangeList4'), value: "4"},
        {label: t('Report_InbAnalytic_DateRangeList5'), value: "5"},
        {label: t('Report_InbAnalytic_DateRangeList6'), value: "6"},
    ];

    const getAllSource = () => {
        
        axios.get('ws/ws_source.php',{
            params:{
                task: 2,
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var dsCatData = data.source_cat.map((data)=>{
                    return {label: data.source_category_title, value: data.source_category_id}
                });

                var dsData = data.record.map((data)=>{
                    return {label: data.ds_title, value: data.ds_id, category: data.source_category_id};
                });

                setDsCatList(dsCatData);
                setDsList(dsData);
            }else{
                setDsCatList([]);
                setDsList([]);
            }
        });
    }

    const getAllBU = () => {
    
        axios.get("ws/ws_setting.php",{
            params:{
                task: "getAllBU",
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var buData = data.record.map((data)=>{
                    return {label: data.unit_title, value: data.business_unit_id};
                });

                setBuList(buData);
            }else{
                setBuList([]);
            }
        })
        .catch(error => {
            setBuList([]);
        });
    }

    const getChart1Data = useCallback(()=>{
        
        axios.get("ws/ws_marketing_analytics.php",{
            params:{
                task: "marketingAnalyticsReport_BU",
                dateRange: dateRange,
                dateRange2: dateRange2,
                sourceCat_id: dsCatID,
                source_id: dsID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var chartData = [];
                var pieData = [];
                var buIDArr = [];
                
                for(let i = 0; i < data.buTitle.length; i++){
                    var totalValBU = 0;

                    for(let j = 0; j < data.record.length; j++){
                        var fieldName = `b${data.buTitle[i].business_unit_id}`;
                        totalValBU = totalValBU + Number(data.record[j][fieldName]);
                        
                    }

                    buIDArr.push(`b${data.buTitle[i].business_unit_id}`);

                    if (Number(totalValBU) > 0) {
                        pieData.push({name: data.buTitle[i].unit_title , value: totalValBU});
                    }
                }

                data.record.forEach((data)=>{
                    var dateFormat = '';
                    if (Number(dateRange) === 5) {
                        var string = moment(data.deal_date_time_create).format("MMMM YYYY").split(" ");
                        dateFormat = string[0].slice(0,3) + " " + string[1];
                    } else if (Number(dateRange) === 6) {
                        dateFormat = moment(data.deal_date_time_create).format("YYYY");
                    } else {
                        dateFormat = moment(data.deal_date_time_create).format("YYYY-MM-DD");
                    }

                    chartData.push({name: dateFormat});
                });

                for (let index = 0; index < data.record.length; index++) {
                    Object.keys(data.record[index]).forEach((keys)=>{
                        if (buIDArr.includes(keys)) {
                            chartData[index][keys] = data.record[index][keys];
                        }
                    });
                }

                setChart1Bar(chartData);
                setChart1Pie(pieData);
                setIsChart1(true);
            }else{
                setChart1Bar([]);
                setChart1Pie([]);
                setIsChart1(false);

                Swal.fire({
                    icon: 'error',
                    title: t('Report_alert_Title'),
                    text: t('Report_alert_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })

            setChart1Bar([]);
            setChart1Pie([]);
            setIsChart1(false);
        });
    },[dateRange, dsCatID, dsID, dateRange2, session]);

    const getChart2Data = useCallback(()=>{
        
        axios.get('ws/ws_marketing_analytics.php',{
            params:{
                task: "marketingAnalyticsReport_BU2",
                dateRange: dateRange,
                dateRange2: dateRange2,
                bu_id: buID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var chartData = [];
                var pieData = [];
                var dsID = [];

                for (let index = 0; index < data.catTitle_arr.length; index++) {
                    var totalValDs = 0;

                    for (let j = 0; j < data.record.length; j++) {
                        var fieldName = `b${data.catTitle_arr[index].source_category_id}`;
                        totalValDs = totalValDs + Number(data.record[j][fieldName]);
                    }
                    dsID.push(`b${data.catTitle_arr[index].source_category_id}`);
                    if (totalValDs !== 0) {
                        pieData.push({name: data.catTitle_arr[index].source_category_title, value: totalValDs});
                    }
                }

                data.record.forEach((data)=>{

                    var dateFormat = '';
                    if (Number(dateRange) === 5) {
                        var string = moment(data.deal_date_time_create).format("MMMM YYYY").split(" ");
                        dateFormat = string[0].slice(0,3) + " " + string[1];
                    } else if (Number(dateRange) === 6) {
                        dateFormat = moment(data.deal_date_time_create).format("YYYY");
                    } else {
                        dateFormat = moment(data.deal_date_time_create).format("YYYY-MM-DD");
                    }

                    chartData.push({name: dateFormat});
                });

                for (let index = 0; index < data.record.length; index++) {
                    Object.keys(data.record[index]).forEach((keys)=>{
                        if (dsID.includes(keys)) {
                            chartData[index][keys] = data.record[index][keys];
                        }
                    });
                }

                setChart2Bar(chartData);
                setChart2Pie(pieData);
                setIsChart2(true);
            }else{
                setChart2Bar([]);
                setChart2Pie([]);
                setIsChart2(false);

                Swal.fire({
                    icon: 'error',
                    title: t('Report_alert_Title'),
                    text: t('Report_alert_Text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })

            setChart2Bar([]);
            setChart2Pie([]);
            setIsChart2(false);
        });
    },[dateRange, buID, dateRange2, session]);

    const getRandomColor = () =>{

        var color1 = [];
        var color2 = [];
        

        for (var i = 0; i < 100; i++) {
            color1.push('#' + Math.floor(Math.random() * 16777215).toString(16));
        }

        for (var j = 0; j < 100; j++) {
            color2.push('#' + Math.floor(Math.random() * 16777215).toString(16));
        }

        setChart1Color(color1);
        setChart2Color(color2);
    }

    const getChartName = (id, type) => {
        var name = "";

        if (type === "bu") {
            buList.forEach((data)=>{
                if (data.value === id.replace("b","")) {
                    name = data.label;
                }
            });
        }else{
            dsCatList.forEach((data)=>{
                if (data.value === id.replace("b","")) {
                    name = data.label;
                }
            });
        }
        
        return name;
    }

    const CustomTooltip = ({ active, payload, label }) =>{

        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        {
                            payload.map((info, i)=>(
                                <div key={`${info.value}_${i}`} style={{color: info.color}}>
                                    {`${info.name}: ${info.value}`}
                                </div>
                            ))
                        }
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    // const renderCustomizedLabel = (props, index) => {
    //     const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
    //     const sin = Math.sin(-RADIAN * midAngle);
    //     const cos = Math.cos(-RADIAN * midAngle);
    //     const delta = Math.abs(1 / cos) + 10;
    //     const sx = cx + outerRadius * cos;
    //     const sy = cy + outerRadius * sin;
    //     const mx = cx + (outerRadius + delta) * cos;
    //     const my = cy + (outerRadius + delta) * sin;
    //     const ex = mx + Number(cos.toFixed(1)) * 100;
    //     const ey = my;
    //     const textAnchor = cos >= 0 ? "start" : "end";
    //     const adjustedEy = ey + index * 5;
        
    //     if (Number(value) > 0) {
    //       return (
    //         <>
    //             {value !== '0' && <g>
    //                 <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
    //                 <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
    //                 <text x={ex + (cos >= 0 ? 1 : -1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
    //                     {`${payload.name}: ${value}`}
    //                 </text>
    //             </g>}
    //         </>
    //       );
    //     }
    //   };

    const CustomTooltipPie = ({ active, payload }) => {

        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>
                            {payload[0].name}
                        </div>
                        {/* {
                            payload.forEach((info, i)=>(
                                <div key={`${info.payload.name}_${i}`}>
                                    {`${info.payload.name}`}
                                </div>
                            ))
                        } */}
                    </Card.Header>
                    <Card.Body>
                        <div>
                            {`Total Deal: ${payload[0].value}`}
                        </div>
                        {/* {
                            payload.forEach((info, i)=>(
                                <div key={`${info.value}_${i}`} className="d-flex justify-content-center align-items-center">
                                    {`Total Deal: ${info.value}`}
                                </div>
                            ))
                        } */}
                    </Card.Body>
                </Card>
            );
        }

    }

    const getPastMonth = () => {
        var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
        var monthVal = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var d = new Date();
        var pastMonth = [];
        d.setDate(1);
        for (let i=0; i<=11; i++) {
            d.setMonth(d.getMonth() - 1);
            pastMonth.push({label: monthName[d.getMonth()] + ' ' + d.getFullYear(), value: d.getFullYear() + '-' + monthVal[d.getMonth()]});
        }

        setPastMonthList(pastMonth);
    }

    const handleExpand = (chart) =>{
        if (chart === "chart1bar") {
            if (chart1BarSize === 6) {
                setChart1BarSize(12);
            }else{
                setChart1BarSize(6);
            }
        }else if(chart === "chart1pie"){
            if (chart1PieSize === 6) {
                setChart1PieSize(12);
            }else{
                setChart1PieSize(6);
            }
        }else if (chart === "chart2bar") {
            if (chart2BarSize === 6) {
                setChart2BarSize(12);
            }else{
                setChart2BarSize(6);
            }
        }else if(chart === "chart2pie"){
            if (chart2PieSize === 6) {
                setChart2PieSize(12);
            }else{
                setChart2PieSize(6);
            }
        }
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getPastMonth();
            getAllSource();
            getAllBU();
            getChart1Data();
            getChart2Data();
            getRandomColor();
            setIsLoading(false);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            { isLoading ? (<Loader />):(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <Form.Group style={{width: "180px"}}>
                                <FormSelect
                                    placeholder="Select Date Range"
                                    options={dateRangeList}
                                    valueDefault={dateRangeList.filter(option => option.value === dateRange)}
                                    onChange={(e)=>{
                                        setDateRange(e.value);
                                        e.value === '3' ? setDateRange2(pastMonthList[0].value):setDateRange2("");
                                    }}
                                />
                            </Form.Group>
                            {dateRange === '3' && <Form.Group style={{width: "180px"}}>
                                <FormSelect
                                    placeholder="Select past month"
                                    options={pastMonthList}
                                    valueDefault={pastMonthList[0]}
                                    onChange={(e)=>{setDateRange2(e ? e.value:"")}}
                                />
                            </Form.Group>}
                            {(dateRange === '4' || dateRange === '5') && <Form.Group style={{width: "180px"}}>
                                <Form.Control 
                                    type="date"
                                    onChange={(e)=>{setDateRange2(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>}
                            <button className="btn op-button op-primary-color text-light shadow ms-auto" onClick={()=>{getChart1Data(); getChart2Data();}}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-3">
                        <div className="mb-3">
                            <Stack direction="horizontal" gap={1}>
                                <Form.Group style={{width: "250px"}}>
                                    <FormSelect
                                        placeholder={t('Report_InbAnalytic_AllMktgCat')}
                                        options={dsCatList}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(e)=>{setdsCatID(e ? e.value:"")}}
                                    />
                                </Form.Group>
                                <Form.Group style={{width: "250px"}}>
                                    <FormSelect
                                        placeholder={t('Report_InbAnalytic_AllMktgSrc')}
                                        options={dsCatID ? dsList.filter(option => option.category === dsCatID):dsList}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(e)=>{setDsID(e ? e.value:"")}}
                                    />
                                </Form.Group>
                            </Stack>
                        </div>
                            
                        <div>
                            <Row>
                                <Col className="mb-3" sm={chart1BarSize}>
                                    <Card className="shadow-sm border-0">
                                        <Card.Header className="py-2">
                                            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                                                <h6 className="mt-2">{t('Report_InbAnalytic_ChartTitle1')}</h6>
                                                <div className="ms-auto" style={{cursor: "pointer"}} onClick={()=>{handleExpand('chart1bar')}}>
                                                    <FontAwesomeIcon icon={faExpand} size="xl"/>
                                                </div>
                                            </Stack>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart
                                                    width={500}
                                                    height={400}
                                                    data={chart1Bar}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                    {
                                                        isChart1 && Object.keys(chart1Bar[0])
                                                        .filter(key => key !== 'name')
                                                        .map((key, i)=>(
                                                            <Bar key={`bar${i}`} dataKey={key} stackId="a" fill={chart1Color[i % chart1Color.length]} name={getChartName(key, "bu")}/>
                                                        ))
                                                    }
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={chart1PieSize}>
                                    <Card className="shadow-sm border-0">
                                        <Card.Header className="py-2">
                                            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                                                <h6 className="mt-2">{t('Report_InbAnalytic_ChartTitle1')}</h6>
                                                <div className="ms-auto" style={{cursor: "pointer"}} onClick={()=>{handleExpand('chart1pie')}}>
                                                    <FontAwesomeIcon icon={faExpand} size="xl"/>
                                                </div>
                                            </Stack>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <PieChart width={400} height={400}>
                                                <Legend verticalAlign="top" wrapperStyle={{ left: "2%"}} />
                                                <Pie
                                                    data={chart1Pie} 
                                                    dataKey="value" 
                                                    cx="50%" 
                                                    cy="50%" 
                                                    innerRadius={70} 
                                                    outerRadius={90}  
                                                    label= {({ name, value }) =>
                                                    `${name}: ${value} `
                                                    }
                                                    // label={(props) =>
                                                    //     renderCustomizedLabel(
                                                    //       props,
                                                    //       chart1Pie.findIndex((item) => item.name === props.payload.name && Number(props.payload.value) > 0)
                                                    //     )
                                                    // }
                                                >
                                                    {isChart1 && chart1Pie.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={chart1Color[index % chart1Color.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip content={<CustomTooltipPie />} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="mb-3">
                            <Stack direction="horizontal">
                                <Form.Group style={{width: "250px"}}>
                                    <FormSelect
                                        placeholder={t('Report_AllBU')}
                                        options={buList}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(e)=>{setBuID(e ? e.value:"")}}
                                    />
                                </Form.Group>
                            </Stack>
                        </div>
                        <div>
                            <Row>
                                <Col className="mb-3" sm={chart2BarSize}>
                                    <Card className="shadow-sm border-0">
                                        <Card.Header className="py-2">
                                            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                                                <h6 className="mt-2">{t('Report_InbAnalytic_ChartTitle2')}</h6> 
                                                <div className="ms-auto" style={{cursor: "pointer"}} onClick={()=>{handleExpand('chart2bar')}}>
                                                    <FontAwesomeIcon icon={faExpand} size="xl"/>
                                                </div>
                                            </Stack>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                <BarChart
                                                    width={500}
                                                    height={400}
                                                    data={chart2Bar}
                                                >
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis dataKey="name" />
                                                    <YAxis />
                                                    <Tooltip content={<CustomTooltip />} />
                                                    <Legend />
                                                    {
                                                        isChart2 && Object.keys(chart2Bar[0])
                                                        .filter(key => key !== 'name')
                                                        .map((key, i)=>(
                                                            <Bar key={`${key}_${i}`} dataKey={key} stackId="a" fill={chart2Color[i % chart2Color.length]} name={getChartName(key, "ds")}/>
                                                        ))
                                                    }
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={chart2PieSize}>
                                    <Card className="shadow-sm border-0">
                                        <Card.Header className="py-2">
                                            <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                                                <h6 className="mt-2">{t('Report_InbAnalytic_ChartTitle2')}</h6> 
                                                <div className="ms-auto" style={{cursor: "pointer"}} onClick={()=>{handleExpand('chart2pie')}}>
                                                    <FontAwesomeIcon icon={faExpand} size="xl"/>
                                                </div>
                                            </Stack>
                                        </Card.Header>
                                        <Card.Body>
                                            <ResponsiveContainer width="100%" height={400}>
                                                
                                                <PieChart width={400} height={400}>
                                                <Legend verticalAlign="top" wrapperStyle={{ left: "2%"}} />
                                                <Pie
                                                    data={chart2Pie} 
                                                    dataKey="value" 
                                                    cx="50%" 
                                                    cy="50%" 
                                                    innerRadius={70} 
                                                    outerRadius={90} 
                                                    label= {({ name, value }) =>
                                                    `${name}: ${value} `
                                                    }
                                                >
                                                    {isChart2 && chart2Pie.map((entry, index) => (
                                                        <Cell key={`cell2-${index}`} fill={chart2Color[index % chart2Color.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip content={<CustomTooltipPie />}/>
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            ) }
        </div>
    );
}

export default InboundAnalyticReport;