import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { Container, Stack, Card, Form } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import FormSelect from "../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const InboundLeadPerformanceReport = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [dsCatList, setDsCatList] = useState([]);
    const [dsList, setDsList] = useState([]);
    const [buList, setBuList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [isGrid, setIsGrid] = useState(false);
    // const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [dsCatID, setDsCatID] = useState("");
    const [dsID, setDsID] = useState("");
    const [buID, setBuID] = useState("");
    const [userID, setUserID] = useState("");

    const { t } = useTranslation(); 

    const getAllSource = () => {

        axios.get('ws/ws_source.php',{
            params:{
                task: 2,
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {

                var dsData = data.record.map((data)=>{
                    return {label: data.ds_title, value: data.ds_id, source_category_id: data.source_category_id};
                });

                var catData = data.source_cat.map((data)=>{
                    return {label: data.source_category_title, value: data.source_category_id};
                });

                setDsCatList(catData);
                setDsList(dsData);
            }else{
                setDsCatList([]);
                setDsList([]);
            }
        })
        .catch(error => {
            setDsCatList([]);
            setDsList([]);
        });
    }

    const getAllBU = () => {
        
        axios.get('ws/ws_setting.php',{
            params:{
               task: "getAllBU",
               company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var buData = data.record.map((data) => {
                    return {label: data.unit_title, value: data.business_unit_id};
                });
                setBuList(buData);
            }else{
                setBuList([]);
            }
        })
        .catch(error => {
            setBuList([]);
        });
    }

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((data) => {
                    return {label: data.user_name, value: data.user_id}
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        })
        .catch(error => {
            setUserList([]);
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "70vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const reportColumn = [
        {
            headerName: t('Report_InbLeadPer_HeaderName1'),
            field: "user_name",
            flex: 1,
            resizable: true,
            minWidth: 250
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName2'),
            field: "TOTAL_LEADS_RECEIVED",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName3'),
            field: "TOTAL_UNCONTACTED",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName4'),
            field: "TOTAL_CONTACTED",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName5'),
            field: "TOTAL_APPOINTMENT",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName6'),
            field: "TOTAL_WON_DEALS",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t('Report_InbLeadPer_HeaderName7'),
            field: "TOTAL_LOST_DEALS",
            resizable: true,
            width: 250,
            headerClass: "center",
            cellClass: "center"
        }
    ];

    const getChartGridData = useCallback(()=>{
        
        axios.get('ws/ws_performance.php',{
            params:{
                task: "performanceReport_round_robin",
                ds: startDate,
                de: endDate,
                sourceCat: dsCatID,
                source: dsID,
                bu: buID,
                user: userID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var chartData = data.record.map((data)=>{
                    return {
                        name: data.user_name,
                        data1: data.TOTAL_LEADS_RECEIVED,
                        data2: data.TOTAL_UNCONTACTED,
                        data3: data.TOTAL_CONTACTED,
                        data4: data.TOTAL_APPOINTMENT,
                        data5: data.TOTAL_WON_DEALS,
                        data6: data.TOTAL_LOST_DEALS,
                    }
                });
                setChartData(chartData);
                setIsGrid(true);
                setRowData(data.record);



                var TOTAL_LEADS_RECEIVED = 0;
                var TOTAL_UNCONTACTED = 0;
                var TOTAL_CONTACTED = 0;
                var TOTAL_APPOINTMENT = 0;
                var TOTAL_WON_DEALS = 0;
                var TOTAL_LOST_DEALS = 0;

                data.record.forEach((data) => {
                    TOTAL_LEADS_RECEIVED = TOTAL_LEADS_RECEIVED + parseInt(data.TOTAL_LEADS_RECEIVED);
                    TOTAL_UNCONTACTED = TOTAL_UNCONTACTED + parseInt(data.TOTAL_UNCONTACTED);
                    TOTAL_CONTACTED = TOTAL_CONTACTED + parseInt(data.TOTAL_CONTACTED);
                    TOTAL_APPOINTMENT = TOTAL_APPOINTMENT + parseInt(data.TOTAL_APPOINTMENT);
                    TOTAL_WON_DEALS = TOTAL_WON_DEALS + parseInt(data.TOTAL_WON_DEALS);
                    TOTAL_LOST_DEALS = TOTAL_LOST_DEALS + parseInt(data.TOTAL_LOST_DEALS);
                });

                var result = [];

                result.push({
                    user_name: 'TOTAL',
                    TOTAL_LEADS_RECEIVED: TOTAL_LEADS_RECEIVED,
                    TOTAL_UNCONTACTED: TOTAL_UNCONTACTED,
                    TOTAL_CONTACTED: TOTAL_CONTACTED,
                    TOTAL_APPOINTMENT: TOTAL_APPOINTMENT,
                    TOTAL_WON_DEALS: TOTAL_WON_DEALS,
                    TOTAL_LOST_DEALS: TOTAL_LOST_DEALS
                });

                gridRef.current.api.setGridOption('pinnedBottomRowData', result);
            }else{
                setChartData([]);
                setIsGrid(false);
                setRowData([]);
                Swal.fire({
                    icon: "error",
                    title: t('Report_alert_Title'),
                    text: t('Report_alert_Text')
                });
            }
        })
        .catch(error => {
            setChartData([]);
            setIsGrid(false);
            setRowData([]);
        });
    }, [startDate, endDate, dsCatID, dsID, buID, userID, session]);

    const CustomTooltip = ({ active, payload, label }) =>{
        if (active && payload && payload.length) {
            return(
                <Card className="shadow-sm border-0">
                    <Card.Header>
                        <div>{`${label}`}</div>
                    </Card.Header>
                    <Card.Body>
                        <div style={{color: payload[0].color}}>{`${payload[0].name}: ${payload[0].value}`}</div>
                        <div style={{color: payload[1].color}}>{`${payload[1].name}: ${payload[1].value}`}</div>
                        <div style={{color: payload[2].color}}>{`${payload[2].name}: ${payload[2].value}`}</div>
                        <div style={{color: payload[3].color}}>{`${payload[3].name}: ${payload[3].value}`}</div>
                        <div style={{color: payload[4].color}}>{`${payload[4].name}: ${payload[4].value}`}</div>
                        <div style={{color: payload[5].color}}>{`${payload[5].name}: ${payload[5].value}`}</div>
                    </Card.Body>
                </Card>
            );
        }
        return null;
    }

    const handleDownload = () => {
        var params = {
            fileName : 'Inbound-Lead-Performance-dataExport.csv',
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllSource();
            getAllBU();
            getAllUser();
            getChartGridData();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            { isLoading ? (<Loader />):(
                <Container fluid style={{height: "calc(100vh - 56px)", overflow: "auto"}}>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '200px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllDSCat')}
                                    options={dsCatList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setDsCatID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '200px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllDS')}
                                    options={dsCatID !== "" ? dsList.filter(option=> Number(option.source_category_id) === Number(dsCatID)) : dsList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setDsID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <Form.Group className="ms-auto" style={{width: '250px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllBU')}
                                    options={buList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setBuID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <Form.Group style={{width: '200px'}}>
                                <FormSelect
                                    placeholder={t('Report_AllUser')}
                                    options={userList}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(e)=>{setUserID(e ? e.value:"")}}
                                />
                            </Form.Group>
                            <button className="btn op-button op-primary-color text-light shadow" onClick={getChartGridData}>{t('Report_Apply')}</button>
                        </Stack>
                    </div>
                    <div className="mb-4">
                        <Card className="shadow-sm border-0">
                            <Card.Body>
                                <ResponsiveContainer width="98%" height={530}>
                	                <BarChart
                                        width={500}
                                        height={300}
                                        layout="vertical"
                                        data={chartData}
                                        margin={{
                                            top: 15,
                                            right: 10,
                                            left: 40,
                                            bottom: 5,
                                          }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number"/>
                                        <YAxis dataKey="name" type="category" />
                                        <Tooltip content={<CustomTooltip/>}/>
                                        <Legend />
                                        <Bar dataKey="data1" stackId="a" fill="#0089cb" name={t('Report_InbLeadPer_BarName1')} />
                                        <Bar dataKey="data2" stackId="a" fill="#FE5F55" name={t('Report_InbLeadPer_BarName2')} />
                                        <Bar dataKey="data3" stackId="a" fill="#00bf9d" name={t('Report_InbLeadPer_BarName3')} />
                                        <Bar dataKey="data4" stackId="a" fill="#8bffea" name={t('Report_InbLeadPer_BarName4')} />
                                        <Bar dataKey="data5" stackId="a" fill="#e4c272" name={t('Report_InbLeadPer_BarName5')} />
                                        <Bar dataKey="data6" stackId="a" fill="#f315c0" name={t('Report_InbLeadPer_BarName6')} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </div>
                    <div style={{paddingBottom: "10px"}}>
                        <div>
                            <Stack className="d-flex" direction="horizontal" gap={1}>
                                <button className="btn op-button op-primary-color text-light ms-auto" onClick={handleDownload}>{t('Report_Download')}</button>
                            </Stack>
                        </div>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={50}
                                // pagination={false}
                                // paginationPageSize={30}
                                paginationPageSizeSelector={false}
                            />
                            </div>
                        </div>
                    </div>
                </Container>
            ) }
        </div>
    );
}

export default InboundLeadPerformanceReport;