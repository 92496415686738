import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, InputGroup  } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { useTranslation } from "react-i18next";

const SmartlinkTemplateSetting = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [editInitVal, setEditInitVal] = useState();

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingSmartlinkTemplate_headername_1'),
            field: "smart_link_title",
            filter: true,
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_2'),
            field: "open_count",
            resizable: true,
            width: 110
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_3'),
            field: "link_shared_count",
            resizable: true,
            width: 115
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_4'),
            field: "time_spent",
            resizable: true,
            width: 110
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_5'),
            field: "last_opened",
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_6'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                var checked = params.data.inactive;
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <Form.Check
                                type="switch"
                                className="d-flex align-items-center justify-content-center"
                                checked={Number(checked) === 0 ? true:false}
                                onChange={() => {markAsActive(params.data)}}
                            />
                        </div>
                    );
                }
            }
        },
        {
            headerName: t('SettingSmartlinkTemplate_headername_7'),
            resizable: true,
            width: 100,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.smart_link_template_id)}}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        var api_url = '';

        if (session.hostUrlType === 'cloud_staging') {
            api_url = 'https://app.outperformhq.io/docs/smart_link_api_staging.php';
        }else{
            api_url = 'https://app.outperformhq.io/docs/smart_link_api.php';
        }
        
        axios.get(api_url, {
            params: {
                task: "getAllLinks",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {

                const filteredRows = data.record;

                setRecordFound(filteredRows.length);
                setRowData(filteredRows);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(true);
            setIsLoading(false);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const markAsActive = (data) => {
        var api_url;
        var mode = data.inactive;

        if (Number(mode) === 0) {
            mode = 1;
        }else{
            mode = 0;
        }
        
        if (session.hostUrlType === 'cloud_staging') {
            api_url = 'https://app.outperformhq.io/docs/smart_link_api_staging.php';
        }else{
            api_url = 'https://app.outperformhq.io/docs/smart_link_api.php';
        }
        axios.get(api_url, {
            params: {
                task: "smartlinkActive",
                linkid: data.smart_link_template_id,
                mode: mode,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData();
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingSmartlinkTemplate_alert_failed_title'),
                    text: t('SettingSmartlinkTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const addSchema = yup.object().shape({
        template_title: yup.string().required(t('SettingSmartlinkTemplate_required_field')),
        template_item: yup.array().min(1,t('SettingSmartlinkTemplate_required_field')) 
        
    });

    const sendAdd = async(values) => {
        
        const params = new FormData();

        params.append('txtCaption', values.template_title);
        params.append('file', values.template_item[0]);
        params.append('utoken', session.user_ac_token);
        params.append('ctoken', session.company_token);

        try {

            const res = await axios.post(`${session.hostUrl}/${session.hostUrlType}/php/document_smartlinkV2.php`,params);

            let data = res.data;

            if (data.status === "OK") {
                Swal.fire({
                    icon: "success",
                    title: t('SettingSmartlinkTemplate_alert_success_title'),
                    text: t('SettingSmartlinkTemplate_alert_success_title_add'),
                    timer: 1500
                })
                .then(result => {
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingSmartlinkTemplate_alert_failed_title'),
                    text: t('SettingSmartlinkTemplate_alert_failed_text')
                });
            }
            
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }
        
    }

    const editSchema = yup.object().shape({
        template_title_edit: yup.string().required(t('SettingSmartlinkTemplate_required_field'))
    });

    const handleEdit = (data) => {
        setEditInitVal(
            {
                template_id: data.smart_link_template_id,
                template_title_edit: data.smart_link_title
            }
        );
        setIsEdit(true);
    }

    const sendEdit = (values) => {
        var api_url;

        if (session.hostUrlType === 'cloud_staging') {
            api_url = 'https://app.outperformhq.io/docs/smart_link_api_staging.php';
        }else{
            api_url = 'https://app.outperformhq.io/docs/smart_link_api.php';
        }

        axios.get(api_url,{
            params: {
                task: 'updateTemplate',
                linkid: values.template_id,
                title: values.template_title_edit,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingSmartlinkTemplate_alert_success_title'),
                    text: t('SettingSmartlinkTemplate_alert_success_title_edit'),
                    timer: 1500
                })
                .then(result => {
                    setIsEdit(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingSmartlinkTemplate_alert_failed_title'),
                    text: t('SettingSmartlinkTemplate_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        var api_url;

        if (session.hostUrlType === 'cloud_staging') {
            api_url = 'https://app.outperformhq.io/docs/smart_link_api_staging.php';
        }else{
            api_url = 'https://app.outperformhq.io/docs/smart_link_api.php';
        }

        Swal.fire({
            icon: "warning",
            title: t('SettingSmartlinkTemplate_alert_warning_title'),
            text: t('SettingSmartlinkTemplate_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingSmartlinkTemplate_alert_warning_confirm'),
            cancelButtonText: t('Setting_Alert_Warning_CancelText')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get(api_url, {
                    params: {
                        task: "delLink",
                        smart_link_template_id: id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingSmartlinkTemplate_alert_success_title'),
                            text: t('SettingSmartlinkTemplate_alert_success_title_delete'),
                            timer: 1500
                        })
                        .then(result => {
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingSmartlinkTemplate_alert_failed_title'),
                            text: t('SettingSmartlinkTemplate_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleSearch = (text) => {
        const searchText = text;
        var filterInstance = gridRef.current.api.getFilterInstance('smart_link_title');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={2} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingSmartlinkTemplate_add_btn')}</button>
                            <Form.Group style={{width: 300}}>
                            <InputGroup>
                                    <Form.Control
                                        placeholder={t('SettingSmartlinkTemplate_search_placeholder')}
                                        type="text"
                                        onChange={(e) => {handleSearch(e.target.value)}}
                                    />
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon" onClick={getGridData}/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <div className="ms-auto">{recordFound === 1 ? t('SettingSmartlinkTemplate_record', {count: recordFound}) : recordFound > 1 ? t('SettingSmartlinkTemplate_record', {count: recordFound}):t('SettingSmartlinkTemplate_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    template_title: "",
                                    template_item_type: 0,
                                    template_item: []
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingSmartlinkTemplate_modalHeader_add_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('SettingSmartlinkTemplate_modalBody_add_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_title && touched.template_title}
                                                onChange={handleChange('template_title')}
                                            />
                                            {errors.template_title && touched.template_title && <div className="op-error-message">{errors.template_title}</div>}
                                        </Form.Group>
                                        {/* <Form.Group className="mb-3">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Upload File"
                                                value={0}
                                                name="smartlink_type"
                                                checked={Number(values.template_item_type) === 0 ? true:false}
                                                onChange={handleChange('template_item_type')}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Direct link"
                                                value={1}
                                                name="smartlink_type"
                                                checked={Number(values.template_item_type) === 1 ? true:false}
                                                onChange={handleChange('template_item_type')}
                                            />
                                        </Form.Group> */}
                                        {
                                            Number(values.template_item_type) === 0 
                                            &&
                                            <Form.Group>
                                                <FilePond
                                                    allowMultiple={false}
                                                    maxFiles={1}
                                                    name="file"
                                                    files={values.template_item}
                                                    credits={false}
                                                    acceptedFileTypes={["application/pdf"]}
                                                    onupdatefiles={(fileItems) => {
                                                        
                                                        if (fileItems && fileItems.length) {
                                                            if (fileItems[0].file.type === "application/pdf") {
                                                                setFieldValue(
                                                                    "template_item",
                                                                    fileItems.map((fileItem) => fileItem.file)
                                                                );
                                                            }
                                                        }
                                                    }}
                                                    onremovefile={() => {
                                                        setFieldValue(
                                                            "template_item",
                                                            []
                                                        );
                                                    }}
                                                    labelIdle={`${t('Filepond_Label1')} <span class="filepond--label-action"> ${t('Filepond_Label2')} </span>`}
                                                />
                                                {errors.template_item && touched.template_item && <div className="op-error-message">{errors.template_item}</div>}
                                            </Form.Group>
                                        }
                                        {/* {
                                            Number(values.template_item_type) === 1 
                                            &&
                                            <Form.Group>
                                                direct link
                                            </Form.Group>
                                        } */}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingEmailTemplate_Modal_Footer')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={editInitVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingSmartlinkTemplate_modalHeader_edit_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingSmartlinkTemplate_modalBody_edit_title_1')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                isInvalid={errors.template_title_edit && touched.template_title_edit}
                                                value={values.template_title_edit}
                                                onChange={handleChange('template_title_edit')}
                                            />
                                            {errors.template_title_edit && touched.template_title_edit && <div className="op-error-message">{errors.template_title_edit}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingEmailTemplate_Modal_Footer')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );
}

export default SmartlinkTemplateSetting;