import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown, ButtonGroup, ToggleButton } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import Loader from "../../includes/Loader";
import { useTranslation } from "react-i18next";

const AddContributor = ({prospectID, prospectData, contributorData, closeModal, onSuccess}) => {

    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [userList, setUserList] = useState([]);
    const [assignedList, setAssignedList] = useState([]);

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "4b",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                var dataList = data.record.map((rec) => {
                    return {label: rec.user_name, value: rec.user_id};
                });

                setUserList(dataList);
                setOpen(true);
                setIsLoading(false);
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Failed to open add contributor. Please try again or contact your IT Support"
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const getAssignedUser = () => {
        if (contributorData) {
            var list = contributorData.map((data) => {
                return data.user_id;
            });

            setAssignedList(list);
        }
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
            getAssignedUser();
        }
    });

    const addSchema = yup.object().shape({
        userID: yup.string().required("This field is required")
    });

    const sendAdd = (values) => {
        var prospect_ID = "";
		var prospect_IDHq = prospectID;

        if (prospectData.hq_prospect_id && prospectData.hq_prospect_id != 0) {
            if (session.company_id == 251) {
                prospect_ID = prospectData.hq_prospect_id;
            }else{
                prospect_ID = prospectData.prospect_id;
            }
        }else{
            prospect_ID = prospectData.prospect_id;
        }

        if (session.company_id == 251) {
            handleAddHq(values, prospect_ID);
        }else{
            handleAddProject(values, prospect_ID, prospect_IDHq);
        }
    }

    const handleAddHq = (data, id) => {

        axios.get('ws/ws_prospect.php', {
            params: {
                task: "addProspectContributor",
                prospectid: id,
                userid: data.userID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 1) {
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "The user chosen has already been added to this deal"
                });
            }else{
                onSuccess();
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleAddProject = (data, id, hqID) => {
        
        axios.get('ws/ws_prospect.php',{
            params: {
                task: "addProspectContributor",
                prospectid: id,
                userid: data.userID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 1) {
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support"
                });
            }else{
                // handleAddProject2(data, hqID); 
                onSuccess();
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleAddProject2 = (data, hqID) => {

        axios.get('ws/ws_prospect.php',{
            params: {
                task: "addProspectContributor",
                prospectid: hqID,
                userid: data.userID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 1) {
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support"
                });
            }else{
                onSuccess();
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    return(
        <>
            <Modal show={open} onHide={closeModal}>
                <Formik
                    validationSchema={addSchema}
                    onSubmit={sendAdd}
                    initialValues={
                        {
                            userID : ""
                        }
                    }
                >
                    {({ handleSubmit, setFieldValue, errors, touched }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Header closeButton>
                                <Modal.Title>{t('ProspectDetGL_Contributor_Modal_Add_HeaderTitle')}</Modal.Title>
                            </Modal.Header>
                            {isLoading && <Modal.Body style={{height: "150px"}}> <Loader/> </Modal.Body>}
                            {!isLoading && <Modal.Body>
                                <Form.Group>
                                    <Form.Label>{t('ProspectDetGL_Contributor_Modal_Add_Body_UserName')}</Form.Label>
                                    <FormSelect
                                        options={contributorData ? userList.filter(opt => !assignedList.includes(opt.value)) : userList}
                                        isSearchable={true}
                                        isClearable={true}
                                        isInvalid={errors.userID && touched.userID}
                                        onChange={(e) => {setFieldValue('userID', e ? e.value : "")}}
                                    />
                                    {errors.userID && touched.userID && <div className="op-error-message">{errors.userID}</div>}
                                </Form.Group>
                            </Modal.Body>}
                            {!isLoading && <Modal.Footer>
                                <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_Contributor_Modal_Add_Footer_Submit')}</button>
                            </Modal.Footer>}
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );

}

export default AddContributor;