import React, { useState, useEffect, useRef, useMemo } from "react";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { BarChart, YAxis, XAxis, Legend, Bar, Tooltip, ResponsiveContainer, Cell } from "recharts";
import { FormSelect } from "../includes/FormCustom";
import axios from "../api/axios";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import moment from 'moment';



const generatePastYearMonths = () => {
    const months = [];
    const now = moment();

    for (let i = 0; i < 12; i++) {
        const month = now.clone().subtract(i, 'months');
        const label = month.format('MMMM YYYY'); // Format as 'Month YYYY'
        const value = month.format('YYYY-MM'); // Format as 'YYYY-MM'
        months.push({ label, value });
    }

    return months;
};

const CampaignDashboard = () => {
    const init = useRef(false);
    const gridRef = useRef();
    const { session } = useAuth();
    const [loading, setLoading] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [campaignData, setCampaignData] = useState([]);
    const [campaignTitle, setCampaignTitle] = useState([]);
    const [chartColors, setChartColors] = useState({});
    const [gridColumn, setGridColumn] = useState([]);

    const [businessUnit, setBusinessUnit] = useState([]);
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [selectedCampaignList, setSelectedCampaignList] = useState([]);
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedGamudaOptions, setSelectedGamudaOptions] = useState([]);

    const [selectedPastMonth, setSelectedPastMonth] = useState(null);
    const pastYearMonths = generatePastYearMonths();
    const [dateRangeState, setDateRangeState] = useState({
        ds: "",
        de: ""
    });

    const getGamudaOptions = [
        { label: "Gamuda HQ", value: "all", id: "251" },
        { label: "Gamuda Gardens", value: "gardens", id: "180" },
        { label: "Gamuda Jade Hills", value: "jadehill", id: "190" },
        { label: "Gamuda Cove", value: "cove", id: "200" },
        { label: "Gamuda 257 Kemuning", value: "kemuning", id: "203" },
        { label: "Gamuda Horizon Hills", value: "horizon", id: "210" },
        { label: "Gamuda Bukit Bantayan", value: "bantayan", id: "212" },
        { label: "Gamuda Highpark Suites", value: "highpark", id: "213" },
    ]

    const gamudaOptions = [
        {
            label: "Gamuda HQ",
            value: "",
            id: "251",
            ctoken: "4d10baf95d0fde0172fb8c7fd10be267",
            utoken: "38cecfadf5b89105aa879bab98b5fff0",
        },
        {
            label: "Gamuda Gardens",
            value: "gardens",
            id: "180",
            ctoken: "48ade9786a2d11568db7aad9657a90de",
            utoken: "a04fcdaedc1a3e10695d468258e3f217",
        },
        {
            label: "Gamuda Cove",
            value: "cove",
            id: "200",
            ctoken: "c7a341f4b174827d2f2b88a947554855",
            utoken: "243d7203cd06bb5d2fadbdafa32e213e",
        },
        {
            label: "Gamuda Jade Hills",
            value: "jadehill",
            id: "190",
            ctoken: "3f684e70f4379ce5dc998a5b1d433751",
            utoken: "f18e8821d0a8130bbb43f7b158fccedc",
        },
        {
            label: "Gamuda 257 Kemuning",
            value: "kemuning",
            id: "203",
            ctoken: "73b6a677f19f9c9ef0ba994b35ac8ed6",
            utoken: "5fd3e152170afe3aefa48cb14fd970d8",
        },
        {
            label: "Gamuda Bukit Bantayan",
            value: "bantayan",
            id: "212",
            ctoken: "19dc1aa5e5767cd6049a4f0fa958d5ec",
            utoken: "b3376001d54890bc0858e9a66fd4e0fe",
        },
        {
            label: "Gamuda Highpark Suites",
            value: "highpark",
            id: "213",
            ctoken: "60a32b0b9656bc7e38e25dd89d65e9d8",
            utoken: "ea404a7b4d4ca65745305808635ce6c3",
        },
        {
            label: "Gamuda Horizon Hills",
            value: "horizon",
            id: "210",
            ctoken: "9e326bbe084ccb68e4ec27dab66f7731",
            utoken: "04c7f6f7ed445574971d5816b0e62014",
        },
    ];

    const getRandomColor = async (params) => {
        const colors = {};

        params.forEach((title) => {
            colors[title.ds_id] = '#' + Math.floor(Math.random() * 16777215).toString(16);
        });

        setChartColors(colors);
    };

    const dateRange = [
        { label: "Last 30 days", value: "1" },
        { label: "This Month", value: "2" },
        { label: "Choose Past Month", value: "3" },
        { label: "By Week", value: "4" },
        { label: "By Month", value: "5" },
        { label: "By Year", value: "6" },
    ];


    // Get Function
    const getCampaignData = async () => {
        const daterangestart = selectedPastMonth ? selectedPastMonth.value : dateRangeState.ds || "";
        const daterangeend = dateRangeState.de ? dateRangeState.de : "";

        let token = {};

        if (selectedGamudaOptions) {
            token = gamudaOptions.find(item => item.id === selectedGamudaOptions.id) || session;
        }

        const getToken = token.ctoken || session.company_token;
        const userToken = token.utoken || session.user_ac_token;

        try {
            const response = await axios.get("ws/ws_campaign_analytics.php", {
                params: {
                    bu_id: selectedBusinessUnit.value || '',
                    campaign_id: selectedCampaignList.value || '',
                    ctoken: getToken,
                    dateRange: selectedDate.value || 1,
                    dateRange2: daterangestart,
                    dateRange3: daterangeend,
                    task: "campaignAnalyticsReport",
                    utoken: userToken
                },
            });

            if (response.data.status == '0') {
                const { record, catTitle_arr } = response.data;
                const { transformedData, uniqueDates } = transformData(record, catTitle_arr);

                setCampaign(transformedData);

                const columnDefs = generateColumnDefs(uniqueDates);
                setGridColumn(columnDefs);

                setCampaignData(record);
                setCampaignTitle(catTitle_arr);
                await getRandomColor(catTitle_arr);

                setLoading(false);
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    // trigger this when gamuda options change
    const getBusinessUnit = async (options) => {
        try {
            const response = await axios.get("ws/ws_setting.php", {
                params: {
                    company: options ? options.id : session.company_id,
                    task: "getAllBU"
                },
            });

            if (response.data.status == '0') {
                const buOptions = response.data.record.map(bu => ({
                    value: bu.business_unit_id,
                    label: bu.unit_title
                }));

                buOptions.unshift({ label: "All Business Unit", value: "" });
                setBusinessUnit(buOptions);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const getCampaignList = async (options) => {
        let token = {};
        let task = '';

        if (options) {
            token = gamudaOptions.find(item => item.id === options.id) || session;
        }

        const getToken = token.ctoken || session.company_token;
        const userToken = token.utoken || session.user_ac_token;

        if (token.id !== '251') {
            task = 'listFromProject'
        } else {
            task = 'list'
        }

        try {
            const response = await axios.get("ws/ws_campaign.php", {
                params: {
                    ctoken: getToken,
                    task: task,
                    utoken: userToken
                }
            });

            if (response.data.record) {
                const campaignOptions = response.data.record.map(co => ({
                    value: co.marketing_campaign_id,
                    label: co.marketing_campaign_title
                }));
                campaignOptions.unshift({ label: "All Campaign", value: "" });

                setCampaignList(campaignOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const generateColumnDefs = (dates) => {
        const dateColumns = dates.map(date => ({
            headerName: date,
            field: date,
            width: 150,
            cellStyle: { 'text-align': 'center' }
        }));

        const totalCountColumn = {
            headerName: 'Total Count',
            field: 'totalCount',
            width: 200,
            cellStyle: { 'text-align': 'center' },
            valueGetter: params => {
                let totalCount = 0;
                dates.forEach(date => {
                    totalCount += params.data[date] || 0;
                });
                return totalCount;
            }
        };

        return [
            { headerName: "Title", field: "ds_title", width: 400, cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' } },
            ...dateColumns,
            totalCountColumn
        ];
    };

    const transformData = (campaignData, campaignTitle) => {
        const uniqueDates = [...new Set(campaignData.map(item => {
            const date = new Date(item.deal_date_time_create);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        }))];

        const transformedData = campaignTitle.map(title => {
            const row = { ds_title: title.ds_title };
            uniqueDates.forEach(date => {
                const dataItem = campaignData.find(item => {
                    const itemDate = new Date(item.deal_date_time_create);
                    const formattedDate = `${itemDate.getFullYear()}-${(itemDate.getMonth() + 1).toString().padStart(2, '0')}-${itemDate.getDate().toString().padStart(2, '0')}`;
                    return formattedDate === date;
                });
                row[date] = dataItem ? parseInt(dataItem[`b${title.ds_id}`], 10) : 0;
            });
            return row;
        });

        return { transformedData, uniqueDates };
    };

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getCampaignData();
                    await getBusinessUnit();
                    await getCampaignList();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session, init]);

    const mergeData = (campaignData, campaignTitle) => {
        return campaignData.map(item => {
            const date = new Date(item.deal_date_time_create);
            const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

            // Extract keys for mapping
            const keys = Object.keys(item).filter(key => key.startsWith('b'));
            let data = { date: formattedDate };

            keys.forEach(key => {
                const ds_id = key.substring(1);
                const ds_title = campaignTitle.find(ct => ct.ds_id === ds_id)?.ds_title || ds_id;

                data[ds_title] = parseInt(item[key], 10);
            });

            return data;
        });
    };

    const chartData = mergeData(campaignData, campaignTitle);

    // Apply Button
    const handleApplyButtonClick = async () => {
        setLoading(true);

        try {
            await getCampaignData();
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDateRangeChange = (selectedOption) => {
        setSelectedDate(selectedOption);

        if (selectedOption.value === "3" && pastYearMonths.length > 0) {
            setSelectedPastMonth(pastYearMonths[0]);
        } else {
            setSelectedPastMonth(null);
        }

        if (selectedOption.value === "4" || selectedOption.value === "5") {
            setDateRangeState({
                ds: "",
                de: "",
            });
        }
    };

    const onchangeDateRange = (key, value) => {
        setDateRangeState((prev) => ({ ...prev, [key]: value }));
    };

    const handleDownload = () => {
        var params = {
            fileName: "campaign-dashboard.csv",
            processCellCallback: function (params) {
                if (params.column.colId === "ds_title") {
                    return params.node.data.ds_title;
                } else {
                    var res = params.value;
                    if (res !== undefined && res !== null) {
                        res = String(res).replace(/<br>/g, "\r\n")
                                         .replace(/<li>/g, "\r\n")
                                         .replace(/<[^>]+>/g, "")
                                         .replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };


    return (
        <Container fluid className="p-0 m-0">
            <div className="p-4" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
                <div className="px-5" style={{ height: '50px' }}>
                    <Stack className="mb-3" direction="horizontal" gap={3}>
                        {session.company_id === '251' && (
                            <Form.Group>
                                <FormSelect
                                    options={getGamudaOptions}
                                    placeholder="Select an options"
                                    valueDefault={getGamudaOptions[0]}
                                    border={false}
                                    shadow={true}
                                    width="150px"
                                    onChange={(selectedOption) => {
                                        setSelectedGamudaOptions(selectedOption);
                                        getBusinessUnit(selectedOption);
                                        getCampaignList(selectedOption);
                                    }}
                                />
                            </Form.Group>
                        )}

                        <Form.Group>
                            <FormSelect
                                options={dateRange}
                                placeholder="Select Date"
                                valueDefault={dateRange[0]}
                                border={false}
                                shadow={true}
                                width="200px"
                                onChange={handleDateRangeChange}
                            />
                        </Form.Group>

                        {selectedDate.value === "3" && (
                            <Form.Group>
                                <FormSelect
                                    options={pastYearMonths}
                                    placeholder="Select a Month"
                                    valueDefault={pastYearMonths[0]}
                                    border={false}
                                    shadow={true}
                                    width="200px"
                                    onChange={(selectedOption) => {
                                        setSelectedPastMonth(selectedOption);
                                    }}
                                />
                            </Form.Group>
                        )}

                        {(selectedDate.value === "4" || selectedDate.value === "5") && (
                            <>
                                <Form.Group className="me-2">
                                    <Form.Control
                                        type="date"
                                        className="shadow-sm border-0"
                                        value={moment(dateRangeState.ds, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            onchangeDateRange("ds", moment(e.target.value).format("YYYY-MM-DD"));
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="me-2">
                                    <Form.Control
                                        type="date"
                                        className="shadow-sm border-0"
                                        value={moment(dateRangeState.de, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        onChange={(e) => {
                                            onchangeDateRange("de", moment(e.target.value).format("YYYY-MM-DD"));
                                        }}
                                    />
                                </Form.Group>
                            </>
                        )}

                        <Form.Group>
                            <FormSelect
                                options={businessUnit}
                                placeholder="Select Business Unit"
                                valueDefault={businessUnit[0]}
                                border={false}
                                shadow={true}
                                width="200px"
                                onChange={(selectedOption) => {
                                    setSelectedBusinessUnit(selectedOption);
                                }}
                                isClearable={true}
                            />
                        </Form.Group>

                        <Form.Group>
                            <FormSelect
                                options={campaignList}
                                placeholder="Select Campaign"
                                border={false}
                                shadow={true}
                                width="200px"
                                onChange={(selectedOption) => {
                                    setSelectedCampaignList(selectedOption);
                                }}
                                isClearable={true}
                            />
                        </Form.Group>

                        <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
                            Apply
                        </Button>
                    </Stack>
                </div>
                {loading ? <Loader /> : (
                    <div>
                        <div>
                            <ResponsiveContainer width="100%" height={500}>
                                <BarChart data={chartData} margin={{ bottom: 80 }}>
                                    <Legend verticalAlign="top" height={70} wrapperStyle={{ display: "flex", justifyContent: "center", padding: "0px 150px" }} />
                                    <XAxis dataKey="date" interval={chartData.length < 5 ? 0 : "preserveStartEnd"} angle={chartData.length < 10 ? 0 : 315} textAnchor="end" />
                                    <YAxis />
                                    <Tooltip />
                                    {campaignTitle.map((title) => (
                                        <Bar key={title.ds_id} dataKey={title.ds_title} stackId="a" fill={chartColors[title.ds_id]}>
                                            {chartData.map((entry, dataIndex) => (
                                                <Cell key={`cell-${dataIndex}`} fill={chartColors[title.ds_id]} />
                                            ))}
                                        </Bar>
                                    ))}
                                </BarChart>
                            </ResponsiveContainer>
                        </div>

                        <div>
                            <button className="btn btn-dark" onClick={handleDownload}>
                                Download
                            </button>
                        </div>

                        <div>
                            <Row>
                                <Col xxl={12}>
                                    <div style={containerStyle}>
                                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                            <AgGridReact
                                                ref={gridRef}
                                                columnDefs={gridColumn}
                                                rowData={loading ? [] : campaign}
                                                rowHeight={120}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CampaignDashboard;
