import { useState, useEffect, useMemo, useRef} from "react";
import { Stack, Form, InputGroup } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import FormSelect from "../includes/FormSelect";
import "../lib/scss/op-report-style.scss";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
// import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
import NoRecord from "../includes/NoRecord";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const ScheduleDealReport = () => {
    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    // const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [trigger, setTrigger] = useState(0);
    const [isReport, setIsReport] = useState(false);
    const [recordFound, setRecordFound] = useState("");

    const [userList, setUserList] = useState([]);

    const [prospect, setProspect] = useState("");
    const [startDate, setStartDate] = useState(moment(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [userID, setUserID] = useState("");
    const { t } = useTranslation();

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {

            let data = res.data;

            if (data.status === 0) {
                var userData = data.record.map((rec)=>{
                    return { label: rec.user_name, value: rec.user_id };
                });

                setUserList(userData);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const reportColumn = [
        {
            headerName: t('Report_ScheduleDeal_HeaderName1'),
            field: "start_date",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_ScheduleDeal_HeaderName2'),
            field: "prospect_name",
            resizable: true,
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    window.open("/prospect/" + params.data.prospect_id, '_blank');
                }
            }
        },
        {
            headerName: t('Report_ScheduleDeal_HeaderName3'),
            field: "deal_title",
            resizable: true,
            width: 250,
            onCellClicked: function (params) {
                if (params.data) {
                    window.open("/deal/" + params.data.deal_id, '_blank');
                }
            }
        },
        {
            headerName: t('Report_ScheduleDeal_HeaderName4'),
            field: "parent_deal",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_ScheduleDeal_HeaderName5'),
            field: "user_name",
            resizable: true,
            width: 250
        },
        {
            headerName: t('Report_ScheduleDeal_HeaderName6'),
            field: "date_time_create",
            resizable: true,
            width: 250
        }
    ];

    const reportDataSource = useMemo(()=>{
        return{
            rowCount: undefined,
            getRows: (params) => {
                //ds=2024-1-16&de=2024-1-23&prospectname=&user=&source=&startRow=0
                axios.get("ws/ws_schedule_deal_report.php",{
                    params:{
                        task: "getScheduledDeals",
                        ds: startDate,
                        de: endDate,
                        prospectname: prospect,
                        user: userID,
                        source: "",
                        startRow: params.startRow,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        setIsReport(true);
                    }else{
                        setIsReport(false);
                        Swal.fire({
                            icon: 'error',
                            title: t('Report_alert_Title'),
                            text: t('Report_alert_Text')
                        });
                    }

                    setTimeout(() => {
                        var recInfo = [];
                        var totalRecord = 0;

                        if (data.status === 0) {
                            recInfo = data.record;
                            totalRecord = Number(data.totalRecord);
                            setRecordFound(totalRecord);
                        } else {
                            recInfo = [];
                            totalRecord = 0;
                            setRecordFound(totalRecord);
                        }

                        var rowsThisPage = recInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);
                });
            }
        }
    }, [trigger, session]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if (!init.current) {
            init.current = true;
            getAllUser();
            setIsLoading(false);
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = () =>{
        
        var params = {
            fileName : 'dataExport.csv',
            processCellCallback : function(params) {

                if(params.column.colId === 'auto_increment_num'){
                    return params.node.data.temprecord_id;
                }else{
                    var res = params.value;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return res;
                }

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <div className="report-main">
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t('Report_FilterCriteria')}</div>
                        </section>
                        <section className="content">
                            <Form.Group className="mb-3">
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('Report_ScheduleDeal_SearchByProspectName')}
                                        onChange={(e) => {setProspect(e.target.value)}}
                                    />
                                    <InputGroup.Text id="basic-addon1">
                                        <FontAwesomeIcon icon={faMagnifyingGlass} size="xl"/>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <FormSelect
                                    placeholder={t('Report_AllUser')}
                                    options={userList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e)=>{setUserID(e ? e.value:"")}}
                                />
                            </Form.Group>
                        </section>
                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button 
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={()=>{setTrigger(trigger + 1)}}
                                >
                                    {t('Report_ViewReport')}
                                </button>
                                <button className="btn btn-dark" onClick={handleDownload}>{t('Report_Download')}</button>
                            </div>
                        </section>
                    </div>
                    <div className="report-content">
                        <section className="w-100">
                            {isReport && <Stack direction="horizontal">
                                <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>
                            </Stack>}
                        </section>
                        <section className="w-100">
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        datasource={reportDataSource}
                                        defaultColDef={reportColDef}
                                        rowSelection={"multiple"}
                                        rowModelType={"infinite"}
                                        rowHeight={70}
                                        cacheBlockSize={30}
                                        cacheOverflowSize={2}
                                        maxConcurrentDatasourceRequests={1}
                                        infiniteInitialRowCount={1}
                                        maxBlocksInCache={50}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                        suppressRowClickSelection={true}
                                        animateRows={true}
                                    />
                                </div>
                            </div>
                            {/* {!isReport && <NoRecord message="No matching record were found based on your criteria." description="Consider resetting your filters or making adjustments to your current filter settings." width={300} />} */}
                        </section>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ScheduleDealReport;