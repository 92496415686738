import { useState, useEffect, useRef, useMemo } from "react";
import { useAuth } from "../../auth/AuthContext";
import { FormSelect } from "../../includes/FormCustom";
import axios from "../../api/axios";
import { Container, Row, Col, OverlayTrigger, Tooltip, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume, faPencil, faTrash, faBoxCircleCheck, faBox } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { setCurrency } from "../../lib/js/Function";
import { useTranslation } from "react-i18next";
import * as formik from "formik";

const MySwal = withReactContent(Swal);

const ProductComponent = ({ productList, getDealProductV2, dealproduct, onSubmit, getuser }) => {
  const { t } = useTranslation();
  const { Formik } = formik;
  const initialized = useRef(false);
  const { session } = useAuth();
  const cToken = session.company_token;
  const uToken = session.user_ac_token;
  const [searchTerm, setSearchTerm] = useState('');
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [values, setValues] = useState({
    activity_type: "1",
  });

  const [selectedCategory, setSelectedCategory] = useState({ value: "", label: t("ProductComponent_all_product_category") });
  const [category, setCategory] = useState([{ value: "", label: t("ProductComponent_all_product_category") }]);
  const [productDetails, setProductDetails] = useState([]);
  const [gridKey, setGridKey] = useState(0);
  const [currentData, setCurrentData] = useState(null);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    const mappedCategory = productList.map((item) => ({
      value: item.product_cat_id,
      label: item.cat_title,
    }));

    setCategory([{ value: "", label: t("ProductComponent_all_product_category") }, ...mappedCategory]);
  }, [productList]);

  const handleChange = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  // search term
  useEffect(() => {
    const delay = 500;

    const delaySearch = setTimeout(() => {
      setDelayedSearchTerm(searchTerm);
    }, delay);

    return () => clearTimeout(delaySearch);
  }, [searchTerm]);

  const getQuickFilterText = (params) => {
    const textFields = ["sku_codes", "product_title"];
    const searchTermLowerCase = delayedSearchTerm.toLowerCase(); // Use delayed search term
    return textFields.map((field) => params.data[field]).join(' ').toLowerCase().includes(searchTermLowerCase);
  };

  // action btn (delete)

  const sendDeleteActivity = (params) => {
    MySwal.fire({
      title: t("ProductComponent_are_you_sure"),
      text: t("ProductComponent_you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProductComponent_yes_delete_it"),
    }).then((result) => {
      if (result.isConfirmed) {
        performDeleteActivity(params.product_id, params.deal_id);
      }
    });
  };

  const performDeleteActivity = (productId, dealId) => {
    axios
      .get("/ws/ws_product.php", {
        params: {
          ctoken: cToken,
          deal: dealId,
          product: productId,
          task: "delDealProduct",
          utoken: uToken,
        },
      })
      .then(() => {
        setGridKey((prevKey) => prevKey + 1);
        MySwal.fire(t("ProductComponent_deleted"), t("ProductComponent_your_record_has_been_deleted"), "success");
        onSubmit();
      })
      .catch((error) => {
        MySwal.fire(t("ProductComponent_error"), t("ProductComponent_an_error_occured_while_deleting_the_record"), "error");
      });
  };

  // grid
  const containerStyle = useMemo(() => ({ width: "100%", height: "60vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    { headerName: t("ProductComponent_product_title"), field: "product_title", cellStyle: { whiteSpace: "initial", lineHeight: "1.2" } },
    {
      headerName: t("ProductComponent_remarks"),
      field: 'remark',
      cellStyle: { whiteSpace: 'initial', lineHeight: '1.2' },
      maxWidth: 350,
      cellRenderer: (params) => {
        const remarks = params.data.remark || '';
        const remarkLines = remarks.split('\n').map((line, index) => <div key={index}>{line}</div>);
        return <div style={{ whiteSpace: 'initial', lineHeight: '1.2', overflow: 'auto', width: '350px', maxHeight: '200px' }}>{remarkLines}</div>;
      },
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        cols: 20, // Number of columns in the textarea
        rows: 8, // Number of rows in the textarea
        useFormatter: true,
        stopEditingWhenGridLosesFocus: true,
        stopEditingWhenTyping: true,
        autoHeight: true,
        cellHeight: 50,
        suppressKeyboardEvent: (params) => {
          const isEnterKey = params.event.keyCode === 13 && !params.event.shiftKey;
          if (isEnterKey) {
            params.stopEditing();
            return true; // Prevent the default behavior
          }
          return false;
        },
      },
    },
    { headerName: t("ProductComponent_quantity"), headerClass: "center", cellClass: "center", field: "total_qty", maxWidth: 150 },
    { headerName: t("ProductComponent_price"), headerClass: "center", cellClass: "center", field: "default_price", maxWidth: 150, cellRenderer: (params) => setCurrency(params.data.default_price) },
    { headerName: t("ProductComponent_discount"), headerClass: "center", cellClass: "center", field: "discount", maxWidth: 150, cellRenderer: (params) => setCurrency(params.data.discount) },
    { headerName: t("ProductComponent_final_price"), headerClass: "center", cellClass: "center", field: "final_price", maxWidth: 150, cellRenderer: (params) => setCurrency(params.data.final_price) },
    {
      headerName: t("ProductComponent_comission"),
      headerClass: "center",
      cellClass: "center",
      field: "comission",
      maxWidth: 150,
      cellRenderer: function (params) {
        if (params.data.commission) {
          return params.data.commission + "%";
        }
        return ""; // return empty string if commission value is not present
      }
    },
    {
      headerName: t("ProductComponent_action"),
      field: "",
      headerClass: "center",
      cellClass: "center",
      pinned: "right",
      cellRenderer: function (params) {
        return (
          <div className="d-flex">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Delete</Tooltip>}
            >
              <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Edit</Tooltip>}
            >
              <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
            </OverlayTrigger>
          </div>
        );
      }
    },
  ];

  const onCellClicked = (event) => {
    let target = event.event.target;
    while (target && !target.getAttribute("data-action")) {
      target = target.parentElement;
    }
    if (target) {
      const action = target.getAttribute("data-action");
      const rowData = event.data;
      switch (action) {
        case "edit":
          handleEditClick(rowData);
          break;
        case "trash":
          sendDeleteActivity(rowData);
          break;
        default:
          break;
      }
    }
  };

  const handleEditClick = (params) => {
    setCurrentData(params);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUpdate = async (values) => {
    try {
      const response = await axios.get("ws/ws_product.php", {
        params: {
          commission: values.commission,
          utoken: session.user_ac_token,
          deal_product_id: values.deal_product_id,
          task: "EditProductCommission",
          ctoken: session.company_token,
        }
      });

      const data = response.data;
      
      if (data.status === 0) {
        setShowModal(false);
        Swal.fire({
          icon: "success",
          title: "Successfully Update",
          text: "Successfully update a record!",
          timer: 1500,
        });
        window.location.reload();
      }

    } catch (error) {
      console.error(error);
    }
  };

  const gridColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      flex: 1,
    };
  }, []);

  const gridColDef2 = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  // add product
  useEffect(() => {

    const details = productList.flatMap((category) =>
      category.products.map((product) => ({
        prod_id: product.product_id,
        sku_codes: product.sku_code,
        product_title: product.product_title,
        remarks: null,
        curr_qty: product.curr_qty,
        price: product.price,
        discount: null,
        quantity: null,
        final_price: null,
      }))
    );

    // If a category is selected, filter the details
    const filteredDetails = selectedCategory.value ? details.filter((product) => productList.find((category) => category.product_cat_id === selectedCategory.value && category.products.some((p) => p.sku_code === product.sku_codes))) : details;

    setProductDetails(filteredDetails);
  }, [productList, selectedCategory]);

  // add product function
  const handleAddProducts = async () => {
    const productsToAdd = productDetails.filter((product) => product.final_price > 0);
    const currentUser = getuser.config.params.deal;

    if (productsToAdd.length > 0) {
      const url = "ws/ws_product.php";

      const params = {
        ctoken: cToken,
        deal: currentUser,
        task: "addProducts",
        utoken: uToken,
      };

      // Arrays to accumulate values for each parameter
      const discounts = [];
      const finalPrices = [];
      const products = [];
      const quantities = [];
      const remarks = [];

      productsToAdd.forEach((product, index) => {
        const prefix = `product${index + 1}_`;
        discounts.push(product.discount || "");
        finalPrices.push(product.final_price || "");
        products.push(product.prod_id || "");
        quantities.push(product.quantity || "");
        remarks.push(product.remarks || "");
      });

      // Assign arrays to params object
      params["discount"] = discounts.join(",");
      params["final_price"] = finalPrices.join(",");
      params["product"] = products.join(",");
      params["quantity"] = quantities.join(",");
      params["remark"] = remarks.join(",");

      const paramString = Object.entries(params)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join("&");

      try {
        const response = await axios.get(`${url}?${paramString}`);
        Swal.fire({
          icon: "success",
          title: t("ProductComponent_success"),
          text: t("ProductComponent_the_product_has_been_added"),
          timer: 2000
        });
        onSubmit(Math.random());
      } catch (error) {
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: t("ProductComponent_oops"),
        text: t("ProductComponent_no_product_selected"),
      });
    }
  };

  const gridColumnAddProduct = [
    { headerName: t("ProductComponent_sku_codes"), field: "sku_codes", cellStyle: { whiteSpace: "initial", lineHeight: "1.2" } },
    { headerName: t("ProductComponent_product_title"), field: "product_title", cellStyle: { whiteSpace: "initial", lineHeight: "1.2" } },
    {
      headerName: t("ProductComponent_remarks"),
      field: 'remarks',
      editable: true,
      maxWidth: 250,
      cellEditor: 'agLargeTextCellEditor',
      cellEditorParams: {
        cols: 25, // Number of columns in the textarea
        rows: 6, // Number of rows in the textarea
        useFormatter: true,
        stopEditingWhenGridLosesFocus: true,
        stopEditingWhenTyping: true,
        autoHeight: true,
        cellHeight: 50,
        suppressKeyboardEvent: (params) => {
          const isEnterKey = params.event.keyCode === 13 && !params.event.shiftKey;
          if (isEnterKey) {
            params.stopEditing();
            return true; // Prevent the default behavior
          }
          return false;
        },
      },
      cellStyle: { whiteSpace: 'initial', lineHeight: '1.2', border: "1px solid #ced4da", padding: "6px", borderRadius: "10px", height: "80%", backgroundColor: 'white', top: '8%' },
    },
    { headerName: t("ProductComponent_availability"), headerClass: "center", cellClass: "center", field: "curr_qty", maxWidth: 120 },
    { headerName: t("ProductComponent_price"), headerClass: "center", cellClass: "center", field: "price", maxWidth: 150, cellRenderer: (params) => setCurrency(params.data.price) },
    {
      headerName: t("ProductComponent_discount"),
      headerClass: "center",
      cellClass: "center",
      field: "discount",
      maxWidth: 150,
      editable: true,
      cellRenderer: params => Number(params.data.discount) > 0 ? setCurrency(params.data.discount) : "",
      cellStyle: { border: "1px solid #ced4da", padding: "6px", height: "30%", width: "10%", top: "35%", margin: "20px", borderRadius: "10px" },
    },
    {
      headerName: t("ProductComponent_quantity"),
      headerClass: "center", cellClass: "center",
      field: "quantity",
      editable: true,
      maxWidth: 150,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        defaultValue: "0",
      },
      cellRenderer: params => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.value}
        </div>
      ),
      cellStyle: { border: "1px solid #ced4da", padding: "6px", height: "30%", width: "10%", top: "35%", margin: "20px", borderRadius: "10px" },
    },
    {
      headerName: t("ProductComponent_final_price"),
      field: "final_price",
      headerClass: "center", cellClass: "center",
      maxWidth: 150,
      valueFormatter: (params) => {
        var finalPrice = params.data.final_price;
        return finalPrice !== null ? setCurrency(Number(finalPrice)) : null;
      },
    },
  ];

  const onCellValueChanged = (event) => {
    if (event.colDef.field === "quantity") {
      const price = event.data.price || 0;
      const discount = event.data.discount || 0;
      const quantity = event.data.quantity || 0;

      if (!isNaN(quantity) && !isNaN(discount)) {
        const finalPrice = Number(price * Number(quantity)) - Number(discount);
        event.data.final_price = finalPrice !== 0 ? Number(finalPrice) : null;
        event.api.refreshCells({ force: true });
      }
    }
  };

  return (
    <div style={{ zIndex: 1050, position: 'relative' }}>
      {showModal && <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1051
      }}></div>}

      <div style={{ zIndex: 1050, position: 'relative' }}>
        <Modal.Header closeButton>
          <Modal.Title as="h6">{t("ProductComponent_product")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Group>
              <ButtonGroup className="activity-tab" style={{ width: "40%" }}>
                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                  <FontAwesomeIcon icon={faBoxCircleCheck} size="lg" className="me-1" />
                  {t("ProductComponent_chosen_product")}
                </ToggleButton>
                <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                  <FontAwesomeIcon icon={faBox} size="lg" className="me-1" />
                  {t("ProductComponent_add_product")}
                </ToggleButton>
              </ButtonGroup>
            </Form.Group>

            {/* chosen product */}
            {values.activity_type === "1" && (
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact key={gridKey} columnDefs={gridColumn} rowData={getDealProductV2} defaultColDef={gridColDef} rowHeight={200} pagination={true} paginationPageSize={200} paginationPageSizeSelector={false} animateRows={true} onCellClicked={onCellClicked} />
                </div>
              </div>
            )}

            {/* add product */}
            {values.activity_type === "2" && (
              <div>
                <Row className="pt-3">
                  <div className="d-flex justify-content-between">
                    <div className="" style={{ width: "200px" }}>
                      <Form.Group as={Col}>
                        <FormSelect
                          options={category}
                          value={selectedCategory || { value: "", label: t("ProductComponent_all_product_category") }}
                          onChange={(selectedOption) => {
                            setSelectedCategory(selectedOption);
                          }}
                          placeholder={t("ProductComponent_all_product_category")}
                          border={true}
                          shadow={true}
                        />
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group as={Col}>
                        <Form.Control type="text" className="me-1 shadow-sm border-1" placeholder={t("ProductComponent_search")} style={{ width: 200 }} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                      </Form.Group>
                    </div>
                  </div>
                </Row>

                <div style={containerStyle}>
                  <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                    <AgGridReact
                      columnDefs={gridColumnAddProduct}
                      rowData={productDetails}
                      defaultColDef={gridColDef2}
                      rowHeight={200}
                      pagination={true}
                      paginationPageSize={100}
                      paginationPageSizeSelector={false}
                      animateRows={true}
                      singleClickEdit={true}
                      quickFilterText={delayedSearchTerm}
                      getQuickFilterText={getQuickFilterText}
                      onCellValueChanged={(event) => {
                        // Handle changes for both "Discount" and "Quantity" columns
                        if (event.colDef.field === "quantity" || event.colDef.field === "discount") {
                          const price = event.data.price || 0;
                          const discount = event.data.discount || 0;
                          const quantity = event.data.quantity || 0;

                          if (!isNaN(quantity) && !isNaN(discount)) {
                            const finalPrice = Number(price * Number(quantity)) - Number(discount);
                            event.data.final_price = finalPrice !== 0 ? Number(finalPrice) : null;
                            event.api.refreshCells({ force: true });
                          }
                        }
                      }}

                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        {values.activity_type === "2" && (
          <Modal.Footer>
            <Button variant="" className="op-primary-color text-light" onClick={handleAddProducts}>{t("ProductComponent_add_product")}</Button>
          </Modal.Footer>
        )}
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="md" style={{ zIndex: '1061' }}>
        <Formik
          initialValues={{
            commission: currentData ? currentData.commission : "",
            deal_product_id: currentData ? currentData.deal_product_id : ""
          }}
          onSubmit={handleUpdate}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            setFieldValue
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Commission</Form.Label>
                  <Form.Control
                    type="text"
                    name="commission"
                    value={values.commission}
                    onChange={handleChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="submit">
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ProductComponent;
