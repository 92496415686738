import React, { createContext, useEffect, useContext, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderSalesKit from "../includes/NavbarSalesKit";
import axios from "../api/axios";
import CryptoJS from "crypto-js";
import Loader from "../includes/Loader";
import "../lib/scss/customerKit.scss";
const ProjectContext = createContext();
const OutletContext = createContext();

const CustomerKit = () => {
  const { key } = useParams();
  const init = useRef(false);
  const [projectData, setProjectData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [useContainer, setUseContainer] = useState(true);
  const [allProjectData, setAllProjectData] = useState([]);

  const encryptSecretKey = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
  const encryptSecretIV = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
  const decodedToken = atob(key);
  const decryptedData = CryptoJS.AES.decrypt(decodedToken, encryptSecretKey, { mode: CryptoJS.mode.CBC, iv: encryptSecretIV });
  const decryptedToken = decryptedData.toString(CryptoJS.enc.Utf8);
  const splitValues = decryptedToken.split("_");

  const contextData = { projectData: projectData, hostUrl: "https://app.outperformhq.io", hostUrlType: "cloud", company_id: splitValues[0], allProjectData: allProjectData };

  const getContextData = async () => {
    try {
      const response = await axios.post("https://www.nexcrmapis.com/cloud_staging/ws/ws_rea_prod.php", { task: "CustomerKit", key: key }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } });
      const data = response.data;

      if (data.status === 0) {
        data.record.forEach((info) => {
          info.project_id = info.project_name.replaceAll(" ", "").toLowerCase().trim();
          if (info.phase_count > 0 && info.phase_record) {
            info.phase_record.forEach((record) => {
              record.phase_id = record.phase_name.replaceAll(" ", "").toLowerCase().trim();
            });
          }
        });
        setAllProjectData(data);
        setProjectData(data.record);
        if (location.pathname === `/rea-sales-kit/${key}` || location.pathname === `/rea-sales-kit/${key}/`) {
          navigate(`/rea-sales-kit/${key}/project`, { replace: true });
        } else {
          navigate(location.pathname, { replace: true });
        }
      } else {
        setProjectData([]);
      }
    } catch (error) {
      setProjectData([]);
    }
  };

  useEffect(() => {
    if (projectData.length === 0) {
      init.current = false;
    }
  }, [projectData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const initData = async () => {
      try {
        await getContextData();
        init.current = true;
      } catch {}
    };

    if (location.pathname === "/rea-sales-kit" || location.pathname === "/rea-sales-kit/") {
      navigate("/", { replace: true });
    } else {
      if (!init.current) {
        initData();
      }
    }
  }, [init.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProjectContext.Provider value={contextData}>
      {/* <OutletContext.Provider value={{ useContainer, setUseContainer }}> */}
      {init.current === false ? (
        <Loader />
      ) : (
        <div className="customer-kit">
          <HeaderSalesKit />
          <div className="w-100 h-100 overflow-x-auto">
            <Outlet />
          </div>
        </div>
      )}
      {/* </OutletContext.Provider> */}
    </ProjectContext.Provider>
  );
};

export const useKit = () => {
  return useContext(ProjectContext);
};

export const useOutlet = () => {
  return useContext(OutletContext);
};

export default CustomerKit;
