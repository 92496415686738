import React, { useEffect, useState } from "react";
import { Container, Navbar, Button, Nav, Image } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import OutperformLogo from "../assets/images/outperform-logo.png";
import OutperformLogo2 from "../assets/images/outperform_logo_menu2.svg";

const HeaderSalesKit = () => {
  const { key, project_id, phase_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    console.log(location.pathname);
    const paths = {
      [`/rea-sales-kit/${encodeURIComponent(key)}/project`]: "Project",
      [`/rea-sales-kit/${encodeURIComponent(key)}/${project_id}`]: "Phase",
      [`/rea-sales-kit/${encodeURIComponent(key)}/${project_id}/${phase_id}`]: "Phase Details",
      [`/rea-sales-kit/${encodeURIComponent(key)}/${project_id}/${phase_id}/saleschart`]: "Sales Chart",
      default: "Outperform",
    };

    const titlePath = Object.keys(paths).find((path) => location.pathname === path) || paths.default;
    document.title = paths[titlePath];
    setPageTitle(paths[titlePath]);
  }, [location]);

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary shadow-sm" style={{ height: 56 }}>
      <Container>
        <Navbar className="p-0 m-0">
          <Navbar.Brand>
            {(pageTitle === "Phase" || pageTitle === "Phase Details" || pageTitle === "Sales Chart") && (
              <Button
                variant="light"
                className="me-2"
                onClick={() => {
                  if (pageTitle === "Phase") {
                    navigate(`/rea-sales-kit/${encodeURIComponent(key)}/project`);
                  } else if (pageTitle === "Phase Details") {
                    navigate(`/rea-sales-kit/${encodeURIComponent(key)}/${project_id}`);
                  } else if (pageTitle === "Sales Chart") {
                    navigate(`/rea-sales-kit/${encodeURIComponent(key)}/${project_id}/${phase_id}`);
                  }
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </Button>
            )}

            <span className="op-extrabold-font text-muted" style={{ fontSize: 15 }}>
              {pageTitle}
            </span>
          </Navbar.Brand>
        </Navbar>

        <Navbar className="p-0 m-0">
          <Navbar.Brand className="desktop-logo">
            <Image src={OutperformLogo} width={150} />
          </Navbar.Brand>

          <Navbar.Brand className="mobile-logo">
            <Image src={OutperformLogo2} width={30} />
          </Navbar.Brand>
        </Navbar>
      </Container>
    </Navbar>
  );
};

export default HeaderSalesKit;
