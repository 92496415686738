import { faGrid } from "@fortawesome/pro-solid-svg-icons";
import { faBuildings, faCircleDollar, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import { Nav } from "react-bootstrap";
import { debounce } from "lodash";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { useState, useEffect } from "react";
import { setCurrency } from "../lib/js/Function";
import { useNavigate } from "react-router-dom";

const SearchDropdown = ({ onSearch, onLoading, handleSearchClose }) => {
  const { session } = useAuth();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recent, setRecent] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setOptions([]);
    onLoading(true);
    setLoading(true);

    if (!onSearch) {
      onLoading(false);
      setLoading(false);
      setOptions([]);
    }

    const fetchData = async () => {
      try {
        const response = await axios.get("ws/ws_deallisting.php", {
          params: {
            task: 1,
            prospectname: onSearch,
            ds: "",
            de: "",
            pipeid: "All",
            user: 0,
            source: 0,
            stage: 0,
            dealstatus: 0,
            bu_id: 0,
            cfid: "",
            cfvalue: "",
            startRow: 0,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          setOptions(data.record);
          onLoading(false);
          setLoading(false);
        } else {
          setOptions([]);
          onLoading(false);
          setLoading(false);
        }
      } catch {
        setOptions([]);
        onLoading(false);
        setLoading(false);
      }
    };

    const debouncedLoadOptions = onSearch ? debounce(fetchData, 1000) : null;

    if (debouncedLoadOptions) {
      debouncedLoadOptions();
    }

    return () => {
      if (debouncedLoadOptions) {
        debouncedLoadOptions.cancel();
      }
    };
  }, [onSearch, session.user_ac_token, session.company_token]);

  useEffect(() => {
    const recent = sessionStorage.getItem("recentViewed") || "[]";
    setRecent(JSON.parse(recent));
  }, []);

  const openOptions = (record) => {
    const recent = sessionStorage.getItem("recentViewed") || "[]";
    const options = JSON.parse(recent);
    const isRecordExists = options.some((data) => data.deal_id === record.deal_id);

    if (!isRecordExists) {
      options.push(record);
      const optionsEncode = JSON.stringify(options);
      sessionStorage.setItem("recentViewed", optionsEncode);
    }

    // navigate(0);
    navigate(`/deal/${record.deal_id}`, { replace: true });
    handleSearchClose(false);
  };

  const setDealStatus = (input) => {
    if (input === "new") {
      return "Contact Lead";
    } else if (input === "active") {
      return "Progressing";
    } else if (input === "inactive") {
      return "Follow Up";
    }
  };

  return (
    <div className={`op-search-dropdown shadow-sm ${onSearch ? "active" : ""}`}>
      {onSearch && (
        <div className={`left-panel ${onSearch ? "open" : "close"}`}>
          <div className="d-flex flex-column flex-shrink-0 p-2">
            <Nav className="nav-pills flex-column mb-auto">
              <Nav.Item>
                <Nav.Link className="search-filter active op-text-medium">
                  <div className="nav-icon">
                    <FontAwesomeIcon icon={faGrid} size="lg" />
                  </div>
                  <div className="search-filter-text">All categories</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="search-filter op-text-medium" disabled>
                  <div className="nav-icon">
                    <FontAwesomeIcon icon={faCircleDollar} size="lg" />
                  </div>
                  <div className="search-filter-text">Deals</div>
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link className="search-filter op-text-medium" disabled>
                  <div className="nav-icon">
                    <FontAwesomeIcon icon={faUser} size="lg" />
                  </div>
                  <div className="search-filter-text">People</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="search-filter op-text-medium" disabled>
                  <div className="nav-icon">
                    <FontAwesomeIcon icon={faBuildings} size="lg" />
                  </div>
                  <div className="search-filter-text">Organizations</div>
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </div>
        </div>
      )}

      <div className="right-panel">
        {onSearch ? (
          <>
            <h6 className="op-text-medium">All search results</h6>
            <ListGroup>
              {options.length > 0 ? (
                options.map((record, index) => (
                  <ListGroup.Item action className="op-text-medium list-item" key={index} onClick={() => openOptions(record)}>
                    <div className="list-icon">
                      <div className="list-icon-wrapper">
                        <FontAwesomeIcon icon={faCircleDollar} size="lg" />
                      </div>
                    </div>
                    <div className="list-content">
                      <div className="list-title">{record.deal_title}</div>
                      <div className="list-desc">
                        {setCurrency(record.deal_value)}&nbsp;&nbsp;.&nbsp;&nbsp;{record.prospect_name}&nbsp;&nbsp;.&nbsp;&nbsp;{setDealStatus(record.deal_status)}
                      </div>
                    </div>
                  </ListGroup.Item>
                ))
              ) : (
                <div>{loading ? <div className="text-center op-text-medium">Please wait...</div> : <div className="text-center op-text-medium">No record found</div>}</div>
              )}
            </ListGroup>
          </>
        ) : (
          <>
            <h6 className="op-text-medium">Recently viewed</h6>
            {recent.length > 0 ? (
              <ListGroup>
                {recent.map((record, index) => (
                  <ListGroup.Item
                    action
                    className="op-text-medium list-item"
                    key={index}
                    onClick={() => {
                      // navigate(0);
                      navigate(`/deal/${record.deal_id}`, { replace: true });
                      handleSearchClose(false);
                    }}
                  >
                    <div className="list-icon">
                      <div className="list-icon-wrapper">
                        <FontAwesomeIcon icon={faCircleDollar} size="lg" />
                      </div>
                    </div>
                    <div className="list-content">
                      <div className="list-title">{record.deal_title}</div>
                      <div className="list-desc">
                        {setCurrency(record.deal_value)}&nbsp;&nbsp;.&nbsp;&nbsp;{record.prospect_name}&nbsp;&nbsp;.&nbsp;&nbsp;{setDealStatus(record.deal_status)}
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <div className="op-text-medium text-center">No recently viewed items at the moment.</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SearchDropdown;
