import { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import axios from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { faDown } from "@fortawesome/pro-solid-svg-icons";
import domtoimage from "dom-to-image";
import { io } from "socket.io-client";

const MobileSalesChartLevel = () => {
  const init = useRef(false);
  const { rea_phase_id, level_id, company_id, utoken, ctoken, booking_restricted } = useParams();
  const [loading, setLoading] = useState(true);
  const [isSalesChart, setIsSalesChart] = useState(false);
  const [unitList, setUnitList] = useState({});
  const imgRef = useRef(null);
  const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
  const [salesChartImage, setSalesChartImage] = useState("");
  const salesChartRef2 = useRef();
  const { search } = useLocation();
  const width = new URLSearchParams(search).get("w");
  const unitid = new URLSearchParams(search).get("unitid");
  const [unitRecord, setUnitRecord] = useState([]);

  // GET FUNCTION ------------------------------

  const getUnitListing = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud/ws/ws_rea_unit.php", {
        params: {
          task: "GetUnitListing",
          utoken: utoken,
          ctoken: ctoken,
          rea_phase_id: rea_phase_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setUnitRecord(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChart = async () => {
    try {
      const response = await axios.get(`https://app.outperformhq.io/cloud/assets/rea_booking/api_booking.php`, {
        params: {
          company_id: company_id,
          filename: "booking_map",
        },
      });

      const data = response.data;
      if (data.status === 0 && !Array.isArray(data.record)) {
        const clientMap = data.record[rea_phase_id];
        if (clientMap) {
          setUnitLot(clientMap);
        } else {
          setIsSalesChart(false);
        }
      } else {
        setIsSalesChart(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSalesChartImage = async (record) => {
    try {
      const url = record.split(".");
      const urlType = url[url.length - 1];

      if (urlType === 'jpg') {
        urlType = 'jpeg';
      }

      const response = await axios.get(`https://app.outperformhq.io/cloud/php/api_get_file_content.php`, {
        params: {
          filepath: `../assets/rea_booking/${company_id}/project/${record}`,
        },
      });

      const data = response.data;
      if (data && data.content) {
        const base64Mode = `data:image/${urlType};base64,`;
        setSalesChartImage(base64Mode + data.content);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // SET FUNCTION -----------------------------------------

  const setUnitLot = async (clientMap) => {
    if (clientMap) {
      const recordLevel = clientMap.level.find((record) => record.level_title === level_id);
      setUnitList(recordLevel);
      await getSalesChartImage(recordLevel.sales_chart);
      setTimeout(() => setIsSalesChart(true), 500);
    } else {
      setIsSalesChart(false);
    }
  };

  const setUnitColor3 = (status) => {
    if (status === "available") {
      return "rgba(25, 135, 84, 0.5)";
    } else if (status === "booked") {
      return Number(booking_restricted) === 0 ? "rgba(255, 193, 7, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "reserved") {
      return Number(booking_restricted) === 0 ? "rgba(13, 110, 253, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "sold") {
      return Number(booking_restricted) === 0 ? "rgba(220, 53, 69, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "unavailable") {
      return Number(booking_restricted) === 0 ? "rgba(153, 153, 153, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else if (status === "on_hold") {
      return Number(booking_restricted) === 0 ? "rgba(253, 126, 20, 0.5)" : "rgba(220, 53, 69, 0.5)";
    } else {
      return "";
    }
  };

  const setFindScaleRect = (coords, image) => {
    const [x1, y1, x2, y2] = coords.map(Number);
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    return [x1 * scaleX, y1 * scaleY, x2 * scaleX, y2 * scaleY];
  };

  const setFindScalePoly = (coords, image) => {
    const scaleX = image.width / image.naturalWidth;
    const scaleY = image.height / image.naturalHeight;
    const formattedCoords = [];
    for (let i = 0; i < coords.length; i += 2) {
      const x = coords[i] * scaleX;
      const y = coords[i + 1] * scaleY;
      formattedCoords.push(`${x}, ${y}`);
    }
    return formattedCoords.join(" ");
  };

  // SEND FUNCTION ----------------------------------------

  const sendDownload = () => {
    domtoimage
      .toPng(salesChartRef2.current)
      .then(function (dataUrl) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ mode: "download", record: dataUrl, record2: salesChartImage }));
      })
      .catch(function (error) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ mode: "error", record: error }));
      });
  };

  // RENDER FUNCTION --------------------------------------

  const RenderControl = ({ zoomIn, zoomOut }) => (
    <Stack direction="vertical" gap={2} style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
      <Button variant="light" className="shadow-sm" onClick={() => zoomIn()}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={() => zoomOut()}>
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <Button variant="light" className="shadow-sm" onClick={sendDownload}>
        <FontAwesomeIcon icon={faDown} />
      </Button>
    </Stack>
  );

  const RenderClientUnitRect = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;

      const coords = record.coords.split(",");
      const scaledCoords = setFindScaleRect(coords, image.current);
      const unitData = unitRecord.find((data) => data.rea_unit_id === record.rea_unit_id);

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;
      const jsonData = { mode: "unit", record: unitData };

      return (
        <>
          <rect key={record.rea_unit_id} width={scaledCoords[2] - scaledCoords[0]} height={scaledCoords[3] - scaledCoords[1]} y={scaledCoords[1]} x={scaledCoords[0]} fill={setUnitColor3(unitData.unit_status)} onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify(jsonData))} />

          {Number(unitData.rea_unit_id) === Number(unitid) && (
            <circle cx={scaledCoords[0]} cy={(scaledCoords[1] + scaledCoords[3]) / 2} r={width ? 0.5 : 5} fill="#87E400">
              <animate attributeName="r" values={width ? "0.5;1;0.5" : "3;5;3"} dur="1s" repeatCount="indefinite" />
            </circle>
          )}

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={scaledCoords[0]} cy={scaledCoords[1]} r={width ? 2 : 5} fill="#000" />
              <text x={scaledCoords[0]} y={scaledCoords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: width ? 2 : 5 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const scaledCoords = setFindScalePoly(coords, image.current);
      const circleScaled = setFindScaleRect(coords, image.current);
      const unitData = unitRecord.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;
      const jsonData = { mode: "unit", record: unitData };

      return (
        <>
          <polygon key={record.rea_unit_id} points={scaledCoords} fill={setUnitColor3(unitData.unit_status)} onClick={() => window.ReactNativeWebView.postMessage(JSON.stringify(jsonData))} />

          {Number(unitData.rea_unit_id) === Number(unitid) && (
            <circle cx={circleScaled[0]} cy={(circleScaled[1] + circleScaled[3]) / 2} r={width ? 0.5 : 5} fill="#87E400">
              <animate attributeName="r" values={width ? "0.5;1;0.5" : "3;5;3"} dur="1s" repeatCount="indefinite" />
            </circle>
          )}

          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={circleScaled[0]} cy={circleScaled[1]} r={width ? 2 : 5} fill="#000" />
              <text x={circleScaled[0]} y={circleScaled[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: width ? 2 : 5 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitRect2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;

      const coords = record.coords.split(",");
      const unitData = unitRecord.find((data) => data.rea_unit_id === record.rea_unit_id);

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <rect key={record.rea_unit_id} width={coords[2] - coords[0]} height={coords[3] - coords[1]} y={coords[1]} x={coords[0]} fill={setUnitColor3(unitData.unit_status)} />
          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const RenderClientUnitPoly2 = useCallback(
    ({ record, image }) => {
      if (!image || !image.current) return null;
      const coords = record.coords.split(",");
      const unitData = unitRecord.find((data) => Number(data.rea_unit_id) === Number(record.rea_unit_id));

      if (!unitData) return;
      unitData.unit_status = Number(unitData.on_hold) === 1 ? "on_hold" : unitData.unit_status;

      return (
        <>
          <polygon key={record.rea_unit_id} points={coords} fill={setUnitColor3(unitData.unit_status)} />
          {Number(unitData.total_interested) > 0 && (
            <>
              <circle cx={coords[0]} cy={coords[1]} r="10" fill="#000" />
              <text x={coords[0]} y={coords[1]} textAnchor="middle" alignmentBaseline="middle" fill="#fff" style={{ fontSize: 10 }}>
                {unitData.total_interested}
              </text>
            </>
          )}
        </>
      );
    },
    [imgRef, unitRecord] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // USEEFFECT FUNCTION ------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getUnitListing();
          await getSalesChart();
          init.current = true;
          setTimeout(() => setLoading(false), 600);
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (init.current) {
      const socket = io.connect("https://app.outperformhq.io:50100", {
        query: "roomID=saleschart_" + rea_phase_id,
      });

      socket.on("chat message", function () {
        getUnitListing();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [init.current]);

  return (
    <Container className="m-0 p-0" fluid style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
      {loading ? (
        <Loader />
      ) : (
        <div className="px-4">
          <Row>
            <Col xxl={12}>
              <TransformWrapper initialScale={1} initialPositionX={200} wheel={{ disabled: true }}>
                {(utils) => (
                  <Container fluid className="position-relative m-0 p-0">
                    <RenderControl {...utils} />
                    <TransformComponent wrapperStyle={{ width: "100%", height: "80vh", backgroundColor: "#999" }}>
                      <div className="position-relative d-inline-block" style={{ width: width ? width : 1000 }}>
                        <img
                          ref={imgRef}
                          src={`https://app.outperformhq.io/cloud/assets/rea_booking/${company_id}/project/${unitList.sales_chart}`}
                          width={"100%"}
                          onLoad={() => {
                            const { width, height } = imgRef.current;
                            setSvgDimensions({ width, height });
                          }}
                          alt="svg"
                        />
                        {isSalesChart ? (
                          <svg width={svgDimensions.width} height={svgDimensions.height} style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}>
                            {unitList.unit.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly key={index} record={record} image={imgRef} /> : null))}
                          </svg>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </TransformComponent>
                    <div style={{ overflow: "auto", height: 0, width: 0 }}>
                      <div ref={salesChartRef2} className="position-relative d-inline-block" style={{ width: imgRef.current ? imgRef.current.naturalWidth : 1000 }}>
                        <img src={salesChartImage} width={"100%"} alt="saleschart" />
                        {isSalesChart && imgRef.current && (
                          <svg width={imgRef.current ? imgRef.current.naturalWidth : 0} height={imgRef.current ? imgRef.current.naturalHeight : 0} style={{ position: "absolute", top: 0, left: 0, zIndex: 999 }}>
                            {unitList.unit.map((record, index) => (record.shape === "rect" ? <RenderClientUnitRect2 key={index} record={record} image={imgRef} /> : record.shape === "poly" ? <RenderClientUnitPoly2 key={index} record={record} image={imgRef} /> : null))}
                          </svg>
                        )}
                      </div>
                    </div>
                  </Container>
                )}
              </TransformWrapper>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default MobileSalesChartLevel;
