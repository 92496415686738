import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../lib/css/Project.css";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { Button, Card, Col, Container, Row, Stack } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CryptoJS from "crypto-js";
import { faHousePersonReturn } from "@fortawesome/pro-duotone-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const Project = () => {
  const { session } = useAuth();
  const { deal_id, prospect_id } = useParams();
  const init = useRef(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState({});

  // GET FUNCTION -----------------------------

  const getProjectListing = async () => {
    try {
      const response = await axios.get("ws/ws_rea_project.php", {
        params: {
          task: "ProjectList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setProjectList(data.record);
        data.record.forEach((record, idx) => {
          setThumbsSwiper((prevstate) => ({
            ...prevstate,
            [idx]: null,
          }));
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SET FUNCTION ----------------------------

  const setProjectImage = (image) => {
    if (image !== "generic.png") {
      return `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/${session.company_id}/project/${image}`;
    } else {
      return `${session.hostUrl}/${session.hostUrlType}/assets/rea_booking/generic.png`;
    }
  };

  // OPEN FUNCTION ---------------------------

  const openProject = (record) => {
    navigate(`/booking/project/${record.rea_project_id}/${deal_id}/${prospect_id}`);
  };

  const openSalesKit = () => {
    const encryptSecretKey = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    const encryptSecretIV = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
    const encryptedToken = CryptoJS.AES.encrypt(session.company_id + "_" + session.user_id, encryptSecretKey, { mode: CryptoJS.mode.CBC, iv: encryptSecretIV }).toString();
    const encodedToken = btoa(encryptedToken);
    window.open(`${session.origin}/rea-sales-kit/${encodedToken}/project`, "_blank");
  };

  const openShareWhatsapp = () => {
    const encryptSecretKey = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    const encryptSecretIV = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");
    const encryptedToken = CryptoJS.AES.encrypt(session.company_id + "_" + session.user_id, encryptSecretKey, { mode: CryptoJS.mode.CBC, iv: encryptSecretIV }).toString();
    const encodedToken = btoa(encryptedToken);
    const url = `${session.baseUrl}rea-sales-kit/${encodedToken}/project`;
    const text = `The eagerly awaited media details for all projects are ready and waiting for you. Dive into the vibrant visuals and comprehensive information you requested. It's all here, just for you: ${url}`;
    const whatsapp = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(whatsapp, "_blank");
  };

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getProjectListing();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0 internal-project">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" className="mb-2">
            <div>
              <h6>All Project</h6>
              <p className="ms-auto">{projectList.length > 1 ? `${projectList.length} records` : projectList.length === 1 ? `1 record` : `No record`}</p>
            </div>
            <div className="ms-auto">
              <Button variant="light" className="float-end shadow-sm border me-2" onClick={() => openSalesKit()}>
                <FontAwesomeIcon icon={faHousePersonReturn} size="xl" />
              </Button>

              <Button variant="light" className="float-end shadow-sm border me-2" onClick={() => openShareWhatsapp()}>
                <FontAwesomeIcon icon={faWhatsapp} size="xl" style={{ color: "#25D366" }} />
              </Button>
            </div>
          </Stack>
          <Row className="op-swiper">
            {projectList.length > 0 &&
              projectList.map((record, idx) => (
                <Col key={idx} xxl={12} className="mb-3" style={{ marginRight: 100 }}>
                  <Card className="shadow border-0 h-100">
                    <Row>
                      <Col xxl={7} className="cursor-pointer" onClick={() => openProject(record)}>
                        <Swiper spaceBetween={0} navigation={false} thumbs={{ swiper: thumbsSwiper[idx] }} modules={[FreeMode, Navigation, Thumbs]} className="mySwiper2">
                          {record.project_image.map((data, i) => (
                            <SwiperSlide key={i}>
                              <img src={setProjectImage(data)} alt="" />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Col>
                      <Col xxl={5} className="py-4" style={{ paddingRight: "2.5rem" }}>
                        <Stack direction="vertical" className="h-100">
                          <h5 className="cursor-pointer" onClick={() => openProject(record)}>
                            {record.project_name}
                          </h5>
                          <p className="mb-0 text-muted">{record.project_location}</p>
                          <p className="mb-0">{record.project_desc}</p>
                          <div className="mt-auto">
                            <Swiper onSwiper={(swiper) => setThumbsSwiper((prevSwipers) => ({ ...prevSwipers, [idx]: swiper }))} spaceBetween={5} slidesPerView={4} freeMode={true} watchSlidesProgress={true} className="mySwiper" modules={[FreeMode, Navigation, Thumbs]}>
                              {record.project_image.map((data, i) => (
                                <SwiperSlide key={i}>
                                  <img src={setProjectImage(data)} alt="" />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </Stack>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default Project;
