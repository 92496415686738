import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Form, Stack, InputGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { PieChart, Pie, Cell } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-duotone-svg-icons";
// import { useTranslation } from "react-i18next";
// import { faRightLeft } from "@fortawesome/sharp-solid-svg-icons";
// import AddWSCampaign from "./whatsapp_campaign_component/AddWSCampaign";
import AddWSCampaignV2 from "./whatsapp_campaign_component/AddWSCampaignV2";

const WhatsappCampaign = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 5 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: "Date Campaign Create",
            field: "date_time_create",
            width: 200
        },
        {
            headerName: "Campaign Title",
            field: "campaign_title",
            filter: true,
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Curation",
            field: "curation_title",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Template Name",
            field: "template_name",
            flex: 1,
            minWidth: 400
        },
        {
            headerName: "Total Sent",
            field: "TOTAL_DELIVERED",
            width: 120,
            headerClass: 'center',
            cellClass: 'center',
            autoHeight: true,
            pinned: 'right',
            cellRenderer: (params) => {
                if (params.data) {
                    var chartData = [
                        {name: 'Total Delivered', value: Number(params.data.TOTAL_DELIVERED)},
                        {name: 'Total Contacted', value: (Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_DELIVERED))},
                    ];

                    return(
                        <Stack direction="horizontal" gap={2} className="position-relative d-flex justify-content-center">
                            <div>{params.data.TOTAL_DELIVERED}</div>
                            <PieChart width={100} height={110}>
                                <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"30%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                                    <Cell fill={"#000"} />
                                    <Cell fill={"#ddd"} />
                                </Pie>
                            </PieChart>
                        </Stack>
                    );
                }
            }
        },
        {
            headerName: "Read",
            field: "TOTAL_WAREAD",
            width: 120,
            pinned: 'right',
            headerClass: 'center',
            cellClass: 'center',
            cellRenderer: (params) => {
                if (params.data) {
                    var chartData = [
                        {name: 'Total Delivered', value: Number(params.data.TOTAL_WAREAD)},
                        {name: 'Total Contacted', value: (Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_WAREAD))},
                    ];

                    return(
                        <Stack direction="horizontal" gap={2} className="position-relative d-flex justify-content-center">
                            <div>{params.data.TOTAL_WAREAD}</div>
                            <PieChart width={100} height={110}>
                                <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"30%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                                    <Cell fill={"#000"} />
                                    <Cell fill={"#ddd"} />
                                </Pie>
                            </PieChart>
                        </Stack>
                    );
                }
            }
        },
        {
            headerName: "Responded",
            field: "TOTAL_RESPONDED",
            width: 120,
            pinned: 'right',
            headerClass: 'center',
            cellClass: 'center',
            cellRenderer: (params) => {
                if (params.data) {
                    var chartData = [
                        {name: 'Total Delivered', value: Number(params.data.TOTAL_RESPONDED)},
                        {name: 'Total Contacted', value: (Number(params.data.TOTAL_CONTACT) - Number(params.data.TOTAL_RESPONDED))},
                    ];

                    return(
                        <Stack direction="horizontal" gap={2} className="position-relative d-flex justify-content-center">
                            <div>{params.data.TOTAL_RESPONDED}</div>
                            <PieChart width={100} height={110}>
                                <Pie dataKey="value" isAnimationActive={false} data={chartData} cx={"30%"} cy={"50%"} innerRadius={20} outerRadius={30}>
                                    <Cell fill={"#000"} />
                                    <Cell fill={"#ddd"} />
                                </Pie>
                            </PieChart>
                        </Stack>
                    );
                }
            }
        },
        {
            headerName: "Total Leads",
            field: "TOTAL_CONTACT",
            width: 120,
            pinned: 'right',
            headerClass: 'center',
            cellClass: 'center'
        }
    ]);

    const getGridData = () => {
        
        axios.get("ws/ws_whatsapp_campaign.php", {
            params: {
                task: "getWACampaignListing",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                data.record.sort(function(a, b) { return new Date(b.date_time_create) - new Date(a.date_time_create) });
                setRowData(data.record);
                setRecordFound(data.totalRecord);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'No record'
                });
            }
        })
        .catch(error =>{
            setRowData([]);
            setRecordFound(0);
            setIsGrid(false);
            setIsLoading(false);

            Swal.fire({
                icon: 'error',
                title: error.message
            });
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSearch = (text) => {
        gridRef.current.api.setQuickFilter(text);
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <div>
            {isLoading ? <Loader/> : 
                <Container fluid className="p-4">
                    <Stack direction="horizontal" gap={2} className="mb-2">
                        <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>Add Campaign</button>
                        <Form.Group style={{width: '250px'}}>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="search by campaign title"
                                    onChange={(e) => {
                                        handleSearch(e.target.value);
                                    }}
                                />
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} size="xl" className="fontAwesomeIcon"/>
                                </InputGroup.Text>
                            </InputGroup>
                        </Form.Group>
                        <div className="ms-auto">
                            {Number(recordFound) === 1 ? `${recordFound} record found.` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}
                        </div>
                    </Stack>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                            />
                        </div>
                    </div>

                    {
                        isAdd && 
                        <AddWSCampaignV2
                            isOpen={setIsAdd}
                            onSuccess={() => {getGridData(); setIsAdd(false);}}
                        />
                    }
                </Container>
            }
        </div>
    );
}

export default WhatsappCampaign;