import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../lib/css/settingStyle.css";
// import NoRecord from "../includes/NoRecord";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";

const FBTokenSetting = () => {

    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "78vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingFBToken_headername_1'),
            field: "fb_page_name",
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingFBToken_headername_2'),
            field: "fb_page_access_token",
            flex: 1,
            width: 300,
            autoHeight: true,
            wrapText: true,
            resizable: true,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div>
                            {params.data.fb_page_access_token}
                        </div>
                    );
                }
            }
        },
        {
            headerName: t('SettingFBToken_headername_3'),
            width: 80,
            pinned: "right",
            resizable: true,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faCopy} size="xl" className="fontAwesomeIcon" onClick={() => {handleCopy(params.data.fb_page_access_token)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const handleCopy = (fb_token) => {
        navigator.clipboard.writeText(fb_token);
        Swal.fire({
            icon: "success",
            title: t('SettingFBToken_alert_successText_copy'),
            timer: 1000
        });
    }
    

    const getGridData = () => {
        
        axios.get("ws/ws_fb_token.php",{
            params:{
                task: "list",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1}>
                            <div className="px-2 ms-auto">{recordFound === 1 ? t('SettingFBToken_record_one', {count: recordFound}) : recordFound > 1 ? t('SettingFBToken_record_many',{count: recordFound}):t('SettingFBToken_record_no')}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={80}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {/* {!isGrid && <NoRecord message="No record at the moment." width={200}/>} */}
                </Container>
            )}
        </div>
    );

}

export default FBTokenSetting;
