import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import * as formik from "formik";
import { FormSelect } from "../../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";

const amenitiesType = [
  { label: "Education", value: "education" },
  { label: "Health Care", value: "healthcare" },
  { label: "Shopping", value: "shopping" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Public Transport", value: "public_transport" },
];

const amenitiesType2 = [
  { label: "All Type", value: "" },
  { label: "Education", value: "education" },
  { label: "Health Care", value: "healthcare" },
  { label: "Shopping", value: "shopping" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Public Transport", value: "public_transport" },
];

const BookingAmenities = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [phase, setPhase] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState({ label: "", value: "", lat: "", long: "", google: 1 });
  const [amenities, setAmenities] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({ rea_amenities_id: "", amenities_name: "", amenities_type: "", amenities_lat: "", amenities_lon: "" });
  const [typeFilter, setTypeFilter] = useState("");
  const [isLogin, setIsLogin] = useState(false);

  // GET FUNCTION ===================================================

  const getPhase = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseListing",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordData = data.record.map((record) => ({ label: record.phase_name, value: record.rea_phase_id, lat: record.phase_lat, long: record.phase_lon, google: record.googleapis_trigger }));
        setPhase(recordData);
        getData(recordData[0]);
        setSelectedPhase(recordData[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = useCallback(async (phase) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetAmenities",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: phase.value,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setAmenities(data.record);
      } else {
        setAmenities([]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // OPEN FUNCTION ===================================================

  const openEdit = (record) => {
    setInitialValues({
      rea_amenities_id: record.rea_amenities_id,
      amenities_name: record.amenities_name,
      amenities_type: record.amenities_type,
      amenities_lat: record.amenities_lat,
      amenities_lon: record.amenities_lon,
    });

    setIsEdit(true);
  };

  // ONCHANGE ========================================================

  const onchangePhase = (e) => {
    setSelectedPhase(e);
    getData(e);
  };

  // SEND FUNCTION ===================================================

  const sendAmenities = useCallback(
    async (values) => {
      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "CreateAmenities",
            amenities_name: values.amenities_name,
            amenities_type: values.amenities_type,
            amenities_lat: values.amenities_lat,
            amenities_lon: values.amenities_lon,
            rea_phase_id: selectedPhase.value,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getData(selectedPhase);
          setIsCreate(false);
          immediateToast("success", {
            title: "Success",
            message: "Successfully created",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedPhase]
  );

  const sendEditAmenities = useCallback(async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdateAmenities",
          amenities_name: values.amenities_name,
          amenities_type: values.amenities_type,
          amenities_lat: values.amenities_lat,
          amenities_lon: values.amenities_lon,
          rea_amenities_id: values.rea_amenities_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getData(selectedPhase);
        setIsEdit(false);
        immediateToast("success", {
          title: "Success",
          message: "Successfully updated",
          timeout: 2000,
        });
      } else {
        immediateToast("error", {
          title: "Failed",
          message: "Failed updated",
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const sendDeleteAmenities = useCallback(
    async (values) => {
      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemoveAmenities",
            rea_amenities_id: values.rea_amenities_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          setAmenities(amenities.filter((record) => Number(record.rea_amenities_id) !== Number(values.rea_amenities_id)));
          setIsEdit(false);
          immediateToast("success", {
            title: "Success",
            message: "Successfully deleted",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed deleted",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [amenities]
  );

  const sendGoogleAmenities = useCallback(
    async (values) => {
      if (values.password !== "p@ssword!123") {
        setIsLogin(false);
        return immediateToast("error", {
          title: "Unauthorized",
          message: "You are not allow to do this, please stop before i kill you",
          timeout: 2000,
        });
      }

      const currentPhase = { ...selectedPhase };
      currentPhase.google = 1;
      setSelectedPhase(currentPhase);

      const recordIndex = phase.findIndex((record) => Number(record.value) === Number(selectedPhase.value));
      const recordOption = [...phase];

      recordOption[recordIndex].google = 1;
      setPhase(recordOption);
      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "GoogleAmenities",
            rea_phase_id: selectedPhase.value,
            lat: selectedPhase.lat,
            lon: selectedPhase.long,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getData(selectedPhase);

          immediateToast("success", {
            title: "Success",
            message: "Successfully deleted",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed deleted",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLogin(false);
      }
    },
    [phase, selectedPhase]
  );

  // EDUCATION GRID ==================================================

  const containterStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Name",
      field: "amenities_name",
      autoHeight: true,
      wrapText: true,
      cellStyle: { lineHeight: 1.5 },
    },
    {
      headerName: "Type",
      field: "amenities_type",
      cellRenderer: (params) =>
        params.data.amenities_type === "education"
          ? "Education"
          : params.data.amenities_type === "healthcare"
          ? "Health Care"
          : params.data.amenities_type === "shopping"
          ? "Shpping"
          : params.data.amenities_type === "entertainment"
          ? "Entertainment"
          : params.data.amenities_type === "public_transport"
          ? "Public Transport"
          : "",
    },
    {
      headerName: "Latitude",
      field: "amenities_lat",
    },
    {
      headerName: "Longitude",
      field: "amenities_lon",
    },
    {
      headerName: "",
      field: "",
      pinned: "right",
      maxWidth: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2">
              <FontAwesomeIcon icon={faTrash} size="sm" onClick={() => sendDeleteAmenities(params.data)} />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_amenities_id.toString();
  }, []);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getPhase();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" gap={2} className="mb-4">
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create Amenities
            </Button>
            <Button variant="" className="op-primary-color text-light" disabled={(Number(selectedPhase.lat) === 0 && Number(selectedPhase.long) === 0) || Number(selectedPhase.google) === 1} onClick={setIsLogin}>
              Google Amenities
            </Button>
            <div className="ms-auto">
              <FormSelect options={amenitiesType2} valueDefault={amenitiesType2[0]} onChange={(e) => setTypeFilter(e.value)} width="250px" />
            </div>
            <div>
              <FormSelect options={phase} valueDefault={phase[0]} onChange={onchangePhase} width="250px" />
            </div>
          </Stack>

          <div style={containterStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact defaultColDef={gridColDef} rowData={typeFilter ? amenities.filter((record) => record.amenities_type === typeFilter) : amenities} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={50} suppressRowClickSelection={false} animateRows={true} />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik onSubmit={sendAmenities} initialValues={{ amenities_name: "", amenities_type: "", amenities_lat: "", amenities_lon: "" }}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New Amenities</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Amenties Name</Form.Label>
                  <Form.Control type="text" value={values.amenities_name} onChange={handleChange("amenities_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Amenties Type</Form.Label>
                  <FormSelect options={amenitiesType} onChange={(e) => handleChange("amenities_type")(e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control type="text" value={values.amenities_lat} onChange={handleChange("amenities_lat")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control type="text" value={values.amenities_lon} onChange={handleChange("amenities_lon")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEditAmenities} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit Amenities</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Amenties Name</Form.Label>
                  <Form.Control type="text" value={values.amenities_name} onChange={handleChange("amenities_name")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Amenties Type</Form.Label>
                  <FormSelect options={amenitiesType} valueDefault={amenitiesType.find((record) => record.value === values.amenities_type)} onChange={(e) => handleChange("amenities_type")(e.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control type="text" value={values.amenities_lat} onChange={handleChange("amenities_lat")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control type="text" value={values.amenities_lon} onChange={handleChange("amenities_lon")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isLogin} onHide={setIsLogin} size="lg">
        <Formik onSubmit={sendGoogleAmenities} initialValues={{ password: "" }}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Google Amenities</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" value={values.password} onChange={handleChange("password")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default BookingAmenities;
