import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Col, Container, Row, Stack, Form, Modal, Button, InputGroup, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import { faFilterList, faMagnifyingGlass, faSearch, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { useAuth } from "../auth/AuthContext";
import Tippy from "@tippyjs/react";
import { faEllipsisStrokeVertical } from "@fortawesome/pro-regular-svg-icons";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";
import "../lib/css/settingStyle.css";
import moment from "moment";
import FormSelect from "../includes/FormSelect";
import EditPersonGl from "./gamuda/EditPersonGl";
import EditPersonGlVN from "./gamuda/EditPersonGLVN";
import "../lib/css/ClickAbleGrid.css";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

const ProspectListGL = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const { t } = useTranslation();

    const [userVal, setUserVal] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [contactTypeVal, setContactTypeVal] = useState("");
    const [trigger, setTrigger] = useState(0);

    const [userList, setUserList] = useState([]);
    const [prosData, setProsData] = useState("");

    const [isFilter, setIsFilter] = useState(false);
    const [isEditProspect, setIsEditProspect] = useState(false);

    const [isGrid, setIsGrid] = useState(false);
    const [gridCount, setGridCount] = useState(0);
    const gridRef = useRef(null)
    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "80vh" : "80vh", paddingTop: 20 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const roleList = [
        {label: t('ProspectListGL_buyer'), value: "Buyer"},
        {label: t('ProspectListGL_subsale'), value: "Subsales"},
        {label: t('ProspectListGL_prospect'), value: "Prospect"}
    ];

    const gridColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false
        };
    }, []);

    const vietnam = ['402', '412'];

    const RenderProspectAction = (params) => {
        const tippyRef = useRef();
        const [visible, setVisible] = useState(false);

        const show = () => setVisible(true);
        const hide = () => setVisible(false);

        const dropDownContent = (
            <div className="op-ag-action">
              {(session.readwrite_permission == 0 || session.readwrite_permission == 1) && <div className="op-ag-action-item" onClick={() => {handleClick("edit")}}>
                {t('ProspectListGL_tippy_1')}
              </div>}
              {session.readwrite_permission == 0 && <div className="op-ag-action-item" onClick={() => {handleClick("delete")}}>
              {t('ProspectListGL_tippy_2')}
              </div>}
            </div>
        );

        const handleClick = (opt) => {
            hide();

            if (opt == "edit") {
                handleEdit(params.data);
            }else{
                handleDelete(params.data);
            }
        }

        return (
            <Tippy ref={tippyRef} content={dropDownContent} visible={visible} onClickOutside={hide} allowHTML={true} arrow={false} appendTo={document.body} interactive={true} placement="left">
              {params.data && <button className="btn btn-light" onClick={visible ? hide : show}>
                <FontAwesomeIcon icon={faEllipsisStrokeVertical} size="lg" />
              </button>}
            </Tippy>
        );
    }

    const handleEdit = (data) => {
        setProsData(data);
        setIsEditProspect(true);
    }

    const handleDelete = (data) => {
        Swal.fire({
            icon: "warning",
            title: t('ProspectListGL_delete_prospect_title'),
            text: t('ProspectListGL_delete_prospect_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('ProspectListGL_delete_prospect_confirmText'),
            cancelButtonText: t('ProspectListGL_delete_prospect_cancelText')
        })
        .then(result => {
            if (result.isConfirmed) {

                axios.get("ws/ws_prospect.php",{
                    params: {
                        task: "10",
                        prospect: data.prospect_id,
                        forceDelete: "",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status == 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('ProspectListGL_delete_prospect_success_title'),
                            text: t('ProspectListGL_delete_prospect_success_text'),
                            timer: 1500
                        })
                        .then(result => {
                            setTrigger(trigger + 1);
                        });
                    }else{
                        Swal.fire({
                            icon: "warning",
                            title: t('ProspectListGL_delete_prospect_dependency_title'),
                            text: t('ProspectListGL_delete_prospect_dependency_text'),
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t('ProspectListGL_delete_prospect_dependency_confirmText'),
                            cancelButtonText: t('ProspectListGL_delete_prospect_dependency_cancelText')
                        })
                        .then(result => {
                            if (result.isConfirmed) {
                                handleForceDelete(data);   
                            }
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    const handleForceDelete = (data) => {
        axios.get("ws/ws_prospect.php",{
            params: {
                task: "10",
                prospect: data.prospect_id,
                forceDelete: "yes",
                utoken: session.user_ac_token,
                ctoken: session.company_token,
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: t('ProspectListGL_delete_prospect_success_title'),
                    text: t('ProspectListGL_delete_prospect_success_text')
                })
                .then(result => {
                    setTrigger(trigger + 1);
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('ProspectListGL_alert_failed_title'),
                    text: t('ProspectListGL_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const [gridColumn, setGridColumn] = useState([
        {
            headerName: t('ProspectListGL_headerName_1'),
            field: "prospect_name",
            resizable: true,
            width: 300,
            // wrapText: true,
            cellRenderer: (params) => {
                if (params.data) {
                    var initials = params.data.prospect_name ? params.data.prospect_name.split("")[0] : params.data.contact1 ? params.data.contact1.split("")[0] : params.data.email1 ? params.data.email1.split("")[0] : "N";
                    var prospectID = "";

                    if (params.data.hq_prospect_id && params.data.hq_prospect_id != 0 && session.company_id == 251) {
                        prospectID = params.data.hq_prospect_id;
                    }else{
                        prospectID = params.data.prospect_id;
                    }

                    return (
                        <div className="d-flex">
                            <div className="avatar me-1">
                                <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                                    <div className="avatar-txt text-uppercase">{initials}</div>
                                </div>
                            </div>
                            <span className="clickable" onClick={() => {window.open(session.origin+"/prospect/"+prospectID)}}>
                                {params.data.prospect_name ? params.data.prospect_name : params.data.contact1 ? params.data.contact1 : params.data.email1 ? params.data.email1 : "No Name"}
                            </span>
                        </div>
                    );
                }else{
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            },
            // onCellClicked: function(params){
            //     var prospectID = "";

            //     if (params.data.hq_prospect_id && params.data.hq_prospect_id != 0 && session.company_id == 251) {
            //         prospectID = params.data.hq_prospect_id;
            //     }else{
            //         prospectID = params.data.prospect_id;
            //     }

            //     window.open(session.origin+"/prospect/"+prospectID);
            // }
        },
        {
            headerName: t('ProspectListGL_headerName_2'),
            field: "contact1",
            resizable: true,
            flex: 1,
            // minWidth: "300"
        },
        {
            headerName: t('ProspectListGL_headerName_3'),
            field: "email1",
            resizable: true,
            flex: 1,
            // minWidth: "300"
        },
        {
            headerName: vietnam.includes(session.company_id) ? t('ProspectListGL_headerName_4') : "PDPA",
            field: "pdpa",
            resizable: true,
            flex: 1,
            // minWidth: "100",
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.pdpa.toUpperCase() == 'TRUE') {
                        return t('ProspectListGL_yes');
                    }else if(params.data.pdpa.toUpperCase() == 'FALSE'){
                        return t('ProspectListGL_no');
                    }else{
                        return params.data.pdpa;
                    }
                }
            }
        },
        {
            headerName: t('ProspectListGL_headerName_5'),
            resizable: true,
            // flex: 1,
            // minWidth: "80",
            cellRenderer: (params) => {
                if (params.data) {
                    return moment(new Date(params.data.prospect_date_time_create)).format("lll");
                }
            }
        },
        {
            headerName: t('ProspectListGL_headerName_6'),
            resizable: true,
            flex: 1,
            // minWidth: "200",
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.tsmb_id > 0 || params.data.tsjb_id > 0) {
                        return t('ProspectListGL_buyer');
                    }else if(params.data.tssmb_id > 0 || params.data.tssjb_id > 0){
                        return t('ProspectListGL_subsale');
                    }else{
                        return t('ProspectListGL_prospect');
                    }
                }
            }
        },
        {
            headerName: 'Owner',
            field: 'OWNER_NAME',
            resizable: true,
            flex: 1,
        },
        {
            headerName: t('ProspectListGL_headerName_7'),
            resizable: true,
            width: "100",
            headerClass: 'center',
            cellClass: "center", 
            cellRenderer: RenderProspectAction, 
            // pinned: "right"
        }
    ]);

    var api_url = vietnam.includes(session.company_id) ? "ext/glMigration_vn/ws_prospect_VN.php" : "ws/ws_prospect.php";

    const gridDataSource = useMemo(() => {
        return {
            rowCount: undefined,
            getRows: (params) => {
                axios.get(api_url,{
                    params: {
                        task: vietnam.includes(session.company_id) ? "searchGLProspectsv2_VN" : "searchGLProspectsv3",
                        company: session.company_id,
                        uid: session.user_id,
                        m: "yes",
                        page: Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
                        size: params.endRow - params.startRow,
                        q: searchVal,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token,
                        user: userVal,
                        contactType: contactTypeVal
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status == 0) {
                        setIsGrid(true);
                    }else{
                        setIsGrid(false);
                    }

                    setGridCount(data.totalCount);

                    setTimeout(() => {
                        var gridInfo = [];
                        var totalRecord = 0;
                        if (data.status == 0) {
                            // data.record.sort((a, b) => a.prospect_name.localeCompare(b.prospect_name));
                            gridInfo = data.record;
                            totalRecord = Number(data.totalCount);
                        } else {
                            gridInfo = [];
                            totalRecord = 0;
                        }

                        var rowsThisPage = gridInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
            }
        }
    }, [session, userVal, trigger]);

    const getAllUser = () => {
        axios.get("ws/ws_user.php",{
            params:{
                task: '4b',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                var list = data.record.map(data => {
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(list);
            }else{
                setUserList();
            }
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
        }
    }, []);

    const handleKeyPress = (e) => {
        if (e.key == "Enter") {
            setTrigger(trigger + 1);
        }
    }

    return(
        <Container fluid className="p-4">
            <div className="mb-1">
                <Stack direction="horizontal" gap={1}>
                    <div className="me-2 ms-auto">
                        Result: {gridCount == 1 ? t("DealList_record_one", { count: gridCount }) : gridCount > 1 ? t("ProspectListGL_record_other", {count: gridCount}) : t('ProspectListGL_record_no')}
                    </div>
                    {session.role_id == 1 && 
                    <div className="me-2">
                        <Form.Group style={{width: "250px"}}>
                            <FormSelect
                                placeholder={t('ProspectListGL_userDropdown_placeholder')}
                                options={userList}
                                isClearable={true}
                                isSearchable={true}
                                shadow={true}
                                border={false}
                                onChange={(e) => {setUserVal(e ? e.value : "")}}
                            />
                        </Form.Group>
                    </div>
                    }
                    <div className="me-1" style={{width: "250px"}}>
                        <InputGroup className="border-0 shadow-sm">
                            <Form.Control
                                type="text"
                                placeholder={t('ProspectListGL_search_placeholder')}
                                value={searchVal}
                                onKeyDown={handleKeyPress}
                                onChange={(e) => {setSearchVal(e.target.value)}}
                            />
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="fontAwesomeIcon" size="lg" onClick={() => {setTrigger(trigger + 1)}}/>
                            </InputGroup.Text>
                        </InputGroup>
                    </div>
                    <div className="me-1">
                        <button type="button" className="btn btn-light shadow-sm" onClick={() => {setTrigger(trigger + 1); setSearchVal("")}}>
                            <FontAwesomeIcon icon={faArrowsRotate} size="lg"/>
                        </button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-light shadow-sm" onClick={setIsFilter}>
                            <FontAwesomeIcon icon={faFilterList} size="lg"/>
                        </button>
                    </div>
                </Stack>
            </div>
            <div>
                <div style={containerStyle}>
                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={gridColumn}
                            datasource={gridDataSource}
                            defaultColDef={gridColDef}
                            rowSelection={"multiple"}
                            rowModelType={"infinite"}
                            rowHeight={70}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={2}
                            infiniteInitialRowCount={10}
                            maxBlocksInCache={2}
                            pagination={true}
                            paginationPageSize={100}
                            paginationPageSizeSelector={false}
                            suppressRowClickSelection={true}
                            animateRows={true}
                        />
                    </div>
                </div>
            </div>

            <Offcanvas show={isFilter} onHide={setIsFilter} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('ProspectListGL_offcanvas_title')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form.Group>
                        <FormSelect
                            placeholder={t('ProspectListGL_roleDropdown_placeholder')}
                            options={roleList}
                            value={roleList.filter(opt => opt.value == contactTypeVal)}
                            isClearable={true}
                            shadow={true}
                            onChange={(e) => {setContactTypeVal(e ? e.value : "")}}
                        />
                    </Form.Group>
                </Offcanvas.Body>
                <div className="offcanvas-footer">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={() => {setTrigger(trigger + 1); setIsFilter(false);}}>{t("ProspectListGL_offcanvas_filter_button")}</button>
                </div>
            </Offcanvas>

            {isEditProspect && !vietnam.includes(session.company_id) &&
            <EditPersonGl 
                prospect_id={(prosData.hq_prospect_id && Number(prosData.hq_prospect_id) != 0) ? prosData.hq_prospect_id : prosData.prospect_id}
                pipeline_id={prosData.pipeline_id}
                closeModal={setIsEditProspect}
                onSuccess={()=>{setTrigger(trigger + 1); setIsEditProspect(false);}}
            />}

            {isEditProspect && vietnam.includes(session.company_id) &&
            <EditPersonGlVN 
                prospect_id={(prosData.hq_prospect_id && Number(prosData.hq_prospect_id) != 0) ? prosData.hq_prospect_id : prosData.prospect_id}
                pipeline_id={prosData.pipeline_id}
                closeModal={setIsEditProspect}
                onSuccess={()=>{setTrigger(trigger + 1); setIsEditProspect(false);}}
            />}
        </Container>
    );
}

export default ProspectListGL;