import { useState, useRef, useEffect, useMemo } from "react";
import { Stack, Form, Container, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import Loader from "../includes/Loader";
import "../lib/scss/op-report-style.scss";
import moment from "moment";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";

const IncomingLogGL = () => {
    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isReport, setIsReport] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [startDate, setStartDate] = useState(moment().subtract(7, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchVal, setSearchVal] = useState("");
    const [thirdParty, setThirdParty] = useState("");

    const thirdPartyList = [
        {label: 'RR', value: 'rr'},
        {label: 'GLL', value: 'gll'},
        {label: 'GL PLAY', value: 'glplay'}
    ];

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "80vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          resizable: true,
          wrapText: true,
          autoHeight: true
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: 'Date time triggered',
            field: 'date_time_triggered',
            flex: 1,
            minWidth: 200
        },
        {
            headerName: 'Lifestyle Log ID',
            field: 'rr_lifestyle_log_id',
            headerClass: 'center',
            cellClass: 'center',
            width: 180
        },
        {
            headerName: '3rd party',
            field: '3rd_party',
            headerClass: 'center',
            cellClass: 'center',
            width: 100
        },
        {
            headerName: 'Deal ID',
            field: 'deal_id',
            headerClass: 'center',
            cellClass: 'center',
            width: 100
        },
        {
            headerName: 'Prospect ID',
            field: 'prospect_id',
            headerClass: 'center',
            cellClass: 'center',
            width: 100
        },
        {
            headerName: 'Data',
            width: 350,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.data) {
                        var value = JSON.stringify(params.data.data);

                        value = value.replaceAll("{","");
                        value = value.replaceAll("}","");
                        value = value.replaceAll(",","\n");

                        return(
                            <Form.Group className="py-3 px-1" style={{width: '300px', resize: 'none'}}>
                                <Form.Control
                                    as={'textarea'}
                                    rows={8}
                                    value={value}
                                    style={{resize: 'none'}}
                                    readOnly
                                />
                            </Form.Group>
                        );
                        // if (typeof params.data.data === 'string') {
                        //     return params.data.data;
                        // }else{
                        //     var value = [];
                        //     var value2 = [];
                        //     var value3 = [];
                        //     var value4 = [];

                        //     Object.keys(params.data.data).forEach(key => {
                        //         if (key !== 'mainbuyerInfo') {
                        //             value.push({label: key, value: params.data.data[key]});
                        //         }else{
                        //             value2.push(params.data.data[key]);
                        //         }
                        //     });

                        //     if (value2.length > 0) {
                        //         Object.keys(value2[0]).forEach(key => {
                        //             if (key !== 'jointBuyerInfo') {
                        //                 value3.push({label: key, value: value2[0][key]});
                        //             }else{

                        //             }
                        //         });
                        //     }

                        //     return(
                        //         <div>
                        //             {value.map((rec, index) => (
                        //                 <div key={index} className="my-0">
                        //                     {rec.label}: {rec.value}
                        //                 </div>
                        //             ))}

                        //             {value3.length > 0 && <div>main buyer info</div>}

                        //             {value3.length > 0 && value3.map((rec, index) => (
                        //                 <div key={index} className="my-0">
                        //                     {rec.label}: {rec.value}
                        //                 </div>
                        //             ))}


                        //         </div>
                        //     );
                        // }
                    }else{
                        return "";
                    }
                }
            }
        },
        {
            headerName: 'Action',
            field: 'action',
            width: 180
        },
        {
            headerName: 'Ref ID',
            field: 'ext_ref_id',
            headerClass: 'center',
            cellClass: 'center',
            width: 100
        },
        {
            headerName: 'Response',
            field: 'response',
            width: 300
        }
    ]);

    const getGridData = () => {

        axios.get('ext/log/gl_lifestyle_log.php', {
            params: {
                date_start: startDate,
                date_end: endDate,
                third_party: thirdParty,
                parameter: searchVal
            }
        })
        .then(res => {
            let data = res.data;

            if (data) {
                setRecordFound(data.length);
                setRowData(data);
                setIsReport(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData(0);
                setIsReport(true);
                setIsLoading(false);
            }
        })
        .catch(error => {
            console.log(error);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
        }
    }, []);

    return(
        <div>
            {isLoading ? <Loader/> : 
                <div className="report-main">
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">Filter Criteria:</div>
                        </section>

                        <section className="content">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by data"
                                    onChange={(e) => {setSearchVal(e.target.value)}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => {setStartDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => {setEndDate(moment(e.target.value).format('YYYY-MM-DD'))}}
                                />
                            </Form.Group>
                            <Form.Group>
                                <FormSelect
                                    placeholder="All Third Party"
                                    options={thirdPartyList}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={(e) => {
                                        setThirdParty(e ? e.value : '');
                                    }}
                                />
                            </Form.Group>
                        </section>
                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={() => {
                                        getGridData();
                                    }}
                                >
                                    View Report
                                </button>
                            </div>
                        </section>
                    </div>
                    <div className="report-content">
                        <section className="w-100">
                            <Stack direction="horizontal">
                                {isReport && <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>}
                            </Stack>
                        </section>
                        <section className="w-100">
                            <div className="mb-3" style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        rowData={rowData}
                                        defaultColDef={reportColDef}
                                        rowHeight={80}
                                        pagination={false}
                                        // paginationPageSize={30}
                                        // paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </div>
    );
}

export default IncomingLogGL;