import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";

const MySwal = withReactContent(Swal);

const FactSheet = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [factSheetList, setFactSheetList] = useState([]);
    const [isAddConversation, setisAddConversation] = useState(false);
    const [businessUnit, setBusinessUnit] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [selectedOption, setSelectedOption] = useState('first');


    // Validation Schema
    const validationSchema = yup.object().shape({
        business_unit_id: yup.string().required("Business unit is required"),
    });

    // GET FUNCTION --------------------------------------------------------
    const getFactSheetList = () => {
        try {
            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, {
                    params: {
                        task: "getFactsheet",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    if (data.status === 0) {
                        setFactSheetList(data.record);
                        setLoading(false);
                    } else {
                        setFactSheetList([]);
                        setLoading(false);
                    }
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getBusinessUnitOption = async () => {
        try {
            const res = await axios.get("ws/ws_setting.php", {
                params: {
                    task: "getAllBU",
                    company: session.company_id
                }
            })

            const data = res.data;

            if (data.status === 0) {
                const buOptions = data.record.map(bu => ({
                    value: bu.business_unit_id,
                    label: bu.unit_title
                }));

                // buOptions.unshift({ label: "All Business Unit", value: "" });
                setBusinessUnit(buOptions);
            }
        } catch {

        }
    }

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: "No", field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: "Fact Sheet Text",
            field: "",
            width: 500,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.factsheet_text) {
                        return (
                            <Form.Group className="col-sm-12">
                                <Form.Control
                                    style={{ resize: "none", color: 'black', backgroundColor: '#ffff', border: "none" }}
                                    as="textarea"
                                    rows={6}
                                    disabled={true}
                                    value={params.data.factsheet_text}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        },
        {
            headerName: "Business Unit",
            field: "business_unit_id",
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.business_unit_id) {
                        const bu = businessUnit.find(item => item.value === params.data.business_unit_id)?.label || '';

                        return (
                            <div>
                                {bu}
                            </div>
                        );
                    }
                }
            }
        },
        { headerName: "Vector DB Index", field: "vector_db_index", width: 250 },
        { headerName: "Vector Namespace", field: "vector_namespace", width: 250 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            width: 250,
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        try {
            const formData = new FormData();

            formData.append("task", "updateFactsheet");
            formData.append("utoken", session.user_ac_token);
            formData.append("ctoken", session.company_token);
            formData.append("factsheet_id", values.factsheet_id);
            formData.append("factsheet_text", values.factsheet_text);
            formData.append("vector_db_index", values.vector_db_index);
            formData.append("vector_namespace", values.vector_namespace);

            const response = await axios.post(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, formData);
            const data = response.data;

            if (data.status == '0') {
                setShowModal(false);
                getFactSheetList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.factsheet_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, {
                params: {
                    task: "deleteFactsheet",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    factsheet_id: params.factsheet_id,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getFactSheetList();
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const formData = new FormData();

            formData.append("task", "setFactsheet");
            formData.append("utoken", session.user_ac_token);
            formData.append("ctoken", session.company_token);
            formData.append("factsheet_text", values.factsheet_text);
            formData.append("vector_db_index", values.vector_db_index);
            formData.append("vector_namespace", values.vector_namespace);
            formData.append("business_unit_id", values.business_unit_id);

            const response = await axios.post(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_blueprint2.php`, formData);
            const data = response.data;

            if (data.status == '0') {
                setisAddConversation(false);
                getFactSheetList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getFactSheetList();
            getBusinessUnitOption();
        }
    }, []);

    const toggleAddConversationModal = async () => {
        setisAddConversation(prevState => !prevState);
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddConversationModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> AI Fact Sheet
                    </button>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={gridColumn}
                                        defaultColDef={{ flex: 1 }}
                                        rowData={loading ? [] : factSheetList}
                                        rowHeight={150}
                                        pagination={true}
                                        onCellClicked={onCellClicked}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddConversation} onHide={toggleAddConversationModal} size="lg">
                        <Formik
                            initialValues={{
                                factsheet_text: "",
                                vector_db_index: "",
                                vector_namespace: "",
                                business_unit_id: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Fact Sheet</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form.Group className="mb-3">
                                            <Form.Label>Select Edit Type</Form.Label>
                                            <Form.Group className="d-flex">
                                                <Form.Check
                                                    className="me-3"
                                                    type="radio"
                                                    label="Edit Fact Sheet Text"
                                                    name="radioOption"
                                                    value="first"
                                                    checked={selectedOption === 'first'}
                                                    onChange={() => setSelectedOption('first')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Edit Vector"
                                                    name="radioOption"
                                                    value="second"
                                                    checked={selectedOption === 'second'}
                                                    onChange={() => setSelectedOption('second')}
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Fact Sheet Text</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="factsheet_text"
                                                onChange={handleChange}
                                                disabled={selectedOption === 'second'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector DB Index</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_db_index"
                                                onChange={handleChange}
                                                disabled={selectedOption === 'first'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector Namespace</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_namespace"
                                                onChange={handleChange}
                                                disabled={selectedOption === 'first'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>
                                                Select Business Unit
                                            </Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                name="business_unit_id"
                                                placeholder="Select an option"
                                                options={businessUnit}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('business_unit_id', selectedOption ? selectedOption.value : '');
                                                }}
                                                isSearchable={true}
                                                isInvalid={errors.business_unit_id && touched.business_unit_id}
                                            />
                                            {errors.business_unit_id && touched.business_unit_id && (
                                                <div className="op-error-message">{errors.business_unit_id}</div>
                                            )}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                        <Formik
                            initialValues={{
                                business_unit_id: currentData ? currentData.business_unit_id : "",
                                factsheet_text: currentData ? currentData.factsheet_text : "",
                                vector_db_index: currentData ? currentData.vector_db_index : "",
                                vector_namespace: currentData ? currentData.vector_namespace : "",
                                factsheet_id: currentData ? currentData.factsheet_id : "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Edit</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Select Edit Type</Form.Label>
                                            <Form.Group className="d-flex">
                                                <Form.Check
                                                    className="me-3"
                                                    type="radio"
                                                    label="Edit Fact Sheet Text"
                                                    name="radioOption"
                                                    value="first"
                                                    checked={selectedOption === 'first'}
                                                    onChange={() => setSelectedOption('first')}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="Edit Vector"
                                                    name="radioOption"
                                                    value="second"
                                                    checked={selectedOption === 'second'}
                                                    onChange={() => setSelectedOption('second')}
                                                />
                                            </Form.Group>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Fact Sheet Text</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="factsheet_text"
                                                value={values.factsheet_text}
                                                onChange={handleChange}
                                                disabled={selectedOption === 'second'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector DB Index</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_db_index"
                                                value={values.vector_db_index}
                                                onChange={handleChange}
                                                disabled={selectedOption === 'first'}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Vector Namespace</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="vector_namespace"
                                                value={values.vector_namespace}
                                                onChange={handleChange}
                                                disabled={selectedOption === 'first'}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                </div>
            )}
        </Container>
    );
}

export default FactSheet;
