import { useState, useRef, useEffect, useMemo } from "react";
import { Form, Modal } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import FormSelect from "../../includes/FormSelect";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
// import ReactHtmlParser from "html-react-parser";

const EditReserve = ({ isOpen, onSuccess, dataSet }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;

    const [openModal, setOpenModal] = useState(false);
    const [editInitVal, setEditInitVal] = useState({});
    const [idTypeList, setIdTypeList] = useState([]);

    const getAllData = () => {
        var idTypeList = "";
        var idType = "";
        var idNumber = "";

        dataSet.custom_field_person_data.forEach((rec) => {
            if (Number(rec.cf_id) === 5001) {
                idTypeList = rec.cf_value;
                idType = rec.fd_value;
            }

            if (Number(rec.cf_id) === 5002) {
                idNumber = rec.fd_value;
            }
        });

        var optList = JSON.parse(idTypeList);
        var optListNew = Object.values(optList).map((rec) => {
            return {label: rec, value: rec};
        });

        var initVal = {
            name: dataSet.prospect_name,
            idType: idType,
            idNumOri: idNumber,
            idNum: idNumber,
            phoneNumPref: dataSet.contact1_prefix,
            phoneNum: dataSet.contact1,
            email: dataSet.email1
        };

        setIdTypeList(optListNew);
        setEditInitVal(initVal);
        setOpenModal(true);
    }

    useEffect(() => {
        if (!init.current) {
            getAllData();
        }
    }, []);

    const editSchema = yup.object().shape({
        name: yup.string().required('This field is required'),
        idType: yup.string().required('This field is required'),
        idNum: yup.string().required('This field is required').when('idType', ([idType], schema) => {
            if (idType === 'NRIC No.')
                return yup.number('Number only').typeError('Must be a number type').required('This field is required');
            return schema;
        }),
        phoneNum: yup.string().required('This field is required')
    });

    const sendEdit = (values) => {
        if (values.idNum === values.idNumOri) {
            handleUpdateProsName(values);
        }else{
            axios.get('ext/gamuda/api_check_prospect.php',{
                params:{
                    prospect_id: dataSet.hq_prospect_id && Number(dataSet.hq_prospect_id) !== 0 ? dataSet.hq_prospect_id : dataSet.prospect_id,
                    ic_no: values.idNum,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (Number(data.status) === 0) {
                    handleUpdateProsName(values);
                }else{
                    Swal.fire({
                        icon: "error",
                        title: "Failed",
                        text: data.message
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    const handleUpdateProsName = (values) => {
        axios.get('ws/ws_edit_prospect_details.php',{
            params:{
                task: "updateProfile",
                prospect_id: dataSet.prospect_id,
                hq_prospect_id: dataSet.hq_prospect_id,
                prospect_name: values.name,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                handleUpdateCF(values);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    const handleUpdateCF = (values) => {
        var prospectID = dataSet.hq_prospect_id && Number(dataSet.hq_prospect_id) !== 0 ? dataSet.hq_prospect_id : dataSet.prospect_id;

        var cf_id = [];
        var cf_label = [];
        var fd_value = [];
        var record = [];
        dataSet.custom_field_person_data.forEach((rec) => {
            if (Number(rec.cf_id) === 5001) {
                rec.fd_value = values.idType;
            }

            if (Number(rec.cf_id) === 5002) {
                rec.fd_value = values.idNum;
            }

            cf_id.push(rec.cf_id);
            cf_id.push(rec.cf_label);
            fd_value.push(rec.fd_value);
            record.push(prospectID);
        });

        axios.get('ws/ws_custom_field.php',{
            params:{
                task: "updateOrAddCF",
                record: record.toString(),
                audithistory_prospectID: dataSet.hq_prospect_id && Number(dataSet.hq_prospect_id) !== 0 ? dataSet.hq_prospect_id : dataSet.prospect_id,
                hq_prospect_id: dataSet.hq_prospect_id,
                cf_label: cf_label.toString(),
                cf: cf_id.toString(),
                value: fd_value.toString(),
                referrer: "reserve",
                user_id: session.user_id,
                company_id: session.company_id,
                secret_key: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                onSuccess();
                isOpen();
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        })
        .catch(error => {
            console.log(error);
        })
    }



    return(
        <Modal show={openModal} onHide={isOpen}>
            <Formik
                validationSchema={editSchema}
                initialValues={editInitVal}
                onSubmit={sendEdit}
            >
                {({ handleSubmit, setFieldValue, errors, touched, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title as={'h6'}>Reserve</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-3">
                                <Form.Label>Name *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.name}
                                    isInvalid={errors.name && touched.name}
                                    onChange={(e) => {setFieldValue('name', e.target.value)}}
                                />
                                {errors.name && touched.name && <div className="op-error-message">{errors.name}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Identification Type *</Form.Label>
                                <FormSelect
                                    options={idTypeList}
                                    valueDefault={idTypeList.filter(opt => opt.value === values.idType)}
                                    isClearable={true}
                                    isInvalid={errors.idType && touched.idType}
                                    onChange={(e) => {
                                        setFieldValue('idType', e ? e.value : '')
                                    }}
                                />
                                {errors.idType && touched.idType && <div className="op-error-message">{errors.idType}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Identification Number *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.idNum}
                                    isInvalid={errors.idNum && touched.idNum}
                                    onChange={(e) => {setFieldValue('idNum', e.target.value)}}
                                />
                                {errors.idNum && touched.idNum && <div className="op-error-message">{errors.idNum}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Phone Number *</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.phoneNum}
                                    isInvalid={errors.phoneNum && touched.phoneNum}
                                    onChange={(e) => {setFieldValue('phoneNum', e.target.value)}}
                                />
                                {errors.phoneNum && touched.phoneNum && <div className="op-error-message">{errors.phoneNum}</div>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={values.email}
                                    onChange={(e) => {setFieldValue('email', e.target.value)}}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn op-button op-primary-color text-light">Reserve</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default EditReserve;