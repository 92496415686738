import React, { useState, memo, useCallback } from "react";
import { Button, Nav } from "react-bootstrap";
import { faBell, faDollarCircle, faCalendarAlt, faHouseCircleExclamation, faObjectsColumn, faCircleEllipsis, faPerson } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
const Notification = ({ notiInfo, getNotification }) => {
  const { unread_count, record: noti_record, total_count } = notiInfo;
  const navigate = useNavigate();
  const [isMenu, setIsMenu] = useState(false);
  const [notiFilter, setNotiFilter] = useState("all");
  const { session } = useAuth();

  const getMarkAsRead = useCallback(async () => {
    try {
      await axios("ws/ws_system.php", {
        params: {
          task: 14,
          user: session.user_id,
          type: session.role_id,
        },
      });
      getNotification();
    } catch (error) {
      console.error(error);
    }
  }, [session]);

  const markAsRead = async (notification) => {
    try {
      if (notification.noti_status === "unread") {
        await axios("ws/ws_system.php", {
          params: {
            task: 6,
            notification: notification.notification_id,
          },
        });

        if (Number(notification.notification_type_id) !== 0 && notification.notification_type_id != null) {
          if (Number(notification.action_id) === 9) {
            navigate("/report-duplicate-lead");
          } else if (Number(notification.action_id) === 2 || Number(notification.action_id) === 7) {
            if (Number(notification.deal_id) > 0) {
              navigate(`/deal/${notification.deal_id}`);
            } else {
              navigate(`/activity/list`);
            }
          } else if (notification.notification_type === "booking") {
            navigate(`booking-details/${notification.notification_type_id}/${notification.extra_id1}`);
          } else {
            navigate(`/deal/${notification.notification_type_id}`);
          }
        } else {
          navigate("/report-duplicate-lead");
        }
      } else {
        if (Number(notification.notification_type_id) !== 0 && notification.notification_type_id != null) {
          if (Number(notification.action_id) === 9) {
            navigate("/report-duplicate-lead");
          } else if (Number(notification.action_id) === 2 || Number(notification.action_id) === 7) {
            if (Number(notification.deal_id) > 0) {
              navigate(`/deal/${notification.deal_id}`);
            } else {
              navigate(`/activity/list`);
            }
          } else if (notification.notification_type === "booking") {
            if (Number(notification.notification_type_id) > 0) {
              navigate(`booking-details/${notification.notification_type_id}/${notification.extra_id1}`);
            }
          } else {
            navigate(`/deal/${notification.notification_type_id}`);
          }
        } else {
          navigate("/report-duplicate-lead");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const RenderNotification = useCallback(() => {
    var notiList = noti_record;
    if (notiFilter !== "all") {
      notiList = noti_record.filter((record) => record.notification_type === notiFilter);
    }

    return notiList.map((record, index) => (
      <div key={index} className="op-root-header__notification-right__body-item" style={{ backgroundColor: record.noti_status === "unread" ? "#d9edf7" : "#fff" }} onClick={() => markAsRead(record)}>
        <div className="d-flex justify-content-center align-items-center" style={{ width: 30 }}>
          <RenderNotificationIcon type={record.notification_type} />
        </div>

        <div className="ms-3 w-100 position-relative" style={{ textWrap: "wrap" }}>
          {record.notification_title && <h6 className="op-text-medium m-0 mb-1 text-dark fw-bold">{record.notification_title}</h6>}
          <h6 className="op-text-medium m-0 mb-1 text-dark">{record.notification_message}</h6>
          <p className="text-muted m-0 text-dark" style={{ fontSize: 10 }}>
            {record.time_tags}
          </p>
        </div>
      </div>
    ));
  }, [noti_record, notiFilter]);

  const RenderNotificationIcon = useCallback(({ type }) => {
    if (type === "deal") {
      return <FontAwesomeIcon className="text-dark" icon={faDollarCircle} size="xl" />;
    } else if (type === "prospect") {
      return <FontAwesomeIcon className="text-dark" icon={faPerson} size="xl" />;
    } else if (type === "activity") {
      return <FontAwesomeIcon className="text-dark" icon={faCalendarAlt} size="xl" />;
    } else if (type === "booking") {
      return <FontAwesomeIcon className="text-dark" icon={faHouseCircleExclamation} size="xl" />;
    }

    return <FontAwesomeIcon className="text-dark" icon={faBell} size="xl" />;
  }, []);

  const setShowUnread = useCallback(
    (mode) => {
      if (noti_record && noti_record.length) {
        const records = noti_record.filter((record) => record.notification_type === mode && record.noti_status === "unread");
        if (records.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [noti_record]
  );

  const setTotalUnread = useCallback(
    (mode) => {
      if (noti_record && noti_record.length) {
        const records = noti_record.filter((record) => record.notification_type === mode && record.noti_status === "unread");
        if (records.length > 0) {
          return records.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    [noti_record]
  );

  return (
    <>
      <Button variant="link" className="position-relative" onClick={setIsMenu} aria-expanded={isMenu}>
        {unread_count > 0 && (
          <div style={{ position: "absolute", top: -2, right: 5 }}>
            <span className="badge text-bg-primary rounded">{unread_count}</span>
          </div>
        )}
        <FontAwesomeIcon icon={faBell} size="xl" style={{ color: "#000" }} />
      </Button>

      {isMenu && (
        <>
          <div className="op-root-header__notification">
            <div className="d-flex">
              <div className="d-flex flex-column flex-shrink-0 p-2 op-root-header__notification-left">
                <Nav className="nav-pills flex-column mb-auto" activeKey={notiFilter} onSelect={(key) => setNotiFilter(key)}>
                  <Nav.Link className="d-flex align-items-center justify-content-between" eventKey="all">
                    <div className="d-flex align-items-center">
                      <div className="text-light me-2 d-flex justify-content-center aling-items-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={faObjectsColumn} size="lg" />
                      </div>
                      <div className="text-light">All Notification</div>
                    </div>
                    {unread_count > 0 && <span className="badge text-bg-primary rounded-pills">{unread_count}</span>}
                  </Nav.Link>
                  <Nav.Link className="d-flex align-items-center justify-content-between" eventKey="deal">
                    <div className="d-flex align-items-center">
                      <div className="text-light me-2 d-flex justify-content-center aling-items-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={faDollarCircle} size="lg" />
                      </div>
                      <div className="text-light">Deals</div>
                    </div>
                    {setShowUnread("deal") && <span className="badge text-bg-primary rounded-pills float-right">{setTotalUnread("deal")}</span>}
                  </Nav.Link>
                  <Nav.Link className="d-flex align-items-center justify-content-between" eventKey="activity">
                    <div className="div d-flex align-items-center">
                      <div className="text-light me-2 d-flex justify-content-center aling-items-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={faCalendarAlt} size="lg" />
                      </div>
                      <div className="text-light">Activity</div>
                    </div>
                    {setShowUnread("activity") && <span className="badge text-bg-primary rounded-pills float-right">{setTotalUnread("activity")}</span>}
                  </Nav.Link>
                  {Number(session.booking_module) === 1 && (
                    <Nav.Link className="d-flex align-items-center justify-content-between" eventKey="booking">
                      <div className="d-flex align-items-center">
                        <div className="text-light me-2 d-flex justify-content-center aling-items-center" style={{ width: 25 }}>
                          <FontAwesomeIcon icon={faHouseCircleExclamation} size="lg" />
                        </div>
                        <div className="text-light">Booking</div>
                      </div>
                      {setShowUnread("booking") && <span className="badge text-bg-primary rounded-pills float-right">{setTotalUnread("booking")}</span>}
                    </Nav.Link>
                  )}

                  <Nav.Link className="d-flex align-items-center justify-content-between" eventKey="">
                    <div className="d-flex align-items-center">
                      <div className="text-light me-2 d-flex justify-content-center aling-items-center" style={{ width: 25 }}>
                        <FontAwesomeIcon icon={faCircleEllipsis} size="lg" />
                      </div>
                      <div className="text-light">Others</div>
                    </div>
                    {setShowUnread("") && <span className="badge text-bg-primary rounded-pills float-right">{setTotalUnread("")}</span>}
                  </Nav.Link>
                </Nav>
              </div>
              <div className="op-root-header__notification-right">
                <div className="op-root-header__notification-right__header">All Result</div>
                <div className="op-root-header__notification-right__body">{total_count > 0 && <RenderNotification />}</div>
                <div className="op-root-header__notification-right__footer" onClick={getMarkAsRead}>
                  Mark all as read
                </div>
              </div>
            </div>
          </div>
          <div className="op-root-header__notification-backdrop" onClick={() => setIsMenu(false)} />
        </>
      )}
    </>
  );
};

export default memo(Notification);
