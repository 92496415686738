import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Container, Row, Col, Stack, Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FormSelect } from "../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { useTranslation } from "react-i18next";
import iziToast from "izitoast";
import moment from "moment";

const CurationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { session } = useAuth();
  const { Formik } = formik;
  const { curation_id } = useParams();
  const curationInit = useRef(false);
  const init = useRef(false);
  const [curation, setCuration] = useState([]);
  const [isAddCuration, setIsAddCuration] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditCuration, setIsEditCuration] = useState(false);
  const [editCurationInitial, setEditCurationInitial] = useState({ curation_id: "", curation_title: "", curation_source: "" });
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const curationSources = [
    { label: "Deal Report", value: "deal_report" },
    { label: "Contact Report", value: "contact_report" },
    Number(session.company_id) === 251 && { label: "Segmentation", value: "segmentation" },
    Number(session.company_id) === 251 && { label: "GL Play", value: "deal_report" },
    { label: "Others", value: "none" },
  ].filter(Boolean);

  const addCurationSchema = yup.object().shape({
    curation_title: yup.string().required(t("Curation_error_message")),
    curation_source: yup.string().required(t("Curation_error_message")),
  });

  const editCurationSchema = yup.object().shape({
    curation_title: yup.string().required(t("Curation_error_message")),
    curation_source: yup.string().required(t("Curation_error_message")),
  });

  // GET FUNCTION -------------------------------------------------------------------------

  const getCurationList = useCallback(
    async (curationId) => {
      try {
        const response = await axios.get("ws/ws_curation.php", {
          params: {
            task: "listCuration",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          var curationInfo = {};

          var curationList = data.record.map((record) => ({
            label: record.curation_title,
            value: record.curation_id,
          }));

          if (curationId && curationId > 0) {
            curationInfo = curationList.find((record) => Number(record.value) === Number(curationId));
          } else {
            curationInfo = curationList[0];
          }

          setCuration(data.record);
          curationInit.current = true;
        } else {
          setCuration([]);
          curationInit.current = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    [session, curationInit]
  );

  // GRID SETUP ---------------------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => params.rowIndex + 1,
    },
    {
      headerName: "Curation Name",
      field: "curation_title",
      filter: true,
      onCellClicked: (params) => navigate(`/curation/${params.data.curation_id}`),
    },
    {
      headerName: "Source",
      field: "curation_source",
      filter: true,
      maxWidth: 200,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => (params.data.curation_source === "segmentation" ? "Segmentation" : params.data.curation_source === "gl_play" ? "GL Play" : params.data.curation_source === "deal_report" ? "Deal Report" : params.data.curation_source === "contact_report" ? "Contact Report" : "Others"),
    },
    {
      headerName: "Dynamic List (Only available for segmentation)",
      field: "dynamic_list",
      filter: false,
      sortable: false,
      maxWidth: 150,
      cellClass: "center",
      headerClass: "center",
      hide: Number(session.company_id) !== 251,
      cellRenderer: (params) => {
        if (params.data.curation_source === "segmentation") {
          return <Form.Check type="switch" label="" className="d-flex justify-content-center align-items-center" checked={Number(params.data.dynamic_list) === 1} onChange={() => sendDynamicCuration("dynamic_list", params.rowIndex, params.data)} />;
        } else {
          return null;
        }
      },
    },
    {
      headerName: "Last Dynamic Update",
      field: "dynamic_triggered_date_time",
      filter: false,
      sortable: false,
      maxWidth: 200,
      cellClass: "center",
      headerClass: "center",
      hide: Number(session.company_id) !== 251,
      cellRenderer: (params) => {
        if (params.data.curation_source === "segmentation" && Number(params.data.dynamic_list) === 1) {
          if (params.data.dynamic_triggered_date_time === "0000-00-00 00:00:00") {
            return "No update yet";
          } else {
            return moment(new Date(params.data.dynamic_triggered_date_time)).format("LLL");
          }
        } else {
          return "";
        }
      },
    },
    {
      headerName: "",
      field: "",
      filter: false,
      sortable: false,
      headerClass: "center",
      cellClass: "center",
      pinned: "right",
      maxWidth: 150,
      cellRenderer: function (params) {
        return (
          <div className="d-flex">
            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>}>
              <FontAwesomeIcon className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} onClick={() => handleTrashClick(params.data)} />
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
              <FontAwesomeIcon className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} onClick={() => handleEditClick(params.data)} />
            </OverlayTrigger>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.curation_id.toString();
  }, []);

  // HANDLE GRID CLICK -----------------------------------------------

  const handleEditClick = useCallback((params) => {
    setEditCurationInitial({ curation_id: params.curation_id, curation_title: params.curation_title, curation_source: params.curation_source ? params.curation_source : "none" });
    setIsEditCuration(true);
  }, []);

  const handleTrashClick = (params) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        sendDelete(params);
      }
    });
  };

  // ADD FUNCTION --------------------------------------------------------

  const sendNewCuration = useCallback(
    async (values) => {
      try {
        const response = await axios.get("ws/ws_curation.php", {
          params: {
            task: "addCurationV2",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            curation_title: values.curation_title,
            curation_source: values.curation_source,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          setCuration((prevState) => [
            ...prevState,
            {
              curation_id: data.record,
              date_time_create_curation: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
              curation_title: values.curation_title,
              user_id: session.user_id,
              company_id: session.company_id,
              dynamic_list: "0",
              curation_source: values.curation_source,
              dynamic_triggered_date_time: "0000-00-00 00:00:00",
            },
          ]);
          iziToast.success({
            title: t("Curation_successful"),
            message: t("Setting_Alert_Success_Text_Add"),
            timeout: 2000,
          });
        } else {
          iziToast.error({
            title: t("Setting_Alert_Failed_Title"),
            message: t("Setting_Alert_Failed_Text"),
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsAddCuration(false);
      }
    },
    [session]
  );

  const sendDynamicCuration = useCallback(
    async (column, index, values) => {
      const recordValue = Number(values[column]) === 1 ? 0 : 1;
      const recordUpdate = [...curation];

      try {
        const response = await axios.get("ws/ws_curation.php", {
          params: {
            task: "UpdateDynamicCuration",
            curation_id: values.curation_id,
            dynamic_list: recordValue,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          recordUpdate[index][column] = recordValue;
          setCuration(recordUpdate);
          iziToast.success({
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          recordUpdate[index][column] = Number(values[column]);
          setCuration(recordUpdate);
          iziToast.warning({
            title: "Caution",
            message: data.message,
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [session, curation]
  );

  const sendDelete = useCallback(
    async (params) => {
      try {
        const response = await axios.get("ws/ws_curation.php", {
          params: {
            task: "DeleteCurationV2",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            curation_id: params.curation_id,
          },
        });

        const data = response.data;
        if (Number(data.status) === 0) {
          setCuration((prevCuration) => prevCuration.filter((item) => item.curation_id !== params.curation_id));
          iziToast.success({
            title: t("Setting_Alert_Success_Title"),
            message: t("Setting_Alert_Success_Text_Delete"),
            timer: 2000,
          });
        } else if (Number(data.status) === 409) {
          Swal.fire({
            title: "Are you sure?",
            text: "There are prospects connected to this curation. Are you sure you want to remove all of them? This action is irreversible!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "rgba(8, 14, 44, 1)",
            cancelButtonColor: "#d33",
            confirmButtonText: "Force Delete",
          }).then((result) => {
            if (result.isConfirmed) {
              sendForceDelete(params);
            }
          });
        } else {
          iziToast.error({
            title: t("Setting_Alert_Failed_Title"),
            message: t("Setting_Alert_Failed_Text"),
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [session]
  );

  const sendForceDelete = useCallback(
    async (params) => {
      try {
        if (params.curation_source === "segmentation" && Number(session.company_id) === 251) {
          setLoadingUpdate(true);
          const formData = new FormData();
          formData.append("task", "ForceDeleteCurationMTARGET");
          formData.append("utoken", session.user_ac_token);
          formData.append("ctoken", session.company_token);
          formData.append("curation_id", params.curation_id);

          const response = await axios.post("ws/ws_curation_segmentation.php", formData);
          const data = response.data;
          if (Number(data.status) === 0) {
            setCuration((prevCuration) => prevCuration.filter((item) => item.curation_id !== params.curation_id));
            iziToast.success({
              title: t("Setting_Alert_Success_Title"),
              message: t("Setting_Alert_Success_Text_Delete"),
              timer: 2000,
            });
          } else {
            iziToast.error({
              title: t("Setting_Alert_Failed_Title"),
              message: data.message,
              timeout: 2000,
            });
          }
        } else {
          const response = await axios.get("ws/ws_curation.php", {
            params: {
              task: "ForceDeleteCuration",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              curation_id: params.curation_id,
            },
          });

          const data = response.data;
          if (Number(data.status) === 0) {
            setCuration((prevCuration) => prevCuration.filter((item) => item.curation_id !== params.curation_id));
            iziToast.success({
              title: t("Setting_Alert_Success_Title"),
              message: t("Setting_Alert_Success_Text_Delete"),
              timer: 2000,
            });
          } else {
            iziToast.error({
              title: t("Setting_Alert_Failed_Title"),
              message: t("Setting_Alert_Failed_Text"),
              timeout: 2000,
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingUpdate(false);
      }
    },
    [session]
  );

  const sendEditCuration = useCallback(
    async (values) => {
      try {
        const response = await axios.get("ws/ws_curation.php", {
          params: {
            task: "editCurationV2",
            utoken: session.user_ac_token,
            ctoken: session.company_token,
            curation_title: values.curation_title,
            curation_id: values.curation_id,
            curation_source: values.curation_source,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          const index = curation.findIndex((record) => Number(record.curation_id) === Number(values.curation_id));
          const recordUpdate = [...curation];
          recordUpdate[index].curation_title = values.curation_title;
          recordUpdate[index].curation_source = values.curation_source;
          recordUpdate[index].dynamic_list = values.dynamic_list;
          setCuration(recordUpdate);
          iziToast.success({
            title: t("Setting_Alert_Success_Title"),
            message: t("Setting_Alert_Success_Text_Edit"),
            timeout: 2000,
          });
        } else {
          iziToast.error({
            title: t("Setting_Alert_Failed_Title"),
            message: t("Setting_Alert_Failed_Text"),
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsEditCuration(false);
      }
    },
    [session, curation]
  );

  // USEEFFECT FUNCTION -------------------------------------------------------------------

  useEffect(() => {
    if (!init.current) {
      getCurationList(curation_id);
      init.current = true;

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [session]);

  return (
    <Container fluid className="px-4 py-4">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <Col xxl={12}>
              <Stack direction="horizontal" gap={2}>
                <Button variant="" className="btn op-button op-primary-color text-light" onClick={setIsAddCuration}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("Curation")}
                </Button>
              </Stack>
            </Col>
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact columnDefs={gridColumn} getRowId={gridRowId} defaultColDef={{ flex: 1 }} rowData={curation} rowHeight={60} pagination={true} />
                </div>
              </div>
            </Col>
          </Row>

          <Modal show={isAddCuration} onHide={setIsAddCuration}>
            <Formik validationSchema={addCurationSchema} onSubmit={sendNewCuration} initialValues={{ curation_title: "", curation_source: "" }}>
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("Curation_add_curation")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Curation_curation_title")}</Form.Label>
                      <Form.Control type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("curation_title")} />
                      {errors.curation_title && touched.curation_title && <div className="op-error-message">{errors.curation_title}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Curation_curation_source")}</Form.Label>
                      {Number(session.company_id) === 251 && (
                        <p className="mb-2 text-muted" style={{ fontSize: 10 }}>
                          Please select 'Segmentation' if you would like this list to be pushed to MTARGET for EDM purposes.
                        </p>
                      )}
                      <FormSelect options={curationSources} onChange={(e) => handleChange("curation_source")(e.value)} />
                      {errors.curation_source && touched.curation_source && <div className="op-error-message">{errors.curation_source}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light shadow">
                      {t("Curation_submit")}
                    </button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>

          <Modal show={isEditCuration} onHide={setIsEditCuration} size="md">
            <Formik initialValues={editCurationInitial} validationSchema={editCurationSchema} onSubmit={sendEditCuration} enableReinitialize>
              {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t("Curation_edit_curation")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Curation_add_curation")}</Form.Label>
                      <Form.Control type="text" name="curation_title" value={values.curation_title} onChange={handleChange} isInvalid={touched.curation_title && !!errors.curation_title} />
                      <Form.Control.Feedback type="invalid">{errors.curation_title}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Curation_curation_source")}</Form.Label>
                      <FormSelect options={curationSources} valueDefault={curationSources.find((record) => record.value === values.curation_source)} onChange={(e) => handleChange("curation_source")(e.value)} />
                      {errors.curation_source && touched.curation_source && <div className="op-error-message">{errors.curation_source}</div>}
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="success" type="submit">
                      Update
                    </Button>
                  </Modal.Footer>
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
      )}

      {loadingUpdate && (
        <div style={{ position: "absolute", top: 0, left: 56, width: "100%", height: "100%" }}>
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default CurationList;
