import { useState, useRef, useEffect } from "react";
import { Modal, Stack, ListGroup } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faRightLeftLarge } from "@fortawesome/pro-light-svg-icons";

const SwitchAccount = ({ closeModal }) => {
  const init = useRef(false);
  const { session, switchAccount } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [accountData, setAccountData] = useState([]);

  const getAllAccount = () => {
    axios
      .get("ws/ws_setting.php", {
        params: {
          task: "getAllAccounts",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          setAccountData(data.record);
          setIsLoading(false);
          setOpen(true);
        }
      });
  };

  useEffect(() => {
    if (!init.current) {
      init.current = true;
      getAllAccount();
    }
  }, []);

  return (
    <>
      <Modal show={open} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title as={"h6"}>Switch Account</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {isLoading ? (
            <Loader />
          ) : (
            <div style={{ overflow: "auto", height: "50vh", width: "100%" }} className="my-4 px-4">
              <ListGroup>
                {accountData.map((rec, index) => (
                  <ListGroup.Item key={index} action onClick={() => switchAccount(rec.company_id)}>
                    <Stack direction="horizontal" gap={3} className="d-flex justify-content-center align-items-center">
                      <div>
                        <FontAwesomeIcon icon={faBuilding} size="lg" />
                      </div>
                      <div className="text-center">{rec.company_title}</div>
                      <div className="ms-auto">
                        <FontAwesomeIcon icon={faRightLeftLarge} size="lg" />
                      </div>
                    </Stack>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SwitchAccount;
