import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Offcanvas, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import Swal from "sweetalert2/dist/sweetalert2";
import { immediateToast } from "izitoast-react";

const BookingModules = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState({ rea_stages_id: "", company_id: session.company_id, reserved: "1", booked: "1", spa_signed: "1", spa_stamped: "1", spa_sold: "1" });
  const [modules, setModules] = useState({
    rea_modules_id: "",
    is_calc_bumi: "1",
    is_calc_booking_fee: "1",
    is_ql_saleschart_salesperson: "1",
    is_ql_saleschart_customer: "1",
    is_ql_virtual_tour_salesperson: "1",
    is_ql_virtual_tour_customer: "1",
    is_ql_unit_salesperson: "1",
    is_ql_unit_customer: "1",
    is_ql_floor_plan_salesperson: "1",
    is_ql_floor_plan_customer: "1",
    is_ql_files_customer: "1",
    is_ql_files_salesperson: "1",
    is_ql_contacts_salesperson: "1",
    is_ql_contacts_customer: "1",
    is_site_plan_salesperson: "1",
    is_site_plan_customer: "1",
    is_location_salesperson: "1",
    is_location_customer: "1",
    is_unit_summary_salesperson: "1",
    is_unit_summary_customer: "1",
    is_spa_info: "1",
    is_solicitor_info: "1",
    is_financier_info: "1",
    is_document: "1",
    is_unit_available: "1",
    is_builtup_area: "1",
    is_land_area: "1",
    is_price: "1",
    is_floor_plan: "1",
    is_bedroom: "1",
    is_bathroom: "1",
    is_reservation_fee: "1",
  });

  // USEEFFECT FUNCTION ----------------------

  const getData = useCallback(async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetModules",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setModules(data.record);
        setStages(data.stages);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const onchangeData = useCallback(
    async (column) => {
      const recordUpdate = { ...modules };
      recordUpdate[column] = Number(modules[column]) === 1 ? 0 : 1;
      setModules(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateModules",
            column: column,
            value: Number(modules[column]) === 1 ? 0 : 1,
            rea_modules_id: modules.rea_modules_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [modules, session]
  );

  const onchangeData2 = useCallback(
    async (column) => {
      const recordUpdate = { ...stages };
      recordUpdate[column] = Number(stages[column]) === 1 ? 0 : 1;
      setStages(recordUpdate);

      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "UpdateStages",
            column: column,
            value: Number(stages[column]) === 1 ? 0 : 1,
            rea_stages_id: stages.rea_stages_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          immediateToast("success", {
            title: "Success",
            message: "Successfully updated",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed to updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [stages, session]
  );

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getData();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <section className="mb-4">
            <h6>Booking Details Stages</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Reserved
                <Form.Check type="switch" checked={Number(stages.reserved) === 1} onChange={() => onchangeData2("reserved")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Booking Confirm
                <Form.Check type="switch" checked={Number(stages.booked) === 1} onChange={() => onchangeData2("booked")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                SPA Signed
                <Form.Check type="switch" checked={Number(stages.spa_signed) === 1} onChange={() => onchangeData2("spa_signed")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                SPA Stamped
                <Form.Check type="switch" checked={Number(stages.spa_stamped) === 1} onChange={() => onchangeData2("spa_stamped")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                SPA Sold
                <Form.Check type="switch" checked={Number(stages.spa_sold) === 1} disabled />
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-4">
            <h6>Booking Form</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Calculate Bumi Discount
                <Form.Check type="switch" checked={Number(modules.is_calc_bumi) === 1} onChange={() => onchangeData("is_calc_bumi")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Calculate Booking Fee
                <Form.Check type="switch" checked={Number(modules.is_calc_booking_fee) === 1} onChange={() => onchangeData("is_calc_booking_fee")} />
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-4">
            <h6>Unit Summary - Phase Details</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                <span>Unit Available</span>
                <Form.Check type="switch" checked={Number(modules.is_unit_available) === 1} onChange={() => onchangeData("is_unit_available")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Bedroom
                <Form.Check type="switch" checked={Number(modules.is_bedroom) === 1} onChange={() => onchangeData("is_bedroom")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Bathroom
                <Form.Check type="switch" checked={Number(modules.is_bathroom) === 1} onChange={() => onchangeData("is_bathroom")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Built-Up Area
                <Form.Check type="switch" checked={Number(modules.is_builtup_area) === 1} onChange={() => onchangeData("is_builtup_area")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Land Area
                <Form.Check type="switch" checked={Number(modules.is_land_area) === 1} onChange={() => onchangeData("is_land_area")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Price
                <Form.Check type="switch" checked={Number(modules.is_price) === 1} onChange={() => onchangeData("is_price")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Floor Plan
                <Form.Check type="switch" checked={Number(modules.is_floor_plan) === 1} onChange={() => onchangeData("is_floor_plan")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Reservation Fee
                <Form.Check type="switch" checked={Number(modules.is_reservation_fee) === 1} onChange={() => onchangeData("is_reservation_fee")} />
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-4">
            <h6>Phase Details - Internal</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Sales Chart
                <Form.Check type="switch" checked={Number(modules.is_ql_saleschart_salesperson) === 1} onChange={() => onchangeData("is_ql_saleschart_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Virtual Tour
                <Form.Check type="switch" checked={Number(modules.is_ql_virtual_tour_salesperson) === 1} onChange={() => onchangeData("is_ql_virtual_tour_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Units
                <Form.Check type="switch" checked={Number(modules.is_ql_unit_salesperson) === 1} onChange={() => onchangeData("is_ql_unit_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Floor Plan
                <Form.Check type="switch" checked={Number(modules.is_ql_floor_plan_salesperson) === 1} onChange={() => onchangeData("is_ql_floor_plan_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Files
                <Form.Check type="switch" checked={Number(modules.is_ql_files_salesperson) === 1} onChange={() => onchangeData("is_ql_files_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Contacts
                <Form.Check type="switch" checked={Number(modules.is_ql_contacts_salesperson) === 1} onChange={() => onchangeData("is_ql_contacts_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Site Plan
                <Form.Check type="switch" checked={Number(modules.is_site_plan_salesperson) === 1} onChange={() => onchangeData("is_site_plan_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Location
                <Form.Check type="switch" checked={Number(modules.is_location_salesperson) === 1} onChange={() => onchangeData("is_location_salesperson")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unit Summary
                <Form.Check type="switch" checked={Number(modules.is_unit_summary_salesperson) === 1} onChange={() => onchangeData("is_unit_summary_salesperson")} />
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-4">
            <h6>Phase Details - Public</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Sales Chart
                <Form.Check type="switch" checked={Number(modules.is_ql_saleschart_customer) === 1} onChange={() => onchangeData("is_ql_saleschart_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Virtual Tour
                <Form.Check type="switch" checked={Number(modules.is_ql_virtual_tour_customer) === 1} onChange={() => onchangeData("is_ql_virtual_tour_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Units
                <Form.Check type="switch" checked={Number(modules.is_ql_unit_customer) === 1} onChange={() => onchangeData("is_ql_unit_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Floor Plan
                <Form.Check type="switch" checked={Number(modules.is_ql_floor_plan_customer) === 1} onChange={() => onchangeData("is_ql_floor_plan_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Files
                <Form.Check type="switch" checked={Number(modules.is_ql_files_customer) === 1} onChange={() => onchangeData("is_ql_files_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Quick Link Contacts
                <Form.Check type="switch" checked={Number(modules.is_ql_contacts_customer) === 1} onChange={() => onchangeData("is_ql_contacts_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Site Plan
                <Form.Check type="switch" checked={Number(modules.is_site_plan_customer) === 1} onChange={() => onchangeData("is_site_plan_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Location
                <Form.Check type="switch" checked={Number(modules.is_location_customer) === 1} onChange={() => onchangeData("is_location_customer")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unit Summary
                <Form.Check type="switch" checked={Number(modules.is_unit_summary_customer) === 1} onChange={() => onchangeData("is_unit_summary_customer")} />
              </ListGroup.Item>
            </ListGroup>
          </section>

          <section className="mb-4">
            <h6>Booking Details</h6>
            <ListGroup>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                SPA Information
                <Form.Check type="switch" checked={Number(modules.is_spa_info) === 1} onChange={() => onchangeData("is_spa_info")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Solicitor Information
                <Form.Check type="switch" checked={Number(modules.is_solicitor_info) === 1} onChange={() => onchangeData("is_solicitor_info")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Financier Information
                <Form.Check type="switch" checked={Number(modules.is_financier_info) === 1} onChange={() => onchangeData("is_financier_info")} />
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Documents
                <Form.Check type="switch" checked={Number(modules.is_document) === 1} onChange={() => onchangeData("is_document")} />
              </ListGroup.Item>
            </ListGroup>
          </section>
        </div>
      )}
    </Container>
  );
};

export default BookingModules;
