import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Deal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/deal" || location.pathname === "/deal/") {
      navigate("/deal/list", { replace: true });
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid style={{ margin: 0, padding: 0 }}>
      <Outlet />
    </Container>
  );
};

export default Deal;
