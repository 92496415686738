import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Form, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import FormSelect from "../includes/FormSelect";

const SettingPermission = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [permissionList, setPermissionList] = useState([]);

    const [permissionID, setPermissionID] = useState(1);
    const [searchVal, setSearchVal] = useState("");

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          cellDataType: true,
        };
    }, []);

    const [gridColumn, setGridColumn] = useState([]);

    const getGridData = (id) => {
        // setIsLoading(true);
        
        axios.get('ws/ws_permission.php',{
            params: {
                task: 'listv3',
                permission_module_id: id ? id : 1,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;
            
            if (Number(data.listing_of_teams.status) === 0) {
                
                const records = data.listing_of_teams.record;
                
                const userList = records.map(record => {
                    return {user_name: record.user_name, user_id: record.user_id}
                });

                const permissionModuleColumns = records.map(record => {
                    const rowData = {};
                    record.permission_module.forEach(result => {
                      Object.keys(result).forEach(key => {
                        if (key !== 'date_time_create') {
                          rowData[key] = result[key];
                        }
                      });
                    });
                    return rowData;
                });

                const rowData = records.map(record => {
                    const rowData = {};
                    record.permission_module_result.forEach(result => {
                      Object.keys(result).forEach(key => {
                        if (key !== 'date_time_create') {
                          rowData[key] = result[key];
                        }
                      });
                    });
                    return rowData;
                });

                const combineArray = permissionModuleColumns.map((item, index) => ({
                    ...item,
                    ...rowData[index],
                }));

                const combineArray2 = combineArray.map((item, index) => ({
                    ...item,
                    ...userList[index],
                }));

                const gridColumn = [];

                Object.keys(combineArray2[0]).forEach(key => {
                    if (key === 'user_name' || (key.includes('_title') && combineArray2[0][key])) {
                        gridColumn.push({
                            headerName: key === 'user_name' ? 'Group' : key === 'module_title' ? 'Module level' : combineArray2[0][key],
                            field: key,
                            flex: 1,
                            filter: key === 'user_name' ? true : false,
                            headerClass: key === 'user_name' ? '' : 'center',
                            cellClass: key === 'user_name' ? '' : 'center',
                            minWidth: key === 'user_name' ? 250 : 180,
                            cellRenderer: key !== 'user_name' ? RenderGridAction : RenderGridShow,
                        });
                    }
                });

                var groupIndex = gridColumn.findIndex(item => item.headerName === 'Group');

                if (groupIndex !== -1) {
                    const newArray = [gridColumn[groupIndex], ...gridColumn.slice(0, groupIndex), ...gridColumn.slice(groupIndex + 1)];

                    setGridColumn(newArray);
                }else{
                    setGridColumn(gridColumn);
                }

                setRowData(combineArray2);
                setRecordFound(data.listing_of_teams.record.length);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setRowData([]);
                setRecordFound(0);
                setIsGrid(false);
                setIsLoading(false);
            });
        });
    }

    const RenderGridShow = (params) => {
        if (params.data) {
            return params.data.user_name;
        }
    }

    const RenderGridAction = (params) => {
        if (params.data) {
            
            if (params.colDef.field === 'module_title') {
                return(
                    <Form.Group>
                        <Form.Check
                            type="switch"
                            checked={Number(params.data.module_level_permission) === 1 ? true : false}
                            onChange={() => {handleClick(params.data, "module_level")}}
                        />
                    </Form.Group>
                );
            }
            else{
                for (let index = 1; index <= 20; index++) {
                    if (params.colDef.field === `rules${index}_title`) {
                        return(
                            <Form.Group>
                                <Form.Check
                                    type="switch"
                                    checked={Number(params.data[`rules${index}`]) === 1 ? true : false}
                                    // disabled={Number(params.data.module_level_permission) === 1 ? false : true}
                                    onChange={() => {handleClick(params.data, `rules${index}`)}}
                                />
                            </Form.Group>
                        );
                    }
                }
            }
        }
    }

    const handleClick = (values, clicked) => {
        // setIsLoading(true);
        
        if (clicked === 'module_level') {
            if (Number(values.module_level_permission) === 1) {
                values.module_level_permission = 0;
            }else{
                values.module_level_permission = 1;
            }
        }else{
            Object.keys(values).forEach(key => {
                if (key === clicked) {
                    if (Number(values[key]) === 1) {
                        values[key] = 0;
                    }else{
                        values[key] = 1;
                    }
                }
            });
        }
        
        axios.get('ws/ws_permission.php',{
            params: {
                task: "add_module",
                user_id: values.user_id,
                permission_module_id: values.permission_module_id,
                module_level_permission: values.module_level_permission,
                rules1: values.rules1,
                rules2: values.rules2,
                rules3: values.rules3,
                rules4: values.rules4,
                rules5: values.rules5,
                rules6: values.rules6,
                rules7: values.rules7,
                rules8: values.rules8,
                rules9: values.rules9,
                rules10: values.rules10,
                rules11: values.rules11,
                rules12: values.rules12,
                rules13: values.rules13,
                rules14: values.rules14,
                rules15: values.rules15,
                rules16: values.rules16,
                rules17: values.rules17,
                rules18: values.rules18,
                rules19: values.rules19,
                rules20: values.rules20,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                getGridData(values.permission_module_id);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                })
                .then(result => {
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });
    }

    const getPermissionList = () => {
        axios.get('ws/ws_permission.php',{
          params: {
            task: "list_module_info"
          }  
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var listData = [];

                data.record.forEach(data => {
                    listData.push({label: data.module_title, value: data.permission_module_id});
                });

                setPermissionList(listData);
            }else{
                setPermissionList([]);
            }
        })
        .catch(error => {
            setPermissionList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getGridData();
            getPermissionList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (data) => {
        setPermissionID(data.value);
        getGridData(data.value);
    }

    const handleSearch = (text) => {
        const searchText = text;
        const filterInstance = gridRef.current.api.getFilterInstance('user_name');
        filterInstance.setModel({
        type: 'contains',
        filter: searchText,
        });
        gridRef.current.api.onFilterChanged();
        setRecordFound(gridRef.current.api.getModel().getRowCount());
    }

    return(
        <Container fluid>
            {isLoading ? (<Loader/>) : (
                <div>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="mb-3">
                            <div>
                                <Form.Group style={{width: 250}}>
                                    <FormSelect
                                        placeholder="select module"
                                        isSearchable={true}
                                        value={permissionList.filter(opt => Number(opt.value) === Number(permissionID))}
                                        options={permissionList}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group style={{width: 250}}>
                                    <Form.Control
                                        type="text"
                                        placeholder="search by group"
                                        value={searchVal}
                                        onChange={(e) => {handleSearch(e.target.value); setSearchVal(e.target.value);}}
                                    />
                                </Form.Group>
                            </div>
                            <div className="ms-auto">
                                result: {Number(recordFound) === 1 ? `${recordFound} record found` : Number(recordFound) > 1 ? `${recordFound} records found.` : `No record found.`}
                            </div>
                        </Stack>
                        <div className="mb-3" style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={gridColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={80}
                                    pagination={false}
                                    // paginationPageSize={30}
                                    // paginationPageSizeSelector={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
}

export default SettingPermission;