import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";

const SearchInput = ({ searchRef, handleSearch, handleSearchShow, handleSearchClose, loading }) => {
  const [searching, setSearching] = useState(false);

  const handleChange = (e) => {
    handleSearch(e.target.value);

    if (e.target.value === '') {
      setSearching(false);
    }

    if (e.target.value.length > 1) {
      setTimeout(() => {
        setSearching(true);
      }, 500);
    }
  };

  return (
    <React.Fragment>
      <Form className="op-search-container">
        <div className="op-search">
          {loading ? (
            <div className="op-icon-search">
              <Spinner animation="border" size="sm" />
            </div>
          ) : (
            <FontAwesomeIcon icon={faMagnifyingGlass} className="op-icon-search" />
          )}

          {/* <FontAwesomeIcon icon={faMagnifyingGlass} className="op-icon-search" /> */}

          <Form.Control className="op-search-input" type="text" placeholder="Search Outperform" onFocus={handleSearchShow} onChange={handleChange} />
          {/* {searchRef && <FontAwesomeIcon icon={faXmark} size="xl" className="op-icon-close" onClick={} />} */}
        </div>
      </Form>
      {searchRef && <div className="op-search-backdrop" onClick={handleSearchClose}></div>}
    </React.Fragment>
  );
};

export default SearchInput;
