import { useAuth } from "../../auth/AuthContext";
import axios from "../../api/axios";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { Container, Row, Col, Stack, Button, Accordion, useAccordionButton, Card, Nav, Form, Modal, Image, ButtonGroup, InputGroup, Dropdown } from "react-bootstrap";
import moment from "moment";

const EditSpaInformation = ({ bookingData, spaRecord, onDone }) => {
  const { session } = useAuth();
  const { Formik } = formik;

  const spaInitialValues = {
    spa_reference: spaRecord.spaRef ? spaRecord.spaRef : "",
    spaDate: spaRecord.spaDate != "0000-00-00 00:00:00" ? moment(new Date(spaRecord.spaDate)).format("YYYY-MM-DD") : "",
    spaStampDate: spaRecord.spaStampDate != "0000-00-00 00:00:00" ? moment(new Date(spaRecord.spaStampDate)).format("YYYY-MM-DD") : "",
  };

  const sendSpa = async (values) => {
    try {
      const response = await axios.get("ws/ws_rea_booking.php", {
        params: {
          task: "EditSpa",
          spa_type: "spa",
          rea_booking_id: bookingData.rea_booking_id,
          sales_rea_id: spaRecord.sales_rea_id,
          spaRef: values.spa_reference,
          spaStampDate: values.spaStampDate,
          spaDate: values.spaDate,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        onDone();
        Swal.fire({
          icon: "success",
          text: "Successfully updated the SPA Information",
          timer: 2000,
        });
      } else {
        onDone();
        Swal.fire({
          icon: "error",
          text: "Failed to update the SPA Information, please try again or contact our developer",
        });
      }
    } catch (error) {
      onDone();
      Swal.fire({
        icon: "error",
        text: error,
      });
    }
  };

  return (
    <Formik onSubmit={sendSpa} initialValues={spaInitialValues}>
      {({ handleSubmit, handleChange, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title as={"h6"} className="text-uppercase">
              Edit SPA Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4">
            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">SPA Date</Form.Label>
              <Form.Control type="date" value={values.spaDate} onChange={(e) => handleChange("spaDate")(e ? e : "")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">SPA Stamped Date</Form.Label>
              <Form.Control type="date" value={values.spaStampDate} onChange={(e) => handleChange("spaStampDate")(e ? e : "")} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase">SPA Reference</Form.Label>
              <Form.Control className="text-uppercase" type="text" value={values.spa_reference} onChange={(e) => handleChange("spa_reference")(e.target.value)} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn op-button op-primary-color text-light shadow text-uppercase">
              Save
            </button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  );
};

export default EditSpaInformation;
