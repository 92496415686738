import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Card, Offcanvas } from "react-bootstrap";
import { FormSelect, FormDate } from "../includes/FormCustom";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import moment from "moment";
import Loader from "../includes/Loader";
import { faCheckToSlot, faCircleCheck, faClock, faPaperPlane, faTicket, faTickets, faXmarkCircle } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar, CartesianGrid } from "recharts";
import { max } from "lodash";

const RedemptionDashboard = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState({ label: "All Category", value: "" });
  const [ds, setDs] = useState(moment().add(-3, "months").format("DD-MM-YYYY"));
  const [de, setDe] = useState(moment().add(0, "months").format("DD-MM-YYYY"));
  const [statsData, setStatsData] = useState({});
  const [emailSent, setEmailSent] = useState([]);
  const [isTotalVoucher, setIsTotalVoucher] = useState(false);
  const [voucherData, setVoucherData] = useState([]);
  const [isAvailable, setIsAvailable] = useState(false);
  const [availableData, setAvailableData] = useState([]);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [assignedData, setAssignedData] = useState([]);
  const [isAssignedData, setIsAssignedData] = useState(false);
  const [redeemedData, setRedeemedData] = useState([]);
  const [isRedeemed, setisRedeemed] = useState(false);
  const [expiredData, setExpiredData] = useState([]);
  const [isExpired, setIsExpired] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [pieChartData, setPieChartData] = useState([]);
  const colors = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
  const [voucherCount, setVoucherCount] = useState(0);
  const [availableCount, setAvailableCount] = useState(0);
  const [sentCount, setSentCount] = useState(0);
  const [assignedCount, setAssignedCount] = useState(0);
  const [redeemedCount, setRedeemedCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  // GET FUNCTION =================================================

  const getCategory = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_crud_category.php", {
        params: {
          task: "CategoryList",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.category_name,
          value: record.category_id,
        }));

        recordOptions.unshift({
          label: "All Category",
          value: "",
        });

        setCategoryData(recordOptions[0]);
        setCategoryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStatistics = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_redemption_dashboard.php", {
        params: {
          task: "GetStatisticsDataV2",
          ds: ds,
          de: de,
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        data.record.vendor.forEach((record) => {
          record.available_count = Number(record.available_count);
          record.assigned_count = Number(record.assigned_count);
          record.redeemed_count = Number(record.redeemed_count);
          record.expired_count = Number(record.expired_count);
          record.error_count = Number(record.error_count);
        });

        setStatsData(data.record);
        const report = [
          { name: "Available", value: Number(data.record.available_voucher) },
          { name: "Assigned", value: Number(data.record.assigned_voucher) },
          { name: "Redeemed", value: Number(data.record.redeemed_voucher) },
          { name: "Expired", value: Number(data.record.expired_voucher) },
          { name: "Error", value: Number(data.record.error_voucher) },
        ];

        setPieChartData(report);
        setLoading2(false);
      } else {
        setStatsData({});
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // DOWNLOAD FUNCTION ==================================================

  const downloadVoucher = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_voucher.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadAvailable = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_available.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadEmailSent = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_email_sent.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const donwloadAssigned = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_assigned.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const donwloadRedeemed = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_redeemed.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const donwloadExpird = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_expired.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadError = async () => {
    try {
      const response = await axios.get("ext/glRedemption/api_download_error.php", {
        params: {
          category_id: categoryData.value,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: ds,
          de: de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        window.open(`${session.hostUrlApi}/${session.hostUrlApiType}/ext/glRedemption/file/${data.filename}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  AG GRID FUNCTION ==================================================

  const containerStyle = useMemo(() => ({ width: "100%", height: "80vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  // Voucher -----------------------------------------------------

  const voucherRef = useRef();
  const voucherColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
    },
    {
      headerName: "Voucher 1",
      field: "voucher_code",
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
    },
    {
      headerName: "Category",
      field: "category_name",
    },

    {
      headerName: "Assigned Date",
      field: "voucher_assign_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_assign_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_assign_date_time)).format("LLL");
          }

          return "";
        }
      },
    },

    {
      headerName: "Redeemed Date",
      field: "voucher_redeem_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_redeem_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_redeem_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Expired Date",
      field: "voucher_expired_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_expired_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_expired_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Error Date",
      field: "voucher_error_date_time",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_error_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_error_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
    },
  ];

  const voucherDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetVoucherDataV2",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              category_id: categoryData.value,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setVoucherCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isTotalVoucher]);

  const voucherColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const voucherRowId = useCallback(function (params) {
    return params.data.voucher_id.toString();
  }, []);

  // Available ----------------------------------------------------

  const availableRef = useRef();
  const availableColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Voucher 1",
      field: "voucher_code",
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
    },
    {
      headerName: "Category",
      field: "category_name",
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
    },
    {
      headerName: "Created Date",
      field: "date_time_create",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.date_time_create !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.date_time_create)).format("LLL");
          }

          return "";
        }
      },
    },
  ];

  const availableDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetAvailableDataV2",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              category_id: categoryData.value,
              startRow: params.startRow,
              endRow: params.endRow,
              ds: ds,
              de: de,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setAvailableCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isAvailable]);

  const availableColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const availableRowId = useCallback(function (params) {
    return params.data.voucher_code.toString();
  }, []);

  // Sent ----------------------------------------------------

  const sentRef = useRef();
  const sentColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Salutation",
      field: "prospect_salutation",
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
    },
    {
      headerName: "Date Email Sent",
      field: "date_time_create",
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.date_time_create !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.date_time_create)).format("LLL");
          }
          return "";
        }
      },
    },
  ];

  const sentDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetEmailSentV2",
              ds: ds,
              de: de,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setSentCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isEmailSent]);

  const sentColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const sentRowId = useCallback(function (params) {
    return params.data.redemption_id.toString();
  }, []);

  // Assigned ----------------------------------------------------

  const assignedRef = useRef();
  const assignedColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
      minWidth: 150,
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
      minWidth: 150,
    },
    {
      headerName: "Voucher 1",
      field: "voucher_code",
      minWidth: 150,
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
      minWidth: 150,
    },
    {
      headerName: "Category",
      field: "category_name",
      minWidth: 150,
    },
    {
      headerName: "Assigned Date",
      field: "voucher_assign_date_time",
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_assign_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_assign_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Redemption Available Until",
      field: "redemption_expiry_date_time",
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.redemption_expiry_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.redemption_expiry_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
      minWidth: 150,
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
      minWidth: 150,
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
      minWidth: 150,
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
      minWidth: 150,
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
      minWidth: 150,
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
      minWidth: 150,
    },
  ];

  const assignedDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetAssignedDataV2",
              ds: ds,
              de: de,
              category_id: categoryData.value,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setAssignedCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isAssignedData]);

  const assignedColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const assignedRowId = useCallback(function (params) {
    return params.data.voucher_code.toString();
  }, []);

  // Redeemed ----------------------------------------------------

  const redeemedRef = useRef();
  const redeemedColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      minWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
      minWidth: 150
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
      minWidth: 150
    },
    {
      headerName: "Voucher",
      field: "voucher_code",
      minWidth: 150
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
      minWidth: 150
    },
    {
      headerName: "Category",
      field: "category_name",
      minWidth: 150
    },
    {
      headerName: "Redeemed Date",
      field: "voucher_redeem_date_time",
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_redeem_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_redeem_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
      minWidth: 150,
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
      minWidth: 150,
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
      minWidth: 150,
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
      minWidth: 150,
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
      minWidth: 150,
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
      minWidth: 150,
    },
  ];

  const redeemedDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetRedeemedDataV2",
              ds: ds,
              de: de,
              category_id: categoryData.value,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setRedeemedCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isRedeemed]);

  const redeemedColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const redeemedRowId = useCallback(function (params) {
    return params.data.voucher_code.toString();
  }, []);

  // Redeemed ----------------------------------------------------

  const expiredRef = useRef();
  const expiredColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      minWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
      minWidth: 150,
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
      minWidth: 150,
    },
    {
      headerName: "Voucher",
      field: "voucher_code",
      minWidth: 150,
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
      minWidth: 150,
    },
    {
      headerName: "Category",
      field: "category_name",
      minWidth: 150,
    },
    {
      headerName: "Expired Date",
      field: "voucher_expired",
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_expired !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_expired)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
      minWidth: 150,
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
      minWidth: 150,
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
      minWidth: 150,
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
      minWidth: 150,
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
      minWidth: 150,
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
    },
  ];

  const expiredDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetExpiredDataV2",
              ds: ds,
              de: de,
              category_id: categoryData.value,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setExpiredCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isExpired]);

  const expiredColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const expiredRowId = useCallback(function (params) {
    return params.data.voucher_code.toString();
  }, []);

  // Redeemed ----------------------------------------------------

  const errorRef = useRef();
  const errorColumn = [
    {
      headerName: "No",
      headerClass: "center",
      cellClass: "center",
      field: "",
      maxWidth: 80,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
      minWidth: 150,
    },
    {
      headerName: "Voucher 1",
      field: "voucher_code",
      minWidth: 150,
    },
    {
      headerName: "Vendor",
      field: "vendor_name",
      minWidth: 150,
    },
    {
      headerName: "Category",
      field: "category_name",
      minWidth: 150,
    },
    {
      headerName: "Error Date",
      field: "voucher_error_date_time",
      minWidth: 150,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.voucher_error_date_time !== "0000-00-00 00:00:00") {
            return moment(new Date(params.data.voucher_error_date_time)).format("LLL");
          }

          return "";
        }
      },
    },
    {
      headerName: "Voucher 2",
      field: "voucher_popcorn",
      minWidth: 150,
    },
    {
      headerName: "Voucher 3",
      field: "voucher3",
      minWidth: 150,
    },
    {
      headerName: "Voucher 4",
      field: "voucher4",
      minWidth: 150,
    },
    {
      headerName: "Voucher 5",
      field: "voucher5",
      minWidth: 150,
    },
    {
      headerName: "Voucher 6",
      field: "voucher6",
      minWidth: 150,
    },
    {
      headerName: "Voucher 7",
      field: "voucher7",
      minWidth: 150,
    },
  ];

  const errorDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        axios
          .get("ext/glRedemption/api_redemption_dashboard.php", {
            params: {
              task: "GetErrorDataV2",
              ds: ds,
              de: de,
              category_id: categoryData.value,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              startRow: params.startRow,
              endRow: params.endRow,
            },
          })
          .then((response) => {
            const data = response.data;
            setTimeout(() => {
              var voucherInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                voucherInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setErrorCount(Number(data.totalRecord));
              }

              var rowsThisPage = voucherInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }
              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [session, categoryData, isError]);

  const errorColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
    };
  }, []);

  const errorRowId = useCallback(function (params) {
    return params.data.voucher_code.toString();
  }, []);

  // RENDER FUNCTION ====================================================

  const renderCustomizedLabel = (props, index) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 10;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const adjustedEy = ey + index * 12;

    if (Number(value) > 0) {
      return (
        <g>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
          <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
          <text x={ex + (cos >= 0 ? 1 : -1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
            {`${payload.name}: ${value}`}
          </text>
        </g>
      );
    }
  };

  // USEEFFECT FUNCTION =================================================

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getStatistics();
          await getCategory();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session, init]);

  return (
    <Container fluid className="p-0 m-0 position-relative vh-100">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4 position-relative">
          <Row>
            <Col xxl={12} className="mb-4">
              <Stack direction="horizontal" className="flex-row-reverse" gap={2}>
                <Button
                  variant=""
                  className="btn op-primary-color text-light"
                  onClick={() => {
                    setLoading2(true);
                    getStatistics();
                  }}
                >
                  Apply
                </Button>

                <Form.Group>
                  <FormDate placeholder="Start Date" className="shadow-sm border-0" value={moment(de, "DD-MM-YYYY").format("DD/MM/YYYY")} onChange={setDe} />
                </Form.Group>

                <Form.Group>
                  <FormDate placeholder="End Date" className="shadow-sm border-0" value={moment(ds, "DD-MM-YYYY").format("DD/MM/YYYY")} onChange={setDs} />
                </Form.Group>

                <Form.Group>
                  <FormSelect options={categoryList} valueDefault={categoryData} onChange={setCategoryData} border={false} shadow={true} width="250px" />
                </Form.Group>
              </Stack>
            </Col>

            <Col xxl={12} className="mb-4">
              <Row>
                <Col xxl={4} className="mb-3">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Voucher
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setIsTotalVoucher}>
                          {statsData.total_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faTicket} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={4} className="mb-3">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Available
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setIsAvailable}>
                          {statsData.available_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faTickets} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={4} className="mb-3">
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        1<sup>st</sup> Of Month Redemption Email Sent
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setIsEmailSent}>
                          {statsData.total_sent}
                        </Button>
                        <FontAwesomeIcon icon={faPaperPlane} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={3}>
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Assigned
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setIsAssignedData}>
                          {statsData.assigned_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faCircleCheck} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={3}>
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Redeemed
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setisRedeemed}>
                          {statsData.redeemed_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faCheckToSlot} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={3}>
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Expired
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0" onClick={setIsExpired}>
                          {statsData.expired_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faClock} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xxl={3}>
                  <Card className="border-0 shadow-sm w-100">
                    <Card.Header className="bg-light">
                      <Card.Title as={"h6"} className="m-0">
                        Total Error
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center" onClick={setIsError}>
                        <Button variant="link" className="text-dark text-decoration-none py-2 px-0">
                          {statsData.error_voucher}
                        </Button>
                        <FontAwesomeIcon icon={faXmarkCircle} size="3x" />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xxl={12} className="mb-4">
              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-0">
                    Voucher Status
                  </Card.Title>
                </Card.Header>
                <Card.Body className="py-3 px-0">
                  <ResponsiveContainer width="100%" height={450}>
                    <PieChart>
                      <Legend verticalAlign="top" wrapperStyle={{ left: "2%" }} />
                      <Pie
                        data={pieChartData}
                        innerRadius={80}
                        outerRadius={120}
                        dataKey="value"
                        label={(props) =>
                          renderCustomizedLabel(
                            props,
                            pieChartData.findIndex((item) => item.name === props.payload.name)
                          )
                        }
                        labelLine={false}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>

            <Col xxl={12} className="mb-4">
              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-0">
                    Voucher Status
                  </Card.Title>
                </Card.Header>
                <Card.Body className="py-3">
                  <ResponsiveContainer height={450} width={"100%"}>
                    <BarChart layout="vertical" data={statsData.vendor}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis type="number" />
                      <YAxis type="category" dataKey="vendor_name" width={150} />
                      <Tooltip />
                      <Bar dataKey="available_count" fill="#008ffb" stackId="a" name="Available" />
                      <Bar dataKey="assigned_count" fill="#00e396" stackId="a" name="Assigned" />
                      <Bar dataKey="redeemed_count" fill="#feb019" stackId="a" name="Redeemed" />
                      <Bar dataKey="expired_count" fill="#ff4560" stackId="a" name="Expired" />
                      <Bar dataKey="error_count" fill="#775dd0" stackId="a" name="Error" />
                    </BarChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Offcanvas show={isTotalVoucher} onHide={setIsTotalVoucher} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>List Voucher Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={downloadVoucher}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{voucherCount === 0 ? "No record found" : voucherCount === 1 ? "1 record found" : `${voucherCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={voucherRef}
                    columnDefs={voucherColumn}
                    datasource={voucherDataSource}
                    defaultColDef={voucherColDef}
                    getRowId={voucherRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isAvailable} onHide={setIsAvailable} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Available Voucher Status Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={downloadAvailable}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{availableCount === 0 ? "No record found" : availableCount === 1 ? "1 record found" : `${availableCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={availableRef}
                    columnDefs={availableColumn}
                    datasource={availableDataSource}
                    defaultColDef={availableColDef}
                    getRowId={availableRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isEmailSent} onHide={setIsEmailSent} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Total Email Sent</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={downloadEmailSent}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{sentCount === 0 ? "No record found" : sentCount === 1 ? "1 record found" : `${sentCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={sentRef}
                    columnDefs={sentColumn}
                    datasource={sentDataSource}
                    defaultColDef={sentColDef}
                    getRowId={sentRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isAssignedData} onHide={setIsAssignedData} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Assigned Voucher Status Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={donwloadAssigned}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{assignedCount === 0 ? "No record found" : assignedCount === 1 ? "1 record found" : `${assignedCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={assignedRef}
                    columnDefs={assignedColumn}
                    datasource={assignedDataSource}
                    defaultColDef={assignedColDef}
                    getRowId={assignedRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isRedeemed} onHide={setisRedeemed} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Redeemed Voucher Status Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={donwloadRedeemed}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{redeemedCount === 0 ? "No record found" : redeemedCount === 1 ? "1 record found" : `${redeemedCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={redeemedRef}
                    columnDefs={redeemedColumn}
                    datasource={redeemedDataSource}
                    defaultColDef={redeemedColDef}
                    getRowId={redeemedRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isExpired} onHide={setIsExpired} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Expired Voucher Status Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={donwloadExpird}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{expiredCount === 0 ? "No record found" : expiredCount === 1 ? "1 record found" : `${expiredCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={expiredRef}
                    columnDefs={expiredColumn}
                    datasource={expiredDataSource}
                    defaultColDef={expiredColDef}
                    getRowId={expiredRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Offcanvas show={isError} onHide={setIsError} placement="bottom" style={{ height: "100vh", left: 57 }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Error Voucher Status Data</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Stack direction="horizontal" className="flex-row-reverse mb-3">
                <Button variant="" className="btn op-primary-color text-light" onClick={downloadError}>
                  Download
                </Button>
                <p className="op-text-small mb-0 me-3">{errorCount === 0 ? "No record found" : errorCount === 1 ? "1 record found" : `${errorCount} records found`}</p>
              </Stack>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={errorRef}
                    columnDefs={errorColumn}
                    datasource={errorDataSource}
                    defaultColDef={errorColDef}
                    getRowId={errorRowId}
                    rowSelection={"multiple"}
                    rowModelType={"infinite"}
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    pagination={true}
                    paginationPageSize={100}
                    paginationPageSizeSelector={false}
                    suppressRowClickSelection={true}
                    animateRows={true}
                  />
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      )}

      {loading2 && (
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}>
          <div style={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", backgroundColor: "#000", opacity: 0.1, zIndex: 1 }} />
          <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: 2 }}>
            <Loader />
          </div>
        </div>
      )}
    </Container>
  );
};

export default RedemptionDashboard;
