import { useState, useEffect, useMemo, useCallback, useRef, forwardRef } from "react";
import { Col, Container, Row, Stack, Dropdown, Form, Offcanvas, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faFilterList, faBullhorn } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { FormSelect, FormSelectCreatable, FormCustomField, FormDate } from "../includes/FormCustom";
import axios from "../api/axios";
import { setCurrency, setRelativeTime } from "../lib/js/Function";
import "../lib/scss/op-deal.scss";
import moment from "moment";
import NoRecord from "../includes/NoRecord";
import Loader from "../includes/Loader";
import { useAuth } from "../auth/AuthContext";
import "../lib/scss/op-deal.scss";
import { useTranslation } from "react-i18next";
import LoaderAction from "../includes/LoaderAction";
import iziToast from "izitoast";

var startDate = new Date();
var endDate = new Date();
startDate.setDate(endDate.getDate() - 600);

const DealList = () => {
  const { session } = useAuth();
  const { t } = useTranslation();
  const { Formik } = formik;
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [initLoad, setInitLoad] = useState(false);
  const initialized = useRef(false);
  const filterInit = useRef(false);
  const [modalAddDeal, setModalAddDeal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [customFieldDeal, setCustomFieldDeal] = useState([]);
  const [customFieldPerson, setCustomFieldPerson] = useState([]);
  const [pipeline, setPipeline] = useState([]);
  const [user, setUser] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [interaction, setInteraction] = useState([]);
  const [dealSource, setDealSource] = useState([]);
  const [pipelineData, setPipelineData] = useState({ label: "", value: "" });
  const [stageDefaultData, setStageDefaultData] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [dealCount, setDealCount] = useState(0);
  const [ísPipeline, setIsPipeline] = useState(false);
  const [isDisabledCreateDeal, setIsDisabledCreateDeal] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isInteraction, setIsInteraction] = useState(false);
  const [isFindProspect, setIsFindProspect] = useState(false);
  const [isDeal, setIsDeal] = useState(true);
  const [isFilter, setIsFilter] = useState(false);
  const [userFilterOpts, setUserFilterOpts] = useState([]);
  const [businessUnitFilterOpts, setBusinessUnitFilterOpts] = useState([]);
  const [dealSourceFilterOpts, setDealSourceFilterOpts] = useState([]);
  const [stageFilterOpts, setStageFilterOpts] = useState([]);
  const [statusFilterOpts, setStatusFilterOpts] = useState([]);
  const [dealCustomField, setDealCustomField] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [isTransferPipe, setIsTransferPipe] = useState(false);
  const [pipelineList, setPipelineList] = useState([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const dealRef = useRef(null);

  const [filter, setFilter] = useState({
    search: "",
    ds: moment(startDate).format("YYYY-MM-DD"),
    de: moment(endDate).format("YYYY-MM-DD"),
    user: { label: t("DealList_all_user"), value: "All" },
    business_unit: { label: t("DealList_all_business_unit"), value: "All" },
    deal_source: { label: t("DealList_all_source"), value: "All" },
    stage: { label: t("DealList_all_stage"), value: "All" },
    status: { label: t("DealList_all_status"), value: "All" },
    custom_field: [],
  });

  const [addDeal, setAddDeal] = useState({
    deal_title: "",
    deal_source: "",
    deal_value: "",
    expected_close_date: "",
    prospect_name: "",
    prospect_id: "",
    contact1: "",
    contact_id: "",
    contact1_disabled: false,
    cf_deals: [],
    cf_person: [],
  });

  const transferDealSchema = yup.object().shape({
    user: yup.string().required("User is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const emailSchema = yup.object().shape({
    email_template_name: yup.string().required("Email template is required"),
    campaign_title: yup.string().required("Campaign title is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const smartInteractionSchema = yup.object().shape({
    interaction: yup.string().required("Smart interaction template is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  const newDealSchema = yup.object().shape({
    deal_title: yup.string().required(t("DealList_error_message")),
    prospect_name: yup.string().required(t("DealList_error_message")),
    prospect_id: yup.string().required(t("DealList_error_message")),
    contact1: yup.string().required(t("DealList_error_message")),
  });

  const transferPipeSchema = yup.object().shape({
    pipeline: yup.string().required("This field is required"),
    confirmation: yup
      .string()
      .required(t("DealList_error_message"))
      .test("is-yes", t("Curation_error_confirmation_must_be_yes"), (value) => value === "YES"),
  });

  // GET FUNCTION --------------------------------------------------------------

  const getPipeline = async () => {
    try {
      const response = await axios.get("/ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: session.company_id,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const pipelineRecord = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        const pipelineRecord2 = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        pipelineRecord2.unshift({ label: "Select Pipeline", value: "" });
        setPipelineList(pipelineRecord2);

        pipelineRecord.unshift({
          label: t("DealList_all_pipeline"),
          value: "All",
        });

        var selectedPipeline = {};
        if (session.pipe_pipeid) {
          selectedPipeline = pipelineRecord.find((record) => record.value === session.pipe_pipeid);
        } else {
          selectedPipeline = pipelineRecord[0];
        }

        setPipelineData(selectedPipeline);
        setPipeline(pipelineRecord);
        getStage(selectedPipeline.value);
        setIsPipeline(true);
        setLoading2(false);
      } else {
        setPipeline([]);
        setIsPipeline(false);
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStage = (pipelineId) => {
    axios
      .get("/ws/ws_setting.php", {
        params: {
          task: "getPipeline",
          company: session.company_id,
          pipeline_id: pipelineId,
        },
      })
      .then(({ data }) => {
        if (Number(data.status) === 0) {
          const stageRecord = data.record.map((record) => ({
            label: record.cs_title,
            value: record.cs_id,
          }));

          stageRecord.unshift({
            label: t("DealList_all_stage"),
            value: "All",
          });

          setStageFilterOpts(stageRecord);

          if (!firstLoad) {
            setFirstLoad(true);
            setStageDefaultData(data.record[0].cs_id);
          }

          if (Number(data.manual_deal_creation_disable) === 1) {
            setIsDisabledCreateDeal(true);
          }

          getCustomField(pipelineId);
        } else {
          if (pipelineId !== "All") {
            Swal.fire({
              icon: "error",
              title: t("DealList_oppss"),
              text: t("DealList_no_stage"),
            });
          }
        }
      });
  };

  const getCustomField = (pipelineId) => {
    axios
      .get("/ws/ws_custom_field.php", {
        params: {
          task: "getCFForAddDeal",
          area: "",
          cfpipe: pipelineId,
          company: session.company_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          v_add: "yes",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === 0) {
          var cfDeal = data.record.filter((record) => {
            return record.cf_area === "deal";
          });

          var cfPerson = data.record.filter((record) => {
            return record.cf_area === "person";
          });

          setCustomFieldDeal(cfDeal);
          setCustomFieldPerson(cfPerson);

          setAddDeal((prevState) => ({
            ...prevState,
            cf_deals: cfDeal.map((record) => ({
              name: record.cf_id,
              fd_values: record.ctf_title === "Checkbox" ? [] : "",
            })),
            cf_person: cfPerson.map((record) => ({
              name: record.cf_id,
              fd_values: record.ctf_title === "Checkbox" ? [] : "",
            })),
          }));
        } else {
          setCustomFieldDeal([]);
          setCustomFieldPerson([]);
        }
      });
  };

  const getUser = () => {
    axios
      .get("/ws/ws_user.php", {
        params: {
          task: "4a",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const userRecord = data.record.map((record) => ({
            label: record.user_name,
            value: record.user_id,
          }));

          const userRecordFilter = data.record.map((record) => ({
            label: record.user_name,
            value: record.user_id,
          }));

          userRecordFilter.unshift({
            label: t("DealList_all_user"),
            value: "All",
          });

          setUserFilterOpts(userRecordFilter);
          setUser(userRecord);
        }
      });
  };

  const getEmailTemplate = () => {
    axios
      .get("/ws/ws_user.php", {
        params: {
          task: "getEmailTemplate",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const emailRecord = data.record.map((record) => ({
            label: record.email_template_title,
            value: record.email_template_id,
          }));

          setEmailTemplate(emailRecord);
        }
      });
  };

  const getSmartInteraction = () => {
    axios
      .get("/ws/ws_user.php", {
        params: {
          task: "getInteractions",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const interactionRecord = data.record.map((record) => ({
            label: record.interaction_name,
            value: record.custom_interaction_id,
          }));

          setInteraction(interactionRecord);
        }
      });
  };

  const getProspect = (inputValue, callback) => {
    setIsFindProspect(false);
    axios
      .get("/ws/ws_prospect.php", {
        params: {
          task: 9,
          company: session.company_id,
          uid: session.user_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var prospectRecord = data.record
            .filter((record) => {
              return Number(record.prospect_id) > 0;
            })
            .map((record) => ({
              label: record.prospect_name,
              value: record.prospect_id,
              customLabel: "",
              contact1: record.contact1 ? record.contact1 : record.contact2 ? record.contact3 : record.contact3 ? record.contact3 : record.contact4 ? record.contact4 : record.contact5 ? record.contact5 : "",
              contact_id: record.contact_id,
            }));

          var newProspectRecord = data.record.find((record) => Number(record.prospect_id) === 0);
          if (newProspectRecord) {
            prospectRecord.unshift({
              label: inputValue,
              value: 0,
              customLabel: newProspectRecord.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(prospectRecord);
          setIsFindProspect(true);
        } else {
          var options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindProspect(true);
        }
      });
  };

  const getDealSource = () => {
    axios
      .get("/ws/ws_source.php", {
        params: {
          task: 2,
          company: session.company_id,
        },
      })
      .then((response) => {
        var data = response.data;

        if (data.status === 0) {
          const dealSourceRecord = data.record.map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

          const filterRecord = data.record.map((record) => ({
            label: record.ds_title,
            value: record.ds_id,
          }));

          filterRecord.unshift({
            label: t("DealList_all_source"),
            value: "All",
          });

          setDealSourceFilterOpts(filterRecord);
          setDealSource(dealSourceRecord);
        }
      });
  };

  const getBusinessUnit = async () => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllBU",
          company: session.company_id,
        },
      });

      var data = response.data;
      if (data.status === 0) {
        const filterRecord = data.record.map((record) => ({
          label: record.unit_title,
          value: record.business_unit_id,
        }));

        filterRecord.unshift({
          label: t("DealList_all_business_unit"),
          value: "All",
        });

        setBusinessUnitFilterOpts(filterRecord);
      } else {
        setBusinessUnitFilterOpts([{ label: t("DealList_all_business_unit"), value: "All" }]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomFieldDeal = () => {
    axios
      .get("/ws/ws_custom_field.php", {
        params: {
          task: "5a",
          company: session.company_id,
          area: "deal",
          type: "select",
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            if (record.ctf_title === "Checkbox") {
              record.ctf_title = "Select";
            } else if (record.ctf_title === "Radio") {
              record.ctf_title = "Select";
            }
          });

          setFilter((prevState) => ({
            ...prevState,
            custom_field: data.record.map((record) => ({
              cf_id: record.cf_id,
              name: record.cf_id,
              fd_values: "-",
            })),
          }));

          filterInit.current = true;
          setDealCustomField(data.record);
        } else {
          filterInit.current = true;
        }
      });
  };

  // ONCHANGE FUNCTION ---------------------------------------------------------

  const onchangePipeline = (info) => {
    if (Number(rowCount) > 0) {
      dealRef.current.api.deselectAll();
      setIsRowSelected(false);
      setIsChecked(false);
      setIsIndeterminate(false);
      setRowCount(0);
      setRowData([]);
    }

    setPipelineData(info);
    if (info.value !== "All") {
      getStage(info.value);
    }
  };

  // OPEN FUNCTION -------------------------------------------------------------

  const openTransferDeal = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_100"),
      });
    } else {
      setIsTransfer(true);
    }
  };

  const openBulkEmail = () => {
    if (rowCount > 100) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_100"),
      });
    } else {
      setIsEmail(true);
    }
  };

  const openBulkInteraction = () => {
    if (rowCount > 10) {
      Swal.fire({
        icon: "error",
        title: t("DealList_oppss"),
        text: t("DealList_bulk_error_message_10"),
      });
    } else {
      setIsInteraction(true);
    }
  };

  // SEND FUNCTION -------------------------------------------------------------

  const sendTransferDeal = (values) => {
    var deal_id = rowData.map((record) => {
      return record.data.deal_id;
    });

    axios
      .get("/ws/ws_deal.php", {
        params: {
          task: "setBulkDealTransfer",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          source: "deal",
          type: deal_id.toString(),
          user_id: values.user,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setIsTransfer(false);
          onchangePipeline(pipelineData);
        } else {
          setIsTransfer(false);
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_alert_error_transfer_deal"),
          });
        }
      });
  };

  const sendBulkEmail = (values) => {
    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    axios
      .post("/ws/ws_email_post.php", {
        params: {
          task: "sendBulkEmail",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          emailTemplate: values.email_template_name,
          prospect: prospect_id.toString(),
          campaignTitle: values.campaign_title,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setIsEmail(false);
          onchangePipeline(pipelineData);
        } else {
          setIsEmail(false);
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_alert_error"),
          });
        }
      });
  };

  const sendSmartInteraction = (values) => {
    var deal_id = rowData.map((record) => {
      return record.data.deal_id;
    });

    var prospect_id = rowData.map((record) => {
      return record.data.prospect_id;
    });

    axios
      .post("/ws/ws_custom_interaction.php", {
        params: {
          task: "setBulkInteraction",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          source: "deal",
          type: deal_id.toString(),
          prospect: prospect_id.toString(),
          interaction_id: values.interaction,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          setIsInteraction(false);
          onchangePipeline(pipelineData);
        } else {
          setIsInteraction(false);
          Swal.fire({
            icon: "error",
            title: t("DealList_oppss"),
            text: t("DealList_alert_error"),
          });
        }
      });
  };

  const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];
  const sendCreateDeal = async (values) => {
    if (gamuda.includes(Number(session.company_id))) {
      const response = await axios.get("ws/ws_prospect.php", {
        params: {
          task: "getMobileExists",
          mobile: values.contact1,
          company: session.company_id,
          type: "",
          people: "",
          user: session.user_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        sendCreateDealGamuda(values);
      } else {
        if (Number(data.foundProspect) === 0) {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Contact number entered already exists in the system.",
          });
        } else {
          sendCreateDealGamuda(values);
        }
      }
    } else {
      sendCreateDealGlobal(values);
    }
  };

  const sendCreateDealGlobal = async (values) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);

      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipelineData.value === "All" ? 0 : pipelineData.value,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: "",
          organization_name: "",
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCreateDealGamuda = async (values) => {
    try {
      var customField = values.cf_deals.concat(values.cf_person);

      var cf_id = customField.map((record) => {
        return record.name;
      });

      var fd_values = customField.map((record) => {
        if (Array.isArray(record.fd_values)) {
          return record.fd_values.join("|");
        } else {
          return record.fd_values;
        }
      });

      const response = await axios.get("ws/ws_deal.php", {
        params: {
          task: "addDealWithCfv3",
          title: values.deal_title,
          value: values.deal_value,
          source: values.deal_source,
          pipeid: pipelineData.value === "All" ? 0 : pipelineData.value,
          close_date: values.expected_close_date ? moment(values.expected_close_date).format("YYYY-MM-DD") : "",
          prospect: values.prospect_id,
          prospect_name: values.prospect_name,
          organization: "",
          organization_name: "",
          contact: values.contact1,
          contact_id: values.contact_id,
          user: session.user_id,
          cs: stageDefaultData,
          cfid_arr: cf_id.join("[-0-]"),
          cfval_arr: fd_values.join("[-0-]"),
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company: session.company_id,
        },
      });

      const data = response.data;
      if (Number(data.status) === 0) {
        setModalAddDeal(false);
        setRefresh(Math.random());
        Swal.fire({
          icon: "success",
          title: t("DealList_successful"),
          text: t("DealList_success_deal_created"),
          timer: 1500,
        });
      } else {
        setModalAddDeal(false);
        Swal.fire({
          icon: "error",
          text: t("DealDetails_error_api_message"),
          timer: 1500,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendFilter = (values) => {
    setFilter(values);
    setIsFilter(false);
  };

  const sendClearFilter = () => {
    setFilter({
      search: "",
      ds: moment(startDate).format("YYYY-MM-DD"),
      de: moment(endDate).format("YYYY-MM-DD"),
      user: { label: "All User", value: "All" },
      business_unit: { label: "All Business Unit", value: "All" },
      deal_source: { label: "All Source", value: "All" },
      stage: { label: "All Stage", value: "All" },
      status: { label: "All Status", value: "All" },
      custom_field: dealCustomField.map((record) => ({
        cf_id: record.cf_id,
        name: record.cf_id,
        fd_values: "-",
      })),
    });
    setIsFilter(false);
  };

  const sendTransferDealToPipeline = async (values) => {
    setIsTransferPipe(false);
    setLoadingAction(true);
    try {
      var deal_id = rowData.map((record) => record.data.deal_id);
      var prospect_id = rowData.map((record) => record.data.prospect_id);
      const formData = new FormData();
      formData.append("task", "setBulkDealPipeTransfer");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("deal", deal_id.toString());
      formData.append("prospect", prospect_id.toString());
      formData.append("pipe_id", values.pipeline);

      const response = await axios.post("ws/ws_deal_pipe_transfer.php", formData);
      const data = response.data;
      if (Number(data.status) === 0) {
        setIsPipeline(false);
        onchangePipeline(values.pipelineArr);
      } else {
        iziToast.error({
          title: "Caution",
          message: "Something went wrong with your entry, please try again",
          timeout: 10000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsPipeline(true);
        setLoadingAction(false);
      }, 100);
    }
  };

  // GRID SETUP ----------------------------------------------------------------

  const containerStyle = useMemo(() => ({ width: "100%", height: isDeal && dealCount > 0 ? "80vh" : "0vh", paddingTop: 20 }), [isDeal, dealCount]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const CustomHeaderCheckbox = useCallback(
    (props) => {
      const { api } = props;

      const handleClick = () => {
        if (isIndeterminate) {
          if (api) {
            api.forEachNode((node) => node.setSelected(false));
          }
        } else {
          setIsChecked((prevChecked) => {
            const newChecked = !prevChecked;
            setIsIndeterminate(false);

            if (api) {
              api.forEachNode((node) => node.setSelected(newChecked));
            }

            return newChecked;
          });
        }
      };

      return (
        <div className="ag-header-cell" onClick={handleClick} style={{ padding: 0 }}>
          <div>
            {isChecked && <span className="ag-icon ag-icon-checkbox-checked"></span>}
            {!isChecked && !isIndeterminate && <span className="ag-icon ag-icon-checkbox-unchecked"></span>}
            {isIndeterminate && <span className="ag-icon ag-icon-checkbox-indeterminate"></span>}
          </div>
        </div>
      );
    },
    [isChecked, isIndeterminate]
  );

  const RenderOwner = useCallback((params) => {
    if (params.data) {
      const { deal_id, owner_id, owner_name } = params.data;
      return (
        <div className="d-flex align-items-center">
          <div id={`img-${deal_id.toString()}`}>
            <img className="d-block rounded-circle me-2" alt="owner_image" src={`${session.hostUrl}/${session.hostUrlType}/assets/account_avatar/${owner_id}.png?c=${Math.random()}`} width={30} height={30} onError={() => handleError(deal_id)} />
          </div>
          <div id={`avatar-${deal_id.toString()}`} className="d-none avatar me-2">
            <div className="avatar-img op-primary-color" style={{ width: 30, height: 30, backgroundColor: "#eee" }}>
              <div className="avatar-txt text-uppercase fs-5 text-light">{owner_name.charAt(0)}</div>
            </div>
          </div>
          {owner_name}
        </div>
      );
    }
  }, []);

  const RenderSource = useCallback((params) => {
    if (params.data) {
      const iconTitle = params.data.icon_title;
      const defaultImage = `${session.hostUrl}/${session.hostUrlType}/assets/source_cat/default.jpg`;

      if (!iconTitle) {
        return (
          <div>
            <img className="rounded-circle me-2" width={30} height={30} src={defaultImage} alt="defaultImg"></img>
            {params.data.ds_title}
            {params.data.utm_content ? ` | UTM: ${params.data.utm_content}` : ""}
          </div>
        );
      } else {
        const sourceImage = `${session.hostUrl}/${session.hostUrlType}/assets/source_cat/${iconTitle}`;
        return (
          <div>
            <img
              src={sourceImage}
              className="rounded-circle me-2"
              width={30}
              height={30}
              alt="defaultImg"
              onError={(e) => {
                e.target.src = defaultImage;
              }}
            />
            {params.data.ds_title}
            {params.data.utm_content ? ` | UTM: ${params.data.utm_content}` : ""}
          </div>
        );
      }
    } else if (params.data && params.data.ds_title) {
      return params.data.ds_title;
    } else {
      return "";
    }
  }, []);

  const handleError = (deal_id) => {
    const imgElement = document.getElementById(`img-${deal_id}`);
    const avatarElement = document.getElementById(`avatar-${deal_id}`);

    if (imgElement && avatarElement) {
      imgElement.classList.add("d-none");
      avatarElement.classList.remove("d-none");
    }
  };

  const dealColumn = [
    {
      headerComponent: CustomHeaderCheckbox,
      headerName: "",
      field: "",
      checkboxSelection: true,
      resizable: false,
      width: 50,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return "";
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: t("DealList_date"),
      field: "deal_date_time_create",
      resizable: true,
      width: 150,
      cellRenderer: (params) => {
        if (params.data) {
          return setRelativeTime(params.data.deal_date_time_create);
        }
      },
    },
    {
      headerName: t("DealList_owner"),
      field: "owner_name",
      width: 150,
      resizable: true,
      hide: false,
      cellRenderer: RenderOwner,
    },
    {
      headerName: t("DealList_title"),
      field: "deal_title",
      resizable: true,
      minWidth: 300,
      hide: false,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("DealList_source"),
      field: "ds_title",
      resizable: true,
      hide: false,
      cellRenderer: RenderSource,
    },
    { headerName: t("DealList_stage"), field: "cs_title", resizable: true, width: 200, hide: false },
    {
      headerName: t("DealList_status"),
      field: "deal_status",
      width: 180,
      resizable: true,
      hide: false,
      cellRenderer: function (params) {
        if (params.data) {
          var stageColor = "";
          var stageText = "";

          switch (params.data.deal_status) {
            case "new":
              stageColor = "bg-danger";
              stageText = t("DealList_contact_lead");
              break;
            case "inactive":
              stageColor = "bg-warning";
              stageText = t("DealList_follow_up");
              break;
            case "active":
              stageColor = "bg-success";
              stageText = t("DealList_progressing");
              break;
            default:
              stageColor = "bg-secondary";
              stageText = params.data.deal_status;
              break;
          }

          return (
            <>
              <div className="op-label circular text-light" style={{ backgroundColor: "#a333c8" }}>
                {params.data.prospect_lead_score}
              </div>
              <div className={`op-label text-light ${stageColor}`}>{stageText}</div>
            </>
          );
        }
      },
    },
    {
      headerName: t("DealList_last_updated"),
      field: "deal_status_last_updated",
      resizable: true,
      hide: false,
      cellRenderer: (params) => {
        if (params.data) {
          return setRelativeTime(params.data.deal_status_last_updated);
        }
      },
    },
    {
      headerName: t("DealList_latest_notes"),
      field: "notes",
      resizable: true,
      hide: false,
      cellRenderer: (params) => {
        if (params.data) {
          if (params.data.total_notes !== undefined && params.data.total_notes !== null) {
            if (params.data.total_notes === 1 && params.data.notes) {
              return <div dangerouslySetInnerHTML={{ __html: params.data.notes[0].note_content }}></div>;
            }
          }
        }
      },
    },
    {
      headerName: t("DealList_prospect_name"),
      field: "prospect_name",
      resizable: true,
      hide: false,
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("DealList_organization"),
      field: "organization_title",
      resizable: true,
      hide: false,
      onCellClicked: function (params) {
        if (params.data && params.data.organization_title) {
          window.open(`${session.origin}/organizations/${params.data.organization_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("DealList_value"),
      field: "deal_value",
      resizable: true,
      hide: false,
      cellRenderer: function (params) {
        if (params.data) {
          return setCurrency(params.data.deal_value);
        }
      },
    },
  ];

  const dealDataSource = useMemo(() => {
    if (!filterInit.current) return null;

    const cf_id = filter.custom_field.map((record) => record.cf_id);
    const cf_value = filter.custom_field.map((record) => record.fd_values);

    return {
      rowCount: undefined,
      getRows: async (params) => {
        axios
          .get("ws/ws_deallisting.php", {
            params: {
              task: 1,
              prospectname: filter.search,
              ds: filter.ds,
              de: filter.de,
              pipeid: pipelineData.value,
              user: filter.user.value === "All" ? 0 : filter.user.value,
              source: filter.deal_source.value === "All" ? 0 : filter.deal_source.value,
              stage: filter.stage.value === "All" ? 0 : filter.stage.value,
              dealstatus: filter.status.value === "All" ? 0 : filter.status.value,
              bu_id: filter.business_unit.value === "All" ? 0 : filter.business_unit.value,
              cfid: cf_id.join(","),
              cfvalue: cf_value.join(","),
              startRow: params.startRow,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((response) => {
            const data = response.data;
            if (data.status === 0) {
              if (Number(data.totalRecord) > 0) {
                setIsDeal(true);
                setInitLoad(true);
              } else {
                setIsDeal(false);
                setInitLoad(true);
              }
            } else {
              setIsDeal(false);
              setInitLoad(true);
            }

            setDealCount(Number(data.totalRecord));
            setTimeout(() => {
              var dealInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                dealInfo = data.record;
                totalRecord = Number(data.totalRecord);
                setIsDeal(true);
              } else {
                dealInfo = [];
                totalRecord = 0;
                setIsDeal(false);
              }

              var rowsThisPage = dealInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }

              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          });
      },
    };
  }, [filterInit.current, filter, pipelineData, session, refresh]);

  const dealColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      enableCellChangeFlash: false,
    };
  }, []);

  const dealRowId = useCallback(function (params) {
    return params.data.deal_id.toString();
  }, []);

  const onDealSelected = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;
    const allSelected = atLeastOneSelected && selectedNodes.length === params.api.getDisplayedRowCount();

    setIsChecked(allSelected);
    setIsIndeterminate(atLeastOneSelected && !allSelected);

    setIsRowSelected(atLeastOneSelected);
    setRowCount(atLeastOneSelected ? selectedNodes.length : 0);
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  // RENDER FUNCTION -----------------------------------------------------------

  const RenderBulkActionToggle = forwardRef(({ children, onClick }, ref) => {
    const openDropdown = (e) => {
      e.preventDefault();
      onClick(e);
    };

    const renderTooltip = (props) => <Tooltip {...props}>Bulk Action</Tooltip>;

    return (
      <OverlayTrigger placement="bottom" overlay={renderTooltip}>
        <button ref={ref} onClick={openDropdown} className="btn btn-light shadow-sm">
          <FontAwesomeIcon icon={faBullhorn} size="lg" />
          {children}
        </button>
      </OverlayTrigger>
    );
  });

  const RenderBulkActionMenu = forwardRef(({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <Dropdown.Header className="fw-semibold op-text-medium">{t("DealList_bulk_action")}</Dropdown.Header>
        {Number(session.role_id) === 1 && <Dropdown.Item onClick={openTransferDeal}>{t("DealList_transfer_all_selected")}</Dropdown.Item>}
        <Dropdown.Item onClick={openBulkEmail}>{t("DealList_email_all_selected")}</Dropdown.Item>
        <Dropdown.Item onClick={openBulkInteraction}>{t("DealList_bulk_note_or_activity")}</Dropdown.Item>
        <Dropdown.Item>{t("DealList_power_dialer")}</Dropdown.Item>
        <Dropdown.Item onClick={setIsTransferPipe}>Transfer Deals To Another Pipeline</Dropdown.Item>
      </div>
    );
  });

  const renderTooltip = (props, text) => <Tooltip {...props}>{text}</Tooltip>;

  useEffect(() => {
    if (!initialized.current) {
      getPipeline();
      getUser();
      getEmailTemplate();
      getSmartInteraction();
      getDealSource();
      getBusinessUnit();
      getCustomFieldDeal();

      var statusArray = [
        { label: t("DealList_all_status"), value: "All" },
        { label: t("DealList_progressing"), value: "active" },
        { label: t("DealList_contact_lead"), value: "new" },
        { label: t("DealList_follow_up"), value: "inactive" },
      ];

      setStatusFilterOpts(statusArray);
      initialized.current = true;
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  // RETURN JSX ----------------------------------------------------------------

  return (
    <Container fluid className="position-relative" style={{ height: "calc(100vh - 56px)" }}>
      {loading && loading2 ? (
        <Loader />
      ) : (
        <div className="py-4 px-4">
          <Row>
            <Col xxl={12}>
              <Stack direction="horizontal" gap={2}>
                <button type="button" className="btn op-button op-primary-color text-light shadow-sm" disabled={isDisabledCreateDeal} onClick={setModalAddDeal}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("DealList_deal")}
                </button>

                {isRowSelected ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle as={RenderBulkActionToggle}></Dropdown.Toggle>
                      <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="start" size={50} as={RenderBulkActionMenu} />
                    </Dropdown>

                    <h6 className="op-text-medium mt-2 me-2">{t("DealList_row_selected", { count: rowCount })}</h6>
                  </>
                ) : null}

                <h6 className="ms-auto op-text-medium mt-2 me-2"> {dealCount === 1 ? t("DealList_record_one", { count: dealCount }) : dealCount > 1 ? t("DealList_record_many", { count: dealCount }) : t("DealList_record_none")}</h6>
                {ísPipeline ? <FormSelect options={pipeline} valueDefault={pipelineData} onChange={onchangePipeline} shadow={true} border={false} placeholder="Select Pipeline" width={250} /> : null}

                <OverlayTrigger placement="bottom" overlay={(props) => renderTooltip(props, "Filters")}>
                  <button className="btn btn-light shadow-sm" onClick={setIsFilter}>
                    <FontAwesomeIcon icon={faFilterList} size="lg" />
                  </button>
                </OverlayTrigger>
              </Stack>
            </Col>
            <Col xxl={12}>
              <div style={containerStyle}>
                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                  <AgGridReact
                    ref={dealRef}
                    onSelectionChanged={onDealSelected}
                    columnDefs={dealColumn}
                    defaultColDef={dealColDef}
                    rowSelection="multiple"
                    rowModelType="infinite"
                    rowHeight={70}
                    cacheBlockSize={100}
                    cacheOverflowSize={2}
                    maxConcurrentDatasourceRequests={2}
                    infiniteInitialRowCount={10}
                    maxBlocksInCache={2}
                    getRowId={dealRowId}
                    pagination={true}
                    paginationPageSize={100}
                    suppressRowClickSelection={true}
                    animateRows={true}
                    datasource={dealDataSource}
                    enableCellTextSelection={true}
                  />
                </div>
              </div>

              {(!isDeal || dealCount === 0) && initLoad && <NoRecord message={t("DealList_no_record_message")} description={t("DealList_no_record_description")} width={300} />}
            </Col>
          </Row>
        </div>
      )}

      <Modal show={modalAddDeal} onHide={setModalAddDeal} size="lg">
        <Formik validationSchema={newDealSchema} onSubmit={sendCreateDeal} initialValues={addDeal}>
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>{t("DealList_add_deal")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col className="w-50 px-4 py-3">
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DeaList_contact_person")}</Form.Label>
                      <FormSelectCreatable
                        name="prospect_id"
                        loadOptions={getProspect}
                        isLoading={isFindProspect}
                        isInvalid={errors.prospect_id && touched.prospect_id}
                        onChange={(info) => {
                          handleChange({
                            target: { name: "prospect_name", value: info.label },
                          });
                          handleChange({
                            target: { name: "prospect_id", value: info.value },
                          });

                          if (Number(info.value) > 0) {
                            handleChange({
                              target: { name: "contact1", value: info.contact1 },
                            });

                            handleChange({
                              target: { name: "contact_id", value: info.contact_id },
                            });

                            if (info.contact1) {
                              handleChange({
                                target: { name: "contact1_disabled", value: true },
                              });
                            } else {
                              handleChange({
                                target: { name: "contact1_disabled", value: false },
                              });
                            }
                          } else {
                            handleChange({
                              target: { name: "contact1", value: "" },
                            });

                            handleChange({
                              target: { name: "contact_id", value: "" },
                            });

                            handleChange({
                              target: { name: "contact1_disabled", value: false },
                            });
                          }
                        }}
                      />
                      {errors.prospect_id && touched.prospect_id && <div className="op-error-message">{errors.prospect_id}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_deal_title")}</Form.Label>
                      <Form.Control name="deal_title" type="text" isInvalid={errors.deal_title && touched.deal_title} onChange={handleChange("deal_title")} />
                      {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_deal_source")}</Form.Label>
                      <FormSelect name="deal_source" options={dealSource} isSearchable={true} placeholder="" shadow={false} onChange={(info) => handleChange("deal_source")(info.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_deal_value")}</Form.Label>
                      <Form.Control name="deal_value" type="text" onChange={(e) => handleChange("deal_value")(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_expected_close_date")}</Form.Label>
                      <FormDate name="expected_close_date" onChange={(date) => handleChange("expected_close_date")(date)} />
                    </Form.Group>
                    {customFieldDeal.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={record.cf_id}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_deals[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_deals];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_deals", updatedCustomFields);
                        }}
                      />
                    ))}
                  </Col>
                  <Col className="w-50 px-4 py-3">
                    <div className="op horizontal-start divider">{t("DealList_person")}</div>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("DealList_contact_number")}</Form.Label>
                      <Form.Control name="contact1" value={values.contact1} disabled={values.contact1_disabled} type="text" isInvalid={errors.contact1 && touched.contact1} onChange={handleChange("contact1")} />
                      {errors.contact1 && touched.contact1 && <div className="op-error-message">{errors.contact1}</div>}
                    </Form.Group>
                    {customFieldPerson.map((record, index) => (
                      <FormCustomField
                        key={record.cf_id}
                        id={record.cf_id}
                        label={record.cf_label}
                        options={record.cf_value}
                        type={record.ctf_title}
                        name={record.cf_id}
                        placeholder=""
                        firstOptions={false}
                        valueDefault={false}
                        isClearable={true}
                        value={values.cf_person[index].fd_values}
                        onChange={(info) => {
                          const updatedCustomFields = [...values.cf_person];
                          if (record.ctf_title === "Select") {
                            updatedCustomFields[index].fd_values = info.value;
                          } else if (record.ctf_title === "Radio") {
                            const { value, checked } = info.target;
                            updatedCustomFields[index].fd_values = checked ? value : "";
                          } else if (record.ctf_title === "Checkbox") {
                            const { value, checked } = info.target;
                            if (checked) {
                              updatedCustomFields[index].fd_values.push(value);
                            } else {
                              const indexToRemove = updatedCustomFields[index].fd_values.indexOf(value);
                              if (indexToRemove !== -1) {
                                updatedCustomFields[index].fd_values.splice(indexToRemove, 1);
                              }
                            }
                          } else if (record.ctf_title === "Date") {
                            updatedCustomFields[index].fd_values = info;
                          } else {
                            updatedCustomFields[index].fd_values = info.target.value;
                          }

                          setFieldValue("cf_person", updatedCustomFields);
                        }}
                      />
                    ))}
                  </Col>
                  <div className="op vertical fluid divider"></div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("DealList_submit")}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Offcanvas show={isTransfer} placement={"end"} onHide={setIsTransfer}>
        <Formik
          validationSchema={transferDealSchema}
          onSubmit={sendTransferDeal}
          initialValues={{
            user: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_transfer_deal")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })} </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="user"
                    value={values.user}
                    onChange={(info) => {
                      handleChange("user")(info.value);
                    }}
                    isInvalid={errors.user && touched.user}
                    options={user}
                    placeholder={t("DealList_select_user")}
                  />
                  {errors.user && touched.user && <div className="op-error-message">{errors.user}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("DealList_bulk_confirmation_placeholder")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isEmail} placement={"end"} onHide={setIsEmail}>
        <Formik
          validationSchema={emailSchema}
          onSubmit={sendBulkEmail}
          initialValues={{
            email_template_name: "",
            campaign_title: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_bulk_email")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}</h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="email_template_name"
                    onChange={(info) => {
                      handleChange("email_template_name")(info.value);
                    }}
                    isInvalid={errors.email_template_name && touched.email_template_name}
                    options={emailTemplate}
                    placeholder={t("DealList_select_email_template")}
                  />
                  {errors.email_template_name && touched.email_template_name && <div className="op-error-message">{errors.email_template_name}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control name="campaign_title" value={values.campaign_title} onChange={handleChange("campaign_title")} isInvalid={errors.campaign_title && touched.campaign_title} type="text" placeholder={t("DealList_campaign_title")} />
                  {errors.campaign_title && touched.campaign_title && <div className="op-error-message">{errors.campaign_title}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("DealList_bulk_confirmation_placeholder")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isInteraction} placement={"end"} onHide={setIsInteraction}>
        <Formik
          validationSchema={smartInteractionSchema}
          onSubmit={sendSmartInteraction}
          initialValues={{
            interaction: "",
            confirmation: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_smart_interaction")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })}</h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="interaction"
                    onChange={(info) => {
                      handleChange("interaction")(info.value);
                    }}
                    isInvalid={errors.interaction && touched.interaction}
                    options={interaction}
                    placeholder={t("DealList_select_smart_interaction_template")}
                  />
                  {errors.interaction && touched.interaction && <div className="op-error-message">{errors.interaction}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("DealList_bulk_confirmation_placeholder")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isFilter} placement={"end"} onHide={setIsFilter}>
        <Formik onSubmit={sendFilter} initialValues={filter}>
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("DealList_filters")}</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Form.Group className="mb-3">
                  <Form.Control type="text" value={values.search} placeholder={t("DealList_search_deal_prospect_organization")} onChange={handleChange("search")} />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="date"
                    value={values.ds}
                    onChange={(e) => {
                      var newDate = new Date(e.target.value);
                      var momentDate = moment(newDate).format("YYYY-MM-DD");
                      handleChange("ds")(momentDate);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    type="date"
                    value={values.de}
                    onChange={(e) => {
                      var newDate = new Date(e.target.value);
                      var momentDate = moment(newDate).format("YYYY-MM-DD");
                      handleChange("de")(momentDate);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={userFilterOpts}
                    valueDefault={values.user}
                    onChange={(e) => {
                      setFieldValue("user", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={businessUnitFilterOpts}
                    valueDefault={values.business_unit}
                    onChange={(e) => {
                      setFieldValue("business_unit", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={dealSourceFilterOpts}
                    valueDefault={values.deal_source}
                    onChange={(e) => {
                      setFieldValue("deal_source", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={stageFilterOpts}
                    valueDefault={values.stage}
                    onChange={(e) => {
                      setFieldValue("stage", e);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <FormSelect
                    options={statusFilterOpts}
                    valueDefault={values.status}
                    onChange={(e) => {
                      setFieldValue("status", e);
                    }}
                  />
                </Form.Group>

                {dealCustomField.map((record, index) => (
                  <FormCustomField
                    key={record.cf_id}
                    id={record.cf_id}
                    label={record.cf_label}
                    options={record.cf_value}
                    type={record.ctf_title}
                    placeholder=""
                    firstOptions={true}
                    value={values.custom_field[index].fd_values}
                    onChange={(info) => {
                      const updatedCustomFields = [...values.custom_field];

                      if (record.ctf_title === "Select") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.value,
                        };
                      } else if (record.ctf_title === "Radio") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.checked ? info.target.value : "",
                        };
                      } else if (record.ctf_title === "Checkbox") {
                        const { value, checked } = info.target;
                        const currentValues = typeof values.custom_field[index] === "string" ? values.custom_field[index] : "";
                        const updatedValues = [...currentValues.split(",")];

                        if (checked) {
                          updatedValues.push(value);
                        } else {
                          const indexToRemove = updatedValues.indexOf(value);
                          if (indexToRemove !== -1) {
                            updatedValues.splice(indexToRemove, 1);
                          }
                        }

                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: updatedValues.join(","),
                        };
                      } else {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.value,
                        };
                      }

                      setFieldValue("custom_field", updatedCustomFields);
                    }}
                  />
                ))}
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <div className="d-flex justify-content-center align-items-center w-100">
                  <button type="submit" className="btn op-button op-primary-color text-light me-2">
                    {t("DealList_search")}
                  </button>
                  <button type="button" className="btn bg-body-secondary" onClick={sendClearFilter}>
                    {t("DealList_clear")}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      <Offcanvas show={isTransferPipe} placement={"end"} onHide={setIsTransferPipe}>
        <Formik validationSchema={transferPipeSchema} onSubmit={sendTransferDealToPipeline} initialValues={{ pipeline: "", pipelineArr: { label: "", value: "" }, confirmation: "" }}>
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Transfer Deal to Another Pipeline</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <h6 className="op-text-bigger fw-semibold"> {rowCount > 1 ? t("DealList_bulk_record_many", { count: rowCount }) : t("DealList_bulk_record_one", { count: rowCount })} </h6>
                <Form.Group className="mb-3">
                  <FormSelect
                    name="pipeline"
                    placeholder="Select Pipeline"
                    value={values.pipeline}
                    isInvalid={errors.pipeline && touched.pipeline}
                    options={pipelineList.filter((record) => Number(record.value) !== Number(pipelineData.value))}
                    onChange={(info) => {
                      setFieldValue("pipelineArr", info);
                      handleChange("pipeline")(info.value);
                    }}
                  />
                  {errors.pipeline && touched.pipeline && <div className="op-error-message">{errors.pipeline}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("DealList_bulk_confirmation_label")}</Form.Label>
                  <Form.Control name="confirmation" value={values.confirmation} onChange={handleChange("confirmation")} isInvalid={errors.confirmation && touched.confirmation} type="text" placeholder={t("DealList_bulk_confirmation_placeholder")} />
                  {errors.confirmation && touched.confirmation && <div className="op-error-message">{errors.confirmation}</div>}
                </Form.Group>
              </Offcanvas.Body>
              <div className="offcanvas-footer">
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("DealList_perform_action")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Offcanvas>

      {loadingAction && <LoaderAction />}
    </Container>
  );
};

export default DealList;
