import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Outlet, useNavigate, useLocation, useParams } from "react-router-dom";
import Sidebar from "../includes/Sidebar";
import Header from "../includes/Navbar";
import { useAuth } from "./AuthContext";
import { routeLevelAccess } from "./RouteAccess";

const PrivateRoutes = () => {
  const { session } = useAuth();
  const [access, setAccess] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    if (!session) {
      navigate("/", { replace: true });
    }

    setAccess(routeLevelAccess(location.pathname, session, params));
  }, [location]);

  const logout = () => {
    navigate("/", { replace: true });
  };

  const forbidden = () => {
    navigate("/forbidden", { replace: true });
  };

  return (
    <>
      {!session ? (
        logout()
      ) : !access ? (
        forbidden()
      ) : (
        <div className="vh-100 d-flex" style={{ background: "#f3f3f3" }}>
          <Sidebar />
          <div className="content w-100">
            <Header />
            <Container fluid style={{ padding: 0 }}>
              <Outlet />
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateRoutes;
