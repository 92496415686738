import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
import { useTranslation } from "react-i18next";

const TeamUserSetting = ({ user_tag_id }) => {

    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [isGrid, setIsGrid] = useState(false);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isAdd, setIsAdd] = useState(false);

    const [userList, setUserList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);

    const getAllUser = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var list = data.record.map(data => {
                    return {label: data.user_name, value: data.user_id};
                });

                setUserList(list);
            }else{
                setUserList([]);
            }
        });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "78vh" : "0vh", paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
          cellDataType: true,
        };
    }, []);

    const [reportColumn] = useState([
        {
            headerName: t('SettingTeamUser_headername_1'),
            field: "user_name",
            resizable: true,
            flex: 1
        },
        {
            headerName: t('SettingTeamUser_headername_2'),
            field: "user_email",
            resizable: true,
            flex: 2
        },
        {
            headerName: t('SettingTeamUser_headername_3'),
            field: "user_contact",
            resizable: true,
            flex: 2
        },
        {
            headerName: t('SettingTeamUser_headername_4'),
            field: "role_title",
            resizable: true,
            width: 200
        },
        {
            headerName: t('SettingTeamUser_headername_5'),
            resizable: true,
            width: 80,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data.user_id)}}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getGridData = () => {
        
        axios.get('ws/ws_user.php',{
            params: {
                task: "4a",
                user_tag: user_tag_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                var selected = data.record.map(data => {
                    return data.user_id;
                });

                setSelectedUser(selected);

                setRecordFound(data.record.length);
                setRowData(data.record);
                setIsGrid(true);
                setIsLoading(false);
            }else{
                setRecordFound(0);
                setRowData([]);
                setIsGrid(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setRecordFound(0);
            setRowData([]);
            setIsGrid(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getAllUser();
            getGridData();
        }
    }); // eslint-disable-line react-hooks/exhaustive-deps

    const addSchema = yup.object().shape({
        user_id: yup.string().required(t('SettingTeamUser_required_field'))
    });

    const sendAdd = (values) => {
        axios.get('ws/ws_user.php',{
            params:{
                task: 'addUserTagUser',
                user_tag_id: user_tag_id,
                user_id: values.user_id,
                company_id: session.company_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t('SettingTeamUser_success_title'),
                    text: t('SettingTeamUser_alert_success_add_text'),
                    timer: 1500
                }).then(result => {
                    setIsLoading(true);
                    setIsAdd(false);
                    getGridData();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: t('SettingTeamUser_alert_failed_title'),
                    text: t('SettingTeamUser_alert_failed_text')
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: t('SettingTeamUser_alert_warning_title'),
            text: t('SettingTeamUser_alert_warning_text'),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('SettingTeamUser_alert_warning_confirmText')
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ws/ws_user.php',{
                    params:{
                        task: "RemoveUser",
                        user_id: id,
                        user_tag_id: user_tag_id,
                        company_id: session.company_id,
                        edit_by: session.user_id,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
        
                    if (data.status === 0) {
                        Swal.fire({
                            icon: "success",
                            title: t('SettingTeamUser_success_title'),
                            text: t('SettingTeamUser_success_text'),
                            timer: 1500
                        }).then(result => {
                            setIsLoading(true);
                            getGridData();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t('SettingTeamUser_alert_failed_title'),
                            text: t('SettingTeamUser_alert_failed_text')
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    })
                });
            }
        });
    }

    return(
        <div>
            {isLoading ? (<Loader/>):(
                <Container fluid>
                    <div className="mt-3 mb-3">
                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-center align-items-center">
                            <button className="btn op-button op-primary-color text-light" onClick={setIsAdd}>{t('SettingTeamUser_button_add')}</button>
                            <div className="px-2 ms-auto">Result: {recordFound === 0 ? t('SettingTeamUser_record_no'):t('SettingTeamUser_record', {count: recordFound})}</div>
                        </Stack>
                    </div>
                    <div className="mb-3" style={containerStyle}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                            <AgGridReact
                                ref={gridRef}
                                columnDefs={reportColumn}
                                rowData={rowData}
                                defaultColDef={reportColDef}
                                rowHeight={100}
                                pagination={false}
                                // paginationPageSize={30}
                                // paginationPageSizeSelector={false}
                            />
                        </div>
                    </div>
                    {!isGrid && <NoRecord message="No record at the moment." width={200}/>}

                    <Modal show={isAdd} onHide={setIsAdd}>
                        <Formik
                            validationSchema={addSchema}
                            onSubmit={sendAdd}
                            initialValues={
                                {
                                    user_id: ""
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t('SettingTeamUser_modalHeader_title')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group>
                                            <Form.Label>{t('SettingTeamUser_modalBody_title_1')}</Form.Label>
                                            <FormSelect
                                                placeholder={t('SettingTeamUser_modalBody_title_1_placeholder')}
                                                options={userList.filter(option => selectedUser.includes(option.value) === false)}
                                                isSearchable={true}
                                                isClearable={true}
                                                isInvalid={errors.user_id && touched.user_id}
                                                onChange={(e) => {handleChange('user_id')(e ? e.value:"")}}
                                            />
                                            {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">{t('SettingTeamUser_modalFooter_submit')}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </Container>
            )}
        </div>
    );

}

export default TeamUserSetting;