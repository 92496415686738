import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip as BootstrapTooltip, Offcanvas, Card } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil, faPlus, faRightLeft, faCopy, faArrowsRotate } from "@fortawesome/pro-duotone-svg-icons";
import { PieChart, Pie, Cell, Tooltip as RechartsTooltip, Label, Legend, ResponsiveContainer } from "recharts";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect, FormDate } from "../includes/FormCustom";
import React from 'react';
import ReactDom from 'react-dom';
import { ReactSortable } from "react-sortablejs";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";


const format = 'h:mm a';
const now = moment().hour(0).minute(0);

const MySwal = withReactContent(Swal);

const EventRsvp = () => {
    registerPlugin(FilePondPluginImagePreview);
    registerPlugin(FilePondPluginPdfPreview);
    registerPlugin(FilePondPluginFileValidateType);
    const { t } = useTranslation();
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [eventlist, setEventList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [chatInstance, setChatInstance] = useState([]);
    const [surveyForm, setSurveyForm] = useState([]);
    const [whatsappRsvpMessage, setWhatsappRsvpMessage] = useState([]);
    const [dateRange, setdateRange] = useState({
        ds: "",
        de: "",
    });
    const [isLoadingChatInstances, setIsLoadingChatInstances] = useState(false);
    const [isLoadingSurveyForms, setIsLoadingSurveyForms] = useState(false);
    const [isLoadingWhatsappTemplates, setIsLoadingWhatsappTemplates] = useState(false);
    const [isLoadingTeam, setIsLoadingTeam] = useState(false);
    const [CFList, setCFList] = useState([]);
    const [selCFList, setSelCFList] = useState([]);
    const [team, setTeam] = useState([]);
    const [source, setSource] = useState([]);
    const [bannerImg, setBannerImg] = useState("");

    const [eventLeadData, setEventLeadData] = useState([]);
    const [eventRegisteredData, setEventRegisteredData] = useState([]);
    const [eventAttendedData, setEventAttendedData] = useState([]);
    const [eventSurveyData, setEventSurveyData] = useState([]);

    const [showLeadEditModal, setShowLeadEditModal] = useState(false);
    const [customFieldInfo, setCustomFieldInfo] = useState([]);
    const [showRegisteredEditModal, setShowRegisteredEditModal] = useState(false);
    const [showAttendedEditModal, setShowAttendedEditModal] = useState(false);
    const [showSurveyEditModal, setShowSurveyEditModal] = useState(false);
    const [customFieldInfoSurvey, setCustomFieldInfoSurvey] = useState([]);
    const [customFieldInfoSurveyStat, setCustomFieldInfoSurveyStat] = useState([]);


    const ConsentType = [
        { label: "PDPA", value: "PDPA" }
    ]

    // Validation Schema (add)
    const validationSchema = yup.object().shape({
        form_title: yup.string().required("This field is required")
    });

    // Validation Schema (edit)
    const validationSchemaEdit = yup.object().shape({
        form_title: yup.string().required("This field is required"),
        slug: yup.string().required("This field is required"),
        startDate: yup.string().required("This field is required"),
        endDate: yup.string().required("This field is required"),
        startTime: yup.string().required("This field is required"),
        endTime: yup.string().required("This field is required"),
        event_location: yup.string().required("This field is required"),
        chat_instance_id: yup.string().required("This chat instance field is required"),
        whatsapp_rsvp_message: yup.string().required("This field is required"),
        whatsapp_qr_name: yup.string().required("This field is required"),
        banner_img: yup.string().required("This field is required"),
        // whatsapp_survey_message: yup.string().required("This field is required")

    })


    // GET FUNCTION --------------------------------------------------------

    const getEventList = async () => {
        try {
            const url = session.hostUrlType === 'cloud' ? "https://www.nexcrmapis.com/form/registration_api.php" : "https://www.nexcrmapis.com/form/registration_api_staging.php";

            const response = await axios.get(url, {
                params: {
                    ctoken: session.company_token,
                    mode: 'Event',
                    task: "getAllFormsV3",
                    // task: "getAllFormsV2",
                    utoken: session.user_ac_token,
                }
            })

            const data = response.data;

            if (data.status === 0) {
                const sortedData = data.record.sort((a, b) => new Date(b.date_time_create) - new Date(a.date_time_create));
                setEventList(sortedData);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    // SET FUNCTION -----------------------------------------
    const setDateFormat = (params) => {
        const dateTime = params.data.date_time_create;
        const providedDate = moment(dateTime);

        if (dateTime === "0000-00-00 00:00:00") {
            return "-";
        } else {
            return providedDate.format("ll");
        }
    };

    const getImageCell = (params) => {
        const image = `${session.hostUrl}/form/${params.data.banner_img}`
        return (
            <span className="d-flex h-100 w-100 justify-content-center align-items-center">
                {params.data.banner_img && (
                    <img
                        src={image}
                        className="logo"
                        style={{ width: '90%' }}
                    />
                )}
            </span>
        )
    }

    const COLORSresponse = ['#39e75f', '#ececec'];

    const getFormSubmissionChart = (params) => {
        let formSubmission = Number(params.data.form_submission);

        // Validate and format the formSubmission value
        if (isNaN(formSubmission) || formSubmission < 0) {
            formSubmission = 0;
        }

        // Calculate the deduction value as 2 times the form_submission value
        const deductionValue = 2 * formSubmission;
        const remainingValue = deductionValue > 100 ? deductionValue : 100; // Set a minimum remaining value

        const data = [
            { name: 'Registered', value: formSubmission },
            { name: 'Remaining', value: remainingValue - formSubmission }
        ];

        return (
            <PieChart width={80} height={80}>
                <Pie
                    data={data}
                    cx={40}
                    cy={40}
                    innerRadius={20}
                    outerRadius={30}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                    ))}
                    <Label
                        value={data[0].value}
                        position="center"
                        fill="#000"
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                    />
                </Pie>
            </PieChart>
        );
    }

    const getEventAttendedChart = (params) => {
        let eventAttended = Number(params.data.event_attended);

        // Validate and format the eventAttended value
        if (isNaN(eventAttended) || eventAttended < 0) {
            eventAttended = 0;
        }

        // Calculate the deduction value as 2 times the event_attended value
        const deductionValue = 2 * eventAttended;
        const remainingValue = deductionValue > 100 ? deductionValue : 100; // Set a minimum remaining value

        const data = [
            { name: 'Attended', value: eventAttended },
            { name: 'Remaining', value: remainingValue - eventAttended }
        ];

        return (
            <PieChart width={80} height={80}>
                <Pie
                    data={data}
                    cx={40}
                    cy={40}
                    innerRadius={20}
                    outerRadius={30}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                    ))}
                    <Label
                        value={data[0].value}
                        position="center"
                        fill="#000"
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                    />
                </Pie>
            </PieChart>
        );
    }

    const getSurveySubmittedChart = (params) => {
        let surveySubmitted = Number(params.data.survey_submitted);

        // Validate and format the surveySubmitted value
        if (isNaN(surveySubmitted) || surveySubmitted < 0) {
            surveySubmitted = 0;
        }

        // Calculate the deduction value as 2 times the survey_submitted value
        const deductionValue = 2 * surveySubmitted;
        const remainingValue = deductionValue > 100 ? deductionValue : 100; // Set a minimum remaining value

        const data = [
            { name: 'Submitted', value: surveySubmitted },
            { name: 'Remaining', value: remainingValue - surveySubmitted }
        ];

        return (
            <PieChart width={80} height={80}>
                <Pie
                    data={data}
                    cx={40}
                    cy={40}
                    innerRadius={20}
                    outerRadius={30}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORSresponse[index % COLORSresponse.length]} />
                    ))}
                    <Label
                        value={data[0].value}
                        position="center"
                        fill="#000"
                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                    />
                </Pie>
            </PieChart>
        );
    }

    const getPublishedResult = (params) => {
        const getNewPublishedValue = () => {
            return params.data.published === 'Yes' ? 'No' : 'Yes';
        };

        const handleClick = () => {
            const newPublished = getNewPublishedValue();

            const newData = eventlist.map(item => {
                if (item.reg_form_id === params.data.reg_form_id) {
                    return { ...item, published: newPublished };
                }
                return item;
            });

            setEventList(newData);
        };

        const handleButtonClick = () => {
            const newPublished = getNewPublishedValue();

            axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_setting.php`, {
                    params: {
                        ctoken: session.company_token,
                        published: newPublished,  // Use the new value
                        reg_form_id: params.data.reg_form_id,
                        task: "setFormsStatus",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    if (res.data.status == '0') {
                        handleClick();
                    }
                })
                .catch(error => {
                    console.error("There was an error updating the form status:", error);
                });
        };

        const isPublished = params.data.published === 'Yes';
        const buttonLabel = isPublished ? 'Published' : 'Draft';
        const buttonVariant = isPublished ? 'success' : 'secondary';

        return (
            <Button
                variant={buttonVariant}
                onClick={handleButtonClick}
                style={{ padding: "0.2rem 0.5rem", fontSize: "0.8rem" }}
            >
                {buttonLabel}
            </Button>
        );
    };

    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        { headerName: t("Event_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        { headerName: t("Event_date"), field: "date_time_create", width: 150, cellRenderer: setDateFormat },
        {
            headerName: t("Event_title"),
            field: "form_title",
            cellClass: "cursor-pointer",
            width: 280,
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' },
            onCellClicked: function (params) {
                const slug = params.data.url_title;
                if (!slug) {
                    Swal.fire({
                        icon: 'warning',
                        title: t("Event_missing_slug"),
                        text: t("Event_please_add_a_slug_in_the_edit_section"),
                        timer: 1500
                    });
                } else {
                    const url = session.hostUrlType == 'cloud_staging'
                        ? `${session.hostUrl}/form/e4/${slug}/${params.data.form_hash}/`
                        : `${session.hostUrl}/form/e3/${slug}/${params.data.form_hash}/`;
                    window.open(url, '_blank');
                }
            }
        },
        {
            headerName: t("Event_image"),
            field: "",
            headerClass: "center",
            cellRenderer: getImageCell,
            width: 350,
        },
        {
            headerName: t("Event_slug"),
            field: "url_title",
            width: 300,
            cellClass: "cursor-pointer",
            cellStyle: { 'white-space': 'normal', 'word-wrap': 'break-word', 'overflow': 'hidden', 'line-height': '1.2' },
            onCellClicked: function (params) {
                const slug = params.data.url_title;
                if (!slug) {
                    Swal.fire({
                        icon: 'warning',
                        title: t("Event_missing_slug"),
                        text: t("Event_please_add_a_slug_in_the_edit_section"),
                        timer: 1500
                    });
                } else {
                    const url = session.hostUrlType == 'cloud_staging'
                        ? `${session.hostUrl}/form/e4/${slug}/${params.data.form_hash}/`
                        : `${session.hostUrl}/form/e3/${slug}/${params.data.form_hash}/`;
                    window.open(url, '_blank');
                }
            }
        },
        { headerName: t("Event_view_count"), headerClass: "center", field: "view_count", width: 150, cellClass: "center" },
        {
            headerName: t("Event_lead_assigned"),
            field: "event_lead_assigned",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            cellStyle: { cursor: "pointer" },
            onCellClicked: function (params) {
                if (params.data) {
                    var formId = params.data.reg_form_id;
                    getEventLeadData(formId);
                }
            }
        },
        {
            headerName: t("Event_registered"),
            field: "form_submission",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            cellStyle: { cursor: "pointer" },
            cellRenderer: getFormSubmissionChart,
            onCellClicked: function (params) {
                if (params.data) {
                    var formId = params.data.reg_form_id;
                    getEventRegisteredData(formId);
                }
            }
        },
        {
            headerName: t("Event_attended"),
            field: "event_attended",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            cellStyle: { cursor: "pointer" },
            cellRenderer: getEventAttendedChart,
            onCellClicked: function (params) {
                if (params.data) {
                    var formId = params.data.reg_form_id;
                    getEventAttendedData(formId);
                }
            }
        },
        {
            headerName: t("Event_survey_submitted"),
            field: "survey_submitted",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            cellStyle: { cursor: "pointer" },
            cellRenderer: getSurveySubmittedChart,
            onCellClicked: function (params) {
                if (params.data) {
                    var surveyId = params.data.associated_survey;
                    getEventSurveyData(surveyId);
                }
            }
        },
        {
            headerName: t("Event_published"),
            field: "published",
            width: 120,
            cellRenderer: getPublishedResult,
            pinned: "right",
            headerClass: "center",
            cellClass: "center"
        },
        {
            headerName: t("Event_action"),
            field: "",
            width: 150,
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            cellRenderer: function (params) {
                const isPublished = params.data.published === 'Yes';

                const handleCopy = (params) => {
                    let textToCopy = '';

                    if (session.hostUrlType == 'cloud_staging') {
                        textToCopy = `${session.hostUrl}/form/e4/${params.data.url_title || ""}/${params.data.form_hash}/`;
                    } else {
                        textToCopy = `${session.hostUrl}/form/e3/${params.data.url_title || ""}/${params.data.form_hash}/`;
                    }

                    navigator.clipboard.writeText(textToCopy)
                        .then(() => {
                            Swal.fire({
                                icon: 'success',
                                title: t("Event_copied"),
                                text: t("Event_the_link_has_been_copied_to_the_clipboard"),
                                timer: 1500
                            });
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: 'error',
                                title: t("Event_oops"),
                                text: t("Event_failed_to_copy_the_link"),
                                timer: 1500
                            });
                        });
                };

                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<BootstrapTooltip>{t("Event_copy")}</BootstrapTooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    data-action="copy"
                                    className="fontAwesomeIcon"
                                    size="xl"
                                    icon={faCopy}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCopy(params)}
                                />
                            </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="top"
                            overlay={<BootstrapTooltip>{t("Event_delete")}</BootstrapTooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    data-action="trash"
                                    className={`fontAwesomeIcon ms-3 ${isPublished ? 'disabled' : ''}`}
                                    size="xl"
                                    icon={faTrash}
                                    style={{ pointerEvents: isPublished ? 'none' : 'auto', opacity: isPublished ? 0.5 : 1 }}
                                />
                            </span>
                        </OverlayTrigger>

                        <OverlayTrigger
                            placement="top"
                            overlay={<BootstrapTooltip>{t("Event_edit")}</BootstrapTooltip>}
                        >
                            <span>
                                <FontAwesomeIcon
                                    data-action="edit"
                                    className={`fontAwesomeIcon ms-3 ${isPublished ? 'disabled' : ''}`}
                                    size="xl"
                                    icon={faPencil}
                                    style={{ pointerEvents: isPublished ? 'none' : 'auto', opacity: isPublished ? 0.5 : 1 }}
                                />
                            </span>
                        </OverlayTrigger>
                    </div>
                );

                // return (
                //     <div className="d-flex">
                //         <OverlayTrigger
                //             placement="top"
                //             overlay={<BootstrapTooltip>{t("Event_copy")}</BootstrapTooltip>}
                //         >
                //             <FontAwesomeIcon
                //                 data-action="copy"
                //                 className="fontAwesomeIcon"
                //                 size="xl"
                //                 icon={faCopy}
                //                 style={{ cursor: 'pointer' }}
                //                 onClick={() => handleCopy(params)}
                //             />
                //         </OverlayTrigger>
                //         <OverlayTrigger
                //             placement="top"
                //             overlay={<BootstrapTooltip>{t("Event_delete")}</BootstrapTooltip>}
                //         >
                //             <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon ms-3" size="xl" icon={faTrash} />
                //         </OverlayTrigger>
                //         <OverlayTrigger
                //             placement="top"
                //             overlay={<BootstrapTooltip>{t("Event_edit")}</BootstrapTooltip>}
                //         >
                //             <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                //         </OverlayTrigger>
                //     </div>
                // );
            }
        },
    ];

    // GET MODAL DATA -------------------------------------------------

    const getEventLeadData = async (form_id) => {
        try {
            const res = await axios.get("ws/ws_event_rsvp.php", {
                params: {
                    ctoken: session.company_token,
                    reg_form_id: form_id,
                    task: "leadAssignedList",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setEventLeadData(data.record);
                setShowLeadEditModal(true);
            } else {
                setEventLeadData([]);
                setShowLeadEditModal(true)
            }
        } catch {

        }
    }

    const getEventRegisteredData = async (form_id) => {
        try {
            const res = await axios.get("ws/ws_event_rsvp.php", {
                params: {
                    ctoken: session.company_token,
                    reg_form_id: form_id,
                    task: "registeredList",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setEventRegisteredData(data.record);
                setCustomFieldInfo(data.cf_info);
                setShowRegisteredEditModal(true);
            } else {
                setEventRegisteredData([]);
                setCustomFieldInfo([]);
                setShowRegisteredEditModal(true)
            }
        } catch {

        }
    }

    const getEventAttendedData = async (form_id) => {
        try {
            const res = await axios.get("ws/ws_event_rsvp.php", {
                params: {
                    ctoken: session.company_token,
                    reg_form_id: form_id,
                    task: "attendedList",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setEventAttendedData(data.record);
                setShowAttendedEditModal(true);
            } else {
                setEventAttendedData([]);
                setShowAttendedEditModal(true)
            }
        } catch {

        }
    }

    const getEventSurveyData = async (form_id) => {
        try {
            const res = await axios.get("ws/ws_survey.php", {
                params: {
                    ctoken: session.company_token,
                    reg_form_id: form_id,
                    task: "submittedList",
                    utoken: session.user_ac_token
                }
            })

            const data = res.data;

            if (data.status === 0) {
                setEventSurveyData(data.record);
                setCustomFieldInfoSurvey(data.cf_info);
                setCustomFieldInfoSurveyStat(data.stat);
                setShowSurveyEditModal(true);
            } else {
                setEventSurveyData([]);
                setCustomFieldInfoSurvey([]);
                setCustomFieldInfoSurveyStat([]);
                setShowSurveyEditModal(true);
            }
        } catch {

        }
    }

    const handleLeadCloseModal = () => {
        setShowLeadEditModal(false);
    }

    const handleRegisteredCloseModal = () => {
        setShowRegisteredEditModal(false);
    }

    const handleAttendedCloseModal = () => {
        setShowAttendedEditModal(false);
    }

    const handleSurveyCloseModal = () => {
        setShowSurveyEditModal(false);
    }

    // Modal Grid Data -------------------------------------------------

    const gridColumnLead = [
        { headerName: t("Event_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("Event_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("Event_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("Event_contact"),
            field: "contact1",
            width: 250,
        },
        {
            headerName: t("Event_lead_assigned"),
            field: "lead_assigned",
            width: 250,
        },
        {
            headerName: t("Event_lead_assigned_user"),
            field: "lead_assigned_user",
            width: 250,
        },
    ]

    const createDynamicColumnsRegistered = (cfInfo) => {
        if (!cfInfo || !Array.isArray(cfInfo.cf_record)) {
            return [];
        }

        return cfInfo.cf_record.map(cf => ({
            headerName: cf.cf_label,
            field: cf.cf_id,
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
            valueGetter: (params) => {
                const record = params.data.cf_val.cf_record ? params.data.cf_val.cf_record.find(item => item.cf_id === cf.cf_id) : [];
                return record ? record.fd_value : '';
            }
        }));
    };

    const gridColumnRegistered = [
        { headerName: t("Event_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("Event_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("Event_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("Event_contact"),
            field: "contact1",
            width: 250,
        },
        ...(customFieldInfo ? createDynamicColumnsRegistered(customFieldInfo) : []),
    ]

    const gridColumnAttended = [
        { headerName: t("Event_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("Event_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("Event_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("Event_contact"),
            field: "contact1",
            width: 250,
        },
    ]

    const createDynamicColumnsSurvey = (cfInfo) => {
        if (!cfInfo || !Array.isArray(cfInfo.cf_record)) {
            return [];
        }

        return cfInfo.cf_record.map(cf => ({
            headerName: cf.cf_label,
            field: cf.cf_id,
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
            valueGetter: (params) => {
                const record = params.data.cf_val.cf_record ? params.data.cf_val.cf_record.find(item => item.cf_id === cf.cf_id) : [];
                return record ? record.fd_value : '';
            }
        }));
    };

    const gridColumnSurvey = [
        { headerName: t("Event_no"), field: "", headerClass: "center", valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: "center" },
        {
            headerName: t("Event_prospect_name"),
            field: "prospect_name",
            width: 250,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: 1.2
            },
        },
        {
            headerName: t("Event_email"),
            field: "email1",
            width: 250,
        },
        {
            headerName: t("Event_contact"),
            field: "contact1",
            width: 250,
        },
        ...(customFieldInfoSurvey ? createDynamicColumnsSurvey(customFieldInfoSurvey) : []),
    ]

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = async (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    await handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.reg_form_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const url = session.hostUrlType === 'cloud' ? "https://www.nexcrmapis.com/form/registration_api.php" : "https://www.nexcrmapis.com/form/registration_api_staging.php";

            const response = await axios.get(url, {
                params: {
                    ctoken: session.company_token,
                    form_id: params.reg_form_id,
                    task: "delForm",
                    utoken: session.user_ac_token,
                }
            })

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getEventList();
            }

        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    // DATE RANGE FILTER --------------------------------------------------
    const onchangeDateRange = (mode, value) => {
        setdateRange((prevState) => ({
            ...prevState,
            [mode]: value,
        }));
    };

    // OPEN MODAL ---------------------------------------------------
    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleEditClick = async (params) => {
        // Extract start and end dates
        const eventDateParts = params.event_date ? params.event_date.split(' - ') : [];
        const startDate = eventDateParts.length > 0 ? eventDateParts[0] : '';
        const endDate = eventDateParts.length > 1 ? eventDateParts[1] : '';

        setdateRange({
            ds: startDate,
            de: endDate,
        });

        setIsLoadingChatInstances(true);
        setIsLoadingSurveyForms(true);
        setIsLoadingWhatsappTemplates(true);
        setIsLoadingTeam(true);

        try {
            await Promise.all([
                // getChatInstanceListing(),
                // getSurveyListing(),
                getCustomFieldData(params.cf_record),
                // getTeam(),
                // getSource(),
                getBannerImg(params),
                getWhatsappRsvpMessageTemplate(params)
            ]);
        } catch (error) {
            console.error("Error loading data:", error);
        } finally {
            setIsLoadingChatInstances(false);
            setIsLoadingSurveyForms(false);
            setIsLoadingWhatsappTemplates(false);
            setIsLoadingTeam(false);
            setShowEditModal(true);
        }
    };

    const getChatInstanceListing = async () => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_ai_campaign.php`, {
                    params: {
                        company_id: session.company_id,
                        ctoken: session.company_token,
                        task: "getAudienceOptions",
                        type: "chat_instance",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    const transformedData = data.record.map(instance => ({
                        label: `${instance.instance_title} - ${instance.user_number}`,
                        value: instance.chat_instance_id
                    }));

                    setChatInstance(transformedData);
                    setIsLoadingChatInstances(false);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getSurveyListing = async () => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_setting.php`, {
                    params: {
                        ctoken: session.company_token,
                        mode: "Survey",
                        task: "getAllFormsV2",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    const transformedData = data.record.map(instance => ({
                        label: instance.form_title,
                        value: instance.reg_form_id
                    }));

                    transformedData.unshift({ label: "No Survey", value: "0" });

                    setSurveyForm(transformedData);
                    setIsLoadingSurveyForms(false);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getCustomFieldData = async (paramsDetails) => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_custom_field.php`, {
                    params: {
                        area: "person",
                        company: session.company_id,
                        task: "5",
                    }
                })
                .then(res => {
                    let data = res.data;

                    const transformedData = data.record.map(instance => ({
                        label: instance.cf_label,
                        value: instance.cf_id
                    }));

                    var selectedFields = [];
                    var remainingFields = [];

                    if (paramsDetails) {
                        transformedData.forEach(field => {
                            if (paramsDetails.some(param => param.cf_id === field.value)) {
                                selectedFields.push(field);
                            } else {
                                remainingFields.push(field);
                            }
                        });

                        setCFList(remainingFields);
                    } else {
                        setCFList(transformedData);
                    }

                    setSelCFList(selectedFields);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getWhatsappRsvpMessageTemplate = async (params) => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_conversation.php`, {
                    params: {
                        ctoken: session.company_token,
                        task: "getWATemplateListing",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    const transformedData = data.record.map(instance => ({
                        label: instance.whatsapp_template_name,
                        value: instance.whatsapp_template_name,
                        message: instance.message
                    }));

                    // Extract start and end times
                    const eventTimeParts = params.event_time ? params.event_time.split(' - ') : [];
                    const startTime = eventTimeParts.length > 0 ? eventTimeParts[0] : '';
                    const endTime = eventTimeParts.length > 1 ? eventTimeParts[1] : '';

                    const selectedMessageTemplate = transformedData.find(option => option.value === params.whatsapp_rsvp_message);
                    const selectedQrTemplate = transformedData.find(option => option.value === params.whatsapp_qr_name);
                    const selectedSurveyTemplate = transformedData.find(option => option.value === params.whatsapp_survey_message);

                    // Extract start and end dates
                    const eventDateParts = params.event_date ? params.event_date.split(' - ') : [];
                    const startDate = eventDateParts.length > 0 ? eventDateParts[0] : '';
                    const endDate = eventDateParts.length > 1 ? eventDateParts[1] : '';

                    setCurrentData({
                        ...params,
                        startTime: startTime,
                        endTime: endTime,
                        rsvp_message: selectedMessageTemplate ? selectedMessageTemplate.message : "",
                        qr_message: selectedQrTemplate ? selectedQrTemplate.message : "",
                        survey_message: selectedSurveyTemplate ? selectedSurveyTemplate.message : "",
                        startDate: startDate,
                        endDate: endDate
                    });

                    setWhatsappRsvpMessage(transformedData);
                    setIsLoadingWhatsappTemplates(false);
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getTeam = async () => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_user.php`, {
                    params: {
                        ctoken: session.company_token,
                        task: "getUserTags",
                        utoken: session.user_ac_token,
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        setTeam(data.record);
                        setIsLoadingTeam(false);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getSource = async () => {
        try {
            await axios
                .get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_source.php`, {
                    params: {
                        company: session.company_id,
                        task: "2",
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        setSource(data.record);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }

    const getBannerImg = async (params) => {
        try {
            await axios
                .get(`${session.hostUrl}/${session.hostUrlType}/php/form_image_settings.php`, {
                    params: {
                        task: "ReadImage",
                        banner_img: params.banner_img
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0 && data.base64) {
                        setBannerImg(`data:${data.mime_type};base64,${data.base64}`);
                    } else {
                        setBannerImg('');
                    }
                });
        } catch (error) {
            console.error(error);
        }
    }


    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleEditCloseModal = () => {
        setShowEditModal(false);
    };

    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const url = session.hostUrlType === 'cloud' ? "https://www.nexcrmapis.com/form/registration_api.php" : "https://www.nexcrmapis.com/form/registration_api_staging.php";

            const response = await axios.get(url, {
                params: {
                    utoken: session.user_ac_token,
                    task: "addTemplateEvent",
                    title: values.form_title,
                    ctoken: session.company_token,
                }
            })

            if (response.data.status == '0') {
                handleCloseModal();
                getEventList();

                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
            } else {
                console.error(response.data.message);
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // UPDATE FUNCTION ----------------------------------------------

    const formatDate = (dateString) => {
        if (dateString.includes("/")) {
            // Format is DD/MM/YYYY
            const [day, month, year] = dateString.split("/");
            return `${day}/${month}/${year}`;
        } else if (dateString.includes("-")) {
            // Format is YYYY-MM-DD
            const [year, month, day] = dateString.split("-");
            return `${day}/${month}/${year}`;
        }
        return dateString;
    };

    const handleUpdate = async (values) => {
        await getUpdatedCF(values);

        // combined time
        const { startTime, endTime } = values;
        const combinedTime = `${startTime} - ${endTime}`;

        // combined date
        const { ds, de } = dateRange;
        const formattedStartDate = formatDate(ds);
        const formattedEndDate = formatDate(de);
        const combinedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

        // consent
        if (values.consent_needed === "0") {
            values.consent_1_type = "";
            values.consent_1_text = "";
        }

        // event
        if (values.event_create_lead === "0") {
            values.event_user_tag = "0";
            values.event_lead_assigned_source_token = "";
        }

        try {
            const url = session.hostUrlType === 'cloud' ? "https://www.nexcrmapis.com/form/registration_api.php" : "https://www.nexcrmapis.com/form/registration_api_staging.php";

            const response = await axios.get(url, {
                params: {
                    task: "updateTemplateV2",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    title: values.form_title,
                    slug: values.slug,
                    ds_id: values.ds_id,
                    user_id: values.user_id,
                    banner_img: values.banner_img,
                    form_id: values.reg_form_id,
                    mode: values.mode,
                    pipeline_id: values.pipeline_id,
                    chat_instance_id: values.chat_instance_id,
                    published: values.published,
                    whatsapp_rsvp_message: values.whatsapp_rsvp_message,
                    whatsapp_qr_name: values.whatsapp_qr_name,
                    whatsapp_survey_message: values.whatsapp_survey_message,
                    associated_survey: values.associated_survey,
                    consent_needed: values.consent_needed,
                    consent_1_type: values.consent_1_type,
                    consent_1_text: values.consent_1_text,
                    event_date: combinedDateRange,
                    event_time: combinedTime,
                    event_location: values.event_location,
                    event_create_lead: values.event_create_lead,
                    event_user_tag: values.event_user_tag,
                    event_lead_assigned_source_token: values.event_lead_assigned_source_token,
                    form_description: values.form_description,
                }
            })

            const data = response.data;

            if (data.status === 0) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Your record has been updated',
                    timer: 1500
                });

                setShowEditModal(false);
                getEventList();
            }
        } catch {

        }
    }

    const getUpdatedCF = async (values) => {
        const cfIds = selCFList.map(item => item.value);
        const cfIdsString = cfIds.join('|');

        try {
            await axios.get(`${session.hostUrlApi}/${session.hostUrlApiType}/ws/ws_event_rsvp.php`, {
                params: {
                    task: "update",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    reg_form_id: values.reg_form_id,
                    cf_id: cfIdsString,
                }
            })
        } catch {

        }
    }

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        const initData = async () => {
            if (!init.current) {
                try {
                    await getEventList();
                    getChatInstanceListing();
                    getSurveyListing();
                    getTeam();
                    getSource();
                    setLoading(false);
                    init.current = true;
                } catch (error) {
                    setLoading(false);
                }
            }
        };

        initData();
    }, [session, init]);

    const handleAddSurvey = () => {
        sessionStorage.setItem('submitSurvey', JSON.stringify({
            outcome: 'true'
        }));
        window.open(`${session.origin}/settings/survey-settings`, "_blank");
        sessionStorage.removeItem('submitSurvey');
    };

    const convertTo24HourFormat = (timeString) => {
        const [time, period] = timeString.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);

        if (period === 'PM' && hours < 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    const handleRefreshList = () => {
        getSurveyListing();
    }

    const handleSurveyListRefresh = () => {
        Swal.fire({
            icon: 'success',
            title: t("Event_updated"),
            text: t("Event_survey_form_list_updated"),
            timer: 1000
        });
    };

    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const renderPieCharts = () => {
        if (!customFieldInfoSurveyStat || !customFieldInfoSurveyStat.stat_record) {
            return null; // or handle loading state or error state
        }

        return (
            <div className="d-flex justify-content-center flex-wrap">
                {customFieldInfoSurveyStat.stat_record.map((item, index) => {
                    // Extract entries from item object directly
                    const entries = Object.values(item).filter(entry => typeof entry === 'object');

                    // Map through entries to create pie chart data
                    const pieChartData = entries.map(entry => ({
                        name: entry.fd_value,
                        value: parseInt(entry.count)
                    }));

                    // Generate random colors for each cell
                    const colors = pieChartData.map(() => generateRandomColor());

                    return (
                        <div key={index} className="mb-4" style={{ width: '45%', margin: '0 auto' }}>
                            <Card className="border-0 shadow-sm">
                                <Card.Header>
                                    <div className="font h5 m-3" style={{ width: '100%' }}>{item.stat_label}</div>
                                </Card.Header>
                                <ResponsiveContainer width="100%" height={300}>
                                    <PieChart>
                                        <Legend verticalAlign="top" />
                                        <Pie
                                            data={pieChartData}
                                            dataKey="value"
                                            nameKey="name"
                                            cx="50%"  // Adjust positioning as needed
                                            cy="50%"
                                            outerRadius={80}  // Adjust radius as needed
                                            innerRadius={30}
                                            label
                                        >
                                            {pieChartData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                            ))}
                                        </Pie>
                                        <RechartsTooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </Card>
                        </div>
                    );
                })}
            </div>
        );
    };

    // Download excel
    const handleDownloadLead = () => {
        var params = {
            fileName: "lead-assigned.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    const handleDownloadRegistered = () => {
        var params = {
            fileName: "registered.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    const handleDownloadAttended = () => {
        var params = {
            fileName: "attended.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };

    const handleDownloadSurvey = () => {
        var params = {
            fileName: "survey.csv",
            processCellCallback: function (params) {
                if (params.value) {
                    var res = params.value;
                    if (res !== undefined && res !== null && res !== "") {
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, "");
                        res = res.replace(/&nbsp;/g, "");
                    }

                    return res;
                }
            },
        };

        gridRef.current.api.exportDataAsCsv(params);
    };


    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={handleOpenModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> {t("Event_new_event")}
                    </button>

                    <Modal show={showModal} onHide={handleCloseModal} size="md">
                        <Formik
                            initialValues={{
                                form_title: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">{t("Event_add_new_event")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Event_form_title")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="form_title"
                                                value={values.form_title}
                                                onChange={handleChange}
                                                isInvalid={touched.form_title && !!errors.form_title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.form_title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            {t("Event_submit")}
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showEditModal} onHide={handleEditCloseModal} size="lg">
                        <Formik
                            initialValues={{
                                form_title: currentData ? currentData.form_title : "",
                                slug: currentData ? currentData.url_title : "",
                                event_date: currentData ? currentData.event_date : "",
                                startDate: currentData ? currentData.startDate : "",
                                endDate: currentData ? currentData.endDate : "",
                                event_time: currentData ? currentData.event_time : "",
                                startTime: currentData ? currentData.startTime : "",
                                endTime: currentData ? currentData.endTime : "",
                                event_location: currentData ? currentData.event_location : "",
                                form_description: currentData ? currentData.form_description : "",
                                chat_instance_id: currentData && currentData.chat_instance_id !== null && currentData.chat_instance_id !== undefined && currentData.chat_instance_id !== "0" ? currentData.chat_instance_id : "",
                                survey: currentData ? currentData.survey_submitted : "",
                                whatsapp_rsvp_message: currentData ? currentData.whatsapp_rsvp_message : "",
                                rsvp_message: currentData ? currentData.rsvp_message : "",
                                whatsapp_qr_name: currentData ? currentData.whatsapp_qr_name : "",
                                qr_message: currentData ? currentData.qr_message : "",
                                event_create_lead: currentData ? currentData.event_create_lead : "",
                                consent_needed: currentData ? currentData.consent_needed : "",
                                published: currentData ? currentData.published : "",
                                banner_img: currentData ? currentData.banner_img : "",
                                event_user_tag: currentData ? currentData.event_user_tag : "",
                                event_lead_assigned_source_token: currentData ? currentData.event_lead_assigned_source_token : "",
                                consent_1_type: currentData ? currentData.consent_1_type : "",
                                consent_1_text: currentData ? currentData.consent_1_text : "",
                                ds_id: currentData ? currentData.ds_id : "",
                                user_id: currentData ? currentData.user_id : "",
                                mode: currentData ? currentData.mode : "",
                                pipeline_id: currentData ? currentData.pipeline_id : "",
                                associated_survey: currentData ? currentData.associated_survey : "",
                                reg_form_id: currentData ? currentData.reg_form_id : "",
                                whatsapp_survey_message: currentData ? currentData.whatsapp_survey_message : "",
                                survey_message: currentData ? currentData.survey_message : "",
                                banner_img_upload: bannerImg ? [{ source: bannerImg }] : ""
                            }}
                            validationSchema={validationSchemaEdit}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                handleBlur
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t("Event_edit_event")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("Event_form_title")}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="form_title"
                                                            value={values.form_title}
                                                            onChange={handleChange}
                                                            isInvalid={touched.form_title && !!errors.form_title}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.form_title}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("Event_slug")}</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="slug"
                                                            value={values.slug}
                                                            onChange={handleChange}
                                                            isInvalid={touched.slug && !!errors.slug}
                                                            onKeyPress={(e) => {
                                                                if (e.key === ' ') {
                                                                    e.preventDefault(); // Prevent space from being entered
                                                                }
                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.slug}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("Event_event_start_date")}</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="startDate"
                                                            placeholder={t("Event_from")}
                                                            className="border"
                                                            value={dateRange.ds ? moment(dateRange.ds, 'DD/MM/YYYY').format('YYYY-MM-DD') : ""}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onchangeDateRange("ds", moment(e.target.value).format("DD/MM/YYYY"));
                                                            }}
                                                            isInvalid={touched.startDate && !!errors.startDate}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.startDate}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>{t("Event_event_end_date")}</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            name="endDate"
                                                            placeholder={t("Event_to")}
                                                            className="border"
                                                            value={dateRange.de ? moment(dateRange.de, 'DD/MM/YYYY').format('YYYY-MM-DD') : ""}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                onchangeDateRange("de", moment(e.target.value).format("DD/MM/YYYY"));
                                                            }}
                                                            isInvalid={touched.endDate && !!errors.endDate}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.endDate}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("Event_event_start_time")}</Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="time"
                                                            name="startTime"
                                                            value={values.startTime ? convertTo24HourFormat(values.startTime) : ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.startTime && !!errors.startTime}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.startTime}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>{t("Event_event_end_time")}</Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="time"
                                                            name="endTime"
                                                            value={values.endTime ? convertTo24HourFormat(values.endTime) : ''}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isInvalid={touched.endTime && !!errors.endTime}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.endTime}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Event_event_location")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="event_location"
                                                value={values.event_location}
                                                onChange={handleChange}
                                                isInvalid={touched.event_location && !!errors.event_location}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.event_location}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Event_event_description")}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={8}
                                                name="form_description"
                                                value={values.form_description}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>{t("Event_chat_instance")}</Form.Label>
                                            {errors.chat_instance_id && touched.chat_instance_id && (
                                                <div className="op-error-message">{errors.chat_instance_id}</div>
                                            )}
                                            <FormSelect
                                                className="mb-3"
                                                name="chat_instance_id"
                                                placeholder={t("Event_select_chat_instance")}
                                                options={chatInstance}
                                                valueDefault={chatInstance.find(option => option.value === values.chat_instance_id)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('chat_instance_id', selectedOption.value);
                                                }}
                                                isSearchable={true}
                                                isInvalid={touched.chat_instance_id && !!errors.chat_instance_id}
                                            />
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>{t("Event_survey_form")}</Form.Label>
                                            <div className="d-flex">
                                                <div className="w-75">
                                                    <FormSelect
                                                        className="mb-3 w-90"
                                                        placeholder={t("Event_select_survey")}
                                                        options={surveyForm}
                                                        valueDefault={surveyForm.find(option => option.value == values.associated_survey) || surveyForm.find(option => option.value === '0')}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('associated_survey', selectedOption.value);
                                                        }}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center" style={{ height: 35, width: '10%' }}>
                                                    <Button variant="secondary" onClick={handleRefreshList}>
                                                        <FontAwesomeIcon className="fontAwesomeIcon" icon={faArrowsRotate} onClick={() => handleSurveyListRefresh()} />
                                                    </Button>
                                                </div>
                                                <div className="d-flex justify-content-center" style={{ height: 35 }}>
                                                    <Button variant="primary" onClick={handleAddSurvey}>
                                                        {t("Event_add_survey")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>{t("Event_whatsapp_rsvp_message_template")}</Form.Label>
                                            {errors.whatsapp_rsvp_message && touched.whatsapp_rsvp_message && (
                                                <div className="op-error-message">{errors.whatsapp_rsvp_message}</div>
                                            )}
                                            <FormSelect
                                                className="mb-3"
                                                name="whatsapp_rsvp_message"
                                                placeholder={t("Event_select_whatsap_rsvp_message_template")}
                                                options={whatsappRsvpMessage}
                                                valueDefault={whatsappRsvpMessage.find(option => option.value === values.whatsapp_rsvp_message)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('whatsapp_rsvp_message', selectedOption.value);
                                                    setFieldValue('rsvp_message', selectedOption.message);
                                                }}
                                                isSearchable={true}
                                                isInvalid={touched.whatsapp_rsvp_message && !!errors.whatsapp_rsvp_message}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-5">
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="rsvp_message"
                                                value={values.rsvp_message}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>

                                        <Form.Group className="w-100">
                                            <Form.Label>{t("Event_whatsapp_qr_code_template")}</Form.Label>
                                            {errors.whatsapp_qr_name && touched.whatsapp_qr_name && (
                                                <div className="op-error-message">{errors.whatsapp_qr_name}</div>
                                            )}
                                            <FormSelect
                                                className="mb-3"
                                                name="whatsapp_qr_name"
                                                placeholder={t("Event_select_whatsapp_qr_code_template")}
                                                options={whatsappRsvpMessage}
                                                valueDefault={whatsappRsvpMessage.find(option => option.value === values.whatsapp_qr_name)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('whatsapp_qr_name', selectedOption.value);
                                                    setFieldValue('qr_message', selectedOption.message);
                                                }}
                                                isSearchable={true}
                                                isInvalid={touched.whatsapp_qr_name && !!errors.whatsapp_qr_name}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-5">
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                name="qr_message"
                                                value={values.qr_message}
                                                onChange={handleChange}
                                                disabled
                                            />
                                        </Form.Group>


                                        {/* whatsapp survey message template */}
                                        {surveyForm.some(option => option.value === values.associated_survey) && values.associated_survey !== "0" && values.associated_survey !== "" && (
                                            <>
                                                <Form.Group className="w-100">
                                                    <Form.Label>{t("Event_whatsapp_survey_message_template")}</Form.Label>
                                                    {/* {errors.whatsapp_survey_message && touched.whatsapp_survey_message && (
                                                        <div className="op-error-message">{errors.whatsapp_survey_message}</div>
                                                    )} */}
                                                    <FormSelect
                                                        className="mb-3"
                                                        name="whatsapp_survey_message"
                                                        placeholder={t("Event_select_whatsapp_survey_message_template")}
                                                        options={whatsappRsvpMessage}
                                                        valueDefault={whatsappRsvpMessage.find(option => option.value === values.whatsapp_survey_message)}
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('whatsapp_survey_message', selectedOption.value);
                                                            setFieldValue('survey_message', selectedOption.message);
                                                        }}
                                                        isSearchable={true}
                                                    // isInvalid={touched.whatsapp_survey_message && !!errors.whatsapp_survey_message}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-5">
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={4}
                                                        name="survey_message"
                                                        value={values.survey_message}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                        <div className="mb-3">
                                            <div className="mb-2 fw-bold">{t("Event_do_you_want_to_create_lead")}</div>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="lead"
                                                type="radio"
                                                value="1"
                                                checked={values.event_create_lead === "1"}
                                                onChange={() => setFieldValue("event_create_lead", "1")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="lead"
                                                type="radio"
                                                value="0"
                                                checked={values.event_create_lead === "0"}
                                                onChange={() => setFieldValue("event_create_lead", "0")}
                                            />
                                        </div>

                                        {values.event_create_lead === "1" && (
                                            <>
                                                <Form.Group className="w-100">
                                                    <Form.Label>{t("Event_team")}</Form.Label>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder={t("Event_select_team")}
                                                        options={team.map(item => ({ value: item.user_tag_id, label: item.user_tag_title }))}
                                                        valueDefault={
                                                            values.event_user_tag && values.event_user_tag !== "0"
                                                                ? {
                                                                    label: team.find(item => item.user_tag_id === values.event_user_tag)?.user_tag_title || '',
                                                                    value: values.event_user_tag
                                                                }
                                                                : null
                                                        }
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('event_user_tag', selectedOption.value);
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100 mb-5">
                                                    <Form.Label>{t("Event_source")}</Form.Label>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder={t("Event_select_deal_source")}
                                                        options={source.map(item => ({ value: item.ds_token, label: item.ds_title }))}
                                                        valueDefault={
                                                            values.event_lead_assigned_source_token
                                                                ? {
                                                                    label: source.find(item => item.ds_token === values.event_lead_assigned_source_token)?.ds_title || '',
                                                                    value: values.event_lead_assigned_source_token
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('event_lead_assigned_source_token', selectedOption.value);
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                        <div className="mb-3">
                                            <div className="mb-2 fw-bold">{t("Event_so_you_want_to_create_consent")}</div>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="consent"
                                                type="radio"
                                                value="1"
                                                checked={values.consent_needed === "1"}
                                                onChange={() => setFieldValue("consent_needed", "1")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="consent"
                                                type="radio"
                                                value="0"
                                                checked={values.consent_needed === "0"}
                                                onChange={() => setFieldValue("consent_needed", "0")}
                                            />
                                        </div>

                                        {values.consent_needed === "1" && (
                                            <>
                                                <Form.Group className="w-100">
                                                    <Form.Label>{t("Event_consent_type")}</Form.Label>
                                                    <FormSelect
                                                        className="mb-3"
                                                        placeholder={t("Event_choose_consent_type")}
                                                        options={ConsentType.map(item => ({ value: item.value, label: item.label }))}
                                                        valueDefault={
                                                            values.consent_1_type
                                                                ? {
                                                                    label: ConsentType.find(item => item.value === values.consent_1_type)?.label || '',
                                                                    value: values.consent_1_type
                                                                }
                                                                : ""
                                                        }
                                                        onChange={(selectedOption) => {
                                                            setFieldValue('consent_1_type', selectedOption.value);
                                                        }}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="w-100 mb-5">
                                                    <Form.Label>Consent Text</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t("Event_consent_text")}
                                                        name="consent_1_text"
                                                        value={values.consent_1_text}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </>
                                        )}

                                        <div className="mb-3">
                                            <div className="mb-2 fw-bold">{t("Event_do_you_want_to_publish_this_form")}</div>
                                            <Form.Check
                                                inline
                                                label="Yes"
                                                name="publish"
                                                type="radio"
                                                value="Yes"
                                                checked={values.published === "Yes"}
                                                onChange={() => setFieldValue("published", "Yes")}
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                name="publish"
                                                type="radio"
                                                value="No"
                                                checked={values.published === "No"}
                                                onChange={() => setFieldValue("published", "No")}
                                            />
                                        </div>

                                        <div className='mb-3' style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%" }}>
                                            <Card style={{ width: '45%' }}>
                                                <Card.Header className="text-center">{t("Event_custom_field")}</Card.Header>
                                                <Card.Body style={{ height: '350px', overflow: 'auto' }}>
                                                    <ReactSortable list={CFList} setList={setCFList} group="shared" style={{ height: '100%' }}>
                                                        {CFList.map((item, index) => (
                                                            <Card key={index} className="mb-2">
                                                                <Card.Body>{item.label}</Card.Body>
                                                            </Card>
                                                        ))}
                                                    </ReactSortable>
                                                </Card.Body>
                                            </Card>

                                            <div>
                                                <FontAwesomeIcon icon={faRightLeft} />
                                            </div>

                                            <Card style={{ width: '45%' }}>
                                                <Card.Header className="text-center">{t("Event_selected_custom_field")}</Card.Header>
                                                <Card.Body style={{ height: '350px', overflow: 'auto' }}>
                                                    <ReactSortable list={selCFList} setList={setSelCFList} group="shared" style={{ height: '100%' }}>
                                                        {(selCFList.length > 0) && selCFList.map((item, index) => (
                                                            <Card key={index} className="mb-2" style={{ cursor: "grab" }}>
                                                                <Card.Body>{item.label}</Card.Body>
                                                            </Card>
                                                        ))}
                                                    </ReactSortable>
                                                </Card.Body>
                                            </Card>
                                        </div>

                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("Event_image")}</Form.Label>
                                            {errors.banner_img && touched.banner_img && (
                                                <div className="op-error-message">{errors.banner_img}</div>
                                            )}
                                            <FilePond
                                                allowMultiple={false}
                                                name={"banner_img"}
                                                maxFiles={1}
                                                files={values.banner_img_upload}
                                                credits={false}
                                                onremovefile={() => {
                                                    setFieldValue("banner_img", "");
                                                    setFieldValue("banner_img_upload", []);
                                                }}
                                                onupdatefiles={(fileItems) => {
                                                    setFieldValue(
                                                        "banner_img_upload",
                                                        fileItems.map((fileItem) => fileItem.file)
                                                    );
                                                }}
                                                instantUpload={false}
                                                server={{
                                                    process: (fieldName, file, metadata, load, error, progress, abort) => {
                                                        const formData = new FormData();
                                                        const reader = new FileReader();

                                                        const name = file.name.split('.');

                                                        formData.append("task", "UploadImage");
                                                        formData.append("banner_img", `${Date.now()}_${Math.random()}`);
                                                        formData.append("mime_type", name[name.length - 1])

                                                        reader.onload = (e) => {
                                                            formData.append("data", e.target.result);
                                                            axios.post(`${session.hostUrl}/${session.hostUrlType}/php/form_image_settings.php`, formData).then((response) => {
                                                                const data = response.data;
                                                                if (Number(data.status) === 0) {
                                                                    load(data);
                                                                    setFieldValue("banner_img", data.file_name);
                                                                }
                                                            });
                                                        };
                                                        reader.readAsDataURL(file);
                                                    },
                                                }}
                                                isInvalid={touched.banner_img && !!errors.banner_img}
                                            />
                                        </Form.Group>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" type="submit">
                                            {t("Event_update")}
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact columnDefs={gridColumn} rowData={loading ? [] : eventlist} rowHeight={120} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Open modal */}

                    <Modal show={showLeadEditModal} onHide={handleLeadCloseModal} size="xl">
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between w-100">
                                <Modal.Title>{t("Event_lead_assigned")}</Modal.Title>
                                <Button variant="secondary" onClick={handleDownloadLead}>
                                    {t("Event_download")}
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", height: "80vh" }}>
                                <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumnLead} rowData={eventLeadData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showRegisteredEditModal} onHide={handleRegisteredCloseModal} size="xl">
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between w-100">
                                <Modal.Title>{t("Event_registered")}</Modal.Title>
                                <Button variant="secondary" onClick={handleDownloadRegistered}>
                                    {t("Event_download")}
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", height: "80vh" }}>
                                <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumnRegistered} rowData={eventRegisteredData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showAttendedEditModal} onHide={handleAttendedCloseModal} size="xl">
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between w-100">
                                <Modal.Title>{t("Event_attended")}</Modal.Title>
                                <Button variant="secondary" onClick={handleDownloadAttended}>
                                    {t("Event_download")}
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", height: "80vh" }}>
                                <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumnAttended} rowData={eventAttendedData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={showSurveyEditModal} onHide={handleSurveyCloseModal} size="xl">
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between w-100">
                                <Modal.Title>{t("Event_survey_submitted")}</Modal.Title>
                                <Button variant="secondary" onClick={handleDownloadSurvey}>
                                    {t("Event_download")}
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                {renderPieCharts()}
                            </div>

                            <div style={{ width: "100%", height: "80vh" }}>
                                <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumnSurvey} rowData={eventSurveyData} rowHeight={70} pagination={true} paginationPageSize={100} />
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </Container>
    );
}

export default EventRsvp;
