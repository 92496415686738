import { useState, useRef, useEffect } from "react";
import { Modal, Form, Row, Col } from "react-bootstrap";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import Loader from "../../includes/Loader";
import "../../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
import FormSelect from "../../includes/FormSelect";
import { useTranslation } from "react-i18next";

const EditContactDetail = ({ prospectID, closeModal, onSuccess }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);

    const [prosToken, setProsToken] = useState("");
    const [prosData, setProsData] = useState({});
    const [open, setOpen] = useState(false);

    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const prefixList = [
        { label: "60", value: "60" },
        { label: "61", value: "61" },
        { label: "65", value: "65" },
        { label: "1", value: "1" },
        { label: "27", value: "27" },
        { label: "33", value: "33" },
        { label: "41", value: "41" },
        { label: "44", value: "44" },
        { label: "49", value: "49" },
        { label: "62", value: "62" },
        { label: "63", value: "63" },
        { label: "66", value: "66" },
        { label: "81", value: "81" },
        { label: "82", value: "82" },
        { label: "84", value: "84" },
        { label: "86", value: "86" },
        { label: "90", value: "90" },
        { label: "91", value: "91" },
        { label: "92", value: "92" },
        { label: "94", value: "94" },
        { label: "353", value: "353" },
        { label: "357", value: "357" },
        { label: "673", value: "673" },
        { label: "850", value: "850" },
        { label: "852", value: "852" },
        { label: "853", value: "853" },
        { label: "880", value: "880" },
        { label: "886", value: "886" },
        { label: "966", value: "966" },
        { label: "971", value: "971" },
        { label: "973", value: "973" },
        { label: "974", value: "974" },
        { label: "996", value: "996" }
    ];

    const getCountry = () => {
        
        axios.get('ws/ws_listplace.php', {
            params: {
                task: "listCountry",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var countryList = data.record.map((info) => {
                    return {label: info.country_title, value: info.country_id};
                });
                
                setCountryData(countryList);
            }else{
                setCountryData([]);
            }
        })
        .catch(error => {
            setCountryData([]);
        });
    }

    const getState = () => {

        axios.get('ws/ws_listplace.php', {
            params: {
                task: "listState",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var stateList = data.record.map((info) => {
                    return {label: info.state_title, value: info.state_id, country: info.country_id};
                });

                setStateData(stateList);
            }else{
                setStateData([]);
            }
        })
        .catch(error => {
            setStateData([]);
        });
    }

    const getCity = () => {

        axios.get('ws/ws_listplace.php', {
            params: {
                task: "listCityGL",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var stateList = data.record.map((rec) => {
                    return {label: rec.city_title, value: rec.city_id, state: rec.state_id};
                });

                setCityData(stateList);
            }else{
                setCityData([]);
            }
        })
        .catch(error => {
            setCityData([]);
        });
    }

    const getProspectDetail = () => {

        axios.get('ws/ws_prospect_view.php', {
            params:{
                task: "viewProspectDetailsV2",
                prospect: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                var idNo1;

                data.record.custom_field_data.map((rec) => {
                    if (rec.cf_id == 5002) {
                        idNo1 = rec.fd_value;
                    }
                });

                setProsToken(data.record.prospect_token);

                setProsData({
                    id_no1: idNo1,
                    contact1Pref: data.record.contact1_prefix,
                    contact1: data.record.contact1,
                    contact1_old: data.record.contact1, 
                    contact2Pref: data.record.contact2_prefix,
                    contact2: data.record.contact2,
                    contact3Pref: data.record.contact3_prefix,
                    contact3: data.record.contact3,
                    contact4Pref: data.record.contact4_prefix,
                    contact4: data.record.contact4,
                    email1: data.record.email1,
                    email2: data.record.email2,
                    addressType: data.record.address_type,
                    address1: data.record.address_line1,
                    address2: data.record.address_line2,
                    address3: data.record.address_line3,
                    postcode: data.record.postal_code,
                    city_id: data.record.city_id,
                    state_id: data.record.state_id,
                    country_id: data.record.country_id
                });
                setOpen(true);
                setIsLoading(false);
            }else{
                setProsData([]);
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Failed to open edit prospect"
                });
            }
        })
        .catch(error => {
            setProsData([]);
            Swal.fire({
                icon: "error",
                title: error.message
            });
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getProspectDetail();
            getCountry();
            getState();
            getCity();
        }
    }, []);

    const editSchema = yup.object().shape({
        contact1Pref: yup.string().required('This field is required'),
        contact1: yup.number().typeError('Must be a number type').required('This field is required'),
        contact2: yup.number().typeError('Must be a number type'),
        contact3: yup.number().typeError('Must be a number type'),
        contact4: yup.number().typeError('Must be a number type'),
        email1: yup.string().email('must be valid email').required('This field is required'),
        email2: yup.string().email('must be valid email'),
        address1: yup.string().required('This field is required'),
        address2: yup.string().required('This field is required'),
        postcode: yup.string().required('This field is required').notOneOf(['0'],'The value inserted are not allowed'),
        city_id: yup.number().min(1, 'This field is required').required('This field is required'),
        state_id: yup.number().min(1, 'This field is required').required('This field is required'),
        country_id: yup.number().min(1, 'This field is required').required('This field is required')
    });

    const sendEdit = (values) => {
        setIsLoading(true);

        var allowContact1 = 1;
        var allowContact2 = 1;
        var allowContact3 = 1;
        var allowContact4 = 1;

        if (Number(values.contact1Pref) === 60 && values.contact1) {
            if (Number(values.contact1[0]) !== 0) {
                allowContact1 = 0;
            }
        }

        if (Number(values.contact2Pref) === 60 && values.contact2) {
            if (Number(values.contact2[0]) !== 0) {
                allowContact2 = 0;
            }
        }

        if (Number(values.contact3Pref) === 60 && values.contact3) {
            if (Number(values.contact3[0]) !== 0) {
                allowContact3 = 0;
            }
        }

        if (Number(values.contact4Pref) === 60 && values.contact4) {
            if (Number(values.contact4[0]) !== 0) {
                allowContact4 = 0;
            }
        }

        if (allowContact1 === 0 || allowContact2 === 0 || allowContact3 === 0 || allowContact4 === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please insert valid contact number for prefix 60, ie: 0123456789'
            });
        }

        if (allowContact1 === 1 && allowContact2 === 1 && allowContact3 === 1 && allowContact4 === 1) {
            triggerEdit(values);    
        }else{
            setIsLoading(false);
        }

    }

    const triggerEdit = (values) => {
        axios.get('ws/ws_edit_prospect_details.php', {
            params:{
                task: "updateContacts",
                prospect_id: prospectID,
                id_no: values.id_no1,
                contact1_prefix: values.contact1Pref,
                contact1: values.contact1,
                contact1_old: values.contact1_old,
                contact2_prefix: values.contact2Pref,
                contact2: values.contact2,
                contact3_prefix: values.contact3Pref,
                contact3: values.contact3,
                contact4_prefix: values.contact4Pref,
                contact4: values.contact4,
                email1: values.email1,
                email2: values.email2,
                address_type: values.addressType,
                address1: values.address1,
                address2: values.address2,
                address3: values.address3,
                state_id: values.state_id,
                city_id: values.city_id,
                district_id: 0,
                postcode: values.postcode,
                country_id: values.country_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                triggerRRGLLApi();
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support."
                })
                .then(result => {
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });
    }

    const triggerRRGLLApi = () => {

        axios.get('ext/gamuda/api_rr_prospectivebuyerupdate.php', {
            params:{
                prospectID: prospectID,
            }
        })
        .then(res => {

            axios.get('ext/gamudaLifestyle/gllapi_update_prospect_profile', {
                params:{
                    Outperform_ID: prosToken,
                }
            })
            .then(res => {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully updated contact details",
                    timer: 1500
                })
                .then(result => {
                    onSuccess();
                });
            });

        });
    }

    return(
        <Modal show={open} onHide={closeModal} size="lg">

            <Formik
                validationSchema={editSchema}
                onSubmit={sendEdit}
                initialValues={prosData}
            >
                {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title as={"h6"}>Edit Contact Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="p-0">
                            {isLoading ? (<div style={{height: "40vh", width: "100%"}}><Loader/></div>) : 
                                (
                                    <div style={{overflow: "auto", height: "65vh", width: "100%"}} className="py-0 px-4">
                                        <Row>
                                            <Col className="py-4">

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Contact Number 1 *</Form.Label>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <FormSelect
                                                                options={prefixList}
                                                                valueDefault={prefixList.filter(opt => opt.value == values.contact1Pref)}
                                                                isInvalid={errors.contact1Pref && touched.contact1Pref}
                                                                isSearchable={true}
                                                                onChange={(e) => {setFieldValue('contact1Pref', e.value)}}
                                                            />
                                                            {errors.contact1Pref && touched.contact1Pref && <div className="op-error-message">{errors.contact1Pref}</div>}
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.contact1}
                                                                isInvalid={errors.contact1 && touched.contact1}
                                                                onChange={handleChange('contact1')}
                                                            />
                                                            {errors.contact1 && touched.contact1 && <div className="op-error-message">{errors.contact1}</div>}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Contact Number 2</Form.Label>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <FormSelect
                                                                options={prefixList}
                                                                valueDefault={prefixList.filter(opt => opt.value == values.contact2Pref)}
                                                                isSearchable={true}
                                                                onChange={(e) => {setFieldValue('contact2Pref', e.value)}}
                                                            />
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.contact2}
                                                                isInvalid={errors.contact2 && touched.contact2}
                                                                onChange={handleChange('contact2')}
                                                            />
                                                            {errors.contact2 && touched.contact2 && <div className="op-error-message">{errors.contact2}</div>}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobile Number 1</Form.Label>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <FormSelect
                                                                options={prefixList}
                                                                valueDefault={prefixList.filter(opt => opt.value == values.contact3Pref)}
                                                                isSearchable={true}
                                                                onChange={(e) => {setFieldValue('contact3Pref', e.value)}}
                                                            />
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.contact3}
                                                                isInvalid={errors.contact3 && touched.contact3}
                                                                onChange={handleChange('contact3')}
                                                            />
                                                            {errors.contact3 && touched.contact3 && <div className="op-error-message">{errors.contact3}</div>}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Mobile Number 2</Form.Label>
                                                    <Row>
                                                        <Col xs={3}>
                                                            <FormSelect
                                                                options={prefixList}
                                                                valueDefault={prefixList.filter(opt => opt.value == values.contact4Pref)}
                                                                isSearchable={true}
                                                                onChange={(e) => {setFieldValue('contact4Pref', e.value)}}
                                                            />
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.contact4}
                                                                isInvalid={errors.contact4 && touched.contact4}
                                                                onChange={handleChange('contact4')}
                                                            />
                                                            {errors.contact4 && touched.contact4 && <div className="op-error-message">{errors.contact4}</div>}
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email 1 *</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.email1}
                                                        isInvalid={errors.email1 && touched.email1}
                                                        onChange={handleChange('email1')}
                                                    />
                                                    {errors.email1 && touched.email1 && <div className="op-error-message">{errors.email1}</div>}
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email 2</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.email2}
                                                        onChange={handleChange('email2')}
                                                    />
                                                </Form.Group>

                                            </Col>
                                            <Col className="border-start py-4">

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address Line 1 *</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.address1}
                                                        isInvalid={errors.address1 && touched.address1}
                                                        onChange={handleChange('address1')}
                                                    />
                                                    {errors.address1 && touched.address1 && <div className="op-error-message">{errors.address1}</div>}
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address Line 2 *</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.address2}
                                                        isInvalid={errors.address2 && touched.address2}
                                                        onChange={handleChange('address2')}
                                                    />
                                                    {errors.address2 && touched.address2 && <div className="op-error-message">{errors.address2}</div>}
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Address Line 3</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.address3}
                                                        onChange={handleChange('address3')}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Postcode *</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={values.postcode}
                                                        isInvalid={errors.postcode && touched.postcode}
                                                        onChange={handleChange('postcode')}
                                                    />
                                                    {errors.postcode && touched.postcode && <div className="op-error-message">{errors.postcode}</div>}
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Country *</Form.Label>
                                                    <FormSelect
                                                        options={countryData}
                                                        value={countryData.filter(opt => opt.value == values.country_id)}
                                                        isInvalid={errors.country_id && touched.country_id}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={(e) => {
                                                            setFieldValue('country_id', e ? e.value : 0);
                                                            setFieldValue('state_id', 0);
                                                            setFieldValue('city_id', 0);
                                                        }}
                                                    />
                                                    {errors.country_id && touched.country_id && <div className="op-error-message">{errors.country_id}</div>}
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>State *</Form.Label>
                                                    <FormSelect
                                                        options={Number(values.country_id) > 0 ? stateData.filter(opt => opt.country == values.country_id) : stateData}
                                                        value={stateData.filter(opt => opt.value == values.state_id)}
                                                        isInvalid={errors.state_id && touched.state_id}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={(e) => {
                                                            setFieldValue('state_id', e ? e.value : 0);
                                                            setFieldValue('city_id', 0);
                                                        }}
                                                    />
                                                    {errors.state_id && touched.state_id && <div className="op-error-message">{errors.state_id}</div>}
                                                </Form.Group>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>City *</Form.Label>
                                                    <FormSelect
                                                        options={Number(values.state_id) > 0 ? cityData.filter(opt => opt.state == values.state_id) : cityData}
                                                        value={cityData.filter(opt => opt.value == values.city_id)}
                                                        isInvalid={errors.city_id && touched.city_id}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        onChange={(e) => {
                                                            setFieldValue('city_id', e ? e.value : 0);
                                                        }}
                                                    />
                                                    {errors.city_id && touched.city_id && <div className="op-error-message">{errors.city_id}</div>}
                                                </Form.Group>

                                            </Col>
                                        </Row>
                                    </div>
                                
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <button type="submit" className="btn op-button op-primary-color text-light" disabled={isLoading ? true : false}>Submit</button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>

        </Modal>
    );

}

export default EditContactDetail;