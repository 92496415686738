import { useState, useRef, useEffect, useCallback, forwardRef, useMemo } from "react";
import { FormSelect, NewFormCustomField } from "../includes/FormCustom";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Stack, Dropdown, Card, Nav, Accordion, useAccordionButton, CloseButton, Form, ToggleButton, ButtonGroup, Modal, Spinner, Button } from "react-bootstrap";
import "../lib/scss/op-deal-details.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faEllipsis, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { faCircle as faCircleLight } from "@fortawesome/pro-light-svg-icons";
import {
    faChartLineDown,
    faChartLineUp,
    faEnvelopeOpenText,
    faFlag,
    faPenToSquare,
    faPhoneVolume,
    faStopwatch,
    faUser,
    faUsers,
    faSave,
    faCalendarClock,
    faCircleUser,
    faNoteSticky,
    faEnvelope,
    faCalendarDay,
    faBuilding,
    faBallot,
    faPhone,
    faListCheck,
    faEnvelopeCircleCheck,
    faPhoneArrowDownLeft,
    faPhoneArrowUpRight,
    faLink,
    faLocationCheck,
    faCircleMinus,
    faPeopleArrows,
    faCircleArrowRight,
    faBoxArchive,
    faLockKeyholeOpen,
    faEnvelopeOpen,
    faFile,
    faFileImage,
    faGlobe
} from "@fortawesome/pro-duotone-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { setCurrency, setDateTimeStr } from "../lib/js/Function";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import ReactHtmlParser from "html-react-parser";
import { Editor } from "@tinymce/tinymce-react";
import { setLatestTime } from "../lib/js/Function";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
// import { useNavigate } from "react-router-dom";
import NoteEditor from "../includes/NoteEditor";
import { useTranslation } from "react-i18next";

const OrganizationDetails = () => {
    const { t } = useTranslation();
    const { organization_id } = useParams();
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const editorRef = useRef(null);
    const editorRefActivity = useRef(null);
    const prosDealGridRef = useRef();
    const orgProsGridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [isEditOrganization, setIsEditOrganization] = useState(false);
    const [isActiveTab, setIsActiveTab] = useState(false);
    const [activeTab, setActiveTab] = useState("notes");

    const [orgDetData, setOrgDetData] = useState([]);
    const [analyticData, setAnalyticData] = useState({});
    const [initial, setInitial] = useState("");
    const [userList, setUserList] = useState([]);
    const [initialVal, setInitialVal] = useState({});
    const [editProsCf, setEditProsCf] = useState([]);

    const [editNoteInit, setEditNoteInit] = useState({});
    const [isEditNote, setIsEditNote] = useState(false);
    const [editActivityInit, setEditActivityInit] = useState({});
    const [isEditActivity, setIsEditActivity] = useState(false);

    const [timelineData, setTimelineData] = useState([]);
    const [callAnalytics, setCallAnalytics] = useState([]);
    const [isGenerateCall, setIsGenerateCall] = useState(false);

    const [isMain, setIsMain] = useState("");
    const [isProsTab, setIsProsTab] = useState("contact");

    const [prosDealRowData, setProsDealRowData] = useState([]);
    const [isProsDeal, setIsProsDeal] = useState(false);

    const [getOrgPros, setGetOrgPros] = useState([]);
    const [isOrgPros, setIsOrgPros] = useState(false);

    const [industryList, setIndustryList] = useState([]);
    const [countryData, setCountryData] = useState([]);
    const [user, setUser] = useState([]);

    const getIndustry = async () => {
        axios
            .get("ws/ws_organization.php", {
                params: {
                    company_id: session.company_id,
                    task: "getOrganizations",
                },
            })
            .then((response) => {
                const data = response.data;
                const options = data.record.map((record) => ({
                    label: record.in_title,
                    value: record.in_id,
                }));
                setIndustryList(options)
            });
    };

    const getCountry = async () => {
        axios
            .get("ws/ws_organization.php", {
                params: {
                    task: 10,
                },
            })
            .then((response) => {
                const data = response.data;
                const options = data.record.map((record) => ({
                    label: record.country_nicename,
                    value: record.country_id,
                }));
                setCountryData(options);
            });
    };

    const getUser = () => {
        axios
            .get("ws/ws_user.php", {
                params: {
                    task: "4b",
                    ctoken: session.company_token,
                    utoken: session.user_ac_token,
                },
            })
            .then((response) => {
                var data = response.data;
                if (data.status === 0) {
                    // const userlist = data.record.forEach((record) => {
                    //     record.label = record.user_name;
                    //     record.value = record.user_id;
                    // });

                    setUser(data.record);
                }
            });
    };

    const getOrganizationDet = () => {
        setIsLoading(true);

        axios.get("ws/ws_organization.php", {
            params: {
                ctoken: session.company_token,
                organization: organization_id,
                task: "12",
                utoken: session.user_ac_token
            }
        })
            .then(res => {
                let data = res.data;

                if (data.status === 0) {
                    setOrgDetData(data.record);
                    setInitial(data.record.organization_title.split("")[0]);
                    var contriID = [];

                    if (data.record.contributor_count > 0) {
                        data.record.contributor.forEach((data) => {
                            contriID.push(data.user_id);
                        });
                        // setSelectedContr(contriID);
                    } else {
                        // setSelectedContr([]);
                    }
                    setGetOrgPros(data.record.prospects_data);
                    setIsOrgPros(true);
                    setIsLoading(false);
                } else {
                    setOrgDetData([]);
                    setGetOrgPros([]);
                    setIsOrgPros(false);
                    setInitial("");
                    // setSelectedContr([]);
                    setIsLoading(false);
                }
            });
    }

    const getEditOrganization = async () => {

        try {
            const res = await axios.get('ws/ws_organization.php', {
                params: {
                    task: "3",
                    organization: organization_id,
                    pipe: "",
                }
            })

            let data = res.data;
            if (data.status === 0) {
                setInitialVal(
                    {
                        orgName: data.record.organization_title,
                        industry: data.record.in_id,
                        webUrl: data.record.organization_web_url,
                        phoneNumber: data.record.organization_contact,
                        orgAddress: data.record.organization_address,
                        country: data.record.organization_country_id,
                        owner: data.record.owner_id,
                        cf_person: data.record.custom_field > 0 ? data.record.custom_field_data.map((record) => ({
                            cf_id: record.cf_id,
                            fd_id: record.fd_id,
                            name: record.cf_id,
                            fd_values: record.fd_value,
                        })) : ""
                    }
                );

                if (data.record.custom_field > 0) {
                    setEditProsCf(data.record.custom_field_data);
                } else {
                    setEditProsCf([]);
                }

                setIsEditOrganization(true);
            } else {

                setInitialVal(
                    {
                        orgName: "",
                        industry: "",
                        webUrl: "",
                        phoneNumber: "",
                        orgAddress: "",
                        country: "",
                        owner: "",
                        cf_person: ""
                    }
                );

                setEditProsCf([]);
                setIsEditOrganization(true);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }

    };

    const durationList = [
        { label: "5 Mins", value: "5min" },
        { label: "10 Mins", value: "10min" },
        { label: "15 Mins", value: "15min" },
        { label: "20 Mins", value: "20min" },
        { label: "30 Mins", value: "30min" },
        { label: "35 Mins", value: "35min" },
        { label: "40 Mins", value: "40min" },
        { label: "45 Mins", value: "45min" },
        { label: "50 Mins", value: "50min" },
        { label: "55 Mins", value: "55min" },
        { label: "1 Hour", value: "60min" },
        { label: "1 Hour 30 Mins", value: "90min" },
        { label: "2 Hours", value: "120min" },
        { label: "2 Hours 30 Mins", value: "150min" },
        { label: "3 Hours", value: "180min" },
        { label: "3 Hours 30 Mins", value: "210min" },
        { label: "4 Hours", value: "240min" },
    ];

    const timings = [
        { label: "08:00", value: "08:00" },
        { label: "08:15", value: "08:15" },
        { label: "08:30", value: "08:30" },
        { label: "08:45", value: "08:45" },
        { label: "09:00", value: "09:00" },
        { label: "09:15", value: "09:15" },
        { label: "09:30", value: "09:30" },
        { label: "09:45", value: "09:45" },
        { label: "10:00", value: "10:00" },
        { label: "10:15", value: "10:15" },
        { label: "10:30", value: "10:30" },
        { label: "10:45", value: "10:45" },
        { label: "11:00", value: "11:00" },
        { label: "11:15", value: "11:15" },
        { label: "11:30", value: "11:30" },
        { label: "11:45", value: "11:45" },
        { label: "12:00", value: "12:00" },
        { label: "12:15", value: "12:15" },
        { label: "12:30", value: "12:30" },
        { label: "12:45", value: "12:45" },
        { label: "13:00", value: "13:00" },
        { label: "13:15", value: "13:15" },
        { label: "13:30", value: "13:30" },
        { label: "13:45", value: "13:45" },
        { label: "14:00", value: "14:00" },
        { label: "14:15", value: "14:15" },
        { label: "14:30", value: "14:30" },
        { label: "14:45", value: "14:45" },
        { label: "15:00", value: "15:00" },
        { label: "15:15", value: "15:15" },
        { label: "15:30", value: "15:30" },
        { label: "15:45", value: "15:45" },
        { label: "16:00", value: "16:00" },
        { label: "16:15", value: "16:15" },
        { label: "16:30", value: "16:30" },
        { label: "16:45", value: "16:45" },
        { label: "17:00", value: "17:00" },
        { label: "17:15", value: "17:15" },
        { label: "17:30", value: "17:30" },
        { label: "17:45", value: "17:45" },
        { label: "18:00", value: "18:00" },
        { label: "18:15", value: "18:15" },
        { label: "18:30", value: "18:30" },
        { label: "18:45", value: "18:45" },
        { label: "19:00", value: "19:00" },
        { label: "19:15", value: "19:15" },
        { label: "19:30", value: "19:30" },
        { label: "19:45", value: "19:45" },
        { label: "20:00", value: "20:00" },
        { label: "20:15", value: "20:15" },
        { label: "20:30", value: "20:30" },
        { label: "20:45", value: "20:45" },
        { label: "21:00", value: "21:00" },
        { label: "21:15", value: "21:15" },
        { label: "21:30", value: "21:30" },
        { label: "21:45", value: "21:45" },
        { label: "22:00", value: "22:00" },
    ];

    const noteSchema = yup.object().shape({
        prosNotes: yup.string().required("This field is required"),
    });

    const addActivitySchema = yup.object().shape({
        activity_title: yup.string().required("Title is required"),
        activity_type: yup.string().required("Activity type is required"),
        activity_user: yup.string().required("User is required")
    });

    const editNoteSchema = yup.object().shape({
        activity_note: yup.string().required("This field is required")
    });

    const editActivitySchema = yup.object().shape({
        activity_title: yup.string().required("Title is required"),
        activity_type: yup.string().required("Activity type is required"),
        activity_user: yup.string().required("User is required")
    });

    const onchangeActionTabs = (key) => {
        if (user) {
            userOptions();
        }
        setActiveTab(key);
        setIsActiveTab(false);
    };

    const userOptions = () => {
        const userOption = user.map(user => ({
            value: user.user_id,
            label: user.user_name
        }));

        setUserList(userOption);
    }

    const RenderActionTab = useCallback(({ tab, active, userListNotes, activityUser }) => {
        switch (tab) {
            case 'notes':

                if (active) {
                    return (
                        <Formik
                            validationSchema={noteSchema}
                            onSubmit={sendProsNote}
                            initialValues={
                                {
                                    prosNotes: "",
                                    user: session.user_id
                                }
                            }
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Card.Body className="note-notes">
                                        <Form.Group className="mb-3">
                                            <Form.Group className="activity-notes">
                                                <NoteEditor value={values.prosNotes} onChangeValue={(value) => handleChange("prosNotes")(value)} />
                                            </Form.Group>
                                            {errors.prosNotes && touched.prosNotes && <div className="op-error-message">{errors.prosNotes}</div>}
                                        </Form.Group>
                                    </Card.Body>
                                    <Card.Footer className="d-flex flex-row-reverse">
                                        <button type="submit" className="btn op-button op-primary-color text-light">Save</button>
                                    </Card.Footer>
                                </Form>
                            )}
                        </Formik>
                    );
                } else {
                    return (
                        <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                            {t("OrganizationDetails_click_here_to_take_a_notes")}
                        </Card.Body>
                    );
                }
            case 'activity':
                if (active) {

                    return (
                        <>
                            <Formik
                                validationSchema={addActivitySchema}
                                onSubmit={sendAddActivity}
                                initialValues={{
                                    activity_title: "",
                                    activity_type: "",
                                    activity_date: moment(new Date()).format("YYYY-MM-DD"),
                                    activity_time: setLatestTime(timings).value,
                                    activity_dur: "",
                                    activity_user: session.user_id,
                                    activity_notes: "",
                                    activity_mad: "no"
                                }}
                            >

                                {({ handleSubmit, handleChange, values, errors, touched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>

                                        <Card.Body id="notes_collapse">
                                            <div className="d-flex mb-3">
                                                <div style={{ width: "5%" }}></div>
                                                <div style={{ width: "95%" }}>
                                                    <Form.Group>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={t("OrganizationDetails_activity_title")}
                                                            isInvalid={errors.activity_title && touched.activity_title}
                                                            onChange={handleChange('activity_title')}
                                                        />
                                                        {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-3">
                                                <div style={{ width: "5%" }}></div>
                                                <div style={{ width: "95%" }}>
                                                    <Form.Group>
                                                        <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                                                                <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                                                {t("OrganizationDetails_call")}
                                                            </ToggleButton>
                                                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                                                                <FontAwesomeIcon icon={faUsers} className="me-1" />
                                                                {t("OrganizationDetails_meeting")}
                                                            </ToggleButton>
                                                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                                                                <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                                                {t("OrganizationDetails_task")}
                                                            </ToggleButton>
                                                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                                                                <FontAwesomeIcon icon={faFlag} className="me-1" />
                                                                {t("OrganizationDetails_deadline")}
                                                            </ToggleButton>
                                                            <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                                                                <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                                                {t("OrganizationDetails_email")}
                                                            </ToggleButton>
                                                        </ButtonGroup>
                                                        {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-3">
                                                <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                                                    <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                                                </div>
                                                <div style={{ width: "95%" }}>
                                                    <Stack direction="horizontal" gap={1}>
                                                        <Row style={{ width: "100%" }}>
                                                            <Col sm={4}>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        type="date"
                                                                        value={values.activity_date}
                                                                        onChange={handleChange('activity_date')}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Form.Group>
                                                                    <FormSelect
                                                                        placeholder={t("OrganizationDetails_select_time")}
                                                                        options={timings}
                                                                        valueDefault={timings.filter(option => option.value === values.activity_time)}
                                                                        onChange={e => handleChange("activity_time")(e.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <Form.Group>
                                                                    <FormSelect
                                                                        placeholder={t("OrganizationDetails_select_duration")}
                                                                        options={durationList}
                                                                        onChange={e => handleChange('activity_dur')(e.value)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Stack>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-3">
                                                <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                                                    <FontAwesomeIcon icon={faCircleUser} size="lg" />
                                                </div>

                                                <div style={{ width: "95%" }}>
                                                    <Form.Group>
                                                        <FormSelect
                                                            placeholder={t("OrganizationDetails_select_user")}
                                                            options={userList}
                                                            valueDefault={userList ? userList.filter(option => option.value === values.activity_user) : []}
                                                            isInvalid={errors.activity_user && touched.activity_user}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            onChange={(e) => { handleChange('activity_user')(e ? e.value : "") }}
                                                        />
                                                        {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="d-flex mb-3">
                                                <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                                                    <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                                                </div>
                                                <div style={{ width: "95%" }}>
                                                    <Form.Group className="activity-notes">
                                                        <Form.Group className="activity-notes">
                                                            <NoteEditor value={values.activity_notes} onChangeValue={(value) => handleChange("activity_notes")(value)} />
                                                        </Form.Group> 
                                                    </Form.Group>
                                                </div>
                                            </div>

                                        </Card.Body>
                                        <Card.Footer className="d-flex flex-row-reverse align-items-center">
                                            <button type="submit" className="btn op-button op-primary-color text-light">Save</button>
                                            <Form.Check
                                                type="switch"
                                                label="Mark as done"
                                                className="d-flex justify-content-center align-items-center me-3"
                                                onChange={(e) => {
                                                    if (e.target.checked === true) {
                                                        handleChange("activity_mad")("yes");
                                                    } else {
                                                        handleChange("activity_mad")("no")
                                                    }
                                                }}
                                            />
                                        </Card.Footer>

                                    </Form>
                                )}

                            </Formik>
                        </>
                    );

                } else {
                    return (
                        <Card.Body id="notes" onClick={setIsActiveTab} style={{ cursor: "pointer" }}>
                            {t("OrganizationDetails_click_here_to_take_a_notes")}
                        </Card.Body>
                    );
                }
            default:
                return null;
        }
    }, [editorRef.current, isActiveTab]); // eslint-disable-line react-hooks/exhaustive-deps

    const sendProsNote = async (values) => {
        const noteData = new FormData();

        noteData.append("task", "addProspectOrgNotes");
        noteData.append("user", values.user);
        noteData.append("content", values.prosNotes);
        noteData.append("organizationid", organization_id);
        noteData.append("type", "organization");
        noteData.append("utoken", session.user_ac_token);
        noteData.append("ctoken", session.company_token);

        try {
            const res = await axios.post('ws/ws_post_note.php', noteData);

            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationDetails_success"),
                    text: t("OrganizationDetails_successfully_add_note"),
                    timer: 1500
                }).then((result) => {
                    setIsActiveTab(false);
                    getProsTimeline();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("OrganizationDetails_failed"),
                    text: t("OrganizationDetails_please_try_again_or_contact_it_support")
                });
            }

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const sendAddActivity = (values) => {
        const cleanNotes = values.activity_notes.replace(/<\/?p>/g, '');
        const formattedDate = new Date(values.activity_date).toLocaleDateString('en-GB').replace(/\//g, '-');

        axios.get('ws/ws_deal.php', {
            params: {
                assign_user: values.activity_user,
                content: cleanNotes,
                create_user: values.activity_user,
                dat: values.activity_type,
                dated: formattedDate,
                deal: '',
                duration: values.activity_dur || '',
                mad: values.activity_mad,
                organization: organization_id,
                task: "2",
                timed: values.activity_time,
                title: values.activity_title,
            }
        })
            .then((res) => {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationDetails_success"),
                    text: t("OrganizationDetails_activity_successfully_created"),
                    timer: 1500
                }).then(result => {
                    setIsActiveTab(false);
                    getProsTimeline();
                });
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                });
            });
    }

    const sendMarkAsDone = (record) => {
        if (record.record_activity_type_id === 6 || record.record_activity_type_id === 7) {
            Swal.fire({
                icon: "error",
                title: t("OrganizationDetails_restrict"),
                text: t("OrganizationDetails_sorry_this_activity_cant_be_edited")
            });
        } else {
            var mark = record.record_activity_mad === 'yes' ? 'no' : 'yes';

            axios.get('ws/ws_deal.php', {
                params: {
                    task: "16",
                    activity: record.record_activity_id,
                    status: mark,
                    user: session.user_id
                }
            })
                .then(res => {
                    let data = res.data;

                    if (data.status === 0) {
                        setTimelineData((prev) => {
                            return prev.map((info) => {
                                if (info.record_activity_id === record.record_activity_id) {
                                    return {
                                        ...info,
                                        record_activity_mad: mark,
                                    }
                                }
                                return info
                            });
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: t("OrganizationDetails_failed"),
                            text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
        }
    }

    const RenderTimelineAction = ({ record }) => {
        const customToggle = forwardRef(({ onClick }, ref) => {
            const openDropdown = (e) => {
                e.preventDefault();
                onClick(e);
            };

            return (
                <Button ref={ref} variant="light" onClick={openDropdown}>
                    <FontAwesomeIcon icon={faEllipsis} size="lg" />
                </Button>
            );
        });

        return (
            <Dropdown size={100}>
                <Dropdown.Toggle as={customToggle} />
                <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100} style={{ right: 0, top: 30 }}>
                    {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && <Dropdown.Item onClick={() => { editActivityModal(record) }}>{t("OrganizationDetails_edit_activity")}</Dropdown.Item>}
                    {(record.record_type === "Activity" || record.record_type === "CustomActivity") && record.record_activity_type !== "whatsapp" && <Dropdown.Item onClick={() => { sendDeleteActivity(record) }}>{t("OrganizationDetails_delete_activity")}</Dropdown.Item>}
                    {(record.record_type === "Note_Organization" || record.record_type === "Note") && <Dropdown.Item onClick={() => { editNoteModal(record) }}>{t("OrganizationDetails_edit_note")}</Dropdown.Item>}
                    {(record.record_type === "Note_Organization" || record.record_type === "Note") && Number(session.readwrite_permission) === 0 && <Dropdown.Item onClick={() => { sendDeleteNote(record) }}>{t("OrganizationDetails_delete_note")}</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    const editActivityModal = (rec) => {

        axios.get('ws/ws_deal.php', {
            params: {
                task: 18,
                activity: rec.record_activity_id
            }
        })
            .then(res => {
                let data = res.data;

                if (data.status === 0) {
                    var timeOri = data.record.activity_timed.split(":");
                    var timeNew = `${timeOri[0]}:${timeOri[1]}`;

                    const editData = {
                        activity_dealid: data.record.deal_id,
                        activity_id: data.record.activity_id,
                        activity_title: data.record.activity_title,
                        activity_type: data.record.dat_id,
                        activity_date: data.record.activity_dated,
                        activity_time: timeNew,
                        activity_dur: data.record.activity_duration,
                        activity_user: data.record.assign_user_id,
                        activity_note: data.record.activity_content,
                        activity_mad: data.record.activity_mark_as_done
                    }

                    setEditActivityInit(editData);
                    setIsEditActivity(true);
                    userOptions();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("OrganizationDetails_failed"),
                        text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                });
            });

    }

    const sendEditActivity = async (values) => {
        try {
            const res = await axios.get('ws/ws_deal.php', {
                params: {
                    task: 11,
                    user: session.user_id,
                    dt: values.activity_date
                }
            });

            let data = res.data;

            if (data.status === 0) {
                axios.get('ws/ws_deal.php', {
                    params: {
                        aid: values.activity_id,
                        assign_user: values.activity_user,
                        ca_id: 0,
                        content: values.activity_note,
                        create_user: values.activity_user,
                        dat: values.activity_type,
                        dated: values.activity_date,
                        duration: values.activity_dur,
                        mad: values.activity_mad,
                        task: "12",
                        timed: values.activity_time,
                        title: values.activity_title
                    }
                })
                    .then(response => {
                        let rec = response.data;

                        if (rec.status === 0) {
                            Swal.fire({
                                icon: "success",
                                title: t("OrganizationDetails_success"),
                                text: t("OrganizationDetails_activity_successfully_updated"),
                                timer: 1500
                            }).then(result => {
                                setIsEditActivity(false);
                                getProsTimeline();
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: t("OrganizationDetails_failed"),
                                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                            });
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: "error",
                            title: error.message
                        });
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t("OrganizationDetails_failed"),
                    text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }
    }

    const editNoteModal = (rec) => {
        var type;
        var dealid;
        if (rec.record_type === "Note") {
            type = "deal";
            dealid = rec.deal_id;
        } else {
            type = "organization";
            dealid = "";
        }

        axios.get('ws/ws_deal.php', {
            params: {
                task: 19,
                note: rec.record_activity_id,
                type: type,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
            .then(res => {
                let data = res.data;

                if (data.status === 0) {
                    const editData = {
                        activity_dealid: dealid,
                        activity_type: type,
                        activity_id: rec.record_activity_id,
                        activity_note: data.record.note_content,
                    };

                    setEditNoteInit(editData);
                    setIsEditNote(true);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("OrganizationDetails_failed"),
                        text: t("Organization_sorry_cannot_edit_note_at_the_moment"),
                    });
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: "error",
                    title: error.message
                });
            });
    }

    const sendEditNote = async (values) => {
        var editNoteData = new FormData();

        if (values.activity_type === "organization") {
            editNoteData.append("task", "editProspectOrgNotes");
            editNoteData.append("source", values.activity_type);
            editNoteData.append("user", session.user_id);
            editNoteData.append("content", values.activity_note);
            editNoteData.append("note", values.activity_id);
            editNoteData.append("utoken", session.user_ac_token);
            editNoteData.append("ctoken", session.company_token);
        } else {
            editNoteData.append("task", "editNotes");
            editNoteData.append("note", values.activity_id);
            editNoteData.append("deal", values.activity_dealid);
            editNoteData.append("content", values.activity_note);
            editNoteData.append("utoken", session.user_ac_token);
            editNoteData.append("ctoken", session.company_token);
        }

        try {
            const res = await axios.post('ws/ws_post_note.php', editNoteData);

            let data = res.data;

            if (data.status === 0) {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationDetails_success"),
                    text: t("OrganizationDetails_note_successfullt_updated"),
                    timer: 1500
                }).then(result => {
                    setIsEditNote(false);
                    getProsTimeline();
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("OrganizationDetails_failed"),
                    text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: error.message
            });
        }

    }

    const sendDeleteActivity = (rec) => {
        Swal.fire({
            icon: "warning",
            title: t("OrganizationDetails_are_you_sure"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("OrganizationDetails_yes_delete_it"),
        }).then(result => {
            if (result.isConfirmed) {

                axios.get('ws/ws_deal.php', {
                    params: {
                        task: 23,
                        activity: rec.record_activity_id
                    }
                })
                    .then(res => {
                        let data = res.data;

                        if (data.status === 0) {
                            Swal.fire({
                                icon: "success",
                                title: t("OrganizationDetails_success"),
                                text: t("OrganizationDetails_successfully_delete_note"),
                                timer: 1500
                            }).then(result => {
                                getProsTimeline();
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: t("OrganizationDetails_failed"),
                                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                            });
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: "error",
                            title: error.message
                        });
                    });

            }
        });
    }

    const sendDeleteNote = (rec) => {
        var paramsData;

        if (rec.record_type === "Note") {
            paramsData = {
                task: 24,
                note: rec.record_activity_id,
                prospectNote: 0,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            };
        } else if (rec.record_type === "Note_Organization") {
            paramsData = {
                task: 24,
                note: rec.record_activity_id,
                type: "organization",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            };
        }

        Swal.fire({
            icon: "warning",
            title: t("OrganizationDetails_are_you_sure"),
            text: t("OrganizationDetails_this_note_will_be_deleted"),
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("OrganizationDetails_yes_delete_it"),
        }).then(result => {
            if (result.isConfirmed) {

                axios.get('ws/ws_deal.php', {
                    params: paramsData
                })
                    .then(res => {
                        let data = res.data;

                        if (data.status === 0) {
                            Swal.fire({
                                icon: "success",
                                title: t("OrganizationDetails_success"),
                                text: t("OrganizationDetails_successfully_delete_note"),
                                timer: 1500
                            }).then(result => {
                                getProsTimeline();
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: t("OrganizationDetails_failed"),
                                text: t("OrganizationDetails_please_try_again_or_contact_it_support"),
                            });
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: "error",
                            title: error.message
                        });
                    });

            }
        });
    }

    const setFilename = (x) => {
        var str = x.toString();
        var strSplit = str.split("/");
        var index = strSplit.length - 1;
        var output = strSplit[index];
        return output;
    };

    const setTimelineDateTime = (record) => {
        var icon = "";
        var content = "";

        if (record.record_type === "Note_Prospect" || record.record_type === "Note") {
            icon = faUser;
            content = record.CREATE_USER;
        } else if (record.record_type === "Activity" || record.record_type === "CustomActivity") {
            icon = faUser;
            content = record.ASSIGN_USER;
        } else if ((record.record_type === "Activity" || record.record_type === "CustomActivity")) {
            icon = faBuilding;
            content = "";
        } else if (record.record_type === "Email" && record.record_comment !== "") {
            icon = faEnvelopeOpen;
            content = record.record_comment;
        }

        return (
            <Stack direction="horizontal" gap={3}>
                <div>{setDateTimeStr(record.record_date_time)}</div>
                <div className="d-flex">
                    {icon ? (
                        <FontAwesomeIcon icon={icon} size="lg" className="me-2" />
                    ) : ""}
                    {content}
                </div>
            </Stack>
        );
    };

    const RenderTimelineIcon = ({ record }) => {
        if (record.record_type === "Activity") {
            if (Number(record.record_activity_type_id) === 1) {
                return <FontAwesomeIcon icon={faPhone} />;
            } else if (Number(record.record_activity_type_id) === 2) {
                return <FontAwesomeIcon icon={faUsers} />;
            } else if (Number(record.record_activity_type_id) === 3) {
                return <FontAwesomeIcon icon={faListCheck} />;
            } else if (Number(record.record_activity_type_id) === 4) {
                return <FontAwesomeIcon icon={faFlag} />;
            } else if (Number(record.record_activity_type_id) === 5) {
                return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
            } else if (Number(record.record_activity_type_id) === 6) {
                return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
            } else if (Number(record.record_activity_type_id) === 7) {
                return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
            } else if (Number(record.record_activity_type_id) === 8) {
                return <FontAwesomeIcon icon={faWhatsapp} />;
            } else if (Number(record.record_activity_type_id) === 9) {
                return <FontAwesomeIcon icon={faLink} />;
            } else if (Number(record.record_activity_type_id) === 10) {
                return <FontAwesomeIcon icon={faLocationCheck} />;
            } else if (Number(record.record_activity_type_id) === 11) {
                return <FontAwesomeIcon icon={faCircleMinus} />;
            }
        } else if (record.record_type === "CustomActivity") {
            return <FontAwesomeIcon icon={faListCheck} />;
        } else if (record.record_type === "NoEditActivity") {
            if (Number(record.record_activity_type_id) === 1) {
                return <FontAwesomeIcon icon={faPhone} />;
            } else if (Number(record.record_activity_type_id) === 2) {
                return <FontAwesomeIcon icon={faUsers} />;
            } else if (Number(record.record_activity_type_id) === 3) {
                return <FontAwesomeIcon icon={faListCheck} />;
            } else if (Number(record.record_activity_type_id) === 4) {
                return <FontAwesomeIcon icon={faFlag} />;
            } else if (Number(record.record_activity_type_id) === 5) {
                return <FontAwesomeIcon icon={faEnvelopeCircleCheck} />;
            } else if (Number(record.record_activity_type_id) === 6) {
                return <FontAwesomeIcon icon={faPhoneArrowDownLeft} />;
            } else if (Number(record.record_activity_type_id) === 7) {
                return <FontAwesomeIcon icon={faPhoneArrowUpRight} />;
            } else if (Number(record.record_activity_type_id) === 8) {
                return <FontAwesomeIcon icon={faWhatsapp} />;
            } else if (Number(record.record_activity_type_id) === 9) {
                return <FontAwesomeIcon icon={faLink} />;
            } else if (Number(record.record_activity_type_id) === 10) {
                return <FontAwesomeIcon icon={faLocationCheck} />;
            } else if (Number(record.record_activity_type_id) === 11) {
                return <FontAwesomeIcon icon={faCircleMinus} />;
            }
        } else if (record.record_type === "File") {
            return <FontAwesomeIcon icon={faFile} />;
        } else if (record.record_type === "Ownership") {
            return <FontAwesomeIcon icon={faPeopleArrows} />;
        } else if (record.record_type === "Status") {
            return <FontAwesomeIcon icon={faCircleArrowRight} />;
        } else if (record.record_type === "Archieve") {
            return <FontAwesomeIcon icon={faBoxArchive} />;
        } else if (record.record_type === "Reopen") {
            return <FontAwesomeIcon icon={faLockKeyholeOpen} />;
        } else if (record.record_type === "Email") {
            if (record.record_comment) {
                return <FontAwesomeIcon icon={faEnvelope} />;
            } else {
                return <FontAwesomeIcon icon={faEnvelopeOpen} />;
            }
        } else if (record.record_type === "Note_Prospect") {
            return <FontAwesomeIcon icon={faNoteSticky} />;
        } else if (record.record_type === "Note") {
            return <FontAwesomeIcon icon={faNoteSticky} />;
        } else if (record.record_type === "Deal") {
            return <FontAwesomeIcon icon={faSave} />
        } else {
            return "";
        }
    };

    const RenderTimelineHeader = ({ record }) => {
        let element = "";
        let location = "";

        switch (record.record_type) {
            case "Ownership":
                element = `Deal transfer to ${record.ASSIGN_USER}`;
                break;
            case "Status":
            case "Archieve":
            case "Reopen":
                element = record.record_description;
                break;
            case "File":
                return <RenderFile url={`${session.hostUrlApi}/${session.hostUrlApiType}/${record.record_file_url}`} title={`${record.record_title}.${record.record_file_ext}`} />;
            case "Activity":
            case "CustomActivity":
            case "NoEditActivity":
                element = `${record.record_type === "NoEditActivity" ? "" : record.record_activity_type + " - "}${record.record_title}`;
                if (record.record_comment !== "," && record.record_comment) {
                    location = `<a href="http://www.google.com/maps/place/${record.record_comment}" target="_blank" class="btn btn-light shadow-sm"><FontAwesomeIcon icon={faMapLocation} size="lg" /></a>`;
                }
                break;
            case "Note_Prospect":
                element = "Note";
                element = ReactHtmlParser(element);
                // element = "Note";
                // element = ReactHtmlParser("Note");
                // return (
                //     <div style={{ overflow: "auto", width: 700 }}>
                //         <div dangerouslySetInnerHTML={{ __html: element }}></div>
                //     </div>
                // );
                break;
            case "Note":
                element = "Note";
                element = ReactHtmlParser(element);
                // return (
                //     <div style={{ overflow: "auto", width: 700 }}>
                //         <div dangerouslySetInnerHTML={{ __html: element }}></div>
                //     </div>
                // );
                break;
            case "Deal":
                element = `${record.deal_title} - Deal Created`;
                element = ReactHtmlParser(element);
                // return (
                //     <div style={{ overflow: "auto", width: 700 }}>
                //         <div dangerouslySetInnerHTML={{ __html: element }}></div>
                //     </div>
                // );
                break;
            case "Email":
                element = record.record_title;
                break;
            default:
                return null;
        }

        return <div dangerouslySetInnerHTML={{ __html: element + location }} />;
    };

    const RenderTimelineDescription = ({ record }) => {
        const { record_type, record_description, record_comment, record_activity_type, record_file_url, record_activity_id } = record;

        if (record_type === "Activity" || record_type === "CustomActivity" || record_type === "NoEditActivity") {
            if (record_activity_type === "Call" && record_file_url) {
                var isLabel = callAnalytics.find((record) => record.record_activity_id === record_activity_id);
                return (
                    <div style={{ padding: "10px 0" }}>
                        <RenderAudio url={record_file_url} />

                        {!isLabel ? (
                            <Button variant="link" style={{ textDecoration: "none" }} onClick={() => sendCallAnalytics(record_file_url, record_activity_id)}>
                                {isGenerateCall ? (
                                    <div>
                                        <Spinner animation="border" size="sm" className="me-1" /> {t("OrganizationDetails_generating_call_analytics")}
                                    </div>
                                ) : (
                                    <div>
                                        <FontAwesomeIcon icon={faChartLineUp} size="lg" className="me-1" /> {t("OrganizationDetails_generate_call_analytics")}
                                    </div>
                                )}
                            </Button>
                        ) : (
                            <div className="mt-2">
                                {isLabel["Customer Persona"] && <div className="op label mb-2">{t("OrganizationDetails_customer_persona")} {isLabel["Customer Persona"]}</div>}
                                {isLabel["Purpose of Call"] && <div className="op label mb-2">{t("OrganizationDetails_purpose_of_call")} {isLabel["Purpose of Call"]}</div>}
                                {isLabel["Type of Project"] && <div className="op label mb-2">{t("OrganizationDetails_type_of_project")} {isLabel["Type of Project"]}</div>}
                                {isLabel["Interested Property Type"] && <div className="op label mb-2">{t("OrganizationDetails_interested_property_type")} {isLabel["Interested Property Type"]}</div>}
                                {isLabel["Requested Info"] && <div className="op label mb-2">{t("OrganizationDetails_requested_info")} {isLabel["Requested Info"]}</div>}
                                {isLabel["Call Sentiment"] && <div className="op label mb-2">{t("OrganizationDetails_call_sentiment")} {isLabel["Call Sentiment"]}</div>}
                                {isLabel["Appointment Availability"] && <div className="op label mb-2">{t("OrganizationDetails_appointment_availability")} {isLabel["Appointment Availability"]}</div>}
                            </div>
                        )}
                    </div>
                );
            } else {
                return ReactHtmlParser(record_description);//<div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
            }
        } else if (record_type === "Archieve") {
            return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_comment) }} />;
        } else if (record_type === "Email") {
            return <div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
        } else if (record_type === "Note_Organization") {
            return ReactHtmlParser(record_description);//<div dangerouslySetInnerHTML={{ __html: ReactHtmlParser(record_description) }} />;
        } else if (record_type === "Note") {
            return ReactHtmlParser(record_description);
        } else {
            return "";
        }
    };

    const RenderTimelineDocument = ({ record }) => {
        if (record.record_file_ext === "image" && record.record_file_url !== "") {
            return <RenderImage url={record.record_file_url} title={setFilename(record.record_file_url)} />;
        } else if (record.record_file_ext === "voice" && record.record_file_url !== "") {
            return <RenderAudio url={record.record_file_url} />;
        } else if (record.record_file_ext === "document" && record.record_file_url !== "") {
            return <RenderFile url={record.record_file_url} title={setFilename(record.record_file_url)} />;
        } else {
            return "";
        }
    };

    const RenderFile = ({ url = "", title = "" }) => {
        return (
            <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFile} size="xl" className="me-2" />
                {title}
            </a>
        );
    };

    const RenderImage = ({ url = "", title = "" }) => {
        return (
            <a className="btn btn-light d-flex align-items-center shadow-sm border mb-3 p-3" href={url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFileImage} size="xl" className="me-2" />
                {title}
            </a>
        );
    };

    const RenderAudio = ({ url = "" }) => {
        return (
            <audio controls className="w-100">
                <source src={url} type="audio/ogg" />
            </audio>
        );
    };

    const sendCallAnalytics = (url, record_activity_id) => {
        if (isGenerateCall) {
            Swal.fire({
                icon: "warning",
                title: "opps...",
                text: "We're receiving numerous calls. Kindly hold for a brief moment. Thank you!",
                timer: 2000,
            });
            return;
        }

        setIsGenerateCall(true);
        axios
            .get("https://app.outperformhq.io/cloud_staging/ws/ws_sentiment_analysis.php", {
                params: {
                    task: "glomac_call_insight",
                    url: encodeURIComponent(url),
                },
            })
            .then((response) => {
                var data = response.data;
                const labels = data.response.split("--").filter(Boolean);
                const resultObject = {};
                labels.forEach((section) => {
                    const keyValuePairs = section.split(":").map((item) => item.trim());
                    const key = keyValuePairs[0];
                    const value = keyValuePairs[1];
                    resultObject[key] = value;
                });

                resultObject.record_activity_id = record_activity_id;
                setCallAnalytics((prevCallAnalytics) => [...prevCallAnalytics, { ...resultObject }]);
                setIsGenerateCall(false);
            });
    };

    const getOrganizationAnalytic = () => {
        axios.get("ws/ws_organization.php", {
            params: {
                task: "13",
                organization: organization_id,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
            .then(res => {
                let data = res.data;

                setAnalyticData(data);
            })
    }

    const getProsTimeline = () => {

        axios.get('ws/ws_organization.php', {
            params: {
                ctoken: session.company_token,
                task: "11",
                organization: organization_id,
                utoken: session.user_ac_token,
            }
        })
            .then((res) => {
                let data = res.data;

                if (data.record) {
                    setTimelineData(data.record);
                } else {
                    setTimelineData([]);
                }
            });
    }

    const containerStyle = useMemo(() => ({ width: "100%", height: isProsDeal ? "70vh" : "0vh", paddingTop: 20 }), [isProsDeal]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const containerStyleOrgPros = useMemo(() => ({ width: "100%", height: isOrgPros ? "70vh" : "0vh", paddingTop: 20 }), [isOrgPros]);
    const gridStyleOrgPros = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
            sortable: false,
            filter: false,
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const openDealDetails = (params) => {
        if (params.data.deal_id) {
            window.open(`${session.origin}/deal/${params.data.deal_id}`, "_blank");
        }
    }

    const openProspectDetails = (params) => {
        if (params.data.prospect_id) {
            window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
    }

    const [prosDealColumn] = useState([
        {
            headerName: t("OrganizationDetails_deals"),
            field: "deal_title",
            resizable: true,
            width: 250,
            onCellClicked: openDealDetails
        },
        {
            headerName: t("OrganizationDetails_outcome"),
            field: "deal_archieve",
            resizable: true,
            width: 250,
            cellRenderer: (params) => {
                if (params.data) {

                    if (params.data.deal_archieve === '') {
                        return "Open";
                    } else {
                        return params.data.deal_archieve;
                    }

                }
            }
        },
        {
            headerName: t("OrganizationDetails_deal_value"),
            field: "deal_value",
            resizable: true,
            width: 250,
            cellRenderer: function (params) {
                if (params.data) {
                    return setCurrency(params.data.deal_value);
                }
            },
        },
        {
            headerName: t("OrganizationDetails_remark"),
            field: "archieve_remark",
            resizable: true,
            width: 250
        },
        {
            headerName: t("OrganizationDetails_expected_close_date"),
            field: "deal_expected_close_date",
            resizable: true,
            width: 250
        },
    ]);

    const [orgProsColumn] = useState([
        {
            headerName: t("OrganizationDetails_prospect_name"),
            field: "prospect_name",
            resizable: true,
            width: 300,
            onCellClicked: openProspectDetails
        },
    ]);

    const getOrganizationDeals = () => {
        axios
            .get('ws/ws_organization.php', {
                params: {
                    task: "13",
                    organization: organization_id,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                },
            })
            .then((res) => {
                let data = res.data;

                const recordOpen = data.record_open || [];
                const recordWon = data.record_won || [];
                const recordLost = data.record_lost || [];

                const allDeals = [
                    ...recordOpen,
                    ...recordWon,
                    ...recordLost,
                ];

                if (allDeals.length > 0) {
                    setIsProsDeal(true);
                    setProsDealRowData(allDeals);
                } else {
                    setIsProsDeal(false);
                    setProsDealRowData([]);
                }
            })
            .catch((error) => {
                setIsProsDeal(false);
                setProsDealRowData([]);
            });
    };


    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getIndustry();
            getCountry();
            getUser();
            getOrganizationDet();
            getOrganizationAnalytic();
            getProsTimeline();
            getOrganizationDeals();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const RenderAccordionButton = ({ children, eventKey }) => {
        const [isOpen, setIsOpen] = useState(false);
        const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
        return (
            <button onClick={decoratedOnClick} className="accordion-button">
                <FontAwesomeIcon icon={faChevronDown} className={`icon-transition ${isOpen ? "rotate-up" : "rotate-down"} me-2`} />
                <div className="mt-1">{children}</div>
            </button>
        );
    };

    const editProsSchema = yup.object().shape({
        orgName: yup.string().required("Name is required"),
    });

    const sendEditPros = (values) => {
        axios.get("ws/ws_organization.php", {
            params: {
                address: values.orgAddress,
                company: session.company_id,
                contact: values.phoneNumber,
                country: values.country,
                industry: values.industry,
                organization: organization_id,
                owner: values.owner,
                task: "2",
                title: values.orgName,
                weburl: values.webUrl
            }
        })
            .then((res) => {
                let data = res.data;

                if (data.status === 0) {
                    triggerEditProspect2(values);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("OrganizationDetails_oops"),
                        text: t("OrganizationDetails_something_wrong_with_your_entry"),
                        timer: 1500,
                    });
                }
            })
    }

    const triggerEditProspect2 = (values) => {
        const customFieldData = [
            { cf: values.cf_person[0].cf_id, fd: values.cf_person[0].fd_id, fd_value: values.cf_person[0].fd_values },
            { cf: values.cf_person[1].cf_id, fd: values.cf_person[1].fd_id, fd_value: values.cf_person[1].fd_values },
            { cf: values.cf_person[2].cf_id, fd: values.cf_person[2].fd_id, fd_value: values.cf_person[2].fd_values },
            { cf: values.cf_person[3].cf_id, fd: values.cf_person[3].fd_id, fd_value: values.cf_person[3].fd_values },
            { cf: values.cf_person[4].cf_id, fd: values.cf_person[4].fd_id, fd_value: values.cf_person[4].fd_values },
            { cf: values.cf_person[5].cf_id, fd: values.cf_person[5].fd_id, fd_value: values.cf_person[5].fd_values },
        ];

        const customFieldRequests = customFieldData.map((field) =>
            axios.get("ws/ws_custom_field.php", {
                params: {
                    task: "12",
                    fd: field.fd,
                    value: field.fd_value,
                    cf: field.cf,
                    record: organization_id
                }
            })
        );

        Promise.all(customFieldRequests)
            .then((responses) => {
                Swal.fire({
                    icon: "success",
                    title: t("OrganizationDetails_success"),
                    text: t("OrganizationDetails_updated_successfully"),
                    timer: 1500
                });
                getOrganizationDet();
                setIsEditOrganization(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("OrganizationDetails_error"),
                    text: t("OrganizationDetails_an_error_occured_during_submission"),
                    timer: 1500
                });
            });
    }


    const handleProsTab = (values) => {
        setIsProsTab(values);

        if (values === 'contact') {
            setIsMain("");
        } else {
            setIsMain("d-none");
        }
    }

    const RenderOtherTab = ({ tab }) => {

        switch (tab) {
            case "deal":

                return (
                    <div className="section-content">
                        <Container fluid>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={prosDealGridRef}
                                        columnDefs={prosDealColumn}
                                        rowData={prosDealRowData}
                                        defaultColDef={reportColDef}
                                        rowHeight={70}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                            {!isProsDeal && <NoRecord message="No record found." width={300} />}
                        </Container>
                    </div>
                );

            case "prospect":

                return (
                    <div className="section-content">
                        <Container fluid>
                            <div style={containerStyleOrgPros}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleOrgPros }}>
                                    <AgGridReact
                                        ref={orgProsGridRef}
                                        columnDefs={orgProsColumn}
                                        rowData={getOrgPros}
                                        defaultColDef={reportColDef}
                                        rowHeight={70}
                                        pagination={true}
                                        paginationPageSize={30}
                                        paginationPageSizeSelector={false}
                                    />
                                </div>
                            </div>
                            {!isOrgPros && <NoRecord message="No record found." width={300} />}
                        </Container>
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <>
            {isLoading === true ? (
                <p>{t("OrganizationDetails_loading")}</p>
            ) : (
                <div className="main">
                    <div className="section-header">
                        <Row>
                            <Col xxl={12} className="py-2 px-5">
                                <Stack direction="horizontal" gap={2}>
                                    <div>
                                        <Stack direction="horizontal" gap={2}>
                                            <div className="d-flex">
                                                <div className="avatar me-2">
                                                    <div className="avatar-img" style={{ width: 70, height: 70, backgroundColor: "#eee" }}>
                                                        <div className="avatar-txt text-uppercase" style={{ "fontSize": "35px" }}>
                                                            {initial}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="d-flex align-items-center">
                                                    <div className="op-text-bigger fs-5 me-2">{orgDetData.organization_title}</div>
                                                </div>
                                                <div className="op-text-small">
                                                    {orgDetData.organization_title === "" ? "Individual" : orgDetData.organization_title}
                                                </div>
                                            </div>
                                        </Stack>
                                    </div>
                                </Stack>
                            </Col>
                            <Col xxl={12}>
                                <Form.Group style={{ paddingLeft: "10px", paddingRight: "10px", paddingBottom: "10px" }}>
                                    <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                        <ToggleButton variant="" type="radio" name="pros_tab" value="contact" checked={isProsTab === 'contact'} onClick={() => { handleProsTab('contact') }}>
                                            {t("OrganizationDetails_contact")}
                                        </ToggleButton>
                                        <ToggleButton variant="" type="radio" name="pros_tab" value="deal" checked={isProsTab === 'deal'} onClick={() => { handleProsTab('deal') }}>
                                            {t("OrganizationDetails_deals")}
                                        </ToggleButton>
                                        <ToggleButton variant="" type="radio" name="pros_tab" value="prospect" checked={isProsTab === 'prospect'} onClick={() => { handleProsTab('prospect') }}>
                                            {t("OrganizationDetails_prospect")}
                                        </ToggleButton>
                                    </ButtonGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="section-body">
                        <div className={`section-sidebar ${isMain}`}>
                            <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
                                <div className="sidebar-accordion">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <RenderAccordionButton eventKey="0">{t("OrganizationDetails_personal_information")}</RenderAccordionButton>
                                        <div className="btn-group shadow-sm">
                                            <button
                                                type="button"
                                                className="btn btn-light border"
                                                onClick={
                                                    () => {
                                                        getEditOrganization();
                                                    }
                                                }
                                            >
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </button>
                                        </div>
                                    </div>

                                    <Accordion.Collapse eventKey="0">
                                        <div className="accordion-body">
                                            <div className="d-flex justify-content-between align-items-center p-2 w-100">
                                                <div className="d-flex">
                                                    <div className="me-2 text-center" style={{ width: 15 }}>
                                                        <FontAwesomeIcon icon={faUser} size="lg" />
                                                    </div>
                                                    {t("OrganizationDetails_contact_number")}
                                                </div>
                                                <span>
                                                    {orgDetData.organization_contact !== "" ? orgDetData.organization_contact : "-"}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center p-2 w-100">
                                                <div className="d-flex">
                                                    <div className="me-2 text-center" style={{ width: 15 }}>
                                                        <FontAwesomeIcon icon={faGlobe} size="lg" />
                                                    </div>
                                                    {t("OrganizationDetails_website")}
                                                </div>
                                                <span>
                                                    {orgDetData.organization_web_url !== "" ? orgDetData.organization_web_url : "-"}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center p-2 w-100">
                                                <div className="d-flex">
                                                    <div className="me-2 text-center" style={{ width: 15 }}>
                                                        <FontAwesomeIcon icon={faBuilding} size="lg" />
                                                    </div>
                                                    {t("OrganizationDetails_address")}
                                                </div>
                                                <span>
                                                    {orgDetData.organization_address !== "" ? orgDetData.organization_address : "-"}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center p-2 w-100">
                                                <div className="d-flex">
                                                    <div className="me-2 text-center" style={{ width: 15 }}>
                                                        <FontAwesomeIcon icon={faBuilding} size="lg" />
                                                    </div>
                                                    {t("OrganizationDetails_industry")}
                                                </div>
                                                <span>
                                                    {orgDetData.in_title === "" ? "-" : orgDetData.in_title}
                                                </span>
                                            </div>

                                            {Number(orgDetData.custom_field) > 0 ? (
                                                <>
                                                    {orgDetData.custom_field_data.map((data, i) => (

                                                        <div key={i} className="d-flex justify-content-between align-items-center p-2 w-100">
                                                            <div className="d-flex">
                                                                <div className="me-2 text-center" style={{ width: 15 }}>
                                                                    <FontAwesomeIcon icon={faBallot} size="lg" />
                                                                </div>
                                                                {data.cf_label}
                                                            </div>
                                                            <span>{data.fd_value ? data.fd_value : "-"}</span>
                                                        </div>

                                                    ))}
                                                </>
                                            ) : ""}
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="sidebar-accordion">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <RenderAccordionButton eventKey="1">{t("OrganizationDetails_analytics")}</RenderAccordionButton>
                                    </div>

                                    <Accordion.Collapse eventKey="1">
                                        <div className="accordion-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center w-30">
                                                    <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                                                    <div className="ms-2">
                                                        <span className="op-text-bigger fw-bold">{setCurrency(analyticData.open_amount ?? 0)}</span> <br />
                                                        <span className="op-text-medium">
                                                            {Number(analyticData.total_open) > 1 ? `${analyticData.total_open} Open Deals` : `${analyticData.total_open ?? 0} Open Deal`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center w-60">
                                                    <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                                                    <div className="ms-2">
                                                        <span className="op-text-bigger fw-bold">{setCurrency(analyticData.deleted_amount ?? 0)}</span> <br />
                                                        <span className="op-text-medium">
                                                            {Number(analyticData.total_deleted) > 1 ? `${analyticData.total_deleted} Archieved Deals` : `${analyticData.total_deleted ?? 0} Archieved Deals`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center w-30">
                                                    <FontAwesomeIcon icon={faChartLineUp} size="2xl" />
                                                    <div className="ms-2">
                                                        <span className="op-text-bigger fw-bold">{setCurrency(analyticData.won_amount ?? 0)}</span> <br />
                                                        <span className="op-text-medium">
                                                            {Number(analyticData.total_won) > 1 ? `${analyticData.total_won} Won Deals` : `${analyticData.total_won ?? 0} Won Deal`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center w-60">
                                                    <FontAwesomeIcon icon={faChartLineDown} size="2xl" />
                                                    <div className="ms-2">
                                                        <span className="op-text-bigger fw-bold">{setCurrency(analyticData.lost_amount ?? 0)}</span> <br />
                                                        <span className="op-text-medium">
                                                            {Number(analyticData.total_lost) > 1 ? `${analyticData.total_lost} Lost Deals` : `${analyticData.total_lost ?? 0} Lost Deal`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>
                        <div className={`section-content ${isMain}`}>
                            <Container fluid className="p-4">
                                <Card>
                                    <Card.Header className="d-flex justify-content-between align-items-center" style={{ padding: "0 20px" }}>
                                        <Nav variant="underline" defaultActiveKey="notes" onSelect={onchangeActionTabs}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="notes">
                                                    <FontAwesomeIcon icon={faNoteSticky} className="me-2" size="lg" />
                                                    {t("OrganizationDetails_notes")}
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="activity">
                                                    <FontAwesomeIcon icon={faCalendarDay} className="me-2" size="lg" />
                                                    {t("OrganizationDetails_activity")}
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        {isActiveTab && <CloseButton className="float-end me-2" onClick={() => setIsActiveTab(false)} />}
                                    </Card.Header>
                                    <RenderActionTab tab={activeTab} active={isActiveTab} />
                                    {/* <RenderActionTab tab={activeTab} active={isActiveTab} userListNotes={userList} activityUser={userList} /> */}
                                </Card>

                                <Accordion defaultActiveKey={["0"]} alwaysOpen className="position-relative">
                                    <div className="sidebar-accordion">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <RenderAccordionButton eventKey="0">{t("OrganizationDetails_timeline")}</RenderAccordionButton>
                                        </div>

                                        <Accordion.Collapse eventKey="0">
                                            <div className="accordion-body">
                                                <VerticalTimeline layout="1-column-left" style={{ width: "100%" }}>
                                                    {timelineData.length && timelineData.map((record, index) => (
                                                        <VerticalTimelineElement key={index} date={setTimelineDateTime(record)} iconStyle={{ background: "#fff" }} icon={<RenderTimelineIcon record={record} />}>
                                                            <Row>
                                                                <Col sm={10}>
                                                                    <div className="op-text-bigger fw-bold">
                                                                        <RenderTimelineHeader record={record} />
                                                                    </div>
                                                                    <div className="op-text-medium">
                                                                        <RenderTimelineDescription record={record} />
                                                                    </div>
                                                                    <RenderTimelineDocument record={record} />
                                                                </Col>
                                                                {record.record_type === "Activity" || record.record_type === "CustomActivity" ? (
                                                                    <Col sm={1}>
                                                                        <div className="w-100 d-flex justify-content-center mt-2" style={{ cursor: "pointer" }} onClick={() => sendMarkAsDone(record)}>
                                                                            {record.record_activity_mad === "yes" ? <FontAwesomeIcon icon={faCircleCheck} size="2xl" /> : <FontAwesomeIcon icon={faCircleLight} size="2xl" />}
                                                                        </div>
                                                                    </Col>
                                                                ) : ""}
                                                                {Number(session.readwrite_permission) === 0 || Number(session.readwrite_permission) === 1 ? (
                                                                    <Col sm={1}>
                                                                        <RenderTimelineAction record={record} />
                                                                    </Col>
                                                                ) : ""}
                                                            </Row>
                                                        </VerticalTimelineElement>
                                                    ))}
                                                </VerticalTimeline>
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>
                            </Container>
                        </div>
                        <RenderOtherTab tab={isProsTab} />
                    </div>

                    <Modal show={isEditOrganization} onHide={setIsEditOrganization}>
                        <Formik
                            onSubmit={sendEditPros}
                            validationSchema={editProsSchema}
                            initialValues={initialVal}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_organization_details")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_name")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.orgName}
                                                isInvalid={errors.orgName && touched.orgName}
                                                onChange={handleChange("orgName")}
                                            />
                                            {errors.orgName && touched.orgName && <div className="op-error-message">{errors.orgName}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_industry")}</Form.Label>
                                            <FormSelect
                                                options={Array.isArray(industryList) ? industryList : []}
                                                placeholder={t("OrganizationDetails_select_industry")}
                                                onChange={(e) => {
                                                    handleChange("industry")(e.value)
                                                }}
                                                valueDefault={Array.isArray(industryList) ? industryList.find(option => option.value === String(values.industry)) : undefined}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_website_url")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.webUrl}
                                                onChange={handleChange("webUrl")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_phone_number")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.phoneNumber}
                                                onChange={handleChange("phoneNumber")}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_organization_address")}</Form.Label>
                                            <Form.Control
                                                as={"textarea"}
                                                rows={3}
                                                type="text"
                                                onChange={handleChange("orgAddress")}
                                                placeholder={t("OrganizationDetails_organization_address")}
                                                value={values.orgAddress}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_country")}</Form.Label>
                                            <FormSelect
                                                options={Array.isArray(countryData) ? countryData : []}
                                                placeholder={t("OrganizationDetails_select_country")}
                                                onChange={(e) => {
                                                    handleChange("country")(e.value)
                                                }}
                                                valueDefault={Array.isArray(countryData) ? countryData.find(option => option.value === String(values.country)) : undefined}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t("OrganizationDetails_prospect_owner")}</Form.Label>
                                            <FormSelect
                                                options={Array.isArray(user) ? user : []}
                                                placeholder={t("OrganizationDetails_select_owner")}
                                                onChange={(e) => {
                                                    handleChange("owner")(e.value)
                                                }}
                                                valueDefault={Array.isArray(user) ? user.find(option => option.value === String(values.owner)) : undefined}
                                            />
                                        </Form.Group>
                                        {Number(editProsCf.length) > 0 ? (
                                            <>
                                                {editProsCf.map((record, index) => (
                                                    <NewFormCustomField
                                                        key={record.cf_id}
                                                        id={record.cf_id}
                                                        label={record.cf_label}
                                                        options={record.cf_value}
                                                        type={record.ctf_title}
                                                        name={record.cf_id}
                                                        placeholder=""
                                                        value={record.fd_value}
                                                        onChange={(info) => {
                                                            const updatedCustomFields = [...values.cf_person];
                                                            if (record.ctf_title === "Select") {
                                                                updatedCustomFields[index] = {
                                                                    cf_id: record.cf_id,
                                                                    fd_id: record.fd_id,
                                                                    fd_values: info.value,
                                                                };
                                                            } else if (record.ctf_title === "Radio") {
                                                                updatedCustomFields[index] = {
                                                                    cf_id: record.cf_id,
                                                                    fd_id: record.fd_id,
                                                                    fd_values: info.target.value,
                                                                };
                                                            } else if (record.ctf_title === "Date") {
                                                                updatedCustomFields[index] = {
                                                                    cf_id: record.cf_id,
                                                                    fd_id: record.fd_id,
                                                                    fd_values: info,
                                                                };
                                                            } else if (record.ctf_title === "Checkbox") {
                                                                updatedCustomFields[index] = {
                                                                    cf_id: record.cf_id,
                                                                    fd_id: record.fd_id,
                                                                    name: record.cf_id,
                                                                    fd_values: info.target.value,
                                                                    checked: info.target.value
                                                                };
                                                            } else {
                                                                updatedCustomFields[index] = {
                                                                    cf_id: record.cf_id,
                                                                    fd_id: record.fd_id,
                                                                    fd_values: info.target ? info.target.value : info.value,
                                                                };
                                                            }

                                                            handleChange({
                                                                target: { name: "cf_person", value: updatedCustomFields },
                                                            });
                                                        }}
                                                    />
                                                ))}
                                            </>
                                        ) : ""}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">{t("OrganizationDetails_submit")}</button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                    <Modal show={isEditNote} onHide={setIsEditNote} size="lg">
                        <Formik
                            validationSchema={editNoteSchema}
                            onSubmit={sendEditNote}
                            initialValues={editNoteInit}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_note")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Group className="activity-notes">
                                                <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} />
                                            </Form.Group>
                                            {errors.activity_note && touched.activity_note && <div className="op-error-message">{errors.activity_note}</div>}
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">{t("OrganizationDetails_submit")}</button>
                                    </Modal.Footer>

                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={isEditActivity} onHide={setIsEditActivity} size="lg">
                        <Formik
                            validationSchema={editActivitySchema}
                            onSubmit={sendEditActivity}
                            initialValues={editActivityInit}
                        >
                            {({ handleSubmit, handleChange, values, errors, touched }) => (
                                <Form noValidate onSubmit={handleSubmit}>

                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>{t("OrganizationDetails_edit_activity")}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>

                                        <div className="d-flex mb-3">
                                            <div style={{ width: "5%" }}></div>
                                            <div style={{ width: "95%" }}>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t("OrganizationDetails_activity_title")}
                                                        value={values.activity_title}
                                                        isInvalid={errors.activity_title && touched.activity_title}
                                                        onChange={handleChange('activity_title')}
                                                    />
                                                    {errors.activity_title && touched.activity_title && <div className="op-error-message">{errors.activity_title}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3">
                                            <div style={{ width: "5%" }}></div>
                                            <div style={{ width: "95%" }}>
                                                <Form.Group>
                                                    <ButtonGroup className="activity-tab" style={{ width: "100%" }}>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_1" value="1" checked={values.activity_type === "1"} onChange={() => handleChange("activity_type")("1")}>
                                                            <FontAwesomeIcon icon={faPhoneVolume} className="me-1" />
                                                            {t("OrganizationDetails_call")}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_2" value="2" checked={values.activity_type === "2"} onChange={() => handleChange("activity_type")("2")}>
                                                            <FontAwesomeIcon icon={faUsers} className="me-1" />
                                                            {t("OrganizationDetails_meeting")}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_3" value="3" checked={values.activity_type === "3"} onChange={() => handleChange("activity_type")("3")}>
                                                            <FontAwesomeIcon icon={faStopwatch} className="me-1" />
                                                            {t("OrganizationDetails_task")}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_4" value="4" checked={values.activity_type === "4"} onChange={() => handleChange("activity_type")("4")}>
                                                            <FontAwesomeIcon icon={faFlag} className="me-1" />
                                                            {t("OrganizationDetails_deadline")}
                                                        </ToggleButton>
                                                        <ToggleButton variant="" type="radio" name="activity_type" id="activity_type_5" value="5" checked={values.activity_type === "5"} onChange={() => handleChange("activity_type")("5")}>
                                                            <FontAwesomeIcon icon={faEnvelopeOpenText} className="me-1" />
                                                            {t("OrganizationDetails_email")}
                                                        </ToggleButton>
                                                    </ButtonGroup>
                                                    {errors.activity_type && touched.activity_type && <div className="op-error-message">{errors.activity_type}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3">
                                            <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                                                <FontAwesomeIcon icon={faCalendarClock} size="lg" />
                                            </div>
                                            <div style={{ width: "95%" }}>
                                                <Stack direction="horizontal" gap={1}>
                                                    <Row style={{ width: "100%" }}>
                                                        <Col sm={4}>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    type="date"
                                                                    value={values.activity_date}
                                                                    onChange={handleChange('activity_date')}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group>
                                                                <FormSelect
                                                                    placeholder={t("OrganizationDetails_select_time")}
                                                                    options={timings}
                                                                    valueDefault={timings.filter(option => option.value === values.activity_time)}
                                                                    onChange={e => handleChange("activity_time")(e.value)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <Form.Group>
                                                                <FormSelect
                                                                    placeholder={t("OrganizationDetails_select_duration")}
                                                                    options={durationList}
                                                                    valueDefault={durationList.filter(option => option.value === values.activity_dur)}
                                                                    onChange={e => handleChange('activity_dur')(e.value)}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Stack>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3">
                                            <div className="d-flex justify-content-center align-items-center" style={{ width: "5%" }}>
                                                <FontAwesomeIcon icon={faCircleUser} size="lg" />
                                            </div>

                                            <div style={{ width: "95%" }}>
                                                <Form.Group>
                                                    <FormSelect
                                                        placeholder={t("OrganizationDetails_select_user")}
                                                        options={userList}
                                                        valueDefault={userList.filter(option => option.value === values.activity_user)}
                                                        isInvalid={errors.activity_user && touched.activity_user}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        onChange={(e) => { handleChange('activity_user')(e ? e.value : "") }}
                                                    />
                                                    {errors.activity_user && touched.activity_user && <div className="op-error-message">{errors.activity_user}</div>}
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3">
                                            <div className="d-flex justify-content-center" style={{ width: "5%" }}>
                                                <FontAwesomeIcon icon={faNoteSticky} size="lg" className="mt-2" />
                                            </div>
                                            <div style={{ width: "95%" }}>
                                                <Form.Group className="activity-notes">
                                                    {/* <Editor
                                                        apiKey="le384qsrmn1jklwsc584jcgvt8wd3db2mn8pyelkm636440i"
                                                        onInit={(evt, editor) => (editorRefActivity.current = editor)}
                                                        value={values.activity_note}
                                                        onEditorChange={(value) => handleChange("activity_note")(value)}
                                                        init={{
                                                            plugins:
                                                                "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
                                                            menubar: false,
                                                            toolbar:
                                                                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                                            branding: false,
                                                            quickbars_selection_toolbar: "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                                            toolbar_mode: "sliding",
                                                            height: 250,
                                                            min_height: 250,
                                                            max_height: 400,
                                                            statusbar: true,
                                                            autoresize_overflow_padding: 5,
                                                            autoresize_bottom_margin: "25s",
                                                            content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:12px }",
                                                        }}
                                                    /> */}
                                                    <Form.Group className="activity-notes">
                                                        <NoteEditor value={values.activity_note} onChangeValue={(value) => handleChange("activity_note")(value)} />
                                                    </Form.Group>
                                                </Form.Group>
                                            </div>
                                        </div>

                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Form.Check
                                            type="switch"
                                            label="Mark as done"
                                            className="d-flex justify-content-center align-items-center me-3"
                                            checked={values.activity_mad === 'yes'}
                                            onChange={(e) => {
                                                if (e.target.checked === true) {
                                                    handleChange("activity_mad")("yes");
                                                } else {
                                                    handleChange("activity_mad")("no")
                                                }
                                            }}
                                        />
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">{t("OrganizationDetails_submit")}</button>
                                    </Modal.Footer>

                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            )}
        </>
    );
}

export default OrganizationDetails;