import { useState, useMemo, useRef } from "react";
import { Stack, Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-duotone-svg-icons";

const MeetupLog = ({dealID, activityID}) => {
    const { session } = useAuth();
    const gridRef = useRef();

    const [isGrid, setIsGrid] = useState(true);
    const [recordFound, setRecordFound] = useState(0);
    const [rowData, setRowData] = useState([]);

    const [isOpen, setIsOpen] = useState(false);

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const containerStyle = useMemo(() => ({ width: "100%", height: isGrid ? "30vh": "30vh" , paddingTop: 10 }), [isGrid]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        {
            headerName: "Mode",
            field: "mode",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Chosen Date",
            field: "chosen_date",
            flex: 1,
            minWidth: 250
        },
        {
            headerName: "Chosen Time",
            field: "chosen_time",
            flex: 1,
            minWidth: 250
        }
    ];

    const getGridData = () => {
        
        axios.get('ws/ws_meetup.php',{
            params: {
                task: 'getMeetupRescheduleLog',
                deal_id: dealID,
                activity_id: activityID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                setRowData(data.record);
                setRecordFound(data.record.length);
                setIsGrid(true);
                setIsOpen(true);
            }else{
                setRowData([]);
                setRecordFound(0);
                setIsGrid(true);
                setIsOpen(true);
            }
        })
        .catch(error => {
            setRowData([]);
            setRecordFound(0);
            setIsGrid(true);
            setIsOpen(true);
        });
    }

    return(
        <>
            <div>
                <FontAwesomeIcon icon={faCircleInfo} size="lg" onClick={() => {getGridData()}} style={{cursor: "pointer"}}/>
            </div>

            <Modal show={isOpen} onHide={setIsOpen} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Meeting Log</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack direction="horizontal" gap={1}>
                        <div className="ms-auto">{recordFound + " record found."}</div>
                    </Stack>
                    <div className="mb-4">
                        <div style={containerStyle}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                <AgGridReact
                                    ref={gridRef}
                                    columnDefs={gridColumn}
                                    rowData={rowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={60}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MeetupLog;