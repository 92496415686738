import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Stack, Button, Row, Col } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/sharp-regular-svg-icons";
import "../lib/css/settingStyle.css";
import * as formik from "formik";
import * as yup from "yup";
// import NoRecord from "../includes/NoRecord";
import FormSelect from "../includes/FormSelect";
// import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

const CustomFieldBooking = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState([]);

  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editInit, setEditInit] = useState({});

  const { Formik } = formik;

  const [fieldTypeList, setFieldTypeList] = useState([]);
  const [pipeList, setPipeList] = useState([]);


  // GET FUNCTION =====================================================================

  const getCustomField = async () => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: 5,
          company: session.company_id,
          area: "booking",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setRowData(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFieldType = async () => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: 4,
        },
      });

      let data = response.data;
      if (Number(data.status) === 0) {
        var recordOptions = data.record.map((record) => ({
          label: record.ctf_label,
          value: record.cft_id
        }));
        setFieldTypeList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPipeline = () => {
    axios.get('ws/ws_setting.php', {
      params: {
        task: 'getAllPipeline',
        company: session.company_id
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        var dataList = data.record.map((data) => {
          return {label: data.pipeline_title, value: data.pipeline_id};
        });

        setPipeList(dataList);
      }else{
        setPipeList([]);
      }
    })
    .catch(error => {
      setPipeList([]);
    });
  }

  // GRID FUNCTION ====================================================================

  const containerStyle = useMemo(() => ({ width: "100%", height: "70vh", paddingTop: 10 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridColDef = useMemo(() => {
    return {
      resizable: false,
      sortable: false,
      filter: false,
      flex: 1,
    };
  }, []);

  const [gridColumn] = useState([
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      headerClass: "center",
      cellClass: "center",
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    { headerName: "Field Name", field: "cf_label", minWidth: 800, wrapText: true, autoHeight: true, cellStyle: { lineHeight: 1.5 } },
    { headerName: "Type", field: "ctf_label", minWidth: 150, wrapText: true, autoHeight: true, cellStyle: { lineHeight: 1.5 } },
    { 
      headerName: "Form view", 
      field: "cf_view_form", 
      minWidth: 100, 
      wrapText: true, 
      autoHeight: true, 
      headerClass: "center", 
      cellClass: "center", 
      cellStyle: { lineHeight: 1.5 }, 
      cellRenderer: (params) => {
        if (params.data) {
          return(
            <Form.Group>
              <Form.Check
                type="switch"
                checked={params.data.cf_view_form === 'yes' ? true : false}
                onChange={(e) => {
                  var value = params.data.cf_view_form === 'yes' ? 'no' : 'yes';
                  handleSwitch(value, params.colDef.field, params.data.cf_id);
                }}
              />
            </Form.Group>
          );
        }
      } 
    },
    {
      headerName: "Required",
      field: "compulsory_column",
      minWidth: 100, 
      wrapText: true, 
      autoHeight: true, 
      headerClass: "center", 
      cellClass: "center", 
      cellStyle: { lineHeight: 1.5 }, 
      cellRenderer: (params) => {
        if (params.data) {
          return(
            <Form.Group>
              <Form.Check
                type="switch"
                checked={Number(params.data.compulsory_column) === 1 ? true : false}
                onChange={(e) => {
                  var value = Number(params.data.compulsory_column) === 1 ? 0 : 1;
                  handleSwitch(value, params.colDef.field, params.data.cf_id);
                }}
              />
            </Form.Group>
          );
        }
      }
    },
    {
      headerName: "",
      resizable: true,
      width: 100,
      cellClass: "center",
      pinned: 'right',
      cellRenderer: (params) => {
        if (params.data) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => {handleDelete(params.data)}}/>
              <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => {handleEdit(params.data)}}/>
            </div>
          );
        }
      },
    },
  ]);

  const handleSwitch = (data, col, id) => {
    axios.get("ws/ws_custom_field.php", {
      params: {
        task: 11,
        cf: id,
        col: col,
        val: data
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        getCustomField();
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please try again or contact your IT Support'
        });
      }

    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: error.message
      });
    });
  }

  // USEEFFECT FUNCTION ================================================================

  useEffect(() => {
    if (!init.current) {
      getCustomField();
      getFieldType();
      getAllPipeline();
      setLoading(false);
      init.current = true;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addFieldSchema = yup.object().shape({
    field_name: yup.string().required("This field is required"),
    field_type: yup.string().required("This field is required"),
    field_opt: yup.string().notRequired().when('field_type', ([field_type], schema) => {
      if (Number(field_type) === 4 || Number(field_type) === 3 || Number(field_type) === 2) 
        return yup.string().required('This field is required');
      return schema
      
    })
  });

  const sendAddField = (values) => {
    var option = values.field_opt.split('\n');
    
    axios.get("ws/ws_custom_field.php", {
      params: {
        task: 1,
        company: session.company_id,
        area: 'booking',
        label: values.field_name,
        cft: values.field_type,
        value: option.toString(),
        add: values.field_show_add,
        detail: values.field_detail_view,
        compulsory_column: values.field_required,
        reg_form_id: ""
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Successfully added',
          timer: 1500
        })
        .then(() => {
          setIsCreate(false);
          getCustomField();
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please try again or contact your IT Support'
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: error.message
      });
    });
  }

  const handleEdit = (data) => {

    var options = "";
    var optArray = [];

    if (Number(data.cft_id) === 4 || Number(data.cft_id) === 3 || Number(data.cft_id) === 2) {
      options = JSON.parse(data.cf_value);
      Object.keys(options).forEach(key => {
        optArray.push({label: options[key], value: options[key]});
      });
    }

    var initialValues = {
      field_id: data.cf_id,
      field_name: data.cf_label,
      field_pipe: data.pipe_id,
      field_type: data.cft_id,
      field_options: options ? optArray : "",
      field_options_text: "",
      field_fb_lead_ad_map: data.fb_lead_ads_map,
      field_fb_lead_ad_name: data.fb_lead_ads_field_name,
      field_getresponse_map: data.getresponse_map,
      field_getresponse_name: data.getresponse_field_name,
      field_required: data.compulsory_column

    };
    
    setEditInit(initialValues);
    setIsEdit(true);
  }

  const editSchema = yup.object().shape({
    field_name: yup.string().required('This field is required'),
    field_fb_lead_ad_name: yup.string().notRequired().when('field_fb_lead_ad_map', ([field_fb_lead_ad_map], schema) => {
      if (Number(field_fb_lead_ad_map) === 1)
        return yup.string().required('This field is required');
      return schema;
    }),
    field_getresponse_name: yup.string().notRequired().when('field_getresponse_map', ([field_getresponse_map], schema) => {
      if (Number(field_getresponse_map) === 1)
        return yup.string().required('This field is required');
      return schema;
    })
  });

  const sendEdit = (values) => {
    var allow = 1;
    var allow2 = 1;

    if (values.field_options_text) {
      allow = 0;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'There is a value in the textarea, clear it or insert the value into list by click the check button.'
      });
    }

    if (Number(values.field_type) === 2 || Number(values.field_type) === 3 || Number(values.field_type) === 4) {
      if (values.field_options.length === 0) {
        allow2 = 0;
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please insert at least one option'
        });
      }
    }

    if (allow === 1 && allow2 === 1) {
      handleEditApi(values);
    }
  }

  const handleEditApi = (values) => {
    if (values.field_options) {
      var opt_val = values.field_options.map((data) => {
        return data.value;
      });
    }

    axios.get("ws/ws_custom_field.php", {
      params: {
        task: 14,
        cf: values.field_id,
        company: session.company_id,
        label: values.field_name,
        fbmap: values.field_fb_lead_ad_map,
        fbfield: values.field_fb_lead_ad_name,
        grmap: values.field_getresponse_map,
        grfield: values.field_getresponse_name,
        value: values.field_options ? opt_val.toString() : '',
        pipeid: values.field_pipe,
        compulsory_column: values.field_required,
        reg_form_id: "",
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Successfully updated',
          timer: 1500
        })
        .then(result => {
          setIsEdit(false);
          getCustomField();
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please try again or contact your IT Support'
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: error.message
      });
    });
  }

  const handleDelete = (data) => {

    var cfID = data.cf_id;

    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This field will be deleted',
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: 'Yes, delete it',
      cancelButtonText: 'Cancel'
    })
    .then(result => {
      if (result.isConfirmed) {
        axios.get('ws/ws_custom_field.php', {
          params: {
            task: 7,
            cf: data.cf_id
          }
        })
        .then(res => {
          let data = res.data;

          if (Number(data.status) === 0) {

            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'Successfully deleted',
              timer: 1500
            })
            .then(() => {
              getCustomField();
            });

          }else if(Number(data.status) === 2){

            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'This field has existing data, it can not be deleted',
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: 'Force delete',
              cancelButtonText: 'Cancel'
            })
            .then(result => {
              if (result.isConfirmed) {
                handleForceDel(cfID);
              }
            });

          }else{

            Swal.fire({
              icon: 'error',
              title: 'Failed',
              text: 'Please try again or contact your IT Support'
            });

          }
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: error.message
          });
        });
      }
    });
  }

  const handleForceDel = (id) =>{
    axios.get('ws/ws_custom_field.php',{
      params: {
        task: 15,
        cf: id
      }
    })
    .then(res => {
      let data = res.data;

      if (Number(data.status) === 0) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Successfully deleted',
          timer: 1500
        })
        .then(() => {
          getCustomField();
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: 'Please try again or contact your IT Support'
        });
      }
    })
    .catch(error => {
      Swal.fire({
        icon: 'error',
        title: error.message
      });
    });
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Container fluid={true} className="p-0 m-0">
          <Stack direction="horizontal">
            <Button variant="" className="op-primary-color text-light d-flex align-items-center" onClick={setIsCreate}>
              <FontAwesomeIcon icon={faPlus} size="md" className="me-2" /> Custom Field
            </Button>
          </Stack>

          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact columnDefs={gridColumn} rowData={rowData} defaultColDef={gridColDef} rowHeight={50} pagination={false} />
            </div>
          </div>
        </Container>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik
          validationSchema={addFieldSchema}
          onSubmit={sendAddField}
          initialValues={{
            field_name: "",
            field_type: "",
            field_opt: "",
            field_show_add: "no",
            field_detail_view: "no",
            field_required: 0,
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Add Deal Field</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xxl={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Field Name *</Form.Label>
                      <Form.Control name="vendor_name" type="text" value={values.field_name} isInvalid={errors.field_name && touched.field_name} onChange={handleChange("field_name")} />
                      {errors.field_name && touched.field_name && <div className="op-error-message">{errors.field_name}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Field Type *</Form.Label>
                      <FormSelect 
                        options={fieldTypeList}
                        isSearchable={true}
                        isClearable={true}
                        isInvalid={errors.field_type && touched.field_type}
                        onChange={(e) => {
                          setFieldValue('field_type', e ? e.value : '');
                          if (Number(values.field_type) === 4 || Number(values.field_type) === 3 || Number(values.field_type) === 2) {
                            setFieldValue('field_opt', "");
                          }
                        }} 
                      />
                      {errors.field_type && touched.field_type && <div className="op-error-message">{errors.field_type}</div>}
                    </Form.Group>
                  </Col>
                  <Col xxl={6}>
                    {(Number(values.field_type) === 4 || Number(values.field_type) === 3 || Number(values.field_type) === 2) && <Form.Group className="mb-3">
                      <Form.Label>Options *</Form.Label>
                      <Form.Control
                        as={"textarea"}
                        rows={5}
                        isInvalid={errors.field_opt && touched.field_opt}
                        onChange={(e) => {setFieldValue('field_opt', e.target.value)}}
                      />
                      {errors.field_opt && touched.field_opt && <div className="op-error-message">{errors.field_opt}</div>}
                    </Form.Group>}
                    <Form.Group className="mb-1">
                      <Form.Label>Quality Rules</Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        type="switch"
                        label="Mark as required"
                        onChange={(e) => {setFieldValue('field_required', e.target.checked ? 1 : 0)}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        type="switch"
                        label="Show in add new dialog"
                        onChange={(e) => {setFieldValue('field_show_add', e.target.checked ? 'yes': 'no')}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Check
                        type="switch"
                        label="Show in details view"
                        onChange={(e) => {setFieldValue('field_detail_view', e.target.checked ? 'yes': 'no')}}
                      />
                    </Form.Group>
                  </Col>
                  <div className="op vertical fluid divider"></div>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
          <Formik
            validationSchema={editSchema}
            onSubmit={sendEdit}
            initialValues={editInit}
          >
            {({ handleSubmit, setFieldValue, handleChange, values, errors, touched }) => (
              <Form noValidate onSubmit={handleSubmit}>
                  <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>Edit Custom Field</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row sm={12}>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Field Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.field_name}
                            isInvalid={errors.field_name && touched.field_name}
                            onChange={(e) => {setFieldValue('field_name', e.target.value)}}
                          />
                          {errors.field_name && touched.field_name && <div className="op-error-message">{errors.field_name}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Field pipeline</Form.Label>
                          <FormSelect
                            placeholder="All pipeline"
                            options={pipeList}
                            valueDefault={pipeList.filter(opt => opt.value === values.field_pipe)}
                            isSearchable={true}
                            isClearable={true}
                            onChange={(e) => {setFieldValue('field_pipe', e ? e.value : 0)}}
                          />
                        </Form.Group>
                        {values.field_options && <Form.Group className="mb-3">
                          <Form.Label>Options</Form.Label>
                          <div className="mb-2">
                            {values.field_options.length > 0 && values.field_options.map((data, index) => (
                              <Stack key={index} direction="horizontal" gap={2} className="mb-2">
                                <Form.Group className="w-100">
                                  <Form.Control
                                    type="text"
                                    value={data.value}
                                    onChange={(e) => {
                                      values.field_options.forEach((rec) => {
                                        if (rec.value === data.value) {
                                          rec.value = e.target.value;
                                          rec.label = e.target.value;
                                        }
                                      });

                                      setFieldValue('field_options', values.field_options);
                                    }}
                                  />
                                </Form.Group>
                                <button 
                                  className="btn btn-light"
                                  type="button"
                                  onClick={() => {
                                    var newList = values.field_options.filter(opt => opt.value !== data.value);
                                    setFieldValue('field_options', newList);
                                  }}
                                >
                                  <FontAwesomeIcon 
                                    icon={faTrash} 
                                    size="lg" 
                                    className="fontAwesomeIcon"
                                  />
                                </button>
                              </Stack>
                            ))}
                          </div>
                          <Form.Control
                            as={'textarea'}
                            rows={5}
                            value={values.field_options_text}
                            onChange={(e) => {setFieldValue('field_options_text', e.target.value)}}
                          />
                          {values.field_options_text && 
                            <Stack direction="horizontal" gap={1}>
                                <button
                                  className="btn btn-light mt-2 ms-auto"
                                  type="button"
                                  onClick={() => {
                                    var textAreaArray = values.field_options_text.split("\n");
                                    var newTextAreaArray = textAreaArray.map((text) => {
                                      return {label: text, value: text};
                                    });
                                    var newArray = [...values.field_options, ...newTextAreaArray];
                                    setFieldValue('field_options', newArray);
                                    setFieldValue('field_options_text', '');
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCheck} size="lg"/>
                                </button>
                            </Stack>
                          }
                        </Form.Group>}
                      </Col>
                      <Col sm={6}>
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="switch"
                            label="Map FB Field"
                            checked={Number(values.field_fb_lead_ad_map) === 0 ? false : true}
                            onChange={(e) => {
                              setFieldValue('field_fb_lead_ad_map', e.target.checked ? 1 : 0);
                              setFieldValue('field_fb_lead_ad_name', '');
                            }}
                          />
                        </Form.Group>
                        {Number(values.field_fb_lead_ad_map) === 1 && <Form.Group className="mb-3">
                          <Form.Label>FB Field Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.field_fb_lead_ad_name}
                            isInvalid={errors.field_fb_lead_ad_name && touched.field_fb_lead_ad_name}
                            onChange={(e) => {setFieldValue('field_fb_lead_ad_name', e.target.value)}}
                          />
                          {errors.field_fb_lead_ad_name && touched.field_fb_lead_ad_name && <div className="op-error-message">{errors.field_fb_lead_ad_name}</div>}
                        </Form.Group>}
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="switch"
                            label="Map Getresponse Field"
                            checked={Number(values.field_getresponse_map) === 0 ? false : true}
                            onChange={(e) => {
                              setFieldValue('field_getresponse_map', e.target.checked ? 1 : 0);
                              setFieldValue('field_getresponse_name', '');
                            }}
                          />
                        </Form.Group>
                        {Number(values.field_getresponse_map) === 1 && <Form.Group className="mb-3">
                          <Form.Label>Getresponse field name</Form.Label>
                          <Form.Control
                            type="text"
                            value={values.field_getresponse_name}
                            isInvalid={errors.field_getresponse_name && touched.field_getresponse_name}
                            onChange={(e) => {setFieldValue('field_getresponse_name', e.target.value)}}
                          />
                          {errors.field_getresponse_name && touched.field_getresponse_name && <div className="op-error-message">{errors.field_getresponse_name}</div>}
                        </Form.Group>}
                        <Form.Group>
                          <Form.Check
                            type="switch"
                            label='required'
                            checked={Number(values.field_required) === 1 ? true : false}
                            onChange={(e) => {
                              setFieldValue('field_required', e.target.checked ? 1 : 0);
                              // handleChange('field_required')(e.target.checked ? 1 : 0);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="op vertical fluid divider"></div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                  </Modal.Footer>
              </Form>
            )}
          </Formik>
      </Modal>
    </div>
  );
};

export default CustomFieldBooking;
