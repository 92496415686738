import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row, Stack } from "react-bootstrap";
import Loader from "../../includes/Loader";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/keybinding-sublime";
import { immediateToast } from "izitoast-react";
import * as formik from "formik";
import { FormSelect } from "../../includes/FormCustom";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";

const BookingContact = () => {
  const { Formik } = formik;
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [phase, setPhase] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState({ label: "", value: "" });
  const [dataRecord, setDataRecord] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({ rea_contact_id: "", contact_title: "", contact_value: "" });

  // GET FUNCTION ===================================================

  const getPhase = async () => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetPhaseLight",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setPhase(data.record);
        getData(data.record[0]);
        setSelectedPhase(data.record[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = useCallback(async (phase) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "GetContact",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          rea_phase_id: phase.value,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setDataRecord(data.record);
      } else {
        setDataRecord([]);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // OPEN FUNCTION ===================================================

  const openEdit = (record) => {
    setInitialValues({
      rea_contact_id: record.rea_contact_id,
      contact_title: record.contact_title,
      contact_value: record.contact_value,
    });

    setIsEdit(true);
  };

  // ONCHANGE ========================================================

  const onchangePhase = (e) => {
    setSelectedPhase(e);
    getData(e);
  };

  // SEND FUNCTION ===================================================

  const sendCreate = useCallback(
    async (values) => {
      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "CreateContact",
            contact_title: values.contact_title,
            contact_value: values.contact_value,
            rea_phase_id: selectedPhase.value,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          getData(selectedPhase);
          setIsCreate(false);
          immediateToast("success", {
            title: "Success",
            message: "Successfully created",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed updated",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [selectedPhase, session]
  );

  const sendEdit = useCallback(async (values) => {
    try {
      const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
        params: {
          task: "UpdateContact",
          contact_title: values.contact_title,
          contact_value: values.contact_value,
          rea_contact_id: values.rea_contact_id,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        getData(selectedPhase);
        setIsEdit(false);
        immediateToast("success", {
          title: "Success",
          message: "Successfully updated",
          timeout: 2000,
        });
      } else {
        immediateToast("error", {
          title: "Failed",
          message: "Failed updated",
          timeout: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [session, selectedPhase]);

  const sendDelete = useCallback(
    async (values) => {
      try {
        const response = await axios.get("https://www.nexcrmapis.com/cloud_staging/ws/booking_settings.php", {
          params: {
            task: "RemoveContact",
            rea_contact_id: values.rea_contact_id,
            utoken: session.user_ac_token,
            ctoken: session.company_token,
          },
        });

        const data = response.data;
        if (data.status === 0) {
          setDataRecord(dataRecord.filter((record) => Number(record.rea_contact_id) !== Number(values.rea_contact_id)));
          setIsEdit(false);
          immediateToast("success", {
            title: "Success",
            message: "Successfully deleted",
            timeout: 2000,
          });
        } else {
          immediateToast("error", {
            title: "Failed",
            message: "Failed deleted",
            timeout: 2000,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dataRecord]
  );

  // EDUCATION GRID ==================================================

  const containterStyle = useMemo(() => ({ width: "100%", height: "75vh" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 70,
      cellClass: "center",
      headerClass: "center",
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Contact Title",
      field: "contact_title",
      autoHeight: true,
      wrapText: true,
      cellStyle: { lineHeight: 1.5 },
    },
    {
      headerName: "Contact Value",
      field: "contact_value",
    },
    {
      headerName: "",
      field: "",
      pinned: "right",
      maxWidth: 100,
      cellRenderer: (params) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Button variant="primary" size="sm" onClick={() => openEdit(params.data)}>
              <FontAwesomeIcon icon={faPencil} size="sm" />
            </Button>
            <Button variant="danger" size="sm" className="ms-2">
              <FontAwesomeIcon icon={faTrash} size="sm" onClick={() => sendDelete(params.data)} />
            </Button>
          </div>
        );
      },
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.rea_contact_id.toString();
  }, []);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  // USEEFFECT FUNCTION ----------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getPhase();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };
    initData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="m-0 p-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Stack direction="horizontal" gap={2} className="mb-4">
            <Button variant="" className="op-primary-color text-light" onClick={setIsCreate}>
              Create
            </Button>
            <div className="ms-auto">
              <FormSelect options={phase} valueDefault={phase[0]} onChange={onchangePhase} width="250px" />
            </div>
          </Stack>

          <div style={containterStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact defaultColDef={gridColDef} rowData={dataRecord} columnDefs={gridColumn} getRowId={gridRowId} rowHeight={50} suppressRowClickSelection={false} animateRows={true} />
            </div>
          </div>
        </div>
      )}

      <Modal show={isCreate} onHide={setIsCreate} size="lg">
        <Formik onSubmit={sendCreate} initialValues={{ contact_title: "", contact_value: "" }}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>New</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Title</Form.Label>
                  <Form.Control type="text" value={values.contact_title} onChange={handleChange("contact_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Value</Form.Label>
                  <Form.Control type="text" value={values.contact_value} onChange={handleChange("contact_value")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEdit} onHide={setIsEdit} size="lg">
        <Formik onSubmit={sendEdit} initialValues={initialValues}>
          {({ handleSubmit, handleChange, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title as={"h6"}>Edit</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Title</Form.Label>
                  <Form.Control type="text" value={values.contact_title} onChange={handleChange("contact_title")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Contact Value</Form.Label>
                  <Form.Control type="text" value={values.contact_value} onChange={handleChange("contact_value")} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default BookingContact;
