import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Card, Modal, Container, Stack } from "react-bootstrap";
import { FormSelect, FormDate } from "../includes/FormCustom";
import { AreaChart, Area, PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar } from "recharts";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import { setCurrency } from "../lib/js/Function";
import { AgGridReact } from "ag-grid-react";
import "../lib/css/Statistics.css";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarCircle, faHandshake, faHandshakeSlash } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from 'react-to-print';


const Statistics = () => {
  const { t } = useTranslation();
  const targetRef = useRef();
  const { session } = useAuth();
  const initialized = useRef(false);
  const [user, setuser] = useState([{ value: '', label: t("statistics_all_user") }]);
  const [pipeline, setpipeline] = useState([{ value: '', label: t("statistics_all_pipeline") }]);
  const [selectedUser, setSelectedUser] = useState({ value: '', label: t("statistics_all_user") });
  const [selectedPipeline, setSelectedPipeline] = useState({ value: '', label: t("statistics_all_pipeline") });
  const [wondeal, setwondeal] = useState(null);
  const [lostdeal, setlostdeal] = useState(null);
  const [wonlost, setwonlost] = useState(null);
  const [dealcount, setdealcount] = useState(null);
  const [activityData, setActivityData] = useState([]);
  // const [activityTypeId, setActivityTypeId] = useState([]);
  const [secondActivityData, setSecondActivityData] = useState([]);
  const [opendeal, setopendeal] = useState([]);
  const [dealSourceWonData, setDealSourceWonData] = useState([]);
  const [callTrackingData, setCallTrackingData] = useState([]);
  const [callTrackingWonData, setCallTrackingWonData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [monthlyDealData, setMonthlyDealData] = useState({});
  const [salesPersonWonData, setSalesPersonWonData] = useState([]);
  const [salesPersonAddedData, setSalesPersonAddedData] = useState([]);
  const [selectedUserValue, setSelectedUserValue] = useState('');
  const [selectedPipelineValue, setSelectedPipelineValue] = useState('');

  const [fetchClosedDealError, setFetchClosedDealError] = useState(false);
  const [fetchOpenDealError, setFetchOpenDealError] = useState(false);
  const [fetchWonDealError, setFetchWonDealError] = useState(false);
  const [fetchTrackingWonDealError, setFetchTrackingWonDealError] = useState(false);
  const [fetchPipelineError, setFetchPipelineError] = useState(false);
  const [loading, setLoading] = useState(true);


  // START/END DATE
  const today = new Date();
  const formattedToday = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

  const oneYearAgo = new Date();
  oneYearAgo.setMonth(oneYearAgo.getMonth() - 12);
  const formattedOneYearAgo = `${oneYearAgo.getDate()}-${oneYearAgo.getMonth() + 1}-${oneYearAgo.getFullYear()}`

  const [dateRange, setdateRange] = useState({
    ds: formattedOneYearAgo,
    de: formattedToday,
  });

  const [showModal, setShowModal] = useState(false);
  const [dealModalData, setDealModalData] = useState([]);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [showWonModal, setShowWonModal] = useState(false);
  const [wonModalData, setWonModalData] = useState([]);
  const openWonModal = () => setShowWonModal(true);
  const closeWonModal = () => setShowWonModal(false);

  const [showLostModal, setShowLostModal] = useState(false);
  const [lostModalData, setLostModalData] = useState([]);
  const openLostModal = () => setShowLostModal(true);
  const closeLostModal = () => setShowLostModal(false);

  // activity added
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [activityModalData, setActivityModalData] = useState([]);
  const openActivityModal = () => setShowActivityModal(true);
  const closeActivityModal = () => setShowActivityModal(false);

  // activity completed
  const [showActivityCompletedModal, setShowActivityCompletedModal] = useState(false);
  const [activityCompletedModalData, setActivityCompletedModalData] = useState([]);
  const openActivityCompletedModal = () => setShowActivityCompletedModal(true);
  const closeActivityCompletedModal = () => setShowActivityCompletedModal(false);

  const [showDealSourceModal, setShowDealSourceModal] = useState(false);
  const [dealSourceModalData, setDealSourceModalData] = useState([]);
  const openDealSourceModal = () => setShowDealSourceModal(true);
  const closeDealSourceModal = () => setShowDealSourceModal(false);

  const [showWonSourceModal, setShowWonSourceModal] = useState(false);
  const [wonSourceModalData, setWonSourceModalData] = useState([]);
  const openWonSourceModal = () => setShowWonSourceModal(true);
  const closeWonSourceModal = () => setShowWonSourceModal(false);

  const [showPipelineSourceModal, setShowPipelineSourceModal] = useState(false);
  const [pipelineSourceModalData, setPipelineSourceModalData] = useState([]);
  const openPipelineSourceModal = () => setShowPipelineSourceModal(true);
  const closePipelineSourceModal = () => setShowPipelineSourceModal(false);

  // won
  const [showProgressWonModal, setShowProgressWonModal] = useState(false);
  const [progressWonModalData, setProgressWonModalData] = useState([]);
  const openProgressWonModal = () => setShowProgressWonModal(true);
  const closeProgressWonModal = () => setShowProgressWonModal(false);

  // lost
  const [showProgressLostModal, setShowProgressLostModal] = useState(false);
  const [progressLostModalData, setProgressLostModalData] = useState([]);
  const openProgressLostModal = () => setShowProgressLostModal(true);
  const closeProgressLostModal = () => setShowProgressLostModal(false);

  // (total deal)
  const [showWonRepModal, setShowWonRepModal] = useState(false);
  const [wonRepModalData, setWonRepModalData] = useState([]);
  const openWonRepModal = () => setShowWonRepModal(true);
  const closeWonRepModal = () => setShowWonRepModal(false);

  // (rm)
  const [showWonRepRmModal, setShowWonRepRmModal] = useState(false);
  const [wonRepRmModalData, setWonRepRmModalData] = useState([]);
  const openWonRepRmModal = () => setShowWonRepRmModal(true);
  const closeWonRepRmModal = () => setShowWonRepRmModal(false);

  // (total deal)
  const [showDealAddedModal, setShowDealAddedModal] = useState(false);
  const [dealAddedModalData, setDealAddedModalData] = useState([]);
  const openDealAddedModal = () => setShowDealAddedModal(true);
  const closeDealAddedModal = () => setShowDealAddedModal(false);

  // (rm)
  const [showDealAddedRmModal, setShowDealAddedRmModal] = useState(false);
  const [dealAddedRmModalData, setDealAddedRmModalData] = useState([]);
  const openDealAddedRmModal = () => setShowDealAddedRmModal(true);
  const closeDealAddedRmModal = () => setShowDealAddedRmModal(false);

  const cToken = session.company_token;
  const uToken = session.user_ac_token;
  const companyId = session.company_id;

  const RADIAN = Math.PI / 180;

  const [clickedDealName, setClickedDealName] = useState('');
  let dateRangeValues = {};
  const getLoginUser = session.role_id == "3" ? session.user_id : "";




  // GET USER
  const getUser = () => {
    const userUrl = 'ws/ws_user.php';
    const params = {
      ctoken: cToken,
      task: '4b',
      utoken: uToken,
    };

    axios.get(userUrl, { params })
      .then((response) => {
        var data = response.data;
        const users = data.record.map((user) => ({ value: user.user_id, label: user.user_name }));
        setuser([{ value: '', label: t("statistics_all_user") }, ...users]);
      })
      .catch((error) => {
        console.error("Error fetching project options:", error);
      });
  };

  // GET PIPELINE
  const getPipeline = () => {
    const pipelineUrl = 'ws/ws_setting.php';
    const params = {
      company: companyId,
      task: 'getAllPipeline',
    };

    axios.get(pipelineUrl, { params })
      .then((response) => {
        var data = response.data;
        const pipelines = data.record.map((pipeline) => ({ value: pipeline.pipeline_id, label: pipeline.pipeline_title }));
        setpipeline([{ value: '', label: t("statistics_all_pipeline") }, ...pipelines]);
      })
      .catch((error) => {
        console.error("Error fetching project options:", error);
      });
  };

  // GET WON/LOST DEAL (CLOSED DEAL)
  const getWonLost = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const wonlostUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      utoken: uToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: '1',
      user: user || getLoginUser,
    };

    axios.get(wonlostUrl, { params })
      .then((response) => {
        const data = response.data;
        const pieData = data.record.map(entry => ({
          name: entry.STATUS === 'won' ? 'Won Deals' : 'Lost Deals',
          value: parseInt(entry.TOTAL_DEAL, 10),
        }));

        const wonDeal = data.record.find(entry => entry.STATUS === 'won');
        const lostDeal = data.record.find(entry => entry.STATUS === 'lost');

        setwonlost(pieData);
        setwondeal(wonDeal);
        setlostdeal(lostDeal);
        setFetchClosedDealError(false)
      })
      .catch((error) => {
        console.error("Error fetching won/lost count:", error);
        setFetchClosedDealError(true)
      });
  };

  const getWonDataForModal = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'won',
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setWonModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  const getLostDataForModal = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'lost',
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setLostModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // GET DEAL
  const getDealCount = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const dealcountUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      utoken: uToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: '2',
      user: user || getLoginUser,
    };

    axios.get(dealcountUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setdealcount(data);
      })
      .catch((error) => {
        console.error("Error fetching deal count:", error);
      });
  };

  const getDealDataForModal = (dateRange = {}, user, pipeline) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      ctoken: cToken,
      de: endDate,
      dealadded: 1,
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setDealModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // ACTIVITIES ADDED
  const getActivityData = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const activityUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      utoken: uToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: '4',
      user: user || getLoginUser,
    };

    axios.get(activityUrl, { params })
      .then((response) => {
        const data = response.data.record;
        // const activityTypeId = data.map(activity => activity.ACTIVITY_TYPE_ID);

        setActivityData(data);
        // setActivityTypeId(activityTypeId);
      })
      .catch((error) => {
        console.error("Error fetching activity data:", error);
      });
  };

  const fetchSecondActivityData = async (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const taskTypeUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      utoken: uToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: '5',
      user: user || getLoginUser,
    };

    axios.get(taskTypeUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setSecondActivityData(data);
      })
      .catch((error) => {
        console.error("Error fetching activity data:", error);
      });
  };

  // Activities Added Area click
  const handleAddedClick = (data, event) => {
    const clientX = event.clientX;
    const chartContainer = document.getElementById("activity");

    if (chartContainer) {
      const containerWidth = chartContainer.offsetWidth;
      const containerRect = chartContainer.getBoundingClientRect();
      const clickRatio = (clientX - containerRect.left) / containerWidth;
      const clickedIndex = Math.floor(clickRatio * (data.points.length - 1));
      const activityTypeId = data.points[clickedIndex].payload.activityTypeId;


      Promise.all([
        fetchSecondActivityData(selectedUserValue, selectedPipelineValue, dateRange),
        getActivityDataForModal(activityTypeId, dateRange, selectedPipelineValue, selectedUserValue)
      ])
        .then(() => {
          openActivityModal();
        })
        .catch((error) => {
          console.error('Error handling pie click:', error);
        });
    }
  };

  const handleCloseAddedModal = () => {
    fetchSecondActivityData(selectedUserValue, selectedPipelineValue, dateRange);
    closeActivityModal();
  };

  const getActivityDataForModal = (activityTypeId, dateRange = {}, pipeline, user) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_activityreport.php';
    const params = {
      ctoken: cToken,
      dat: activityTypeId,
      de: endDate,
      ds: startDate,
      mad: '',
      pipeid: pipeline,
      task: '1',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setActivityModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // Activities Completed Area click
  const handleCompletedClick = (data, event) => {
    const clientX = event.clientX;
    const chartContainer = document.getElementById("activity");

    if (chartContainer) {
      const containerWidth = chartContainer.offsetWidth;
      const containerRect = chartContainer.getBoundingClientRect();
      const clickRatio = (clientX - containerRect.left) / containerWidth;
      const clickedIndex = Math.floor(clickRatio * (data.points.length - 1));
      const activityTypeId = data.points[clickedIndex].payload.activityTypeId;


      Promise.all([
        getActivityData(selectedUserValue, selectedPipelineValue, dateRange),
        getActivityCompletedDataForModal(activityTypeId, dateRange, selectedPipelineValue, selectedUserValue)
      ])
        .then(() => {
          openActivityCompletedModal();
        })
        .catch((error) => {
          console.error('Error handling pie click:', error);
        });
    }
  };

  const handleCloseCompletedModal = () => {
    getActivityData(selectedUserValue, selectedPipelineValue, dateRange);
    closeActivityCompletedModal();
  };

  const getActivityCompletedDataForModal = (activityTypeId, dateRange = {}, pipeline, user) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_activityreport.php';
    const params = {
      ctoken: cToken,
      dat: activityTypeId,
      de: endDate,
      ds: startDate,
      mad: 'yes',
      pipeid: pipeline,
      task: '1',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setActivityCompletedModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // OPEN DEAL
  const getDealSourceData = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const dealSourceUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      utoken: uToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'dealSource',
      user: user || getLoginUser,
    };

    axios.get(dealSourceUrl, { params })
      .then((response) => {
        const data = response.data.record;
        const pieChartData = data.map(entry => ({
          id: entry.ds_id,
          name: entry.ds_title,
          value: parseInt(entry.total_deal, 10),
        }));
        setopendeal(pieChartData);
        setFetchOpenDealError(false);
      })
      .catch((error) => {
        console.error("Error fetching deal source data:", error);
        setFetchOpenDealError(true);
      });
  };

  const handleDealClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.payload.id;
    const clickedName = clickedData.payload.name;
    setClickedDealName(clickedName);

    Promise.all([
      getDealSourceData(selectedUserValue, selectedPipelineValue, dateRangeValues),
      getDealForModal(dateRange, selectedUserValue, selectedPipelineValue, clickedId)
    ])
      .then(() => {
        openDealSourceModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleCloseDealModal = () => {
    getDealSourceData(selectedUserValue, selectedPipelineValue, dateRange);
    closeDealSourceModal();
  };

  const getDealForModal = (dateRange = {}, user, pipeline, clickedId) => {
    const endDate = dateRange.de || formattedToday;
    const startDate = dateRange.ds || formattedOneYearAgo;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      ctoken: cToken,
      dsid: clickedId,
      pipeid: pipeline,
      de: endDate,
      ds: startDate,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setDealSourceModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // WON DEAL
  const getDealSourceWonData = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const dealSourceWonUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'dealSource_won',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(dealSourceWonUrl, { params })
      .then((response) => {
        const data = response.data.record;
        const pieChartData = data.map(entry => ({
          id: entry.ds_id,
          name: entry.ds_title,
          value: parseInt(entry.total_deal, 10),
        }));
        setDealSourceWonData(pieChartData);
        setFetchWonDealError(false);
      })
      .catch((error) => {
        console.error("Error fetching deal source (won) data:", error);
        setFetchWonDealError(true);
      });
  };

  const handleWonClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.payload.id;
    const clickedName = clickedData.payload.name;
    setClickedDealName(clickedName);

    Promise.all([
      getDealSourceWonData(selectedUserValue, selectedPipelineValue, dateRangeValues),
      getWonForModal(selectedPipelineValue, dateRange, clickedId, selectedUserValue)
    ])
      .then(() => {
        openWonSourceModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleWonModal = () => {
    getDealSourceWonData(selectedUserValue, selectedPipelineValue, dateRange);
    closeWonSourceModal();
  };

  const getWonForModal = (pipeline, dateRange = {}, clickedId, user) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'won',
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      dsid: clickedId,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setWonSourceModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // CALL TRACKING (ALL LEAD)
  const getCallTrackingData = (user, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const callTrackingUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      task: 'callTracking_stat',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(callTrackingUrl, { params })
      .then((response) => {
        const data = response.data.record;
        const pieChartData = data.map(entry => ({
          name: entry.ds_title,
          value: parseInt(entry.total_deal, 10),
        }));
        setCallTrackingData(pieChartData);
      })
      .catch((error) => {
        console.error("Error fetching call tracking data:", error);
      });
  };

  // CALL TRACKING (WON)
  const getCallTrackingWonData = (user, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const callTrackingWonUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      task: 'callTracking_stat_won',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(callTrackingWonUrl, { params })
      .then((response) => {
        const data = response.data.record;
        const pieChartData = data.map(won => ({
          name: won.ds_title,
          value: parseInt(won.total_deal, 10),
        }));
        setCallTrackingWonData(pieChartData);
        setFetchTrackingWonDealError(false);
      })
      .catch((error) => {
        console.error("Error fetching call tracking data:", error);
        setFetchTrackingWonDealError(true);
      });
  };

  // PIPELINE STAGE
  const getPipelineData = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const pipelineUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'pipeline',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(pipelineUrl, { params })
      .then((response) => {
        const data = response.data.record;
        const pieChartData = data.map(stage => ({
          id: stage.cs_id,
          name: stage.cs_title,
          value: parseInt(stage.total_deal, 10),
        }));
        setPipelineData(pieChartData);
        setFetchPipelineError(false);
      })
      .catch((error) => {
        console.error("Error fetching pipeline data:", error);
        setFetchPipelineError(true);
      });
  };

  const handleClosePipelineModal = () => {
    getPipelineData(selectedUserValue, selectedPipelineValue, dateRange);
    closePipelineSourceModal();
  };

  const handlePipelineClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.payload.id;
    const clickedName = clickedData.payload.name;
    setClickedDealName(clickedName);

    Promise.all([
      getPipelineData(selectedUserValue, selectedPipelineValue, dateRangeValues),
      getPipelineForModal(selectedUserValue, dateRange, clickedId)
    ])
      .then(() => {
        openPipelineSourceModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const getPipelineForModal = (user, dateRange = {}, clickedId) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: '',
      cs: clickedId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setPipelineSourceModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // PROGRESS
  const getMonthlyDealData = (user, pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const monthlyDealUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 3,
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(monthlyDealUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setMonthlyDealData(data);
      })
      .catch((error) => {
        console.error("Error fetching monthly deal data:", error);
      });
  };

  // (WON)
  const handleProgressWonClick = (data) => {
    const clickedData = data;
    const clickDateStart = clickedData.DATE_START;
    const clickDateEnd = clickedData.DATE_END;

    const formattedDate = moment(clickDateEnd).format('MMMM YYYY');
    setClickedDealName(formattedDate);

    Promise.all([
      getMonthlyDealData(selectedUserValue, selectedPipelineValue, dateRangeValues),
      getProgressWonForModal(clickDateEnd, clickDateStart, selectedPipelineValue, selectedUserValue)
    ])
      .then(() => {
        openProgressWonModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const getProgressWonForModal = (clickDateEnd, clickDateStart, pipeline, user) => {
    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'won',
      ctoken: cToken,
      de: clickDateEnd,
      ds: clickDateStart,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setProgressWonModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // (LOST)
  const handleProgressLostClick = (data) => {
    const clickedData = data;
    const clickDateStart = clickedData.DATE_START;
    const clickDateEnd = clickedData.DATE_END;

    const formattedDate = moment(clickDateEnd).format('MMMM YYYY');
    setClickedDealName(formattedDate);

    Promise.all([
      getMonthlyDealData(selectedUserValue, selectedPipelineValue, dateRangeValues),
      getProgressLostForModal(clickDateEnd, clickDateStart, selectedPipelineValue, selectedUserValue)
    ])
      .then(() => {
        openProgressLostModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const getProgressLostForModal = (clickDateEnd, clickDateStart, pipeline, user) => {
    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'lost',
      ctoken: cToken,
      de: clickDateEnd,
      ds: clickDateStart,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: user || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setProgressLostModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };


  // WON DEAL BY SALES REP (TOTAL DEAL)
  const getWonDealByRep = (pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const wonDealRepUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'salesPerson_won',
      utoken: uToken,
    };

    axios.get(wonDealRepUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setSalesPersonWonData(data);
      })
      .catch((error) => {
        console.error("Error fetching rep won deal data:", error);
      });
  };

  // (TOTAL DEAL)
  const handleWonRepClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.SALESPERSON_ID;
    const clickedName = clickedData.SALESPERSON;
    setClickedDealName(clickedName);

    Promise.all([
      getWonDealByRep(selectedPipelineValue, dateRangeValues),
      getWonRepForModal(selectedPipelineValue, dateRange, clickedId)
    ])
      .then(() => {
        openWonRepModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleCloseWonRepModal = () => {
    getWonDealByRep(selectedPipelineValue, dateRange);
    closeWonRepModal();
  };

  const getWonRepForModal = (pipeline, dateRange = {}, clickedId) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'won',
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: clickedId || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setWonRepModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // (RM)
  const handleWonRepRmClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.SALESPERSON_ID;
    const clickedName = clickedData.SALESPERSON;
    setClickedDealName(clickedName);

    Promise.all([
      getWonDealByRep(selectedPipelineValue, dateRangeValues),
      getWonRepForRmModal(selectedPipelineValue, dateRange, clickedId)
    ])
      .then(() => {
        openWonRepRmModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleCloseWonRepRmModal = () => {
    getWonDealByRep(selectedPipelineValue, dateRange);
    closeWonRepRmModal();
  };

  const getWonRepForRmModal = (pipeline, dateRange = {}, clickedId) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      archieve: 'won',
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: clickedId || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setWonRepRmModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };


  // DEAL ADDED BY SALES REP (TOTAL DEAL)
  const getDealAddedByRep = (pipeline, dateRange = {}) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const dealAddedRepUrl = 'ws/ws_report.php';
    const params = {
      company: companyId,
      ctoken: cToken,
      de: endDate,
      ds: startDate,
      pipeid: pipeline,
      task: 'salesPerson_newDeal',
      utoken: uToken,
    };

    axios.get(dealAddedRepUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setSalesPersonAddedData(data);
      })
      .catch((error) => {
        console.error("Error fetching rep won deal data:", error);
      });
  };

  // (TOTAL DEAL)
  const handleDealAddedClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.SALESPERSON_ID;
    const clickedName = clickedData.SALESPERSON;
    setClickedDealName(clickedName);

    Promise.all([
      getDealAddedByRep(selectedPipelineValue, dateRangeValues),
      getDealAddedForModal(selectedPipelineValue, dateRange, clickedId)
    ])
      .then(() => {
        openDealAddedModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleCloseDealAddedModal = () => {
    getDealAddedByRep(selectedPipelineValue, dateRange);
    closeDealAddedModal();
  };

  const getDealAddedForModal = (pipeline, dateRange = {}, clickedId) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      ctoken: cToken,
      de: endDate,
      dealadded: '1',
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: clickedId || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setDealAddedModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // (RM)
  const handleDealAddedRmClick = (data) => {
    const clickedData = data;
    const clickedId = clickedData.SALESPERSON_ID;
    const clickedName = clickedData.SALESPERSON;
    setClickedDealName(clickedName);

    Promise.all([
      getDealAddedByRep(selectedPipelineValue, dateRangeValues),
      getDealAddedRmForModal(selectedPipelineValue, dateRange, clickedId)
    ])
      .then(() => {
        openDealAddedRmModal();
      })
      .catch((error) => {
        console.error('Error handling pie click:', error);
      });
  };

  const handleCloseDealAddedRmModal = () => {
    getDealAddedByRep(selectedPipelineValue, dateRange);
    closeDealAddedRmModal();
  };

  const getDealAddedRmForModal = (pipeline, dateRange = {}, clickedId) => {
    const startDate = dateRange.ds || formattedOneYearAgo;
    const endDate = dateRange.de || formattedToday;

    const modalUrl = 'ws/ws_deal.php';
    const params = {
      ctoken: cToken,
      de: endDate,
      dealadded: '1',
      ds: startDate,
      pipeid: pipeline,
      task: 'listDealsForReport',
      user: clickedId || getLoginUser,
      utoken: uToken,
    };

    axios.get(modalUrl, { params })
      .then((response) => {
        const data = response.data.record;
        setDealAddedRmModalData(data);
      })
      .catch((error) => {
        console.error('Error fetching data for modal:', error);
      });
  };

  // USEEFFECT
  useEffect(() => {
    if (!initialized.current) {
      setLoading(true);
      initialized.current = true;
      getUser(session);
      getPipeline(session);
      getWonLost(selectedUserValue, selectedPipelineValue, dateRange);
      getWonDataForModal(selectedUserValue, selectedPipelineValue, dateRange);
      getLostDataForModal(selectedUserValue, selectedPipelineValue, dateRange);

      getDealCount(selectedUserValue, selectedPipelineValue, dateRange);
      getDealDataForModal(dateRange, selectedUserValue, selectedPipelineValue);

      getActivityData(selectedUserValue, selectedPipelineValue, dateRange);
      fetchSecondActivityData(selectedUserValue, selectedPipelineValue, dateRange);
      getActivityDataForModal(selectedUserValue, selectedPipelineValue, dateRange);

      getDealSourceData(selectedUserValue, selectedPipelineValue, dateRange);
      getDealSourceWonData(selectedUserValue, selectedPipelineValue, dateRange);
      getCallTrackingData(selectedUserValue, dateRange);
      getCallTrackingWonData(selectedUserValue, dateRange);
      getPipelineData(selectedUserValue, selectedPipelineValue, dateRange);
      getMonthlyDealData(selectedUserValue, selectedPipelineValue, dateRange);
      getWonDealByRep(selectedPipelineValue, dateRange);
      getDealAddedByRep(selectedPipelineValue, dateRange);

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [session, selectedUserValue, selectedPipelineValue, dateRange]);  // eslint-disable-line react-hooks/exhaustive-deps

  //  DATE RANGE FILTER
  const onchangeDateRange = (mode, value) => {
    setdateRange((prevState) => ({
      ...prevState,
      [mode]: value,
    }));
  };

  const handleApplyButtonClick = () => {
    setLoading(true);
    const selectedUserValue = selectedUser.value;
    setSelectedUserValue(selectedUserValue);

    const selectedPipelineValue = selectedPipeline.value;
    setSelectedPipelineValue(selectedPipelineValue);

    const dateRangeValues = {
      ds: dateRange.ds,
      de: dateRange.de,
    };

    getDealCount(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getDealDataForModal(dateRangeValues, selectedUserValue, selectedPipelineValue);
    getWonLost(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getWonDataForModal(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getLostDataForModal(selectedUserValue, selectedPipelineValue, dateRangeValues);

    getActivityData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    fetchSecondActivityData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getActivityDataForModal(selectedUserValue, selectedPipelineValue, dateRange);

    getDealSourceData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getDealForModal(dateRangeValues, selectedUserValue, selectedPipelineValue);
    getDealSourceWonData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getWonForModal(selectedUserValue, selectedPipelineValue, dateRangeValues);

    getCallTrackingData(selectedUserValue, dateRangeValues);
    getCallTrackingWonData(selectedUserValue, dateRangeValues);
    getPipelineData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getMonthlyDealData(selectedUserValue, selectedPipelineValue, dateRangeValues);
    getWonDealByRep(selectedPipelineValue, dateRangeValues);

    getDealAddedByRep(selectedPipelineValue, dateRangeValues);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    content: () => targetRef.current,
  });

  // CHART DATA
  const combinedLineChartData = activityData && secondActivityData
    ? activityData.map(activity => {
      const matchingSecondActivity = secondActivityData.find(
        secondActivity => secondActivity.ACTIVITY_NAME === activity.ACTIVITY_NAME
      );

      return {
        name: activity.ACTIVITY_NAME,
        added: parseInt(activity.TOTAL_ACTIVITY, 10),
        completed: matchingSecondActivity ? parseInt(matchingSecondActivity.TOTAL_ACTIVITY, 10) : 0,
        activityTypeId: activity.ACTIVITY_TYPE_ID,
      };
    })
    : [];


  // RANDOM COLOR
  const getRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  // dealTitle column
  const dealTitleRenderer = (params) => {
    if (params.data) {
      const deal_title = params.data.deal_title || '';

      const handleClick = () => {
        if (params.data.deal_id) {
          const url = `${session.origin}/deal/${params.data.deal_id}`;
          window.open(url, '_blank');
        }
      };

      return (
        <div
          className="text-click cursor-pointer text-primary"
          style={{
            whiteSpace: 'normal',
            lineHeight: '1.2'
          }}
          onClick={handleClick}
        >
          {deal_title}
        </div>
      );
    }
    return params.value;
  };

  // prospectName column
  const prospectNameRenderer = (params) => {
    if (params.data) {
      const prospect_name = params.data.prospect_name || '';

      const handleClick = () => {
        if (params.data.prospect_id) {
          const url = `${session.origin}/prospect/${params.data.prospect_id}`;
          window.open(url, '_blank');
        }
      };

      return (
        <div
          className="text-click cursor-pointer text-primary"
          style={{
            whiteSpace: 'normal',
            lineHeight: '1.2'
          }}
          onClick={handleClick}
        >
          {prospect_name}
        </div>
      );
    }
    return params.value;
  };

  // areaDealTitleRenderer column
  const areaDealTitleRenderer = (params) => {
    if (params.data) {
      const deal_title = params.data.deal_title || '';

      const handleClick = () => {
        if (params.data.deal_id) {
          const url = `${session.origin}/deal/${params.data.deal_id}`;
          window.open(url, '_blank');
        }
      };

      return (
        <div
          className="text-click cursor-pointer text-primary"
          style={{
            whiteSpace: 'normal',
          }}
          onClick={handleClick}
        >
          {deal_title}
        </div>
      );
    }
    return params.value;
  };

  // areaProspectNameRenderer column
  const areaProspectNameRenderer = (params) => {
    if (params.data) {
      const prospect_name_alt = params.data.prospect_name_alt || '';

      const handleClick = () => {
        if (params.data.prospect_id_alt) {
          const url = `${session.origin}/prospect/${params.data.prospect_id_alt}`;
          window.open(url, '_blank');
        }
      };

      return (
        <div
          className="text-click cursor-pointer text-primary"
          style={{
            whiteSpace: 'normal',
          }}
          onClick={handleClick}
        >
          {prospect_name_alt}
        </div>
      );
    }
    return params.value;
  };

  // GRID COLUMN
  const gridColumn = [
    { headerName: t("statistics_no"), field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
    {
      headerName: t("statistics_deal_uppercase"),
      field: "",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellRenderer: dealTitleRenderer
    },
    {
      headerName: t("statistics_contact_person"),
      field: "",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellRenderer: prospectNameRenderer
    },
    {
      headerName: t("statistics_remarks"),
      field: "archieve_remark",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.2
      },
      autoHeight: true,
    },
    {
      headerName: t("statistics_owner"),
      field: "user_name",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.2,
      },
    },
    { headerName: t("statistics_value"), field: "deal_value", headerClass: "center", cellClass: "center", filter: 'agTextColumnFilter', },
  ];

  const gridColumnArea = [
    { headerName: t("statistics_no"), field: '', valueFormatter: (params) => params.node.rowIndex + 1, width: 75, cellClass: 'booking-list-font' },
    {
      headerName: t("statistics_deal_uppercase"),
      field: "deal_title",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.2
      },
      cellRenderer: areaDealTitleRenderer
    },
    {
      headerName: t("statistics_prospect"),
      field: "prospect_name_alt",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.2
      },
      cellRenderer: areaProspectNameRenderer
    },
    { headerName: t("statistics_activity"), field: "record_title", headerClass: "center", cellClass: "center", filter: 'agTextColumnFilter', },
    {
      headerName: t("statistics_description"),
      field: "record_description",
      headerClass: "center",
      cellClass: "center",
      filter: 'agTextColumnFilter',
      cellStyle: {
        whiteSpace: 'normal',
        lineHeight: 1.2,
      },
      autoHeight: true,
    },
    { headerName: t("statistics_time"), field: "record_date_time", headerClass: "center", cellClass: "center", filter: 'agTextColumnFilter', },
  ];

  // open & won deal
  const renderCustomizedLabel = (props, index) => {
    const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 18;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const adjustedEy = ey + index * 2;

    if (Number(value) > 5) {
      return (
        <g>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
          <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
          <text x={ex + (cos >= 0 ? 1 : -1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
            {`${payload.name}: ${value}`}
          </text>
        </g>
      );
    }
  };

  // const renderCustomizedLabel2 = (props, index, totalItems, opendeal) => {
  //   if (!opendeal || !Array.isArray(opendeal)) {
  //     return null; // or handle the error as appropriate
  //   }

  //   const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
  //   const RADIAN = Math.PI / 180;
  //   const sin = Math.sin(-RADIAN * midAngle);
  //   const cos = Math.cos(-RADIAN * midAngle);
  //   const delta = Math.abs(1 / cos) + 18;

  //   const sx = cx + outerRadius * cos;
  //   const sy = cy + outerRadius * sin;
  //   const mx = cx + (outerRadius + delta * 1.5) * cos;
  //   const my = cy + (outerRadius + delta * 1.5) * sin;
  //   const ex = mx + Number(cos.toFixed(1)) * 15;
  //   const ey = my;

  //   const textAnchor = cos >= 0 ? "start" : "end";
  //   const adjustedEy = ey + index * 0.2;

  //   // Calculate the highest value and dynamic threshold
  //   const highestValue = Math.max(...opendeal.map(item => item.value));
  //   const minValueThreshold = highestValue / 5;

  //   // Check if the value is above the dynamic threshold or if it's the last label
  //   if (Number(value) > minValueThreshold || index === totalItems - 1) {
  //     return (
  //       <g key={index}>
  //         <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
  //         <rect x={ex + (cos >= 0 ? 1 * 5 : 1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
  //         <text x={ex + (cos >= 0 ? 1 : 1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
  //           {`${payload.name}: ${value}`}
  //         </text>
  //       </g>
  //     );
  //   } else {
  //     return null; // Skip rendering for small values
  //   }
  // };

  const renderCustomizedLabel2 = (props, index, totalItems, dataset) => {
    if (!dataset || !Array.isArray(dataset) || dataset.length === 0) {
      return null; // Handle case when dataset is undefined, not an array, or empty
    }

    const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 18;

    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta * 1.5) * cos;
    const my = cy + (outerRadius + delta * 1.5) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 15;
    const ey = my;

    const textAnchor = cos >= 0 ? "start" : "end";
    const adjustedEy = ey + index * 0.2;

    // Calculate the highest value and dynamic threshold
    const highestValue = Math.max(...dataset.map(item => item.value), 0); // Ensure no negative highestValue
    const minValueThreshold = highestValue / 5;

    // Check if the value is above the dynamic threshold or if it's the last label
    if (Number(value) > minValueThreshold || index === totalItems - 1) {
      return (
        <g key={index}>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
          <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
          <text x={ex + (cos >= 0 ? 1 : -1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
            {`${payload.name}: ${value}`}
          </text>
        </g>
      );
    } else {
      return null; // Skip rendering for small values
    }
  };

  const symbol = session.default_lang === 'vi' ? 'VND' :
    session.default_lang === 'au' ? 'AUD' : 'RM';


  return (
    <Container fluid className="p-0 m-0">
      <div className="p-4" style={{ height: "calc(100vh - 56px)", overflow: "auto" }}>
        <Stack className="mb-3" direction="horizontal" gap={3}>
          {session.role_id == '1' && (
            <Form.Group>
              <FormSelect
                options={user}
                value={selectedUser || { value: "", label: t("statistics_all_user") }}
                onChange={(selectedOption) => {
                  setSelectedUser(selectedOption);
                }}
                placeholder={t("statistics_select_user")}
                border={false}
                shadow={true}
                width="250px"
              />
            </Form.Group>
          )}

          <Form.Group>
            <FormSelect
              options={pipeline}
              value={selectedPipeline || { value: "", label: t("statistics_all_pipeline") }}
              onChange={(selectedOption) => {
                setSelectedPipeline(selectedOption);
              }}
              placeholder={t("statistics_select_pipeline")}
              border={false}
              shadow={true}
              width="250px"
            />
          </Form.Group>

          <Form.Group className="ms-auto">
            <Form.Control
              type="date"
              placeholder={t("statistics_from")}
              className="shadow-sm border-0"
              value={moment(dateRange.ds, 'DD-MM-YYYY').format('YYYY-MM-DD')}
              onChange={(e) => {
                onchangeDateRange("ds", moment(e.target.value).format("DD-MM-YYYY"));
              }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Control
              type="date"
              placeholder={t("statistics_to")}
              className="shadow-sm border-0"
              value={moment(dateRange.de, 'DD-MM-YYYY').format('YYYY-MM-DD')}
              onChange={(e) => {
                onchangeDateRange("de", moment(e.target.value).format("DD-MM-YYYY"));
              }}
            />
          </Form.Group>

          <Button variant="" className="op-primary-color text-light" onClick={handleApplyButtonClick}>
            {t("statistics_apply_btn")}
          </Button>

          <Button variant="" className="op-primary-color text-light" onClick={handlePrint}>
            {t("statistics_print")}
          </Button>
        </Stack>

        {loading ? (
          <Loader />
        ) : (
          <div ref={targetRef}>
            <Stack direction="horizontal" gap={3}>
              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-0">
                    {t("statistics_deal")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2 deal-count" onClick={openModal} style={{ cursor: "pointer" }}>
                      <div className="text-danger" style={{ fontSize: "18px" }}>
                        {dealcount && dealcount.TOTAL_DEAL}
                      </div>
                      <div style={{ fontSize: "14px", fontWeight: "600" }}>{dealcount && setCurrency(dealcount.TOTAL_VALUE, session.default_lang)}</div>
                    </div>
                    <FontAwesomeIcon icon={faDollarCircle} size="3x" />
                  </div>
                </Card.Body>
              </Card>

              <Modal show={showModal} onHide={closeModal} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title as={"h6"}>{t("statistics_new_deal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ width: "100%", height: "80vh" }}>
                    <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                      <AgGridReact columnDefs={gridColumn} rowData={dealModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-0">
                    {t("statistics_won_deal")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2 deal-count">
                      {fetchClosedDealError ? (
                        <div></div>
                      ) : (
                        <div onClick={openWonModal} style={{ cursor: "pointer" }}>
                          <div className="text-danger" style={{ fontSize: "18px" }}>
                            {wondeal && wondeal.TOTAL_DEAL}
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "600" }}>{wondeal && setCurrency(wondeal.TOTAL_VALUE, session.default_lang)}</div>
                        </div>
                      )}
                    </div>
                    <FontAwesomeIcon icon={faHandshake} size="3x" />
                  </div>
                </Card.Body>
              </Card>

              <Modal show={showWonModal} onHide={closeWonModal} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title as={"h6"}>{t("statistics_won_deal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ width: "100%", height: "80vh" }}>
                    <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                      <AgGridReact columnDefs={gridColumn} rowData={wonModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>

              <Card className="border-0 shadow-sm w-100">
                <Card.Header className="bg-light">
                  <Card.Title as={"h6"} className="m-0">
                    {t("statistics_lost_deal")}
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="py-2 deal-count">
                      {fetchClosedDealError ? (
                        <div></div>
                      ) : (
                        <div onClick={openLostModal} style={{ cursor: "pointer" }}>
                          <div className="text-danger" style={{ fontSize: "18px" }}>
                            {lostdeal && lostdeal.TOTAL_DEAL}
                          </div>
                          <div style={{ fontSize: "14px", fontWeight: "600" }}>{lostdeal && setCurrency(lostdeal.TOTAL_VALUE, session.default_lang)}</div>
                        </div>
                      )}
                    </div>
                    <FontAwesomeIcon icon={faHandshakeSlash} size="3x" />
                  </div>
                </Card.Body>
              </Card>

              <Modal show={showLostModal} onHide={closeLostModal} size="xl">
                <Modal.Header closeButton>
                  <Modal.Title as={"h6"}>{t("statistics_lost_deal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ width: "100%", height: "80vh" }}>
                    <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                      <AgGridReact columnDefs={gridColumn} rowData={lostModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </Stack>

            <Row>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_closed_deal")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {fetchClosedDealError ? (
                        <div></div>
                      ) : (
                        <PieChart>
                          <Pie
                            data={wonlost}
                            dataKey="value"
                            startAngle={180}
                            endAngle={0}
                            nameKey="name"
                            cx="45%"
                            cy="80%"
                            outerRadius={200}
                            innerRadius={140}
                            fill="#8884d8"
                            label={({ name, value }) => `${name} - (${value})`}
                            labelLine={false}
                          >
                            <Cell key="won" fill="#eb9234" onClick={openWonModal} />
                            <Cell key="lost" fill="#e6176d" onClick={openLostModal} />
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6} className="mt-3" id="activity">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_activities_added")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      <AreaChart data={combinedLineChartData}>
                        <XAxis dataKey="name" />
                        <YAxis type="number" />
                        <Area
                          type="monotone"
                          dataKey="added"
                          stroke="#82ca9d"
                          fill="#d8d8d8"
                          name={t("statistics_activities_added")}
                          onClick={(data, index) => handleAddedClick(data, index)}
                        />
                        <Area
                          type="monotone"
                          dataKey="completed"
                          stroke="#8884d8"
                          fill="#d8d8d8"
                          name={t("statistics_activities_completed")}
                          onClick={(data, index) => handleCompletedClick(data, index)}
                        />
                        <Tooltip />
                        <Legend verticalAlign="top" height={35} />
                      </AreaChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
                {/* Activities Added */}
                <Modal show={showActivityModal} onHide={handleCloseAddedModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_activities_added")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumnArea} rowData={activityModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* Activities Completed */}
                <Modal show={showActivityCompletedModal} onHide={handleCloseCompletedModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_activities_completed")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumnArea} rowData={activityCompletedModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_deal_source_open")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {fetchOpenDealError ? (
                        <div></div>
                      ) : (
                        <PieChart>
                          <Pie
                            data={opendeal}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            innerRadius={70}
                            label={(props) => {
                              if (opendeal && Array.isArray(opendeal)) {
                                return renderCustomizedLabel2(
                                  props,
                                  opendeal.findIndex((item) => item.name === props.payload.name),
                                  opendeal.length,
                                  opendeal
                                );
                              }
                              return null; // Or handle the case when opendeal is undefined or not an array
                            }}
                            labelLine={false}
                            startAngle={-320}
                            endAngle={40}
                            onClick={(data, index) => handleDealClick(data, index)}
                          >
                            {opendeal.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getRandomColor()} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
                <Modal show={showDealSourceModal} onHide={handleCloseDealModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumn} rowData={dealSourceModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_deal_source_won")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {fetchWonDealError ? (
                        <div></div>
                      ) : (
                        <PieChart>
                          <Pie
                            data={dealSourceWonData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            innerRadius={90}
                            label={(props) =>
                              renderCustomizedLabel(
                                props,
                                dealSourceWonData.findIndex((item) => item.name === props.payload.name)
                              )
                            }
                            startAngle={100}
                            endAngle={-260}
                            onClick={(data, index) => handleWonClick(data, index)}
                            labelLine={false}
                          >
                            {dealSourceWonData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getRandomColor()} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
                <Modal show={showWonSourceModal} onHide={handleWonModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumn} rowData={wonSourceModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_call_tracking_all")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      <PieChart>
                        <Pie
                          data={callTrackingData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={120}
                          innerRadius={90}
                          fill="#8884d8"
                          label={({ name, value }) => `${name} - (${value})`}
                          labelLine={false}
                          startAngle={120}
                          endAngle={-240}
                        >
                          <Cell key="callTracking" fill="#d91414" />
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_call_tracking_won")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {fetchTrackingWonDealError ? (
                        <div></div>
                      ) : (
                        <PieChart>
                          <Pie
                            data={callTrackingWonData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            innerRadius={90}
                            fill="#8884d8"
                            label={({ name, value }) => `${name} - (${value})`}
                            labelLine={false}
                            startAngle={120}
                            endAngle={-240}
                          >
                            <Cell key="callTracking" fill="#d91414" />
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_pipeline_stage")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {fetchPipelineError ? (
                        <div></div>
                      ) : (
                        <PieChart>
                          <Pie
                            data={pipelineData}
                            dataKey="value"
                            nameKey="name"
                            cx="45%"
                            cy="50%"
                            outerRadius={100}
                            innerRadius={70}
                            fill="#8884d8"
                            label={(props) => {
                              if (pipelineData && Array.isArray(pipelineData)) {
                                return renderCustomizedLabel2(
                                  props,
                                  pipelineData.findIndex((item) => item.name === props.payload.name),
                                  pipelineData.length,
                                  pipelineData
                                );
                              }
                              return null;
                            }}
                            labelLine={false}
                            onClick={(data, index) => handlePipelineClick(data, index)}
                          >
                            {pipelineData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getRandomColor()} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
                <Modal show={showPipelineSourceModal} onHide={handleClosePipelineModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumn} rowData={pipelineSourceModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
              <Col xl={6} className="mt-3">
                <Card className="border-0 shadow-sm">
                  <Card.Header>
                    <div className="font h6">{t("statistics_progress")}</div>
                  </Card.Header>
                  <Card.Body>
                    <ResponsiveContainer width="100%" height={400}>
                      {monthlyDealData && Object.keys(monthlyDealData).length > 0 ? (
                        <BarChart data={Object.values(monthlyDealData)}>
                          <XAxis dataKey="MONTH_TITLE" />
                          <YAxis type="number" />
                          <Tooltip />
                          <Legend verticalAlign="top" height={35} />
                          <Bar dataKey="TOTAL_VALUE" fill="#eb9234" name={t("statistics_won_deals_rm", { symbol: symbol })} onClick={(data, index) => handleProgressWonClick(data, index)} />
                          <Bar dataKey="TOTAL_DEAL_LOST" fill="#e6176d" name={t("statistics_lost_deals_rm", { symbol: symbol })} onClick={(data, index) => handleProgressLostClick(data, index)} />
                        </BarChart>
                      ) : (
                        <div></div>
                      )}
                    </ResponsiveContainer>
                  </Card.Body>
                </Card>
                {/* Won */}
                <Modal show={showProgressWonModal} onHide={closeProgressWonModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumn} rowData={progressWonModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* Lost */}
                <Modal show={showProgressLostModal} onHide={closeProgressLostModal} size="xl">
                  <Modal.Header closeButton>
                    <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%", height: "80vh" }}>
                      <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                        <AgGridReact columnDefs={gridColumn} rowData={progressLostModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </Col>
              {session.role_id === "1" && (
                <>
                  <Col xl={6} className="mt-3">
                    <Card className="border-0 shadow-sm">
                      <Card.Header>
                        <div className="font h6">{t("statistics_won_deal_sales_total")}</div>
                      </Card.Header>
                      <Card.Body>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={salesPersonWonData} layout="vertical">
                            <XAxis type="number" ticks={[0, 10, 20, 30, 40]} />
                            <YAxis dataKey="SALESPERSON" type="category" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={35} />
                            <Bar dataKey="TOTAL_DEAL" fill="#de421b" name={t("statistics_won_deals_total_deals")} onClick={(data, index) => handleWonRepClick(data, index)} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Card.Body>
                    </Card>
                    <Modal show={showWonRepModal} onHide={handleCloseWonRepModal} size="xl">
                      <Modal.Header closeButton>
                        <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div style={{ width: "100%", height: "80vh" }}>
                          <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                            <AgGridReact columnDefs={gridColumn} rowData={wonRepModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col xl={6} className="mt-3">
                    <Card className="border-0 shadow-sm">
                      <Card.Header>
                        <div className="font h6">{t("statistics_deal_added_sales_total")}</div>
                      </Card.Header>
                      <Card.Body>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={salesPersonAddedData} layout="vertical">
                            <XAxis type="number" ticks={[0, 20, 40, 60, 80, 100, 120]} />
                            <YAxis dataKey="SALESPERSON" type="category" width={100} />
                            <Tooltip />
                            <Legend verticalAlign="top" height={35} />
                            <Bar dataKey="TOTAL_DEAL" fill="#de421b" name={t("statistics_new_deals_total_deals")} onClick={(data, index) => handleDealAddedClick(data, index)} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Card.Body>
                    </Card>
                    <Modal show={showDealAddedModal} onHide={handleCloseDealAddedModal} size="xl">
                      <Modal.Header closeButton>
                        <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div style={{ width: "100%", height: "80vh" }}>
                          <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                            <AgGridReact columnDefs={gridColumn} rowData={dealAddedModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col xl={6} className="mt-3">
                    <Card className="border-0 shadow-sm">
                      <Card.Header>
                        <div className="font h6">{t("statistics_won_deal_sales_rm", { symbol: symbol })}</div>
                      </Card.Header>
                      <Card.Body>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={salesPersonWonData} layout="vertical">
                            <XAxis type="number" ticks={[0, 400000, 800000, 1200000, 1600000, 2000000]} />
                            <YAxis dataKey="SALESPERSON" type="category" />
                            <Tooltip />
                            <Legend verticalAlign="top" height={35} />
                            <Bar dataKey="TOTAL_VALUE" fill="#de421b" name={t("statistics_won_deals_rm", { symbol: symbol })} onClick={(data, index) => handleWonRepRmClick(data, index)} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Card.Body>
                    </Card>
                    <Modal show={showWonRepRmModal} onHide={handleCloseWonRepRmModal} size="xl">
                      <Modal.Header closeButton>
                        <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div style={{ width: "100%", height: "80vh" }}>
                          <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                            <AgGridReact columnDefs={gridColumn} rowData={wonRepRmModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                  <Col xl={6} className="mt-3">
                    <Card className="border-0 shadow-sm">
                      <Card.Header>
                        <div className="font h6 ">{t("statistics_deal_added_sales_rm", { symbol: symbol })}</div>
                      </Card.Header>
                      <Card.Body>
                        <ResponsiveContainer width="100%" height={400}>
                          <BarChart data={salesPersonAddedData} layout="vertical">
                            <XAxis type="number" />
                            <YAxis dataKey="SALESPERSON" type="category" width={100} />
                            <Tooltip />
                            <Legend verticalAlign="top" height={35} />
                            <Bar dataKey="TOTAL_VALUE" fill="#de421b" name={t("statistics_new_deals_rm", { symbol: symbol })} onClick={(data, index) => handleDealAddedRmClick(data, index)} />
                          </BarChart>
                        </ResponsiveContainer>
                      </Card.Body>
                    </Card>
                    <Modal show={showDealAddedRmModal} onHide={handleCloseDealAddedRmModal} size="xl">
                      <Modal.Header closeButton>
                        <Modal.Title>{t("statistics_deal_source_clicked")} {clickedDealName}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div style={{ width: "100%", height: "80vh" }}>
                          <div className={"ag-theme-quartz ag-op"} style={{ height: "100%", width: "100%" }}>
                            <AgGridReact columnDefs={gridColumn} rowData={dealAddedRmModalData} rowHeight={70} pagination={true} paginationPageSize={100} />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </>
              )}
            </Row>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Statistics;
