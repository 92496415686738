import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { useAuth } from "../../auth/AuthContext";
import { Button, Modal } from "react-bootstrap";
import axios from "../../api/axios";
import { AgGridReact } from "ag-grid-react";
import JSZip from "jszip";
import Swal from "sweetalert2/dist/sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faImage } from "@fortawesome/pro-light-svg-icons";
import Loader from "../../includes/Loader";

const BookingDownloadDocuments = ({ bookingid, bookingData, buyerRecord, documents, uploadedDocuments }) => {
  const { session } = useAuth();
  const [file, setFile] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);

  // GET FUNCTION -------------------------------------------------

  const getFileFromHQ = async (filepath) => {
    try {
      const response = await axios.get(`${session.hostUrl}/${session.hostUrlType}/php/api_get_file_content.php`, {
        params: {
          filepath: filepath,
        },
      });

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const getGeneratedDocuments = async (filepath) => {
    try {
      const response = await axios.get(filepath, { responseType: "arraybuffer" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const base64String = await blobToBase64(blob);

      return {
        content: base64String,
        filesize: response.data.byteLength,
        mimetype: blob.type,
      };
    } catch (error) {
      console.error(error);
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const getUploadedDocuments = async (filepath) => {
    try {
      const response = await axios.get(`${session.baseUrl}api/api_get_file_content.php`, {
        params: {
          filepath: filepath.replace(session.baseUrl, "../"),
        },
      });

      const data = response.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const setFormatFileSize = (params) => {
    if (params.value === 0 || !params.value) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(params.value) / Math.log(k));
    return parseFloat((params.value / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const setFormatFileType = (params) => {
    if (params.value) {
      const mimetype = params.value.split("/")[1];
      return mimetype.toUpperCase() + " FILE";
    }

    return "";
  };

  const setFileName = (params) => {
    if (params.data.mimetype === "application/pdf") {
      return (
        <>
          <FontAwesomeIcon icon={faFilePdf} className="me-2" size="lg" /> {params.value.toUpperCase()}
        </>
      );
    } else if (params.data.mimetype === "image/jpeg" || params.data.mimetype === "image/jpg" || params.data.mimetype === "image/png") {
      return (
        <>
          <FontAwesomeIcon icon={faImage} className="me-2" size="lg" /> {params.value.toUpperCase()}
        </>
      );
    }

    return "";
  };

  useEffect(() => {
    const fetchFileData = async () => {
      try {
        let finalFileList = [];

        if (bookingData.payment_slip) {
          const file = await getFileFromHQ(`../assets/rea_booking/${session.company_id}/payment_slip/${bookingData.payment_slip}`);
          finalFileList.push({
            id: 1,
            filename: "Payment Receipt",
            filesize: file.file_size,
            mimetype: file.mime_type,
            content: file.content,
          });
        }

        if (buyerRecord.length > 0) {
          const buyerFileRecords = await Promise.all(
            buyerRecord.map(async (record, index) => {
              const records = [];

              if (record.buyer_id_front) {
                const file = await getFileFromHQ(`../assets/rea_booking/${session.company_id}/ic/${record.buyer_id_front}`);
                records.push({
                  id: Math.random(),
                  filename: `Buyer ${index + 1} Front Identification Card`,
                  filesize: file.file_size,
                  mimetype: file.mime_type,
                  content: file.content,
                });
              }

              if (record.buyer_id_back) {
                const file = await getFileFromHQ(`../assets/rea_booking/${session.company_id}/ic/${record.buyer_id_back}`);
                records.push({
                  id: Math.random(),
                  filename: `Buyer ${index + 1} Back Identification Card`,
                  filesize: file.file_size,
                  mimetype: file.mime_type,
                  content: file.content,
                });
              }

              return records;
            })
          );

          finalFileList = [...finalFileList, ...buyerFileRecords.flat()];
        }

        if (documents.length > 0) {
          const documentList = await Promise.all(
            documents.map(async (record) => {
              const file = await getGeneratedDocuments(`${session.baseUrl}assets/rea_booking/${session.company_id}/booking_forms/${record.form_title}_${bookingid}.pdf`);
              return {
                id: Math.random(),
                filename: `${record.form_title} (Custom)`,
                filesize: file.filesize,
                mimetype: file.mimetype,
                content: file.content,
              };
            })
          );

          finalFileList = [...finalFileList, ...documentList];
        }

        if (uploadedDocuments.length > 0) {
          const uploadedDocumentList = await Promise.all(
            uploadedDocuments.map(async (record) => {
              const file = await getUploadedDocuments(record.form_url);
              return {
                id: Math.random(),
                filename: record.form_title,
                filesize: file.file_size,
                mimetype: file.mime_type,
                content: file.content,
              };
            })
          );

          finalFileList = [...finalFileList, ...uploadedDocumentList];
        }

        setFile(finalFileList);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchFileData();
  }, []);

  const downloadDocument = async (files) => {
    if (files.length === 0) {
      return Swal.fire({
        icon: "error",
        title: "Opppss...",
        text: "There are no selected files",
      });
    }

    if (files.length === 1) {
      // If there's only one file, download it directly without zipping
      const record = files[0];
      const byteArray = atob(record.data.content);
      const byteNumbers = new Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        byteNumbers[i] = byteArray.charCodeAt(i);
      }
      const blob = new Blob([new Uint8Array(byteNumbers)], { type: record.data.mimetype });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = record.data.filename;
      link.click();
      return;
    }

    // If there are multiple files, zip them before downloading
    const zip = new JSZip();
    files.forEach((record) => {
      const byteArray = atob(record.data.content);
      const byteNumbers = new Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        byteNumbers[i] = byteArray.charCodeAt(i);
      }
      const blob = new Blob([new Uint8Array(byteNumbers)], { type: record.data.mimetype });
      zip.file(record.data.filename + "." + record.data.mimetype.split("/")[1], blob);
    });

    const zipData = await zip.generateAsync({ type: "blob" });
    const url = window.URL.createObjectURL(zipData);
    const link = document.createElement("a");
    link.href = url;
    link.download = bookingData.phase_name + "_" + bookingData.unit_number + ".zip";
    link.click();
  };

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: "50vh", paddingTop: 20 }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "",
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
    },
    {
      headerName: "FILENAME",
      field: "filename",
      cellRenderer: setFileName,
    },
    {
      headerName: "FILE TYPE",
      field: "mimetype",
      maxWidth: 150,
      cellRenderer: setFormatFileType,
    },
    {
      headerName: "FILE SIZE",
      field: "filesize",
      maxWidth: 150,
      cellRenderer: setFormatFileSize,
    },
  ];

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: false,
      filter: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.id.toString();
  }, []);

  const onRowSelected = useCallback((params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const atLeastOneSelected = selectedNodes.length > 0;
    setRowData(atLeastOneSelected ? selectedNodes : []);
  }, []);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="text-uppercase">
          Download Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="position-relative" style={{ height: "50vh" }}>
            <Loader />
          </div>
        ) : (
          <div style={containerStyle}>
            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
              <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={file} defaultColDef={gridColDef} rowHeight={50} rowSelection={"multiple"} getRowId={gridRowId} animateRows={true} onSelectionChanged={onRowSelected} />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="op-primary-color text-light" onClick={() => downloadDocument(rowData)}>
          Download
        </Button>
      </Modal.Footer>
    </>
  );
};

export default BookingDownloadDocuments;
