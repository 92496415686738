import React, { useState, useEffect, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Button, Container, Stack, Card } from "react-bootstrap";
import { FormSelect, FormDate, FormDate2 } from "../includes/FormCustom";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, BarChart, YAxis, XAxis, Legend, Bar } from "recharts";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import moment from "moment";
import Loader from "../includes/Loader";
import { faCheckToSlot, faClipboardCheck, faHouseCircleCheck, faHouseCircleXmark, faSignature, faStamp } from "@fortawesome/pro-duotone-svg-icons";
import Chart from "react-apexcharts";
import { faSquare } from "@fortawesome/pro-solid-svg-icons";

const dateType = [
  { label: "By Week", value: "week" },
  { label: "By Month", value: "month" },
  { label: "By Year", value: "year" },
];

const BookingDashboard = () => {
  const { session } = useAuth();
  const init = useRef(false);
  const [loading, setLoading] = useState(true);
  const [selectedDateType, setSelectedDateType] = useState(dateType[0]);
  const [stackedData, setStackedData] = useState([]);
  const [pieChartData, setPieChartData] = useState([
    { name: "Reserved", value: 0 },
    { name: "Booked", value: 0 },
    { name: "SPA Signed", value: 0 },
    { name: "SPA Stamped", value: 0 },
    { name: "Sold", value: 0 },
    { name: "Cancelled", value: 0 },
  ]);
  const [reportData, setReportData] = useState(null);
  const [totalData, setTotalData] = useState({ reserved: 0, booked: 0, spa_signed: 0, spa_stamped: 0, spa_sold: 0, cancelled: 0 });
  const RADIAN = Math.PI / 180;
  const [bookingStatusRecord, setBookingStatusRecord] = useState({ reserved: [], booked: [], spa_signed: [], spa_stamped: [], spa_sold: [], cancelled: [] });
  const [dateRange, setDateRange] = useState({
    ds: moment(new Date()).subtract(3, "months").format("YYYY-MM-DD"),
    de: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [stages, setStages] = useState({
    reserved: 1,
    booked: 1,
    spa_signed: 1,
    spa_stamped: 1,
    spa_sold: 1,
  });

  // GET FUNCTION ----------------------------------------------------

  const getViewDashboard = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking_report.php", {
        params: {
          task: "ViewBookingDashboard",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        setStages(data.record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBookingReport = async () => {
    try {
      const response = await axios.get("ws/ws_rea_booking_report.php", {
        params: {
          task: "GetBookingReport",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          ds: dateRange.ds,
          de: dateRange.de,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const { totals, weekly_data, monthly_data, yearly_data } = data;
        const { value } = selectedDateType;
        const selectedData = setQuarterData(value === "week" ? weekly_data : value === "month" ? monthly_data : yearly_data);
        setReportData(data);
        setStackedData(selectedData);
        setPieChartData(totals);
        setBookingStatusRecord(data.booking_status_record);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // ONCHANGE FUNCTION ----------------------------------------------

  const onchangeDateRange = (date, input) => {
    if (input === "ds") {
      setDateRange((prevState) => ({
        ...prevState,
        ds: date,
      }));
    } else {
      setDateRange((prevState) => ({
        ...prevState,
        ds: date,
      }));
    }
  };

  const onchangeDateType = (event) => {
    const { value } = event;
    const { weekly_data, monthly_data, yearly_data, totals } = reportData;
    const selectedData = setQuarterData(value === "week" ? weekly_data : value === "month" ? monthly_data : yearly_data);

    setStackedData(selectedData);
    setSelectedDateType(event);
    setTotalData(value === "week" ? totals.week : value === "month" ? totals.month : totals.year);
  };

  // SET FUNCTION ---------------------------------------------------

  const setQuarterData = (data) => {
    return data.map((item) => ({
      label: item.label,
      Reserved: Number(item.reserved),
      Booked: Number(item.booked),
      "SPA Signed": Number(item.spa_signed),
      "SPA Stamped": Number(item.spa_stamped),
      Sold: Number(item.spa_sold),
      Cancelled: Number(item.cancelled),
      allWeekCount: Number(item.reserved) + Number(item.booked) + Number(item.spa_signed) + Number(item.spa_stamped) + Number(item.spa_sold) + Number(item.cancelled),
    }));
  };

  // RENDER FUNCTION ------------------------------------------------

  const renderCustomizedLabel = (props, index) => {
    const { cx, cy, midAngle, outerRadius, fill, payload, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const delta = Math.abs(1 / cos) + 10;
    const sx = cx + outerRadius * cos;
    const sy = cy + outerRadius * sin;
    const mx = cx + (outerRadius + delta) * cos;
    const my = cy + (outerRadius + delta) * sin;
    const ex = mx + Number(cos.toFixed(1)) * 20;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    const adjustedEy = ey + index * 12;

    if (Number(value) > 0) {
      return (
        <g>
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${adjustedEy}`} stroke={fill} fill="none" />
          <rect x={ex + (cos >= 0 ? 1 * 5 : -1 * 17)} y={adjustedEy - 4} width={12} height={8} fill={fill} />
          <text x={ex + (cos >= 0 ? 1 : -1) * 21} y={adjustedEy + 4} textAnchor={textAnchor} fill="#333">
            {`${payload.name}: ${value}`}
          </text>
        </g>
      );
    }
  };

  // USEEFFECT FUNCTION ---------------------------------------------

  useEffect(() => {
    const initData = async () => {
      if (!init.current) {
        try {
          await getViewDashboard();
          await getBookingReport();
          setLoading(false);
          init.current = true;
        } catch (error) {
          setLoading(false);
        }
      }
    };

    initData();
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  const reservedOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#0d6efd"] },
      fill: { type: "solid", colors: ["#0d6efd"], opacity: 0.4 },
      colors: ["#0d6efd"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.reserved.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.reserved]
  );

  const bookedOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#ffc107"] },
      fill: { type: "solid", colors: ["#ffc107"], opacity: 0.4 },
      colors: ["#ffc107"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.booked.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.booked]
  );

  const signedOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#20c997"] },
      fill: { type: "solid", colors: ["#20c997"], opacity: 0.4 },
      colors: ["#20c997"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.spa_signed.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.spa_signed]
  );

  const stampedOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#0dcaf0"] },
      fill: { type: "solid", colors: ["#0dcaf0"], opacity: 0.4 },
      colors: ["#0dcaf0"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.spa_stamped.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.spa_stamped]
  );

  const soldOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#dc3545"] },
      fill: { type: "solid", colors: ["#dc3545"], opacity: 0.4 },
      colors: ["#dc3545"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.spa_sold.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.spa_sold]
  );

  const cancelledOpts = useMemo(
    () => ({
      chart: { height: 100, type: "area", sparkline: { enabled: true } },
      stroke: { width: 2, curve: "smooth", colors: ["#6f42c1"] },
      fill: { type: "solid", colors: ["#6f42c1"], opacity: 0.4 },
      colors: ["#6f42c1"],
      xaxis: {
        type: "datetime",
        categories: bookingStatusRecord.cancelled.map((record) => record.date),
        tickAmount: 10,
        labels: {
          formatter: function (value, timestamp) {
            return moment(new Date(timestamp)).format("MMM yy");
          },
        },
      },
    }),
    [bookingStatusRecord.cancelled]
  );

  const reservedSeries = useMemo(() => [{ name: "Reserved", data: bookingStatusRecord.reserved.map((record) => record.count) }], [bookingStatusRecord.reserved]);
  const bookedSeries = useMemo(() => [{ name: "Booked", data: bookingStatusRecord.booked.map((record) => record.count) }], [bookingStatusRecord.booked]);
  const signedSeries = useMemo(() => [{ name: "SPA Signed", data: bookingStatusRecord.spa_signed.map((record) => record.count) }], [bookingStatusRecord.spa_signed]);
  const stampedSeries = useMemo(() => [{ name: "SPA Stamped", data: bookingStatusRecord.spa_stamped.map((record) => record.count) }], [bookingStatusRecord.spa_stamped]);
  const soldSeries = useMemo(() => [{ name: "Sold", data: bookingStatusRecord.spa_sold.map((record) => record.count) }], [bookingStatusRecord.spa_sold]);
  const cancelledSeries = useMemo(() => [{ name: "Cancelled", data: bookingStatusRecord.cancelled.map((record) => record.count) }], [bookingStatusRecord.cancelled]);

  // RENDER LEGENDS -----------------------------------------------------------------------

  const BarLegend = ({ payload }) => {
    const colors = ["#0d6efd", "#ffc107", "#20c997", "#0dcaf0", "#dc3545", "#6f42c1"];

    const legendItems = payload.filter((entry) => {
      if (entry.value === "Reserved" && Number(stages.reserved) === 1) {
        return true;
      } else if (entry.value === "Booked" && Number(stages.booked) === 1) {
        return true;
      } else if (entry.value === "SPA Signed" && Number(stages.spa_signed) === 1) {
        return true;
      } else if (entry.value === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
        return true;
      } else if (entry.value === "Sold" && Number(stages.spa_sold) === 1) {
        return true;
      } else if (entry.value === "Cancelled") {
        return true;
      }
      return false;
    });

    const setColor = (entry) => {
      if (entry.value === "Reserved" && Number(stages.reserved) === 1) {
        return colors[0];
      } else if (entry.value === "Booked" && Number(stages.booked) === 1) {
        return colors[1];
      } else if (entry.value === "SPA Signed" && Number(stages.spa_signed) === 1) {
        return colors[2];
      } else if (entry.value === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
        return colors[3];
      } else if (entry.value === "Sold" && Number(stages.spa_sold) === 1) {
        return colors[4];
      } else if (entry.value === "Cancelled") {
        return colors[5];
      }
    };

    return (
      <div className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
        {legendItems.map((entry, index) => (
          <>
            <FontAwesomeIcon icon={faSquare} color={setColor(entry)} size="lg" />
            <span className="mx-2" key={`item-${index}`}>
              {entry.value}
            </span>
          </>
        ))}
      </div>
    );
  };

  const BarTooltip = ({ active, payload, label }) => {
    const colors = ["#0d6efd", "#ffc107", "#20c997", "#0dcaf0", "#dc3545", "#6f42c1"];

    if (active && payload?.length) {
      const legendItems = payload.filter((entry) => {
        if (entry.name === "Reserved" && Number(stages.reserved) === 1) {
          return true;
        } else if (entry.name === "Booked" && Number(stages.booked) === 1) {
          return true;
        } else if (entry.name === "SPA Signed" && Number(stages.spa_signed) === 1) {
          return true;
        } else if (entry.name === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
          return true;
        } else if (entry.name === "Sold" && Number(stages.spa_sold) === 1) {
          return true;
        } else if (entry.name === "Cancelled") {
          return true;
        }
        return false;
      });

      const setColor = (entry) => {
        if (entry.name === "Reserved" && Number(stages.reserved) === 1) {
          return colors[0];
        } else if (entry.name === "Booked" && Number(stages.booked) === 1) {
          return colors[1];
        } else if (entry.name === "SPA Signed" && Number(stages.spa_signed) === 1) {
          return colors[2];
        } else if (entry.name === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
          return colors[3];
        } else if (entry.name === "Sold" && Number(stages.spa_sold) === 1) {
          return colors[4];
        } else if (entry.name === "Cancelled") {
          return colors[5];
        }
      };

      return (
        <Card>
          <Card.Header>
            <Card.Title className="m-0" style={{ fontSize: 12 }}>
              {label}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {legendItems.map((ele, index) => (
              <div className="d-flex">
                <FontAwesomeIcon icon={faSquare} color={setColor(ele)} size="lg" className="me-2" />
                <span key={index}>
                  {ele.name} : {ele.value}
                </span>
                <br />
              </div>
            ))}
          </Card.Body>
        </Card>
      );
    }
    return null;
  };

  const DonutLegend = ({ payload }) => {
    const colors = ["#0d6efd", "#ffc107", "#20c997", "#0dcaf0", "#dc3545", "#6f42c1"];

    const legendItems = payload.filter((entry) => {
      if (entry.value === "Reserved" && Number(stages.reserved) === 1) {
        return true;
      } else if (entry.value === "Booked" && Number(stages.booked) === 1) {
        return true;
      } else if (entry.value === "SPA Signed" && Number(stages.spa_signed) === 1) {
        return true;
      } else if (entry.value === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
        return true;
      } else if (entry.value === "Sold" && Number(stages.spa_sold) === 1) {
        return true;
      } else if (entry.value === "Cancelled") {
        return true;
      }
      return false;
    });

    const setColor = (entry) => {
      if (entry.value === "Reserved" && Number(stages.reserved) === 1) {
        return colors[0];
      } else if (entry.value === "Booked" && Number(stages.booked) === 1) {
        return colors[1];
      } else if (entry.value === "SPA Signed" && Number(stages.spa_signed) === 1) {
        return colors[2];
      } else if (entry.value === "SPA Stamped" && Number(stages.spa_stamped) === 1) {
        return colors[3];
      } else if (entry.value === "Sold" && Number(stages.spa_sold) === 1) {
        return colors[4];
      } else if (entry.value === "Cancelled") {
        return colors[5];
      }
    };

    return (
      <div className="d-flex justify-content-center" style={{ marginBottom: 15 }}>
        {legendItems.map((entry, index) => {
          return (
            <>
              <FontAwesomeIcon icon={faSquare} color={setColor(entry)} size="lg" />
              <span className="mx-2" key={`item-${index}`}>
                {entry.value}
              </span>
            </>
          );
        })}
      </div>
    );
  };

  const DonutTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      return (
        <Card>
          <Card.Header>
            <Card.Title className="m-0 text-center" style={{ fontSize: 12 }}>
              {payload[0].name}
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-center py-1">{payload[0].value}</Card.Body>
        </Card>
      );
    }
    return null;
  };
  

  return (
    <Container fluid className="p-0 m-0">
      {loading ? (
        <Loader />
      ) : (
        <div className="p-4">
          <Row>
            <Col sm={12} className="mb-4">
              <Stack direction="horizontal" gap={1}>
                <Form.Group className="me-2 ms-auto">
                  <FormDate2 value={dateRange.ds} className="border-0 shadow-sm rounded" onChange={(date) => onchangeDateRange(date, "ds")} />
                </Form.Group>
                <Form.Group className="me-2">
                  <FormDate2 value={dateRange.de} className="border-0 shadow-sm rounded" onChange={(date) => onchangeDateRange(date, "de")} />
                </Form.Group>
                <Button variant="" className="btn op-primary-color text-light" onClick={getBookingReport}>
                  Apply
                </Button>
              </Stack>
            </Col>
            <Col sm={12} className="mb-3">
              <Row>
                {Number(stages.reserved) === 1 && (
                  <Col sm={stages.spa_signed === 0 && stages.spa_stamped === 0 ? 6 : 4} className="mb-3">
                    <Card className="w-100 border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Card.Title className="m-0 fw-bold">{pieChartData[0].value}</Card.Title>
                            <Card.Text className="text-muted">Reserved</Card.Text>
                          </div>
                          <FontAwesomeIcon icon={faCheckToSlot} size="2xl" />
                        </div>
                      </Card.Body>
                      <Chart options={reservedOpts} series={reservedSeries} type="area" height={50} />
                    </Card>
                  </Col>
                )}

                {Number(stages.booked) === 1 && (
                  <Col sm={stages.spa_signed === 0 && stages.spa_stamped === 0 ? 6 : 4} className="mb-3">
                    <Card className="w-100 border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Card.Title className="m-0 fw-bold">{pieChartData[1].value}</Card.Title>
                            <Card.Text className="text-muted">Booked</Card.Text>
                          </div>
                          <FontAwesomeIcon icon={faClipboardCheck} size="2xl" />
                        </div>
                      </Card.Body>
                      <Chart options={bookedOpts} series={bookedSeries} type="area" height={50} />
                    </Card>
                  </Col>
                )}

                {Number(stages.spa_signed) === 1 && (
                  <Col sm={4} className="mb-3">
                    <Card className="w-100 border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Card.Title className="m-0 fw-bold">{pieChartData[2].value}</Card.Title>
                            <Card.Text className="text-muted">SPA Signed</Card.Text>
                          </div>
                          <FontAwesomeIcon icon={faSignature} size="2xl" />
                        </div>
                      </Card.Body>
                      <Chart options={signedOpts} series={signedSeries} type="area" height={50} />
                    </Card>
                  </Col>
                )}

                {Number(stages.spa_stamped) === 1 && (
                  <Col sm={4} className="mb-3">
                    <Card className="w-100 border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Card.Title className="m-0 fw-bold">{pieChartData[3].value}</Card.Title>
                            <Card.Text className="text-muted">SPA Stamped</Card.Text>
                          </div>
                          <FontAwesomeIcon icon={faStamp} size="2xl" />
                        </div>
                      </Card.Body>
                      <Chart options={stampedOpts} series={stampedSeries} type="area" height={50} />
                    </Card>
                  </Col>
                )}

                {Number(stages.spa_sold) === 1 && (
                  <Col sm={stages.spa_signed === 0 && stages.spa_stamped === 0 ? 6 : 4} className="mb-3">
                    <Card className="w-100 border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Card.Title className="m-0 fw-bold">{pieChartData[4].value}</Card.Title>
                            <Card.Text className="text-muted">Sold</Card.Text>
                          </div>
                          <FontAwesomeIcon icon={faHouseCircleCheck} size="2xl" />
                        </div>
                      </Card.Body>
                      <Chart options={soldOpts} series={soldSeries} type="area" height={50} />
                    </Card>
                  </Col>
                )}

                <Col sm={stages.spa_signed === 0 && stages.spa_stamped === 0 ? 6 : 4} className="mb-3">
                  <Card className="w-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <Card.Title className="m-0 fw-bold">{pieChartData[5].value}</Card.Title>
                          <Card.Text className="text-muted">Cancelled</Card.Text>
                        </div>
                        <FontAwesomeIcon icon={faHouseCircleXmark} size="2xl" />
                      </div>
                    </Card.Body>
                    <Chart options={cancelledOpts} series={cancelledSeries} type="area" height={50} />
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col sm={6} className="mb-3">
              <Card className="border-0 shadow-sm h-100">
                <Card.Header className="bg-light d-flex justify-content-between align-items-center">
                  <Card.Title className="m-0" as={"h6"}>
                    Booking Trends Over Time
                  </Card.Title>
                  <Form.Group>
                    <FormSelect options={dateType} valueDefault={dateType[0]} onChange={(e) => onchangeDateType(e)} border={true} shadow={true} width="120px" />
                  </Form.Group>
                </Card.Header>
                <Card.Body className="py-3 px-0">
                  <ResponsiveContainer width="95%" height={450}>
                    <BarChart data={stackedData}>
                      <XAxis dataKey="label" interval={0} scaleToFit="true" padding="gap" textAnchor={stackedData.length > 4 ? "end" : "middle"} verticalAnchor="start" angle={stackedData.length > 4 ? -40 : 0} height={stackedData.length > 4 ? 70 : 30} />
                      <YAxis />
                      <Tooltip content={<BarTooltip active={false} payload={[]} label={""} />} />
                      <Legend content={<BarLegend />} verticalAlign="top" />
                      {Number(stages.reserved) === 1 && <Bar dataKey="Reserved" stackId="status" fill="#0d6efd" />}
                      {Number(stages.booked) === 1 && <Bar dataKey="Booked" stackId="status" fill="#ffc107" />}
                      {Number(stages.spa_signed) === 1 && <Bar dataKey="SPA Signed" stackId="status" fill="#20c997" />}
                      {Number(stages.spa_stamped) === 1 && <Bar dataKey="SPA Stamped" stackId="status" fill="#0dcaf0" />}
                      {Number(stages.spa_sold) === 1 && <Bar dataKey="Sold" stackId="status" fill="#dc3545" />}
                      <Bar dataKey="Cancelled" stackId="status" fill="#6f42c1" />
                    </BarChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6} className="mb-3">
              <Card className="border-0 shadow-sm h-100">
                <Card.Header className="bg-light" style={{ padding: "0.9rem 1rem" }}>
                  <Card.Title className="m-0" as={"h6"}>
                    Booking Distribution Overview
                  </Card.Title>
                </Card.Header>
                <Card.Body className="py-3 px-0">
                  <ResponsiveContainer width="100%" height={450}>
                    <PieChart>
                      <Legend content={<DonutLegend />} verticalAlign="top" />
                      <Pie
                        data={pieChartData}
                        innerRadius={80}
                        outerRadius={120}
                        dataKey="value"
                        label={(props) =>
                          renderCustomizedLabel(
                            props,
                            pieChartData.findIndex((item) => item.name === props.payload.name)
                          )
                        }
                        labelLine={false}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={["#0d6efd", "#ffc107", "#20c997", "#0dcaf0", "#dc3545", "#6f42c1"][index]} />
                        ))}
                      </Pie>
                      <Tooltip content={<DonutTooltip active={false} payload={[]} label={""} />} />
                    </PieChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default BookingDashboard;
