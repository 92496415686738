import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./lib/scss/op-root.scss";
import "./lib/scss/op-transition.scss";
import "./lib/scss/op-sidebar.scss";
import "./lib/scss/op-navbar.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "sweetalert2/src/sweetalert2.scss";
import "filepond/dist/filepond.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "filepond/dist/filepond.min.css";
import "./i18next.jsx";
import Loader from "./includes/Loader.jsx";
import "react-quill/dist/quill.snow.css";
import "izitoast-react/dist/iziToast.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Suspense fallback={<Loader />}>
    <App />
  </React.Suspense>
);
