import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Container, Row, Col, Stack, Button, Dropdown, Offcanvas, Form, Modal, Card, Badge } from "react-bootstrap";
import Loader from "../includes/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FormSelect } from "../includes/FormCustom";
import { faBullhorn } from "@fortawesome/pro-duotone-svg-icons";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from "../auth/AuthContext";
import axios from "../api/axios";
import NoRecord from "../includes/NoRecord";
import { useNavigate, useParams } from "react-router-dom";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";
import { faArrowsRotate, faHorizontalRule } from "@fortawesome/pro-light-svg-icons";
import Loader2 from "../includes/Loader2";

const CurationSegment = () => {
  const { curation_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(false);
  const init = useRef(false);
  const [show, setShow] = useState(false);
  const { session } = useAuth();
  const { Formik } = formik;
  const [genderList, setGenderList] = useState([]);
  const [salutationList, setSalutationList] = useState([]);
  const [raceList, setRaceList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [maritalList, setMaritalList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [sourceCategoryList, setSourceCategoryList] = useState([]);
  const [dealSourceList, setDealSourceList] = useState([]);
  const [pipelineList, setPipelineList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [phaseList, setPhaseList] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);
  const [prospectList, setProspectList] = useState([]);
  const [selectedProspect, setSelectedProspect] = useState([]);

  const [condition1] = useState([
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not_equals" },
    { label: "Contains", value: "contains" },
    { label: "Not Contains", value: "not_contains" },
  ]);

  const [condition2] = useState([
    { label: "Equals", value: "equals" },
    { label: "Not Equals", value: "not_equals" },
  ]);

  const [condition3] = useState([{ label: "Between", value: "between" }]);
  const [condition4] = useState([{ label: "Equals", value: "equals" }]);

  const [birthList] = useState([
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ]);

  const [contactTypeList] = useState([
    { value: "all", label: "All Contact" },
    { value: "prospect", label: "Prospect" },
    { value: "buyer", label: "Purchaser" },
    { value: "subsales_buyer", label: "Sub Purchaser" },
  ]);

  const [projectInterestedList] = useState([
    { label: "Gamuda Gardens", value: 180 },
    { label: "Jade Hills", value: 190 },
    { label: "Gamuda Cove", value: 200 },
    { label: "twentyfive.7", value: 203 },
    { label: "Horizon Hills", value: 210 },
    { label: "Bukit Bantayan", value: 212 },
    { label: "Highpark Suites", value: 213 },
    { label: "GL Placemaking", value: 397 },
  ]);

  const [dealStatusList] = useState([
    { label: "Progressing", value: "active" },
    { label: "Contact Lead", value: "new" },
    { label: "Follow Up", value: "inactive" },
  ]);

  const [dealOutcomeList] = useState([
    { label: "Won", value: "won" },
    { label: "Open", value: "open" },
    { label: "Lost", value: "lost" },
  ]);

  const [buyerRoleList] = useState([
    { label: "Main Buyer", value: "main" },
    { label: "Joint Buyer", value: "joint" },
  ]);

  const [tierList] = useState([
    { label: "Acquaint", value: "Acquaint" },
    { label: "Aspire", value: "Aspire" },
    { label: "Arrived", value: "Arrived" },
    { label: "Ascend", value: "Ascend" },
  ]);

  const [salesStatusList] = useState([
    { label: "Signed", value: "Signed" },
    { label: "Booked", value: "Booked" },
    { label: "Cancelled", value: "Cancelled" },
  ]);

  const [moduleCodeList] = useState([
    { label: "PMS", value: "pms" },
    { label: "PFE/PDS", value: "pfe" },
  ]);

  const [projectList] = useState([
    { label: "Gamuda Gardens", value: "180" },
    { label: "Jade Hills", value: "190" },
    { label: "Gamuda Cove", value: "200" },
    { label: "Kundang Estates", value: "202" },
    { label: "twentyfive.7", value: "203" },
    { label: "Horizon Hills", value: "210" },
    { label: "Madge Mansions", value: "211" },
    { label: "Bukit Bantayan", value: "212" },
    { label: "Highpark Suites", value: "213" },
    { label: "The Robertson", value: "214" },
    { label: "Bandar Botanic", value: "403" },
    { label: "Valencia", value: "404" },
    { label: "Gamuda Kemuning", value: "405" },
    { label: "Kota Kemuning", value: "406" },
    { label: "GL HQ", value: "251" },
  ]);

  const [contactPreferenceList] = useState([
    { label: "Allow", value: "Allow" },
    { label: "Disallow", value: "Disallow" },
  ]);

  const [lifestyleList] = useState([
    { label: "Yes", value: "True" },
    { label: "No", value: "False" },
  ]);

  const [initialValues, setInitialValues] = useState({
    nameCon: condition1[0],
    nameReset: false,
    name: "",
    emailCon: condition1[0],
    emailReset: false,
    email: "",
    corporateCon: condition2[0],
    corporateReset: false,
    corporateArr: [],
    corporate: "",
    dobCon: condition3[0],
    dobReset: false,
    dobStart: "",
    dobEnd: "",
    ageCon: condition3[0],
    ageReset: false,
    ageStart: "",
    ageEnd: "",
    birthCon: condition2[0],
    birthReset: false,
    birthArr: [],
    birth: "",
    genderCon: condition2[0],
    genderReset: false,
    genderArr: [],
    gender: "",
    salutationCon: condition2[0],
    salutationReset: false,
    salutationArr: [],
    salutation: "",
    raceCon: condition2[0],
    raceReset: false,
    raceArr: [],
    race: "",
    nationalityCon: condition2[0],
    nationalityReset: false,
    nationalityArr: [],
    nationality: "",
    maritalCon: condition2[0],
    maritalReset: false,
    maritalArr: [],
    marital: "",
    countryCon: condition2[0],
    countryReset: false,
    countryArr: [],
    country: "",
    stateCon: condition2[0],
    stateReset: false,
    stateDisabled: true,
    stateArr: [],
    state: "",
    cityCon: condition2[0],
    cityReset: false,
    cityDisabled: true,
    cityArr: [],
    city: "",
    contactTypeCon: condition2[0],
    contactTypeReset: false,
    contactType: contactTypeList[0],
    projectInterestedCon: condition4[0],
    projectInterestedDisabled: true,
    projectInterestedReset: false,
    projectInterested: "",
    sourceCategoryCon: condition2[0],
    sourceCategoryDisabled: true,
    sourceCategoryReset: false,
    sourceCategoryArr: [],
    sourceCategory: "",
    dealSourceCon: condition2[0],
    dealSourceDisabled: true,
    dealSourceReset: false,
    dealSourceArr: [],
    dealSource: "",
    pipelineCon: condition2[0],
    pipelineDisabled: true,
    pipelineReset: false,
    pipelineArr: [],
    pipeline: "",
    stageCon: condition2[0],
    stageDisabled: true,
    stageReset: false,
    stageArr: [],
    stage: "",
    dealStatusCon: condition2[0],
    dealStatusDisabled: true,
    dealStatusReset: false,
    dealStatusArr: [],
    dealStatus: "",
    dealOutcomeCon: condition2[0],
    dealOutcomeDisabled: true,
    dealOutcomeReset: false,
    dealOutcomeArr: [],
    dealOutcome: "",
    dealCreatedCon: condition3[0],
    dealCreatedDisabled: true,
    dealCreatedReset: false,
    dealCreatedStart: "",
    dealCreatedEnd: "",
    buyerRoleCon: condition4[0],
    buyerRoleDisabled: true,
    buyerRoleReset: false,
    buyerRoleArr: [],
    buyerRole: "",
    tierCon: condition2[0],
    tierDisabled: true,
    tierReset: false,
    tierArr: [],
    tier: "",
    lifestyleCon: condition2[0],
    lifestyleDisabled: true,
    lifestyleReset: false,
    lifestyleArr: [],
    lifestyle: "",
    sourceCon: condition2[0],
    sourceDisabled: true,
    sourceReset: false,
    sourceArr: [],
    source: "",
    salesStatusCon: condition2[0],
    salesStatusDisabled: true,
    salesStatusReset: false,
    salesStatusArr: [],
    salesStatus: "",
    statusReasonCon: condition1[0],
    statusReasonDisabled: true,
    statusReasonReset: false,
    statusReason: "",
    salesDateCon: condition3[0],
    salesDateDisabled: true,
    salesDateStatus: true,
    salesDateReset: false,
    salesDateStart: "",
    salesDateEnd: "",
    spaDateCon: condition3[0],
    spaDateDisabled: true,
    spaDateReset: false,
    spaDateStart: "",
    spaDateEnd: "",
    stampedDateCon: condition3[0],
    stampedDateDisabled: true,
    stampedDateReset: false,
    stampedDateStart: "",
    stampedDateEnd: "",
    accumCon: condition3[0],
    accumDisabled: true,
    accumReset: false,
    accumStart: "",
    accumEnd: "",
    moduleCodeCon: condition2[0],
    moduleCodeDisabled: true,
    moduleCodeReset: false,
    moduleCode: "",
    projectCon: condition2[0],
    projectDisabled: true,
    projectReset: false,
    projectArr: [],
    project: "",
    phaseCon: condition2[0],
    phaseDisabled: true,
    phaseReset: false,
    phaseArr: [],
    phase: "",
    lotUnitCon: condition1[0],
    lotUnitDisabled: true,
    lotUnitReset: false,
    lotUnit: "",
    pdpaCon: condition2[0],
    pdpaReset: false,
    pdpa: "",
    emailCPCon: condition2[0],
    emailCPReset: false,
    emailCP: "",
    emailBulkCon: condition2[0],
    emailBulkReset: false,
    emailBulk: "",
    phoneCon: condition2[0],
    phoneReset: false,
    phone: "",
    faxCon: condition2[0],
    faxReset: false,
    fax: "",
    mailCon: condition2[0],
    mailReset: false,
    mail: "",
    whatsappCon: condition2[0],
    whatsappReset: false,
    whatsapp: "",
  });

  // GET FUNCTION =============================================================

  const getCondition = async () => {
    try {
      const response = await axios.get("ws/ws_curation_segmentation.php", {
        params: {
          task: "ListSegmentFilter",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          curation_id: curation_id,
        },
      });
      const data = response.data;
      if (data.status === 0) {
        const conditionRule = JSON.parse(data.record[data.record.length - 1].segment_rule);
        const conditionValue = JSON.parse(data.record[data.record.length - 1].segment_data);

        // NAME -----------------------
        var nameCon = condition1.find((record) => record.value === conditionRule.ruleName) || condition1[0];
        var name = conditionValue.name || "";

        // EMAIL ----------------------
        var emailCon = condition1.find((record) => record.value === conditionRule.ruleEmailAddress) || condition1[0];
        var email = conditionValue.emailAddress || "";

        // CORPORATE ------------------
        var corporate = conditionValue.corporate || "";
        var corporateCon = condition2.find((record) => record.value === conditionRule.ruleCorporate) || condition2[0];
        var corporateArr = corporate
          ? corporateList.filter((record) => {
              const corporateValues = conditionValue.corporate.split(",");
              return corporateValues.some((value) => record.value.includes(value));
            })
          : [];

        // DOB ------------------------
        var dobCon = condition3.find((record) => record.value === conditionRule.ruleDob) || condition3[0];
        var dobStart = conditionValue.dobStart || "";
        var dobEnd = conditionValue.dobEnd || "";

        // AGE ------------------------
        var ageCon = condition3.find((record) => record.value === conditionRule.ruleAge) || condition3[0];
        var ageStart = conditionValue.ageStart || "";
        var ageEnd = conditionValue.ageEnd || "";

        // BIRTH MONTH ----------------
        var birth = conditionValue.birthMonth || "";
        var birthCon = condition2.find((record) => record.value === conditionRule.ruleBirthMonth) || condition2[0];
        var birthArr = conditionValue.birthMonth
          ? birthList.filter((record) => {
              const values = conditionValue.birthMonth.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // GENDER ---------------------
        var gender = conditionValue.gender || "";
        var genderCon = condition2.find((record) => record.value === conditionRule.ruleGender) || condition2[0];
        var genderArr = conditionValue.gender
          ? genderList.filter((record) => {
              const values = conditionValue.gender.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // SALUTATION -----------------
        var salutation = conditionValue.salutation || "";
        var salutationCon = condition2.find((record) => record.value === conditionRule.ruleSalutation) || condition2[0];
        var salutationArr = conditionValue.salutation
          ? salutationList.filter((record) => {
              const values = conditionValue.salutation.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // RACE -----------------------
        var race = conditionValue.race || "";
        var raceCon = condition2.find((record) => record.value === conditionRule.ruleRace) || condition2[0];
        var raceArr = conditionValue.race
          ? raceList.filter((record) => {
              const values = conditionValue.race.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // NATIONALITY ----------------
        var nationality = conditionValue.nationality || "";
        var nationalityCon = condition2.find((record) => record.value === conditionRule.ruleNationality) || condition2[0];
        var nationalityArr = conditionValue.nationality
          ? nationalityList.filter((record) => {
              const values = conditionValue.nationality.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // MARITAL STATUS -------------
        var marital = conditionValue.maritalStatus || "";
        var maritalCon = condition2.find((record) => record.value === conditionRule.ruleMaritalStatus) || condition2[0];
        var maritalArr = conditionValue.maritalStatus
          ? maritalList.filter((record) => {
              const values = conditionValue.maritalStatus.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // COUNTRY --------------------
        var country = conditionValue.country || "";
        var countryCon = condition2.find((record) => record.value === conditionRule.ruleCountry) || condition2[0];
        var countryArr = conditionValue.country
          ? countryList.filter((record) => {
              const values = conditionValue.country.split(",");
              return values.some((value) => record.value.includes(value));
            })
          : [];

        // STATE ----------------------
        var state = conditionValue.state && conditionValue.country ? conditionValue.state : "";
        var stateCon = condition2.find((record) => record.value === conditionRule.ruleState) || condition2[0];
        var stateDisabled = conditionValue.country ? false : true;
        var stateArr =
          conditionValue.state && conditionValue.country
            ? stateList.filter((record) => {
                const values = conditionValue.state.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

        // CITY -----------------------
        var city = conditionValue.city && conditionValue.state && conditionValue.country ? conditionValue.city : "";
        var cityCon = condition2.find((record) => record.value === conditionRule.ruleCity) || condition2[0];
        var cityDisabled = conditionValue.state && conditionValue.country ? false : true;
        var cityArr =
          conditionValue.city && conditionValue.state && conditionValue.country
            ? cityList.filter((record) => {
                const values = conditionValue.city.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

        // CONTACT TYPE ---------------
        var contactTypeCon = condition2.find((record) => record.value === conditionRule.ruleContactType) || condition2[0];
        var contactType = conditionValue.contactType ? contactTypeList.find((record) => record.value === conditionValue.contactType) : contactTypeList[0];

        var projectInterestedCon = condition4[0];
        var projectInterested = "";
        var projectInterestedDisabled = true;

        var sourceCategoryCon = condition2[0];
        var sourceCategoryArr = [];
        var sourceCategory = "";
        var sourceCategoryDisabled = true;

        var dealSourceCon = condition2[0];
        var dealSourceArr = [];
        var dealSource = "";
        var dealSourceDisabled = true;

        var pipeline = "";
        var pipelineCon = condition2[0];
        var pipelineDisabled = true;
        var pipelineArr = [];

        var stage = "";
        var stageCon = condition2[0];
        var stageDisabled = true;
        var stageArr = [];

        var dealStatusCon = condition2[0];
        var dealStatusDisabled = true;
        var dealStatusArr = [];
        var dealStatus = "";

        var dealOutcome = "";
        var dealOutcomeCon = condition2[0];
        var dealOutcomeDisabled = true;
        var dealOutcomeArr = [];

        var dealCreatedCon = condition3[0];
        var dealCreatedDisabled = true;
        var dealCreatedStart = "";
        var dealCreatedEnd = "";

        var buyerRole = "";
        var buyerRoleDisabled = true;
        var buyerRoleCon = condition4[0];
        var buyerRoleArr = [];

        var tier = "";
        var tierDisabled = true;
        var tierCon = condition4[0];
        var tierArr = [];

        var lifestyle = "";
        var lifestyleDisabled = true;
        var lifestyleCon = condition4[0];
        var lifestyleArr = [];

        var source = "";
        var sourceDisabled = true;
        var sourceCon = condition2[0];
        var sourceArr = [];

        var salesStatus = "";
        var salesStatusDisabled = true;
        var salesStatusCon = condition2[0];
        var salesStatusArr = [];

        var statusReasonCon = condition1[0];
        var statusReasonDisabled = true;
        var statusReason = "";

        var salesDateCon = condition3[0];
        var salesDateDisabled = true;
        var salesDateStart = "";
        var salesDateEnd = "";

        var spaDateCon = condition3[0];
        var spaDateStart = "";
        var spaDateEnd = "";
        var spaDateDisabled = true;

        var stampedDateCon = condition3[0];
        var stampedDateStart = "";
        var stampedDateEnd = "";
        var stampedDateDisabled = true;

        var accumCon = condition3[0];
        var accumStart = "";
        var accumEnd = "";
        var accumDisabled = true;

        var moduleCodeCon = condition2.find((record) => record.value === conditionRule.ruleModuleCode) || condition2[0];
        var moduleCode = conditionValue.moduleCode ? moduleCodeList.find((record) => record.value === conditionValue.moduleCode) : "";
        var moduleCodeDisabled = true;

        var project = "";
        var projectDisabled = true;
        var projectCon = condition2[0];
        var projectArr = [];

        var phase = "";
        var phaseDisabled = true;
        var phaseCon = condition2[0];
        var phaseArr = [];

        var lotUnitCon = condition1[0];
        var lotUnitDisabled = false;
        var lotUnit = "";

        if (conditionValue.contactType === "prospect") {
          // PROJECT INTERESTED -----------------------
          projectInterestedCon = condition4.find((record) => record.value === conditionRule.ruleTownship) || condition4[0];
          projectInterested = conditionValue.township ? projectInterestedList.find((record) => record.value === conditionValue.township) : "";
          projectInterestedDisabled = false;

          Promise.all([getSourceCategory(conditionValue.township), getDealSource(conditionValue.township), getPipeline(conditionValue.township), getStage(conditionValue.township)]).then(() => {
            // SOURCE CATEGORY --------------------------
            sourceCategory = conditionValue.sourceCategory || "";
            sourceCategoryDisabled = false;
            sourceCategoryCon = condition2.find((record) => record.value === conditionRule.ruleSourceCategory) || condition2[0];
            sourceCategoryArr = conditionValue.sourceCategory
              ? sourceCategoryList.filter((record) => {
                  const values = conditionValue.sourceCategory.split(",");
                  return values.some((value) => record.value.includes(value));
                })
              : [];

            // DEAL SOURCE ------------------------------
            dealSource = conditionValue.dealSource || "";
            dealSourceCon = condition2.find((record) => record.value === conditionRule.ruleDealSource) || condition2[0];
            dealSourceDisabled = false;
            dealSourceArr = conditionValue.dealSource
              ? dealSourceList.filter((record) => {
                  const values = conditionValue.dealSource.split(",");
                  return values.some((value) => record.value.includes(value));
                })
              : [];

            // PIPELINE --------------------------------
            pipeline = conditionValue.pipeline || "";
            pipelineCon = condition2.find((record) => record.value === conditionRule.rulePipeline) || condition2[0];
            pipelineDisabled = false;
            pipelineArr = conditionValue.pipeline
              ? pipelineList.filter((record) => {
                  const values = conditionValue.pipeline.split(",");
                  return values.some((value) => record.value.includes(value));
                })
              : [];

            // STAGE -----------------------------------
            stage = conditionValue.stage || "";
            stageCon = condition2.find((record) => record.value === conditionRule.ruleStage) || condition2[0];
            stageDisabled = false;
            stageArr = conditionValue.stage
              ? stageList.filter((record) => {
                  const values = conditionValue.stage.split(",");
                  return values.some((value) => record.value.includes(value));
                })
              : [];
          });

          // DEAL STATUS ----------------------------
          dealStatus = conditionValue.dealStatus || "";
          dealStatusCon = condition2.find((record) => record.value === conditionRule.ruleDealStatus) || condition2[0];
          dealStatusDisabled = false;
          dealStatusArr = conditionValue.dealStatus
            ? dealStatusList.filter((record) => {
                const values = conditionValue.dealStatus.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // DEAL OUTCOME ---------------------------
          dealOutcome = conditionValue.dealOutcome || "";
          dealOutcomeCon = condition2.find((record) => record.value === conditionRule.ruleDealOutcome) || condition2[0];
          dealOutcomeDisabled = false;
          dealOutcomeArr = conditionValue.dealOutcome
            ? dealOutcomeList.filter((record) => {
                const values = conditionValue.dealOutcome.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // DEAL CREATED ---------------------------
          dealCreatedCon = condition3.find((record) => record.value === conditionRule.ruleDealCreated) || condition3[0];
          dealCreatedDisabled = false;
          dealCreatedStart = conditionValue.dealCreatedStart || "";
          dealCreatedEnd = conditionValue.dealCreatedEnd || "";
        } else if (conditionValue.contactType === "buyer") {
          // BUYER ROLE --------------------------------
          buyerRole = conditionValue.buyerRole || "";
          buyerRoleDisabled = false;
          buyerRoleCon = condition4.find((record) => record.value === conditionRule.ruleBuyerRole) || condition4[0];
          buyerRoleArr = conditionValue.buyerRole
            ? buyerRoleList.filter((record) => {
                const values = conditionValue.buyerRole.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LOYALTY TIER ------------------------------
          tier = conditionValue.loyaltyTier || "";
          tierDisabled = false;
          tierCon = condition2.find((record) => record.value === conditionRule.ruleLoyaltyTier) || condition2[0];
          tierArr = conditionValue.loyaltyTier
            ? tierList.filter((record) => {
                const values = conditionValue.loyaltyTier.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LIFESTYLE ---------------------------------
          lifestyle = conditionValue.lifestyleRegistration || "";
          lifestyleDisabled = false;
          lifestyleCon = condition2.find((record) => record.value === conditionRule.ruleLifestyleRegistration) || condition2[0];
          lifestyleArr = conditionValue.lifestyleRegistration
            ? lifestyleList.filter((record) => {
                const values = conditionValue.lifestyleRegistration.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // SOURCE ------------------------------------
          source = conditionValue.source || "";
          sourceDisabled = false;
          sourceCon = condition2.find((record) => record.value === conditionRule.ruleSource) || condition2[0];
          sourceArr = conditionValue.source
            ? sourceList.filter((record) => {
                const values = conditionValue.source.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // SALES STATUS ------------------------------
          salesStatus = conditionValue.salesStatus || "";
          salesStatusDisabled = false;
          salesStatusCon = condition2.find((record) => record.value === conditionRule.ruleSalesStatus) || condition2[0];
          salesStatusArr = conditionValue.salesStatus
            ? salesStatusList.filter((record) => {
                const values = conditionValue.salesStatus.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // STATUS REASON -----------------------------
          statusReasonCon = condition1.find((record) => record.value === conditionRule.ruleStatusReason) || condition1[0];
          statusReason = conditionValue.statusReason || "";
          statusReasonDisabled = false;

          // SALES DATE --------------------------------
          salesDateCon = condition3.find((record) => record.value === conditionRule.ruleSalesDate) || condition3[0];
          salesDateStart = conditionValue.salesDateStart || "";
          salesDateEnd = conditionValue.salesDateEnd || "";
          salesDateDisabled = false;

          // SPA DATE ----------------------------------
          spaDateCon = condition3.find((record) => record.value === conditionRule.ruleSpaDate) || condition3[0];
          spaDateStart = conditionValue.spaDateStart || "";
          spaDateEnd = conditionValue.spaDateEnd || "";
          spaDateDisabled = false;

          // SPA STAMPED DATE -------------------------
          stampedDateCon = condition3.find((record) => record.value === conditionRule.ruleSpaStampedDate) || condition3[0];
          stampedDateStart = conditionValue.spaStampedDateStart || "";
          stampedDateEnd = conditionValue.spaStampedDateEnd || "";
          stampedDateDisabled = false;

          // ACCUMULATIVE VALUE -----------------------
          accumCon = condition3.find((record) => record.value === conditionRule.ruleTotalAccumulative) || condition3[0];
          accumStart = conditionValue.totalAccumulativeStart || "";
          accumEnd = conditionValue.totalAccumulativeEnd || "";
          accumDisabled = false;

          // MODULE CODE ------------------------------
          moduleCodeCon = condition2.find((record) => record.value === conditionRule.ruleModuleCode) || condition2[0];
          moduleCode = conditionValue.moduleCode ? moduleCodeList.find((record) => record.value === conditionValue.moduleCode) : "";
          moduleCodeDisabled = false;

          // PROJECT ----------------------------------
          project = conditionValue.project || "";
          projectDisabled = false;
          projectCon = condition2.find((record) => record.value === conditionRule.ruleProject) || condition2[0];
          projectArr = conditionValue.project
            ? projectList.filter((record) => {
                const values = conditionValue.project.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // PHASE -----------------------------------
          phase = conditionValue.phase || "";
          phaseDisabled = false;
          phaseCon = condition2.find((record) => record.value === conditionRule.rulePhase) || condition2[0];
          phaseArr = conditionValue.phase
            ? phaseList.filter((record) => {
                const values = conditionValue.phase.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LOT UNIT --------------------------------
          lotUnitCon = condition1.find((record) => record.value === conditionRule.ruleLotUnit) || condition1[0];
          lotUnitDisabled = false;
          lotUnit = conditionValue.lotUnit || "";
        } else if (conditionValue.contactType === "subsales_buyer") {
          // BUYER ROLE --------------------------------
          buyerRole = conditionValue.buyerRole || "";
          buyerRoleDisabled = false;
          buyerRoleCon = condition4.find((record) => record.value === conditionRule.ruleBuyerRole) || condition4[0];
          buyerRoleArr = conditionValue.buyerRole
            ? buyerRoleList.filter((record) => {
                const values = conditionValue.buyerRole.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LOYALTY TIER ------------------------------
          tier = conditionValue.loyaltyTier || "";
          tierDisabled = false;
          tierCon = condition2.find((record) => record.value === conditionRule.ruleLoyaltyTier) || condition2[0];
          tierArr = conditionValue.loyaltyTier
            ? tierList.filter((record) => {
                const values = conditionValue.loyaltyTier.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LIFESTYLE ---------------------------------
          lifestyle = conditionValue.lifestyleRegistration || "";
          lifestyleDisabled = false;
          lifestyleCon = condition2.find((record) => record.value === conditionRule.ruleLifestyleRegistration) || condition2[0];
          lifestyleArr = conditionValue.lifestyleRegistration
            ? lifestyleList.filter((record) => {
                const values = conditionValue.lifestyleRegistration.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // SOURCE ------------------------------------
          source = conditionValue.source || "";
          sourceDisabled = false;
          sourceCon = condition2.find((record) => record.value === conditionRule.ruleSource) || condition2[0];
          sourceArr = conditionValue.source
            ? sourceList.filter((record) => {
                const values = conditionValue.source.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // SALES STATUS ------------------------------
          salesStatus = conditionValue.salesStatus || "";
          salesStatusDisabled = false;
          salesStatusCon = condition2.find((record) => record.value === conditionRule.ruleSalesStatus) || condition2[0];
          salesStatusArr = conditionValue.salesStatus
            ? salesStatusList.filter((record) => {
                const values = conditionValue.salesStatus.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // STATUS REASON -----------------------------
          statusReasonCon = condition1.find((record) => record.value === conditionRule.ruleStatusReason) || condition1[0];
          statusReason = conditionValue.statusReason || "";
          statusReasonDisabled = false;

          // SALES DATE --------------------------------
          salesDateCon = condition3.find((record) => record.value === conditionRule.ruleSalesDate) || condition3[0];
          salesDateStart = conditionValue.salesDateStart || "";
          salesDateEnd = conditionValue.salesDateEnd || "";
          salesDateDisabled = false;

          // SPA DATE ----------------------------------
          spaDateCon = condition3.find((record) => record.value === conditionRule.ruleSpaDate) || condition3[0];
          spaDateStart = conditionValue.spaDateStart || "";
          spaDateEnd = conditionValue.spaDateEnd || "";
          spaDateDisabled = false;

          // SPA STAMPED DATE -------------------------
          stampedDateCon = condition3.find((record) => record.value === conditionRule.ruleSpaStampedDate) || condition3[0];
          stampedDateStart = conditionValue.spaStampedDateStart || "";
          stampedDateEnd = conditionValue.spaStampedDateEnd || "";
          stampedDateDisabled = false;

          // ACCUMULATIVE VALUE -----------------------
          accumCon = condition3.find((record) => record.value === conditionRule.ruleTotalAccumulative) || condition3[0];
          accumStart = conditionValue.totalAccumulativeStart || "";
          accumEnd = conditionValue.totalAccumulativeEnd || "";
          accumDisabled = false;

          // PROJECT ----------------------------------
          project = conditionValue.project || "";
          projectDisabled = false;
          projectCon = condition2.find((record) => record.value === conditionRule.ruleProject) || condition2[0];
          projectArr = conditionValue.project
            ? projectList.filter((record) => {
                const values = conditionValue.project.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // PHASE -----------------------------------
          phase = conditionValue.phase || "";
          phaseDisabled = false;
          phaseCon = condition2.find((record) => record.value === conditionRule.rulePhase) || condition2[0];
          phaseArr = conditionValue.phase
            ? phaseList.filter((record) => {
                const values = conditionValue.phase.split(",");
                return values.some((value) => record.value.includes(value));
              })
            : [];

          // LOT UNIT --------------------------------
          lotUnitCon = condition1.find((record) => record.value === conditionRule.ruleLotUnit) || condition1[0];
          lotUnitDisabled = false;
          lotUnit = conditionValue.lotUnit || "";
        }

        var pdpaCon = condition2.find((record) => record.value === conditionRule.rulePdpa) || condition2[0];
        var pdpa = conditionValue.pdpa ? contactPreferenceList.find((record) => record.value === conditionValue.pdpa) : "";
        var emailCPCon = condition2.find((record) => record.value === conditionRule.ruleEmail) || condition2[0];
        var emailCP = conditionValue.email ? contactPreferenceList.find((record) => record.value === conditionValue.email) : "";
        var emailBulkCon = condition2.find((record) => record.value === conditionRule.ruleBulkEmail) || condition2[0];
        var emailBulk = conditionValue.bulkEmail ? contactPreferenceList.find((record) => record.value === conditionValue.bulkEmail) : "";
        var phoneCon = condition2.find((record) => record.value === conditionRule.rulePhone) || condition2[0];
        var phone = conditionValue.phone ? contactPreferenceList.find((record) => record.value === conditionValue.phone) : "";
        var faxCon = condition2.find((record) => record.value === conditionRule.ruleFax) || condition2[0];
        var fax = conditionValue.fax ? contactPreferenceList.find((record) => record.value === conditionValue.fax) : "";
        var mailCon = condition2.find((record) => record.value === conditionRule.ruleMail) || condition2[0];
        var mail = conditionValue.mail ? contactPreferenceList.find((record) => record.value === conditionValue.mail) : "";
        var whatsappCon = condition2.find((record) => record.value === conditionRule.ruleWhatsapp) || condition2[0];
        var whatsapp = conditionValue.whatsapp ? contactPreferenceList.find((record) => record.value === conditionValue.whatsapp) : "";

        const values = {
          nameCon: nameCon,
          nameReset: false,
          name: name,
          emailCon: emailCon,
          emailReset: false,
          email: email,
          corporateCon: corporateCon,
          corporateReset: false,
          corporateArr: corporateArr,
          corporate: corporate,
          dobCon: dobCon,
          dobReset: false,
          dobStart: dobStart,
          dobEnd: dobEnd,
          ageCon: ageCon,
          ageReset: false,
          ageStart: ageStart,
          ageEnd: ageEnd,
          birthCon: birthCon,
          birthReset: false,
          birthArr: birthArr,
          birth: birth,
          genderCon: genderCon,
          genderReset: false,
          genderArr: genderArr,
          gender: gender,
          salutationCon: salutationCon,
          salutationReset: false,
          salutationArr: salutationArr,
          salutation: salutation,
          raceCon: raceCon,
          raceReset: false,
          raceArr: raceArr,
          race: race,
          nationalityCon: nationalityCon,
          nationalityReset: false,
          nationalityArr: nationalityArr,
          nationality: nationality,
          maritalCon: maritalCon,
          maritalReset: false,
          maritalArr: maritalArr,
          marital: marital,
          countryCon: countryCon,
          countryReset: false,
          countryArr: countryArr,
          country: country,
          stateCon: stateCon,
          stateReset: false,
          stateDisabled: stateDisabled,
          stateArr: stateArr,
          state: state,
          cityCon: cityCon,
          cityReset: false,
          cityDisabled: cityDisabled,
          cityArr: cityArr,
          city: city,
          contactTypeCon: contactTypeCon,
          contactTypeReset: false,
          contactType: contactType,
          projectInterestedCon: projectInterestedCon,
          projectInterestedDisabled: projectInterestedDisabled,
          projectInterestedReset: false,
          projectInterested: projectInterested,
          sourceCategoryCon: sourceCategoryCon,
          sourceCategoryDisabled: sourceCategoryDisabled,
          sourceCategoryReset: false,
          sourceCategoryArr: sourceCategoryArr,
          sourceCategory: sourceCategory,
          dealSourceCon: dealSourceCon,
          dealSourceDisabled: dealSourceDisabled,
          dealSourceReset: false,
          dealSourceArr: dealSourceArr,
          dealSource: dealSource,
          pipelineCon: pipelineCon,
          pipelineDisabled: pipelineDisabled,
          pipelineReset: false,
          pipelineArr: pipelineArr,
          pipeline: pipeline,
          stageCon: stageCon,
          stageDisabled: stageDisabled,
          stageReset: false,
          stageArr: stageArr,
          stage: stage,
          dealStatusCon: dealStatusCon,
          dealStatusDisabled: dealStatusDisabled,
          dealStatusReset: false,
          dealStatusArr: dealStatusArr,
          dealStatus: dealStatus,
          dealOutcomeCon: dealOutcomeCon,
          dealOutcomeDisabled: dealOutcomeDisabled,
          dealOutcomeReset: false,
          dealOutcomeArr: dealOutcomeArr,
          dealOutcome: dealOutcome,
          dealCreatedCon: dealCreatedCon,
          dealCreatedDisabled: dealCreatedDisabled,
          dealCreatedReset: false,
          dealCreatedStart: dealCreatedStart,
          dealCreatedEnd: dealCreatedEnd,
          buyerRoleCon: buyerRoleCon,
          buyerRoleDisabled: buyerRoleDisabled,
          buyerRoleReset: false,
          buyerRoleArr: buyerRoleArr,
          buyerRole: buyerRole,
          tierCon: tierCon,
          tierDisabled: tierDisabled,
          tierReset: false,
          tierArr: tierArr,
          tier: tier,
          lifestyleCon: lifestyleCon,
          lifestyleDisabled: lifestyleDisabled,
          lifestyleReset: false,
          lifestyleArr: lifestyleArr,
          lifestyle: lifestyle,
          sourceCon: sourceCon,
          sourceDisabled: sourceDisabled,
          sourceReset: false,
          sourceArr: sourceArr,
          source: source,
          salesStatusCon: salesStatusCon,
          salesStatusDisabled: salesStatusDisabled,
          salesStatusReset: false,
          salesStatusArr: salesStatusArr,
          salesStatus: salesStatus,
          statusReasonCon: statusReasonCon,
          statusReasonDisabled: statusReasonDisabled,
          statusReasonReset: false,
          statusReason: statusReason,
          salesDateCon: salesDateCon,
          salesDateDisabled: salesDateDisabled,
          salesDateStatus: true,
          salesDateReset: false,
          salesDateStart: salesDateStart,
          salesDateEnd: salesDateEnd,
          spaDateCon: spaDateCon,
          spaDateDisabled: spaDateDisabled,
          spaDateReset: false,
          spaDateStart: spaDateStart,
          spaDateEnd: spaDateEnd,
          stampedDateCon: stampedDateCon,
          stampedDateDisabled: stampedDateDisabled,
          stampedDateReset: false,
          stampedDateStart: stampedDateStart,
          stampedDateEnd: stampedDateEnd,
          accumCon: accumCon,
          accumDisabled: accumDisabled,
          accumReset: false,
          accumStart: accumStart,
          accumEnd: accumEnd,
          moduleCodeCon: moduleCodeCon,
          moduleCodeDisabled: moduleCodeDisabled,
          moduleCodeReset: false,
          moduleCode: moduleCode,
          projectCon: projectCon,
          projectDisabled: projectDisabled,
          projectReset: false,
          projectArr: projectArr,
          project: project,
          phaseCon: phaseCon,
          phaseDisabled: phaseDisabled,
          phaseReset: false,
          phaseArr: phaseArr,
          phase: phase,
          lotUnitCon: lotUnitCon,
          lotUnitDisabled: lotUnitDisabled,
          lotUnitReset: false,
          lotUnit: lotUnit,
          pdpaCon: pdpaCon,
          pdpaReset: false,
          pdpa: pdpa,
          emailCPCon: emailCPCon,
          emailCPReset: false,
          emailCP: emailCP,
          emailBulkCon: emailBulkCon,
          emailBulkReset: false,
          emailBulk: emailBulk,
          phoneCon: phoneCon,
          phoneReset: false,
          phone: phone,
          faxCon: faxCon,
          faxReset: false,
          fax: fax,
          mailCon: mailCon,
          mailReset: false,
          mail: mail,
          whatsappCon: whatsappCon,
          whatsappReset: false,
          whatsapp: whatsapp,
        };

        setInitialValues(values);
        setLoading3(false);
        setShow(true);
        // sendFindProspect(values);
      } else {
        setLoading3(false);
        setShow(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomField = async () => {
    try {
      const response = await axios.get("ws/ws_custom_field.php", {
        params: {
          task: "5",
          company: session.company_id,
          area: "person",
        },
      });

      const data = response.data;
      if (data.status === 0) {
        var salutationData = data.record.find((record) => record.cf_label === "Salutation");
        var genderData = data.record.find((record) => record.cf_label === "Gender");
        var raceData = data.record.find((record) => record.cf_label === "Race");
        var nationalityData = data.record.find((record) => record.cf_label === "Nationality");
        var maritalStatusData = data.record.find((record) => record.cf_label === "Marital Status");
        var corporateData = data.record.find((record) => record.cf_label === "Corporate");

        var salutationValue = JSON.parse(salutationData.cf_value);
        var genderValue = JSON.parse(genderData.cf_value);
        var raceValue = JSON.parse(raceData.cf_value);
        var nationalityValue = JSON.parse(nationalityData.cf_value);
        var maritalStatusValue = JSON.parse(maritalStatusData.cf_value);
        var corporateValue = JSON.parse(corporateData.cf_value);

        const salutationOpts = Object.values(salutationValue).map((record) => ({
          label: record,
          value: record,
        }));

        const genderOpts = Object.values(genderValue).map((record) => ({
          label: record,
          value: record,
        }));

        const raceOpts = Object.values(raceValue).map((record) => ({
          label: record,
          value: record,
        }));

        const nationalityOpts = Object.values(nationalityValue).map((record) => ({
          label: record,
          value: record,
        }));

        const maritalStatusOpts = Object.values(maritalStatusValue).map((record) => ({
          label: record,
          value: record,
        }));

        const corporateOpts = Object.values(corporateValue).map((record) => ({
          label: record,
          value: record,
        }));

        setSalutationList(salutationOpts);
        setGenderList(genderOpts);
        setRaceList(raceOpts);
        setNationalityList(nationalityOpts);
        setMaritalList(maritalStatusOpts);
        setCorporateList(corporateOpts);
        getCondition();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCountry = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const recordOptions = data.record.map((record) => ({
        label: record.country_title,
        value: record.country_id,
      }));

      setCountryList(recordOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getState = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const recordOptions = data.record.map((record) => ({
        label: record.state_title,
        value: record.state_id,
        country: record.country_id,
      }));

      setStateList(recordOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getCity = async () => {
    try {
      const response = await axios.get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      const recordOptions = data.record.map((record) => ({
        label: record.city_title,
        value: record.city_id,
        state: record.state_id,
      }));

      setCityList(recordOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const getSourceCategory = async (company) => {
    try {
      const response = await axios.get("ws/ws_curation_segmentation.php", {
        params: {
          task: "GetSourceCategory",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: company,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.source_category_title,
          value: record.source_category_id,
        }));

        setSourceCategoryList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDealSource = async (company) => {
    try {
      const response = await axios.get("ws/ws_source.php", {
        params: {
          task: 2,
          company: company,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.ds_title,
          value: record.ds_id,
        }));

        setDealSourceList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPipeline = async (company) => {
    try {
      const response = await axios.get("ws/ws_setting.php", {
        params: {
          task: "getAllPipeline",
          company: company,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.pipeline_title,
          value: record.pipeline_id,
        }));

        setPipelineList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStage = async (company) => {
    try {
      const response = await axios.get("ws/ws_curation_segmentation.php", {
        params: {
          task: "GetStage",
          company_id: company,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.cs_title,
          value: record.cs_id,
        }));
        setStageList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSource = async () => {
    try {
      const response = await axios.get("ws/ws_curation_segmentation.php", {
        params: {
          task: "GetSourceCategory",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          company_id: 251,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.source_category_title,
          value: record.source_category_id,
        }));

        setSourceList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRRPhase = async (township) => {
    try {
      const response = await axios.get("ws/ws_curation_segmentation.php", {
        params: {
          task: "getPhaseTownship",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          project_township: township,
        },
      });

      const data = response.data;
      if (data.status === 0) {
        const recordOptions = data.record.map((record) => ({
          label: record.rr_value,
          value: `${record.township_company_id}_${record.rr_id}`,
        }));
        setPhaseList(recordOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // SEND FUNCTION ===============================================================

  const sendFindProspect = async (values) => {
    setLoading2(true);
    setInitialValues(values);
    try {
      var phaseTownship = [];
      var phaseId = [];

      if (values.phase) {
        values.phase.split(",").forEach((record) => {
          var parts = record.split("_");
          if (parts.length === 2) {
            phaseTownship.push(parts[0]);
            phaseId.push(parts[1]);
          }
        });
      }

      var formData = new FormData();
      formData.append("task", "getSegmentList");
      formData.append("ctoken", session.company_token);
      formData.append("utoken", session.user_ac_token);
      formData.append("name", values.name ? values.name : "");
      formData.append("ruleName", values.nameCon ? values.nameCon.value : "");
      formData.append("emailAddress", values.email ? values.email : "");
      formData.append("ruleEmailAddress", values.emailCon.value ? values.emailCon.value : "");
      formData.append("corporate", values.corporate ? values.corporate.replace(/,/g, "|") : "");
      formData.append("ruleCorporate", values.corporateCon ? values.corporateCon.value : "");
      formData.append("dobStart", values.dobStart ? values.dobStart : "");
      formData.append("dobEnd", values.dobEnd ? values.dobEnd : "");
      formData.append("ruleDob", values.dobCon ? values.dobCon.value : "");
      formData.append("ageStart", values.ageStart ? values.ageStart : "");
      formData.append("ageEnd", values.ageEnd ? values.ageEnd : "");
      formData.append("ruleAge", values.ageCon ? values.ageCon.value : "");
      formData.append("birthMonth", values.birth ? values.birth.replace(/,/g, "|") : "");
      formData.append("ruleBirthMonth", values.birthCon ? values.birthCon.value : "");
      formData.append("gender", values.gender ? values.gender.replace(/,/g, "|") : "");
      formData.append("ruleGender", values.genderCon ? values.genderCon.value : "");
      formData.append("salutation", values.salutation ? values.salutation.replace(/,/g, "|") : "");
      formData.append("ruleSalutation", values.salutationCon ? values.salutationCon.value : "");
      formData.append("race", values.race ? values.race.replace(/,/g, "|") : "");
      formData.append("ruleRace", values.raceCon ? values.raceCon.value : "");
      formData.append("nationality", values.nationality ? values.nationality.replace(/,/g, "|") : "");
      formData.append("ruleNationality", values.nationalityCon ? values.nationalityCon.value : "");
      formData.append("maritalStatus", values.marital ? values.marital.replace(/,/g, "|") : "");
      formData.append("ruleMaritalStatus", values.maritalCon ? values.maritalCon.value : "");
      formData.append("country", values.country ? values.country.replace(/,/g, "|") : "");
      formData.append("ruleCountry", values.countryCon ? values.countryCon.value : "");
      formData.append("state", values.state ? values.state.replace(/,/g, "|") : "");
      formData.append("ruleState", values.stateCon ? values.stateCon.value : "");
      formData.append("city", values.city ? values.city.replace(/,/g, "|") : "");
      formData.append("ruleCity", values.cityCon ? values.cityCon.value : "");
      formData.append("contactType", values.contactType ? values.contactType.value : "");
      formData.append("ruleContactType", values.contactTypeCon ? values.contactTypeCon.value : "");
      formData.append("township", values.projectInterested ? values.projectInterested.value : values.projectInterested);
      formData.append("ruleTownship", values.projectInterestedCon ? values.projectInterestedCon.value : "");
      formData.append("sourceCategory", values.sourceCategory ? values.sourceCategory.replace(/,/g, "|") : "");
      formData.append("ruleSourceCategory", values.sourceCategoryCon ? values.sourceCategoryCon.value : "");
      formData.append("dealSource", values.dealSource ? values.dealSource.replace(/,/g, "|") : "");
      formData.append("ruleDealSource", values.dealSourceCon ? values.dealSourceCon.value : "");
      formData.append("pipeline", values.pipeline ? values.pipeline.replace(/,/g, "|") : "");
      formData.append("rulePipeline", values.pipelineCon ? values.pipelineCon.value : "");
      formData.append("stage", values.stage ? values.stage.replace(/,/g, "|") : "");
      formData.append("ruleStage", values.stageCon ? values.stageCon.value : "");
      formData.append("dealStatus", values.dealStatus ? values.dealStatus.replace(/,/g, "|") : "");
      formData.append("ruleDealStatus", values.dealStatusCon ? values.dealStatusCon.value : "");
      formData.append("dealOutcome", values.dealOutcome ? values.dealOutcome.replace(/,/g, "|") : "");
      formData.append("ruleDealOutcome", values.dealOutcomeCon ? values.dealOutcomeCon.value : "");
      formData.append("dealCreatedStart", values.dealCreatedStart ? values.dealCreatedStart : "");
      formData.append("dealCreatedEnd", values.dealCreatedEnd ? values.dealCreatedEnd : "");
      formData.append("ruleDealCreated", values.dealCreatedCon ? values.dealCreatedCon.value : "");
      formData.append("buyer_role", values.buyerRole ? values.buyerRole.replace(/,/g, "|") : "");
      formData.append("ruleBuyerRole", values.buyerRoleCon ? values.buyerRoleCon.value : "");
      formData.append("loyalty_tier", values.tier ? values.tier.replace(/,/g, "|") : "");
      formData.append("ruleLoyaltyTier", values.tierCon ? values.tierCon.value : "");
      formData.append("lifestyleRegistration", values.lifestyle ? values.lifestyle.replace(/,/g, "|") : "");
      formData.append("ruleLifestyleRegistration", values.lifestyleCon ? values.lifestyleCon.value : "");
      formData.append("source", values.source ? values.source.replace(/,/g, "|") : "");
      formData.append("ruleSource", values.sourceCon ? values.sourceCon.value : "");
      formData.append("salesStatus", values.salesStatus ? values.salesStatus.replace(/,/g, "|") : "");
      formData.append("ruleSalesStatus", values.salesStatusCon ? values.salesStatusCon.value : "");
      formData.append("statusReason", values.statusReason ? values.statusReason.replace(/,/g, "|") : "");
      formData.append("ruleStatusReason", values.statusReasonCon ? values.statusReasonCon.value : "");
      formData.append("salesDateStart", values.salesDateStart ? values.salesDateStart : "");
      formData.append("salesDateEnd", values.salesDateStart ? values.salesDateEnd : "");
      formData.append("ruleSalesDate", values.salesDateCon ? values.salesDateCon.value : "");
      formData.append("spaDateStart", values.spaDateStart ? values.spaDateStart : "");
      formData.append("spaDateEnd", values.spaDateEnd ? values.spaDateEnd : "");
      formData.append("ruleSpaDate", values.spaDateCon ? values.spaDateCon.value : "");
      formData.append("spaStampedDateStart", values.stampedDateStart ? values.stampedDateStart : "");
      formData.append("spaStampedDateEnd", values.stampedDateEnd ? values.stampedDateEnd : "");
      formData.append("ruleSpaStampedDate", values.stampedDateCon ? values.stampedDateCon.value : "");
      formData.append("totalAccumulativeStart", values.accumStart ? values.accumStart : "");
      formData.append("totalAccumulativeEnd", values.accumEnd ? values.accumEnd : "");
      formData.append("ruleTotalAccumulative", values.accumCon ? values.accumCon.value : "");
      formData.append("moduleCode", values.moduleCode ? values.moduleCode.value : "");
      formData.append("ruleModuleCode", values.moduleCodeCon ? values.moduleCodeCon.value : "");
      formData.append("project", values.project ? values.project.replace(/,/g, "|") : "");
      formData.append("ruleProject", values.projectCon ? values.projectCon.value : "");
      formData.append("phase", phaseTownship.length > 0 ? phaseTownship.join("|") : "");
      formData.append("phaseId", phaseId.length > 0 ? phaseId.join("|") : "");
      formData.append("rulePhase", values.phaseCon ? values.phaseCon.value : "");
      formData.append("lotUnit", values.lotUnit ? values.lotUnit : "");
      formData.append("ruleLotUnit", values.lotUnitCon ? values.lotUnitCon.value : "");
      formData.append("pdpa", values.pdpa ? values.pdpa.value : "");
      formData.append("rulePdpa", values.pdpaCon ? values.pdpaCon.value : "");
      formData.append("email", values.emailCP ? values.emailCP.value : "");
      formData.append("ruleEmail", values.emailCPCon ? values.emailCPCon.value : "");
      formData.append("email_bulk", values.emailBulk ? values.emailBulk.value : "");
      formData.append("ruleBulkEmail", values.emailBulkCon ? values.emailBulkCon.value : "");
      formData.append("phone", values.phone ? values.phone.value : "");
      formData.append("rulePhone", values.phoneCon ? values.phoneCon.value : "");
      formData.append("fax", values.fax ? values.fax.value : "");
      formData.append("ruleFax", values.faxCon ? values.faxCon.value : "");
      formData.append("mail", values.mail ? values.mail.value : "");
      formData.append("ruleMail", values.mailCon ? values.mailCon.value : "");
      formData.append("whatsapp", values.whatsapp ? values.whatsapp.value : "");
      formData.append("ruleWhatsapp", values.whatsappCon ? values.whatsappCon.value : "");

      const response = await axios.post("ws/ws_curation_segmentation.php", formData);
      const data = response.data;
      if (data.status === 0) {
        data.record.forEach((record, index) => {
          if (record.email1 !== "") {
            record.prospect_email = record.email1;
          } else if (record.email2 !== "") {
            record.prospect_email = record.email2;
          } else if (record.email3 !== "") {
            record.prospect_email = record.email3;
          } else {
            record.prospect_email = "";
          }

          record.index = index;
        });

        setTotalRecord(data.record.length);
        setTotalSelected(0);
        setProspectList(data.record);
        setLoading2(false);
      } else {
        setTotalRecord(0);
        setTotalSelected(0);
        setProspectList([]);
        setLoading2(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendClear = () => {
    setShow(false);
    setProspectList([]);
    setTotalRecord(0);
    setTotalSelected(0);
    setSelectedProspect([]);
    setInitialValues({
      nameCon: condition1[0],
      nameReset: false,
      name: "",
      emailCon: condition1[0],
      emailReset: false,
      email: "",
      corporateCon: condition2[0],
      corporateReset: false,
      corporateArr: [],
      corporate: "",
      dobCon: condition3[0],
      dobReset: false,
      dobStart: "",
      dobEnd: "",
      ageCon: condition3[0],
      ageReset: false,
      ageStart: "",
      ageEnd: "",
      birthCon: condition2[0],
      birthReset: false,
      birthArr: [],
      birth: "",
      genderCon: condition2[0],
      genderReset: false,
      genderArr: [],
      gender: "",
      salutationCon: condition2[0],
      salutationReset: false,
      salutationArr: [],
      salutation: "",
      raceCon: condition2[0],
      raceReset: false,
      raceArr: [],
      race: "",
      nationalityCon: condition2[0],
      nationalityReset: false,
      nationalityArr: [],
      nationality: "",
      maritalCon: condition2[0],
      maritalReset: false,
      maritalArr: [],
      marital: "",
      countryCon: condition2[0],
      countryReset: false,
      countryArr: [],
      country: "",
      stateCon: condition2[0],
      stateReset: false,
      stateDisabled: true,
      stateArr: [],
      state: "",
      cityCon: condition2[0],
      cityReset: false,
      cityDisabled: true,
      cityArr: [],
      city: "",
      contactTypeCon: condition2[0],
      contactTypeReset: false,
      contactType: contactTypeList[0],
      projectInterestedCon: condition4[0],
      projectInterestedDisabled: true,
      projectInterestedReset: false,
      projectInterested: "",
      sourceCategoryCon: condition2[0],
      sourceCategoryDisabled: true,
      sourceCategoryReset: false,
      sourceCategoryArr: [],
      sourceCategory: "",
      dealSourceCon: condition2[0],
      dealSourceDisabled: true,
      dealSourceReset: false,
      dealSourceArr: [],
      dealSource: "",
      pipelineCon: condition2[0],
      pipelineDisabled: true,
      pipelineReset: false,
      pipelineArr: [],
      pipeline: "",
      stageCon: condition2[0],
      stageDisabled: true,
      stageReset: false,
      stageArr: [],
      stage: "",
      dealStatusCon: condition2[0],
      dealStatusDisabled: true,
      dealStatusReset: false,
      dealStatusArr: [],
      dealStatus: "",
      dealOutcomeCon: condition2[0],
      dealOutcomeDisabled: true,
      dealOutcomeReset: false,
      dealOutcomeArr: [],
      dealOutcome: "",
      dealCreatedCon: condition3[0],
      dealCreatedDisabled: true,
      dealCreatedReset: false,
      dealCreatedStart: "",
      dealCreatedEnd: "",
      buyerRoleCon: condition4[0],
      buyerRoleDisabled: true,
      buyerRoleReset: false,
      buyerRoleArr: [],
      buyerRole: "",
      tierCon: condition2[0],
      tierDisabled: true,
      tierReset: false,
      tierArr: [],
      tier: "",
      lifestyleCon: condition2[0],
      lifestyleDisabled: true,
      lifestyleReset: false,
      lifestyleArr: [],
      lifestyle: "",
      sourceCon: condition2[0],
      sourceDisabled: true,
      sourceReset: false,
      sourceArr: [],
      source: "",
      salesStatusCon: condition2[0],
      salesStatusDisabled: true,
      salesStatusReset: false,
      salesStatusArr: [],
      salesStatus: "",
      statusReasonCon: condition1[0],
      statusReasonDisabled: true,
      statusReasonReset: false,
      statusReason: "",
      salesDateCon: condition3[0],
      salesDateDisabled: true,
      salesDateStatus: true,
      salesDateReset: false,
      salesDateStart: "",
      salesDateEnd: "",
      spaDateCon: condition3[0],
      spaDateDisabled: true,
      spaDateReset: false,
      spaDateStart: "",
      spaDateEnd: "",
      stampedDateCon: condition3[0],
      stampedDateDisabled: true,
      stampedDateReset: false,
      stampedDateStart: "",
      stampedDateEnd: "",
      accumCon: condition3[0],
      accumDisabled: true,
      accumReset: false,
      accumStart: "",
      accumEnd: "",
      moduleCodeCon: condition2[0],
      moduleCodeDisabled: true,
      moduleCodeReset: false,
      moduleCode: "",
      projectCon: condition2[0],
      projectDisabled: true,
      projectReset: false,
      projectArr: [],
      project: "",
      phaseCon: condition2[0],
      phaseDisabled: true,
      phaseReset: false,
      phaseArr: [],
      phase: "",
      lotUnitCon: condition1[0],
      lotUnitDisabled: true,
      lotUnitReset: false,
      lotUnit: "",
      pdpaCon: condition2[0],
      pdpaReset: false,
      pdpa: "",
      emailCPCon: condition2[0],
      emailCPReset: false,
      emailCP: "",
      emailBulkCon: condition2[0],
      emailBulkReset: false,
      emailBulk: "",
      phoneCon: condition2[0],
      phoneReset: false,
      phone: "",
      faxCon: condition2[0],
      faxReset: false,
      fax: "",
      mailCon: condition2[0],
      mailReset: false,
      mail: "",
      whatsappCon: condition2[0],
      whatsappReset: false,
      whatsapp: "",
    });

    setTimeout(() => setShow(true), 1);
  };

  const sendSegment = async (record) => {
    setLoading4(true);
    try {
      const prospectName = record.map((item) => {
        return item.prospect_name;
      });

      const prospectEmail = record.map((item) => {
        return item.prospect_email;
      });

      const prospectId = record.map((item) => {
        return item.prospect_id;
      });

      const formData = new FormData();
      formData.append("task", "SendLabelToMTARGET");
      formData.append("prospect_ids", prospectId.join("[-0-]"));
      formData.append("prospect_names", prospectName.join("[-0-]"));
      formData.append("prospect_emails", prospectEmail.join("[-0-]"));
      formData.append("curation_id", curation_id);
      formData.append("ctoken", session.company_token);
      formData.append("utoken", session.user_ac_token);

      const response = await axios.post("ws/ws_curation_segmentation.php", formData);
      const data = response.data;
      if (data.status === 0) {
        await sendCondition(initialValues);
        navigate(`/curation/${curation_id}`);
      } else {
        setLoading4(false);
        Swal.fire({
          icon: "error",
          text: "Something wrong with your entry. please try again or contact our IT support",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading4(false);
    }
  };

  const sendCondition = async (values) => {
    try {
      var rule = {
        ruleName: values.nameCon.value,
        ruleSalutation: values.salutationCon.value,
        ruleGender: values.genderCon.value,
        ruleProject: values.projectCon.value,
        ruleLoyaltyTier: values.tierCon.value,
        ruleBuyerRole: values.buyerRoleCon.value,
        ruleEmail: values.emailCPCon.value,
        ruleBulkEmail: values.emailBulkCon.value,
        rulePhone: values.phoneCon.value,
        ruleFax: values.faxCon.value,
        ruleMail: values.mailCon.value,
        ruleWhatsapp: values.whatsappCon.value,
        rulePdpa: values.pdpaCon.value,
        ruleDob: values.dobCon.value,
        ruleContactType: values.contactTypeCon.value,
        ruleSalesDate: values.salesDateCon.value,
        ruleSpaDate: values.spaDateCon.value,
        ruleSpaStampedDate: values.stampedDateCon.value,
        ruleAge: values.ageCon.value,
        ruleRace: values.raceCon.value,
        ruleNationality: values.nationalityCon.value,
        ruleMaritalStatus: values.maritalCon.value,
        ruleCountry: values.countryCon.value,
        ruleState: values.stateCon.value,
        ruleCity: values.cityCon.value,
        ruleCorporate: values.corporateCon.value,
        ruleSalesStatus: values.salesStatusCon.value,
        ruleTotalAccumulative: values.accumCon.value,
        rulePhase: values.phaseCon.value,
        ruleBirthMonth: values.birthCon.value,
        ruleTownship: values.projectInterestedCon.value,
        ruleDealSource: values.dealSourceCon.value,
        ruleLotUnit: values.lotUnitCon.value,
        ruleStatusReason: values.statusReasonCon.value,
        ruleLifestyleRegistration: values.lifestyleCon.value,
        ruleSourceCategory: values.sourceCategoryCon.value,
        rulePipeline: values.pipelineCon.value,
        ruleDealStatus: values.dealStatusCon.value,
        ruleStage: values.stageCon.value,
        ruleModuleCode: values.moduleCodeCon.value,
        ruleEmailAddress: values.emailCon.value,
        ruleDealOutcome: values.dealOutcomeCon.value,
        ruleDealCreated: values.dealCreatedCon.value,
        ruleSource: values.sourceCon.value,
      };

      var value = {
        name: values.name,
        salutation: values.salutation ? values.salutation : "",
        gender: values.gender ? values.gender : "",
        project: values.project ? values.project : "",
        loyaltyTier: values.tier ? values.tier : "",
        buyerRole: values.buyerRole ? values.buyerRole : "",
        email: values.emailCP ? values.emailCP.value : "",
        bulkEmail: values.emailBulk ? values.emailBulk.value : "",
        phone: values.phone ? values.phone.value : "",
        fax: values.fax ? values.fax.value : "",
        mail: values.mail ? values.mail.value : "",
        whatsapp: values.whatsapp ? values.whatsapp.value : "",
        pdpa: values.pdpa ? values.pdpa.value : "",
        dobStart: values.dobStart ? values.dobStart : "",
        dobEnd: values.dobEnd ? values.dobEnd : "",
        contactType: values.contactType ? values.contactType.value : "",
        salesDateStart: values.salesDateStart ? values.salesDateStart : "",
        salesDateEnd: values.salesDateStart ? values.salesDateEnd : "",
        spaDateStart: values.spaDateStart ? values.spaDateStart : "",
        spaDateEnd: values.spaDateEnd ? values.spaDateEnd : "",
        spaStampedDateStart: values.stampedDateStart ? values.stampedDateStart : "",
        spaStampedDateEnd: values.stampedDateEnd ? values.stampedDateEnd : "",
        ageStart: values.ageStart ? values.ageStart : "",
        ageEnd: values.ageEnd ? values.ageEnd : "",
        race: values.race ? values.race : "",
        nationality: values.nationality ? values.nationality : "",
        maritalStatus: values.marital ? values.marital : "",
        country: values.country ? values.country : "",
        state: values.state ? values.state : "",
        city: values.city ? values.city : "",
        corporate: values.corporate ? values.corporate : "",
        salesStatus: values.salesStatus ? values.salesStatus : "",
        birthMonth: values.birth ? values.birth : "",
        totalAccumulativeStart: values.accumStart ? values.accumStart : "",
        totalAccumulativeEnd: values.accumEnd ? values.accumEnd : "",
        township: values.projectInterested ? values.projectInterested.value : values.projectInterested,
        dealSource: values.dealSource ? values.dealSource : "",
        lotUnit: values.lotUnit ? values.lotUnit : "",
        statusReason: values.statusReason ? values.statusReason : "",
        lifestyleRegistration: values.lifestyle ? values.lifestyle : "",
        sourceCategory: values.sourceCategory ? values.sourceCategory : "",
        pipeline: values.pipeline ? values.pipeline : "",
        dealStatus: values.dealStatus ? values.dealStatus : "",
        stage: values.stage ? values.stage : "",
        moduleCode: values.moduleCode ? values.moduleCode.value : "",
        emailAddress: values.email ? values.email : "",
        dealOutcome: values.dealOutcome ? values.dealOutcome : "",
        dealCreatedStart: values.dealCreatedStart ? values.dealCreatedStart : "",
        dealCreatedEnd: values.dealCreatedEnd ? values.dealCreatedEnd : "",
        source: values.source ? values.source : "",
      };

      var ruleJson = JSON.stringify(rule);
      var valueJson = JSON.stringify(value);
      const formData = new FormData();
      formData.append("task", "CreateSegmentFilter");
      formData.append("utoken", session.user_ac_token);
      formData.append("ctoken", session.company_token);
      formData.append("curation_id", curation_id);
      formData.append("segment_title", "curation_" + curation_id + "_" + Date.now());
      formData.append("segment_data", valueJson);
      formData.append("segment_rule", ruleJson);
      await axios.post("ws/ws_curation_segmentation.php", formData);
    } catch (error) {
      console.error(error);
    }
  };

  // GRID FUNCTION ===============================================================

  const gridRef = useRef();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const gridColumn = [
    {
      headerName: "No",
      field: "",
      maxWidth: 100,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      cellRenderer: (params) => {
        if (params.data) {
          return params.rowIndex + 1;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
    },
    {
      headerName: "Prospect Name",
      field: "prospect_name",
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: "Prospect Email",
      field: "prospect_email",
    },
  ];

  const gridRowId = useCallback(function (params) {
    return params.data.index.toString();
  }, []);

  const gridColDef = useMemo(() => {
    return {
      flex: 1,
      sortable: true,
      filter: true,
    };
  }, []);

  const gridSelectionChanged = useCallback(
    (params) => {
      setTotalSelected(gridRef.current.api.getSelectedRows().length);
      setSelectedProspect(gridRef.current.api.getSelectedRows());
    },
    [gridRef]
  );

  // USEEFFECT FUNCTION =======================================================

  useEffect(() => {
    if (!init.current) {
      Promise.all([getCustomField(), getCountry(), getState(), getCity(), getSource(), getRRPhase()]).then(() => {
        init.current = true;
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
    }
  }, [session]);

  return (
    <div fluid="true">
      {loading || loading3 || !show ? (
        <Loader />
      ) : (
        <Row className="g-0 p-0 m-0">
          <Col xxl={7} className="p-0 m-0">
            <div className="w-100" style={{ height: "calc(100vh - 56px)" }}>
              {show && (
                <Formik onSubmit={sendFindProspect} initialValues={initialValues}>
                  {({ handleSubmit, handleChange, setFieldValue, values }) => (
                    <Form noValidate onSubmit={handleSubmit} className="w-100 h-100 border-0">
                      <Card className="w-100 h-100 border-0" style={{ borderRadius: 0, backgroundColor: "transparent" }}>
                        <Card.Body className="op-scrollbar">
                          <Card className="w-100 border shadow-sm mb-4">
                            <Card.Header>Prospect</Card.Header>
                            <Card.Body>
                              {/* NAME */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Name
                                </Form.Label>
                                <div className="w-25">{!values.nameReset && <FormSelect options={condition1} valueDefault={values.nameCon} onChange={(e) => setFieldValue("nameCon", e)} width="100%" />}</div>
                                <Form.Control className="w-50" value={values.name} onChange={handleChange("name")} />
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("nameReset", true);
                                    setFieldValue("nameCon", condition1[0]);
                                    setFieldValue("name", "");
                                    setTimeout(() => setFieldValue("nameReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* EMAIL */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Email
                                </Form.Label>
                                <div className="w-25">{!values.emailReset && <FormSelect options={condition1} valueDefault={values.emailCon} onChange={(e) => setFieldValue("emailCon", e)} width="100%" />}</div>
                                <Form.Control className="w-50" value={values.email} onChange={handleChange("email")} />
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("emailReset", true);
                                    setFieldValue("emailCon", condition1[0]);
                                    setFieldValue("email", "");
                                    setTimeout(() => setFieldValue("emailReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* CORPORATE */}
                              {corporateList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Corporate
                                  </Form.Label>
                                  <div className="w-25">{!values.corporateReset && <FormSelect options={condition2} valueDefault={values.corporateCon} onChange={(e) => setFieldValue("corporateCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.corporateReset && (
                                      <FormSelect
                                        options={corporateList}
                                        isMulti={true}
                                        valueDefault={values.corporateArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("corporate", e.map((record) => record.value).toString());
                                            setFieldValue("corporateArr", e);
                                          } else {
                                            setFieldValue("corporate", "");
                                            setFieldValue("corporateArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("corporateReset", true);
                                      setFieldValue("corporateCon", condition2[0]);
                                      setFieldValue("corporate", "");
                                      setTimeout(() => setFieldValue("corporateReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* DOB */}
                              {}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  DOB
                                </Form.Label>
                                <div className="w-25">{!values.dobReset && <FormSelect options={condition3} valueDefault={values.dobCon} onChange={(e) => setFieldValue("dobCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="date" className="w-50 me-1" value={values.dobStart} onChange={handleChange("dobStart")} />
                                  <Form.Control type="date" className="w-50" value={values.dobEnd} onChange={handleChange("dobEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("dobReset", true);
                                    setFieldValue("dobCon", condition3[0]);
                                    setFieldValue("dobStart", "");
                                    setFieldValue("dobEnd", "");
                                    setTimeout(() => setFieldValue("dobReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* AGE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Age
                                </Form.Label>
                                <div className="w-25">{!values.ageReset && <FormSelect options={condition3} valueDefault={values.ageCon} onChange={(e) => setFieldValue("ageCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="number" className="w-50 me-1" value={values.ageStart} onChange={handleChange("ageStart")} />
                                  <Form.Control type="number" className="w-50" value={values.ageEnd} onChange={handleChange("ageEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("ageReset", true);
                                    setFieldValue("ageCon", condition3[0]);
                                    setFieldValue("ageStart", "");
                                    setFieldValue("ageEnd", "");
                                    setTimeout(() => setFieldValue("ageReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* BIRTH MONTH */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Birth Month
                                </Form.Label>
                                <div className="w-25">{!values.birthReset && <FormSelect options={condition2} valueDefault={values.birthCon} onChange={(e) => setFieldValue("birthCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.birthReset && (
                                    <FormSelect
                                      options={birthList}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.birthArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("birth", e.map((record) => record.value).toString());
                                          setFieldValue("birthArr", e);
                                        } else {
                                          setFieldValue("birth", "");
                                          setFieldValue("birthArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("birthReset", true);
                                    setFieldValue("birthCon", condition2[0]);
                                    setFieldValue("birthArr", []);
                                    setFieldValue("birth", "");
                                    setTimeout(() => setFieldValue("birthReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* GENDER */}
                              {genderList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Gender
                                  </Form.Label>
                                  <div className="w-25">{!values.genderReset && <FormSelect options={condition2} valueDefault={values.genderCon} onChange={(e) => setFieldValue("genderCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.genderReset && (
                                      <FormSelect
                                        options={genderList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.genderArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("gender", e.map((record) => record.value).toString());
                                            setFieldValue("genderArr", e);
                                          } else {
                                            setFieldValue("gender", "");
                                            setFieldValue("genderArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("genderReset", true);
                                      setFieldValue("genderCon", condition2[0]);
                                      setFieldValue("genderArr", []);
                                      setFieldValue("gender", "");
                                      setTimeout(() => setFieldValue("genderReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* SALUTATION */}
                              {salutationList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Salutation
                                  </Form.Label>
                                  <div className="w-25">{!values.salutationReset && <FormSelect options={condition2} valueDefault={values.salutationCon} onChange={(e) => setFieldValue("salutationCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.salutationReset && (
                                      <FormSelect
                                        options={salutationList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.salutationArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("salutation", e.map((record) => record.value).toString());
                                            setFieldValue("salutationArr", e);
                                          } else {
                                            setFieldValue("salutation", "");
                                            setFieldValue("salutationArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("salutationReset", true);
                                      setFieldValue("salutationCon", condition2[0]);
                                      setFieldValue("salutationArr", []);
                                      setFieldValue("salutation", "");
                                      setTimeout(() => setFieldValue("salutationReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* RACE */}
                              {raceList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Race
                                  </Form.Label>
                                  <div className="w-25">{!values.raceReset && <FormSelect options={condition2} valueDefault={values.raceCon} onChange={(e) => setFieldValue("raceCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.raceReset && (
                                      <FormSelect
                                        options={raceList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.raceArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("race", e.map((record) => record.value).toString());
                                            setFieldValue("raceArr", e);
                                          } else {
                                            setFieldValue("race", "");
                                            setFieldValue("raceArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("raceReset", true);
                                      setFieldValue("raceCon", condition2[0]);
                                      setFieldValue("raceArr", []);
                                      setFieldValue("race", "");
                                      setTimeout(() => setFieldValue("raceReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* NATIONALITY */}
                              {nationalityList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Nationality
                                  </Form.Label>
                                  <div className="w-25">{!values.nationalityReset && <FormSelect options={condition2} valueDefault={values.nationalityCon} onChange={(e) => setFieldValue("nationalityCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.nationalityReset && (
                                      <FormSelect
                                        options={nationalityList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.nationalityArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("nationality", e.map((record) => record.value).toString());
                                            setFieldValue("nationalityArr", e);
                                          } else {
                                            setFieldValue("nationality", "");
                                            setFieldValue("nationalityArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("nationalityReset", true);
                                      setFieldValue("nationalityCon", condition2[0]);
                                      setFieldValue("nationalityArr", []);
                                      setFieldValue("nationality", "");
                                      setTimeout(() => setFieldValue("nationalityReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* MARITAL STATUS */}
                              {maritalList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Marital Status
                                  </Form.Label>
                                  <div className="w-25">{!values.maritalReset && <FormSelect options={condition2} valueDefault={values.maritalCon} onChange={(e) => setFieldValue("maritalCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.maritalReset && (
                                      <FormSelect
                                        options={maritalList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.maritalArr}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("marital", e.map((record) => record.value).toString());
                                            setFieldValue("maritalArr", e);
                                          } else {
                                            setFieldValue("marital", "");
                                            setFieldValue("maritalArr", []);
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("maritalReset", true);
                                      setFieldValue("maritalCon", condition2[0]);
                                      setFieldValue("maritalArr", []);
                                      setFieldValue("marital", "");
                                      setTimeout(() => setFieldValue("maritalReset", false), 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* COUNTRY */}
                              {countryList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    Country
                                  </Form.Label>
                                  <div className="w-25">{!values.countryReset && <FormSelect options={condition2} valueDefault={values.countryCon} onChange={(e) => setFieldValue("countryCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.countryReset && (
                                      <FormSelect
                                        options={countryList}
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.country}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("country", e.map((record) => record.value).toString());
                                            setFieldValue("countryArr", e);
                                            setFieldValue("stateDisabled", false);
                                            setFieldValue("cityDisabled", true);
                                          } else {
                                            setFieldValue("country", "");
                                            setFieldValue("countryArr", []);
                                            setFieldValue("stateDisabled", true);
                                            setFieldValue("cityDisabled", true);
                                          }

                                          setFieldValue("stateReset", true);
                                          setFieldValue("cityReset", true);
                                          setFieldValue("stateCon", condition2[0]);
                                          setFieldValue("cityCon", condition2[0]);
                                          setFieldValue("stateArr", []);
                                          setFieldValue("cityArr", []);
                                          setFieldValue("state", "");
                                          setFieldValue("city", "");
                                          setTimeout(() => {
                                            setFieldValue("stateReset", false);
                                            setFieldValue("cityReset", false);
                                          }, 1);
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("countryReset", true);
                                      setFieldValue("stateReset", true);
                                      setFieldValue("cityReset", true);
                                      setFieldValue("stateDisabled", true);
                                      setFieldValue("cityDisabled", true);
                                      setFieldValue("countryCon", condition2[0]);
                                      setFieldValue("stateCon", condition2[0]);
                                      setFieldValue("cityCon", condition2[0]);
                                      setFieldValue("country", "");
                                      setFieldValue("countryArr", []);
                                      setFieldValue("stateArr", []);
                                      setFieldValue("cityArr", []);
                                      setFieldValue("state", "");
                                      setFieldValue("city", "");
                                      setTimeout(() => {
                                        setFieldValue("countryReset", false);
                                        setFieldValue("stateReset", false);
                                        setFieldValue("cityReset", false);
                                      }, 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* STATE */}
                              {stateList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    State
                                  </Form.Label>
                                  <div className="w-25">{!values.stateReset && <FormSelect options={condition2} disabled={values.stateDisabled} valueDefault={values.stateCon} onChange={(e) => setFieldValue("stateCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.stateReset && (
                                      <FormSelect
                                        options={
                                          values.country
                                            ? stateList.filter((record) => {
                                                return values.country.split(",").includes(record.country);
                                              })
                                            : ""
                                        }
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.stateArr}
                                        disabled={values.stateDisabled}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("state", e.map((record) => record.value).toString());
                                            setFieldValue("stateArr", e);
                                            setFieldValue("cityDisabled", false);
                                          } else {
                                            setFieldValue("state", "");
                                            setFieldValue("stateArr", []);
                                            setFieldValue("cityDisabled", true);
                                          }

                                          setFieldValue("cityReset", true);
                                          setFieldValue("cityCon", condition2[0]);
                                          setFieldValue("cityArr", []);
                                          setFieldValue("city", "");
                                          setTimeout(() => {
                                            setFieldValue("cityReset", false);
                                          }, 1);
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("stateReset", true);
                                      setFieldValue("cityReset", true);
                                      setFieldValue("cityDisabled", true);
                                      setFieldValue("stateCon", condition2[0]);
                                      setFieldValue("cityCon", condition2[0]);
                                      setFieldValue("state", "");
                                      setFieldValue("stateArr", []);
                                      setFieldValue("cityArr", []);
                                      setFieldValue("city", "");
                                      setTimeout(() => {
                                        setFieldValue("stateReset", false);
                                        setFieldValue("cityReset", false);
                                      }, 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* CITY */}
                              {cityList.length > 0 && (
                                <Stack direction="horizontal" gap={1} className="mb-2">
                                  <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                    City
                                  </Form.Label>
                                  <div className="w-25">{!values.cityReset && <FormSelect options={condition2} disabled={values.cityDisabled} valueDefault={values.cityCon} onChange={(e) => setFieldValue("cityCon", e)} width="100%" />}</div>
                                  <div className="w-50">
                                    {!values.cityReset && (
                                      <FormSelect
                                        options={
                                          values.state
                                            ? cityList.filter((record) => {
                                                return values.state.split(",").includes(record.state);
                                              })
                                            : ""
                                        }
                                        isMulti={true}
                                        isSearchable={true}
                                        valueDefault={values.state}
                                        disabled={values.cityDisabled}
                                        onChange={(e) => {
                                          if (e) {
                                            setFieldValue("city", e.map((record) => record.value).toString());
                                          } else {
                                            setFieldValue("city", "");
                                          }
                                        }}
                                        width="100%"
                                      />
                                    )}
                                  </div>
                                  <Button
                                    variant=""
                                    type="button"
                                    size="sm"
                                    className="rounded-circle border"
                                    style={{ backgroundColor: "#eee" }}
                                    onClick={() => {
                                      setFieldValue("cityReset", true);
                                      setFieldValue("cityCon", condition2[0]);
                                      setFieldValue("cityArr", []);
                                      setFieldValue("city", "");
                                      setTimeout(() => {
                                        setFieldValue("cityReset", false);
                                      }, 1);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faArrowsRotate} />
                                  </Button>
                                </Stack>
                              )}

                              {/* CONTACT TYPE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Contact Type
                                </Form.Label>
                                <div className="w-25">{!values.contactTypeReset && <FormSelect options={condition4} valueDefault={values.contactTypeCon} onChange={(e) => setFieldValue("contactTypeCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.contactTypeReset && (
                                    <FormSelect
                                      options={contactTypeList}
                                      isSearchable={true}
                                      valueDefault={values.contactType}
                                      onChange={(e) => {
                                        setFieldValue("contactType", e);
                                        if (e.value === "all") {
                                          setFieldValue("projectInterestedDisabled", true);
                                          setFieldValue("sourceCategoryDisabled", true);
                                          setFieldValue("dealSourceDisabled", true);
                                          setFieldValue("pipelineDisabled", true);
                                          setFieldValue("stageDisabled", true);
                                          setFieldValue("dealStatusDisabled", true);
                                          setFieldValue("dealOutcomeDisabled", true);
                                          setFieldValue("dealCreatedDisabled", true);
                                          setFieldValue("buyerRoleDisabled", true);
                                          setFieldValue("tierDisabled", true);
                                          setFieldValue("lifestyleDisabled", true);
                                          setFieldValue("sourceDisabled", true);
                                          setFieldValue("salesStatusDisabled", true);
                                          setFieldValue("statusReasonDisabled", true);
                                          setFieldValue("salesDateDisabled", true);
                                          setFieldValue("spaDateDisabled", true);
                                          setFieldValue("spaDateDisabled", true);
                                          setFieldValue("stampedDateDisabled", true);
                                          setFieldValue("accumDisabled", true);
                                          setFieldValue("moduleCodeDisabled", true);
                                          setFieldValue("projectDisabled", true);
                                          setFieldValue("phaseDisabled", true);
                                          setFieldValue("lotUnitDisabled", true);
                                        } else if (e.value === "prospect") {
                                          setFieldValue("projectInterestedDisabled", false);
                                          setFieldValue("sourceCategoryDisabled", false);
                                          setFieldValue("dealSourceDisabled", false);
                                          setFieldValue("pipelineDisabled", false);
                                          setFieldValue("stageDisabled", false);
                                          setFieldValue("dealStatusDisabled", false);
                                          setFieldValue("dealOutcomeDisabled", false);
                                          setFieldValue("dealCreatedDisabled", false);
                                          setFieldValue("buyerRoleDisabled", true);
                                          setFieldValue("tierDisabled", true);
                                          setFieldValue("lifestyleDisabled", true);
                                          setFieldValue("sourceDisabled", true);
                                          setFieldValue("salesStatusDisabled", true);
                                          setFieldValue("statusReasonDisabled", true);
                                          setFieldValue("salesDateDisabled", true);
                                          setFieldValue("spaDateDisabled", true);
                                          setFieldValue("spaDateDisabled", true);
                                          setFieldValue("stampedDateDisabled", true);
                                          setFieldValue("accumDisabled", true);
                                          setFieldValue("moduleCodeDisabled", true);
                                          setFieldValue("projectDisabled", true);
                                          setFieldValue("phaseDisabled", true);
                                          setFieldValue("lotUnitDisabled", true);
                                        } else if (e.value === "buyer") {
                                          setFieldValue("projectInterestedDisabled", true);
                                          setFieldValue("sourceCategoryDisabled", true);
                                          setFieldValue("dealSourceDisabled", true);
                                          setFieldValue("pipelineDisabled", true);
                                          setFieldValue("stageDisabled", true);
                                          setFieldValue("dealStatusDisabled", true);
                                          setFieldValue("dealOutcomeDisabled", true);
                                          setFieldValue("dealCreatedDisabled", true);
                                          setFieldValue("buyerRoleDisabled", false);
                                          setFieldValue("tierDisabled", false);
                                          setFieldValue("lifestyleDisabled", false);
                                          setFieldValue("sourceDisabled", false);
                                          setFieldValue("salesStatusDisabled", false);
                                          setFieldValue("statusReasonDisabled", false);
                                          setFieldValue("salesDateDisabled", false);
                                          setFieldValue("spaDateDisabled", false);
                                          setFieldValue("spaDateDisabled", false);
                                          setFieldValue("stampedDateDisabled", false);
                                          setFieldValue("accumDisabled", false);
                                          setFieldValue("moduleCodeDisabled", false);
                                          setFieldValue("projectDisabled", false);
                                          setFieldValue("phaseDisabled", false);
                                          setFieldValue("lotUnitDisabled", false);
                                        } else {
                                          setFieldValue("projectInterestedDisabled", true);
                                          setFieldValue("sourceCategoryDisabled", true);
                                          setFieldValue("dealSourceDisabled", true);
                                          setFieldValue("pipelineDisabled", true);
                                          setFieldValue("stageDisabled", true);
                                          setFieldValue("dealStatusDisabled", true);
                                          setFieldValue("dealOutcomeDisabled", true);
                                          setFieldValue("dealCreatedDisabled", true);
                                          setFieldValue("buyerRoleDisabled", false);
                                          setFieldValue("tierDisabled", false);
                                          setFieldValue("lifestyleDisabled", false);
                                          setFieldValue("sourceDisabled", false);
                                          setFieldValue("salesStatusDisabled", false);
                                          setFieldValue("statusReasonDisabled", false);
                                          setFieldValue("salesDateDisabled", false);
                                          setFieldValue("spaDateDisabled", false);
                                          setFieldValue("spaDateDisabled", false);
                                          setFieldValue("stampedDateDisabled", false);
                                          setFieldValue("accumDisabled", false);
                                          setFieldValue("moduleCodeDisabled", true);
                                          setFieldValue("projectDisabled", false);
                                          setFieldValue("phaseDisabled", false);
                                          setFieldValue("lotUnitDisabled", false);
                                        }

                                        setFieldValue("projectInterested", "");
                                        setFieldValue("projectInterestedArr", []);
                                        setFieldValue("projectInterestedCon", condition2[0]);

                                        setFieldValue("sourceCategory", "");
                                        setFieldValue("sourceCategoryArr", []);
                                        setFieldValue("sourceCategoryCon", condition2[0]);

                                        setFieldValue("dealSource", "");
                                        setFieldValue("dealSourceArr", []);
                                        setFieldValue("dealSourceCon", condition2[0]);

                                        setFieldValue("pipeline", "");
                                        setFieldValue("pipelineArr", []);
                                        setFieldValue("pipelineCon", condition2[0]);

                                        setFieldValue("pipeline", "");
                                        setFieldValue("pipelineArr", []);
                                        setFieldValue("pipelineCon", condition2[0]);

                                        setFieldValue("stage", "");
                                        setFieldValue("stageArr", "");
                                        setFieldValue("stageCon", condition2[0]);

                                        setFieldValue("dealStatus", "");
                                        setFieldValue("dealStatusArr", []);
                                        setFieldValue("dealStatusCon", condition2[0]);

                                        setFieldValue("dealStatus", "");
                                        setFieldValue("dealOutcomeArr", []);
                                        setFieldValue("dealOutcomeCon", condition2[0]);

                                        setFieldValue("dealCreatedStart", "");
                                        setFieldValue("dealCreatedEnd", "");
                                        setFieldValue("dealCreatedCon", condition3[0]);

                                        setFieldValue("buyerRole", "");
                                        setFieldValue("buyerRoleArr", []);
                                        setFieldValue("buyerRoleCon", condition2[0]);

                                        setFieldValue("tier", "");
                                        setFieldValue("tierArr", []);
                                        setFieldValue("tierCon", condition2[0]);

                                        setFieldValue("lifestyle", "");
                                        setFieldValue("lifestyleArr", []);
                                        setFieldValue("lifestyleCon", condition2[0]);

                                        setFieldValue("source", "");
                                        setFieldValue("sourceArr", []);
                                        setFieldValue("sourceCon", condition2[0]);

                                        setFieldValue("salesStatus", "");
                                        setFieldValue("salesStatusArr", []);
                                        setFieldValue("salesStatusCon", condition2[0]);

                                        setFieldValue("statusReason", "");
                                        setFieldValue("statusReasonCon", condition1[0]);

                                        setFieldValue("salesDateStart", "");
                                        setFieldValue("salesDateEnd", "");
                                        setFieldValue("salesDateCon", condition3[0]);

                                        setFieldValue("spaDateStart", "");
                                        setFieldValue("spaDateEnd", "");
                                        setFieldValue("spaDateCon", condition3[0]);

                                        setFieldValue("stampedDateStart", "");
                                        setFieldValue("stampedDateEnd", "");
                                        setFieldValue("stampedDateCon", condition3[0]);

                                        setFieldValue("accumStart", "");
                                        setFieldValue("accumEnd", "");
                                        setFieldValue("accumCon", condition3[0]);

                                        setFieldValue("moduleCode", "");
                                        setFieldValue("moduleCodeCon", condition2[0]);

                                        setFieldValue("project", "");
                                        setFieldValue("projectArr", []);
                                        setFieldValue("projectCon", condition2[0]);

                                        setFieldValue("phase", "");
                                        setFieldValue("phaseArr", []);
                                        setFieldValue("phaseCon", condition2[0]);

                                        setFieldValue("lotUnit", "");
                                        setFieldValue("lotUnitCon", condition1[0]);

                                        setFieldValue("projectInterestedReset", true);
                                        setFieldValue("sourceCategoryReset", true);
                                        setFieldValue("dealSourceReset", true);
                                        setFieldValue("pipelineReset", true);
                                        setFieldValue("stageReset", true);
                                        setFieldValue("dealStatusReset", true);
                                        setFieldValue("dealOutcomeReset", true);
                                        setFieldValue("dealCreatedReset", true);
                                        setFieldValue("buyerRoleReset", true);
                                        setFieldValue("tierReset", true);
                                        setFieldValue("lifestyleReset", true);
                                        setFieldValue("sourceReset", true);
                                        setFieldValue("salesStatusReset", true);
                                        setFieldValue("statusReason", "");
                                        setFieldValue("salesDateReset", true);
                                        setFieldValue("spaDateReset", true);
                                        setFieldValue("stampedDateReset", true);
                                        setFieldValue("accumReset", true);
                                        setFieldValue("moduleCodeReset", true);
                                        setFieldValue("projectReset", true);
                                        setFieldValue("phaseReset", true);
                                        setFieldValue("lotUnitReset", true);

                                        setTimeout(() => {
                                          setFieldValue("projectInterestedReset", false);
                                          setFieldValue("sourceCategoryReset", false);
                                          setFieldValue("dealSourceReset", false);
                                          setFieldValue("pipelineReset", false);
                                          setFieldValue("stageReset", false);
                                          setFieldValue("dealStatusReset", false);
                                          setFieldValue("dealOutcomeReset", false);
                                          setFieldValue("dealCreatedReset", false);
                                          setFieldValue("buyerRoleReset", false);
                                          setFieldValue("tierReset", false);
                                          setFieldValue("lifestyleReset", false);
                                          setFieldValue("sourceReset", false);
                                          setFieldValue("salesStatusReset", false);
                                          setFieldValue("statusReasonReset", false);
                                          setFieldValue("salesDateReset", false);
                                          setFieldValue("spaDateReset", false);
                                          setFieldValue("stampedDateReset", false);
                                          setFieldValue("accumReset", false);
                                          setFieldValue("moduleCodeReset", false);
                                          setFieldValue("projectReset", false);
                                          setFieldValue("phaseReset", false);
                                          setFieldValue("lotUnitReset", false);
                                        }, 1);
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("projectInterestedDisabled", true);
                                    setFieldValue("sourceCategoryDisabled", true);
                                    setFieldValue("dealSourceDisabled", true);
                                    setFieldValue("pipelineDisabled", true);
                                    setFieldValue("stageDisabled", true);
                                    setFieldValue("dealStatusDisabled", true);
                                    setFieldValue("dealOutcomeDisabled", true);
                                    setFieldValue("dealCreatedDisabled", true);
                                    setFieldValue("buyerRoleDisabled", true);
                                    setFieldValue("tierDisabled", true);
                                    setFieldValue("lifestyleDisabled", true);
                                    setFieldValue("sourceDisabled", true);
                                    setFieldValue("salesStatusDisabled", true);
                                    setFieldValue("statusReasonDisabled", true);
                                    setFieldValue("salesDateDisabled", true);
                                    setFieldValue("spaDateDisabled", true);
                                    setFieldValue("spaDateDisabled", true);
                                    setFieldValue("stampedDateDisabled", true);
                                    setFieldValue("accumDisabled", true);
                                    setFieldValue("moduleCodeDisabled", true);
                                    setFieldValue("projectDisabled", true);
                                    setFieldValue("phaseDisabled", true);
                                    setFieldValue("lotUnitDisabled", true);
                                    setFieldValue("contactTypeReset", true);
                                    setFieldValue("contactTypeCon", condition2[0]);
                                    setFieldValue("contactType", contactTypeList[0]);

                                    setFieldValue("projectInterested", "");
                                    setFieldValue("projectInterestedArr", []);
                                    setFieldValue("projectInterestedCon", condition2[0]);

                                    setFieldValue("sourceCategory", "");
                                    setFieldValue("sourceCategoryArr", []);
                                    setFieldValue("sourceCategoryCon", condition2[0]);

                                    setFieldValue("dealSource", "");
                                    setFieldValue("dealSourceArr", []);
                                    setFieldValue("dealSourceCon", condition2[0]);

                                    setFieldValue("pipeline", "");
                                    setFieldValue("pipelineArr", []);
                                    setFieldValue("pipelineCon", condition2[0]);

                                    setFieldValue("pipeline", "");
                                    setFieldValue("pipelineArr", []);
                                    setFieldValue("pipelineCon", condition2[0]);

                                    setFieldValue("stage", "");
                                    setFieldValue("stageArr", "");
                                    setFieldValue("stageCon", condition2[0]);

                                    setFieldValue("dealStatus", "");
                                    setFieldValue("dealStatusArr", []);
                                    setFieldValue("dealStatusCon", condition2[0]);

                                    setFieldValue("dealStatus", "");
                                    setFieldValue("dealOutcomeArr", []);
                                    setFieldValue("dealOutcomeCon", condition2[0]);

                                    setFieldValue("dealCreatedStart", "");
                                    setFieldValue("dealCreatedEnd", "");
                                    setFieldValue("dealCreatedCon", condition3[0]);

                                    setFieldValue("buyerRole", "");
                                    setFieldValue("buyerRoleArr", []);
                                    setFieldValue("buyerRoleCon", condition2[0]);

                                    setFieldValue("tier", "");
                                    setFieldValue("tierArr", []);
                                    setFieldValue("tierCon", condition2[0]);

                                    setFieldValue("lifestyle", "");
                                    setFieldValue("lifestyleArr", []);
                                    setFieldValue("lifestyleCon", condition2[0]);

                                    setFieldValue("source", "");
                                    setFieldValue("sourceArr", []);
                                    setFieldValue("sourceCon", condition2[0]);

                                    setFieldValue("salesStatus", "");
                                    setFieldValue("salesStatusArr", []);
                                    setFieldValue("salesStatusCon", condition2[0]);

                                    setFieldValue("statusReason", "");
                                    setFieldValue("statusReasonCon", condition1[0]);

                                    setFieldValue("salesDateStart", "");
                                    setFieldValue("salesDateEnd", "");
                                    setFieldValue("salesDateCon", condition3[0]);

                                    setFieldValue("spaDateStart", "");
                                    setFieldValue("spaDateEnd", "");
                                    setFieldValue("spaDateCon", condition3[0]);

                                    setFieldValue("stampedDateStart", "");
                                    setFieldValue("stampedDateEnd", "");
                                    setFieldValue("stampedDateCon", condition3[0]);

                                    setFieldValue("accumStart", "");
                                    setFieldValue("accumEnd", "");
                                    setFieldValue("accumCon", condition3[0]);

                                    setFieldValue("moduleCode", "");
                                    setFieldValue("moduleCodeCon", condition2[0]);

                                    setFieldValue("project", "");
                                    setFieldValue("projectArr", []);
                                    setFieldValue("projectCon", condition2[0]);

                                    setFieldValue("phase", "");
                                    setFieldValue("phaseArr", []);
                                    setFieldValue("phaseCon", condition2[0]);

                                    setFieldValue("lotUnit", "");
                                    setFieldValue("lotUnitCon", condition1[0]);

                                    setFieldValue("projectInterestedReset", true);
                                    setFieldValue("sourceCategoryReset", true);
                                    setFieldValue("dealSourceReset", true);
                                    setFieldValue("pipelineReset", true);
                                    setFieldValue("stageReset", true);
                                    setFieldValue("dealStatusReset", true);
                                    setFieldValue("dealOutcomeReset", true);
                                    setFieldValue("dealCreatedReset", true);
                                    setFieldValue("buyerRoleReset", true);
                                    setFieldValue("tierReset", true);
                                    setFieldValue("lifestyleReset", true);
                                    setFieldValue("sourceReset", true);
                                    setFieldValue("salesStatusReset", true);
                                    setFieldValue("statusReasonReset", true);
                                    setFieldValue("salesDateReset", true);
                                    setFieldValue("spaDateReset", true);
                                    setFieldValue("stampedDateReset", true);
                                    setFieldValue("accumReset", true);
                                    setFieldValue("moduleCodeReset", true);
                                    setFieldValue("projectReset", true);
                                    setFieldValue("phaseReset", true);
                                    setFieldValue("lotUnitReset", true);

                                    setTimeout(() => {
                                      setFieldValue("contactTypeReset", false);
                                      setFieldValue("projectInterestedReset", false);
                                      setFieldValue("sourceCategoryReset", false);
                                      setFieldValue("dealSourceReset", false);
                                      setFieldValue("pipelineReset", false);
                                      setFieldValue("stageReset", false);
                                      setFieldValue("dealStatusReset", false);
                                      setFieldValue("dealOutcomeReset", false);
                                      setFieldValue("dealCreatedReset", false);
                                      setFieldValue("buyerRoleReset", false);
                                      setFieldValue("tierReset", false);
                                      setFieldValue("lifestyleReset", false);
                                      setFieldValue("sourceReset", false);
                                      setFieldValue("salesStatusReset", false);
                                      setFieldValue("statusReasonReset", false);
                                      setFieldValue("salesDateReset", false);
                                      setFieldValue("spaDateReset", false);
                                      setFieldValue("stampedDateReset", false);
                                      setFieldValue("accumReset", false);
                                      setFieldValue("moduleCodeReset", false);
                                      setFieldValue("projectReset", false);
                                      setFieldValue("phaseReset", false);
                                      setFieldValue("lotUnitReset", false);
                                    }, 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>
                            </Card.Body>
                          </Card>

                          <Card className="w-100 border shadow-sm mb-4">
                            <Card.Header>Opportunities</Card.Header>
                            <Card.Body>
                              {/* PROJECT INTERESTED */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Project Interested
                                </Form.Label>
                                <div className="w-25">{!values.projectInterestedReset && <FormSelect options={condition2} disabled={values.projectInterestedDisabled} valueDefault={values.projectInterestedCon} onChange={(e) => setFieldValue("projectInterestedCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.projectInterestedReset && (
                                    <FormSelect
                                      options={projectInterestedList}
                                      disabled={values.projectInterestedDisabled}
                                      valueDefault={values.projectInterested}
                                      onChange={(e) => {
                                        setFieldValue("projectInterested", e);
                                        getSourceCategory(e.value);
                                        getDealSource(e.value);
                                        getPipeline(e.value);
                                        getStage(e.value);
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("projectInterestedReset", true);
                                    setFieldValue("projectInterestedCon", condition2[0]);
                                    setFieldValue("projectInterested", "");
                                    setTimeout(() => setFieldValue("projectInterestedReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SOURCE CATEGORY */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Source Category
                                </Form.Label>
                                <div className="w-25">{!values.sourceCategoryReset && <FormSelect options={condition2} disabled={values.sourceCategoryDisabled} valueDefault={values.sourceCategoryCon} onChange={(e) => setFieldValue("sourceCategoryCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.sourceCategoryReset && (
                                    <FormSelect
                                      options={sourceCategoryList}
                                      disabled={values.sourceCategoryDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.sourceCategoryArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("sourceCategory", e.map((record) => record.value).toString());
                                          setFieldValue("sourceCategoryArr", e);
                                        } else {
                                          setFieldValue("sourceCategory", "");
                                          setFieldValue("sourceCategoryArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("sourceCategoryReset", true);
                                    setFieldValue("sourceCategoryCon", condition2[0]);
                                    setFieldValue("sourceCategoryArr", []);
                                    setFieldValue("sourceCategory", "");
                                    setTimeout(() => setFieldValue("sourceCategoryReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* DEAL SOURCE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Deal Source
                                </Form.Label>
                                <div className="w-25">{!values.dealSourceReset && <FormSelect options={condition2} disabled={values.dealSourceDisabled} valueDefault={values.dealSourceCon} onChange={(e) => setFieldValue("dealSourceCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.dealSourceReset && (
                                    <FormSelect
                                      options={dealSourceList}
                                      disabled={values.dealSourceDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.dealSourceArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("dealSource", e.map((record) => record.value).toString());
                                          setFieldValue("dealSourceArr", e);
                                        } else {
                                          setFieldValue("dealSource", "");
                                          setFieldValue("dealSourceArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("dealSourceReset", true);
                                    setFieldValue("dealSourceCon", condition2[0]);
                                    setFieldValue("dealSourceArr", []);
                                    setFieldValue("dealSource", "");
                                    setTimeout(() => setFieldValue("dealSourceReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* PIPELINE STATUS */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Pipeline Status
                                </Form.Label>
                                <div className="w-25">{!values.pipelineReset && <FormSelect options={condition2} disabled={values.pipelineDisabled} valueDefault={values.pipelineCon} onChange={(e) => setFieldValue("pipelineCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.pipelineReset && (
                                    <FormSelect
                                      options={pipelineList}
                                      disabled={values.pipelineDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.pipelineArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("pipeline", e.map((record) => record.value).toString());
                                          setFieldValue("pipelineArr", e);
                                        } else {
                                          setFieldValue("pipeline", "");
                                          setFieldValue("pipelineArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("pipelineReset", true);
                                    setFieldValue("pipelineCon", condition2[0]);
                                    setFieldValue("pipelineArr", []);
                                    setFieldValue("pipeline", "");
                                    setTimeout(() => setFieldValue("pipelineReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* STAGE STATUS */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Stage Status
                                </Form.Label>
                                <div className="w-25">{!values.stageReset && <FormSelect options={condition2} disabled={values.stageDisabled} valueDefault={values.stageCon} onChange={(e) => setFieldValue("stageCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.stageReset && (
                                    <FormSelect
                                      options={stageList}
                                      disabled={values.stageDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.stageArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("stage", e.map((record) => record.value).toString());
                                          setFieldValue("stageArr", e);
                                        } else {
                                          setFieldValue("stage", "");
                                          setFieldValue("stageArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("stageReset", true);
                                    setFieldValue("stageCon", condition2[0]);
                                    setFieldValue("stageArr", []);
                                    setFieldValue("stage", "");
                                    setTimeout(() => setFieldValue("stageReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* DEAL STATUS */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Deal Status
                                </Form.Label>
                                <div className="w-25">{!values.dealStatusReset && <FormSelect options={condition2} disabled={values.dealStatusDisabled} valueDefault={values.dealStatusCon} onChange={(e) => setFieldValue("dealStatusCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.dealStatusReset && (
                                    <FormSelect
                                      options={dealStatusList}
                                      disabled={values.dealStatusDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.dealStatusArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("dealStatus", e.map((record) => record.value).toString());
                                          setFieldValue("dealStatusArr", e);
                                        } else {
                                          setFieldValue("dealStatus", "");
                                          setFieldValue("dealStatusArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("dealStatusReset", true);
                                    setFieldValue("dealStatusCon", condition2[0]);
                                    setFieldValue("dealStatusArr", []);
                                    setFieldValue("dealStatus", "");
                                    setTimeout(() => setFieldValue("dealStatusReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* DEAL OUTCOME */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Deal Outcome
                                </Form.Label>
                                <div className="w-25">{!values.dealOutcomeReset && <FormSelect options={condition2} disabled={values.dealOutcomeDisabled} valueDefault={values.dealOutcomeCon} onChange={(e) => setFieldValue("dealOutcomeCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.dealOutcomeReset && (
                                    <FormSelect
                                      options={dealOutcomeList}
                                      disabled={values.dealOutcomeDisabled}
                                      isMulti={true}
                                      isSearchable={true}
                                      valueDefault={values.dealOutcomeArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("dealOutcome", e.map((record) => record.value).toString());
                                          setFieldValue("dealOutcomeArr", e);
                                        } else {
                                          setFieldValue("dealOutcome", "");
                                          setFieldValue("dealOutcomeArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("dealOutcomeReset", true);
                                    setFieldValue("dealOutcomeCon", condition2[0]);
                                    setFieldValue("dealOutcomeArr", []);
                                    setFieldValue("dealOutcome", "");
                                    setTimeout(() => setFieldValue("dealOutcomeReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* DEAL DATE CREATED */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Deal Date Created
                                </Form.Label>
                                <div className="w-25">{!values.dealCreatedReset && <FormSelect options={condition3} disabled={values.dealCreatedDisabled} valueDefault={values.dealCreatedCon} onChange={(e) => setFieldValue("dealCreatedCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="date" className="w-50 me-1" value={values.dealCreatedStart} disabled={values.dealCreatedDisabled} onChange={handleChange("dealCreatedStart")} />
                                  <Form.Control type="date" className="w-50" value={values.dealCreatedEnd} disabled={values.dealCreatedDisabled} onChange={handleChange("dealCreatedEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("dealCreatedReset", true);
                                    setFieldValue("dealCreatedCon", condition3[0]);
                                    setFieldValue("dealCreatedStart", "");
                                    setFieldValue("dealCreatedEnd", "");
                                    setTimeout(() => setFieldValue("dealCreatedReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>
                            </Card.Body>
                          </Card>

                          <Card className="w-100 border shadow-sm mb-4">
                            <Card.Header>Buyer</Card.Header>
                            <Card.Body>
                              {/* BUYER ROLE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Buyer Role
                                </Form.Label>
                                <div className="w-25">{!values.buyerRoleReset && <FormSelect options={condition4} disabled={values.buyerRoleDisabled} valueDefault={values.buyerRoleCon} onChange={(e) => setFieldValue("buyerRoleCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.buyerRoleReset && (
                                    <FormSelect
                                      options={buyerRoleList}
                                      isMulti={true}
                                      disabled={values.buyerRoleDisabled}
                                      valueDefault={values.buyerRoleArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("buyerRole", e.map((record) => record.value).toString());
                                          setFieldValue("buyerRoleArr", e);
                                        } else {
                                          setFieldValue("buyerRole", "");
                                          setFieldValue("buyerRoleArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("buyerRoleReset", true);
                                    setFieldValue("buyerRoleCon", condition4[0]);
                                    setFieldValue("buyerRoleArr", []);
                                    setFieldValue("buyerRole", "");
                                    setTimeout(() => setFieldValue("buyerRoleReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* LOYALTY TIER */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Loyalty Tier
                                </Form.Label>
                                <div className="w-25">{!values.tierReset && <FormSelect options={condition2} disabled={values.tierDisabled} valueDefault={values.tierCon} onChange={(e) => setFieldValue("tierCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.tierReset && (
                                    <FormSelect
                                      options={tierList}
                                      isMulti={true}
                                      disabled={values.tierDisabled}
                                      valueDefault={values.tierArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("tier", e.map((record) => record.value).toString());
                                          setFieldValue("tierArr", e);
                                        } else {
                                          setFieldValue("tier", "");
                                          setFieldValue("tierArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("tierReset", true);
                                    setFieldValue("tierCon", condition4[0]);
                                    setFieldValue("tierArr", []);
                                    setFieldValue("tier", "");
                                    setTimeout(() => setFieldValue("tierReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* LIFESTYLE REGISTERATION */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Lifestyle Registration
                                </Form.Label>
                                <div className="w-25">{!values.lifestyleReset && <FormSelect options={condition2} disabled={values.lifestyleDisabled} valueDefault={values.lifestyleCon} onChange={(e) => setFieldValue("lifestyleCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.lifestyleReset && (
                                    <FormSelect
                                      options={lifestyleList}
                                      isMulti={true}
                                      disabled={values.lifestyleDisabled}
                                      valueDefault={values.lifestyleArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("lifestyle", e.map((record) => record.value).toString());
                                          setFieldValue("lifestyleArr", e);
                                        } else {
                                          setFieldValue("lifestyle", "");
                                          setFieldValue("lifestyleArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("lifestyleReset", true);
                                    setFieldValue("lifestyleCon", condition4[0]);
                                    setFieldValue("lifestyleArr", []);
                                    setFieldValue("lifestyle", "");
                                    setTimeout(() => setFieldValue("lifestyleReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SOURCE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Source
                                </Form.Label>
                                <div className="w-25">{!values.sourceReset && <FormSelect options={condition2} disabled={values.sourceDisabled} valueDefault={values.sourceCon} onChange={(e) => setFieldValue("sourceCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.sourceReset && (
                                    <FormSelect
                                      options={sourceList}
                                      isMulti={true}
                                      disabled={values.sourceDisabled}
                                      valueDefault={values.sourceArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("source", e.map((record) => record.value).toString());
                                          setFieldValue("sourceArr", e);
                                        } else {
                                          setFieldValue("source", "");
                                          setFieldValue("sourceArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("sourceReset", true);
                                    setFieldValue("sourceCon", condition4[0]);
                                    setFieldValue("sourceArr", []);
                                    setFieldValue("source", "");
                                    setTimeout(() => setFieldValue("sourceReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SALES STATUS */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Sales Status
                                </Form.Label>
                                <div className="w-25">{!values.salesStatusReset && <FormSelect options={condition2} disabled={values.salesStatusDisabled} valueDefault={values.salesStatusCon} onChange={(e) => setFieldValue("salesStatusCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.salesStatusReset && (
                                    <FormSelect
                                      options={salesStatusList}
                                      isMulti={true}
                                      disabled={values.salesStatusDisabled}
                                      valueDefault={values.salesStatusArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("salesStatus", e.map((record) => record.value).toString());
                                          setFieldValue("salesStatusArr", e);
                                        } else {
                                          setFieldValue("salesStatus", "");
                                          setFieldValue("salesStatusArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("salesStatusReset", true);
                                    setFieldValue("salesStatusCon", condition4[0]);
                                    setFieldValue("salesStatusArr", []);
                                    setFieldValue("salesStatus", "");
                                    setTimeout(() => setFieldValue("salesStatusReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* STATUS REASON */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Status Reason
                                </Form.Label>
                                <div className="w-25">{!values.statusReasonReset && <FormSelect options={condition1} disabled={values.statusReasonDisabled} valueDefault={values.statusReasonCon} onChange={(e) => setFieldValue("statusReasonCon", e)} width="100%" />}</div>
                                <Form.Control className="w-50" value={values.statusReason} disabled={values.statusReasonDisabled} onChange={handleChange("statusReason")} />
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("statusReasonReset", true);
                                    setFieldValue("statusReasonCon", condition1[0]);
                                    setFieldValue("statusReason", "");
                                    setTimeout(() => setFieldValue("statusReasonReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SALES DATE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Sales Date
                                </Form.Label>
                                <div className="w-25">{!values.salesDateReset && <FormSelect options={condition3} disabled={values.salesDateDisabled} valueDefault={values.salesDateCon} onChange={(e) => setFieldValue("salesDateCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="date" className="w-50 me-1" disabled={values.salesDateDisabled} value={values.salesDateStart} onChange={handleChange("salesDateStart")} />
                                  <Form.Control type="date" className="w-50" disabled={values.salesDateDisabled} value={values.salesDateEnd} onChange={handleChange("salesDateEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("salesDateReset", true);
                                    setFieldValue("salesDateCon", condition3[0]);
                                    setFieldValue("salesDateStart", "");
                                    setFieldValue("salesDateEnd", "");
                                    setTimeout(() => setFieldValue("salesDateReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SPA DATE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  SPA Date
                                </Form.Label>
                                <div className="w-25">{!values.spaDateReset && <FormSelect options={condition3} disabled={values.spaDateDisabled} valueDefault={values.spaDateCon} onChange={(e) => setFieldValue("spaDateCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="date" className="w-50 me-1" disabled={values.spaDateDisabled} value={values.spaDateStart} onChange={handleChange("spaDateStart")} />
                                  <Form.Control type="date" className="w-50" disabled={values.spaDateDisabled} value={values.spaDateEnd} onChange={handleChange("spaDateEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("spaDateReset", true);
                                    setFieldValue("spaDateCon", condition3[0]);
                                    setFieldValue("spaDateStart", "");
                                    setFieldValue("spaDateEnd", "");
                                    setTimeout(() => setFieldValue("spaDateReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* SPA STAMPED DATE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  SPA Stamped Date
                                </Form.Label>
                                <div className="w-25">{!values.stampedDateReset && <FormSelect options={condition3} disabled={values.stampedDateDisabled} valueDefault={values.stampedDateCon} onChange={(e) => setFieldValue("stampedDateCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="date" className="w-50 me-1" disabled={values.stampedDateDisabled} value={values.stampedDateStart} onChange={handleChange("stampedDateStart")} />
                                  <Form.Control type="date" className="w-50" disabled={values.stampedDateDisabled} value={values.stampedDateEnd} onChange={handleChange("stampedDateEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("stampedDateReset", true);
                                    setFieldValue("stampedDateCon", condition3[0]);
                                    setFieldValue("stampedDateStart", "");
                                    setFieldValue("stampedDateEnd", "");
                                    setTimeout(() => setFieldValue("stampedDateReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* TOTAL ACCUMULATIVE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Total Accumulative
                                </Form.Label>
                                <div className="w-25">{!values.accumReset && <FormSelect options={condition3} disabled={values.accumDisabled} valueDefault={values.accumCon} onChange={(e) => setFieldValue("accumCon", e)} width="100%" />}</div>
                                <div className="d-flex w-50">
                                  <Form.Control type="number" min={0} disabled={values.accumDisabled} className="w-50 me-1" value={values.accumStart} onChange={handleChange("accumStart")} />
                                  <Form.Control type="number" min={0} disabled={values.accumDisabled} className="w-50" value={values.accumEnd} onChange={handleChange("accumEnd")} />
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("accumReset", true);
                                    setFieldValue("accumCon", condition3[0]);
                                    setFieldValue("accumStart", "");
                                    setFieldValue("accumEnd", "");
                                    setTimeout(() => setFieldValue("accumReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* MODULE CODE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Module Code
                                </Form.Label>
                                <div className="w-25">{!values.moduleCodeReset && <FormSelect options={condition2} disabled={values.moduleCodeDisabled} valueDefault={values.moduleCodeCon} onChange={(e) => setFieldValue("moduleCodeCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.moduleCodeReset && <FormSelect options={moduleCodeList} disabled={values.moduleCodeDisabled} valueDefault={values.moduleCode} onChange={(e) => setFieldValue("moduleCode", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("moduleCodeReset", true);
                                    setFieldValue("moduleCodeCon", condition4[0]);
                                    setFieldValue("moduleCode", "");
                                    setTimeout(() => setFieldValue("moduleCodeReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>
                            </Card.Body>
                          </Card>

                          <Card className="w-100 border shadow-sm mb-4">
                            <Card.Header>Project</Card.Header>
                            <Card.Body>
                              {/* PROJECT */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Project
                                </Form.Label>
                                <div className="w-25">{!values.projectReset && <FormSelect options={condition2} disabled={values.projectDisabled} valueDefault={values.projectCon} onChange={(e) => setFieldValue("projectCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.projectReset && (
                                    <FormSelect
                                      options={projectList}
                                      isMulti={true}
                                      disabled={values.projectDisabled}
                                      valueDefault={values.projectArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("project", e.map((record) => record.value).toString());
                                          setFieldValue("projectArr", e);
                                          getRRPhase(e.map((record) => record.value).toString());
                                        } else {
                                          setFieldValue("project", "");
                                          setFieldValue("projectArr", []);
                                          getRRPhase();
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("projectReset", true);
                                    setFieldValue("projectCon", condition4[0]);
                                    setFieldValue("projectArr", []);
                                    setFieldValue("project", "");
                                    setTimeout(() => setFieldValue("projectReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* PHASE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Phase
                                </Form.Label>
                                <div className="w-25">{!values.phaseReset && <FormSelect options={condition2} disabled={values.phaseDisabled} valueDefault={values.phaseCon} onChange={(e) => setFieldValue("phaseCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.phaseReset && (
                                    <FormSelect
                                      options={phaseList}
                                      isMulti={true}
                                      disabled={values.phaseDisabled}
                                      valueDefault={values.phaseArr}
                                      onChange={(e) => {
                                        if (e) {
                                          setFieldValue("phase", e.map((record) => record.value).toString());
                                          setFieldValue("phaseArr", e);
                                        } else {
                                          setFieldValue("phase", "");
                                          setFieldValue("phaseArr", []);
                                        }
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("phaseReset", true);
                                    setFieldValue("phaseCon", condition4[0]);
                                    setFieldValue("phaseArr", []);
                                    setFieldValue("phase", "");
                                    setTimeout(() => setFieldValue("phaseReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* LOT UNIT */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Lot Unit
                                </Form.Label>
                                <div className="w-25">{!values.lotUnitReset && <FormSelect options={condition1} disabled={values.lotUnitDisabled} valueDefault={values.lotUnitCon} onChange={(e) => setFieldValue("lotUnitCon", e)} width="100%" />}</div>
                                <Form.Control className="w-50" value={values.lotUnit} disabled={values.lotUnitDisabled} onChange={handleChange("lotUnit")} />
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("lotUnitReset", true);
                                    setFieldValue("lotUnitCon", condition1[0]);
                                    setFieldValue("lotUnit", "");
                                    setTimeout(() => setFieldValue("lotUnitReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>
                            </Card.Body>
                          </Card>

                          <Card className="w-100 border shadow-sm mb-4">
                            <Card.Header>Contact Preference</Card.Header>
                            <Card.Body>
                              {/* PDPA */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  PDPA
                                </Form.Label>
                                <div className="w-25">{!values.pdpaReset && <FormSelect options={condition2} valueDefault={values.pdpaCon} onChange={(e) => setFieldValue("pdpaCon", e)} width="100%" />}</div>
                                <div className="w-50">
                                  {!values.pdpaReset && (
                                    <FormSelect
                                      options={contactPreferenceList}
                                      valueDefault={values.pdpa}
                                      onChange={(e) => {
                                        setFieldValue("pdpa", e);
                                        setFieldValue("emailCP", e);
                                        setFieldValue("emailCPCon", condition2[0]);
                                        setFieldValue("emailCPReset", true);

                                        setFieldValue("emailBulk", e);
                                        setFieldValue("emailBulkCon", condition2[0]);
                                        setFieldValue("emailBulkReset", true);

                                        setFieldValue("phone", e);
                                        setFieldValue("phoneCon", condition2[0]);
                                        setFieldValue("phoneReset", true);

                                        setFieldValue("fax", e);
                                        setFieldValue("faxCon", condition2[0]);
                                        setFieldValue("faxReset", true);

                                        setFieldValue("mail", e);
                                        setFieldValue("mailCon", condition2[0]);
                                        setFieldValue("mailReset", true);

                                        setFieldValue("whatsapp", e);
                                        setFieldValue("whatsappCon", condition2[0]);
                                        setFieldValue("whatsappReset", true);

                                        setTimeout(() => {
                                          setFieldValue("emailCPReset", false);
                                          setFieldValue("emailBulkReset", false);
                                          setFieldValue("phoneReset", false);
                                          setFieldValue("faxReset", false);
                                          setFieldValue("mailReset", false);
                                          setFieldValue("whatsappReset", false);
                                        }, 1);
                                      }}
                                      width="100%"
                                    />
                                  )}
                                </div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("pdpaReset", true);
                                    setFieldValue("pdpaCon", condition4[0]);
                                    setFieldValue("pdpa", "");
                                    setTimeout(() => setFieldValue("pdpaReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* EMAIL */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Email
                                </Form.Label>
                                <div className="w-25">{!values.emailCPReset && <FormSelect options={condition2} valueDefault={values.emailCPCon} onChange={(e) => setFieldValue("emailCPCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.emailCPReset && <FormSelect options={contactPreferenceList} valueDefault={values.emailCP} onChange={(e) => setFieldValue("emailCP", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("emailCPReset", true);
                                    setFieldValue("emailCPCon", condition4[0]);
                                    setFieldValue("emailCP", "");
                                    setTimeout(() => setFieldValue("emailCPReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* EMAIL BULK */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Bulk Email
                                </Form.Label>
                                <div className="w-25">{!values.emailBulkReset && <FormSelect options={condition2} valueDefault={values.emailBulkCon} onChange={(e) => setFieldValue("emailBulkCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.emailBulkReset && <FormSelect options={contactPreferenceList} valueDefault={values.emailBulk} onChange={(e) => setFieldValue("emailBulk", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("emailBulkReset", true);
                                    setFieldValue("emailBulkCon", condition4[0]);
                                    setFieldValue("emailBulk", "");
                                    setTimeout(() => setFieldValue("emailBulkReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* PHONE */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Phone
                                </Form.Label>
                                <div className="w-25">{!values.phoneReset && <FormSelect options={condition2} valueDefault={values.phoneCon} onChange={(e) => setFieldValue("phoneCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.phoneReset && <FormSelect options={contactPreferenceList} valueDefault={values.phone} onChange={(e) => setFieldValue("phone", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("phoneReset", true);
                                    setFieldValue("phoneCon", condition4[0]);
                                    setFieldValue("phone", "");
                                    setTimeout(() => setFieldValue("phoneReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* FAX */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Fax
                                </Form.Label>
                                <div className="w-25">{!values.faxReset && <FormSelect options={condition2} valueDefault={values.faxCon} onChange={(e) => setFieldValue("faxCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.faxReset && <FormSelect options={contactPreferenceList} valueDefault={values.fax} onChange={(e) => setFieldValue("fax", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("faxReset", true);
                                    setFieldValue("faxCon", condition4[0]);
                                    setFieldValue("fax", "");
                                    setTimeout(() => setFieldValue("faxReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* MAIL */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Mail
                                </Form.Label>
                                <div className="w-25">{!values.mailReset && <FormSelect options={condition2} valueDefault={values.mailCon} onChange={(e) => setFieldValue("mailCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.mailReset && <FormSelect options={contactPreferenceList} valueDefault={values.mail} onChange={(e) => setFieldValue("mail", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("mailReset", true);
                                    setFieldValue("mailCon", condition4[0]);
                                    setFieldValue("mail", "");
                                    setTimeout(() => setFieldValue("mailReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>

                              {/* WHATSAPP */}
                              <Stack direction="horizontal" gap={1} className="mb-2">
                                <Form.Label className="op-label border w-25 mb-0 text-dark" style={{ backgroundColor: "#fff", padding: "0.375rem 0.75rem", lineHeight: 1.5 }}>
                                  Whatsapp
                                </Form.Label>
                                <div className="w-25">{!values.whatsappReset && <FormSelect options={condition2} valueDefault={values.whatsappCon} onChange={(e) => setFieldValue("whatsappCon", e)} width="100%" />}</div>
                                <div className="w-50">{!values.whatsappReset && <FormSelect options={contactPreferenceList} valueDefault={values.whatsapp} onChange={(e) => setFieldValue("whatsapp", e)} width="100%" />}</div>
                                <Button
                                  variant=""
                                  type="button"
                                  size="sm"
                                  className="rounded-circle border"
                                  style={{ backgroundColor: "#eee" }}
                                  onClick={() => {
                                    setFieldValue("whatsappReset", true);
                                    setFieldValue("whatsappCon", condition4[0]);
                                    setFieldValue("whatsapp", "");
                                    setTimeout(() => setFieldValue("whatsappReset", false), 1);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowsRotate} />
                                </Button>
                              </Stack>
                            </Card.Body>
                          </Card>
                        </Card.Body>
                        <Card.Footer className="d-flex justify-content-center">
                          <Button variant="" type="button" className="me-2" style={{ backgroundColor: "#ddd" }} onClick={sendClear}>
                            Clear
                          </Button>
                          <Button variant="" type="submit" className="op-primary-color text-light">
                            Find Prospect
                          </Button>
                        </Card.Footer>
                      </Card>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </Col>
          <Col xxl={5}>
            <div className="w-100" style={{ height: "calc(100vh - 56px)" }}>
              {loading2 ? (
                <div className="position-relative h-100 d-flex justify-content-center align-items-center">
                  <Loader2 />
                </div>
              ) : (
                <Card className="w-100 h-100 border-0" style={{ borderRadius: 0, backgroundColor: "transparent" }}>
                  <Card.Body>
                    <div style={containerStyle}>
                      <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                        <AgGridReact
                          ref={gridRef}
                          rowSelection="multiple"
                          rowData={prospectList}
                          defaultColDef={gridColDef}
                          columnDefs={gridColumn}
                          getRowId={gridRowId}
                          onSelectionChanged={gridSelectionChanged}
                          rowHeight={50}
                          pagination={true}
                          paginationPageSize={100}
                          paginationPageSizeSelector={false}
                          suppressRowClickSelection={true}
                          animateRows={true}
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between">
                    <div>
                      <p className="m-0" style={{ lineHeight: 1.2 }}>
                        <b>Row Selected :</b> {totalSelected} selected
                      </p>
                      <p className="m-0" style={{ lineHeight: 1.2 }}>
                        <b>Total Record :</b> {totalRecord === 0 ? "No record" : totalRecord === 1 ? "1 record" : `${totalRecord} records`}
                      </p>
                    </div>
                    <Button variant="" disabled={totalSelected === 0} className="op-primary-color text-light" onClick={() => sendSegment(selectedProspect)}>
                      Add to Curation
                    </Button>
                  </Card.Footer>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      )}

      {loading4 && (
        <div style={{ width: "100%", height: "100%", position: "absolute", zIndex: 100000000, top: 0, left: 0 }}>
          <div style={{ background: "#000", opacity: 0.2, width: "100%", height: "100%", position: "absolute", zIndex: 1 }}></div>
          <div style={{ position: "absolute", top: "40%", left: "48%", zIndex: 2 }}>
            <Loader2 />
          </div>
        </div>
      )}
    </div>
  );
};

export default CurationSegment;
