import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown, Container, ButtonGroup, ToggleButton, InputGroup, Spinner } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../../includes/NoRecord";
import Loader from "../../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setCurrency } from "../../lib/js/Function";
import "../../lib/css/settingStyle.css";
import { faMagnifyingGlass, faPencil, faPlus, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { FALSE } from "sass";

const LinkedContact = ({ prospectID }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;

    const [isLoading, setIsLoading] = useState(true);

    const lcGridRef = useRef();
    const [lcRowData, setLcRowData] = useState([]);
    const [isLc, setIsLc] = useState(false);

    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const [isLdAdd, setIsLdAdd] = useState(false);
    const [isLdEdit, setIsLdEdit] = useState(false);

    const [initVal, setInitVal] = useState();

    const [countryList, setCountryList] = useState([]);

    const [addTab, setAddTab] = useState("add1");

    const [idNoVal, setIdNoVal] = useState("");
    const [lcProspectData, setLcProspectData] = useState([]);
    const [isLdLCSearch, setIsLdLCSearch] = useState(false);

    const containerStyleLc = useMemo(() => ({ width: "100%", height: isLc ? "65vh" : "65vh", paddingTop: 10 }), [isLc]);
    const gridStyleLc = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          filter: false,
        };
    }, []);

    const [lcColumn, setLcColumn] = useState([
        {
            headerName: "Purchased Lot",
            field: "purchased_lot",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            width: 200
        },
        {
            headerName: "Project",
            field: "project",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            width: 200
        },
        {
            headerName: "CRM URL",
            field: "direct_link",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            width: 200
        },
        {
            headerName: "Country",
            field: "country_title",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            flex: 1,
            minWidth: 200
        },
        {
            headerName: "Date Created",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            width: 150,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.record_date) {
                        return moment( new Date(params.data.record_date) ).format("lll");
                    }
                }                
            }
        },
        {
            headerName: "Modified Date",
            sortable: true,
            unSortIcon: true,
            resizable: true,
            width: 150,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.modified_date) {
                        return moment( new Date(params.data.modified_date) ).format("lll");
                    }
                }
            }
        },
        {
            headerName: "Action",
            resizable: true,
            width: 100,
            pinned: "right",
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div className="d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon icon={faTrash} size="xl" className="fontAwesomeIcon" onClick={() => handleDelete(params.data.linked_contact_id)}/>
                            <FontAwesomeIcon icon={faPencil} size="xl" className="fontAwesomeIcon ms-3" onClick={() => handleEdit(params.data)}/>
                        </div>
                    );
                }
            }
        }
    ]);

    const getLinkedContact = () => {
        axios.get('ws/ws_linked_contact.php',{
            params:{
                task: 4,
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                setLcRowData(data.record);
                setIsLc(true);
                setIsLoading(false);
            }else{
                setLcRowData([]);
                setIsLc(false);
                setIsLoading(false);
            }
        })
        .catch(error => {
            setLcRowData([]);
            setIsLc(false);
            setIsLoading(false);
        });
    }

    const getAllCountry = () => {
        axios.get("ws/ws_listplace.php",{
            params:{
                task: "listCountry",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record && data.record.length > 0) {
                var list = data.record.map(info => {
                    return {label: info.country_title, value: info.country_id};
                });

                setCountryList(list);
            }else{
                setCountryList([]);
            }
        })
        .catch(error => {
            setCountryList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getLinkedContact();
            getAllCountry();
        }
    }, []);

    const RenderAddTab = ({ tab }) => {
        
        switch (tab) {
            case 'add1':
                
                return(
                    <Formik
                        validationSchema={add1Schema}
                        onSubmit={sendAdd1}
                        initialValues={
                            {
                                project: "",
                                purLot: "",
                                link: "",
                                country: ""
                            }
                        }
                    >

                        {({ handleSubmit, handleChange, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Group className="my-3 px-4">
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Country</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                    options={countryList}
                                                    isInvalid={errors.country && touched.country}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    onChange={(e) => handleChange('country')(e ? e.value : "")}
                                                />
                                                {errors.country && touched.country && <div className="op-error-message">{errors.country}</div>}
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Project Group Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                />
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Project Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <Form.Control
                                                    type="text"
                                                    isInvalid={errors.project && touched.project}
                                                    onChange={handleChange('project')}
                                                />
                                                {errors.project && touched.project && <div className="op-error-message">{errors.project}</div>}
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Phase Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <Form.Control
                                                    type="text"
                                                />
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <Form.Control
                                                    type="text"
                                                    isInvalid={errors.purLot && touched.purLot}
                                                    onChange={handleChange('purLot')}
                                                />
                                                {errors.purLot && touched.purLot && <div className="op-error-message">{errors.purLot}</div>}
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>CRM URL</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <Form.Control
                                                    type="text"
                                                    isInvalid={errors.link && touched.link}
                                                    onChange={handleChange('link')}
                                                />
                                                {errors.link && touched.link && <div className="op-error-message">{errors.link}</div>}
                                            </Form.Group>
                                        </Stack>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                </Modal.Footer>
                            </Form>
                        )}
                        
                    </Formik>
                );
            
            case 'add2':
                return(
                    <Formik
                        validationSchema={add2Schema}
                        onSubmit={sendAdd2}
                        initialValues={
                            {
                                project: "",
                                purLot: "",
                                link: "",
                                country: ""
                            }
                        }
                    >
                        {({ handleSubmit, handleChange, errors, touched, values, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <div className="my-2 px-4">
                                        <Form.Group className="mb-3">
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search by Identification No 1"
                                                    value={idNoVal}
                                                    onChange={(e) => {setIdNoVal(e.target.value)}}
                                                />
                                                <InputGroup.Text>
                                                    {!isLdLCSearch && <FontAwesomeIcon 
                                                        icon={faMagnifyingGlass} 
                                                        className="fontAwesomeIcon" 
                                                        onClick={
                                                            (e) => {e.preventDefault(); handleSearchLCProspect();}
                                                        }
                                                    />}
                                                    {isLdLCSearch && <Spinner animation="border" size="sm" variant="secondary"/>}
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </Form.Group>
                                        {lcProspectData.length > 0 && <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Prospect</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <Form.Label>{lcProspectData[0].prospect_name}</Form.Label>
                                            </Form.Group>
                                        </Stack>}
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Country</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                    options={countryList}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isInvalid={errors.country && touched.country}
                                                    onChange={(e) => {handleChange("country")(e ? e.value : "")}}
                                                />
                                                {errors.country && touched.country && <div className="op-error-message">{errors.country}</div>}
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Project Group Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                />
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Project Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isInvalid={errors.project && touched.project}
                                                    onChange={(e) => handleChange('project')(e ? e.value : "")}
                                                />
                                                {errors.project && touched.project && <div className="op-error-message">{errors.project}</div>}
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Phase Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                />
                                            </Form.Group>
                                        </Stack>
                                        <Stack direction="horizontal" gap={1} className="d-flex justify-content-between mb-3" style={{width: "100%"}}>
                                            <Form.Group style={{width: "20%"}}>
                                                <Form.Label>Lot Code</Form.Label>
                                            </Form.Group>
                                            <Form.Group style={{width: "80%"}}>
                                                <FormSelect
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isInvalid={errors.purLot && touched.purLot}
                                                    onChange={(e) => handleChange('purLot')(e ? e.value : "")}
                                                />
                                                {errors.purLot && touched.purLot && <div className="op-error-message">{errors.purLot}</div>}
                                            </Form.Group>
                                        </Stack>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                );
        
            default:
                return null;
        }
    }

    const add1Schema = yup.object().shape({
        project: yup.string().required("This field is required"),
        purLot: yup.string().required("This field is required"),
        link: yup.string().required("This field is required"),
        country: yup.string().required("This field is required")
    });

    const sendAdd1 = (values) => {
        setIsLdAdd(true);

        axios.get("ws/ws_linked_contact.php",{
            params:{
                task: 1,
                prospect_id: prospectID,
                project: values.project,
                purchased_lot: values.purLot,
                direct_link: values.link,
                country_id: values.country,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully added linked contact",
                    timer: 1500
                })
                .then(result => {
                    setIsLdAdd(false);
                    setIsAdd(false);
                    setIsLoading(true);
                    getLinkedContact();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support"
                })
                .then(result => {
                    setIsLdAdd(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdAdd(false);
            });
        });
    }

    const add2Schema = yup.object().shape({
        project: yup.string().required("This field is required"),
        purLot: yup.string().required("This field is required"),
        country: yup.string().required("This field is required")
    });

    const handleSearchLCProspect = () => {
        setIsLdLCSearch(true);

        axios.get('ws/ws_linked_contact.php',{
            params:{
                task: "checkingProspect",
                id_no: idNoVal,
                cf_id: '5002',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setLcProspectData(data.record);
                setIsLdLCSearch(false);
            }else{
                setLcProspectData([]);
                setIsLdLCSearch(false);
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: idNoVal == "" ? "Please insert Identification No 1" : "Please try again or contact your IT Support"
                });
            }
        })
        .catch(error => {
            setLcProspectData([]);
            setIsLdLCSearch(false);
            Swal.fire({
                icon: "error",
                title: error.message
            })
        });

    }

    const sendAdd2 = (values) => {
        if (lcProspectData.length == 0) {
            Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Prospect is empty"
            });
        }else{
            console.log(values);
        }   
    }

    const handleEdit = (data) => {
        var initVal = {
            id: data.linked_contact_id,
            purLot: data.purchased_lot,
            project: data.project,
            url: data.direct_link,
            country: data.country_id
        };

        setInitVal(initVal);

        setIsEdit(true);
    }

    const editSchema = yup.object().shape({
        purLot: yup.string().required("This field is required"),
        project: yup.string().required("This field is required"),
        url: yup.string().required("This field is required"),
        country: yup.string().required("This field is required")
    });

    const sendEdit = (values) => {
        setIsLdEdit(true);
        
        axios.get('ws/ws_linked_contact.php',{
            params:{
                task: 2,
                linked_contact_id: values.id,
                prospect_id: prospectID,
                purchased_lot: values.purLot,
                project: values.project,
                country_id: values.country,
                direct_link: values.url,
                utoken: values.user_ac_token,
                ctoken: values.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully updated linked contact",
                    timer: 1500
                })
                .then(result => {
                    setIsLdEdit(false);
                    setIsEdit(false);
                    setIsLoading(true);
                    getLinkedContact();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support"
                })
                .then(result => {
                    setIsLdEdit(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdEdit(false);
            });
        });
    }

    const handleDelete = (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "This linked contact will be deleted",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it",
            cancelButtonText: "Cancel"
        })
        .then(result => {
            if (result.isConfirmed) {
                setIsLoading(true);

                axios.get('ws/ws_linked_contact.php',{
                    params:{
                        task: 3,
                        linked_contact_id: id,
                        prospect_id: prospectID,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;

                    if (data.status == 0) {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Successfully deleted linked contact",
                            timer: 1500
                        })
                        .then(result => {
                            getLinkedContact();
                        });
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: "Failed",
                            text: "Please try again or contact your IT Support"
                        })
                        .then(result => {
                            setIsLoading(false);
                        });
                    }
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: error.message
                    });
                });
            }
        });
    }

    return(
        <Container fluid>
            {isLoading ? (<Loader/>) : (
                <div className="px-2">
                    <Card className="rounded border-1 shadow-sm mt-4">
                        <Card.Header className="rounded border-0" style={{backgroundColor: "#fff", padding: 0}}>
                            <div style={{padding: "10px 15px"}}>
                                <Stack direction="horizontal" gap={1}>
                                    <h6 className="mt-2">Linked Contact</h6>
                                    <div className="btn-group shadow-sm ms-auto">
                                        <button 
                                            type="button" 
                                            className="btn btn-light border"
                                            onClick={setIsAdd}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>
                                    </div>
                                </Stack>
                            </div>
                        </Card.Header>
                    </Card>
                    <div style={containerStyleLc}>
                        <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleLc }}>
                            <AgGridReact
                                ref={lcGridRef}
                                columnDefs={lcColumn}
                                rowData={lcRowData}
                                defaultColDef={reportColDef}
                                rowHeight={70}
                                pagination={false}
                            />
                        </div>
                    </div>
                    {/* {!isLc && <NoRecord message="No record at the moment." width={200}/>} */}

                    <Modal show={isAdd} onHide={setIsAdd} size="lg">
                        <Modal.Header closeButton>
                            <Modal.Title as={"h6"}>Add Linked Contact</Modal.Title>
                        </Modal.Header>
                        {isLdAdd ? (<Modal.Body style={{height: "40vh"}}><Loader/></Modal.Body>) : (
                            <>
                                <ButtonGroup className="activity-tab p-2">
                                    <ToggleButton variant="" type="radio" name="addLC" value={"add1"} checked={addTab === "add1"} onClick={(e) => {e.preventDefault(); setAddTab("add1"); setIdNoVal(""); setLcProspectData([]);}}>
                                        Add Linked Contact
                                    </ToggleButton>
                                    <ToggleButton variant="" type="radio" name="addLC" value={"add2"} checked={addTab === "add2"} onClick={(e) => {e.preventDefault(); setAddTab("add2"); setIdNoVal(""); setLcProspectData([]);}}>
                                        Add Linked Contact By ID
                                    </ToggleButton>
                                </ButtonGroup>

                                <RenderAddTab tab={addTab}/>
                            </>
                        )}
                    </Modal>

                    <Modal show={isEdit} onHide={setIsEdit}>
                        <Formik
                            validationSchema={editSchema}
                            onSubmit={sendEdit}
                            initialValues={initVal}
                        >
                            {({ handleSubmit, handleChange, errors, touched, values }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title as={"h6"}>Edit Linked Contact</Modal.Title>
                                    </Modal.Header>
                                    {isLdEdit && <Modal.Body style={{height: "42vh"}}>
                                        <Loader/>
                                    </Modal.Body>}
                                    {!isLdEdit && <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Purchased Lot</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.purLot}
                                                isInvalid={errors.purLot && touched.purLot}
                                                onChange={handleChange('purLot')}
                                            />
                                            {errors.purLot && touched.purLot && <div className="op-error-message">{errors.purLot}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Project</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.project}
                                                isInvalid={errors.project && touched.project}
                                                onChange={handleChange('project')}
                                            />
                                            {errors.project && touched.project && <div className="op-error-message">{errors.project}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>CRM URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={values.url}
                                                isInvalid={errors.url && touched.url}
                                                onChange={handleChange('url')}
                                            />
                                            {errors.url && touched.url && <div className="op-error-message">{errors.url}</div>}
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Country</Form.Label>
                                            <FormSelect
                                                options={countryList}
                                                valueDefault={countryList.filter(opt => opt.value == values.country)}
                                                isInvalid={errors.country && touched.country}
                                                isSearchable={true}
                                                isClearable={true}
                                                onChange={(e) => handleChange('country')(e ? e.value : "")}
                                            />
                                            {errors.country && touched.country && <div className="op-error-message">{errors.country}</div>}
                                        </Form.Group>
                                    </Modal.Body>}
                                    {!isLdEdit && <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                    </Modal.Footer>}
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                </div>
            )}
        </Container>
    );
    
}

export default LinkedContact;