import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown, ButtonGroup, ToggleButton } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal, { swal } from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../../includes/NoRecord";
import Loader from "../../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshake, faUser, faUserTie } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";

const BuyLot = ({ prospectID, prosData, onSuccess }) => {

    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const [openDealBuyLotData, setOpenDealBuyLotData] = useState();
    const [dsList, setDsList] = useState([]);

    const [isBuyLotTab, setIsBuyLotTab] = useState('openDeal');
    
    const [isLoading, setIsLoading] = useState(false);

    var vietnam = [402, 412];

    const getOpenDealBuyLot = () => {

        axios.get('ws/ws_prospect_deal.php', {
            params:{
                task: "OpenDealBuyLot",
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setOpenDealBuyLotData(data.record);
            }else{
                setOpenDealBuyLotData("");
            }
        })
        .catch(error => {
            setOpenDealBuyLotData("");
        });
    }

    const getAllDS = () => {
        
        axios.get('ws/ws_source.php', {
            params: {
                task: '2',
                disp: 'show',
                company: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {

                data.record.sort((a,b) => a.ds_title.localeCompare(b.ds_title));

                var dsData = data.record.map((rec) => {
                    return {label: rec.ds_title, value: rec.ds_id};
                });

                setDsList(dsData);
            }else{
                setDsList([]);
            }
        })
        .catch(error => {
            setDsList([]);
        });

    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getOpenDealBuyLot();
            getAllDS();
        }
    }, []);

    const addDealSchema = yup.object().shape({
        deal_title: yup.string().required("This field is required"),
        ds_id: yup.string().required('This field is required')
    });

    const sendAddDeal = (values) => {
        setIsLoading(true);
        
        axios.get('ws/ws_deal.php', {
            params: {
                    task: 'addDealWithCf',
		            title: values.deal_title,
		            value : 0,
		            source: values.ds_id,
		            pipeid: values.pipeline_id,
		            close_date : "",
		            prospect : prospectID,
		            prospect_name : prosData.prospect_name,
		            organization : prosData.organization_id,
		            organization_name: prosData.organization_title,
		            user: session.user_id,
		            company: session.company_id,
		            cs: session.default_cs_id,
		            cfid_arr: "",
		            cfval_arr: ""
            }
        })
        .then(res => {
            let data =  res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully add deal",
                    timer: 1500
                })
                .then(result => {
                    setIsLoading(false);
                    setOpen(false);
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: "Please try again or contact your IT Support"
                })
                .then(result => {
                    setIsLoading(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: 'error',
                title: error.message
            })
            .then(result => {
                setIsLoading(false);
            });
        });
    }

    const RenderBuyLot = ({ item }) => {
        switch (item) {
            case 'openDeal':
                
                return(
                    <>
                        <Modal.Body>
                            {openDealBuyLotData && <Row className="my-3">
                                <Col sm={12} className="mb-3">
                                    <Card className="shadow-sm border-1">
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_Modal_Tab_OpenDeal_DealTitle')}</Card.Title>
                                                <Card.Text style={{fontSize: 13}}>{openDealBuyLotData.deal_title}</Card.Text>
                                            </div>
                                            <FontAwesomeIcon icon={faHandshake} size="2xl"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={12} className="mb-3">
                                    <Card className="shadow-sm border-1">
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_Modal_Tab_OpenDeal_ProspectName')}</Card.Title>
                                                <Card.Text style={{fontSize: 13}}>{openDealBuyLotData.prospect_name}</Card.Text>
                                            </div>
                                            <FontAwesomeIcon icon={faUser} size="2xl"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={12}>
                                    <Card className="shadow-sm border-1">
                                        <Card.Body className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <Card.Title as={"h6"} className="fw-bold">{t('ProspectDetGL_Modal_Tab_OpenDeal_OwnerName')}</Card.Title>
                                                <Card.Text style={{fontSize: 13}}>{openDealBuyLotData.owner_name}</Card.Text>
                                            </div>
                                            <FontAwesomeIcon icon={faUserTie} size="2xl"/>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>}
                            {!openDealBuyLotData && <div className="my-3" style={{textAlign: "center"}}>
                                <h6>{t('ProspectDetGL_Modal_Tab_OpenDeal_NoOpenDeal')}</h6>
                            </div>}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn op-button op-primary-color text-light" disabled={openDealBuyLotData ? false : true} onClick={() => {window.open(session.origin+'/deal/'+openDealBuyLotData.deal_id,'_blank')}}>Buy Lot</button>
                        </Modal.Footer>
                    </>
                );
            
            case 'addDeal':

                return(
                    <Formik
                        validationSchema={addDealSchema}
                        onSubmit={sendAddDeal}
                        initialValues={
                            {
                                deal_title: "",
                                ds_id: "",
                                pipeline_id: session.company_id == 251 ? 623 : session.company_id == 180 ? 51 : session.company_id == 190 ? 107 : session.company_id == 200 ? 119 : session.company_id == 203 ? 136 : session.company_id == 210 ? 717 : session.company_id == 212 ? 155 : session.company_id == 213 ? 298 : session.company_id == 402 ? 768 : 0
                            }
                        }
                    >
                        {({ handleSubmit, handleChange, errors, touched }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('ProspectDetGL_Modal_Tab_AddDeal_DealTitle')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            isInvalid={errors.deal_title && touched.deal_title}
                                            onChange={handleChange('deal_title')}
                                        />
                                        {errors.deal_title && touched.deal_title && <div className="op-error-message">{errors.deal_title}</div>}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t('ProspectDetGL_Modal_Tab_AddDeal_DealSource')}</Form.Label>
                                        <FormSelect
                                            options={dsList}
                                            isInvalid={errors.ds_id && touched.ds_id}
                                            isSearchable={true}
                                            isClearable={true}
                                            onChange={(e) => {handleChange('ds_id')(e ? e.value : "")}}
                                        />
                                        {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="submit" className="btn op-button op-primary-color text-light">{t('ProspectDetGL_BuyLot')}</button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                );
        
            default:
                return null;
        }
    }

    return(
        <>
            <button className="btn btn-success text-light ms-auto" disabled={vietnam.includes(Number(session.company_id)) ? true : false} onClick={setOpen}>{t('ProspectDetGL_BuyLot')}</button>
            <Modal show={open} onHide={setOpen}>
                                
                <Modal.Header closeButton>
                    <Modal.Title as={"h6"}>{t('ProspectDetGL_BuyLot')}</Modal.Title>
                </Modal.Header>

                {isLoading ? (<Modal.Body style={{height: "200px"}}><Loader/></Modal.Body>) :(
                    
                    <>
                        <ButtonGroup className="activity-tab p-2">
                            <ToggleButton variant="" type="radio" name="buyLotBtn" value={"openDeal"} checked={isBuyLotTab === "openDeal"} onClick={(e) => {e.preventDefault(); setIsBuyLotTab("openDeal");}}>
                                {t('ProspectDetGL_Modal_Tab_OpenDeal')}
                            </ToggleButton>
                            <ToggleButton variant="" type="radio" name="buyLotBtn" value={"addDeal"} checked={isBuyLotTab === "addDeal"} onClick={(e) => {e.preventDefault(); setIsBuyLotTab("addDeal");}}>
                                {t('ProspectDetGL_Modal_Tab_AddDeal')}
                            </ToggleButton>
                        </ButtonGroup>
                        <RenderBuyLot item={isBuyLotTab}/>
                    </>

                )}

            </Modal>
        </>
        
    );

}

export default BuyLot;