import { useState, useRef, useEffect, useMemo } from "react";
import { Container, Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip, Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import { useAuth } from "../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import Loader from "../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faPencil } from "@fortawesome/pro-duotone-svg-icons";
import * as formik from "formik";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FormSelect } from "../includes/FormCustom";
import { ValueService } from "ag-grid-community";

const MySwal = withReactContent(Swal);

const ChatInstance = () => {
    const init = useRef(false);
    const { session } = useAuth();
    const gridRef = useRef();
    const { Formik } = formik;
    const [loading, setLoading] = useState(true);
    const [chatInstanceList, setChatInstanceList] = useState([]);
    const [isAddInstance, setisAddInstance] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [dsOption, setDSOption] = useState([]);
    const [userTagOption, setUserTagOption] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userListAdmin, setUserListAdmin] = useState([]);
    const [blueprint2List, setBlueprint2List] = useState([]);
    const [pipelineID, setPipelineID] = useState([]);
    const [combinedCsData, setCombinedCsData] = useState([]);


    const instanceProvider = [
        { value: 'whatsapp', label: 'Whatsapp' },
        { value: 'webchat', label: 'Web Chat' },
    ];

    const chatModeOption = [
        { value: 'manual', label: 'Manual' },
        { value: 'autopilot', label: 'Autopilot' },
        { value: 'inbound_whatsapp', label: 'Inbound Whatsapp' },
        { value: 'blueprint', label: 'Blueprint' },
        { value: 'df_chatbot', label: 'DF Chatbot' },
        { value: 'blueprint_personna', label: 'Blueprint Personna' },
        { value: 'blueprint_takeover', label: 'Blueprint Takeover' },
        { value: 'blueprint2_chatbot', label: 'Blueprint2 Chatbot' },
        { value: 'blueprint2_outreach', label: 'Blueprint2 Outreach' },
    ]

    const avanserOutboundCall = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    const avanserFromNumberCallerID = [
        { value: '+60-', label: '+60-' },
        { value: '+61-', label: '+61-' },
        { value: '+62-', label: '+62-' },
    ];

    const avanserSmsPrefix = [
        { value: '60', label: '60' },
        { value: '61', label: '61' },
        { value: '65', label: '65' },
    ];

    const webchatNoBranding = [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' },
    ];

    // Validation Schema
    const validationSchema = yup.object().shape({
        instance_title: yup.string().required("This field is required"),
    });

    const validationSchemaUpdate = yup.object().shape({
        instance_title: yup.string().required("This field is required"),
    });

    // GET FUNCTION --------------------------------------------------------
    const getChatInstanceList = () => {
        try {
            axios
                .get('ws/ws_blueprint2.php', {
                    params: {
                        task: "getChatInstance",
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res => {
                    let data = res.data;
                    setChatInstanceList(data.record)
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const dsData = async () => {
        try {
            const res = await axios.get('ws/ws_source.php', {
                params: {
                    task: 2,
                    company: session.company_id
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.ds_id,
                    label: record.ds_title,
                    ds_token: record.ds_token,
                }));
                setDSOption(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const userTag = async () => {
        try {
            const res = await axios.get('ws/ws_user.php', {
                params: {
                    task: "getUserTagsV2",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.user_tag_id,
                    label: record.user_tag_title,
                    tag_token: record.tag_token,
                }));

                setUserTagOption(recordOptions);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getUserList = async () => {
        try {
            const res = await axios.get('ws/ws_user.php', {
                params: {
                    task: "4a",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    user_tag: ""
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.user_id,
                    label: record.user_name,
                }));
                setUserList(recordOptions);

                const filteredRecords = data.record.filter(record => record.role_id === "1");

                var recordOptionsAdmin = filteredRecords.map((record) => ({
                    value: record.user_id,
                    label: record.user_name,
                }));
                setUserListAdmin(recordOptionsAdmin);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getBlueprint2 = async () => {
        try {
            const res = await axios.get('ws/ws_blueprint2.php', {
                params: {
                    task: "getBlueprint2Settings",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            });

            let data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.blueprint2_id,
                    label: record.main_prompt,
                }));
                setBlueprint2List(recordOptions);
                setLoading(false);
            } else {
                setBlueprint2List([]);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const getAllPipelines = async () => {
        try {
            const res = await axios.get('ws/ws_pipeline.php', {
                params: {
                    task: "getAllPipelines",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                }
            })
            const data = res.data;

            if (data.status === 0) {
                var recordOptions = data.record.map((record) => ({
                    value: record.pipeline_id,
                    label: record.pipeline_title,
                }));
                setPipelineID(recordOptions);

                let allCSRecords = [];

                for (const record of data.record) {
                    const csRecords = await getCSData(record.pipeline_id);
                    allCSRecords = allCSRecords.concat(csRecords);
                }

                var csOption = allCSRecords.map((record) => ({
                    value: record.cs_id,
                    label: record.cs_title,
                    cs_token: record.cs_token
                }));
                setCombinedCsData(csOption)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getCSData = async (pipeid) => {
        try {
            const res = await axios.get("ws/ws_pipeline.php", {
                params: {
                    task: "getStages",
                    pipeid: pipeid,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            const data = res.data;

            if (data.status === 0) {
                return data.record; // Return the records array
            } else {
                return [];
            }

        } catch {

        }
    }

    // SET FUNCTION -----------------------------------------
    const setUserTag = (params) => {
        const matchingTag = userTagOption.find(option => option.tag_token === params.data.user_tag);
        return matchingTag ? matchingTag.label : '';
    }

    const setDSToken = (params) => {
        const matchingToken = dsOption.find(option => option.ds_token === params.data.ds_token);
        return matchingToken ? matchingToken.label : '';
    }

    const setCSToken = (params) => {
        const token = params.data.cs_token === '' ? 0 : params.data.cs_token;
        const matchingToken = combinedCsData.find(option => option.cs_token === token);
        return matchingToken ? matchingToken.label : '';
    }


    // GRID FUNCTION ----------------------------------------
    const containerStyle = useMemo(() => ({ width: "100%", height: "80vh", paddingTop: 20 }), []);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const gridColumn = [
        {
            headerName: "Title",
            field: "instance_title",
            width: 300,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        { headerName: "User Number", field: "user_number", width: 200 },
        { headerName: "Instance ID", field: "instance_id", width: 200 },
        { headerName: "Mode", field: "chat_mode", width: 250 },
        { headerName: "User Tag", field: "user_tag", width: 250, cellRenderer: setUserTag },
        {
            headerName: "CS Token",
            field: "cs_token",
            width: 250,
            cellRenderer: setCSToken
        },
        {
            headerName: "DS Token",
            field: "ds_token",
            width: 250,
            cellRenderer: setDSToken,
            cellStyle: {
                whiteSpace: 'normal',
                lineHeight: '1.2'
            }
        },
        { headerName: "Instance Provider", field: "instance_provider", width: 250 },
        {
            headerName: "Action",
            field: "",
            headerClass: "center",
            cellClass: "center",
            pinned: "right",
            width: 250,
            cellRenderer: function (params) {
                return (
                    <div className="d-flex">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Delete</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="trash" className="fontAwesomeIcon" size="xl" icon={faTrash} />
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Edit</Tooltip>}
                        >
                            <FontAwesomeIcon data-action="edit" className="fontAwesomeIcon ms-3" size="xl" icon={faPencil} />
                        </OverlayTrigger>
                    </div>
                );
            }
        },
    ];

    // HANDLE GRID CLICK -----------------------------------------------

    const onCellClicked = (event) => {
        let target = event.event.target;
        while (target && !target.getAttribute("data-action")) {
            target = target.parentElement;
        }
        if (target) {
            const action = target.getAttribute("data-action");
            const rowData = event.data;
            switch (action) {
                case "edit":
                    handleEditClick(rowData);
                    break;
                case "trash":
                    handleTrashClick(rowData);
                    break;
                default:
                    break;
            }
        }
    };

    const handleEditClick = (params) => {
        setCurrentData(params);
        setShowModal(true);
    };

    const handleUpdate = async (values) => {
        const combinedAvanserNumber = `${values.avanser_from_number_prefix}${values.avanser_sms_from_number}`;
        const combinedAvanserCaller = `${values.avanser_call_caller_id_prefix}${values.avanser_call_caller_id}`;

        try {
            const response = await axios.get('ws/ws_blueprint2.php', {
                params: {
                    task: "updateChatInstance",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    instance_title: values.instance_title,
                    user_id: values.user_id,
                    user_number: values.user_number,
                    instance_id: values.instance_id,
                    '360D_api_key': values["360D_api_key"],
                    '360D_api_namespace': values["360D_api_namespace"],
                    avanser_sms_api_token: values.avanser_sms_api_token,
                    avanser_sms_from_number: combinedAvanserNumber,
                    avanser_outbound_call: values.avanser_outbound_call,
                    avanser_call_caller_id: combinedAvanserCaller,
                    sms_prefix: values.sms_prefix,
                    chat_mode: values.chat_mode,
                    inbound_whatsapp_reply: values.inbound_whatsapp_reply,
                    ds_token: values.ds_token,
                    user_tag: values.user_tag,
                    cs_token: values.cs_token,
                    cs_id: values.cs_id,
                    fallback_user: values.fallback_user,
                    node_ai_port: values.node_ai_port,
                    instance_provider: values.instance_provider,
                    admin_transfer_button: values.admin_transfer_button,
                    df_project_id: values.df_project_id,
                    blueprint2_setting: values.blueprint2_setting,
                    chat_instance_id: values.chat_instance_id,
                    web_chat_api_key: values.web_chat_api_key,
                    web_chat_host: values.web_chat_host,
                    web_chat_privacy_policy_text: values.web_chat_privacy_policy_text,
                    web_chat_floating_logo: values.web_chat_floating_logo,
                    web_chat_header_logo: values.web_chat_header_logo,
                    web_chat_conversation_logo: values.web_chat_conversation_logo,
                    web_chat_no_branding: values.web_chat_no_branding,
                    web_chat_privacy_policy_url: values.web_chat_privacy_policy_url,
                    web_chat_header_title: values.web_chat_header_title,
                    web_chat_greeting_message: values.web_chat_greeting_message,
                }
            });
            const data = response.data;

            if (data.status === 0) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Record has been updated',
                    timer: 1500
                });
                setShowModal(false);
                getChatInstanceList();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleTrashClick = (params) => {
        MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                if (params && params.chat_instance_id) {
                    sendDelete(params);
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Invalid data for deletion',
                        timer: 1500
                    });
                }
            }
        });
    };

    const sendDelete = async (params) => {
        try {
            const response = await axios.get('ws/ws_blueprint2.php', {
                params: {
                    task: "deleteChatInstance",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    chat_instance_id: params.chat_instance_id,
                }
            });

            if (response.data.status == '0') {
                MySwal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Your record has been deleted',
                    timer: 1500
                });
                getChatInstanceList();
            }
        } catch (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error happened while deleting record',
                timer: 1500
            });
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
    };


    // ADD FUNCTION ---------------------------------------------------------

    const handleSubmitForm = async (values) => {
        try {
            const response = await axios.get('ws/ws_blueprint2.php', {
                params: {
                    task: "setChatInstance",
                    utoken: session.user_ac_token,
                    ctoken: session.company_token,
                    instance_title: values.instance_title,
                    user_id: values.user_id,
                    user_number: values.user_number,
                    instance_id: values.instance_id,
                    '360D_api_key': values.D_api_key,
                    '360D_api_namespace': values.D_api_namespace,
                    avanser_sms_api_token: values.avanser_sms_api_token,
                    avanser_sms_from_number: values.avanser_sms_from_number,
                    avanser_outbound_call: values.avanser_outbound_call,
                    avanser_call_caller_id: values.avanser_call_caller_id,
                    sms_prefix: values.sms_prefix,
                    chat_mode: values.chat_mode,
                    inbound_whatsapp_reply: values.inbound_whatsapp_reply,
                    ds_token: values.ds_token,
                    user_tag: values.user_tag,
                    cs_token: values.cs_token,
                    cs_id: values.cs_id,
                    fallback_user: values.fallback_user,
                    node_ai_port: values.node_ai_port,
                    instance_provider: values.instance_provider,
                    admin_transfer_button: values.admin_transfer_button,
                    df_project_id: values.df_project_id,
                    blueprint2_setting: values.blueprint2_setting,
                    web_chat_api_key: values.web_chat_api_key,
                    web_chat_host: values.web_chat_host,
                    web_chat_privacy_policy_text: values.web_chat_privacy_policy_text,
                    web_chat_floating_logo: values.web_chat_floating_logo,
                    web_chat_header_logo: values.web_chat_header_logo,
                    web_chat_conversation_logo: values.web_chat_conversation_logo,
                    web_chat_no_branding: values.web_chat_no_branding,
                    web_chat_privacy_policy_url: values.web_chat_privacy_policy_url,
                    web_chat_header_title: values.web_chat_header_title,
                    web_chat_greeting_message: values.web_chat_greeting_message,
                }
            });
            const data = response.data;

            if (data.status === 0) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Successfully Added',
                    text: 'Record has been added successfully',
                    timer: 1500
                });
                setisAddInstance(false);
                getChatInstanceList();
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    // USEEFFECT ----------------------------------------------------
    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getChatInstanceList();
            dsData();
            userTag();
            getUserList();
            getBlueprint2();
            getAllPipelines();
        }
    }, []);

    const toggleAddInstanceModal = () => {
        setisAddInstance(prevState => !prevState);
    };

    return (
        <Container fluid className="p-0 m-0">
            {loading ? (
                <Loader />
            ) : (
                <div className="p-4">
                    <button type="button" className="btn op-button op-primary-color text-light" onClick={toggleAddInstanceModal}>
                        <FontAwesomeIcon icon={faPlus} className="me-2" /> Instance
                    </button>
                    <Row>
                        <Col xxl={12}>
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact ref={gridRef} columnDefs={gridColumn} rowData={loading ? [] : chatInstanceList} rowHeight={90} pagination={true} onCellClicked={onCellClicked} />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Modal show={isAddInstance} onHide={toggleAddInstanceModal} size="lg">
                        <Formik
                            initialValues={{
                                instance_title: "",
                                user_id: "",
                                user_number: "",
                                instance_id: "",
                                D_api_key: "",
                                D_api_namespace: "",
                                avanser_sms_api_token: "",
                                avanser_sms_from_number: "",
                                avanser_outbound_call: "",
                                avanser_call_caller_id: "",
                                sms_prefix: "",
                                chat_mode: "",
                                inbound_whatsapp_reply: "",
                                ds_token: "",
                                user_tag: "",
                                cs_token: "",
                                cs_id: "",
                                fallback_user: "",
                                node_ai_port: "",
                                instance_provider: "",
                                admin_transfer_button: "",
                                df_project_id: "",
                                blueprint2_setting: "",
                                web_chat_api_key: "",
                                web_chat_host: "",
                                web_chat_privacy_policy_text: "",
                                web_chat_floating_logo: "",
                                web_chat_header_logo: "",
                                web_chat_conversation_logo: "",
                                web_chat_no_branding: "",
                                web_chat_privacy_policy_url: "",
                                web_chat_header_title: "",
                                web_chat_greeting_message: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmitForm}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Add Instance</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="instance_title"
                                                value={values.instance_title}
                                                onChange={handleChange}
                                                isInvalid={touched.instance_title && !!errors.instance_title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.instance_title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button type="submit" className="btn op-button op-primary-color text-light shadow">
                                            Submit
                                        </button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg">
                        <Formik
                            initialValues={{
                                "360D_api_key": currentData ? currentData["360D_api_key"] : "",
                                "360D_api_namespace": currentData ? currentData["360D_api_namespace"] : "",
                                admin_transfer_button: currentData ? currentData.admin_transfer_button : "",
                                avanser_sms_api_token: currentData ? currentData.avanser_sms_api_token : "",
                                avanser_outbound_call: currentData ? currentData.avanser_outbound_call : "",
                                avanser_call_caller_id: currentData ? currentData.avanser_call_caller_id.substring(4) : "",
                                avanser_sms_from_number: currentData ? currentData.avanser_sms_from_number.substring(4) : "",
                                blueprint2_setting: currentData ? currentData.blueprint2_setting : "",
                                chat_mode: currentData ? currentData.chat_mode : "",
                                c_token: currentData ? currentData.ctoken : "",
                                cs_token: currentData ? currentData.cs_token : "",
                                cs_id: currentData ? currentData.cs_id : "",
                                df_project_id: currentData ? currentData.df_project_id : "",
                                ds_token: currentData ? currentData.ds_token : "",
                                fallback_user: currentData ? currentData.fallback_user : "",
                                inbound_whatsapp_reply: currentData ? currentData.inbound_whatsapp_reply : "",
                                instance_id: currentData ? currentData.instance_id : "",
                                instance_provider: currentData ? currentData.instance_provider : "",
                                instance_title: currentData ? currentData.instance_title : "",
                                node_ai_port: currentData ? currentData.node_ai_port : "",
                                sms_prefix: currentData ? currentData.sms_prefix : "",
                                user_number: currentData ? currentData.user_number : "",
                                user_id: currentData ? currentData.user_id : "",
                                user_tag: currentData ? currentData.user_tag : "",
                                web_chat_api_key: currentData ? currentData.web_chat_api_key : "",
                                web_chat_conversation_logo: currentData ? currentData.web_chat_conversation_logo : "",
                                web_chat_floating_logo: currentData ? currentData.web_chat_floating_logo : "",
                                web_chat_greeting_message: currentData ? currentData.web_chat_greeting_message : "",
                                web_chat_header_logo: currentData ? currentData.web_chat_header_logo : "",
                                web_chat_header_title: currentData ? currentData.web_chat_header_title : "",
                                web_chat_host: currentData ? currentData.web_chat_host : "",
                                web_chat_no_branding: currentData ? currentData.web_chat_no_branding : "",
                                web_chat_privacy_policy_text: currentData ? currentData.web_chat_privacy_policy_text : "",
                                web_chat_privacy_policy_url: currentData ? currentData.web_chat_privacy_policy_url : "",
                                chat_instance_id: currentData ? currentData.chat_instance_id : "",
                                avanser_from_number_prefix: currentData ? currentData.avanser_sms_from_number.substring(0, 4) : "",
                                avanser_call_caller_id_prefix: currentData ? currentData.avanser_call_caller_id.substring(0, 4) : ""
                            }}
                            validationSchema={validationSchemaUpdate}
                            onSubmit={handleUpdate}
                            enableReinitialize
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                touched,
                                setFieldValue
                            }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Header closeButton>
                                        <Modal.Title className="op-text-bigger">Edit Instance</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="instance_title"
                                                value={values.instance_title}
                                                onChange={handleChange}
                                                isInvalid={touched.instance_title && !!errors.instance_title}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.instance_title}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>User ID</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select User"
                                                options={userListAdmin}
                                                valueDefault={
                                                    values.user_id == "0"
                                                        ? ""
                                                        : {
                                                            label: userListAdmin.find(item => item.value === values.user_id)?.label || '',
                                                            value: values.user_id
                                                        }
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue('user_id', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>User Contact</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="user_number"
                                                value={values.user_number}
                                                onChange={handleChange}
                                                isInvalid={touched.user_number && !!errors.user_number}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.user_number}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Instance ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="instance_id"
                                                value={values.instance_id}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>360D API Key</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="360D_api_key"
                                                value={values["360D_api_key"]}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>360D API Namespace</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="360D_api_namespace"
                                                value={values["360D_api_namespace"]}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Avanser SMS API Token</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="avanser_sms_api_token"
                                                value={values.avanser_sms_api_token}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Avanser SMS From Number</Form.Label>
                                            <div className="d-flex justify-content-between">
                                                <FormSelect
                                                    placeholder="Prefix"
                                                    options={avanserFromNumberCallerID}
                                                    valueDefault={avanserFromNumberCallerID.find(option => option.value == values.avanser_from_number_prefix)}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('avanser_from_number_prefix', selectedOption.value);
                                                    }}
                                                    style={{ width: '7%' }}
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="avanser_sms_from_number"
                                                    value={values.avanser_sms_from_number}
                                                    onChange={handleChange}
                                                    style={{ width: '85%' }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Avanser Outbound Call</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={avanserOutboundCall}
                                                valueDefault={avanserOutboundCall.find(option => option.value == values.avanser_outbound_call)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('avanser_outbound_call', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Avanser Call Caller ID</Form.Label>
                                            <div className="d-flex justify-content-between">
                                                <FormSelect
                                                    placeholder="Prefix"
                                                    options={avanserFromNumberCallerID}
                                                    valueDefault={avanserFromNumberCallerID.find(option => option.value == values.avanser_call_caller_id_prefix)}
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('avanser_call_caller_id_prefix', selectedOption.value);
                                                    }}
                                                    style={{ width: '7%' }}
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="avanser_call_caller_id"
                                                    value={values.avanser_call_caller_id}
                                                    onChange={handleChange}
                                                    style={{ width: '85%' }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>SMS Prefix</Form.Label>
                                            <FormSelect
                                                placeholder="Select an option"
                                                options={avanserSmsPrefix}
                                                valueDefault={avanserSmsPrefix.find(option => option.value == values.sms_prefix)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('sms_prefix', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Chat Mode</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={chatModeOption}
                                                valueDefault={chatModeOption.find(option => option.value == values.chat_mode)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('chat_mode', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Inbound Whatsapp Reply</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="inbound_whatsapp_reply"
                                                value={values.inbound_whatsapp_reply}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>DS Token</Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select DS Token"
                                                    value={values.ds_token}
                                                    options={dsOption}
                                                    valueDefault={
                                                        !values.ds_token || values.ds_token == "0"
                                                            ? ""
                                                            : {
                                                                label: dsOption.find(item => item.ds_token === values.ds_token)?.label || '',
                                                                value: values.ds_token
                                                            }
                                                    }
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('ds_token', selectedOption.ds_token);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>User Tag</Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select User Tag"
                                                    value={values.user_tag}
                                                    options={userTagOption.map(option => ({
                                                        value: option.value,
                                                        label: `${option.label} (${option.tag_token})`,
                                                        tag_token: option.tag_token,
                                                    }))}
                                                    valueDefault={
                                                        !values.user_tag || values.user_tag == "0"
                                                            ? ""
                                                            : {
                                                                label: `${userTagOption.find(item => item.tag_token === values.user_tag)?.label || ''} (${values.user_tag})`,
                                                                value: values.user_tag
                                                            }
                                                    }
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('user_tag', selectedOption.tag_token);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>CS Token</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={combinedCsData.map(option => ({
                                                    value: option.value,
                                                    label: `${option.label} (${option.cs_token})`,
                                                    cs_token: option.cs_token,
                                                }))}
                                                valueDefault={
                                                    !values.cs_token || values.cs_token == '0'
                                                        ? ""
                                                        : {
                                                            label: `${combinedCsData.find(item => item.cs_token === values.cs_token)?.label || ''} (${values.cs_token})`,
                                                            value: values.cs_token
                                                        }
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue('cs_token', selectedOption.cs_token);
                                                    setFieldValue('cs_id', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Fallback User</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select Fallback User"
                                                options={userList}
                                                valueDefault={
                                                    values.fallback_user == "0"
                                                        ? ""
                                                        : {
                                                            label: userList.find(item => item.value === values.fallback_user)?.label || '',
                                                            value: values.fallback_user
                                                        }
                                                }
                                                onChange={(selectedOption) => {
                                                    setFieldValue('fallback_user', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Node AI Port</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="node_ai_port"
                                                value={values.node_ai_port}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Instance Provider</Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    placeholder="Select Instance Provider"
                                                    options={instanceProvider}
                                                    valueDefault={
                                                        values.instance_provider == "0"
                                                            ? ""
                                                            : {
                                                                label: instanceProvider.find(item => item.value === values.instance_provider)?.label || '',
                                                                value: values.instance_provider
                                                            }
                                                    }
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('instance_provider', selectedOption.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Admin Transfer Button</Form.Label>
                                            <div>
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="admin_transfer_button"
                                                    type="radio"
                                                    value="1"
                                                    checked={values.admin_transfer_button == "1"}
                                                    onChange={handleChange("admin_transfer_button")}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="admin_transfer_button"
                                                    type="radio"
                                                    value="0"
                                                    checked={values.admin_transfer_button == "0"}
                                                    onChange={handleChange("admin_transfer_button")}
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Project ID</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="df_project_id"
                                                value={values.df_project_id}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Blueprint2 Setting</Form.Label>
                                            <Form.Group className="w-100">
                                                <FormSelect
                                                    className="mb-3"
                                                    style={{ maxWidth: "100%" }}
                                                    placeholder="Select Blueprint2 Option"
                                                    options={blueprint2List.map(option => ({
                                                        ...option,
                                                        label: (
                                                            <span title={option.label} style={{ display: "block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>
                                                                {option.label}
                                                            </span>
                                                        )
                                                    }))}
                                                    valueDefault={
                                                        values.blueprint2_setting == '0'
                                                            ? ""
                                                            : {
                                                                label: blueprint2List.find(item => item.value === values.blueprint2_setting)?.label || '',
                                                                value: values.blueprint2_setting
                                                            }
                                                    }
                                                    onChange={(selectedOption) => {
                                                        setFieldValue('blueprint2_setting', selectedOption.value);
                                                    }}
                                                />
                                            </Form.Group>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat API Key</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_api_key"
                                                value={values.web_chat_api_key}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Host</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_host"
                                                value={values.web_chat_host}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Privacy Policy Text</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_privacy_policy_text"
                                                value={values.web_chat_privacy_policy_text}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Floating Logo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_floating_logo"
                                                value={values.web_chat_floating_logo}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Header Logo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_header_logo"
                                                value={values.web_chat_header_logo}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Conversation Logo</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_conversation_logo"
                                                value={values.web_chat_conversation_logo}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat No Branding</Form.Label>
                                            <FormSelect
                                                className="mb-3"
                                                placeholder="Select an option"
                                                options={webchatNoBranding}
                                                valueDefault={webchatNoBranding.find(option => option.value == values.web_chat_no_branding)}
                                                onChange={(selectedOption) => {
                                                    setFieldValue('web_chat_no_branding', selectedOption.value);
                                                }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Privacy Policy URL</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_privacy_policy_url"
                                                value={values.web_chat_privacy_policy_url}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Header Title</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_header_title"
                                                value={values.web_chat_header_title}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Web Chat Greeting Message</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="web_chat_greeting_message"
                                                value={values.web_chat_greeting_message}
                                                onChange={handleChange}
                                            />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" type="submit">
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                </div>
            )}
        </Container>
    );
}

export default ChatInstance;
