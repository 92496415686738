import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Col, Container, Row, Stack, Form, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FormSelect, FormSelectCreatable, FormCustomField } from "../includes/FormCustom";
import * as formik from "formik";
import * as yup from "yup";
import axios from "../api/axios";
import { faSearch, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import Swal from "sweetalert2/dist/sweetalert2";
import { AgGridReact } from "ag-grid-react";
import NoRecord from "../includes/NoRecord";
import { useAuth } from "../auth/AuthContext";
import Tippy from "@tippyjs/react";
import { faEllipsisStrokeVertical } from "@fortawesome/pro-regular-svg-icons";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const Prospect = () => {
  const { Formik } = formik;
  const initialized = useRef(false);
  const [user, setUser] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isAddProspect, setisAddProspect] = useState(false);
  const [isEditProspect, setIsEditProspect] = useState(false);
  const [isFindDesignation, setIsFindDesignation] = useState(false);
  const [isContact, setisContact] = useState(true);
  const [userSearch, setUserSearch] = useState("");
  const [contactSearch, setContactSearch] = useState("");
  const [isContactSearch, setisContactSearch] = useState(0);
  const { session } = useAuth();
  const [trigger, setTrigger] = useState(0);
  const [prosCF, setProsCF] = useState([]);
  const [prosCfInitVal, setProsCfInitVal] = useState({});
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const { t } = useTranslation();

  var phoneType = [
    { label: t("ProspectList_phoneType_list_label_1"), value: "Mobile" },
    { label: t("ProspectList_phoneType_list_label_2"), value: "Work" },
    { label: t("ProspectList_phoneType_list_label_3"), value: "Home" },
    { label: t("ProspectList_phoneType_list_label_4"), value: "Other" },
  ];

  var emailType = [
    { label: t("ProspectList_emailType_list_label_1"), value: "Work" },
    { label: t("ProspectList_emailType_list_label_2"), value: "Home" },
    { label: t("ProspectList_emailType_list_label_3"), value: "Other" },
  ];

  var prefix = [
    { label: "60", value: "60" },
    { label: "61", value: "61" },
    { label: "65", value: "65" },
  ];

  // SCHEMA ------------------------------------------------------

  const addProspectSchema = yup.object().shape({
    prospect: yup.string().required(t("ProspectList_required_field")),
    contact: yup
      .array()
      .of(
        yup.object().shape({
          contact: yup.string(),
        })
      )
      .test(t("ProspectList_required_field"), t("ProspectList_required_field"), (contacts) => {
        if (contacts.length > 0) {
          return !!contacts[0].contact;
        }
        return false;
      })
      .required(t("ProspectList_required_field")),
  });

  const editProspectSchema = yup.object().shape({
    prospectEdit: yup.string().required(t("ProspectList_required_field")),
    prospectOwnerIDEdit: yup.string().required(t("ProspectList_required_field")),

    contactNum1Pref: yup.string().required(t("ProspectList_required_field")),
    contactNum1: yup.string().required(t("ProspectList_required_field")),
  });

  // GET FUNCTION ------------------------------------------------

  const getUser = (loginData) => {
    axios
      .get("ws/ws_user.php", {
        params: {
          task: "4a",
          ctoken: loginData.company_token,
          utoken: loginData.user_ac_token,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          data.record.forEach((record) => {
            record.label = record.user_name;
            record.value = record.user_id;
          });

          setUser(data.record);
        }
      });
  };

  const getExistingDesignation = (inputValue, callback) => {
    setIsFindDesignation(false);
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: 11,
          company: session.company_id,
          q: inputValue,
        },
      })
      .then((response) => {
        var data = response.data;
        if (data.status === 0) {
          var options = data.record
            .filter((record) => {
              return Number(record.designation_id) > 0;
            })
            .map((record) => ({
              label: record.designation_title,
              value: record.designation_id,
              customLabel: "",
            }));

          var newOptions = data.record.find((record) => Number(record.designation_id) === 0);
          if (newOptions) {
            options.unshift({
              label: inputValue,
              value: 0,
              customLabel: newOptions.msg,
              contact1: "",
              contact_id: "",
            });
          }

          callback(options);
          setIsFindDesignation(true);
        } else {
          options = [{ label: inputValue, value: 0, customLabel: data.error[0].msg }];
          callback(options);
          setIsFindDesignation(true);
        }
      });
  };

  const getCountry = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCountry",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.country_title,
          value: record.country_id,
        }));
        setCountryData(options);
      });
  };

  const getState = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listState",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.state_title,
          value: record.state_id,
          country: record.country_id,
        }));
        setStateData(options);
      });
  };

  const getCity = async () => {
    axios
      .get("ws/ws_listplace.php", {
        params: {
          task: "listCity",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((response) => {
        const data = response.data;
        const options = data.record.map((record) => ({
          label: record.city_title,
          value: record.city_id,
          state: record.state_id,
        }));
        setCityData(options);
      });
  };

  // HANDLE FUNCTION ---------------------------------------------

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setisContactSearch(Math.random());
    }
  };

  // SEND FUNCTION -----------------------------------------------

  const sendAddProspect = async (values) => {
    let checkContact = await sendCheckProspect(values.contact[0]);
    if (!checkContact) {
      Swal.fire({
        icon: "warning",
        title: t("ProspectList_alert_warning_1_title"),
        text: t("ProspectList_alert_warning_1_text"),
        timer: 2000,
      });
      return;
    }
    
    axios.get('ws/ws_prospect.php',{
      params: {
        task: "addNewProspectV2",
        name: values.prospect,
        des: values.designation_id,
        des_title: values.designation_title,
        contact: values.contact[0].contact,
        contact2: values.contact[1] ? values.contact[1].contact : "",
        contact3: values.contact[2] ? values.contact[2].contact : "",
        contact4: values.contact[3] ? values.contact[3].contact : "",
        email: values.email[0].email,
        email2: values.email[1] ? values.email[1].email : "",
        email3: values.email[2] ? values.email[1].email : "",
        organization: "",
        utoken: session.user_ac_token,
        ctoken: session.company_token
      }
    })
    .then(res => {
        let data = res.data;

        if (data.status == 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectList_alert_success_title"),
            text: t("ProspectList_alert_success_text_add"),
            timer: "1500",
          }).then((result) => {
            setisAddProspect(false);
            setTrigger(trigger + 1);
          });
        }else{
          Swal.fire({
            icon: "error",
            title: t("ProspectList_alert_failed_title"),
            text: t("ProspectList_alert_failed_text"),
            timer: "1500",
          });
        }
    })
    .catch(error => {
      Swal.fire({
        icon: "error",
        title: error.message
      });
    });
    
  };

  const sendEditProspect = (values) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "2",
          des_title: values.designationTitleEdit,
          des: values.designationIDEdit,
          organization: "",
          prospect_address: "",
          prospect: values.prospectIDEdit,
          name: values.prospectEdit,
          owner_id: values.prospectOwnerIDEdit,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          triggerEditProspect2(values);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectList_alert_failed_title"),
            text: t("ProspectList_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const triggerEditProspect2 = (values) => {
    axios
      .get("ws/ws_edit_prospect_details.php", {
        params: {
          task: "updateContacts",
          prospect_id: values.prospectIDEdit,
          contact1_prefix: values.contactNum1Pref,
          contact1: values.contactNum1,
          contact1_old: "",
          contact2_prefix: values.contactNum2Pref,
          contact2: values.contactNum2,
          contact3_prefix: values.contactNum3Pref,
          contact3: values.contactNum3,
          contact4_prefix: values.contactNum4Pref,
          contact4: values.contactNum4,
          email1: values.email1,
          email2: values.email2,
          address1: values.address1,
          address2: values.address2,
          address3: values.address3,
          state_id: values.stateID,
          city_id: values.cityID,
          postcode: values.postcode,
          country_id: values.countryID,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          triggerEditProspect3(values.prospectIDEdit, values.cf_person);
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectList_alert_failed_title"),
            text: t("ProspectList_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const triggerEditProspect3 = (prosID, prosCFData) => {
    var record = [];
    var cf_id = [];
    var cf_val = [];

    prosCFData.forEach((data) => {
      record.push(prosID);
      cf_id.push(data.cf_id);
      cf_val.push(data.fd_values);
    });

    axios
      .get("ws/ws_custom_field.php", {
        params: {
          task: "updateOrAddCF",
          record: record.toString(),
          hq_prospect_id: "",
          cf_label: "",
          cf: cf_id.toString(),
          value: cf_val.toString(),
          user_id: session.user_id,
          company_id: session.company_id,
          secret_key: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            icon: "success",
            title: t("ProspectList_alert_success_title"),
            text: t("ProspectList_alert_success_text_edit"),
            timer: 1500,
          }).then((result) => {
            setIsEditProspect(false);
            setTrigger(trigger + 1);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: t("ProspectList_alert_failed_title"),
            text: t("ProspectList_alert_failed_text"),
            timer: 1500,
          });
        }
      });
  };

  const sendCheckProspect = async (contact) => {
    try {
      const res = await axios.get("ws/ws_prospect.php", {
        params: {
          task: 13,
          company: session.company_id,
          type: contact.contact_type.value,
          mobile: contact.contact,
          people: "",
        },
      });

      let data = res.data;

      if (Number(data.status === 0)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  // GRID SETUP --------------------------------------------------

  const gridRef = useRef(null);
  const containerStyle = useMemo(() => ({ width: "100%", height: isContact ? "80vh" : "0vh", paddingTop: 20 }), []); // eslint-disable-line react-hooks/exhaustive-deps
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); // eslint-disable-line react-hooks/exhaustive-deps
  const [gridCount, setGridCount] = useState(0);

  const RenderProspectAction = (params) => {
    const tippyRef = useRef();
    const [visible, setVisible] = useState(false);

    const show = () => setVisible(true);
    const hide = () => setVisible(false);

    const dropDownContent = (
      <div className="op-ag-action">
        <div onClick={() => onClickHandler("edit")} className="op-ag-action-item">
          {t("ProspectList_edit_prospect")}
        </div>
        <div onClick={() => onClickHandler("delete")} className="op-ag-action-item">
          {t("ProspectList_delete_prospect")}
        </div>
      </div>
    );

    const onClickHandler = (option) => {
      hide();

      if (option === "delete") {
        sendDeleteProspect(params.data);
      }

      if (option === "edit") {
        getProspectCF(params.data);
      }
    };

    return (
      <Tippy ref={tippyRef} content={dropDownContent} visible={visible} onClickOutside={hide} allowHTML={true} arrow={false} appendTo={document.body} interactive={true} placement="left">
        <button className="btn btn-light" onClick={visible ? hide : show}>
          <FontAwesomeIcon icon={faEllipsisStrokeVertical} size="lg" />
        </button>
      </Tippy>
    );
  };

  const sendDeleteProspect = (data) => {
    Swal.fire({
      icon: "warning",
      title: t("ProspectList_alert_warning_2_title"),
      text: t("ProspectList_alert_warning_2_text"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ProspectList_alert_warning_2_confirm"),
      cancelButtonText: t("ProspectList_alert_warning_cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get("ws/ws_prospect.php", {
            params: {
              task: "10",
              prospect: data.prospect_id,
              forceDelete: "",
              utoken: session.user_ac_token,
              ctoken: session.company_token,
            },
          })
          .then((res) => {
            let data2 = res.data;

            if (data2.status === 0) {
              Swal.fire({
                title: t("ProspectList_alert_success_title"),
                text: t("ProspectList_alert_success_text_delete"),
                icon: "success",
                timer: 1500,
              }).then((result) => {
                setTrigger(trigger + 1);
              });
            } else {
              Swal.fire({
                title: t("ProspectList_alert_warning_3_title"),
                text: `${t("ProspectList_alert_warning_3_text_1")} ${t("ProspectList_alert_warning_3_text_2")} ${data.prospect_name}, ${t("ProspectList_alert_warning_3_text_3")}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: t("ProspectList_alert_warning_3_confirm"),
                cancelButtonText: t("ProspectList_alert_warning_cancel"),
              }).then((result) => {
                if (result.isConfirmed) {
                  ForceDelete(data, "yes");
                }
              });
            }
          });
      }
    });
  };

  const ForceDelete = (data, force) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "10",
          prospect: data.prospect_id,
          forceDelete: force,
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          Swal.fire({
            title: t("ProspectList_alert_success_title"),
            text: t("ProspectList_alert_success_text_delete2"),
            icon: "success",
            timer: 1500,
          }).then((result) => {
            setTrigger(trigger + 1);
          });
        } else {
          Swal.fire({
            title: t("ProspectList_alert_warning_4_title"),
            text: t("ProspectList_alert_warning_4_text"),
            icon: "warning",
          });
        }
      });
  };

  const getProspectCF = (prospectEditData) => {
    axios
      .get("ws/ws_prospect.php", {
        params: {
          task: "getProspectDetails",
          prospect: prospectEditData.prospect_id,
          pipe: "",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status === 0) {
          if (Number(data.record.custom_field) > 0) {
            setProsCF(data.record.custom_field_data);
          } else {
            setProsCF([]);
          }
          setProsCfInitVal({
            prospectIDEdit: prospectEditData.prospect_id,
            prospectEdit: prospectEditData.prospect_name,
            addressEdit: prospectEditData.prospect_address,
            designationIDEdit: prospectEditData.designation_id,
            designationTitleEdit: prospectEditData.DESIGNATION_NAME,
            prospectOwnerIDEdit: prospectEditData.owner_id,
            contactNum1Pref: prospectEditData.contact1_prefix,
            contactNum1: prospectEditData.contact1 !== "" ? prospectEditData.contact1 : prospectEditData.contact_count > 0 && prospectEditData.contacts[0] ? prospectEditData.contacts[0].contact_num : "",
            contactNum2Pref: prospectEditData.contact2_prefix,
            contactNum2: prospectEditData.contact2 !== "" ? prospectEditData.contact2 : prospectEditData.contact_count > 0 && prospectEditData.contacts[1] ? prospectEditData.contacts[1].contact_num : "",
            contactNum3Pref: prospectEditData.contact3_prefix,
            contactNum3: prospectEditData.contact3 !== "" ? prospectEditData.contact3 : prospectEditData.contact_count > 0 && prospectEditData.contacts[2] ? prospectEditData.contacts[2].contact_num : "",
            contactNum4Pref: prospectEditData.contact4_prefix,
            contactNum4: prospectEditData.contact4 !== "" ? prospectEditData.contact4 : prospectEditData.contact_count > 0 && prospectEditData.contacts[3] ? prospectEditData.contacts[3].contact_num : "",
            email1: prospectEditData.email1 !== "" ? prospectEditData.email1 : prospectEditData.email_count > 0 && prospectEditData.emails[0] ? prospectEditData.emails[0].email_title : "",
            email2: prospectEditData.email2 !== "" ? prospectEditData.email2 : prospectEditData.email_count > 0 && prospectEditData.emails[1] ? prospectEditData.emails[1].email_title : "",
            email3: prospectEditData.email3 !== "" ? prospectEditData.email3 : prospectEditData.email_count > 0 && prospectEditData.emails[2] ? prospectEditData.emails[2].email_title : "",
            address1: prospectEditData.address_line1 === "" ? prospectEditData.prospect_address : prospectEditData.address_line1,
            address2: prospectEditData.address_line2,
            address3: prospectEditData.address_line3,
            postcode: Number(prospectEditData.postal_code) === 0 ? "" : prospectEditData.postal_code,
            countryID: prospectEditData.country_id,
            stateID: prospectEditData.state_id,
            cityID: prospectEditData.city_id,
            cf_person:
              Number(data.record.custom_field) > 0 &&
              data.record.custom_field_data.map((record) => ({
                cf_id: record.cf_id,
                name: record.cf_id,
                fd_values: record.fd_value,
              })),
          });

          setIsEditProspect(true);
        } else {
          setProsCF([]);

          setProsCfInitVal({
            prospectIDEdit: prospectEditData.prospect_id,
            prospectEdit: prospectEditData.prospect_name,
            addressEdit: prospectEditData.prospect_address,
            designationIDEdit: prospectEditData.designation_id,
            designationTitleEdit: prospectEditData.DESIGNATION_NAME,
            prospectOwnerIDEdit: prospectEditData.owner_id,
            contactNum1Pref: prospectEditData.contact1_prefix,
            contactNum1: prospectEditData.contact1 !== "" ? prospectEditData.contact1 : prospectEditData.contact_count > 0 && prospectEditData.contacts[0] ? prospectEditData.contacts[0].contact_num : "",
            contactNum2Pref: prospectEditData.contact2_prefix,
            contactNum2: prospectEditData.contact2 !== "" ? prospectEditData.contact2 : prospectEditData.contact_count > 0 && prospectEditData.contacts[1] ? prospectEditData.contacts[1].contact_num : "",
            contactNum3Pref: prospectEditData.contact3_prefix,
            contactNum3: prospectEditData.contact3 !== "" ? prospectEditData.contact3 : prospectEditData.contact_count > 0 && prospectEditData.contacts[2] ? prospectEditData.contacts[2].contact_num : "",
            contactNum4Pref: prospectEditData.contact4_prefix,
            contactNum4: prospectEditData.contact4 !== "" ? prospectEditData.contact4 : prospectEditData.contact_count > 0 && prospectEditData.contacts[3] ? prospectEditData.contacts[3].contact_num : "",
            email1: prospectEditData.email1 !== "" ? prospectEditData.email1 : prospectEditData.email_count > 0 && prospectEditData.emails[0] ? prospectEditData.emails[0].email_title : "",
            email2: prospectEditData.email2 !== "" ? prospectEditData.email2 : prospectEditData.email_count > 0 && prospectEditData.emails[1] ? prospectEditData.emails[1].email_title : "",
            email3: prospectEditData.email3 !== "" ? prospectEditData.email3 : prospectEditData.email_count > 0 && prospectEditData.emails[2] ? prospectEditData.emails[2].email_title : "",
            address1: prospectEditData.address_line1 === "" ? prospectEditData.prospect_address : prospectEditData.address_line1,
            address2: prospectEditData.address_line2,
            address3: prospectEditData.address_line3,
            postcode: Number(prospectEditData.postal_code) === 0 ? "" : prospectEditData.postal_code,
            countryID: prospectEditData.country_id,
            stateID: prospectEditData.state_id,
            cityID: prospectEditData.city_id,
            cf_person: [],
          });

          setIsEditProspect(true);
        }
      });
  };

  const gridColumn = [
    {
      headerName: t("ProspectList_headername_1"),
      field: "",
      minWidth: 300,
      cellRenderer: (params) => {
        if (params.data !== undefined) {
          var initials = params.data.prospect_name.split("")[0];
          return (
            <div className="d-flex">
              <div className="avatar me-2">
                <div className="avatar-img" style={{ width: 35, height: 35, backgroundColor: "#eee" }}>
                  <div className="avatar-txt text-uppercase">{initials}</div>
                </div>
              </div>
              <span>{params.data.prospect_name}</span>
            </div>
          );

          // return params.data.prospect_name;
        } else {
          return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
        }
      },
      onCellClicked: function (params) {
        if (params.data) {
          window.open(`${session.origin}/prospect/${params.data.prospect_id}`, "_blank");
        }
      },
    },
    {
      headerName: t("ProspectList_headername_2"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data && params.data.contact_count > 0) {
          var contacts = params.data.contacts.map((record) => record.contact_num);
          return (
            <div className="d-flex">
              {contacts.map((record, index) => (
                <div className="border rounded me-1" key={index} style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {record}
                </div>
              ))}
            </div>
          );
        } else if (params.data && params.data.contact_count === 0) {
          if (params.data.contact1 === "") {
            return "";
          } else {
            return (
              <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                {params.data.contact1}
              </div>
            );
          }
        }
      },
    },
    {
      headerName: t("ProspectList_headername_3"),
      field: "",
      minWidth: 200,
      cellRenderer: (params) => {
        if (params.data && params.data.email_count > 0) {
          var emails = params.data.emails.map((record) => record.email_title);
          return (
            <div className="d-flex">
              {emails.map((record, index) => (
                <div className="border rounded me-1" key={index} style={{ padding: "8px 10px", lineHeight: 1 }}>
                  {record}
                </div>
              ))}
            </div>
          );
        } else if (params.data && params.data.email_count === 0) {
          if (params.data.email1 === "") {
            return "";
          } else {
            return (
              <div className="border rounded me-1" style={{ padding: "8px 10px", lineHeight: 1 }}>
                {params.data.email1}
              </div>
            );
          }
        }
      },
    },
    { headerName: t("ProspectList_headername_4"), field: "organization_title", width: 300 },
    { headerName: t("ProspectList_headername_5"), field: "prospect_address", width: 250 },
    { headerName: t("ProspectList_headername_6"), field: "DESIGNATION_NAME", width: 200 },
    { headerName: t("ProspectList_headername_7"), field: "OWNER_NAME", width: 200 },
    { headerName: "", field: "", cellClass: "center", cellRenderer: RenderProspectAction, pinned: "right" },
  ];

  const gridDataSource = useMemo(() => {
    return {
      rowCount: undefined,
      getRows: (params) => {
        console.log("asking for ", params.startRow, " to ", params.endRow);
        axios
          .get("ws/ws_prospect.php", {
            params: {
              task: "8a",
              company: session.company_id,
              uid: session.user_id,
              m: "yes",
              page: Math.floor(params.startRow / (params.endRow - params.startRow)) + 1,
              size: params.endRow - params.startRow,
              q: contactSearch,
              utoken: session.user_ac_token,
              ctoken: session.company_token,
              user: userSearch,
            },
          })
          .then((response) => {
            const data = response.data;

            if (data.status === 0) {
              if (Number(data.totalCount) > 0) {
                setisContact(true);
              } else {
                setisContact(false);
              }
            } else {
              setisContact(false);
            }

            setGridCount(Number(data.totalCount));
            setTimeout(() => {
              var gridInfo = [];
              var totalRecord = 0;
              if (data.status === 0) {
                gridInfo = data.record;
                totalRecord = Number(data.totalCount);
                setisContact(true);
              } else {
                gridInfo = [];
                totalRecord = 0;
                setisContact(false);
              }

              var rowsThisPage = gridInfo;
              var lastRow = -1;

              if (Number(totalRecord) <= params.endRow) {
                lastRow = totalRecord;
              }

              params.successCallback(rowsThisPage, lastRow);
            }, 500);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      },
    };
  }, [userSearch, isContactSearch, session, trigger]); // eslint-disable-line react-hooks/exhaustive-deps

  const gridColDef = useMemo(() => {
    return {
      sortable: false,
      filter: false,
      suppressRowClickSelection: false,
    };
  }, []);

  const gridRowId = useCallback(function (params) {
    return params.data.prospect_id.toString();
  }, []);

  // USEEFFECT FUNCTION ------------------------------------------

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getUser(session);
      getCountry();
      getState();
      getCity();

      setTimeout(() => {
        setisLoading(false);
      }, 500);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className="p-4">
      {isLoading ? (
        <Loader />
      ) : (
        <Row>
          <Col xxl={12}>
            <Stack direction="horizontal" gap={3}>
              <button type="button" className="btn op-button op-primary-color text-light" onClick={setisAddProspect}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t("ProspectList_btn_add")}
              </button>
              <h6 className="ms-auto op-text-medium mt-2 ms-auto"> {gridCount === 1 ? t("ProspectList_record", { count: gridCount }) : gridCount > 1 ? t("ProspectList_record", { count: gridCount }) : t("ProspectList_record_no")}</h6>
              {Number(session.role_id) > 0 && (
                <FormSelect
                  options={user}
                  placeholder={t("ProspectList_user_dropdown_placeholder")}
                  isClearable={true}
                  onChange={(e) => {
                    e !== null ? setUserSearch(e.value) : setUserSearch("");
                  }}
                  width="250px"
                  border={false}
                  shadow={true}
                />
              )}
              <div className="d-flex">
                <Form.Control type="text" className="border-0 me-1 shadow-sm" onChange={(e) => setContactSearch(e.target.value)} onKeyDown={handleKeyPress} placeholder={t("ProspectList_search_placeholder")} style={{ width: 250 }} />
                <button type="button" className="btn btn-light shadow-sm" onClick={() => setisContactSearch(Math.random())}>
                  <FontAwesomeIcon icon={faSearch} size="lg" />
                </button>
              </div>
            </Stack>
          </Col>
          <Col xxl={12}>
            <div style={containerStyle}>
              <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={gridColumn}
                  datasource={gridDataSource}
                  defaultColDef={gridColDef}
                  rowSelection={"multiple"}
                  rowModelType={"infinite"}
                  rowHeight={70}
                  cacheBlockSize={100}
                  cacheOverflowSize={2}
                  maxConcurrentDatasourceRequests={2}
                  infiniteInitialRowCount={10}
                  maxBlocksInCache={2}
                  getRowId={gridRowId}
                  pagination={true}
                  paginationPageSize={100}
                  paginationPageSizeSelector={false}
                  suppressRowClickSelection={true}
                  animateRows={true}
                />
              </div>
            </div>
            {!isContact && <NoRecord message={t("ProspectList_no_record_message")} description={t("ProspectList_no_record_desc")} width={300} />}
          </Col>
        </Row>
      )}

      <Modal show={isAddProspect} onHide={setisAddProspect}>
        <Formik
          validationSchema={addProspectSchema}
          onSubmit={sendAddProspect}
          initialValues={{
            prospect: "",
            designation_id: "",
            designation_title: "",
            address: "",
            contact: [{ id: Math.random(), contact: "", contact_type: { label: t("ProspectList_phoneType_list_label_1"), value: "Mobile" } }],
            email: [{ id: Math.random(), email: "", email_type: { label: t("ProspectList_emailType_list_label_1"), value: "Work" } }],
          }}
        >
          {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">{t("ProspectList_modalHeader_add_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_1")}</Form.Label>
                  <Form.Control type="text" isInvalid={errors.prospect && touched.prospect} onChange={handleChange("prospect")} />
                  {errors.prospect && touched.prospect && <div className="op-error-message">{errors.prospect}</div>}
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_2")}</Form.Label>
                  <FormSelectCreatable
                    loadOptions={getExistingDesignation}
                    isLoading={isFindDesignation}
                    isInvalid={errors.designation_id && touched.designation_id}
                    onChange={(info) => {
                      handleChange({
                        target: { name: "designation_id", value: info.value },
                      });

                      handleChange({
                        target: { name: "designation_title", value: info.label },
                      });
                    }}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_3")}</Form.Label>
                  <Form.Control as={"textarea"} rows={3} type="text" onChange={handleChange("address")} />
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_4")}</Form.Label>
                  {values.contact.map((record, index) => (
                    <Stack key={record.id} direction="horizontal" gap={1} className="mb-1">
                      <Form.Group className="w-75">
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            const updatedContacts = [...values.contact];
                            updatedContacts[index] = {
                              id: updatedContacts[index].id,
                              contact: e.target.value,
                              contact_type: updatedContacts[index].contact_type,
                            };
                            setValues((prevValues) => ({
                              ...prevValues,
                              contact: updatedContacts,
                            }));
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="w-25">
                        <FormSelect
                          options={phoneType}
                          valueDefault={record.contact_type}
                          onChange={(e) => {
                            const updatedContacts = [...values.contact];
                            updatedContacts[index] = {
                              id: updatedContacts[index].id,
                              contact: updatedContacts[index].contact,
                              contact_type: e,
                            };
                            setValues((prevValues) => ({
                              ...prevValues,
                              contact: updatedContacts,
                            }));
                          }}
                        />
                      </Form.Group>
                      {values.contact.length > 1 && (
                        <Form.Group>
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              var contactsArray = values.contact;
                              var contactToDelete = contactsArray[index].id;
                              var contactsUpdated = contactsArray.filter((record) => record.id !== contactToDelete);
                              console.log(contactsUpdated);
                              setValues((prevValues) => ({
                                ...prevValues,
                                contact: contactsUpdated,
                              }));
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </Form.Group>
                      )}
                    </Stack>
                  ))}
                  {errors.contact && <div className="op-error-message">{errors.contact}</div>}
                  <Button
                    variant="link"
                    onClick={() => {
                      if (values.contact.length === 5) {
                        Swal.fire({
                          icon: "warning",
                          title: t("ProspectList_alert_warning_5_title"),
                          text: t("ProspectList_alert_warning_5_text"),
                          timer: 2000,
                        });
                        return;
                      }

                      setValues((prevValues) => ({
                        ...prevValues,
                        contact: [...prevValues.contact, { id: Math.random(), contact: "", contact_type: { label: "Mobile", value: "Mobile" } }],
                      }));
                    }}
                    style={{ textDecoration: "none", paddingLeft: 0 }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    {t("ProspectList_modalBody_add_title_4_addPhone")}
                  </Button>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_add_title_5")}</Form.Label>
                  {values.email.map((record, index) => (
                    <Stack key={record.id} direction="horizontal" gap={1} className="mb-1">
                      <Form.Group className="w-75">
                        <Form.Control
                          type="text"
                          onChange={(e) => {
                            const updatedEmails = [...values.email];
                            updatedEmails[index] = {
                              id: updatedEmails[index].id,
                              email: e.target.value,
                              email_type: updatedEmails[index].email_type,
                            };
                            setValues((prevValues) => ({
                              ...prevValues,
                              email: updatedEmails,
                            }));
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="w-25">
                        <FormSelect
                          options={emailType}
                          valueDefault={record.email_type}
                          onChange={(e) => {
                            const updatedEmails = [...values.email];
                            updatedEmails[index] = {
                              id: updatedEmails[index].id,
                              email: updatedEmails[index].email,
                              email_type: e,
                            };
                            setValues((prevValues) => ({
                              ...prevValues,
                              email: updatedEmails,
                            }));
                          }}
                        />
                      </Form.Group>
                      {values.email.length > 1 && (
                        <Form.Group>
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              var emailArray = values.email;
                              var emailToDelete = emailArray[index].id;
                              var emailUpdated = emailArray.filter((record) => record.id !== emailToDelete);
                              setValues((prevValues) => ({
                                ...prevValues,
                                email: emailUpdated,
                              }));
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </Form.Group>
                      )}
                    </Stack>
                  ))}
                  <Button
                    variant="link"
                    onClick={() => {
                      if (values.email.length === 3) {
                        Swal.fire({
                          icon: "warning",
                          title: t("ProspectList_alert_warning_6_title"),
                          text: t("ProspectList_alert_warning_6_text"),
                          timer: 2000,
                        });
                        return;
                      }

                      setValues((prevValues) => ({
                        ...prevValues,
                        email: [...prevValues.email, { id: Math.random(), email: "", email_type: { label: "Work", value: "Work" } }],
                      }));
                    }}
                    style={{ textDecoration: "none", paddingLeft: 0 }}
                  >
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    {t("ProspectList_modalBody_add_title_5_addEmail")}
                  </Button>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light shadow">
                  {t("ProspectList_modalFooter_submit")}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={isEditProspect} onHide={setIsEditProspect}>
        {/* <ProspectEdit id={prospectID}/> */}
        <Formik validationSchema={editProspectSchema} onSubmit={sendEditProspect} initialValues={prosCfInitVal}>
          {({ handleSubmit, handleChange, setValues, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title className="op-text-bigger">{t("ProspectList_modalHeader_edit_title")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_1")}</Form.Label>
                  <Form.Control type="text" name="prospectEdit" value={values.prospectEdit} isInvalid={errors.prospectEdit && touched.prospectEdit} onChange={handleChange("prospectEdit")} />
                  {errors.prospectEdit && touched.prospectEdit && <div className="op-error-message">{errors.prospectEdit}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_2")}</Form.Label>
                  <FormSelectCreatable
                    loadOptions={getExistingDesignation}
                    isLoading={isFindDesignation}
                    value={values.designationTitleEdit}
                    onChange={(info) => {
                      handleChange({
                        target: { name: "designationIDEdit", value: info.value },
                      });

                      handleChange({
                        target: { name: "designationTitleEdit", value: info.label },
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_3")}</Form.Label>
                  <FormSelect
                    placeholder={t("ProspectList_modalBody_edit_title_3_placeholder")}
                    options={user}
                    valueDefault={user.filter((option) => option.value === values.prospectOwnerIDEdit)}
                    isClearable={true}
                    isSearchable={true}
                    isInvalid={errors.prospectOwnerIDEdit && touched.prospectOwnerIDEdit}
                    onChange={(e) => {
                      e !== null ? handleChange("prospectOwnerIDEdit")(e.value) : handleChange("prospectOwnerIDEdit")("");
                    }}
                  />
                  {errors.prospectOwnerIDEdit && touched.prospectOwnerIDEdit && <div className="op-error-message">{errors.prospectOwnerIDEdit}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_4")}</Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Group className="w-25">
                      <FormSelect
                        placeholder={t("ProspectList_modalBody_edit_title_prefix_placeholder")}
                        valueDefault={prefix.filter((option) => option.value === values.contactNum1Pref)}
                        options={prefix}
                        isInvalid={errors.contactNum1Pref && touched.contactNum1Pref}
                        onChange={(e) => {
                          handleChange("contactNum1Pref")(e.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control type="text" value={values.contactNum1} name="contactNum1" className="w-75" isInvalid={errors.contactNum1 && touched.contactNum1} onChange={handleChange("contactNum1")} />
                  </Stack>
                  <Stack direction="horizontal" gap={2}>
                    {errors.contactNum1Pref && touched.contactNum1Pref && <div className="op-error-message w-25">{errors.contactNum1Pref}</div>}
                    {errors.contactNum1 && touched.contactNum1 && <div className="op-error-message w-75">{errors.contactNum1}</div>}
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_5")}</Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Group className="w-25">
                      <FormSelect
                        placeholder={t("ProspectList_modalBody_edit_title_prefix_placeholder")}
                        valueDefault={prefix.filter((option) => option.value === values.contactNum2Pref)}
                        options={prefix}
                        onChange={(e) => {
                          handleChange("contactNum2Pref")(e.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control type="text" value={values.contactNum2} className="w-75" isInvalid={errors.contactNum2 && touched.contactNum2} onChange={handleChange("contactNum2")} />
                  </Stack>
                  {errors.contactNum2 && touched.contactNum2 && <div className="op-error-message">{errors.contactNum2}</div>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_6")}</Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Group className="w-25">
                      <FormSelect
                        placeholder={t("ProspectList_modalBody_edit_title_prefix_placeholder")}
                        valueDefault={prefix.filter((option) => option.value === values.contactNum3Pref)}
                        options={prefix}
                        onChange={(e) => {
                          handleChange("contactNum3Pref")(e.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control type="text" value={values.contactNum3} className="w-75" onChange={handleChange("contactNum3")} />
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_7")}</Form.Label>
                  <Stack direction="horizontal" gap={2}>
                    <Form.Group className="w-25">
                      <FormSelect
                        placeholder={t("ProspectList_modalBody_edit_title_prefix_placeholder")}
                        options={prefix}
                        valueDefault={prefix.filter((option) => option.value === values.contactNum4Pref)}
                        onChange={(e) => {
                          handleChange("contactNum4Pref")(e.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Control type="text" value={values.contactNum4} className="w-75" onChange={handleChange("contactNum4")} />
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_8")}</Form.Label>
                  <Form.Control type="text" value={values.email1} onChange={handleChange("email1")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_9")}</Form.Label>
                  <Form.Control type="text" value={values.email2} onChange={handleChange("email2")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_10")}</Form.Label>
                  <Form.Control type="text" value={values.email3} onChange={handleChange("email3")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_11")}</Form.Label>
                  <Form.Control type="text" value={values.address1} onChange={handleChange("address1")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_12")}</Form.Label>
                  <Form.Control type="text" value={values.address2} onChange={handleChange("address2")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_13")}</Form.Label>
                  <Form.Control type="text" value={values.address3} onChange={handleChange("address3")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_14")}</Form.Label>
                  <Form.Control type="text" value={values.postcode} onChange={handleChange("postcode")} />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_15")}</Form.Label>
                  <FormSelect
                    placeholder={t("ProspectList_modalBody_edit_title_15_placeholder")}
                    options={countryData}
                    valueDefault={countryData.filter((option) => Number(option.value) === Number(values.countryID))}
                    isSearchable={true}
                    isClearable={true}
                    onChange={(e) => {
                      handleChange("countryID")(e ? e.value : "0");
                      handleChange("stateID")("0");
                      handleChange("cityID")("0");
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_16")}</Form.Label>
                  <FormSelect
                    placeholder={t("ProspectList_modalBody_edit_title_16_placeholder")}
                    options={Number(values.countryID) > 0 ? stateData.filter((record) => Number(record.country) === Number(values.countryID)) : stateData}
                    valueDefault={stateData.filter((option) => Number(option.value) === Number(values.stateID))}
                    isSearchable={true}
                    isClearable={true}
                    onChange={(e) => {
                      handleChange("stateID")(e ? e.value : "0");
                      handleChange("cityID")("0");
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("ProspectList_modalBody_edit_title_17")}</Form.Label>
                  <FormSelect
                    placeholder={t("ProspectList_modalBody_edit_title_17_placeholder")}
                    options={Number(values.stateID) > 0 ? cityData.filter((record) => Number(record.state) === Number(values.stateID)) : cityData}
                    valueDefault={cityData.filter((option) => Number(option.value) === Number(values.cityID))}
                    isSearchable={true}
                    isClearable={true}
                    onChange={(e) => {
                      handleChange("cityID")(e ? e.value : "0");
                    }}
                  />
                </Form.Group>
                {prosCF.map((record, index) => (
                  <FormCustomField
                    key={record.cf_id}
                    id={record.cf_id}
                    label={record.cf_label}
                    options={record.cf_value}
                    type={record.ctf_title}
                    name={record.cf_id}
                    placeholder=""
                    value={record.fd_value}
                    onChange={(info) => {
                      const updatedCustomFields = [...values.cf_person];
                      if (record.ctf_title === "Select") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.value,
                        };
                      } else if (record.ctf_title === "Radio") {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.checked ? info.target.value : "",
                        };
                      } else if (record.ctf_title === "Checkbox") {
                        const { value, checked } = info.target;
                        const currentValues = typeof values.cf_person[index].fd_values === "string" ? values.cf_person[index].fd_values : "";
                        const updatedValues = currentValues.split(",");
                        const emptyIndex = updatedValues.indexOf("");

                        if (emptyIndex !== -1) {
                          updatedValues.splice(emptyIndex, 1);
                        }

                        if (checked) {
                          if (!updatedValues.includes(value)) {
                            updatedValues.push(value);
                          }
                        } else {
                          const indexToRemove = updatedValues.indexOf(value);
                          if (indexToRemove !== -1) {
                            updatedValues.splice(indexToRemove, 1);
                          }
                        }

                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          name: record.cf_id,
                          fd_values: updatedValues.join(","),
                        };
                      } else {
                        updatedCustomFields[index] = {
                          cf_id: record.cf_id,
                          fd_values: info.target.value,
                        };
                      }

                      handleChange({
                        target: { name: "cf_person", value: updatedCustomFields },
                      });
                    }}
                  />
                ))}
              </Modal.Body>
              <Modal.Footer>
                <button type="submit" className="btn op-button op-primary-color text-light">
                  Submit
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </Container>
  );
};

export default Prospect;
